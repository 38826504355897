import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button, { buttonTypes } from '../Button/Button';
import EntityListing from '../EntityListing/EntityListing';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './ManagedUserUploadListing.css';
import PaginationButtons from '../PaginationButtons/PaginationButtons';
import Link from '../Link/Link';
import userRoles, { userRoleFriendlyNames } from '../../globals/userRoles';

class ManagedUserUploadListing extends Component {
  _renderStudentCount = ({ count }) => {
    const {
      localizedContent: { managedUserUploadListingComponent: content }
    } = this.props;
    return (
      <span className={styles.description}>
        {count} {content.students_added}
      </span>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  _renderCreatedAt = ({ date }) => <div className={styles.createdAt}>{moment(date).format('LT')}</div>;

  // eslint-disable-next-line class-methods-use-this
  _renderCompletedBy = ({ creatorName, creatorLink, creatorRole }) => {
    if ([userRoles.OUP_ADMIN, userRoles.OUP_SUPPORT].includes(creatorRole)) {
      return <div className={styles.creator}>{userRoleFriendlyNames(creatorRole)}</div>;
    }
    return (
      <Link to={creatorLink}>
        <div className={styles.creator}>{creatorName}</div>
      </Link>
    );
  };

  _renderAction = ({ url, isExpired }) => {
    const {
      localizedContent: { managedUserUploadListingComponent: content }
    } = this.props;
    return (
      <Button
        type={buttonTypes.ROUNDED}
        to={url}
        disabled={isExpired}
        text={content.view_sign_in_cards_button}
        glyph={GLYPHS.ICON_PDF}
      />
    );
  };

  _renderContent = () => {
    const {
      localizedContent: { managedUserUploadListingComponent: content },
      activity = {}
    } = this.props;
    return Object.entries(activity).map(([date, uploads], $0) => (
      <div key={$0} className="gin-bot2">
        <EntityListing
          items={uploads.reduce(
            (carry, upload, index) => ({
              ...carry,
              [upload.id || index]: {
                [date]: this._renderStudentCount(upload),
                [content.created_at_column_heading]: this._renderCreatedAt(upload),
                [content.completed_by_column_heading]: this._renderCompletedBy(upload),
                Action: this._renderAction(upload)
              }
            }),
            {}
          )}
        />
      </div>
    ));
  };

  render() {
    const { page, numberOfPages, onSetPageClick } = this.props;

    return (
      <div>
        {this._renderContent()}

        {numberOfPages > 1 ? (
          <div className="gin-top2">
            <PaginationButtons
              idPrefix="searchResults"
              value={page}
              numberOfPages={numberOfPages}
              onClick={onSetPageClick}
              aria={{ 'aria-controls': 'searchResults' }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ManagedUserUploadListing.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  activity: PropTypes.object,
  page: PropTypes.number,
  numberOfPages: PropTypes.number,
  onSetPageClick: PropTypes.func
};

export default withLocalizedContent('managedUserUploadListingComponent')(ManagedUserUploadListing);
