import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import styles from './SkeletonLoader.scss';

function ClassOrgLoader(props) {
  const { showUserDetails, tabHeight } = props;
  return (
    <div className={styles.skeletonLoader}>
      <div className={styles.headerSection}>
        <div className="grid">
          {showUserDetails && (
            <div className={`row ${styles.row}`}>
              <div className="col">
                <ContentLoader height="75" width="100%" viewBox="0 0 100% 75" {...props}>
                  <circle cx="40" cy="40" r="35" fill="white" />
                  <rect x="100" y="10" rx="5" ry="5" width="50%" height="34" />
                  <rect x="100" y="50" rx="5" ry="5" width="30%" height="20" />
                </ContentLoader>
              </div>
            </div>
          )}
          <div className={`row ${styles.row}`}>
            <div className="col">
              <ContentLoader height={`${tabHeight}`} width="100%" viewBox={`0 0 100% ${tabHeight}`} {...props}>
                <rect x="0" y="0" rx="5" ry="5" width="15%" height={tabHeight + 3} />
                <rect x="16%" y="0" rx="5" ry="5" width="15%" height={tabHeight + 3} />
                <rect x="32%" y="0" rx="5" ry="5" width="15%" height={tabHeight + 3} />
              </ContentLoader>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ClassOrgLoader.propTypes = {
  // Prop Defined
  speed: PropTypes.number,
  foregroundColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  showUserDetails: PropTypes.bool,
  tabHeight: PropTypes.number
};

ClassOrgLoader.defaultProps = {
  showUserDetails: true,
  tabHeight: 50
};

export default ClassOrgLoader;
