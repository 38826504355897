import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { safePanelUnlink } from '../../../../../../utils/links/panelLinks.js';

import { resetForm } from '../../../../../../redux/reducers/addToLibrary.reducer.js';

import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import DataList from '../../../../../../components/DataList/DataList.js';
import { isHubMode } from '../../../../../../utils/platform.js';
import cmsContent from '../../../../../../utils/cmsContent.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../globals/hubConstants.js';

function AddToLibraryErrorReviewing({ resetAction, data, closePanel }) {
  const history = useHistory();
  const CMS = cmsContent.addToLibrary || {};

  const failedDataPairs = data.map(({ row, value, cmsErrorKeys }) => ({
    title:
      row === '0'
        ? CMS.file_format_error_title
        : CMS.row_error_title.replace('{n}', row).replace('{value}', value === undefined ? '' : value),
    value: cmsErrorKeys.map(val => CMS[val]).join(', '),
    status: 'ERROR'
  }));

  const handleClosePanel = () => {
    if (isHubMode()) {
      closePanel();
    }
    // Close the Popout panel
    history.push(safePanelUnlink('addLicences'));
    // Once its closed then reset the form
    setTimeout(resetAction, 300);
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      {data.length > 0 ? (
        /* ERROR SCENARIO 1: Errors found in data: */
        <ScrollContainer
          headerContent={
            <div>
              <div className="text-right">
                <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={handleClosePanel} />
              </div>
              {isHubMode() ? (
                <PopoutPanelIllustrationHeading
                  title={(CMS.bulk_upload_file_errors_text || '').replace('{errorCount}', data.length)}
                  subtitle={CMS.bulk_upload_file_errors_body_text}
                  illustrationSrc={HubIllustrationConstants.ERROR}
                  illustrationAltText={HubIllustrationAltText.ERROR}
                />
              ) : (
                <PopoutPanelIconHeading
                  type={iconHeadingTypes.ERROR}
                  title={(CMS.bulk_upload_file_errors_text || '').replace('{errorCount}', data.length)}
                  subtitle={CMS.bulk_upload_file_errors_body_text}
                />
              )}
            </div>
          }
          footerContent={<PopoutNavFooter backAction={resetAction} />}
        >
          <div id="error-list-container" style={{ padding: '1rem' }}>
            <DataList dataPairs={failedDataPairs} />
          </div>
        </ScrollContainer> /* ERROR SCENARIO 2: No error found in data, but error uploading: */
      ) : (
        <ScrollContainer
          headerContent={
            <div>
              <div className="text-right">
                <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={handleClosePanel} />
              </div>

              {isHubMode() ? (
                <PopoutPanelIllustrationHeading
                  title={CMS.error_message_uploadError_intro_text}
                  subtitle={CMS.error_message_uploadError_outro_text}
                  illustrationSrc={HubIllustrationConstants.ERROR}
                  illustrationAltText={HubIllustrationAltText.ERROR}
                />
              ) : (
                <PopoutPanelIconHeading
                  type={iconHeadingTypes.ERROR}
                  title={CMS.error_message_uploadError_intro_text}
                  subtitle={CMS.error_message_uploadError_outro_text}
                />
              )}
            </div>
          }
          footerContent={<PopoutNavFooter backAction={resetAction} />}
        />
      )}
    </form>
  );
}

AddToLibraryErrorReviewing.propTypes = {
  resetAction: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string,
      status: PropTypes.string
    })
  ).isRequired,
  closePanel: PropTypes.func
};

export default connect(
  state => ({
    data: state.addToLibrary.failedEntries
  }),
  {
    // Done button
    resetAction: resetForm
  }
)(AddToLibraryErrorReviewing);
