import { call, put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes.js';
import {
  invalidateContentSelectorGroupSuccess,
  invalidateContentSelectorGroupFailure
} from '../../../../actions/invalidateContentSelectorGroup.actions.js';
import invalidateContentSelectorGroupApi from '../../../apiCalls/ngi/invalidateContentSelectorGroupApi.js';

function* fetchInvalidateContentGroup(action) {
  try {
    const response = yield call(invalidateContentSelectorGroupApi, action.payload);
    yield put(invalidateContentSelectorGroupSuccess(response));
    action.callback();
  } catch (error) {
    yield put(invalidateContentSelectorGroupFailure('Failed to invalidate content selector session'));
  }
}

function* fetchInvalidateContentSelectorGroupSaga() {
  yield takeLatest(t.NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_REQUEST, fetchInvalidateContentGroup);
}

export default fetchInvalidateContentSelectorGroupSaga;
