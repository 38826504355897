import React from 'react';
import PropTypes from 'prop-types';
import TableAccordion, { columnTypes } from '../TableAccordion/TableAccordion';
import Button, { buttonTypes } from '../Button/Button';
import { rowTypes } from '../Table/Table';
import TimeTag from '../TimeTag/TimeTag';
import { roleCanLinkOicOrgToJanison } from '../../globals/userRoles';
import styles from './ResultComponent.scss';
import { mode } from '../../redux/reducers/oicLinkOrgToJanison.reducer';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Tooltip from '../Tooltip/Tooltip';
import CopyToClipboardButton from '../CopyToClipboardButton/CopyToClipboardButton';

function NoResults({ localizedContent }) {
  return (
    <div className="grid">
      <div className="row">
        <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
          <p id="searchResults-orgs-noResults" className="gin-top1 gin-bot1">
            {localizedContent?.no_search_results_text}
          </p>
        </div>
      </div>
    </div>
  );
}

NoResults.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

const getRows = (
  localizedContent,
  resultIds,
  orgs,
  orgsDataRecency,
  userRole,
  oicLinkOrgToJanison,
  oicLinkOrgToJanisonAction
) =>
  resultIds.map(id => {
    const org = orgs[id] || {};
    const orgState = oicLinkOrgToJanison?.organizations[id] || {};
    const isProcessing = orgsDataRecency?.syncing && orgsDataRecency?.ids.includes(id);
    const isLoading = orgState.mode === mode.LOADING;
    const isSuccess = orgState.mode === mode.SUCCESS;
    const isError = orgState.mode === mode.ERROR;
    const notLinkedOrErrorMessage = isError
      ? localizedContent.action_message_error
      : localizedContent.table_head_link_action;

    const cells = [
      <div key={0}>{org.name}</div>,
      <div key={1} className="flex-row align-items-center">
        <pre>{id}</pre>
        <CopyToClipboardButton classCode={id} label={localizedContent.action_copy_organization_id} />
      </div>,
      <div key={2} id={`searchResults-date-${id}`}>
        <TimeTag dateString={org?.createdDate} />
      </div>
    ];

    if (roleCanLinkOicOrgToJanison(userRole)) {
      cells.push(
        <div key={3}>
          {!isSuccess && (
            <Tooltip title={isError && orgState.errors} className={styles.tooltip}>
              <Button
                text={isLoading ? localizedContent.action_message_loading : notLinkedOrErrorMessage}
                onClick={() => oicLinkOrgToJanisonAction({ input: { orgId: id } })}
                customClassName={styles.linkButton}
                type={buttonTypes.NO_BORDER}
                glyph={GLYPHS.ICON_INTEGRATION}
                hasGlyphContainer
                glyphClassName={styles.glyphClassName}
                disabled={isLoading}
                loading={isLoading}
              />
            </Tooltip>
          )}
          {isSuccess && (
            <div className={styles.feedback}>
              <span>{localizedContent.action_message_success}</span>
              <SVGIcon glyph={GLYPHS.ICON_CHECK_CIRCLE} />
            </div>
          )}
        </div>
      );
    }

    return {
      id: `org${id}`,
      cells,
      ...(isProcessing ? { type: rowTypes.PROCESSING } : {})
    };
  });

function ResultComponent({
  localizedContent,
  userRole,
  resultIds,
  orgs,
  orgsDataRecency,
  oicLinkOrgToJanison,
  oicLinkOrgToJanisonAction
}) {
  const columns = [
    {
      heading: localizedContent.table_head_organization,
      type: columnTypes.STATUS
    },
    {
      heading: localizedContent.table_head_id,
      type: columnTypes.TEXT
    },
    {
      heading: localizedContent.table_head_created_date,
      type: columnTypes.TEXT
    },
    {
      heading: localizedContent.table_head_link_action,
      type: columnTypes.BUTTON
    }
  ];

  const hasResults = resultIds.length > 0;
  const rows = getRows(
    localizedContent,
    resultIds,
    orgs,
    orgsDataRecency,
    userRole,
    oicLinkOrgToJanison,
    oicLinkOrgToJanisonAction
  );

  return hasResults ? (
    <TableAccordion customClass={styles.oicOrsTable} columns={columns} rows={rows} />
  ) : (
    <NoResults localizedContent={localizedContent} />
  );
}

ResultComponent.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  orgs: PropTypes.array.isRequired,
  resultIds: PropTypes.array.isRequired,
  orgsDataRecency: PropTypes.object.isRequired,
  oicLinkOrgToJanison: PropTypes.object.isRequired,
  oicLinkOrgToJanisonAction: PropTypes.func.isRequired
};

export default ResultComponent;
