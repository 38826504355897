import PropTypes from 'prop-types';
import React from 'react';
import PanelHeading from '../../../../../components/PanelHeading/PanelHeading';
import PopoutNavFooter from '../../../../../components/PopoutNavFooter/PopoutNavFooter';
import ScrollContainer from '../../../../../components/ScrollContainer/ScrollContainer';
import PanelNavigationLink from '../../../../../components/PanelNavigationLink/PanelNavigationLink.js';

function JoinAClassReviewing({
  localizedContent: { studentRequestToJoinAClass: content },
  onBackClick,
  onSubmitClick,
  closePanel,
  orgClassDetails: { organisationName, className }
}) {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={content.close_panel_text} action={closePanel} />
            </div>
            <PanelHeading title={content.review_title} subtitle={content.review_subtitle || ''} />
          </div>
        }
        footerContent={
          <PopoutNavFooter
            backAction={onBackClick}
            backButtonText={content.review_back_button}
            nextAction={() => onSubmitClick('confirm')}
            nextButtonText={content.review_submit_button}
            nextButtonDisabled={false}
          />
        }
      >
        <div className="gin1">
          <div className="text-center">
            <h2>{content.review_org_text}</h2>
            <p className="lead">{organisationName}</p>
            <h2>{content.review_class_text}</h2>
            <p className="lead">{className}</p>
          </div>
        </div>
      </ScrollContainer>
    </form>
  );
}

JoinAClassReviewing.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  onBackClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  closePanel: PropTypes.func,
  orgClassDetails: PropTypes.object
};

export default JoinAClassReviewing;
