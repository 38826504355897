import React from 'react';
import PropTypes from 'prop-types';

import style from './PillTabs.scss';

function PillTabs(props) {
  const { current, items } = props;

  return (
    <div className={style.tabs}>
      <ul>
        {items.map((item, index) => (
          <li className={current === index ? style.active : ''} key={index}>
            <a onClick={item.action} onKeyPress={item.action} role="button" tabIndex="0">
              {item.tabText}
            </a>
          </li>
        ))}
      </ul>
      <div className={style.panel}>{items[current].panelContent}</div>
    </div>
  );
}

PillTabs.propTypes = {
  current: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      tabText: PropTypes.string.isRequired,
      panelContent: PropTypes.element.isRequired
    })
  ).isRequired
};

export default PillTabs;
