import { put, take } from 'redux-saga/effects';
import { getContentPreviewSuccess, getContentPreviewFailure } from '../../../../actions/contentPreview';
import { GET_EREADER_PRODUCTS_REQUEST } from '../../../../actionTypes';
import getEReaderProductsRequest from './getEReaderProductsApi.js';

function* getEReaderProducts() {
  while (true) {
    yield take(GET_EREADER_PRODUCTS_REQUEST);

    console.log('[getEReaderProducts Saga] Start Request');
    const result = yield getEReaderProductsRequest();

    if (result) {
      console.log('[getEReaderProducts Saga] Request completed, showing results');
      yield put(getContentPreviewSuccess(result));
    } else {
      console.log('[getEReaderProducts Saga] Request completed, but failed, showing error');
      yield put(getContentPreviewFailure(result.error));
    }
  }
}

export default getEReaderProducts;
