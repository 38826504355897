import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GlyphIcon } from '../IconEmblem/IconEmblem';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ContentPlayerSwitchBook.scss';
import changeRoleToProductVariant from '../StructuredContentPlayer/structuredContentPlayerUtils';
import { toggleSwitchBook } from '../../redux/actions/structuredContentPlayer';

const { getProductTypeFromLinkedContentCode, abbreviateProductType } = require('@oup/shared-node-browser/product.js');
const { ELT_PRODUCT_TYPES, ELT_PRODUCT_TYPES_SHORT } = require('../../../lambda/common/constants');

const findLinkedContentCodes = activity => {
  const linkedContentCodes = {
    sb: [],
    wb: [],
    op: []
  };

  if (activity.linkedContentCodes) {
    activity.linkedContentCodes.forEach(productObj => {
      const product = Object.keys(productObj)[0];
      const { linkedNodeId } = productObj[product];
      const productType = getProductTypeFromLinkedContentCode(product);
      const [contentId] = product.split('_');
      let productWithId = { product, id: linkedNodeId, contentId };
      switch (productType) {
        case ELT_PRODUCT_TYPES.STUDENTBOOK:
          productWithId = {
            ...productWithId,
            type: ELT_PRODUCT_TYPES_SHORT.STUDENTBOOK_SHORT,
            isClickable: activity.isClickable
          };
          linkedContentCodes.sb.push(productWithId);
          break;
        case ELT_PRODUCT_TYPES.WORKBOOK:
          productWithId = {
            ...productWithId,
            type: ELT_PRODUCT_TYPES_SHORT.WORKBOOK_SHORT,
            isClickable: activity.isClickable
          };
          linkedContentCodes.wb.push(productWithId);
          break;
        case ELT_PRODUCT_TYPES.ONLINEPRACTICE:
          productWithId = {
            ...productWithId,
            type: ELT_PRODUCT_TYPES_SHORT.ONLINEPRACTICE_SHORT,
            isClickable: activity.isClickable
          };
          linkedContentCodes.op.push(productWithId);
          break;
        default:
          break;
      }
    });
  }

  return linkedContentCodes;
};

const renderSwitchBookContents = (
  isSwitchBookOpen,
  linkedContentCodes,
  cptContent,
  productVariant,
  toggleSwitchBookAction
) => {
  const viewVersion = changeRoleToProductVariant(productVariant);
  const url = `http://${window.location.host}/launch/${viewVersion}/`;
  const shortViewVersion = abbreviateProductType(viewVersion);

  const hasLinkedContentCodes =
    linkedContentCodes?.sb.length > 0 || linkedContentCodes?.wb.length > 0 || linkedContentCodes?.op.length > 0;

  if (!isSwitchBookOpen) {
    return null;
  }

  if (isSwitchBookOpen && !hasLinkedContentCodes) {
    return (
      <div className={styles.switchBookContainer}>
        <div className={styles.switchBookLinkContainer}>
          <div className={styles.switchBookChild}>
            <p>{cptContent.no_linked_content_codes}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.switchBookContainer}>
      {linkedContentCodes.sb.length > 0 && (
        <div className={styles.switchBookLinkContainer}>
          {linkedContentCodes.sb.map((item, index) => (
            <a
              disabled={!item.isClickable}
              key={index}
              onClick={() => toggleSwitchBookAction()}
              href={`${url}${item.contentId}?type=${item.type}&version=${shortViewVersion}&node=${item.id}`}
              className={styles.switchBookChild}
              target="_self"
              rel="noreferrer"
            >
              {cptContent.studentBook}
              <SVGIcon className={styles.switchBookSVG} glyph={GlyphIcon.ICON_EXTERNAL_LINK} />
            </a>
          ))}
        </div>
      )}
      {linkedContentCodes.wb.length > 0 && (
        <div className={styles.switchBookLinkContainer}>
          {linkedContentCodes.wb.map((item, index) => (
            <a
              disabled={!item.isClickable}
              key={index}
              onClick={() => toggleSwitchBookAction()}
              href={`${url}${item.contentId}?type=${item.type}&version=${shortViewVersion}&node=${item.id}`}
              className={styles.switchBookChild}
              target="_self"
              rel="noreferrer"
            >
              {cptContent.workbook}
              <SVGIcon className={styles.switchBookSVG} glyph={GlyphIcon.ICON_EXTERNAL_LINK} />
            </a>
          ))}
        </div>
      )}
      {linkedContentCodes.op.length > 0 && (
        <div className={styles.switchBookLinkContainer}>
          {linkedContentCodes.op.map((item, index) => (
            <a
              disabled={!item.isClickable}
              key={index}
              onClick={() => toggleSwitchBookAction()}
              href={`${url}${item.contentId}?type=${item.type}&version=${shortViewVersion}&node=${item.id}`}
              className={styles.switchBookChild}
              target="_self"
              rel="noreferrer"
            >
              {cptContent.online_practice}
              <SVGIcon className={styles.switchBookSVG} glyph={GlyphIcon.ICON_EXTERNAL_LINK} />
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

function ContentPlayerSwitchBook({
  isSwitchBookOpen,
  cptContent,
  productVariant,
  currentActivities,
  currentActivity,
  toggleSwitchBookAction
}) {
  let linkedContentCodes;
  const currentActivityObj = currentActivity
    ? currentActivities.find(activity => activity.id === currentActivity)
    : null;

  if (currentActivityObj) {
    linkedContentCodes = findLinkedContentCodes(currentActivityObj);
  }

  return renderSwitchBookContents(
    isSwitchBookOpen,
    linkedContentCodes,
    cptContent,
    productVariant,
    toggleSwitchBookAction
  );
}

ContentPlayerSwitchBook.propTypes = {
  isSwitchBookOpen: PropTypes.bool,
  currentActivity: PropTypes.string,
  currentActivities: PropTypes.object,
  toggleSwitchBookAction: PropTypes.func
};

const mapStateToProps = ({ structuredContentPlayer: { productVariant, navigation } }) => ({
  productVariant,
  currentActivities: navigation.currentActivities,
  currentActivity: navigation.currentActivity
});

const mapDispatchToProps = {
  toggleSwitchBookAction: toggleSwitchBook
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentPlayerSwitchBook);
