// Create className helper functions for converting color names into the related CSS classname

// Basic color classes
export const fgClass = colorName => `color-${colorName}-fg`;
export const bgClass = colorName => `color-${colorName}-bg`;
export const bdClass = colorName => `color-${colorName}-bd`;

// Dark color variants for border
export const darkBdClass = colorName => `color-${colorName}-dark-bd`;

// Dark hover classname for button hover states
export const darkBgHoverClass = colorName => `color-${colorName}-dark-bg-hover`;
