import { put } from 'redux-saga/effects';
import { loadCptContentSuccess } from '../../../../actions/structuredContentPlayer';

function* getMockCptContent(params) {
  const contentCode = params.contentCode;

  let products = localStorage.getItem('offline_products');
  products = JSON.parse(products);
  products[contentCode].product.hasLicense = true;
  products[contentCode].product.lockedNodes = [];
  products[contentCode].product.dictionaryEnabled = false;
  products[contentCode].product.resources = [];

  yield put(loadCptContentSuccess(products[contentCode].product));
}

export default getMockCptContent;
