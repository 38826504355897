// Helper to prepend all the object's keys with a specified string.
// Does not add prefix where it's already present.
// Eg: Change {haspopup:true} to {'aria-haspopup':true}

export default function prefixKeys(object, prefix) {
  return (
    object &&
    Object.keys(object).reduce((result, key) => {
      const attrName = key.indexOf(prefix) === 0 ? key : `${prefix}${key}`;
      // eslint-disable-next-line no-param-reassign
      result[attrName] = object[key];
      return result;
    }, {})
  );
}
