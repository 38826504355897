/* eslint-disable react/jsx-curly-newline */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import colors from '../../globals/colors.js';
// TODO: Fetch these from Lambda/CMS:
import { defaultOrgRole, formattedOrgRoles } from '../../globals/orgRoles.js';
// This is the first form in the EDIT CLASS journey.

// Used for getting Regex
import appSettings from '../../globals/appSettings.js';

// Action imports
import { setFormState } from '../../redux/reducers/data/forms.reducer.js';

// Component imports
import InformationPopupModal from '../../components/InformationPopupModal/InformationPopupModal.js';
import { setInformationPopupViewed } from '../../redux/reducers/app.reducer.js';
import Validation from '../../components/Validation/Validation.js';
import Card from '../../components/Card/Card.js';
import Dropdown from '../../components/Dropdown/Dropdown.js';
import TextInput from '../../components/TextInput/TextInput.js';
import Button from '../../components/Button/Button.js';
import PageHeading from '../../components/PageHeading/PageHeading.js';

import ConnectedField from './formUtil/ConnectedField.js';
import FormValidity from './formUtil/FormValidity.js';

import cmsContent from '../../utils/cmsContent.js';

class CreateOrgForm extends Component {
  constructor(props) {
    super(props);
    // Toggle displaying the information popup when clicking "Learn More"
    this.state = {
      showPopup: false
    };
  }

  render() {
    const { gotoOrgReviewAction, setInformationPopupViewedAction } = this.props;
    const { showPopup } = this.state;
    const CMS = cmsContent.createOrganisation || {};
    const learnMoreCMS = cmsContent.learnMorePopup || {};

    return (
      <form id="registerOrgForm">
        <div className="row">
          <div className="col">
            <PageHeading title={CMS.register_organisation_title} subtitle={CMS.register_organisation_subtitle} />
          </div>
        </div>

        <div className="row gin-top4">
          <div className="col sm8">
            <Card headingText={CMS.register_organisation_header} headingColor={colors.ORGANIZATION}>
              <ConnectedField
                formName="orgCreate"
                fieldName="orgName"
                positiveValidationFunction={value => value.length > 2}
                negativeValidationFunction={value => value.length < 3}
                getValidationMessage={value => {
                  if (!value) {
                    return CMS.organisation_name_validation_text;
                  }
                  if (value.length < 3) {
                    return CMS.organisation_name_too_short_validation_text;
                  }
                  return null;
                }}
                render={(id, value, onChange, onBlur, message, isValid, isError) => (
                  <Validation isValid={isValid} isError={isError} message={message} forId={id}>
                    <TextInput
                      id={id}
                      label={CMS.organisation_name_label_text}
                      placeholder={CMS.organisation_name_label_placeholder}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </Validation>
                )}
              />

              <ConnectedField
                formName="orgCreate"
                fieldName="orgRole"
                initialValue={defaultOrgRole}
                reportErrorImmediately
                positiveValidationFunction={value => value !== ''}
                negativeValidationFunction={value => value === ''}
                getValidationMessage={value => (value === '' ? CMS.organisation_type_validation_text : null)}
                render={(id, value, onChange, onBlur, message, isValid, isError) => (
                  <Validation hideField isValid={isValid} isError={isError} message={message} forId={id}>
                    <Dropdown
                      id={id}
                      label={CMS.organisation_type_label_text}
                      value={value}
                      disabled
                      required
                      onChange={onChange}
                      options={[{ value: '', text: CMS.organisation_type_dropdown_title_text }, ...formattedOrgRoles]}
                    />
                  </Validation>
                )}
              />

              <ConnectedField
                formName="orgCreate"
                fieldName="orgEmail"
                positiveValidationFunction={value => appSettings.EMAIL_REGEX.test(value)}
                negativeValidationFunction={value => !appSettings.EMAIL_REGEX.test(value)}
                getValidationMessage={value =>
                  !appSettings.EMAIL_REGEX.test(value) ? CMS.organisation_email_validation_text : null
                }
                render={(id, value, onChange, onBlur, message, isValid, isError) => (
                  <Validation isValid={isValid} isError={isError} message={message} forId={id}>
                    <TextInput
                      id={id}
                      type="email"
                      label={CMS.organisation_email_label_text}
                      placeholder={CMS.organisation_email_placeholder_text}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </Validation>
                )}
              />

              <p className="pad-top1">{CMS.ensure_appropriate_permissions}</p>

              <ConnectedField
                formName="orgCreate"
                fieldName="orgWebsite"
                initialValueIsValid
                positiveValidationFunction={value => value === '' || appSettings.URL_REGEX.test(value)}
                negativeValidationFunction={value => value !== '' && !appSettings.URL_REGEX.test(value)}
                getValidationMessage={value =>
                  value !== '' && !appSettings.URL_REGEX.test(value)
                    ? CMS.organisation_webaddress_validation_text
                    : null
                }
                render={(id, value, onChange, onBlur, message, isValid, isError) => (
                  <Validation isValid={isValid} isError={isError} message={message} forId={id}>
                    <TextInput
                      id={id}
                      label={CMS.organisation_webaddress_label_text}
                      placeholder=""
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      required={false}
                    />
                  </Validation>
                )}
              />

              <div className="gin-top4">
                <FormValidity
                  formName="orgCreate"
                  requiredFields={['orgName', 'orgEmail']} // , 'orgRole'
                  render={valid => (
                    <Button
                      id="orgFormContinue"
                      text={CMS.button_continue_text}
                      onClick={gotoOrgReviewAction}
                      disabled={!valid}
                    />
                  )}
                />
              </div>
            </Card>
          </div>

          <div className="col sm4">
            <Card headingText={CMS.support_heading_title_text} headingColor={colors.DASHBOARD}>
              <h2>{CMS.support_heading_text}</h2>
              <p>{CMS.support_body_text}</p>
              <button
                type="button"
                className="gin-top2 color-primary-fg"
                onClick={() => this.setState({ showPopup: true })}
              >
                {CMS.support_learn_more_link}
              </button>
            </Card>
          </div>
        </div>
        {/* Information Popup Modal */}
        {showPopup && (
          <div>
            <InformationPopupModal
              negativeClick={() => {
                this.setState({ showPopup: false });
                setInformationPopupViewedAction();
                document.getElementById('learnMoreBtn').focus();
              }}
              firstSuggestionButton="/org/register"
              firstSuggestionButtonText={learnMoreCMS.content_final_page_register_organisation_button}
              secondSuggestionButtonText={learnMoreCMS.content_final_page_view_profile_button}
              secondSuggestionButton="/myProfile"
              displayAFinalPanel
              finalPanelTitle={learnMoreCMS.content_final_page_title}
              finalPanelSubtitle={learnMoreCMS.content_final_page_subtitle}
              contentList={[
                {
                  imgSrc: learnMoreCMS.page_image_source_0,
                  imgAlt: learnMoreCMS.imgAlt_0,
                  header: learnMoreCMS.content_page1_header,
                  body: learnMoreCMS.content_page1_body
                },
                {
                  imgSrc: learnMoreCMS.page_image_source_1,
                  imgAlt: learnMoreCMS.imgAlt_1,
                  header: learnMoreCMS.content_page2_header,
                  body: learnMoreCMS.content_page2_body
                },
                {
                  imgSrc: learnMoreCMS.page_image_source_2,
                  imgAlt: learnMoreCMS.imgAlt_2,
                  header: learnMoreCMS.content_page3_header,
                  body: learnMoreCMS.content_page3_body
                },
                {
                  imgSrc: learnMoreCMS.page_image_source_3,
                  imgAlt: learnMoreCMS.imgAlt_3,
                  header: learnMoreCMS.content_page4_header,
                  body: learnMoreCMS.content_page4_body
                }
              ]}
            />
          </div>
        )}
      </form>
    );
  }
}

CreateOrgForm.propTypes = {
  gotoOrgReviewAction: PropTypes.func.isRequired,
  setInformationPopupViewedAction: PropTypes.func.isRequired
};

export default connect(null, {
  gotoOrgReviewAction: () => setFormState('orgCreate', 'REVIEWING'),
  setInformationPopupViewedAction: setInformationPopupViewed
})(CreateOrgForm);
