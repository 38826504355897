import { difference, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  OrgUserEditForm,
  SidePanel,
  PasswordResetProcessing,
  PasswordResetSuccess,
  SendInvitationReminderProcessing,
  SendInvitationReminderSuccess,
  UserEditFailure,
  UserEditProcessing,
  UserEditSuccess,
  SendInvitationReminderFailure
} from '../components';
import withFormHandlers from '../decorators/withFormHandlers';
import userRoles, { userRoleFriendlyNamesObject, userRoleFriendlyNames } from '../globals/userRoles';
import { orgRoles } from '../globals/orgRoles';
import withLocalizedContent from '../language/withLocalizedContent';
import actions from '../redux/actions';
import getAssignableRoles from '../utils/assignableRoles';
import {
  cancelInvitationStates,
  componentStates as userProfileStates
} from '../redux/reducers/user/userProfile.reducer.js';
import createGetEditableUserInputs, { OrgUserInputs } from '../redux/selectors/createGetEditableUserInputs';
import { initialiseInstance } from '../redux/reducers/data/search.reducer';
import { isEltAssessmentMode } from '../utils/platform';

class OrgUserEditPanel extends Component {
  componentWillMount() {
    const { orgId, userId, isOpen, getUserDetails, getClassrooms } = this.props;
    if (isOpen) {
      getUserDetails(orgId, userId);
      getClassrooms(orgId);
    }
  }

  componentDidUpdate(prevProps) {
    const { orgId, userId, isOpen, getUserDetails, getClassrooms } = this.props;
    if (!prevProps.isOpen && isOpen) {
      getUserDetails(orgId, userId);
      getClassrooms(orgId);
    }
  }

  _edit = () => {
    const { orgId, userId, formInputChanges, edit } = this.props;
    edit(orgId, userId, formInputChanges);
  };

  _closePanel = () => {
    const { onClearFormChanges, reset, closePanel } = this.props;
    onClearFormChanges();
    reset();
    closePanel();
  };

  _renderContent = () => {
    const {
      localizedContent: { orgUserEditPanel: content },
      classrooms,
      orgId,
      email,
      hasFailed,
      isLoading,
      isSubmitting,
      isSuccessful,
      isSendReminderSubmitting,
      cancelInvitationState,
      userProfileState,
      isPasswordResetSubmitting,
      isPasswordResetSuccessful,
      hasPasswordResetFailed,
      onBlurWithParams,
      sendPasswordResetEmail,
      reset,
      authUserRole,
      roleName,
      isPrimarySchool,
      editableInputs,
      formInputChanges,
      userId
    } = this.props;

    // apply localisation to the user roles
    const localisedUserRoles = userRoleFriendlyNamesObject;
    if (isEltAssessmentMode()) {
      Object.entries(userRoles).forEach(([key]) => {
        localisedUserRoles[key] = userRoleFriendlyNames(key);
      });
    }

    const roles = pick(
      localisedUserRoles,
      getAssignableRoles({ authUserRole, editUserRole: roleName, isPrimarySchool })
    );
    const hiddenFields = difference(Object.values(OrgUserInputs), editableInputs);
    const socialFields = ['social', 'social-unlink-confirm'];
    hiddenFields.push(...socialFields);
    const isLastTeacherInClass =
      classrooms.some(({ teacherIdList = [] }) => teacherIdList.length === 1 && teacherIdList[0] === userId) &&
      !!roleName &&
      roleName === userRoles.TEACHER &&
      !!formInputChanges.roleName &&
      formInputChanges.roleName === userRoles.LEARNER;

    switch (true) {
      case hasFailed:
        return <UserEditFailure onDone={this._closePanel} />;
      case isLoading:
        return <UserEditProcessing isLoading />;
      case isSubmitting:
        return <UserEditProcessing isWaiting />;
      case isSuccessful:
        return (
          <UserEditSuccess
            {...pick(this.props, ['firstName', 'lastName', 'userName'])}
            onBack={reset}
            onDone={this._closePanel}
            subtitle={isLastTeacherInClass ? content.no_teacher_subtitle : null}
          />
        );
      case isPasswordResetSubmitting:
        return <PasswordResetProcessing />;
      case isPasswordResetSuccessful:
        return (
          <PasswordResetSuccess
            {...pick(this.props, ['firstName', 'lastName'])}
            onBack={reset}
            onDone={this._closePanel}
          />
        );
      case userProfileState === userProfileStates.FETCHING && isSendReminderSubmitting:
        return <SendInvitationReminderProcessing onDone={this._handleClosePanel} />;
      case userProfileState === userProfileStates.PROFILE_COMPLETE && isSendReminderSubmitting:
        return (
          <SendInvitationReminderSuccess {...pick(this.props, ['firstName', 'lastName'])} onDone={this._closePanel} />
        );
      case cancelInvitationState === cancelInvitationStates.ERROR:
        return <SendInvitationReminderFailure onDone={this._handleClosePanel} />;
      case hasPasswordResetFailed:
      default:
        return (
          <OrgUserEditForm
            {...pick(this.props, [
              'firstName',
              'displayName',
              'lastName',
              'userName',
              'email',
              'roleName',
              'formInputChanges',
              'formInputErrors',
              'isCheckingUserNameTaken',
              'isUserNameTaken',
              'isCheckingEmailTaken',
              'isEmailTaken',
              'onChange',
              'archive',
              'edit',
              'canRemoveUser'
            ])}
            roles={roles}
            hidden={hiddenFields}
            onBlur={onBlurWithParams(orgId)}
            sendPasswordResetEmail={() => sendPasswordResetEmail(email)}
            edit={this._edit}
            isAdminOrSupport={[userRoles.OUP_ADMIN, userRoles.OUP_SUPPORT].includes(authUserRole)}
            isLastTeacherInClass={isLastTeacherInClass}
          />
        );
    }
  };

  render() {
    const {
      localizedContent: { orgUserEditPanel: content },
      formInputChanges,
      isOpen
    } = this.props;

    return (
      <SidePanel
        id="OrgUserEdit"
        ariaLabel={content.title}
        confirmBeforeClose={!!Object.keys(formInputChanges).length}
        isOpen={isOpen}
        onClose={this._closePanel}
      >
        {this._renderContent()}
      </SidePanel>
    );
  }
}

OrgUserEditPanel.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  authUserId: PropTypes.string.isRequired,
  authUserRole: PropTypes.string.isRequired,
  classrooms: PropTypes.arrayOf(
    PropTypes.shape({
      teacherIdList: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ).isRequired,
  orgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  roleName: PropTypes.string.isRequired,
  formInputChanges: PropTypes.object.isRequired,
  formInputErrors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isCheckingUserNameTaken: PropTypes.bool.isRequired,
  isUserNameTaken: PropTypes.bool.isRequired,
  isCheckingEmailTaken: PropTypes.bool.isRequired,
  isEmailTaken: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isSuccessful: PropTypes.bool.isRequired,
  hasFailed: PropTypes.bool.isRequired,
  isSendReminderSubmitting: PropTypes.bool,
  cancelInvitationState: PropTypes.string.isRequired,
  userProfileState: PropTypes.string,
  isPasswordResetSubmitting: PropTypes.bool.isRequired,
  isPasswordResetSuccessful: PropTypes.bool.isRequired,
  hasPasswordResetFailed: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlurWithParams: PropTypes.func.isRequired,
  onClearFormChanges: PropTypes.func.isRequired,
  getClassrooms: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  sendPasswordResetEmail: PropTypes.func.isRequired,
  archive: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  canRemoveUser: PropTypes.bool.isRequired,
  isPrimarySchool: PropTypes.bool.isRequired,
  editableInputs: PropTypes.array.isRequired,
  displayName: PropTypes.string
};

export default compose(
  withLocalizedContent('orgUserEditPanel'),
  connect(
    (state, { userId, orgId }) => ({
      ...pick(state.editUser, [
        'firstName',
        'lastName',
        'email',
        'userName',
        'roleName',
        'isLoading',
        'isCheckingUserNameTaken',
        'isUserNameTaken',
        'isCheckingEmailTaken',
        'isEmailTaken',
        'isSubmitting',
        'isSuccessful',
        'hasFailed'
      ]),
      displayName: state.userProfile.firstName || state.userProfile.username,
      authUserId: state.identity.userId,
      authUserRole: state.identity.role,
      isSendReminderSubmitting: state.userProfile.isSendingReminder,
      cancelInvitationState: state.userProfile.cancelInvitationState,
      userProfileState: state.userProfile.componentState,
      isPasswordResetSubmitting: state.resetPassword.isSubmitting,
      isPasswordResetSuccessful: state.resetPassword.isSuccessful,
      hasPasswordResetFailed: state.resetPassword.hasFailed,
      formInputErrors: state.editUser.errors,
      isPrimarySchool: state.organisations.data[orgId]
        ? state.organisations.data[orgId].role === orgRoles.PRIMARY_SCHOOL
        : false,
      classrooms: (state.classrooms && Object.values(state.classrooms.data || {})) || [],
      editableInputs: createGetEditableUserInputs(state)(userId)
    }),
    dispatch => ({
      getClassrooms: orgId => {
        dispatch(initialiseInstance('orgClasses', false, { active: true, orgId }));
      },
      getUserDetails: (orgId, userId) => {
        dispatch(actions.editUserGetDetailsRequest(orgId, userId));
      },
      validate: (orgId, input) => {
        dispatch(actions.editUserValidateInput(orgId, input));
      },
      sendPasswordResetEmail: email => {
        dispatch(actions.resetPasswordRequest(email));
      },
      edit: (orgId, userId, input) => {
        dispatch(actions.editUserInOrgRequest(orgId, userId, input));
      },
      reset: () => {
        dispatch(actions.editUserReset());
        dispatch(actions.resetPasswordReset());
      }
    })
  ),
  withFormHandlers()
)(OrgUserEditPanel);
