import PropTypes from 'prop-types';
import React from 'react';

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos);
}

export default class OrbTeacherContentMetadata extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
  };

  static renderItem(item, i) {
    return <p className="orb-lozenge orb-lozenge-level" key={i}>{item.title}</p>;
  }

  render() {
    const { content } = this.props;

    let items = [];

    if (content.level) items.push(content.level);
    if (content.dragonfly_level) items.push(content.dragonfly_level);
    if (content.recovery_level) items.push(content.recovery_level);
    if (content.readerstage) items.push(content.readerstage);
    if (content.bookband) items.push(content.bookband);
    if (content.interestage) items.push(content.interestage);
    items = items.concat(content.genres || []);
    items = items.concat(content.series || []);
    items = items.concat((content.keywords || []).map(k => ({ title: k, key: k })));
    items = items.concat((content.country || []).map(k => ({ title: k, key: k })));
    items = items.concat(content.curriculum || []);

    return <div>{removeDuplicates(items, 'title').map(OrbTeacherContentMetadata.renderItem)}</div>;
  }
}
