import React from 'react';
import PropTypes from 'prop-types';
import DetailReportGrid from '../DetailReportGrid/DetailReportGrid';
import withLocalizedContent from '../../language/withLocalizedContent';

function AttainmentDetails({
  localizedContent: { attainmentDetailsComponent: content },
  forceRowDisplay = false,
  levelGroup,
  currentLevel,
  expectedLevel
}) {
  return (
    <DetailReportGrid
      forceRowDisplay={forceRowDisplay}
      gridData={[
        {
          header: `${content.current} ${levelGroup}`,
          content: currentLevel,
          description: `(${expectedLevel})`
        }
      ]}
    />
  );
}

AttainmentDetails.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  forceRowDisplay: PropTypes.bool,
  levelGroup: PropTypes.string.isRequired,
  currentLevel: PropTypes.string.isRequired,
  expectedLevel: PropTypes.string.isRequired
};

export default withLocalizedContent('attainmentDetailsComponent')(AttainmentDetails);
