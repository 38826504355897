import { select } from 'redux-saga/effects';
import generateReportApi from './generateReportApi';

export default function* productPublishRequest() {
  console.log(`[generateReport Saga] Submitting request to get report.`);

  const { reportType } = yield select(state => ({
    reportType: state.generateReport.reportType,
    userEmail: state.generateReport.userEmail
  }));

  return yield generateReportApi(reportType);
}
