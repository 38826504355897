import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './ProgressStepper.scss';

function ProgressStepper({ totalSteps, currentStep }) {
  const renderSteps = () => {
    const steps = [];

    for (let i = 1; i <= totalSteps; i += 1) {
      const isCurrentStep = currentStep === i;
      const step = (
        <div
          className={classnames(styles.step, { [styles.previousStep]: currentStep > i })}
          key={i}
          data-testid={`step-${i}`}
        >
          <div className={classnames(styles.animation, { [styles.grow]: isCurrentStep })} />
        </div>
      );
      steps.push(step);
    }
    return steps;
  };

  return <div className={styles.progressContainer}> {renderSteps()} </div>;
}

ProgressStepper.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired
};

export default ProgressStepper;
