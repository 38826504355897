import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import style from './InfoNotice.scss';

function InfoNotice({ leadNotice, notice, ctaText, ctaLink, centerText, glyphName = 'INFO' }) {
  return (
    <div>
      <div className={classnames('gin-top3 gin-bot2', style.container, style.containerPointer)}>
        <div className={style.center}>
          <span className={style.iconContainer}>
            <SVGIcon glyph={glyphName} />
          </span>
        </div>
        {leadNotice ? (
          <p className={classnames('lead gin-top1', centerText ? style.center : null)}>{leadNotice}</p>
        ) : null}
        <p className={classnames('gin-top2', centerText ? style.center : null)}>{notice}</p>
      </div>
      {ctaLink && ctaText ? (
        <div>
          <hr className={style.hr} />
          <div className={style.ctaContainer}>
            <LinkWithIcon id="viewLicensesToBeAssigned" text={ctaText} glyph={GLYPHS.ICON_RIGHT} to={ctaLink} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

InfoNotice.propTypes = {
  leadNotice: PropTypes.string,
  notice: PropTypes.string.isRequired,
  centerText: PropTypes.bool,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  glyphName: PropTypes.string
};

export default InfoNotice;
