import * as t from '../actionTypes';

const initialState = {
  loading: false,
  success: false,
  error: false,
  errorMessage: '',
  successMessage: ''
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.POST_UPLOAD_CONTENT_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        errorMessage: ''
      };
    case t.POST_UPLOAD_CONTENT_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: payload,
        success: true,
        error: false,
        errorMessage: ''
      };
    case t.POST_UPLOAD_CONTENT_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        successMessage: '',
        success: false,
        error: true,
        errorMessage: payload
      };
    default:
      return {
        ...state
      };
  }
};
