import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as OUP_SVG_SET from '@oup/shared-front-end/src/svg/oup';
import * as STREAMLINE_SVG_SET from '@oup/shared-front-end/src/svg/streamline';
import styles from './SVGIcon.scss';
import withTheme from '../../theme/withTheme';

const SVG_SETS = {
  oup: OUP_SVG_SET,
  streamline: STREAMLINE_SVG_SET
};

// Identify icons that are merely css variations of the same glyph:
const ROTATED = {
  ARROW_UP: 'ICON_ARROW_RIGHT',
  ARROW_LEFT: 'ICON_ARROW_RIGHT',
  ARROW_DOWN: 'ICON_ARROW_RIGHT',
  CHEVRON_UP: 'ICON_RIGHT',
  CHEVRON_DOWN: 'ICON_RIGHT',
  CHEVRON_UP_THICK: 'ICON_RIGHT_THICK',
  CHEVRON_DOWN_THICK: 'ICON_RIGHT_THICK',
  VALIDATION_WARNING_BLUE: 'ICON_WARNING_CIRCLE'
};

// Build a simple map of glyph names for other components to refer to:
// This map gets populated further down this file.
export const GLYPHS = {};

// Glyphs from Streamline, only used by NGS (which is paused)
export const GLYPHS_NGS = {};

// Import icons here under the same name as their file
// This data is only exported for use in test specs.
export const GLYPHS_DATA = { ...SVG_SETS.oup };
export const GLYPHS_NGS_DATA = { ...SVG_SETS.oup, ...SVG_SETS.streamline };

Object.keys(GLYPHS_DATA).forEach(key => {
  GLYPHS[key] = key;
});

Object.keys(GLYPHS_NGS_DATA).forEach(key => {
  GLYPHS_NGS[key] = key;
});

Object.keys(ROTATED).forEach(key => {
  GLYPHS[key] = key;
});

// Array of GLYPH values for PropTypes validation
export const validGlyphs = Object.keys(GLYPHS);

// The component renders an SVG tag with a reference to a symbol from the webpack-generated sprite.
// Note the use of focusable and tabIndex to prevent svg from receiving focus in both old IE and new browsers respectively.
function SVGIcon({
  glyph,
  glyphViewBox,
  rotate,
  className = '',
  fill = null,
  rounded = false,
  themeOptions = {},
  tabIndex = -1,
  title = '',
  width,
  height,
  style = {}
}) {
  const iconSet = themeOptions.iconSet || 'oup';
  const glyphName = ROTATED[glyph] || glyph;

  const setData = SVG_SETS[iconSet] || SVG_SETS.oup;
  const SvgComponent = setData[glyphName] || SVG_SETS.oup[glyphName];

  return (
    <SvgComponent
      {...(glyphViewBox ? { viewBox: glyphViewBox } : {})}
      {...(rotate ? { transform: `rotate(${rotate})` } : {})}
      tabIndex={tabIndex}
      focusable="false"
      aria-hidden="true"
      aria-label={title}
      className={classnames(styles.svgIcon, className, styles[iconSet], {
        [styles[`icon-${glyph}`]]: glyph,
        [styles.rounded]: rounded
      })}
      style={{ ...style, fill }}
      width={width}
      height={height}
    />
  );
}

// You must provide a glyph name which is defined in the object above.
SVGIcon.propTypes = {
  glyph: PropTypes.oneOf(validGlyphs).isRequired,
  glyphViewBox: PropTypes.string,
  rotate: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  rounded: PropTypes.bool,
  themeOptions: PropTypes.object,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object
};

export default withTheme(SVGIcon);
