import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import colors from '../../globals/colors.js';
import Card from '../../components/Card/Card.js';
import RegisterFormInput from './RegisterFormInput.js';
import RegisterFormFederatedAccess from './RegisterFormFederatedAccess.js';
import PageHeading from '../../components/PageHeading/PageHeading.js';
import { formStates } from '../../redux/reducers/registration/registration.reducer.js';
import { featureIsEnabled, socialIdpIsEnabled } from '../../globals/envSettings';
import { getCurrentPlatform, isOteMode } from '../../utils/platform';
import cmsContent from '../../utils/cmsContent.js';
import RegisterStep from './RegisterStep.js';

// This is mostly a wrapper around <RegisterFormInput> so it can be positioned next to other content.
// IMPORTANT: This renders forms for both FULL and PARTIAL registration.

function PartialRegisterFormInputPage({ formState, firstNameValue, platform, returnUrl }) {
  const CMS = cmsContent.registrationPage || {};

  const getRegisterForm = () => {
    if (
      socialIdpIsEnabled(getCurrentPlatform()) &&
      (returnUrl === undefined || (returnUrl && !returnUrl.includes('oxfordreadingbuddy.com')))
    ) {
      return <RegisterFormFederatedAccess platform={platform} formState={formState} returnUrl={returnUrl} />;
    }

    return <RegisterFormInput platform={platform} formState={formState} returnUrl={returnUrl} />;
  };

  const isExtRegFeature = featureIsEnabled('ote-extend-registration');
  const isOTEPlatform = isOteMode();
  const shouldApplyOTERegFormChanges = isExtRegFeature && isOTEPlatform;

  return (
    <div className="row">
      <div className="col pad-bot2">
        {/* Customise HEADING for each type of partial-registration: */}

        {formState === formStates.INPUTTING_PARTIAL_VIA_TOKEN && !shouldApplyOTERegFormChanges && (
          <div>
            <PageHeading
              title={CMS.pageTitle}
              subtitle={(CMS.pageSubtitle || '').replace('{firstName}', firstNameValue)}
            />
            <p>{CMS.create_id_text}</p>
          </div>
        )}

        {formState === formStates.INPUTTING_PARTIAL_VIA_TOKEN && shouldApplyOTERegFormChanges && (
          <div>
            <RegisterStep
              title={CMS.register_step1_title_text}
              subtitle={CMS.register_step1_subtitle_text}
              pageSubtitle={CMS.pageSubtitle}
              isRegistrationForm
            />
          </div>
        )}

        {formState === formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY && (
          <div>
            <PageHeading
              title={CMS.pageTitle}
              subtitle={(CMS.pageSubtitle_update_account || '').replace('{firstName}', firstNameValue)}
            />
            <p>{CMS.confirm_email_address_text}</p>
          </div>
        )}

        {formState === formStates.INPUTTING_PARTIAL_VIA_FINALISE_EXISTING && (
          <div>
            <PageHeading
              title={CMS.pageTitle}
              subtitle={(CMS.pageSubtitle_update_account || '').replace('{firstName}', firstNameValue)}
            />
            <p>{CMS.accept_conditions_text}</p>
          </div>
        )}
      </div>

      {/* Customise FORM for each type of partial-registration: */}

      <div className="col">
        <Card headingText={CMS.registration_title} headingColor={colors.PROFILE}>
          {getRegisterForm()}
        </Card>
      </div>
    </div>
  );
}

PartialRegisterFormInputPage.propTypes = {
  formState: PropTypes.oneOf(Object.values(formStates)).isRequired,
  firstNameValue: PropTypes.string.isRequired,
  platform: PropTypes.string,
  returnUrl: PropTypes.string
};

export default connect(
  state => ({
    // Connect values
    firstNameValue: state.registration.firstNameValue
  }),
  {}
)(PartialRegisterFormInputPage);
