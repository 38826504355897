import React from 'react';
import PropTypes from 'prop-types';
import styles from '../css/AddLicenseContentPage.scss';
import Calendar from '../../../../../components/Calendar/Calendar.js';
import useCalendarDisplay from './hooks/useCalendarDisplay';
import withLocalizedContent from '../../../../../language/withLocalizedContent';

function BatchDetails({ batchDetails, setBatchDetails, localizedContent: { activationCodeBatch: content } }) {
  const { batchName, numberOfTokens, batchAllowedUsage, tokenStartDate, tokenEndDate, format } = batchDetails;
  const setDetails = setBatchDetails();
  const {
    toggleCalendarDisplay,
    changeAllToFalse,
    convertDate,
    tokenValidityStartModal,
    tokenValidityEndModal
  } = useCalendarDisplay();
  const minValue = 1;
  const maxValue = 200000;
  return (
    <div className="container">
      <article className={styles.licenseDetails}>
        <div className="inputs">
          <span className={styles.licenseTitle}>{content.batch}</span>
          <label htmlFor="batchName" className={styles.inputContainer}>
            <span className={styles.names}>{content.batchName}</span>
            <input
              type="text"
              id="batchName"
              value={batchName}
              onChange={e => {
                setDetails.setBatchNameFunction(e.target.value);
              }}
            />
          </label>
          <label htmlFor="format" className={styles.inputContainer}>
            <span className={styles.names}>{content.format}</span>
            <select id="format" type="checbox" defaultValue={format}>
              <option value="rolling ">{content.eacNumeric}</option>
            </select>
          </label>
          <label htmlFor="numOftokens" className={styles.inputContainer}>
            <span className={styles.names}>
              {' '}
              {content.numberOfTokens} <span className={styles.redStar}>*</span>
            </span>
            <input
              type="number"
              id="numOftokens"
              value={numberOfTokens}
              min={minValue}
              max={maxValue}
              onChange={e => {
                const numberInput = Number(e.target.value);
                if (!Number.isNaN(numberInput) && numberInput >= minValue && numberInput <= maxValue) {
                  setDetails.setNumberOfTokensFunction(numberInput);
                } else {
                  setDetails.setNumberOfTokensFunction(minValue);
                }
              }}
            />
          </label>
          <label htmlFor="batchAllowedUsage" className={styles.inputContainer}>
            <span className={styles.names}>
              {' '}
              {content.allowedUsages} <span className={styles.redStar}>*</span>
            </span>
            <input
              type="number"
              id="batchAllowedUsage"
              value={batchAllowedUsage}
              min={minValue}
              max={maxValue}
              step={1}
              onChange={e => {
                const numberInput = Number(e.target.value);
                if (!Number.isNaN(numberInput) && numberInput >= minValue && numberInput <= maxValue) {
                  setDetails.setBatchAllowedUsageFunction(numberInput);
                } else {
                  setDetails.setBatchAllowedUsageFunction(minValue);
                }
              }}
            />
          </label>
          <label htmlFor="tokenValidityStartDate" className={styles.inputContainer}>
            <span className={styles.names}>{content.tokenStartDate}</span>
            <input
              readOnly
              placeholder={content.tokenStartDatePlaceHolder}
              type="text"
              id="tokenValidityStartDate"
              value={convertDate(tokenStartDate)}
              onClick={() => {
                changeAllToFalse();
                toggleCalendarDisplay('tokenValidityStartModal', true);
              }}
            />
          </label>
          <div className={styles.calendarContainer}>
            {tokenValidityStartModal && (
              <Calendar onDateSelectedHandler={setDetails.setTokenStartDateFunction} selectedDate={new Date()} />
            )}
          </div>

          <label htmlFor="tokenValidityEndDate" className={styles.inputContainer}>
            <span className={styles.names}>{content.tokenEndDate}</span>
            <input
              placeholder={content.tokenEndDatePlaceHolder}
              readOnly
              type="text"
              id="tokenValidityEndDate"
              value={convertDate(tokenEndDate)}
              onClick={() => {
                changeAllToFalse();
                toggleCalendarDisplay('tokenValidityEndModal', true);
              }}
            />
          </label>
          <div className={styles.calendarContainer}>
            {tokenValidityEndModal && (
              <Calendar onDateSelectedHandler={setDetails.setTokenEndDateFunction} selectedDate={new Date()} />
            )}
          </div>
        </div>
      </article>
    </div>
  );
}
BatchDetails.propTypes = {
  batchDetails: PropTypes.object,
  setBatchDetails: PropTypes.func,
  localizedContent: PropTypes.object
};

export default withLocalizedContent('activationCodeBatch')(BatchDetails);
