import { createSelector } from 'reselect';
import userRoles from '../../globals/userRoles';

export const OrgUserInputs = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  USERNAME: 'userName',
  EMAIL: 'email',
  ROLE: 'roleName'
};

/**
 * Creates a function which returns the editable fields on a self-managed user
 *
 * @param {Object} state
 *
 * @return {Function}
 */
export default createSelector(
  state => state.identity.role,
  state => state.editUser.roleName,
  state => state.identity.userId,
  (authUserRole, targetUserRole, authUserId) => targetUserId => {
    const isTargetUserAuthUser = targetUserId === authUserId;
    switch (true) {
      case authUserRole === userRoles.OUP_ADMIN:
      case authUserRole === userRoles.OUP_SUPPORT && !targetUserRole !== userRoles.OUP_ADMIN:
      case authUserRole === userRoles.ORG_ADMIN && isTargetUserAuthUser:
        return [
          OrgUserInputs.FIRST_NAME,
          OrgUserInputs.LAST_NAME,
          OrgUserInputs.USERNAME,
          OrgUserInputs.EMAIL,
          OrgUserInputs.ROLE
        ];
      case isTargetUserAuthUser:
        return [OrgUserInputs.FIRST_NAME, OrgUserInputs.LAST_NAME];
      case authUserRole === userRoles.ORG_ADMIN:
        return [OrgUserInputs.ROLE];
      default:
        return [];
    }
  }
);
