import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { ErrorStatus, PageWrapper } from '../../../components';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ProductPublish from './ProductPublish';
import CsvProductStructureUploadSuccess from '../../../components/CsvProductStructureUpload/CsvProductStructureUploadSuccess';
import { reset } from '../../../redux/reducers/productPublish.reducer';

class ProductPublishPage extends Component {
  componentWillUnmount() {
    const { redirect } = this.props;
    redirect('/products');
  }

  render() {
    const {
      inProgress,
      loading,
      errorOccurred,
      success,
      localizedContent: { productPublishPage: content },
      redirect
    } = this.props;

    return (
      <PageWrapper>
        <Helmet title={content.title} />
        {inProgress ? <ProductPublish content={content} /> : null}
        {loading ? <PopoutPanelIconHeading type={types.LOADING} title={content.waitingMessage} /> : null}
        {errorOccurred ? (
          <ErrorStatus
            title={content.errorMessage}
            buttonText={content.backButton}
            buttonOnClickHandler={() => redirect('/products')}
          />
        ) : null}
        {success ? (
          <CsvProductStructureUploadSuccess content={content} goBackToDashboard={() => redirect('/products')} />
        ) : null}
      </PageWrapper>
    );
  }
}

ProductPublishPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errorOccurred: PropTypes.bool,
  inProgress: PropTypes.bool,
  success: PropTypes.bool
};

export default compose(
  withRouter,
  withLocalizedContent('productPublishPage'),
  connect(
    state => ({
      inProgress: state.productPublish.inProgress,
      loading: state.productPublish.loading,
      errorOccurred: state.productPublish.errorOccurred,
      errorMessage: state.productPublish.errorMessage,
      success: state.productPublish.success
    }),
    (dispatch, props) => ({
      redirect: path => {
        dispatch(reset());
        props.history.push(path);
      }
    })
  )
)(ProductPublishPage);
