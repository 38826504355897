import getProductLicencesApi from '../../../../../apiCalls/library/getProductLicences.api.js';

export default function* prepareRequest(orgId, productId, userIds) {
  console.log(`[assignLearningMaterial] User selected product, collecting licence information...`);

  /**
   *  SEND API REQUEST
   */
  return yield getProductLicencesApi(orgId, {
    productId,
    userIds
  });
}
