import React from 'react';
import PropTypes from 'prop-types';

function ArticleImages({ title: heading, images }) {
  const image = images[0];

  return image ? (
    <div>
      <h2>{heading}</h2>

      {images.length === 1 ? (
        <img src={image.img_src} alt={image.img_alt} aria-hidden={!image.img_alt} />
      ) : (
        <ul>
          {images.map((img, i) => (
            <li key={i}>
              <img src={img.img_src} alt={img.img_alt} aria-hidden={!img.img_alt} />
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : null;
}

ArticleImages.propTypes = {
  title: PropTypes.string.isRequired, // Heading
  images: PropTypes.arrayOf(
    PropTypes.shape({
      img_src: PropTypes.string.isRequired,
      img_alt: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ArticleImages;
