import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setFormState } from '../../../redux/reducers/data/forms.reducer.js';

class FormController extends Component {
  componentWillMount() {
    const { initialise, formName, initialFormState } = this.props;
    initialise(formName, initialFormState);
  }

  render() {
    const { formStateMapping, formState } = this.props;
    return formStateMapping[formState] || null;
  }
}

FormController.propTypes = {
  // Props sourced values
  formName: PropTypes.string.isRequired,
  initialFormState: PropTypes.string.isRequired,
  formStateMapping: PropTypes.object.isRequired,

  // State sourced values
  formState: PropTypes.string,

  // Actions
  initialise: PropTypes.func.isRequired
};

// Extract the state of the current form instance
export default connect(
  (state, { formName }) => ({
    formState: (state.forms[formName] || {}).formState
  }),
  { initialise: setFormState }
)(FormController);
