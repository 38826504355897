import * as t from '../actionTypes';

export const gradebookSchoolDialReportFailureDeprecated = () => ({
  type: t.GRADEBOOK_SCHOOL_DIAL_REPORT_FAILURE_DEPRECATED
});

export const gradebookSchoolDialReportFailure = orgId => ({
  type: t.GRADEBOOK_SCHOOL_DIAL_REPORT_FAILURE,
  payload: { orgId }
});

export const gradebookSchoolDialReportSuccessDeprecated = data => ({
  // can be deleted after mat-report-change-for-reducers FF is enabled
  type: t.GRADEBOOK_SCHOOL_DIAL_REPORT_SUCCESS_DEPRECATED,
  payload: data
});

export const gradebookSchoolDialReportSuccess = (data, orgId) => ({
  type: t.GRADEBOOK_SCHOOL_DIAL_REPORT_SUCCESS,
  payload: { data, orgId }
});

export const gradebookSchoolDialReportRequest = (orgId, locationId) => ({
  type: t.GRADEBOOK_SCHOOL_DIAL_REPORT_REQUEST,
  payload: {
    orgId,
    locationId
  }
});
