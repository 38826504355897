import { toQueryString } from '../../../../utils/url';
import signedFetch from '../util/signedFetch.js';

export default (orgId, locationId, isbns) =>
  signedFetch(
    'orbGetTeacherResources',
    `${__API_BASE__}/org/${orgId}/orb/teacher-resources${toQueryString({
      locationId,
      isbns: encodeURIComponent(isbns)
    })}`
  );
