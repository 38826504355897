const minutesInOneHour = 60;
const hoursInOneDay = 24;

export function getDaysHoursMinutesFromMinutes(totalMinutes) {
  const minutes = totalMinutes % minutesInOneHour;
  const hours = ((totalMinutes - minutes) / minutesInOneHour) % hoursInOneDay;
  const days = Math.floor((totalMinutes - minutes) / minutesInOneHour / hoursInOneDay);

  return {
    minutes,
    hours,
    days
  };
}

export function getMinutesFromDaysHourMinute(days, hours, minutes) {
  const minutesFromDays = days * hoursInOneDay * minutesInOneHour;
  const minutesFromHours = hours * minutesInOneHour;

  return minutesFromDays + minutesFromHours + minutes;
}

export default {
  getDaysHoursMinutesFromMinutes,
  getMinutesFromDaysHourMinute
};
