import jsonFetch from '../util/jsonFetch.js';

export default () => {
  let url = `${__API_BASE__}/open/settings`;
  const params = [];

  const language = localStorage.getItem('language');
  if (language) {
    params.push(`locale=${language}`);
  }

  if (__SHA_VERSION__) {
    params.push(`buildVersion=${__SHA_VERSION__}`);
  }

  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  return jsonFetch('GetAppSettings', [url]);
};
