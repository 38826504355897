import { put } from 'redux-saga/effects';
import { generateDownloadLinksRequest } from '../../../../actions/generateDownloadLinks';
import deleteGuestUserLicencesApi from '../../../apiCalls/product/deleteGuestUserLicencesApi';

function* deleteGuestUserLicences(action) {
  const { userToken, userLicence, platform } = action.payload;
  yield deleteGuestUserLicencesApi({ userToken, userLicence });
  yield put(generateDownloadLinksRequest({ userToken, platform }));
}

export default deleteGuestUserLicences;
