import * as t from '../actionTypes';

export const loadCourse = (orgId, region, courseId) => ({
  type: t.NGS_LOAD_COURSE,
  payload: { orgId, region, courseId }
});

export const unloadCourse = () => ({
  type: t.NGS_UNLOAD_COURSE
});

export const loadCourseSuccess = payload => ({
  type: t.NGS_LOAD_COURSE_SUCCESS,
  payload
});

export const loadCourseFailure = payload => ({
  type: t.NGS_LOAD_COURSE_FAILURE,
  payload
});

// Course books actions
export const courseBooksRequest = (orgId, courseId) => ({
  type: t.NGS_COURSE_BOOKS_REQUEST,
  payload: { orgId, courseId }
});

export const courseBooksRequestSuccess = payload => ({
  type: t.NGS_COURSE_BOOKS_SUCCESS,
  payload
});

export const courseBooksRequestFailure = payload => ({
  type: t.NGS_COURSE_BOOKS_FAILURE,
  payload
});

export const loadCourseBook = (orgId, courseId, bookId) => ({
  type: t.NGS_LOAD_COURSE_BOOK,
  payload: { orgId, courseId, bookId }
});

export const loadCourseBookSuccess = payload => ({
  type: t.NGS_LOAD_COURSE_BOOK_SUCCESS,
  payload
});

export const loadCourseBookFailure = payload => ({
  type: t.NGS_LOAD_COURSE_BOOK_FAILURE,
  payload
});

// Course structure actions
export const courseStructureRequest = (orgId, courseId) => ({
  type: t.NGS_COURSE_STRUCTURE_REQUEST,
  payload: { orgId, courseId }
});

export const courseStructureRequestSuccess = payload => ({
  type: t.NGS_COURSE_STRUCTURE_SUCCESS,
  payload
});

export const courseStructureRequestFailure = payload => ({
  type: t.NGS_COURSE_STRUCTURE_FAILURE,
  payload
});

// Course resources actions
export const courseResourcesRequest = (orgId, courseId, nodeId, format = null, sort = null, loadNextPage = false) => ({
  type: loadNextPage ? t.NGS_NEXT_PAGE_COURSE_RESOURCES_REQUEST : t.NGS_COURSE_RESOURCES_REQUEST,
  payload: { orgId, courseId, nodeId, format, sort }
});

export const courseResourcesRequestSuccess = (payload, nextPage) => ({
  type: nextPage ? t.NGS_NEXT_PAGE_COURSE_RESOURCES_SUCCESS : t.NGS_COURSE_RESOURCES_SUCCESS,
  payload
});

export const courseResourcesRequestFailure = (payload, nextPage) => ({
  type: nextPage ? t.NGS_NEXT_PAGE_COURSE_RESOURCES_SUCCESS : t.NGS_COURSE_RESOURCES_SUCCESS,
  payload
});

// Course reports actions
export const courseReportsRequest = (orgId, courseId) => ({
  type: t.NGS_COURSE_REPORTS_REQUEST,
  payload: { orgId, courseId }
});

export const courseReportsRequestSuccess = payload => ({
  type: t.NGS_COURSE_REPORTS_SUCCESS,
  payload
});

export const courseReportsRequestFailure = payload => ({
  type: t.NGS_COURSE_REPORTS_FAILURE,
  payload
});

// Course marks actions
export const courseMarksRequest = (orgId, courseId) => ({
  type: t.NGS_COURSE_MARKS_REQUEST,
  payload: { orgId, courseId }
});

export const courseMarksRequestSuccess = payload => ({
  type: t.NGS_COURSE_MARKS_SUCCESS,
  payload
});

export const courseMarksRequestFailure = payload => ({
  type: t.NGS_COURSE_MARKS_FAILURE,
  payload
});

// Course homework actions
export const courseHomeworkRequest = (orgId, courseId) => ({
  type: t.NGS_COURSE_HOMEWORK_REQUEST,
  payload: { orgId, courseId }
});

export const courseHomeworkRequestSuccess = payload => ({
  type: t.NGS_COURSE_HOMEWORK_SUCCESS,
  payload
});

export const courseHomeworkRequestFailure = payload => ({
  type: t.NGS_COURSE_HOMEWORK_FAILURE,
  payload
});

// Create  assignment actions
export const openCreateAssignmentForm = (orgId, courseId, resourceIds) => ({
  type: t.NGS_OPEN_CREATE_ASSIGNMENT_FORM,
  payload: { orgId, courseId, resourceIds }
});

export const createAssignmentRequest = payload => ({
  type: t.NGS_CREATE_ASSIGNMENT_REQUEST,
  payload
});

export const createAssignmentRequestSuccess = payload => ({
  type: t.NGS_CREATE_ASSIGNMENT_SUCCESS,
  payload
});

export const createAssignmentRequestFailure = payload => ({
  type: t.NGS_CREATE_ASSIGNMENT_FAILURE,
  payload
});

export const updateAssignmentRequest = payload => ({
  type: t.NGS_UPDATE_ASSIGNMENT_REQUEST,
  payload
});

export const updateAssignmentRequestSuccess = payload => ({
  type: t.NGS_UPDATE_ASSIGNMENT_SUCCESS,
  payload
});

export const updateAssignmentRequestFailure = payload => ({
  type: t.NGS_UPDATE_ASSIGNMENT_FAILURE,
  payload
});

export const removeAssignmentRequest = payload => ({
  type: t.NGS_REMOVE_ASSIGNMENT_REQUEST,
  payload
});

export const removeAssignmentRequestSuccess = payload => ({
  type: t.NGS_REMOVE_ASSIGNMENT_SUCCESS,
  payload
});

export const removeAssignmentRequestFailure = payload => ({
  type: t.NGS_REMOVE_ASSIGNMENT_FAILURE,
  payload
});

export const getBasketAssignmentsRequest = payload => ({
  type: t.NGS_GET_BASKET_ASSIGNMENTS_REQUEST,
  payload
});

export const getBasketAssignmentsSuccess = payload => ({
  type: t.NGS_GET_BASKET_ASSIGNMENTS_SUCCESS,
  payload
});

export const getBasketAssignmentsFailure = payload => ({
  type: t.NGS_GET_BASKET_ASSIGNMENTS_FAILURE,
  payload
});

export const getClassesAndStudentsRequeust = payload => ({
  type: t.NGS_GET_CLASSES_AND_STUDENTS_REQUEST,
  payload
});

export const getClassesAndStudentsSuccess = payload => ({
  type: t.NGS_GET_CLASSES_AND_STUDENTS_SUCCESS,
  payload
});

export const getClassesAndStudentsFailure = payload => ({
  type: t.NGS_GET_CLASSES_AND_STUDENTS_FAILURE,
  payload
});

export const getBasketResourcesRequest = (
  orgId,
  courseId,
  nodeId,
  format = null,
  sort = null,
  loadNextPage = false
) => ({
  type: loadNextPage ? t.NGS_NEXT_PAGE_BASKET_RESOURCES_REQUEST : t.NGS_BASKET_RESOURCES_REQUEST,
  payload: { orgId, courseId, nodeId, format, sort, nextPage: loadNextPage }
});

export const getBasketResourcesSuccess = (payload, nextPage) => ({
  type: nextPage ? t.NGS_NEXT_PAGE_BASKET_RESOURCES_SUCCESS : t.NGS_BASKET_RESOURCES_SUCCESS,
  payload
});

export const getBasketResourcesFailure = (payload, nextPage) => ({
  type: nextPage ? t.NGS_NEXT_PAGE_BASKET_RESOURCES_FAILURE : t.NGS_BASKET_RESOURCES_FAILURE,
  payload
});
