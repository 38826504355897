import React from 'react';
import PropTypes from 'prop-types';
import Button from '@oup/shared-front-end/src/components/Button';
import styles from './ActionList.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

/**
 * A vertically stacked set of actions.
 */
function ActionList({ title = null, children }) {
  return (
    <div className={styles.container}>
      {typeof title === 'string' ? <h4 className={styles.title}>{`${title}:`}</h4> : title}
      {children}
    </div>
  );
}

ActionList.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default ActionList;

/**
 * A full-width button to be stacked in an `ActionList`.
 */
export function Action({
  label,
  back = false,
  disabled = false,
  primary = false,
  onClick,
  className = '',
  dataTestId = ''
}) {
  return (
    <Button
      className={className}
      variant={primary ? 'filled' : 'outline'}
      text={label}
      onClick={onClick}
      disabled={disabled}
      ariaAttributes={{ label }}
      dataAttributes={{ testid: dataTestId }}
      {...(back ? { icon: { component: <SVGIcon glyph={GLYPHS.ICON_LEFT} /> } } : {})}
    />
  );
}

Action.propTypes = {
  label: PropTypes.string.isRequired,
  back: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  redirectUrl: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string
};
