import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { connect } from 'react-redux';
// Redux
import { searchUsers } from '../../../redux/actions/searchUsers';
// Components
import PageHeading from '../../../components/PageHeading/PageHeading';
import SearchInput from '../../../components/SearchInput/SearchInput';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import UserDataTable from './UserDataTable';
// Utils
import withLocalizedContent from '../../../language/withLocalizedContent';
// Styles
import styles from './UserImpersonationPage.scss';

function UserImpersonation({
  searchUsersAction,
  isLoading,
  usersData,
  localizedContent: { userImpersonation: content }
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleOnChange = term => {
    setSearchTerm(term);
  };

  const handleSearchUsers = () => {
    if (searchTerm.trim()) {
      searchUsersAction(searchTerm);
    }
  };

  return (
    <div className={`${styles.pageContainer} grid pad-top4 pad-bot4`}>
      <Helmet title={content.page_title} />
      <PageHeading title={content.page_title} subtitle={content.page_subtitle} />
      <div className={styles.searchContainer}>
        <SearchInput
          id="searchUserToImpersonateId"
          placeholder={content.search_placeholder}
          value={searchTerm}
          onChange={term => handleOnChange(term)}
          buttonAction={handleSearchUsers}
        />
      </div>
      <div className={styles.resultsContent}>
        {isLoading ? (
          <div className={styles.loaderSvg}>
            <SVGIcon glyph={GLYPHS.ICON_LOADING} />
          </div>
        ) : (
          <div>
            {usersData.length === 0 ? (
              <span className={styles.boldText}>{content.no_matches_found}</span>
            ) : (
              <UserDataTable usersData={usersData} content={content} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

UserImpersonation.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  usersData: PropTypes.array.isRequired,
  searchUsersAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default compose(
  withLocalizedContent('userImpersonation'),
  connect(({ searchUsers: { isLoading = false, usersData = [] } = {} }) => ({ isLoading, usersData }), {
    searchUsersAction: searchUsers
  })
)(UserImpersonation);
