import React from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './ResourceModalHeader.scss';
import footerStyles from './ResourceModalFooter.scss';
// Components
import Button, { buttonTypes } from '../Button/Button';
import { GLYPHS } from '../SVGIcon/SVGIcon';
// Constants
import breakpoints from '../../globals/breakpoints';

function ResourceModalHeader({
  closeModal,
  hubContent,
  downloadResource,
  resource,
  downloadIsLoading,
  breakpoint,
  resourceCanBePreviewed
}) {
  return (
    <div className={styles.resourceModalHeader}>
      {breakpoint !== breakpoints.XXS && (
        <div className={footerStyles.resourceModalDownloadRow}>
          <div className={footerStyles.resourceModalFooterInfo}>
            <p>{resource.title}</p>
          </div>
          {downloadResource ? (
            <div>
              <Button
                id={resource.resourceId}
                text={hubContent.button_download_text}
                glyph={GLYPHS.ICON_DOWNLOAD}
                disabled={downloadIsLoading}
                onClick={() => downloadResource(resource)}
                dataAttribute={resource.title}
              />
            </div>
          ) : null}
        </div>
      )}
      {breakpoint === breakpoints.XXS && !resourceCanBePreviewed ? (
        <div className={styles.resourceTitle}>
          <p>{resource.title}</p>
        </div>
      ) : null}
      <div className={styles.closeButtonContainer}>
        <Button
          text={hubContent.button_close_text || hubContent.close_button_text}
          type={buttonTypes.CLOSE_WHITE}
          onClick={e => {
            e.stopPropagation();
            closeModal();
          }}
        />
      </div>
    </div>
  );
}

ResourceModalHeader.propTypes = {
  closeModal: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  downloadResource: PropTypes.func,
  resource: PropTypes.object,
  downloadIsLoading: PropTypes.bool,
  breakpoint: PropTypes.string,
  resourceCanBePreviewed: PropTypes.bool
};

export default ResourceModalHeader;
