import PropTypes from 'prop-types';
import React from 'react';
import PersonRepresentation from '../PersonRepresentation/PersonRepresentation';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading, { types as iconHeadingTypes } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import { COLOR_FOR_ROLE } from '../../globals/colors';
import { toInitials } from '../../utils/string';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import withGetYearGroupOptions from '../../language/withGetYearGroupOptions';
import { topLevelPlatformUsesLicences, isHubMode } from '../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';

function ClassUserRemoveReview({
  localizedContent: content,
  classroomName = '',
  curriculumType = '',
  users = {},
  notifyByEmail,
  onNotifyByEmailToggle,
  onSubmitClick,
  toggleRender,
  getOptionNameByValue,
  getYearGroupOptions,
  currentOrganisationLti = false
}) {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            {isHubMode() ? (
              <PopoutPanelIllustrationHeading
                title={(content.review_remove_user_confirmation_title || '').replace('{className}', classroomName)}
                subtitle={topLevelPlatformUsesLicences() ? '' : content.review_remove_user_confirmation_subtitle}
                illustrationSrc={HubIllustrationConstants.WARNING}
                illustrationAltText={HubIllustrationAltText.WARNING}
              />
            ) : (
              <PopoutPanelIconHeading
                type={iconHeadingTypes.WARNING}
                title={(content.review_remove_user_confirmation_title || '').replace('{className}', classroomName)}
                subtitle={topLevelPlatformUsesLicences() ? '' : content.review_remove_user_confirmation_subtitle}
              />
            )}
          </div>
        }
        footerContent={
          <PopoutActionFooter
            {...(onNotifyByEmailToggle && toggleRender
              ? {
                  toggleSwitchId: 'notifyWithEmail',
                  toggleSwitchLabel: content.review_remove_user_notify_by_email_text,
                  toggleSwitchValue: notifyByEmail,
                  toggleSwitchOnChange: () => onNotifyByEmailToggle(!notifyByEmail)
                }
              : {})}
            primaryButtonText={(content.review_remove_user_confirm_button || '').replace('{className}', classroomName)}
            primaryButtonAction={onSubmitClick}
          />
        }
      >
        <div>
          {Object.entries(users).map(([userId, { firstname, lastname, email, yearGroup, isLocked, roleName }]) => (
            <div key={userId} className="gin2">
              <PersonRepresentation
                key={userId}
                thumbnailSize={thumbnailSizes.MEDIUM}
                name={`${firstname} ${lastname}`}
                initials={toInitials(firstname, lastname)}
                email={currentOrganisationLti ? email.slice(0, email.indexOf('@')) : email}
                thumbnailColor={COLOR_FOR_ROLE[roleName] || COLOR_FOR_ROLE.USER}
                locked={isLocked}
                yearGroup={curriculumType ? getOptionNameByValue(getYearGroupOptions(curriculumType), yearGroup) : ''}
              />
            </div>
          ))}
        </div>
      </ScrollContainer>
    </form>
  );
}

ClassUserRemoveReview.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  classroomName: PropTypes.string.isRequired,
  curriculumType: PropTypes.string,
  users: PropTypes.object,
  notifyByEmail: PropTypes.bool,
  onSubmitClick: PropTypes.func,
  onNotifyByEmailToggle: PropTypes.func,
  getYearGroupOptions: PropTypes.func.isRequired,
  toggleRender: PropTypes.bool,
  getOptionNameByValue: PropTypes.func.isRequired,
  currentOrganisationLti: PropTypes.bool
};

export default withGetYearGroupOptions(ClassUserRemoveReview);
