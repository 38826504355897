import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Table, { columnTypes } from '../../../../components/Table/Table';
import tableStyles from '../../../../components/Table/Table.scss';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import styles from './ProductSearchResults.scss';
import Link from '../../../../components/Link/Link';
import Button, { buttonTypes } from '../../../../components/Button/Button';

function ProductSearchResults({ localizedContent: { productSearchResults: content }, products }) {
  const columns = [
    {
      heading: content.column_product,
      type: columnTypes.TEXT,
      width: '30%'
    },
    {
      heading: content.column_product_id,
      type: columnTypes.TEXT,
      width: '30%'
    },
    {
      heading: content.column_org_unit,
      type: columnTypes.TEXT,
      aligned: 'center'
    },
    {
      heading: content.column_reg_type,
      type: columnTypes.TEXT
    },
    {
      heading: content.column_activation,
      type: columnTypes.TEXT,
      aligned: 'center'
    },
    {
      heading: content.column_actions,
      type: columnTypes.TEXT,
      aligned: 'center'
    }
  ];

  const rows = products.map(
    ({ productName, productId, organisationalUnit, registrableType, activationDetail }, index) => ({
      id: `row_${index}`,
      cells: [
        <div key={`prod_link_${index}`}>
          {productName && <Link to={`/dashboard_eps/product_dashboard/${productId}`}>{productName}</Link>}
        </div>,
        <div key={`prod_id_${index}`}>{productId}</div>,
        <div key={`org_unit_${index}`}>{organisationalUnit}</div>,
        <div key={`reg_type_${index}`}>{registrableType}</div>,
        <div key={`act_type_${index}`}>{activationDetail.activationType}</div>,
        <div key={`actions_${index}`} className={styles.actions}>
          <Button key={`link_edit_${index}`} type={buttonTypes.PENCIL_REVIEW} />
          <Button key={`link_delete_${index}`} type={buttonTypes.CLOSE} />
        </div>
      ]
    })
  );

  return <Table columns={columns} rows={rows} customClass={tableStyles.leftAligned} />;
}

ProductSearchResults.propTypes = {
  localizedContent: PropTypes.object,
  products: PropTypes.array
};

export default compose(withLocalizedContent('productSearchResults'))(ProductSearchResults);
