import signedFetch from '../util/signedFetch.js';
import { toQueryString } from '../../../../utils/url';

export default params => {
  const parentResourceIds =
    params.ids && params.ids.length
      ? `${toQueryString({
          'parent-resource-ids': encodeURIComponent(params.ids.join(','))
        })}`
      : '';

  const URL = `${__API_BASE__}/user/${params.userId}/adaptedTests${parentResourceIds}`;

  return signedFetch('getAdaptedTests', URL, 'GET');
};
