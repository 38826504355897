import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Link from '../../../components/Link/Link';
import styles from './SupportSearchPage.scss';
import groupBy from '../../../utils/array/groupBy.js';
import TextInputWithButton from '../../../components/TextInputWithButton/TextInputWithButton.js';
import PopoutPanelListHeading from '../../../components/PopoutPanelListHeading/PopoutPanelListHeading.js';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';

import {
  formStates,
  initForm,
  setSearchTerm,
  submitForm
} from '../../../redux/reducers/support/supportSearch.reducer.js';
import { featureIsEnabled } from '../../../globals/envSettings';

import cmsContent from '../../../utils/cmsContent.js';

class SupportSearchPage extends Component {
  componentWillMount() {
    const { initFormAction } = this.props;
    initFormAction();
  }

  render() {
    const CMS = cmsContent.supportPage || {};
    const {
      formState,
      searchInputValue,
      searchResultsValue,
      searchInputOnChangeAction,
      searchInputOnSubmitAction
    } = this.props;

    const groupedResults = (searchResultsValue || []).reduce(groupBy('category'), {});

    return (
      <div className="grid pad-top4">
        <div className="row">
          <div className="col">
            <Helmet title={CMS.pageTitle} />

            {formState === formStates.SUBMITTING ? (
              <PopoutPanelIconHeading
                type={types.LOADING}
                title={CMS.loading_heading}
                subtitle={CMS.loading_subheading}
              />
            ) : (
              <div>
                <form>
                  <TextInputWithButton
                    id="searchCriteria-searchTerm"
                    label={CMS.searchInput_label}
                    labelHidden
                    placeholder={CMS.searchInput_placeholder}
                    value={searchInputValue}
                    onChange={searchInputOnChangeAction}
                    buttonType="submit"
                    buttonAction={e => {
                      e.preventDefault();
                      searchInputOnSubmitAction();
                    }}
                    buttonText={CMS.searchButton_text}
                  />
                </form>

                <div className="pad-top2">
                  {searchResultsValue && searchResultsValue.length ? (
                    <ul id="searchResults-list" className={styles.searchResults}>
                      {/* Results are grouped by category: */}
                      {Object.keys(groupedResults).map(category => (
                        <li key={category} className={styles.searchResultsCategory}>
                          <PopoutPanelListHeading text={category} />

                          {/* Results for this category: */}
                          <ol className={styles.searchResultsCategoryList}>
                            {groupedResults[category].map(article =>
                              (!featureIsEnabled('oald-redeem-code') &&
                                article.pageName === 'how-do-i-redeem-my-code') ||
                              article.pageName === 'mfl' ? null : (
                                <li key={article.pageName} className={styles.searchResultsItem}>
                                  <Link to={`/support/articles/${article.pageName}`}>{article.title}</Link>
                                  {article.summary === article.title ? '' : <p>{article.summary}</p>}
                                </li>
                              )
                            )}
                          </ol>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p id="searchResults-none" className="pad-top2">
                      {CMS.searchResults_notFound_text}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SupportSearchPage.propTypes = {
  formState: PropTypes.oneOf(Object.values(formStates)),
  searchInputValue: PropTypes.string,
  searchResultsValue: PropTypes.array,
  searchInputOnChangeAction: PropTypes.func,
  searchInputOnSubmitAction: PropTypes.func,
  initFormAction: PropTypes.func
};

export default connect(
  state => ({
    formState: state.supportSearch.formState,
    searchResultsValue: state.supportSearch.searchResultsValue
  }),
  {
    initFormAction: initForm,
    searchInputOnChangeAction: setSearchTerm,
    searchInputOnSubmitAction: submitForm
  }
)(SupportSearchPage);
