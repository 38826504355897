import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from '../css/ProductCreationPage.scss';
import withLocalizedContent from '../../../../../language/withLocalizedContent';
import Table, { columnTypes } from '../../../../../components/Table/Table';
import Button, { buttonTypes } from '../../../../../components/Button/Button';
import { platformsRequest } from '../../../../../redux/actions/createProduct.js';
import PopupOverlay from '../../../../../components/PopupOverlay/PopupOverlay';
import AlertModal from './AlertModal.js';

function PlatformsTab({
  productDetails,
  setProductDetails,
  getPlatforms,
  success,
  localizedContent: { productCreate: content }
}) {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [message, setMessage] = useState('');

  const { selectedPlatform, productPlatforms } = productDetails;
  const setDetails = setProductDetails();

  useEffect(() => {
    getPlatforms();
  }, []);

  useEffect(() => {
    // Set default selectedPlatform when we receive the platforms from backend
    if (success.length > 0 && selectedPlatform === '') {
      setDetails.setSelectedPlatformFunction(JSON.stringify(success[0]));
    }
  }, [success]);

  const columns = [
    {
      heading: content.platform_name_th,
      type: columnTypes.TEXT
    },
    {
      heading: content.platform_code_th,
      type: columnTypes.TEXT
    },
    {
      heading: content.actions_th,
      type: columnTypes.TEXT
    }
  ];

  const addPlatform = () => {
    const selectedValue = JSON.parse(selectedPlatform);
    if (!selectedValue.platform_code) {
      return;
    }
    const elementExists = productPlatforms.find(element => element.platform_code === selectedValue.platform_code);

    if (elementExists) {
      setMessage(content.item_already_added);
      setIsAlertOpen(true);
      return;
    }

    const newProductPlatforms = [...productPlatforms, selectedValue];
    setDetails.setProductPlatformsFunction(newProductPlatforms);
  };

  const removePlatform = platformCode => {
    const newProductPlatforms = productPlatforms.filter(item => item.platform_code !== platformCode);
    setDetails.setProductPlatformsFunction(newProductPlatforms);
  };

  const getRows = () => {
    if (!productPlatforms) return [];
    return productPlatforms.map(platformItem => ({
      id: `row_${platformItem.platform_code}`,
      cells: [
        <div key={`platform_name_${platformItem.platform_code}`} className={styles.productName}>
          {platformItem.platform_name}
        </div>,
        <div key={`platform_id_${platformItem.platform_code}`}>{platformItem.platform_code}</div>,
        <div key={`platform_actions_${platformItem}`} className={styles.productActions}>
          <Button onClick={() => removePlatform(platformItem.platform_code)} type={buttonTypes.CLOSE} />
        </div>
      ]
    }));
  };

  const renderAlertModal = () => {
    if (isAlertOpen) {
      return <AlertModal message={message} isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} />;
    }
    return <> </>;
  };

  const renderOverlay = () => {
    if (isOverlayOpen && success) {
      return (
        <PopupOverlay
          id="addPlatformOverlay"
          ariaLabel={` `}
          visible={isOverlayOpen}
          panelSize="fullWidth"
          customClassName={styles.popUp}
          inline
          onTogglePopup={() => {}}
        >
          <div className={styles.popUpInner}>
            <div>
              <h2>{content.add_platform}</h2>
            </div>
            <div>
              <label htmlFor="platformCodesDropdown" className={styles.inputContainer}>
                <span className={styles.names}>{content.platform_code_th}</span>
                <select
                  id="platformCodesDropdown"
                  type="checbox"
                  onChange={e => {
                    setDetails.setSelectedPlatformFunction(e.target.value);
                  }}
                >
                  {success.map(item => (
                    <option key={item.platform_code} value={JSON.stringify(item)}>
                      {item.platform_code}
                    </option>
                  ))}
                </select>
              </label>

              <label htmlFor="platformName" className={styles.inputContainer}>
                <span className={styles.names}>{content.platform_name_th}</span>
                <input type="text" id="platformName" disabled value={JSON.parse(selectedPlatform).platform_name} />
              </label>
            </div>
            <div className={styles.platformButtonsContainer}>
              <Button
                customClassName={styles.addPlatformButton}
                onClick={() => addPlatform()}
                type={buttonTypes.PRIMARY_THIN}
                text={content.add}
              />
              <Button
                customClassName={styles.cancelPlatformButton}
                onClick={() => setIsOverlayOpen(false)}
                type={buttonTypes.PRIMARY_THIN}
                text={content.cancel}
              />
            </div>
          </div>
        </PopupOverlay>
      );
    }
    return <> </>;
  };

  const rows = getRows();
  return (
    <div className="container">
      <article className={styles.licenseDetails}>
        <div className="inputs">
          <Table
            columns={columns}
            rows={rows}
            fixedFirstColumnWidth
            persistantColumns
            customClass={styles.prodGrTable}
          />
          {rows.length === 0 && <div className={styles.noPlatformsMessage}>{content.no_platforms_text}</div>}
          <div className={styles.addPlatformContainer}>
            {success && (
              <Button
                onClick={() => setIsOverlayOpen(true)}
                type={buttonTypes.PRIMARY_THIN}
                text={content.add_platform}
              />
            )}
          </div>
          {renderOverlay()}
          {renderAlertModal(message)}
        </div>
      </article>
    </div>
  );
}
PlatformsTab.propTypes = {
  localizedContent: PropTypes.object,
  getPlatforms: PropTypes.func,
  success: PropTypes.array,
  productDetails: PropTypes.object,
  setProductDetails: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  getPlatforms: () => {
    dispatch(platformsRequest());
  }
});

export default connect(
  ({ getPlatforms: { isLoading, error, success } }) => ({
    isLoading,
    error,
    success
  }),

  mapDispatchToProps
)(withLocalizedContent('productCreate')(PlatformsTab));
