import { JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const getGetUserDetailsUrl = (orgId, userId) => `${__API_BASE__}/org/${orgId}/user/${userId}`;

export default function* getUserDetails(orgId, userId) {
  return yield signedFetch('getUserDetails', getGetUserDetailsUrl(orgId, userId), 'GET', undefined, {
    Accept: JSON_ENCODED,
    'Content-Type': JSON_ENCODED
  });
}
