import updateStore from '../../../utils/store/updateStore.js';
import { SET_IDENTITY_DETAILS } from '../sharedActions.js';

export const STORE_ORGS = 'organisations/STORE_ORGS';
export const UPDATE_ORGANISATION = 'organisations/UPDATE_ORGANISATION';

const initialState = {
  data: {}
};

export default function organisationsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_ORGS:
      return {
        ...state,
        data: updateStore(state.data, action.organisations)
      };

    // TODO: Code that calls this could be refactored to use STORE_ORGS above:
    case UPDATE_ORGANISATION:
      return {
        ...state,
        data: {
          ...state.data,
          [action.orgId]: {
            ...state.data[action.orgId],
            name: action.name,
            type: action.orgType,
            role: action.orgRole,
            primaryEmail: action.primaryEmail,
            webAddress: action.webAddress
          }
        }
      };
    case SET_IDENTITY_DETAILS: {
      const loadedOrganisations = action.organisations.reduce((orgMap, currentOrg) => {
        orgMap[currentOrg.id] = {
          ...state.data[currentOrg.id],
          name: currentOrg.name,
          ...(currentOrg?.isPlacementCentre && { isPlacementCentre: currentOrg.isPlacementCentre }),
          type: currentOrg.type,
          role: currentOrg.role,
          roleName: currentOrg.roleName,
          customId: currentOrg.customId,
          curriculumType: currentOrg.curriculumType || '',
          isBlocked: currentOrg.isBlocked
        };
        return orgMap;
      }, {});

      return {
        ...state,
        data: {
          ...state.data,
          ...loadedOrganisations
        }
      };
    }
    default:
      return state;
  }
}

export const storeOrganisations = organisations => ({
  type: STORE_ORGS,
  organisations
});

export const updateOrganisation = (orgId, name, orgRole, primaryEmail, webAddress) => ({
  type: UPDATE_ORGANISATION,
  orgId,
  name,
  orgRole,
  primaryEmail,
  webAddress
});

export const storeOrgs = orgs => ({
  type: STORE_ORGS,
  orgs
});
