import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { validColors } from '../../globals/colors';
import { bdClass, fgClass } from '../../utils/colorClassNames';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Lozenge.scss';

/** The component renders a white box with a colored top border and heading label. Contains generic content. */
function Lozenge({ id, text, backgroundColor, buttonAction, useArrowIcon }) {
  return (
    <div id={id} className={classnames(styles.lozenge, bdClass(backgroundColor))}>
      <span className={classnames({ [styles.hasButton]: !!buttonAction })}>{text}</span>
      {buttonAction && (
        <button type="submit" id={`${id}-btn`} className={fgClass(backgroundColor)} onClick={buttonAction}>
          <SVGIcon glyph={useArrowIcon ? GLYPHS.ICON_RIGHT_THICK : GLYPHS.ICON_CLOSE_BOLD} />
        </button>
      )}
    </div>
  );
}

Lozenge.propTypes = {
  /** The html id of the Lozenge. */
  id: PropTypes.string,
  /** The text of the Lozenge. */
  text: PropTypes.string.isRequired,
  /** The color to be used for the background of the Lozenge. */
  backgroundColor: PropTypes.oneOf(validColors).isRequired,
  /** The action to be completed on the button onclick. */
  buttonAction: PropTypes.func,
  /** An alternative to the default icon is an arrow. Set this prop to true to use it */
  useArrowIcon: PropTypes.bool
};

export default Lozenge;
