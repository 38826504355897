import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '@oup/shared-front-end/src/components/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './ImportCTA.scss';

function ImportCTA({ bulkInputAction, importText, buttonText }) {
  return (
    <div className={classnames(styles.importCTA)}>
      <p>{importText}</p>

      <Button
        id="importCallToActionButton"
        variant="outline"
        text={buttonText}
        icon={{ component: <SVGIcon glyph={GLYPHS.ICON_IMPORT} /> }}
        onClick={bulkInputAction}
      />
    </div>
  );
}

ImportCTA.propTypes = {
  // Titles
  importText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  // Button props
  bulkInputAction: PropTypes.func
};
export default ImportCTA;
