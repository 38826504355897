import { createSelector } from 'reselect';

/**
 * A selector returning the number of imported users that have at least one error.
 *
 * @param {Object} state
 *
 * @return {Number}
 */
export default createSelector(
  state => state.importUsers.errors,
  errors => Object.values(errors).reduce((sum, user) => (Object.values(user).some(Boolean) ? sum + 1 : sum), 0)
);
