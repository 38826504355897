import * as t from '../actionTypes';

export const gradebookClassReportEnvironmentRequest = orgId => ({
  type: t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_REQUEST,
  payload: { orgId }
});

export const gradebookClassReportEnvironmentRequestV2 = orgId => ({
  type: t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_REQUEST_V2,
  payload: { orgId }
});

export const gradebookClassReportEnvironmentSuccess = payload => ({
  type: t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_SUCCESS,
  payload
});

export const gradebookClassReportEnvironmentSuccessV2 = (data, orgId) => ({
  type: t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_SUCCESS_V2,
  payload: { data, orgId }
});

export const gradebookClassReportEnvironmentFailure = (message, code) => ({
  type: t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE,
  payload: { message, code }
});

export const gradebookClassReportEnvironmentFailureV2 = (message, code, orgId) => ({
  type: t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE_V2,
  payload: { message, code, orgId }
});

export const gradebookClassReportEnvironmentFailureAddOrgDetails = (orgId, orgName, locationId) => ({
  type: t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE_ADD_ORG_DETAILS,
  payload: { orgId, orgName, locationId }
});

export const gradebookGroupClassReportRequest = (orgId, groupId, locationId, academicYear, startDate, endDate) => ({
  type: t.GRADEBOOK_GROUP_CLASS_REPORT_REQUEST,
  payload: {
    orgId,
    groupId,
    locationId,
    academicYear,
    startDate,
    endDate
  }
});

export const gradebookGroupClassReportFailure = (message, code) => ({
  type: t.GRADEBOOK_GROUP_CLASS_REPORT_FAILURE,
  payload: { message, code }
});

export const gradebookGroupClassReportSuccess = results => ({
  type: t.GRADEBOOK_GROUP_CLASS_REPORT_SUCCESS,
  payload: results
});

export const gradebookGroupClassReportRequestV2 = (orgId, groupId, locationId, academicYear, startDate, endDate) => ({
  type: t.GRADEBOOK_GROUP_CLASS_REPORT_REQUEST_V2,
  payload: {
    orgId,
    groupId,
    locationId,
    academicYear,
    startDate,
    endDate
  }
});

export const gradebookGroupClassReportSuccessV2 = (data, orgId) => ({
  type: t.GRADEBOOK_GROUP_CLASS_REPORT_SUCCESS_V2,
  payload: { data, orgId }
});

export const gradebookGroupClassReportFailureV2 = (message, code, orgId) => ({
  type: t.GRADEBOOK_GROUP_CLASS_REPORT_FAILURE_V2,
  payload: { message, code, orgId }
});

/**
 * Makes an api call to receie currently viewed student's level's attempt statistics
 * & previous attempts statistics
 */
export const gradebookLevelPanelRequest = (orgId, classId, learnerId, levelId, locationId) => ({
  type: t.GRADEBOOK_LEVEL_PANEL_REQUEST,
  payload: {
    orgId,
    classId,
    learnerId,
    levelId,
    locationId
  }
});

export const gradebookLevelPanelRequestV2 = (orgId, classId, learnerId, levelId, locationId) => ({
  type: t.GRADEBOOK_LEVEL_PANEL_REQUEST_V2,
  payload: {
    orgId,
    classId,
    learnerId,
    levelId,
    locationId
  }
});

export const gradebookLevelPanelError = () => ({
  type: t.GRADEBOOK_LEVEL_PANEL_ERROR
});

export const gradebookLevelPanelSuccess = panel => ({
  type: t.GRADEBOOK_LEVEL_PANEL_SUCCESS,
  payload: panel
});

export const gradebookLevelPanelErrorV2 = (error, orgId) => ({
  type: t.GRADEBOOK_LEVEL_PANEL_ERROR_V2,
  payload: {
    error,
    orgId
  }
});

export const gradebookLevelPanelSuccessV2 = (panel, orgId) => ({
  type: t.GRADEBOOK_LEVEL_PANEL_SUCCESS_V2,
  payload: { panel, orgId }
});

export const gradebookAssignSelectedProduct = payload => ({
  type: t.GRADEBOOK_ASSIGN_SELECTED_PRODUCT,
  payload
});

export const gradebookClassReportRequest = (level, page, params) => ({
  type: t.GRADEBOOK_CLASS_REPORT_REQUEST,
  payload: {
    level,
    page,
    params
  }
});

export const gradebookClassDialReportFailure = data => ({
  type: t.GRADEBOOK_CLASS_DIAL_REPORT_FAILURE,
  payload: data
});

export const gradebookClassDialReportSuccess = data => ({
  type: t.GRADEBOOK_CLASS_DIAL_REPORT_SUCCESS,
  payload: data
});

export const gradebookClassDialReportRequest = (orgId, classId, locationId, academicYear) => ({
  type: t.GRADEBOOK_CLASS_DIAL_REPORT_REQUEST,
  payload: {
    orgId,
    classId,
    locationId,
    academicYear
  }
});

export const gradebookClassReportFailure = (error, page) => ({
  type: t.GRADEBOOK_CLASS_REPORT_FAILURE,
  payload: {
    error,
    page
  }
});

export const gradebookClassReportLoading = () => ({
  type: t.GRADEBOOK_CLASS_REPORT_LOADING
});

export const gradebookClassReportSuccess = (data, page) => ({
  type: t.GRADEBOOK_CLASS_REPORT_SUCCESS,
  payload: {
    data,
    page
  }
});

/**
 * Create an action to assign a new ORB level value to all of the given users.
 *
 * @param {(string[]|string)} userIds
 * @param {string}            level
 *
 * @return {Object}
 */
export const assignLevel = (userIds, level, orgId) => ({
  type: t.GRADEBOOK_ASSIGN_LEVEL,
  payload: {
    orgId,
    action: 'assign_level',
    userIds: [].concat(userIds),
    level
  }
});

/**
 * Create an action to advance the ORB level for the given users.
 *
 * @param {(string[]|string)} userIds
 *
 * @return {Object}
 */
export const levelUp = (userIds, orgId) => ({
  type: t.GRADEBOOK_LEVEL_UP,
  payload: {
    orgId,
    action: 'level_up',
    userIds: [].concat(userIds)
  }
});

/**
 * Create an action to decrement the ORB level for the given users.
 *
 * @param {(string[]|string)} userIds
 *
 * @return {Object}
 */
export const levelDown = (userIds, orgId) => ({
  type: t.GRADEBOOK_LEVEL_DOWN,
  payload: {
    orgId,
    action: 'level_down',
    userIds: [].concat(userIds)
  }
});

/**
 * Create an action to reset current level progress for the given users.
 *
 * @param {(string[]|string)} userIds
 *
 * @return {Object}
 */
export const resetLevel = (userIds, orgId) => ({
  type: t.GRADEBOOK_RESET_LEVEL,
  payload: {
    orgId,
    action: 'reset_level',
    userIds: [].concat(userIds)
  }
});

/**
 * Create an action to reset the timeout on the next level advancement for the
 * given users.
 *
 * @param {(string[]|string)} userIds
 *
 * @return {Object}
 */
export const delayLevelChange = (userIds, orgId) => ({
  type: t.GRADEBOOK_DELAY_LEVEL_CHANGE,
  payload: {
    action: 'delay_level_change',
    orgId,
    userIds: [].concat(userIds)
  }
});

export const getClassroomsRequest = orgId => ({
  type: t.GET_CLASSROOMS_REQUEST,
  payload: { orgId }
});

export const getClassroomsSuccess = classes => ({
  type: t.GET_CLASSROOMS_SUCCESS,
  payload: classes
});

export const getClassroomsSuccessV2 = (classes, orgId) => ({
  type: t.GET_CLASSROOMS_SUCCESS_V2,
  payload: { classes, orgId }
});

export const getClassroomsFailure = reason => ({
  type: t.GET_CLASSROOMS_FAILURE,
  payload: reason
});

export const getClassroomsFailureV2 = (reason, orgId) => ({
  type: t.GET_CLASSROOMS_FAILURE_V2,
  payload: { reason, orgId }
});

export const setSelectedProductType = productType => ({
  type: t.GRADEBOOK_SELECTED_PRODUCT_TYPE,
  payload: productType
});
