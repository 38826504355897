import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import createPlacementTestSession from './createPlacementTestSession';
import editPlacementPlacementTestSession from './editPlacementTestSession';
import getPlacementTest from './getPlacementTest';

function* root() {
  yield takeLatest(t.ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_REQUEST, ({ payload }) =>
    createPlacementTestSession(payload)
  );
  yield takeLatest(t.ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST, ({ payload }) =>
    editPlacementPlacementTestSession(payload)
  );
  yield takeLatest(t.ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST, ({ payload }) => getPlacementTest(payload));
}

export default root;
