import PropTypes from 'prop-types';

const _filter = (collection, tag) => (tag ? collection.filter(item => item.tags.includes(tag)) : collection);

/**
 * A `collection` can be any array of objects. The `tags` prop is an array
 * containing values that are to be compared against each object's `tags`
 * property in the collection.
 *
 * E.g. The below example would return 'Grant' and 'Malcolm'.
 * collection = [
 *  { name: 'Hammond', tags: ['Ownner'] },
 *  { name: 'Grant', tags: ['Visitor', 'Doctor'] },
 *  { name: 'Wu', tags: ['Employee', 'Doctor'] },
 *  { name: 'Malcolm', tags: ['Visitor', 'Chaotician'] }
 * ];
 * tags = ['Visitor'];
 *
 * @param {array} collection
 * @param {array} tags
 * @returns {function}
 */
function Filter({ collection, tags, properties = null, children }) {
  return children(
    tags.reduce(
      (filtered, tag) => (tag ? _filter(filtered, tag) : filtered),
      properties ? collection.filter(item => properties.some(property => item[property])) : collection
    )
  );
}

Filter.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object).isRequired,
  tags: PropTypes.array.isRequired,
  properties: PropTypes.array,
  children: PropTypes.func.isRequired
};

export default Filter;
