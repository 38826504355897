import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// At time of writing, OUP requested that we use the browser's default toDateString() to render American English date format
// because any further localisation would be inconsistent with the rest of the app at this state.
// This decision was made in an email from OUP Tom Howells on 27 Oct 2017.

function TimeTag({
  dateString,
  displayDateFormat = 'US', // See note above
  datetimeFormat = 'YYYY-MM-DD',
  displayDatePlaceholder = '--/--/--'
}) {
  const date = moment(dateString);
  const isValidDate = date.isValid();
  const formattedDate = isValidDate
    ? (displayDateFormat === 'US' && date.toDate().toDateString()) || date.format(displayDateFormat)
    : null;
  const dateTime = isValidDate ? date.format(datetimeFormat) : null;

  return isValidDate ? <time dateTime={dateTime}>{formattedDate}</time> : <span>{displayDatePlaceholder}</span>;
}

TimeTag.propTypes = {
  /* The date string to be used to render the date */
  dateString: PropTypes.string.isRequired,
  /* The format of the date to be rendered */
  displayDateFormat: PropTypes.string,
  /* The format of the date to be rendered in the datetime property */
  datetimeFormat: PropTypes.string,
  /* A placeholder string for invalid dates */
  displayDatePlaceholder: PropTypes.string
};
export default TimeTag;
