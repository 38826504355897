import { PLATFORMS } from '@oup/shared-node-browser/constants';

import * as t from '../actionTypes';

import { COURSE_MODAL_CLOSE_LINK_KEY } from '../../globals/cptConstants';

const initialState = {
  loading: false,
  success: false,
  error: false,
  coursePreviewModalOpen: false,
  courseModalCloseLink: '',
  currentPlatform: PLATFORMS.EPS_CONSOLE,
  results: [],
  courses: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.GET_COURSE_PREVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        results: []
      };
    case t.GET_COURSE_PREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        results: payload,
        success: true,
        error: false
      };
    case t.GET_COURSE_PREVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        results: [],
        success: false,
        error: payload
      };
    case t.OPEN_COURSE_PREVIEW_MODAL:
      return {
        ...state,
        coursePreviewModalOpen: true
      };
    case t.CLOSE_COURSE_PREVIEW_MODAL:
      return {
        ...initialState,
        coursePreviewModalOpen: false
      };
    case t.SET_COURSE_MODAL_CLOSE_LINK:
      if (payload !== '') {
        sessionStorage.setItem(COURSE_MODAL_CLOSE_LINK_KEY, payload);
      } else {
        sessionStorage.removeItem(COURSE_MODAL_CLOSE_LINK_KEY);
      }
      return {
        ...state,
        courseModalCloseLink: payload
      };
    case t.LOAD_COURSE_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        results: []
      };
    case t.LOAD_COURSE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: payload.courses,
        success: true,
        error: false
      };
    case t.LOAD_COURSE_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload
      };
    default:
      return {
        ...state
      };
  }
};
