function getLearnMoreContent(learnMoreGlossaryContent) {
  const contentList = [];
  const maxContentItems = 4; // matches the glossary format - if we need more, add to the schema

  function addContentItem(index) {
    const number = index + 1;
    const imgSrc = learnMoreGlossaryContent[`page_image_source_${number}`];
    const imgAlt = learnMoreGlossaryContent[`page_image_alt_text_${number}`] || '';
    const header = learnMoreGlossaryContent[`page_header_${number}`] || '';
    const body = learnMoreGlossaryContent[`page_body_${number}`] || '';

    if (!imgSrc) {
      return;
    }

    contentList.push({
      imgSrc,
      imgAlt,
      header,
      body
    });
  }

  for (let i = 0; i <= maxContentItems; i += 1) {
    addContentItem(i);
  }

  return contentList;
}

export default getLearnMoreContent;
