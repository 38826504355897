import * as t from '../actionTypes';

export const courseUploadRequest = file => ({
  type: t.COURSE_UPLOAD_REQUEST,
  payload: file
});

export const courseUploadStart = () => ({
  type: t.COURSE_UPLOAD_START
});

export const courseUploadSuccess = data => ({
  type: t.COURSE_UPLOAD_SUCCESS,
  payload: data
});

export const courseUploadFailure = error => ({
  type: t.COURSE_UPLOAD_FAILURE,
  payload: error
});

export const resetForm = () => ({
  type: t.COURSE_UPLOAD_INITIALISE
});
