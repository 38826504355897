export default function getIndividualAndLinkedProducts(products = {}, classAssignments = {}, profileAssignments = {}) {
  let allProducts = {};
  Object.values({ ...classAssignments, ...profileAssignments }).forEach(assignment => {
    if (assignment.linkedProductDetails) {
      allProducts = {
        ...allProducts,
        ...assignment.linkedProductDetails
      };
    }
  });

  allProducts = {
    ...allProducts,
    ...products
  };

  return allProducts;
}
