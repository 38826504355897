import { put } from 'redux-saga/effects';
import { bulkPasswordResetSuccess, bulkPasswordResetError } from '../../../../actions/bulkPasswordReset.js';
import bulkPasswordResetApi from '../../../apiCalls/bulkPasswordReset.api.js';

function* bulkPasswordReset({ orgId, userIds = [] } = {}) {
  const response = yield bulkPasswordResetApi(orgId, userIds);

  if (response.status === 'success') {
    yield put(bulkPasswordResetSuccess());
  } else {
    yield put(bulkPasswordResetError('Failed to initiate the bulk password reset action'));
  }
}

export default bulkPasswordReset;
