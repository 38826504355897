import * as t from '../actionTypes';

export const postNotificationMessage = ({ eventName, viewId }) => ({
  type: t.NOTIFICATION_MESSAGE_POST,
  payload: { type: 'NOTIFICATION', eventName, viewId }
});

export const postNavigationMessage = url => ({
  type: t.NAVIGATION_MESSAGE_POST,
  payload: { type: 'NAVIGATION', url }
});

export const postGotoMessage = url => ({
  type: t.GOTO_MESSAGE_POST,
  payload: { type: 'GOTO', url }
});
