import * as t from '../actionTypes';

export const getCourseContentsRequest = data => ({
  type: t.GET_COURSE_CONTENTS_REQUEST,
  payload: data
});

export const loadCourseContentsSuccess = data => ({
  type: t.GET_COURSE_CONTENTS_SUCCESS,
  payload: data
});

export const loadCourseContentsFailure = data => ({
  type: t.GET_COURSE_CONTENTS_FAILURE,
  payload: data
});
