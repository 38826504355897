import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
// Components
import { GLYPHS } from '../SVGIcon/SVGIcon';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import AssessmentTestFolder from '../AssessmentTest/AssessmentTestFolder';
import TestList from '../TestList/TestList';
// Utils
import { isLockedNode } from '../../structure/HubCourseAssessments/Utils/isLockedNode';
import folderHasTests from '../OnlineTestAssessments/Utils/folderHasTests';
// Style
import styles from './OnlineTestAssessmentSubfolder.scss';

function OnlineTestAssessmentSubfolder({
  folderId = '',
  assessmentData = {},
  course = {},
  onClickBackLink,
  content,
  hubContent,
  downloadAssessment,
  selectTest
}) {
  const renderSubfolders = () => (
    <ul className={styles.assessmentTestFoldersList}>
      {get(assessmentData, [folderId]) &&
        Object.entries(assessmentData[folderId]).map(([subfolderId, subfolder]) => {
          if (!subfolder?.title || !folderHasTests(subfolder)) return null;
          return (
            subfolder.title && (
              <AssessmentTestFolder
                key={subfolderId}
                id={subfolderId || ''}
                title={subfolder.title || ''}
                locked={isLockedNode(course, subfolder, true)}
                isLocked={(currentCourse, folder) => isLockedNode(currentCourse, folder, true)}
                tests={subfolder}
                hubContent={hubContent}
                course={course}
                level={0}
                downloadAssessment={downloadAssessment}
                hideDownloadButtonText
                selectOnlineTest={selectTest}
              />
            )
          );
        })}
    </ul>
  );

  return (
    <div className={styles.subfolderPanelContainer}>
      <span className={styles.backButton}>
        <LinkWithIcon
          id="AssessmentSubfolderBackLink"
          text={`${content.back_to_tests.replace('[assessmentTitle]', assessmentData?.title)}`}
          action={onClickBackLink}
          isLhs
          glyph={GLYPHS.ICON_LEFT}
          aria-label="Previous page"
        />
      </span>
      <div className={styles.panelTitle}>{assessmentData[folderId]?.title}</div>
      <TestList
        assessmentData={assessmentData?.[folderId]}
        course={course}
        hubContent={hubContent}
        downloadAssessment={downloadAssessment}
        selectTest={selectTest}
      />
      {renderSubfolders()}
    </div>
  );
}

OnlineTestAssessmentSubfolder.propTypes = {
  folderId: PropTypes.string.isRequired,
  assessmentData: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  onClickBackLink: PropTypes.func.isRequired,
  downloadAssessment: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  hubContent: PropTypes.object.isRequired,
  selectTest: PropTypes.func.isRequired
};
export default OnlineTestAssessmentSubfolder;
