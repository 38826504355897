import * as t from '../actionTypes';

export const searchProductRequest = payload => ({
  type: t.SEARCH_PRODUCT_REQUEST,
  payload
});

export const searchProductSuccess = payload => ({
  type: t.SEARCH_PRODUCT_SUCCESS,
  payload
});

export const searchProductFailure = payload => ({
  type: t.SEARCH_PRODUCT_FAILURE,
  payload
});

export const emptySearchProductResults = payload => ({
  type: t.EMPTY_SEARCH_PRODUCT_RESULTS,
  payload
});
