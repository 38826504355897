import { put } from 'redux-saga/effects';

import actions from '../../../../actions';

function* destroyImportUsers(id) {
  yield put(actions.removeImportUsersRequest(id));
  yield put(actions.checkImportUsersRequest());
}

export default destroyImportUsers;
