import { select } from 'redux-saga/effects';
import productUploadCheckApi from './productUploadCheckApi';

export default function* productUploadCheckLogsRequest() {
  console.log(`[productUploadCheckRequest Saga] Submitting request to get checkFile.`);

  const { formData, selectedPlatformCode } = yield select(state => ({
    formData: state.productUploadCheckLogs.formData,
    selectedPlatformCode: state.productUploadCheckLogs.selectedPlatformCode
  }));

  return yield productUploadCheckApi(formData, selectedPlatformCode);
}
