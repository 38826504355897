import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import colors from '../../globals/colors';
import { triggerSearch } from '../../redux/reducers/data/search.reducer';
import content from '../../utils/cmsContent';
import Button from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import { featureIsEnabled } from '../../globals/envSettings';
import SubSectionSkeletonLoader from '../SkeletonLoader/SubSectionSkeletonLoader';

function SearchStatus({
  notStarted,
  notStartedContent,
  noResultsFoundContent,
  loadingResults,
  searchFailed,
  resultIds,
  resultClass,
  resultStaff,
  resultPlatformEntitlements = [],
  triggerSearchAction
}) {
  const CMS = content.appComponents || {};

  if (notStarted && notStartedContent) {
    return notStartedContent;
  }

  return (
    <div>
      {loadingResults ? (
        <SubSectionSkeletonLoader
          speed={2}
          foregroundColor={colors.COLOR_GREY_DISABLED}
          backgroundColor={colors.COLOR_WHITE}
        />
      ) : null}

      {searchFailed && (
        <div style={{ textAlign: 'center' }}>
          <div style={{ width: '5rem', margin: '2rem auto 0' }}>
            <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
          </div>
          <p className="gin2">{CMS.error_loading_search_text}</p>
          <Button text="Retry" onClick={triggerSearchAction} />
        </div>
      )}

      {featureIsEnabled('client-side-pagination')
        ? !searchFailed &&
          !(
            resultIds.length !== 0 ||
            resultClass.length !== 0 ||
            resultStaff.length !== 0 ||
            (featureIsEnabled('otc-registration') && resultPlatformEntitlements.length !== 0)
          ) &&
          !loadingResults &&
          noResultsFoundContent
        : !searchFailed && resultIds.length === 0 && !loadingResults && noResultsFoundContent}
    </div>
  );
}

SearchStatus.propTypes = {
  // Prop Defined
  searchSource: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  noResultsFoundContent: PropTypes.element.isRequired,
  notStarted: PropTypes.bool,
  notStartedContent: PropTypes.element,
  // State Defined
  loadingResults: PropTypes.bool.isRequired,
  searchFailed: PropTypes.bool.isRequired,
  resultIds: PropTypes.array.isRequired,
  resultClass: PropTypes.array.isRequired,
  resultStaff: PropTypes.array.isRequired,
  resultPlatformEntitlements: PropTypes.array,
  triggerSearchAction: PropTypes.func.isRequired
};

/** creating pagination, filter, sorting, refresh for Front_End
 * orgStudents check for Org Student Tab
 */
export default connect(
  (state, { searchSource }) => {
    let resultIds = ['orgStudents'].includes(searchSource)
      ? Object.keys(state.search[searchSource].currentUsersList)
      : [];

    if (
      [
        'classStudents',
        'profileAssignments',
        'orgSubscriptions',
        'userAssignments',
        'classAssignments',
        'classTeachers',
        'oupStaff'
      ].includes(searchSource)
    ) {
      resultIds = Object.keys(state.search[searchSource].ids);
    }

    return {
      loadingResults: state.search[searchSource].loading,
      searchFailed: state.search[searchSource].error,
      resultIds,
      resultStaff: searchSource === 'orgStaff' ? Object.keys(state.search[searchSource].currentUsersList) : [],
      resultClass: Object.keys(state.search[searchSource].currentClassList) || []
    };
  },
  (dispatch, { searchSource }) => ({
    triggerSearchAction: () => dispatch(triggerSearch(searchSource))
  })
)(SearchStatus);
