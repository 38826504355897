import { put } from 'redux-saga/effects';
import { loadOfflineProducts } from '../../../../actions/offlineContentPlayer';

function* addOfflineProducts(productsToAdd) {
  const storedValue = localStorage.getItem('offline_products');
  const products = storedValue ? JSON.parse(storedValue) : {};

  productsToAdd.forEach(product => {
    const entries = Object.entries(product);
    const [key, value] = entries[0];
    products[key] = value;
  });

  localStorage.setItem('offline_products', JSON.stringify(products));
  yield put(loadOfflineProducts(products));
}

export default addOfflineProducts;
