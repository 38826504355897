import { put } from 'redux-saga/effects';
import actions from '../../../../actions';

function* updateStatus(success = true) {
  if (success) {
    yield put(actions.multiSchoolDialReportUpdateLoadedOrgs());
  } else {
    yield put(actions.multiSchoolDialReportFailure());
  }
}

export default updateStatus;
