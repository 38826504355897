import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button.js';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon.js';
import styles from '../formStates/ProductFinderFormStates.scss';

function ProductFinderLicencesFailed({ productFinderContent, getProductFinderLicences }) {
  return (
    <div className={styles.textCenter}>
      <div className={styles.licenceLoadingIconContainer}>
        <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
      </div>
      <p className={styles.paragraph}>{productFinderContent.loading_licences_error_text}</p>
      <Button text={productFinderContent.retry_button_text} onClick={getProductFinderLicences} />
    </div>
  );
}

ProductFinderLicencesFailed.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  getProductFinderLicences: PropTypes.func
};

export default ProductFinderLicencesFailed;
