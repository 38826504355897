import classnames from 'classnames';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import ConnectedSiteHeader from '../SiteHeader/SiteHeader';
import SubHeader from '../SiteHeader/SubHeader/SubHeader';
import BrowserCompatibilityBanner from '../Banners/BrowserCompatibilityBanner/BrowserCompatibilityBanner';
import CookieBanner from '../Banners/CookieBanner/CookieBanner';
import styles from './HeadersAndBanners.scss';
import { isCesMode, isHubMode } from '../../utils/platform';
import { openNavigation, closeNavigation } from '../../redux/actions/hubUi';
import SystemNotificationBanner from '../SiteHeader/SystemNotificationBanner/SystemNotificationBanner';
import { displayHeaderOrFooter } from '../../services/cptHelperMethods';

function runInstantlyAndRepeat(func, interval, amount) {
  // Set timeouts for increasing intervals to run the function
  for (let i = 0; i < amount; i += 1) {
    setTimeout(func, i * interval);
  }
}

class HeadersAndBanners extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      headerHeight: 61,
      displayHeader: true
    };
  }

  componentDidMount() {
    // Bind events that listen for need to rerender the header
    window.addEventListener('resize', this.handleHeaderHeightChange);
    // Initial run
    this.handleHeaderHeightChange();
    const { history } = this.props;
    this.unlisten = displayHeaderOrFooter(history, this.setDisplay);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  setDisplay = value => {
    this.setState({ displayHeader: value });
  };

  // Helper to close menu when user presses escape:
  onKeyEscape = e => {
    const { menuOpen } = this.state;
    if (e.keyCode === 27 && menuOpen) {
      this.onToggleMenu(e);
    }
  };

  // This has the effect of toggling a class that reveals the <SubHeader> on XS breakpoint:
  onToggleMenu = e => {
    const { menuOpen } = this.state;

    if (isHubMode()) {
      const {
        // eslint-disable-next-line no-shadow
        props: { openNavigation, closeNavigation, hubSideNavOpen }
      } = this;

      if (hubSideNavOpen === true) closeNavigation();
      else openNavigation();
      return;
    }

    const subHeaderMenu = document.getElementById('subNavMenu');
    const banners = document.getElementById('banners');

    // Discard event if user clicked outside the menu:
    if (!subHeaderMenu || !(subHeaderMenu.contains(e.target) || banners.contains(e.target))) {
      e.stopPropagation();
    }

    // Bind/unbind events when menu is toggled:
    if (menuOpen) {
      this.unbindMenuCloser();
    } else {
      this.bindMenuCloser();
    }

    this.setState({ menuOpen: !menuOpen });
  };

  bindMenuCloser = () => {
    document.addEventListener('click', this.onToggleMenu, true);
    document.addEventListener('keydown', this.onKeyEscape, true);
  };

  unbindMenuCloser = () => {
    document.removeEventListener('click', this.onToggleMenu, true);
    document.removeEventListener('keydown', this.onKeyEscape, true);
  };

  handleHeaderHeightChange = () => {
    // This negates the issue where componentDidUpdate is called before the DOM has updated
    runInstantlyAndRepeat(this.headerHeightChange, 100, 10);
  };

  headerHeightChange = () => {
    const fixedElement = document.getElementById('fixedHeader');
    const offsetHeight = (fixedElement || { offsetHeight: 61 }).offsetHeight;
    const { headerHeight } = this.state;

    if (headerHeight === offsetHeight) return;

    this.setState({ headerHeight: offsetHeight });
    document.documentElement.style.setProperty('--fixed-header-height', `${offsetHeight}px`);
  };

  render() {
    const {
      customSiteHeaderComponent,
      customSubHeader,
      orgId,
      isEmbedded,
      hideSignInLink,
      hideRegisterLink,
      hideWidgetDropDown,
      hideSubHeader,
      hideHelpLink,
      disableLogoLink,
      myOrgUrl,
      activityOrgUrl,
      orgChangeUrl,
      hasPendingInvites,
      hideMyAccountText,
      hubSideNavOpen,
      selfSelectRoleModalOpen,
      hideBurgerMenu,
      userId
    } = this.props;

    const { menuOpen, headerHeight, displayHeader } = this.state;

    const SiteHeaderComponent = customSiteHeaderComponent || ConnectedSiteHeader;
    const subHeader = customSubHeader || <SubHeader menuOpen={menuOpen} />;

    return (
      displayHeader && (
        <div className={classnames({ [styles.headersEmbedded]: isEmbedded })}>
          <header id="fixedHeader" style={{ position: 'fixed', zIndex: '104', width: '100%' }}>
            <div id="banners" role="complementary" className={styles.banners}>
              <CookieBanner handleHeaderHeightChange={this.handleHeaderHeightChange} />
              <BrowserCompatibilityBanner handleHeaderHeightChange={this.handleHeaderHeightChange} />
              <SystemNotificationBanner
                className={styles.systemNotification}
                handleHeaderHeightChange={this.handleHeaderHeightChange}
              />
            </div>
            <SiteHeaderComponent
              orgId={orgId}
              onToggleMenu={this.onToggleMenu}
              hideSignInLink={hideSignInLink}
              hideRegisterLink={hideRegisterLink}
              hideWidgetDropDown={hideWidgetDropDown}
              hideHelpLink={hideHelpLink}
              disableLogoLink={disableLogoLink}
              myOrgUrl={myOrgUrl}
              userId={userId}
              activityOrgUrl={activityOrgUrl}
              orgChangeUrl={orgChangeUrl}
              hideMyAccountText={hideMyAccountText}
              hideBurgerMenu={hideBurgerMenu}
              hubMenuSelected={hubSideNavOpen}
              selfSelectRoleModalOpen={selfSelectRoleModalOpen}
              hasPendingInvites={hasPendingInvites}
            />
          </header>
          <div style={{ height: `${headerHeight}px` }} />
          {!hideSubHeader && !hasPendingInvites ? subHeader : null}
        </div>
      )
    );
  }
}

HeadersAndBanners.propTypes = {
  customSiteHeaderComponent: PropTypes.element,
  customSubHeader: PropTypes.element,
  orgId: PropTypes.string,
  userId: PropTypes.string.isRequired,
  isEmbedded: PropTypes.bool.isRequired,
  hideSubHeader: PropTypes.bool.isRequired,
  hideRegisterLink: PropTypes.bool.isRequired,
  hideSignInLink: PropTypes.bool.isRequired,
  hideWidgetDropDown: PropTypes.bool.isRequired,
  hideHelpLink: PropTypes.bool.isRequired,
  disableLogoLink: PropTypes.bool.isRequired,
  myOrgUrl: PropTypes.string,
  selfSelectRoleModalOpen: PropTypes.bool.isRequired,
  activityOrgUrl: PropTypes.string,
  orgChangeUrl: PropTypes.string,
  hasPendingInvites: PropTypes.number,
  hideMyAccountText: PropTypes.bool,
  hideBurgerMenu: PropTypes.bool,
  hubSideNavOpen: PropTypes.bool,
  openNavigation: PropTypes.func,
  closeNavigation: PropTypes.func,
  history: PropTypes.object
};

// Grab the hubSideNavOpen key only for the Hub application
const mapStateToProps = state => {
  let stateToFetch = {
    orgId: state.identity.currentOrganisationId,
    ...pick(state.identity, ['userId'])
  };
  if (isHubMode()) {
    stateToFetch = {
      ...stateToFetch,
      hubSideNavOpen: state.hubUi.sideNavOpen,
      selfSelectRoleModalOpen: state.hubUi.selfSelectRoleModalOpen
    };
  }

  if (isCesMode()) {
    stateToFetch = {
      ...stateToFetch
    };
  }

  return stateToFetch;
};

// Grab openNavigation and closeNavigation actions only for Hub application
const mapDispatchToProps = isHubMode() ? { openNavigation, closeNavigation } : null;

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(HeadersAndBanners);
