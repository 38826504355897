// Helper to prevent focus on elements that are hidden by .a11y-hide etc.
// Made complicated by the the fact that inside a focus event we can't detect whether Shift is pressed (with Tab)
// So we have to track that keypress as a boolean that we can reference in the focus event.

import isFocusable from './isFocusable.js';
import querySelectorPrevFocusable from './querySelectorPrevFocusable.js';
import querySelectorNextFocusable from './querySelectorNextFocusable.js';

// This wil be true while Shift key is pressed at same time as Tab key:
let isShiftTab = false;

// To detect user tabbing into an element that's hidden or inside a hidden parent:
function onFocusIn(e) {
  if (e.target && !isFocusable(e.target)) {
    // Looks like we don't want focus here so do what we can to move it along:
    e.preventDefault();
    e.stopImmediatePropagation();

    // Are we navigating to previous or next element?
    const nextElem = isShiftTab ? querySelectorPrevFocusable() : querySelectorNextFocusable();

    // Switch focus to next element before anyone notices:
    // Otherwise the best we can do is throw away the focus state.
    if (nextElem && nextElem.focus) {
      nextElem.focus();
    } else if (e.target.blur) {
      e.target.blur();
    }
  }
}

// To detect when Tab key is released:
function onTabKeyUp(e) {
  if (e.which === 9) {
    // Unbind event listeners concerned with tab navigation:
    document.removeEventListener('focusin', onFocusIn, true);
    document.removeEventListener('keyup', onTabKeyUp, true);
  }
}

// To detect when Tab key is pressed:
function onTabKeyDown(e) {
  if (e.which === 9) {
    // Bind event listeners concerned with tab navigation:
    isShiftTab = e.shiftKey;
    document.addEventListener('focusin', onFocusIn, true);
    document.addEventListener('keyup', onTabKeyUp, true);
  }
}

// We only bind to the keydown event and it handles the rest:
document.addEventListener('keydown', onTabKeyDown, true);
