import { put } from 'redux-saga/effects';
import { orgSubscriptionType } from '@oup/shared-node-browser/subscriptions';
import bodySchema from '../../../../../../static/schema/draft-4/add-org-subscription-request-body.json';

import actions from '../../../../actions';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import getOrgDetailsApi from '../../../apiCalls/organisations/getOrgDetails.api.js';

import validateStartEndDates from '../../../utils/validateStartEndDates';
import { ISO_DATE } from '../../../../../globals/dateFormats';

function* validateOrgSubscription(input) {
  const { orgId, startDate, endDate } = input.formData;
  let { subscriptionType } = input.formData;
  const { field } = input;

  const bodyErrors = {};

  if ((['startDate', 'endDate'].includes(field) && startDate !== undefined) || endDate !== undefined) {
    const dateErrors =
      startDate && endDate ? validateStartEndDates(input.formData, { startDate, endDate }, ISO_DATE) : {};
    const errors = { ...bodyErrors, ...dateErrors };
    yield put(actions.createOrgSubscriptionValidated(errors));
  }

  if (field === 'orgId' && orgId !== undefined) {
    const errors = yield validateInputUsingSchema(bodySchema, null, { orgId });
    errors.isBlocked = false;

    let orgName = '';
    if (orgId) {
      const response = yield getOrgDetailsApi(orgId);
      errors.orgNotFound = response.status === 'error';

      if (response.data) {
        if (response.data?.isBlocked) {
          errors.isBlocked = true;
        }
        orgName = response.data.name;
        subscriptionType = orgSubscriptionType(response.data);

        yield put(actions.createOrgSubscriptionInput({ field: 'orgId', value: orgId }));
        yield put(actions.createOrgSubscriptionInput({ field: 'subscriptionType', value: subscriptionType }));
      }
    } else {
      errors.orgId = true;
    }

    yield put(actions.createOrgSubscriptionInput({ field: 'orgName', value: orgName }));
    yield put(actions.createOrgSubscriptionValidated(errors));
  }

  if (field === 'subscriptionType' && subscriptionType !== undefined) {
    yield put(actions.createOrgSubscriptionInput({ field: 'subscriptionType', value: subscriptionType }));
  }
}

export default validateOrgSubscription;
