import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ArchiveClassroomReview from './ArchiveClassroomReview.js';
import ArchiveClassroomSubmitting from './ArchiveClassroomSubmitting.js'; // Ajax processing spinner
import PopoutPanelIconHeading, {
  types
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import { closeForm } from '../../../../../../redux/reducers/classroomArchive';
import cmsContent from '../../../../../../utils/cmsContent.js';

function ArchiveClassroom({
  formState,
  classroomIds,
  requestFailed,
  failedIds,
  closeArchiveAction,
  resetForm,
  onComplete,
  backButtonAction
}) {
  const CMS = cmsContent.archiveClassPanel || {};
  let content;

  switch (formState) {
    case 'REVIEW_SELECTIONS':
      content = (
        <ArchiveClassroomReview
          classroomIds={classroomIds}
          backButtonAction={backButtonAction}
          closePopoutAction={closeArchiveAction}
        />
      );
      break;
    case 'SUBMITTING':
      content = (
        <PopoutPanelIconHeading
          type={types.LOADING}
          title={CMS.loading_page_title}
          subtitle={CMS.loading_page_subtitle}
        />
      );
      break;
    case 'CONFIRMATION':
      content = (
        <ArchiveClassroomSubmitting
          requestFailed={requestFailed}
          failedIds={failedIds}
          onDoneClick={() => {
            if (onComplete) onComplete();
            setTimeout(resetForm, 300);
          }}
        />
      );
      break;
    default:
      content = (
        <div>
          <p>Error. Missing a form for `{formState}`.</p>
        </div>
      );
  }

  return <div>{content}</div>;
}

ArchiveClassroom.propTypes = {
  formState: PropTypes.string.isRequired,
  failedIds: PropTypes.array,
  requestFailed: PropTypes.bool,
  resetForm: PropTypes.func.isRequired,
  closeArchiveAction: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  backButtonAction: PropTypes.func,
  classroomIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default connect(
  (state, { classroomIds }) => ({
    formState: state.classroomArchive.formState,
    classroomIds: classroomIds || state.classroomArchive.classroomIds,
    orgId: state.classroomArchive.orgId,
    requestFailed: state.classroomArchive.requestFailed,
    failedIds: state.classroomArchive.failedIds,
    usersRole: state.identity.role
  }),
  {
    resetForm: closeForm
  }
)(ArchiveClassroom);
