import { pick } from 'lodash';
import * as t from '../actionTypes';

const initialState = {
  orgId: null,
  adminUser: { email: '' },
  registrationStatus: '',
  name: '',
  role: '',
  countryCode: '',
  orgRegion: '',
  orgPostcode: '',
  curriculumType: '',
  orgUniqueId: '',
  primaryEmail: '',
  webAddress: '',
  address: '',
  city: '',
  province: '',
  hearAboutUs: '',
  department: '',
  taxRegistration: '',
  sapNumber: '',
  isLmsLtiTool: false,
  platformCode: '',
  errors: {},
  loading: false,
  reviewing: false,
  confirm: false,
  submitting: false,
  success: false,
  failure: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.VALIDATE_ORG_COMPLETED:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload
        }
      };
    case t.VALIDATE_ORG_ERROR:
      return {
        ...state,
        submitting: false
      };
    case t.REVIEW_ORG_INPUT:
      return {
        ...state,
        reviewing: true
      };
    case t.REVIEW_ORG_COMPLETED:
      return {
        ...state,
        reviewing: false,
        confirm: payload
      };
    case t.REVIEW_ORG_ERROR:
      return {
        ...state,
        reviewing: false
      };
    case t.CHANGE_ORG_INPUT:
      return {
        ...state,
        confirm: false
      };
    case t.REGISTER_ORG_REQUEST:
      return {
        ...state,
        confirm: false,
        submitting: true
      };
    case t.REGISTER_ORG_SUCCESS:
      return {
        ...state,
        orgId: payload,
        submitting: false,
        success: true
      };
    case t.REGISTER_ORG_FAILURE:
      return {
        ...state,
        submitting: false,
        failure: true
      };
    case t.GENERATE_ORG_UNIQUE_ID_SUCCESS:
      return {
        ...state,
        orgUniqueId: payload
      };
    case t.GET_PARTIAL_ORG_REQUEST:
      return {
        ...state,
        orgId: payload,
        loading: true
      };
    case t.GET_PARTIAL_ORG_SUCCESS:
      return {
        ...state,
        ...pick(payload, [
          'registrationStatus',
          'name',
          'role',
          'countryCode',
          'orgRegion',
          'orgPostcode',
          'curriculumType',
          'primaryEmail',
          'webAddress',
          'sapNumber'
        ]),
        orgUniqueId: payload.customId,
        loading: false
      };
    case t.GET_PARTIAL_ORG_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true
      };
    case t.REGISTER_ORG_FAILURE_CLEAR:
      return {
        ...state,
        failure: false
      };
    case t.REGISTER_ORG_RESET:
      return initialState;
    default:
      return state;
  }
};
