import * as t from '../../actionTypes';

const defaultStatusOptions = { submitting: false, success: false, error: false };
const requestStatusOptions = { submitting: true, success: false, error: false };
const successStatusOptions = { submitting: false, success: true, error: false };
const failureStatusOptions = { submitting: false, success: false, error: true };

const initialState = {
  courseId: '',
  course: { title: '', color: '', logo_path: '' },
  ...defaultStatusOptions,
  books: { items: [], ...defaultStatusOptions },
  structure: { items: [], ...defaultStatusOptions },
  resources: {
    items: [],
    page: { total: 0, size: 30, number: 1 },
    ...defaultStatusOptions,
    nextPageSubmitting: false,
    nextPageSuccess: false,
    nextPageError: false,
    uiHalted: false
  },
  reports: { data: {}, ...defaultStatusOptions },
  marks: { items: {}, ...defaultStatusOptions },
  homework: { items: [], loading: false, ...defaultStatusOptions },
  createAssignment: { data: {}, ...defaultStatusOptions },
  assignmentBasket: {
    fetched: false,
    loading: false,
    error: false,
    assignments: [],
    usedContentIds: [],
    resources: {
      items: [],
      page: {
        size: 20,
        total: 0
      },
      ...defaultStatusOptions
    }
  },
  classes: {},
  classesLoading: false
};

function ngsCourse(state = initialState, { type, payload }) {
  const classStructure = {};

  switch (type) {
    case t.NGS_LOAD_COURSE:
      return {
        ...initialState,
        courseId: payload.courseId,
        ...requestStatusOptions
      };
    case t.NGS_UNLOAD_COURSE:
      return initialState;
    case t.NGS_LOAD_COURSE_SUCCESS:
      return { ...state, course: payload, ...successStatusOptions };
    case t.NGS_LOAD_COURSE_FAILURE:
      return { ...state, ...failureStatusOptions };

    case t.NGS_COURSE_BOOKS_REQUEST:
      return { ...state, books: { ...state.books, ...requestStatusOptions } };
    case t.NGS_COURSE_BOOKS_SUCCESS:
      return { ...state, books: { items: payload, ...successStatusOptions } };
    case t.NGS_COURSE_BOOKS_FAILURE:
      return { ...state, books: { ...state.books, ...failureStatusOptions } };

    case t.NGS_COURSE_STRUCTURE_REQUEST:
      return { ...state, structure: { ...state.structure, ...requestStatusOptions } };
    case t.NGS_COURSE_STRUCTURE_SUCCESS:
      return { ...state, structure: { items: payload, ...successStatusOptions } };
    case t.NGS_COURSE_STRUCTURE_FAILURE:
      return { ...state, structure: { ...state.structure, ...failureStatusOptions } };

    case t.NGS_COURSE_RESOURCES_REQUEST:
      return { ...state, resources: { ...state.resources, ...requestStatusOptions } };
    case t.NGS_COURSE_RESOURCES_SUCCESS:
      return { ...state, resources: { items: payload.items, page: payload.page, ...successStatusOptions } };
    case t.NGS_COURSE_RESOURCES_FAILURE:
      return { ...state, resources: { ...state.resources, ...failureStatusOptions } };

    case t.NGS_BASKET_RESOURCES_REQUEST:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          resources: { ...state.assignmentBasket.resources, ...requestStatusOptions, loading: true }
        }
      };
    case t.NGS_BASKET_RESOURCES_SUCCESS:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          resources: { items: payload.items, page: payload.page, ...successStatusOptions, loading: false }
        }
      };

    case t.NGS_BASKET_RESOURCES_FAILURE:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          resources: {
            ...failureStatusOptions,
            loading: false
          }
        }
      };

    case t.NGS_NEXT_PAGE_COURSE_RESOURCES_REQUEST:
      return { ...state, resources: { ...state.resources, nextPageSubmitting: true, nextPageSuccess: false } };
    case t.NGS_NEXT_PAGE_COURSE_RESOURCES_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          items: state.resources.items.concat(payload.items),
          page: payload.page,
          nextPageSubmitting: false,
          nextPageSuccess: true
        }
      };
    case t.NGS_NEXT_PAGE_COURSE_RESOURCES_FAILURE:
      return { ...state, resources: { ...state.resources, nextPageSubmitting: false, nextPageError: true } };

    case t.NGS_NEXT_PAGE_BASKET_RESOURCES_REQUEST:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          resources: { ...state.assignmentBasket.resources, nextPageSubmitting: true, nextPageSuccess: false }
        }
      };
    case t.NGS_NEXT_PAGE_BASKET_RESOURCES_SUCCESS:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          resources: {
            ...state.assignmentBasket.resources,
            items: state.assignmentBasket.resources.items.concat(payload.items),
            page: payload.page,
            nextPageSubmitting: false,
            nextPageSuccess: true
          }
        }
      };
    case t.NGS_NEXT_PAGE_BASKET_RESOURCES_FAILURE:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          resources: { ...state.assignmentBasket.resources, nextPageSubmitting: false, nextPageError: true }
        }
      };

    case t.NGS_COURSE_REPORTS_REQUEST:
      return { ...state, reports: { ...state.reports, ...requestStatusOptions } };
    case t.NGS_COURSE_REPORTS_SUCCESS:
      return { ...state, reports: { data: payload, ...successStatusOptions } };
    case t.NGS_COURSE_REPORTS_FAILURE:
      return { ...state, reports: { ...state.reports, ...failureStatusOptions } };

    case t.NGS_COURSE_MARKS_REQUEST:
      return { ...state, marks: { ...state.marks, ...requestStatusOptions } };
    case t.NGS_COURSE_MARKS_SUCCESS:
      return { ...state, marks: { data: payload, ...successStatusOptions } };
    case t.NGS_COURSE_MARKS_FAILURE:
      return { ...state, marks: { ...state.marks, ...failureStatusOptions } };

    case t.NGS_COURSE_HOMEWORK_REQUEST:
      return { ...state, homework: { ...state.homework, ...requestStatusOptions, loading: true } };
    case t.NGS_COURSE_HOMEWORK_SUCCESS:
      return { ...state, homework: { items: payload, ...successStatusOptions, loading: false } };
    case t.NGS_COURSE_HOMEWORK_FAILURE:
      return { ...state, homework: { ...state.homework, ...failureStatusOptions, loading: false } };

    case t.NGS_OPEN_CREATE_ASSIGNMENT_FORM:
      return { ...state, createAssignment: { data: { ...payload }, ...defaultStatusOptions } };

    case t.NGS_GET_BASKET_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          loading: true
        },
        resources: {
          ...state.resources,
          uiHalted: (payload.meta && payload.meta.halt === true) || false
        }
      };

    case t.NGS_GET_BASKET_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          fetched: true,
          loading: false,
          assignments: payload,
          usedContentIds: payload.map(assignment => assignment.content[0].contentId)
        },
        resources: {
          ...state.resources,
          uiHalted: false
        }
      };

    case t.NGS_GET_BASKET_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          loading: false,
          error: true
        }
      };

    case t.NGS_UPDATE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          loading: payload.meta.loading || false
        }
      };

    case t.NGS_UPDATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          loading: false,
          assignments: state.assignmentBasket.assignments.map(assignment => {
            if (assignment.assignmentId === payload.assignmentId) {
              return {
                ...assignment,
                ...payload
              };
            }
            return assignment;
          })
        }
      };

    case t.NGS_GET_CLASSES_AND_STUDENTS_REQUEST:
      return {
        ...state,
        classesLoading: true
      };

    case t.NGS_CREATE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          uiHalted: (payload.meta && payload.meta.halt === true) || false
        },
        assignmentBasket: {
          ...state.assignmentBasket,
          loading: true
        }
      };

    case t.NGS_CREATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          uiHalted: false
        },
        assignmentBasket: {
          ...state.assignmentBasket,
          loading: false
        }
      };

    case t.NGS_REMOVE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          loading: false,
          assignments: state.assignmentBasket.assignments.filter(
            assignment => !(payload.assignments || []).includes(assignment.assignmentId)
          )
        }
      };

    case t.NGS_REMOVE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        assignmentBasket: {
          ...state.assignmentBasket,
          usedContentIds: state.assignmentBasket.usedContentIds.filter(id => !payload.removedContentIds.includes(id))
        }
      };

    case t.NGS_GET_CLASSES_AND_STUDENTS_SUCCESS:
      payload.classes.forEach(item => {
        const students = {};
        payload.students
          .filter(student => (student.classIds || []).includes(item.id))
          .forEach(student => {
            students[student.id] = {
              assigneeId: student.id,
              assigneeGroup: item.id, // class ID for now
              displayName: `${student.firstName || ''} ${student.lastName || ''}`
            };
          });

        classStructure[item.id] = {
          ...item,
          students
        };
      });

      return {
        ...state,
        classes: classStructure,
        classesLoading: false
      };

    default:
      return state;
  }
}

export default ngsCourse;
