/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies

import styles from './NotificationBoxText.scss';

import SvgIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Button, { buttonTypes } from '../Button/Button';

function NotificationBoxText({ message, customClassName, handleClickClosePanel, title }) {
  return (
    <div className={classNames(styles.notificationBoxTextContainer, customClassName)}>
      <div className={styles.body}>
        <SvgIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
        <div className={styles.textWrapper}>
          {title && <h3>{title}</h3>}
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      </div>
      <Button
        type={buttonTypes.PRIMARY_THIN}
        text="Okay"
        onClick={() => handleClickClosePanel({ showInfoPanel: false })}
        customClassName={styles.roundedCorners}
      />
    </div>
  );
}

NotificationBoxText.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  customClassName: PropTypes.string,
  handleClickClosePanel: PropTypes.func
};

export default NotificationBoxText;
