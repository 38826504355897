import { takeEvery } from 'redux-saga/effects';

import pollEntitiesSynced from './pollEntitiesSynced';
import * as t from '../../../../actionTypes';

// TODO: Correct correct calling of this saga on action dispatch
function* root() {
  yield takeEvery(t.ENTITIES_SYNCED_REQUEST, ({ payload, operationParams }) =>
    pollEntitiesSynced(payload, operationParams)
  );
}

export default root;
