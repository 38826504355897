import * as t from '../actionTypes';

export const csvProductStructureUploadFileParseRequest = payload => ({
  type: t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_PARSE_FILE_REQUEST,
  payload
});

export const csvProductStructureUploadFileParseCompleted = payload => ({
  type: t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_PARSE_FILE_COMPLETED,
  payload
});

export const csvProductStructureUploadFileParseFailure = (type, reason) => ({
  type: t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_PARSE_FILE_FAILURE,
  payload: { type, reason }
});

export const csvProductStructureUpload = payload => ({
  type: t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_START_UPLOAD,
  payload
});

export const csvProductStructureInputUpdate = payload => ({
  type: t.CSVPRODUCT_STRUCTURE_INPUT_UPDATE,
  payload
});

export const csvProductStructureUploadSuccess = payload => ({
  type: t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_SUCCESS,
  payload
});

export const csvProductStructureUploadFailure = payload => ({
  type: t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_FAILURE,
  payload
});

export const csvProductStructureUploadReset = () => ({
  type: t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_INIT
});
