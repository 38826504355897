import React from 'react';

import PropTypes from 'prop-types';
import styles from '../css/AddLicenseContentPage.scss';
import ProductInput from './ProductInput';
import ProductDetails from '../../../../../components/ProductDetails/ProductDetails.js';
import withLocalizedContent from '../../../../../language/withLocalizedContent';
import Calendar from '../../../../../components/Calendar/Calendar.js';
import useCalendarDisplay from './hooks/useCalendarDisplay';

function AddLicenseDetails({
  product,
  addProductToState,
  localizedContent: { activationCodeBatch: content },
  setLicenseDetails,
  licenseDetails
}) {
  const setDetails = setLicenseDetails();
  const minValue = 1;
  const maxValue = 200000;
  const {
    licenseStartDate,
    licenseEndDate,
    licenseType,
    totalConcurrency,
    userConcurrency,
    licenseInfo,
    beginOn,
    unitType,
    timePeriod,
    licenseAllowedUsage
  } = licenseDetails;

  const lincenseTypeDescription = (
    <p>
      <strong> {content.concurrent}:</strong> {content.licenseConcurrent} <br /> <br />
      <strong> {content.usage}:</strong> {content.licenseUsage}
      <br /> <br />
      <strong>{content.rolling}:</strong> {content.licenseRolling}
    </p>
  );
  const {
    toggleCalendarDisplay,
    changeAllToFalse,
    convertDate,
    licenceStartDateModal,
    licenceEndDateModal
  } = useCalendarDisplay();

  const generateExternalID = param => {
    if (!param) return {};
    const [obj] = param;
    const { id, systemId, typeId } = obj;
    return { id, systemId, typeId };
  };

  const processProductDetails = productDetails => {
    if (Object.keys(productDetails).length === 0 || productDetails.productInput !== undefined)
      return [
        { label: `${content.organisational_unit}`, text: '' },
        { label: `${content.activation_type}`, text: '' },
        { label: `${content.external_id_span}`, text: '' }
      ];
    const result = [];
    if (productDetails.organisationalUnit) {
      result.push({ label: `${content.organisational_unit}`, text: productDetails.organisationalUnit });
    }
    if (productDetails.activationDetail?.activationType) {
      result.push({ label: `${content.activation_type}`, text: productDetails.activationDetail.activationType });
    }
    if (productDetails?.externalSystems?.length) {
      const { id, systemId, typeId } = generateExternalID(productDetails.externalSystems);
      result.push({ label: `${content.external_id_span}`, text: `${systemId}/${typeId}: ${id}` });
    }
    return result;
  };

  const productName = product?.productName || product?.productGroupName || '';
  const productDetails = processProductDetails(product);
  return (
    <div className={styles.addLicenseDetailsContainer}>
      <span className={styles.productSpan}>{content.product_span}</span>
      <div className={styles.mainContainer}>
        <ProductInput selectedProduct={product} action={addProductToState} />
        <article className={styles.productDetails}>
          <span className={styles.productSummary}> {content.product_summary_span}</span>
          <ProductDetails id="1" title="Product" subtitle={productName} listData={productDetails} />
        </article>
      </div>
      <div className={styles.licenseTabContainer}>
        <article className={styles.licenseDetails}>
          <div className="inputs">
            <span className={styles.licenseTitle}>{content.licence}</span>
            <label htmlFor="licenceType" className={styles.inputContainer}>
              <span className={styles.names}>{content.licenceType}</span>
              <select
                id="licenceType"
                type="checbox"
                value={licenseType}
                onChange={e => {
                  setDetails.setLicenseTypeFunction(e.target.value);
                }}
              >
                <option value="ROLLING">{content.rolling.toUpperCase()}</option>
                <option value="CONCURRENT">{content.concurrent.toUpperCase()}</option>
                <option value="USAGE">{content.usage.toUpperCase()}</option>
              </select>
            </label>

            <div className={styles.calendarLabelContainer}>
              <label htmlFor="licenceStartDate" className={styles.inputContainer}>
                <span className={styles.names}> {content.licenceStartDate}</span>
                <input
                  readOnly
                  type="text"
                  id="licenceStartDate"
                  value={convertDate(licenseStartDate)}
                  onClick={() => {
                    changeAllToFalse();
                    toggleCalendarDisplay('licenceStartDateModal', true);
                  }}
                />
              </label>
              <div className={styles.calendarContainer}>
                {licenceStartDateModal && (
                  <Calendar onDateSelectedHandler={setDetails.setStartDateFunction} selectedDate={new Date()} />
                )}
              </div>
            </div>

            <div className={styles.calendarLabelContainer}>
              <label htmlFor="licenceEndDate" className={styles.inputContainer}>
                <span className={styles.names}> {content.licenceEndDate}</span>
                <input
                  readOnly
                  type="text"
                  id="licenceEndDate"
                  value={convertDate(licenseEndDate)}
                  onClick={() => {
                    changeAllToFalse();
                    toggleCalendarDisplay('licenceEndDateModal', true);
                  }}
                />
              </label>
              <div className={styles.calendarContainer}>
                {licenceEndDateModal && (
                  <Calendar onDateSelectedHandler={setDetails.setEndDateFucntion} selectedDate={new Date()} />
                )}
              </div>
            </div>
            {licenseType === `${content.concurrent.toUpperCase()}` && (
              <>
                <label htmlFor="totalConcurrency" className={styles.inputContainer}>
                  <span className={styles.names}>
                    {' '}
                    {content.totalConcurrencys}
                    <span className={styles.redStar}>*</span>
                  </span>
                  <input
                    type="number"
                    id="totalConcurrency"
                    value={totalConcurrency}
                    min={minValue}
                    max={maxValue}
                    onChange={e => {
                      const numberInput = Number(e.target.value);
                      if (!Number.isNaN(numberInput) && numberInput >= minValue && numberInput <= maxValue) {
                        setDetails.setTotalConcurrencyFunction(numberInput);
                      } else {
                        setDetails.setTotalConcurrencyFunction(minValue);
                      }
                    }}
                  />
                </label>

                <label htmlFor="userConcurrency" className={styles.inputContainer}>
                  <span className={styles.names}>
                    {' '}
                    {content.userConcurrency} <span className={styles.redStar}>*</span>
                  </span>
                  <input
                    type="number"
                    id="userConcurrency"
                    value={userConcurrency}
                    min={minValue}
                    max={maxValue}
                    onChange={e => {
                      const numberInput = Number(e.target.value);
                      if (!Number.isNaN(numberInput) && numberInput >= minValue && numberInput <= maxValue) {
                        setDetails.setUserConcurrencyFunction(numberInput);
                      } else {
                        setDetails.setUserConcurrencyFunction(minValue);
                      }
                    }}
                  />
                </label>
              </>
            )}

            {licenseType === `${content.rolling.toUpperCase()}` && (
              <>
                <label htmlFor="timePeriod" className={styles.inputContainer}>
                  <span className={styles.names}>
                    {' '}
                    {content.timePeriod} <span className={styles.redStar}>*</span>
                  </span>
                  <input
                    type="number"
                    required
                    id="timePeriod"
                    value={timePeriod}
                    min={minValue}
                    max={maxValue}
                    onChange={e => {
                      const numberInput = Number(e.target.value);
                      if (!Number.isNaN(numberInput) && numberInput >= minValue && numberInput <= maxValue) {
                        setDetails.setTimePeriodFunction(numberInput);
                      } else {
                        setDetails.setTimePeriodFunction(minValue);
                      }
                    }}
                  />
                </label>

                <label htmlFor="unitType" className={styles.inputContainer}>
                  <span className={styles.names}>{content.unitType}</span>
                  <select
                    id="unitType"
                    type="checbox"
                    value={unitType}
                    onChange={e => {
                      console.log(e.target.value);
                      setDetails.setUnitTypeFunction(e.target.value);
                    }}
                  >
                    <option value="year">{content.year}</option>
                    <option value="month">{content.month}</option>
                    <option value="week">{content.week}</option>
                    <option value="day">{content.day}</option>
                    <option value="hour">{content.hour}</option>
                    <option value="minute">{content.minute}</option>
                  </select>
                </label>

                <label htmlFor="beginOn" className={styles.inputContainer}>
                  <span className={styles.names}>{content.beginOn}</span>
                  <select
                    id="beginOn"
                    type="checbox"
                    value={beginOn}
                    onChange={e => {
                      setDetails.setBeginOnFunction(e.target.value);
                    }}
                  >
                    <option value="first_use">{content.firstUse}</option>
                    <option value="creation">{content.creation}</option>
                  </select>
                </label>
              </>
            )}
            {licenseType === `${content.usage.toUpperCase()}` && (
              <label htmlFor="licenseAllowedUsage" className={styles.inputContainer}>
                <span className={styles.names}>
                  {' '}
                  {content.allowedUsages}
                  <span className={styles.redStar}>*</span>
                </span>
                <input
                  type="number"
                  id="licenseAllowedUsage"
                  value={licenseAllowedUsage}
                  min={minValue}
                  max={maxValue}
                  onChange={e => {
                    const numberInput = Number(e.target.value);
                    if (!Number.isNaN(numberInput) && numberInput >= minValue && numberInput <= maxValue) {
                      setDetails.setLicenseAllowedUsageFunction(numberInput);
                    } else {
                      setDetails.setLicenseAllowedUsageFunction(minValue);
                    }
                  }}
                />
              </label>
            )}
          </div>
          <div className={styles.informationContainer}>
            <div className={`${styles.infoContainer} ${licenseInfo ? styles.infoContainerActive : ''} `}>
              {lincenseTypeDescription}
            </div>
            <span
              className={styles.italicI}
              onMouseEnter={() => {
                setDetails.setLicenseInfoFunction(true);
              }}
              onMouseLeave={() => {
                setDetails.setLicenseInfoFunction(false);
              }}
            >
              i
            </span>
          </div>
        </article>
      </div>
    </div>
  );
}

AddLicenseDetails.propTypes = {
  localizedContent: PropTypes.object,
  product: PropTypes.object,
  addProductToState: PropTypes.func,
  setLicenseDetails: PropTypes.func,
  licenseDetails: PropTypes.object
};

export default withLocalizedContent('activationCodeBatch')(AddLicenseDetails);
