import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './InformationBox.scss';

import SvgIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

/* eslint-disable react/no-danger */
function InformationBox({
  title = '',
  firstMessage,
  secondMessage = '',
  isFirstHtml = false,
  isSecondHtml = false,
  customClassName
}) {
  return (
    <div className={classNames(styles.informationBoxContainer, customClassName)}>
      <div className={styles.header}>
        <SvgIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
        <p>{title}</p>
      </div>
      <div className={styles.body}>
        {isFirstHtml ? <p dangerouslySetInnerHTML={{ __html: firstMessage }} /> : <p>{firstMessage}</p>}
        {secondMessage && isSecondHtml ? (
          <p dangerouslySetInnerHTML={{ __html: secondMessage }} />
        ) : (
          <p>{secondMessage}</p>
        )}
      </div>
    </div>
  );
}

InformationBox.propTypes = {
  title: PropTypes.string,
  firstMessage: PropTypes.string.isRequired,
  secondMessage: PropTypes.string,
  isFirstHtml: PropTypes.bool,
  isSecondHtml: PropTypes.bool,
  customClassName: PropTypes.string
};

export default InformationBox;
