import PropTypes from 'prop-types';
import React from 'react';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import EntityRepresentation from '../../../../../../components/EntityRepresentation/EntityRepresentation.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import { SIZES as thumbnailSizes } from '../../../../../../components/Thumbnail/Thumbnail.js';
import colors, { COLOR_FOR_ROLE } from '../../../../../../globals/colors.js';
import { validUserRoles as VALID_USER_ROLES } from '../../../../../../globals/userRoles.js';
import content from '../../../../../../utils/cmsContent.js';
import ContentWithSVGIcon from '../../../../../../components/ContentWithSVGIcon/ContentWithSVGIcon';
import { GLYPHS } from '../../../../../../components/SVGIcon/SVGIcon';
import { isHubMode } from '../../../../../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../globals/hubConstants';

const getIconHeading = (CMS, organisationName, invitePending, failed, error, userExists, classesOnboardFailed) => {
  if (error) {
    return {
      title: CMS.confirmation_error_title,
      subtitle: CMS.confirmation_error_subtitle,
      type: iconHeadingTypes.WARNING_ERROR,
      illustrationSrc: HubIllustrationConstants.ERROR,
      illustrationAltText: HubIllustrationAltText.ERROR
    };
  }
  if (classesOnboardFailed) {
    return {
      title: CMS.confirmation_partial_success_title_userAdded,
      subtitle: CMS.confirmation_partial_success_subtitle_userAdded.replace('{organisationName}', organisationName),
      type: iconHeadingTypes.WARNING,
      illustrationSrc: HubIllustrationConstants.WARNING,
      illustrationAltText: HubIllustrationAltText.WARNING
    };
  }
  if (userExists) {
    return {
      title: CMS.confirmation_error_title,
      subtitle: CMS.confirmation_error_subtitle_userExists.replace('{organisationName}', organisationName),
      type: iconHeadingTypes.WARNING_ERROR,
      illustrationSrc: HubIllustrationConstants.ERROR,
      illustrationAltText: HubIllustrationAltText.ERROR
    };
  }

  return {
    title: (invitePending
      ? CMS.confirmation_success_title_reminderSent
      : CMS.confirmation_success_title_userAdded
    ).replace('{organisationName}', organisationName),
    subtitle: invitePending
      ? ''
      : CMS.confirmation_success_subtitle_userAdded.replace('{organisationName}', organisationName),
    type: iconHeadingTypes.VALID,
    illustrationSrc: HubIllustrationConstants.SUCCESS,
    illustrationAltText: HubIllustrationAltText.SUCCESS
  };
};

const getButtonLableByContext = context => {
  const CMS = content.enrolUserPanel;
  if (context === APP_CONSTANTS.ORG_STUDENTS) return CMS.confirmation_button_addAnother;
  return CMS.confirmation_button_addAnother_staff;
};

function EnrolUserConfirmation({
  organisationName,
  name,
  email,
  roleName,
  invitePending,
  userExists,
  failed,
  apiError,
  closePanel,
  onAddOther,
  onComplete,
  context,
  classesOnboardFailed
}) {
  const CMS = content.enrolUserPanel;

  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePanel} />
          </div>
          {isHubMode() ? (
            <PopoutPanelIllustrationHeading
              {...getIconHeading(
                CMS,
                organisationName,
                invitePending,
                failed,
                apiError,
                userExists,
                classesOnboardFailed
              )}
            />
          ) : (
            <PopoutPanelIconHeading
              {...getIconHeading(
                CMS,
                organisationName,
                invitePending,
                failed,
                apiError,
                userExists,
                classesOnboardFailed
              )}
            />
          )}
        </div>
      }
      footerContent={
        <PopoutActionFooter
          showActionTextAndIcon={!isHubMode()}
          primaryButtonAction={failed ? onAddOther : onComplete}
          primaryButtonText={failed ? CMS.confirmation_button_tryAgain : CMS.confirmation_button_done}
          secondaryButtonAction={onAddOther}
          secondaryButtonText={failed ? '' : getButtonLableByContext(context)}
        />
      }
    >
      <div>
        {!failed ? (
          <div style={{ padding: '1rem', backgroundColor: '#E5E5E0' }}>
            <ContentWithSVGIcon glyph={failed ? GLYPHS.ICON_ERROR_CIRCLE : GLYPHS.ICON_CHECK_CIRCLE}>
              <EntityRepresentation
                userUpdateSuccess
                id="editedOrganisationName"
                thumbnailSize={thumbnailSizes.MEDIUM}
                thumbnailColor={COLOR_FOR_ROLE[roleName] || COLOR_FOR_ROLE.USER}
                thumbnailText={name}
                line1Text={name}
                line1Color={isHubMode() ? colors.BLACK_COLOR : colors.PRIMARY_BLUE}
                line2Text={isHubMode() ? email : null}
                line3Text={(failed ? CMS.confirmation_entity_notAdded : CMS.confirmation_entity_added).replace(
                  '{organisationName}',
                  organisationName
                )}
                status={failed ? 'ERROR' : 'TEXT'}
              />
            </ContentWithSVGIcon>
          </div>
        ) : null}
      </div>
    </ScrollContainer>
  );
}

EnrolUserConfirmation.propTypes = {
  closePanel: PropTypes.func.isRequired,
  organisationName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  roleName: PropTypes.oneOf(VALID_USER_ROLES).isRequired,
  invitePending: PropTypes.bool,
  userExists: PropTypes.bool,
  failed: PropTypes.bool.isRequired,
  apiError: PropTypes.object,
  onAddOther: PropTypes.func,
  onComplete: PropTypes.func,
  context: PropTypes.string,
  classesOnboardFailed: PropTypes.bool
};

export default EnrolUserConfirmation;
