import PropTypes from 'prop-types';
import React from 'react';

import withLocalizedContent from '../../language/withLocalizedContent';

import OrbLearningObjectLink from '../OrbLearningObjectLink/OrbLearningObjectLink.jsx';
import OrbContentCoverImage from '../OrbContentCoverImage/OrbContentCoverImage.js';
import OrbTeacherContentMetadata from '../OrbTeacherContentMetadata/OrbTeacherContentMetadata.jsx';

class OrbTeacherGenericContent extends React.PureComponent {
  render() {
    const {
      content,
      localizedContent: { orbTeacherResourcesPage: i18n }
    } = this.props;

    return (
      <div className="orb-card-container">
        <div className="row orb-card-header">
          <div className="col sm12">
            <div>
              <OrbLearningObjectLink content={content} downloadable title={i18n[content.resource_type]} />
            </div>
          </div>
        </div>
        <div className="row orb-card-content">
          {content.cover_image && (
            <div className="col sm2">
              <div className="orb-cover-container">
                <OrbContentCoverImage url={content.cover_image} className="orb-book-cover" />
              </div>
            </div>
          )}
          <div className={`col ${content.cover_image ? 'sm10' : 'sm12'}`}>
            <OrbTeacherContentMetadata content={content} />
            {content.description && <div className="orb-book-description">{content.description}</div>}
          </div>
        </div>
      </div>
    );
  }
}

OrbTeacherGenericContent.propTypes = {
  content: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('orbTeacherResourcesPage')(OrbTeacherGenericContent);
