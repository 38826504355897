import { put } from 'redux-saga/effects';
import editPlacementTestApi from '../../../apiCalls/editPlacementTestApi';
import {
  submitEditPlacesInTestSessionSuccess,
  submitEditPlacesInTestSessionFailure
} from '../../../../actions/placementTests';

function* submitSessionPlacesUpdate({ payload }) {
  const { orgId, groupId, data } = payload;
  const response = yield editPlacementTestApi(orgId, groupId, data);

  if (response.status === 'success') {
    yield put(submitEditPlacesInTestSessionSuccess(response.data));
  } else {
    yield put(submitEditPlacesInTestSessionFailure(response.message));
  }
}

export default submitSessionPlacesUpdate;
