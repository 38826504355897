import React from 'react';
import PropTypes from 'prop-types';

import SimpleHeader from '../SimpleHeader/SimpleHeader.js';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';

function LoadingPage({ showHeader, customLogoComponent }) {
  return (
    <div>
      {showHeader && <SimpleHeader customLogoComponent={customLogoComponent} />}
      <main
        id="maincontent"
        style={{
          display: 'flex',
          height: 'var(--vh100, 100vh)',
          minHeight: 'var(--vh100, 100vh)',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <PopoutPanelIconHeading type={types.LOADING} title="Loading" />
      </main>
    </div>
  );
}

LoadingPage.defaultProps = {
  showHeader: true
};

LoadingPage.propTypes = {
  showHeader: PropTypes.bool,
  customLogoComponent: PropTypes.element
};

export default LoadingPage;
