import { createSelector } from 'reselect';
import { get } from 'lodash';

/**
 * Create a selector resolving if the authenticated user has one of a set of
 * user roles.
 *
 * @param {(string|string[])} roles
 *
 * @return {Function}
 */
const createAuthorizeByRole = roles =>
  createSelector(
    state => get(state, ['identity', 'role'], ''),
    role => [].concat(roles).includes(role)
  );

export default createAuthorizeByRole;
