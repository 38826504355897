import { put, select } from 'redux-saga/effects';
import { isMarketingPrefEnabledPlatform } from '@oup/shared-node-browser/marketingPrefUtils.js';

import { setFormResult } from '../../../../reducers/data/forms.reducer.js';
import updatePartialUserRegistrationApi from '../../../apiCalls/users/updatePartialUserRegistration.api.js';
import { getFieldValue, requestFailed } from '../formUtil.js';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import { isHubMode, getCurrentPlatform } from '../../../../../utils/platform';

export default function* submitIncompleteAccount() {
  const currentPlatformCode = getCurrentPlatform();
  const { userId, payload } = yield select(state => {
    const finalPayload = {
      email: state.identity.email,
      privacyPolicyAccepted: true
    };

    if (featureIsEnabled('enable-marketing-preferences') && isMarketingPrefEnabledPlatform(currentPlatformCode)) {
      finalPayload.tsAndCs = state.registration.termsAndConditionsValue;
      finalPayload.marketingEmailAccepted = state.registration.marketingEmailAcceptedValue;
      finalPayload.underAgeAccepted = state.registration.underAgeAcceptedValue;
      finalPayload.platformCode = currentPlatformCode;
      finalPayload.orgId = state.identity.currentOrganisationId;
    } else {
      finalPayload.tsAndCs = getFieldValue(state, 'incompleteAccount', 'termsAndConditions');
    }
    return { userId: state.identity.userId, payload: finalPayload };
  });
  if (isHubMode()) {
    payload.hubTsAndCsAccepted = payload.tsAndCs;
    delete payload.tsAndCs;
  } else {
    payload.tsAndCsAccepted = payload.tsAndCs;
    payload.platformCode = currentPlatformCode;
    delete payload.tsAndCs;
  }
  const apiResult = yield updatePartialUserRegistrationApi(userId, payload);

  yield put(setFormResult('incompleteAccount', 'CONFIRMATION', requestFailed(apiResult)));
}
