import PropTypes from 'prop-types';
import React from 'react';

/**
 * An event handler that prevents the default action.
 *
 * @param {Event} e
 */
const noop = e => e.preventDefault();

/**
 * A form abstraction that allows submit behaviour to be handled by the parent
 * component.
 */
function ControlledForm({ action = null, onSubmit, children, ...props }) {
  return (
    <form action={action} onSubmit={action ? undefined : onSubmit || noop} {...props}>
      {children}
    </form>
  );
}

ControlledForm.propTypes = {
  action: PropTypes.string,
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default ControlledForm;
