import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import styles from './UserAdminDashboardPage.scss';
import { featureIsEnabled } from '../../../../globals/envSettings';

function UserAdminDashboardPage({ localizedContent: { userAdminDashboardPage: content } }) {
  const renderSearch = () => {
    if (featureIsEnabled('eps-admin-user-search')) {
      return (
        <Button
          to="/dashboard_eps/user_dashboard_search"
          type={buttonTypes.SECONDARY}
          text={content.search_label}
          fullWidth
          customClassName={styles.button}
        />
      );
    }

    return <div />;
  };

  return (
    <PageWrapper>
      <Helmet title="EPS" />
      {renderSearch()}
    </PageWrapper>
  );
}

UserAdminDashboardPage.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('userAdminDashboardPage'))(UserAdminDashboardPage);
