import PropTypes from 'prop-types';
import React from 'react';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './PasswordFieldDetail.css';

/**
 * The glossary keys of the base set of rules to display.
 *
 * @type {array}
 */
// prettier-ignore
const BASE_RULES = [
  'rule_min_length',
  'rule_lowercase',
  'rule_uppercase',
  'rule_no_spaces'
];
const BASE_RULES_SOCIAL = [
  'rule_min_length_social',
  'rule_lowercase_social',
  'rule_uppercase_social',
  'rule_no_spaces_social'
];

/**
 * The glossary keys of the additional rules to display when a user's password
 * is being updated.
 *
 * @type {array}
 */
const UPDATING_RULES = ['rule_not_recent'];

/**
 * A listed set of rules providing guidance for entering a user password.
 */
function PasswordFieldDetail({ updating = false, localizedContent: { passwordFieldDetail: content } }) {
  return (
    <article className={styles.article}>
      <SVGIcon className={styles.icon} glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
      <div>
        <h1 className={styles.title}>{content.prelude}:</h1>
        <ul className={styles.list}>
          {[...(updating ? BASE_RULES : BASE_RULES_SOCIAL), ...(updating ? UPDATING_RULES : [])].map((rule, i) => (
            <li key={i}>{content[rule]}</li>
          ))}
        </ul>
      </div>
    </article>
  );
}

PasswordFieldDetail.propTypes = {
  updating: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('passwordFieldDetail')(PasswordFieldDetail);
