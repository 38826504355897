const { pick } = require('lodash');

// user roles
const userRoles = [
  'MANAGED_USER',
  'LEARNER',
  'TEACHER',
  'TEACHER_ADMIN',
  'ORG_ADMIN',
  'OUP_SUPPORT',
  'OUP_ADMIN',
  'ALL'
];

const sortHandler = (roleGroups, userDetails, reversed, ...properties) => roleName => {
  roleGroups[roleName].sort((a, b) =>
    properties.reduce((result, property) => {
      const referenceString = `${userDetails[reversed ? b : a][property]}`;
      const compareString = `${userDetails[reversed ? a : b][property]}`;
      const compareOptions =
        !Number.isNaN(referenceString) && !Number.isNaN(compareString) ? { numeric: true } : undefined;
      if (referenceString && compareString) {
        return result || referenceString.localeCompare(compareString, undefined, compareOptions);
      }

      if (!referenceString && compareString) {
        return result || 1;
      }

      if (referenceString && !compareString) {
        return result || -1;
      }
      return result || -1;
    }, false)
  );
};

const sortUsers = (userDetails, sort, filters, requestedFromClientSide = false) => {
  const roleGroups = {};
  if ((filters.orgId || filters.classId) && filters.roles) {
    // Store user details
    Object.keys(userDetails).forEach(userId => {
      // Add user ID to respective group
      roleGroups[userDetails[userId].roleName] = roleGroups[userDetails[userId].roleName] || [];
      roleGroups[userDetails[userId].roleName].push(userId);
    });
  } else {
    roleGroups.ALL = Object.keys(userDetails);
  }
  let currentSort;
  if (sort === 'lastName:desc') {
    // Last name Z-A then first name Z-A
    currentSort = sortHandler(roleGroups, userDetails, true, 'lastname', 'firstname');
  } else if (sort === 'firstName:desc') {
    // First name Z-A then last name Z-A
    currentSort = sortHandler(roleGroups, userDetails, true, 'firstname', 'lastname');
  } else if (sort === 'name:desc') {
    currentSort = sortHandler(roleGroups, userDetails, true, 'name');
  } else if (sort === 'name:asc') {
    currentSort = sortHandler(roleGroups, userDetails, false, 'name');
  } else if (sort === 'date:asc' && filters.orgId && filters.userId && !filters.roles) {
    // User Joined Newest first
    currentSort = sortHandler(roleGroups, userDetails, false, `userJoinedDate-${filters.userId}`);
  } else if (sort === 'date:desc' && filters.orgId && filters.userId && !filters.roles) {
    //  User Joined Oldest first
    currentSort = sortHandler(roleGroups, userDetails, true, `userJoinedDate-${filters.userId}`);
  } else if (sort === 'date:desc' && !filters.roles) {
    currentSort = sortHandler(roleGroups, userDetails, true, 'createdDate');
  } else if (sort === 'date:asc' && !filters.roles) {
    currentSort = sortHandler(roleGroups, userDetails, false, 'createdDate');
  } else if (sort === 'firstName:asc') {
    // First name Z-A then last name Z-A
    currentSort = sortHandler(roleGroups, userDetails, false, 'firstname', 'lastname');
  } else if (sort === 'date:asc' && filters.classId) {
    // First name Z-A then last name Z-A
    currentSort = sortHandler(roleGroups, userDetails, false, `classStartDate-${filters.classId}`);
  } else if (sort === 'date:desc' && filters.classId) {
    // First name Z-A then last name Z-A
    currentSort = sortHandler(roleGroups, userDetails, true, `classStartDate-${filters.classId}`);
  } else if (sort === 'date:asc' && filters.orgId) {
    // First name Z-A then last name Z-A
    currentSort = sortHandler(roleGroups, userDetails, false, 'orgInviteDate');
  } else if (sort === 'date:desc' && filters.orgId) {
    // First name Z-A then last name Z-A
    currentSort = sortHandler(roleGroups, userDetails, true, 'orgInviteDate');
  } else if (sort === 'date:asc' && (!filters.orgId || !filters.classId)) {
    // First name Z-A then last name Z-A
    currentSort = sortHandler(roleGroups, userDetails, false, 'createdDate');
  } else if (sort === 'date:desc' && (!filters.orgId || !filters.classId)) {
    // First name Z-A then last name Z-A
    currentSort = sortHandler(roleGroups, userDetails, true, 'createdDate');
  } else if (sort === 'yearGroup:asc') {
    currentSort = sortHandler(roleGroups, userDetails, false, 'yearGroup');
  } else if (sort === 'yearGroup:desc') {
    currentSort = sortHandler(roleGroups, userDetails, true, 'yearGroup');
  } else {
    // lastname:asc comes here
    // Default is to sort by last name A-Z then first name A-Z
    currentSort = sortHandler(roleGroups, userDetails, false, 'lastname', 'firstname');
  }
  Object.keys(roleGroups).forEach(currentSort);
  const idsInOrder = (filters.roles || userRoles).reduce(
    (list, roleName) => [...list, ...(roleGroups[roleName] || [])],
    []
  );

  /**
   * 'requestedFromClientSide' is used to check sortUsers called from front-end
   * 'filters.isOrgStudentTab' is to check for org student tab only
   * pick is used for fron-end listing. sending users object to front-end instead of only userIds
   * If not from front-end sending userIds
   */
  const sortedList =
    requestedFromClientSide &&
    (filters.isOrgStudentTab ||
      filters.isOrgStaffTab ||
      filters.isOrgClassTab ||
      filters.isProfileClassTab ||
      filters.isUserProfileClassTab)
      ? pick(userDetails, idsInOrder)
      : idsInOrder;
  return sortedList;
};

module.exports = { sortUsers };
