import * as t from '../actionTypes';

export const getProductStructureRequest = payload => ({
  type: t.GET_PRODUCT_STRUCTURE_REQUEST,
  payload
});

export const getProductStructureSuccess = payload => ({
  type: t.GET_PRODUCT_STRUCTURE_SUCCESS,
  payload
});

export const getProductStructureError = () => ({
  type: t.GET_PRODUCT_STRUCTURE_ERROR
});

export const productStuctureResetState = payload => ({
  type: t.PRODUCT_STRUCTURE_RESET_STATE,
  payload
});
