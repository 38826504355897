import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import setOnlineTest from './setOnlineTest';
import loadClassAssessments from './loadClassAssessments';

function* root() {
  yield takeLatest(t.ASSESSMENTS_SET_ONLINE_TEST, ({ payload }) => setOnlineTest(payload));
  yield takeLatest(t.GET_CLASS_ASSESSMENTS, ({ payload }) => loadClassAssessments(payload));
}

export default root;
