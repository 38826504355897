import { tokenIsTerminated } from '@oup/shared-node-browser/userIdentity';
import authSettings from '../../../../globals/authSettings.js';
import { isLocal } from '../../../../globals/envSettings.js';
import console from '../../../../utils/console/asyncConsoleGroup.js';

// asyncConsole is used to batch up a group of async console.logs and output them all at once.
// It prevents noise from other console.logs from appearing inside a console.group.
// The downside is that no part of the group will output until groupEnd is called (or timeout).

export const TIMEOUT_ERROR = 'TIMEOUT_ERROR';

// Excludes console logs for below API's
const excludeAPILogs = ['getManagedUserDetails', 'getUserQueueApi'];

// Wrapper so we can fake a timeout feature on fetch requests:
// Sadly a fetch can't be aborted either (until FetchController reaches browsers)
function timeoutPromise(promise, timeout, error = TIMEOUT_ERROR) {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject(error), timeout);
    promise.then(resolve, reject);
  });
}

// fetch reponse headers
const responseHeaders = {};
function getHeaders(response) {
  [...response.headers.entries()].forEach(header => {
    responseHeaders[header[0]] = header[1];
  });
}

export default function jsonFetch(
  apiName,
  fetchArgs,
  includeHeaders = false,
  includeXAPIKey = true,
  includeStatusCode = false
) {
  const logGroupName = `[jsonFetch:${apiName}]`;
  let httpStatus;

  const REQUEST_URI = 0;
  const REQUEST_OPTIONS = 1;

  const fetchUri = fetchArgs[REQUEST_URI];
  const fetchOptions = fetchArgs[REQUEST_OPTIONS] || {};
  const timeout = fetchOptions.timeout; // overloading Request options with this custom property is confusing...
  delete fetchOptions.timeout; // ... tidy up the mess we created

  if (!isLocal() && includeXAPIKey === true) {
    // we cannot add the x-api-key header on local because the Koa server cannot deal with cors preflight OPTIONS https://www.npmjs.com/package/cors#enabling-cors-pre-flight
    if (!fetchOptions.headers) {
      fetchOptions.headers = {};
    }
    fetchOptions.headers['x-api-key'] = authSettings.cesApiKey;
  }

  // Wrap fetch in a ticking promise if a timeout is needed:
  const doFetch = timeout ? timeoutPromise(fetch(fetchUri, fetchOptions), timeout) : fetch(fetchUri, fetchOptions);

  // Output a standard log message to mark when this logGroupName actually started:
  console.log(logGroupName, '[Starting async console.group]');

  // Start console group:
  console.groupCollapsed(logGroupName, 'ASYNC');
  console.log(logGroupName, 'Parameters:', fetchArgs);

  return (
    doFetch
      .then(response => {
        getHeaders(response);
        if (!excludeAPILogs.includes(apiName)) {
          console.log(logGroupName, 'Response:', response);
        }
        httpStatus = response.status;
        return response.text();
      })
      // Note the deliberate use of response.text instead of response.json
      // to help elucidate json parse errors from response errors:
      .then(text => {
        if (!excludeAPILogs.includes(apiName)) {
          console.log(logGroupName, 'Response text:', text);
        }
        return JSON.parse(text);
      })
      .then(json => {
        if (!excludeAPILogs.includes(apiName)) {
          console.log(logGroupName, 'JSON:', json);
        }

        if (includeHeaders) json.headers = responseHeaders;
        if (includeStatusCode) json.statusCode = httpStatus;

        const launchToken = localStorage.getItem('lti-token') || localStorage.getItem('impersonation-token');
        const tokenMode = launchToken && !tokenIsTerminated(launchToken);
        /**
         * In launch token modes (LTI / Impersonation) we desire to actively log the user out and tells them
         * what happened when an API returns information about token expiry.
         */
        if (tokenMode && json?.info?.authInfo?.needNewToken) {
          // handle an expired token with a redirect to the logged out page
          const { expiredMinutesAgo, expiredPhrasing, orgId, launchId } = json.info.authInfo;
          window.location.href = `/logged-out?token-status=expired&minutes-ago=${expiredMinutesAgo}&phrasing=${expiredPhrasing}&org-id=${orgId}&launch=${launchId}`;
        }

        return json;
      })
      .catch(error => {
        const output = {};
        output.error = error;
        if (includeHeaders) output.headers = responseHeaders;
        if (includeStatusCode) output.statusCode = httpStatus;

        console.error(logGroupName, 'Error:', error);
        return output;
      })
      .then(result => {
        if (!excludeAPILogs.includes(apiName)) {
          console.groupEnd(logGroupName);
        }
        return result;
      })
  );
}
