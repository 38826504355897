import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import DOMPurify from 'dompurify';

import cmsContent from '../../utils/cmsContent';
import Button, { buttonTypes } from '../Button/Button';
import { GLYPHS, validGlyphs } from '../SVGIcon/SVGIcon';
import styles from './TableAccordion.scss';

export const columnTypes = {
  TEXT: 'TEXT',
  ICON: 'ICON',
  BUTTON: 'BUTTON'
};

export const validColumnTypes = Object.keys(columnTypes);

const MISSING_COL = {
  heading: '(Missing columns propType)',
  type: columnTypes.TEXT
};

function hasRevealableContent(rowProps) {
  return rowProps.revealableContent;
}

class TableAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // This array will keep track of which rows to show/hide,
      // takes from the expandedRows prop by default.
      revealRow: props.expandedRows
    };
  }

  // Toggle show/hide state of specified row:
  onToggleClick = i => {
    const { revealRow } = this.state;
    const newRevealRow = revealRow.slice();
    newRevealRow[i] = !newRevealRow[i];
    this.setState({ revealRow: newRevealRow });
  };

  render() {
    const CMS = cmsContent.searchResults || {};
    const { caption, columns, rows, customClass, revealButtonGlyph } = this.props;
    const { revealRow } = this.state;

    // Figure out if any of the rows have more cols defined than the columns prop.
    // That way we can show a missing-prop header instead of mystery "undefined" errors:
    const maxColCount = rows.reduce((count, row) => Math.max(count, (row.cells && row.cells.length) || 0), 0);
    const maxCols = new Array(Math.max(columns.length, maxColCount)).fill(0);

    // If ANY rows have revealableContent then we'll need to make a special column for the toggle arrow thing:
    const isRevealomaticTable = rows.some(hasRevealableContent);
    const columnsWithFixedWidth = ['Created By', 'Modified By'];
    const columnsWithFixedWidthIndex = columnsWithFixedWidth.map(fixedColumnName =>
      columns.findIndex(column => column.heading === fixedColumnName)
    );

    return (
      <table className={classnames(styles.tableAccordion, customClass)}>
        <caption className="a11y-hide">{caption || CMS.defaultCaption}</caption>
        <thead className={styles.tableAccordion__head}>
          <tr>
            {maxCols.map((n, index) => {
              const heading = (columns[index] || MISSING_COL).heading;
              const headingHidden = (columns[index] || MISSING_COL).headingHidden;
              return (
                <th
                  key={index}
                  scope="col"
                  className={classnames(
                    styles.tableAccordion__cell,
                    styles.tableAccordion__hCell,
                    columnsWithFixedWidth.includes(heading) && styles.fixedWidthEllipsis
                  )}
                >
                  <span className={headingHidden && 'a11y-hide'}>{heading}</span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tableAccordion__body}>
          {rows.map((row, rowIndex) => [
            <tr key={row.id} id={row.id} className={styles.tableAccordion__row}>
              {row.cells.map((cell, index) => {
                const colProp = columns[index];
                const cellTypeClassname = `cell-${(colProp || MISSING_COL).type}`;

                // Decide whether this cell is a special row-toggler cell to reveal hidden row:
                const isToggleCell = isRevealomaticTable && index === row.cells.length - 1;
                let content = cell;
                if (isToggleCell) {
                  content = (
                    <div
                      className={classnames(styles.revealButton, {
                        [styles['revealButton--open']]: revealRow[rowIndex]
                      })}
                    >
                      {row.id !== 'Total Row' && (
                        <Button
                          iconOnly
                          id={`reveal-${row.id}`}
                          text={cell}
                          type={buttonTypes.NO_BORDER}
                          glyph={revealButtonGlyph}
                          onClick={() => this.onToggleClick(rowIndex)}
                        />
                      )}
                    </div>
                  );
                } else if (typeof cell === 'string') {
                  // eslint-disable-next-line react/no-danger
                  content = <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cell) }} />;
                }

                return (
                  <td
                    key={index}
                    className={classnames(
                      {
                        [styles.tableAccordion__cell]: true,
                        [styles.tableAccordion__tCell]: true,
                        [styles['tableAccordion__tCell--border']]: rowIndex > 0,
                        [styles['tableAccordion__tCell--first']]: !index,
                        [styles['tableAccordion__tCell--background']]: row.shouldHighlightScore,
                        [styles[cellTypeClassname]]: index && styles[cellTypeClassname]
                      },
                      columnsWithFixedWidthIndex.includes(index) && styles.fixedWidthEllipsis
                    )}
                  >
                    {content}
                  </td>
                );
              })}
            </tr>,

            // This is only relevant when Table has revealableContent defined:
            row.revealableContent && (
              <tr
                className={classnames(
                  styles.tableAccordion__row,
                  styles.popupRow,
                  revealRow[rowIndex] && styles['popupRow--expanded']
                )}
              >
                <td colSpan={maxCols.length} className={styles.popupRow__cell}>
                  <AnimateHeight duration={500} height={revealRow[rowIndex] ? 'auto' : 0}>
                    {row.revealableContent}
                  </AnimateHeight>
                </td>
              </tr>
            )
          ])}
        </tbody>
      </table>
    );
  }
}

TableAccordion.propTypes = {
  /* Table caption text. Recommended. Defaults to cmsContent.searchResults.defaultCaption */
  caption: PropTypes.string,
  /** The headings of the Table */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      type: PropTypes.oneOf(validColumnTypes)
    })
  ).isRequired,
  /** The data of the Table */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      cells: PropTypes.arrayOf(PropTypes.node),
      /** Optionally define content for hidden "popup" row:
      (Requires corresponding extra item in cells prop to define text for toggle button) */
      revealableContent: PropTypes.any,
      shouldHighlightScore: PropTypes.bool
    })
  ).isRequired,
  expandedRows: PropTypes.array,
  customClass: PropTypes.string,
  revealButtonGlyph: PropTypes.oneOf(validGlyphs)
};

TableAccordion.defaultProps = {
  caption: '',
  expandedRows: [],
  customClass: '',
  revealButtonGlyph: GLYPHS.ICON_RIGHT_THICK
};

export default TableAccordion;
