import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Action imports
import {
  setNotifyWithEmail,
  archiveClass,
  handleClassroomArchiveSelectionPage,
  setClassroomsToArchive
} from '../../../../../../redux/reducers/classroomArchive.js';

// Component imports
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import ConnectedClassRepresentation from '../../../../../../components/ClassRepresentation/ConnectedClassRepresentation.js';
import PaginationButtons from '../../../../../../components/PaginationButtons/PaginationButtons';
import { SIZES as thumbnailSizes } from '../../../../../../components/Thumbnail/Thumbnail.js';

import cmsContent from '../../../../../../utils/cmsContent.js';
import { featureIsEnabled } from '../../../../../../globals/envSettings';
import { isOrbMode, isHubMode } from '../../../../../../utils/platform';
import { orgRoles } from '../../../../../../globals/orgRoles';
import {
  HubIllustrationConstants,
  HubIllustrationAltText,
  HubLayoutConstants
} from '../../../../../../globals/hubConstants.js';

function ArchiveClassroomReview({
  // Input values
  classroomIds,
  notifyWithEmailValue,

  // Form actions
  setNotifyWithEmailAction,

  // Navigation actions
  archiveClassAction,
  closePopoutAction,
  backButtonAction,
  isSecondarySchool,

  // Pagination
  handlePageSelectionAction,
  page,
  startSlice,
  endSlice,

  orgId,
  setClassroomsToArchiveAction
}) {
  const toggleRender = featureIsEnabled('class-toggle-remove') ? !isOrbMode() : true;
  const classesBulkArchiveEnabled = featureIsEnabled('classes-bulk-archive');
  const CMS = cmsContent.archiveClassPanel || {};
  const { MAX_ORGANIZATION_CLASSES_PER_PAGE } = HubLayoutConstants;
  const totalSelections = classroomIds.length;
  const popoutPrimaryBtnText = classesBulkArchiveEnabled
    ? (CMS.bulk_archive_classes_button_text || '').replace('[classroomIds]', totalSelections)
    : CMS.archive_class_button_text;
  const selectedClassroomIds = classesBulkArchiveEnabled ? classroomIds.slice(startSlice, endSlice) : classroomIds;

  useEffect(() => () => setClassroomsToArchiveAction([], orgId), []);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePopoutAction} />
            </div>
            {isHubMode() ? (
              <PopoutPanelIllustrationHeading
                title={CMS.archive_confirmation_title}
                subtitle={CMS.archive_confirmation_subtitle}
                illustrationSrc={HubIllustrationConstants.WARNING}
                illustrationAltText={HubIllustrationAltText.WARNING}
              />
            ) : (
              <PopoutPanelIconHeading
                title={CMS.archive_confirmation_title}
                subtitle={CMS.archive_confirmation_subtitle}
                type={iconHeadingTypes.WARNING}
              />
            )}
          </div>
        }
        footerContent={
          <PopoutActionFooter
            withIcon={!isHubMode()}
            wizardButtons={!!backButtonAction}
            secondaryButtonText={backButtonAction && CMS.back_button_text}
            secondaryButtonAction={backButtonAction}
            primaryButtonText={popoutPrimaryBtnText}
            primaryButtonAction={archiveClassAction}
            primaryButtonTestHook="ARCHIVE_CLASS_PANEL_ACTION_BUTTON"
            {...(toggleRender && !isSecondarySchool
              ? {
                  toggleSwitchId: 'notifyWithEmail',
                  toggleSwitchLabel: CMS.notify_students_text,
                  toggleSwitchValue: notifyWithEmailValue,
                  toggleSwitchOnChange: setNotifyWithEmailAction
                }
              : {})}
          />
        }
      >
        <div className="gin2">
          {classroomIds &&
            selectedClassroomIds.map(classroomId => (
              <div className="pad-bot1" key={classroomId}>
                <ConnectedClassRepresentation
                  id={`classroom-${classroomId}`}
                  thumbnailSize={thumbnailSizes.MEDIUM}
                  entityId={classroomId}
                  renderLearnerAmount
                />
              </div>
            ))}
          {classesBulkArchiveEnabled && totalSelections > MAX_ORGANIZATION_CLASSES_PER_PAGE ? (
            <div>
              <PaginationButtons
                idPrefix="selectedClassesArchive"
                value={page}
                numberOfPages={Math.ceil(totalSelections / 10)}
                onClick={handlePageSelectionAction}
                aria={{ 'aria-controls': 'classesArchive' }}
              />
            </div>
          ) : null}
        </div>
      </ScrollContainer>
    </form>
  );
}

ArchiveClassroomReview.propTypes = {
  // Input values
  classroomIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  notifyWithEmailValue: PropTypes.bool.isRequired,

  // Form actions
  setNotifyWithEmailAction: PropTypes.func.isRequired,

  // Navigation actions
  archiveClassAction: PropTypes.func.isRequired,
  closePopoutAction: PropTypes.func.isRequired,
  backButtonAction: PropTypes.func,
  isSecondarySchool: PropTypes.bool,

  // Pagination
  handlePageSelectionAction: PropTypes.func,
  page: PropTypes.number,
  startSlice: PropTypes.number,
  endSlice: PropTypes.number,

  orgId: PropTypes.string.isRequired,
  setClassroomsToArchiveAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    // Connect values
    classroomIds: state.classroomArchive.classroomIds,
    notifyWithEmailValue: state.classroomArchive.notifyWithEmail,
    isSecondarySchool:
      state.organisations.data[state.identity.currentOrganisationId].role === orgRoles.SECONDARY_SCHOOL,
    page: state.classroomArchive.page,
    startSlice: state.classroomArchive.startSlice,
    endSlice: state.classroomArchive.endSlice,
    orgId: state.classroomArchive.orgId
  }),
  {
    // Connect actions
    setNotifyWithEmailAction: setNotifyWithEmail,
    archiveClassAction: archiveClass,
    handlePageSelectionAction: handleClassroomArchiveSelectionPage,
    setClassroomsToArchiveAction: setClassroomsToArchive
  }
)(ArchiveClassroomReview);
