import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  ErrorStatus,
  PageWrapper,
  PopoutPanelIconHeading,
  RegisterOrgAdmin,
  RegisterOrgAdminSuccess
} from '../components';
import { types as popoutPanelIconHeadingTypes } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../language/withLocalizedContent';
import actions from '../redux/actions';
import { getCurrentPlatform } from '../utils/platform';

class RegisterOrgAdminPage extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      platform: getCurrentPlatform()
    };
  }

  _createChangeHandler = name => value => {
    this.setState({ [name]: value });
  };

  _createBlurHandler = name => () => {
    const { validate } = this.props;
    validate(pick(this.state, [name]));
  };

  _submit = () => {
    const { register } = this.props;
    register(pick(this.state, ['firstName', 'lastName', 'email', 'platform']));
  };

  _goToRegisterOrg = () => {
    const { email } = this.state;
    const { redirect } = this.props;

    let path = '/dashboard/org/register';

    if (email.length) {
      path = `${path}?adminUserEmail=${encodeURIComponent(email)}`;
    }

    redirect(path);
  };

  _goBackToDashboard = () => {
    const { redirect } = this.props;
    redirect('/dashboard');
  };

  render() {
    const { firstName, lastName, email } = this.state;

    const {
      localizedContent: { registerOrgAdminPage: content },
      errors,
      submitting,
      success,
      failure,
      emailLoading,
      emailUnavailable,
      clearFailure
    } = this.props;

    return (
      <PageWrapper>
        <Helmet title={content.title} />
        {(() => {
          switch (true) {
            case submitting:
              return (
                <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.LOADING} title={content.waiting_message} />
              );
            case success:
              return (
                <RegisterOrgAdminSuccess
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  primaryButtonLabel={content.register_organisation_button}
                  secondaryButtonLabel={content.back_to_dashboard_button}
                  onPrimaryButtonClick={this._goToRegisterOrg}
                  onSecondaryButtonClick={this._goBackToDashboard}
                />
              );
            case failure:
              return (
                <ErrorStatus
                  title={content.error_title}
                  buttonText={content.error_button}
                  buttonOnClickHandler={clearFailure}
                />
              );
            default:
              return (
                <RegisterOrgAdmin
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  errors={errors}
                  emailLoading={emailLoading}
                  emailUnavailable={emailUnavailable}
                  createChangeHandler={this._createChangeHandler}
                  createBlurHandler={this._createBlurHandler}
                  onSubmit={this._submit}
                  onSkipStep={this._goToRegisterOrg}
                />
              );
          }
        })()}
      </PageWrapper>
    );
  }
}

RegisterOrgAdminPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  emailLoading: PropTypes.bool,
  emailUnavailable: PropTypes.bool,
  clearFailure: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  withLocalizedContent('registerOrgAdminPage'),
  connect(
    state =>
      pick(state.orgAdminRegistration, [
        'errors',
        'submitting',
        'success',
        'failure',
        'emailLoading',
        'emailUnavailable'
      ]),
    (dispatch, props) => ({
      clearFailure: () => {
        dispatch(actions.registerOrgAdminClearFailure());
      },
      validate: input => {
        dispatch(actions.validateOrgAdminInput(input));
      },
      register: input => {
        dispatch(actions.registerOrgAdminRequest(input));
      },
      redirect: path => {
        dispatch(actions.registerOrgAdminReset());
        props.history.push(path);
      }
    })
  )
)(RegisterOrgAdminPage);
