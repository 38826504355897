import React from 'react';
import PropTypes from 'prop-types';
// Components
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import ActionList, { Action } from '../ActionList/ActionList';
// Constants
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
// Styles
import styles from './OnlineTestEditFailure.scss';

function OnlineTestEditFailure({ content, prev, assessmentTitle }) {
  return (
    <ScrollContainer
      footerContent={
        <ActionList>
          <Action back label={content.online_test_try_again} onClick={() => prev()} />
        </ActionList>
      }
    >
      <PopoutPanelIllustrationHeading
        title={content.online_test_error_header}
        illustrationSrc={HubIllustrationConstants.ERROR}
        illustrationAltText={HubIllustrationAltText.ERROR}
      />
      <div className={styles.midContainer}>
        {assessmentTitle} {content.confirmation_failed_update_subtitle}
      </div>
    </ScrollContainer>
  );
}

OnlineTestEditFailure.propTypes = {
  content: PropTypes.object,
  prev: PropTypes.func,
  assessmentTitle: PropTypes.string
};

export default OnlineTestEditFailure;
