import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { validColors } from '../../globals/colors';
import Lozenge from './Lozenge';

/** The component renders a white box with a colored top border and heading label. Contains generic content. */
function ConnectedLozenge({ personId, name, backgroundColor, buttonAction, useArrowIcon }) {
  return (
    <Lozenge
      id={`lozenge-person-${personId}`}
      text={name}
      backgroundColor={backgroundColor}
      buttonAction={buttonAction}
      useArrowIcon={useArrowIcon}
    />
  );
}

ConnectedLozenge.propTypes = {
  personId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  backgroundColor: PropTypes.oneOf(validColors).isRequired,
  buttonAction: PropTypes.func,
  /** An alternative to the default icon is an arrow. Set this prop to true to use it */
  useArrowIcon: PropTypes.bool
};

export default connect((state, { personId }) => ({
  name: `${state.people.data[personId].firstname || ''} ${state.people.data[personId].lastname || ''}`
}))(ConnectedLozenge);
