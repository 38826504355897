import signedFetch from './util/signedFetch';

export default function* lookupProductsApi(id) {
  const body = {
    searchString: id,
    searchProducts: true,
    searchProductGroups: true,
    searchExternalId: true,
    searchName: true
  };
  const URL = `${__API_BASE__}/admin-console/product/lookup`;
  return yield signedFetch('lookupProduct', URL, 'POST', body);
}
