import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../language/withLocalizedContent';
import ActionList, { Action } from '../ActionList/ActionList';
import ControlledForm from '../ControlledForm/ControlledForm';
import Dropdown from '../Dropdown/Dropdown';
import PersonRepresentation from '../PersonRepresentation/PersonRepresentation';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import TextInput from '../TextInput/TextInput';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import { SIZES as THUMBNAIL_SIZES } from '../Thumbnail/Thumbnail';
import {
  NAME_MAX_LIMIT,
  USERNAME_MAX_LIMIT,
  ALLOWED_ONLY_ALPHANUMERIC_AND_OPTIONAL_PERIOD,
  MANAGED_USER_ALLOWED_CHARACTERS
} from '../../globals/validations';
import Button, { buttonTypes } from '../Button/Button';
import styles from './ManagedUserEditForm.scss';

const _userNameRegex = value => ALLOWED_ONLY_ALPHANUMERIC_AND_OPTIONAL_PERIOD.test(value);

const _firstLastNameRegex = value => !MANAGED_USER_ALLOWED_CHARACTERS.test(value);

const _passwordIsSet = props => {
  if (props.password !== undefined) return true;
  if (props.userDetails.password === undefined && props.getInput.password === '') {
    return false;
  }
  return true;
};

const populatePasswordValidationStatus = props => {
  let status;
  let showMessage;
  if (props.editedField === 'password' && props.editedFieldState === 'onBlur') {
    status = props.errors.data.password ? 'error' : 'valid';
    showMessage = status !== 'valid';
  } else if (
    (props.editedField === 'password' &&
      (props.editedFieldState === 'onChange' || props.editedFieldState === 'onFocus')) ||
    props.getInput.password === ''
  ) {
    status = 'notice';
    showMessage = true;
  }
  return { status, showMessage };
};

class ManagedUserEditForm extends Component {
  // eslint-disable-next-line react/destructuring-assignment
  _yearGroupChangeHandler = this.props.createChangeHandler('yearGroup');

  componentWillUnmount() {
    const { clearStateForEditedFieldAction } = this.props;
    clearStateForEditedFieldAction();
  }

  _handleYearGroupChange = value => {
    const { yearGroups } = this.props;

    this._yearGroupChangeHandler(yearGroups[value].value);
  };

  _isEdited = () => {
    const {
      editedField,
      getInput: {
        firstName: inputFirstName,
        lastName: inputLastName,
        editableUserName: inputEditableUserName,
        password: inputPassword,
        yearGroup: inputYearGroup
      },
      user: {
        firstName: userFirstName,
        lastName: userLastName,
        editableUserName: userEditableUserName,
        password: userPassword,
        yearGroup: userYearGroup
      }
    } = this.props;

    if (editedField === 'password') return true;
    const retVal =
      userFirstName === inputFirstName &&
      userLastName === inputLastName &&
      userEditableUserName === inputEditableUserName &&
      userPassword === inputPassword &&
      userYearGroup === inputYearGroup;
    return !retVal;
  };

  _isActive = field => {
    const { errors = {}, putManagedUserApi } = this.props;
    if (putManagedUserApi) {
      return errors.data ? errors.data[field] : null;
    }
    return errors[field];
  };

  _getUserNameValidationStatus = () => {
    const {
      isUserNameTaken,
      getInput: { editableUserName }
    } = this.props;
    const originalValue = editableUserName;

    return this._getValidationStatus(editableUserName, 'username', originalValue, isUserNameTaken);
  };

  _getFirstNameValidationStatus = () => {
    const {
      getInput: { firstName }
    } = this.props;
    return this._getValidationStatus(firstName, 'first_name');
  };

  _getLastNameValidationStatus = () => {
    const {
      getInput: { lastName }
    } = this.props;
    return this._getValidationStatus(lastName, 'last_name');
  };

  _getValidationStatus = (fieldValue, fieldName, originalValue = null, isTaken = false) => {
    const {
      localizedContent: { managedUserChangeUsernamePanel, managedUserEditPanel }
    } = this.props;
    const content = fieldName === 'username' ? managedUserChangeUsernamePanel : managedUserEditPanel;
    const MAX_LIMIT = fieldName === 'username' ? USERNAME_MAX_LIMIT : NAME_MAX_LIMIT;

    switch (true) {
      case !fieldValue:
        return {
          isActive: true,
          message: content[`input_${fieldName}_error_default`]
        };
      case fieldValue.length > MAX_LIMIT:
        return {
          isActive: true,
          message: content[`input_${fieldName}_error_max_limit`]
        };
      case fieldName === 'username' && !_userNameRegex(fieldValue):
      case ['first_name', 'last_name'].includes(fieldName) && _firstLastNameRegex(fieldValue):
        return {
          isActive: true,
          message: content[`input_${fieldName}_error_allowed_characters`]
        };
      case fieldName === 'username' && isTaken && originalValue !== fieldValue:
        return {
          isActive: true,
          message: content.input_username_error_unique
        };
      default:
        return {
          isActive: false,
          message: null
        };
    }
  };

  render() {
    const {
      localizedContent: { managedUserEditPanel: content },
      getInput,
      username,
      yearGroups,
      userLocked = false,
      errors = {},
      onChangeUsernameClick,
      onChangePasswordClick,
      onRemoveClick,
      createChangeHandler,
      createBlurHandler,
      createFocusHandler,
      onSubmit,
      canRemoveUser,
      canOnlyChangePassword,
      userDetails,
      restPasswordPanel,
      putManagedUserApi,
      downloadSignInCard
    } = this.props;

    const { firstName, lastName, editableUserName, password, yearGroup } = getInput;

    const { isActive: usernameError, message: usernameErrorMessage } = this._getUserNameValidationStatus();
    const { isActive: firstNameError, message: firstNameErrorMessage } = this._getFirstNameValidationStatus();
    const { isActive: lastNameError, message: lastNameErrorMessage } = this._getLastNameValidationStatus();

    const disabled =
      !this._isEdited() ||
      (errors.data && Object.values(errors.data).some(Boolean)) ||
      !_passwordIsSet(this.props) ||
      usernameError ||
      !firstName ||
      !lastName ||
      firstNameError ||
      lastNameError;

    const isDownloadSigninCardButtonDisable =
      this._isEdited(this.props) || (errors.data && Object.values(errors.data).some(Boolean));

    const { status, showMessage } = populatePasswordValidationStatus(this.props);

    return (
      <ScrollContainer
        headerContent={
          <header className="gin-top2 gin-bot2">
            <PersonRepresentation
              thumbnailSize={THUMBNAIL_SIZES.MEDIUM}
              headerSizeName
              name={`${firstName} ${lastName}`}
              email={username}
              locked={userLocked}
            />
          </header>
        }
        footerContent={
          putManagedUserApi ? (
            <ActionList>
              <Button
                type={userDetails ? buttonTypes.ACTION : buttonTypes.DOWNLOAD}
                text={userDetails ? content.create_signin_card_text : content.download_signin_card_text}
                subText={userDetails ? '' : content.download_signin_card_sub_text}
                onClick={userDetails.password ? downloadSignInCard : restPasswordPanel}
                customClassName={styles.buttonWithSubText}
                fullWidth
                disabled={userDetails.password ? isDownloadSigninCardButtonDisable : false}
              />
              {canRemoveUser && <Action label={content.button_remove_text} onClick={onRemoveClick} />}
              <Action label={content.button_save_text} onClick={onSubmit} primary disabled={disabled} />
            </ActionList>
          ) : (
            <ActionList title={content.actions_heading}>
              {!canOnlyChangePassword && (
                <Action label={content.button_change_username_text} onClick={onChangeUsernameClick} />
              )}
              <Action label={content.button_change_password_text} onClick={onChangePasswordClick} />
              {canRemoveUser && <Action label={content.button_remove_text} onClick={onRemoveClick} />}
              <Action
                label={content.button_save_text}
                onClick={onSubmit}
                primary
                disabled={Object.values(errors).some(Boolean)}
              />
            </ActionList>
          )
        }
      >
        <div className="pad2">
          <ControlledForm>
            <ValidationStatus forId="firstName" isActive={firstNameError} message={firstNameErrorMessage}>
              <TextInput
                id="firstName"
                name="firstName"
                placeholder=""
                label={content.input_first_name_label}
                value={firstName}
                onChange={createChangeHandler('firstName')}
                onBlur={createBlurHandler('firstName')}
                required
              />
            </ValidationStatus>
            <ValidationStatus forId="lastName" isActive={lastNameError} message={lastNameErrorMessage}>
              <TextInput
                id="lastName"
                name="lastName"
                placeholder=""
                label={content.input_last_name_label}
                value={lastName}
                onChange={createChangeHandler('lastName')}
                onBlur={createBlurHandler('lastName')}
                required
              />
            </ValidationStatus>
            {putManagedUserApi && (
              <ValidationStatus forId="userName" isActive={usernameError} message={usernameErrorMessage}>
                <TextInput
                  id="userName"
                  name="userName"
                  placeholder=""
                  label={content.input_user_name_label}
                  value={editableUserName}
                  onChange={createChangeHandler('editableUserName')}
                  onBlur={createBlurHandler('editableUserName')}
                  required
                />
              </ValidationStatus>
            )}
            {putManagedUserApi &&
              (userDetails ? (
                <ValidationStatus
                  forId="password"
                  type={status}
                  isActive={showMessage}
                  message={status === 'notice' ? content.password_hint : content.password_input_error}
                >
                  <TextInput
                    id="password"
                    name="password"
                    placeholder=""
                    label={content.input_password_label}
                    value={password}
                    onChange={createChangeHandler('password')}
                    onBlur={createBlurHandler('password')}
                    onFocus={createFocusHandler('password')}
                    required
                  />
                </ValidationStatus>
              ) : (
                <div>
                  <p style={{ display: 'block', margin: '15px 0 5px', fontWeight: '600' }}>
                    <label htmlFor="lable">{content.input_password_upadate_label}</label>
                  </p>
                  <Button
                    to=""
                    type={buttonTypes.ACTION_BLOCK}
                    onClick={restPasswordPanel}
                    text={content.link_password_update_label}
                  />
                </div>
              ))}
            <ValidationStatus
              forId="yearGroup"
              type={this._isActive('yearGroup') === null || this._isActive('yearGroup') === undefined ? null : 'valid'}
              isActive={
                this._isActive('yearGroup') === null || this._isActive('yearGroup') === undefined
                  ? this._isActive('yearGroup')
                  : !this._isActive('yearGroup')
              }
              message={content.input_year_group_valid_text}
            >
              <Dropdown
                id="yearGroup"
                name="yearGroup"
                label={content.input_year_group_label}
                value={Object.keys(yearGroups).find(key => yearGroups[key].value === yearGroup)}
                options={Object.entries(yearGroups).map(([key, { name }]) => ({
                  text: name,
                  value: key
                }))}
                onChange={this._handleYearGroupChange}
                onBlur={createBlurHandler('yearGroup')}
                customClassName={styles.dropdown}
                required
              />
            </ValidationStatus>
            <p className="gin-top1">{content.appropriate_permissions_notice}</p>
          </ControlledForm>
        </div>
      </ScrollContainer>
    );
  }
}

ManagedUserEditForm.propTypes = {
  localizedContent: PropTypes.object,
  getInput: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    editableUserName: PropTypes.string.isRequired,
    password: PropTypes.string,
    yearGroup: PropTypes.number
  }),
  username: PropTypes.string.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    editableUserName: PropTypes.string.isRequired,
    password: PropTypes.string,
    yearGroup: PropTypes.number
  }),
  editedField: PropTypes.string,
  yearGroups: PropTypes.object,
  userLocked: PropTypes.bool,
  onChangeUsernameClick: PropTypes.func.isRequired,
  onChangePasswordClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  errors: PropTypes.object,
  createChangeHandler: PropTypes.func.isRequired,
  createBlurHandler: PropTypes.func.isRequired,
  createFocusHandler: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  canRemoveUser: PropTypes.bool.isRequired,
  canOnlyChangePassword: PropTypes.bool,
  userDetails: PropTypes.object,
  isUserNameTaken: PropTypes.bool,
  restPasswordPanel: PropTypes.func.isRequired,
  putManagedUserApi: PropTypes.bool,
  downloadSignInCard: PropTypes.func,
  clearStateForEditedFieldAction: PropTypes.func
};

export default withLocalizedContent('managedUserEditPanel', 'managedUserChangeUsernamePanel')(ManagedUserEditForm);
