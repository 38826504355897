import React from 'react';
import PropTypes from 'prop-types';
import PopoutPanelIconHeading, {
  types
} from '../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import content from '../../../../../utils/cmsContent.js';

function JoinAClassSubmitting({ context, orgClassDetails }) {
  const CMS = content.studentRequestToJoinAClass || {};
  return (
    <PopoutPanelIconHeading
      type={types.LOADING}
      title={CMS.submitting_title}
      subtitle={
        !context
          ? CMS.submitting_subtitle
          : CMS.review_submitting_subtitle.replace('{organisationName}', orgClassDetails.organisationName)
      }
    />
  );
}

JoinAClassSubmitting.propTypes = {
  context: PropTypes.string,
  orgClassDetails: PropTypes.object
};

export default JoinAClassSubmitting;
