import { put } from 'redux-saga/effects';
import searchUserApi from '../../../apiCalls/search/searchUsers.api';
import { getCurrentPlatform } from '../../../../../utils/platform';
import { getClassStudentsSuccess, getClassStudentsError } from '../../../../actions/classOnboardingWizard';
import userRoles from '../../../../../globals/userRoles';

function* getClassStudents(data) {
  const payload = {
    sort: 'firstName:asc',
    filters: {
      active: true,
      invited: true,
      archived: true,
      orgId: data.orgId,
      roles: [userRoles.LEARNER],
      classId: data.classId
    },
    start: 0,
    size: 100,
    term: '',
    platformCode: getCurrentPlatform() || ''
  };

  const results = yield searchUserApi(payload);

  if (results?.status === 'success') {
    return yield put(
      getClassStudentsSuccess({
        students: results.data.users
      })
    );
  }
  return yield put(getClassStudentsError());
}

export default getClassStudents;
