// Helper to prevent content behind a modal from scrolling.
// The solution is two fold:
// 1. Use css to prevent scrolling on body. Works in most browsers. See .preventBodyScroll in base.scss.
// 2. As belt and braces, any scroll events that get past the css are blocked too.

let isPreventionEnabled;

function blockScrollEvent() {
  // It's not possible to preventDefault on scroll event so this is the best we can do:
  window.scroll(0, 0);
}

export default function preventBodyScroll(enable = true) {
  if (enable && !isPreventionEnabled) {
    isPreventionEnabled = true;
    document.body.classList.add('preventBodyScroll');
    window.addEventListener('scroll', blockScrollEvent, false);
  } else if (!enable && isPreventionEnabled) {
    isPreventionEnabled = false;
    document.body.classList.remove('preventBodyScroll');
    window.removeEventListener('scroll', blockScrollEvent, false);
  }
}
