import { put, takeLatest } from 'redux-saga/effects';
import { SET_FORM_STATE } from '../../../reducers/data/forms.reducer.js';

import submitOrgArchive from './handlers/submitOrgArchive.saga.js';
import submitOrgCreate from './handlers/submitOrgCreate.saga.js';
import submitOrgEdit from './handlers/submitOrgEdit.saga.js';
import submitContactUs from './handlers/submitContactUs.saga.js';

import loadResetPassword from './handlers/loadResetPassword.saga.js';
import submitResetPassword from './handlers/submitResetPassword.saga.js';
import submitRequestPasswordReset from './handlers/submitRequestPasswordReset.saga.js';
import submitRequestUpdateUserName from './handlers/submitRequestUpdateUserName.saga.js';
import submitIncompleteAccount from './handlers/submitIncompleteAccount.saga.js';

import { appError } from '../../../reducers/app.reducer.js';

const handlers = {
  orgCreate: { SUBMITTING: submitOrgCreate },
  orgEdit: { SUBMITTING: submitOrgEdit },
  orgArchive: { SUBMITTING: submitOrgArchive },
  contactUs: { SUBMITTING: submitContactUs },
  requestPasswordReset: { SUBMITTING: submitRequestPasswordReset },
  requestChangeUsername: { SUBMITTING: submitRequestUpdateUserName },
  resetPassword: { LOADING: loadResetPassword, SUBMITTING: submitResetPassword },
  incompleteAccount: { SUBMITTING: submitIncompleteAccount }
};

function* formStateChangeHandler({ formName, formState }) {
  // Scroll form to top to ensure user will see the next page of content:
  // This is necessary when for example, moving from the bottom of a long form to a confirmation page.

  // Detect when PopoutPanel is open: (It adds a className to the <body> element while open)
  const isPanelOpen = document.body.classList.contains('preventBodyScroll');

  // Scroll the page or panel back to the top:
  // We assume there's only ever one ScrollContainer on the page.
  if (isPanelOpen) {
    (document.getElementById('scrollContainerViewport') || {}).scrollTop = 0;
  } else {
    window.scroll(0, 0);
  }

  try {
    const handler = handlers[formName] && handlers[formName][formState];

    if (handler) {
      yield handler();
    }
  } catch (error) {
    console.log('[bindFormHandlers:formStateChangeHandler] Error occurred:', error);
    yield put(appError(`---- ${error}`));
  }
}

export default function* bindFormHandlers() {
  console.log('[bindFormHandlers] Binding...');
  yield takeLatest(SET_FORM_STATE, formStateChangeHandler);
}
