import { put, take } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import { showSuccessMessage, showGenerateReportError } from '../../../../reducers/schoolReport.reducer';
import generateReportRequest from './generateSchoolReportRequest';

export default function* generateSchoolReport() {
  console.log('[generateReport Saga] Beginning');

  while (true) {
    yield take(t.GENERATE_SCHOOL_REPORT_REQUEST);

    console.log('[generateReport Saga] Start Request');
    const result = yield generateReportRequest();
    const status = result.status ? result.status.toLowerCase() : 'error';

    const failed = !!result.error || status !== 'success';
    const apiError = result.error || status !== 'success' ? result.message : null;

    if (!failed) {
      console.log('[generateReport Saga] Request completed, showing results');
      yield put(showSuccessMessage(result.data));
    } else {
      console.log('[generateReport Saga] Request completed, but failed, showing error');
      yield put(showGenerateReportError(apiError));
    }
  }
}
