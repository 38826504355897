import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import styles from './BrowserCompatibilityBanner.scss';

import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';
import Button, { buttonTypes } from '../../../components/Button/Button.js';

import { closeCompatibilityBanner } from '../../../redux/reducers/app.reducer.js';

import { isDev } from '../../../globals/envSettings.js';

import content from '../../../utils/cmsContent.js';

class BrowserCompatibilityBanner extends Component {
  componentDidUpdate() {
    const { handleHeaderHeightChange } = this.props;
    handleHeaderHeightChange();
  }

  render() {
    const { visible, hideBanner } = this.props;
    const CMS = content.browserCompatibilityBanner;

    let browserInfo = '';

    if (isDev()) {
      const browserInfoName = sessionStorage.getItem('browserInfoName')
        ? sessionStorage.getItem('browserInfoName')
        : 'unknown';
      const browserInfoVersion = sessionStorage.getItem('browserInfoVersion')
        ? sessionStorage.getItem('browserInfoVersion')
        : 'unknown';
      const browserInfoOS = sessionStorage.getItem('browserInfoOS')
        ? sessionStorage.getItem('browserInfoOS')
        : 'unknown';
      if (browserInfoName === 'unknown' && browserInfoVersion === 'unknown' && browserInfoOS === 'unknown') {
        browserInfo = '';
      } else {
        browserInfo = `Your browser is identifying itself as ${browserInfoName}, version ${browserInfoVersion} and is running on ${browserInfoOS}.`;
      }
    }

    return (
      visible && (
        <div className={styles.browserCompatibilityBanner}>
          <div className="grid">
            <div className="row">
              <div className="col">
                <div className={classnames(styles.icon, 'xs-hide sm-show')}>
                  <SVGIcon glyph={GLYPHS.ICON_WARNING_CIRCLE} />
                </div>
                <div className={styles.text}>
                  <p>{CMS.line1Text}</p>
                  <p>{CMS.line2Text.replace('{browserInfo}', browserInfo)}</p>
                </div>
                <div className={styles.buttons}>
                  <Button type={buttonTypes.PRIMARY} to="/support" text={CMS.button1Text} />
                  <Button type={buttonTypes.SECONDARY} onClick={hideBanner} text={CMS.button2Text} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

BrowserCompatibilityBanner.propTypes = {
  visible: PropTypes.bool,
  hideBanner: PropTypes.func.isRequired,
  handleHeaderHeightChange: PropTypes.func.isRequired
};

export default connect(
  state => ({
    visible: state.app.browserIncompatible && state.app.compatibilityBannerOpen
  }),
  {
    hideBanner: closeCompatibilityBanner
  }
)(BrowserCompatibilityBanner);
