import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextInputWithButton from '../../../../components/TextInputWithButton/TextInputWithButton';
import TextInputField from '../../../../components/TextInputField/TextInputField';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import withLocalizedContent from '../../../../language/withLocalizedContent';

import {
  setCodeForDashboard,
  createActivationCode,
  setAllowedUsageForDashboard,
  setBatchNameForDashboard
} from '../../../../redux/actions/activationCodeDashboard';

function ActivationCodeCreateArea({
  localizedContent: { activationCodeDashboard: content },
  setAllowedUsage,
  setBatchName,
  createActCode
}) {
  const performCodeCreation = () => {
    createActCode();
  };

  return (
    <div>
      <div>
        <h2>{content.create_section}</h2>
        <div>
          <TextInputField id="allowUsage" onChange={setAllowedUsage} placeholder={content.allowed_usage} />
        </div>

        <div>
          <TextInputWithButton
            placeholder={content.batch_name}
            onChange={setBatchName}
            buttonAction={performCodeCreation}
            buttonIcon={GLYPHS.ICON_PLUS}
          />
        </div>
      </div>
    </div>
  );
}

ActivationCodeCreateArea.propTypes = {
  localizedContent: PropTypes.object,
  setAllowedUsage: PropTypes.func,
  setBatchName: PropTypes.func,
  createActCode: PropTypes.func
};

const mapStateToProps = state => {
  const {
    activationCodeDashboard: { errorOccurred, errorMessage, success, successMessage }
  } = state;
  return { errorOccurred, errorMessage, success, successMessage };
};

export default compose(
  withLocalizedContent('activationCodeDashboard'),
  connect(mapStateToProps, dispatch => ({
    setActCode: code => {
      dispatch(setCodeForDashboard({ code }));
    },
    setAllowedUsage: allowedUsage => {
      dispatch(setAllowedUsageForDashboard({ allowedUsage }));
    },
    setBatchName: batchName => {
      dispatch(setBatchNameForDashboard({ batchName }));
    },
    createActCode: () => {
      dispatch(createActivationCode());
    }
  }))
)(ActivationCodeCreateArea);
