import moment from 'moment';
import { pick, pickBy } from 'lodash';
import transformUser from '../../utils/transformUser';

export const GET_USER_DETAILS = 'userProfile/GET_USER_DETAILS';
const SET_USER_DETAILS = 'userProfile/SET_USER_DETAILS';
const USER_NOT_FOUND = 'userProfile/USER_NOT_FOUND';

export const SEND_INVITE_REMINDER = 'userProfile/SEND_INVITE_REMINDER';
const DISPLAY_MODAL = 'userProfile/DISPLAY_MODAL';
const HIDE_MODAL = 'userProfile/HIDE_MODAL';
const SEND_DONE = 'userProfile/SEND_DONE';
export const CANCEL_INVITATION = 'userProfile/CANCEL_INVITATION';
export const CANCEL_INVITATION_ERROR_WITH_MESSAGE = 'userProfile/CANCEL_INVITATION_ERROR_WITH_MESSAGE';
// Hub specific actions
export const HUB_CANCEL_INVITATION_PENDING = 'userProfile/HUB_CANCEL_INVITATION_PENDING';
export const HUB_CANCEL_INVITATION_SUCCESS = 'userProfile/HUB_CANCEL_INVITATION_SUCCESS';
export const HUB_CANCEL_INVITATION_ERROR = 'userProfile/HUB_CANCEL_INVITATION_ERROR';
export const HUB_RESET_CANCEL_INVITATION_FORM = 'userProfile/HUB_RESET_CANCEL_INVITATION_FORM';

export const componentStates = {
  FETCHING: 'FETCHING',
  PROFILE_COMPLETE: 'PROFILE_COMPLETE',
  DISPLAY_MODAL: 'DISPLAY_MODAL',
  USER_NOT_FOUND: 'USER_NOT_FOUND'
};

export const cancelInvitationStates = {
  REVIEWING: 'REVIEWING',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

const initialState = {
  componentState: componentStates.FETCHING,
  orgId: null,
  userId: null,
  email: null,
  firstName: null,
  lastName: null,
  role: null,
  invitationPending: null,
  invitationTimestamp: null,
  invitationExpiryTimestamp: null,
  reminderTimestamps: {},
  isSendingReminder: false,
  showDoneButton: false,
  userDetails: {},
  cancelInvitationState: cancelInvitationStates.REVIEWING,
  errorMessage: null,
  isSupervised: false
};

export default function userProfile(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_DETAILS:
      return {
        ...state,
        isSendingReminder: false,
        componentState: componentStates.FETCHING,
        orgId: action.orgId,
        userId: action.userId,
        userDetails: {}
      };
    case SET_USER_DETAILS: {
      return {
        ...state,
        componentState: componentStates.PROFILE_COMPLETE,
        ...pickBy(
          pick(action.userDetails, [
            'email',
            'firstName',
            'lastName',
            'yearGroup',
            'archived',
            'invitationPending',
            'invitationTimestamp',
            'invitationExpiryTimestamp',
            'isSupervised'
          ]),
          value => value !== undefined
        ),
        reminderTimestamps: {
          ...state.reminderTimestamps,
          [state.userId]:
            action.userDetails && action.userDetails.reminderTimestamp
              ? action.userDetails.reminderTimestamp
              : state.reminderTimestamps[state.userId]
        },
        username: action?.userDetails?.userName || state.username,
        role: action?.userDetails?.roleName || state.role,
        userDetails: { ...state.userDetails, ...action.managedUserDetails }
      };
    }
    case DISPLAY_MODAL:
      return {
        ...state,
        componentState: componentStates.DISPLAY_MODAL
      };
    case HIDE_MODAL:
      return {
        ...state,
        componentState: componentStates.PROFILE_COMPLETE
      };
    case USER_NOT_FOUND:
      return {
        ...state,
        componentState: componentStates.USER_NOT_FOUND
      };
    case SEND_INVITE_REMINDER:
      return {
        ...state,
        isSendingReminder: true,
        invitationPending: true,
        componentState: componentStates.PROFILE_COMPLETE,
        showDoneButton: true,
        reminderTimestamps: {
          ...state.reminderTimestamps,
          [state.userId]: moment().toJSON()
        }
      };
    case SEND_DONE:
      return {
        ...state,
        isSendingReminder: false,
        showDoneButton: false
      };
    case CANCEL_INVITATION_ERROR_WITH_MESSAGE:
      return {
        ...state,
        cancelInvitationState: cancelInvitationStates.ERROR,
        errorMessage: action.errorMessage,
        componentState: componentStates.PROFILE_COMPLETE,
        showDoneButton: true
      };
    case HUB_CANCEL_INVITATION_PENDING:
      return {
        ...state,
        cancelInvitationState: cancelInvitationStates.PENDING
      };
    case HUB_CANCEL_INVITATION_SUCCESS:
      return {
        ...state,
        cancelInvitationState: cancelInvitationStates.SUCCESS
      };
    case HUB_CANCEL_INVITATION_ERROR:
      return {
        ...state,
        cancelInvitationState: cancelInvitationStates.ERROR
      };
    case HUB_RESET_CANCEL_INVITATION_FORM:
      return {
        ...state,
        cancelInvitationState: cancelInvitationStates.REVIEWING
      };
    default:
      return state;
  }
}

export const getUserDetails = (orgId, userId) => ({
  type: GET_USER_DETAILS,
  orgId,
  userId
});

export const setUserDetails = (userDetails, managedUserDetails = {}) => ({
  type: SET_USER_DETAILS,
  userDetails: transformUser(userDetails),
  managedUserDetails
});

export const displayModal = () => ({
  type: DISPLAY_MODAL
});

export const hideModal = () => ({
  type: HIDE_MODAL
});

export const showUserNotFoundPage = () => ({
  type: USER_NOT_FOUND
});

export const sendReminder = history => ({
  type: SEND_INVITE_REMINDER,
  history
});

export const cancelInvitation = history => ({
  type: CANCEL_INVITATION,
  history
});

export const sendDone = () => ({
  type: SEND_DONE
});

export const cancelInvitationPending = () => ({
  type: HUB_CANCEL_INVITATION_PENDING
});

export const cancelInvitationSuccess = () => ({
  type: HUB_CANCEL_INVITATION_SUCCESS
});

export const cancelInvitationError = () => ({
  type: HUB_CANCEL_INVITATION_ERROR
});

export const cancelInvitationErrorWithMessage = message => ({
  type: CANCEL_INVITATION_ERROR_WITH_MESSAGE,
  errorMessage: message
});

export const resetCancelInvitationForm = () => ({
  type: HUB_RESET_CANCEL_INVITATION_FORM
});
