// Polyfill the MouseEvent() constructor for IE10 IE11
// Adapted from https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill

export default (function featureDetection() {
  try {
    // Feature-detect support for MouseEvent()
    // eslint-disable-next-line no-unused-vars
    const test = new MouseEvent('test');
    return MouseEvent;
  } catch (e) {
    // Polyfills DOM4 MouseEvent
    const MouseEventPolyfill = function Polyfill(eventType, params) {
      // eslint-disable-next-line no-param-reassign
      params = params || { bubbles: false, cancelable: false };
      const mouseEvent = document.createEvent('MouseEvent');
      mouseEvent.initMouseEvent(
        eventType,
        params.bubbles,
        params.cancelable,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      return mouseEvent;
    };
    MouseEventPolyfill.prototype = Event.prototype;
    return MouseEventPolyfill;
  }
})();
