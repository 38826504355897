import { call, put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes.js';
import {
  fetchLinkedProductsSuccess,
  fetchLinkedProductsFailure
} from '../../../../actions/getLinkedProductsActions.js';
import fetchLinkedProductsApi from '../../../apiCalls/ngi/fetchLinkedProductsApi.js';

function* fetchLinkedProducts(action) {
  const response = yield call(fetchLinkedProductsApi, action.payload);

  if (!response.items) {
    yield put(fetchLinkedProductsFailure('Failed to fetch linked products'));
  } else {
    yield put(fetchLinkedProductsSuccess(response.items));
  }
}

function* fetchLinkedProductsSaga() {
  yield takeLatest(t.NGI_FETCH_LINKED_PRODUCTS_REQUEST, fetchLinkedProducts);
}

export default fetchLinkedProductsSaga;
