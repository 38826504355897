import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { subIsCurriculumType } from '@oup/shared-node-browser/subscriptions';
import colors from '../../globals/colors';
import Button, { buttonTypes } from '../Button/Button';
import Card from '../Card/Card';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../language/withLocalizedContent';

export const operationTypes = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
};

function OrgSubscriptionSuccess({
  dateFormat = 'DD/MM/YYYY',
  localizedContent: { orgSubscriptionSuccess: localizedContent },
  operation,
  orgName,
  productName,
  subscriptionId,
  subscriptionType = 'orb',
  startDate,
  endDate,
  nextUrl,
  addAnotherLicence,
  backUrl
}) {
  const [isCurriculumType] = useState(subIsCurriculumType(subscriptionType));

  return (
    <div className="grid pad-bot4">
      <div className="row">
        <div className="col">
          <PopoutPanelIconHeading
            type={types.VALID}
            title={
              operation === operationTypes.CREATE ? localizedContent.created_title : localizedContent.updated_title
            }
          />
          <p className="lead text-center gin-bot3">
            {operation === operationTypes.CREATE
              ? localizedContent.created_subtitle
              : localizedContent.updated_subtitle}
          </p>
          <div className="gin-bot3">
            <Card headingText={localizedContent.licence_card_title} headingColor={colors.PROFILE}>
              <CopyToClipboard id="org-name-label" label={localizedContent.org_label} value={orgName} />
              <CopyToClipboard id="product-name-label" label={localizedContent.product_label} value={productName} />
              <CopyToClipboard id="licence-id-label" label={localizedContent.licence_id_label} value={subscriptionId} />
              <CopyToClipboard
                id="start-date-label"
                label={localizedContent.start_date_label}
                value={moment(startDate).format(dateFormat)}
              />
              <CopyToClipboard
                id="end-date-label"
                label={localizedContent.end_date_label}
                value={moment(endDate).format(dateFormat)}
              />
            </Card>
          </div>
          <div className="row cols-center">
            {isCurriculumType && (
              <Button
                customClassName="gin-right2"
                type={buttonTypes.PRIMARY}
                to={nextUrl}
                text={localizedContent.add_test_credits_button}
              />
            )}
            {addAnotherLicence && (
              <Button
                customClassName="gin-right2"
                type={buttonTypes.PRIMARY}
                text={localizedContent.add_another_licence_button}
                onClick={addAnotherLicence}
              />
            )}
            <Button
              id="secondary-button"
              type={buttonTypes.SECONDARY}
              text={localizedContent.back_button}
              to={backUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

OrgSubscriptionSuccess.propTypes = {
  dateFormat: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  operation: PropTypes.oneOf(Object.values(operationTypes)).isRequired,
  orgName: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  subscriptionType: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  nextUrl: PropTypes.string,
  addAnotherLicence: PropTypes.func,
  backUrl: PropTypes.string
};

export default withLocalizedContent('orgSubscriptionSuccess')(OrgSubscriptionSuccess);
