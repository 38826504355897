import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { setCmsContent } from '../../../../../utils/cmsContent.js';

const moduleName = '[[GetAssessmentContent saga]]';
export const StatusEnum = {
  Error: 'error',
  Success: 'Success'
};

function* getAmLocalizedContent() {
  const locale = 'en-x-assessment';
  // @ts-ignore
  const apiResults = yield signedFetch('GetLocalizedContent', `${__API_BASE__}/open/content?locale=${locale}`, 'GET');
  console.log(moduleName, 'Settings result:', apiResults);
  if (apiResults.status === 'error' || apiResults.status === 'fail') {
    yield put(actions.assessmentLocaliziedContentFailure({ status: StatusEnum.Error, apiResults }));
  } else {
    setCmsContent(apiResults.data);
    yield put(actions.assessmentLocaliziedContentSuccess({ status: StatusEnum.Success, data: apiResults.data }));
  }
}

export default getAmLocalizedContent;
