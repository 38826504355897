import { put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

export const markNotificationUrl = (userId, orgId, notificationId) =>
  `${__API_BASE__}/org/${orgId}/user/${userId}/orb/notifications/${notificationId}/mark-viewed`;

function* markNotification(userId, orgId, notificationId) {
  const response = yield signedFetch('markNotification', markNotificationUrl(userId, orgId, notificationId), 'POST');
  if (response.status === 'success') {
    yield put(actions.markNotificationAsReadSuccess(response.data));
    yield put(actions.getNotifications(userId, orgId));
  }
}

export default function* root() {
  yield takeLatest(t.ORB_GET_NOTIFICATIONS_MARK_AS_VIEWED, ({ payload: { userId, orgId, notificationId } }) =>
    markNotification(userId, orgId, notificationId)
  );
}
