import PropTypes from 'prop-types';
import React from 'react';
import ENTITY_TYPES from '../../globals/entityTypes';
import content from '../../utils/cmsContent';
import ConnectedClassRepresentation from '../ClassRepresentation/ConnectedClassRepresentation';
import ContentWithSVGIcon from '../ContentWithSVGIcon/ContentWithSVGIcon';
// Entity Renderers
import ConnectedPersonRepresentation from '../PersonRepresentation/ConnectedPersonRepresentation';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import styles from './PopoutConfirmationListItem.scss';

// const content = {
//   appComponents: {
//     archived_button: 'Archived',
//     edit_button: 'Edit',
//     search_button: 'Search',
//     filter_button: 'Filter',
//     sort_button: 'Sort',
//     help_and_support_button: 'Help & Support',
//     settings_button: 'Settings',
//     ally_subtract_1: 'Subtract 1',
//     ally_add_1: 'Add 1',
//     previous_page_text: 'Previous page',
//     aria_page_text: 'Page',
//     next_page_text: 'Next page',
//     password_error_text: ' Password must contain 1 uppercase, 1 lowercase and be between 6-15 characters',
//     added_to_class_success: 'Added to class',
//     added_to_class_warning: 'Not added to class',
//     removed_from_class_success: 'Removed from class',
//     removed_from_class_warning: 'Not removed from class',
//     error_loading_search_text: 'There was an error loading search results',
//     close_text: 'Close',
//     label_show_text: 'Show',
//     search_text: 'Search',
//     results_text: 'results',
//     spaces_avalible_text: 'spaces available',
//     selected_text: 'selected',
//     next_text: 'Next',
//     skip_text: 'Skip'
//   }
// };

const ComponentLookup = {
  PERSON: ConnectedPersonRepresentation,
  CLASS: ConnectedClassRepresentation
};

// Helper for deciding which status text to show:
const lookupStatusMessageFor = CMS => ({
  PERSON: {
    ADD: {
      SUCCESS: CMS.added_to_class_success,
      WARNING: CMS.added_to_class_warning
    },
    REMOVE: {
      SUCCESS: CMS.removed_from_class_success,
      WARNING: CMS.removed_from_class_warning
    }
  },
  // Not needed yet but included to avoid lookup error:
  CLASS: {
    ADD: {
      SUCCESS: undefined, // 'Added to organisation',
      WARNING: undefined // 'Not added to organisation'
    },
    REMOVE: {
      SUCCESS: undefined, // 'Removed from organisation',
      WARNING: undefined // 'Not removed from organisation'
    }
  }
});

function PopoutConfirmationListItem({ id, entityType, entityId, subtext, isWarning, isRemoving, orgId }) {
  const CMS = content.appComponents || {};
  const EntityTag = ComponentLookup[entityType];
  const actionType = isRemoving ? 'REMOVE' : 'ADD';
  const statusType = isWarning ? 'WARNING' : 'SUCCESS';
  const statusText = lookupStatusMessageFor(CMS)[entityType][actionType][statusType];

  return (
    <div id={id} className={styles.popoutConfirmationListItem}>
      <ContentWithSVGIcon glyph={isWarning ? GLYPHS.ICON_WARNING_CIRCLE : GLYPHS.ICON_CHECK_CIRCLE}>
        <EntityTag
          id={`${entityType.toLowerCase()}-${entityId}`}
          entityId={entityId}
          thumbnailSize={thumbnailSizes.MEDIUM}
          subtext={subtext}
          statusText={statusText}
          status={statusType}
          extraInformationIsWarning={isWarning}
          orgId={orgId}
        />
      </ContentWithSVGIcon>
    </div>
  );
}

PopoutConfirmationListItem.propTypes = {
  /** Html id for this component */
  id: PropTypes.string.isRequired,
  /** Whether to render PERSON or CLASS entity */
  entityType: PropTypes.oneOf(Object.keys(ENTITY_TYPES)).isRequired,
  /** The system id of the entity (so we can look up name or email etc) */
  entityId: PropTypes.string.isRequired,
  /** Optionally specify line 2 of the list item */
  subtext: PropTypes.string,
  /** This affects the style of the list item */
  isWarning: PropTypes.bool,
  /** This affects the status message of the list item */
  isRemoving: PropTypes.bool,
  orgId: PropTypes.string
};

export default PopoutConfirmationListItem;
