import { put } from 'redux-saga/effects';
import { loadOfflineProfiles } from '../../../../actions/offlineContentPlayer';

function* deleteOfflineProfiles(profileIds) {
  const storedValue = localStorage.getItem('offline_profiles');
  const profiles = storedValue ? JSON.parse(storedValue) : {};

  profileIds.forEach(id => {
    delete profiles[id];
  });

  localStorage.setItem('offline_profiles', JSON.stringify(profiles));
  yield put(loadOfflineProfiles(profiles));
}

export default deleteOfflineProfiles;
