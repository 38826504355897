import React from 'react';
import PropTypes from 'prop-types';
import CourseAssessmentFolder from '../CourseAssessmentFolder/CourseAssessmentFolder';
import { HubLayoutConstants } from '../../globals/hubConstants';
import styles from './OnlineTestAssessmentsList.scss';

function OnlineTestAssessmentsList({
  assessments = {},
  courses = {},
  expandedAssessmentId = '',
  setExpandedAssessment,
  setOnlineTestContent,
  hubContent,
  downloadAssessment,
  onSubfolderClick,
  selectTest
}) {
  const hasInteractiveFolders = assessmentTree => {
    let hasInterFolders = false;
    let subFoldersHaveInterFolders = false;
    let hasSubFolders = false;
    if (assessmentTree?.resources) {
      for (let j = 0; j < assessmentTree.resources.length; j += 1) {
        if (assessmentTree.resources[j].type === HubLayoutConstants.ASSESSMENT_TYPES.INTERACTIVE_FOLDER) {
          hasInterFolders = true;
          break;
        }
      }
    }

    const keys = assessmentTree ? Object.keys(assessmentTree) : [];
    for (let i = 0; i < keys.length; i += 1) {
      if (typeof assessmentTree[keys[i]] === 'object' && !assessmentTree[keys[i]]?.length) {
        hasSubFolders = true;
        subFoldersHaveInterFolders = subFoldersHaveInterFolders || hasInteractiveFolders(assessmentTree[keys[i]]);
      }
    }
    return hasSubFolders ? hasInterFolders || subFoldersHaveInterFolders : hasInterFolders;
  };
  return (
    <>
      <div className={styles.panelTitle}>{setOnlineTestContent.choose_test}</div>
      {Object.entries(assessments).map(
        ([id, assessment]) =>
          hasInteractiveFolders(assessment) && (
            <CourseAssessmentFolder
              id={id}
              key={id}
              title={assessment?.title}
              assessmentData={assessment}
              isExpanded={expandedAssessmentId === id}
              setExpandedAssessment={setExpandedAssessment}
              course={courses[assessment.courseId]}
              hubContent={hubContent}
              downloadAssessment={downloadAssessment}
              onSubfolderClick={onSubfolderClick}
              selectTest={selectTest}
            />
          )
      )}
    </>
  );
}

OnlineTestAssessmentsList.propTypes = {
  assessments: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
  expandedAssessmentId: PropTypes.string.isRequired,
  setExpandedAssessment: PropTypes.func.isRequired,
  setOnlineTestContent: PropTypes.object.isRequired,
  hubContent: PropTypes.object.isRequired,
  downloadAssessment: PropTypes.func.isRequired,
  onSubfolderClick: PropTypes.func.isRequired,
  selectTest: PropTypes.func.isRequired
};
export default OnlineTestAssessmentsList;
