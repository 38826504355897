import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import { toInitials } from '../../utils/string';
import ActionList, { Action } from '../ActionList/ActionList';
import EntityRepresentation from '../EntityRepresentation/EntityRepresentation';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import ContentWithSVGIcon from '../ContentWithSVGIcon/ContentWithSVGIcon';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './UserEnrolConfirmation.scss';

function UserEnrolConfirmation({
  localizedContent: { userEnrolConfirmationComponent: content },
  orgName,
  firstName,
  lastName,
  hasFailed = false,
  onDone,
  downloadSigninCard
}) {
  return (
    <ScrollContainer
      headerContent={
        hasFailed ? (
          <PopoutPanelIconHeading
            type={popoutPanelIconHeadingTypes.ERROR}
            title={content.error_title}
            subtitle={content.error_subtitle}
          />
        ) : (
          <PopoutPanelIconHeading
            type={popoutPanelIconHeadingTypes.VALID}
            title={`${content.success_title} ${orgName}`}
            subtitle={content.success_subtitle}
          />
        )
      }
      footerContent={
        <ActionList title={content.actions_title}>
          <Action label={content.create_sign_in_card_button} onClick={downloadSigninCard} disabled={hasFailed} />
          <Action label={content.done_button} primary onClick={onDone} />
        </ActionList>
      }
    >
      <div className={styles.enrol_entity}>
        <ContentWithSVGIcon glyph={GLYPHS.ICON_CHECK_CIRCLE}>
          <EntityRepresentation
            id="MyProfileEditForm"
            thumbnailSize={thumbnailSizes.MEDIUM}
            thumbnailColor={colors.LEARNER}
            thumbnailText={toInitials(firstName, lastName)}
            line1Text={`${firstName} ${lastName}`}
            line2Text={`${hasFailed ? content.enrol_error : content.enrol_success} ${orgName}`}
          />
        </ContentWithSVGIcon>
      </div>
    </ScrollContainer>
  );
}

UserEnrolConfirmation.propTypes = {
  localizedContent: PropTypes.object,
  orgName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  hasFailed: PropTypes.bool,
  onDone: PropTypes.func.isRequired,
  downloadSigninCard: PropTypes.func.isRequired
};

export default withLocalizedContent('userEnrolConfirmationComponent')(UserEnrolConfirmation);
