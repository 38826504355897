import { put } from 'redux-saga/effects';

import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

function* generateOrgUniqueId() {
  const response = yield signedFetch('generateOrgUniqueId', `${__API_BASE__}/org/generate-unique-id`, 'POST', {});
  if (response.status === 'success') {
    yield put(actions.generateOrgUniqueIdSuccess(response.data.orgUniqueId));
    return;
  }
  yield put(actions.generateOrgUniqueIdFailure(response.errorMessages));
}

export default generateOrgUniqueId;
