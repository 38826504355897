import { JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../util/signedFetch.js';

export default body => {
  const URL = `${__API_BASE__}/getClassAssessments`;

  const headers = { 'Content-Type': JSON_ENCODED, Accept: JSON_ENCODED };

  return signedFetch('getClassAssessments', URL, 'POST', body, headers);
};
