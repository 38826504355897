import env from '../globals/envSettings';

const setLanguage = language => {
  localStorage.setItem('language', language);
};

const getLanguage = () => {
  const defaultLanguage = 'en-x-assessment';
  const storedLanguage = localStorage.getItem('language') || defaultLanguage;

  // Checks if there is a supported language that matches the user's preferred browser language, implemented for the RVS platform.
  const supportedLanguage = env.integrations.rvs.languages.find(
    language => language.code.split('-')[0] === storedLanguage.split('-')[0]
  );

  if (!supportedLanguage) {
    setLanguage(defaultLanguage);
    return defaultLanguage;
  }

  return supportedLanguage.code;
};

export { getLanguage, setLanguage };
