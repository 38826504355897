import React from 'react';
import PropTypes from 'prop-types';
import styles from '../css/ProductCreationPage.scss';
import withLocalizedContent from '../../../../../language/withLocalizedContent';

function ToggleTabs({ selectedTab, setTab, localizedContent: { productCreate: content } }) {
  return (
    <div className={styles.licenseBatchSelector}>
      <ul>
        <li>
          <button
            onClick={() => {
              setTab('definition');
            }}
            className={`${styles.tabButton} ${selectedTab === 'definition' ? styles.active : ''} `}
            type="button"
          >
            {content.definition_button}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setTab('registration');
            }}
            className={`${styles.tabButton}   ${selectedTab === 'registration' ? styles.active : ''} `}
            type="button"
          >
            {content.registration_button}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setTab('associations');
            }}
            className={`${styles.tabButton}   ${selectedTab === 'associations' ? styles.active : ''} `}
            type="button"
          >
            {content.associations_button}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setTab('external');
            }}
            className={`${styles.tabButton}   ${selectedTab === 'external' ? styles.active : ''} `}
            type="button"
          >
            {content.external_button}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setTab('urls');
            }}
            className={`${styles.tabButton}   ${selectedTab === 'urls' ? styles.active : ''} `}
            type="button"
          >
            {content.url_button}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setTab('platforms');
            }}
            className={`${styles.tabButton}   ${selectedTab === 'platforms' ? styles.active : ''} `}
            type="button"
          >
            {content.platforms_button}
          </button>
        </li>
      </ul>
    </div>
  );
}

ToggleTabs.propTypes = {
  selectedTab: PropTypes.string,
  setTab: PropTypes.func,
  localizedContent: PropTypes.object
};
export default withLocalizedContent('productCreate')(ToggleTabs);
