import React from 'react';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import cmsContent from '../../../utils/cmsContent.js';

function AssignLearningMaterialSubmitting() {
  const CMS = cmsContent.assignLearningMaterialPanel || {};
  return (
    <PopoutPanelIconHeading type={types.LOADING} title={CMS.loading_page_title} subtitle={CMS.loading_page_subtitle} />
  );
}

export default AssignLearningMaterialSubmitting;
