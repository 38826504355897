import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { ErrorStatus, PageWrapper } from '../../../components';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import GenerateReport from './GenerateReport';
import CsvProductStructureUploadSuccess from '../../../components/CsvProductStructureUpload/CsvProductStructureUploadSuccess';
import { reset } from '../../../redux/reducers/generateReport.reducer';

class GenerateReportPage extends Component {
  componentWillUnmount() {
    const { redirect } = this.props;
    redirect('/dashboard');
  }

  render() {
    const {
      inProgress,
      loading,
      errorOccurred,
      success,
      localizedContent: { generateReportPage: content },
      redirect
    } = this.props;

    return (
      <PageWrapper>
        <Helmet title={content.title} />
        {inProgress ? <GenerateReport content={content} /> : null}
        {loading ? <PopoutPanelIconHeading type={types.LOADING} title={content.waitingMessage} /> : null}
        {errorOccurred ? (
          <ErrorStatus
            title={content.errorMessage}
            buttonText={content.backButton}
            buttonOnClickHandler={() => redirect('/dashboard')}
          />
        ) : null}
        {success ? (
          <CsvProductStructureUploadSuccess content={content} goBackToDashboard={() => redirect('/dashboard')} />
        ) : null}
      </PageWrapper>
    );
  }
}

GenerateReportPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errorOccurred: PropTypes.bool,
  inProgress: PropTypes.bool,
  success: PropTypes.bool
};

export default compose(
  withRouter,
  withLocalizedContent('generateReportPage'),
  connect(
    state => ({
      inProgress: state.generateReport.inProgress,
      loading: state.generateReport.loading,
      errorOccurred: state.generateReport.errorOccurred,
      errorMessage: state.generateReport.errorMessage,
      success: state.generateReport.success
    }),
    (dispatch, props) => ({
      redirect: path => {
        dispatch(reset());
        props.history.push(path);
      }
    })
  )
)(GenerateReportPage);
