/* eslint-disable import/prefer-default-export */

/**
 * The format to use for shorthand dates.
 *
 * @type {string}
 */
import moment from 'moment';

export const DATE_SHORT = 'DD/MM/YY';

export const DATE_LONG = 'DD/MM/YYYY';

export const ISO_DATE = 'YYYY-MM-DD';

export const HOURREGEX = /^(([0-1]{0,1})[0-9]|2[0-3]):[0-5][0-9]$/;
export const ALLOWEDHOURREGEX = /^[0-9]{0,2}:{0,1}[0-9]{0,2}$/;

export const dateFormat = value => {
  const valueDateFormat = ISO_DATE;
  const result = moment(value).format(valueDateFormat);
  return result;
};

// function that extracts HH in number format from a 'HH:MM' string
export const getHour = value => Number(value.match(/(\d+)/g)[0]);
// function that extracts MM in number format from a 'HH:MM' string
export const getMinutes = value => Number(value.match(/(\d+)/g)[1]);

export const getRoundHour = value => {
  // given a string with the format of 'HH:MM' or 'H:MM',
  // will return a time in which the minutes are aproximated to a multiple of 5
  let hour = getHour(value);
  let minutes = getMinutes(value);
  minutes = Math.round(minutes / 5) * 5;
  let finalMinutes = minutes.toString();
  let finalHour;
  if (minutes === 0 || minutes === 60) {
    if (minutes === 60) {
      if (hour === 23) {
        hour = 0;
      } else {
        hour += 1;
      }
    }
    finalMinutes = '00';
  } else if (minutes < 10) {
    finalMinutes = ['0', minutes].join('');
  }
  finalHour = hour.toString();
  if (hour < 10) {
    finalHour = ['0', hour].join('');
  }
  return [finalHour, ':', finalMinutes].join('');
};
// given a date in format Date and an hour in 'HH:MM' format, it will set that
// date's hour and minutes to HH and MM
export const setHourOnDate = (date, hour) => {
  const numberHour = getHour(hour);
  const numberMinutes = getMinutes(hour);
  date.setHours(numberHour);
  date.setMinutes(numberMinutes);
  return date;
};

// given a date, it extracts the hour and the minutes and return a 'HH:MM' string
export const getStringHour = date => {
  const hour = date.getHours();
  const minutes = date.getMinutes();
  let finalMinutes = minutes.toString();
  let finalHour;
  if (minutes === 0) {
    finalMinutes = '00';
  } else if (minutes < 10) {
    finalMinutes = ['0', minutes].join('');
  }
  finalHour = hour.toString();
  if (hour < 10) {
    finalHour = ['0', hour].join('');
  }
  return [finalHour, ':', finalMinutes].join('');
};

// given a date, it returns a "month day, year" format
// example: 'February 15, 2022'
export const getDayMonthYearFormat = date => {
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};
/**
 * The placeholder to use when the displayed user date is hidden.
 *
 * @type {string}
 */
export const DATE_PLACEHOLDER = DATE_SHORT.replace(/\w/g, '-');
