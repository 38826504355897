import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formStates, resetForm, showLibraryForm } from '../../../../../../redux/reducers/addToLibrary.reducer';
import AddToLibraryInputting from './AddToLibraryInputting';
import AddToLibrarySubmitting from './AddToLibrarySubmitting';
import AddToLibraryErrorReviewing from './AddToLibraryErrorReviewing';
import AddToLibraryConfirmation from './AddToLibraryConfirmation';
import AddToLibraryBulkInputting from './AddToLibraryBulkInputting';
import AddToLibraryBulkSubmitting from './AddToLibraryBulkSubmitting';
import AddToLibraryBulkConfirmation from './AddToLibraryBulkConfirmation';

class AddToLibrary extends React.Component {
  handleClose = () => {
    const { resetAction, closePanel } = this.props;

    resetAction();
    closePanel();
  };

  handleOnComplete = () => {
    const { resetAction, onComplete } = this.props;

    resetAction();
    onComplete();
  };

  renderAddLicencesPanel = () => {
    const { resetAction, showLibraryFormAction } = this.props;

    resetAction();
    showLibraryFormAction();
  };

  render() {
    const {
      productData,
      productFetchError,
      productDetailsLoading,
      errorOccurred,
      errorMessage,
      accessCodeFormatted,
      email,
      closePanel,
      formState,
      orgName
    } = this.props;

    let content;

    switch (formState) {
      case formStates.INPUTTING:
        content = <AddToLibraryInputting closePanel={closePanel} orgName={orgName} />;
        break;
      case formStates.SUBMITTING:
        content = <AddToLibrarySubmitting />;
        break;
      case formStates.CONFIRMATION:
        content = (
          <AddToLibraryConfirmation
            productData={productData}
            productFetchError={productFetchError}
            productDetailsLoading={productDetailsLoading}
            errorOccurred={errorOccurred}
            errorMessage={errorMessage}
            accessCodeFormatted={accessCodeFormatted}
            closePanel={this.handleClose}
            onComplete={this.handleOnComplete}
            renderAddLicencesPanel={this.renderAddLicencesPanel}
          />
        );
        break;

      case formStates.ERROR_REVIEWING:
        content = <AddToLibraryErrorReviewing closePanel={this.handleClose} />;
        break;

      case formStates.BULK_INPUTTING:
        content = <AddToLibraryBulkInputting closePanel={this.handleClose} orgName={orgName} />;
        break;
      case formStates.BULK_SUBMITTING:
        content = <AddToLibraryBulkSubmitting />;
        break;
      case formStates.BULK_CONFIRMATION:
        content = (
          <AddToLibraryBulkConfirmation
            orgName={orgName}
            email={email}
            closePanel={this.handleClose}
            onComplete={this.handleOnComplete}
            renderAddLicencesPanel={this.renderAddLicencesPanel}
          />
        );
        break;
      default:
        content = (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }

    return <div>{content}</div>;
  }
}

AddToLibrary.propTypes = {
  orgName: PropTypes.string.isRequired,
  formState: PropTypes.string.isRequired,
  email: PropTypes.string,
  productData: PropTypes.object,
  productFetchError: PropTypes.bool,
  productDetailsLoading: PropTypes.bool,
  errorOccurred: PropTypes.bool,
  errorMessage: PropTypes.string,
  accessCodeFormatted: PropTypes.string.isRequired,
  resetAction: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  showLibraryFormAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    formState: state.addToLibrary.formState,
    email: state.identity.email,
    errorOccurred: state.addToLibrary.errorOccurred,
    errorMessage: state.addToLibrary.errorMessage,
    accessCodeFormatted: state.addToLibrary.accessCodeValue,
    productData: state.addToLibrary.productData,
    productFetchError: state.addToLibrary.productFetchError,
    productDetailsLoading: state.addToLibrary.productDetailsLoading,
    instance: state.library.instance
  }),
  {
    resetAction: resetForm,
    showLibraryFormAction: showLibraryForm
  }
)(AddToLibrary);
