import { put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

import submit from './submit';

import constants from '../../../../../components/RvsForm/constants';
import validateFormField from './validateFormField';
import mapReduxAction from './utils/mapReduxAction';

function* validate(input) {
  const { ERROR_TYPE } = constants;
  const [field, compareToField] = Object.entries(input);
  const [fieldName, fieldValue] = field;
  const [, compareToFieldValue] = compareToField || [];

  if (compareToFieldValue) {
    // This case will only happen for testTakerNumber and certificateReferenceNumber
    const CRN = 'certificateReferenceNumber';
    const error = fieldValue !== compareToFieldValue ? ERROR_TYPE.INVALID_CRN : null;

    const action = mapReduxAction(CRN, error);
    yield put(action);

    if (error && fieldName === CRN) {
      return action;
    }
  }

  const validationError = validateFormField(fieldName, fieldValue);
  const action = mapReduxAction(fieldName, validationError);
  yield put(action);
  return action;
}

// eslint-disable-next-line func-names
export default function*() {
  yield takeLatest(t.RVS_FORM_SUBMIT, ({ payload }) => submit(payload));
  yield takeLatest(t.RVS_FORM_VALIDATE_INPUT, ({ payload }) => validate(payload));
}
