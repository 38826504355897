import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import searchActivity from '../../../api/searchActivity';

export default function* getActivity(body) {
  const response = yield searchActivity(body);
  if (response.status.toLowerCase() !== 'success') {
    yield put(actions.getActivityFailure());
    return;
  }
  yield put(actions.getActivitySuccess(response.data));
}
