import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import searchProductGroupsApi from '../../../apiCalls/searchProductGroup';

function* searchProductGroups(action) {
  const apiResults = yield searchProductGroupsApi(action.data.id, action.data.filter);
  if (apiResults.status === 'error') {
    yield put(actions.setLookUpErrors(apiResults.error));
  } else {
    yield put(actions.setLookUpdata(apiResults.data));
  }
}

export default searchProductGroups;
