import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../Button/Button';
import Card from '../Card/Card';
import ControlledForm from '../ControlledForm/ControlledForm';
import Dropdown from '../Dropdown/Dropdown';
import { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import { PopoutPanelIconHeading } from '..';

function DeferEmailsForm({
  localizedContent: { deferEmailsForm: content },
  deferredEmailsOrgs = [],
  orgId = '',
  userRole = '',
  changeOrgHandler = () => {},
  releaseDeferredEmails = () => {},
  changeUserRole = () => {},
  submittingDeferredEmails
}) {
  return (
    <ControlledForm>
      <div className="col md8">
        <div className="gin-bot3">
          <Card headingText={content.deferred_emails_title} headingColor={colors.PROFILE}>
            <div className="row">
              <div className="col">
                <Dropdown
                  id="emailToolsPage-chooseUserRole"
                  name="emailToolsPage-chooseUserRole"
                  label={content.chooseUserRole_heading}
                  value={userRole}
                  options={[
                    { value: '', text: content.userRole_default_option },
                    { value: 'learner', text: content.userRole_learner },
                    { value: 'staff', text: content.userRole_staff },
                    { value: 'primary-orgadmin', text: content.userRole_primary_orgadmin }
                  ]}
                  onChange={value => changeUserRole(value)}
                />
              </div>
            </div>
            <div>
              {(() => {
                switch (true) {
                  case submittingDeferredEmails:
                    return <PopoutPanelIconHeading type={types.LOADING} title={content.loading} />;
                  default:
                    return (
                      <Dropdown
                        id="emailToolsPage-chooseOrg"
                        name="emailToolsPage-chooseOrg"
                        label={content.chooseOrg_heading}
                        value={orgId}
                        options={[
                          { value: '', text: content.org_default_option },
                          ...deferredEmailsOrgs.map(org => ({
                            value: Object.keys(org)[0],
                            text: `${org[Object.keys(org)[0]].name}: ${org[Object.keys(org)[0]].deferrals} emails`
                          }))
                        ]}
                        onChange={changeOrgHandler}
                      />
                    );
                }
              })()}
            </div>
            <div className="pad-top1">
              <Button
                type={buttonTypes.PRIMARY}
                text={content.send_emails_button}
                onClick={() => {
                  releaseDeferredEmails(userRole);
                }}
                disabled={!orgId}
              />
            </div>
          </Card>
        </div>
      </div>
    </ControlledForm>
  );
}

DeferEmailsForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  deferredEmailsOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      deferrals: PropTypes.number,
      name: PropTypes.string
    })
  ),
  orgId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  changeUserRole: PropTypes.func.isRequired,
  changeOrgHandler: PropTypes.func.isRequired,
  releaseDeferredEmails: PropTypes.func.isRequired,
  submittingDeferredEmails: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('deferEmailsForm'),
  connect(state => ({
    submittingDeferredEmails: state.deferredEmails.submitting
  }))
)(DeferEmailsForm);
