import { getUsernameFromDummyEmail } from '@oup/shared-node-browser/supervisedUser';

export default function transformUser(user) {
  const userName = getUsernameFromDummyEmail(user.email ?? user.userName);
  const isSupervised = userName !== null;

  return {
    ...user,
    isSupervised,
    email: isSupervised ? userName : user.email,
    // We need to cover both versions, as some APIs
    // return the lowercase version. When one it's
    // undefined it won't be written to the store.
    userName: isSupervised ? userName : user.userName,
    username: isSupervised ? userName : user.username
  };
}
