import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';

import CoursePublish from './CoursePublish';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function CoursePublishPage({ localizedContent: { coursePublishPage: content } }) {
  return (
    <PageWrapper>
      <Helmet title={content.helmet_title} />
      <CoursePublish />
    </PageWrapper>
  );
}

CoursePublishPage.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('coursePublishPage'))(CoursePublishPage);
