import { put } from 'redux-saga/effects';
import { setOnlineTestSuccess, setOnlineTestFailure } from '../../../../actions/onlineTestActions';
import submitTaskAssignmentsFetch from '../../../api/submitTaskAssignments';
import { getCurrentPlatform } from '../../../../../utils/platform';

function* setOnlineTest(payload) {
  const platformCode = getCurrentPlatform().toUpperCase() || '';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localStartDate = new Date(payload.startDate);
  const localEndDate = new Date(payload.endDate);
  const utcStartDate = localStartDate.toUTCString();
  const utcEndDate = localEndDate.toUTCString();

  const body = {
    action: payload.action,
    authorId: payload.authorId,
    selectedStudentIds: payload.selectedStudentIds,
    testId: payload.testId,
    orgId: payload.orgId,
    id: payload.id,
    // Send the UTC values for testStartDate and testEndDate
    testStartDate: utcStartDate,
    testEndDate: utcEndDate,
    classId: payload.classId,
    sendEmailToStudents: payload.sendEmailToStudents,
    title: payload.testTitle,
    assessmentTitle: payload.productAssessmentTitle,
    timeZone,
    platformCode,
    printableResourceId: payload.printableResourceId,
    digitalResourceId: payload.digitalResourceId,
    testDuration: payload.testDuration,
    isAdaptedTest: payload.isAdaptedTest
  };
  const response = yield submitTaskAssignmentsFetch(body);

  if (response && response.status === 'success') {
    yield put(setOnlineTestSuccess());
  } else {
    yield put(setOnlineTestFailure());
  }
}

export default setOnlineTest;
