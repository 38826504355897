import { vstRedeemCodeRole, isRoleInvalidForUser } from '../../redux/reducers/activationCode/activationCodeUtil';

export const getMessageAttributes = (
  value,
  isError,
  incorrectFormat,
  incorrectMaxLength,
  otherErrorMessage,
  incorrectErrorMessage,
  isTeacherCodeErrorMessage,
  isStudentRoleErrorMessage,
  studentRoleMismatch,
  teacherRoleMismatch,
  isInvalidCodeError
) => ({
  value,
  isError,
  incorrectFormat,
  incorrectMaxLength,
  otherErrorMessage,
  incorrectErrorMessage,
  isTeacherCodeErrorMessage,
  isStudentRoleErrorMessage,
  studentRoleMismatch,
  teacherRoleMismatch,
  isInvalidCodeError
});

export const getLabelMessage = (
  {
    value,
    isError,
    incorrectFormat,
    incorrectMaxLength,
    otherErrorMessage,
    isTeacherCodeErrorMessage,
    isStudentRoleErrorMessage,
    studentRoleMismatch,
    teacherRoleMismatch,
    isInvalidCodeError
  },
  roleBasedOnIdentity,
  roleBasedOnCode,
  isVstCodeFlagEnabled
) => {
  const includesLetters = !/^[^a-zA-Z]*$/.test(value);
  const includesLettersAfterFirst = !/^[^a-zA-Z]*$/.test(value.slice(1));
  const includesNonVstLetters = !/^[^b-ru-zB-RU-Z]*$/.test(value);
  const includesSpecialCharacters = /[~`!#$%@^&*+=[\]\\';,_./{}|\\":<>?]/.test(value);

  if (
    (!isVstCodeFlagEnabled && includesLetters && isError) ||
    (isVstCodeFlagEnabled && includesNonVstLetters && isError) ||
    (isVstCodeFlagEnabled && includesLettersAfterFirst && isError) ||
    (isVstCodeFlagEnabled && includesSpecialCharacters && isError) ||
    roleBasedOnCode === vstRedeemCodeRole.ADMIN.name
  ) {
    return incorrectFormat;
  }

  if (
    isError &&
    roleBasedOnIdentity &&
    roleBasedOnIdentity !== roleBasedOnCode &&
    roleBasedOnCode !== vstRedeemCodeRole.ADMIN.name
  ) {
    const isRoleInvalid = isRoleInvalidForUser(
      roleBasedOnCode,
      roleBasedOnIdentity,
      studentRoleMismatch,
      teacherRoleMismatch
    );
    if (isRoleInvalid) {
      return isRoleInvalid;
    }
  }

  if (
    (!isVstCodeFlagEnabled && !includesLetters && isError) ||
    (isVstCodeFlagEnabled && !includesNonVstLetters && isError)
  ) {
    return incorrectMaxLength;
  }

  if (roleBasedOnCode && !isError && !isInvalidCodeError) {
    switch (roleBasedOnCode) {
      case vstRedeemCodeRole.TEACHER.name:
        return isTeacherCodeErrorMessage;
      case vstRedeemCodeRole.STUDENT.name:
        return isStudentRoleErrorMessage;
      default:
        return '';
    }
  }
  return otherErrorMessage;
};
