import React from 'react';
import PropTypes from 'prop-types';
import TableAccordion from '../TableAccordion/TableAccordion';
import tableStyles from '../TableAccordion/TableAccordion.scss';
import styles from './LearnerProgressTable.scss';
import { toInitials } from '../../utils/string';
import formatLastAccessed from '../../utils/date/formatLastAccessed';
import { featureIsEnabled } from '../../globals/envSettings';

function LearnerProgressTable({
  columns,
  rows,
  user,
  tableSpacing,
  ownProfile,
  expandedRows,
  learnerEmail,
  myProgress
}) {
  const expandedRowData = [...expandedRows];
  if (expandedRows.length !== rows.length) {
    for (let index = 0; index < Math.abs(rows.length - expandedRows.length); index += 1) {
      expandedRowData.unshift(false);
    }
  }
  return (
    <div>
      {!ownProfile ? (
        <div className={styles.userName}>
          {user ? (
            <div>
              {!myProgress && (
                <div className="row">
                  <div className={styles.flexTitle}>
                    <div className={styles.backgroundCenter}>
                      <h2>{toInitials(user.firstname, user.lastname)}</h2>
                    </div>
                    <div className={`col ${styles.titleFormat}`}>
                      <dt className={styles.linkFormat}>
                        {user.firstname} {user.lastname}
                      </dt>
                      <dd className={styles.subtextFormat}>{learnerEmail || ''}</dd>
                    </div>
                  </div>
                </div>
              )}
              <dd className={styles.accessFormat}>
                {featureIsEnabled('replacing-last-accessed-with-last-opened') && user.lastOpened ? (
                  <div>
                    Last opened <em className={styles.blackText}>{formatLastAccessed(user.lastOpened)}</em>
                  </div>
                ) : (
                  <div>
                    Last accessed <em className={styles.blackText}>{formatLastAccessed(user.lastAccessed)}</em>
                  </div>
                )}
              </dd>
            </div>
          ) : null}
        </div>
      ) : null}

      <TableAccordion
        columns={columns}
        rows={rows}
        customClass={tableSpacing && tableStyles[`tableAccordion--${tableSpacing}Spacing`]}
        expandedRows={expandedRowData}
      />
    </div>
  );
}

LearnerProgressTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  tableSpacing: PropTypes.string,
  expandedRows: PropTypes.array.isRequired,
  ownProfile: PropTypes.bool,
  learnerEmail: PropTypes.string,
  myProgress: PropTypes.bool
};

LearnerProgressTable.defaultProps = {
  ownProfile: false
};

export default LearnerProgressTable;
