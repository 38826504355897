import { parse as parseQueryString } from 'query-string';
import { put, select } from 'redux-saga/effects';
import { get } from 'lodash';
import getStoredCredentials from './getCredentials/getStoredCredentials.saga.js';
import getNewCredentials from './getCredentials/getNewCredentials.saga.js';
import getLinkAccountsStatus from './getLinkAccountsStatus.saga.js';
// TODO: check if this is real
// eslint-disable-next-line import/no-cycle
import { setRefreshTimeout, handleOidcSession } from './authRefreshHandler.saga.js';
import { setAuthCredentials } from '../../../reducers/identity.reducer.js';
import { appError } from '../../../reducers/app.reducer.js';
import { isEmbeddedInIframe, updateProductMetadataFromQuery } from '../../../../utils/platform.js';
import { featureIsEnabled } from '../../../../globals/envSettings';
import { extractQuery, queryParams } from '../../../../services/extractQuery';
import { completeLogout } from '../../appFunctions/authorisedFunctions/auth/handleLogout.saga.js';

export default function* getCredentials() {
  console.log('[getCredentials] Starting');

  // Allow the JWT to override stored credentials
  const authString = window.location.search.split('?')[2] || window.location.search;
  const parsedQuery = parseQueryString(authString);
  const gotoPath = parsedQuery.goto;
  const autoRefresh = !isEmbeddedInIframe() || (isEmbeddedInIframe() && parsedQuery.autoRefresh === 'true');

  let { jwt } = parsedQuery;
  const { code } = parsedQuery;
  const isOIDCFlow = !!code;

  // Check if the secondary account (to be linked) creds exist.
  // This is needed for Safari ("non-standalone" mode), where the Auth0 popup login
  // doesn't work because of the Intelligent Tracking Prevention (ITP) mechanism.
  const linkAccountsStatus = yield getLinkAccountsStatus(isOIDCFlow);

  if (linkAccountsStatus !== null) {
    // the user should be redirected to the link accounts page already
    return false;
  }

  if (isOIDCFlow) {
    // Actively prevent OIDC and impersonation / LTI Launch Tokens co-existing
    // OIDC flow wins and clears either of the others
    localStorage.removeItem('lti-token');
    localStorage.removeItem('impersonation-token');
    localStorage.removeItem('masquerade-user-id');
  }

  if (!isOIDCFlow) {
    // Verify JWT isn't a repeat load
    const lastUsedJwt = localStorage.getItem('ces-jwt');
    if (jwt === lastUsedJwt) {
      console.log('[getCredentials] Duplicate jwt load detected. Dropping JWT in favour of already stored credentails');
      jwt = null;
    }
  }

  const { state: auth0State, offlineAppCustomFlow } = parsedQuery;

  if (auth0State && offlineAppCustomFlow) {
    sessionStorage.setItem('offlineAppInitialState', auth0State);
  }

  let credentials = yield getStoredCredentials();
  const oidcReturnToPath = sessionStorage.getItem('oidcReturnToPath');
  sessionStorage.setItem('embedded-by-url', parsedQuery.embedded === 'true');

  if (isOIDCFlow && oidcReturnToPath) {
    sessionStorage.removeItem('oidcReturnToPath');
    sessionStorage.setItem('goto-path', oidcReturnToPath);
  } else if (gotoPath) {
    sessionStorage.setItem('goto-path', gotoPath);
  }

  // Convert x-product-... options to object and store it to localStorage
  updateProductMetadataFromQuery(parsedQuery);

  if (isEmbeddedInIframe()) {
    return !!credentials;
  }

  const checkForCredentials = isOIDCFlow || jwt || !credentials || credentials.doOrgSwitch;

  // We might need to bypass auth if no credentials are found and the use is accessing a public page
  let bypassAuth = false;
  let receivedNewCreds = false;
  if (checkForCredentials) {
    if (!credentials) {
      console.log('[getCredentials] No credentials were found in localStorage. Attempting to get new credentials');
    } else {
      console.log(
        '[getCredentials] OrgSwitch detected. Attempting to get new credentials for org',
        credentials.currentOrganisationId,
        credentials.currentRole
      );
    }
    const credentialResults = yield getNewCredentials(isOIDCFlow ? code : jwt, credentials, isOIDCFlow);

    bypassAuth = credentialResults.bypassAuth;
    credentials = credentialResults.credentials;
    receivedNewCreds = true;
  }

  if (credentials) {
    if (!isOIDCFlow && !credentials.oidcExpiresIn) {
      console.log('[getCredentials] Credentials ready. Storing in state');
      const returnTo = yield select(state => get(state, ['routing', 'locationBeforeTransitions', 'query', 'returnTo']));
      const redirectoToOrg = returnTo && get(extractQuery(queryParams.ORG_ID_QUERY, returnTo), 1);
      yield put(
        setAuthCredentials(
          credentials.accessKeyId,
          credentials.secretAccessKey,
          credentials.sessionToken,
          credentials.expiryTime,
          credentials.rightSuiteToken,
          credentials.email,
          credentials.userId,
          credentials.currentRole,
          credentials.orgRoleMap,
          redirectoToOrg || credentials.currentOrganisationId,
          credentials.registrationStatus
        )
      );

      if (featureIsEnabled(`nr-enabled`) && featureIsEnabled(`nr-custom-attributes`)) {
        try {
          window.newrelic.setCustomAttribute(`cesOrgId`, credentials.currentOrganisationId);

          if (featureIsEnabled(`nr-custom-attributes-pii`)) {
            window.newrelic.setCustomAttribute(`cesRole`, credentials.currentRole);
            window.newrelic.setCustomAttribute(`cesUserId`, credentials.userId);
          }
        } catch (error) {
          console.log(`failed to set newrelic.setCustomAttribute - ${error}`);
        }
      }

      // Init the refresh handler
      if (autoRefresh) {
        setRefreshTimeout(credentials.expiryTime);
      }
    } else {
      // init oidc session handler
      const handleSessionResult = yield handleOidcSession(receivedNewCreds);
      if (!handleSessionResult) {
        credentials = undefined;
        yield completeLogout();
      }
    }
  } else if (bypassAuth) {
    console.log('[getCredentials] Credentials could not be loaded but the user is accessing a public page');
  } else {
    console.log('[getCredentials] Credentials could not be loaded');
    yield put(appError('---- [getCredentials] Credentials could not be loaded'));
  }

  return !!credentials;
}
