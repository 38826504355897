const sanitizeContent = content => content.replace('’', '`');

function triggerDownload({ url, fileName }) {
  const element = document.createElement('a');
  element.href = url;
  element.download = fileName || 'download';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

function downloadContentAsFile({ content, fileName, type = 'application/octet-stream', isBase64 = false }) {
  const data = isBase64 ? Uint8Array.from(atob(content), c => c.charCodeAt(0)) : sanitizeContent(content);
  const blob = new Blob([data], { type });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, fileName); // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
  } else {
    const url = URL.createObjectURL(blob);
    triggerDownload({ url, fileName });
    URL.revokeObjectURL(url);
  }
}

function downloadUrlAsFile({ url, fileName }) {
  triggerDownload({ url, fileName });
}

export default function downloadAsFile(options) {
  const { url, content } = options;

  if (url) {
    downloadUrlAsFile(options);
  } else if (content) {
    downloadContentAsFile(options);
  } else {
    console.error('Either "url" or "content" must be provided to download a file.');
  }
}
