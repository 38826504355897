import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import ROLES, { userRoleFriendlyNames } from '../../globals/userRoles';
import { DropdownOption as DropdownOptionPropType } from '../../types';
import content from '../../utils/cmsContent';
import prefixKeys from '../../utils/object/prefixKeys';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './DropdownLink.scss';

function findTextByValue(options, valueToFind) {
  const value = options.find(option => option.value === valueToFind);
  return value ? value.text : 'Not found'; // Return the text or a default value if not found
}

function DropdownLink({ id, name, options, value, onChange, onBlur, aria }) {
  // If aria attributes were supplied, ensure they all have a prefix of "aria-":
  const ariaAttrs = prefixKeys(aria, 'aria-');
  const [isDropdownOpen, setDropDownOpen] = useState(false);
  const dropdownContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const ulRef = useRef(null); // Add a ref to the <ul> element
  const label = findTextByValue(options, value);

  const handleOptionClick = v => {
    onChange(v);
    setDropDownOpen(false); // Close the dropdown after selecting an option
  };

  const toggleDropdown = () => {
    setDropDownOpen(!isDropdownOpen);
    if (isDropdownOpen) {
      // If the dropdown is open, set the focus on the selected <li>
      const selectedLi = ulRef.current.querySelector(`[value="${value}"]`);
      if (selectedLi) {
        selectedLi.focus();
      }
    }
  };

  // Add an event listener to the window object to close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropDownOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <button className={styles.country_selected} onClick={toggleDropdown} type="button">
        {label} <SVGIcon glyph={GLYPHS.ICON_CARAT} />
      </button>
      {isDropdownOpen && (
        <div
          className={classnames(styles.dropdown_container)}
          ref={dropdownContainerRef}
          aria-expanded={isDropdownOpen}
        >
          <ul
            id={id}
            name={name}
            value={value}
            onChange={event => onChange(event.target.value, event.target.name)}
            onBlur={onBlur ? event => onBlur(event.target.name) : null}
            {...ariaAttrs}
            ref={ulRef}
          >
            {options.map((option, key) => (
              <li key={key} value={option.value}>
                <button key={key} value={option.value} onClick={() => handleOptionClick(option.value)} type="button">
                  {option.value === value ? <SVGIcon glyph={GLYPHS.ICON_CHECK} /> : null}
                  {option.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

DropdownLink.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string,
  /** the name of the input field needs to be unique */
  name: PropTypes.string,
  /** the inputs text label */
  label: PropTypes.string,
  /** Options are required */
  options: PropTypes.arrayOf(DropdownOptionPropType).isRequired,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.any,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func.isRequired,
  /** Function to bind to the native input onBlur event */
  onBlur: PropTypes.func,
  /** The DropdownLink label can be hidden to users and only visible to screen readers */
  labelHidden: PropTypes.bool,
  /** The DropdownLink label can be placed to the left of the field instead of above */
  labelLeft: PropTypes.bool,
  /** Allows extending the base DropdownLink styling */
  customClassName: PropTypes.string,
  /** Set this to true to include inline specific style */
  inline: PropTypes.bool,
  /** Set this to true to disable any interaction on the select field */
  disabled: PropTypes.bool,
  /** Set this to true to make field mandatory for screenreaders etc */
  required: PropTypes.bool,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object,
  readOnly: PropTypes.bool
};

DropdownLink.defaultsFor = () => {
  const CMS = content.appComponents || {};
  return {
    userRole: {
      id: 'userRole',
      label: `${CMS.DropdownLink_label}${':'}`,
      placeholder: CMS.DropdownLink_placeholder,
      options: Object.keys(ROLES).map(key => ({
        value: key,
        text: userRoleFriendlyNames(key) || ROLES[key]
      }))
    }
  };
};

export default DropdownLink;
