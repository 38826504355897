import { put, select, take } from 'redux-saga/effects';
import { ARCHIVE_CLASS, CLOSE_FORM, showConfirmation } from '../../../../reducers/classroomArchive';
import { clearArchivedClassesSelection } from '../../../../reducers/organisationPage.reducer';
import { archiveClassrooms } from '../../../../reducers/data/classrooms';

import archiveClassroomsApi from '../../../apiCalls/classes/archiveClassrooms';
import { pollOrgClassesRemoved } from '../dataRecency/pollOrgClasses';
import { isHubMode, getCurrentPlatform } from '../../../../../utils/platform';

function* callArchiveClassesApi() {
  const { organisationId, ...payload } = yield select(state => ({
    // Context IDs
    actioningUserId: state.identity.userId,
    // Data to change
    organisationId: state.classroomArchive.orgId,
    classes: state.classroomArchive.classroomIds,
    notifyLearners: state.classroomArchive.notifyWithEmail,
    platformCode: getCurrentPlatform() || ''
  }));
  const resultOfArchive = yield archiveClassroomsApi(organisationId, payload);
  return { resultOfArchive, classes: payload.classes };
}

export default function* classroomArchive() {
  console.log('[classroomArchive Saga] Beginning');

  while (true) {
    console.log('[classroomArchive Saga] Waiting for user to submit form');
    yield take(ARCHIVE_CLASS);

    console.log('[classroomArchive Saga] Submitting Archive Classes request');
    const { resultOfArchive, classes } = yield callArchiveClassesApi();

    console.log('[classroomArchive Saga] Archive Classes request complete, showing confirmation page');

    const requestFailed = !resultOfArchive || resultOfArchive.error || resultOfArchive.status !== 'success';
    const failedIds = resultOfArchive && resultOfArchive.data && resultOfArchive.data.failedClassIds;

    yield put(showConfirmation(requestFailed, failedIds));

    if (!requestFailed) {
      const orgId = yield select(state => state.classroomArchive.orgId);
      yield put(clearArchivedClassesSelection(orgId, failedIds));
      const successfullyRemovedClassIds = classes.filter(classId => !failedIds.includes(classId));
      yield pollOrgClassesRemoved(successfullyRemovedClassIds);

      if (isHubMode()) {
        yield put(archiveClassrooms(successfullyRemovedClassIds));
      }
    }

    yield take(CLOSE_FORM);
  }
}
