import * as t from '../../actionTypes.js';

const initialState = {
  loading: false,
  error: null,
  products: null,
  groupGuid: null,
  config: null
};

export default function getContentSelectorStatus(state = initialState, action) {
  switch (action.type) {
    case t.NGI_GET_CONTENT_SELECTOR_STATUS_REQUEST:
      return { ...state, loading: true, error: null, products: null, groupGuid: null, config: null };
    case t.NGI_GET_CONTENT_SELECTOR_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload.products,
        groupGuid: action.payload.groupGuid,
        config: action.payload.config
      };
    case t.NGI_GET_CONTENT_SELECTOR_STATUS_FAILURE:
      return { ...state, loading: false, error: action.payload, products: null, groupGuid: null, config: null };
    default:
      return state;
  }
}
