import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../globals/colors';
import Lozenge from '../Lozenge/Lozenge';
import PanelHeading from '../PanelHeading/PanelHeading';
import PopoutNavFooter from '../PopoutNavFooter/PopoutNavFooter';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import SelectionList from '../SelectionList/SelectionList';
import ToggleWithNumberInput from '../ToggleWithNumberInput/ToggleWithNumberInput';
import Validation from '../Validation/Validation';
import { featureIsEnabled } from '../../globals/envSettings';
import { isOrbMode } from '../../utils/platform';
import styles from './ClassUserAddReview.scss';

function ClassUserAddReview({
  localizedContent: content,
  classroomName = '',
  users = {},
  seatLimit,
  listThreshold,
  showSeatLimitWarning,
  seatLimitEnable,
  submitDisabled,
  onSectionLinkClick,
  onRemoveUserClick,
  onSeatLimitToggle,
  onSeatLimitChange,
  isPrimarySchool,
  onBackClick,
  isPlacementTest,
  placementTestSessionName,
  onSubmitClick
}) {
  const toggleRender = featureIsEnabled('class-toggle-remove') ? !isOrbMode() : true;
  const userCount = Object.keys(users).length;
  const isSubmitDisabled = submitDisabled || userCount === 0;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <PanelHeading
              title={content.review_title}
              subtitle={(content.review_subtitle || '').replace(
                '{className}',
                isPlacementTest ? placementTestSessionName : classroomName
              )}
              customClassName="addStudentsReviewPanel"
            />
          </div>
        }
        footerContent={
          <PopoutNavFooter
            backAction={onBackClick}
            backButtonText={content.review_back_button}
            nextAction={onSubmitClick}
            nextButtonText={
              isPlacementTest ? content.review_submit_button.replace('class', 'session') : content.review_submit_button
            }
            nextButtonDisabled={isSubmitDisabled}
          />
        }
      >
        <div className={styles.userAddReviewContainer}>
          <h3>{content.review_section_list_header}</h3>
          <SelectionList linkText={content.review_section_list_link} linkAction={onSectionLinkClick}>
            {listThreshold && userCount > listThreshold ? (
              <Lozenge
                text={`${userCount}  ${content.review_user_plural_text}`}
                backgroundColor={colors.LEARNERS}
                buttonAction={onSectionLinkClick}
                useArrowIcon
              />
            ) : (
              Object.entries(users).map(([id, user]) => (
                <Lozenge
                  key={id}
                  text={`${user.firstname || ''} ${user.lastname || ''}`}
                  id={`addedUser-${id}`}
                  backgroundColor={colors.LEARNERS}
                  buttonAction={() => onRemoveUserClick(id)}
                />
              ))
            )}
          </SelectionList>
          {toggleRender && onSeatLimitToggle && isPrimarySchool && onSeatLimitChange ? (
            <Validation
              isWarning={showSeatLimitWarning}
              message={content.review_seat_limit_error_message}
              rightHandInput
              forId="numberOfSeats"
              noLabelAboveField
            >
              <ToggleWithNumberInput
                toggleId="numberOfSeatsToggle"
                toggleLabel={content.review_seat_limit_toggle_label}
                toggleValue={seatLimitEnable}
                toggleOnChange={onSeatLimitToggle}
                numberInputId="numberOfSeats"
                numberInputLabel={content.review_seat_limit_input_label}
                numberInputValue={seatLimit}
                numberInputOnChange={onSeatLimitChange}
                numberInputMin={1}
              />
            </Validation>
          ) : null}
        </div>
      </ScrollContainer>
    </form>
  );
}

ClassUserAddReview.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  classroomName: PropTypes.string,
  users: PropTypes.object,
  listThreshold: PropTypes.number,
  seatLimit: PropTypes.number,
  showSeatLimitWarning: PropTypes.bool,
  isPrimarySchool: PropTypes.bool,
  seatLimitEnable: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  onSectionLinkClick: PropTypes.func,
  onRemoveUserClick: PropTypes.func,
  onBackClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  onSeatLimitToggle: PropTypes.func,
  onSeatLimitChange: PropTypes.func,
  placementTestSessionName: PropTypes.string,
  isPlacementTest: PropTypes.bool
};

export default ClassUserAddReview;
