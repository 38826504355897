import * as t from '../actionTypes';

export const preseedOrg = input => ({
  type: t.PRESEED_ORG,
  payload: input
});

export const preseedOrgInProgress = status => ({
  type: t.PRESEED_ORG_IN_PROGRESS,
  payload: status
});

export const preseedOrgSuccess = status => ({
  type: t.PRESEED_ORG_SUCCESS,
  payload: status
});

export const preseedOrgFailure = errors => ({
  type: t.PRESEED_ORG_FAILURE,
  payload: errors
});

export const preseedReset = () => ({
  type: t.PRESEED_RESET
});
