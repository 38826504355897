// Generic container for any Notification, such as an Invitation

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './BannerNotification.scss';
import { validColors } from '../../globals/colors';
import { bgClass } from '../../utils/colorClassNames';
import PopupOverlay from '../PopupOverlay/PopupOverlay';

function BannerNotification({ isPopup, backgroundColor, children }) {
  return (
    children &&
    (isPopup ? (
      <div className={classnames(bgClass(`${backgroundColor}-dark`))}>
        <div className={classnames('grid pad-bot1', styles.bannerNotification)}>
          <PopupOverlay visible inline customClassName={styles.bannerNotificationPopup} onTogglePopup={() => {}}>
            <div className={styles.bannerNotification}>{children}</div>
          </PopupOverlay>
        </div>
      </div>
    ) : (
      <div className={styles.bannerNotification}>{children}</div>
    ))
  );
}

BannerNotification.propTypes = {
  /** Optionally style the notification as a popup with a beak */
  isPopup: PropTypes.bool,

  backgroundColor: PropTypes.oneOf(validColors),
  /** Content or notification banner. */
  children: PropTypes.any.isRequired
};

export default BannerNotification;
