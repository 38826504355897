import PropTypes from 'prop-types';
import React from 'react';
import { upperFirst } from 'lodash';

import withLocalizedContent from '../../language/withLocalizedContent';
import { isHubMode } from '../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import PopoutPanelIconHeading, { types as iconHeadingTypes } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';

function UserArchivePendingPane({
  closeFormAction,
  requestFailed,
  showRemovalPending,
  userType,
  email,
  localizedContent: { userArchivePanel: content }
}) {
  // Helper to decide which success/partial/error heading+icon to show:
  const getIconHeading = () => {
    if (requestFailed) {
      // Archive not initiated successfully
      return {
        title: content.archive_user_error_text,
        type: iconHeadingTypes.ERROR
      };
    }

    return {
      title: `${upperFirst(userType)} ${content.users_pending_archive_text}`,
      type: iconHeadingTypes.PROCESSING
    };
  };

  return (
    <ScrollContainer
      footerContent={
        <PopoutActionFooter
          primaryButtonDisabled={!showRemovalPending}
          primaryButtonText={content.complete_panel_text}
          primaryButtonAction={closeFormAction}
        />
      }
    >
      {showRemovalPending && !(isHubMode() && requestFailed) ? (
        <PopoutPanelIconHeading {...getIconHeading(requestFailed, userType)}>
          {!requestFailed ? (
            <div className="pad4">
              {content.email_complete_body_text_1}
              <span className="block pad1 gin-top1 gin-bot1 color-default-bg" style={{ borderRadius: '4px' }}>
                <em>{email}</em>
              </span>
              <span className="block">{content.email_complete_body_text_2}</span>
              <span className="block gin2 gin-top4">{content.email_complete_body_text_3}</span>
            </div>
          ) : null}
        </PopoutPanelIconHeading>
      ) : null}
      {showRemovalPending && isHubMode() && requestFailed ? (
        <PopoutPanelIllustrationHeading
          title={content.archive_user_error_text}
          illustrationSrc={HubIllustrationConstants.ERROR}
          illustrationAltText={HubIllustrationAltText.ERROR}
        />
      ) : null}

      {!showRemovalPending ? (
        <PopoutPanelIconHeading
          type={iconHeadingTypes.LOADING}
          title={content.loading_page_title}
          subtitle={(content.submitting_user_archive_text || '').replace('{user}', userType)}
        />
      ) : null}
    </ScrollContainer>
  );
}

UserArchivePendingPane.propTypes = {
  userType: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  requestFailed: PropTypes.bool,
  showRemovalPending: PropTypes.bool,
  closeFormAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('userArchivePanel')(UserArchivePendingPane);
