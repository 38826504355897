import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button, { buttonTypes } from '../../../components/Button/Button';
import Dropdown from '../../../components/Dropdown/Dropdown';
import styles from '../ProductUpload/ProductUpload.scss';
import { startGenerateReportRequest } from '../../../redux/reducers/generateReport.reducer';
import Card from '../../../components/Card/Card';
import colors from '../../../globals/colors';

class GenerateReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportType: 'noSelection'
    };
  }

  _onSubmit = () => {
    const { startGenerateReportRequestAction } = this.props;
    const { reportType } = this.state;

    startGenerateReportRequestAction(reportType);
  };

  _onDropDownChange = value => {
    this.setState({
      reportType: value
    });
  };

  render() {
    const { content } = this.props;
    const { reportType } = this.state;
    const publishProductOptions = [
      { text: 'Select a report', value: 'noSelection' },
      { text: 'Login', value: 'login' },
      { text: 'Duoc', value: 'duoc' }
    ];

    return (
      <div className="col md8">
        <Card headingText={content.title} headingColor={colors.PROFILE}>
          <div className={styles.cardContainer}>
            <Dropdown
              id="reportTypeDropdown"
              name="reportTypeDropdown"
              label="Report type"
              onChange={this._onDropDownChange}
              options={publishProductOptions}
            />

            <Button
              id="generateReport"
              type={buttonTypes.PRIMARY}
              text={content.submitButton}
              customClassName={styles.uploadButton}
              disabled={reportType === 'noSelection'}
              onClick={this._onSubmit}
            />
          </div>
        </Card>
      </div>
    );
  }
}

GenerateReport.propTypes = {
  content: PropTypes.object.isRequired,
  startGenerateReportRequestAction: PropTypes.func
};

export default connect(null, {
  startGenerateReportRequestAction: startGenerateReportRequest
})(GenerateReport);
