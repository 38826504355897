import { useState } from 'react';

const allowedValues = [0, 1];

const useRestrictedState = initialState => {
  if (!allowedValues.includes(initialState)) {
    throw new Error(
      `Invalid initial state value of ${initialState}. The allowed values are ${allowedValues.join(', ')}`
    );
  }
  return useState(initialState);
};

export default useRestrictedState;
