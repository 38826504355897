import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../css/ProductCreationPage.scss';
import withLocalizedContent from '../../../../../language/withLocalizedContent';
import LookupProducts from '../../../../../components/LookupProducts/LookupProducts';
import AlertModal from './AlertModal.js';
import Table, { columnTypes } from '../../../../../components/Table/Table';
import Button, { buttonTypes } from '../../../../../components/Button/Button';

function AssociationsTab({ productDetails, setProductDetails, localizedContent: { productCreate: content } }) {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const setDetails = setProductDetails();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [message, setMessage] = useState('');

  const setOverlay = param => {
    setIsOverlayOpen(param);
  };

  const renderAlertModal = () => {
    if (isAlertOpen) {
      return <AlertModal message={message} isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} />;
    }
    return <> </>;
  };

  const findAssociation = productId => {
    const association = productDetails.associations.find(assoc => assoc.productId === productId);

    return association;
  };

  const addAssociation = product => {
    setSelectedProduct(product);
    if (product.productId && !findAssociation(product.productId)) {
      const newAssoc = [...productDetails.associations, product];
      setDetails.setAssociationsFunction(newAssoc);
    } else if (findAssociation(product.productId)) {
      setMessage(content.item_already_added);
      setIsAlertOpen(true);
    }
  };

  const removeAssociation = productId => {
    const associations = productDetails.associations.filter(assoc => assoc.productId !== productId);

    setDetails.setAssociationsFunction(associations);
  };

  const renderTableRows = () => {
    if (productDetails.associations.length === 0) {
      return [];
    }

    return productDetails.associations.map(assoc => ({
      id: assoc.productId,
      cells: [
        assoc.productName,
        <Button
          key={assoc.productId}
          type={buttonTypes.NO_BORDER}
          onClick={() => removeAssociation(assoc.productId)}
          text="Delete"
        />
      ]
    }));
  };

  return (
    <div className="container">
      <article className={styles.licenseDetails}>
        <span className={styles.productSummary}>{content.product_span}</span>
        <div className={styles.productDetailsContainer}>
          <div className={styles.productRequired}>
            <span>{content.product_span}</span> <span className={styles.redStar}>*</span>
          </div>
          <LookupProducts
            returnProductOrId
            productSelected={addAssociation}
            productSelectedValue={selectedProduct.productInput}
            isOverlayOpen={isOverlayOpen}
            setIsOverlayOpen={setOverlay}
          />
        </div>
      </article>
      {productDetails.associations.length > 0 && (
        <article className={styles.licenseDetails}>
          <span className={styles.productSummary}>{content.associations_button}</span>
          <Table
            columns={[
              { heading: content.product_span, type: columnTypes.TEXT },
              { heading: 'Action', type: columnTypes.BUTTON }
            ]}
            rows={renderTableRows()}
          />
        </article>
      )}
      {renderAlertModal(message)}
    </div>
  );
}
AssociationsTab.propTypes = {
  productDetails: PropTypes.object,
  setProductDetails: PropTypes.func,
  localizedContent: PropTypes.object
};

export default withLocalizedContent('productCreate')(AssociationsTab);
