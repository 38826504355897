import * as t from '../actionTypes';

export const teacherResourcesRequest = (orgId, locationId, isbns) => ({
  type: t.ORB_TEACHER_RESOURCES_REQUEST,
  payload: { orgId, locationId, isbns }
});

export const teacherResourcesSuccess = payload => ({
  type: t.ORB_TEACHER_RESOURCES_SUCCESS,
  payload
});

export const teacherResourcesFailure = error => ({
  type: t.ORB_TEACHER_RESOURCES_FAILURE,
  payload: error
});
