import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import loadPlacementTestResults from './loadPlacementTestResults';

function* root() {
  yield takeLatest(t.DOWNLOAD_PLACEMENT_TEST_RESULTS, ({ payload, callback }) =>
    loadPlacementTestResults(payload, callback)
  );
}

export default root;
