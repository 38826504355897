import * as t from '../actionTypes';

export const initialState = {
  data: []
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case t.ORB_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        success: true,
        data: action.payload
      };
    default:
      return state;
  }
}
