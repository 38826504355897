import { take, put, select } from 'redux-saga/effects';

import { BULK_SUBMIT_FORM, bulkShowConfirmation, showErrorReview } from '../../../../../reducers/enrolUser.reducer.js';
import bulkUserUploadRequest from './bulkUserUploadRequest.saga.js';

const DELIMITERS = [',', ';', '|', '\t'];
const COLUMNS = ['UserName', 'FirstName', 'LastName', 'EmailAddress', 'RoleName', 'Class'];

const getDelimeter = line =>
  DELIMITERS.reduce(
    (carry, delimiter) => (line.split(delimiter).length === COLUMNS.length ? delimiter : carry),
    DELIMITERS[0]
  );

export default function* bulkUploadToClass() {
  console.log('[bulkUploadToClass Saga] Beginning');

  while (true) {
    console.log('[bulkUploadToClass Saga] Waiting for user to submit form');
    yield take(BULK_SUBMIT_FORM);
    console.log('[bulkUploadToClass Saga] Uploading file contents...');
    const result = yield bulkUserUploadRequest();

    if (result.status === 'success') {
      console.log('[bulkUploadToClass Saga] Showing confirmation screen');
      yield put(bulkShowConfirmation());
    } else {
      const fileContents = yield select(state => state.enrolUser.file.data);

      const errorEntries = [];
      if (result.data) {
        const errors = Object.entries(result.data);
        // Format the file to remove any double commas
        const formattedFileContents = fileContents.replace(/,{2}/g, ',');
        // Split the file
        const splitFile = formattedFileContents.split('\n');
        const delimiter = getDelimeter(splitFile[0]);
        const hasHeader = COLUMNS.join(delimiter) === splitFile[0].trim();
        const indexOffset = hasHeader ? 0 : 1;

        errors.forEach(entry =>
          errorEntries.push({
            row: entry[0],
            value: entry[0] === '0' ? null : splitFile[entry[0] - indexOffset],
            cmsErrorKeys: entry[1]
          })
        );
      }

      yield put(showErrorReview(errorEntries));
    }
  }
}
