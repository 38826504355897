import authSettings from '../../../../globals/authSettings.js';
import { isOIDCStandard } from '../../../../globals/envSettings';
import initAuth0 from '../../../../globals/oidcSettings';

export default function* getIdTokenAPI(authCode) {
  if (!isOIDCStandard(authSettings.idpStandard)) {
    return { id_token: authCode };
  }

  return yield initAuth0().then(async auth0 => {
    const resp = await auth0.handleRedirectCallback();
    const isAuthenticated = await auth0.isAuthenticated();
    if (isAuthenticated) {
      const token = await auth0.getTokenSilently();
      const claims = await auth0.getIdTokenClaims();
      return {
        id_token: claims.__raw,
        access_token: token,
        expires_in: claims.exp - claims.iat
      };
    }
    return resp;
  });
}
