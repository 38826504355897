import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './SpacingOptions.scss';

export const OPTIONS = {
  WIDE: 'wide',
  MEDIUM: 'medium',
  NARROW: 'narrow'
};

export const THEMES = {
  NONE: 'none',
  WHITE: 'white'
};

function SpacingOptions({ onSwitch, selected = OPTIONS.MEDIUM, customClassName, setTheme = THEMES.NONE, label }) {
  return (
    <ul
      className={classnames(
        styles.spacingOptions,
        setTheme === THEMES.NONE ? '' : styles[`${setTheme}BG`],
        customClassName
      )}
    >
      {label && <li className={styles.spacingOptions__listLabelItem}>{label}&nbsp;</li>}
      {Object.values(OPTIONS).map(option => (
        <li key={`option-${option}`} className={styles.spacingOptions__listItem}>
          <input
            className={styles.spacingOptions__input}
            onClick={onSwitch}
            type="radio"
            name="spacing-options"
            id={option}
            defaultChecked={option === selected}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.spacingOptions__label} htmlFor={option}>
            <SVGIcon className={styles.spacingOptions__icon} glyph={GLYPHS[`ICON_ROWS_${option.toUpperCase()}`]} />
          </label>
        </li>
      ))}
    </ul>
  );
}

SpacingOptions.propTypes = {
  /** The function to fire on option switch */
  onSwitch: PropTypes.func.isRequired,
  /** The item for default selection on display */
  selected: PropTypes.oneOf(Object.values(OPTIONS)),
  /** Any custom class to apply to the component */
  customClassName: PropTypes.string,
  /** Any custom class to apply to the component */
  setTheme: PropTypes.string,
  /** Any custom class to apply to the component */
  label: PropTypes.string
};

SpacingOptions.defaultProps = {
  selected: OPTIONS.MEDIUM,
  customClassName: false
};

export default SpacingOptions;
