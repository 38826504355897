import React from 'react';
import PropTypes from 'prop-types';
import styles from './CurriculumTermsTable.scss';
import curriculumTerms from '../../../language/glossaries/en/shared/curriculumTerms.glossary.json';
import appSettings from '../../globals/appSettings';

function CurriculumTermsTable({ curriculumType, headingYearGroup, headingYearGroupNumber }) {
  const getContentForLocale = (glossary, locale = null) => glossary.locales[locale] || glossary.glossary;
  const renderTableRow = (yrGrp, yrGrpNum) => (
    <tr>
      <td>{yrGrp}</td>
      <td>{yrGrpNum.replace('YEAR_', '')}</td>
    </tr>
  );

  const { curriculumLocale } = appSettings.curriculumTypes[curriculumType] || {};
  const content = { ...getContentForLocale(curriculumTerms, `en-x-${curriculumLocale}`) };
  console.log(content);
  const keys = Object.keys(content);
  return (
    <div className={styles.wrapper}>
      <div>
        <table>
          <thead>
            <tr>
              <th>{headingYearGroup}</th>
              <th>{headingYearGroupNumber}</th>
            </tr>
          </thead>
          <tbody>{keys.map(key => renderTableRow(content[key], key))}</tbody>
        </table>
      </div>
    </div>
  );
}

CurriculumTermsTable.propTypes = {
  curriculumType: PropTypes.string,
  headingYearGroup: PropTypes.string,
  headingYearGroupNumber: PropTypes.string
};

export default CurriculumTermsTable;
