import userRoles from '../../../globals/userRoles';

const getOrgLMSConfigActionButtons = (content, { editDeployment = null, archiveDeployment = null }) => ({
  [userRoles.ORG_ADMIN]: [
    {
      title: content.edit_deployment,
      action: editDeployment
    },
    {
      title: content.archive_deployment,
      action: archiveDeployment
    }
  ]
});

export default getOrgLMSConfigActionButtons;
