import * as t from '../actionTypes';

const initialState = {
  selectedAssessmentId: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.COURSE_ASSESSMENTS_SET_ASSESSMENT_ID:
      return {
        ...state,
        selectedAssessmentId: payload
      };
    default:
      return state;
  }
};
