import React, { useEffect, useState } from 'react';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button, { buttonTypes } from '../../../../components/Button/Button';
import { columnTypes } from '../../../../components/TableAccordion/TableAccordion.js';
import CoursePublishTable from './CoursePublishTable.js';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import PageHeading from '../../../../components/PageHeading/PageHeading';
import SearchInput from '../../../../components/SearchInput/SearchInput';

import {
  CONTENT_STATES,
  CONTENT_STATES_OPTIONS,
  COURSE_MODAL_CLOSE_LINK_KEY
} from '../../../../globals/cptConstants.js';
import { getEnvType } from '../../../../globals/envSettings';
import styles from './CoursePublish.scss';

import { getCoursePublishRequest } from '../../../../redux/actions/coursePublish.js';
import preventBodyScroll from '../../../../utils/dom/preventBodyScroll';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import CoursePublishModal from './CoursePublishModal.js';
import { getCourseContentsRequest } from '../../../../redux/actions/courseContents';
import Link from '../../../../components/Link/Link.js';
import { isDummyTitle } from '../../../../../sharedNodeBrowser/isDummyIsbn';

function CoursePublish({
  getCoursePublishRequestData,
  loading,
  success,
  results,
  localizedContent: { coursePublish: content },
  getCourseContentsRequestData,
  courseContentsDetails
}) {
  const [contentState, setContentState] = useState(CONTENT_STATES.ALL);
  const [title, setTitle] = useState('');
  const [courseContents, setCourseContents] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openedCourseTitle, setOpenedCurrentCourseTitle] = useState('');

  useEffect(() => {
    getCoursePublishRequestData({ 'content-state': contentState, title });
  }, []);

  useEffect(() => {
    sessionStorage.setItem(COURSE_MODAL_CLOSE_LINK_KEY, window.location.pathname);
    preventBodyScroll(false);
  }, []);

  useEffect(() => {
    setCourseContents(courseContentsDetails);
  }, [courseContentsDetails]);

  const handleDropdownUpdate = value => {
    setContentState(value);
    getCoursePublishRequestData({ 'content-state': value, title });
  };

  const closePublishModal = () => {
    setIsModalOpen(false);
  };

  const openPublishModal = (courseCode, courseTitle) => {
    setOpenedCurrentCourseTitle(courseTitle);
    getCourseContentsRequestData({ courseCode });
    setCourseContents(courseContentsDetails);
    setIsModalOpen(true);
  };

  const renderPublishButton = (item, key) => (
    <Button
      key={`course_${key}`}
      type={buttonTypes.SECONDARY}
      text={content.publish_button}
      ariaLabel={item.title}
      disabled={getEnvType() !== 'PROD' && !isDummyTitle(item.title)}
      onClick={() => openPublishModal(item.courseCode, item.title)}
    />
  );

  const renderPreviewButton = (item, key, isPublished) => (
    <Link key={`id_${key}_student`} to={`course-preview/${item.courseCode}/courseMaterial`} disabled={isPublished}>
      <Button key={`buttons_${key}`} text={content.preview} type={buttonTypes.SECONDARY} disabled={isPublished} />
    </Link>
  );

  const generateTableRows = data => {
    const rows = [];

    data?.forEach((item, key) => {
      const isPublished = item.status === CONTENT_STATES.PUBLISHED;

      rows.push({
        id: `id${key}`,
        cells: [
          item.title,
          item.type,
          item.status,
          <div className={styles.buttonsContainer} key={`buttonsContainer_${key}`}>
            {renderPublishButton(item, key)}
            {renderPreviewButton(item, key, isPublished)}
          </div>
        ]
      });
    });
    return rows;
  };

  const renderTable = () => {
    const columns = [
      {
        heading: content.title_column,
        type: columnTypes.TEXT
      },
      {
        heading: content.type_column,
        type: columnTypes.TEXT
      },
      {
        heading: content.content_state_column,
        type: columnTypes.TEXT
      },
      {
        heading: content.actions_column,
        type: columnTypes.BUTTON
      }
    ];

    const rows = generateTableRows(results);

    return (
      <CoursePublishTable
        columns={columns}
        rows={rows}
        success={success}
        loading={loading}
        noCourseMessage={content.no_courses}
      />
    );
  };

  return (
    <div>
      <div className={styles.heading}>
        <PageHeading title={content.page_title} />
        <Dropdown
          id="contentState"
          label={content.filter_content_state}
          options={CONTENT_STATES_OPTIONS}
          value={contentState}
          onChange={value => handleDropdownUpdate(value)}
          labelHidden
        />
      </div>
      <br />
      <div className={styles.coursePublishTableContainer}>
        <div className={styles.filters}>
          <div className={styles.filter}>
            <SearchInput
              placeholder={content.search_title_label}
              value={title}
              onChange={value => {
                setTitle(value);
              }}
              buttonAction={() => {
                getCoursePublishRequestData({ 'content-state': contentState, title });
              }}
            />
          </div>
        </div>
        {renderTable()}
        <CoursePublishModal
          closeModal={closePublishModal}
          isOpen={isModalOpen}
          modalContentBody={courseContents}
          courseTitle={openedCourseTitle}
          content={content}
        />
      </div>
    </div>
  );
}

CoursePublish.propTypes = {
  getCoursePublishRequestData: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  results: PropTypes.object,
  localizedContent: PropTypes.object,
  getCourseContentsRequestData: PropTypes.func,
  courseContentsDetails: PropTypes.object
};

const mapStateToProps = state => {
  const {
    coursePublish: { loading, success, results },
    courseContents: { courseContentsDetails }
  } = state;
  return { loading, success, results, courseContentsDetails };
};

const mapDispatchToProps = {
  getCoursePublishRequestData: getCoursePublishRequest,
  getCourseContentsRequestData: getCourseContentsRequest
};

export default compose(
  withLocalizedContent('coursePublish'),
  connect(mapStateToProps, mapDispatchToProps)
)(CoursePublish);
