import { omit } from 'lodash';
import { put } from 'redux-saga/effects';
import { API_VERSIONS, JSON_ENCODED } from '@oup/shared-node-browser/constants';
import editOrgSchema from '../../../../../../static/schema/draft-4/edit-org-request-body.json';
import { orgRoles } from '../../../../../globals/orgRoles';
import actions from '../../../../actions';
import * as t from '../../../../actionTypes';
import { setActiveOrgId } from '../../../../reducers/organisationPage.reducer';
import signedFetch from '../../../apiCalls/util/signedFetch';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';

function* editOrg(orgId, input) {
  const isPrimarySchool = input.role === orgRoles.PRIMARY_SCHOOL;
  const data = omit(input, isPrimarySchool ? [] : ['curriculumType', 'orgUniqueId']);
  const errors = yield validateInputUsingSchema(editOrgSchema, t.EDIT_ORG_VALIDATE_COMPLETED, data);
  const hasErrors = Object.values(errors).includes(true);
  if (hasErrors) {
    yield put(actions.editOrgValidateError());
    return;
  }
  const response = yield signedFetch('editOrg', `${__API_BASE__}/org/${orgId}`, 'PUT', data, {
    Accept: API_VERSIONS.API_VERSION_0_2,
    'Content-Type': JSON_ENCODED
  });
  if (response.status === 'success') {
    yield put(actions.editOrgSuccess(response.data.groupId));
    yield put(setActiveOrgId(orgId));
    return;
  }
  yield put(actions.editOrgFailure(response.errorMessages));
}

export default editOrg;
