export const INITIALISE_SELECTION_VIEW = 'selection/INITIALISE_SELECTION_VIEW';
export const SET_SEARCH_TERM = 'selection/SET_SEARCH_TERM';
export const SET_SEARCH_FILTER = 'selection/SET_SEARCH_FILTER';
const SET_RESULTS = 'selection/SET_RESULTS';
const UPDATE_SELECTION = 'selection/UPDATE_SELECTION';
const OVERWRITE_SELECTION = 'selection/OVERWRITE_SELECTION';
export const SET_PAGE = 'selection/SET_PAGE';
const SET_LIMIT_TOGGLE = 'selection/SET_LIMIT_TOGGLE';
const SET_LIMIT = 'selection/SET_LIMIT';

const initialState = {
  context: '',
  // Type of entity being searched. It should only ever be "teachers", "students" or "material" TODO: Confirm
  entityType: null,

  // Search Term input string value
  searchTerm: '',
  // Some search pages have an additional filter for the search results
  searchFilter: '',

  // List of result to be shown
  results: {
    admins: [],
    teacherAdmins: [],
    teachers: [],
    students: []
  },
  // Amount of total results
  totalResults: 0,

  // The current page value and total amount of pages
  currentPage: 1,

  // Selection
  selectedIds: [],
  initialSelectedIds: [],

  // Optional limit toggle and value
  limitToggleValue: false,
  limitValue: 1
};

export default function selection(state = initialState, action = {}) {
  switch (action.type) {
    case INITIALISE_SELECTION_VIEW:
      return {
        // Reset state
        ...initialState,
        context: action.context,
        entityType: action.entityType,
        selectedIds: action.initialSelectedIds,
        initialSelectedIds: action.initialSelectedIds,
        searchFilter: action.initialFilterValue,
        limitToggleValue: action.initialLimitToggleValue || false,
        limitValue: parseInt(action.initialLimitValue, 10) || 1
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };
    case SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.searchFilter
      };
    case SET_RESULTS:
      return {
        ...state,
        results: action.results,
        totalResults: action.totalResults,
        currentPage: action.pageNumber
      };
    case OVERWRITE_SELECTION:
      return {
        ...state,
        selectedIds: action.payload
      };
    case UPDATE_SELECTION:
      return {
        ...state,
        selectedIds: action.selected
          ? // Add the Id to the end of the list
            [...state.selectedIds, action.id]
          : // Filter the Id out of the list
            state.selectedIds.filter(id => id !== action.id)
      };
    case SET_PAGE:
      return {
        ...state,
        currentPage: action.pageNumber
      };

    case SET_LIMIT_TOGGLE:
      return {
        ...state,
        limitToggleValue: action.limitToggleValue,
        limitValue: state.selectedIds.length
      };
    case SET_LIMIT: {
      const value = parseInt(action.limitValue, 10) || 1;
      return {
        ...state,
        limitValue: Number.isNaN(Number(value)) ? 1 : Math.max(value, 1)
      };
    }

    default:
      return state;
  }
}

export const initialiseSelectionView = (
  context,
  entityType,
  initialSelectedIds,
  initialFilterValue,
  initialLimitToggleValue,
  initialLimitValue
) => ({
  type: INITIALISE_SELECTION_VIEW,
  context,
  entityType,
  initialSelectedIds,
  initialFilterValue,
  initialLimitToggleValue,
  initialLimitValue
});

export const setSearchTerm = searchTerm => ({
  type: SET_SEARCH_TERM,
  searchTerm
});

export const setSearchFilter = searchFilter => ({
  type: SET_SEARCH_FILTER,
  searchFilter
});

export const setResults = (results, totalResults, pageNumber) => ({
  type: SET_RESULTS,
  results,
  totalResults,
  pageNumber
});

export const overwriteSelection = payload => ({
  type: OVERWRITE_SELECTION,
  payload
});
export const updateSelection = (id, selected) => ({
  type: UPDATE_SELECTION,
  id,
  selected
});

export const setPage = pageNumber => ({
  type: SET_PAGE,
  pageNumber
});

export const setLimitToggle = (limitToggleValue, externalLimitBaseValue) => ({
  type: SET_LIMIT_TOGGLE,
  limitToggleValue,
  externalLimitBaseValue
});

export const setLimit = limitValue => ({
  type: SET_LIMIT,
  limitValue
});
