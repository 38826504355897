import { put } from 'redux-saga/effects';
import getAdaptedTestsRequest from '../../../apiCalls/hub/getAdaptedTests';
import { getAdaptedTestsSuccess, getAdaptedTestsFailure } from '../../../../actions/adaptedTests';

function* getAdaptedTests(payload) {
  const response = yield getAdaptedTestsRequest(payload);

  if (response.status === 'success') {
    yield put(getAdaptedTestsSuccess(response.data));
  }
  yield put(getAdaptedTestsFailure());
}

export default getAdaptedTests;
