import * as t from '../actionTypes';

export const mode = {
  NOOP: 'NOOP',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

const initialState = {
  mode: mode.NOOP,
  data: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.GET_ORG_REQUEST:
      return {
        ...state,
        mode: mode.LOADING,
        data: null
      };
    case t.GET_ORG_SUCCESS:
      return {
        ...state,
        mode: mode.SUCCESS,
        data: payload
      };
    case t.GET_ORG_FAILURE:
      return {
        ...state,
        mode: mode.ERROR,
        data: payload
      };
    case t.GET_ORG_RESET:
      return initialState;
    default:
      return state;
  }
};
