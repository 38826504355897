import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getClassReportDialsRequest from '../../../apiCalls/gradebook/getClassReportDials.api';

function* getClassReportDials(orgId, classId, locationId, academicYear) {
  const response = yield getClassReportDialsRequest(orgId, classId, locationId, academicYear);
  if (response.status === 'success') {
    yield put(actions.gradebookClassDialReportSuccess({ ...response, classId }));
    return;
  }
  yield put(actions.gradebookClassDialReportFailure({ ...response, classId }));
}

export default getClassReportDials;
