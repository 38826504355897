import * as t from '../../actionTypes';

// const defaultStatusOptions = { submitting: false, success: false, error: false };
const requestStatusOptions = { loading: true, success: false, error: false };
const successStatusOptions = { loading: false, success: true, error: false };
const failureStatusOptions = { loading: false, success: false, error: true };

const initialState = {
  courses: {},
  courseIds: []
};

function hubCourses(state = initialState, { type, payload }) {
  switch (type) {
    case t.HUB_GET_COURSES:
      return {
        ...initialState,
        ...requestStatusOptions
      };
    case t.HUB_SET_COURSES:
      return {
        ...state,
        courses: payload
      };
    case t.HUB_GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: payload.courses,
        courseIds: payload.ids,
        ...successStatusOptions
      };
    case t.HUB_GET_COURSES_FAILURE:
      return { ...state, ...failureStatusOptions };
    default:
      return state;
  }
}

export default hubCourses;
