import { pick } from 'lodash';
import { INVITATION_STATUS } from '../../globals/appConstants';

import {
  DECLINED_ORG_DETAILS_INIT,
  DECLINED_ORG_DETAILS_LOAD_SUCCESS,
  DECLINED_ORG_DETAILS_LOAD_ERROR,
  DECLINED_ORG_INVITE_UPDATING,
  DECLINED_ORG_INVITE_UPDATE_SUCCESS,
  DECLINED_ORG_INVITE_UPDATE_ERROR
} from '../actionTypes';

// Loading Status:
export const formStates = {
  LOADING: 'LOADING',
  REVIEWING: 'REVIEWING',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION'
};

export const initialState = {
  loading: true,
  errorLoading: false,
  formState: formStates.LOADING,

  // Init Invitation Data
  token: null,
  platform: null,
  orgName: null,
  offeredRole: null,
  tokenStatus: null,
  currentInvitationStatus: null,
  responseDate: null,
  invitationStatus: null
};

export default function declineOrgInvitePage(state = initialState, action = {}) {
  switch (action.type) {
    case DECLINED_ORG_DETAILS_INIT:
      return {
        ...state,
        token: action.token
      };

    case DECLINED_ORG_DETAILS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        errorLoading: false,
        formState: formStates.REVIEWING,
        ...pick(action.payload, [
          'platform',
          'orgName',
          'offeredRole',
          'tokenStatus',
          'currentInvitationStatus',
          'responseDate'
        ])
      };

    case DECLINED_ORG_DETAILS_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        errorLoading: true,
        formState: formStates.CONFIRMATION,
        ...pick(action.payload, [
          'platform',
          'orgName',
          'offeredRole',
          'tokenStatus',
          'currentInvitationStatus',
          'invitationArchiveStatus',
          'responseDate'
        ])
      };

    case DECLINED_ORG_INVITE_UPDATING:
      return {
        ...state,
        loading: true,
        formState: formStates.SUBMITTING,
        invitationStatus: INVITATION_STATUS.REJECTED
      };

    case DECLINED_ORG_INVITE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorLoading: false,
        formState: formStates.CONFIRMATION
      };

    case DECLINED_ORG_INVITE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        errorLoading: true,
        formState: formStates.CONFIRMATION
      };

    default:
      return state;
  }
}
