import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { getCurrentPlatform } from '../../../utils/platform';
import { featureIsEnabled } from '../../../globals/envSettings';
import LegacySystemNotificationComponent from './LegacySystemNotificationComponent';
import SystemNotificationComponent from './SystemNotificationComponent';

function SystemNotificationBanner({
  systemNotification = {},
  handleHeaderHeightChange = null,
  localizedContent: { systemNotification: content },
  orbNotifications = [],
  readOnlyMode = false,
  isAuthenticated = false,
  homePageOnly = false,
  className = {}
}) {
  const { pathname } = useLocation();
  const isGranularSystemNotificationFF = featureIsEnabled('granular-platform-system-notifications');

  const [showBanner, setShowBanner] = useState(true);
  const platform = getCurrentPlatform();
  const showOnHomePageOnly = pathname === '/'; // this is needed for HUB
  let platformNotification;
  let isVisible = false;

  if (isGranularSystemNotificationFF) {
    platformNotification = platform && systemNotification?.find(({ platformCode }) => platformCode === platform);
    const canDisplay = platformNotification?.enabled && !orbNotifications.length;

    isVisible = homePageOnly ? showOnHomePageOnly && canDisplay : canDisplay;
  } else {
    platformNotification = platform && systemNotification[platform];
    isVisible = platformNotification?.showNotification && !orbNotifications.length;
  }

  if (!isVisible || !showBanner) {
    return null;
  }

  const onBannerClose = () => {
    setShowBanner(!showBanner);
    if (handleHeaderHeightChange) handleHeaderHeightChange();
  };

  return isGranularSystemNotificationFF ? (
    <SystemNotificationComponent
      readOnlyMode={readOnlyMode}
      isAuthenticated={isAuthenticated}
      content={content}
      platformNotification={platformNotification}
      onBannerClose={onBannerClose}
      className={className}
    />
  ) : (
    <LegacySystemNotificationComponent
      content={content}
      platformNotification={platformNotification}
      onBannerClose={onBannerClose}
    />
  );
}

SystemNotificationBanner.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  systemNotification: PropTypes.object.isRequired,
  orbNotifications: PropTypes.array.isRequired,
  handleHeaderHeightChange: PropTypes.func,
  readOnlyMode: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  homePageOnly: PropTypes.bool,
  className: PropTypes.object
};

export default compose(
  connect(state => ({
    systemNotification: state.app.systemNotification || {},
    orbNotifications: state.notifications?.data || [],
    readOnlyMode: state.app.readOnlyMode,
    isAuthenticated: !!state.identity?.userId
  })),
  withLocalizedContent('systemNotification')
)(SystemNotificationBanner);
