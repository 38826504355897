import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import unsignedFetch from '../../../apiCalls/util/unsignedFetch';
import { getCurrentPlatform } from '../../../../../utils/platform';

function* sendResetPasswordEmail(email) {
  const platformCode = getCurrentPlatform();

  const response = yield unsignedFetch(
    'requestPasswordReset',
    `${__API_BASE__}/open/users/requestPasswordReset`,
    'POST',
    {
      email,
      platform: platformCode.toUpperCase()
    }
  );
  if (response.status.toLowerCase() === 'success') {
    yield put(actions.resetPasswordSuccess());
    return;
  }
  yield put(actions.resetPasswordFailure());
}

export default sendResetPasswordEmail;
