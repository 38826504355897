import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { debounce } from 'lodash';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import TextInputLabel from '../TextInputLabel/TextInputLabel';
import styles from './PasswordInputManagedUser.scss';
import Color from '../../styles/constants/_colors.scss';

const TYPE_STOP_DELAY = 200;
export default class PasswordInputManagedUser extends React.Component {
  static _updateInput = (input, callback) => (input !== '' ? callback(input) : false);

  constructor(props) {
    super(props);
    PasswordInputManagedUser._updateInput = debounce(PasswordInputManagedUser._updateInput, TYPE_STOP_DELAY);
    this.state = {
      inputType: 'password',
      icon: GLYPHS.ICON_EYE_OFF
    };
  }

  toggleInputType = () => {
    const { inputType } = this.state;
    if (inputType === 'password') {
      this.setState({ inputType: 'text', icon: GLYPHS.ICON_EYE_ON });
    } else {
      this.setState({ inputType: 'password', icon: GLYPHS.ICON_EYE_OFF });
    }
  };

  render() {
    const { id, labelHidden, label, placeholder, role, onChange, onBlur, onFocus, onTypeStop, ...props } = this.props;
    const { inputType, icon } = this.state;

    const _onChange = ({ target }) => {
      if (onTypeStop) PasswordInputManagedUser._updateInput(target.value, onTypeStop);
      if (onChange) onChange(target.value, target.name);
    };

    const _onBlur = ({ target }) => {
      if (onBlur) onBlur(target.name);
    };

    const _onFocus = ({ target }) => {
      if (onFocus) onFocus(target.name);
    };

    return (
      <div className={classnames(styles.passwordInputV2)}>
        {!labelHidden ? <TextInputLabel id={id} label={label} labelHidden={labelHidden} /> : null}
        <div className={classnames(styles.inputContainer)}>
          <input
            className={classnames(styles.inputField)}
            id={id}
            name={id}
            type={inputType}
            onChange={_onChange}
            placeholder={placeholder}
            onBlur={_onBlur}
            onFocus={_onFocus}
            {...props}
          />
          <div
            aria-hidden="true"
            onClick={this.toggleInputType}
            onKeyDown={this.toggleInputType}
            className={classnames(styles.svgContainer)}
          >
            <SVGIcon glyph={icon} fill={Color['#border-color']} />
          </div>
        </div>
      </div>
    );
  }
}

PasswordInputManagedUser.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelHidden: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  role: PropTypes.any,
  value: PropTypes.string,
  onTypeStop: PropTypes.func,
  disabled: PropTypes.bool
};

PasswordInputManagedUser.defaultProps = {
  placeholder: 'Enter Password',
  labelHidden: false,
  disabled: false
};
