import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import validateOrgInput from './validateOrgInput';

function* reviewOrgInput(input, branch) {
  const confirm = !(yield validateOrgInput(input, branch));
  if (!confirm) {
    yield put(actions.reviewOrgError());
    return;
  }
  yield put(actions.reviewOrgCompleted(confirm));
}

export default reviewOrgInput;
