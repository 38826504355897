import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './InformLabel.scss';

function InformLabel({
  message = '',
  glyphIcon = '',
  buttonText = '',
  buttonAction,
  customClassName = '',
  showFirstButtonInformLabel = false
}) {
  const handleOnClick = e => {
    e.stopPropagation();
    buttonAction();
  };

  return (
    <div className={`${styles.labelContainer} ${styles[customClassName] || ''}`}>
      {glyphIcon ? (
        <div className={styles.svgContainer}>
          <SVGIcon glyph={glyphIcon} fill="#fff" />
        </div>
      ) : null}

      {!showFirstButtonInformLabel && <span>{message}</span>}
      {buttonText ? (
        <div className={styles.buttonContainer}>
          <button type="submit" onKeyDown={handleOnClick} onKeyPress={handleOnClick} onClick={handleOnClick}>
            {buttonText}
          </button>
        </div>
      ) : null}
      {showFirstButtonInformLabel && <span className={styles.messageContainer}>{message}</span>}
    </div>
  );
}

InformLabel.propTypes = {
  message: PropTypes.string.isRequired,
  glyphIcon: PropTypes.string,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
  customClassName: PropTypes.string,
  showFirstButtonInformLabel: PropTypes.bool
};

export default InformLabel;
