import { put, take } from 'redux-saga/effects';
import {
  showSuccessMessage,
  GENERATE_REPORT_REQUEST,
  showGenerateReportError
} from '../../../../reducers/generateReport.reducer';
import generateReportRequest from './generateReportRequest';

export default function* generateReport() {
  console.log('[generateReport Saga] Beginning');

  while (true) {
    yield take(GENERATE_REPORT_REQUEST);

    console.log('[generateReport Saga] Start Request');
    const result = yield generateReportRequest();
    const status = result.status ? result.status.toLowerCase() : 'error';

    const failed = !!result.error || status !== 'success';
    const apiError = result.error || status !== 'success' ? result.message : null;

    if (!failed) {
      console.log('[generateReport Saga] Request completed, showing results');
      yield put(showSuccessMessage(result.data));
    } else {
      console.log('[generateReport Saga] Request completed, but failed, showing error');
      yield put(showGenerateReportError(apiError));
    }
  }
}
