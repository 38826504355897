import PropTypes from 'prop-types';
import React from 'react';
import Button, { buttonTypes } from '../Button/Button';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../language/withLocalizedContent';

function DeferEmailsSuccess({ localizedContent: { deferEmailsSuccess: content }, secondaryButtonAction }) {
  return (
    <div className="grid">
      <div className="row">
        <div className="col">
          <PopoutPanelIconHeading type={types.VALID} title={content.title} />
          <p className="lead text-center gin-bot3">{content.subtitle}</p>
          <div className="row cols-center">
            <span className="gin-right2">
              <Button
                id="secondary-button"
                type={buttonTypes.SECONDARY}
                text={content.secondary_button_text}
                onClick={secondaryButtonAction}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

DeferEmailsSuccess.propTypes = {
  localizedContent: PropTypes.object,
  secondaryButtonAction: PropTypes.func.isRequired
};

export default withLocalizedContent('deferEmailsSuccess')(DeferEmailsSuccess);
