import { put } from 'redux-saga/effects';
import {
  assignProductFinderProductsFailure,
  assignProductFinderProductsSuccess
} from '../../../../actions/productFinderActions';
import { pollClassAssignmentsAdded } from '../dataRecency/pollClassAssignments';
import createLearningAssignmentApi from '../../../apiCalls/library/createLearningAssignment.api';

export default function* assignProductFinderProducts({
  orgId,
  classId,
  existingAssignmentId,
  assignLicences,
  teacherIdList,
  studentIdList,
  usersIdsWithLicences,
  product,
  context
}) {
  let shouldAssignLicences = assignLicences;
  const allUsersIds = [...teacherIdList, ...studentIdList];
  let userIdsToAssignTo = [...allUsersIds].filter(id => usersIdsWithLicences.indexOf(id) === -1);

  // if there is nobody to assign to in a groupAssignment context, we assign to all, but without licences
  if (userIdsToAssignTo.length === 0 && !!classId) {
    shouldAssignLicences = false;
    userIdsToAssignTo = allUsersIds;
  }

  const payload = {
    existingAssignmentId,
    classId,
    productId: product.productid,
    groupAssignment: !!classId,
    assignLicences: shouldAssignLicences,
    userIds: [...userIdsToAssignTo]
  };

  const assignmentResult = yield createLearningAssignmentApi(orgId, payload);

  if (assignmentResult.status === 'success') {
    let assignmentIds = [];
    if (assignmentResult.data.assignmentId) {
      assignmentIds.push({ licenseId: assignmentResult.data.assignmentId });
    }
    if (assignmentResult.data.assignmentIds && assignmentResult.data.assignmentIds.length > 0) {
      assignmentIds = assignmentResult.data.assignmentIds.map(licenseId => ({ licenseId }));
    }
    yield pollClassAssignmentsAdded(assignmentIds, {
      orgId,
      isClassroom: payload && !!payload.classId,
      currentContext: context
    });

    return yield put(assignProductFinderProductsSuccess());
  }
  console.log('[assignLearningMaterial] Assignment result:', assignmentResult);
  return yield put(assignProductFinderProductsFailure());
}
