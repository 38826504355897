import { mapValues } from 'lodash';
import curriculumTerms from '../../language/glossaries/en/shared/curriculumTerms.glossary.json';
import appSettings from '../globals/appSettings';
import { YEAR_GROUPS } from '../globals/yearGroup.js';

/**
 * The mapping of year group glossary keys to API values.
 *
 * @type {Object}
 */

/**
 * Get the entries from a given glossary definition for a given locale, or for
 * the default locale if unmatched.
 *
 * This function is not exported and should not be needed by the app beyond
 * this feature. Localised content should otherwise be fetched through the API.
 *
 * @private
 *
 * @param {Object}  glossary
 * @param {?string} locale
 *
 * @return {Object}
 */
function getContentForLocale(glossary, locale = null) {
  return glossary.locales[locale] || glossary.glossary;
}

/**
 * Create a function to return the display names and values for the set of
 * available year group options for the given curriculum type.
 *
 * @param {Object} sharedTerms
 *
 * @return {(curriculumType: ?string) => Object}
 */
export default function createGetYearGroupOptions(sharedTerms) {
  return (curriculumType = 'UK_ENGLAND_WALES') => {
    const { curriculumLocale } = appSettings.curriculumTypes[curriculumType] || {};
    const content = {
      // eslint-disable-next-line prefer-template
      ...getContentForLocale(curriculumTerms, 'en-x-' + curriculumLocale),
      YEAR_NO_GROUP: sharedTerms.YEAR_NO_GROUP
    };

    return mapValues(YEAR_GROUPS, (value, key) => ({
      name: content[key],
      value
    }));
  };
}
