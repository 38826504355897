import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import colors from '../../globals/colors.js';
// TODO: Fetch these from Lambda/CMS:
// import orgRoles from '../../globals/orgRoles.js';

// Action imports
import { setFormState } from '../../redux/reducers/data/forms.reducer.js';

// Component imports
import Link from '../../components/Link/Link';
import Card from '../../components/Card/Card.js';
import Button, { buttonTypes } from '../../components/Button/Button.js';
import DataList from '../../components/DataList/DataList.js';
import PageHeading from '../../components/PageHeading/PageHeading.js';

import cmsContent from '../../utils/cmsContent.js';

function CreateOrgReview({
  orgNameValue,
  // orgRoleValue,
  orgEmailValue,
  orgWebsiteValue,

  gotoOrgEditAction,
  createOrgAction
}) {
  const CMS = cmsContent.createOrganisation || {};
  return (
    <div>
      <div className="row">
        <div className="col">
          <PageHeading title={CMS.review_details_title_text} subtitle={CMS.review_details_subtitle_text} />
        </div>
      </div>

      <div className="row gin-top4">
        <div className="col sm8">
          <Card headingText={CMS.register_organisation_header} headingColor={colors.ORGANIZATION}>
            <div className="row">
              <div className="col xs8">
                <DataList
                  dataPairs={[
                    { title: CMS.organisation_list_item_name_text, value: orgNameValue },
                    // temporarily hidden
                    // {
                    //   title: CMS.organisation_list_item_type_text,
                    //   value: orgRoles[orgRoleValue] || orgRoleValue
                    // },
                    { title: CMS.organisation_list_item_email_text, value: orgEmailValue },
                    {
                      title: CMS.organisation_list_item_webaddress_text,
                      value: orgWebsiteValue || 'n/a'
                    }
                  ]}
                />

                <div className="gin-top4">
                  <Button
                    id="orgFormSubmit"
                    text={CMS.button_register_text}
                    type={buttonTypes.PRIMARY}
                    onClick={createOrgAction}
                  />
                </div>
              </div>

              <div className="col xs4 gin-top2">
                <Link
                  to="/org/register"
                  id="orgFormGotoEdit"
                  onClick={e => {
                    e.preventDefault();
                    gotoOrgEditAction();
                  }}
                >
                  {CMS.edit_link_text}
                </Link>
              </div>
            </div>
          </Card>
        </div>

        <div className="col sm4">
          <Card headingText={CMS.support_heading_title_text} headingColor={colors.DASHBOARD}>
            <h3>{CMS.support_heading_text}</h3>
            <p>
              {CMS.support_body_text}
              <Link to="#LearnMore" className="block">
                {CMS.support_learn_more_link}
              </Link>
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
}

CreateOrgReview.propTypes = {
  orgNameValue: PropTypes.string,
  // orgRoleValue: PropTypes.string,
  orgEmailValue: PropTypes.string,
  orgWebsiteValue: PropTypes.string,

  // Navigation actions:
  gotoOrgEditAction: PropTypes.func.isRequired,
  createOrgAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    // Connect values
    orgNameValue: state.fields['orgCreate.orgName'].value,
    orgRoleValue: state.fields['orgCreate.orgRole'].value,
    orgEmailValue: state.fields['orgCreate.orgEmail'].value,
    orgWebsiteValue: state.fields['orgCreate.orgWebsite'].value
  }),
  {
    // Navigation actions
    gotoOrgEditAction: () => setFormState('orgCreate', 'EDITING'),
    createOrgAction: () => setFormState('orgCreate', 'SUBMITTING')
  }
)(CreateOrgReview);
