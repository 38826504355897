import { put, select } from 'redux-saga/effects';
import { pick, lowerFirst, has } from 'lodash';
import Papa from 'papaparse';
import uuid from 'uuid';
import {
  checkImportUsersRequest,
  parseImportUsersSourceCompleted,
  parseImportUsersSourceFailure as setFailure
} from '../../../../actions/importUsers';
import { ErrorTypes } from '../../../../../components/ImportUsers/ImportUsersErrorStatus';
import buildUsername from '../../../../../utils/buildUsername';
import sanitizeCSV from '../../../../../utils/sanitizeCSV';
/**
 * The maximum number of data rows to consider.
 *
 * @type {number}
 */
export const MAX_DATA_ROWS = 1000;

/**
 * The set of ordered column headings expected.
 *
 * @type {array}
 */
// prettier-ignore
const COLUMN_HEADINGS = [
  'firstName',
  'lastName',
  'userName',
  'password',
  'yearGroup',
  'class'
];

/**
 * Return the string contents of the given file object.
 *
 * @param {File} file
 *
 * @return {Promise<string>}
 */
function* readSourceFromFile(file) {
  const reader = new FileReader();
  return yield new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error({ error: 'Error while reading the file' }));
    };
    reader.onload = event => resolve(event.target.result);
    reader.readAsText(file);
  });
}

// eslint-disable-next-line consistent-return
function* parseImportUsersSource(source) {
  try {
    const raw = source instanceof File ? yield readSourceFromFile(source) : source;
    const {
      data,
      meta: { fields }
    } = Papa.parse(sanitizeCSV(raw, lowerFirst, COLUMN_HEADINGS), {
      header: true,
      skipEmptyLines: true
    });

    if (data.length > MAX_DATA_ROWS) {
      return yield put(setFailure(ErrorTypes.TOO_MANY_ROWS));
    }

    const missingColumns = (yield select(state => state.importUsers.required)).filter(key => !fields.includes(key));

    if (missingColumns.length !== 0) {
      return yield put(setFailure(ErrorTypes.MISSING_COLUMNS, missingColumns.toString()));
    }

    const usernameOccurrences = {};

    const users = Object.values(data).reduce((acc, user) => {
      let userName = user.userName || buildUsername(user.firstName, user.lastName);
      const id = uuid.v4();

      usernameOccurrences[userName] = (usernameOccurrences[userName] || 0) + 1;

      if (usernameOccurrences[userName] > 1) {
        userName += usernameOccurrences[userName];
      }

      return {
        ...acc,
        [id]: {
          id,
          ...pick(user, COLUMN_HEADINGS),
          userName,
          password: user.password,
          yearGroup:
            // eslint-disable-next-line no-nested-ternary
            user.yearGroup === '' || !has(user, 'yearGroup')
              ? null
              : Number.isNaN(Number(parseInt(user.yearGroup, 10)))
              ? user.yearGroup
              : parseInt(user.yearGroup, 10),
          class: user.class || ''
        }
      };
    }, {});

    yield put(parseImportUsersSourceCompleted(users));
    yield put(checkImportUsersRequest());
  } catch (e) {
    return yield put(setFailure(ErrorTypes.UNRECOGNISED_DATA));
  }
}

export default parseImportUsersSource;
