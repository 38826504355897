import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Table, { columnTypes } from '../../../../components/Table/Table';
import tableStyles from '../../../../components/Table/Table.scss';
import Link from '../../../../components/Link/Link';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function ActivationCodeSearchResults({ localizedContent: { activationCodeSearchResults: content }, results }) {
  const { data } = results.success;
  const getAvailableStatus = status => (status === 'AVAILABLE' ? content.yes : content.no);
  const columns = [
    {
      heading: content.code,
      type: columnTypes.TEXT,
      width: '20%'
    },
    {
      heading: content.product,
      type: columnTypes.TEXT,
      width: '30%'
    },
    {
      heading: content.productGroup,
      type: columnTypes.TEXT,
      width: '30%'
    },
    {
      heading: content.available,
      type: columnTypes.TEXT,
      aligned: 'center',
      width: '5%'
    }
  ];
  const rows = data.map(
    ({ activationCode, productId, productGroupId, productName, productGroupName, status }, index) => ({
      id: `row_${index}`,
      cells: [
        <div key={`link_code_${index}`}>
          <a href={`/dashboard_eps/ac_detail/${activationCode}`}>{activationCode}</a>
        </div>,
        <div key={`link_product_${index}`}>{productName && <Link to={`/${productId}`}>{productName}</Link>}</div>,
        <div key={`link_product_group_${index}`}>
          {productGroupName && <Link to={`/${productGroupId}`}>{productGroupName}</Link>}
        </div>,
        <div key={`active_${index}`}>{getAvailableStatus(status)}</div>
      ]
    })
  );

  return <Table columns={columns} rows={rows} customClass={tableStyles.leftAligned} />;
}

ActivationCodeSearchResults.propTypes = {
  localizedContent: PropTypes.object,
  results: PropTypes.object
};

export default compose(withLocalizedContent('activationCodeSearchResults'))(ActivationCodeSearchResults);
