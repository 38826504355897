import * as t from '../actionTypes';

const initialState = {
  selectedProduct: null,
  products: {
    OLB: {
      submitting: false,
      success: false,
      failure: false,
      errors: {}
    },
    HUB: {
      submitting: false,
      success: false,
      failure: false,
      errors: {}
    }
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.CONTACT_US_CHANGE_PRODUCT_SELECTION:
      return {
        ...initialState,
        selectedProduct: payload
      };
    case t.CONTACT_US_REQUEST:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.platformCode]: {
            ...state.OLB,
            submitting: true
          }
        }
      };
    case t.CONTACT_US_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          [payload]: {
            ...state.products[payload],
            submitting: false,
            success: true
          }
        }
      };
    case t.CONTACT_US_FAILURE:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.platformCode]: {
            ...state.products[payload.platformCode],
            submitting: false,
            failure: true
          }
        }
      };
    case t.CONTACT_US_VALIDATE_COMPLETED:
      return {
        ...state,
        submitting: false,
        products: {
          ...state.products,
          [state.selectedProduct]: {
            ...state.products[state.selectedProduct],
            errors: {
              ...state.products[state.selectedProduct].errors,
              ...payload
            }
          }
        }
      };
    default:
      return state;
  }
};
