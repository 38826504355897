import * as t from '../actionTypes';

const initialState = {
  submitting: false,
  success: false,
  failure: false,
  signedURL: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.SET_MANAGED_USER:
    case t.USER_ENROL_RESET:
      return { ...initialState };
    case t.GENERATE_MANAGED_USER_SIGN_IN_CARD_REQUEST:
      return {
        ...initialState,
        submitting: true
      };
    case t.GENERATE_MANAGED_USER_SIGN_IN_CARD_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        failure: false,
        signedURL: payload
      };
    case t.GENERATE_MANAGED_USER_SIGN_IN_CARD_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        failure: true
      };
    default:
      return state;
  }
};
