import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import InvitationUpdateForm from './InvitationUpdateForm.js';

class InvitationUpdatePage extends Component {
  componentWillMount() {
    const { history, invitationPageValid, context } = this.props;

    if (!invitationPageValid) {
      history.push(context === 'NGS' ? '/ngs' : '/');
    }
  }

  render() {
    const { inviteStatus } = this.props;

    return (
      inviteStatus === 'ACCEPTED' && (
        <div>
          {/* TODO: move this title to a glossary */},
          <Helmet title="Invitation" />
          <div className="grid">
            <div className="row">
              <div className="col pad-top2 pad-bot4">
                <InvitationUpdateForm />
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

InvitationUpdatePage.propTypes = {
  history: PropTypes.object.isRequired,
  inviteStatus: PropTypes.string,
  invitationPageValid: PropTypes.bool.isRequired,
  context: PropTypes.string
};

export default withRouter(
  connect(state => ({
    invitationPageValid: state.invites.formState === 'SUBMITTING' || state.invites.formState === 'CONFIRMATION',
    inviteStatus: state.invites.invites.reduce(
      (result, curr) => (curr.orgId === state.invites.orgId ? curr.newStatus : result),
      null
    )
  }))(InvitationUpdatePage)
);
