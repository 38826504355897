import { takeEvery, takeLatest } from 'redux-saga/effects';
import createOrgSubscriptionRequestBodySchema from '../../../../../../static/schema/draft-4/add-org-subscription-request-body.json';
import createOrgSubscriptionRequestParamsSchema from '../../../../../../static/schema/draft-4/add-org-subscription-request-params.json';
import createOrgSchema from '../../../../../../static/schema/draft-4/create-org-request-body.json';
import updateOrgSubscriptionRequestBodySchema from '../../../../../../static/schema/draft-4/edit-org-subscriptions-request-body.json';
import updateOrgSubscriptionRequestParamsSchema from '../../../../../../static/schema/draft-4/edit-org-subscriptions-request-params.json';
import * as t from '../../../../actionTypes';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import createOrgSubscription from './createOrgSubscription';
import validateOrgSubscription from './validateOrgSubscription';

import generateOrgUniqueId from './generateOrgUniqueId';
import getPartialOrg from './getPartialOrg';
import registerOrg from './registerOrg';
import registerOrgAdmin from './registerOrgAdmin';
import reviewOrgInput from './reviewOrgInput';
import updateOrgSubscription from './updateOrgSubscription';
import validateOrgAdminInput from './validateOrgAdminInput';
import validateOrgSubscriptionInputUsingSchema from './validateOrgSubscriptionInputUsingSchema';
import linkOicOrgToJanison from './linkOicOrgToJanison';
import getOrgDetails from './getOrgDetails';

function* root() {
  yield takeLatest(t.VALIDATE_ORG_ADMIN_INPUT, ({ payload: input }) => validateOrgAdminInput(input));
  yield takeLatest(t.REGISTER_ORG_ADMIN_REQUEST, ({ payload: input }) => registerOrgAdmin(input));
  yield takeLatest(t.VALIDATE_ORG_INPUT, ({ payload: input }) =>
    validateInputUsingSchema(createOrgSchema, t.VALIDATE_ORG_COMPLETED, input)
  );
  yield takeLatest(t.REVIEW_ORG_INPUT, ({ branch, payload: input }) => reviewOrgInput(input, branch));
  yield takeLatest(t.REGISTER_ORG_REQUEST, ({ branch, payload: input }) => registerOrg(input, branch));
  yield takeLatest(t.GENERATE_ORG_UNIQUE_ID_REQUEST, generateOrgUniqueId);
  yield takeLatest(t.GET_PARTIAL_ORG_REQUEST, ({ payload: id }) => getPartialOrg(id));
  yield takeLatest(t.CREATE_ORG_SUBSCRIPTION_REQUEST, ({ payload: input }) => createOrgSubscription(input));
  yield takeLatest(t.CREATE_ORG_SUBSCRIPTION_VALIDATE, ({ payload: input }) => validateOrgSubscription(input));
  yield takeLatest(t.UPDATE_ORG_SUBSCRIPTION_REQUEST, ({ payload: input }) => updateOrgSubscription(input));
  yield takeLatest(t.VALIDATE_CREATE_ORG_SUBSCRIPTION_INPUT, ({ payload: { input, additionalProps } }) =>
    validateOrgSubscriptionInputUsingSchema(
      createOrgSubscriptionRequestParamsSchema,
      createOrgSubscriptionRequestBodySchema,
      t.VALIDATE_CREATE_ORG_SUBSCRIPTION_COMPLETED,
      input,
      additionalProps
    )
  );
  yield takeLatest(t.VALIDATE_UPDATE_ORG_SUBSCRIPTION_INPUT, ({ payload: { input, additionalProps } }) =>
    validateOrgSubscriptionInputUsingSchema(
      updateOrgSubscriptionRequestParamsSchema,
      updateOrgSubscriptionRequestBodySchema,
      t.VALIDATE_UPDATE_ORG_SUBSCRIPTION_COMPLETED,
      input,
      additionalProps
    )
  );
  yield takeEvery(t.OIC_LINK_ORG_TO_JANISON_REQUEST, ({ payload: { input } }) => linkOicOrgToJanison(input));
  yield takeLatest(t.GET_ORG_REQUEST, ({ payload: id }) => getOrgDetails(id));
}

export default root;
