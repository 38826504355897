import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLocalizedContent from '../../../language/withLocalizedContent.js';

import classnames from 'classnames';
import ExpandableItem from '../../../components/ExpandableItem/ExpandableItem';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { bgClass } from '../../../utils/colorClassNames';
import styles from './DialsAccordion.scss';
import CorrelationChartTab from '../../../components/CorrelationChartTab/CorrelationChartTab';
import { featureIsEnabled } from '../../../globals/envSettings';

import OrbReportDials from '../../../components/OrbReportDials/OrbReportDials';

class DialsAccordion extends Component {
  static propTypes = {
    dialsData: PropTypes.object,
    locationId: PropTypes.string.isRequired,
    localizedContent: PropTypes.object.isRequired,
    orbClassReportTab: PropTypes.number,
    orgSubscriptions: PropTypes.object
  };

  state = {
    classReportExpanded: true,
    statusExpanded: false,
    correlationChartExpanded: false
  };

  /**
   * The desired behaviour of the composed accordion
   * is defined in this function.
   */
  _handleToggleExpand = name => () => this.setState(prevState => ({ [name]: !prevState[name] }));

  renderClassOverviewReport() {
    const {
      localizedContent: { classReportComponent: i18n },
      dialsData,
      orbClassReportTab,
      orgSubscriptions
    } = this.props;
    return (
      <div>
        {dialsData ? <OrbReportDials dialsData={this.props.dialsData} locationId={this.props.locationId} /> : null}
        {(featureIsEnabled('orb-class-report-overview-ui-without-avallain-data') && orbClassReportTab === 0) || (featureIsEnabled('orb-class-report-overview') && orbClassReportTab === 1)? (
          <ExpandableItem
            label="Progress status key"
            expanded={this.state.statusExpanded}
            onToggleExpand={this._handleToggleExpand('statusExpanded')}
          >
            <div className={classnames(styles.statusContainer, 'grid')}>
              <div className="row">
                <div className="col sm3">
                  <div className={styles.keySection}>
                    <div className={classnames(styles.icon, bgClass('ahead'))}>
                      <SVGIcon glyph={GLYPHS.ICON_STAR} />
                    </div>
                    <div>{i18n.ahead_rate}</div>
                  </div>
                </div>
                <div className="col sm3">
                  <div className={classnames(styles.keySection)}>
                    <div className={classnames(styles.icon, bgClass('expected'))}>
                      <SVGIcon glyph={GLYPHS.ICON_CIRCLE} />
                    </div>
                    <div>{i18n.at_expected_rate}</div>
                  </div>
                </div>
                <div className="col sm3">
                  <div className={classnames(styles.keySection)}>
                    <div className={classnames(styles.icon, bgClass('behind'))}>
                      <SVGIcon glyph={GLYPHS.ICON_DIAMOND} />
                    </div>
                    <div>{i18n.behind_rate}</div>
                  </div>
                </div>
                <div className="col sm3">
                  <div className={classnames(styles.keySection)}>
                    <div className={classnames(styles.icon, bgClass('very-behind'))}>
                      <SVGIcon glyph={GLYPHS.ICON_HEXAGON} />
                    </div>
                    <div>{i18n.significantly_behind}</div>
                  </div>
                </div>
              </div>
            </div>
          </ExpandableItem>
        ) : null}
        {this.props.locationId !== 'af' &&
        ((featureIsEnabled('orb-class-report-overview-ui-without-avallain-data') && orbClassReportTab === 0) || (featureIsEnabled('orb-class-report-overview') && orbClassReportTab === 1)) &&
        Object.values(orgSubscriptions).length > 0 &&
        Object.values(orgSubscriptions).find(subscription => subscription.productDetails.market !== 'ASIA') ? (
          <ExpandableItem
            label="Level correlation chart"
            expanded={this.state.correlationChartExpanded}
            onToggleExpand={this._handleToggleExpand('correlationChartExpanded')}
          >
            <CorrelationChartTab locationId={this.props.locationId} />
          </ExpandableItem>
        ) : null}
      </div>
    );
  }

  renderDials() {
    const {
      localizedContent: { classReportComponent: i18n },
      orgSubscriptions
    } = this.props;
    return (
      <div className="gin-top2">
        <ExpandableItem
          label="Class report"
          expanded={this.state.classReportExpanded}
          onToggleExpand={this._handleToggleExpand('classReportExpanded')}
        >
          {this.props.dialsData ? (
            <OrbReportDials dialsData={this.props.dialsData} locationId={this.props.locationId} />
          ) : null}
        </ExpandableItem>
        <ExpandableItem
          label="Progress status key"
          expanded={this.state.statusExpanded}
          onToggleExpand={this._handleToggleExpand('statusExpanded')}
        >
          <div className={classnames(styles.statusContainer, 'grid')}>
            <div className="row">
              <div className="col sm3">
                <div className={styles.keySection}>
                  <div className={classnames(styles.icon, bgClass('ahead'))}>
                    <SVGIcon glyph={GLYPHS.ICON_STAR} />
                  </div>
                  <div>{i18n.ahead_rate}</div>
                </div>
              </div>
              <div className="col sm3">
                <div className={classnames(styles.keySection)}>
                  <div className={classnames(styles.icon, bgClass('expected'))}>
                    <SVGIcon glyph={GLYPHS.ICON_CIRCLE} />
                  </div>
                  <div>{i18n.at_expected_rate}</div>
                </div>
              </div>
              <div className="col sm3">
                <div className={classnames(styles.keySection)}>
                  <div className={classnames(styles.icon, bgClass('behind'))}>
                    <SVGIcon glyph={GLYPHS.ICON_DIAMOND} />
                  </div>
                  <div>{i18n.behind_rate}</div>
                </div>
              </div>
              <div className="col sm3">
                <div className={classnames(styles.keySection)}>
                  <div className={classnames(styles.icon, bgClass('very-behind'))}>
                    <SVGIcon glyph={GLYPHS.ICON_HEXAGON} />
                  </div>
                  <div>{i18n.significantly_behind}</div>
                </div>
              </div>
            </div>
          </div>
        </ExpandableItem>
        {this.props.locationId !== 'af' &&
        Object.values(orgSubscriptions).length > 0 &&
        Object.values(orgSubscriptions).find(subscription => subscription.productDetails.market !== 'ASIA') ? (
          <ExpandableItem
            label="Level correlation chart"
            expanded={this.state.correlationChartExpanded}
            onToggleExpand={this._handleToggleExpand('correlationChartExpanded')}
          >
            <CorrelationChartTab locationId={this.props.locationId} />
          </ExpandableItem>
        ) : null}
      </div>
    );
  }

  render() {
    return featureIsEnabled('orb-class-report-overview') || featureIsEnabled('orb-class-report-overview-ui-without-avallain-data') ? this.renderClassOverviewReport() : this.renderDials();
  }
}

export default compose(
  withLocalizedContent('classReportComponent'),
  connect(
    state => ({
      orbClassReportTab: state.savedSettings.settings.orbClassReportTab,
      orgSubscriptions: state.subscriptions
    }),
    {}
  )
)(DialsAccordion);
