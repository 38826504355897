// IMPORTANT: When refactoring forms to use this reducer, don't forget to update tracking in /utils/tracking/formState.js.

import * as t from '../../actionTypes';

export const SET_FORM_STATE = 'forms/SET_FORM_STATE';
export const SET_FORM_RESULT = 'forms/SET_FORM_RESULT';

const initialFormState = {
  formState: null,

  // Field data
  validFields: [],
  invalidFields: [],
  changedFields: [],

  // Result data
  failed: null,
  data: null
};

const initialState = {
  // Stores each instance of a form at root level
};

export default function forms(state = initialState, action = {}) {
  switch (action.type) {
    case t.SET_FORM_STATE:
      return {
        ...state,
        [action.formName]: {
          // First form state set will pull in the initial values
          ...(state[action.formName] || initialFormState),
          formState: action.formState
        }
      };

    case t.SET_FIELD_VALIDITY:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          validFields:
            action.isValid || action.isWarning
              ? // Field is now valid so add to valid list
                [...state[action.formName].validFields, action.fieldName]
              : // Field is now valid so remove from invalid list
                state[action.formName].validFields.filter(fieldName => fieldName !== action.fieldName),
          invalidFields: action.isInvalid
            ? // Field is now invalid so add to invalid list
              [...state[action.formName].invalidFields, action.fieldName]
            : // Field is now valid so remove from invalid list
              state[action.formName].invalidFields.filter(fieldName => fieldName !== action.fieldName),
          changedFields: action.hasChanged
            ? // Field has changed so add it to the changed fields list
              [...state[action.formName].changedFields, action.fieldName]
            : // Field is on its initial value so remove it from the changed fields list
              state[action.formName].changedFields.filter(fieldName => fieldName !== action.fieldName)
        }
      };

    case t.SET_FORM_RESULT:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          formState: action.formState,
          failed: action.failed,
          data: action.data
        }
      };

    case t.RESET_FORM:
      return {
        ...state,
        [action.formName]: {
          ...initialFormState,
          formState: action.formState
        }
      };

    default:
      return state;
  }
}

export const setFormState = (formName, formState) => ({
  type: t.SET_FORM_STATE,
  formName,
  formState
});

export const setFieldValidity = (formName, fieldName, isValid, isInvalid, hasChanged, isWarning) => ({
  type: t.SET_FIELD_VALIDITY,
  formName,
  fieldName,
  isValid,
  isInvalid,
  hasChanged,
  isWarning
});

export const setFormResult = (formName, formState, failed, data) => ({
  type: t.SET_FORM_RESULT,
  formName,
  formState,
  failed,
  data
});

export const resetForm = (formName, formState) => ({
  type: t.RESET_FORM,
  formName,
  formState
});
