import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PopoutPanelIconHeading, { types } from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';

// Form states
import { formStates } from '../../../../redux/reducers/user/licenceRecall.reducer.js';

// Sub-view components
import LicenceRecallInputting from './licenceRecallInput.js';
import LicenceRecallComplete from './licenceRecallComplete.js';

import content from '../../../../utils/cmsContent.js';
// const content = {
//   licenceRecallPage: {
//     button_close_text: 'Close',
//     button_submit_text: 'Recall licences',

//     beforeSubmit_heading_text: 'Are you sure you want to recall this licence from the following students?',
//     beforeSubmit_subheading_text: 'Any recalled licences will be returned to the library.',

//     duringSubmit_heading_text: 'Please wait...',
//     duringSubmit_subheading_text: 'The licences are being recalled',

//     afterSubmit_success_heading_text: 'You recalled this licence from the following students',
//     afterSubmit_success_subheading_text: 'The licences have been returned to the library',
//     afterSubmit_error_heading_text: 'There was an error.',
//     afterSubmit_error_subheading_text: 'No licences have been recalled.'
//   }
// };

function LicenceRecallPanel({ closePanel, formState }) {
  const CMS = content.licenceRecallPage || {};

  switch (formState) {
    case formStates.INPUTTING:
      return (
        <div>
          <LicenceRecallInputting closePanel={closePanel} />
        </div>
      );

    case formStates.SUBMITTING:
      return (
        <div>
          <PopoutPanelIconHeading
            type={types.LOADING}
            title={CMS.duringSubmit_heading_text}
            subtitle={CMS.duringSubmit_subheading_text}
          />
        </div>
      );

    case formStates.CONFIRMATION:
      return (
        <div>
          <LicenceRecallComplete closePanel={closePanel} />
        </div>
      );

    default:
      return <p>Error. Missing a form for `{formState}`.</p>;
  }
}

// Define props:
LicenceRecallPanel.propTypes = {
  closePanel: PropTypes.func.isRequired,
  formState: PropTypes.string.isRequired
};

export default connect(state => ({
  formState: state.licenceRecall.formState
}))(LicenceRecallPanel);
