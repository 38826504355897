import { put, select } from 'redux-saga/effects';
import { ORG_REGISTRATION_CONTEXT, JSON_ENCODED } from '@oup/shared-node-browser/constants';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';
import getCreateOrgRequestApiContext from './getCreateOrgRequestApiContext';
import validateOrgInput from './validateOrgInput';
import session from '../../../../../utils/session';
import UserRoles from '../../../../../globals/userRoles';
import { pollOrgUsersAdded } from '../dataRecency/pollOrgUsers';

import { featureIsEnabled } from '../../../../../globals/envSettings';
import getIdentity from '../../../preRender/refactor/auth/getIdentity.saga';
import getIdentityLegacy from '../../../preRender/auth/getIdentity.saga';

function* registerOrg(input, branch) {
  input.clientTimezone = input.clientTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const hasErrors = yield validateOrgInput(input, branch);
  if (hasErrors) {
    return;
  }
  const { orgId } = yield select(state => state.orgRegistration);
  const { userId } = yield select(state => state.identity);
  const { name, url, version, method, body } = yield getCreateOrgRequestApiContext(input, branch);
  const response = yield signedFetch(name, url, method, body, {
    Accept: version,
    'Content-Type': JSON_ENCODED
  });
  if (response.status === 'success') {
    const groupId = response.data.orgId || response.data.groupId || orgId;
    const credentialData = session.get();
    credentialData.orgRoleMap[groupId] = UserRoles.ORG_ADMIN;
    session.set(credentialData);

    if (featureIsEnabled('single-client-session-store')) {
      yield getIdentity();
    } else {
      yield getIdentityLegacy();
    }
    const people = { [userId]: { userId, roleName: UserRoles.ORG_ADMIN } };
    if (branch !== ORG_REGISTRATION_CONTEXT.OUP_CUSTOMER_SUPPORT) {
      yield pollOrgUsersAdded([userId], people, groupId);
    }

    yield put(actions.registerOrgSuccess(groupId));
    return;
  }
  yield put(actions.registerOrgFailure(response.errorMessages));
}

export default registerOrg;
