import { select } from 'redux-saga/effects';

import { upperFirst } from 'lodash';
import bulkUserUploadApi from '../../../../apiCalls/bulkUserUploadApi.js';
import sanitizeCSV from '../../../../../../utils/sanitizeCSV';
import { getCurrentPlatform } from '../../../../../../utils/platform';

const COLUMN_HEADINGS = ['userName', 'firstName', 'lastName', 'emailAddress', 'roleName', 'class'];

export default function* bulkUserUploadRequest() {
  const { orgId, actioningUserId, actioningUserEmail, fileContents } = yield select(state => {
    const currentOrgId = state.organisationPage.orgId;
    return {
      orgId: currentOrgId,
      fileContents: state.enrolUser.file.data,
      actioningUserId: state.identity.userId,
      actioningUserEmail: state.identity.email
    };
  });

  const processedFileContents = sanitizeCSV(fileContents, upperFirst, COLUMN_HEADINGS);

  console.log(
    `[bulkUserUploadRequest Saga] Submitting request to upload users: ${JSON.stringify(processedFileContents)}.`,
    actioningUserId
  );
  return yield bulkUserUploadApi(orgId, {
    actioningUserId,
    actioningUserEmail,
    fileContents: processedFileContents,
    platformCode: getCurrentPlatform()
  });
}
