import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import PersonRepresentation from '../PersonRepresentation/PersonRepresentation';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import { SIZES as THUMBNAIL_SIZES } from '../Thumbnail/Thumbnail';
import ValidationStatus from '../ValidationStatus/ValidationStatus';

class ManagedUserUpdatePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordTouched: false
    };
  }

  _handlePasswordBlur = () => {
    this.setState(({ isPasswordTouched }) => (!isPasswordTouched ? { isPasswordTouched: true } : {}));
  };

  _handleGeneratePassword = async () => {
    const { onGeneratePassword } = this.props;
    await onGeneratePassword();
    this._handlePasswordBlur();
  };

  _getPasswordErrorMessage() {
    const {
      localizedContent: { managedUserUpdatePasswordForm: content },
      password = '',
      passwordMinLength,
      isPasswordUsed = false,
      errors = {}
    } = this.props;

    switch (true) {
      case password.length === 0:
        return content.validation_password_required;
      case password.length < passwordMinLength:
        return content.validation_password_min_length.replace('*number*', passwordMinLength);
      case isPasswordUsed:
        return content.validation_password_used;
      case errors.newPassword:
        return content.password_invalid_error;
      case errors.generatedPassword && errors.generatedPassword.length > 0:
        return errors.generatedPassword.join(' ');
      default:
        return '';
    }
  }

  render() {
    const {
      localizedContent: { managedUserUpdatePasswordForm: content },
      title,
      detail = null,
      firstName,
      lastName,
      username,
      password = '',
      userLocked,
      onPasswordChange,
      onSubmit,
      generatingPassword
    } = this.props;
    const { isPasswordTouched } = this.state;
    const passwordError = this._getPasswordErrorMessage();

    return (
      <ScrollContainer
        headerContent={
          <header className="gin-top2 gin-bot2">
            <PersonRepresentation
              thumbnailSize={THUMBNAIL_SIZES.MEDIUM}
              headerSizeName
              name={`${firstName} ${lastName}`}
              email={username}
              locked={userLocked}
            />
          </header>
        }
        footerContent={
          <PopoutActionFooter
            primaryButtonAction={onSubmit}
            primaryButtonText={content.button_save_changes}
            primaryButtonDisabled={!!passwordError}
          />
        }
      >
        <div className="pad2">
          <h2>{title}</h2>
          {typeof detail === 'string' ? <p>{detail}</p> : detail}

          <div className="gin-top3">
            <ValidationStatus
              forId="password"
              hideIcon={!isPasswordTouched}
              {...(generatingPassword
                ? {
                    type: 'waiting',
                    isActive: true
                  }
                : {
                    isActive: isPasswordTouched && !!passwordError,
                    message: passwordError
                  })}
            >
              <TextInputWithButton
                id="password"
                label={content.input_password_label}
                value={password}
                onChange={onPasswordChange}
                onBlur={this._handlePasswordBlur}
                required
                buttonTitle={content.button_password_generate_title}
                buttonText={content.button_password_generate_title}
                buttonAction={this._handleGeneratePassword}
              />
            </ValidationStatus>
            <p className="gin-top1">{content.password_guidance_line1}</p>
            <p className="gin-top1">{content.password_guidance_line2}</p>
            <p className="gin-top1">{content.password_guidance_line3}</p>
            <p className="gin-top1">{content.password_notice}</p>
          </div>
        </div>
      </ScrollContainer>
    );
  }
}

ManagedUserUpdatePasswordForm.propTypes = {
  localizedContent: PropTypes.object,
  title: PropTypes.string.isRequired,
  detail: PropTypes.node,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string,
  errors: PropTypes.array,
  passwordMinLength: PropTypes.number.isRequired,
  isPasswordUsed: PropTypes.bool,
  onPasswordChange: PropTypes.func.isRequired,
  onGeneratePassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userLocked: PropTypes.bool,
  generatingPassword: PropTypes.bool
};

export default withLocalizedContent('managedUserUpdatePasswordForm')(ManagedUserUpdatePasswordForm);
