// Helper to return true if an element can receive focus.

import closest from './querySelectorClosest.js';

const COMMAS = /,/g;

export const candidates = 'input,select,textarea,button,iframe,area,[href],[tabindex]';
export const unfocusables =
  '.a11y-hide,[hidden],[disabled],[aria-hidden="true"],[aria-disabled="true"],[tabindex="-1"]';
export const notUnfocusables = `:not(${unfocusables.replace(COMMAS, '):not(')})`;

// Generate a humungous CSS selector to find focusable elements:
// Note: Anything [contentEditable=true] is focusable too but we don't use any.
export const focusables = `${candidates},`.replace(COMMAS, `${notUnfocusables},`).slice(0, -1);

// Very hard to detect whether elements are visible & focusable
// but these seem to be sufficient in our use cases so far:
// We also check whether the element or any of its parents are .a11y-hide etc.
// https://davidwalsh.name/offsetheight-visibility
export default function isFocusable(elem) {
  return !!(elem && (elem.offsetHeight || elem.offsetWidth) && !closest(elem, unfocusables));
}
