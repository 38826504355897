import * as t from '../actionTypes';

export const getProductFinderProductsSuccess = (products = []) => ({
  type: t.PRODUCT_FINDER_GET_PRODUCTS_SUCCESS,
  payload: products
});
export const getProductFinderProductsFailure = (products = []) => ({
  type: t.PRODUCT_FINDER_GET_PRODUCTS_FAILURE,
  payload: products
});

export const getProductFinderProducts = (searchTerm = '') => ({
  type: t.PRODUCT_FINDER_GET_PRODUCTS_REQUEST,
  payload: searchTerm
});

export const setProductFinderFilters = (filters = {}) => ({
  type: t.PRODUCT_FINDER_SET_FILTERS,
  payload: filters
});

export const getProductFinderSeries = (searchTerm = '') => ({
  type: t.PRODUCT_FINDER_GET_SERIES_REQUEST,
  payload: searchTerm
});

export const getProductFinderSeriesSuccess = (series = []) => ({
  type: t.PRODUCT_FINDER_GET_SERIES_SUCCESS,
  payload: series
});

export const getProductFinderSeriesFailure = (series = []) => ({
  type: t.PRODUCT_FINDER_GET_SERIES_FAILURE,
  payload: series
});

export const getProductFinderProductsForSeriesRequest = (series = {}) => ({
  type: t.PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_REQUEST,
  payload: series
});

export const getProductFinderProductsForSeriesSuccess = (products = {}) => ({
  type: t.PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_SUCCESS,
  payload: products
});

export const getProductFinderProductsForSeriesFailure = (error = '') => ({
  type: t.PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_FAILURE,
  payload: error
});

export const selectProductFinderProduct = (productId = '') => ({
  type: t.PRODUCT_FINDER_SELECT_PRODUCT,
  payload: productId
});
export const selectProductFinderSingleProduct = (product = {}) => ({
  type: t.PRODUCT_FINDER_SELECT_SINGLE_PRODUCT,
  payload: product
});

export const getProductFinderLicencesRequest = payload => ({
  type: t.PRODUCT_FINDER_GET_LICENCES_REQUEST,
  payload
});

export const getProductFinderLicencesSuccess = payload => ({
  type: t.PRODUCT_FINDER_GET_LICENCES_SUCCESS,
  payload
});

export const removeProductFinderSelectedProduct = id => ({
  type: t.PRODUCT_FINDER_REMOVE_SELECTED_PRODUCT,
  payload: id
});

export const getProductFinderLicencesFailure = payload => ({
  type: t.PRODUCT_FINDER_GET_LICENCES_FAILURE,
  payload
});

export const setSelectedCountryCode = payload => ({
  type: t.PRODUCT_FINDER_SET_SELECTED_COUNTRY_CODE,
  payload
});

export const changeProductSeries = () => ({
  type: t.PRODUCT_FINDER_CHANGE_SERIES
});

export const resetProductFinderState = () => ({
  type: t.PRODUCT_FINDER_RESET_STATE
});

export const assignProductFinderProductsRequest = payload => ({
  type: t.PRODUCT_FINDER_ASSIGN_PRODUCTS_REQUEST,
  payload
});

export const assignProductFinderProductsSuccess = payload => ({
  type: t.PRODUCT_FINDER_ASSIGN_PRODUCTS_SUCCESS,
  payload
});

export const assignProductFinderProductsFailure = payload => ({
  type: t.PRODUCT_FINDER_ASSIGN_PRODUCTS_FAILURE,
  payload
});

export const assignMultipleProductsRequest = payload => ({
  type: t.PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_REQUEST,
  payload
});

export const assignMultipleProductsRequestSuccess = payload => ({
  type: t.PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_SUCCESS,
  payload
});

export const assignMultipleProductsRequestFailure = payload => ({
  type: t.PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_FAILURE,
  payload
});

// this action was created to work around the fact that hub and probably ces prop drilling too often,
// if using this component in new projects, avoid using this action to set state and instead declare
// a productFinderPanel and pass props directly to it.
export const setProductFinderData = payload => ({
  type: t.PRODUCT_FINDER_SET_DATA,
  payload
});
export const setFormState = formState => ({
  type: t.PRODUCT_FINDER_SET_CURRENT_FORM_STATE,
  payload: formState
});

export const addProductFinderLicenceData = licence => ({
  type: t.PRODUCT_FINDER_ADD_LICENCE_DATA,
  payload: licence
});

export const setProductFinderAssignLicences = assignLicences => ({
  type: t.PRODUCT_FINDER_SET_ASSIGN_LICENCE,
  payload: assignLicences
});

export const setAssignLicenceToTeacher = assignLicenceToTeacher => ({
  type: t.PRODUCT_FINDER_SET_ASSIGN_LICENCE_TO_TEACHER,
  payload: assignLicenceToTeacher
});

export const selectMultipleProducts = (products, isProductSelected) => ({
  type: t.PRODUCT_FINDER_SELECT_MULTIPLE_PRODUCTS,
  payload: { products, isProductSelected }
});

export const changeSearchType = tab => ({
  type: t.PRODUCT_FINDER_CHANGE_SEARCH_TYPE,
  payload: tab
});

export const clearSelectedProducts = () => ({
  type: t.PRODUCT_FINDER_CLEAR_SELECTED_PRODUCTS
});

export const goToPreviousFormState = () => ({
  type: t.PRODUCT_FINDER_GO_TO_PREVIOUS_FORM_STATE
});

export const setSearchState = payload => ({
  type: t.PRODUCT_FINDER_SET_SEARCH_STATE,
  payload
});

export const clearSearchState = payload => ({
  type: t.PRODUCT_FINDER_CLEAR_SEARCH_STATE,
  payload
});

export const clearAssignmentInitialToggleValue = () => ({
  type: t.PRODUCT_FINDER_SET_ORG_LICENCES_INITIAL_TOGGLE
});
