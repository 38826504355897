import PropTypes from 'prop-types';
import { Component } from 'react';

function mergeDeep(target, source) {
  const isObject = obj => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep({ ...targetValue }, sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

export default class ThemeProvider extends Component {
  getChildContext() {
    const { themeOptions: parentThemeOptions = {} } = this.context;
    const { themeOptions } = this.props;

    return {
      themeOptions: mergeDeep(parentThemeOptions, themeOptions)
    };
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ThemeProvider.propTypes = {
  themeOptions: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired
};

// parent theme
ThemeProvider.contextTypes = {
  themeOptions: PropTypes.object
};

ThemeProvider.childContextTypes = {
  themeOptions: PropTypes.object
};
