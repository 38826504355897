import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import moment from 'moment';
// Redux
import { connect } from 'react-redux';
import { toggleAssessmentLicenceTab } from '../../redux/actions/hubUi';
// Services
import withLocalizedContent from '../../language/withLocalizedContent';
// Components
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import ActionList, { Action } from '../ActionList/ActionList';
// Style
import styles from './OnlineTestReviewSettings.scss';
// Utils
import { getTestTitle } from '../../structure/HubCourseAssessments/Services/getTestData';
import { getDaysHoursMinutesFromMinutes } from '../../utils/getDaysHoursMinutesFromMinutes';

class OnlineTestReviewSettings extends Component {
  getDurationTestMessage = () => {
    const {
      durationTestSet,
      durationInMinutes,
      localizedContent: { hubSetTestOnline: content }
    } = this.props;

    if (!durationTestSet) {
      return content.test_duration_not_set;
    }

    const { days, hours, minutes } = getDaysHoursMinutesFromMinutes(durationInMinutes);

    if (!!days && !!hours && !!minutes) {
      return content.duration_test_days_hours_minutes
        .replace('[days]', `${days}`)
        .replace('[hours]', `${hours}`)
        .replace('[minutes]', `${minutes}`);
    }
    if (!!days && !!hours) {
      return content.duration_test_days_hours.replace('[days]', `${days}`).replace('[hours]', `${hours}`);
    }
    if (!!days && !!minutes) {
      return content.duration_test_days_minutes.replace('[days]', `${days}`).replace('[minutes]', `${minutes}`);
    }
    if (!!hours && !!minutes) {
      return content.duration_test_hours_minutes.replace('[hours]', `${hours}`).replace('[minutes]', `${minutes}`);
    }
    if (days) {
      return content.duration_test_days.replace('[days]', `${days}`);
    }
    if (hours) {
      return content.duration_test_hours.replace('[hours]', `${hours}`);
    }

    return content.duration_test_minutes.replace('[minutes]', `${minutes}`);
  };

  render() {
    const {
      test,
      assessmentTitle,
      classroomName,
      next,
      prev,
      localizedContent: { hubSetTestOnline: content },
      nrOfSelectedStudents,
      totalClassStudents,
      startDate,
      endDate,
      startHour,
      endHour
      // toggleAssessmentLicenceCodeTab,
      // selectedStudentIds = []
    } = this.props;

    const testTitle = getTestTitle(test);

    return (
      <ScrollContainer
        headerContent={
          <header>
            <div>
              <h2>{content.online_test_title}</h2>
              <div>{test ? `${assessmentTitle} ${testTitle}` : ''}</div>
            </div>
          </header>
        }
        footerContent={
          <ActionList>
            <Action label={content.edit_button_text} onClick={() => prev()} />
            <Action label={content.online_test_title} primary onClick={() => next()} />
          </ActionList>
        }
      >
        <div className={styles.contentContainer}>
          <div className={styles.heading}>
            <p>
              {content.confirmation_summary
                .replace('[testTitle]', `${assessmentTitle} ${testTitle}`)
                .replace('[classroomName]', classroomName)
                .replace('[nrOfSelectedStudents]', nrOfSelectedStudents)
                .replace('[nrOfClassStudents]', totalClassStudents)}
            </p>
          </div>
          <div className={styles.sectionContainer}>
            <span>{content.test_opens}</span>
            <span>{`${moment(startDate).format('dddd, D MMMM YYYY')}, ${startHour}`}</span>
          </div>
          <div className={styles.sectionContainer}>
            <span>{content.test_closes}</span>
            <span>{`${moment(endDate).format('dddd, D MMMM YYYY')}, ${endHour}`}</span>
          </div>
          <div className={styles.sectionContainer}>
            <span>{content.test_duration}</span>
            <span>{this.getDurationTestMessage()}</span>
          </div>
          <div className={styles.sectionContainer}>
            <p>{content.email_notification_preference_confirmation}</p>
          </div>
        </div>
        {/*
        The info label is temporarily disabled (EPS-10154).
        The function everyStudentHasLicence() relies on 'licenceStudentsList' state which was previously mocked.
        We will have an implementation for setting the correct data to that field but until then this label is disabled
        {!everyStudentHasLicence(test, selectedStudentIds, endDate) &&
          getInfoLabel(
            content.online_test_info_label,
            content.online_test_button_text_students_without_licence,
            styles.infoLabelContainer,
            GLYPHS.ICON_WARNING_CIRCLE,
            toggleAssessmentLicenceCodeTab
          )}
        */}
      </ScrollContainer>
    );
  }
}

export default compose(
  withLocalizedContent('hubSetTestOnline'),
  connect(
    ({
      search: { profileClasses: { currentClassList = {} } = {}, userSelection: { totalResults = 0 } = {} } = {},
      onlineTest: {
        classId = '',
        startDate = '',
        endDate = '',
        startHour = '',
        endHour = '',
        selectedStudentIds = [],
        durationTestSet = false,
        durationInMinutes = 0
      } = {}
    }) => ({
      classroomName: currentClassList[classId].name,
      totalClassStudents: totalResults,
      nrOfSelectedStudents: selectedStudentIds.length,
      selectedStudentIds,
      startDate,
      endDate,
      startHour,
      endHour,
      durationTestSet,
      durationInMinutes
    }),
    { toggleAssessmentLicenceCodeTab: toggleAssessmentLicenceTab }
  )
)(OnlineTestReviewSettings);

OnlineTestReviewSettings.defaultProps = {
  assessmentTitle: '',
  classroomName: ''
};

OnlineTestReviewSettings.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  next: PropTypes.func,
  prev: PropTypes.func,
  test: PropTypes.object,
  assessmentTitle: PropTypes.string,
  classroomName: PropTypes.string,
  nrOfSelectedStudents: PropTypes.number.isRequired,
  totalClassStudents: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  startHour: PropTypes.string.isRequired,
  endHour: PropTypes.string.isRequired,
  // selectedStudentIds: PropTypes.array,
  // toggleAssessmentLicenceCodeTab: PropTypes.func,
  durationTestSet: PropTypes.bool,
  durationInMinutes: PropTypes.number
};
