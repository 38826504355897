import { takeEvery, put, select } from 'redux-saga/effects';
import {
  SUBMIT_FORM,
  CONFIRM,
  getOrgDetails,
  submissionComplete
} from '../../../../reducers/studentRequestToJoinAClass.reducer';

import getOrgAndClassDetails from '../../../api/getOrgAndClassDetails.js';
import studentSelfEnrol from '../../../api/studentSelfEnrol.js';
import userRoles from '../../../../../globals/userRoles';

import { featureIsEnabled } from '../../../../../globals/envSettings';
import getIdentity from '../../../preRender/refactor/auth/getIdentity.saga';
import getIdentityLegacy from '../../../preRender/auth/getIdentity.saga';

function* fetchOrgAndClassDetails() {
  console.log('[fetchOrgClassDetails generator] Beginning');
  try {
    const { classroomId } = yield select(state => ({
      classroomId: state.studentRequestToJoinAClass.classCodeValue
    }));
    const result = yield getOrgAndClassDetails(classroomId);
    if (result.status === 'success') {
      yield put(getOrgDetails(result.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* handleRequestSubmission() {
  console.log('[handledRequestSubmission generator] Beginning');

  const { classroomId } = yield select(state => ({
    classroomId: state.studentRequestToJoinAClass.classCodeValue
  }));
  let userDetails;
  if (featureIsEnabled('single-client-session-store')) {
    userDetails = yield getIdentity();
  } else {
    userDetails = yield getIdentityLegacy();
  }
  const { firstName, lastName, email } = userDetails.data.user;
  const submit = yield studentSelfEnrol(classroomId, {
    firstName,
    lastName,
    email,
    roleName: userRoles.LEARNER
  });
  const failed = !!submit.error || submit.status !== 'success';
  const apiError = submit.error || submit.status !== 'success' ? { code: submit.code } : null;
  yield put(submissionComplete(apiError, failed));
}

export default function* studentRequestToJoinAClass() {
  console.log('[studentRequestToJoinAClass Saga] Beginning');
  yield takeEvery(SUBMIT_FORM, fetchOrgAndClassDetails);
  yield takeEvery(CONFIRM, handleRequestSubmission);
}
