import { put } from 'redux-saga/effects';
import convertLocalDatetoUTCDate from '../../../../../utils/convertLocalDateToUTCDate';
import actions from '../../../../actions';
import searchActCodeBatches from '../../../apiCalls/searchActCodeBatches';

const buildRequestString = action => {
  const rawRequest = {
    batchName: action.data.batchName,
    productId: action.data.productId,
    productGroupId: action.data.productGroupId,
    activationCode: action.data.activationCode,
    tokenValidDate: action.data.validDate && convertLocalDatetoUTCDate(new Date(action.data.validDate)).toISOString(),
    licenseType: action.data.licenseType
  };
  // Sometimes filters can come in null, we need to avoid sending those to the backend
  const cleanedRequest = Object.entries(rawRequest).filter(
    ([, v]) => v !== null && v !== 'null' && v !== ' ' && v !== '' && v !== undefined
  );
  let requestString = '?';
  cleanedRequest.forEach((keyValuePair, index) => {
    const [key, value] = keyValuePair;
    if (index === 0) {
      requestString += `${key}=${value}`;
    } else {
      requestString += `&${key}=${value}`;
    }
  });
  requestString += action.data.pagination;
  return requestString;
};

function* searchActivationCodeBatch(action) {
  let apiResults = {};
  const requestString = buildRequestString(action);
  apiResults = yield searchActCodeBatches(requestString);

  if (apiResults.error || apiResults.status !== 'success') {
    yield put(actions.setErrorActCodeBatchSearch({ error: apiResults.message }));
  } else {
    yield put(actions.setResultActCodeBatchSearch({ success: apiResults.data }));
  }
}

export default searchActivationCodeBatch;
