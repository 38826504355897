import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
// Style
import styles from '../../HubProductLayout/Resources/ResourceFolder/ResourceFolder.scss';
// Components
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import Link from '../../../components/Link/Link';

class AssessmentFolder extends PureComponent {
  handleKeyDown = e => {
    const { onClick } = this.props;
    if (onClick && e.key === 'Enter') {
      onClick();
    }
    return null;
  };

  render() {
    const { title, locked, itemLink, key, customClassname, onClick } = this.props;
    return (
      <div
        key={key}
        role="button"
        tabIndex={0}
        className={`${styles.itemFolderContainer} ${locked ? styles.itemFolderLockedContainer : ''} ${customClassname ||
          ''}`}
        onClick={onClick || null}
        onKeyDown={this.handleKeyDown}
      >
        {itemLink ? <Link to={itemLink} className={styles.itemFolderLinkContainer} /> : null}
        {locked ? (
          <div className={styles.itemFolderIconContainer}>
            <SVGIcon className={styles.itemFolderIcon} glyph={GLYPHS.ICON_LOCKED_FOLDER} />
          </div>
        ) : (
          <div className={styles.itemFolderIconContainer}>
            <SVGIcon className={styles.itemFolderIcon} glyph={GLYPHS.ICON_UNLOCKED_FOLDER} />
          </div>
        )}
        <div className={title.split(' ').length > 1 ? styles.itemFolderTitleLong : styles.itemFolderTitleShort}>
          {title}
        </div>
      </div>
    );
  }
}

AssessmentFolder.propTypes = {
  title: PropTypes.string,
  locked: PropTypes.bool,
  itemLink: PropTypes.string,
  onClick: PropTypes.func,
  key: PropTypes.any,
  customClassname: PropTypes.string
};

export default AssessmentFolder;
