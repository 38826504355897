import React from 'react';
import PropTypes from 'prop-types';
import TableAccordion from '../../../../components/TableAccordion/TableAccordion';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import styles from './CoursePublish.scss';

function CoursePublishTable({ columns, rows, success, loading, noCourseMessage }) {
  if (success && rows && rows.length > 0) {
    return <TableAccordion columns={columns} rows={rows} />;
  }
  if (success && rows && rows.length === 0) {
    return <p className={styles.no_courses}>{noCourseMessage}</p>;
  }
  if (loading) {
    return <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.LOADING} />;
  }

  return null;
}

CoursePublishTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  success: PropTypes.bool,
  loading: PropTypes.bool,
  noCourseMessage: PropTypes.string
};

export default CoursePublishTable;
