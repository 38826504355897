import React from 'react';
import DialsSkeletonLoader from './DialsSkeletonLoader';
import SubSectionSkeletonLoader from '../SubSectionSkeletonLoader';
import colors from '../../../globals/colors';

function OrbClassReportLoader() {
  return (
    <div>
      <DialsSkeletonLoader />
      <SubSectionSkeletonLoader speed="2" foregroundColor={colors.COLOR_GREY_DISABLED2} backgroundColor="#fff" isOrb />
    </div>
  );
}

export default OrbClassReportLoader;
