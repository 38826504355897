/* eslint-disable import/default */
/* eslint-disable import/namespace */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default-member
import SearchInitialiser from './SearchInitialiser';
import { isHubMode } from '../../utils/platform';

/* eslint-disable react/function-component-definition */
const withSearchInitialiser = ({
  initialFilters = {},
  searchSource,
  pageSize,
  sort,
  isAsYouType = false
}) => Component => {
  const WithSearchInitialiser = (props = {}) =>
    props.tabName || isHubMode() ? (
      <SearchInitialiser
        initialFilters={{ orgId: props.orgId, ...initialFilters }}
        pageSize={pageSize}
        searchSource={searchSource}
        sort={sort}
        isAsYouType={isAsYouType}
      >
        <Component {...props} />
      </SearchInitialiser>
    ) : null;

  WithSearchInitialiser.propTypes = {
    tabName: PropTypes.string,
    orgId: PropTypes.string
  };

  return WithSearchInitialiser;
};

export default withSearchInitialiser;
