import qs from 'query-string';
import signedFetch from '../apiCalls/util/signedFetch';
import { getProductMetadata } from '../../../utils/platform';

export const getStudentReportUrl = (type, orgId, studentId, locationId, subject) =>
  // @ts-ignore
  `${__API_BASE__}/gradebook/org/${orgId}/${type}?${qs.stringify({
    studentId,
    locationId,
    subject,
    cache: true
  })}`;

function* getStudentReport(type, orgId, studentId, locationId) {
  const subject = getProductMetadata('orb', 'subject');

  return yield signedFetch(
    `getStudent${type}Report`,
    getStudentReportUrl(type, orgId, studentId, locationId, subject),
    'GET'
  );
}

export default getStudentReport;
