import React from 'react';
import PropTypes from 'prop-types';

import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './ImportUsersSuccess.scss';
import PanelScrollContainer from '../PanelScrollContainer/PanelScrollContainer';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';

function ImportUsersSuccess({ email = '', onDismiss, localizedContent: { importUsersSuccess: content } }) {
  return (
    <PanelScrollContainer
      heading={content.heading}
      subHeading={content.subheading}
      closeText={content.close_panel_text}
      footer={<PopoutActionFooter primaryButtonText={content.done_button_text} primaryButtonAction={onDismiss} />}
    >
      <div className={styles.content}>
        <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.PROCESSING} title={content.panel_icon_heading} />
        <p className="gin-top2"> You will be emailed {email} when this task is complete.</p>
        <p> This may take 2 - 5 minutes, but during peak times it might take longer.</p>
        <p>{content.instructions_primary_2}</p>
        <p>{content.instructions_primary_3} </p>
        <ul className={styles.aligntext}>
          <li>the students or classes tabs</li>
          <li>the email you receive when your upload is complete</li>
        </ul>
        <p>{content.instructions_secondary}</p>
      </div>
    </PanelScrollContainer>
  );
}

ImportUsersSuccess.propTypes = {
  email: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('importUsersSuccess')(ImportUsersSuccess);
