import * as t from '../../actionTypes.js';

const initialState = {
  jwt: null,
  deepLinkReturnUrl: '',
  loading: false,
  error: null,
  lineItemsConfig: null
};

export default function getJwtAndDeeplinkReturnUrl(state = initialState, action) {
  switch (action.type) {
    case t.NGI_FETCH_JWT_AND_DEEPLINK_REQUEST:
      return { ...state, loading: true, error: null };
    case t.NGI_FETCH_JWT_AND_DEEPLINK_SUCCESS:
      return {
        ...state,
        loading: false,
        jwt: action.payload.jwt,
        error: null,
        deepLinkReturnUrl: action.payload.deepLinkReturnUrl,
        lineItemsConfig: action.payload.createLineItemsResponse
      };
    case t.NGI_FETCH_JWT_AND_DEEPLINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
