export const YEAR_GROUPS = {
  'YEAR_-1': -1,
  YEAR_0: 0,
  YEAR_1: 1,
  YEAR_2: 2,
  YEAR_3: 3,
  YEAR_4: 4,
  YEAR_5: 5,
  YEAR_6: 6,
  YEAR_7: 7,
  YEAR_8: 8,
  YEAR_9: 9,
  YEAR_10: 10,
  YEAR_11: 11,
  YEAR_12: 12,
  YEAR_13: 13
};

export const defaultYearGroup = 'YEAR_-1';
