const SET_CONTEXT = 'removeLearningMaterial/SET_CONTEXT';
export const SET_MATERIAL_TO_REMOVE = 'removeLearningMaterial/SET_MATERIAL_TO_REMOVE';
const SET_ASSIGNMENT_SELECTED = 'removeLearningMaterial/SET_ASSIGNMENT_SELECTED';
const SET_REMOVED_COUNT = 'removeLearningMaterial/SET_REMOVED_COUNT';
const SET_RETURNED_COUNT = 'removeLearningMaterial/SET_RETURNED_COUNT';
const SET_RESULTS = 'removeLearningMaterial/SET_RESULTS';
const SET_RESULT_STATUS = 'removeLearningMaterial/SET_RESULT_STATUS';
const TOGGLE_NOTIFY_LEARNERS = 'removeLearningMaterial/TOGGLE_NOTIFY_LEARNERS';
export const REMOVE_LEARNING_MATERIAL = 'removeLearningMaterial/REMOVE_LEARNING_MATERIAL';
const SHOW_CONFIRMATION = 'removeLearningMaterial/SHOW_CONFIRMATION';
const TOGGLE_MODAL = 'removeLearningMaterial/TOGGLE_MODAL';
const CLEAR_ASSIGNMENT_SELECTION = 'removeLearningMaterial/CLEAR_ASSIGNMENT_SELECTION';
const RESET_STATE_FOR_INSTANCE = 'removeLearningMaterial/RESET_STATE_FOR_INSTANCE';
export const RESET_STATE = 'removeLearningMaterial/RESET_STATE';
const SET_POLL_OPERATION = 'removeLearningMaterial/SET_POLL_OPERATION';

export const formStates = {
  REVIEW: 'REVIEW',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION'
};

const initialState = {
  formState: formStates.REVIEW,
  context: null,

  assignments: [],

  groupId: null,
  productIds: [],
  assignmentIds: [],
  selectedAssignmentIds: [],
  notifyLearners: false,
  showRemoveMaterialModal: false,
  removedCount: null,
  returnedCount: null,
  results: [],
  resultStatus: ''
};

export default function removeLearningMaterial(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CONTEXT:
      return {
        ...state,
        context: action.context
      };
    case SET_MATERIAL_TO_REMOVE:
      return {
        ...state,
        assignments: action.assignments
      };
    // Update list selection actions
    case SET_ASSIGNMENT_SELECTED:
      return {
        ...state,
        selectedAssignmentIds: action.isSelected
          ? // If now is selected, add to list
            [...state.selectedAssignmentIds, action.assignmentId]
          : // Else remove from list
            state.selectedAssignmentIds.filter(id => id !== action.assignmentId)
      };
    case SET_REMOVED_COUNT:
      return {
        ...state,
        removedCount: action.count
      };
    case SET_RETURNED_COUNT:
      return {
        ...state,
        returnedCount: action.count
      };
    case SET_RESULTS:
      return {
        ...state,
        results: action.results
      };
    case SET_RESULT_STATUS:
      return {
        ...state,
        resultStatus: action.resultStatus
      };
    case TOGGLE_NOTIFY_LEARNERS:
      return {
        ...state,
        notifyLearners: !state.notifyLearners
      };
    case REMOVE_LEARNING_MATERIAL:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        showRemoveMaterialModal: !state.showRemoveMaterialModal
      };
    case CLEAR_ASSIGNMENT_SELECTION:
      // keep context and clear assignment selection for when the Done button is
      // pressed in the remove LM confirmation panel in order to uncheck archived LM
      return {
        ...initialState,
        context: state.context
      };
    case RESET_STATE_FOR_INSTANCE:
      // keep context and selected ids when closing the remove LM panel via the confirmation modal
      return {
        ...initialState,
        context: state.context,
        selectedAssignmentIds: state.selectedAssignmentIds
      };
    case RESET_STATE:
      return {
        ...initialState
      };
    case SET_POLL_OPERATION:
      return {
        ...state,
        pollOperation: action.operation
      };
    default:
      return state;
  }
}

export const setContext = context => ({
  type: SET_CONTEXT,
  context
});

export const setMaterialToRemove = assignments => ({
  type: SET_MATERIAL_TO_REMOVE,
  assignments
});

export const setAssignmentSelected = (assignmentId, isSelected) => ({
  type: SET_ASSIGNMENT_SELECTED,
  assignmentId,
  isSelected
});

export const setRemovedCount = count => ({
  type: SET_REMOVED_COUNT,
  count
});

export const setReturnedCount = count => ({
  type: SET_RETURNED_COUNT,
  count
});

export const setResultStatus = resultStatus => ({
  type: SET_RESULT_STATUS,
  resultStatus
});

export const setResults = results => ({
  type: SET_RESULTS,
  results
});

export const toggleNotifyLearners = () => ({
  type: TOGGLE_NOTIFY_LEARNERS
});

export const removeLearningMaterials = () => ({
  type: REMOVE_LEARNING_MATERIAL
});

export const showConfirmation = () => ({
  type: SHOW_CONFIRMATION
});

export const toggleRemoveMaterialModal = () => ({
  type: TOGGLE_MODAL
});

export const clearAssignmentSelection = () => ({
  type: CLEAR_ASSIGNMENT_SELECTION
});

export const resetStateForInstance = () => ({
  type: RESET_STATE_FOR_INSTANCE
});

export const resetState = () => ({
  type: RESET_STATE
});

export const setPollOperation = operation => ({
  type: SET_POLL_OPERATION,
  operation
});
