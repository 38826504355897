import signedFetch from '../apiCalls/util/signedFetch';

/**
 * An enumeration representing API authorisation token types.
 *
 * @type {Object}
 */
export const TokenType = Object.freeze({
  UPDATE_AUTH: Symbol('UPDATE_AUTH')
});

const tokenTypeValues = {
  [TokenType.UPDATE_AUTH]: 'UPDATE_AUTH'
};

/**
 * A saga to fetch an authorisation token for a given user.
 *
 * @param {Symbol} orgId
 * @param {Symbol} userId
 * @param {Symbol} tokenType The TokenType to generate.
 *
 * @throws {Error} If a network request fails.
 * @throws {Error} If a the API responds without a token.
 *
 * @yield {Object} The next Redux effect description.
 *
 * @return {string}
 */
function* generateUserToken(orgId, userId, tokenType) {
  const response = yield signedFetch(
    'generateUserToken',
    `${__API_BASE__}/org/${orgId}/user/${userId}/generate-token?tokenType=${tokenTypeValues[tokenType]}`
  );

  if (response.status !== 'success') {
    throw new Error(`Failed to generate user ${tokenType} token`);
  }

  return response.data.token;
}

export default generateUserToken;
