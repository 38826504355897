import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
// Redux
import { connect } from 'react-redux';
import {
  getFilteredCourseMaterials,
  setUnfilteredCourseMaterials,
  resetFiltersCourseMaterials
} from '../../redux/actions/hubCourseMaterialFilters';
import { filterAssessment } from '../../redux/reducers/hub/hubCourseMaterialFilters.reducer';
// Components
import HubFilterBar from '../HubFilterBar/HubFilterBar';

function HubCourseMaterialFilters({
  courseId,
  products,
  assessment,
  data,
  hubContent,
  isDataSet,
  breakpoint,
  setUnfilteredCourseMaterialsAction,
  getFilteredCourseMaterialsAction,
  resetFiltersCourseMaterialsAction,
  filters
}) {
  let options = [];

  useEffect(() => {
    if (filters?.length >= 0) {
      resetFiltersCourseMaterialsAction({ courseId });
      if ((products && Object.keys(products).length) || assessment) {
        const shouldFilterAssessment = !!assessment && filterAssessment(assessment, filters);
        setUnfilteredCourseMaterialsAction({
          isDataSet: true,
          data: products,
          initialData: products,
          courseId,
          assessment,
          shouldAssessmentShow: shouldFilterAssessment
        });
      }
    }
    return () => {
      resetFiltersCourseMaterialsAction({ courseId: '' });
    };
  }, [courseId, assessment]);

  useEffect(() => {
    const isInitialDataSet =
      !data.length && !isDataSet && products && Object.keys(products).length && filters?.length >= 0;
    if (isInitialDataSet) {
      const shouldFilterAssessment = !!assessment && filterAssessment(assessment, filters);
      setUnfilteredCourseMaterialsAction({
        isDataSet: true,
        data: products,
        initialData: products,
        courseId,
        assessment,
        shouldAssessmentShow: shouldFilterAssessment
      });
    }
  }, [products]);

  const setFilterOptions = valueName => {
    const filterOptions = [];
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].value === valueName) {
        options[i].checked = filters.indexOf(valueName) === -1;
      }
      if (options[i].checked) {
        filterOptions.push(options[i].value);
      }
    }
    return getFilteredCourseMaterialsAction(filterOptions);
  };

  const getFilterOptions = stateOptions => {
    for (let i = 0; i < options.length; i += 1) {
      if (stateOptions && stateOptions.length && stateOptions.indexOf(options[i].value) !== -1) {
        options[i].checked = true;
      }
    }
    return options;
  };

  options = [
    {
      checked: false,
      id: 'courseMaterials-notStarted',
      name: 'courseMaterials-notStarted',
      onChange: valueName => setFilterOptions(valueName),
      text: hubContent?.hub_course_material_filters_not_started,
      value: 'notStarted'
    },
    {
      checked: false,
      id: 'courseMaterials-active',
      name: 'courseMaterials-active',
      onChange: valueName => setFilterOptions(valueName),
      text: hubContent?.hub_course_material_filters_active,
      value: 'active'
    },
    {
      checked: false,
      id: 'courseMaterials-expiringSoon',
      name: 'courseMaterials-expiringSoon',
      onChange: valueName => setFilterOptions(valueName),
      text: hubContent?.hub_course_material_filters_expiring_soon,
      value: 'expiringSoon'
    },
    {
      checked: false,
      id: 'courseMaterials-expired',
      name: 'courseMaterials-expired',
      onChange: valueName => setFilterOptions(valueName),
      text: hubContent?.hub_course_material_filters_expired,
      value: 'expired'
    },
    {
      checked: false,
      id: 'courseMaterials-noLicence',
      name: 'courseMaterials-noLicence',
      onChange: valueName => setFilterOptions(valueName),
      text: hubContent?.hub_course_material_filters_no_licence,
      value: 'noLicence'
    }
  ];

  return (
    <HubFilterBar
      key=""
      idPrefix=""
      filterButtonText={hubContent.hub_course_material_filters_licence}
      overlayLabelText={hubContent.hub_course_material_filters_licence}
      breakpoint={breakpoint}
      filterOptions={getFilterOptions(filters)}
      ariaControls="searchResults"
    />
  );
}

HubCourseMaterialFilters.propTypes = {
  courseId: PropTypes.string,
  products: PropTypes.object,
  assessment: PropTypes.object,
  data: PropTypes.object,
  hubContent: PropTypes.object,
  isDataSet: PropTypes.bool,
  breakpoint: PropTypes.string,
  setUnfilteredCourseMaterialsAction: PropTypes.func,
  getFilteredCourseMaterialsAction: PropTypes.func,
  resetFiltersCourseMaterialsAction: PropTypes.func,
  filters: PropTypes.array
};

export default compose(
  connect(
    ({ hubCourseMaterialFilters }) => ({
      data: hubCourseMaterialFilters.data,
      isDataSet: hubCourseMaterialFilters.isDataSet,
      filters: hubCourseMaterialFilters.filters
    }),
    {
      setUnfilteredCourseMaterialsAction: setUnfilteredCourseMaterials,
      getFilteredCourseMaterialsAction: getFilteredCourseMaterials,
      resetFiltersCourseMaterialsAction: resetFiltersCourseMaterials
    }
  )
)(HubCourseMaterialFilters);
