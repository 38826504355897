import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '../../../components/Button/Button.js';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import { resetForm } from '../../../redux/reducers/data/forms.reducer.js';
import { resetFields } from '../../../redux/reducers/data/fields.reducer.js';

import cmsContent from '../../../utils/cmsContent.js';

function ExampleTabConfirmation({ resetFormAction }) {
  const CMS = cmsContent.supportPage || {};
  return (
    <div className="pad4">
      <PopoutPanelIconHeading
        type={types.VALID}
        title={CMS.contact_us_form_sent_title_text}
        subtitle={CMS.contact_us_form_sent_subtitle_text}
      />
      <div className="row cols-center pad-top2">
        <Button id="submit-form" text={CMS.button_contact_form_complete_text} onClick={resetFormAction} />
      </div>
    </div>
  );
}

ExampleTabConfirmation.propTypes = {
  resetFormAction: PropTypes.func.isRequired
};

export default connect(null, dispatch => ({
  resetFormAction: () => {
    dispatch(resetForm('contactUs', 'INPUTTING'));
    dispatch(resetFields('contactUs', ['email', 'name', 'ces10', 'country', 'comment']));
  }
}))(ExampleTabConfirmation);
