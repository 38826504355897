/* eslint-disable class-methods-use-this */
const { groupBy, uniq, flatten } = require('lodash');
const Fuse = require('fuse.js/dist/fuse.js');

const FIELDS = {
  title: 'Title',
  description: 'Description',
  author: 'Author',
  keywords: 'Keywords',
  'dragonfly_level.title': 'Dragonfly Level',
  'recovery_level.title': 'Australian Reading Level',
  'readerstage.title': 'Stage of the reader',
  'bookband.title': 'Book Band',
  'interestage.title': 'Age',
  'genres.title': 'Genre',
  country: 'Country',
  'curriculum.title': 'Curriculum'
};

const SEARCH_OPTS = {
  threshold: 0.5,
  minMatchCharLength: 3,
  includeMatches: true,
  ignoreLocation: true,
  useExtendedSearch: true,
  keys: [
    'title',
    'description',
    'author',
    'dragonfly_level.title',
    'recovery_level.title',
    'readerstage.title',
    'bookband.title',
    'interestage.title',
    'genres.title',
    'keywords',
    'country',
    'curriculum.title'
  ]
};

export default class SearchService {
  constructor(resources) {
    this.resources = resources;
  }

  getAutocompleteSuggestions(searchParams) {
    const fuse = new Fuse(this.resources, SEARCH_OPTS);
    const items = fuse.search(this.normalizeSearch(searchParams.search));

    const results = [];

    items.forEach(item => {
      item.matches.forEach(match => {
        if (results.length < 10 && !results.find(i => i.value === match.value) && FIELDS[match.key]) {
          results.push({
            match: match.value.replace(new RegExp(searchParams.search, 'i'), '<b>$&</b>'),
            field: FIELDS[match.key],
            value: match.value
          });
        }
      });
    });
    const searchResults = [];
    Object.values(groupBy(results, 'field')).forEach(array => {
      searchResults.push(...array);
    });

    return searchResults;
  }

  search({ filters, search }) {
    let filteredResources = this.applyFilters(this.resources, filters);

    if (search) {
      const fuse = new Fuse(filteredResources, {
        ...SEARCH_OPTS,
        threshold: 0.1,
        minMatchCharLength: 1
      });
      filteredResources = fuse.search(`"${search}"`).map(({ item }) => item);
    }
    const availableFilters = this.calculateAvailableFilters(filters, filteredResources);
    return { resources: filteredResources, filters: availableFilters };
  }

  normalizeSearch(query, minLength) {
    return query
      .split(' ')
      .filter(word => {
        const match = word.match(/(\w+)/);
        return match && match[0].length >= (minLength || 3);
      })
      .join(' ');
  }

  calculateAvailableFilters(filters, filteredResources) {
    if (!filters) {
      return {};
    }
    const level = filters.level ? this.projectLevels(this.resources) : this.projectLevels(filteredResources);
    const series = filters.series ? this.projectSeries(this.resources) : this.projectSeries(filteredResources);
    const genre = filters.genre ? this.projectGenres(this.resources) : this.projectGenres(filteredResources);
    const resourceType = filters.resource_type
      ? this.projectResourceTypes(this.resources)
      : this.projectResourceTypes(filteredResources);

    return { level, series, genre, resource_type: resourceType };
  }

  projectLevels(resources) {
    return uniq(resources.filter(r => r.level).map(r => r.level.key));
  }

  projectSeries(resources) {
    return flatten(uniq(resources.map(r => r.series.map(s => s.key))));
  }

  projectGenres(resources) {
    return flatten(uniq(resources.map(r => r.genres.map(s => s.key))));
  }

  projectResourceTypes(resources) {
    return uniq(resources.map(r => r.resource_type));
  }

  applyFilters(resources, filters) {
    const { level, series, genre } = filters || {};
    const resourceType = filters?.resource_type;
    let filteredResources = resources;

    if (level) filteredResources = this.filterByLevel(filteredResources, level);
    if (series) filteredResources = this.filterBySeries(filteredResources, series);
    if (genre) filteredResources = this.filterByGenre(filteredResources, genre);
    if (resourceType) filteredResources = this.filterByResourceType(filteredResources, resourceType);

    return filteredResources;
  }

  filterByLevel(resources, level) {
    return resources.filter(r => r.level && r.level.key === parseInt(level, 10));
  }

  filterBySeries(resources, serie) {
    return resources.filter(r => r.series && !!r.series.find(s => s.key === serie));
  }

  filterByGenre(resources, genre) {
    return resources.filter(r => r.genres && !!r.genres.find(g => g.key === genre));
  }

  filterByResourceType(resources, resourceType) {
    return resources.filter(r => r.resource_type === resourceType);
  }
}
