import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PopoutPanelIconHeading, {
  types
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import cmsContent from '../../../../../../utils/cmsContent.js';

function AddToLibraryBulkSubmitting({ fileName }) {
  const CMS = cmsContent.addToLibrary || {};
  return (
    <PopoutPanelIconHeading
      type={types.LOADING}
      title={CMS.loading_page_title}
      subtitle={(CMS.uploading_file_subtitle_text || '').replace('{fileName}', fileName)}
    />
  );
}

AddToLibraryBulkSubmitting.propTypes = {
  fileName: PropTypes.string.isRequired
};

export default connect(state => ({
  fileName: state.addToLibrary.file.name
}))(AddToLibraryBulkSubmitting);
