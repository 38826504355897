import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../globals/colors.js';
// Constants
import { HubLayoutConstants, ErrorModalType } from '../../../globals/hubConstants';
// Styles
import styles from './HubProductErrorModal.scss';
// Components
import Button, { buttonTypes } from '../../../components/Button/Button';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import HubEmptyState from '../../../components/HubEmptyState/HubEmptyState';
import Link from '../../../components/Link/Link';
import HubModalLayout from '../../../components/HubModalLayout/HubModalLayout';

function HubProductErrorModal({
  hubContent,
  closeModal,
  externalUrl,
  isError,
  downloadType,
  glyphIcon,
  glyphSize,
  glyphBackgroundColour,
  title,
  subTitle,
  showLink,
  actionLabel,
  isOpen,
  errorModalType,
  closeExpiredModal,
  closeNotStartedModal
}) {
  const closeErrorModal = () => {
    if (errorModalType === ErrorModalType.EXPIRED) {
      closeExpiredModal();
    } else if (errorModalType === ErrorModalType.NOT_STARTED) {
      closeNotStartedModal();
    } else {
      closeModal();
    }
  };

  const closeModalOnKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      closeErrorModal();
    }
  };
  const isVstError = isError && downloadType === HubLayoutConstants.DOWNLOAD_TYPES.VST;

  return (
    <div className={`${styles.outerModal} ${isVstError || isOpen ? styles.open : ''}`}>
      <div className={styles.innerModal}>
        <HubModalLayout isOpen={isVstError || isOpen} id="productErrorModalId" closeAction={closeErrorModal}>
          <div className={styles.modalButtonHeader}>
            <Button
              text={hubContent.button_close_text}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              onClick={e => {
                e.stopPropagation();
                closeErrorModal();
              }}
            />
          </div>
          <div className={styles.modalContent}>
            <HubEmptyState
              glyphIcon={glyphIcon}
              glyphSize={glyphSize}
              glyphBackgroundColour={glyphBackgroundColour}
              title={title}
              subTitle={subTitle}
            />
          </div>
          <div className={styles.modalButtonBottom}>
            {showLink ? (
              <Link
                role="button"
                className={styles.modalLink}
                to={externalUrl}
                onKeyDown={closeModalOnKeyPress}
                onClick={() => closeErrorModal()}
                tabIndex={0}
              >
                <span>{hubContent.product_modal_link_button_text}</span>
                <span className="a11y-hide">{hubContent.ally_opens_in_new_tab}</span>
                <SVGIcon glyph={GLYPHS.ICON_LINK} />
              </Link>
            ) : (
              <Button text={actionLabel} type={buttonTypes.PRIMARY} onClick={() => closeErrorModal()} />
            )}
          </div>
        </HubModalLayout>
      </div>
    </div>
  );
}

HubProductErrorModal.propTypes = {
  hubContent: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  externalUrl: PropTypes.string,
  isError: PropTypes.bool,
  downloadType: PropTypes.string,
  glyphIcon: PropTypes.string,
  glyphSize: PropTypes.string,
  glyphBackgroundColour: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showLink: PropTypes.bool,
  actionLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  errorModalType: PropTypes.any,
  closeExpiredModal: PropTypes.func,
  closeNotStartedModal: PropTypes.func
};

HubProductErrorModal.defaultProps = {
  glyphIcon: GLYPHS.ICON_INFORMATION_CIRCLE,
  glyphSize: thumbnailSizes.HEADING,
  glyphBackgroundColour: colors.COLORS,
  showLink: true
};

export default HubProductErrorModal;
