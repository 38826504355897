import React from 'react';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import cmsContent from '../../utils/cmsContent.js';

function RegisterFormSubmitting() {
  const CMS = cmsContent.classEditPanel || {};
  return (
    <PopoutPanelIconHeading
      type={types.LOADING}
      title={CMS.loading_page_text_title}
      subtitle={CMS.loading_page_text_subtitle}
    />
  );
}

export default RegisterFormSubmitting;
