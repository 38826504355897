import * as t from '../actionTypes';

export const assessmentLocaliziedContentSuccess = (status, data) => ({
  type: t.AM_LOCALIZED_CONTENT_SUCCESS,
  payload: { status, data }
});

export const assessmentLocaliziedContentFailure = errors => ({
  type: t.AM_LOCALIZED_CONTENT_FAILURE,
  payload: errors
});
