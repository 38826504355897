import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import actions from '../../redux/actions';

import withLocalizedContent from '../../language/withLocalizedContent';
import ValidationStatus, { validationStatusCodes } from '../ValidationStatus/ValidationStatus';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import Badge from '../Badge/Badge';
import colors from '../../globals/colors';

import { GLYPHS } from '../SVGIcon/SVGIcon';
import { mode as opMode } from '../../redux/reducers/oicTestCredits.reducer.js';
import styles from './EditOicTestCredits.scss';

const { ERROR_STATUS, VALID_STATUS, WAITING_STATUS } = validationStatusCodes;

function EditOicTestCredits({
  localizedContent: { oicCreateTestCreditsForm: localizedContent },
  expanded,
  orgId,
  productId,
  getOicTestCredits,
  products = {},
  input,
  updateOicTestCredits
}) {
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const { mode = opMode.EMPTY, formData } = products[productId] || {};
  const handleGetOicTestCredits = () => getOicTestCredits({ orgId, productId });
  const handleForm = () => updateOicTestCredits(formData);
  const handleInput = (value, field) => input({ productId, field, value });
  const handleAddTestCredits = () => history.push(`/dashboard/org/${orgId}/test-credits`);

  const isInputMode = mode === opMode.INPUT;
  const isLoadingMode = mode === opMode.LOADING;

  useEffect(() => {
    if (expanded) handleGetOicTestCredits();

    return () => {
      setIsVisible(false);
    };
  }, [expanded]);

  useEffect(() => {
    if (mode === opMode.SUCCESS) {
      setIsVisible(false);
    }
  }, [mode]);

  const status = {
    [opMode.LOADING]: WAITING_STATUS,
    [opMode.ERROR]: ERROR_STATUS,
    [opMode.SUCCESS]: VALID_STATUS,
    [opMode.INPUT]: VALID_STATUS
  }[mode];

  const DisplayBadgeComponent = (
    <LinkWithIcon
      text={
        <>
          <Badge backgroundColor={colors.PRIMARY_BLUE} value={formData?.testCredits} />{' '}
          {localizedContent.form_edit_button}
        </>
      }
      action={() => setIsVisible(!isVisible)}
      glyph={GLYPHS.ICON_OUP_ASSESSMENT_EDIT_PENCIL}
    />
  );

  const DisplayMode = {
    [opMode.LOADING]: localizedContent.waiting_message,
    [opMode.ERROR]: (
      <LinkWithIcon text={localizedContent.error_button} action={handleGetOicTestCredits} glyph={GLYPHS.ICON_REFRESH} />
    ),
    [opMode.EMPTY]: (
      <LinkWithIcon
        text={localizedContent.form_submit_button}
        action={handleAddTestCredits}
        glyph={GLYPHS.ICON_RIGHT}
      />
    ),
    [opMode.SUCCESS]: DisplayBadgeComponent,
    [opMode.INPUT]: DisplayBadgeComponent
  }[mode];

  return isVisible ? (
    <ValidationStatus
      containerClassName={styles.testCredits}
      forId="testCredits"
      type={status}
      hideIcon={isInputMode}
      isActive
    >
      <TextInputWithButton
        id="testCredits"
        name="testCredits"
        type="number"
        labelHidden
        disabled={isLoadingMode}
        buttonDisabled={isLoadingMode}
        buttonIcon={GLYPHS.ICON_TICK}
        value={formData?.testCredits}
        buttonAction={handleForm}
        onChange={handleInput}
      />
    </ValidationStatus>
  ) : (
    DisplayMode
  );
}

EditOicTestCredits.propTypes = {
  orgId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  input: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired,
  getOicTestCredits: PropTypes.func.isRequired,
  updateOicTestCredits: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('oicCreateTestCreditsForm'),
  connect(
    state => ({
      ...pick(state.oicTestCredits, ['products'])
    }),
    dispatch => ({
      input: input => dispatch(actions.createOicTestCreditsInput(input)),
      getOicTestCredits: input => dispatch(actions.getOicTestCreditsRequest(input)),
      updateOicTestCredits: input => dispatch(actions.updateOicTestCreditsRequest(input))
    })
  )
)(EditOicTestCredits);
