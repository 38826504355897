import React from 'react';
import { connect } from 'react-redux';
import PopoutPanelIconHeading, {
  types
} from '../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';

import cmsContent from '../../../../../utils/cmsContent.js';

function AddToProfileSubmitting() {
  const CMS = cmsContent.addToProfile || {};
  return (
    <PopoutPanelIconHeading type={types.LOADING} title={CMS.loading_page_title} subtitle={CMS.loading_page_subtitle} />
  );
}

AddToProfileSubmitting.propTypes = {};

export default connect(() => ({}))(AddToProfileSubmitting);
