import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styles from './BannerInvitation.scss';
// Action imports
import {
  doAccept,
  doDecline,
  doDismiss,
  showReviewing,
  showInvites,
  resetInvites
} from '../../redux/reducers/invitationsBanner.reducer';
import { switchOrg } from '../../redux/reducers/identity.reducer.js';
// Components
import BannerInvitation from './BannerInvitation';
import BannerNotification from '../BannerNotification/BannerNotification';

function perform(action, type) {
  return (inviteUserId, inviteOrgId) => {
    if (type === 'ACCEPT') {
      action(inviteUserId, inviteOrgId);
    } else {
      action(inviteUserId, inviteOrgId);
    }
  };
}

function ConnectedBannerInvitation({
  userId,
  email,
  invites,
  doAcceptAction,
  doDeclineAction,
  doDismissAction,
  formState,
  showReviewingAction,
  switchOrgAction,
  showInvitesAction,
  resetInvitesAction,
  orgJoined,
  urlPrefix,
  currentOrganisationId
}) {
  return (
    <BannerNotification>
      <div className={urlPrefix ? '' : styles.connectedBannerInvitation}>
        <BannerInvitation
          userId={userId}
          email={email}
          invites={invites}
          onAccept={perform(doAcceptAction, 'ACCEPT')}
          onDecline={perform(doDeclineAction, 'DECLINE')}
          onDismiss={perform(doDismissAction, 'DISMISS')}
          formState={formState}
          showReviewing={showReviewingAction}
          switchOrg={switchOrgAction}
          showInvites={showInvitesAction}
          resetInvites={resetInvitesAction}
          orgJoined={orgJoined}
          urlPrefix={urlPrefix}
          currentOrganisationId={currentOrganisationId}
        />
      </div>
    </BannerNotification>
  );
}

ConnectedBannerInvitation.propTypes = {
  urlPrefix: PropTypes.string,
  formState: PropTypes.string,
  userId: PropTypes.string,
  email: PropTypes.string,
  showReviewingAction: PropTypes.func,
  switchOrgAction: PropTypes.func,
  showInvitesAction: PropTypes.func,
  orgJoined: PropTypes.bool,
  currentOrganisationId: PropTypes.string,
  resetInvitesAction: PropTypes.func,

  /** The list of pending invitations. */
  invites: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      orgId: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
      roleName: PropTypes.string.isRequired,
      sentDate: PropTypes.string.isRequired,
      expiryDate: PropTypes.string.isRequired,
      expired: PropTypes.bool.isRequired
    })
  ),

  doAcceptAction: PropTypes.func.isRequired,
  doDeclineAction: PropTypes.func.isRequired,
  doDismissAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    formState: state.invites.formState,
    invites: state.invites.invites.filter(invite => invite.state !== 'UPDATED'),
    userId: state.invites.userId,
    email: state.identity.email,
    orgJoined: state.invites.orgJoined,
    currentOrganisationId: state.identity.currentOrganisationId
  }),
  {
    doAcceptAction: doAccept,
    doDeclineAction: doDecline,
    doDismissAction: doDismiss,
    showReviewingAction: showReviewing,
    switchOrgAction: switchOrg,
    showInvitesAction: showInvites,
    resetInvitesAction: resetInvites
  }
)(ConnectedBannerInvitation);
