import PropTypes from 'prop-types';
import React from 'react';

import withLocalizedContent from '../../language/withLocalizedContent';

import OrbLearningObjectLink from '../OrbLearningObjectLink/OrbLearningObjectLink.jsx';
import OrbContentCoverImage from '../OrbContentCoverImage/OrbContentCoverImage.js';
import OrbTeacherContentMetadata from '../OrbTeacherContentMetadata/OrbTeacherContentMetadata.jsx';

class OrbTeacherBook extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,

    localizedContent: PropTypes.object.isRequired
  };

  renderAvailableResources = (item) => {
    const { localizedContent: { orbTeacherResourcesPage: i18n }} = this.props;

    item.linked_resources = item.linked_resources || [];
    if (!item.linked_resources.length) return null;

    const standalone = item.resource_type === 'interactive';
    return (
      <div className="col sm12">
        <h2>{i18n.available_resources}</h2>

        <div>
          {item.linked_resources.map((content, i) => (
            <OrbLearningObjectLink
              key={i}
              book={item}
              content={content}
              standalone={standalone}
              downloadable={['worksheet', 'teaching_note'].includes(content.type)}
            />
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { content } = this.props;

    return (
      <div className="orb-card-container orb-card-resources">
        <div className="row orb-card-content">
          <div className="col sm2">
            <div className="orb-cover-container">
              <OrbContentCoverImage urls={content.cover_image_urls} className="orb-book-cover" />
            </div>
          </div>
          <div className="col sm10">
            <div className="orb-book-title">
              <div className="row">
                <div className="col sm12">
                  <h2>{content.title}</h2>
                  {content.author && <p className="lead">{`by ${content.author}`}</p>}
                </div>
              </div>
            </div>
            <div className="orb-book-description">
              {content.description &&
                <div className="row">
                  <div className="col sm8"><p>{content.description}</p></div>
                  <div className="col sm4"></div>
                </div>
              }
            </div>
            <div className="row">
              <div className="col sm12">
                <OrbTeacherContentMetadata content={content} />
              </div>
            </div>
          </div>
        </div>
        <div className="row orb-card-footer">
          {this.renderAvailableResources(content)}
        </div>
      </div>
    );
  }
}

export default withLocalizedContent('orbTeacherResourcesPage')(OrbTeacherBook);
