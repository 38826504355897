import React, { Component } from 'react';
import PropTypes from 'prop-types';
import querySelectorAllFocusable from '../../utils/dom/querySelectorAllFocusable';
import trapFocusIn from '../../utils/dom/trapFocusIn';

const ANIMATION_DELAY = 300;
export default class HubModalLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      triggeredBy: null
    };
  }

  componentDidUpdate({ isOpen: prevIsOpen }) {
    const { isOpen = false } = this.props;
    if (prevIsOpen !== isOpen) {
      this.handleDisplay();
    }
  }

  onShow = () => {
    const { wrapper } = this;
    const { activeElement } = document;
    const { body } = document;

    this.setState({
      triggeredBy: activeElement === body ? null : activeElement
    });

    if (wrapper) {
      wrapper.addEventListener('keydown', this.onKeyEscape, false);
    }

    setTimeout(this.onAfterShow, ANIMATION_DELAY);
  };

  onAfterShow = () => {
    const { focusOnFirstField = true } = this.props;
    trapFocusIn(this.wrapper);
    if (focusOnFirstField) {
      this.setFocusOnFirstField();
    }
  };

  onHide = () => {
    const { wrapper } = this;

    if (wrapper) {
      wrapper.removeEventListener('keydown', this.onKeyEscape, false);
    }

    trapFocusIn(false);
    setTimeout(this.onAfterHide, ANIMATION_DELAY);
  };

  onAfterHide = () => {
    const { triggeredBy } = this.state;
    if (triggeredBy) triggeredBy.focus();
  };

  onKeyEscape = e => {
    const { keyCode } = e;
    const { closeAction } = this.props;
    if (keyCode === 27) {
      closeAction();
    }
  };

  setFocusOnFirstField() {
    const [firstFocusableElement] = querySelectorAllFocusable(this.wrapper);
    if (firstFocusableElement) firstFocusableElement.focus();
  }

  handleDisplay = () => {
    const { isOpen = false } = this.props;
    if (!isOpen) {
      this.onHide();
      return;
    }
    this.onShow();
  };

  render() {
    const { id, isOpen, children, ariaLabel = '' } = this.props;

    return (
      <div
        id={id}
        ref={elem => {
          this.wrapper = elem;
        }}
        role="dialog"
        aria-hidden={!isOpen}
        aria-label={ariaLabel}
      >
        {children}
      </div>
    );
  }
}

HubModalLayout.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  focusOnFirstField: PropTypes.bool,
  children: PropTypes.any,
  closeAction: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string
};
