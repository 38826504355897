import React from 'react';
import PropTypes from 'prop-types';

import withLocalizedContent from '../../language/withLocalizedContent';
import BulkUploadInformationBubble from '../BulkUploadInformationBubble/BulkUploadInformationBubble';
import ControlledForm from '../ControlledForm/ControlledForm';
import FileUploadInput from '../FileUploadInput/FileUploadInput';
import PanelScrollContainer from '../PanelScrollContainer/PanelScrollContainer';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';

function ImportUsersFileUpload({
  supportedFileTypes = ['.csv'],
  source = {},
  onFileUpload = () => {},
  onNext = () => {},
  onBack = () => {},
  localizedContent: { importUsersFileUpload: content }
}) {
  return (
    <ControlledForm>
      <PanelScrollContainer
        heading={content.panel_heading}
        subHeading={content.panel_subheading}
        footer={
          <PopoutActionFooter
            secondaryButtonText={content.back_button_text}
            secondaryButtonAction={onBack}
            primaryButtonText={content.next_button_text}
            primaryButtonAction={onNext}
            primaryButtonDisabled={!source.name}
            wizardButtons
          />
        }
      >
        <div className="pad2">
          <FileUploadInput
            id="fileUploadInput"
            label={source.name || content.input_label_text}
            buttonText={content.input_button_text}
            supportedFileTypes={supportedFileTypes}
            handleFile={onFileUpload}
          />
          <BulkUploadInformationBubble
            headerTitleText={content.download_template_label}
            downloadTemplateText={content.download_template_button_text}
            downloadFileTemplate={content.download_template_filename}
            downloadFileName={content.download_file_name}
            filecontent={content.download_file_content}
            bodyText={content.download_body}
          />
          <p>{content.bottom_text}</p>
        </div>
      </PanelScrollContainer>
    </ControlledForm>
  );
}

ImportUsersFileUpload.propTypes = {
  supportedFileTypes: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.object,
  onFileUpload: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('importUsersFileUpload')(ImportUsersFileUpload);
