import { put } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch';
import {
  getWizardTestCreditsFailure,
  getWizardTestCreditsSuccess
} from '../../../../reducers/placementTestSessionCreate';

function* optGetTestCredits(input) {
  const { orgId, productId } = input;
  const response = yield signedFetch('optGetTestCredits', `${__API_BASE__}/org/${orgId}/oic/test-credits/${productId}`);

  if (response.status === 'success') {
    if (response.data) {
      const { credits } = response.data;
      return yield put(getWizardTestCreditsSuccess({ testCredits: credits }));
    }
    return yield put(getWizardTestCreditsFailure());
  }
  return yield put(getWizardTestCreditsFailure());
}

export default optGetTestCredits;
