import { API_VERSIONS, JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../util/signedFetch';

export default function* getPlayerLaunchFetch(params) {
  const URL = `${__API_BASE__}/player/launch?contextCode=${params}`;

  const headers = { 'Content-Type': JSON_ENCODED, Accept: API_VERSIONS.API_VERSION_0_2 };

  return yield signedFetch('getPlayerLaunch', URL, 'GET', undefined, headers);
}
