import * as t from '../actionTypes';

export const mode = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

const initialState = {
  organizations: {}
};

function oicLinkOrgToJanison(state = initialState, { type, payload }) {
  switch (type) {
    case t.OIC_LINK_ORG_TO_JANISON_REQUEST:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          [payload.input.orgId]: {
            mode: mode.LOADING,
            data: null,
            errors: {}
          }
        }
      };
    case t.OIC_LINK_ORG_TO_JANISON_SUCCESS:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          [payload.orgId]: {
            mode: mode.SUCCESS,
            data: payload,
            errors: {}
          }
        }
      };
    case t.OIC_LINK_ORG_TO_JANISON_FAILURE:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          [payload.orgId]: {
            mode: mode.ERROR,
            data: null,
            errors: payload.errors
          }
        }
      };
    default:
      return state;
  }
}

export default oicLinkOrgToJanison;
