import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { featureIsEnabled } from '../../../../../globals/envSettings';

function* getClassrooms(orgId) {
  const response = yield signedFetch('searchClasses', `${__API_BASE__}/class/query`, 'POST', {
    filters: { orgId, active: true },
    /**
     * We should avoid this hardcoded params in the future,
     * maybe making these params not mandatory in the endpoint
     * */
    term: '',
    start: 0,
    size: 1000
  });

  if (response.status === 'success') {
    if (featureIsEnabled('mat-report-change-for-reducers')) {
      yield put(actions.getClassroomsSuccessV2(response.data, orgId));
    } else {
      yield put(actions.getClassroomsSuccess(response.data));
    }
    return;
  }

  if (featureIsEnabled('mat-report-change-for-reducers')) {
    yield put(actions.getClassroomsFailureV2(response.message, orgId));
  } else {
    yield put(actions.getClassroomsFailure(response.message));
  }
}

export default getClassrooms;
