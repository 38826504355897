import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

// import getAssignments from './getAssignments';
import getClassesAndStudents from './getClassesAndStudents';
// import updateAssignment from './updateAssignment';
// import deleteAssignment from './deleteAssignment';
import getReports from './getReports';

function* root() {
  // yield takeLatest(t.NGS_TEACHER_ASSIGNMENTS_REQUEST, ({ payload: { orgId }}) =>
  //   getAssignments(orgId)
  // );

  yield takeLatest(t.NGS_ORG_CLASSES_AND_STUDENTS_REQUEST, ({ payload: { orgId } }) => getClassesAndStudents(orgId));

  // yield takeLatest(t.NGS_TEACHER_UPDATE_ASSIGNMENT_REQUEST, ({ payload: { orgId, assignmentId, data }}) =>
  //   updateAssignment(orgId, assignmentId, data)
  // );

  // yield takeLatest(t.NGS_TEACHER_DELETE_ASSIGNMENT_REQUEST, ({ payload: { orgId, assignmentId }}) =>
  //   deleteAssignment(orgId, assignmentId)
  // );

  yield takeLatest(t.NGS_TEACHER_REPORTS_REQUEST, ({ payload: { orgId } }) => getReports(orgId));
}

export default root;
