import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getUserLicencesRequest from '../../../api/getUserLicences';

function* getUserLicences(criteria, value) {
  const response = yield getUserLicencesRequest(criteria, value);
  if (response.status !== 'success') {
    yield put(actions.getUserLicencesFailure(response.message));
    return;
  }
  yield put(actions.getUserLicencesSuccess(response));
}

export default getUserLicences;
