import { put } from 'redux-saga/effects';
import { loadCourseResourceSuccess, loadCourseResourceFailure } from '../../../../actions/ngsCourseResource';
import getCourseResourcesApiCall from '../../../apiCalls/ngs/getCourseResources';

function* loadCourseResource(orgId, courseId, resourceId) {
  const response = yield getCourseResourcesApiCall(orgId, courseId, null, resourceId);

  if (response.status === 'success') {
    yield put(loadCourseResourceSuccess(response.data.items[0]));
    return;
  }

  yield put(loadCourseResourceFailure(response.message));
}

export default loadCourseResource;
