import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import ResourceModal from '../../../../components/ResourceModal/ResourceModal.js';
import breakpoints from '../../../../globals/breakpoints';
import { hubDownloadResourceReset } from '../../../../redux/actions/hubResource';
import ResourceFormHidden from '../../../../structure/HubProductLayout/Resources/ResourceFormHidden';

function ContentPreviewModal({
  localizedContent: { cptGlossary: cptContent },
  hubDownloadResourceResetAction,
  resource,
  resourceAction,
  downloadResourceId
}) {
  return (
    <div>
      {resourceAction && resourceAction === 'play' && (
        <ResourceModal
          breakpoint={breakpoints.LG}
          resourceId={downloadResourceId}
          closeModal={() => {
            hubDownloadResourceResetAction();
          }}
          downloadResource={null}
          resource={{
            resourceId: downloadResourceId,
            subtitleResourceId: null,
            itemId: downloadResourceId,
            title: resource ? resource.resourceName : '',
            format: 'mp3'
          }}
          hubContent={cptContent}
          downloadIsLoading={false}
          url=""
        />
      )}
      <ResourceFormHidden />
    </div>
  );
}

ContentPreviewModal.propTypes = {
  localizedContent: PropTypes.object,
  hubDownloadResourceResetAction: PropTypes.func.isRequired,
  resourceAction: PropTypes.string.isRequired,
  downloadResourceId: PropTypes.string,
  resource: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('contentPreview', 'cptGlossary'),
  connect(
    state => {
      const {
        hubResourceDownload: { resourceAction, resource, downloadResourceId }
      } = state;

      return { resourceAction, resource, downloadResourceId };
    },
    dispatch => ({
      hubDownloadResourceResetAction: () => {
        dispatch(hubDownloadResourceReset());
      }
    })
  )
)(ContentPreviewModal);
