import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setFormState } from '../../../../redux/reducers/data/forms.reducer.js';

import ConnectedField from '../../../CreateOrganisation/formUtil/ConnectedField.js';
import FormValidity from '../../../CreateOrganisation/formUtil/FormValidity.js';

// Component imports
import Button, { buttonTypes } from '../../../../components/Button/Button.js';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch.js';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import ConnectedOrgRepresentation from '../../../../components/OrgRepresentation/ConnectedOrgRepresentation.js';

import content from '../../../../utils/cmsContent.js';

// Rudimantary way to control focus as we flip between "Delete" and "Are you sure" states:
function thenSetFocusOnPrimaryButton() {
  setTimeout(() => {
    document.getElementById('PopoutActionFooterPrimaryLink').focus();
  }, 0);
}
function thenSetFocusOnSecondaryButton() {
  setTimeout(() => {
    document.getElementById('PopoutActionFooterSecondaryLink').focus();
  }, 0);
}

function OrgArchiveInputting({
  formState,
  closePanel,

  // Only needed when we want to show a Back button, such as when Archive is launched from EditOrg:
  backButtonAction,

  // Current name
  organisationId,

  // Form submission action
  submitFormAction,
  submitFormForRealAction,
  resetFormAction
}) {
  const CMS = content.orgArchivePage || {};

  // We'll show wizard-style Back button if a method has been provided:
  // This is needed when Archive is launched from EditOrg.
  const showBackButton = !!backButtonAction;
  const showAreYouSure = formState !== 'INPUTTING';

  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        modalFooter={showAreYouSure}
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink
                isLhs={false}
                text={CMS.button_close_text}
                action={() => closePanel(false)}
                disabled={showAreYouSure}
              />
            </div>
            <PopoutPanelIconHeading
              type={iconHeadingTypes.WARNING}
              title={CMS.beforeArchive_heading_text}
              subtitle={CMS.beforeArchive_subheading_text}
            />
          </div>
        }
        footerContent={
          !showAreYouSure ? (
            // This footer is unusual in that (a) it has 2 states (INPUTTING & ARE_YOU_SURE) and (b) it only shows a back button when opened from EditOrg
            <FormValidity
              formName="orgArchive"
              requiredFields={['userConcent']}
              render={valid => (
                <ConnectedField
                  formName="orgArchive"
                  fieldName="emailNotify"
                  initialValue={false}
                  positiveValidationFunction={value => value}
                  render={(emailNotifyId, emailNotifyValue, emailNotifyOnChange) => (
                    <PopoutActionFooter
                      primaryButtonText={CMS.button_submit_text}
                      primaryButtonAction={() => {
                        submitFormAction();
                        thenSetFocusOnSecondaryButton();
                      }}
                      primaryButtonDisabled={!valid}
                      secondaryButtonText={showBackButton ? CMS.button_back_text : undefined}
                      secondaryButtonAction={backButtonAction}
                      wizardButtons={showBackButton}
                      toggleSwitchId={emailNotifyId}
                      toggleSwitchLabel={CMS.field_option_notifyByEmail_label}
                      toggleSwitchValue={emailNotifyValue}
                      toggleSwitchOnChange={emailNotifyOnChange}
                    >
                      <div>
                        <ConnectedField
                          formName="orgArchive"
                          fieldName="userConcent"
                          initialValue={false}
                          positiveValidationFunction={value => value}
                          render={(userConcentId, userConcentValue, userConcentOnChange) => (
                            <ToggleSwitch
                              id={userConcentId}
                              label={CMS.field_option_writtenConfirmation_label}
                              value={userConcentValue}
                              onChange={userConcentOnChange}
                            />
                          )}
                        />
                        <p className="pad2 gin-top2 gin-bot2 color-borders-bg">
                          {CMS.field_option_writtenConfirmation_message}
                        </p>
                      </div>
                    </PopoutActionFooter>
                  )}
                />
              )}
            />
          ) : (
            // ARE_YOU_SURE
            <PopoutActionFooter>
              <div>
                <h3 className="text-center">{CMS.button_areYouSure_submit_warning}</h3>
                <p className="text-center gin-top2">{CMS.button_areYouSure_submit_question}</p>
                <div className="gin-top2">
                  <Button
                    id="PopoutActionFooterPrimaryLink"
                    fullWidth
                    type={buttonTypes.THERE_BE_DRAGONS}
                    text={CMS.button_submit_text}
                    onClick={submitFormForRealAction}
                  />
                </div>
                <div className="gin-top2">
                  <Button
                    id="PopoutActionFooterSecondaryLink"
                    fullWidth
                    type={buttonTypes.SECONDARY}
                    text={CMS.button_cancel_text}
                    onClick={() => {
                      resetFormAction();
                      thenSetFocusOnPrimaryButton();
                    }}
                  />
                </div>
              </div>
            </PopoutActionFooter>
          )
        }
      >
        {/* The only content not in the panel's header/footer is a little summary of the org: */}
        <div className="pad2">
          {organisationId ? (
            <ConnectedOrgRepresentation entityId={organisationId} />
          ) : (
            <p>
              <em>{CMS.no_org_selected_message}</em>
            </p>
          )}
        </div>
      </ScrollContainer>
    </form>
  );
}

OrgArchiveInputting.propTypes = {
  formState: PropTypes.string.isRequired,
  closePanel: PropTypes.func.isRequired,

  // Only needed when we want to show a Back button, such as when Archive is launched from EditOrg:
  backButtonAction: PropTypes.func,

  organisationId: PropTypes.string.isRequired,

  // Submit action
  submitFormAction: PropTypes.func.isRequired,
  submitFormForRealAction: PropTypes.func.isRequired,
  resetFormAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    organisationId: (state.fields['orgArchive.orgId'] || {}).value,
    formState: state.forms.orgArchive.formState
  }),
  {
    // Submit button
    submitFormAction: () => setFormState('orgArchive', 'ARE_YOU_SURE'),
    submitFormForRealAction: () => setFormState('orgArchive', 'SUBMITTING'),
    resetFormAction: () => setFormState('orgArchive', 'INPUTTING')
  }
)(OrgArchiveInputting);
