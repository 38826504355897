import React from 'react';
import PropTypes from 'prop-types';
import styles from './ArticleVideos.scss';

// Toggle play/pause when video is clicked anywhere
function playPause({ target }) {
  return target.paused ? target.play() : target.pause();
}

function ArticleVideos({ title: heading, videos }) {
  return videos && videos.length ? (
    <div className={styles.ArticleVideos}>
      <h2>{heading}</h2>

      <ul className="row">
        {videos.map((video, i) => (
          <li key={i} className="col sm6">
            <div className={styles.videoContainer}>
              <video className="video" controls poster={video.poster_src} onClick={playPause}>
                <track src={video.track_src} label="English subtitles" kind="captions" srcLang="en" default />
                <source src={video.video_src} type="video/mp4" />
              </video>
              <h4>{video.heading}</h4>
              <div>{video.subheading}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}

ArticleVideos.propTypes = {
  title: PropTypes.string.isRequired, // Heading
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
      video_src: PropTypes.string.isRequired,
      track_src: PropTypes.string.isRequired,
      poster_src: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ArticleVideos;
