const SET_OUP_ADMIN_SELECTED = 'institutionPage/SET_OUP_ADMIN_SELECTED';
const SET_OUP_SUPPORT_SELECTED = 'institutionPage/SET_OUP_SUPPORT_SELECTED';
const SET_OUP_CONTENT_SELECTED = 'institutionPage/SET_OUP_CONTENT_SELECTED';
const SET_OUP_CONTENT_REVIEWER_SELECTED = 'institutionPage/SET_OUP_CONTENT_REVIEWER_SELECTED';
const SET_EXTERNAL_CONTENT_REVIEWER_SELECTED = 'institutionPage/SET_EXTERNAL_CONTENT_REVIEWER_SELECTED';
const SET_ORG_SELECTED = 'institutionPage/SET_ORG_SELECTED';
const SET_OTHER_USER_SELECTED = 'institutionPage/SET_OTHER_USER_SELECTED';

const initialState = {
  selectedOupAdminIds: [],
  selectedOupSupportIds: [],
  selectedOupContentIds: [],
  selectedOupContentReviewerIds: [],
  selectedExternalContentReviewerIds: [],
  selectedOrgIds: [],
  selectedOtherUserIds: []
};

function addOrRemove(selectedIds, id, isSelected) {
  return isSelected ? [...selectedIds, id] : selectedIds.filter(oldId => oldId !== id);
}

export default function institutionPage(state = initialState, action = {}) {
  switch (action.type) {
    case SET_OUP_ADMIN_SELECTED:
      return {
        ...state,
        selectedOupAdminIds: addOrRemove(state.selectedOupAdminIds, action.oupAdminId, action.isSelected)
      };
    case SET_OUP_SUPPORT_SELECTED:
      return {
        ...state,
        selectedOupSupportIds: addOrRemove(state.selectedOupSupportIds, action.oupSupportId, action.isSelected)
      };
    case SET_OUP_CONTENT_SELECTED:
      return {
        ...state,
        selectedOupContentIds: addOrRemove(state.selectedOupContentIds, action.oupContentId, action.isSelected)
      };
    case SET_OUP_CONTENT_REVIEWER_SELECTED:
      return {
        ...state,
        selectedOupContentReviewerIds: addOrRemove(
          state.selectedOupContentReviewerIds,
          action.oupContentReviewerId,
          action.isSelected
        )
      };
    case SET_EXTERNAL_CONTENT_REVIEWER_SELECTED:
      return {
        ...state,
        selectedExternalContentReviewerIds: addOrRemove(
          state.selectedExternalContentReviewerIds,
          action.externalContentReviewerId,
          action.isSelected
        )
      };

    case SET_ORG_SELECTED:
      return {
        ...state,
        selectedOrgIds: addOrRemove(state.selectedOrgIds, action.orgId, action.isSelected)
      };
    case SET_OTHER_USER_SELECTED:
      return {
        ...state,
        selectedOtherUserIds: addOrRemove(state.selectedOtherUserIds, action.userId, action.isSelected)
      };
    default:
      return state;
  }
}

// Update list selection actions
export const setOupAdminSelected = (oupAdminId, isSelected) => ({
  type: SET_OUP_ADMIN_SELECTED,
  oupAdminId,
  isSelected
});
export const setOupSupportSelected = (oupSupportId, isSelected) => ({
  type: SET_OUP_SUPPORT_SELECTED,
  oupSupportId,
  isSelected
});
export const setOupContentSelected = (oupContentId, isSelected) => ({
  type: SET_OUP_CONTENT_SELECTED,
  oupContentId,
  isSelected
});
export const setOupContentReviewerSelected = (oupContentReviewerId, isSelected) => ({
  type: SET_OUP_CONTENT_REVIEWER_SELECTED,
  oupContentReviewerId,
  isSelected
});
export const setExternalContentReviewerSelected = (externalContentReviewerId, isSelected) => ({
  type: SET_EXTERNAL_CONTENT_REVIEWER_SELECTED,
  externalContentReviewerId,
  isSelected
});
export const setOrgSelected = (orgId, isSelected) => ({
  type: SET_ORG_SELECTED,
  orgId,
  isSelected
});
export const setOtherUserSelected = (userId, isSelected) => ({
  type: SET_OTHER_USER_SELECTED,
  userId,
  isSelected
});
