import { put } from 'redux-saga/effects';
import { loadCoursesSuccess, loadCoursesFailure } from '../../../../actions/hubCourses';
import getCourses from '../../../apiCalls/hub/getCourses';

function* loadCourses() {
  const response = yield getCourses();

  if (response.status === 'success') {
    yield put(loadCoursesSuccess(response.data));
  } else {
    yield put(loadCoursesFailure('Failed to get courses'));
  }
}

export default loadCourses;
