import * as t from '../../actionTypes';

export const mode = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

const initialState = {
  bookTitle: null,
  mode: null
};

export default function inspectionCopyBookTitle(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_BOOK_TITLE_REQUEST:
      return {
        ...state,
        mode: mode.LOADING,
        bookTitle: null,
        errors: {}
      };
    case t.GET_BOOK_TITLE_SUCCESS:
      return {
        ...state,
        mode: mode.SUCCESS,
        bookTitle: payload.book.title,
        errors: {}
      };
    case t.GET_BOOK_TITLE_FAILURE:
      return {
        ...state,
        mode: mode.ERROR,
        bookTitle: null,
        errors: payload.errors
      };

    default:
      return state;
  }
}
