import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';

import withLocalizedContent from '../../language/withLocalizedContent';
import OTCPlaformRegistrationForm from './OTCPlatformRegistrationForm';

function PlatformRegistrationPage({ hasOTCEntitlement, localizedContent }) {
  const { platform } = useParams();
  const { push } = useHistory();

  const { hubGlossary, platformRegistrationPage } = localizedContent;

  if (platform === 'otc' && hasOTCEntitlement) {
    return (
      <>
        <Helmet title={platformRegistrationPage.page_title} titleTemplate={hubGlossary.page_title_template} />
        <OTCPlaformRegistrationForm />
      </>
    );
  }

  push('/');
  return null;
}

PlatformRegistrationPage.propTypes = {
  hasOTCEntitlement: propTypes.bool.isRequired,
  localizedContent: propTypes.object.isRequired
};

export default withLocalizedContent(
  'hubGlossary',
  'platformRegistrationPage'
)(
  connect(state => ({
    hasOTCEntitlement: Object.keys(state.userPlatformStatistics.redeemed).includes('otc')
  }))(PlatformRegistrationPage)
);
