import { select } from 'redux-saga/effects';

import { EAC_SYSTEM_IDS } from '@oup/shared-node-browser/constants';
import addActivationCodeApi from '../../../../apiCalls/users/addActivationCode.api.js';
import addVSTActivationCodeApi from '../../../../apiCalls/users/addVSTActivationCode.api.js';
import validateActivationCodeApi from '../../../../apiCalls/users/validateActivationCode.api.js';
import { getCurrentPlatform, isHubMode } from '../../../../../../utils/platform';
import { featureIsEnabled } from '../../../../../../globals/envSettings.js';

function* singleInputToProfileRequest() {
  const { userId, activationCode, isVstCode, reCAPTCHAToken } = yield select(state => ({
    userId: state.identity.userId,
    activationCode: state.addToProfile.redeemCodeValue,
    isVstCode: state.addToProfile.isVstCode,
    reCAPTCHAToken: state.reCAPTCHA.token
  }));

  console.log(`[singleInputToProfileRequest Saga] Submitting request to upload licence: ${activationCode}.`);

  if (isVstCode) {
    const vstCode = activationCode.split(' ').join('');
    return yield addVSTActivationCodeApi({
      accessCode: vstCode,
      reCAPTCHAToken,
      reCAPTCHATokenSource: getCurrentPlatform()
    });
  }
  return yield addActivationCodeApi({
    userId,
    activationCode
  });
}

function* validateActivationCodeRequest() {
  const { activationCode, isVstCode, reCAPTCHAToken } = yield select(state => ({
    activationCode: state.addToProfile.redeemCodeValue,
    isVstCode: state.addToProfile.isVstCode,
    reCAPTCHAToken: state.reCAPTCHA.token
  }));

  if (isVstCode) {
    // For VST codes we have a seperate redeem api which handle the validation as well, so we should skip the EAC validation
    return { status: 'success', message: '' };
  }

  console.log(`[validateActivationCodeRequest Saga] Submitting request to validate activationcode: ${activationCode}.`);
  // systemsIds - Is a property which needs to be passed to EAC for validation
  const { OLB, VST, PREMIUM, ECOMM, CBA, MFL } = EAC_SYSTEM_IDS;
  let defaultSystemIds = `${ECOMM},${OLB},${VST},${PREMIUM}`;

  if (isHubMode()) defaultSystemIds += `,${CBA}`;

  if (featureIsEnabled('mfl-features')) defaultSystemIds += `,${MFL}`;

  return yield validateActivationCodeApi({
    activationCode,
    systemId: defaultSystemIds,
    reCAPTCHAToken,
    reCAPTCHATokenSource: getCurrentPlatform()
  });
}

export default { singleInputToProfileRequest, validateActivationCodeRequest };
