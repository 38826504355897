import { put } from 'redux-saga/effects';
import { retrieveLTIConsumerSuccess } from '../../../../actions/ngsLTIOpener';
import signedFetch from '../../../apiCalls/util/signedFetch.js';
import { featureIsEnabled } from '../../../../../globals/envSettings';

const olbParams = content =>
  featureIsEnabled('gradebook-first-and-last-answer') && content.attemptId !== ''
    ? {
        product: 'olb',
        bid: content.bid,
        uid: content.uid,
        userId: content.learnerId,
        orgId: content.orgId,
        attemptId: content.attemptId
      }
    : {
        product: 'olb',
        bid: content.bid,
        uid: content.uid,
        userId: content.learnerId,
        orgId: content.orgId
      };

const CONSUMER_PARAMS_GENERATOR = {
  unity: content => ({
    product: 'unity',
    openQuizPanel: content.openQuizPanel,
    studentId: content.studentId ? content.studentId : '',
    id: content.external_id.toString()
  }),
  kerboodle: content => ({
    product: 'kerboodle',
    id: content.external_id.toString()
  }),
  ereader: content => ({
    product: 'ereader',
    title: content.external_id.toString(),
    library: content.library,
    layout: 'hybrid',
    theme: 'nextgen'
  }),
  olb: content => olbParams(content)
};

function* retrieveLTIConsumer(content) {
  const paramsGenerator = CONSUMER_PARAMS_GENERATOR[content.platform];
  const paramsObj = paramsGenerator(content);

  const queryString = Object.keys(paramsObj)
    .map(key => `${key}=${paramsObj[key]}`)
    .join('&');
  const url = `${__API_BASE__}/lti/consumer?${queryString}`;

  const response = yield signedFetch('LTIConsumer', url, 'POST');

  yield put(retrieveLTIConsumerSuccess(response.data));
}

export default retrieveLTIConsumer;
