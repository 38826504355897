import * as t from '../actionTypes';

export const setStudentsToRestore = ({ userIds, orgId }) => ({
  type: t.RESTORE_STUDENTS_SET,
  payload: { userIds, orgId }
});

export const restoreStudentsRequest = () => ({
  type: t.RESTORE_STUDENTS_REQUEST,
  payload: null
});

export const restoreStudentsSuccess = () => ({
  type: t.RESTORE_STUDENTS_SUCCESS,
  payload: null
});

export const restoreStudentsError = () => ({
  type: t.RESTORE_STUDENTS_ERROR,
  payload: null
});

export const restoreStudentsReset = () => ({
  type: t.RESTORE_STUDENTS_RESET,
  payload: null
});

export const restoreStudentsSubmitting = () => ({
  type: t.RESTORE_STUDENTS_SUBMITTING,
  payload: null
});
