import isOfflineClient from './components/MyDownloads/OfflineHelper';

const prepareServiceWorker = (client, navigator) => {
  const scriptName = `Client for ${client}`;
  if ('serviceWorker' in navigator) {
    console.log(`${scriptName} - Service worker processing`);
    // @ts-ignore
    if (__SERVICE_WORKER_ENABLED__ || isOfflineClient(client)) {
      console.log(`${scriptName} - Adding service worker`);
      // add service worker
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(data => console.log(`${scriptName} - Service worker registration successfull: ${JSON.stringify(data)}`))
        .catch(err => console.log(`${scriptName} - Service worker registration error: ${JSON.stringify(err)}`));
    } else {
      // remove any service workers for ths domain
      console.log(`${scriptName} - Checking for service worker(s)`);
      navigator.serviceWorker
        .getRegistrations()
        .then(registrations => {
          const count = registrations.length;
          if (count === 0) {
            console.log(`${scriptName} - You have no active service workers on this domain`);
          } else {
            console.log(`${scriptName} - You have ${count} active service workers on this domain`);
            registrations.forEach(registration => {
              console.log(`${scriptName} - Removing service worker: ${JSON.stringify(registration)}`);
              // Unregister service worker
              registration
                .unregister()
                .then(data => {
                  console.log(`${scriptName} - Service worker unregistered: ${JSON.stringify(data)}`);
                  // Redirecting to complete removal of service worker(s)
                  setTimeout(() => {
                    console.log(`${scriptName} - Redirecting to remove service worker`);
                    window.location.replace(window.location.href);
                  }, 3000);
                })
                .catch(err => console.log(`${scriptName} - Service worker unregister error: ${JSON.stringify(err)}`));
            });
          }
        })
        .catch(err => console.log(`${scriptName} - getRegistrations error: ${JSON.stringify(err)}`));
    }
  }
};

export default prepareServiceWorker;
