/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Action imports

import {
  resetProductFinderState,
  getProductFinderLicencesRequest,
  setProductFinderAssignLicences,
  setAssignLicenceToTeacher,
  goToPreviousFormState,
  setFormState
} from '../../../redux/actions/productFinderActions';

import { toggleDetailsPopupWithId } from '../../../redux/reducers/assignLearningMaterial.reducer';
import { productFinderFormStates } from '../../../redux/reducers/productFinder.reducer';

// Component imports
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import PanelNavigationLink from '../../PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../PopoutActionFooter/PopoutActionFooter';
import { SIZES as thumbnailSizes } from '../../Thumbnail/Thumbnail';
import MaterialRepresentation from '../../MaterialRepresentation/MaterialRepresentation';
import { GLYPHS } from '../../SVGIcon/SVGIcon';
import Button, { buttonTypes } from '../../Button/Button';
import { sanitizeUrl } from '../../../utils/url';
import styles from './ProductFinderFormStates.scss';

import ProductFinderAssignmentSummary from '../reviewLicences/ProductFinderAssignmentSummary';
import ProductFinderUserAssignments from '../reviewLicences/ProductFinderUserAssignments';
import { STATUS_TYPE } from '../../../globals/appConstants';
import ProductFinderLicencesFailed from '../reviewLicences/ProductFinderLicencesFailed';
import ProductFinderLoadingLicences from '../reviewLicences/ProductFinderLoadingLicences';

const licenceAvailabilityText = (productFinderContent, availableCount, assignedCount) => {
  if (availableCount > 0) {
    return `${availableCount} ${productFinderContent.count_licences_available_text}`;
  }
  if (assignedCount > 0) {
    return productFinderContent.no_licences_available_text;
  }
  return null;
};
const licenceAvailabilityStatus = (availableCount, assignedCount, totalUser, licencesAvailableCount) => {
  if (availableCount > 0 && totalUser <= licencesAvailableCount) {
    return STATUS_TYPE.SUCCESS;
  }

  if (availableCount > 0 && totalUser > licencesAvailableCount) {
    return STATUS_TYPE.WARNING;
  }

  if (assignedCount > 0) {
    return STATUS_TYPE.ERROR;
  }
  return null;
};

function ProductFinderReviewLicences({
  productFinderContent,
  orgId,
  classId,
  selectedUsers,
  product,
  contextName,
  closePopoutAction,
  goToPreviousFormStateAction,
  licencesAvailableCount,
  loadingLicences,
  loadingLicencesFailed,
  toggleDetailsAction,
  getProductFinderLicencesRequestAction,
  teacherIdList = [],
  studentIdList = [],
  existingAssignmentId,
  people,
  assignLicences,
  licencesAvailable,
  licencesTypes,
  numberOfUsersNeedingLicences,
  proposedAssignments,
  usersIdsWithLicences,
  usersExistingLicenceDetails,
  setAssignLicencesAction,
  setAssignLicenceToTeacherAction,
  assignLicenceToTeacher,
  disabledTeacherAssign,
  userIdsToAssignLicencesTo,
  userIdsToNotAssignLicencesTo,
  userIdsWithoutPermission,
  totalUserCount,
  setFormStateAction
}) {
  const [assignedCount, setAssignedCount] = useState(0);
  const [availableCount, setAvailableCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (product?.productid) {
      getProductFinderLicencesRequestAction({ orgId, classId, selectedUsers, product, assignLicenceToTeacher });
    }
  }, [product]);

  useEffect(() => {
    if (licencesAvailable && totalUserCount <= licencesAvailableCount) {
      setAssignLicencesAction(true);
    } else {
      setAssignLicencesAction(false);
    }
  }, [licencesAvailable]);

  useEffect(() => {
    if (licencesTypes?.length) {
      let totalAssignedCount = 0;
      let totalAvailableCount = 0;
      licencesTypes.forEach(licence => {
        totalAssignedCount += licence.amountAssigning;
        totalAvailableCount += licence.availableCount;
      });
      setAssignedCount(totalAssignedCount);
      setAvailableCount(totalAvailableCount);
    }
  }, [licencesTypes]);

  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        headerContent={
          <div>
            <div className={styles.textRight}>
              <PanelNavigationLink
                isLhs={false}
                text={productFinderContent.close_panel_text}
                action={closePopoutAction}
              />
            </div>
            <div className={styles.productContainer}>
              <MaterialRepresentation
                thumbnailSize={thumbnailSizes.TABLE}
                imageSrc={sanitizeUrl(product?.cover || product?.coverImage || '')}
                name={product.title}
                subtext={product.subtitle}
                statusText={licenceAvailabilityText(productFinderContent, availableCount, assignedCount)}
                statusColor={licenceAvailabilityStatus(
                  availableCount,
                  assignedCount,
                  totalUserCount,
                  licencesAvailableCount
                )}
                headerSizeName
                ellipsisHeading
                statusIndication
              />
              <div className={styles.alignedRight}>
                <Button
                  type={buttonTypes.INFO_NO_BORDER}
                  glyph={GLYPHS.ICON_INFORMATION_CIRCLE}
                  iconOnly
                  onClick={() => toggleDetailsAction(product.productid, null, null)}
                />
              </div>
            </div>
          </div>
        }
        footerContent={
          <PopoutActionFooter
            secondaryButtonText={
              existingAssignmentId ? productFinderContent.cancel_button_text : productFinderContent.button_back_text
            }
            secondaryButtonAction={existingAssignmentId ? closePopoutAction : goToPreviousFormStateAction}
            primaryButtonText={productFinderContent.next_button_text}
            primaryButtonAction={() => {
              setFormStateAction(productFinderFormStates.REVIEW);
            }}
            primaryButtonDisabled={false}
            primaryButtonTestHook="ASSIGN_LEARNING_MATERIAL_REVIEW_LICENCES_NEXT"
            wizardButtons
          />
        }
      >
        <div className={styles.licencesContainer}>
          {loadingLicences ? (
            <ProductFinderLoadingLicences productFinderContent={productFinderContent} />
          ) : (
            <div>
              {loadingLicencesFailed ? (
                <ProductFinderLicencesFailed
                  productFinderContent={productFinderContent}
                  getProductFinderLicences={getProductFinderLicencesRequestAction}
                />
              ) : (
                <div>
                  {!selectedTab ? (
                    <ProductFinderAssignmentSummary
                      productFinderContent={productFinderContent}
                      shouldAssignLicences={assignLicences}
                      hasLicencesAvailable={licencesAvailable}
                      licencesTypes={licencesTypes}
                      setAssignLicencesAction={setAssignLicencesAction}
                      toggleView={() => setSelectedTab(1)}
                      licencesAvailableCount={licencesAvailableCount}
                      usersNeedingLicenceCount={numberOfUsersNeedingLicences}
                      totalUserCount={totalUserCount}
                      usersWithLicenceCount={usersIdsWithLicences?.length}
                      contextName={contextName}
                      disabledTeacherAssign={disabledTeacherAssign}
                      productTargetUsertype={product.target_usertype}
                      productPlatform={product.platform}
                      userIdsWithExistingLicence={usersIdsWithLicences}
                      teacherIdList={teacherIdList}
                      setAssignLicenceToTeacherAction={setAssignLicenceToTeacherAction}
                      assignLicenceToTeacher={assignLicenceToTeacher}
                      classId={classId}
                    />
                  ) : (
                    <ProductFinderUserAssignments
                      productFinderContent={productFinderContent}
                      users={people}
                      assignLicences={assignLicences}
                      newLicenceAssignments={proposedAssignments}
                      existingLicenceAssignments={usersExistingLicenceDetails}
                      userIdsWithExistingLicence={usersIdsWithLicences}
                      userIdsToAssignLicencesTo={userIdsToAssignLicencesTo}
                      userIdsToNotAssignLicencesTo={userIdsToNotAssignLicencesTo}
                      teacherIdList={teacherIdList}
                      studentIdList={studentIdList}
                      toggleView={() => setSelectedTab(0)}
                      assignLicenceToTeacher={assignLicenceToTeacher}
                      userIdsWithoutPermission={userIdsWithoutPermission}
                      productTargetUsertype={product?.target_usertype}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </ScrollContainer>
    </form>
  );
}

export default connect(
  state => {
    const productId = state.productFinder?.product?.productid;
    const {
      usersWithoutAssignments = [],
      licencesAvailable,
      licencesTypes = [],
      userIdsInOrder = [],
      proposedAssignments,
      usersIdsWithLicences,
      usersExistingLicenceDetails,
      userIdsWithoutPermission = [],
      teacherIds,
      studentIds,
      assignLicenceToTeacher = false,
      disabledTeacherAssign
    } = state.productFinder?.licenceStructure?.[productId] || {};

    const licencesAvailableCount = licencesTypes?.reduce(
      (total, currentValue) => total + currentValue.availableCount,
      0
    );

    const { existingAssignmentId, loadingLicences, loadingLicencesFailed, assignLicences } = state.productFinder;
    return {
      assignLicences,
      assignLicenceToTeacher,
      disabledTeacherAssign,
      existingAssignmentId,
      loadingLicences,
      loadingLicencesFailed,
      licencesAvailable,
      licencesTypes,
      proposedAssignments,
      usersIdsWithLicences,
      usersExistingLicenceDetails,
      numberOfUsersNeedingLicences: userIdsInOrder.length,
      licencesAvailableCount,
      userIdsToAssignLicencesTo: assignLicences ? userIdsInOrder : [],
      userIdsToNotAssignLicencesTo: assignLicences
        ? usersWithoutAssignments
        : userIdsInOrder.concat(usersWithoutAssignments),
      usersWithoutAssignments,
      totalUserCount: usersWithoutAssignments.length + userIdsInOrder.length,
      people: state.people.data || {},
      teacherIdList: teacherIds,
      studentIdList: studentIds,
      userIdsWithoutPermission,
      userRoleName: state.identity.role
    };
  },
  {
    resetProductFinderStateAction: resetProductFinderState,
    setAssignLicencesAction: setProductFinderAssignLicences,
    toggleDetailsAction: toggleDetailsPopupWithId,
    getProductFinderLicencesRequestAction: getProductFinderLicencesRequest,
    setAssignLicenceToTeacherAction: setAssignLicenceToTeacher,
    goToPreviousFormStateAction: goToPreviousFormState,
    setFormStateAction: setFormState
  }
)(ProductFinderReviewLicences);

ProductFinderReviewLicences.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  classId: PropTypes.string,
  orgId: PropTypes.string,
  // object containing two arrays, teacherIdList and studentIdList
  selectedUsers: PropTypes.object,
  // object containing the product being assigned
  product: PropTypes.object,
  // subtitle
  contextName: PropTypes.string,
  closePopoutAction: PropTypes.func,
  toggleDetailsAction: PropTypes.func,
  licencesAvailableCount: PropTypes.number,
  loadingLicences: PropTypes.bool,
  loadingLicencesFailed: PropTypes.bool,
  getProductFinderLicencesRequestAction: PropTypes.func,
  teacherIdList: PropTypes.array,
  studentIdList: PropTypes.array,
  setAssignLicenceToTeacherAction: PropTypes.func,
  assignLicenceToTeacher: PropTypes.bool,
  disabledTeacherAssign: PropTypes.bool,
  numberOfUsersNeedingLicences: PropTypes.number,
  existingAssignmentId: PropTypes.string,
  people: PropTypes.object.isRequired,
  assignLicences: PropTypes.bool.isRequired,
  licencesAvailable: PropTypes.bool,
  licencesTypes: PropTypes.array,
  proposedAssignments: PropTypes.object,
  usersIdsWithLicences: PropTypes.array,
  usersExistingLicenceDetails: PropTypes.object,
  setAssignLicencesAction: PropTypes.func.isRequired,
  userIdsToAssignLicencesTo: PropTypes.array.isRequired,
  userIdsToNotAssignLicencesTo: PropTypes.array.isRequired,
  totalUserCount: PropTypes.number.isRequired,
  userIdsWithoutPermission: PropTypes.array.isRequired,
  goToPreviousFormStateAction: PropTypes.func.isRequired,
  setFormStateAction: PropTypes.func.isRequired
};
