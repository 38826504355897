import PropTypes from 'prop-types';
import React from 'react';

import styles from './OrbTeacherSearchForm.scss';
import withLocalizedContent from '../../language/withLocalizedContent';

import { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import Button, { buttonTypes } from '../../components/Button/Button';
import TextInputWithButton from '../../components/TextInputWithButton/TextInputWithButton';

class OrbTeacherSearchForm extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    filters: PropTypes.array,
    autocompleteSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        match: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired,

    localizedContent: PropTypes.object.isRequired,

    onChange: PropTypes.func.isRequired,
    onSelectAutocompleteSuggestion: PropTypes.func.isRequired,
    onResetSearch: PropTypes.func.isRequired,
    doSearch: PropTypes.func.isRequired
  };

  static defaultProps = {
    filters: []
  };

  onKeyDownAutocompleteSuggestion = (value, e) => {
    if (e.keyCode === 13) {
      this.props.doSearch({ ...this.props.params, search: value });
    }

    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();

      let targetSuggestion;
      if (e.keyCode === 38) targetSuggestion = document.activeElement.previousSibling;
      if (e.keyCode === 40) targetSuggestion = document.activeElement.nextSibling;

      if (targetSuggestion) {
        targetSuggestion.focus();
      }
    }
  };

  onChangeSearch = value => {
    const { params, onChange } = this.props;
    onChange({ ...params, search: value });
  };

  onKeyDownOnSearch = e => {
    if (e.keyCode === 13) {
      this.props.doSearch(this.props.params);
    }

    if (e.keyCode === 40) {
      e.preventDefault();
      this.autocompleteSuggestionsList.firstChild.focus();
    }
  };

  onChangeFilter = ({ target: { name, value } }) => {
    const { params, onChange, doSearch } = this.props;
    const filters = params.filters || {};
    filters[name] = value;

    const newSearchParams = { ...params, filters };

    onChange(newSearchParams);
    doSearch(newSearchParams);
  };

  onSearchButtonClicked = () => {
    this.props.doSearch(this.props.params);
  };

  onResetForm = () => {
    this.props.onResetSearch();
    document.getElementById('search-input').focus();
  };

  renderAutocompleteSuggestions() {
    const { autocompleteSuggestions, onSelectAutocompleteSuggestion } = this.props;
    if (!autocompleteSuggestions.length) return null;

    return (
      <div
        className={styles.autocompleteSuggestions}
        ref={el => {
          this.autocompleteSuggestionsList = el;
        }}
      >
        {autocompleteSuggestions.map(({ match, value, field }, i) => (
          <div
            className={styles.item}
            key={i}
            onClick={() => onSelectAutocompleteSuggestion(value)}
            onKeyDown={this.onKeyDownAutocompleteSuggestion.bind(this, value)}
            role="button"
            tabIndex="0"
          >
            <span className={styles.match} dangerouslySetInnerHTML={{ __html: match }} />
            <span className={styles.field}>{field}</span>
          </div>
        ))}
      </div>
    );
  }

  renderFullTextSearch() {
    const {
      params,
      localizedContent: { orbTeacherResourcesPage: i18n }
    } = this.props;

    return (
      <div className={styles.search}>
        <TextInputWithButton
          id="search"
          label={i18n.search_placeholder}
          placeholder={i18n.search_placeholder}
          labelHidden
          value={params.search}
          onChange={this.onChangeSearch}
          onKeyDown={this.onKeyDownOnSearch}
          buttonIcon={GLYPHS.ICON_SEARCH}
          buttonAction={this.onSearchButtonClicked}
        />
        {this.renderAutocompleteSuggestions()}
      </div>
    );
  }

  renderFilter = (filter, i) => {
    const selectedValue = (this.props.params.filters || {})[filter.key] || '';

    return (
      <li key={i}>
        <select name={filter.key} onChange={this.onChangeFilter} value={selectedValue}>
          {filter.options.map(([title, value], y) => (
            <option value={value} key={y}>
              {title}
            </option>
          ))}
        </select>
      </li>
    );
  };

  renderFilters() {
    const {
      filters,
      localizedContent: { orbTeacherResourcesPage: i18n }
    } = this.props;

    return (
      <ul className="orb-filter">
        {filters.map(this.renderFilter)}
        <li>
          <Button onClick={this.onResetForm} type={buttonTypes.SECONDARY} text={i18n.start_again_button} />
        </li>
      </ul>
    );
  }

  render() {
    return (
      <div>
        {this.renderFullTextSearch()}
        <div className={styles.filters}>{this.renderFilters()}</div>
      </div>
    );
  }
}

export default withLocalizedContent('orbTeacherResourcesPage')(OrbTeacherSearchForm);
