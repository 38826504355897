/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
// Redux
import { connect } from 'react-redux';
import { setPageClass } from '../../redux/reducers/data/search.reducer.js';
import { setOnlineTest } from '../../redux/actions/onlineTestActions';
import { onlineTestCancelTitle } from '../../utils/onlineTestUtils';
// Utils
import getAssessmentParameters from '../../utils/getAssessmentParameters';
import withLocalizedContent from '../../language/withLocalizedContent';
import { getTestTitle } from '../../structure/HubCourseAssessments/Services/getTestData';
// Constants
import { HubLayoutConstants } from '../../globals/hubConstants';
// Components
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutNavFooter from '../PopoutNavFooter/PopoutNavFooter';
import styles from './OnlineTestCancelConfirmation.scss';
import reviewSettingsStyles from '../OnlineTestReviewSettings/OnlineTestReviewSettings.scss';

class OnlineTestCancelConfirmation extends Component {
  componentDidUpdate(prevProps) {
    const { onlineTest } = this.props;
    if (onlineTest.success !== prevProps.onlineTest.success) {
      const { switchToPage, isCancelPanelFlow = false } = this.props;
      const steps = HubLayoutConstants.ONLINE_TEST_STEPS;

      if (isCancelPanelFlow && onlineTest.success) {
        switchToPage({ page: steps.CANCEL_TEST_SUCCESS });
      } else {
        switchToPage({ page: steps.CANCEL_TEST_FAILURE });
      }
    }
  }

  render() {
    const {
      test,
      assessmentTitle,
      prev = null,
      localizedContent: { hubSetTestOnline: content },
      next,
      classroom,
      selectedStudentIds,
      totalNumberOfStudents,
      startDate,
      endDate,
      startHour,
      endHour,
      isCancelPanelFlow = false
    } = this.props;

    const parametersToShow = getAssessmentParameters(
      classroom,
      test,
      selectedStudentIds,
      totalNumberOfStudents,
      isCancelPanelFlow,
      startDate,
      endDate,
      startHour,
      endHour
    );

    const testTitle = getTestTitle(test);

    return (
      <ScrollContainer
        headerContent={
          <header>
            <div>
              <h2>{isCancelPanelFlow ? content.online_test_class_cancel_test : content.online_test_title}</h2>
              <div>{test ? `${assessmentTitle} ${testTitle}` : ''}</div>
            </div>
          </header>
        }
        footerContent={
          <PopoutNavFooter
            nextAction={() => next()}
            nextButtonText={content.online_test_class_cancel_test}
            {...(prev !== null ? { backAction: () => prev() } : {})}
          />
        }
      >
        <div className={reviewSettingsStyles.contentContainer}>
          <div className={styles.cancelQuestion}>
            {test &&
              `${content.online_test_cancel_question} ${onlineTestCancelTitle(
                assessmentTitle,
                testTitle,
                content,
                parametersToShow
              )}?`}
          </div>
          <div className={reviewSettingsStyles.sectionContainer}>
            <span>{content.test_opens}</span>
            <span>{`${parametersToShow.startDate}, ${parametersToShow.startHour}`}</span>
          </div>
          <div className={reviewSettingsStyles.sectionContainer}>
            <span>{content.test_closes}</span>
            <span>{`${parametersToShow.endDate}, ${parametersToShow.endHour}`}</span>
          </div>
        </div>
      </ScrollContainer>
    );
  }
}

export default compose(
  withLocalizedContent('hubSetTestOnline'),
  connect(
    ({ onlineTest, classrooms, identity, search }) => {
      const classroom = classrooms ? classrooms.data[onlineTest.classId] : {};
      return {
        classroom: classroom || {},
        totalNumberOfStudents: search.userSelection?.totalResults || [],
        selectedStudentIds: onlineTest.selectedStudentIds || [],
        startHour: onlineTest.startHour,
        endHour: onlineTest.endHour,
        startDate: onlineTest.startDate,
        endDate: onlineTest.endDate,
        userId: identity.userId,
        currentOrganisationId: identity.currentOrganisationId,
        onlineTest
      };
    },
    {
      setPageAction: page => setPageClass('profileClasses', page),
      setOnlineTestAction: setOnlineTest
    }
  )
)(OnlineTestCancelConfirmation);

OnlineTestCancelConfirmation.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  next: PropTypes.func,
  prev: PropTypes.func,
  test: PropTypes.object,
  assessmentTitle: PropTypes.string,
  classroom: PropTypes.object,
  totalNumberOfStudents: PropTypes.array,
  selectedStudentIds: PropTypes.array,
  startHour: PropTypes.string,
  endHour: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setOnlineTestAction: PropTypes.func,
  userId: PropTypes.string,
  currentOrganisationId: PropTypes.string,
  isCancelPanelFlow: PropTypes.bool,
  onlineTest: PropTypes.object,
  switchToPage: PropTypes.func
};
