import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './ContentWithThumbnail.scss';

import { validColors } from '../../globals/colors';
import { validGlyphs } from '../SVGIcon/SVGIcon';
import Thumbnail, { validSizes } from '../Thumbnail/Thumbnail';

function ContentWithThumbnail({
  size,
  text,
  glyph,
  backgroundColor,
  imageSrc,
  imageAlt,
  squareImg,
  disabled,
  thumbnailClassName,
  secondaryThumbnailSize,
  secondaryThumbnailGlyph,
  id,
  children,
  smallCircleBackgroundColor,
  smallCircleGlyph,
  smallCircleText,
  isNewEntity
}) {
  return (
    <div className={classnames(styles.contentWithThumbnail, styles[`size-${size}`])} id={id}>
      <div className={classnames(styles.thumbnail, thumbnailClassName)}>
        <Thumbnail
          size={size}
          text={text}
          glyph={glyph}
          backgroundColor={backgroundColor}
          imageSrc={imageSrc}
          imageAlt={imageAlt}
          squareImg={squareImg}
          disabled={disabled}
          smallCircleBackgroundColor={smallCircleBackgroundColor}
          smallCircleGlyph={smallCircleGlyph}
          smallCircleText={smallCircleText}
          isNewTag={isNewEntity}
        />
      </div>

      <div className={styles.children}>{children}</div>

      {secondaryThumbnailGlyph && secondaryThumbnailSize ? (
        <div className={classnames(styles.thumbnail)}>
          <Thumbnail size={secondaryThumbnailSize} glyph={secondaryThumbnailGlyph} />
        </div>
      ) : null}
    </div>
  );
}

// Validation should be improved so that one of `text`, `glyph` or `imageSrc` is required
ContentWithThumbnail.propTypes = {
  // Thumbnail props
  /** The size variant to display */
  size: PropTypes.oneOf(validSizes),
  /** The two-letter text to be shown if using the text variant of this component. */
  text: PropTypes.string,
  /** The SVGIcon to be shown if using the icon variant of this component. */
  glyph: PropTypes.oneOf(validGlyphs),
  /** The background color to be used if using the text or icon variants of this component. */
  backgroundColor: PropTypes.oneOf(validColors),
  /** The image to be shown if using the image variant of this component. */
  imageSrc: PropTypes.string,
  /** The alternative text for the img when using imageSrc. */
  imageAlt: PropTypes.string,
  /** If the image source is for a book cover then squareImg should be set. */
  squareImg: PropTypes.bool,
  /** If the components context is disabled then this will apply disabled styling.  */
  disabled: PropTypes.bool,
  /** The size variant to display */
  secondaryThumbnailSize: PropTypes.oneOf(validSizes),
  /** The SVGIcon to be shown if using the icon variant of this component. */
  secondaryThumbnailGlyph: PropTypes.oneOf(validGlyphs),
  /** This is an optional classname for the element which wraps the Thumbnail. Used by the ListPageHeading. */
  thumbnailClassName: PropTypes.string,
  /** The html ID of the element. */
  id: PropTypes.string,
  smallCircleBackgroundColor: PropTypes.oneOf(validColors),
  smallCircleGlyph: PropTypes.oneOf(validGlyphs),
  smallCircleText: PropTypes.string,
  // Children content
  children: PropTypes.node.isRequired,
  /** Entity is new */
  isNewEntity: PropTypes.bool
};

export default ContentWithThumbnail;
