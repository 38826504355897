import { takeLatest } from 'redux-saga/effects';

import * as t from '../../../actionTypes';
import getPlatformCountryCodes from './getPlatformCountryCodes';

function* root() {
  yield takeLatest(t.GET_PLATFORM_COUNTRY_CODES_REQUEST, ({ payload: role }) => getPlatformCountryCodes(role));
}

export default root;
