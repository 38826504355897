import { useState, useEffect } from 'react';

const usePagination = (array, itemsPerPage, initialPage = 1) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (array.length > 0) {
      const pages = Math.ceil(array.length / itemsPerPage);
      setTotalPages(pages);
    }
  }, [array, itemsPerPage]);

  const setPage = page => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return {
    currentPage,
    setPage,
    totalPages
  };
};

export default usePagination;
