import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../ScrollContainer/ScrollContainer';

function UserEditProcessing({ localizedContent: { userEditProcessingComponent: content }, isWaiting = false }) {
  let popoutPanelIconHeadingProps = {
    title: content.loading_title,
    subtitle: undefined
  };
  if (isWaiting) {
    popoutPanelIconHeadingProps = {
      title: content.waiting_title,
      subtitle: content.waiting_subtitle
    };
  }

  return (
    <ScrollContainer>
      <PopoutPanelIconHeading {...popoutPanelIconHeadingProps} type={popoutPanelIconHeadingTypes.LOADING} />
    </ScrollContainer>
  );
}

UserEditProcessing.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isWaiting: PropTypes.bool,
  isSavingChanges: PropTypes.bool,
  isSendingEmail: PropTypes.bool
};

export default withLocalizedContent('userEditProcessingComponent')(UserEditProcessing);
