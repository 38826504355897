import signedFetch from '../util/signedFetch.js';

/**
 * Get user details.
 *  Request params:
 *  - orgId: Integer - The organisation to check for user membership and invitation status in
 *  - userId: Integer - The user’s id
 */
export default (orgId, userId) =>
  signedFetch(
    'getUserDetails',
    `${__API_BASE__}/org/${encodeURIComponent(orgId)}/user/${encodeURIComponent(userId)}`,
    'GET',
    undefined
  );
