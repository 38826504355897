import { featureIsEnabled } from '../../globals/envSettings';

const INITIALISE_FORM = 'joinClass/INITIALISE_FORM';
export const SHOW_CONFIRMATION = 'joinClass/SHOW_CONFIRMATION';

export const SUBMIT_FORM = 'joinClass/SUBMIT_FORM';
export const SHOW_SUBMITTING = 'joinClass/SHOW_SUBMITTING';
export const SHOW_ERROR_REVIEW = 'joinClass/ERROR_REVIEW';

// Placement test session
export const SET_JOINED_PLACEMENT_SESSSION_INFORMATION = 'joinClass/SET_JOINED_PLACEMENT_SESSSION_INFORMATION';

// Reset the form
export const RESET_FORM = 'joinClass/RESET_FORM';

const SHOW_MODAL = 'joinClass/SHOW_MODAL';

const SET_CLASS_CODE = 'joinClass/SET_CLASS_CODE';
const VALIDATE_CLASS_CODE = 'joinClass/VALIDATE_CLASS_CODE';

const CLASS_CODE_REGEX = /^[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5,30}$/;
const SPECIAL_CHARS_REGEX = /[ `!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
export const formStates = {
  INPUTTING: 'INPUTTING',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION',
  ERROR_REVIEWING: 'ERROR_REVIEWING'
};

export const initialState = {
  formState: formStates.INPUTTING,
  file: null,
  // Access code input and validation values
  classCodeValue: '',
  classCodeValueValidationMessage: '',
  classCodeValueIsValid: false,
  classCodeValueIsError: false,
  hasSpecialCharError: false,
  hasInvalidLengthError: false,

  errorOccurred: null,
  errorMessage: null,
  showModal: true,
  classroomName: '',
  organisationName: '',
  groupType: '',
  // Placement test session state
  joinedPlacementStatus: '',
  placementTestId: ''
};

const formatCode = value => {
  if (value.length <= 5) {
    return value;
  }

  const constainsHyphen = value.startsWith('-', 5);
  const processedvalue = !constainsHyphen ? `${value.slice(0, 5)}-${value.slice(5)}` : value;
  return processedvalue;
};
const hasSpecialChar = code => SPECIAL_CHARS_REGEX.test(code);
const hasInvalidLengthError = code => code.length !== 0 && code.replaceAll('-', '').length < 10;

export default function joinClass(state = initialState, action) {
  switch (action.type) {
    // Initialise action
    case INITIALISE_FORM:
      return {
        ...state
      };
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SET_CLASS_CODE:
      return {
        ...state,
        classCodeValue: featureIsEnabled('opt-main-features')
          ? action.classCodeValue
          : formatCode(action.classCodeValue),
        classCodeValueIsValid: CLASS_CODE_REGEX.test(action.classCodeValue),
        classCodeValueIsError: false
      };
    case VALIDATE_CLASS_CODE:
      if (featureIsEnabled('opt-main-features')) {
        if (state.classCodeValue.length === 0) {
          return { ...state, ...initialState };
        }

        return {
          ...state,
          classCodeValue: state.classCodeValue,
          classCodeValueIsValid: !hasSpecialChar(state.classCodeValue) && !hasInvalidLengthError(state.classCodeValue),
          classCodeValueIsError: hasSpecialChar(state.classCodeValue) || hasInvalidLengthError(state.classCodeValue),
          hasSpecialCharError: hasSpecialChar(state.classCodeValue),
          hasInvalidLengthError: hasInvalidLengthError(state.classCodeValue)
        };
      }

      return {
        ...state,
        classCodeValueIsValid: CLASS_CODE_REGEX.test(state.classCodeValue),
        classCodeValueIsError: state.classCodeValue ? state.classCodeValue.length < 11 : false
      };
    case SHOW_SUBMITTING:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SHOW_ERROR_REVIEW:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        errorOccurred: true,
        errorMessage: action.errorMessage
      };
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        classroomName: action.classroomName,
        errorOccurred: action.errorOccurred,
        errorMessage: action.errorMessage
      };
    case SHOW_MODAL:
      return {
        ...state,
        showModal: action.showModal
      };

    // Placement test
    case SET_JOINED_PLACEMENT_SESSSION_INFORMATION:
      return {
        ...state,
        joinedPlacementStatus: action.placementStatus,
        placementTestId: action.placementTestId
      };
    // Reset the form
    case RESET_FORM:
      return {
        ...initialState,
        classCodeValue: '',
        classCodeValueIsValid: false
      };
    default:
      return state;
  }
}

// Initialise action
export const initialiseForm = () => ({
  type: INITIALISE_FORM
});

// Submission and result actions
export const submitForm = () => ({
  type: SUBMIT_FORM
});

export const validateClassCode = () => ({
  type: VALIDATE_CLASS_CODE
});

export const setClassCode = classCodeValue => ({
  type: SET_CLASS_CODE,
  classCodeValue
});

export const showConfirmation = (errorOccurred, errorMessage, classroomName) => ({
  type: SHOW_CONFIRMATION,
  classroomName,
  errorOccurred,
  errorMessage
});

export const setPlacementSessionInformation = (placementStatus, placementTestId) => ({
  type: SET_JOINED_PLACEMENT_SESSSION_INFORMATION,
  placementStatus,
  placementTestId
});

export const showErrorReview = errorMessage => ({
  type: SHOW_ERROR_REVIEW,
  errorMessage
});

// Reset the form
export const resetForm = () => ({
  type: RESET_FORM
});

export const showClosePanelModal = showModal => ({
  type: SHOW_MODAL,
  showModal
});
