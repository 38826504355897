import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './LandingImage.scss';

import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';

function LandingImage({ urls, altText, className }) {
  if (!urls) {
    return (
      <div className={classnames(styles.imagePlaceholder, className, 'missing-image')}>
        <SVGIcon glyph={GLYPHS.OIC_MISSING_RESOURCE_COVER} />
      </div>
    );
  }

  return (
    <picture className={className}>
      {urls.webp && <source srcSet={urls.webp} type="image/webp" alt={altText} />}
      <source srcSet={urls.default} type="image/jpeg" alt={altText} />
      <img src={urls.default} alt={altText} />
    </picture>
  );
}

LandingImage.propTypes = {
  urls: PropTypes.shape({
    default: PropTypes.string,
    webp: PropTypes.string
  }),
  altText: PropTypes.string,
  className: PropTypes.string
};

export default LandingImage;
