import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon.js';
import styles from '../formStates/ProductFinderFormStates.scss';

function ProductFinderLoadingLicences({ productFinderContent }) {
  return (
    <div className={styles.licenceLoadingContainer}>
      <div className={styles.licenceLoadingIconContainer}>
        <SVGIcon glyph={GLYPHS.ICON_LOADING} />
      </div>
      <p className={styles.paragraph}>{productFinderContent.loading_licences_text}</p>
    </div>
  );
}

ProductFinderLoadingLicences.propTypes = {
  productFinderContent: PropTypes.object.isRequired
};

export default ProductFinderLoadingLicences;
