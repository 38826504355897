import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Component imports
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon.js';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch.js';
import cmsContent from '../../../../utils/cmsContent.js';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { assignmentContexts } from '../../../../globals/assignmentConstants';
import { featureIsEnabled } from '../../../../globals/envSettings';
import colors from '../../../../globals/colors';
import styles from './ReviewLicencesViews.scss';
import MaterialRepresentation from '../../../../components/MaterialRepresentation/MaterialRepresentation.js';
import Link from '../../../../components/Link/Link';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import { STATUS_TYPE } from '../../../../globals/appConstants';
import { isHubMode } from '../../../../utils/platform';
import showIncludeLicencesForTeachers from './utils/showIncludeLicencesForTeachers';

const filterIds = (ids = [], filterId = []) => ids.filter(userId => filterId.includes(userId));

function AssignmentSummaryMultiple({
  shouldAssignLicences,
  hasLicencesAvailable = false,
  setAssignLicencesAction,
  setTeacherAssignLicencesAction,
  context,
  productId,
  toggleView,
  productSelectionAction,
  licencesAssignDetails,
  teacherAssignLicencesDetails,
  isVisibleWhoIsGettingLicence,
  productTargetUsertype,
  userIdsWithExistingLicence,
  teacherIds,
  productPlatform
}) {
  const teacherUserIdsWithExistingLicence = filterIds(userIdsWithExistingLicence, teacherIds);
  const CMS = cmsContent.assignLearningMaterialSummary || {};
  const classAssignmentContext = [
    assignmentContexts.CLASS,
    assignmentContexts.CLASS_CREATION,
    assignmentContexts.CLASS_RENEW,
    assignmentContexts.CLASS_USERS,
    assignmentContexts.CLASS_ONBOARDING_WIZARD
  ].includes(context);
  const isByPassProductSelectionContext = [assignmentContexts.USER_PROFILE, assignmentContexts.CLASS_RENEW].includes(
    context
  );
  let showIncludeLicencesTeachers = classAssignmentContext && shouldAssignLicences && !productTargetUsertype;

  if (showIncludeLicencesTeachers && isHubMode()) {
    showIncludeLicencesTeachers = showIncludeLicencesForTeachers(
      productTargetUsertype,
      productPlatform,
      shouldAssignLicences,
      teacherUserIdsWithExistingLicence,
      teacherIds
    );
  }

  const { teacherAssignLicences, notEnoughLicenceForTeacher } = teacherAssignLicencesDetails;
  const { totalUserCount, licencesAvailableCount, licencesAmountAssigning } = licencesAssignDetails;
  let userWillHaveLicence = false;
  let organizationLicenceAvailableText = '';
  let statusColor = '';
  let statusText = '';

  switch (true) {
    // No user will have licence
    case !shouldAssignLicences && totalUserCount <= licencesAvailableCount: {
      statusColor = STATUS_TYPE.SUCCESS;
      statusText = CMS.licence_info_text
        .replace(`{assignCount}`, 0)
        .replace(`{availableCount}`, licencesAvailableCount);
      break;
    }
    // Not enough licence available
    case licencesAvailableCount > 0 && totalUserCount > licencesAvailableCount: {
      statusColor = STATUS_TYPE.WARNING;
      statusText = CMS.licence_info_text
        .replace(`{assignCount}`, 0)
        .replace(`{availableCount}`, licencesAvailableCount);
      organizationLicenceAvailableText = CMS.no_enough_licences;
      break;
    }
    // No licences available
    case licencesAvailableCount <= 0: {
      statusColor = STATUS_TYPE.ERROR;
      statusText = CMS.licence_info_text.replace(`{assignCount}`, 0).replace(`{availableCount}`, 0);
      organizationLicenceAvailableText = CMS.no_org_licences;
      break;
    }
    // User will have licence
    default: {
      userWillHaveLicence = true;
      statusColor = STATUS_TYPE.SUCCESS;
      statusText = CMS.licence_info_text
        .replace(`{assignCount}`, licencesAmountAssigning)
        .replace(`{availableCount}`, licencesAvailableCount);
    }
  }

  return (
    <div
      className={`${styles.assignmentSummary} ${featureIsEnabled('add-multiple-learning-material') &&
        styles.assignmentSummaryMargin}`}
    >
      <div className={styles.displayFlexBoxMulti}>
        {organizationLicenceAvailableText ? (
          <h4 className={styles.orgLmAvailableStyle}> {organizationLicenceAvailableText} </h4>
        ) : (
          <ToggleSwitch
            id={`useOrganisationLicencesToggle_${productId}`}
            value={shouldAssignLicences}
            onChange={
              hasLicencesAvailable
                ? value => {
                    setAssignLicencesAction(value, productId);
                  }
                : () => {}
            }
            label={CMS.assign_licence_header}
            disabled={!hasLicencesAvailable || totalUserCount > licencesAvailableCount}
          />
        )}

        {showIncludeLicencesTeachers && (
          <Checkbox
            id="include_licences_teachers"
            label={CMS.include_licences_teachers}
            value={teacherAssignLicences}
            onChange={() => setTeacherAssignLicencesAction(!teacherAssignLicences, false, productId)}
            disabled={notEnoughLicenceForTeacher}
          />
        )}
      </div>
      <div id="licenseTypeView" className={styles.reviewSummary}>
        <h4 className="EntityRepresentation__line2Color__gVSA">{CMS.summary}</h4>
        <MaterialRepresentation
          statusText={statusText}
          statusColor={statusColor}
          statusIndication
          headerSizeName
          ellipsisHeading
        />

        {productTargetUsertype && (
          <div>
            <p className={styles.infoAvailable}>
              <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoAvailableIcon} />
              <strong>{`${CMS.ICON_INFORMATION_CIRCLE}: `}</strong>
              {`${
                productTargetUsertype === APP_CONSTANTS.PRODUCT_TARGET_USERTYPE.STUDENT
                  ? CMS.assignment_not_available_teachers
                  : CMS.assignment_not_available_students
              }.`}
            </p>
          </div>
        )}
        {!userWillHaveLicence ? (
          <div>
            <p className={styles.infoDisplay}>
              <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoIcon} />
              <span>{CMS.no_licences_info}</span>
            </p>
            <Link
              openInNewTab
              className={styles.learMoreLink}
              to={
                isHubMode()
                  ? APP_CONSTANTS.HUB_IMPORT_ACCESS_CODE_HELP_LINK
                  : APP_CONSTANTS.IMPORT_ACCESS_CODE_HELP_LINK
              }
            >
              {CMS.learn_more}
            </Link>
          </div>
        ) : null}
      </div>
      <div
        className={classnames(styles.licenceDetails, {
          [styles.spaceBetween]: isVisibleWhoIsGettingLicence
        })}
      >
        {isVisibleWhoIsGettingLicence && (
          <Button
            customClassName={styles.linkButtonStyle}
            text={CMS.who_is_getting_licence}
            onClick={() => toggleView({ productId, statusText, statusColor })}
            type={buttonTypes.NO_BORDER}
          />
        )}

        {!isByPassProductSelectionContext && (
          <Button
            customClassName={styles.removeIcon}
            glyph="REMOVE"
            type={buttonTypes.NO_BORDER}
            glyphFill={colors.WEAK}
            text="Remove"
            onClick={() => {
              productSelectionAction(productId, false);
            }}
          />
        )}
      </div>
    </div>
  );
}

AssignmentSummaryMultiple.propTypes = {
  shouldAssignLicences: PropTypes.bool.isRequired,
  hasLicencesAvailable: PropTypes.bool,
  setAssignLicencesAction: PropTypes.func.isRequired,
  setTeacherAssignLicencesAction: PropTypes.func.isRequired,
  licencesAvailableCount: PropTypes.number,
  totalUserCount: PropTypes.number,
  context: PropTypes.string.isRequired,
  productSelectionAction: PropTypes.bool,
  productId: PropTypes.string,
  licencesAssignDetails: PropTypes.object.isRequired,
  teacherAssignLicencesDetails: PropTypes.object.isRequired,
  toggleView: PropTypes.func.isRequired,
  isVisibleWhoIsGettingLicence: PropTypes.bool.isRequired,
  productTargetUsertype: PropTypes.string.isRequired,
  userIdsWithExistingLicence: PropTypes.array,
  teacherIds: PropTypes.array,
  productPlatform: PropTypes.string
};

export default AssignmentSummaryMultiple;
