import * as t from '../actionTypes';

export const initialState = {
  pendingItems: [
    'orgs',
    'orgStudents',
    'orgStaff',
    'orgClasses',
    'orgLicences',
    'orgSubscriptions',
    'classStudents',
    'classTeachers',
    'classAssignments',
    'oupStaff',
    'userAssignments',
    'others'
  ].reduce(
    (carry, target) => ({
      ...carry,
      [target]: {
        ids: [],
        synced: false,
        syncing: false,
        timeout: false,
        attempt: 0
      }
    }),
    {}
  )
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.ENTITIES_SYNCED_REQUEST:
      return {
        ...state,
        pendingItems: {
          ...state.pendingItems,
          [payload.collection]: {
            ...state.pendingItems[payload.collection],
            ids: [
              ...state.pendingItems[payload.collection].ids,
              ...payload[payload.target].map(item => item[payload.identifier])
            ],
            synced: false,
            syncing: true,
            timeout: false,
            attempt: state.pendingItems[payload.collection].attempt + 1
          }
        }
      };
    case t.ENTITIES_SYNCED_SUCCESS: {
      const ids = state.pendingItems[payload.collection].ids.filter(id => !payload.ids.includes(id));
      return {
        ...state,
        pendingItems: {
          ...state.pendingItems,
          [payload.collection]: {
            ...state.pendingItems[payload.collection],
            ids,
            synced: !ids.length,
            syncing: false,
            timeout: false,
            attempt: 0
          }
        }
      };
    }
    case t.ENTITIES_SYNCED_TIMEOUT:
      return {
        ...state,
        pendingItems: {
          ...state.pendingItems,
          [payload]: {
            ...state.pendingItems[payload],
            syncing: false,
            timeout: true
          }
        }
      };
    case t.ENTITIES_SYNCED_RETRY:
      return {
        ...state,
        pendingItems: {
          ...state.pendingItems,
          [payload]: {
            ...state.pendingItems[payload],
            syncing: true,
            timeout: false,
            attempt: state.pendingItems[payload].attempt + 1
          }
        }
      };
    case t.ENTITIES_SYNCED_CANCEL:
      return {
        ...state,
        pendingItems: {
          ...state.pendingItems,
          [payload]: {
            ...state.pendingItems[payload],
            ids: [],
            timeout: false,
            attempt: 0
          }
        }
      };
    case t.ENTITIES_SYNCED_FLUSH:
      return { ...initialState };
    case t.ENTITIES_SYNCED_FAILURE:
      return {
        ...state,
        pendingItems: {
          ...state.pendingItems,
          [payload]: {
            ...state.pendingItems[payload],
            ids: [],
            syncing: false,
            attempt: 0
          }
        }
      };
    default:
      return state;
  }
};
