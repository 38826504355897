/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import AssessmentTest from '../AssessmentTest/AssessmentTest';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import { HubLayoutConstants } from '../../globals/hubConstants';
import getAssessmentCourseTitle from '../../structure/HubCourseAssessments/Utils/getAssessmentCourseTitle';
import styles from './TestList.scss';
import { hasLockableKeywords } from '../../structure/HubCourseAssessments/Utils/isLockedNode';

function TestList({ assessmentData, course, hubContent, downloadAssessment, selectTest }) {
  const renderTest = resource => {
    if (!resource?.id || resource?.type !== HubLayoutConstants.ASSESSMENT_TYPES.INTERACTIVE_FOLDER) return <></>;

    const resourceId = resource.id;
    const test = course?.interactiveFolders[resourceId];
    if (!test?.title) return <></>;

    const { adaptedTests } = test;
    const isTestLocked = test.keywords && hasLockableKeywords(test.keywords);
    const assessmentCourseTitle = getAssessmentCourseTitle(course);

    return (
      <div key={resourceId} className={styles.testItemContainer}>
        <AssessmentTest
          assessmentId={resourceId}
          assessmentTest={test}
          locked={isTestLocked}
          title={test.title}
          content={hubContent}
          downloadAssessment={downloadAssessment}
          isAdapted={false}
          displayDropdown={false}
          hideDownloadButtonText
          selectOnlineTest={() => selectTest(resourceId, test, assessmentCourseTitle)}
        />
        {!isTestLocked &&
          adaptedTests &&
          adaptedTests.map(item => (
            <div className={styles.adaptedtest} key={item.id}>
              <SVGIcon className={styles.adaptedTestArrow} glyph={GLYPHS.ICON_CHILD} />
              <AssessmentTest
                assessmentId={item.id}
                assessmentTest={item}
                title={item.title}
                locked={item.keywords && hasLockableKeywords(item.keywords)}
                content={hubContent}
                downloadAssessment={downloadAssessment}
                isAdapted
                displayDropdown={false}
                hideDownloadButtonText
                selectOnlineTest={() => selectTest(item.associated_resource_id, item, assessmentCourseTitle)}
              />
            </div>
          ))}
      </div>
    );
  };

  return (
    <>{assessmentData?.resources ? <ul>{assessmentData.resources.map(resource => renderTest(resource))}</ul> : null}</>
  );
}

TestList.propTypes = {
  assessmentData: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  hubContent: PropTypes.object.isRequired,
  downloadAssessment: PropTypes.func,
  selectTest: PropTypes.func
};

export default TestList;
