/* eslint-disable import/prefer-default-export */

import { GLYPHS } from '../SVGIcon/SVGIcon';

export const validationStatusCodes = {
  ERROR_STATUS: 'error',
  WARNING_STATUS: 'warning',
  NOTICE_STATUS: 'notice',
  WAITING_STATUS: 'waiting',
  VALID_STATUS: 'valid'
};

export const glyphFor = {
  [validationStatusCodes.ERROR_STATUS]: GLYPHS.ICON_ERROR_CIRCLE,
  [validationStatusCodes.WARNING_STATUS]: GLYPHS.ICON_WARNING_CIRCLE,
  [validationStatusCodes.NOTICE_STATUS]: GLYPHS.ICON_INFORMATION_CIRCLE,
  [validationStatusCodes.WAITING_STATUS]: GLYPHS.ICON_LOADING,
  [validationStatusCodes.VALID_STATUS]: GLYPHS.ICON_CHECK_CIRCLE
};
