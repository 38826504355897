import React from 'react';
import PropTypes from 'prop-types';

// Component imports
import { GLYPHS } from '../SVGIcon/SVGIcon.js';
import { buttonTypes } from '../Button/Button.js';
import { sanitizeUrl } from '../../utils/url.js';

import styles from './ProductFinder.scss';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail.js';
import ContentWithButton from '../ContentWithButton/ContentWithButton.js';
import MaterialRepresentation from '../MaterialRepresentation/MaterialRepresentation.js';

function ProductFinderSeries({ series, onClick }) {
  return (
    <li className={styles.series}>
      <ContentWithButton
        buttonText={series.name}
        buttonGlyph={GLYPHS.ICON_RIGHT_THICK}
        buttonOnClick={() => onClick(series)}
        buttonType={buttonTypes.PRIMARY}
        buttonTestHook="PRODUCT_FINDER_SELECT_SERIES_BUTTON"
        buttonIconOnly
      >
        <MaterialRepresentation
          thumbnailSize={thumbnailSizes.TABLE}
          imageSrc={sanitizeUrl(series.cover)}
          name={series.name}
        />
      </ContentWithButton>
    </li>
  );
}

ProductFinderSeries.propTypes = {
  series: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ProductFinderSeries;
