import jsonFetch from '../util/jsonFetch.js';

// Regex to pull group Ids out of the SAML group mapping
const groupIdRegex = /"([^"]*)":/g;

export default (authorization, cognitoId) =>
  jsonFetch('getSAML', [
    `${__API_BASE__}/open/user/credentials`,
    {
      headers: {
        authorization,
        'cognito-id': cognitoId
      }
    }
  ]).then(json => {
    if (!json || json.status !== 'success' || !json.data) {
      return {
        error: (json && json.message) || 'No error given'
      };
    }

    try {
      const result = json.data;
      console.log('[getSAML] AWS Credentials retrieved:', result);

      // User Details
      const email = result.Attributes.username.S;
      const userId = result.Attributes.userId.S;
      const currentRole = result.Attributes.currentRole.S;

      const userGroupWithRolesString = result.Attributes.userGroupWithRoles.S || '{}';
      const userGroupWithRoles = JSON.parse(userGroupWithRolesString);
      const orgRoleMap = Object.keys(userGroupWithRoles).reduce((roleMap, orgId) => {
        // eslint-disable-next-line no-param-reassign
        roleMap[orgId] = userGroupWithRoles[orgId][0];
        return roleMap;
      }, {});

      // Match the ids from the role maping and trim the surrounding "":
      // This will extract them in the order of oldest to newest membership
      // Default to blank array becuase if match returns no results it returns null instead of a blank list of results
      const groupIdsInOrder = (userGroupWithRolesString.match(groupIdRegex) || [])
        .map(id => id.slice(1).slice(0, -2))
        .reverse();

      // Extract the most recently joined organisation for the given role
      const currentOrganisationId = groupIdsInOrder.reduce(
        // Return the first ID that your current role matches
        (foundId, orgId) => (!foundId && orgRoleMap[orgId] === currentRole ? orgId : foundId),
        null
      );

      return {
        // Credentials
        accessKeyId: result.Attributes.credentials.M.Credentials.M.AccessKeyId.S,
        secretAccessKey: result.Attributes.credentials.M.Credentials.M.SecretAccessKey.S,
        sessionToken: result.Attributes.credentials.M.Credentials.M.SessionToken.S,
        expiryTime: result.Attributes.credentials.M.Credentials.M.Expiration.S,
        rightSuiteToken: result.Attributes.RightSuitSession.S,

        // User Details
        email,
        userId,
        currentRole,
        orgRoleMap,
        currentOrganisationId
      };
    } catch (error) {
      return {
        error: `Error parsing response: ${error}`
      };
    }
  });
