// Rag status definitions

const ragStatuses = {
  SKIPPED: 'skipped',
  AHEAD: 'ahead',
  EXPECTED: 'expected',
  BEHIND: 'behind',
  VERY_BEHIND: 'very-behind'
};

export default ragStatuses;
