const getUsersGroupedByRole = (state, targetIds = [], targetRoles = []) => {
  const result = {};
  targetRoles.forEach(role => {
    result[role] = {};
  });
  if (targetIds && targetIds.length) {
    targetIds.forEach(userId => {
      const user = state.people.data[userId];
      if (user && result[user.roleName]) {
        result[user.roleName][userId] = user;
      }
    });
  }
  return result;
};

export default getUsersGroupedByRole;
