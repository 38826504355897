import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import ActionList, { Action } from '../ActionList/ActionList';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { isHubMode } from '../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';

function SendInvitationReminderSuccess({
  localizedContent: { sendInvitationReminderSuccessComponent: content },
  firstName,
  lastName,
  onDone
}) {
  return (
    <ScrollContainer
      footerContent={
        <ActionList title={content.actions_title}>
          <Action label={content.done_button} primary onClick={onDone} />
        </ActionList>
      }
    >
      {isHubMode() ? (
        <PopoutPanelIllustrationHeading
          title={[content.title, firstName, lastName].join(' ')}
          illustrationSrc={HubIllustrationConstants.SUCCESS}
          illustrationAltText={HubIllustrationAltText.SUCCESS}
        />
      ) : (
        <PopoutPanelIconHeading
          type={popoutPanelIconHeadingTypes.VALID}
          title={[content.title, firstName, lastName].join(' ')}
        />
      )}
    </ScrollContainer>
  );
}

SendInvitationReminderSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired
};

export default withLocalizedContent('sendInvitationReminderSuccessComponent')(SendInvitationReminderSuccess);
