import USER_ROLES from '../../../../../../../globals/userRoles';
import { isHubMode } from '../../../../../../../utils/platform';
import { assignmentContexts } from '../../../../../../../globals/assignmentConstants';
/**
 *  Functions must consider all possible 11 contexts:
 *
 *  Groups:
 *   CLASS - Assigment for whole class from Assignments Tab
 *
 *  Panels:
 *   CLASS_CREATION - Assignment for GROUP from end of class creation panel
 *   CLASS_ADD_STUDENTS - Assignment for USERS from end of add students panel
 *
 *  Selected Users:
 *   CLASS_TEACHERS - Assignment for USERS selected on class teachers tab
 *   CLASS_STUDENTS - Assignment for USERS selected on class students tab
 *   CLASS_USERS - Assignment for USERS selected on class people tab (hub)
 *   ORG_STAFF - Assignment for USERS selected on org staff tab
 *   ORG_STUDENTS - Assignment for USERS selected on org learners tab
 *
 *  Licence Renews:
 *   CLASS_RENEW - Renewed assignment for users missing licence from class assignments tab
 *   USER - Renewed assignment for user from profile page
 *   USER_PROFILE - Renewed assignment for user from profile page
 *
 */

const getOrgIdForContext = (context, state) => {
  let id;
  if (
    context === assignmentContexts.CLASS ||
    context === assignmentContexts.CLASS_STUDENTS ||
    context === assignmentContexts.CLASS_TEACHERS ||
    context === assignmentContexts.CLASS_USERS ||
    context === assignmentContexts.CLASS_ADD_STUDENTS ||
    context === assignmentContexts.CLASS_RENEW
  ) {
    id = state.classroomPage.orgId;
  } else if (
    context === assignmentContexts.CLASS_CREATION ||
    context === assignmentContexts.ORG_STUDENTS ||
    context === assignmentContexts.ORG_STAFF
  ) {
    id = state.organisationPage.orgId;
  } else if (context === assignmentContexts.USER_PROFILE) {
    id = state.userProfile.orgId;
  } else if (context === assignmentContexts.CLASS_ONBOARDING_WIZARD) {
    id = state.identity.currentOrganisationId;
  }
  return id;
};

const getUserIdsForContext = (context, orgId, state) => {
  let userIds = [];

  if (context === assignmentContexts.CLASS || (context === assignmentContexts.CLASS_RENEW && isHubMode())) {
    // CLASS - All class members
    userIds = [
      ...state.classrooms.data[state.classroomPage.classroomId].studentIdList,
      ...state.classrooms.data[state.classroomPage.classroomId].teacherIdList
    ];
    //
  } else if (context === assignmentContexts.CLASS_CREATION) {
    // CLASS_CREATION - All new class members
    userIds = [...state.classroomCreate.selectedStudentIds, ...state.classroomCreate.selectedTeacherIds];
    //
  } else if (context === assignmentContexts.CLASS_ADD_STUDENTS) {
    // CLASS_ADD_STUDENTS - All new class students
    userIds = state.addStudentsToClassroom.selectedStudentIds;
    //
  } else if (context === assignmentContexts.CLASS_STUDENTS) {
    // CLASS_STUDENTS - Selected Students
    userIds = state.classroomPage.selectedStudentIds;
    //
  } else if (context === assignmentContexts.CLASS_TEACHERS) {
    // CLASS_TEACHERS - Selected Teachers
    userIds = state.classroomPage.selectedTeacherIds;
    //
  } else if (context === assignmentContexts.CLASS_USERS) {
    // CLASS_USERS - Selected Students and Teachers
    userIds = [...state.classroomPage.selectedStudentIds, ...state.classroomPage.selectedTeacherIds];
    //
  } else if (context === assignmentContexts.ORG_STUDENTS) {
    // ORG_STUDENTS - Selected Students
    userIds = state.organisationPage.selectedStudentIds;
    //
  } else if (context === assignmentContexts.ORG_STAFF) {
    // ORG_STAFF - Selected staff
    userIds = state.organisationPage.selectedTeacherIds;
    //
  } else if (
    context === assignmentContexts.USER_PROFILE ||
    (context === assignmentContexts.CLASS_RENEW && !isHubMode())
  ) {
    // In these cases the userIds are passed to the state in initialisation
    userIds = state.assignLearningMaterial.userIds || [];
  } else if (context === assignmentContexts.CLASS_ONBOARDING_WIZARD) {
    const teacherIdList = [state.identity.userId];
    const studentIdList = Object.keys(state?.classOnboardingWizard?.userDetails?.students || {});
    userIds = [...teacherIdList, ...studentIdList];
  }

  return userIds;
};

const getLearnerIds = (context, orgId, state) => {
  let userIds = [];

  if (context === assignmentContexts.CLASS || (context === assignmentContexts.CLASS_RENEW && isHubMode())) {
    // CLASS - All student members
    userIds = state.classrooms.data[state.classroomPage.classroomId].studentIdList;
    //
  } else if (context === assignmentContexts.CLASS_CREATION) {
    // CLASS_CREATION - All new class students
    userIds = state.classroomCreate.selectedStudentIds;
    //
  } else if (context === assignmentContexts.CLASS_ADD_STUDENTS) {
    // CLASS_ADD_STUDENTS - All new class students
    userIds = state.addStudentsToClassroom.selectedStudentIds;
    //
  } else if (context === assignmentContexts.CLASS_STUDENTS) {
    // CLASS_STUDENTS - Selected Students
    userIds = state.classroomPage.selectedStudentIds;
    //
  } else if (context === assignmentContexts.CLASS_USERS) {
    // CLASS_USERS - Selected Students and Teachers
    // userIds = [...state.classroomPage.selectedStudentIds, ...state.classroomPage.selectedTeacherIds];
    userIds = state.classroomPage.selectedStudentIds;
    //
  } else if (context === assignmentContexts.ORG_STUDENTS) {
    // ORG_STUDENTS - Selected Students
    userIds = state.organisationPage.selectedStudentIds;
    //
  } else if (context === assignmentContexts.CLASS_RENEW && !isHubMode()) {
    // CLASS_RENEW - In these cases the userIds filter from class student list are passed to the state in initialisation
    const studentIdList = state.classrooms.data[state.classroomPage.classroomId].studentIdList;
    userIds = (state.assignLearningMaterial.userIds || []).filter(user => studentIdList.includes(user));
    //
  } else if (context === assignmentContexts.USER_PROFILE) {
    // In these cases the userIds are passed to the state in initialisation
    // Filter by user role
    userIds = state.assignLearningMaterial.userIds.filter(
      userId => state.people.data[userId].roleName === USER_ROLES.LEARNER
    );
  } else if (context === assignmentContexts.CLASS_TEACHERS || context === assignmentContexts.ORG_STAFF) {
    // Teacher only lists - none to assign to
    userIds = [];
  } else if (context === assignmentContexts.CLASS_ONBOARDING_WIZARD) {
    userIds = Object.keys(state?.classOnboardingWizard?.userDetails?.students || {});
  }

  return userIds;
};

export default function getDetails(state) {
  const context = state.assignLearningMaterial.context;
  const productId = state.assignLearningMaterial.productId;
  const product = state.products.data[productId] || {};
  const productTargetType = product ? product.target_usertype : null;

  const orgId = getOrgIdForContext(context, state);
  const userIds = getUserIdsForContext(context, orgId, state);
  const learnerUserIds = getLearnerIds(context, orgId, state);
  const productAvailableCount = state.products.data[productId] ? state.products.data[productId].availableCount : 0;

  return {
    context,
    orgId,
    productId,
    userIds,
    learnerUserIds,
    productAvailableCount,
    productTargetType
  };
}
