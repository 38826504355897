import { put } from 'redux-saga/effects';
import { OPT_PRODUCTS_PLATFORM_CODE } from '@oup/shared-node-browser/constants.js';
import { orgSubscriptionType, subIsCurriculumType, subIsPlacementType } from '@oup/shared-node-browser/subscriptions';
import createOicTestCreditsOrgRequestBodySchema from '../../../../../../static/schema/draft-4/add-oic-test-credits-org-body.json';
import actions from '../../../../actions';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import getOrgDetailsApi from '../../../apiCalls/organisations/getOrgDetails.api.js';
import searchSubscriptionsApi from '../../../apiCalls/search/searchSubscriptions.api';
import { mode } from '../../../../reducers/oicTestCredits.reducer';
import getProducts from '../../../api/getProducts.js';
import { featureIsEnabled } from '../../../../../globals/envSettings.js';

function generateProductsFromIds(ids) {
  return ids.reduce((acc, id) => {
    acc[id] = { ...ids[id], mode: mode.EMPTY, formData: {} };
    return acc;
  }, {});
}

/**
 * The OIC credits management page uses subscriptions to fill the product components
 * so this mapper maps OPT products to the CES subscription model
 * @param {Array} products
 * @returns Object to fit the CES subscription model
 */
function createSubscriptionFromProduct(products) {
  return products.reduce((acc, product) => {
    acc[product.productName] = {
      productId: product.productId,
      productDetails: {
        isbn: product.isbn,
        platform: product.platform,
        title: product.productName
      }
    };
    return acc;
  }, {});
}

function* oicValidateTestCreditsOrg(input) {
  const { orgId } = input;

  if (orgId !== undefined) {
    const errors = yield validateInputUsingSchema(createOicTestCreditsOrgRequestBodySchema, null, { orgId });

    yield put(actions.oicTestCreditsOrgState(mode.LOADING));

    let orgName = null;
    let subscriptions = null;
    let generatedProducts = {};

    if (orgId) {
      const orgResponse = yield getOrgDetailsApi(orgId);
      errors.orgNotFound = orgResponse.status === 'error';

      if (orgResponse.data) {
        orgName = orgResponse.data.name;
        const subTypeByRole = orgSubscriptionType(orgResponse.data);

        // -- get OIC subscriptions
        if (subIsCurriculumType(subTypeByRole)) {
          const subscriptionsResult = yield searchSubscriptionsApi({
            filters: {
              orgId,
              active: true,
              notStarted: true,
              expired: true,
              expiring: true
            },
            term: '',
            size: 10000, // max AWS cloud search size limit
            start: 0
          });

          if (subscriptionsResult.data.subscriptions) {
            subscriptions = subscriptionsResult.data.subscriptions;
            // -- map ids to UI form products
            generatedProducts = generateProductsFromIds(subscriptionsResult.data.ids);
          }
        } else if (featureIsEnabled('opt-main-features') && subIsPlacementType(subTypeByRole)) {
          const productResult = yield getProducts(OPT_PRODUCTS_PLATFORM_CODE); // it is AM at the moment, will be changed to OPT once products are migrated
          if (productResult.data.products.length) {
            // -- this func does nothing but anyway
            generatedProducts = generateProductsFromIds(productResult.data.products);
            // --
            subscriptions = createSubscriptionFromProduct(productResult.data.products);
          }
        }
      }
    }
    yield put(actions.storeSubscriptions(subscriptions));
    yield put(actions.getOicTestCreditsProducts(generatedProducts));
    yield put(actions.oicTestCreditsOrgInput({ field: 'orgName', value: orgName }));
    yield put(actions.oicTestCreditsOrgState(errors.orgId || errors.orgNotFound ? mode.ERROR : mode.SUCCESS));
    yield put(actions.oicTestCreditsOrgValidated(errors));
  }
}

export default oicValidateTestCreditsOrg;
