import { put } from 'redux-saga/effects';
import { appDownloadSuccess, appDownloadFailure } from '../../../../actions/orbAppDownloadUrl';
import getAppDownloadUrlApiCall from '../../../apiCalls/orb/getAppDownloadUrl';

function* getDownloadUrl(userId, os, arch) {
  const response = yield getAppDownloadUrlApiCall(userId, os, arch);

  if (response.status === 'success') {
    yield put(appDownloadSuccess(response.data));
    return;
  }

  yield put(appDownloadFailure(response.message));
}

export default getDownloadUrl;
