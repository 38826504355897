import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { isLicenceExpiresEarly } from '@oup/shared-node-browser/licenceHelper.js';
import { ASSIGN_LICENCES } from '@oup/shared-node-browser/constants';
import withLocalizedContent from '../../language/withLocalizedContent';
import { StatusType } from '../StatusIcon/StatusIcon';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import UserRepresentation from '../UserRepresentation/UserRepresentation';
import PopoutPanelListHeading from '../PopoutPanelListHeading/PopoutPanelListHeading';
import styles from './UserLicenceListing.scss';
import LicenceStatusIcon, { getStatusType } from '../LicenceStatusIcon/LicenceStatusIcon';
import cmsContent from '../../utils/cmsContent.js';
import { getExpiresOnText, getLicencePeriodDetails } from '../../utils/licences.js';
import Link from '../Link/Link';
import { featureIsEnabled } from '../../globals/envSettings';

function UserLicenceListing({
  heading,
  users,
  userLicences = {},
  isNotAssignable = false,
  userIdsWithoutPermission = [],
  localizedContent: { userLicenceListingComponent: content }
}) {
  const isFeatureEnable = featureIsEnabled('add-multiple-learning-material');
  return (
    <div>
      <div className="flex-row">
        <div className={`flex-column ${isFeatureEnable ? 'flex' : 'flex-3/4'}`}>
          <PopoutPanelListHeading text={heading} />
        </div>
        {!isFeatureEnable && (
          <div className={`flex-column flex-1/4 align-items-center ${styles.borderBottom}`}>
            <h3>{content.licences_header_text} </h3>
          </div>
        )}
      </div>
      {Object.entries(users).map(([userId, user]) => {
        let userLicence = null;
        const prohibitedUsers = userIdsWithoutPermission.includes(userId);
        if (userLicences[userId]) {
          userLicence = userLicences[userId].status === 'ARCHIVED' ? null : userLicences[userId];
        }
        const isExistingLicence = userLicence ? userLicence.isAssigned && userLicence.status !== 'ARCHIVED' : false;
        let isExistingLicenceActive = false;
        if (isExistingLicence) {
          const licenceType = getStatusType(userLicence);
          isExistingLicenceActive = ![StatusType.PRODUCT_EXPIRED, StatusType.PRODUCT_NO_LICENCE].includes(licenceType);
        }
        const CMS = cmsContent.assignLearningMaterialSummary || {};
        let statusMessage = content.no_licence.replace('{username}', user.firstname);
        let statusType = 'ERROR';
        if (userLicence) {
          userLicence.licenceStartDate = userLicence.startDate;
          userLicence.licenceEndDate = userLicence.endDate;
          statusMessage = getLicencePeriodDetails(CMS, userLicence);

          statusMessage = isLicenceExpiresEarly(userLicence, true)
            ? `${statusMessage} (${getExpiresOnText(CMS, userLicence.licenceEndDate || '')})`
            : statusMessage;
          statusType = 'SUCCESS';
        }
        if (prohibitedUsers || isExistingLicenceActive) {
          if (prohibitedUsers && !isExistingLicenceActive) {
            statusMessage = content.not_valid_permission_assigned_licence;
            statusType = 'INFO';
          } else {
            statusMessage = content.user_name_already_using_licence.replace('{username}', user.firstname);
            statusType = 'INFO';
          }
        }

        return (
          <div key={userId} className={styles.row}>
            <div className="gin2 flex-row align-items-center">
              <div
                className={classNames('flex-row', {
                  [styles.inactive]: !isFeatureEnable && (isNotAssignable || isExistingLicenceActive),
                  'flex-3/4': !isFeatureEnable
                })}
              >
                <UserRepresentation
                  firstName={user.firstname}
                  lastName={user.lastname}
                  identifier={user.email}
                  role={user.roleName}
                  archived={!isFeatureEnable && (isNotAssignable || isExistingLicenceActive)}
                  statusText={isFeatureEnable && statusMessage}
                  statusType={isFeatureEnable && statusType}
                />
              </div>
              {!isFeatureEnable && (
                <div id={`licenceStatus-${userId}`} className="flex-column flex-1/4 align-items-center">
                  <div className={styles.licenceIconWrapper}>
                    <LicenceStatusIcon statusMessage={statusMessage} licenceDetails={userLicence} toBeAssigned />
                  </div>
                </div>
              )}
            </div>
            {!isFeatureEnable && (prohibitedUsers || isExistingLicenceActive) ? (
              <div className={`gin2 ${styles.rowInfo}`}>
                <SVGIcon className={styles.rowInfoIcon} glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
                <span>
                  {prohibitedUsers && !isExistingLicenceActive ? (
                    <div>
                      {content.not_valid_permission_assigned_licence}
                      <Link className={styles.assignLicenseLable} openInNewTab to={ASSIGN_LICENCES}>
                        {content.not_valid_permission_assigned_licence_link}
                      </Link>
                    </div>
                  ) : (
                    content.user_name_already_using_licence_single_lm.replace('{username}', user.firstname)
                  )}
                </span>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

UserLicenceListing.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired,
  userLicences: PropTypes.object,
  isNotAssignable: PropTypes.bool,
  userIdsWithoutPermission: PropTypes.array
};

export default withLocalizedContent('userLicenceListingComponent')(UserLicenceListing);
