import * as t from '../../actionTypes';

const initialState = {
  data: [],
  success: false,
  error: false,
  loading: false,
  openedContent: null
};

function orbTeacherResources(state = initialState, { type, payload }) {
  switch (type) {
    case t.ORB_TEACHER_RESOURCES_REQUEST:
      return {
        ...state,
        success: false,
        error: false,
        loading: true
      };
    case t.ORB_TEACHER_RESOURCES_SUCCESS:
      return {
        ...state,
        data: payload,
        success: true,
        error: false,
        loading: false
      };
    case t.ORB_TEACHER_RESOURCES_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      };
    case t.TEACHER_OPEN_CONTENT:
      return {
        ...state,
        openedContent: { ...payload }
      };
    case t.TEACHER_CLOSE_CONTENT:
      return {
        ...state,
        openedContent: null
      };
    default:
      return state;
  }
}

export default orbTeacherResources;
