import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const sendRemoveUserLicenceUrl = licenceId => `${__API_BASE__}/user-licence/${licenceId}`;

function sendRemoveUserLicence(payload) {
  return signedFetch('sendRemoveUserLicence', sendRemoveUserLicenceUrl(payload), 'DELETE');
}

export default sendRemoveUserLicence;
