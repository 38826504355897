import PropTypes from 'prop-types';
import sumOf from '../../utils/array/sumOf';

export const getRecordWithTotals = (records, useCompletedOnly) =>
  records.map(({ levels = [], ...record }) => ({
    ...record,
    levels,
    totalLevelsCompleted: !record.isActivityLevel
      ? sumOf(levels, 'submittedNumberOfActivities')
      : sumOf(levels, level => (level.totalScoreAchieved > 0 ? 1 : 0)),
    totalLevelsScore: sumOf(levels, level => level.levelScoreAchieved || level.totalScoreAchieved),
    totalLevelsScoreAvailable: sumOf(
      levels,
      level =>
        (useCompletedOnly ? level.levelScoreAvailable : level.allActivityLevelScoreAvailable) ||
        (useCompletedOnly ? level.totalScoreAvailable : level.allActivityTotalScoreAvailable)
    ),
    totalNumberOfActivities: sumOf(levels, level => level.isActivity || level.totalNumberOfActivities),
    sumTotalNumberOfAttempts: sumOf(levels, 'totalNumberOfAttempts'),
    numberOfCompletedActivities: record.numberOfCompletedActivities
  }));

/**
 * Iterates over each record and builds up an aggregate total (score/completed) for each level.
 */
function RecordTotals({ records, useCompletedOnly, children }) {
  return children(getRecordWithTotals(records, useCompletedOnly));
}
RecordTotals.propTypes = {
  records: PropTypes.array.isRequired,
  useCompletedOnly: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired
};

export default RecordTotals;
