import { call, put, race, take } from 'redux-saga/effects';
import randomString from '../utils/randomString';
import { clearCredentialData } from '../utils/credentials';
import { appError, APP_ERROR } from './reducers/app.reducer';

// eslint-disable-next-line import/no-cycle
import appSaga from './sagas/appSaga';

export default function* root() {
  try {
    yield race({
      app: call(appSaga),
      error: take(APP_ERROR)
    });
  } catch (error) {
    console.info('[appSaga] Error', error);
    const appErrorId = `${randomString(8)}----${JSON.stringify(error)}`;
    yield put(appError(appErrorId));
    clearCredentialData(true);
  }
}
