import { select } from 'redux-saga/effects';
import productUploadApi from './productUploadApi.js';

export default function* productUploadLogsRequest() {
  console.log(`[productUploadLogsRequest Saga] Submitting request to get productUpload.`);

  const { formData, selectedPlatformCode } = yield select(state => ({
    formData: state.productUpload.formData,
    selectedPlatformCode: state.productUpload.selectedPlatformCode
  }));

  return yield productUploadApi(formData, selectedPlatformCode);
}
