import { put, select } from 'redux-saga/effects';
import { parse as parseQueryString } from 'query-string';

import { setFormResult } from '../../../../reducers/data/forms.reducer.js';
import passwordResetApi from '../../../apiCalls/users/passwordReset.api.js';
import { getCurrentPlatform } from '../../../../../utils/platform.js';
import { getFieldValue, requestFailed } from '../formUtil.js';

export default function* submitResetPassword() {
  const queryString = parseQueryString(window.location.search);
  const payload = yield select(state => ({
    userToken: getFieldValue(state, 'resetPassword', 'token'),
    password: getFieldValue(state, 'resetPassword', 'password'),
    platform: queryString.platform || getCurrentPlatform().toUpperCase()
  }));

  const apiResult = yield passwordResetApi(payload);

  const failed = requestFailed(apiResult);

  yield put(setFormResult('resetPassword', 'CONFIRMATION', failed));
}
