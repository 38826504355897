import React, { forwardRef } from 'react';

import styles from './CustomDateInput.scss';

import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';

const CustomDateInput = forwardRef((props, ref) => (
  <div className={styles.dateField}>
    <SVGIcon glyph={GLYPHS.ICON_CALENDAR} />
    <input {...props} ref={ref} />
    <SVGIcon glyph={GLYPHS.CHEVRON_DOWN_THICK} />
  </div>
));

export default CustomDateInput;
