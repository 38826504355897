import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import styles from './MultiSchoolReportDialsLoader.scss';

export default function MultiSchoolReportDialsLoader({ totalNumberOfSchools, numberOfLoadedSchools }) {
  const renderSteps = () => {
    const steps = [];

    for (let i = 1; i <= totalNumberOfSchools; i += 1) {
      const isCurrentStep = numberOfLoadedSchools === i;
      const step = (
        <div className={classnames(styles.step, { [styles.previousStep]: numberOfLoadedSchools > i })} key={i}>
          <div className={classnames(styles.animation, { [styles.grow]: isCurrentStep })} />
        </div>
      );
      steps.push(step);
    }
    return steps;
  };

  return (
    <div className={styles.loader}>
      <div className={styles.stepsContainer}>{renderSteps()}</div>
      <div>
        <div className={styles.outOfContainer}>
          <p>
            <span>{numberOfLoadedSchools} </span> {` of `} <span>{totalNumberOfSchools}</span>
          </p>
        </div>
        <div>schools loaded</div>
      </div>
    </div>
  );
}

MultiSchoolReportDialsLoader.propTypes = {
  totalNumberOfSchools: PropTypes.number,
  numberOfLoadedSchools: PropTypes.number
};
