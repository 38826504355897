import { mapValues, pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { PRODUCT_TARGET_USERTYPE } from '@oup/shared-node-browser/constants.js';
// Components
import ProductFinderUserLicenceListing from './ProductFinderUserLicenceListing.js';
import LinkWithIcon from '../../LinkWithIcon/LinkWithIcon.js';
import { GLYPHS } from '../../SVGIcon/SVGIcon.js';
// Styles
import styles from '../ProductFinder.scss';

const filterIds = (ids = [], filterId = []) => ids.filter(userId => filterId.includes(userId));

function ProductFinderLicenceAssignmentInfo({
  productFinderContent,
  users,
  userIdsToAssignLicencesTo = [],
  userIdsWithExistingLicence = [],
  userIdsToNotAssignLicencesTo = [],
  newLicenceAssignments = {},
  existingLicenceAssignments = {},
  teacherIdList = [],
  studentIdList = [],
  backAction,
  productTargetUsertype
}) {
  const existingLicences = mapValues(existingLicenceAssignments, licenceDetails => ({
    ...licenceDetails,
    isAssigned: true
  }));
  // Filter for userIds To Assign Licences
  const learnerUserIdsToAssignLicencesTo = filterIds(userIdsToAssignLicencesTo, studentIdList);
  const teacherUserIdsToAssignLicencesTo = filterIds(userIdsToAssignLicencesTo, teacherIdList);
  // Filter for userIds With Existing Licences
  const learnerUserIdsWithExistingLicence = filterIds(userIdsWithExistingLicence, studentIdList);
  const teacherUserIdsWithExistingLicence = filterIds(userIdsWithExistingLicence, teacherIdList);
  // Filter for userIds Not Assign Licences
  const learnerUserIdsToNotAssignLicencesTo = filterIds(userIdsToNotAssignLicencesTo, studentIdList);
  const teacherUserIdsToNotAssignLicencesTo = filterIds(userIdsToNotAssignLicencesTo, teacherIdList);

  const renderOrgLicencesHeader = () => {
    let userType;
    switch (true) {
      case !!(teacherUserIdsToAssignLicencesTo.length && learnerUserIdsToAssignLicencesTo.length):
        userType = productFinderContent.teachersAndStudents;
        break;
      case !!teacherUserIdsToAssignLicencesTo.length:
        userType = productFinderContent.teachers;
        break;
      case !!learnerUserIdsToAssignLicencesTo.length:
        userType = productFinderContent.students;
        break;
      default:
        break;
    }
    return userType ? (
      <div className={styles.licenceListHeader}>
        <h3>{productFinderContent.users_that_receive_a_licence_title.replace('{userType}', userType)}</h3>
        <span className={styles.licencesSubtitle}>{productFinderContent.users_that_receive_a_licence_subtitle}</span>
      </div>
    ) : null;
  };

  const renderUsersWithLicencesHeader = () => {
    let userType;
    switch (true) {
      case !!(teacherUserIdsWithExistingLicence.length && learnerUserIdsWithExistingLicence.length):
        userType = productFinderContent.teachersAndStudents;
        break;
      case !!teacherUserIdsWithExistingLicence.length:
        userType = productFinderContent.teachers;
        break;
      case !!learnerUserIdsWithExistingLicence.length:
        userType = productFinderContent.students;
        break;
      default:
        break;
    }
    return userType ? (
      <div className={styles.licenceListHeader}>
        <h3>{productFinderContent.users_already_have_a_licence_title.replace('{userType}', userType)}</h3>
        <span className={styles.licencesSubtitle}>{productFinderContent.users_already_have_a_licence_subtitle}</span>
      </div>
    ) : null;
  };

  const renderUsersNeedLicencesHeader = () => {
    let userType;
    switch (true) {
      case !!(teacherUserIdsToNotAssignLicencesTo.length && learnerUserIdsToNotAssignLicencesTo.length):
        userType = productFinderContent.teachersAndStudents;
        break;
      case !!teacherUserIdsToNotAssignLicencesTo.length:
        userType = productFinderContent.teachers;
        break;
      case !!learnerUserIdsToNotAssignLicencesTo.length:
        userType = productFinderContent.students;
        break;
      default:
        break;
    }
    return userType ? (
      <div className={styles.licenceListHeader}>
        <h3>{productFinderContent.users_that_need_a_licence_title.replace('{userType}', userType)}</h3>
        <span className={styles.licencesSubtitle}>{productFinderContent.users_that_need_a_licence_subtitle}</span>
      </div>
    ) : null;
  };

  return (
    <div className={styles.userAssignments}>
      <LinkWithIcon
        text={productFinderContent.back_to_licensing_text}
        isLhs
        glyph={GLYPHS.ICON_LEFT}
        action={backAction}
      />

      {/* List of users that will receive a licence from org code pool */}
      {!!teacherUserIdsToAssignLicencesTo.length || !!learnerUserIdsToAssignLicencesTo.length
        ? renderOrgLicencesHeader()
        : null}
      {teacherUserIdsToAssignLicencesTo.length ? (
        <div className={styles.licenceListContainer}>
          <ProductFinderUserLicenceListing
            heading={productFinderContent.teachers_header_text}
            users={pick(users, teacherUserIdsToAssignLicencesTo)}
            userLicences={newLicenceAssignments}
          />
        </div>
      ) : null}
      {learnerUserIdsToAssignLicencesTo.length ? (
        <div className={styles.licenceListContainer}>
          <ProductFinderUserLicenceListing
            heading={productFinderContent.students_header_text}
            users={pick(users, learnerUserIdsToAssignLicencesTo)}
            userLicences={newLicenceAssignments}
          />
        </div>
      ) : null}

      {/* List of users that already have a licence */}
      {!!teacherUserIdsWithExistingLicence.length || !!learnerUserIdsWithExistingLicence.length
        ? renderUsersWithLicencesHeader()
        : null}
      {!!teacherUserIdsWithExistingLicence.length &&
      (productTargetUsertype === PRODUCT_TARGET_USERTYPE.TEACHER || !productTargetUsertype) ? (
        <div className={styles.licenceListContainer}>
          <ProductFinderUserLicenceListing
            heading={productFinderContent.teachers_header_text}
            users={pick(users, [...teacherUserIdsWithExistingLicence])}
            userLicences={existingLicences}
          />
        </div>
      ) : null}
      {!!learnerUserIdsWithExistingLicence.length &&
      (productTargetUsertype === PRODUCT_TARGET_USERTYPE.STUDENT || !productTargetUsertype) ? (
        <div className={styles.licenceListContainer}>
          <ProductFinderUserLicenceListing
            heading={productFinderContent.students_header_text}
            users={pick(users, [...learnerUserIdsWithExistingLicence])}
            userLicences={existingLicences}
          />
        </div>
      ) : null}

      {/* List of users that need a licence */}
      {!!teacherUserIdsToNotAssignLicencesTo.length || !!learnerUserIdsToNotAssignLicencesTo.length
        ? renderUsersNeedLicencesHeader()
        : null}
      {!!teacherUserIdsToNotAssignLicencesTo.length &&
      (productTargetUsertype === PRODUCT_TARGET_USERTYPE.TEACHER || !productTargetUsertype) ? (
        <div className={styles.licenceListContainer}>
          <ProductFinderUserLicenceListing
            heading={productFinderContent.teachers_header_text}
            users={pick(users, [...teacherUserIdsToNotAssignLicencesTo])}
            userLicences={existingLicences}
          />
        </div>
      ) : null}
      {!!learnerUserIdsToNotAssignLicencesTo.length &&
      (productTargetUsertype === PRODUCT_TARGET_USERTYPE.STUDENT || !productTargetUsertype) ? (
        <div className={styles.licenceListContainer}>
          <ProductFinderUserLicenceListing
            heading={productFinderContent.students_header_text}
            users={pick(users, [...learnerUserIdsToNotAssignLicencesTo])}
            userLicences={existingLicences}
          />
        </div>
      ) : null}
    </div>
  );
}

ProductFinderLicenceAssignmentInfo.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  teacherIdList: PropTypes.array,
  studentIdList: PropTypes.array,
  userIdsToAssignLicencesTo: PropTypes.array,
  userIdsToNotAssignLicencesTo: PropTypes.array,
  userIdsWithExistingLicence: PropTypes.array,
  newLicenceAssignments: PropTypes.object,
  existingLicenceAssignments: PropTypes.object,
  backAction: PropTypes.func.isRequired,
  productTargetUsertype: PropTypes.string
};

export default ProductFinderLicenceAssignmentInfo;
