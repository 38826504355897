import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import breakpoints from '../../../globals/breakpoints';
import styles from './HubFallbackImg.scss';

function HubFallbackImg({ breakpoint, customClassname = '' }) {
  return (
    <div className={`${styles.fallbackImage} ${styles[customClassname]}`}>
      {breakpoint === breakpoints.XXS ? (
        <SVGIcon glyph={GLYPHS.OUP_SYMBOL_REBRANDING} />
      ) : (
        <SVGIcon glyph={GLYPHS.OUP_LOGO_WHITE_REBRANDING} />
      )}
    </div>
  );
}

export default HubFallbackImg;

HubFallbackImg.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  customClassname: PropTypes.string
};
