import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from '../../ActivationCodeBatchPage/css/AddLicenseContentPage.scss';
import withLocalizedContent from '../../../../../language/withLocalizedContent';
import { organizationalUnitsRequest } from '../../../../../redux/actions/createProduct.js';
import LoadingSpinner from '../../../../../components/LoadingSpinner/LoadingSpinner';

function OrganizationalDropdown({
  productDetails,
  setProductDetails,
  getOrgUnits,
  localizedContent: { productCreate: content },
  isLoading,
  success
}) {
  const { organizationalUnit } = productDetails;
  const setDetails = setProductDetails();

  useEffect(() => {
    getOrgUnits();
  }, []);

  useEffect(() => {
    if (success.length > 0 && !organizationalUnit) {
      setDetails.setOrganizationalUnitFunction(success[0]?.division_id.toString());
    }
  }, [success]);

  return (
    <label htmlFor="organizationalUnit" className={styles.inputContainer}>
      <span className={styles.names}>
        {content.organizationalUnit}
        <span className={styles.redStar}>*</span>
      </span>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <select
          id="organizationalUnit"
          type="checbox"
          required
          defaultValue={organizationalUnit}
          onChange={e => {
            setDetails.setOrganizationalUnitFunction(e.target.value);
          }}
        >
          {success ? (
            success.map(item => (
              <option key={item.division_id} value={item.division_id}>
                {item.division_type}
              </option>
            ))
          ) : (
            <option>Error</option>
          )}
        </select>
      )}
    </label>
  );
}
OrganizationalDropdown.propTypes = {
  productDetails: PropTypes.object,
  setProductDetails: PropTypes.func,
  getOrgUnits: PropTypes.func,
  localizedContent: PropTypes.object,
  isLoading: PropTypes.bool,
  success: PropTypes.array
};

const mapDispatchToProps = dispatch => ({
  getOrgUnits: () => {
    dispatch(organizationalUnitsRequest());
  }
});

export default connect(
  ({ getOrganizationalUnits: { isLoading, error, success } }) => ({
    isLoading,
    error,
    success
  }),

  mapDispatchToProps
)(withLocalizedContent('productCreate')(OrganizationalDropdown));
