import { put, select, take } from 'redux-saga/effects';
import { CLEAR_FORM, submissionComplete, SUBMIT_FORM } from '../../../../reducers/addTeachersToClassroom';
import { addTeachersToClassroom as addTeachersToClassroomComplete } from '../../../../reducers/data/classrooms';
import editClassroomApi from '../../../apiCalls/editClassroomApi';
import { pollClassTeachersAdded } from '../dataRecency/pollClassTeachers';
import { triggerLoadClassroomEdit } from './edit';
import { getCurrentPlatform, isHubMode } from '../../../../../utils/platform';
import getHubClassRedirectData from '../../../../../structure/HubDashboardLayout/Services/getHubClassRedirectData';

export default function* addTeachersToClassroom() {
  console.log('[addTeachersToClassroom Saga] Beginning');

  while (true) {
    console.log('[addTeachersToClassroom Saga] Waiting for user to submit form');
    yield take(SUBMIT_FORM);

    console.log('[addTeachersToClassroom Saga] Form submitted. capturing data from state...');
    const { organisationId, classroomId, classroomName, teachersAdded } = yield select(state => ({
      organisationId: state.classroomPage.orgId,
      classroomId: state.classroomPage.classroomId,
      classroomName: state.classrooms.data[state.classroomPage.classroomId].name,
      teachersAdded: state.addTeachersToClassroom.selectedTeacherIds
    }));

    console.log(
      `[addTeachersToClassroom Saga] Submitting request to add teacher IDs: ${teachersAdded} to class with ID: ${classroomId}`
    );

    const hubClassRedirectData = isHubMode() ? getHubClassRedirectData(classroomId, organisationId) : {};
    const response = yield editClassroomApi(organisationId, classroomId, {
      name: classroomName,
      teachersAdded,
      platformCode: getCurrentPlatform(),
      ...hubClassRedirectData
    });

    const editResult = response || {};
    const requestFailed = editResult.status !== 'success';
    const failedIds = (editResult.data || {}).failedIds || [];

    console.log('[addTeachersToClassroom Saga] Create Class request complete, showing confirmation page');
    yield put(submissionComplete(requestFailed, requestFailed ? teachersAdded : failedIds));

    if (!requestFailed) {
      const successfullyAddedTeacherIds = teachersAdded.filter(id => !failedIds.includes(id));
      yield put(addTeachersToClassroomComplete(classroomId, successfullyAddedTeacherIds));
      yield pollClassTeachersAdded(successfullyAddedTeacherIds);
    }

    yield take(CLEAR_FORM);
    yield triggerLoadClassroomEdit();
  }
}
