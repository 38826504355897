import * as t from '../actionTypes';

export const initialState = {
  activeUserCount: 0,
  classesCount: 0,
  learnerCount: 0,
  teacherCount: 0,
  teacherAdminCount: 0,
  orgAdminCount: 0
};

const setOrgUsageSuccess = (state, payload) => {
  const { activeUserCount, classesCount, learnerCount, teacherCount, teacherAdminCount, orgAdminCount } = payload;

  return {
    ...state,
    activeUserCount,
    classesCount,
    learnerCount,
    teacherCount,
    teacherAdminCount,
    orgAdminCount
  };
};

export default function orgUsage(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_ORG_USAGE_SUCCESS:
      return setOrgUsageSuccess(state, payload);
    case t.GET_ORG_USAGE_ERROR:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
