import * as t from '../actionTypes';

const requestStatusOptions = { isLoading: true, success: false, error: false };
const successStatusOptions = { isLoading: false, success: true, error: false };
const failureStatusOptions = { isLoading: false, success: false, error: true };

const initialState = {
  usersData: []
};

function searchUsers(state = initialState, { type, payload }) {
  switch (type) {
    case t.SEARCH_USERS_REQUEST:
      return {
        ...initialState,
        ...requestStatusOptions
      };
    case t.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        usersData: payload,
        ...successStatusOptions
      };
    case t.SEARCH_USERS_FAILURE:
      return { ...state, ...failureStatusOptions };
    default:
      return state;
  }
}

export default searchUsers;
