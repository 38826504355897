import { select } from 'redux-saga/effects';
import { getFullUrl } from '../../../../utils/url';
import signedRequest from './signedRequest';
import jsonFetch from './jsonFetch';
import session from '../../../../utils/session';
import { featureIsEnabled } from '../../../../globals/envSettings';

export const JSON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const identifyReadApis = [
  '/query',
  '/search',
  '/identity',
  '/generate-username',
  '/class/students',
  '/signin-card-details',
  '/getClassAssessments'
];
const writeApiswithOutOrgContext = ['/edu/user', '/ces/user', '/user/auth'];

const isWriteRequest = (method, url) => {
  let output = false;
  let isWriteApiRequest = true;

  // Some of our apis fetch data with POST method syntax (Hence we should ignore them)
  identifyReadApis.forEach(urlKey => {
    if (url.includes(urlKey)) {
      isWriteApiRequest = false;
    }
  });
  // Some of our apis do have orgId in the request but does not update any enrolment or entitlment data (Hence we should ignore them)
  writeApiswithOutOrgContext.forEach(urlKey => {
    if (url.endsWith(urlKey)) {
      isWriteApiRequest = false;
    }
  });

  // Some of our apis do have orgId in the request but does not update any enrolment or entitlment data (Hence we should ignore them)
  writeApiswithOutOrgContext.forEach(urlKey => {
    if (url.endsWith(urlKey)) {
      isWriteApiRequest = false;
    }
  });

  // if the request method is POST , PUT , DELETE  and does not contain query / search kewords in the url path, consider it as write request
  if (method !== 'GET' && isWriteApiRequest) {
    output = true;
  }

  return output;
};

const guidRegexp = /^[a-z0-9]{8}(-?[a-z0-9]{4}){3}-?[a-z0-9]{12}$/i;

const isValidOrgId = orgId => typeof orgId === 'string' && guidRegexp.exec(orgId);

const getOrgIdFromRequest = (url, body) => {
  const paramsList = url.split('/');
  const orgIndex = paramsList.findIndex(element => element === 'org');

  const orgIdFromParams = orgIndex > -1 && paramsList[orgIndex + 1];
  const orgIdFromEventBody = body && body.orgId;
  const currentOrgIdFromBody = body && body.currentOrgId;
  const orgIdFromFilters = body && body.filters && body.filters.orgId;
  const orgIdFromItems = body && body.items && body.items.orgId;

  const possibleOrgIdSources = {
    'event params': orgIdFromParams,
    'body root': orgIdFromEventBody,
    'body currentOrg': currentOrgIdFromBody,
    'body filters': orgIdFromFilters,
    'body items': orgIdFromItems
  };

  const foundOrgIds = Object.keys(possibleOrgIdSources).filter(orgIdSource => {
    const orgId = possibleOrgIdSources[orgIdSource];
    if (isValidOrgId(orgId)) {
      return true;
    }
    return false;
  });

  if (foundOrgIds.length === 0) {
    return null;
  }
  // return the first orgId found
  const firstSourceFound = foundOrgIds[0];
  return possibleOrgIdSources[firstSourceFound];
};

export default function* signedFetch(
  apiName,
  url,
  method = 'GET',
  body,
  headers = JSON_HEADERS,
  includeHeaders = false,
  multipartRequest = false,
  includeXAPIKey = true,
  includeStatusCode = false
) {
  const {
    saml: { accessKeyId, secretAccessKey, sessionToken }
  } = session.get();
  const systemClockOffset = yield select(state => state.app.systemClockOffset);
  const requestBody = body === undefined && ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method) ? {} : body;
  const requestBodyText = typeof requestBody === 'string' ? requestBody : JSON.stringify(requestBody);
  const fullUrl = getFullUrl(url, window.location);
  const isReadOnlyfeatureEnabled = featureIsEnabled('read-only-mode');
  let isOrgBlocked = false;
  let orgIdFromRequest;

  const isWriteMethod = isWriteRequest(method, url);
  if (isWriteMethod) {
    orgIdFromRequest = getOrgIdFromRequest(url, body);
    const userOrgs = yield select(state => state.organisations);
    if (userOrgs && userOrgs?.data && userOrgs.data[orgIdFromRequest] && userOrgs.data[orgIdFromRequest].isBlocked) {
      isOrgBlocked = true;
    }
  }

  if (isReadOnlyfeatureEnabled && isOrgBlocked) {
    return {
      status: 'error',
      error: `Organization ${orgIdFromRequest} is currently in read-only state!`,
      message: `Organization ${orgIdFromRequest} is currently in read-only state!`
    };
  }
  return yield jsonFetch(
    apiName,
    [
      fullUrl,
      signedRequest(
        fullUrl,
        method,
        headers,
        multipartRequest ? requestBody : requestBodyText,
        accessKeyId,
        secretAccessKey,
        sessionToken,
        systemClockOffset
      )
    ],
    includeHeaders,
    includeXAPIKey,
    includeStatusCode
  );
}
