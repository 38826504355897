import jsonFetch from '../util/jsonFetch.js';

export default () => {
  let url = `${__API_BASE__}/open/environment`;

  if (__SHA_VERSION__) {
    url += `?buildVersion=${__SHA_VERSION__}`;
  }

  return jsonFetch('GetEnvironmentSettings', [url]);
};
