import { fork, select } from 'redux-saga/effects';
import userRoles, { roleIsAtLeast } from '../../../../../globals/userRoles';
import enrolUser from '../organisation/enrolUser/enrolUser.saga';
import orgPageLoader from '../organisation/orgPageLoader.saga';
import bulkUploadToClass from '../organisation/bulkEnrolUser/bulkUploadToClass.saga';
import userRootSaga from '../user';

function* loadEnrolUserSaga() {
  const userRole = yield select(state => state.identity.role);

  if (roleIsAtLeast(userRoles.TEACHER_ADMIN, userRole)) {
    yield fork(orgPageLoader);
    yield fork(enrolUser);
    yield fork(bulkUploadToClass);
    yield fork(userRootSaga);
  }
}

export default loadEnrolUserSaga;
