// Page load and clear actions

// Remove individual student

// Student Limit actions
const SET_CLASSCODE_VALUE = 'studenRequestToJoinAClass/SET_CLASSCODE_VALUE';
const VALIDATE_CLASS_CODE = 'studenRequestToJoinAClass/VALIDATE_CLASS_CODE';

export const CLASSCODE_ON_BLUR = 'studenRequestToJoinAClass/CLASSCODE_ON_BLUR';
// Nav actions
export const GET_ORG_DETAILS = 'studenRequestToJoinAClass/GET_ORG_DETAILS ';
export const SUBMIT_FORM = 'studenRequestToJoinAClass/SUBMIT_FORM';
export const CONFIRM = 'studenRequestToJoinAClass/CONFIRM';
const GOTO_CLASSCODE_INPUT = 'studenRequestToJoinAClass/GOTO_CLASSCODE_INPUT';
const SUBMISSION_COMPLETE = 'studenRequestToJoinAClass/SUBMISSION_COMPLETE';
// Reset the form
export const RESET_FORM = 'studenRequestToJoinAClass/RESET_FORM';

const CLASS_CODE_REGEX = /^[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5,30}$/;

export const formStates = {
  INPUTTING: 'INPUTTING',
  GET_ORG_DETAILS: 'GET_ORG_DETAILS',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION' // Success|Fail feedback after SUBMITTING
};

const formatCode = value => {
  if (value.length <= 5) {
    return value;
  }

  const constainsHyphen = value.startsWith('-', 5);
  const processedvalue = !constainsHyphen ? `${value.slice(0, 5)}-${value.slice(5)}` : value;

  return processedvalue;
};

const initialState = {
  formState: formStates.INPUTTING,
  context: null,
  // Input values
  classCodeValue: '',
  classCodeIsValid: null,
  classCodeIsInvalid: null,
  classCodeErrorMessage: '',

  orgClassDetails: {
    organisationName: null,
    className: null
  },

  // General error catch all
  requestFailed: null
};

export default function studentRequestToJoinAClass(state = initialState, action = {}) {
  switch (action.type) {
    // Input field updates
    case SET_CLASSCODE_VALUE:
      return {
        ...state,
        classCodeValue: formatCode(action.classCodeValue),
        classCodeIsValid: CLASS_CODE_REGEX.test(action.classCodeValue),
        classCodeIsInvalid: false
      };
    case CLASSCODE_ON_BLUR:
      return {
        ...state,
        classCodeIsValid: CLASS_CODE_REGEX.test(state.classCodeValue),
        classCodeIsInvalid: !CLASS_CODE_REGEX.test(state.classCodeValue)
      };

    // Nav actions
    case GET_ORG_DETAILS:
      return {
        ...state,
        formState: formStates.GET_ORG_DETAILS,
        orgClassDetails: action.orgClassDetails
      };
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case CONFIRM:
      return {
        ...state,
        context: action.context,
        formState: formStates.SUBMITTING
      };
    case GOTO_CLASSCODE_INPUT:
      return {
        ...state,
        formState: formStates.INPUTTING
      };
    case SUBMISSION_COMPLETE:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        requestFailed: action.requestFailed,
        failedIds: action.failedIds
      };
    // Reset the form
    case RESET_FORM:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
// Input field updates
export const setClassCodeValue = classCodeValue => ({
  type: SET_CLASSCODE_VALUE,
  classCodeValue
});

export const validateClassCode = () => ({
  type: VALIDATE_CLASS_CODE
});

export const classCodeOnBlur = () => ({
  type: CLASSCODE_ON_BLUR
});
// Page load and clear actions
export const getOrgDetails = orgClassDetails => ({
  type: GET_ORG_DETAILS,
  orgClassDetails
});

// Nav actions
export const submitForm = () => ({
  type: SUBMIT_FORM
});
export const confirmDetails = context => ({
  type: CONFIRM,
  context
});
export const submissionComplete = (requestFailed, failedIds) => ({
  type: SUBMISSION_COMPLETE,
  requestFailed,
  failedIds
});
export const gotoClassCodeInput = () => ({
  type: GOTO_CLASSCODE_INPUT
});
export const resetForm = () => ({
  type: RESET_FORM
});
