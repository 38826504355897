import PropTypes from 'prop-types';
import React from 'react';

import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PanelNavigationLink from '../PanelNavigationLink/PanelNavigationLink';
import PanelHeading from '../PanelHeading/PanelHeading';
import PopoutPanelIconHeading from '../PopoutPanelIconHeading/PopoutPanelIconHeading';

function PanelScrollContainer({
  header,
  children,
  heading,
  subHeading,
  headingIconType,
  closeText,
  closeAction,
  footer
}) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          {closeAction ? (
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={closeText} action={closeAction} />
            </div>
          ) : null}
          {headingIconType ? (
            <PopoutPanelIconHeading type={headingIconType} title={heading} subtitle={subHeading} />
          ) : (
            <PanelHeading title={heading} subtitle={subHeading} />
          )}
          {header || null}
        </div>
      }
      footerContent={footer}
    >
      {children}
    </ScrollContainer>
  );
}

PanelScrollContainer.propTypes = {
  children: PropTypes.node.isRequired,
  headingIconType: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  closeText: PropTypes.string.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  closeAction: PropTypes.func
};

PanelScrollContainer.defaultProps = {
  footer: null
};

export default PanelScrollContainer;
