import * as t from '../../actionTypes';

const requestStatusOptions = { result: {}, isLoading: true, error: {} };
const successStatusOptions = { result: {}, isLoading: false, error: {} };
const failureStatusOptions = { result: {}, isLoading: false, error: {} };

const initialState = {
  result: {},
  isLoading: false,
  error: {}
};

function searchProduct(state = initialState, action) {
  switch (action.type) {
    case t.SEARCH_PRODUCT_REQUEST:
      return {
        ...state,
        ...requestStatusOptions,
        error: {}
      };
    case t.SEARCH_PRODUCT_SUCCESS:
      return { ...state, ...successStatusOptions, result: action.payload, error: {} };
    case t.SEARCH_PRODUCT_FAILURE:
      return { ...state, ...failureStatusOptions, error: action.data, result: {} };
    default:
      return state;
  }
}

export default searchProduct;
