import { flowRight, head, toString, toUpper } from 'lodash';

/**
 * Return the uppercased first character of the given string, or an empty
 * string if `null` or `undefined`.
 *
 * @param {string?} str
 *
 * @return {string}
 */
// prettier-ignore
export const toInitial = flowRight(toUpper, head, toString);

/**
 * Return a new string formed of the initials of each given word.
 *
 * @param {...string} words
 *
 * @return {string}
 */
export function toInitials(...words) {
  return words.map(toInitial).join('');
}

export function replaceCommaWithSpace(string = '') {
  return string.replace(/,/g, ' ');
}

export function removeHtmlTags(string = '') {
  return string.replace(/(<([^>]+)>)/gi, '');
}

export function hasValueNotApplicable(value) {
  return value.toLowerCase() === 'n/a';
}

export function hasValueAwaiting(value) {
  return value.toLowerCase() === 'awaiting';
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
