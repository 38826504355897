import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getExternalIdsApi from '../../../apiCalls/product/getExternalIdsApi';

function* getExternalIds() {
  let apiResults = {};

  apiResults = yield getExternalIdsApi();

  if (apiResults.error || apiResults.status !== 'success') {
    yield put(actions.externalIdsCallFailure({ error: apiResults.message }));
  } else {
    yield put(actions.externalIdsCallSuccess({ success: apiResults.data.external_systems }));
  }
}

export default getExternalIds;
