import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getProductMetadata } from '@oup/shared-node-browser/productMetadata.js';
import COLORS from '../../globals/colors.js';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import UrlControlledTabs from '../../components/UrlControlledTabs/UrlControlledTabs.js';
import withLocalizedContent from '../../language/withLocalizedContent.js';
import { generateDownloadLinksRequest, deleteGuestUserLicences } from '../../redux/actions/generateDownloadLinks.js';
import { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';

import ErrorView from '../../views/errors/ErrorView.js';
import Table, { columnTypes } from '../../components/Table/Table.js';
import styles from './GenerateDownloadLinks.scss';

function ProductSearchResults({ products, onDownload, localizedContent }) {
  const columns = [
    {
      heading: localizedContent.column_product,
      type: columnTypes.TEXT
    },
    {
      heading: localizedContent.text_download,
      type: columnTypes.TEXT
    }
  ];

  const rows = products.map(({ title, description, userLicence, signedUrl }, index) => ({
    id: `row_${index}`,
    cells: [
      <div key={`prod_link_${index}`}>
        <div className={styles.title_container}>
          <div className={styles.title}>{title && title}</div>
          <span className={styles.titleSub}>{description}</span>
        </div>
      </div>,

      <div key={`actions_${index}`}>
        <div className={styles.download_container}>
          <button
            className={styles.download_link_container}
            aria-label={`Download ${title}`}
            type="button"
            onClick={() => onDownload(userLicence, signedUrl)}
          >
            <SVGIcon glyph={GLYPHS.ICON_DOWNLOAD_WITHOUT_BACKGROUND} className={styles.download} />
          </button>
        </div>
      </div>
    ]
  }));

  return <Table columns={columns} rows={rows} persistantColumns />;
}

const handleRetry = () => {
  window.location.reload();
};

const showError = (message, content) => (
  <ErrorView
    title={content.error_title}
    detail={message}
    iconType={types.INFO}
    onRetry={!message ? null : handleRetry}
  />
);

function GenerateDownloadLinksPage({
  localizedContent: { generateDownloadLinksPage: content, productPage: productContent },
  productDetails,
  getProductDetailsRequestAction,
  deleteGuestUserLicencesAction
}) {
  const [productList, setProducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [productLoading, setProductLoading] = useState(true);
  const { platform, token } = useParams();
  const location = useLocation();

  const handleDownloadAndRemoveLicence = async (userLicence, signedUrl) => {
    const payload = { userToken: token, userLicence, platform };

    // Extract the file name from the signedUrl
    const fileName = signedUrl
      .split('/')
      .pop()
      .split('?')[0];

    // Create an anchor element and trigger a download
    const link = document.createElement('a');
    link.href = signedUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // delete licence after download.
    deleteGuestUserLicencesAction(payload);
  };

  useEffect(async () => {
    if (
      productDetails &&
      !productDetails.isLoading &&
      Array.isArray(productDetails.success) &&
      !Object.keys(productDetails.success).length &&
      !Object.keys(productDetails.error).length
    ) {
      const payload = { platform, userToken: token };
      getProductDetailsRequestAction(payload);
    }

    if (productDetails.error && !!Object.keys(productDetails.error).length && !productDetails.isLoading) {
      setErrorMessage(productDetails.error);
    }

    if (productDetails && !productDetails.isLoading && Object.keys(productDetails.success).length) {
      const isbns = Object.keys(productDetails.success);
      if (isbns && isbns.length) {
        const result = await getProductMetadata(fetch, isbns);
        const productsDetails = isbns.map(isbn => {
          const { title, description } = result[isbn];
          return {
            title,
            description,
            userLicence: productDetails.success[isbn].licenceId,
            signedUrl: productDetails.success[isbn].signedUrl
          };
        });
        setProducts(productsDetails);
        setProductLoading(false);
      }
    } else {
      setProducts([]);
      setProductLoading(false);
    }
  }, [productDetails]);

  const downloadTab = {
    urlName: content.text_download,
    color: COLORS.PRIMARY_BLUE,
    tabText: content.text_download,
    panelContent: (
      <div className="grid horizantal-scroll-mobile">
        <div className="row " style={{ marginTop: '2rem' }}>
          <ProductSearchResults
            products={productList}
            onDownload={handleDownloadAndRemoveLicence}
            localizedContent={content}
          />
        </div>
      </div>
    )
  };

  const tabItems = [downloadTab];

  return (
    <div className={styles.containerGuestDownload}>
      <Helmet title={content.document_head_title_text} />
      {productDetails.isLoading || productLoading ? (
        <LoadingSpinner />
      ) : (
        <UrlControlledTabs
          items={tabItems}
          tabName={content.tab_name}
          pathname={location.pathname}
          backgroundColor={COLORS.PRIMARY_BLUE}
        />
      )}
      {errorMessage && showError(errorMessage, productContent)}
    </div>
  );
}

ProductSearchResults.propTypes = {
  products: PropTypes.array,
  localizedContent: PropTypes.object,
  onDownload: PropTypes.func.isRequired
};

GenerateDownloadLinksPage.propTypes = {
  localizedContent: PropTypes.object,
  productDetails: PropTypes.object,
  getProductDetailsRequestAction: PropTypes.func.isRequired,
  deleteGuestUserLicencesAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('generateDownloadLinksPage', 'productPage', 'supportPage'),
  connect(
    state => ({
      productDetails: state.generateDownloadLinks || {}
    }),
    {
      getProductDetailsRequestAction: generateDownloadLinksRequest,
      deleteGuestUserLicencesAction: deleteGuestUserLicences
    }
  )
)(GenerateDownloadLinksPage);
