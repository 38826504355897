import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

function* linkOicOrgToJanison(input) {
  const { orgId } = input;

  const response = yield signedFetch(
    'oicCreateJanisonOrgUnit',
    `${__API_BASE__}/org/${orgId}/oic/create-janison-org-unit`,
    'POST',
    {
      orgId
    }
  );

  if (response.status === 'success') {
    return yield put(actions.oicLinkOrgToJanisonSuccess({ orgId }));
  }

  return yield put(actions.oicLinkOrgToJanisonFailure({ orgId, errors: response.message }));
}

export default linkOicOrgToJanison;
