import { put } from 'redux-saga/effects';
import { getRecentlyAccessedSuccess, getRecentlyAccessedError } from '../../../../actions/recentlyAccessed';
import { getRecentlyAccessedRequest } from '../../../apiCalls/product/recentlyAccessedApi';

function* getRecentlyAccessed(payload) {
  const response = yield getRecentlyAccessedRequest(payload);

  if (response.status === 'success') {
    yield put(getRecentlyAccessedSuccess(response.data));
  } else {
    yield put(getRecentlyAccessedError());
  }
}

export default getRecentlyAccessed;
