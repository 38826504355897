import React from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import ProductGroupSearchArea from './ProductGroupSearchArea';

function ProductGroupSearchPage() {
  return (
    <PageWrapper>
      <Helmet title="Product group search" />
      <ProductGroupSearchArea />
    </PageWrapper>
  );
}

export default ProductGroupSearchPage;
