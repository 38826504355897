import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

export const StatusEnum = {
  Error: 'error',
  InProgress: 'inProgress',
  Success: 'Success'
};

function* getPreseedOrgData(input) {
  const { orgId, deleteClasses, deleteMembers } = input;
  const data = { deleteClasses, deleteMembers };
  let completedSuccessfully = true;
  yield put(actions.preseedOrgInProgress(StatusEnum.InProgress));

  const result = yield signedFetch('updateOrgFromEac', `${__API_BASE__}/org/${orgId}/update-from-eac`, 'POST', data);

  let errorResult = {};
  if (result.status === 'error' || result.status === 'fail') {
    completedSuccessfully = false;
    errorResult = { ...result, orgId };
  }

  if (completedSuccessfully) {
    yield put(actions.preseedOrgSuccess(StatusEnum.Success));
  } else {
    yield put(actions.preseedOrgFailure({ status: StatusEnum.Error, errorResult }));
  }
}

export default getPreseedOrgData;
