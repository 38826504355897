const SET_ACCOUNT_LIST = 'unlockAccount/SET_ACCOUNT_LIST';

const initialState = {
  userIds: []
};

export default function unlockAccount(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNT_LIST:
      return {
        ...state,
        userIds: action.userIds
      };
    default:
      return { ...state };
  }
}

export const setAccountToUnlock = userIds => ({
  type: SET_ACCOUNT_LIST,
  userIds: Array.isArray(userIds) ? userIds : [userIds]
});
