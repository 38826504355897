import * as t from '../../actionTypes';

const requestStatusOptions = { success: {}, isLoading: true, error: {} };
const successStatusOptions = { success: {}, isLoading: false, error: {} };
const failureStatusOptions = { success: {}, isLoading: false, error: {} };

const initialState = {
  isLoading: false,
  error: {},
  success: {}
};

export default function createUserActivity(state = initialState, action) {
  switch (action.type) {
    case t.CREATE_USER_ACTIVITY_REQUEST:
      return {
        ...state,
        ...requestStatusOptions,
        error: {}
      };
    case t.CREATE_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        ...successStatusOptions,
        success: action.payload,
        error: {}
      };
    case t.CREATE_USER_ACTIVITY_FAILURE:
      return {
        ...state,
        ...failureStatusOptions,
        error: action.payload,
        success: {}
      };
    default:
      return state;
  }
}
