import unsignedFetch, { JSON_HEADERS } from '../util/unsignedFetch.js';

const includeHeaders = false;
const includeXAPIKey = true;
const includeStatusCode = true;

export default emailAddress =>
  unsignedFetch(
    'checkGlobalUserEmail',
    `${__API_BASE__}/open/user/check-email?emailAddress=${encodeURIComponent(emailAddress.trim())}`,
    'GET',
    undefined,
    JSON_HEADERS,
    includeHeaders,
    includeXAPIKey,
    includeStatusCode
  );
