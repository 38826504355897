import { put } from 'redux-saga/effects';
import { courseMarksRequestSuccess, courseMarksRequestFailure } from '../../../../actions/ngsCourse';
import getMarksApiCall from '../../../apiCalls/ngs/getMarks';

function* getCourseMarks(orgId, courseId) {
  const response = yield getMarksApiCall(orgId, courseId);

  if (response.status === 'success') {
    yield put(courseMarksRequestSuccess(response.data));
    return;
  }

  yield put(courseMarksRequestFailure(response.message));
}

export default getCourseMarks;
