import React from 'react';
import PropTypes from 'prop-types';
import styles from '../css/AddLicenseContentPage.scss';
import withLocalizedContent from '../../../../../language/withLocalizedContent';

function ToggleLicenseBatch({ toggleButton, setToggle, localizedContent: { activationCodeBatch: content } }) {
  return (
    <div className={styles.licenseBatchSelector}>
      <div className={styles.licenseAndBatch}>
        <button
          onClick={() => {
            setToggle(true);
          }}
          className={`${styles.license} ${toggleButton ? styles.active : ''} `}
          type="button"
        >
          {content.license_button}
        </button>
        <button
          onClick={() => {
            setToggle(false);
          }}
          className={`${styles.batch}   ${toggleButton ? '' : styles.active} `}
          type="button"
        >
          {content.batch_button}
        </button>
      </div>
    </div>
  );
}

ToggleLicenseBatch.propTypes = {
  toggleButton: PropTypes.bool,
  setToggle: PropTypes.func,
  localizedContent: PropTypes.object
};
export default withLocalizedContent('activationCodeBatch')(ToggleLicenseBatch);
