// Page load and clear actions
const CLEAR_CLASSROOM_PAGE = 'profilePage/CLEAR_CLASSROOM_PAGE';

// Update list selection actions
const SET_CLASSROOM_SELECTED = 'profilePage/SET_CLASSROOM_SELECTED';
const SET_MATERIAL_SELECTED = 'profilePage/SET_MATERIAL_SELECTED';

const initialState = {
  // Classes Tab
  classroomSearchTerm: '',
  selectedClassroomIds: [],

  // Learning Material Tab
  materialSearchTerm: '',
  instance: 'userProducts',
  selectedMaterialIds: []
};

export default function profilePage(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_CLASSROOM_PAGE:
      return {
        ...initialState
      };

    // Update list selection actions

    case SET_CLASSROOM_SELECTED:
      return {
        ...state,
        selectedClassroomIds: action.isSelected
          ? // If now is selected, add to list
            [...state.selectedClassroomIds, action.classroomId]
          : // Else remove from list
            state.selectedClassroomIds.filter(id => id !== action.classroomId)
      };

    case SET_MATERIAL_SELECTED:
      return {
        ...state,
        selectedMaterialIds: action.isSelected
          ? // If now is selected, add to list
            [...state.selectedMaterialIds, action.materialId]
          : // Else remove from list
            state.selectedMaterialIds.filter(id => id !== action.materialId)
      };

    default:
      return state;
  }
}

// Page load and clear actions
export const clearProfilePage = () => ({
  type: CLEAR_CLASSROOM_PAGE
});

// Update list selection actions

export const setClassroomSelected = (classroomId, isSelected) => ({
  type: SET_CLASSROOM_SELECTED,
  classroomId,
  isSelected
});

export const setMaterialSelected = (materialId, isSelected) => ({
  type: SET_MATERIAL_SELECTED,
  materialId,
  isSelected
});
