import PropTypes from 'prop-types';
import React from 'react';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';

/** Componentt to wrap a TextInputWithButton making it easier to define a search box */
function SearchInput({ id, label, placeholder, value, onChange, buttonAction, onKeyDown }) {
  return (
    <TextInputWithButton
      id={id}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      buttonIcon={GLYPHS.ICON_SEARCH}
      buttonAction={buttonAction}
    />
  );
}

SearchInput.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** the inputs text label */
  label: PropTypes.string.isRequired,
  /** the inputs placeholder text */
  placeholder: PropTypes.string.isRequired,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.any,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func.isRequired,
  /** button onclick action */
  buttonAction: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func
};

export default SearchInput;
