import { put } from 'redux-saga/effects';
import { loadCourseBookSuccess, loadCourseBookFailure } from '../../../../actions/ngsCourseBook';
import getCourseBooksApiCall from '../../../apiCalls/ngs/getCourseBooks';

function* loadCourseBook(orgId, courseId, bookId) {
  const response = yield getCourseBooksApiCall(orgId, courseId, bookId);

  if (response.status === 'success') {
    yield put(loadCourseBookSuccess(response.data[0]));
    return;
  }

  yield put(loadCourseBookFailure(response.message));
}

export default loadCourseBook;
