import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './PopoutPanel.scss';
import MouseEvent from '../../utils/polyfill/MouseEvent';
import querySelectorAllFocusable from '../../utils/dom/querySelectorAllFocusable';

// To control keyboard tabbing and prevent content behind modal from scrolling:
import trapFocusIn from '../../utils/dom/trapFocusIn';
import { isHubMode, isCesMode, isOcpMode } from '../../utils/platform';
import preventBodyScroll from '../../utils/dom/preventBodyScroll';

const ANIMATION_DELAY = 300; // milliseconds

// Lookup of element nodeNames to simplify identification of form field elements:
const isField = { INPUT: 1, SELECT: 1, TEXTAREA: 1 };

function clickPanelCloseButton() {
  // Instead of understanding the intricacies of each panel's close action, let's just fake a click on the Close button:
  const closeButton = document.getElementById('panelNavLink');
  if (closeButton) {
    const event = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
    closeButton.dispatchEvent(event);
  }
}

// For filtering unwanted fields in the panel:
function filterByNotCloseButton(elem) {
  return elem.className.indexOf('panelNavLink') === -1;
}

// To sort inputs etc before buttons: (Array.sort() expects this to return 0, 1, or -1)
function sortByFieldsFirst(elemA, elemB) {
  return isField[elemA.nodeName] !== isField[elemB.nodeName]
    ? // Both are fields or neither are fields, so don't change order:
      0
    : // One of them is a field:
      -isField[elemA.nodeName] || isField[elemB.nodeName];
}

function getFocusableFields(wrapper) {
  const focusables = querySelectorAllFocusable(wrapper).filter(filterByNotCloseButton);
  return focusables.sort(sortByFieldsFirst);
}

export default class PopoutPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelOpen: props.isOpen,
      contentMounted: props.isOpen,
      // If the page has been refreshed with url for the panel to be open, we'll make use of the url when the panel closes:
      urlWhenComponentMounted: window.location.href,
      currentFocusedElementId: '',
      displayModalTransition: false
    };
  }

  componentDidMount() {
    const { isOpen } = this.props;

    if (isOpen) {
      this.onShow();
    } else {
      this.setState({
        panelOpen: false
      });
    }
  }

  componentWillReceiveProps({ isOpen: willOpen }) {
    const { isOpen } = this.props;

    if (!isOpen && willOpen) {
      this.onShow();
    } else if (isOpen && !willOpen) {
      this.onHide();
    }
  }

  onShow = () => {
    preventBodyScroll();

    // Bind events before the animation so we minimise the chance of jank:
    document.addEventListener('keydown', this.onKeyPress, false);
    window.addEventListener('focus', this.onFocus, false);
    window.addEventListener('blur', this.onBlur, false);
    window.addEventListener('focusout', this.trapFocus, false);

    // If we are currently closed and becoming open
    //  1. Mount content
    //  2. Animate in
    this.setState({
      panelOpen: true,
      contentMounted: true,
      // For accessibility we'll need to remember the button that opened the panel:
      triggeredBy: document.activeElement === document.body ? null : document.activeElement,
      displayModalTransition: true
    });

    setTimeout(this.onAfterShow, ANIMATION_DELAY);
  };

  onHide = () => {
    trapFocusIn(false);
    preventBodyScroll(false);

    // Unbind events before the animation so we minimise the chance of jank:
    document.removeEventListener('keydown', this.onKeyPress, false);
    window.removeEventListener('focus', this.onFocus, false);
    window.removeEventListener('blur', this.onBlur, false);

    // If we are currently open and becoming closed
    //  1. Animate out
    //  2. Unmount content
    this.setState({
      panelOpen: false,
      // eslint-disable-next-line react/no-unused-state
      animationTimeout: setTimeout(this.onAfterHide, ANIMATION_DELAY)
    });
  };

  onAfterShow = () => {
    // Set focus on the first field when popup opens:
    trapFocusIn(this.wrapper, getFocusableFields);
    this.setFocusOnFirstField();
  };

  onAfterHide = () => {
    const { isOpen } = this.props;
    const { triggeredBy, urlWhenComponentMounted } = this.state;

    // Line added because isOpen is false by default on hub store and triggers onHide on didMount
    if ((isHubMode() || isCesMode() || isOcpMode()) && isOpen) return;

    // Remove content after the animation delay
    this.setState({ contentMounted: false, displayModalTransition: false });

    // If the page wasn't opened by a link but by a page refresh then we have no button to return focus to.
    // Instead we'll try to be smartarses and find a likely link to the panel's URL:
    const trigger = triggeredBy || querySelectorAllFocusable().filter(elem => elem.href === urlWhenComponentMounted)[0];

    // For accessibility, reset focus back to the element that triggered the panel:
    if (trigger) trigger.focus();
  };

  onFocus = e => {
    const { currentFocusedElementId } = this.state;

    e.preventDefault();

    if (!currentFocusedElementId) {
      // Set the currently focused element to component state
      this.setState({ currentFocusedElementId: document.activeElement.id });
    }
  };

  onBlur = () => {
    // Set the currently active element id to component state when losing focus in order to know which element
    // to set focus when we return to the window
    this.setState({ currentFocusedElementId: document.activeElement.id });
  };

  onKeyPress = e => {
    switch (e.keyCode) {
      case 27:
        // Escape to close menu:
        return this.onKeyEscape(e);
      case 9:
        // Tabbing between fields:
        return this.onKeyTab(e);
      default:
        return null;
    }
  };

  // Helper to close popout when user presses escape:
  onKeyEscape = e => {
    const { contentMounted } = this.state;

    if (e.keyCode === 27 && contentMounted) {
      const { closeAction } = this.props;
      // Ideally, the code that opened the panel also provided a method for closing it:
      if (closeAction) {
        closeAction();
        // Otherwise we have to use some cunning and look for a close button to fake-click:
      } else {
        clickPanelCloseButton();
      }
    }
  };

  // Interfering with tabbing is not ideal, but we need to prevent focus moving to dom elements outside the panel:
  onKeyTab = e => {
    // Derive a list of tabbable fields inside the panel:
    const fields = querySelectorAllFocusable(this.wrapper);
    const lastIndex = fields.length - 1;
    const activeElement = document.activeElement;

    if (!e.shiftKey && activeElement === fields[lastIndex]) {
      fields[0].focus();
      e.preventDefault();
    } else if (e.shiftKey && activeElement === fields[0]) {
      fields[lastIndex].focus();
      e.preventDefault();
    }
  };

  setFocusOnFirstField = () => {
    // Set focus on the first field when popup opens:
    // Sorting allows us to prevent things like "Import from file" from receiving default focus:
    const firstFocusableElement = getFocusableFields(this.wrapper)[0];
    if (firstFocusableElement) setTimeout(() => firstFocusableElement.focus(), 0);
  };

  render() {
    const { id, dataTestId = '', ariaLabel, ariaLive, isOpen, children, panelSize, className } = this.props;
    const { panelOpen, contentMounted, displayModalTransition } = this.state;
    let panelSizeStyle;
    if (panelSize) {
      panelSizeStyle = styles[panelSize];
    }
    return (
      <div
        id={id}
        ref={elem => {
          this.wrapper = elem;
        }}
        role="dialog"
        aria-hidden={!isOpen}
        aria-label={ariaLabel}
        aria-live={ariaLive}
        className={classnames(styles.popoutPanel, {
          [styles.isOpen]: panelOpen,
          [styles.contentMounted]: contentMounted,
          [styles[className]]: className !== undefined,
          [styles.hubPopoutPanel]: isHubMode()
        })}
      >
        {/* looking at the following links, role='document' is not required in the panels because
          our implementation usually has just a form without a informational message to the user
          https://www.deque.com/blog/aria-modal-alert-dialogs-a11y-support-series-part-2/
          http://w3c.github.io/aria-practices/#dialog_modal
          http://w3c.github.io/aria-practices/examples/dialog-modal/dialog.html */}
        <div
          data-testid={dataTestId}
          className={classnames(styles.panel, {
            [styles.panelTransition]: displayModalTransition,
            [panelSizeStyle]: panelSizeStyle !== undefined,
            [styles[className]]: className !== undefined
          })}
        >
          {contentMounted && children}
        </div>
      </div>
    );
  }
}

PopoutPanel.propTypes = {
  // ID used to identify the Popout in testing
  id: PropTypes.string,
  // A11y label for the journey
  ariaLabel: PropTypes.string,
  ariaLive: PropTypes.string,

  // Optional 'ESC' key close function
  closeAction: PropTypes.func,
  className: PropTypes.string,
  // Externally controlled open status
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Content to be displayed within the panel */
  children: PropTypes.any.isRequired,
  panelSize: PropTypes.string,
  dataTestId: PropTypes.string
};
