import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';
import validateOrgAdminInput from './validateOrgAdminInput';

function* registerOrgAdmin(input) {
  const errors = yield validateOrgAdminInput(input);
  const hasErrors = Object.values(errors).includes(true);
  if (hasErrors) {
    yield put(actions.validateOrgAdminError());
    return;
  }
  yield put(actions.registerOrgAdminSubmitting());
  const response = yield signedFetch('createUser', `${__API_BASE__}/user`, 'POST', input);
  if (response.status === 'success') {
    yield put(actions.registerOrgAdminSuccess());
    return;
  }
  yield put(actions.registerOrgAdminFailure(response.errorMessages));
}

export default registerOrgAdmin;
