import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import {
  clearAssignmentSelection,
  formStates,
  setPollOperation
} from '../../../redux/reducers/removeLearningMaterial.reducer';
import content from '../../../utils/cmsContent';
import RemoveLearningMaterialConfirmation from './RemoveLearningMaterialConfirmation';
import RemoveLearningMaterialReview from './RemoveLearningMaterialReview';

const getAssignment = (assignments, id) => assignments.find(assignment => assignment.assignmentId === id);

class RemoveLearningMaterial extends React.Component {
  componentDidMount() {
    const { setPollOperationAction } = this.props;
    const operation = window.location.pathname.split('/').pop();
    setPollOperationAction(operation);
  }

  reset = () => {
    const { clearAssignmentSelectionAction } = this.props;

    setTimeout(() => {
      clearAssignmentSelectionAction();
    }, 500);
  };

  _handleOnComplete = () => {
    const { onComplete } = this.props;
    this.reset();
    onComplete();
  };

  render() {
    const {
      contextName,
      formState,
      closePanel,
      resultStatus,
      removedCount = 0,
      returnedCount = 0,
      results,
      products
    } = this.props;

    const CMS = content.removeLearningMaterialPanel || {};
    let panelContent;

    switch (formState) {
      case formStates.REVIEW:
        panelContent = (
          <RemoveLearningMaterialReview
            contextName={contextName}
            backButtonAction={closePanel}
            closeButtonAction={closePanel}
          />
        );
        break;
      case formStates.SUBMITTING:
        panelContent = (
          <PopoutPanelIconHeading
            type={types.LOADING}
            title={CMS.submitting_title}
            subtitle={CMS.submitting_subtitle}
          />
        );
        break;
      case formStates.CONFIRMATION:
        panelContent = (
          <RemoveLearningMaterialConfirmation
            closePanel={closePanel}
            onComplete={this._handleOnComplete}
            resultStatus={resultStatus}
            removedCount={removedCount}
            returnedCount={returnedCount}
            results={results}
            products={products}
          />
        );
        break;
      default:
        panelContent = (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }

    return <div>{panelContent}</div>;
  }
}

const contextName = (context, state) => {
  if (context === 'ORG') {
    return state.organisations.data[state.identity.currentOrganisationId].name;
  }
  if (context === 'CLASS') {
    return state.classrooms.data[state.classroomPage.classroomId].name;
  }
  if (context === 'USER') {
    return `${state.userProfile.firstName} ${state.userProfile.lastName}`;
  }
  return '';
};

RemoveLearningMaterial.propTypes = {
  contextName: PropTypes.string.isRequired,
  formState: PropTypes.string.isRequired,
  resultStatus: PropTypes.string.isRequired,
  removedCount: PropTypes.number,
  returnedCount: PropTypes.number,
  results: PropTypes.array.isRequired,
  products: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  clearAssignmentSelectionAction: PropTypes.func.isRequired,
  setPollOperationAction: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { context, results, assignments } = state.removeLearningMaterial;

    return {
      context,
      contextName: contextName(context, state),
      ...pick(state.removeLearningMaterial, ['formState', 'resultStatus', 'removedCount', 'returnedCount']),
      products: state.products.data,
      results: results.map(result => ({
        ...result,
        productId: (getAssignment(assignments, result.licenseId) || {}).productId
      }))
    };
  },
  {
    clearAssignmentSelectionAction: clearAssignmentSelection,
    setPollOperationAction: setPollOperation
  }
)(RemoveLearningMaterial);
