import { put, select } from 'redux-saga/effects';
import cmsContent from '../../../../../utils/cmsContent.js';
import { getCurrentPlatform } from '../../../../../utils/platform';
import { PARENT_PLATFORM } from '../../../../../globals/appConstants';
import { setSystemNotification, setReadOnlyMode } from '../../../../reducers/app.reducer';
import { featureIsEnabled } from '../../../../../globals/envSettings';

export default function* readOnlyMode() {
  const { currentOrganisationId } = yield select(state => state.identity);
  const { data: orgDetails } = yield select(state => state.organisations);

  // Notify the user that the current organization is in a BLOCKED state and the data may not be up-to-date
  if (currentOrganisationId && orgDetails && orgDetails[currentOrganisationId]) {
    const { isBlocked } = orgDetails[currentOrganisationId];

    if (featureIsEnabled('granular-platform-system-notifications')) {
      const platform = getCurrentPlatform();
      const isParentPlatform = PARENT_PLATFORM.includes(platform.toUpperCase());

      if (isParentPlatform) yield put(setReadOnlyMode(isBlocked));

      yield null;
    }

    if (isBlocked) {
      const notificationContent = cmsContent.systemNotification;
      const platformNotifications = PARENT_PLATFORM.map(system => ({
        platform: system.toLowerCase(),
        showNotification: true,
        message: notificationContent.org_read_only_notification
      }));
      yield put(setSystemNotification(platformNotifications));
    }
  }
}
