import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrganisationLozenge.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

function OrganisationLozenge({ firstColumn, secondColumn, rightColumn, isError = false }) {
  return (
    <div className={`${styles.lozengeContainer} ${isError ? styles.errorContainer : ''}`}>
      <div className={`${styles.columnItem} ${styles.firstColumn}`}>{firstColumn}</div>
      {secondColumn ? <div className={`${styles.columnItem} ${styles.secondColumn}`}>{secondColumn}</div> : null}
      {rightColumn ? (
        <div className={`${styles.columnItem} ${styles.rightColumn}`}>
          {rightColumn}
          {isError ? (
            <div className={styles.errorIconContainer}>
              <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

OrganisationLozenge.propTypes = {
  firstColumn: PropTypes.node.isRequired,
  secondColumn: PropTypes.node,
  rightColumn: PropTypes.node,
  isError: PropTypes.bool
};

export default OrganisationLozenge;
