import { TEST_BUILDER_CONSTANTS } from '@oup/shared-node-browser/testBuilder';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';

export default function getAdaptedTestStatusIcon(adaptedTestStatus) {
  switch (adaptedTestStatus) {
    case TEST_BUILDER_CONSTANTS.STATUSES.ERROR:
      return GLYPHS.ICON_LOCK_24;

    case TEST_BUILDER_CONSTANTS.STATUSES.INPROGRESS:
      return GLYPHS.ICON_LOADING;

    case TEST_BUILDER_CONSTANTS.STATUSES.SUCCESS:
    default:
      return GLYPHS.ICON_ASSESSMENT;
  }
}
