import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Home page components
import OteTopRow from './parts/OteTopRow/OteTopRow.js';
import OteBottomRow from './parts/OteBottomRow/OteBottomRow.js';
import OteSocialNetworkBar from './parts/OteSocialNetworkBar/OteSocialNetworkBar.js';

import styles from './OteHompage.scss';

import cmsContent from '../../utils/cmsContent.js';

export default class OteHomepage extends Component {
  constructor() {
    super();
    this.state = { CMS: {} };
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.oteHomepage || {} });
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.oteHomepage || {} });
  }

  render() {
    const { CMS } = this.state;

    return (
      <div className={styles.OteHomePageContainer}>
        <Helmet title={CMS.pageTitle} />
        <OteTopRow />
        <OteBottomRow />
        <OteSocialNetworkBar className={styles.socialNetworkBar} />
      </div>
    );
  }
}
