import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

import getUserPreferences from './getUserPreferences';
import setUserPreferences from './setUserPreferences';
import getTeacherResources from './getTeacherResources';
import getDownloadUrl from './getDownloadUrl';

function* root() {
  yield takeLatest(t.ORB_USER_PREFERENCES_REQUEST, ({ payload: { orgId, userId } }) =>
    getUserPreferences(orgId, userId)
  );
  yield takeLatest(t.ORB_TEACHER_RESOURCES_REQUEST, ({ payload: { orgId, locationId, isbns } }) =>
    getTeacherResources(orgId, locationId, isbns)
  );
  yield takeLatest(t.SET_ORB_USER_PREFERENCES_REQUEST, ({ payload: { orgId, userId, preferences } }) =>
    setUserPreferences(orgId, userId, preferences)
  );
  yield takeLatest(t.ORB_APP_DOWNLOAD_URL_REQUEST, ({ payload: { userId, os, arch } }) =>
    getDownloadUrl(userId, os, arch)
  );
}

export default root;
