import { put } from 'redux-saga/effects';
import { updateProductLocksSuccess, updateProductLocksError } from '../../../../actions/productLocks';
import updateProductLocksApi from '../../../apiCalls/product/updateProductLocksApi';

function* updateProductLocksGenerator(payload, params) {
  const response = yield updateProductLocksApi(payload, params);

  if (response.status === 'success') {
    yield put(updateProductLocksSuccess());
  } else {
    yield put(updateProductLocksError('Failed to update product locks'));
  }
}

export default updateProductLocksGenerator;
