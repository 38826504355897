import { put } from 'redux-saga/effects';
import getApiContentFetch from '../../../apiCalls/cptStructuredContentPlayer/getEreaderContent';
import { loadEreaderContentFailure, loadEreaderContentSuccess } from '../../../../actions/ereaderContentPlayer';

function* getEreaderContent(params) {
  const response = yield getApiContentFetch(params);

  if (response.statusCode === 200) {
    yield put(loadEreaderContentSuccess(response.data));
  } else {
    yield put(loadEreaderContentFailure('Failed to launch the eReader book'));
  }
}

export default getEreaderContent;
