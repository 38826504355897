import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setProductFinderFilters } from '../../redux/actions/productFinderActions';

import HubFilterBar from '../HubFilterBar/HubFilterBar.js';
import styles from './formStates/ProductFinderFormStates.scss';
import { orderStrings } from '../../globals/hubConstants.js';

function ProductFinderFilterBar({ levels, editions, filters, productFinderContent, setFiltersAction, breakpoint }) {
  const formatLevelText = text =>
    `${text.charAt(0).toUpperCase()}${text
      .slice(1)
      .toLowerCase()
      .replace(/_/g, '-')}`;

  const selectLatestEdition = () => {
    const latestEdition = [...editions.map(edition => edition?.toString())].sort().reverse()[0];
    setFiltersAction({ ...filters, edition: latestEdition });
  };

  const generateLevelOptions = () =>
    levels.map(level => ({
      checked: filters.level === level,
      id: level,
      name: `productFinder-${level.toLowerCase()}`,
      onChange: newLevel => setFiltersAction({ ...filters, level: newLevel }),
      text: formatLevelText(level),
      value: level
    }));

  const generateEditionOptions = () =>
    editions.map(edition => ({
      checked: filters.edition === edition,
      id: edition,
      name: `productFinder-${edition.toLowerCase()}`,
      onChange: newEdition => setFiltersAction({ ...filters, edition: newEdition }),
      text: productFinderContent[`${orderStrings[edition]}_edition`],
      value: edition
    }));

  useEffect(() => {
    if (editions?.length && !filters.edition) {
      selectLatestEdition();
    }
  }, [editions]);

  return (
    <>
      <HubFilterBar
        breakpoint={breakpoint}
        sortOptions={generateLevelOptions()}
        hasSortButton={false}
        sortLabel={productFinderContent.level}
        ariaControls="searchResults"
        customClassNameContainer={editions?.length ? styles.leftFilterContainer : ''}
      />
      {editions?.length > 1 && (
        <HubFilterBar
          breakpoint={breakpoint}
          sortOptions={generateEditionOptions()}
          hasSortButton={false}
          sortLabel={null}
          ariaControls="searchResults"
          customClassNameContainer={styles.rightFilterContainer}
        />
      )}
    </>
  );
}

ProductFinderFilterBar.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  setFiltersAction: PropTypes.func,
  levels: PropTypes.array,
  editions: PropTypes.array,
  filters: PropTypes.object,
  breakpoint: PropTypes.string
};

export default connect(
  state => ({
    filters: state.productFinder.filters
  }),
  {
    setFiltersAction: setProductFinderFilters
  }
)(ProductFinderFilterBar);
