import signedFetch from '../apiCalls/util/signedFetch.js';

// @ts-ignore
export const getOrgInvitesUrl = () => `${__API_BASE__}/user/invite`;
/**
 * Gets all group invites for the currently loggedin user
 * @return {Promise.<{data: {invites: object[]}, status: string}, {error}>}
 */
export default function* getOrgInvites() {
  return yield signedFetch('getOrgInvites', getOrgInvitesUrl(), 'GET');
}
