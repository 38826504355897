import React from 'react';
import styles from './LoadingIndicator.scss';

/** The component renders an animated loading indicator */
function LoadingIndicator() {
  return (
    <div className={styles.loadingIndicator}>
      <div />
      <div />
      <div />
    </div>
  );
}

export default LoadingIndicator;
