import getCourseLink from '../../HubDashboardLayout/Services/getCourseLink';

const hubSubSideNavbarConstants = {
  getSideNavbarItems: (courseId, content, isPreview = null) => [
    {
      name: content.course_material,
      path: `${getCourseLink(courseId, isPreview)}/courseMaterial`
    }
  ]
};

export default hubSubSideNavbarConstants;
