import * as t from '../actionTypes';

export const resetProductServices = () => ({
  type: t.RESET_GET_PRODUCT_SERVICES
});

export const getProductServicesRequest = parameters => ({
  type: t.GET_PRODUCT_SERVICES_REQUEST,
  payload: parameters
});

export const getProductServicesSuccess = results => ({
  type: t.GET_PRODUCT_SERVICES_SUCCESS,
  payload: results
});

export const getProductServicesFailure = errors => ({
  type: t.GET_PRODUCT_SERVICES_FAILURE,
  payload: errors
});
