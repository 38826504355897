/**
 * Helper to map an object.
 *
 * example:
 * const people = { John: { age: 42 }, Adam: { age: 39 } };
 * mapObject(people, (key, value) => ({ name: key, ...value }));
 *
 * return:
 * [ { name: 'John', age: 42 }, { name: 'Adam', age: 39 } ]
 */

export default function mapObject(obj, mapFn) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc.push(mapFn(key, value));
    return acc;
  }, []);
}
