import { put } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch';
import {
  generateUserNameForManagedUserSuccess,
  generateUserNameForManagedUserFailure
} from '../../../../actions/managedUserChangeUsername';
import { formatUsernameSegments } from '../../../../../utils/buildUsername';

const parseGeneratedUserName = userName => userName.split('_').pop();

function* generateUserNameForManagedUser(orgId, firstNameInput = '', lastNameInput = '') {
  const [firstName, lastName] = formatUsernameSegments(firstNameInput, lastNameInput);
  const response = yield signedFetch(
    'generateUserNameForManagedUser',
    `${__API_BASE__}/org/${orgId}/managed-user/generate-username`,
    'POST',
    { firstName, lastName }
  );

  try {
    const status = response.status.toLowerCase();
    if (status === 'success') {
      const userName = parseGeneratedUserName(response.data.userName);
      yield put(generateUserNameForManagedUserSuccess(userName));
    } else if (status === 'error') {
      yield put(generateUserNameForManagedUserFailure({ errors: { message: response.errorMessages } }));
    }
  } catch (e) {
    yield put(generateUserNameForManagedUserFailure({ errors: { message: e } }));
  }
}

export default generateUserNameForManagedUser;
