import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './AssessmentFallbackImgSmallCircle.scss';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';

function AssessmentFallbackImgSmallCircle({ className }) {
  return (
    <div className={classnames(styles.fallbackImage, className)}>
      <SVGIcon glyph={GLYPHS.OUP_ASSESSMENT_FALLBACK_IMG_SMALL_CIRCLE} />
    </div>
  );
}

export default AssessmentFallbackImgSmallCircle;

AssessmentFallbackImgSmallCircle.propTypes = {
  className: PropTypes.string
};
