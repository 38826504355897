import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getMatData from './getMatData';
import updateStatus from './updateStatus';

function* root() {
  yield takeLatest(t.MULTI_SCHOOL_DIAL_REPORT_REQUEST, ({ payload: { locationId, orgs } }) =>
    getMatData(orgs, locationId)
  );

  yield takeLatest(t.GRADEBOOK_SCHOOL_DIAL_REPORT_SUCCESS, () => updateStatus());
  yield takeLatest(t.GRADEBOOK_SCHOOL_DIAL_REPORT_FAILURE, () => updateStatus(false));
}

export default root;
