import React from 'react';
import PropTypes from 'prop-types';

// Component imports
import LinkWithIcon from '../../LinkWithIcon/LinkWithIcon.js';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon.js';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch.js';
import { getExpiresOnText, getLicencePeriodDetails } from '../../../utils/licences.js';
import { isLicenceExpiresEarly } from '../../../../sharedNodeBrowser/licenceHelper.js';
import Checkbox from '../../Checkbox/Checkbox';
import { PRODUCT_TARGET_USERTYPE } from '../../../../sharedNodeBrowser/constants';
import Tooltip from '../../Tooltip/Tooltip';
import styles from '../ProductFinder.scss';
import { isHubMode } from '../../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';
import Illustration from '../../Illustration/Illustration.js';

function ProductFinderAssignmentSummary({
  productFinderContent,
  shouldAssignLicences,
  assignLicenceToTeacher,
  hasLicencesAvailable,
  licencesTypes,
  setAssignLicencesAction,
  toggleView,
  licencesAvailableCount,
  usersNeedingLicenceCount,
  totalUserCount,
  usersWithLicenceCount,
  classId,
  setAssignLicenceToTeacherAction,
  disabledTeacherAssign,
  productTargetUsertype
}) {
  const showIncludeLicencesTeachers = !!classId && shouldAssignLicences && !productTargetUsertype;

  const viewLicensesMessage = () => {
    if (shouldAssignLicences && totalUserCount <= licencesAvailableCount) {
      return productFinderContent.getting_licences_text;
    }
    if (!shouldAssignLicences && totalUserCount <= licencesAvailableCount) {
      return productFinderContent.view_licences_text;
    }
    return productFinderContent.users_needing_licence_text;
  };

  const _renderLicencesAvailableView = () => (
    <div>
      {licencesTypes.length > 1 && (
        <div className={styles.jumbotron}>
          {isHubMode() ? (
            <Illustration
              illustrationSrc={HubIllustrationConstants.INFORMATION}
              illustrationAltText={HubIllustrationAltText.INFORMATION}
            />
          ) : (
            <div className={styles.mixedLicenceInfo}>
              <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
            </div>
          )}
          {productFinderContent.mixed_licence_type_text}
        </div>
      )}
      <table className={styles.licenseViewTable}>
        <thead>
          <tr>
            <th>{productFinderContent.licence_type_text}</th>
            <th>{productFinderContent.available_text}</th>
            <th>{productFinderContent.assign_text}</th>
          </tr>
        </thead>
        <tbody>
          {licencesTypes.map((licenceType, index) => (
            <tr key={index}>
              <td>
                <span className={styles.licencePeriodContainer}>
                  {getLicencePeriodDetails(productFinderContent, licenceType)}
                </span>
                <span className={styles.licenceStatusContainer}>
                  {isLicenceExpiresEarly(licenceType, true)
                    ? `${getExpiresOnText(productFinderContent, licenceType.licenceEndDate)}`
                    : ``}
                </span>
              </td>
              <td>
                <strong>{licenceType.availableCount}</strong>
              </td>
              <td>
                <strong>{licenceType.amountAssigning}</strong>
              </td>
            </tr>
          ))}
          {productTargetUsertype && (
            <tr>
              <td colSpan={3}>
                <span>
                  <strong>{`${productFinderContent.note_text}: `}</strong>
                  {`${
                    productTargetUsertype === PRODUCT_TARGET_USERTYPE.STUDENT
                      ? productFinderContent.assignment_not_available_teachers
                      : productFinderContent.assignment_not_available_students
                  }.`}
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  const _renderLicenceUnavailableView = () => (
    <div className={styles.unavailableContainer}>
      {isHubMode() ? (
        <Illustration
          illustrationSrc={HubIllustrationConstants.INFORMATION}
          illustrationAltText={HubIllustrationAltText.INFORMATION}
        />
      ) : (
        <div className={styles.unavailableSvgContainer}>
          <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
        </div>
      )}
      {!shouldAssignLicences && totalUserCount <= licencesAvailableCount ? (
        <div>
          <p className={styles.paragraph}>{productFinderContent.not_enough_licences_avalible_header}</p>
          <p>{productFinderContent.no_students_for_licences_body}</p>
        </div>
      ) : (
        <div>
          <p className={styles.paragraph}>{productFinderContent.not_enough_licences_avalible_header}</p>
          <p>{productFinderContent.not_enough_licences_body}</p>
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.assignmentSummary}>
      <div className={styles.displayFlexBox}>
        <ToggleSwitch
          value={shouldAssignLicences}
          onChange={hasLicencesAvailable ? setAssignLicencesAction : () => {}}
          label={productFinderContent.assign_licence_header}
          disabled={!hasLicencesAvailable || totalUserCount > licencesAvailableCount}
        />
        <Tooltip title={productFinderContent.org_licence_info_text} className={styles.largeTooltip}>
          <SVGIcon glyph={GLYPHS.ICON_HELP_CIRCLE} className={styles.questionIcon} />
        </Tooltip>
      </div>
      <div className={styles.licenseTypeView}>
        <div className={styles.licencesContainer}>
          {shouldAssignLicences && totalUserCount <= licencesAvailableCount
            ? _renderLicencesAvailableView()
            : _renderLicenceUnavailableView()}
          {!!shouldAssignLicences &&
            usersNeedingLicenceCount !== 0 &&
            usersNeedingLicenceCount !== totalUserCount &&
            usersWithLicenceCount !== 0 && (
              <div className={styles.informationContainer}>
                <div className={styles.informationInnerContainer}>
                  {isHubMode() ? (
                    <Illustration
                      illustrationSrc={HubIllustrationConstants.INFORMATION}
                      illustrationAltText={HubIllustrationAltText.INFORMATION}
                    />
                  ) : (
                    <div className={styles.availableSvgContainer}>
                      <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
                    </div>
                  )}

                  <div>
                    <p className={styles.licencesInUseContainer}>{productFinderContent.licences_in_use_title}</p>
                    <p className={styles.licencesInUseLabel}>{productFinderContent.licences_in_use_body}</p>
                  </div>
                </div>
              </div>
            )}
        </div>
        <hr />
        <div className={styles.linkContainer}>
          <LinkWithIcon
            id="viewLicensesToBeAssigned"
            text={viewLicensesMessage()}
            glyph={GLYPHS.ICON_RIGHT}
            action={toggleView}
          />
        </div>
      </div>
      {!!showIncludeLicencesTeachers && (
        <Checkbox
          id="include_licences_teachers"
          label={productFinderContent.include_licences_teachers}
          value={assignLicenceToTeacher}
          onChange={() => setAssignLicenceToTeacherAction(!assignLicenceToTeacher)}
          disabled={disabledTeacherAssign}
        />
      )}
    </div>
  );
}

ProductFinderAssignmentSummary.propTypes = {
  productFinderContent: PropTypes.object,
  shouldAssignLicences: PropTypes.bool,
  hasLicencesAvailable: PropTypes.bool,
  licencesTypes: PropTypes.array,
  setAssignLicencesAction: PropTypes.func,
  toggleView: PropTypes.func,
  licencesAvailableCount: PropTypes.number,
  usersNeedingLicenceCount: PropTypes.number,
  totalUserCount: PropTypes.number,
  usersWithLicenceCount: PropTypes.number,
  disabledTeacherAssign: PropTypes.bool,
  productTargetUsertype: PropTypes.string,
  classId: PropTypes.string,
  setAssignLicenceToTeacherAction: PropTypes.func,
  assignLicenceToTeacher: PropTypes.bool
};

export default ProductFinderAssignmentSummary;
