import { select, put, takeLatest } from 'redux-saga/effects';

import { SUBMIT_FORM, showConfirmation } from '../../../../reducers/user/licenceRecall.reducer.js';
import recallActivationCodeApi from '../../../apiCalls/licences/recallActivationCode.api.js';
import { getCurrentPlatform } from '../../../../../utils/platform';
import { pollOrgLicencesUpdated } from '../dataRecency/pollOrgLicences';

function* recallLicenceRequest() {
  console.log('[recallLicence Saga] Form submitted, getting state values...');
  const { orgId, userId, payload } = yield select(state => ({
    userId: state.licenceRecall.userId,
    orgId: state.licenceRecall.orgId,
    payload: {
      activationCode: state.licenceRecall.activationCode,
      assignmentId: state.licenceRecall.assignmentId,
      platformCode: getCurrentPlatform() || ''
    }
  }));

  console.log('[recallLicence Saga] Sending request...');
  const result = yield recallActivationCodeApi(orgId, userId, payload);
  const errorOccurred = result.status !== 'success';

  if (!errorOccurred && payload.activationCode) {
    yield pollOrgLicencesUpdated([payload.activationCode]);
  }

  console.log('[recallLicence Saga] Setting result in state');
  yield put(showConfirmation(!result || result.error || !result.status || result.status !== 'success'));
}

export default function* recallLicence() {
  yield takeLatest(SUBMIT_FORM, recallLicenceRequest);
}
