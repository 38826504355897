import { takeEvery, takeLatest, throttle } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import editUserAuth from './editUserAuth';
import editUserIdentity from './editUserIdentity';
import editUserInOrg from './editUserInOrg';
import unlinkSocialAccounts from './unlinkSocialAccounts';
import getSocialIdentities from './getSocialIdentities';
import acceptOlbTerms from './acceptOlbTerms';
import getUser from './getUser';
import validateEditUserInput from './validateEditUserInput';
import { updateSession } from '../../../preRender/refactor/auth/processAuth/universal/auth0.saga.js';
import editCurrentUserDetails from './editCurrentUserDetails.saga.js';
import checkIsEmailTaken from './checkIsEmailTaken.saga.js';

function* root() {
  yield takeEvery(t.EDIT_USER_VALIDATE_INPUT, ({ payload: { orgId, input } }) => validateEditUserInput(orgId, input));
  yield takeLatest(t.EDIT_USER_GET_DETAILS_REQUEST, ({ payload: { orgId, userId } }) => getUser(orgId, userId));
  yield takeLatest(t.EDIT_USER_SUCCESS, () => updateSession());
  yield takeLatest(t.EDIT_USER_IDENTITY_REQUEST, ({ payload: input }) => editUserIdentity(input));
  yield takeLatest(t.EDIT_USER_AUTH_REQUEST, ({ payload: input }) => editUserAuth(input));
  yield takeLatest(t.EDIT_USER_IN_ORG_REQUEST, ({ payload: { orgId, userId, input } }) =>
    editUserInOrg(orgId, userId, input)
  );
  yield takeLatest(t.EDIT_USER_UNLINK_SOCIAL_ACCOUNTS, ({ payload: { userId, input } }) =>
    unlinkSocialAccounts(userId, input)
  );
  yield takeLatest(t.EDIT_USER_GET_SOCIAL_IDENTITIES_REQUEST, () => getSocialIdentities());
  yield takeLatest(t.EDIT_USER_ACCEPT_OLB_TERMS, ({ payload: { userId } }) => acceptOlbTerms(userId));
  yield takeLatest(t.EDIT_CURRENT_USER_DETAILS_REQUEST, ({ payload: { newUserEmail } }) =>
    editCurrentUserDetails(newUserEmail)
  );
  yield throttle(500, t.IS_EMAIL_TAKEN_REQUEST, ({ payload: { email } }) => checkIsEmailTaken(email));
}

export default root;
