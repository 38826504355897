import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

import getAggregatedHomework from './getAggregatedHomework';
import getAggregatedMarks from './getAggregatedMarks';

function* root() {
  yield takeLatest(t.NGS_STUDENT_HOMEWORK_REQUEST, ({ payload }) => getAggregatedHomework(payload));

  yield takeLatest(t.NGS_STUDENT_MARKS_REQUEST, ({ payload: { orgId } }) => getAggregatedMarks(orgId));
}

export default root;
