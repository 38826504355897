import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Redux
import Button from '@oup/shared-front-end/src/components/Button';
import { launchUserImpersonation } from '../../../redux/actions/impersonateUser';
import { appNotReady } from '../../../redux/reducers/app.reducer';
// Components
import Dropdown from '../../../components/Dropdown/Dropdown';
// Styles
import styles from './UserImpersonationPage.scss';

function UserImpersonationLauncher({ content, org, user, launchUserImpersonationAction, appNotReadyAction }) {
  const [selectedPlatform, setSelectedPlatform] = useState(content.hub_platform_code);
  const { orgId = '', orgFriendlyId = '' } = org;
  const { userId = '' } = user;

  const handleImpersonationLaunch = () => {
    const payload = {
      userId,
      orgId,
      platformCode: selectedPlatform
    };

    appNotReadyAction();
    launchUserImpersonationAction(payload);
  };

  return (
    <div className={`row ${styles.launcherContainer}`}>
      <div className={`col-sm ${styles.launchDropdown}`}>
        <Dropdown
          id={`impersonateUserPage-choosePlatform-${orgFriendlyId}`}
          name={`impersonateUserPage-choosePlatform-${orgFriendlyId}`}
          label=""
          labelHidden
          value={selectedPlatform}
          options={[
            { value: content.hub_platform_code, text: content.hub_platform_label },
            { value: content.ces_platform_code, text: content.ces_platform_label },
            { value: content.oic_platform_code, text: content.oic_platform_label }
          ]}
          onChange={value => setSelectedPlatform(value)}
        />
      </div>

      <div className={`col-sm ${styles.launchButton}`}>
        <Button variant="transparent" text={content.launch_button_label} onClick={handleImpersonationLaunch} />
      </div>
    </div>
  );
}

UserImpersonationLauncher.propTypes = {
  content: PropTypes.object.isRequired,
  org: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  launchUserImpersonationAction: PropTypes.func.isRequired,
  appNotReadyAction: PropTypes.func
};

export default connect(() => {}, {
  launchUserImpersonationAction: launchUserImpersonation,
  appNotReadyAction: appNotReady
})(UserImpersonationLauncher);
