import PropTypes from 'prop-types';
import React from 'react';
import ActionList, { Action } from '../../components/ActionList/ActionList';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';
import PopoutPanelListHeading from '../../components/PopoutPanelListHeading/PopoutPanelListHeading';
import ManagedUserPasswordReset from '../../components/ManagedUserPasswordReset/ManagedUserPasswordReset';

function PasswordResetPanel({
  content,
  items,
  onPasswordBlur,
  errors = {},
  onGeneratePassword,
  isAutoFill = false,
  isDisabled = false,
  onDone
}) {
  const errorInForm = Object.values(errors).filter(e => e.password);
  const emptyFields = items.filter(item => item.password.length === 0);
  const isErrorFound = !errorInForm.length && !emptyFields.length;

  return (
    <ScrollContainer
      headerContent={
        <header className="gin-top2 gin-bot2">
          <h2>{content.password_reset_title}</h2>
          <div className="gin-top1">
            {(content.password_reset_detail || '').replace('{numberOfUsers}', items.length)}
          </div>
          <div className="gin-top1">
            <a href={content.find_out_more_link_url} target="_blank" id="find-out-more" rel="noreferrer">
              {content.find_out_more_link_text}
            </a>
          </div>
        </header>
      }
      footerContent={
        <ActionList>
          <div className="gin-top2">
            <Action label={content.password_reset_button_text} disabled={!isErrorFound} primary onClick={onDone} />
          </div>
        </ActionList>
      }
    >
      <div className="gin2" style={{ fontWeight: 700 }}>
        <ToggleSwitch
          id="checkBoxDisabled"
          label={content.password_reset_toggle_text}
          value={isAutoFill}
          onChange={onGeneratePassword}
        />
      </div>
      <PopoutPanelListHeading text={content.password_reser_subhead} />
      {items.map((item, i) => (
        <ManagedUserPasswordReset
          key={i}
          item={item}
          index={i}
          label={content.password_reset_label}
          onBlur={onPasswordBlur}
          errors={errors}
          isDisabled={isDisabled}
        />
      ))}
    </ScrollContainer>
  );
}

PasswordResetPanel.propTypes = {
  content: PropTypes.object,
  items: PropTypes.array,
  onPasswordBlur: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onGeneratePassword: PropTypes.func.isRequired,
  isAutoFill: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

export default PasswordResetPanel;
