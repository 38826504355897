import { call } from 'redux-saga/effects';

import { parse as parseQueryString } from 'query-string';

import envSettings from '../../../../../../globals/envSettings';

// eslint-disable-next-line import/no-cycle
import store from '../../../../../store';
import { postNotificationMessage } from '../../../../../actions/postMessage';
import listenForCommands from '../../../../messaging/listenForCommands.saga';
import getCredentialsFromMessage from '../../../auth/getCredentials/getCredentialsFromMessage.saga';

export default function* processAuthEmbedded() {
  console.groupCollapsed('Embedded');

  const {
    origin: embeddedParentOrigin = 'no-origin-passed-by-parent',
    'view-id': embeddedViewId = 'view_9999'
  } = parseQueryString(window.location.search);

  // when embedded, it is possible for the outer window to be local, which can affect the origin path
  const parentWindowIsLocal = embeddedParentOrigin.indexOf('localhost') === 0;

  // we assume localhost is always running without https - if ever needed we need to pass that via query too
  const parentOriginWithProtocol = `http${parentWindowIsLocal ? '' : 's'}://${embeddedParentOrigin}`;

  envSettings.embeddedViewId = embeddedViewId;
  envSettings.topLevelOrigin = parentOriginWithProtocol;

  console.log('[EMBED] embedded view ID is', envSettings.embeddedViewId);
  console.log('[EMBED] top level origin is', envSettings.topLevelOrigin);

  // notify top frame that the embedded app is waiting for credentials
  store.dispatch(postNotificationMessage({ eventName: 'APP_WAITING_FOR_CREDS', viewId: envSettings.embeddedViewId }));

  listenForCommands();
  console.log('[EMBED] running embedded - require credentials to continue');
  localStorage.setItem('embed-creds-status', 'pending');
  yield call(getCredentialsFromMessage);

  console.groupEnd();

  return {
    isSignIn: true
  };
}
