import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

import getLibrary from './getLibrary';
import updateMyCourses from './updateMyCourses';

function* root() {
  yield takeLatest(t.NGS_LIBRARY_REQUEST, ({ payload: { orgId, region } }) => getLibrary(orgId, region));

  yield takeLatest(t.NGS_UPDATE_MY_COURSES_REQUEST, ({ payload: { orgId, courseIds } }) =>
    updateMyCourses(orgId, courseIds)
  );
}

export default root;
