import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import Button, { buttonTypes } from '../Button/Button';
import Card from '../Card/Card';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';

function RegisterOrgAdminSuccess({
  localizedContent: { registerOrgAdminSuccessComponent: content },
  firstName,
  lastName,
  email,
  primaryButtonLabel = 'Primary',
  secondaryButtonLabel = 'Secondary',
  onPrimaryButtonClick = () => {},
  onSecondaryButtonClick = () => {}
}) {
  return (
    <div>
      <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.VALID} title={content.title} />
      <p className="lead text-center gin-bot3">{content.subtitle}</p>
      <div className="gin-bot3">
        <Card headingText={content.header} headingColor={colors.PROFILE}>
          <CopyToClipboard label={content.firstname_label} value={firstName} />
          <CopyToClipboard label={content.lastname_label} value={lastName} />
          <CopyToClipboard label={content.email_label} value={email} />
        </Card>
      </div>
      <div className="row cols-center">
        <span className="gin-right2">
          <Button type={buttonTypes.PRIMARY} text={primaryButtonLabel} onClick={onPrimaryButtonClick} />
        </span>
        <span className="gin-right2">
          <Button type={buttonTypes.SECONDARY} text={secondaryButtonLabel} onClick={onSecondaryButtonClick} />
        </span>
      </div>
    </div>
  );
}

RegisterOrgAdminSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func
};

export default withLocalizedContent('registerOrgAdminSuccessComponent')(RegisterOrgAdminSuccess);
