import { parse as parseQueryString } from 'query-string';
import { PLATFORMS } from '@oup/shared-node-browser/constants';
import envSettings, { isLocal } from '../globals/envSettings';
import { EMBEDDABLE_PLATFORM } from '../globals/appConstants';

/**
 * Obtains platform url from envSettings via platformCode
 * @param {string} platformCode
 *
 * @returns {string} url for given platform
 */
export const getPlatformUrl = (platformCode = '') => {
  const platforms = envSettings.integrations || {};
  const defaultUrl = `${window.location.protocol}//${window.location.host}`;
  const platform = platforms[platformCode.toLowerCase()] || {};
  return isLocal() ? defaultUrl : platform.baseUrl || defaultUrl;
};

/**
 * Obtains platform code that matches given platform url in envSettings
 * @param {string} url
 *
 * @returns {string|undefined} platform code if found - ie: 'ORB'
 */
export const getPlatformCode = url => {
  const platforms = envSettings.integrations || {};
  const result = Object.keys(platforms)
    .filter(platformCode => platforms[platformCode].baseUrl === url)
    .reduce((acc, platformCode) => ({ ...acc, platformCode: platformCode.toUpperCase() }), {});
  // @ts-ignore
  return result.platformCode;
};

export const isEmbeddedInIframe = () => {
  try {
    // window.frameWindow throws Access Denied in AppSaga in Edge (see https://stackoverflow.com/a/18678703)
    return window.parent !== window || window.self !== window.top || window.frameElement !== null;
  } catch (e) {
    return false;
  }
};
/**
 * Returns the URL of parent that has currently embedded the APP.
 *
 * @returns {string|null} URL of parent window.
 */
export const getParentUrl = () => {
  let parentUrl = null;

  if (isEmbeddedInIframe()) {
    parentUrl = document.referrer;
  }

  return parentUrl;
};

/**
 * Returns the platform that has currently embedded the APP using parent window's url.
 *
 * @returns {string|undefined} Uppercased platform code if found - ie: 'ORB'
 */
export const embeddedByPlatform = () => {
  const parentUrl = getParentUrl() || '';
  const [protocol, url = ''] = parentUrl.split(/\/\//);
  const platformBaseUrl = `${protocol}//${url.split('/')[0]}`;
  return getPlatformCode(platformBaseUrl);
};

export const epsPlatformOrb = 'orb';
export const epsPlatformDefault = 'ces';
export const epsPlatformEltHub = 'hub';
export const epsPlatformOte = 'ote';
export const epsPlatformOic = 'oic';
export const epsPlatformOpt = 'opt';
export const epsPlatformRvs = 'rvs';
export const epsPlatformOcp = 'ocp';
export const epsPlatformSmart = 'smart';
export const epsPlatformOlb = 'olb';
export const epsPlatformInsp = 'insp';
export const epsPlatformOlbOffline = 'olb_offline';
export const epsProductionDefaultHostname = 'account.oup.com';
const validEpsPlatforms = [
  epsPlatformDefault,
  epsPlatformOrb,
  epsPlatformEltHub,
  epsPlatformOte,
  epsPlatformOic,
  epsPlatformOpt,
  epsPlatformSmart,
  epsPlatformRvs,
  epsPlatformOcp,
  epsPlatformInsp
];

export const epsDefaultDomainRegExp = /\baccount\.oup\.com/i;
export const orbInEpsDomainRegExp = /\boxfordreadingbuddy\.oup\.com/i;
export const eltHubDomainRegExpA = /\bhub\.edu\.oup\.com/i;
export const eltHubDomainRegExpB = /\benglishhub\.oup\.com/i;
export const ltiDomainRegExp = /\blti\.englishhub\.oup\.com/i;
export const eltOteDomainRegExp = /\boxfordtestofenglish\.oup\.com/i;
export const eltOicDomainRegExp = /\boic\.edu\.oup\.com/i;
export const eltOisaDomainRegExp = /\boisa\.oup\.com/i;
export const eltOptDomainRegExp = /\bplacement\.edu\.oup\.com/i;
export const eltRvsDomainRegExp = /\bresultsverification\.oup\.com/i;
export const eltOcpDomainRegExp = /\boffline\.edu\.oup\.com/i;
export const smartDomainRegExp = /\bsmart\.edu\.oup\.com/i;
export const inspDomainRegExp = /\bonlineinspection\.edu\.oup\.com/i;
const localhostDomainRegExp = /\blocalhost/i;
const eltHubLocalPortRegExp =
  // @ts-ignore
  typeof localHubPort !== 'undefined' && localHubPort ? new RegExp(`\b${localHubPort}`, 'i') : null;
const epsOrbLocalPortRegExp =
  // @ts-ignore
  typeof localOrbPort !== 'undefined' && localOrbPort ? new RegExp(`\b${localOrbPort}`, 'i') : null;
const epsOteLocalPortRegExp =
  // @ts-ignore
  typeof localOtePort !== 'undefined' && localOtePort ? new RegExp(`\b${localOtePort}`, 'i') : null;

const epsOicLocalPortRegExp =
  // @ts-ignore
  typeof localOicPort !== 'undefined' && localOicPort ? new RegExp(`\b${localOicPort}`, 'i') : null;
const epsOptLocalPortRegExp =
  // @ts-ignore
  typeof localOptPort !== 'undefined' && localOptPort ? new RegExp(`\b${localOptPort}`, 'i') : null;
const epsRvsLocalPortRegExp =
  // @ts-ignore
  typeof localRvsPort !== 'undefined' && localRvsPort ? new RegExp(`\b${localRvsPort}`, 'i') : null;
const epsOcpLocalPortRegExp =
  // @ts-ignore
  typeof localOcpPort !== 'undefined' && localOcpPort ? new RegExp(`\b${localOcpPort}`, 'i') : null;
const epsSmartLocalPortRegExp =
  // @ts-ignore
  typeof localSmartPort !== 'undefined' && localSmartPort ? new RegExp(`\b${localSmartPort}`, 'i') : null;
const epsInspLocalPortRegExp =
  // @ts-ignore
  typeof localInspPort !== 'undefined' && localInspPort ? new RegExp(`\b${localInspPort}`, 'i') : null;
const TOP_LEVEL_PLATFORM = 'eps-platform-code';

export const isOnDefaultEpsDomain = () => epsDefaultDomainRegExp.exec(window.location.hostname);
export const isOnEpsOrbDomain = () => orbInEpsDomainRegExp.exec(window.location.hostname);
export const isOnEltHubDomain = () =>
  eltHubDomainRegExpA.exec(window.location.hostname) || eltHubDomainRegExpB.exec(window.location.hostname);
export const isOnLtiDomain = () => ltiDomainRegExp.exec(window.location.hostname);
export const isOnEpsOteDomain = () => eltOteDomainRegExp.exec(window.location.hostname);
export const isOnEpsOicDomain = () =>
  eltOicDomainRegExp.exec(window.location.hostname) || eltOisaDomainRegExp.exec(window.location.hostname);
export const isOnEpsOptDomain = () => eltOptDomainRegExp.exec(window.location.hostname);
export const isOnEpsRvsDomain = () => eltRvsDomainRegExp.exec(window.location.hostname);
export const isOnEpsOcpDomain = () => eltOcpDomainRegExp.exec(window.location.hostname);
export const isOnEpsSmartDomain = () => smartDomainRegExp.exec(window.location.hostname);
export const isOnEpsInspDomain = () => inspDomainRegExp.exec(window.location.hostname);

const OPT_IN_LTI = 'opt-in-lti';
export const optInLti = () => localStorage.getItem(OPT_IN_LTI) === 'true';

const EPS_PLATFORM_MODE = 'eps-platform-mode';
const epsPlatFormModeIs = epsPlatformMode => localStorage.getItem(EPS_PLATFORM_MODE) === epsPlatformMode;

const isOnLocalEltHubDomain = () =>
  localhostDomainRegExp.exec(window.location.hostname) &&
  eltHubLocalPortRegExp &&
  eltHubLocalPortRegExp.exec(window.location.port);
const isOnLocalEpsOrbDomain = () =>
  localhostDomainRegExp.exec(window.location.hostname) &&
  epsOrbLocalPortRegExp &&
  epsOrbLocalPortRegExp.exec(window.location.port);
const isOnLocalEpsOteDomain = () =>
  localhostDomainRegExp.exec(window.location.hostname) &&
  epsOteLocalPortRegExp &&
  epsOteLocalPortRegExp.exec(window.location.port);
const isOnLocalEpsOicDomain = () =>
  localhostDomainRegExp.exec(window.location.hostname) &&
  epsOicLocalPortRegExp &&
  epsOicLocalPortRegExp.exec(window.location.port);
const isOnLocalEpsOptDomain = () =>
  localhostDomainRegExp.exec(window.location.hostname) &&
  epsOptLocalPortRegExp &&
  epsOptLocalPortRegExp.exec(window.location.port);
const isOnLocalEpsRvsDomain = () =>
  localhostDomainRegExp.exec(window.location.hostname) &&
  epsRvsLocalPortRegExp &&
  epsRvsLocalPortRegExp.exec(window.location.port);
const isOnLocalEpsOcpDomain = () =>
  localhostDomainRegExp.exec(window.location.hostname) &&
  epsOcpLocalPortRegExp &&
  epsOcpLocalPortRegExp.exec(window.location.port);
const isOnLocalSmartDomain = () =>
  localhostDomainRegExp.exec(window.location.hostname) &&
  epsSmartLocalPortRegExp &&
  epsSmartLocalPortRegExp.exec(window.location.port);
const isOnLocalInspDomain = () =>
  localhostDomainRegExp.exec(window.location.hostname) &&
  epsInspLocalPortRegExp &&
  epsInspLocalPortRegExp.exec(window.location.port);
export const topLevelPlatformIs = platformCode => {
  if (isOnEpsOrbDomain() || isOnLocalEpsOrbDomain()) {
    // if we are on oxfordreadingbuddy.oup.com, always use "orb" mode
    return platformCode === epsPlatformOrb;
  }
  if (isOnEltHubDomain() || isOnLocalEltHubDomain()) {
    // if we are on hub.edu.oup.com, englishhub.oup.com or lti.englishhub.oup.com, always use "hub" mode
    return platformCode === epsPlatformEltHub;
  }
  if (isOnEpsOteDomain() || isOnLocalEpsOteDomain()) {
    // if we are on oxfordtestofenglish.oup.com, always use "ote" mode
    return platformCode === epsPlatformOte;
  }
  if (isOnEpsOptDomain() || isOnLocalEpsOptDomain()) {
    // if we are on placement.edu.oup.com, always use "opt" mode
    return platformCode === epsPlatformOpt;
  }
  if (isOnEpsRvsDomain() || isOnLocalEpsRvsDomain()) {
    // if we are on oxfordtestofenglish.oup.com/verificationService, always use "rvs" mode
    return platformCode === epsPlatformRvs;
  }
  if (isOnEpsOcpDomain() || isOnLocalEpsOcpDomain()) {
    // if we are on ocp.oup.com, always use "ocp" mode
    return platformCode === epsPlatformOcp;
  }
  if (isOnEpsSmartDomain() || isOnLocalSmartDomain()) {
    return platformCode === epsPlatformSmart;
  }
  if (isOnEpsOicDomain() || isOnLocalEpsOicDomain()) {
    // if we are on oxfordtestofenglish.oup.com, always use "oic" mode
    return platformCode === epsPlatformOic;
  }
  if (isOnEpsInspDomain() || isOnLocalInspDomain()) {
    // if we are on onlineinspection.oup.com, always use "insp" mode
    return platformCode === epsPlatformInsp;
  }

  const localStoragePlatformCode = localStorage.getItem(TOP_LEVEL_PLATFORM);

  // when on account.oup.com non-PROD, allow localStorage to set the platformCode (for testing)
  const canSetPlatformCode = isLocal() || isOnDefaultEpsDomain();
  if (canSetPlatformCode && localStoragePlatformCode) {
    if (validEpsPlatforms.indexOf(localStoragePlatformCode) === -1) {
      console.warn('Unknown EPS platform code', localStoragePlatformCode);
    }

    return platformCode === localStorage.getItem(TOP_LEVEL_PLATFORM);
  }

  return platformCode === epsPlatformDefault;
};

export const isHeadlessPage = () => /premium\/resources/.test(window.location.href);

// original ORB "Teacher in Unity"
export const isOrbInUnityFrame = () => embeddedByPlatform() === 'ORB';

export const isOrbMode = () => {
  if (topLevelPlatformIs('orb')) {
    return true;
  }
  if (isOrbInUnityFrame()) {
    // legacy "iframe" on Unity
    return true;
  }
  return false;
};

export const isOlbOdsMode = () => navigator && /OxfordLearnersBookshelf/.test(navigator.userAgent);

export const isLtiMode = () => !!(isOnLtiDomain() || epsPlatFormModeIs('lti'));

export const isHubMode = () => topLevelPlatformIs('hub');

export const isOteMode = () => topLevelPlatformIs('ote');

export const isOicMode = () => topLevelPlatformIs('oic');

export const isOptMode = () => topLevelPlatformIs('opt');

export const isRvsMode = () => topLevelPlatformIs('rvs');

export const isOcpMode = () => topLevelPlatformIs('ocp');

export const isEltAssessmentMode = () => isOteMode() || isOptMode();

export const isSmartMode = () => topLevelPlatformIs('smart');

export const isEbookSampleMode = () => topLevelPlatformIs('insp');

export const isMflMode = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('product') === 'mfl' || /mfl/.test(window.location.pathname);
};

export const isCesMode = () => {
  if (isOrbMode()) {
    return false;
  }
  if (isEbookSampleMode()) {
    return false;
  }
  if (isHubMode()) {
    return false;
  }
  if (isOteMode()) {
    return false;
  }
  if (isOicMode()) {
    return false;
  }
  if (isOptMode()) {
    return false;
  }
  if (isRvsMode()) {
    return false;
  }
  if (isOcpMode()) {
    return false;
  }
  if (isSmartMode()) {
    return false;
  }
  if (topLevelPlatformIs('ces')) {
    return true;
  }

  console.info('Cannot determine platform mode, defaulting to CES');
  return true;
};

export const getCurrentPlatform = () => {
  if (isOrbMode()) {
    return epsPlatformOrb;
  }
  if (isHubMode()) {
    return epsPlatformEltHub;
  }
  if (isOteMode()) {
    return epsPlatformOte;
  }
  if (isOicMode()) {
    return epsPlatformOic;
  }
  if (isOptMode()) {
    return epsPlatformOpt;
  }
  if (isRvsMode()) {
    return epsPlatformRvs;
  }
  if (isOcpMode()) {
    return epsPlatformOcp;
  }
  if (isSmartMode()) {
    return epsPlatformSmart;
  }
  if (isEbookSampleMode()) {
    return epsPlatformInsp;
  }
  if (isOlbOdsMode()) {
    // for the CES pages loaded in OLB ODS we should keep the CES platform specific options
    if (isCesMode()) {
      return epsPlatformDefault;
    }

    return epsPlatformOlbOffline;
  }

  return epsPlatformDefault;
};

export const getPlatformFromQueryString = () => {
  const { providerId = '', platform = '' } = parseQueryString(window.location.search);

  return (decodeURIComponent(providerId) || decodeURIComponent(platform)).toLowerCase();
};

// at the moment everything is ELT related except ORB but this may change
export const useEltBranding = () => !isOrbMode();

export const updateProductMetadataFromQuery = queryOptions => {
  // As oup admin want to change orb student level, that's why product metadata is required for ces.
  if (topLevelPlatformIs('orb') || topLevelPlatformIs('ces')) {
    // in practice this will always be `{"orb":{"subject":"english"}}` because subject feature is not enabled yet
    const defaultMetadata = { orb: { subject: 'english' } };
    localStorage.setItem('product-metadata', JSON.stringify(defaultMetadata));
    return;
  }

  if (!isOrbInUnityFrame()) {
    return;
  }

  const platformCode = 'orb';
  const prefix = `x-product-${platformCode}-`;
  const keys = Object.keys(queryOptions).filter(key => key.startsWith(`x-product-${platformCode}-`));

  const metadata = JSON.parse(localStorage.getItem('product-metadata') || '{}');
  if (!metadata[platformCode]) metadata[platformCode] = {};

  keys.forEach(key => {
    metadata[platformCode][key.replace(prefix, '')] = queryOptions[key];
  });

  localStorage.setItem('product-metadata', JSON.stringify(metadata));
};

export const getProductMetadata = (platformCode, key) => {
  const metadata = JSON.parse(localStorage.getItem('product-metadata') || '{}');
  return (metadata[platformCode.toLowerCase()] || {})[key];
};

export const topLevelPlatformUsesLicences = () => isOrbMode();

export const getPlatformByCodeOrDomain = (platformCode = '') => {
  if (platformCode === PLATFORMS.OLB) {
    return PLATFORMS.OLB;
  }
  if (platformCode === PLATFORMS.ORB) {
    return PLATFORMS.ORB;
  }
  if (platformCode === PLATFORMS.HUB) {
    return PLATFORMS.HUB;
  }
  if (platformCode === PLATFORMS.INSP) {
    return PLATFORMS.INSP;
  }
  if (platformCode === PLATFORMS.OTE) {
    return PLATFORMS.OTE;
  }
  if (platformCode === PLATFORMS.OIC) {
    return PLATFORMS.OIC;
  }
  if (platformCode === PLATFORMS.OPT) {
    return PLATFORMS.OPT;
  }
  if (platformCode === PLATFORMS.RVS) {
    return PLATFORMS.RVS;
  }
  if (platformCode === PLATFORMS.OCP) {
    return PLATFORMS.OCP;
  }
  if (platformCode === PLATFORMS.SMART) {
    return PLATFORMS.SMART;
  }

  if (isHubMode()) {
    return PLATFORMS.HUB;
  }
  if (isEbookSampleMode()) {
    return PLATFORMS.INSP;
  }
  if (isOrbMode()) {
    return PLATFORMS.ORB;
  }
  if (isOteMode()) {
    return PLATFORMS.OTE;
  }
  if (isOicMode()) {
    return PLATFORMS.OIC;
  }
  if (isOptMode()) {
    return PLATFORMS.OPT;
  }
  if (isRvsMode()) {
    return PLATFORMS.RVS;
  }
  if (isOcpMode()) {
    return PLATFORMS.OCP;
  }
  if (isSmartMode()) {
    return PLATFORMS.SMART;
  }
  return PLATFORMS.CES;
};

export const isContentPreviewMode = () => {
  const contentPreviewRegexp = /\/launch\/(teacher|student)\//;
  return contentPreviewRegexp.exec(window.location.pathname);
};

export const isEmbeddablePlatform = platform => EMBEDDABLE_PLATFORM.includes(platform);

export default {
  getPlatformUrl,
  getPlatformCode,
  isEmbeddedInIframe,
  embeddedByPlatform,
  getProductMetadata,
  updateProductMetadataFromQuery
};
