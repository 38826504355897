import { put } from 'redux-saga/effects';
import getPlacementTests from '../../../apiCalls/hub/getPlacementTests';
import { loadPlacementTestsSuccess, loadPlacementTestsFailure } from '../../../../actions/placementTests';

const groupPlacementTestsByStatus = (placementTests, sortDraftsByName) => {
  const newGroupedTests = {
    DRAFT: [],
    ACTIVE: [],
    UPCOMING: [],
    COMPLETED: []
  };

  placementTests.forEach(test => {
    const status = test.configurations.CONFIGURATION_PLACEMENT_TEST.status;
    newGroupedTests[status].push(test);
  });
  const combinedActiveUpcoming = [...newGroupedTests.ACTIVE, ...newGroupedTests.UPCOMING];
  return {
    DRAFT: sortDraftsByName
      ? newGroupedTests.DRAFT.sort((a, b) =>
          a.classGroup.className.toLowerCase() > b.classGroup.className.toLowerCase() ? 1 : -1
        )
      : newGroupedTests.DRAFT,
    ACTIVE_UPCOMING: combinedActiveUpcoming,
    COMPLETED: newGroupedTests.COMPLETED
  };
};

function* loadPlacementTests(payload) {
  const { orgId, sortBy, status, sortDraftsByName, groupPlacementTests = true } = payload;

  const response = yield getPlacementTests({ orgId, sortBy, status });
  if (response.status === 'success') {
    const groupedData = groupPlacementTests
      ? groupPlacementTestsByStatus(response.data, sortDraftsByName)
      : response.data;
    yield put(loadPlacementTestsSuccess(groupedData));
  } else {
    yield put(loadPlacementTestsFailure(response));
  }
}

export default loadPlacementTests;
