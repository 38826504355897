import * as t from '../../actionTypes';
import getUserLicencesStatusData from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/getUserLicencesStatusData';
import { licenceStatus as LICENCE_STATUS } from '../../../globals/hubConstants.js';

const FILTER_VALUES = {
  NOT_STARTED: 'notStarted',
  ACTIVE: 'active',
  EXPIRING_SOON: 'expiringSoon',
  EXPIRED: 'expired',
  NO_LICENCE: 'noLicence'
};

const initialFilters = [
  FILTER_VALUES.NOT_STARTED,
  FILTER_VALUES.ACTIVE,
  FILTER_VALUES.EXPIRING_SOON,
  FILTER_VALUES.NO_LICENCE
];

const initialState = {
  data: {},
  initialData: {},
  isDataSet: false,
  courseId: '',
  assessment: {},
  shouldAssessmentShow: true,
  filters: initialFilters
};

const getLicenceStatus = product => {
  const licenceDetails = product?.hasLicence ? { hasLicence: true, expiryDate: product.expiryDate } : {};
  if (product?.notStartedLicence) {
    licenceDetails.licenceNotStartedDetails = product.licenceNotStartedDetails;
  }
  const statusDetails = getUserLicencesStatusData(licenceDetails);
  return statusDetails.status;
};

const shouldBeAdded = (filters, licenceStatus) => {
  const { EXPIRED, NO_LICENCE, ACTIVE, EXPIRING, NOT_STARTED } = LICENCE_STATUS;
  switch (true) {
    case filters.indexOf(FILTER_VALUES.NOT_STARTED) !== -1 && licenceStatus === NOT_STARTED:
      return true;
    case filters.indexOf(FILTER_VALUES.ACTIVE) !== -1 && licenceStatus === ACTIVE:
      return true;
    case filters.indexOf(FILTER_VALUES.EXPIRING_SOON) !== -1 && licenceStatus === EXPIRING:
      return true;
    case filters.indexOf(FILTER_VALUES.EXPIRED) !== -1 && licenceStatus === EXPIRED:
      return true;
    case filters.indexOf(FILTER_VALUES.NO_LICENCE) !== -1 && licenceStatus === NO_LICENCE:
      return true;
    default:
      return false;
  }
};

export const filterAssessment = (assessment, filters) => {
  const licenceStatus = getLicenceStatus(assessment);
  if (shouldBeAdded(filters, licenceStatus)) {
    return true;
  }
  return false;
};

const filterProducts = (initialData, filters) => {
  const filteredData = {};
  const filteredIsbns = [];
  if (filters.length === 0) {
    return initialData;
  }
  const isbns = Object.keys(initialData);
  for (let i = 0; i < isbns.length; i += 1) {
    const product = initialData[isbns[i]];
    const licenceStatus = getLicenceStatus(product);
    if (filteredIsbns.indexOf(isbns[i]) === -1 && shouldBeAdded(filters, licenceStatus)) {
      filteredIsbns.push(isbns[i]);
    }
  }
  for (let i = 0; i < filteredIsbns.length; i += 1) {
    filteredData[filteredIsbns[i]] = initialData[filteredIsbns[i]];
  }
  return filteredData;
};

function hubCourseMaterialFilters(state = initialState, { type, filters, payload }) {
  switch (type) {
    case t.HUB_GET_FILTERED_COURSE_MATERIALS:
      return {
        ...state,
        filters,
        shouldAssessmentShow:
          Object.keys(state.assessment).length !== 0 ? filterAssessment(state.assessment, filters) : false,
        data: filterProducts(state.initialData, filters)
      };
    case t.HUB_RESET_FILTERS_COURSE_MATERIALS:
      return {
        ...initialState,
        initialData: state.initialData,
        assessment: state.assessment,
        shouldAssessmentShow:
          Object.keys(state.assessment).length !== 0 ? filterAssessment(state.assessment, initialFilters) : false,
        ...payload,
        data: filterProducts(state.initialData, initialFilters)
      };
    case t.HUB_SET_UNFILTERED_COURSE_MATERIALS:
      return {
        ...state,
        data: filterProducts(payload.initialData, initialFilters),
        isDataSet: payload.isDataSet,
        courseId: payload.courseId,
        initialData: payload.initialData,
        filters: state.filters,
        assessment: payload.assessment || {},
        shouldAssessmentShow: payload.shouldAssessmentShow
      };
    default:
      return state;
  }
}
export default hubCourseMaterialFilters;
