import { put, take } from 'redux-saga/effects';
import { ACCEPT_COOKIE_AGREEMENT, showCookieAgreement } from '../../reducers/app.reducer.js';
import session from '../../../utils/session';

export default function* cookieConsent() {
  const cookiesAccepted = localStorage.getItem('cookiesAccepted');
  const sessionExists = session.exists();

  if (!cookiesAccepted && !sessionExists) {
    yield put(showCookieAgreement());

    // eslint-disable-next-line no-unreachable-loop
    while (true) {
      yield take(ACCEPT_COOKIE_AGREEMENT);
      localStorage.setItem('cookiesAccepted', 'true');
      break;
    }
  }
}
