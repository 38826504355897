import { omit } from 'lodash';
import * as t from '../../actionTypes';

// eslint-disable-next-line max-len
const URL_REGEX = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
const ID_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9:_-]*$/;

export const formStates = {
  INPUTTING: 'INPUTTING',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  ARCHIVE: 'ARCHIVE'
};

export const context = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  EDIT_CONFIG: 'EDIT_CONFIG',
  ARCHIVE: 'ARCHIVE'
};

export const initialState = {
  formState: formStates.INPUTTING,
  context: context.ADD,
  oxfordLTIConfig: false,
  isFormValid: false,
  formData: {
    iss: '',
    publicKeysetUrl: '',
    accessTokenUrl: '',
    authenticationRequestUrl: '',
    clientId: '',
    deploymentId: '',
    displayName: ''
  },
  invalidFields: []
};
const isFieldValueValid = (field, value) => {
  if (!value) return false;
  if (field === 'displayName') return true;

  return ['deploymentId', 'clientId'].includes(field) ? ID_REGEX.test(value) : URL_REGEX.test(value);
};
const getInvalidFields = (invalidFields = [], field, value) => {
  if (!isFieldValueValid(field, value)) {
    if (!invalidFields.includes(field)) invalidFields.push(field);
  } else {
    const fieldIndex = invalidFields.indexOf(field);
    if (fieldIndex !== -1) invalidFields.splice(fieldIndex, 1);
  }

  return invalidFields;
};

const isFormValid = (mode, fields, dataFields, invalidFields) =>
  Object.keys(fields).every(key => !!dataFields[key]) && invalidFields.length === 0;

export default function createLMSConfig(state = initialState, action) {
  switch (action.type) {
    case t.LMS_CONFIG_TOGGLE_OXFORD_LTI:
      return {
        ...state,
        oxfordLTIConfig: !state.oxfordLTIConfig
      };

    case t.LMS_CONFIG_SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.field]: action.value || ''
        }
      };
    case t.LMS_CONFIG_DEPLOYMENT_VALIDATE_INPUT:
      return {
        ...state,
        invalidFields: getInvalidFields(state.invalidFields, action.field, state.formData[action.field])
      };
    case t.LMS_CONFIG_CHECK_IF_FORM_IS_VALID:
      return {
        ...state,
        isFormValid: isFormValid(state.context, action.fields, state.formData, state.invalidFields)
      };
    case t.CREATE_LMS_CONFIG_DEPLOYMENT_REQUEST:
      return {
        ...state,
        formState: formStates.LOADING
      };
    case t.CREATE_LMS_CONFIG_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        formState: formStates.SUCCESS
      };
    case t.CREATE_LMS_CONFIG_DEPLOYMENT_FAILURE:
      return {
        ...state,
        formState: formStates.ERROR,
        error: action.payload
      };
    case t.LMS_CONFIG_TOOL_EDIT:
      return {
        ...state,
        formState: formStates.INPUTTING,
        context: context.EDIT,
        formData: {
          ...omit(action.tool, ['tool']),
          ...action.tool.tool
        }
      };
    case t.LMS_CONFIG_TOOL_ARCHIVE:
      return {
        ...state,
        formState: formStates.ARCHIVE,
        formData: {
          ...omit(action.tool, ['tool']),
          ...action.tool.tool
        }
      };
    case t.DELETE_LMS_CONFIG_DEPLOYMENT_REQUEST:
      return {
        ...state,
        formState: formStates.LOADING,
        context: context.ARCHIVE
      };
    case t.DELETE_LMS_CONFIG_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        formState: formStates.SUCCESS
      };
    case t.DELETE_LMS_CONFIG_DEPLOYMENT_FAILURE:
      return {
        ...state,
        formState: formStates.ERROR
      };
    case t.LMS_CONFIG_TOOL_RESET:
      return {
        ...initialState,
        formState: formStates.INPUTTING,
        invalidFields: [],
        isFormValid: false
      };
    case t.GET_LMS_ORG_CONFIG:
      return {
        ...initialState,
        formData: {
          ...initialState.formData,
          ...action.payload
        }
      };
    case t.LMS_CONFIG_TOGGLE_ORG_CONFIG_EDIT:
      return {
        ...state,
        context: action.initialFormState || context.EDIT_CONFIG
      };
    default:
      return state;
  }
}
