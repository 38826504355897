import { put } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { getSubscriptionIdSuccess, getSubscriptionIdFailure } from '../../../../actions/inspectionCopy';

export default function* getSubsciptionId() {
  const response = yield signedFetch('generateEbookSampleToken', `${__API_BASE__}/ebook-sample/generate-token`, 'POST');

  if (response.status === 'success') {
    return yield put(getSubscriptionIdSuccess(response));
  }

  return yield put(getSubscriptionIdFailure({ errors: response.message }));
}
