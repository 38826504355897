import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import { closeEditMyAccountModal } from '../../../redux/actions/hubUi';
import styles from './SupportArticlePage.scss';
import filterBy from '../../../utils/array/filterBy.js';
// import parseMarkdown from '../../../utils/markdown/markdown.js';
import SupportPageHeader from '../../../components/SupportPageHeader/SupportPageHeader.js';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import MyProfileEditPanel from '../../../panels/MyProfileEditPanel';
import parseMarkdown from '../../../utils/markdown/markdown.js';
import gtmPageview from '../../../utils/gtm/pageview.js';
import { isHubMode } from '../../../utils/platform';

// Special componenents for rendering CMS Support Article content blocks:
import ArticleLink from '../../../components/ArticleLink/ArticleLink.js';
import ArticleTable from '../../../components/ArticleTable/ArticleTable.js';
import ArticleImages from '../../../components/ArticleImages/ArticleImages.js';
import ArticleVideos from '../../../components/ArticleVideos/ArticleVideos.js';
import ArticleParagraph from '../../../components/ArticleParagraph/ArticleParagraph.js';
import ArticleBulletList from '../../../components/ArticleBulletList/ArticleBulletList.js';
import ArticleThumbnailLinkList from '../../../components/ArticleThumbnailLinkList/ArticleThumbnailLinkList.js';

import { initForm as fetchArticles } from '../../../redux/reducers/support/supportSearch.reducer.js';

import cmsContent from '../../../utils/cmsContent.js';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';

const getComponentForContentBlock = {
  'oup:articlelink': ArticleLink,
  'oup:articleimg': ArticleImages,
  'oup:articletable': ArticleTable,
  'oup:articlevideos': ArticleVideos,
  'oup:articleparagraph': ArticleParagraph,
  'oup:articlebulletlist': ArticleBulletList,
  'oup:articlethumbnaillinklist': ArticleThumbnailLinkList
};

class SupportArticlePage extends Component {
  componentWillMount() {
    const { articles, fetchArticlesAction } = this.props;
    // Fetch details of all articles so we'll have something to search:
    if (!articles) {
      fetchArticlesAction();
    }
  }

  static getPageName(title) {
    return escape(
      title
        .toString()
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^A-Za-z0-9_-]/g, '')
    );
  }

  render() {
    const {
      match: { params },
      articles,
      hubEditAccountModalOpen,
      hubCloseEditMyAccountModal,
      isMfl
    } = this.props;

    const CMS = cmsContent.supportPage || {};
    const pageName = params?.pageName || (isMfl && params?.tabName);
    const pageNameEscaped = SupportArticlePage.getPageName(pageName);
    const article = Object.values(articles || {}).find(filterBy({ pageName: pageNameEscaped }));
    const notFound = articles && !article;
    const enableCmsArticles = false;
    const content = article && article.content ? article.content : '';
    const markedOptions = { sanitize: false }; // do not sanitize this trusted content that intentionally can contain HTML (including, theoretically script)
    const contentAsHtml = article && article.format === 'markdown' ? parseMarkdown(content, markedOptions) : content;
    const articlesWithoutButton = ['terms-and-conditions', 'activate'];
    const isWithoutButton = articlesWithoutButton.some(elem => elem === pageName);

    if (notFound) {
      // Render "not found" message:
      return (
        <div>
          <Helmet title={CMS.pageNotFound_title} />
          <SupportPageHeader
            title={CMS.pageNotFound_title}
            backButtonTo="/support"
            backButtonText={CMS.button_back_text}
            aria-hidden="true"
          />
          <div className="grid pad-top2 pad-bot2">
            <a href="/support">{CMS.pageNotFound_return_home_text}</a>
          </div>
        </div>
      );
    }
    return (
      <div>
        {!article ? (
          // Show spinner until loaded:
          <PopoutPanelIconHeading
            type={types.LOADING}
            title={CMS.loading_article_heading}
            subtitle={CMS.loading_subheading}
          />
        ) : (
          <article>
            <Helmet
              title={`${article.title} | ${CMS.page_title}`}
              onChangeClientState={newState => gtmPageview(newState.title)}
            />

            {!isMfl && (
              <SupportPageHeader
                title={article.title || CMS.page_title}
                glyph={GLYPHS.ICON_HELP_AND_SUPPORT}
                backButtonTo="/support"
                backButtonText={isWithoutButton ? null : CMS.button_back_text}
              />
            )}

            <div className={classnames('grid', styles.supportArticle)}>
              <div className="row">
                <div className="col sm8">
                  {/* this uses named content types defined in Cloud CMS to render components and is currently disabled */}
                  {enableCmsArticles &&
                    article.content.map(section => {
                      const Tag = getComponentForContentBlock[section._type];

                      return Tag ? (
                        <Tag key={section._qname} {...section} />
                      ) : (
                        <div key={section._qname}>
                          [<em>MISSING COMPONENT</em> for {section._type} "{section.title}"]
                        </div>
                      );
                    })}

                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: contentAsHtml }} />
                </div>
              </div>
            </div>
          </article>
        )}
        {isHubMode() ? (
          <MyProfileEditPanel isOpen={hubEditAccountModalOpen} closePanel={hubCloseEditMyAccountModal} />
        ) : null}
      </div>
    );
  }
}

SupportArticlePage.propTypes = {
  match: PropTypes.object,
  articles: PropTypes.object,
  fetchArticlesAction: PropTypes.func.isRequired,
  hubEditAccountModalOpen: PropTypes.bool,
  isMfl: PropTypes.bool,
  hubCloseEditMyAccountModal: PropTypes.func
};

export default connect(
  ({ supportArticles: state, hubUi: { editAccountModalOpen } }) => ({
    articles: state.data,
    hubEditAccountModalOpen: editAccountModalOpen
  }),
  { fetchArticlesAction: fetchArticles, hubCloseEditMyAccountModal: closeEditMyAccountModal }
)(withRouter(SupportArticlePage));
