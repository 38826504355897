import * as t from '../actionTypes';

export const getCoursePreviewRequest = filters => ({
  type: t.GET_COURSE_PREVIEW_REQUEST,
  payload: filters
});

export const getCoursePreviewSuccess = data => ({
  type: t.GET_COURSE_PREVIEW_SUCCESS,
  payload: data
});

export const getCoursePreviewFailure = () => ({
  type: t.GET_COURSE_PREVIEW_FAILURE
});

export const openCoursePreviewModal = () => ({
  type: t.OPEN_COURSE_PREVIEW_MODAL
});

export const closeCoursePreviewModal = () => ({
  type: t.CLOSE_COURSE_PREVIEW_MODAL
});

export const setCourseModalCloseLink = link => ({
  type: t.SET_COURSE_MODAL_CLOSE_LINK,
  payload: link
});

export const loadCourseContentRequest = data => ({
  type: t.LOAD_COURSE_CONTENT_REQUEST,
  payload: data
});

export const loadCourseContentSuccess = data => ({
  type: t.LOAD_COURSE_CONTENT_SUCCESS,
  payload: data
});

export const loadCourseContentFailure = data => ({
  type: t.LOAD_COURSE_CONTENT_FAILURE,
  payload: data
});
