import * as t from '../actionTypes';

export const getActivityRequest = body => ({
  type: t.GET_ACTIVITY_REQUEST,
  payload: body
});

export const getActivitySuccess = activity => ({
  type: t.GET_ACTIVITY_SUCCESS,
  payload: activity
});

export const getActivityFailure = () => ({
  type: t.GET_ACTIVITY_FAILURE
});
