/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { courseResourceIsTeacherOnly } from '@oup/shared-node-browser/course.js';

// Redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Heading from '@oup/shared-front-end/src/components/Heading';
import { hubDownloadResource, changeResourceView } from '../../../redux/actions/hubResource';
// Style
import styles from './ResourceHome.scss';
// Components
import ResourceFolder from './ResourceFolder/ResourceFolder';
import Resource from '../../../components/Resource/Resource';
import ResourceModal from '../../../components/ResourceModal/ResourceModal';
import ResourceFormHidden from './ResourceFormHidden';
// Services
import getResourceLink from '../Services/getResourceLink';
import getCourseLevel from '../../HubDashboardLayout/Services/getCourseLevel';
import { hasFoldersWithStudentResources } from '../Services/filterResources';
// Constants
import { ResourceConstants, HubLayoutConstants, COURSE_FOLDERS } from '../../../globals/hubConstants';
import withBreakpoint from '../../../decorators/withBreakpoint';
import { hasHideableKeywords, hasLockableKeywords } from '../../HubCourseAssessments/Utils/isLockedNode';
import { featureIsEnabled } from '../../../globals/envSettings';
import { updateRecentlyAccessedRequest } from '../../../redux/actions/recentlyAccessed';
import getSingleCoursePath from '../Services/getSingleCoursePath';

function ResourceHome({
  match,
  courses,
  courseId,
  downloadResource,
  downloadIsLoading,
  breakpoint,
  hubContent,
  handleResourceView,
  updateRecentlyAccessedAction,
  viewerType,
  resourceIdsDownloading,
  isPreview = false
}) {
  const [resourceId, setResourceId] = useState('');
  const [isDownloadable, setIsDownloadable] = useState(true);

  const { params } = match;
  const { folder } = params;

  const getTitle = camelCaseTitle => camelCaseTitle.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

  useEffect(() => {
    const pathnames = HubLayoutConstants.PATH_NAMES;
    if (courses[courseId]) {
      updateRecentlyAccessedAction({
        url: `${getSingleCoursePath(courseId)}${pathnames.RESOURCE_FOLDER}/${folder}${pathnames.RESOURCE_HOME}`,
        title: getTitle(folder),
        subtitle: courses[courseId].title,
        type: 'resource'
      });
    }
  }, [courses, match]);

  const getTitleMenuItem = resourceFolderName => {
    if (resourceFolderName === COURSE_FOLDERS.GET_STARTED) return hubContent.get_started;
    if (resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT) return hubContent.professional_development;
    return resourceFolderName;
  };

  const getTitlePage = (resourceFolderName, courseTitle, courseProperties) => {
    if (resourceFolderName === COURSE_FOLDERS.GET_STARTED) return hubContent.get_started;
    if (resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT) return hubContent.professional_development;
    return `${courseTitle} ${getCourseLevel(courseProperties)} ${getTitleMenuItem(resourceFolderName)}`;
  };
  const getHeadingPage = resourceFolderName => {
    switch (resourceFolderName) {
      case COURSE_FOLDERS.GET_STARTED:
        return hubContent.get_started_heading;
      case COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT:
        return hubContent.professional_development_heading;
      case COURSE_FOLDERS.TESTS:
        return hubContent.tests_heading;
      default:
        return hubContent.resources;
    }
  };

  const isTeacherOnlyFolder = resourceFolderName => {
    if (resourceFolderName === COURSE_FOLDERS.GET_STARTED) return true;
    if (resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT) return true;

    return false;
  };

  const _closeModal = () => {
    setResourceId('');
  };

  const _downloadResource = resource => {
    downloadResource({ ...resource }, 'download');
  };

  const _openResource = url => e => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, '_blank');
  };

  const _openModal = (id, downloadable) => {
    setResourceId(id);
    setIsDownloadable(downloadable);

    const resources = courses?.[params.courseId]?.resources;

    const { PREVIEWABLE_FORMATS } = ResourceConstants;
    if (PREVIEWABLE_FORMATS.includes((resources[id].format || '').toLowerCase())) {
      downloadResource({ ...resources[id] }, 'play');
    }
  };

  const _renderFolders = courseUserRole => {
    const resourceFolders = courses?.[courseId]?.resourceFolders?.[params.folder];
    const resources = courses?.[courseId]?.resources;

    if (courses && resourceFolders) {
      return Object.entries(resourceFolders).reduce((result, [key, value]) => {
        if (
          key === 'resources' ||
          (courseUserRole === HubLayoutConstants.COURSE_USER_ROLES.TEACHER &&
            viewerType === HubLayoutConstants.COURSE_USER_ROLES.STUDENT &&
            !hasFoldersWithStudentResources(value, resources))
        ) {
          return result;
        }
        result.push(
          <ResourceFolder
            key={key}
            title={value.title}
            itemLink={`${getResourceLink(courseId, params.folder, isPreview)}/${key}`}
          />
        );
        return result;
      }, []);
    }

    return null;
  };

  const _handleResourceView = userView => {
    handleResourceView(userView);
  };

  const _renderViewButton = () => (
    <div className={styles.classworkButtons}>
      <button
        type="button"
        className={viewerType === HubLayoutConstants.COURSE_USER_ROLES.TEACHER ? styles.active : styles.inactive}
        onClick={() => _handleResourceView(HubLayoutConstants.COURSE_USER_ROLES.TEACHER)}
      >
        {hubContent.classwork_resource_teacher_view}
      </button>
      <button
        type="button"
        className={viewerType === HubLayoutConstants.COURSE_USER_ROLES.STUDENT ? styles.active : styles.inactive}
        onClick={() => _handleResourceView(HubLayoutConstants.COURSE_USER_ROLES.STUDENT)}
      >
        {hubContent.classwork_resource_student_view}
      </button>
    </div>
  );

  const _renderResources = (resources, folderResourceIds, hasLicence, isExpired, startedLicence) => {
    const filteredResourceIds =
      viewerType === HubLayoutConstants.COURSE_USER_ROLES.STUDENT
        ? folderResourceIds.filter(id => !courseResourceIsTeacherOnly(resources[id]))
        : folderResourceIds;

    return filteredResourceIds.map(
      id =>
        !hasHideableKeywords(resources[id].keywords) && (
          <Resource
            key={id}
            resourceId={id}
            resource={resources[id]}
            downloadResource={_downloadResource}
            openResource={_openResource}
            openModal={_openModal}
            hubContent={hubContent}
            downloadIsLoading={downloadIsLoading}
            hasLicence={hasLicence}
            isExpired={isExpired || (resources[id].keywords && hasLockableKeywords(resources[id].keywords))}
            breakpoint={breakpoint}
            startedLicence={startedLicence}
            resourceIdsDownloading={resourceIdsDownloading}
          />
        )
    );
  };

  const resourceFolders = get(courses, [courseId, 'resourceFolders', params.folder], {});
  const courseProperties = get(courses, [courseId, 'properties'], {});
  const courseTitle = get(courses, [courseId, 'title']);
  const courseUserRole = get(courses, [courseId, 'userRole'], 'student');
  const resources = get(courses, [courseId, 'resources']);

  const pageTitle = courseTitle
    ? `${courseTitle} ${getCourseLevel(courseProperties)} | ${getTitleMenuItem(params.folder)}`
    : 'Loading';

  const course = get(courses, [params.courseId]);
  const courseData = get(course, ['products', 'data']);

  const courseDataValues = courseData && Object.values(courseData);
  const hasLicence = courseDataValues && courseDataValues.filter(product => product.hasLicence).length > 0;
  const isExpired =
    courseDataValues &&
    courseDataValues.every(product => {
      const date = product.expiryDate;
      const productIsExpired = date && moment(date).isValid() && moment(date).isBefore(moment());
      return hasLicence && productIsExpired;
    });

  const startedLicence =
    courseDataValues &&
    courseDataValues.filter(product => {
      const date = product.expiryDate;
      const productIsExpired = date && moment(date).isValid() && moment(date).isBefore(moment());
      const productHasLicence = product.hasLicence;
      return !product.notStartedLicence && !productIsExpired && productHasLicence;
    }).length > 0;

  const hasOnlyResources = Object.keys(resourceFolders).every(subFolder => subFolder === 'resources');
  const title = getTitlePage(params.folder, courseTitle, courseProperties);

  const showHeading = featureIsEnabled('navigation-changes');
  const headingArgs = {
    text: getHeadingPage(params.folder),
    size: 'small',
    variant: 'h1'
  };
  return resourceFolders && courseTitle ? (
    <div>
      <Helmet title={pageTitle} />
      {showHeading && <Heading {...headingArgs} />}
      {courseUserRole === HubLayoutConstants.COURSE_USER_ROLES.TEACHER &&
        !isTeacherOnlyFolder(params.folder) &&
        _renderViewButton()}
      {!showHeading && <div className={styles.resourceHomeHeader}>{title}</div>}
      <div>
        <div>
          {resourceFolders?.resources
            ? _renderResources(resources, resourceFolders.resources, hasLicence, isExpired, startedLicence)
            : null}
        </div>
        {!hasOnlyResources ? (
          <div className={styles.resourceFolderTitle}>{hubContent.assessment_home_assessment_folders}</div>
        ) : null}
        <div className={styles.resourceHomeFolderContainer}>{_renderFolders(courseUserRole)}</div>
      </div>
      <ResourceFormHidden />
      <ResourceModal
        breakpoint={breakpoint}
        resourceId={resourceId}
        closeModal={_closeModal}
        downloadResource={isDownloadable ? _downloadResource : null}
        resource={resourceId ? resources[resourceId] : {}}
        hubContent={hubContent}
        downloadIsLoading={downloadIsLoading}
      />
    </div>
  ) : (
    <div />
  );
}

ResourceHome.propTypes = {
  match: PropTypes.object,
  courses: PropTypes.object,
  courseId: PropTypes.string,
  downloadResource: PropTypes.func.isRequired,
  downloadIsLoading: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string,
  hubContent: PropTypes.object,
  handleResourceView: PropTypes.func,
  updateRecentlyAccessedAction: PropTypes.func,
  viewerType: PropTypes.string,
  resourceIdsDownloading: PropTypes.object,
  isPreview: PropTypes.bool
};

const mapStateToProps = state => ({
  downloadIsLoading: state.hubResourceDownload?.downloadIsLoading,
  resourceIdsDownloading: state.hubResourceDownload?.resourceIdsDownloading,
  viewerType: state.teacherResources.viewerType
});

const mapDispatchToProps = dispatch => ({
  downloadResource: (resource, resourceAction) => {
    dispatch(hubDownloadResource(resource, resourceAction));
  },
  handleResourceView: viewerType => {
    dispatch(changeResourceView(viewerType));
  },
  updateRecentlyAccessedAction: payload => {
    dispatch(updateRecentlyAccessedRequest(payload));
  }
});

export default compose(withBreakpoint, connect(mapStateToProps, mapDispatchToProps))(ResourceHome);
