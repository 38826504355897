import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ToggleSwitch, { toggleTypes } from '../ToggleSwitch/ToggleSwitch';
import Dropdown from '../Dropdown/Dropdown';
import styles from './RecordListingControls.scss';
import { GRADEBOOK_TYPE } from '../../globals/appConstants';
import { featureIsEnabled } from '../../globals/envSettings';
import SpacingOptions, { THEMES as SPACING_THEMES } from '../SpacingOptions/SpacingOptions';
import FilterByScore from './FilterByScore';

function RecordListingControls({
  usePercentages,
  useCompletedOnly,
  onUsePercentagesOnChange,
  onUseCompletedOnlyOnChange,
  attemptFilter,
  attemptFilterOptions,
  onAttemptFilterOnChange,
  tableSpacing,
  tableSpacingOnChange,
  localizedContent: CONTENT,
  rangeValue,
  rangeValueOnchange,
  useFilterByScore,
  onUseFilterByScoreOnChange,
  hideFilterByScore = false,
  isProgressPage = false,
  isMyProgress = false,
  productType
}) {
  return (
    <div className={classnames('grid', styles.grid)}>
      {featureIsEnabled('olb-gradebook-score-all-activities') ? (
        <div
          className={classnames('row', styles.RecordListingControls, {
            [`${styles.assessment}`]: productType === GRADEBOOK_TYPE.ASSESSMENT
          })}
        >
          <div className="col md3 sm6 order-md1 gin-bot2">
            <ToggleSwitch
              id="usePercentages"
              type={toggleTypes.STRING_TOGGLE}
              labelBefore={CONTENT.show_figures_as}
              stringOn={CONTENT.fractions}
              stringOff={CONTENT.percentages}
              value={usePercentages}
              onChange={onUsePercentagesOnChange}
              blockLabelBefore
            />
          </div>
          <div className="col md2 sm6 order-md3 gin-bot2">
            {tableSpacing && (
              <SpacingOptions
                onSwitch={tableSpacingOnChange}
                selected={tableSpacing}
                customClassName={styles.centerSpacingOptions}
                setTheme={SPACING_THEMES.WHITE}
              />
            )}
          </div>
          {!isMyProgress && productType !== GRADEBOOK_TYPE.ASSESSMENT && !isProgressPage && (
            <div className="col md4 sm6 order-md2 gin-bot2">
              <ToggleSwitch
                id="useCompletedOnly"
                type={toggleTypes.STRING_TOGGLE}
                labelBefore={CONTENT.show_scores_out_of}
                stringOn={CONTENT.all_activities}
                stringOff={CONTENT.completed_only}
                value={useCompletedOnly}
                onChange={onUseCompletedOnlyOnChange}
                blockLabelBefore
              />
            </div>
          )}
          {!isProgressPage && productType !== GRADEBOOK_TYPE.ASSESSMENT && (
            <div className="col md3 sm6 order-md4 gin-bot2">
              <Dropdown
                id="selectOne"
                label={CONTENT.scores}
                name="student-attempts"
                options={attemptFilterOptions}
                value={attemptFilter}
                onChange={onAttemptFilterOnChange}
                inline
              />
            </div>
          )}
        </div>
      ) : (
        <div className={classnames('row', styles.RecordListingControls)}>
          <div className="col sm4 gin-bot2">
            <ToggleSwitch
              id="usePercentages"
              type={toggleTypes.STRING_TOGGLE}
              labelBefore="Show completion as"
              stringOn="fraction"
              stringOff="percentage"
              value={usePercentages}
              onChange={onUsePercentagesOnChange}
            />
          </div>
          {tableSpacing && (
            <div className="col sm4 gin-bot2">
              <SpacingOptions
                onSwitch={tableSpacingOnChange}
                selected={tableSpacing}
                customClassName={styles.centerSpacingOptions}
                label={CONTENT.spacing_option_gradebook_label}
                setTheme={SPACING_THEMES.WHITE}
              />
            </div>
          )}
          <div className="col sm4 gin-bot2">
            <Dropdown
              id="selectOne"
              label="Student attempts:"
              name="student-attempts"
              options={attemptFilterOptions}
              value={attemptFilter}
              onChange={onAttemptFilterOnChange}
              inline
            />
          </div>
        </div>
      )}
      {featureIsEnabled('olb-gradebook-student-answer-view') && !hideFilterByScore && (
        <FilterByScore
          rangeValue={rangeValue}
          rangeValueOnchange={rangeValueOnchange}
          useFilterByScore={useFilterByScore}
          onUseFilterByScoreOnChange={onUseFilterByScoreOnChange}
        />
      )}
    </div>
  );
}

RecordListingControls.propTypes = {
  usePercentages: PropTypes.bool.isRequired,
  useCompletedOnly: PropTypes.bool.isRequired,
  onUsePercentagesOnChange: PropTypes.func.isRequired,
  onUseCompletedOnlyOnChange: PropTypes.func.isRequired,
  attemptFilter: PropTypes.string,
  attemptFilterOptions: PropTypes.array,
  onAttemptFilterOnChange: PropTypes.func,
  tableSpacing: PropTypes.string,
  tableSpacingOnChange: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  rangeValue: PropTypes.string.isRequired,
  rangeValueOnchange: PropTypes.func.isRequired,
  useFilterByScore: PropTypes.bool.isRequired,
  hideFilterByScore: PropTypes.bool,
  isProgressPage: PropTypes.bool,
  onUseFilterByScoreOnChange: PropTypes.func.isRequired,
  isMyProgress: PropTypes.bool,
  productType: PropTypes.string
};

export default RecordListingControls;
