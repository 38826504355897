import PersistentStorage from './storage/Persistent';

/**
 * Store data about a user that needs to persist and should be available before calling identity.
 */

const UserInfo = {
  role: null, // Current role
  organisationId: null // Current organisation id
};

class User {
  constructor() {
    this._storage = new PersistentStorage('user-info');
  }

  set(userInfo = UserInfo) {
    const saveUserInfo = {};

    Object.keys(UserInfo).forEach(key => {
      saveUserInfo[key] = userInfo[key];
    });

    this._storage.set(saveUserInfo);
  }

  get() {
    return { ...UserInfo, ...this._storage.get() };
  }

  clear() {
    this._storage.clear();
  }
}

export default new User();
