import PropTypes from 'prop-types';
import React from 'react';
import ListPageControls from '../ListPageControls/ListPageControls';
import SubSectionSkeletonLoader from './SubSectionSkeletonLoader';
import ClassOrgLoader from './ClassOrgLoader';
import colors from '../../globals/colors';

function SkeletonLoader({ loadingResults, tabName, panelName }) {
  return (
    <div>
      <ClassOrgLoader speed={2} foregroundColor={colors.COLOR_GREY_DISABLED} backgroundColor={colors.COLOR_WHITE} />
      <ListPageControls showSkeletonLoader={loadingResults} />
      <SubSectionSkeletonLoader
        tabName={tabName}
        panelName={panelName}
        speed={2}
        foregroundColor={colors.COLOR_GREY_DISABLED}
        backgroundColor={colors.COLOR_WHITE}
      />
    </div>
  );
}

SkeletonLoader.propTypes = {
  loadingResults: PropTypes.bool.isRequired,
  tabName: PropTypes.string,
  panelName: PropTypes.string
};

export default SkeletonLoader;
