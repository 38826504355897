import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import parseMarkdown from '../../utils/markdown/markdown';
import ErrorStatus from '../../components/ErrorStatus/ErrorStatus.js';

import FormController from '../CreateOrganisation/formUtil/FormController.js';

import ChangeUsernameForm from './ChangeUsernameForm.js';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PreAuthConfirmationPage from '../Register/PreAuthConfirmationPage';
import { setFormState } from '../../redux/reducers/data/forms.reducer.js';

import cmsContent from '../../utils/cmsContent.js';

const isRegisterPage = false;
function ChangeUsernamePage({ submitFormAction }) {
  const CMS = cmsContent.changeUsername || {};
  const location = useLocation();

  const { username, providerId, 'cognito-id': cognitoId } = Object.fromEntries(
    new URLSearchParams(location.search || {})
  );
  const helpAndSupportUrl = `/support/contact-us${providerId ? `?platform=${providerId}` : ''}`;

  return (
    <div>
      <Helmet title={CMS.tabTitle} />
      <FormController
        formName="requestChangeUsername"
        initialFormState="INPUTTING"
        formStateMapping={{
          INPUTTING: (
            <ChangeUsernameForm
              username={username}
              providerId={providerId}
              cognitoId={cognitoId}
              helpAndSupportUrl={helpAndSupportUrl}
            />
          ),
          SUBMITTING: (
            <div className="pad-top8 pad-bot8">
              <PopoutPanelIconHeading type={types.LOADING} title={CMS.loading_title_text} />
            </div>
          ),
          LEGACY_MESSAGE: (
            <div className="grid">
              <div className="row">
                <div className="col sm2" />
                <div className="col md8">
                  <div className="pad-top8 pad-bot8">
                    {/* ERROR: User is legacy and has no email address */}
                    <PopoutPanelIconHeading type={types.WARNING} title={CMS.error_no_email_address_text} />
                    {/* eslint-disable react/no-danger */}
                    <p
                      dangerouslySetInnerHTML={{ __html: parseMarkdown(CMS.error_contact_customer_support_text) }}
                      className="pad3"
                      style={{ textAlign: 'center' }}
                    />
                  </div>
                </div>
                <div className="col sm2" />
              </div>
            </div>
          ),
          CONFIRMATION: (
            <PreAuthConfirmationPage platform={providerId} isRegister={isRegisterPage} location={location} />
          ),
          FAILED: (
            <ErrorStatus
              title={CMS.error_title}
              buttonText={CMS.error_button}
              buttonOnClickHandler={submitFormAction}
            />
          )
        }}
      />
    </div>
  );
}

ChangeUsernamePage.propTypes = {
  submitFormAction: PropTypes.func.isRequired
};

export default connect(null, dispatch => ({
  submitFormAction: () => dispatch(setFormState('requestChangeUsername', 'INPUTTING'))
}))(ChangeUsernamePage);
