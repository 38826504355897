// eslint-disable-next-line import/prefer-default-export
export const inspLayoutConstants = {
  PATH_NAMES: {
    START_PAGE: '/books',
    REGISTER_PAGE: '/register'
  }
};

export const inspJobTitles = {
  options: [
    'Biology Teacher',
    'Cambridge Coordinator',
    'Chemistry Teacher',
    'Computing Coordinator',
    'Computing Teacher',
    'Coordinator',
    'Curriculum Coordinator',
    'Director',
    'District Coordinator',
    'DP Coordinator',
    'EAL Coordinator',
    'Early Years Coordinator',
    'Early Years Teacher',
    'Economics Coordinator',
    'Economics Teacher',
    'English Coordinator',
    'English Teacher',
    'French Teacher',
    'Geography Coordinator',
    'Geography Teacher',
    'German Teacher',
    'Global Perspectives Teacher',
    'Head of Biology',
    'Head of Chemistry',
    'Head of Computing',
    'Head of Department',
    'Head of Early Years',
    'Head of Economics',
    'Head of English',
    'Head of Geography',
    'Head of History',
    'Head of Lower School',
    'Head of Maths',
    'Head of MFL',
    'Head of Philosophy',
    'Head of Physics',
    'Head of Primary',
    'Head of Psychology',
    'Head of Religious Education',
    'Head of Science',
    'Head of TOK',
    'Head of Upper School',
    'History Coordinator',
    'History Teacher',
    'IB Coordinator',
    'Librarian',
    'Maths Coordinator',
    'Maths Teacher',
    'MFL Coordinator',
    'Ministry of Education Staff',
    'MYP Coordinator',
    'OUP Staff',
    'Philosophy Coordinator',
    'Philosophy Teacher',
    'Physics Teacher',
    'Primary Teacher',
    'Principal',
    'Psychology Coordinator',
    'Psychology Teacher',
    'PYP Coordinator',
    'Reading Coordinator',
    'Religious Education Coordinator',
    'Religious Education Teacher',
    'School Leader',
    'Science Coordinator',
    'Science Teacher',
    'SEN Coordinator',
    'Spanish Teacher',
    'Teacher',
    'Teaching Assistant',
    'TOK Teacher',
    'Vice Principal',
    'Finance'
  ]
};
