import { put, select } from 'redux-saga/effects';
import { showConfirmation } from '../../../../../reducers/assignLearningMaterial.reducer';
import createLearningAssignmentApi from '../../../../apiCalls/library/createLearningAssignment.api';
import { pollClassAssignmentsAdded } from '../../dataRecency/pollClassAssignments';
import getDetails from './handleProductSelection/getDetails';
import { assignmentContexts } from '../../../../../../globals/assignmentConstants';
import { featureIsEnabled } from '../../../../../../globals/envSettings';

export default function* handleAssignmentSubmission() {
  console.log('[assignLearningMaterial] Sending assignment request...');

  const { orgId, productId, userIds, context } = yield select(getDetails);

  const payload = yield select(state => {
    const existingAssignmentId = state.assignLearningMaterial.existingAssignmentId;
    let totalLicenceCount;
    if (!featureIsEnabled('add-multiple-learning-material')) {
      totalLicenceCount = state.assignLearningMaterial.licencesTypes.reduce(
        (total, licenceType) => total + licenceType.availableCount,
        0
      );
    }

    let classId;
    if (context === assignmentContexts.CLASS || context === assignmentContexts.CLASS_RENEW) {
      classId = state.classroomPage.classroomId;
    } else if (context === assignmentContexts.CLASS_CREATION) {
      classId = state.classroomCreate.createdClassroomId;
    } else if (context === assignmentContexts.CLASS_ONBOARDING_WIZARD) {
      classId = state.classOnboardingWizard.classId;
    }

    if (featureIsEnabled('add-multiple-learning-material')) {
      const userLicenceAssignmentPayload = [];
      const {
        selectedProductIds,
        licencesAssignDetails,
        userIdsInOrder,
        assignLicencesMultiple
      } = state.assignLearningMaterial;
      let totalUserIds = [];
      selectedProductIds.forEach(product => {
        const obj = {};
        obj.productId = product;
        obj.userIds = userIdsInOrder[product];
        totalUserIds = [...totalUserIds, ...userIdsInOrder[product]];
        obj.assignLicences =
          assignLicencesMultiple[product] &&
          licencesAssignDetails[product] &&
          licencesAssignDetails[product].enoughLicencesAvailable;
        userLicenceAssignmentPayload.push(obj);
      });
      return {
        existingAssignmentId,
        classId,
        groupAssignment: [
          assignmentContexts.CLASS,
          assignmentContexts.CLASS_CREATION,
          assignmentContexts.ORG,
          assignmentContexts.CLASS_ONBOARDING_WIZARD
        ].includes(context),
        usersLicenceAssignmentDetails: userLicenceAssignmentPayload,
        userIds: Array.from(new Set(totalUserIds))
      };
    }

    return {
      existingAssignmentId,
      classId,
      productId,
      groupAssignment: [
        assignmentContexts.CLASS,
        assignmentContexts.CLASS_CREATION,
        assignmentContexts.ORG,
        assignmentContexts.CLASS_ONBOARDING_WIZARD
      ].includes(context),
      assignLicences:
        state.assignLearningMaterial.assignLicences &&
        !!state.assignLearningMaterial.userIdsInOrder.length &&
        totalLicenceCount >= state.assignLearningMaterial.userIdsInOrder.length,
      userIds:
        state.assignLearningMaterial.userIdsInOrder.length === 0 ? userIds : state.assignLearningMaterial.userIdsInOrder
    };
  });
  const assignmentResult = yield createLearningAssignmentApi(orgId, payload);
  if (assignmentResult.status === 'success') {
    let assignmentIds = [];
    if (assignmentResult.data.assignmentId) {
      assignmentIds.push({ licenseId: assignmentResult.data.assignmentId });
    }
    if (assignmentResult.data.assignmentIds && assignmentResult.data.assignmentIds.length > 0) {
      assignmentIds = assignmentResult.data.assignmentIds.map(licenseId => ({ licenseId }));
    }
    yield pollClassAssignmentsAdded(assignmentIds, {
      orgId,
      isClassroom: payload && !!payload.classId,
      currentContext: context
    });
  }

  console.log('[assignLearningMaterial] Assignment result:', assignmentResult);
  yield put(showConfirmation(assignmentResult.status !== 'success'));
}
