import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { isHubMode } from '../../utils/platform.js';
import StudentAnswerViewHeader from '../../components/StudentAnswerView/StudentAnswerViewHeader.js';
import StudentAnswerViewLTI from '../../components/StudentAnswerView/StudentAnswerViewLTI.js';
import { loadSavedSettings } from '../../redux/reducers/savedSettings';
import actions from '../../redux/actions';
import withLocalizedContent from '../../language/withLocalizedContent.js';
import { featureIsEnabled } from '../../globals/envSettings';

class StudentAnswerViewPage extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    const attemptIdInfo = urlParams.get('attemptId');
    const { params } = this.props;

    this.state = {
      selectedLearnerId: params.learnerId,
      attemptIdInfo
    };
  }

  componentDidMount() {
    console.log('[StudentAnswerViewPage] Initialising page with props: ', this.props);
    const { loadSavedSettingsAction, loadGradebookDetailsAction, params } = this.props;
    loadSavedSettingsAction();
    const { parentUId, uId } = params;
    const { learnerId, ...restParams } = params;
    const currentUid = parentUId || uId;
    loadGradebookDetailsAction(currentUid, 'group', restParams);
  }

  componentDidUpdate(prevProps) {
    const { params, loadGradebookDetailsAction } = this.props;
    const { parentUId, uId } = params;
    const { parentUId: prevPropsParentUId, uId: prevPropsUid } = prevProps.params;
    const currentUid = parentUId || uId;
    const prevPropsCurrentUid = prevPropsParentUId || prevPropsUid;

    if (currentUid !== prevPropsCurrentUid) {
      const { learnerId, ...restParams } = params;
      loadGradebookDetailsAction(currentUid, 'group', restParams);
    }
  }

  componentWillUnmount() {
    const { gradebookDetailsLoadingAction } = this.props;
    gradebookDetailsLoadingAction();
  }

  get _localizedContent() {
    const { localizedContent } = this.props;
    return localizedContent.gradedReadersLearnerView;
  }

  getLearnerListByRange = () => {
    const { params, gradebookData } = this.props;
    const activityId = params.activityId;
    (Object.keys(gradebookData) || []).forEach(learnerId => {
      (gradebookData[learnerId].activityScore || []).forEach(score => {
        if (score.uId && score.uId.includes(activityId)) {
          gradebookData[learnerId].hasUserSubmittedActivities = !!score.totalNumberOfAttempts;
        }
      });
    });
  };

  static getAttemptId = (gradebookData, activityUid, learnerId) =>
    gradebookData &&
    gradebookData[learnerId] &&
    gradebookData[learnerId].activityScore &&
    gradebookData[learnerId].activityScore.find(scoreData => scoreData.uId === activityUid).attemptId;

  selectLearner = ([learnerId]) => {
    const { params, gradebookData } = this.props;
    const activityUid = `olb:${params.bid}-${params.activityId}`;
    const attemptId = StudentAnswerViewPage.getAttemptId(gradebookData, activityUid, learnerId);
    this.setState({ attemptIdInfo: attemptId, selectedLearnerId: learnerId });
  };

  render() {
    const { params, gradebookData, classDetails } = this.props;
    const { attemptIdInfo, selectedLearnerId } = this.state;
    const classTitle =
      classDetails.data && classDetails.data[params.classroomId] && classDetails.data[params.classroomId].name;
    const activityUid = `olb:${params.bid}-${params.activityId}`;
    const pageTitle = isHubMode()
      ? `${classTitle} | ${this._localizedContent.student_answer_view_page_title}`
      : this._localizedContent.student_answer_view_page_title;
    this.getLearnerListByRange();

    const attemptId = featureIsEnabled('gradebook-first-and-last-answer') ? attemptIdInfo : null;
    return (
      <div>
        <Helmet title={pageTitle} />
        <StudentAnswerViewHeader
          params={params}
          gradebookData={gradebookData}
          classDetails={classDetails}
          selectLearner={this.selectLearner}
          selectedLearnerId={selectedLearnerId}
          classTitle={classTitle}
          currentClassId={params.classroomId}
        />
        {featureIsEnabled('gradebook-first-and-last-answer') && attemptId ? (
          <StudentAnswerViewLTI
            bid={params.bid}
            uid={activityUid}
            learnerId={selectedLearnerId}
            orgId={params.orgId}
            attemptId={attemptId}
          />
        ) : (
          <StudentAnswerViewLTI bid={params.bid} uid={activityUid} learnerId={selectedLearnerId} orgId={params.orgId} />
        )}
      </div>
    );
  }
}

StudentAnswerViewPage.propTypes = {
  params: PropTypes.object.isRequired,
  gradebookData: PropTypes.object.isRequired,
  classDetails: PropTypes.object.isRequired,
  learnerId: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string,
  loadSavedSettingsAction: PropTypes.func.isRequired,
  loadGradebookDetailsAction: PropTypes.func.isRequired,
  gradebookDetailsLoadingAction: PropTypes.func.isRequired
};

export default withRouter(
  compose(
    withLocalizedContent('gradedReadersLearnerView'),
    connect(
      (state, { match: { params } }) => ({
        params,
        gradebookLoading: state.gradebookClassReport.loading,
        gradebookData: state.gradebookClassReport.group,
        classDetails: state.classrooms || {}
      }),
      {
        loadSavedSettingsAction: loadSavedSettings,
        loadGradebookDetailsAction: actions.gradebookClassReportRequest,
        gradebookDetailsLoadingAction: actions.gradebookClassReportLoading
      }
    )
  )(StudentAnswerViewPage)
);
