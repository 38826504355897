import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../language/withLocalizedContent';

function RegisterEmailSendComplete({ errorOccurred, localizedContent: { registrationPage: content } }) {
  return (
    <div className="row">
      <div className="col">
        {!errorOccurred ? (
          <div>
            {/* SUCCESS: */}
            <PopoutPanelIconHeading
              type={types.VALID}
              title={content.email_sent_text}
              subtitle={content.email_sent_sub_text}
            >
              <p>{content.leave_page_text}</p>
            </PopoutPanelIconHeading>
          </div>
        ) : (
          <div>
            {/* ERROR: */}
            <PopoutPanelIconHeading type={types.ERROR} title={content.email_send_failed} />
          </div>
        )}
      </div>
    </div>
  );
}

RegisterEmailSendComplete.propTypes = {
  errorOccurred: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('registrationPage'),
  connect(
    state => ({
      errorOccurred: state.registration.errorOccurred
    }),
    {}
  )
)(RegisterEmailSendComplete);
