// This file contains all of the color names as constants.
// These are for use in validation and to programmatically set colors.

import hexCodes from '../styles/constants/_colors.scss';
import USER_ROLES from './userRoles.js';

// Ensure color names are in SNAKE_UPPERCASE:
// Eg: {'primary-hover':'#002147'} -> {'PRIMARY_HOVER':'primary-hover'}
const HYPHENS = /-/g;
const colors = Object.keys(hexCodes).reduce((result, name) => {
  const COLOR_NAME = name.toUpperCase().replace(HYPHENS, '_');
  result[COLOR_NAME] = name;
  return result;
}, {});

// Array of valid color values for use in validation
const validColors = Object.values(colors);

// Lookup ui colour for any ROLE:
const COLOR_FOR_ROLE = {
  [USER_ROLES.LEARNER]: colors.LEARNER,
  [USER_ROLES.TEACHER]: colors.TEACHER,
  [USER_ROLES.TEACHER_ADMIN]: colors.TEACHER_ADMIN,
  [USER_ROLES.ORG_ADMIN]: colors.ORG_ADMIN,
  // The following are not defined in the StyleGuide but included for completeness:
  [USER_ROLES.USER]: colors.LEARNER,
  [USER_ROLES.OUP_SUPPORT]: colors.ORG_ADMIN,
  [USER_ROLES.OUP_ADMIN]: colors.ORG_ADMIN,
  [USER_ROLES.OUP_SERVICE_MANAGER]: colors.ORG_ADMIN
};

// Export the object to be used in this format: `colors.PRIMARY`
export default colors;
export { validColors, hexCodes, COLOR_FOR_ROLE };
