import { take } from 'redux-saga/effects';
import { EMBED_CREDS_READY } from '../../../../reducers/app.reducer.js';

export default function* getCredentialsFromMessage() {
  while (true) {
    if (localStorage.getItem('embed-creds-status') === 'loaded') {
      console.log('[getCredentialsFromMessage] credentials found');
      return;
    }
    console.log('[getCredentialsFromMessage] Waiting for credentials from postMessage...');
    yield take(EMBED_CREDS_READY);
    console.log('[getCredentialsFromMessage] embed-creds-status:', localStorage.getItem('embed-creds-status'));
  }
}
