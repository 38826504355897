/** Pre Render saga
 *  This saga prepares all data required for the initial application render. A loading page is show whilst this completes.
 */

import { all, call, spawn, put } from 'redux-saga/effects';

import { parse as parseQueryString } from 'query-string';
import loadEnvironment from './environment/loadEnvironment.saga.js';
import { loadContent } from './content/loadContent.saga.js';
import cookieConsent from './cookieConsent.saga.js';
import browserCompatibility from './browserCompatibility.saga.js';
import getCredentialsFromMessage from './auth/getCredentials/getCredentialsFromMessage.saga.js';
// eslint-disable-next-line import/no-cycle
import listenForCommands from '../messaging/listenForCommands.saga.js';
import redirectIfLegacyDomain from '../utils/redirectIfLegacyDomain.js';
import store from '../../store.js';
import { postNotificationMessage } from '../../actions/postMessage';
import { setReCaptchaSiteKey } from '../../actions/reCAPTCHA.js';
import { isOrbMode, isEmbeddedInIframe } from '../../../utils/platform';
import declineOrgInviteDetails from '../appFunctions/DeclineOrgInvite/declineOrgInviteDetails.saga';
import declineOrgInviteUpdate from '../appFunctions/DeclineOrgInvite/updateDeclineOrgInvite.saga';
import envSettings, { featureIsEnabled } from '../../../globals/envSettings';
import checkMicrosoftRegistration from './auth/checkMicrosoftRegistration.saga';

// Refactor
import initialiseAuth from './refactor/auth/initialiseAuth.saga';
import initialiseAuthLegacy from './auth/initialiseAuth.saga.js';
import readOnlyMode from './refactor/auth/readOnlyMode.saga.js';
// ~Refactor

export default function* preRender() {
  console.groupCollapsed('[preRender]');
  console.log('[preRender] Beginning');

  const reCaptchaSiteKey = __RECAPTCHA_SITE_KEY__ || null;
  yield put(setReCaptchaSiteKey(reCaptchaSiteKey));

  // first, get api keys, deployment environment type and feature flags
  yield call(loadEnvironment);

  if (isOrbMode()) {
    // set the theme, since this component is always shown where theming is needed
    document.body.setAttribute('data-theme', `orb-theme`);
  }

  if (featureIsEnabled('single-client-session-store')) {
    console.groupCollapsed('[Process auth]');
    redirectIfLegacyDomain();
    yield all([loadContent, initialiseAuth].map(call));
    console.groupEnd();
  } else {
    if (isEmbeddedInIframe()) {
      const ORIGIN_QUERY_PARAM = 'origin';
      const VIEW_ID_QUERY_PARAM = 'view-id';
      const queryStringAsObject = parseQueryString(window.location.search);
      const embeddedParentOrigin = queryStringAsObject[ORIGIN_QUERY_PARAM] || 'no-origin-passed-by-parent';
      const embeddedViewId = queryStringAsObject[VIEW_ID_QUERY_PARAM] || 'view_9999'; // 9999 just in case this viewId gets used and split

      // when embedded, it is possible for the outer window to be local, which can affect the origin path
      const parentWindowIsLocal = embeddedParentOrigin.indexOf('localhost') === 0;

      // we assume localhost is always running without https - if ever needed we need to pass that via query too
      const parentOriginWithProtocol = `http${parentWindowIsLocal ? '' : 's'}://${embeddedParentOrigin}`;

      envSettings.embeddedViewId = embeddedViewId;
      envSettings.topLevelOrigin = parentOriginWithProtocol;

      console.log('[EMBED] embedded view ID is', envSettings.embeddedViewId);
      console.log('[EMBED] top level origin is', envSettings.topLevelOrigin);

      // notify top frame that the embedded app is waiting for credentials
      store.dispatch(
        postNotificationMessage({ eventName: 'APP_WAITING_FOR_CREDS', viewId: envSettings.embeddedViewId })
      );

      listenForCommands();
      console.log('[EMBED] running embedded - require credentials to continue');
      localStorage.setItem('embed-creds-status', 'pending');
      yield call(getCredentialsFromMessage);
    } else {
      // tidy up any previous status to prevent confusion
      localStorage.removeItem('embed-creds-status');
    }
    redirectIfLegacyDomain();

    // Wait for content and auth to complete
    yield all([loadContent, initialiseAuthLegacy].map(call));
  }

  yield spawn(checkMicrosoftRegistration);
  yield spawn(browserCompatibility);
  yield spawn(declineOrgInviteDetails);
  yield spawn(declineOrgInviteUpdate);
  yield spawn(readOnlyMode);

  try {
    if (!isEmbeddedInIframe()) {
      yield spawn(cookieConsent);
    }
  } catch (e) {
    console.warn('Problem checking cookie consent state', e);
  }

  console.log('[preRender] Complete');
  console.groupEnd();
}
