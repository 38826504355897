import React from 'react';
import PropTypes from 'prop-types';
import styles from './SelectedRow.scss';
import Badge from '../Badge/Badge';
import colors from '../../globals/colors';

/**
 * A vertically stacked set of actions.
 */
function SelectedRow({
  content,
  limitToggleValue = false,
  selectedIds,
  limitValue,
  maximumAllowed = null,
  externalLimitBaseValue
}) {
  return (
    <div id="selectedTotal" className={styles.selectedFooter}>
      <Badge backgroundColor={colors.PRIMARY_BLUE} value={selectedIds.length} />
      <span>
        {content.selected_label}
        {maximumAllowed && selectedIds.length === maximumAllowed && ` ${content.maximum}`}
        {limitToggleValue
          ? `, ${Math.max(0, limitValue - (externalLimitBaseValue + selectedIds.length))} ${
              content.spaces_available_label
            }`
          : ''}
      </span>
    </div>
  );
}

SelectedRow.propTypes = {
  content: PropTypes.object,
  limitToggleValue: PropTypes.bool,
  selectedIds: PropTypes.array,
  limitValue: PropTypes.number,
  externalLimitBaseValue: PropTypes.number,
  maximumAllowed: PropTypes.number
};

export default SelectedRow;
