import { API_VERSIONS, JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../util/signedFetch';

export default function* getXApiLaunchToken(params) {
  let URL = `${__API_BASE__}/player/launch/${params.activityId}?`;

  delete params.activityId;

  const paramsArray = Object.entries(params).map(([key, value]) => `${key}=${value}`);
  URL += paramsArray.join('&');

  const headers = { 'Content-Type': JSON_ENCODED, Accept: API_VERSIONS.API_VERSION_0_2 };
  const encodedURL = encodeURI(URL);

  return yield signedFetch('getXApiLaunchToken', encodedURL, 'POST', undefined, headers);
}
