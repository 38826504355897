import { pick } from 'lodash';

import * as t from '../actionTypes';

const initialState = {
  name: '',
  role: '',
  countryCode: '',
  orgRegion: '',
  orgPostcode: '',
  sapNumber: '',
  curriculumType: '',
  orgUniqueId: '',
  primaryEmail: '',
  webAddress: '',
  errors: {},
  loading: false,
  submitting: false,
  success: false,
  failure: false,
  registrationStatus: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.EDIT_ORG_VALIDATE_COMPLETED:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload
        }
      };
    case t.EDIT_ORG_VALIDATE_ERROR:
      return {
        ...state,
        submitting: false
      };
    case t.EDIT_ORG_GET_DETAILS_REQUEST:
      return {
        ...initialState,
        loading: true
      };
    case t.EDIT_ORG_GET_DETAILS_SUCCESS:
      return {
        ...state,
        ...pick(payload, [
          'name',
          'role',
          'countryCode',
          'curriculumType',
          'primaryEmail',
          'webAddress',
          'registrationStatus',
          'lmsLtiTools'
        ]),
        orgUniqueId: payload.customId,
        orgRegion: payload.orgConfig?.configurations?.LOCATION?.orgRegion,
        orgPostcode: payload.orgConfig?.configurations?.LOCATION?.orgPostcode,
        sapNumber: payload.orgConfig?.configurations?.CONFIGURATION_SAP?.sapNumber,
        loading: false
      };
    case t.EDIT_ORG_REQUEST:
      return {
        ...state,
        submitting: true
      };
    case t.EDIT_ORG_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true
      };
    case t.EDIT_ORG_FAILURE:
      return {
        ...state,
        submitting: false,
        failure: true
      };
    case t.EDIT_ORG_FAILURE_CLEAR:
      return {
        ...state,
        failure: false
      };
    case t.EDIT_ORG_RESET:
      return initialState;
    default:
      return state;
  }
};
