import signedFetch from './util/signedFetch';

export default function* searchProductGroupsApi(id, filter) {
  const body = {
    searchString: id,
    searchProducts: false,
    searchProductGroups: true,
    searchExternalId: false,
    searchName: true
  };
  if (filter === 'product_name') {
    body.searchGroupsByProductName = true;
  }
  if (filter === 'product_id') {
    body.searchName = false;
    body.searchGroupsByProductId = true;
  }
  if (filter === 'external_id') {
    body.searchName = false;
    body.searchGroupsByExternalId = true;
  }
  const URL = `${__API_BASE__}/admin-console/product/lookup`;
  return yield signedFetch('lookupProduct', URL, 'POST', body);
}
