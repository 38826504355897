import { useState } from 'react';

function useBatchState() {
  const [batchName, setBatchName] = useState('');
  const [numberOfTokens, setNumberOfTokens] = useState('');
  const [batchAllowedUsage, setBatchAllowedUsage] = useState('');
  const [tokenStartDate, setTokenStartDate] = useState('');
  const [tokenEndDate, setTokenEndDate] = useState('');

  const format = 'EAC Numeric';

  const setBatchNameFunction = param => setBatchName(param);

  const setNumberOfTokensFunction = param => setNumberOfTokens(param);

  const setBatchAllowedUsageFunction = param => setBatchAllowedUsage(param);

  const setTokenStartDateFunction = param => setTokenStartDate(param);

  const setTokenEndDateFunction = param => setTokenEndDate(param);

  const resetAllFields = () => {
    setBatchNameFunction('');
    setNumberOfTokensFunction('');
    setBatchAllowedUsageFunction('');
    setTokenStartDateFunction('');
    setTokenEndDateFunction('');
  };

  const setBatchDetails = () => ({
    setBatchNameFunction,
    setNumberOfTokensFunction,
    setBatchAllowedUsageFunction,
    setTokenStartDateFunction,
    setTokenEndDateFunction,
    resetAllFields
  });

  const batchDetails = {
    tokenStartDate,
    tokenEndDate,
    format,
    batchName,
    numberOfTokens,
    batchAllowedUsage
  };

  return [batchDetails, setBatchDetails];
}

export default useBatchState;
