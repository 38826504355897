import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './DetailReportGrid.scss';

function DetailReportGrid({ forceRowDisplay = false, gridData }) {
  const [firstRow, secondRow, thirdRow] = gridData;
  return (
    <div className={classnames('row', styles.container)}>
      <div
        className={classnames('gin-bot2', {
          [styles.columnDisplay]: forceRowDisplay,
          'col sm4': !forceRowDisplay
        })}
      >
        <p className={styles.subHeaderBold}>{firstRow.header}</p>
        <h1 className={styles.largeHeaderFormat}>{firstRow.content}</h1>
        {firstRow.description ? <p className={styles.expectedLabel}>{firstRow.description}</p> : null}
      </div>
      {secondRow ? (
        <div
          className={classnames(styles.midProgress, {
            [styles.midColumnDisplay]: forceRowDisplay,
            [styles.columnDisplay]: forceRowDisplay
          })}
        >
          <p className={styles.subHeaderBold}>{secondRow.header}</p>
          <h1 className={styles.largeHeaderFormat}>{secondRow.content}</h1>
          {secondRow.description ? <p className={styles.expectedLabel}>{secondRow.description}</p> : null}
        </div>
      ) : null}
      {thirdRow ? (
        <div
          className={classnames({
            [styles.columnDisplay]: forceRowDisplay,
            'col sm4': !forceRowDisplay
          })}
        >
          <p className={styles.subHeaderBold}>{thirdRow.header}</p>
          <h1 className={styles.largeHeaderFormat}>{thirdRow.content}</h1>
          {thirdRow.description ? <p className={styles.expectedLabel}>{thirdRow.description}</p> : null}
        </div>
      ) : null}
    </div>
  );
}

DetailReportGrid.propTypes = {
  forceRowDisplay: PropTypes.bool,
  gridData: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      description: PropTypes.string
    }).isRequired
  ).isRequired
};

export default DetailReportGrid;
