import React from 'react';
import PropTypes from 'prop-types';
import PanelHeading from '../PanelHeading/PanelHeading';
import Illustration from '../Illustration/Illustration.js';
import styles from './PopoutPanelIllustrationHeading.scss';

function PopoutPanelIllustrationHeading({
  title,
  subtitle,
  children,
  link,
  linkText,
  illustrationSrc,
  illustrationAltText
}) {
  return (
    <div id="popoutPanelIconHeading" className={styles.popoutPanelIconHeading}>
      <Illustration illustrationSrc={illustrationSrc} illustrationAltText={illustrationAltText} />
      <PanelHeading title={title} subtitle={subtitle} link={link} linkText={linkText}>
        {children}
      </PanelHeading>
    </div>
  );
}

PopoutPanelIllustrationHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.any,
  link: PropTypes.string,
  linkText: PropTypes.string,
  illustrationSrc: PropTypes.string,
  illustrationAltText: PropTypes.string
};

export default PopoutPanelIllustrationHeading;
