import { pick, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ImportUsers } from '../../components';
import withGetYearGroupOptions from '../../language/withGetYearGroupOptions';
import actions from '../../redux/actions';
import { getImportUsers, getNumberOfUserImportErrors } from '../../redux/selectors';

function ImportUsersPanel({ getYearGroupOptions, curriculumType, ...props }) {
  return (
    <ImportUsers yearGroupOptions={getYearGroupOptions(curriculumType)} {...props} curriculumType={curriculumType} />
  );
}

ImportUsersPanel.propTypes = {
  ...omit(ImportUsers.propTypes, ['yearGroupOptions']),
  curriculumType: PropTypes.string.isRequired,
  getYearGroupOptions: PropTypes.func.isRequired,
  currentOrgId: PropTypes.string.isRequired
};

export default compose(
  withGetYearGroupOptions,
  connect(
    state => ({
      ...pick(state.importUsers, [
        'parsing',
        'failure',
        'checking',
        'uploading',
        'update',
        'batchId',
        'importRecordsCount',
        'unsavedUsers'
      ]),
      users: getImportUsers(state),
      numberOfErrors: getNumberOfUserImportErrors(state),
      currentOrgId: state.identity.currentOrganisationId
    }),
    dispatch => ({
      parse: source => {
        dispatch(actions.parseImportUsersSourceRequest(source));
      },
      retrySource: () => {
        dispatch(actions.parseImportUsersSourceRetry());
      },
      update: updates => {
        dispatch(actions.updateImportUsersRequest(updates));
      },
      destroy: id => {
        dispatch(actions.destroyImportUsersRequest(id));
      },
      upload: queue => {
        dispatch(actions.uploadImportUsersRequest(queue));
      },
      validate: (id, input) => {
        dispatch(actions.validateImportUsersRequest(id, input));
      },
      storeUnsavedUsers: users => {
        dispatch(actions.storeUnsavedUsers(users));
      }
    })
  )
)(ImportUsersPanel);
