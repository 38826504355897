import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import PageHeading from '../components/PageHeading/PageHeading';
import withLocalizedContent from '../language/withLocalizedContent.js';
import OicLinkOrganizationsToJanisonList from '../components/OicLinkOrganizationsToJanisonList/OicLinkOrganizationsToJanisonList';

function OicLinkOrgsToJanisonPage({ localizedContent: { oicLinkOrganizationsToJanison: localizedContent = {} } }) {
  return (
    <>
      <Helmet title={localizedContent.page_title} />
      <div className="grid pad-top4">
        <div className="sm10">
          <PageHeading title={localizedContent.page_title} subtitle={localizedContent.page_subtitle} />
        </div>
      </div>
      <OicLinkOrganizationsToJanisonList tabName="oicOrganizations" />
    </>
  );
}

OicLinkOrgsToJanisonPage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('oicLinkOrganizationsToJanison'))(OicLinkOrgsToJanisonPage);
