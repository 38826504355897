import moment from 'moment';
// Constants
import { SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS } from '@oup/shared-node-browser/constants';
import { HubLayoutConstants, licenceStatus } from '../../../../globals/hubConstants';
import { LICENCE_CONSTANTS } from '../../../../globals/appConstants';
// Components
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';

const getUserLicencesStatusData = (assignmentDetails = {}, hubContent = {}) => {
  const { endDate = '', expiryDate = '', licenceDetails = null } = assignmentDetails;
  const hasLicence = assignmentDetails.hasLicence;

  const {
    ORG_LICENCES_STATUS: { CHECK_COLOUR, WARNING_COLOUR, WARNING_RED_COLOUR }
  } = HubLayoutConstants;
  const { NOT_STARTED, ACTIVE, EXPIRED, EXPIRING, NO_LICENCE } = licenceStatus;

  const licenceDate = endDate || expiryDate;
  const date = moment(licenceDate).format('D MMMM YYYY');

  const isUnlimitedLicence =
    ((hasLicence || licenceDetails) && (!licenceDate || licenceDate === 'Never')) ||
    // There is no licenceDetails when the licence is not started
    (!licenceDate &&
      assignmentDetails.licenceNotStartedDetails &&
      assignmentDetails.licenceNotStartedDetails?.licenceType !== LICENCE_CONSTANTS.LICENCE_TYPE.ROLLING);

  // Not started Rolling licence
  if (
    !licenceDate &&
    assignmentDetails.licenceNotStartedDetails &&
    assignmentDetails.licenceNotStartedDetails?.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.ROLLING
  ) {
    const licenceData = assignmentDetails.licenceNotStartedDetails;
    return {
      icon: GLYPHS.ICON_CHECK_CIRCLE,
      fill: CHECK_COLOUR,
      text: hubContent.class_classwork_filter_not_started,
      date: `${licenceData.timePeriod} ${licenceData.unitType.toLowerCase()} ${hubContent.rolling_licence_message}`,
      status: NOT_STARTED,
      hasLink: true
    };
  }

  if (isUnlimitedLicence) {
    return {
      icon: GLYPHS.ICON_CHECK_CIRCLE,
      fill: CHECK_COLOUR,
      date: hubContent.unlimited_licence,
      status: assignmentDetails.licenceNotStartedDetails ? NOT_STARTED : ACTIVE,
      hasLink: true
    };
  }

  if (licenceDate && moment(licenceDate).isValid()) {
    if (moment(licenceDate).isBefore(moment().toISOString())) {
      return {
        icon: GLYPHS.ICON_ERROR_CIRCLE,
        fill: WARNING_RED_COLOUR,
        text: hubContent.licence_expired_on,
        date,
        status: EXPIRED,
        hasLink: false
      };
    }

    // Not started Concurrent or Usage licence
    if (
      moment(date).diff(moment(), 'days', true) > SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS &&
      assignmentDetails.licenceNotStartedDetails
    ) {
      return {
        icon: GLYPHS.ICON_CHECK_CIRCLE,
        fill: CHECK_COLOUR,
        text: hubContent.not_started_licence_expires_on,
        date,
        status: NOT_STARTED,
        hasLink: true
      };
    }

    if (
      moment(date).diff(moment(), 'days', true) < SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS &&
      assignmentDetails.licenceNotStartedDetails
    ) {
      return {
        icon: GLYPHS.ICON_ERROR_CIRCLE,
        fill: WARNING_COLOUR,
        text: hubContent.not_started_licence_expires_on,
        date,
        status: NOT_STARTED,
        hasLink: true
      };
    }

    if (moment(date).diff(moment(), 'days', true) > SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS) {
      return {
        icon: GLYPHS.ICON_CHECK_CIRCLE,
        fill: CHECK_COLOUR,
        text: hubContent.licence_expires_on,
        date,
        status: ACTIVE,
        hasLink: true
      };
    }

    if (moment(date).diff(moment(), 'days', true) < SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS) {
      return {
        icon: GLYPHS.ICON_WARNING_CIRCLE,
        fill: WARNING_COLOUR,
        text: hubContent.licence_expires_on,
        date,
        status: EXPIRING,
        hasLink: true
      };
    }
  }

  return {
    icon: GLYPHS.ICON_ERROR_CIRCLE,
    fill: WARNING_RED_COLOUR,
    date: hubContent.no_licence,
    status: NO_LICENCE,
    hasLink: false
  };
};

export default getUserLicencesStatusData;
