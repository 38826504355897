import signedFetch from './util/signedFetch.js';

/**
 * This updated the specified parts of a class. The data payload can contain any of the following:
 *  - actioningUserId: the userId of the user performing the action (only temp as it should be derived from session in the lambda)
 *  - name: String - Updated Class name
 *  - teachersAdded: Array<Int> - Teacher IDs to be added
 *  - studentsAdded: Array<Int> - Student IDs to be added
 *  - teachersRemoved: Array<Int> - Teacher IDs to be removed
 *  - studentsRemoved: Array<Int> - Student IDs to be removed
 *  - limit: Int - Updated Student limit value. Disable (0) or enable (1-n)
 */
export default (orgId, classroomId, data) =>
  signedFetch(
    'editClass',
    `${__API_BASE__}/org/${encodeURIComponent(orgId)}/class/${encodeURIComponent(classroomId)}`,
    'PUT',
    data
  );
