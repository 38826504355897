import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './VideoModal.scss';
import keyCodes from '../../../globals/keyCodes';

class VideoModal extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  checkVisibilityBool = visibilityBool => {
    if (visibilityBool) {
      return styles.show;
    }
    if (this.videoRef?.current?.contentWindow) {
      this.videoRef.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }
    return styles.hide;
  };

  render() {
    const { visibilityChecker, toggleModal, videoId, dataTestId = '' } = this.props;

    return (
      <div className={styles.videoModal} data-testid={dataTestId}>
        <div className={this.checkVisibilityBool(visibilityChecker)}>
          <div
            onKeyDown={event => {
              if (event.keyCode === keyCodes.ESC) toggleModal();
            }}
            role="button"
            tabIndex="0"
            className={styles.modalContainer}
            onClick={toggleModal}
          >
            <div className={styles.modal}>
              <div className={styles.iframeContainer}>
                <iframe
                  ref={this.videoRef}
                  className="video"
                  title="Youtube player"
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  height="100%"
                  width="100%"
                  src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VideoModal.propTypes = {
  /**  True === visible, false === hidden */
  visibilityChecker: PropTypes.bool.isRequired,
  /** If there's no prop given for it, the element will not render (including buttons) */

  videoId: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  dataTestId: PropTypes.string
};

export default VideoModal;
