import PropTypes from 'prop-types';
import React from 'react';
import ContentWithSVGIcon from '../../ContentWithSVGIcon/ContentWithSVGIcon';
import MaterialRepresentation from '../../MaterialRepresentation/MaterialRepresentation';
import PanelNavigationLink from '../../PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading from '../../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import { sanitizeUrl } from '../../../utils/url.js';
import { isHubMode } from '../../../utils/platform';
import { GLYPHS } from '../../SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../../Thumbnail/Thumbnail';
import { LICENSE_STATUS_COLOR } from '../../../globals/appConstants';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';

function ProductFinderError({
  assignLicences,
  errorOccurred = false,
  product,
  closePanel,
  onAddAnotherClick,
  productFinderContent
}) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={productFinderContent.close_panel_text} action={closePanel} />
          </div>
          {isHubMode() ? (
            <PopoutPanelIllustrationHeading
              title={productFinderContent.assignment_error_text}
              illustrationSrc={HubIllustrationConstants.ERROR}
              illustrationAltText={HubIllustrationAltText.ERROR}
            />
          ) : (
            <PopoutPanelIconHeading type="WARNING_ERROR" title={productFinderContent.assignment_error_text} />
          )}
        </div>
      }
      footerContent={
        <PopoutActionFooter
          secondaryButtonAction={onAddAnotherClick}
          secondaryButtonText={productFinderContent.button_add_another_assignment}
          primaryButtonText={productFinderContent.button_try_again_assignment}
          primaryButtonAction={onAddAnotherClick}
          primaryButtonTestHook="ASSIGN_LEARNING_MATERIAL_PANEL_ERROR_BUTTON"
        />
      }
    >
      <div>
        <div className="pad-top1 pad-bot1 pad-left2 pad-right2">
          <ContentWithSVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE}>
            <MaterialRepresentation
              thumbnailSize={thumbnailSizes.TABLE}
              imageSrc={sanitizeUrl(product.coverImage || product?.cover || '')}
              name={product.title}
              subtext={product.subtitle}
              statusColor={assignLicences ? LICENSE_STATUS_COLOR.SUCCESS : LICENSE_STATUS_COLOR.WARNING}
              statusText={errorOccurred && productFinderContent.assignment_error_text}
            />
          </ContentWithSVGIcon>
        </div>
      </div>
    </ScrollContainer>
  );
}

ProductFinderError.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  assignLicences: PropTypes.bool.isRequired,
  errorOccurred: PropTypes.bool.isRequired,
  product: PropTypes.any,
  onAddAnotherClick: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired
};

export default ProductFinderError;
