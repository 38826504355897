import { put, select } from 'redux-saga/effects';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import actions from '../../../../actions';
import { assignmentInstance, assignmentContexts } from '../../../../../globals/assignmentConstants';

export const PollClassAssignmentsOperation = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  ARCHIVE: 'ARCHIVE'
};

function searchInstance(isClassroom, currentContext) {
  if (isClassroom) {
    return assignmentInstance.CLASS_ASSIGNMENTS;
  }

  if (
    currentContext === assignmentContexts.ORG_STUDENTS ||
    currentContext === assignmentContexts.CLASS_STUDENTS ||
    currentContext === assignmentContexts.CLASS_USERS ||
    currentContext === assignmentContexts.CLASS_ONBOARDING_WIZARD
  ) {
    return assignmentInstance.ASSIGNMENT_PRODUCTS;
  }

  if (Object.keys(assignmentContexts).indexOf(currentContext) > -1) {
    return assignmentInstance.USER_ASSIGNMENTS;
  }

  throw new Error('Unknown context');
}

export default function* pollClassAssignments(assignmentIds, operation, { orgId, isClassroom, currentContext }) {
  if (
    !featureIsEnabled('data-recency') ||
    !featureIsEnabled('data-recency-class-assignments') ||
    !assignmentIds.length
  ) {
    return;
  }
  const { orgId: classroomOrgId, classroomId } = yield select(state => state.classroomPage);

  yield put(
    actions.pollEntitiesSynced({
      ...(isClassroom
        ? {
            orgId: classroomOrgId,
            classId: classroomId
          }
        : {
            orgId,
            classId: orgId
          }),
      assignments: assignmentIds.map(assignment => ({
        assignmentId: assignment.licenseId,
        assignmentGroupId: assignment.groupId
      })),
      operation,
      collection: 'classAssignments',
      target: 'assignments',
      responseEntityKey: 'learningAssignmentsPollingStatus',
      identifier: 'assignmentId',
      search: searchInstance(isClassroom, currentContext)
    })
  );
}

export function pollClassAssignmentsAdded(ids, options) {
  return pollClassAssignments(ids, PollClassAssignmentsOperation.ADD, options);
}

export function pollClassAssignmentsRemoved(ids, options) {
  return pollClassAssignments(ids, PollClassAssignmentsOperation.REMOVE, options);
}

export function pollClassAssignmentsArchived(ids, options) {
  return pollClassAssignments(ids, PollClassAssignmentsOperation.ARCHIVE, options);
}
