import { PLATFORMS } from '@oup/shared-node-browser/constants';
import { getIdpLoginUrl } from '../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga';
import { isHubMode, isOteMode, isOptMode, isSmartMode, isOicMode } from '../utils/platform';
import { isUrlFromTrustedDomain, sanitizeUrl } from '../utils/url';
import userRole from './userRoles';

/**
 * The function is used to generate the login url to redirect
 * users to any particular platform based on the predefined path.
 *
 * @params {string} User's Role
 * @params {string} Name of the platform the user belongs to
 * @params {string} Predefined return url.
 */
const generateLoginUrl = async (providedUserRole, platform = PLATFORMS.CES, withReturnUrl = null) => {
  let returnUrl;
  let selectPlatform;
  if (providedUserRole === userRole.LEARNER) {
    selectPlatform = platform;
  } else if (isHubMode()) {
    selectPlatform = PLATFORMS.HUB;
  } else if (isOteMode()) {
    selectPlatform = PLATFORMS.OTE;
  } else if (isOicMode()) {
    selectPlatform = PLATFORMS.OIC;
  } else if (isOptMode()) {
    selectPlatform = PLATFORMS.OPT;
  } else if (isSmartMode()) {
    selectPlatform = PLATFORMS.SMA;
  } else {
    selectPlatform = PLATFORMS.CES;
  }
  if (withReturnUrl && !(selectPlatform === PLATFORMS.OLB && providedUserRole === userRole.LEARNER)) {
    const sanitizedUrl = sanitizeUrl(withReturnUrl);
    returnUrl = isUrlFromTrustedDomain(sanitizedUrl)
      ? sanitizedUrl
      : await getIdpLoginUrl(null, { withReturnTo: false, platform: selectPlatform });
  } else {
    returnUrl = await getIdpLoginUrl(null, { withReturnTo: false, platform: selectPlatform });
  }
  return returnUrl;
};

export default generateLoginUrl;
