import * as t from '../actionTypes';

export const setCodeForDashboard = data => ({
  type: t.SET_ACT_CODE_FOR_DASHBOARD,
  data
});

export const setAllowedUsageForDashboard = data => ({
  type: t.SET_ALLOWED_USAGE_FOR_DASHBOARD,
  data
});

export const setBatchNameForDashboard = data => ({
  type: t.SET_BATCH_NAME_FOR_DASHBOARD,
  data
});

export const searchActivationCode = data => ({
  type: t.SEARCH_ACT_CODE_FOR_DASHBOARD,
  data
});

export const setErrorForDashboard = data => ({
  type: t.SET_ERROR_FOR_ACT_CODE_DASHBOARD,
  data
});

export const setResponseForDashboard = data => ({
  type: t.SET_RESPONSE_FOR_ACT_CODE_DASHBOARD,
  data
});

export const deleteActivationCode = data => ({
  type: t.DELETE_ACT_CODE_FOR_DASHBOARD,
  data
});

export const createActivationCode = data => ({
  type: t.CREATE_ACT_CODE_FOR_DASHBOARD,
  data
});

export const getActivationCodeDetailsForDashboard = data => ({
  type: t.GET_ACT_CODE_DETAILS_FOR_DASHBOARD,
  data
});

export const setActivationCodeDetailsForDashboard = data => ({
  type: t.SET_ACT_CODE_DETAILS_FOR_DASHBOARD,
  data
});

export const setActivationCodeDetailsErrorForDashboard = data => ({
  type: t.SET_ACT_CODE_DETAILS_ERROR_FOR_DASHBOARD,
  data
});

export const lookUpProductForCompletion = data => ({
  type: t.LOOKUP_PRODUCT_FOR_COMPLETION,
  data
});

export const setLookUpdata = data => ({
  type: t.LOOKUP_PRODUCT_SET_DATA,
  data
});

export const setLookUpErrors = data => ({
  type: t.LOOKUP_PRODUCT_SET_ERROR,
  data
});

export const searchProductGroup = data => ({
  type: t.SEARCH_PRODUCT_GROUP,
  data
});

export const resetProductGroup = data => ({
  type: t.RESET_PRODUCT_GROUP,
  data
});

export const resetActivationCodeSearch = data => ({
  type: t.RESET_SEARCH_ACT_CODES,
  data
});

export const startActivationCodeSearch = data => ({
  type: t.SEARCH_ACT_CODES_START,
  data
});
