import * as t from '../actionTypes';

export const getBookTitleRequest = bookId => ({
  type: t.GET_BOOK_TITLE_REQUEST,
  payload: bookId
});

export const getBookTitleSuccess = payload => ({
  type: t.GET_BOOK_TITLE_SUCCESS,
  payload
});

export const getBookTitleFailure = payload => ({
  type: t.GET_BOOK_TITLE_FAILURE,
  payload
});
