import * as t from '../actionTypes';

export const launchUserImpersonation = payload => ({
  type: t.IMPERSONATE_USER_REQUEST,
  payload
});

export const userImpersonationSuccess = payload => ({
  type: t.IMPERSONATE_USER_SUCCESS,
  payload
});

export const userImpersonationFailure = payload => ({
  type: t.IMPERSONATE_USER_FAILURE,
  payload
});
