import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './OteBottomRow.scss';

import cmsContent from '../../../../utils/cmsContent.js';
import Link from '../../../../components/Link/Link';
import VideoModal from '../../../../components/Modals/VideoModal/VideoModal';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';

export default class OteBottowRow extends Component {
  constructor() {
    super();
    this.state = { CMS: {}, isVideoModalOpen: false };
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.oteHomepage || {} });
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.oteHomepage || {} });
  }

  _toggleModal = () => {
    const { isVideoModalOpen } = this.state;
    this.setState({ isVideoModalOpen: !isVideoModalOpen });
  };

  render() {
    const { CMS, isVideoModalOpen } = this.state;

    return (
      <div className={styles.OteHomeBottomRow} data-testid="HOMEPAGE_BUTTOM_ROW">
        <div className="grid">
          <div className={styles.OteHomePageBottomRowSection}>
            <div className={classnames('row', styles.row)}>
              <div className={classnames('col md6', styles.listItem)}>
                <img
                  onClick={this._toggleModal}
                  aria-hidden
                  src={CMS.content_image_src_2}
                  alt={CMS.alt_image_src_2}
                  className={styles.imgBorder}
                />
                <SVGIcon glyph={GLYPHS.ICON_YOUTUBE_WHITE_LOGO} className={styles.logo} />
              </div>
              <div className={classnames('col md6', styles.col)}>
                <p>
                  <span className={styles.redSpan}>{CMS.content_description_0_0}</span>
                  <span>{` ${CMS.content_description_0_1}`}</span>
                </p>
                <p>
                  <span className={styles.redSpan}>{CMS.content_description_1_0}</span>
                  <span>{CMS.content_description_1_1}</span>
                </p>
                <p>
                  <span>{`${CMS.content_description_2_0} `}</span>
                  <span className={styles.redSpan}>{CMS.content_description_2_1}</span>
                </p>
                <Link className={styles.findOutMoreButton} to={CMS.button_find_out_more_url}>
                  <button type="button" id="navHeader-supportButton" target="_blank">
                    {CMS.button_find_out_more}
                  </button>
                </Link>
              </div>
              <VideoModal
                visibilityChecker={isVideoModalOpen}
                videoId={CMS.video_id}
                toggleModal={this._toggleModal}
                dataTestId="HOMEPAGE_VIDEO_MODAL"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
