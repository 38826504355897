import { select } from 'redux-saga/effects';

import updateUserInvitationApi from '../../../apiCalls/users/updateUserInvitation.api.js';

export default function* updateUserInvitation(updateAction) {
  const { orgId, userId } = yield select(state => ({
    updateAction,
    orgId: state.userProfile.orgId,
    userId: state.userProfile.userId
  }));
  console.log(`[updateUserInvitation Saga] Updating using invitation with ${updateAction}`);
  return yield updateUserInvitationApi(updateAction, orgId, userId);
}
