import * as t from '../actionTypes';

export const libraryRequest = (orgId, region) => ({
  type: t.NGS_LIBRARY_REQUEST,
  payload: { orgId, region }
});

export const libraryRequestSuccess = payload => ({
  type: t.NGS_LIBRARY_SUCCESS,
  payload
});

export const libraryRequestFailure = payload => ({
  type: t.NGS_LIBRARY_FAILURE,
  payload
});

export const updateMyCoursesRequest = (orgId, courseIds) => ({
  type: t.NGS_UPDATE_MY_COURSES_REQUEST,
  payload: { orgId, courseIds }
});

export const updateMyCoursesSuccess = payload => ({
  type: t.NGS_UPDATE_MY_COURSES_SUCCESS,
  payload
});

export const updateMyCoursesFailure = payload => ({
  type: t.NGS_UPDATE_MY_COURSES_FAILURE,
  payload
});
