import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';

function NotFoundView({ localizedContent: { notFoundPage: content } }) {
  return (
    <div className="grid">
      <div className="row">
        <div className="col" style={{ padding: '2rem 0' }}>
          <h2>{content.title}</h2>
        </div>
      </div>
    </div>
  );
}

NotFoundView.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('notFoundPage')(NotFoundView);
