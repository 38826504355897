import React from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import EpsContentArea from '../EpsContentArea/EpsContentArea';

// This page is WIP, logic & glossaries will be added later.
function EpsContentPage() {
  return (
    <PageWrapper>
      <Helmet title="EPS" />
      <h1>EPS Content</h1>
      <EpsContentArea />
    </PageWrapper>
  );
}

export default EpsContentPage;
