import { pick } from 'lodash';
import * as t from '../actionTypes';

const initialState = {
  orgs: [],
  submitting: false,
  success: false,
  errors: {}
};

function deferredEmails(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_DEFERRED_EMAILS_REQUEST:
      return {
        ...initialState,
        submitting: true
      };
    case t.GET_DEFERRED_EMAILS_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        errors: payload
      };
    case t.GET_DEFERRED_EMAILS_SUCCESS:
      return {
        ...state,
        submitting: false,
        errors: {},
        success: true,
        ...pick((payload || {}).data, ['orgs'])
      };
    default:
      return state;
  }
}

export default deferredEmails;
