import * as t from '../actionTypes';

const initialState = {
  userLicences: [],
  loadingUserLicences: false,
  success: false,
  errors: {}
};

function userLicences(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_USER_LICENCES_REQUEST:
      return {
        ...initialState,
        loadingUserLicences: true
      };
    case t.GET_USER_LICENCES_FAILURE:
      return {
        ...state,
        loadingUserLicences: false,
        success: false,
        errors: { search: payload.userId ? payload.userId : payload }
      };
    case t.GET_USER_LICENCES_SUCCESS:
      return {
        ...state,
        loadingUserLicences: false,
        errors: {},
        success: true,
        userLicences: payload.data
      };
    case t.GET_USER_LICENCES_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default userLicences;
