import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { PLATFORMS } from '@oup/shared-node-browser/constants';
import withLocalizedContent from '../../language/withLocalizedContent';
import withTheme from '../../theme/withTheme';
import { getRegionalIDP } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import styles from '../SiteHeader/SiteHeader.scss';
import localStyles from './ORBSiteHeader.scss';
import envSettings from '../../globals/envSettings';
import { SiteHeader, connectRedux } from '../SiteHeader/SiteHeader';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import Button, { buttonTypes } from '../../components/Button/Button.js';

class ORBSiteHeader extends SiteHeader {
  static propTypes = {
    ...SiteHeader.propTypes,
    themeOptions: PropTypes.object
  };

  constructor() {
    super();
    this.state = {};
  }

  static redirectToIDP = userType => {
    const idpType = userType === 'teacher' ? 'idp' : 'idp2';
    const betaRegionName = 'EUROPE_BETA';
    const betaRegionValue = {
      idp: 'auth0',
      orb: 'uk-beta',
      label: 'Europe Beta'
    };
    const navigateTo = userType === 'teacher' ? PLATFORMS.CES : PLATFORMS.ORB;
    const regionalSettings = envSettings.regions;
    // Add the auth0 beta region to the regions object (This function getRegionalIDP() will be only used in orb-region demo page)
    if (!regionalSettings[betaRegionName]) {
      regionalSettings[betaRegionName] = betaRegionValue;
    }

    const idpUrl = getRegionalIDP(null, { userType, idpType, navigateTo }, regionalSettings);
    window.location.href = idpUrl;
  };

  render() {
    const {
      themeOptions,
      localizedContent: { siteHeaders: content }
    } = this.props;

    return (
      <header className={styles.siteHeader} style={{ backgroundColor: themeOptions.topBarColor }}>
        <a className="a11y-hide-focusable" href="#maincontent">
          {content.skip_to_main_content}
        </a>

        <div className="grid">
          <div className="row">
            <div id="navHeader" className="col">
              <nav aria-label="Primary">
                <ul>
                  <div className={localStyles.orbSiteHeaders.pushRight} />
                  <li className={styles.loginButton}>
                    <Button
                      id="signIn"
                      type={buttonTypes.GHOST_INVERTED}
                      glyph={GLYPHS.ICON_USER}
                      text={content.sign_in_teacher_text}
                      onClick={() => ORBSiteHeader.redirectToIDP('teacher')}
                      disableExternalBehaviour
                      dataTestId="GOTO_LOGIN:TEACHER"
                    />
                    <Button
                      id="signIn"
                      type={buttonTypes.GHOST_INVERTED}
                      glyph={GLYPHS.ICON_USER}
                      text={content.sign_in_student_text}
                      onClick={() => ORBSiteHeader.redirectToIDP('student')}
                      disableExternalBehaviour
                      dataTestId="GOTO_LOGIN:STUDENT"
                    />
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default compose(withLocalizedContent('siteHeaders'), withTheme, connectRedux)(ORBSiteHeader);
