import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { ErrorStatus, PageWrapper } from '../../../../components';
import PopoutPanelIconHeading, { types } from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import FileUploadInput from '../../../../components/FileUploadInput/FileUploadInput';
import TextInputLabel from '../../../../components/TextInputLabel/TextInputLabel';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import Link from '../../../../components/Link/Link.js';
import Card from '../../../../components/Card/Card.js';

import colors from '../../../../globals/colors';
import styles from '../../ProductUpload/ProductUpload.scss';

import { courseUploadRequest, resetForm } from '../../../../redux/actions/courseUpload.js';
import { resetForm as resetFormLogs } from '../../../../redux/reducers/productUploadLogs.reducer.js';

import withLocalizedContent from '../../../../language/withLocalizedContent';
import CsvProductStructureUploadSuccess from '../../../../components/CsvProductStructureUpload/CsvProductStructureUploadSuccess';

class CourseUploadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseUploadFileIsAdded: false
    };
  }

  _courseUploadFileChange = value => {
    this.setState({
      courseUploadFileIsAdded: value
    });
  };

  _onSubmit = () => {
    const { courseUploadRequestAction } = this.props;
    const { courseUploadFileIsAdded } = this.state;

    const formData = new FormData();
    formData.append('file', courseUploadFileIsAdded);

    courseUploadRequestAction(formData);
  };

  render() {
    const supportedFileTypes = ['.xlsx'];
    const { courseUploadFileIsAdded } = this.state;

    const {
      localizedContent: { courseUploadPage: content },
      redirect,
      loading,
      error,
      errorMessage,
      success,
      successMessage
    } = this.props;

    return (
      <PageWrapper>
        <Helmet title={content.title} />
        <div>
          <Card headingColor={colors.PROFILE} showHeadingLabel={false}>
            <TextInputLabel id="courseUploadInputLabel" label={content.fileInputTextLabel} />
            <FileUploadInput
              id="courseUploadInput"
              label={content.fileInputLabel}
              buttonText={content.fileInputButtonLabel}
              supportedFileTypes={supportedFileTypes}
              handleFile={file => this._courseUploadFileChange(file)}
            />
          </Card>
        </div>
        <Button
          id="uploadButton"
          type={buttonTypes.PRIMARY}
          text={content.submitButton}
          customClassName={styles.uploadButton}
          onClick={this._onSubmit}
          disabled={!courseUploadFileIsAdded}
        />
        {loading ? <PopoutPanelIconHeading type={types.LOADING} title={content.waitingMessage} /> : null}
        {error ? (
          <ErrorStatus
            title={errorMessage}
            buttonText={content.backButton}
            buttonOnClickHandler={() => redirect('/courses')}
          />
        ) : null}
        {success && (
          <>
            <CsvProductStructureUploadSuccess
              content={content}
              warnings={successMessage.warnings || []}
              goBackToDashboard={() => redirect('/courses')}
            />
            <div className="row cols-center">
              <span className="gin-right2">
                <Link to={`course-preview/${successMessage.courseCode}/courseMaterial`}>
                  <Button id="preview-course-button" type={buttonTypes.SECONDARY} text={content.previewButton} />
                </Link>
              </span>
            </div>
          </>
        )}
      </PageWrapper>
    );
  }
}

CourseUploadPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool,
  successMessage: PropTypes.string,
  courseUploadRequestAction: PropTypes.func
};

export default compose(
  withRouter,
  withLocalizedContent('courseUploadPage'),
  connect(
    state => ({
      loading: state.courseUpload.loading,
      error: state.courseUpload.error,
      errorMessage: state.courseUpload.errorMessage,
      success: state.courseUpload.success,
      successMessage: state.courseUpload.successMessage
    }),
    (dispatch, props) => ({
      redirect: path => {
        dispatch(resetForm());
        dispatch(resetFormLogs());
        props.history.push(path);
      },
      courseUploadRequestAction: data => dispatch(courseUploadRequest(data))
    })
  )
)(CourseUploadPage);
