import PropTypes from 'prop-types';
import React from 'react';
import Button, { buttonTypes } from '../Button/Button';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../language/withLocalizedContent';

function OicEmailsSuccess({ localizedContent: { oicEmailsSuccess: content }, response }) {
  return (
    <div className="grid">
      <div className="row">
        <div className="col">
          {response.data.success.length > 0 && (
            <div>
              <PopoutPanelIconHeading type={types.VALID} title={content.title_success} />
            </div>
          )}

          {/* it's less probable that both conditions are true at the same time,
            so we will not have both success and fail message at once */}
          {response.data.failed.length > 0 && (
            <PopoutPanelIconHeading type={types.ERROR} title={content.title_failed} />
          )}

          <div className="row cols-center">
            <div className="col">
              <div className="gin-top3">
                <Button
                  id="secondary-button"
                  type={buttonTypes.SECONDARY}
                  text={content.secondary_button_text}
                  onClick={() => {
                    window.location.href = '/dashboard';
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

OicEmailsSuccess.propTypes = {
  localizedContent: PropTypes.object,
  response: PropTypes.object
};

export default withLocalizedContent('oicEmailsSuccess')(OicEmailsSuccess);
