import { takeLatest, takeEvery } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import loadPlacementTests from './loadPlacementTests';
import { GET_WIZARD_TEST_CREDITS_REQUEST } from '../../../../reducers/placementTestSessionCreate';
import optGetTestCredits from '../placementOnboardingWizard/optGetTestCredits';

function* root() {
  yield takeLatest(t.GET_PLACEMENT_TESTS, ({ payload }) => loadPlacementTests(payload));
  yield takeEvery(GET_WIZARD_TEST_CREDITS_REQUEST, ({ payload }) => optGetTestCredits(payload));
}

export default root;
