import * as t from '../actionTypes';

const initialState = [];

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.GET_PLATFORM_COUNTRY_CODES_REQUEST:
      return initialState;
    case t.GET_PLATFORM_COUNTRY_CODES_SUCCESS:
      return [...payload];
    default:
      return state;
  }
};
