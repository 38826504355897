import * as t from '../actionTypes';

export const mode = {
  INPUT: 'INPUT',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  EMPTY: 'EMPTY'
};

const initialState = {
  mode: mode.INPUT,
  formData: {},
  errors: {}
};

function orgSubscription(state = initialState, { type, payload }) {
  switch (type) {
    case t.CREATE_ORG_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        mode: mode.LOADING
      };
    case t.CREATE_ORG_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        mode: mode.ERROR,
        errors: payload
      };
    case t.CREATE_ORG_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        mode: mode.SUCCESS,
        formData: {
          ...state.formData,
          ...payload
        },
        errors: {}
      };
    case t.CREATE_ORG_SUBSCRIPTION_INPUT:
      return {
        ...state,
        formData: {
          ...state.formData,
          [payload.field]: payload.value || ''
        }
      };
    case t.CREATE_ORG_SUBSCRIPTION_VALIDATED:
      return {
        ...state,
        mode: mode.INPUT,
        errors: {
          ...state.errors,
          ...payload
        }
      };
    case t.CREATE_ORG_SUBSCRIPTION_RESET:
      return initialState;
    case t.CREATE_ORG_SUBSCRIPTION_REPEAT:
      return {
        ...state,
        mode: mode.INPUT,
        formData: {
          ...state.formData,
          ...payload
        },
        errors: {}
      };
    default:
      return state;
  }
}

export default orgSubscription;
