import React from 'react';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../language/withLocalizedContent';
import PopoutNavFooter from '../PopoutNavFooter/PopoutNavFooter';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import styles from './ImportUsersErrorStatus.scss';

export const ErrorTypes = {
  UNRECOGNISED_DATA: 'UNRECOGNISED_DATA',
  MISSING_COLUMNS: 'MISSING_COLUMNS',
  TOO_MANY_ROWS: 'TOO_MANY_ROWS'
};

class ImportUsersErrorStatus extends React.Component {
  _getContent = () => {
    const {
      type = ErrorTypes.UNRECOGNISED_DATA,
      localizedContent: { importUsersErrorStatus: content }
    } = this.props;

    switch (type) {
      case ErrorTypes.TOO_MANY_ROWS:
        return {
          heading: content.too_many_rows_heading,
          detail: content.too_many_rows_detail
        };
      case ErrorTypes.MISSING_COLUMNS:
        return {
          heading: content.missing_columns_heading,
          detail: content.missing_columns_detail,
          instruction: content.missing_columns_instruction
        };
      default:
        return {
          heading: content.default_heading,
          detail: content.default_detail
        };
    }
  };

  render() {
    const { reason = '', onBack } = this.props;
    const content = this._getContent();

    return (
      <ScrollContainer>
        <PopoutPanelIconHeading type={types.WARNING_ERROR} title={content.heading} subtitle={content.detail}>
          <div className={styles.heading}>
            {reason ? <div className="gin-top2">{reason}</div> : null}
            <div className="gin-top2">{content.instruction}</div>
          </div>
        </PopoutPanelIconHeading>
        <PopoutNavFooter backAction={onBack} />
      </ScrollContainer>
    );
  }
}

ImportUsersErrorStatus.propTypes = {
  type: PropTypes.string,
  reason: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('importUsersErrorStatus')(ImportUsersErrorStatus);
