import PropTypes from 'prop-types';
import React from 'react';
import ErrorStatus from '../../../components/ErrorStatus/ErrorStatus';
import SimpleHeader from '../SimpleHeader/SimpleHeader.js';

function ErrorPage({ appErrorReference, showHeader, customLogoComponent }) {
  return (
    <div>
      {showHeader && <SimpleHeader customLogoComponent={customLogoComponent} />}
      <main id="maincontent" style={{ padding: '3em' }}>
        <ErrorStatus
          title="App Failed"
          subtitle="An error occurred. Please reload the application."
          appErrorReference={appErrorReference}
          buttonText="Reload"
          buttonOnClickHandler={() => {
            window.location.reload();
          }}
        />
      </main>
    </div>
  );
}

ErrorPage.propTypes = {
  appErrorReference: PropTypes.string,
  customLogoComponent: PropTypes.element,
  showHeader: PropTypes.bool
};

ErrorPage.defaultProps = {
  showHeader: true
};

export default ErrorPage;
