import * as t from '../actionTypes';

export const searchForProductAndProductGroup = data => ({
  type: t.SEARCH_FOR_PRODUCT_OR_PRODUCTGROUP,
  data
});

export const searchForActCodeBatch = data => ({
  type: t.SEARCH_ACT_CODE_BATCH,
  data
});

export const setResultActCodeBatchSearch = data => ({
  type: t.SET_RESULT_ACT_CODE_BATCH,
  data
});

export const setErrorActCodeBatchSearch = data => ({
  type: t.SET_ERROR_ACT_CODE_BATCH,
  data
});

export const stopSearchForActCodeBatch = data => ({
  type: t.STOP_SEARCH_ACT_CODE_BATCH,
  data
});

export const emptySearchResultsForActCodeBatch = data => ({
  type: t.EMPTY_SEARCH_ACT_CODE_BATCH_RESULTS,
  data
});

export const downloadCodeBatch = data => ({
  type: t.DOWNLOAD_ACT_CODE_BATCH_CODES,
  data
});
