import Dot from 'dot-object';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Card from '../Card/Card';
import ContactUsDetails from '../ContactUsDetails/ContactUsDetails';

function ContactUs({ contactUsDetails }) {
  // Using dot-object to group key/value pairs by their country prefix
  const dotunderscore = new Dot(':');
  const nestedObject = dotunderscore.object({ ...contactUsDetails });

  const countryGlossaries = Object.keys(nestedObject)
    .filter(key => nestedObject.country_keys.split(',').includes(key))
    .map(glossaryKey => ({
      ...nestedObject[glossaryKey],
      // Adding back the universal key/value pairs to each glossary
      ...pick(contactUsDetails, [
        'contact_information_telephone_text',
        'contact_information_address_text',
        'contact_information_online_chat_text'
      ])
    }));
  return (
    <div className="grid">
      <div className="row">
        {countryGlossaries.map(glossary => (
          <div key={glossary.card_header} className="col sm4 pad-top4">
            <Card headingColor="dashboard" headingText={glossary.card_header}>
              <ContactUsDetails glossary={glossary} />
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

ContactUs.propTypes = {
  contactUsDetails: PropTypes.object.isRequired
};

export default ContactUs;
