import { createSelector } from 'reselect';

/**
 * A selector returning the record for the currently authenticated user.
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const getCurrentUser = createSelector(
  state => state.identity.userId,
  state => state.people.data,
  (userId, data) => data[userId]
);

export default getCurrentUser;
