const { ORG_STUDENTS, ORG_STAFF, OUP_STAFF } = require('./constants');
const { isManagedOrg, orgRoles: organisationRoles } = require('./org');

// NOTE: These values are based on the `getAllRoles` results from EAC
const userRoles = {
  // New user
  USER: 'USER',

  // General Org roles
  MANAGED_USER: 'MANAGED_USER',
  LEARNER: 'LEARNER',
  TEACHER: 'TEACHER',
  TEACHER_ADMIN: 'TEACHER_ADMIN',
  ORG_ADMIN: 'ORG_ADMIN',

  // OUP only roles
  OUP_ADMIN: 'OUP_ADMIN',
  OUP_SUPPORT: 'OUP_SUPPORT',
  OUP_SERVICE_MANAGER: 'OUP_SERVICE_MANAGER',
  OUP_CONTENT: 'OUP_CONTENT',
  OUP_CONTENT_REVIEWER: 'OUP_CONTENT_REVIEWER',

  // System roles (deprecated - instead use machine-to-machine APIs with a consumer key/secret approach)
  TRUSTED_SYSTEM: 'TRUSTED_SYSTEM',
  // External content roles
  EXTERNAL_CONTENT: 'EXTERNAL_CONTENT'
};

const validUserRoles = Object.values(userRoles);

/**
 * USER TYPES are a self-selected concept added by Hub - they affect the UI defaults
 * Beware: USER ROLES sound similar but are very different - they are formal associations with
 * orgs and are about permissions. A user has one usertype across EPS ("I see myself as a teacher")
 * but may have a different role in different orgs (in one org they may be a TEACHER,
 * in another an ORG_ADMIN etc or even a LEARNER).
 */
const selfDefinedUserTypes = {
  CLASS_TEACHER: 'teacher',
  CLASS_STUDENT: 'student'
};

/** Avoid exposing these to user. Use locale-defined glossaries whenever possible */
const userRoleNameDefaults = {
  // General Org roles
  [userRoles.MANAGED_USER]: 'Student',
  [userRoles.LEARNER]: 'Student',
  [userRoles.TEACHER]: 'Teacher',
  [userRoles.TEACHER_ADMIN]: 'Class Administrator',
  [userRoles.ORG_ADMIN]: 'Organization Administrator',

  // OUP only roles
  [userRoles.OUP_ADMIN]: 'OUP Administrator',
  [userRoles.OUP_SUPPORT]: 'OUP Support',
  [userRoles.OUP_SERVICE_MANAGER]: 'OUP Service Manager',
  [userRoles.OUP_CONTENT]: 'OUP Content',
  [userRoles.OUP_CONTENT_REVIEWER]: 'OUP Content Reviewer',

  // External roles
  [userRoles.EXTERNAL_CONTENT]: 'External Content'
};

const selfSelectedUserRoles = {
  SELF_LEARNER: 'SELF_LEARNER',
  SELF_TEACHER: 'SELF_TEACHER'
};

// VST Platform Roles
const vstUserRoles = {
  ADMIN: 'ADMIN',
  TEACHER: 'TEACHER',
  STUDENT: 'STUDENT'
};

// UNKNOWN user Platform Role
const UNKNOWN_PLATFORM_USER_ROLE = 'UNKNOWN';

// Materials Target User Types
const materialTargetUserTypes = {
  TEACHER: 'teacher',
  STUDENT: 'student'
};

const materialTargetUserTypesHierarchy = [materialTargetUserTypes.TEACHER, materialTargetUserTypes.STUDENT];

function getPluralFormOfRole(roleName = '', suffix = 'S') {
  // one can imagine exceptions to this but they don't exist so far
  return `${roleName}${suffix}`; // e.g. STUDENT becomes STUDENTS
}

/** users with superadmin type permissions to see all users, send email to any user etc */
const systemLevelUsers = [userRoles.TRUSTED_SYSTEM, userRoles.OUP_ADMIN, userRoles.OUP_SUPPORT];

/** new users who have no defined role in an org */
const guestUserHierarchy = {
  [userRoles.USER]: 0
};

/** users who do not manage their own accounts (org admins CRUD them) and cannot link their account to other orgs */
const managedUsersHierachy = {
  [userRoles.MANAGED_USER]: 10
};

// map user roles to a hierarchy level value. Lower level value = lower in hierarchy.
// Using the user role as key and not the level value because there might be roles
// with the same hierarchy level
const orgRolesHierarchy = {
  // these are all users within an org
  [userRoles.MANAGED_USER]: 20,
  [userRoles.LEARNER]: 30,
  [userRoles.TEACHER]: 40,
  [userRoles.TEACHER_ADMIN]: 50,
  [userRoles.ORG_ADMIN]: 60
};

// The following maps the OUP Staff roles similarly to the orgRoles above
const oupRolesHierarchy = {
  [userRoles.OUP_SERVICE_MANAGER]: 70,
  [userRoles.OUP_SUPPORT]: 90,
  [userRoles.OUP_ADMIN]: 90,
  [userRoles.OUP_CONTENT_REVIEWER]: 20
};

// The following maps the OUP Staff roles similarly to the orgRoles above
const oupContentRolesHierarchy = {
  [userRoles.OUP_SERVICE_MANAGER]: 70,
  [userRoles.OUP_SUPPORT]: 90,
  [userRoles.OUP_ADMIN]: 90,
  [userRoles.OUP_CONTENT]: 30
};

const ELTReviewerRolesHierarchy = {
  [userRoles.OUP_SUPPORT]: 90,
  [userRoles.OUP_ADMIN]: 90,
  [userRoles.OUP_CONTENT]: 30,
  [userRoles.OUP_CONTENT_REVIEWER]: 20,
  [userRoles.EXTERNAL_CONTENT]: 20
};

const externalContentRoles = {
  [userRoles.EXTERNAL_CONTENT]: 10
};

/**
 * System users (servers that call standard EPS APIs with a user role)
 *
 * Avoid this - prefer to generate consumer keys and secrets per system calling machine-to-machine APIs */
const systemRbacUserHierarchy = {
  [userRoles.TRUSTED_SYSTEM]: 100
};

const userRolesHighToLow = [
  userRoles.TRUSTED_SYSTEM,
  userRoles.OUP_ADMIN,
  userRoles.OUP_SUPPORT,
  userRoles.OUP_CONTENT,
  userRoles.OUP_SERVICE_MANAGER,
  userRoles.ORG_ADMIN,
  userRoles.TEACHER_ADMIN,
  userRoles.TEACHER,
  userRoles.LEARNER,
  userRoles.MANAGED_USER,
  userRoles.OUP_CONTENT_REVIEWER,
  userRoles.EXTERNAL_CONTENT,
  userRoles.USER
];

const userRoleLevel = {
  OUP_ADMIN: '1',
  TRUSTED_SYSTEM: '1',
  OUP_SERVICE_MANAGER: '2',
  OUP_SUPPORT: '3',
  ORG_ADMIN: '4',
  TEACHER_ADMIN: '5',
  TEACHER: '6',
  LEARNER: '7',
  MANAGED_USER: '7',
  OUP_CONTENT: '8',
  EXTERNAL_CONTENT: '9',
  OUP_CONTENT_REVIEWER: '10'
};

const rolesThatCanEditAnyClassInOrg = [userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN];

const rolesLimitedToEditingOwnClasses = [userRoles.TEACHER];

const isClassAdminRoleIfMember = {
  [userRoles.TEACHER]: true
};

const isSuperUserRole = (role = '') =>
  Object.keys(oupRolesHierarchy).includes(role) || Object.keys(systemRbacUserHierarchy).includes(role);

const roleCanAdminClasses = (role = '') => rolesThatCanEditAnyClassInOrg.includes(role);

const roleCanAdminClassesIfGroupMember = (role = '') => rolesLimitedToEditingOwnClasses.includes(role);

/** high to low array of user roles that are linked to normal (non-OUP_MASTER_GROUP) orgs */
const orgUserRolesHighToLow = userRolesHighToLow.filter(
  role =>
    Object.keys(orgRolesHierarchy).includes(role) &&
    // exclude systems
    !Object.keys(systemRbacUserHierarchy).includes(role) &&
    // exclude "guest" users with no org-specific role
    !Object.keys(guestUserHierarchy).includes(role)
);

/** high to low array of user roles that are OUP Staff super user roles */
const oupUserRolesHighToLow = userRolesHighToLow.filter(role => Object.keys(oupRolesHierarchy).includes(role));

const externalContentRolesHighToLow = userRolesHighToLow.filter(role =>
  Object.keys(externalContentRoles).includes(role)
);
/** high to low array of user roles that can self-register in one or more (non-OUP_MASTER_GROUP) orgs */
const selfRegisteredOrgUserRoles = orgUserRolesHighToLow.filter(
  role => !Object.keys(managedUsersHierachy).includes(role)
);

/** first argument is the minimum role, second argument is the user role to check */
const roleIsAtLeast = (minimumRole, userRole) =>
  userRole && userRolesHighToLow.indexOf(userRole) <= userRolesHighToLow.indexOf(minimumRole);

const canEditGroup = (userId, userRoleInOrg = '', groupMembers = []) => {
  if (isSuperUserRole(userRoleInOrg)) {
    // Note: this is a strange question because an OUP_ADMIN or similar would not be a member of a typical ORG
    // but this is the accurate boolean response answer and is true of such users when operating in OUP_MASTER_GROUP
    console.warn('Strange question about org roles, check logic.');
    return true;
  }

  if (roleCanAdminClasses(userRoleInOrg)) {
    // such user can edit all groups in their org
    return true;
  }

  if (roleCanAdminClassesIfGroupMember(userRoleInOrg) && groupMembers.includes(userId)) {
    // TEACHER roles can only edit groups if they are a member
    return true;
  }

  return false;
};

function isTeacherAdminOrAbove(roleName = '') {
  return roleIsAtLeast(userRoles.TEACHER_ADMIN, roleName);
}

function isTeacherOrAbove(roleName = '') {
  return roleIsAtLeast(userRoles.TEACHER, roleName);
}

/** returns true if the first roleName parameter is higher (more priviled) than the second  */
const roleIsHigher = (role1, role2) => {
  const role1Index = userRolesHighToLow.indexOf(role1);
  const role2Index = userRolesHighToLow.indexOf(role2);

  if (role1Index === -1) throw new Error(`Unsupported role: ${role1}`);
  if (role2Index === -1) throw new Error(`Unsupported role: ${role2}`);

  return role1Index < role2Index;
};

const oupRoles = Object.keys(oupRolesHierarchy);
const orgRoles = Object.keys(orgRolesHierarchy);
const orgStaffRoles = [userRoles.TEACHER, userRoles.TEACHER_ADMIN, userRoles.ORG_ADMIN];

function getMatchingStudentRoles(orgRole) {
  const managedStudentRole = userRoles.MANAGED_USER;
  const selfRegisteredStudentRole = userRoles.LEARNER;
  return isManagedOrg(orgRole) ? [managedStudentRole] : [selfRegisteredStudentRole];
}

// Returns an array of the userRoles that the provided userRole is allowed to enrol.
// NOTE: This may be extended in the future to only allow users to enrol people of an equal or lower role than their own
function getRolesAllowedToRegister(
  currentUserRole,
  actionContext,
  orgRole,
  isOicMode,
  currentOrganisationLti = false,
  userTypeForOptOrg = false
) {
  if (currentOrganisationLti) {
    if (currentUserRole === userRoles.ORG_ADMIN) {
      return [userRoles.ORG_ADMIN];
    }

    return [];
  }

  const oupStaffRoles = Object.keys(oupRolesHierarchy);

  const orgStaffRolesFiltered = orgStaffRoles.filter(orgStaffRole => roleIsAtLeast(orgStaffRole, currentUserRole));
  if (currentUserRole === userRoles.OUP_ADMIN) {
    oupStaffRoles.push(userRoles.OUP_CONTENT);
    oupStaffRoles.push(userRoles.EXTERNAL_CONTENT);
  }

  switch (actionContext) {
    case ORG_STUDENTS:
      return getMatchingStudentRoles(orgRole);
    case ORG_STAFF:
      if (userTypeForOptOrg) {
        return orgStaffRolesFiltered.filter(role => role !== userRoles.TEACHER);
      }
      if (orgRole === organisationRoles.TEST_CENTER || isOicMode) {
        return orgStaffRolesFiltered.filter(role => role !== userRoles.TEACHER_ADMIN);
      }
      return orgStaffRolesFiltered;
    case OUP_STAFF:
      return oupStaffRoles;
    case 'ORG_ADMIN': // this constant is confusingly the same as its contents - suggest refactor to 'ORG_OWNERS' in future
      return [userRoles.ORG_ADMIN];
    default:
      return [];
  }
}

function getHighestRole(...roleNames) {
  let highestRole;
  userRolesHighToLow.forEach(roleName => {
    if (!highestRole && roleNames.indexOf(roleName) >= 0) {
      highestRole = roleName;
    }
  });

  return highestRole;
}

const roleCanRegisterUser = roleName => roleIsAtLeast(userRoles.ORG_ADMIN, roleName);
const roleCanCreateClass = roleName => roleIsAtLeast(userRoles.TEACHER, roleName);
const roleCanRegisterOupUser = roleName => roleIsAtLeast(userRoles.OUP_ADMIN, roleName);
const roleCanArchiveOupUser = roleName => roleIsAtLeast(userRoles.OUP_ADMIN, roleName);
const roleCanArchiveOrgUser = roleName => roleIsAtLeast(userRoles.ORG_ADMIN, roleName);
const roleCanArchiveOrg = roleName => roleIsAtLeast(userRoles.OUP_ADMIN, roleName);
const roleCanViewActivityLog = roleName => roleIsAtLeast(userRoles.TEACHER, roleName);
const roleCanGetLicence = roleName => roleIsAtLeast(userRoles.LEARNER, roleName);
const roleCanAssignToRole = assignerRole => isTeacherOrAbove(assignerRole);
const roleCanLinkOicOrgToJanison = roleName => roleIsAtLeast(userRoles.OUP_SUPPORT, roleName);
const roleNeedsProductLicense = roleName => orgRolesHierarchy[roleName] || roleName === userRoles.USER;

const isOrgStaff = role => orgStaffRoles.includes(role);
const isOupStaff = role => Object.keys(oupRolesHierarchy).includes(role);
const isOupContent = role => Object.keys(oupContentRolesHierarchy).includes(role);
const isELTReviewer = role => Object.keys(ELTReviewerRolesHierarchy).includes(role);
const canSelfAssign = role => [userRoles.TEACHER_ADMIN, userRoles.ORG_ADMIN].includes(role);
const isOrgAdmin = roleName => roleName === userRoles.ORG_ADMIN;

const canAssignToUser = (assignerUserRole, assignerUserId, targetUserId) => {
  const assignmentAllowedByHierarchy = roleCanAssignToRole(assignerUserRole);
  const selfAssignIsPossible = canSelfAssign(assignerUserRole) && targetUserId === assignerUserId;
  return assignmentAllowedByHierarchy || selfAssignIsPossible;
};

const getContextName = ({ userDetails, content }) =>
  userDetails.length === 1
    ? `${userDetails[0]?.firstname || ''} ${userDetails[0]?.lastname || ''}`
    : `${userDetails.length} ${content.students_text}`;

const canPublishAndUploadCourses = role => [userRoles.OUP_ADMIN, userRoles.OUP_CONTENT].includes(role);

const nonCustomerUserRoles = {
  OUP_ADMIN: 'OUP_ADMIN',
  OUP_CONTENT: 'OUP_CONTENT',
  OUP_SERVICE_MANAGER: 'OUP_SERVICE_MANAGER',
  OUP_SUPPORT: 'OUP_SUPPORT',
  EXTERNAL_CONTENT: 'EXTERNAL_CONTENT'
};

module.exports = {
  userRoles,
  selfSelectedUserRoles,
  userRoleNameDefaults,
  systemLevelUsers,
  guestUserHierarchy,
  orgRolesHierarchy,
  systemRbacUserHierarchy,
  oupRolesHierarchy,
  oupContentRolesHierarchy,
  userRolesHighToLow,
  orgUserRolesHighToLow,
  externalContentRolesHighToLow,
  oupUserRolesHighToLow,
  selfRegisteredOrgUserRoles,
  isClassAdminRoleIfMember,
  validUserRoles,
  oupRoles,
  orgRoles,
  orgStaffRoles,
  selfDefinedUserTypes,
  vstUserRoles,
  UNKNOWN_PLATFORM_USER_ROLE,
  materialTargetUserTypes,
  materialTargetUserTypesHierarchy,
  getPluralFormOfRole,
  roleIsAtLeast,
  roleCanAdminClasses,
  canEditGroup,
  isTeacherAdminOrAbove,
  isTeacherOrAbove,
  isOupStaff,
  isOupContent,
  isELTReviewer,
  isSuperUserRole,
  canSelfAssign,
  getRolesAllowedToRegister,
  roleIsHigher,
  roleCanRegisterUser,
  roleCanCreateClass,
  roleCanRegisterOupUser,
  roleCanArchiveOupUser,
  roleCanArchiveOrgUser,
  roleCanArchiveOrg,
  roleCanViewActivityLog,
  roleCanGetLicence,
  roleCanAssignToRole,
  roleCanLinkOicOrgToJanison,
  roleNeedsProductLicense,
  canAssignToUser,
  getHighestRole,
  isOrgStaff,
  isOrgAdmin,
  userRoleLevel,
  getContextName,
  canPublishAndUploadCourses,
  nonCustomerUserRoles
};
