// Helper to determine array equality.
// Expects 2 arrays and returns true if the arrays contain the same elements and false otherwise
// Note that element position is not taken into consideration, eg equals([1,2],[2,1]) will return true

export default function equals(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every(val => arr2.indexOf(val) >= 0);
}
