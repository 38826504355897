import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import deleteLMSConfigApi from '../../../apiCalls/lmsConfig/deleteLMSConfigApi';

function* deleteLMSConfig(orgId, toolId) {
  const response = yield deleteLMSConfigApi(orgId, toolId);

  if (response && response.status === 'success') {
    yield put(actions.deleteLMSConfigSuccess(response.data));
  } else {
    yield put(actions.deleteLMSConfigFailure(response.data));
  }
}

export default deleteLMSConfig;
