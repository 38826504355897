import * as t from '../../actionTypes';

const getLocalStoredState = () => {
  const storedProducts = localStorage.getItem('offline_products');
  const products = storedProducts ? JSON.parse(storedProducts) : {};

  const storedProfiles = localStorage.getItem('offline_profiles');
  const profiles = storedProfiles ? JSON.parse(storedProfiles) : {};

  const storedUnits = localStorage.getItem('offline_units');
  const units = storedUnits ? JSON.parse(storedUnits) : [];

  return { products, profiles, units };
};

export const initialState = {
  products: {},
  profiles: {},
  units: []
};

export default function offlineContentPlayer(state = getLocalStoredState(), action = {}) {
  switch (action.type) {
    case t.LOAD_OFFLINE_PRODUCTS: {
      return {
        ...state,
        products: action.payload
      };
    }
    case t.LOAD_OFFLINE_PROFILES: {
      return {
        ...state,
        profiles: action.payload
      };
    }
    case t.LOAD_OFFLINE_UNITS: {
      return {
        ...state,
        units: action.payload
      };
    }
    default:
      return { ...state };
  }
}
