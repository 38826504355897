import * as t from '../../actionTypes';

const initialState = {
  data: {},
  success: false,
  error: false,
  loading: false,
  closePreferences: false
};

function orbUserPreferences(state = initialState, { type, payload }) {
  switch (type) {
    case t.ORB_USER_PREFERENCES_REQUEST:
      return {
        ...state,
        success: false,
        error: false,
        loading: true
      };
    case t.ORB_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        data: payload,
        success: true,
        error: false,
        loading: false
      };
    case t.ORB_USER_PREFERENCES_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      };
    case t.SET_ORB_USER_PREFERENCES_REQUEST:
      return {
        ...state,
        success: false,
        error: false,
        loading: true
      };
    case t.SET_ORB_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        data: payload,
        success: true,
        error: false,
        loading: false
      };
    case t.SET_ORB_USER_PREFERENCES_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      };
    case t.CLOSE_ORB_USER_PREFERENCES:
      return {
        ...state,
        closePreferences: true
      };
    default:
      return state;
  }
}

export default orbUserPreferences;
