export default function getTestData(testData = {}) {
  if (!testData.digitalResourceId && !testData.quadAssessment?.resource_id) return testData;

  return {
    ...testData,
    format: 'asmt'
  };
}

export function getTestTitle(testData = {}) {
  return !testData.parent_resource_id ? testData.title || '' : testData.resource_title || '';
}

export function getTestPrintableResourceId(testData = {}, isAdaptedTest = false) {
  const isAdapted = isAdaptedTest || testData.parent_resource_id;
  return !isAdapted ? testData.printableResourceId || '' : testData?.wordPackage?.resource_id || '';
}

export function getTestDigitaleResourceId(testData = {}, isAdaptedTest = false) {
  const isAdapted = isAdaptedTest || testData.parent_resource_id;
  return !isAdapted ? testData.digitalResourceId || '' : testData?.quadAssessment?.resource_id || '';
}
