import { put } from 'redux-saga/effects';
import genericFetch from '../../../apiCalls/util/genericFetch.js';
import actions from '../../../../actions/index.js';

function* submit(data) {
  const url = data;

  yield put(actions.progressBarNavigationResetToDefault());

  const response = yield genericFetch('progressBarNavigationCheck', [
    url,
    {
      method: 'GET'
    }
  ]);

  if (response) {
    const count = (response.match(/<\/screen>/g) || []).length;

    if (count > 1) {
      yield put(actions.progressBarNavigationDisable());
    } else {
      yield put(actions.progressBarNavigationEnable());
    }
  }
}

export default submit;
