import React from 'react';
// Styles
import PropTypes from 'prop-types';
import styles from './ResourceModalFooter.scss';
// Components
import Button from '../Button/Button';
import { GLYPHS } from '../SVGIcon/SVGIcon';

function ResourceModalFooter({ resource, downloadResource = null, hubContent, downloadIsLoading }) {
  return (
    <div className={styles.resourceModalFooter}>
      <div className={styles.resourceModalFooterInfo}>
        <p>{resource.title}</p>
      </div>
      {downloadResource ? (
        <div>
          <Button
            id={resource.resourceId}
            text={hubContent.button_download_text}
            glyph={GLYPHS.ICON_DOWNLOAD}
            disabled={downloadIsLoading}
            onClick={() => downloadResource(resource)}
            dataAttribute={resource.title}
          />
        </div>
      ) : null}
    </div>
  );
}

ResourceModalFooter.propTypes = {
  resource: PropTypes.object.isRequired,
  downloadResource: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  downloadIsLoading: PropTypes.bool.isRequired
};

export default ResourceModalFooter;
