import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getTeacherResource from './getTeacherResource';
import getTeacherResources from './getTeacherResources';

function* root() {
  yield takeLatest(t.TEACHER_RESOURCES_REQUEST, ({ payload: titleIds }) => getTeacherResources(titleIds));
  yield takeLatest(t.TEACHER_RESOURCE_REQUEST, ({ payload: { titleId, resourceId } }) =>
    getTeacherResource(titleId, resourceId)
  );
}

export default root;
