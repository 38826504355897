import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import colors from '../../globals/colors';
import Button, { buttonTypes } from '../Button/Button';
import Card from '../Card/Card';
import FileUploadInput from '../FileUploadInput/FileUploadInput';
import TextInputLabel from '../TextInputLabel/TextInputLabel';
import PageHeading from '../PageHeading/PageHeading';
import { StatusEnum } from '../../redux/sagas/appFunctions/authorisedFunctions/csvProductStructureUpload/csvProductStructureUpload';
import actions from '../../redux/actions';
import BulkUploadInformationBubble from '../BulkUploadInformationBubble/BulkUploadInformationBubble';
import styles from './CsvProductStructure.scss';
import { getEnvType } from '../../globals/envSettings';

const supportedFileTypes = ['.csv'];
class CsvProductStructureUpload extends Component {
  _handleFileChange = value => {
    const { fileParse } = this.props;
    fileParse(value);
  };

  _onSubmit = operationData => {
    const { status, results, formSubmit } = this.props;
    if (status !== StatusEnum.InProgress) {
      formSubmit({ bIds: results, operation: operationData });
    }
  };

  render() {
    const { content, status } = this.props;
    return (
      <div className="grid pad-top2 pad-bot4">
        <div className="row gin-bot3">
          <div className="col sm10 pad-bot2">
            <PageHeading title={content.title} subtitle={content.subtitle} />
          </div>
          <div className="col md12">
            <Card headingColor={colors.PROFILE} showHeadingLabel={false}>
              <TextInputLabel id="fileUploadInputLabel" label={content.fileInputTextLabel} />
              <FileUploadInput
                id="fileUploadInput"
                label={content.fileInputLabel}
                buttonText={content.fileInputButtonLabel}
                supportedFileTypes={supportedFileTypes}
                handleFile={file => this._handleFileChange(file)}
              />
              <BulkUploadInformationBubble
                headerTitleText={content.download_template_label}
                downloadTemplateText={content.download_template_button_text}
                downloadFileTemplate={content.download_template_filename}
                downloadFileName={content.download_file_name}
                filecontent={content.download_file_content}
                bodyText={content.download_body}
              />
            </Card>
          </div>
        </div>
        <div className="container">
          {getEnvType() !== 'PROD' ? (
            <div className="row">
              <div className="col md4" />
              <div className="col md4 pad-top2">
                <Button
                  type={buttonTypes.PRIMARY}
                  text={content.stageButton}
                  onClick={() => this._onSubmit('stage')}
                  disabled={status === StatusEnum.InProgress}
                  id="stage"
                />
              </div>
              <div className="col md4" />
            </div>
          ) : (
            <div className="row">
              <div className="col md4" />
              <div className="col md4 pad-top2">
                <Button
                  type={buttonTypes.PRIMARY}
                  text={content.publishButton}
                  onClick={() => this._onSubmit('publish')}
                  disabled={status === StatusEnum.InProgress}
                  id="publish"
                />
              </div>
              <div className="col md4" />
            </div>
          )}
        </div>
        <div className="row pad-top2 gin-left4">
          <div className="col md12">
            <p>
              <b>Note: </b>
            </p>
            <p className={styles.bodyText}>
              <ul>
                <li>Staging will be done in UAT</li>
                <li>Publishing will be done in PROD</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

CsvProductStructureUpload.propTypes = {
  status: PropTypes.string.isRequired,
  results: PropTypes.array.isRequired,
  fileParse: PropTypes.func.isRequired,
  formSubmit: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired
};

export default connect(
  state => pick(state.csvProductStructureUpload, ['status', 'results', 'isFormValid', 'fileName']),
  dispatch => ({
    fileParse: source => {
      dispatch(actions.csvProductStructureUploadFileParseRequest({ source, status: StatusEnum.InProgress }));
    },
    formSubmit: formData => {
      dispatch(actions.csvProductStructureUpload(formData));
    },
    csvProductStructureInputUpdate: data => {
      dispatch(actions.csvProductStructureInputUpdate(data));
    }
  })
)(CsvProductStructureUpload);
