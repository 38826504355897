import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProductDetails.scss';

function ProductDetails({ id, title, subtitle, listData, description, children }) {
  const headingId = `${id}-heading`;

  return (
    <div id={id} className={styles.container}>
      <div>
        <h3 id={headingId} className={styles.title}>
          {title}
        </h3>{' '}
        {subtitle && <p className={styles.blockSubtitle}>{subtitle}</p>}
      </div>

      <div className={styles.inner}>
        <div className="row">
          <div className="col sm3">
            <ol aria-labelledby={headingId}>
              {listData.map(({ label, text, copyText }, index) => (
                <li key={index}>
                  <span>{label}</span>{' '}
                  <span>
                    {text} {copyText}
                  </span>
                </li>
              ))}
            </ol>
          </div>
          <div className="col sm9">
            <p>{description}</p>
          </div>
        </div>
      </div>

      {children ? <div className={styles.children}>{children}</div> : null}
    </div>
  );
}

ProductDetails.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  children: PropTypes.any
};

export default ProductDetails;
