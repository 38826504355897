import { useState } from 'react';

function useDefinitionState() {
  const [productName, setProductName] = useState('');
  const [organizationalUnit, setOrganizationalUnit] = useState();
  const [state, setState] = useState('ACTIVE');
  const [email, setEmail] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [productPlatforms, setProductPlatforms] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [externalIds, setExternalIds] = useState([]);
  const [protocol, setProtocol] = useState('');
  const [host, setHost] = useState('');
  const [path, setPath] = useState('');
  const [query, setQuery] = useState('');
  const [fragment, setFragment] = useState('');
  const [expression, setExpression] = useState('');

  const setProductNameFunction = param => setProductName(param);

  const setOrganizationalUnitFunction = param => setOrganizationalUnit(param);

  const setStateFunction = param => setState(param);

  const setEmailFunction = param => setEmail(param);

  const setAssociationsFunction = param => setAssociations(param);

  const setSelectedPlatformFunction = param => setSelectedPlatform(param);

  const setProductPlatformsFunction = param => setProductPlatforms(param);

  const setExternalIdsFunction = param => setExternalIds(param);

  const setProtocolFunction = param => setProtocol(param);

  const setHostFunction = param => setHost(param);

  const setPathFunction = param => setPath(param);

  const setQueryFunction = param => setQuery(param);

  const setFragmentFunction = param => setFragment(param);

  const setExpressionFunction = param => setExpression(param);

  const resetUrlFields = () => {
    setProtocolFunction('');
    setHostFunction('');
    setPathFunction('');
    setQueryFunction('');
    setFragmentFunction('');
    setExpressionFunction('');
  };

  const resetAllFields = () => {
    setProductNameFunction('');
    setOrganizationalUnitFunction('');
    setStateFunction('ACTIVE');
    setEmailFunction('');
    setAssociationsFunction([]);
    setExternalIdsFunction([]);
    resetUrlFields();
  };

  const setProductDetails = () => ({
    setProductNameFunction,
    setOrganizationalUnitFunction,
    setStateFunction,
    setEmailFunction,
    setAssociationsFunction,
    setSelectedPlatformFunction,
    setProductPlatformsFunction,
    setProtocolFunction,
    setHostFunction,
    setPathFunction,
    setQueryFunction,
    setFragmentFunction,
    setExpressionFunction,
    setExternalIdsFunction,
    resetUrlFields,
    resetAllFields
  });

  const productDetails = {
    productName,
    organizationalUnit,
    selectedPlatform,
    productPlatforms,
    state,
    email,
    associations,
    externalIds,
    protocol,
    host,
    path,
    query,
    fragment,
    expression
  };

  return [productDetails, setProductDetails];
}

export default useDefinitionState;
