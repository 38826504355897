import { JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const acceptOlbTermsFetchUrl = userId => `${__API_BASE__}/open/user/${userId}/olb-registration`;

export default function* acceptOlbTermsFetch(userId) {
  return yield signedFetch('acceptOlbTerms', acceptOlbTermsFetchUrl(userId), 'PUT', undefined, {
    Accept: JSON_ENCODED,
    'Content-Type': JSON_ENCODED
  });
}
