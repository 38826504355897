import signedFetch from '../apiCalls/util/signedFetch.js';

// @ts-ignore
export const getUrl = orgId => `${__API_BASE__}/org/${orgId}/users/restore`;

export default function* restoreOrgUsers({ orgId, userIdAndRoleNamePairs, notifyUsers, platformCode }) {
  return yield signedFetch('restoreOrgUsers', getUrl(orgId), 'POST', {
    userIdAndRoleNamePairs,
    notifyUsers,
    platformCode
  });
}
