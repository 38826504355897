import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withLocalizedContent from '../../language/withLocalizedContent';
import NumberWithOrdinal from '../NumberWithOrdinal/NumberWithOrdinal';

function AttemptOrdinal({ attemptNr, startDate, endDate, localizedContent: { ordinal: cardinalOrdinalMap } }) {
  const startDateMomentInstance = moment(startDate);
  const endDateMomentInstance = moment(endDate);
  const startDateNumber = parseInt(startDateMomentInstance.format('D'), 10);
  const endDateNumber = parseInt(endDateMomentInstance.format('D'), 10);
  const ordinal = cardinalOrdinalMap[attemptNr] ? cardinalOrdinalMap[attemptNr] : cardinalOrdinalMap.default;
  const hoursAtLevel = endDateMomentInstance.diff(startDateMomentInstance, 'hours');
  const atLevelMoreThanOneDay = hoursAtLevel > 24 || !startDateMomentInstance.isSame(endDateMomentInstance, 'day');

  const period = (
    <span>
      <NumberWithOrdinal number={startDateNumber} /> {startDateMomentInstance.format('MMMM')}
      {atLevelMoreThanOneDay && ' - '}
      {atLevelMoreThanOneDay && <NumberWithOrdinal number={endDateNumber} />}
      {atLevelMoreThanOneDay && ` ${endDateMomentInstance.format('MMMM')}`}
    </span>
  );

  return (
    <p>
      {attemptNr + ordinal} attempt ({period})
    </p>
  );
}

AttemptOrdinal.propTypes = {
  attemptNr: PropTypes.number.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('ordinal')(AttemptOrdinal);
