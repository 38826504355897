import { createSelector } from 'reselect';

/**
 * Takes the raw TR panel resources GET response and replies with the following data structure for use in the
 * horizontal radio buttons group:
 *
 * {
 * "22d68f17-ec04-4896-bce2-4bc2507875d8": {
*   "info": {
        "uuid": "22d68f17-ec04-4896-bce2-4bc2507875d8",
        "name": "High Five 1",
        "cover": "/cover/22d68f17-ec04-4896-bce2-4bc2507875d8",
        "level": "03 EDUCACION SECUNDARIA",
        "stage": "11 1º ESO",
        "titleType": {...},
        "project": {...},
        "serie": {...},
        "subject": [
          {...}
        ]
      },
      "units": [
          {...}...
      ]
    }...,
    verticalRadioNames: [{
                    "uuid": "5d508a9d-f128-41bb-8ca0-20da2d361c85",
                    "name": "General"
                },
                {
                    "uuid": "467f075c-513f-466e-a1f0-c151490ff029",
                    "name": "File 01"
                }]
 * }
 */
export default createSelector(
  state => state.teacherResources.resources,
  resources =>
    Object.entries(resources || {}).reduce((accum, [resourceKey, resourceValue]) => {
      if (resourceValue.status.toLowerCase() === 'success') {
        accum[resourceKey] = {
          info: resourceValue.info,
          units: resourceValue.units
        };
        // @ts-ignore
        accum.radioNames = [...(accum.radioNames ? accum.radioNames : []), ...resourceValue.units];

        return accum;
      }
      return null;
    }, {})
);
