import * as t from '../actionTypes';

export const searchUsers = data => ({
  type: t.SEARCH_USERS_REQUEST,
  payload: data
});

export const searchUsersSuccess = payload => ({
  type: t.SEARCH_USERS_SUCCESS,
  payload
});

export const searchUsersFailure = payload => ({
  type: t.SEARCH_USERS_FAILURE,
  payload
});
