import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getProductFinderProducts from './getProductFinderProducts';
import getProductFinderSeries from './getProductFinderSeries';
import getProductFinderProductsInSeries from './getProductFinderProductsInSeries';
import getProductFinderLicences from './getProductFinderLicences';
import assignProductFinderProducts from './assignProductFinderProducts';
import assignProductFinderMultipleProducts from './assignProductFinderMultipleProducts';

function* root() {
  yield takeLatest(t.PRODUCT_FINDER_GET_PRODUCTS_REQUEST, ({ payload }) => getProductFinderProducts(payload));
  yield takeLatest(t.PRODUCT_FINDER_GET_SERIES_REQUEST, ({ payload }) => getProductFinderSeries(payload));
  yield takeLatest(t.PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_REQUEST, ({ payload }) =>
    getProductFinderProductsInSeries(payload)
  );
  yield takeLatest(t.PRODUCT_FINDER_GET_LICENCES_REQUEST, ({ payload }) => getProductFinderLicences(payload));

  yield takeLatest(t.PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_REQUEST, ({ payload }) =>
    assignProductFinderMultipleProducts(payload)
  );

  yield takeLatest(t.PRODUCT_FINDER_ASSIGN_PRODUCTS_REQUEST, ({ payload }) => assignProductFinderProducts(payload));
}

export default root;
