import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextInputWithButton from '../../../../components/TextInputWithButton/TextInputWithButton';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import withLocalizedContent from '../../../../language/withLocalizedContent';

import { setCodeForDashboard, deleteActivationCode } from '../../../../redux/actions/activationCodeDashboard';

function ActivationCodeDeleteArea({
  localizedContent: { activationCodeDashboard: content },
  setActCode,
  deleteActCode
}) {
  const performCodeDeletion = () => {
    deleteActCode();
  };

  return (
    <div>
      <div>
        <h2>{content.delete_section}</h2>
        <TextInputWithButton
          placeholder={content.delete_label}
          onChange={setActCode}
          buttonAction={performCodeDeletion}
          buttonIcon={GLYPHS.ICON_DELETE}
        />
      </div>
    </div>
  );
}

ActivationCodeDeleteArea.propTypes = {
  localizedContent: PropTypes.object,
  setActCode: PropTypes.func,
  deleteActCode: PropTypes.func
};

const mapStateToProps = state => {
  const {
    activationCodeDashboard: { errorOccurred, errorMessage, success, successMessage }
  } = state;
  return { errorOccurred, errorMessage, success, successMessage };
};

export default compose(
  withLocalizedContent('activationCodeDashboard'),
  connect(mapStateToProps, dispatch => ({
    setActCode: code => {
      dispatch(setCodeForDashboard({ code }));
    },
    deleteActCode: () => {
      dispatch(deleteActivationCode());
    }
  }))
)(ActivationCodeDeleteArea);
