const getProductLicencesActionButtons = (
  content,
  { viewLicenceInformation = null, viewProductInformation = null, removeSelectedMaterial = null }
) => [
  {
    title: content.product_licences_view_information,
    action: viewLicenceInformation
  },
  {
    title: content.product_licences_view_product_information,
    action: viewProductInformation
  },
  {
    title: content.product_licences_remove_material,
    action: removeSelectedMaterial
  }
];

export default getProductLicencesActionButtons;
