import React from 'react';
import PropTypes from 'prop-types';
import TableAccordion from '../../../../components/TableAccordion/TableAccordion';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import styles from './CoursePreview.scss';

function CoursePreviewTable({ rows, columns, success, loading, content, customMessage = content.no_courses }) {
  if (success && rows && rows.length > 0) {
    return <TableAccordion columns={columns} rows={rows} />;
  }
  if (success && rows && rows.length === 0) {
    return <div className={styles.no_courses}>{customMessage}</div>;
  }
  if (loading) {
    return <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.LOADING} />;
  }

  return null;
}

CoursePreviewTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  success: PropTypes.bool,
  loading: PropTypes.bool,
  content: PropTypes.object,
  customMessage: PropTypes.string
};

export default CoursePreviewTable;
