import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './SlideupPanel.scss';

const ANIMATION_DELAY = 300; // 500 ms

export default class SlideupPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelOpen: props.isOpen,
      contentMounted: props.isOpen
    };
  }

  componentWillReceiveProps(nextProps) {
    // Work out if we need to animate
    const { isOpen } = this.props;
    if (!isOpen && nextProps.isOpen) {
      // If we are currently closed and becoming open
      //  1. Mount content
      //  2. Animate in
      this.setState({
        panelOpen: true,
        contentMounted: true
      });

      const { animationTimeout } = this.state;
      if (animationTimeout) {
        // clear any timeouts when re-rendering to avoid blank content in panel
        clearTimeout(animationTimeout);
      }
    } else if (isOpen && !nextProps.isOpen) {
      // If we are currently open and becoming closed
      //  1. Animate out
      //  2. Unmount content
      this.setState({
        panelOpen: false,

        // Remove content after a delay
        animationTimeout: setTimeout(
          () =>
            this.setState({
              contentMounted: false
            }),
          ANIMATION_DELAY
        )
      });
    }
  }

  render() {
    const { children } = this.props;
    const { panelOpen, contentMounted } = this.state;
    return (
      <div
        role="status"
        aria-atomic="true"
        className={classnames(styles.SlideupPanel, {
          [styles.isOpen]: panelOpen
        })}
      >
        <div className={styles.panel}>
          <div className="grid">
            <div className="row">
              <div className="col">{contentMounted && children}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
SlideupPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  /** Content to be displayed within the panel */
  children: PropTypes.any.isRequired
};
