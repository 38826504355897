import jsonFetch from '../util/jsonFetch';

export default async ({ token, items }) => {
  const url = `${__API_BASE__}/open/ltitool1v3/content-selection-confirmation`;

  const payload = { token, items };
  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify(payload);

  const response = await jsonFetch('ltiGetJwtAndDeeplinkReturnUrl', [url, { method: 'POST', headers, body }], false);
  return response;
};
