import React from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import ContentPreview from '../LorContentPage/ContentPreview';

function EpsContentArea() {
  return (
    <PageWrapper>
      <Helmet title="EPS Content Area" />
      <ContentPreview isEpsContentArea />
    </PageWrapper>
  );
}

export default EpsContentArea;
