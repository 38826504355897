import { takeLatest, takeEvery, throttle } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

import loadCourse from './loadCourse';
import getCourseBooks from './getCourseBooks';
import loadCourseBook from './loadCourseBook';
import loadCourseResource from './loadCourseResource';
import getCourseStructure from './getCourseStructure';
import getCourseResources from './getCourseResources';
import getCourseReports from './getCourseReports';
import getCourseMarks from './getCourseMarks';
import getCourseHomework from './getCourseHomework';
import storeResourceInteractions from './storeResourceInteractions';
import getBasketResources from './basket/getResources';
import createAssignmentRequest from './basket/createAssignment';
import updateAssignmentRequest from './basket/updateAssignment';
import removeAssignment from './basket/removeAssignment';
import getBasketAssignments from './basket/getAssignments';
import getClassesAndStudents from './getClassesAndStudents';

function* root() {
  yield takeLatest(t.NGS_LOAD_COURSE, ({ payload: { orgId, region, courseId } }) =>
    loadCourse(orgId, region, courseId)
  );

  yield takeLatest(t.NGS_COURSE_BOOKS_REQUEST, ({ payload: { orgId, courseId } }) => getCourseBooks(orgId, courseId));

  yield takeLatest(t.NGS_LOAD_COURSE_BOOK, ({ payload: { orgId, courseId, bookId } }) =>
    loadCourseBook(orgId, courseId, bookId)
  );

  yield takeLatest(t.NGS_LOAD_COURSE_RESOURCE, ({ payload: { orgId, courseId, resourceId } }) =>
    loadCourseResource(orgId, courseId, resourceId)
  );

  yield takeLatest(t.NGS_STORE_RESOURCE_INTERACTIONS, ({ payload: { orgId, courseId, resourceId, data } }) =>
    storeResourceInteractions(orgId, courseId, resourceId, data)
  );

  yield takeLatest(t.NGS_COURSE_STRUCTURE_REQUEST, ({ payload: { orgId, courseId } }) =>
    getCourseStructure(orgId, courseId)
  );

  yield takeLatest(t.NGS_COURSE_RESOURCES_REQUEST, ({ payload: { orgId, courseId, nodeId, format, sort } }) =>
    getCourseResources(orgId, courseId, nodeId, null, format, sort, false)
  );

  yield takeLatest(t.NGS_NEXT_PAGE_COURSE_RESOURCES_REQUEST, ({ payload: { orgId, courseId, nodeId, format, sort } }) =>
    getCourseResources(orgId, courseId, nodeId, null, format, sort, true)
  );

  yield takeLatest(t.NGS_NEXT_PAGE_BASKET_RESOURCES_REQUEST, ({ payload }) => getBasketResources(payload));

  yield takeLatest(t.NGS_COURSE_REPORTS_REQUEST, ({ payload: { orgId, courseId } }) =>
    getCourseReports(orgId, courseId)
  );

  yield takeLatest(t.NGS_COURSE_MARKS_REQUEST, ({ payload: { orgId, courseId } }) => getCourseMarks(orgId, courseId));

  yield takeLatest(t.NGS_COURSE_HOMEWORK_REQUEST, ({ payload }) => getCourseHomework(payload));

  yield takeEvery(t.NGS_CREATE_ASSIGNMENT_REQUEST, ({ payload }) => createAssignmentRequest(payload));

  yield takeEvery(t.NGS_REMOVE_ASSIGNMENT_REQUEST, ({ payload }) => removeAssignment(payload));

  yield takeEvery(t.NGS_UPDATE_ASSIGNMENT_REQUEST, ({ payload }) => updateAssignmentRequest(payload));

  yield takeEvery(t.NGS_BASKET_RESOURCES_REQUEST, ({ payload }) => getBasketResources(payload));

  yield throttle(500, t.NGS_GET_BASKET_ASSIGNMENTS_REQUEST, ({ payload }) => getBasketAssignments(payload));

  yield takeLatest(t.NGS_GET_CLASSES_AND_STUDENTS_REQUEST, ({ payload }) => getClassesAndStudents(payload));
}

export default root;
