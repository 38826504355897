import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import styles from './SearchListBox.scss';
import { isLocal } from '../../globals/envSettings';

class SearchListBox extends Component {
  static _onKeyDownItem = e => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();

      let targetSuggestion;
      if (e.keyCode === 38) targetSuggestion = document.activeElement.previousSibling;
      if (e.keyCode === 40) targetSuggestion = document.activeElement.nextSibling;

      if (targetSuggestion) {
        targetSuggestion.focus();
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      term: null
    };
  }

  _handleTermChanged = term => {
    const { onTypeStop } = this.props;
    if (onTypeStop) {
      return;
    }

    this.setState({ term });
  };

  _getFilteredItems = () => {
    const { items } = this.props;
    const { term } = this.state;

    return !term ? items : items.filter(item => item.label.toLowerCase().includes(term.toLowerCase()));
  };

  render() {
    const { placeholder = 'search', loading = false, autoFocus = false, onTypeStop } = this.props;

    return (
      <div className={styles.roundedBorder}>
        <TextInputWithButton
          id="SearchListBox"
          autoFocus={autoFocus}
          onTypeStop={onTypeStop}
          placeholder={placeholder}
          onChange={this._handleTermChanged}
        />
        <div className={styles.resultsLimiter}>
          {loading ? (
            <span style={{ textAlign: 'center' }}>
              <LoadingIndicator />
            </span>
          ) : null}
          {this._getFilteredItems().map((item, index) => (
            <a
              key={index}
              role="menuitem"
              tabIndex="0"
              className={styles.resultLine}
              id={index}
              onClick={() => {
                if (!isLocal()) {
                  window.newrelic.interaction().actionText('Choose option from dropdown');
                }
                item.action();
              }}
              onKeyPress={item.action}
              onKeyDown={SearchListBox._onKeyDownItem}
            >
              {item.label}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

SearchListBox.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onTypeStop: PropTypes.func
};

export default SearchListBox;
