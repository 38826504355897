export const FILL_BATCH_ID = 'downloadSigninCardProcess/fill_batch_id';
export const DOWNLOAD_SIGNIN_CARD_PROCESS_SUCCESS = 'downloadSigninCardProcess/download_signin_card_process_success';
export const DOWNLOAD_SIGNIN_CARD_PROCESS_FAIL = 'downloadSigninCardProcess/download_signin_card_process_fail';
export const DOWNLOAD_SIGNIN_CARD_PROCESS_POLLING = 'downloadSigninCardProcess/download_signin_card_process_polling';
export const DOWNLOAD_SIGNIN_CARD_PROCESS_POLLING_TRIGGER =
  'downloadSigninCardProcess/download_signin_card_process_polling_trigger';
export const DOWNLOAD_SIGN_IN_CARD = 'downloadSigninCardProcess/download_signin_card';
export const DOWNLOAD_SIGNIN_CARD_PROCESS_START = 'downloadSigninCard/download_signin_card_process_start';
export const DOWNLOAD_SIGNIN_CARD_COUNT_ITERATION = 'downloadSigninCard/DOWNLOAD_SIGNIN_CARD_COUNT_ITERATION';
export const DOWNLOAD_ERROR_DETAILS = 'downloadSigninCard/DOWNLOAD_ERROR_DETAILS';

const initialState = {
  batchId: null,
  success: false,
  error: false,
  polling: false,
  totalRecords: null,
  completedRecords: 0,
  activeLearners: [],
  archivedLearners: [],
  errorLearners: [],
  iterationCount: 0,
  downloadSigninCard: false,
  pollingStarted: true
};

export default function downloadSigninCardProcess(state = initialState, action) {
  switch (action.type) {
    case FILL_BATCH_ID:
      return {
        ...state,
        batchId: action.batchId,
        totalRecords: action.totalRecords
      };
    case DOWNLOAD_SIGNIN_CARD_PROCESS_SUCCESS:
      return {
        ...state,
        activeLearners: action.activeLearners,
        archivedLearners: action.archivedLearners,
        errorLearners: action.errorLearners,
        completedRecords: action.completedRecords,
        success: true,
        polling: false,
        error: false
      };
    case DOWNLOAD_SIGNIN_CARD_PROCESS_FAIL:
      return {
        ...state,
        success: false,
        polling: false,
        error: true
      };
    case DOWNLOAD_SIGNIN_CARD_PROCESS_POLLING:
      return {
        ...state,
        completedRecords: action.completedRecords
      };
    case DOWNLOAD_SIGNIN_CARD_PROCESS_POLLING_TRIGGER:
      return {
        ...state,
        polling: true,
        success: false,
        error: false
      };
    case DOWNLOAD_SIGN_IN_CARD:
      return {
        ...state,
        downloadSigninCard: true
      };
    case DOWNLOAD_SIGNIN_CARD_PROCESS_START:
      return {
        ...state
      };
    case DOWNLOAD_SIGNIN_CARD_COUNT_ITERATION:
      return {
        ...state,
        iterationCount: action.count
      };
    default:
      return state;
  }
}

export const fillBatchId = (batchId, totalRecords) => ({
  type: FILL_BATCH_ID,
  batchId,
  totalRecords
});

export const downloadSigninCardProcessPolling = completedRecords => ({
  type: DOWNLOAD_SIGNIN_CARD_PROCESS_POLLING,
  completedRecords
});

export const downloadSigninCardProcessSuccess = (
  activeLearners,
  archivedLearners,
  errorLearners,
  completedRecords
) => ({
  type: DOWNLOAD_SIGNIN_CARD_PROCESS_SUCCESS,
  activeLearners,
  archivedLearners,
  errorLearners,
  completedRecords
});

export const downloadSigninCardProcessFail = () => ({
  type: DOWNLOAD_SIGNIN_CARD_PROCESS_FAIL
});

export const downloadSigninCardProcessPollingTrigger = () => ({
  type: DOWNLOAD_SIGNIN_CARD_PROCESS_POLLING_TRIGGER
});

export const downloadSigninCard = () => ({
  type: DOWNLOAD_SIGN_IN_CARD
});

export const startProcess = () => ({
  type: DOWNLOAD_SIGNIN_CARD_PROCESS_START
});

export const addCountIteration = count => ({
  type: DOWNLOAD_SIGNIN_CARD_COUNT_ITERATION,
  count
});

export const downloadErrorDetails = () => ({
  type: DOWNLOAD_ERROR_DETAILS
});
