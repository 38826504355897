import * as t from '../actionTypes';

export default function storeUserPlatformStatistics(payload) {
  return {
    type: t.GET_USER_PLATFORM_STATISTICS_SUCCESS,
    payload
  };
}

export function getUserPlatformStatistics() {
  return {
    type: t.GET_USER_PLATFORM_STATISTICS_SUCCESS
  };
}
