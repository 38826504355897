import * as t from '../actionTypes';

export const createProductRequest = payload => ({
  type: t.CREATE_PRODUCT_REQUEST,
  payload
});

export const createProductSuccess = payload => ({
  type: t.CREATE_PRODUCT_SUCCESS,
  payload
});

export const createProductFailure = payload => ({
  type: t.CREATE_PRODUCT_FAILURE,
  payload
});

export const organizationalUnitsRequest = data => ({
  type: t.CREATE_PRODUCT_ORG_UNITS,
  data
});

export const organizationalUnitsSuccess = data => ({
  type: t.CREATE_PRODUCT_ORG_UNITS_SUCCESS,
  data
});

export const organizationalUnitsFailure = data => ({
  type: t.CREATE_PRODUCT_ORG_UNITS_FAILURE,
  data
});

export const platformsRequest = data => ({
  type: t.CREATE_PRODUCT_GET_PLATFORMS,
  data
});

export const platformsCallSuccess = data => ({
  type: t.CREATE_PRODUCT_PLATFORMS_SUCCESS,
  data
});

export const platformsCallFailure = data => ({
  type: t.CREATE_PRODUCT_PLATFORMS_FAILURE,
  data
});

export const externalIdsRequest = data => ({
  type: t.CREATE_PRODUCT_GET_EXTERNAL_IDS,
  data
});

export const externalIdsCallSuccess = data => ({
  type: t.CREATE_PRODUCT_EXTERNAL_IDS_SUCCESS,
  data
});

export const externalIdsCallFailure = data => ({
  type: t.CREATE_PRODUCT_EXTERNAL_IDS_FAILURE,
  data
});
