import PropTypes from 'prop-types';
import React from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ContentWithSVGIcon.scss';

/** Simple composable component for adding a SVGIcon to some content */
function ContentWithSVGIcon({ glyph, children }) {
  return (
    <div className={styles.contentWithSVGIcon}>
      <div className={styles.children}>{children}</div>
      {glyph && (
        <div className={styles.icon}>
          <SVGIcon glyph={glyph} />
        </div>
      )}
    </div>
  );
}

ContentWithSVGIcon.propTypes = {
  // SVGIcon props
  /** The glyph of the SVGIcon. */
  glyph: PropTypes.any.isRequired,

  // Children content
  children: PropTypes.oneOfType([
    // Can be an element or list of elements
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default ContentWithSVGIcon;
