import * as t from '../actionTypes';

export const validateOrgAdminInput = input => ({
  type: t.VALIDATE_ORG_ADMIN_INPUT,
  payload: input
});

export const validateOrgAdminError = () => ({
  type: t.VALIDATE_ORG_ADMIN_ERROR
});

export const storeOrgAdminInputEmailAvailability = payload => ({
  type: t.STORE_ORG_ADMIN_INPUT_EMAIL_AVAILABILITY,
  payload
});

export const storeOrgAdminInputEmailLoading = () => ({
  type: t.STORE_ORG_ADMIN_INPUT_EMAIL_LOADING
});

export const registerOrgAdminRequest = input => ({
  type: t.REGISTER_ORG_ADMIN_REQUEST,
  payload: input
});

export const registerOrgAdminSubmitting = () => ({
  type: t.REGISTER_ORG_ADMIN_SUBMITTING
});

export const registerOrgAdminSuccess = () => ({
  type: t.REGISTER_ORG_ADMIN_SUCCESS,
  payload: null
});

export const registerOrgAdminFailure = reason => ({
  type: t.REGISTER_ORG_ADMIN_FAILURE,
  payload: { reason }
});

export const registerOrgAdminClearFailure = () => ({
  type: t.REGISTER_ORG_ADMIN_FAILURE_CLEAR,
  payload: null
});

export const registerOrgAdminReset = () => ({
  type: t.REGISTER_ORG_ADMIN_RESET
});
