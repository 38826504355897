/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Used for importing Regex
import appSettings from '../../../globals/appSettings.js';

import Button from '../../../components/Button/Button.js';

import Validation from '../../../components/Validation/Validation.js';
import Dropdown from '../../../components/Dropdown/Dropdown.js';
import TextInput from '../../../components/TextInput/TextInput.js';
import PageHeading from '../../../components/PageHeading/PageHeading.js';

import ConnectedField from '../../CreateOrganisation/formUtil/ConnectedField.js';
import FormValidity from '../../CreateOrganisation/formUtil/FormValidity.js';

import { setFormState } from '../../../redux/reducers/data/forms.reducer.js';

import cmsContent from '../../../utils/cmsContent.js';

function ExampleTabConfirmation({ submitFormAction }) {
  const CMS = cmsContent.examplePage || {};
  return (
    <div className="pad-top4">
      <PageHeading title={CMS.contact_us_pageTitle} subtitle={CMS.contact_us_formHeading} />

      <ConnectedField
        formName="contactUs"
        fieldName="email"
        render={(id, value, onChange) => (
          <input
            className="a11y-hide"
            aria-hidden
            id={id}
            name={id}
            onChange={event => onChange(event.target.value)}
            value={value}
          />
        )}
      />
      <ConnectedField
        formName="contactUs"
        fieldName="name"
        positiveValidationFunction={value => value !== ''}
        negativeValidationFunction={value => value === ''}
        getValidationMessage={value => (value === '' ? CMS.contact_form_name_placeholder : null)}
        render={(id, value, onChange, onBlur, message, isValid, isError) => (
          <Validation forId={id} isValid={isValid} isError={isError} message={message}>
            <TextInput
              id={id}
              label={CMS.contact_form_name_label}
              placeholder={CMS.contact_form_name_placeholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Validation>
        )}
      />

      <ConnectedField
        formName="contactUs"
        fieldName="ces10"
        positiveValidationFunction={value => appSettings.EMAIL_REGEX.test(value)}
        negativeValidationFunction={value => !appSettings.EMAIL_REGEX.test(value)}
        getValidationMessage={value =>
          !appSettings.EMAIL_REGEX.test(value) ? CMS.contact_form_email_placeholder : null
        }
        render={(id, value, onChange, onBlur, message, isValid, isError) => (
          <Validation isValid={isValid} isError={isError} message={message} forId={id}>
            <TextInput
              id={id}
              label={CMS.contact_form_email_label}
              placeholder={CMS.contact_form_email_placeholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Validation>
        )}
      />

      <ConnectedField
        formName="contactUs"
        fieldName="country"
        initialValue="UK"
        initialValueIsValid
        positiveValidationFunction={value => value !== ''}
        render={(id, value, onChange, onBlur, message, isValid) => (
          <Validation isValid={isValid} forId={id}>
            <Dropdown
              id={id}
              label={CMS.contact_form_country_label}
              options={[
                {
                  value: 'UK',
                  text: CMS.country_uk_ireland
                },
                {
                  value: 'FR',
                  text: CMS.country_france
                }
              ]}
              value={value}
              onChange={onChange}
            />
          </Validation>
        )}
      />

      <ConnectedField
        formName="contactUs"
        fieldName="comment"
        positiveValidationFunction={value => value !== ''}
        negativeValidationFunction={value => value === ''}
        getValidationMessage={value => (value === '' ? CMS.contact_form_comment_placeholder : null)}
        render={(id, value, onChange, onBlur, message, isValid, isError) => (
          <Validation forId={id} isValid={isValid} isError={isError} message={message}>
            <TextInput
              textArea
              id={id}
              label={CMS.contact_form_comment_label}
              placeholder={CMS.contact_form_comment_placeholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Validation>
        )}
      />

      <div className="row cols-right pad-top2">
        <FormValidity
          formName="contactUs"
          requiredFields={['name', 'ces10', 'comment']}
          render={valid => (
            <Button
              id="submit-form"
              text={CMS.button_contact_us_submit_form_text}
              onClick={submitFormAction}
              disabled={!valid}
            />
          )}
        />
      </div>
    </div>
  );
}

ExampleTabConfirmation.propTypes = {
  submitFormAction: PropTypes.func.isRequired
};
export default connect(null, {
  submitFormAction: () => setFormState('contactUs', 'SUBMITTING')
})(ExampleTabConfirmation);
