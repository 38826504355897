import { hydrateCourses, getSortedCourseIds } from '@oup/shared-node-browser/course.js';
import { JSON_ENCODED, PLATFORMS } from '@oup/shared-node-browser/constants';
import signedRequest from '../util/signedRequest.js';
import jsonFetch from '../util/jsonFetch';
import { getCurrentPlatform } from '../../../../utils/platform.js';

function filterPlatformRestrictedProducts(courses, platform) {
  Object.keys(courses).forEach(courseId => {
    const products = courses[courseId].products;
    Object.keys(products.data).forEach(productIsbn => {
      // if the product doesn't have visibility on the current platform, we remove it
      if (!products.data[productIsbn]?.visibility?.platforms?.includes(platform)) {
        products.ids.splice(products.ids.indexOf(productIsbn), 1);
        delete products.data[productIsbn];
      }
    });
    // if all the products are restricted to the platform, we remove the course entirely
    if (products.ids.length === 0) {
      delete courses[courseId];
    }
  });
  return courses;
}

export async function hydrateAndWrap(courses, entitlements, platform) {
  const hydratedCourses = await hydrateCourses(fetch, courses, entitlements);
  // and pretend to be the getHubCourses API response for consistency
  const filteredHydratedCourses =
    platform !== PLATFORMS.EPS_CONSOLE ? filterPlatformRestrictedProducts(hydratedCourses, platform) : hydratedCourses;

  const response = {
    status: 'success',
    data: {
      courses: filteredHydratedCourses,
      ids: getSortedCourseIds(hydratedCourses)
    }
  };
  return response;
}

export async function getMyCourses() {
  const currentPlatform = getCurrentPlatform().toUpperCase();
  const headers = { 'Content-Type': JSON_ENCODED };
  const url = `${__API_BASE__}/user/my/courses`;
  const { data = { courses: [], entitlements: [] } } = await jsonFetch(
    'GetMyCourses',
    [url, signedRequest(url, 'GET', headers)],
    false
  );
  const simulatedCoursesApiResponse = await hydrateAndWrap(data.courses, data.entitlements, currentPlatform);
  return simulatedCoursesApiResponse;
}

function* yielded(func) {
  // return the results of an async function
  return yield func();
}

export default () => yielded(async () => getMyCourses());
