// Initialise action
const INITIALISE_FORM = 'licenceRecall/INITIALISE_FORM';

// Submission and result actions
export const SUBMIT_FORM = 'licenceRecall/SUBMIT_FORM';
const SHOW_CONFIRMATION = 'licenceRecall/SHOW_CONFIRMATION';
export const RESET_FORM = 'licenceRecall/RESET_FORM';

export const formStates = {
  INPUTTING: 'INPUTTING',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION'
};

export const initialState = {
  formState: formStates.INPUTTING,

  // Details for request
  orgId: null,
  userId: null,
  assignmentId: null,
  activationCode: null,

  // Submission result
  failed: false
};

export default function licenceRecall(state = initialState, action) {
  switch (action.type) {
    // Initialise action
    case INITIALISE_FORM:
      return {
        ...initialState,
        orgId: action.orgId,
        userId: action.userId,
        assignmentId: action.assignmentId,
        activationCode: action.activationCode
      };

    //  Submit and result actions
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        failed: action.failed
      };

    // Reset the form
    case RESET_FORM:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

// Initialise action
export const initialiseForm = (orgId, userId, assignmentId, activationCode) => ({
  type: INITIALISE_FORM,
  orgId,
  userId,
  assignmentId,
  activationCode
});

// Submission and result actions
export const submitForm = () => ({
  type: SUBMIT_FORM
});
export const showConfirmation = failed => ({
  type: SHOW_CONFIRMATION,
  failed
});

// Reset the form
export const resetForm = () => ({
  type: RESET_FORM
});
