import * as t from '../../actionTypes';
import mapUserSearchResults from './userResultMapper';

export const initialState = {
  errorOccurred: false,
  success: false,
  loading: false,
  userSearchResults: [],
  userSearchTotal: 0
};

export default function userConsole(state = initialState, action) {
  switch (action.type) {
    case t.SEARCH_USER_FOR_CONSOLE:
      return {
        ...state,
        loading: true
      };
    case t.STOP_USER_SEARCH_FOR_CONSOLE:
      return {
        ...state,
        loading: false
      };
    case t.SET_USER_SEARCH_RESULT_FOR_CONSOLE:
      return {
        ...state,
        loading: false,
        errorOccurred: false,
        userSearchResults: mapUserSearchResults(action.data.success.users),
        userSearchTotal: action.data.success.total
      };
    case t.SET_USER_SEARCH_ERROR_FOR_CONSOLE:
      return {
        ...state,
        loading: false,
        errorOccurred: true
      };
    case t.EMPTY_USER_SEARCH_RESULTS_FOR_CONSOLE:
      return {
        ...state,
        userSearchResults: [],
        userSearchTotal: 0
      };
    default:
      return state;
  }
}
