import { fork, put, takeEvery } from 'redux-saga/effects';
import { PLATFORMS } from '@oup/shared-node-browser/constants';
import { isPublicPath } from '../../../../globals/authSettings';
import redirectToIdP from '../../preRender/auth/getCredentials/redirectToIdP.saga';
import { appReady } from '../../../reducers/app.reducer';
import managedUserRootSaga from './managedUser';
import checkLicence from './licence/checklicence.saga';
import changeLanguage from '../../preRender/content/loadContent.saga';
import productStructure from './productStructure/index.js';
import getMockCptContentSaga from './cpt/index';
import offlineContentPlayer from './ocp/index.js';

export default function* unauthorisedFunctions() {
  console.groupCollapsed('[unauthorisedFunctions]');
  console.log('[unauthorisedFunctions] Starting');

  const urlParams = new URLSearchParams(window.location.search);
  const navigateTo = urlParams.get('navigateTo') || '';

  if (navigateTo === PLATFORMS.OLB) {
    yield redirectToIdP(null, {
      withReturnTo: true,
      navigateTo
    });
  }

  // This prevents a user from ever navigating to a page that the need to be logging in to view
  console.log('[unauthorisedFunctions] Binding route change handler');
  yield takeEvery('@@router/LOCATION_CHANGE', function* locationChangeHandler({ location }) {
    console.log('[unauthorisedFunctions] Checking that the user is navigating to a page that they can access');

    const nextPath = location.pathname;

    if (!isPublicPath(nextPath)) {
      const nextUrlParams = new URLSearchParams(location.search);

      if (nextUrlParams.has('account_linking') && nextUrlParams.get('account_linking') === 'true') {
        console.log(
          '[unauthorisedFunctions] User is attempting to navigate to a protected page but his account should be linked. Redirecting to login'
        );

        const options = {
          withReturnTo: false,
          accountLinking: true,
          navigateTo
        };

        yield redirectToIdP(null, options);
      } else {
        console.log('[unauthorisedFunctions] User is attempting to navigate to a protected page. Redirecting to login');
        yield redirectToIdP();
      }
    }
  });

  console.log('[unauthorisedFunctions] Starting functions...');

  // We should start the non-partial user reg code here
  // At the moment it is all started in one place in the appSaga
  // yield fork(registrationSaga);
  // yield fork(passwordResetSaga);

  console.log('[unauthorisedFunctions] Signalling for page to render...');
  yield put(appReady());

  yield fork(managedUserRootSaga);
  yield fork(checkLicence);
  yield fork(changeLanguage);
  yield fork(productStructure);
  yield fork(getMockCptContentSaga);
  yield fork(offlineContentPlayer);

  console.log('[unauthorisedFunctions] Complete');
  console.groupEnd('[unauthorisedFunctions]');
}
