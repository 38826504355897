import * as t from '../actionTypes';

export const loadCourseResource = (orgId, courseId, resourceId) => ({
  type: t.NGS_LOAD_COURSE_RESOURCE,
  payload: { orgId, courseId, resourceId }
});

export const unloadCourseResource = () => ({
  type: t.NGS_UNLOAD_COURSE_RESOURCE
});

export const loadCourseResourceSuccess = payload => ({
  type: t.NGS_LOAD_COURSE_RESOURCE_SUCCESS,
  payload
});

export const loadCourseResourceFailure = payload => ({
  type: t.NGS_LOAD_COURSE_RESOURCE_FAILURE,
  payload
});

export const storeResourceInteractions = (orgId, courseId, resourceId, data) => ({
  type: t.NGS_STORE_RESOURCE_INTERACTIONS,
  payload: { orgId, courseId, resourceId, data }
});
