import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import sendDeferredEmailsRequest from '../../../api/sendDeferredEmails';

function* sendDeferredEmails(orgId, userRole) {
  const response = yield sendDeferredEmailsRequest(orgId, userRole);
  if (response.status !== 'success') {
    yield put(actions.sendDeferredEmailsFailure(response.error));
    return;
  }
  yield put(actions.sendDeferredEmailsSuccess(response));
}

export default sendDeferredEmails;
