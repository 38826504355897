import * as t from '../actionTypes';

export const setContactUsChangeProductSelection = product => ({
  type: t.CONTACT_US_CHANGE_PRODUCT_SELECTION,
  payload: product
});

export const validateContactUsInputFragment = input => ({
  type: t.CONTACT_US_VALIDATE_INPUT,
  payload: input
});

export const submitContactUsRequest = input => ({
  type: t.CONTACT_US_REQUEST,
  payload: input
});

export const setContactUsOlbSuccess = platformCode => ({
  type: t.CONTACT_US_SUCCESS,
  payload: platformCode
});

export const setContactUsOlbFailure = (errorMessages, platformCode) => ({
  type: t.CONTACT_US_FAILURE,
  payload: { errorMessages, platformCode }
});

export const resetContactUs = () => ({
  type: t.CONTACT_US_CHANGE_PRODUCT_SELECTION,
  payload: null
});
