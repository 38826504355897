export const osName = () => {
  const platform = window.navigator.platform.toLowerCase();
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.includes('android')) {
    return 'android';
  }
  if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
    return 'ios';
  }
  if (platform.includes('mac')) {
    return 'mac';
  }

  return 'windows';
};

export const arch = () => {
  const platform = window.navigator.platform.toLowerCase();
  if (platform.includes('win32') || platform.includes('win64') || platform.includes('wow64')) {
    return 'x64';
  }
  return 'x32';
};

export const isIos = () => {
  const os = osName();
  return os === 'ios' || (os === 'mac' && navigator.maxTouchPoints > 1);
};

export const isAndroid = () => {
  const os = osName();
  return os === 'android';
};

export const isIosWebview = () => {
  // @ts-ignore - we need the standalone property here
  const standalone = window.navigator.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  return ios && !standalone && !safari;
};

export const isAndroidWebview = () => {
  const userAgent = window.navigator.userAgent;

  return isAndroid() && /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv)/i.test(userAgent);
};

export const isSafari = () => {
  // @ts-ignore - we need the standalone property here
  const standalone = window.navigator.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /^((?!chrome|android).)*safari/.test(userAgent);

  return safari && !standalone;
};

export const isLinuxElectron = () => {
  const platform = window.navigator.platform.toLowerCase();
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (
    userAgent.includes('android') ||
    userAgent.includes('iphone') ||
    userAgent.includes('ipad') ||
    platform.includes('mac') ||
    platform.includes('win')
  )
    return false;

  return platform.includes('linux') && /electron/.test(userAgent);
};
