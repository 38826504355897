import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Button from '../../components/Button/Button';
import actions from '../../redux/actions';
import styles from './ORBNotifications.scss';
import withLocalizedContent from '../../language/withLocalizedContent';

class ORBNotifications extends PureComponent {
  constructor() {
    super();
    this.state = {
      showNotification: false
    };
  }

  componentWillMount() {
    const { notifications } = this.props;

    if (notifications.length > 0) {
      this.setState({ showNotification: true });
    }
  }

  onClickContinue = () => {
    const { userId, orgId, notifications, markNotificationAsRead } = this.props;

    this.setState({ showNotification: false });

    markNotificationAsRead(userId, orgId, notifications[0].id);
  };

  render() {
    const {
      localizedContent: { orbNotifications: content },
      notifications
    } = this.props;
    const { showNotification } = this.state;

    document.body.classList.toggle(styles.hiddenClass, showNotification);

    if (showNotification) {
      return (
        <div className={styles.orbNotification}>
          <div className="grid">
            <div className="row">
              <div className="col">
                {
                  <div
                    className={styles.content}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: notifications[0].message.join('<br/>') }}
                  />
                }
              </div>
              <div className={`col ${styles.actions}`}>
                <Button onClick={this.onClickContinue} text={content.continueButton} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

ORBNotifications.propTypes = {
  orgId: PropTypes.string.isRequired,
  userId: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  markNotificationAsRead: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('orbNotifications'),
  connect(
    state => ({
      orgId: state.identity.currentOrganisationId,
      userId: state.identity.userId
    }),
    dispatch => ({
      markNotificationAsRead: (userId, orgId, notificationId) => {
        dispatch(actions.markNotificationAsRead(userId, orgId, notificationId));
      }
    })
  )
)(ORBNotifications);
