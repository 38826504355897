import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { debounce } from 'lodash';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import Link from '../../../../components/Link/Link';
import PageHeading from '../../../../components/PageHeading/PageHeading';
import styles from './CoursePreview.scss';
import { getCoursePreviewRequest } from '../../../../redux/actions/coursePreview';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import CoursePreviewTable from './CoursePreviewTable';
import { columnTypes } from '../../../../components/TableAccordion/TableAccordion';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import { COURSE_MODAL_CLOSE_LINK_KEY } from '../../../../globals/cptConstants';
import { NA } from '../../../../globals/contentPreviewConstants.js';
import preventBodyScroll from '../../../../utils/dom/preventBodyScroll';

function CoursePreview({
  getCoursePreviewRequestData,
  loading,
  success,
  results,
  localizedContent: { coursePreview: content }
}) {
  const [title, setTitle] = useState('');

  const handleFilter = value => {
    results.filter(item => {
      const lowerCaseValue = value.toLowerCase();
      const searchableProperties = [item.isbn, item.title, item.author, item.subtitle];
      return searchableProperties.some(property =>
        property ? property.toLocaleLowerCase().includes(lowerCaseValue) : property
      );
    });
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        handleFilter(value);
      }, 300),
    [results]
  );

  useEffect(() => {
    getCoursePreviewRequestData({ title });
  }, []);

  useEffect(() => {
    if (results && results.length > 0) {
      handleFilter('');
    }
  }, [JSON.stringify(results)]);

  useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  const renderNonCustomer = (item, key) => {
    const userDetails = item.entityEvent[key];
    if (userDetails) {
      const { firstName, lastName, userEmail } = userDetails;
      return <a href={`mailto:${userEmail}`}>{`${firstName} ${lastName}`}</a>;
    }
    return NA;
  };

  const generateCoursePreviewRows = data => {
    const rows = [];
    data?.forEach((item, key) => {
      rows.push({
        id: `id${key}`,
        cells: [
          item.title,
          'EPS',
          item.entityEvent?.dateCreated ? new Date(item.entityEvent.dateCreated).toLocaleString() : NA,
          renderNonCustomer(item, 'createdByUserDetails'),
          item.entityEvent?.dateModified ? new Date(item.entityEvent.dateModified).toLocaleString() : NA,
          renderNonCustomer(item, 'modifiedByUserDetails'),
          <Link key={`id_${key}_student`} to={`course-preview/${item.courseCode}/courseMaterial`}>
            <Button text={content.preview} type={buttonTypes.SECONDARY} />
          </Link>
        ]
      });
    });

    return rows;
  };

  useEffect(() => {
    sessionStorage.setItem(COURSE_MODAL_CLOSE_LINK_KEY, window.location.pathname);
    preventBodyScroll(false);
  });

  const renderTable = () => {
    const data = results;
    const rows = generateCoursePreviewRows(data);
    const columns = [
      {
        heading: content.column_title,
        type: columnTypes.TEXT
      },
      {
        heading: content.column_type,
        type: columnTypes.TEXT
      },
      {
        heading: 'Created',
        type: columnTypes.TEXT
      },
      {
        heading: 'Created By',
        type: columnTypes.TEXT
      },
      {
        heading: 'Modified',
        type: columnTypes.TEXT
      },
      {
        heading: 'Modified By',
        type: columnTypes.TEXT
      },
      {
        heading: content.column_preview,
        type: columnTypes.BUTTON
      }
    ];

    return <CoursePreviewTable rows={rows} columns={columns} success={success} loading={loading} content={content} />;
  };

  return (
    <div>
      <PageHeading title={content.page_title} />
      <br />
      <div className={styles.coursePreviewTableContainer}>
        <div className={styles.filters}>
          <div className={styles.filter}>
            <form
              onSubmit={e => {
                e.preventDefault();
                getCoursePreviewRequestData({ title });
              }}
              noValidate
            >
              <SearchInput
                placeholder={content.search_title_label}
                value={title}
                onChange={value => {
                  setTitle(value);
                }}
                buttonAction={() => {
                  getCoursePreviewRequestData({ title });
                }}
              />
            </form>
          </div>
        </div>
        {renderTable()}
      </div>
    </div>
  );
}

CoursePreview.propTypes = {
  getCoursePreviewRequestData: PropTypes.func.isRequired,
  results: PropTypes.object,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  localizedContent: PropTypes.object
};

const mapStateToProps = state => {
  const {
    coursePreview: { loading, success, results }
  } = state;
  return { loading, success, results };
};

const mapDispatchToProps = {
  getCoursePreviewRequestData: getCoursePreviewRequest
};

export default compose(
  withLocalizedContent('coursePreview'),
  connect(mapStateToProps, mapDispatchToProps)
)(CoursePreview);
