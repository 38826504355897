import { getUserQueueRequest, getUserQueueRequestFailed } from '../redux/reducers/userSignInCard.reducer';
import { startProcess, downloadSigninCardProcessFail } from '../redux/reducers/downloadSigninCardProcess.reducer';
import { postContentPackageStart, postContentPackageFailure } from '../redux/actions/assetsPage';
import { courseUploadFailure, courseUploadStart } from '../redux/actions/courseUpload';

/**
 * Polling config
 */
export const pollConfig = {
  stopAfterRetries: 2, // total time to stop polling - 4.30seconds
  throttleIntervalReset: 1,
  retries: 1,
  throttleInterval: [2, 3, 5, 7, 11, 13, 17, 19, 23, 29] // 2.15seconds
};

/**
 * Polling Action Events
 */
export const pollingEvents = {
  MANAGED_USER_POLL: {
    apiFailed: getUserQueueRequestFailed(true),
    conditionToFulfil: getUserQueueRequest()
  },
  DOWNLOAD_SIGNIN_CARD_PROCESS: {
    apiFailed: downloadSigninCardProcessFail(),
    conditionToFulfil: startProcess()
  },
  POST_UPLOAD_CONTENT_PACKAGE_PROCESS: {
    apiFailed: postContentPackageFailure('Failed to upload the content package'),
    conditionToFulfil: postContentPackageStart(),
    throttleInterval: [10, 10],
    stopAfterRetries: 30
  },
  COURSE_UPLOAD_PROCESS: {
    apiFailed: courseUploadFailure('Failed to upload the course content'),
    conditionToFulfil: courseUploadStart(),
    throttleInterval: [10, 10],
    stopAfterRetries: 15
  }
};

export default {
  pollConfig,
  pollingEvents
};
