export default ({
  id,
  firstname,
  lastname,
  lastAccessed,
  lastOpened,
  currentProduct: currentTitle,
  currentLevel,
  mostReadLevel,
  productsCompleted: booksFinished,
  wordsRead,
  readingTime,
  submittedNumberOfActivities: numberOfCompletedActivities,
  levelAggregation = [],
  activityScore = []
}) => ({
  id,
  studentName: `${firstname} ${lastname}`, // @TODO add conditional ordering of name
  studentLink: `${window.location.pathname}/${id}`, // @TODO look for solution that isn't so brittle
  lastAccessed,
  lastOpened,
  currentTitle,
  currentLevel,
  mostReadLevel,
  booksFinished,
  wordsRead,
  readingTime,
  numberOfCompletedActivities,
  levels: levelAggregation.length ? levelAggregation : activityScore,
  isActivityLevel: !!activityScore.length
});
