import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import itemStyle from '../StudentProgress/StudentProgressItem.scss';

import withLocalizedContent from '../../language/withLocalizedContent';
import RelativeDate from '../RelativeDate/RelativeDate';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

const RATING_ICONS = {
  good: GLYPHS.ICON_POSITIVE_SMILE,
  ok: GLYPHS.ICON_NEUTRAL_SMILE,
  bad: GLYPHS.ICON_NEGATIVE_SMILE
};

class StudentProgressWiderReadingItem extends Component {
  static renderBookCover() {
    return (
      <div className={itemStyle.cover}>
        <SVGIcon
          className={classnames(itemStyle.bookCover, itemStyle.noBorder)}
          glyph={GLYPHS.ICON_BOOK_WIDER_READING_LOGO}
        />
      </div>
    );
  }

  renderBookInfo(item) {
    const {
      localizedContent: { studentProgressWiderReadingItemComponent: content }
    } = this.props;

    return (
      <div className={itemStyle.info}>
        <p className={itemStyle.lozenge}>{content.type}</p>
        <h2>{item.book.title}</h2>
        {/* eslint-disable-next-line no-template-curly-in-string */}
        {item.book.author && <p className={itemStyle.lead}>{content.author.replace('${author}', item.book.author)}</p>}
      </div>
    );
  }

  renderResults(item) {
    const {
      localizedContent: { studentProgressWiderReadingItemComponent: content }
    } = this.props;

    return (
      <table className={itemStyle.results}>
        <tbody>
          <tr>
            <td className={itemStyle.noWrap}>{content.completed}</td>
            <td className={itemStyle.fullWidth}>
              <RelativeDate date={item.date} />
            </td>
          </tr>
          <tr>
            <td className={itemStyle.noWrap}>{content.notes}</td>
            <td className={itemStyle.fullWidth}>
              <div className={classnames(itemStyle.note, itemStyle.wordBreak)}>{item.notes}</div>
            </td>
          </tr>
          {content.rating && (
            <tr>
              <td className={itemStyle.noWrap}>{content.rating}</td>
              <td className={classnames(itemStyle.fullWidth, itemStyle.noPadding)}>
                <SVGIcon glyphViewBox="5 5 30 30" className={itemStyle.ratingIcon} glyph={RATING_ICONS[item.rating]} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    const { item } = this.props;

    return (
      <div className={classnames(itemStyle.widerReadingItem, itemStyle.itemContainer)}>
        <div className={classnames('row', itemStyle.content)}>
          <div className="col sm2">{StudentProgressWiderReadingItem.renderBookCover(item)}</div>
          <div className="col sm3">{this.renderBookInfo(item)}</div>
          <div className="col sm7">{this.renderResults(item)}</div>
        </div>
      </div>
    );
  }
}
StudentProgressWiderReadingItem.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

export default withLocalizedContent('studentProgressWiderReadingItemComponent')(StudentProgressWiderReadingItem);
