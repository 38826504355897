import { isHubMode } from '../../../../../utils/platform';
import { PLATFORMS } from '../../../../../globals/hubConstants';

export default function showIncludeLicencesForTeachers(
  productTargetUsertype,
  productPlatform,
  shouldAssignLicences,
  teacherUserIdsWithExistingLicence,
  teacherIds
) {
  if (!isHubMode()) return false;

  if (
    !productTargetUsertype &&
    productPlatform.toUpperCase() === PLATFORMS.OLB &&
    shouldAssignLicences &&
    teacherUserIdsWithExistingLicence.length !== teacherIds.length
  ) {
    return true;
  }

  return false;
}
