import * as types from '../actionTypes';

/**
 * Create an action to set the user record for the managed user change username
 * panel.
 *
 * @param {Object} user
 *
 * @return {Object}
 */
export const setManagedUserChangeUsernameRecord = user => ({
  type: types.MANAGED_USER_CHANGE_USERNAME_SET_RECORD,
  payload: user
});

/**
 * Create an action to validate whether a new username for a managed user is of
 * the valid format and not currently taken within the organisation.
 *
 * @param {Object} username The new username value
 *
 * @return {Object}
 */
export const validateManagedUserNewUsername = username => ({
  type: types.MANAGED_USER_CHANGE_USERNAME_VALIDATE_USERNAME,
  payload: { username }
});

/**
 * Create an action to store the result error set following local validation for
 * the managed user change username panel.
 *
 * @param {Object}  errors
 * @param {boolean} [errors.usernameTaken]
 *
 * @return {Object}
 */
export const setManagedUserChangeUsernameValidationResult = errors => ({
  type: types.MANAGED_USER_CHANGE_USERNAME_SET_VALIDATION_RESULT,
  payload: errors
});

/**
 * Create an action to submit a request to update a managed user username.
 *
 * @param {string} orgId
 * @param {string} userId
 * @param {Object} user
 *
 * @return {Object}
 */
export const submitManagedUserChangeUsername = (orgId, userId, user) => ({
  type: types.MANAGED_USER_CHANGE_USERNAME_SUBMIT,
  payload: { orgId, userId, user }
});

/**
 * Create an action to mark the managed user username update as succeeded.
 *
 * @return {Object}
 */
export const setManagedUserChangeUsernameSuccess = () => ({
  type: types.MANAGED_USER_CHANGE_USERNAME_SUCCESS
});

/**
 * Create an action to mark the managed user username update as failed, with
 * an optional payload object detailing errors.
 *
 * @param {?Object} payload
 *
 * @return {Object}
 */
export const setManagedUserChangeUsernameFailure = payload => ({
  type: types.MANAGED_USER_CHANGE_USERNAME_FAILURE,
  payload
});

/**
 * Create an action to clear the failure state of the managed user edit form.
 *
 * @return {Object}
 */
export const clearManagedUserChangeUsernameFailure = () => ({
  type: types.MANAGED_USER_CHANGE_USERNAME_RETRY
});

export const generateUserNameForManagedUserRequest = payload => ({
  type: types.GENERATE_USERNAME_FOR_MANAGED_USER_REQUEST,
  payload
});

export const generateUserNameForManagedUserSuccess = userName => ({
  type: types.GENERATE_USERNAME_FOR_MANAGED_USER_SUCCESS,
  payload: userName
});

export const generateUserNameForManagedUserFailure = errors => ({
  type: types.GENERATE_USERNAME_FOR_MANAGED_USER_FAILURE,
  payload: errors
});
