import { put, select } from 'redux-saga/effects';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import userRoles from '../../../../../globals/userRoles';
import actions from '../../../../actions';

export const PollClassTeacherOperation = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE'
};

export default function* pollClassTeachers(ids, operation) {
  if (!featureIsEnabled('data-recency') || !featureIsEnabled('data-recency-class-teachers') || !ids.length) {
    return;
  }
  const params = yield select(state => ({
    orgId: state.classroomPage.orgId,
    classId: state.classroomPage.classroomId
  }));
  yield put(
    actions.pollEntitiesSynced({
      ...params,
      role: userRoles.TEACHER,
      collection: 'classTeachers',
      users: ids.map(userId => ({ userId })),
      operation,
      target: 'users',
      responseEntityKey: 'userPollingStatus',
      identifier: 'userId',
      search: 'classTeachers'
    })
  );
}

export function pollClassTeachersAdded(ids) {
  return pollClassTeachers(ids, PollClassTeacherOperation.ADD);
}

export function pollClassTeachersUpdated(ids) {
  return pollClassTeachers(ids, PollClassTeacherOperation.UPDATE);
}

export function pollClassTeachersRemoved(ids) {
  return pollClassTeachers(ids, PollClassTeacherOperation.REMOVE);
}
