import * as t from '../actionTypes';

const initialState = {
  userIds: [],
  orgId: null,
  submitting: false,
  reviewing: false,
  success: false,
  error: false
};

export default function restoreUsers(state = initialState, { type, payload }) {
  switch (type) {
    case t.RESTORE_STUDENTS_SET:
      return {
        ...initialState,
        userIds: payload.userIds,
        orgId: payload.orgId,
        reviewing: true
      };

    case t.RESTORE_STUDENTS_SUCCESS:
      return {
        ...initialState,
        userIds: state.userIds,
        orgId: state.orgId,
        success: true
      };

    case t.RESTORE_STUDENTS_ERROR:
      return {
        ...initialState,
        userIds: state.userIds,
        orgId: state.orgId,
        error: true
      };

    case t.RESTORE_STUDENTS_SUBMITTING:
      return {
        ...initialState,
        userIds: state.userIds,
        orgId: state.orgId,
        submitting: true
      };

    case t.RESTORE_STUDENTS_RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
