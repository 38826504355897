// Page load and clear actions
export const CLEAR_FORM = 'removeMultiplesUsersFromClassroom/CLEAR_FORM';

const SET_MULTIPLE_USERS = 'removeMultiplesUsersFromClassroom/SET_MULTIPLE_USERS';

// Update email choice
const SET_NOTIFY_WITH_EMAIL = 'removeMultiplesUsersFromClassroom/SET_NOTIFY_WITH_EMAIL';

// Nav actions
export const HUB_SUBMIT_FORM = 'removeMultiplesUsersFromClassroom/HUB_SUBMIT_FORM';
const SUBMISSION_COMPLETE = 'removeMultiplesUsersFromClassroom/SUBMISSION_COMPLETE';

export const formStates = {
  REVIEW_SELECTIONS: 'REVIEW_SELECTIONS',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION' // Success|Fail feedback after SUBMITTING
};

const initialState = {
  formState: formStates.REVIEW_SELECTIONS,

  // Selected users and the toggle to allow sending an email to them
  notifyWithEmail: false,

  // List submitted for removal
  submittedIds: [],
  // List of users who were not removed from the class after submission
  failedIds: [],

  // General error catch all
  requestFailed: null
};

export default function hubRemoveUsersFromClassroom(state = initialState, action = {}) {
  switch (action.type) {
    // Page load and clear actions
    case CLEAR_FORM:
      return {
        ...initialState
      };

    // Set users to remove
    case SET_MULTIPLE_USERS:
      return {
        ...state,
        submittedIds: action.submittedIds
      };

    // Update email choice
    case SET_NOTIFY_WITH_EMAIL:
      return {
        ...state,
        notifyWithEmail: action.notifyWithEmail
      };

    // Nav actions
    case HUB_SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SUBMISSION_COMPLETE:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        requestFailed: action.requestFailed,
        failedIds: action.failedIds
      };
    default:
      return state;
  }
}

// Page load and clear actions
export const clearForm = () => ({
  type: CLEAR_FORM
});

export const setMultipleUsers = submittedIds => ({
  type: SET_MULTIPLE_USERS,
  submittedIds
});

// Update email choice
export const setNotifyWithEmail = notifyWithEmail => ({
  type: SET_NOTIFY_WITH_EMAIL,
  notifyWithEmail
});

// Nav actions
export const submitForm = () => ({
  type: HUB_SUBMIT_FORM
});
export const submissionComplete = (requestFailed, failedIds) => ({
  type: SUBMISSION_COMPLETE,
  requestFailed,
  failedIds
});
