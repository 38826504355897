import { toQueryString } from '../../../../utils/url';
import signedFetch from '../util/signedFetch';

// eslint-disable-next-line func-names
export default function*(sectionUid, params, attemptFilter) {
  const baseUrl = `${__API_BASE__}/gradebook/org/${params.orgId}/class/${params.classroomId}`;
  const myProgressBaseUrl = `${__API_BASE__}/gradebook/user`;

  /* Fetch data from classview lambda layer api */
  if (params.learnerId) {
    if (params.myProgress === true) {
      return yield signedFetch(
        'workbookLearnerMyProgressView',
        `${myProgressBaseUrl}/${params.learnerId}/workbook-learner-report${toQueryString({
          productId: params.itemId,
          viewType: attemptFilter,
          cache: 'true'
        })}`
      );
    }
    return yield signedFetch(
      'workbookLearnerView',
      `${baseUrl}/workbook-learner-report${toQueryString({
        productId: params.itemId,
        userId: params.learnerId,
        viewType: attemptFilter,
        cache: 'true'
      })}`
    );
  }
  if ((params.panelName && params.panelName === 'workbook') || (params?.uId && params?.activityId)) {
    return yield signedFetch(
      'workbookClassView',
      `${baseUrl}/workbook-class-report${toQueryString({
        ...(sectionUid && { uId: sectionUid }),
        productId: params.itemId,
        viewType: attemptFilter,
        cache: 'true'
      })}`
    );
  }
  if (params.myProgress === true) {
    return yield signedFetch(
      'workbookProductUserView',
      `${myProgressBaseUrl}/${params.userId}/workbook-products${toQueryString({
        cache: 'true',
        viewType: attemptFilter
      })}`
    );
  }

  return yield signedFetch(
    'workbookProductView',
    `${baseUrl}/workbook-products${toQueryString({ cache: 'true', viewType: attemptFilter })}`
  );
}
