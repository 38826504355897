import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CookieBanner from '../../../components/CookieBanner/CookieBanner';
import { acceptCookieAgreement } from '../../../redux/reducers/app.reducer.js';

class ConnectedCookieBanner extends Component {
  _handleAccept = () => {
    const { acceptCookieAgreementAction, handleHeaderHeightChange } = this.props;
    acceptCookieAgreementAction();
    handleHeaderHeightChange();
  };

  render() {
    const { visible } = this.props;
    return visible ? <CookieBanner onAccept={this._handleAccept} /> : null;
  }
}

ConnectedCookieBanner.propTypes = {
  visible: PropTypes.bool,
  acceptCookieAgreementAction: PropTypes.func.isRequired,
  handleHeaderHeightChange: PropTypes.func.isRequired
};

export default connect(
  state => ({
    visible: state.app.cookieBannerOpen
  }),
  {
    acceptCookieAgreementAction: acceptCookieAgreement
  }
)(ConnectedCookieBanner);
