import browser from 'browser-detect';
import { BROWSER_PLATFORMS } from '../../globals/appConstants';

const userBrowser = browser();

const getScreenSizeAndPlatform = () => {
  if (userBrowser.os.includes('OS')) {
    return BROWSER_PLATFORMS.MOBILE_APPLE;
  }

  if (userBrowser.mobile || userBrowser.os.includes('Android')) {
    return BROWSER_PLATFORMS.MOBILE_ANDROID;
  }

  return BROWSER_PLATFORMS.DEKSTOP;
};

export default getScreenSizeAndPlatform;
