import PropTypes from 'prop-types';
import React from 'react';

import OrbLearningObjectLink from '../OrbLearningObjectLink/OrbLearningObjectLink.jsx';
import OrbTeacherContentMetadata from '../OrbTeacherContentMetadata/OrbTeacherContentMetadata.jsx';

export default class OrbTeacherDownloadableContent extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
  };

  render() {
    const { content } = this.props;

    return (
      <div className="orb-card-container">
        <div className="row orb-card-header">
          <div className="col sm12">
            <div>
              <OrbLearningObjectLink content={content} />
            </div>
          </div>
        </div>
        <div className="row orb-card-content">
          <div className="col sm12">
            <OrbTeacherContentMetadata content={content} />
            {content.description &&
              <div className="orb-book-description">
                <div className="row">
                  <div className="col sm8"><p>{content.description}</p></div>
                  <div className="col sm4"></div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
