import * as t from '../actionTypes';

export const hubDownloadResource = (
  {
    resourceId,
    url = null,
    product,
    domain = null,
    assignmentId = null,
    roles = null,
    resourceType = null,
    contextId = null,
    activityId = null,
    studentId = null,
    isAdaptTestFlow = null,
    assessmentEntitlement = null,
    productTitle = null,
    returnPath = null,
    isAdaptedTest = false,
    assessmentTimeLimit = null,
    assessmentId = null,
    courseCode = null,
    courseId = null,
    subtitleResourceId = null,
    title = null
  },
  resourceAction
) => ({
  type: t.HUB_DOWNLOAD_RESOURCE,
  resourceId,
  url,
  resourceAction,
  product,
  domain,
  assignmentId,
  roles,
  resourceType,
  contextId,
  activityId,
  studentId,
  isAdaptTestFlow,
  assessmentEntitlement,
  productTitle,
  returnPath,
  isAdaptedTest,
  assessmentTimeLimit,
  assessmentId,
  courseCode,
  courseId,
  subtitleResourceId,
  title
});

export const hubDownloadRemoveErrorModal = () => ({
  type: t.HUB_DOWNLOAD_REMOVE_ERROR_MODAL
});

export const changeResourceView = viewerType => ({
  type: t.HUB_CHANGE_ACTIVE_VIEW_TEACHER_STUDENT,
  viewerType
});

export const hubDownloadResourceReset = () => ({
  type: t.HUB_DOWNLOAD_RESOURCE_RESET
});
