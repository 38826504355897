import { put } from 'redux-saga/effects';
import {
  setPlacementTestSessionNameSuccess,
  setPlacementTestSessionNameError,
  updatePlacementTestSessionRequestSuccess
} from '../../../../actions/placementTestOnboardingWizard';
import editClassroomApi from '../../../apiCalls/editClassroomApi';
import editPlacementTestApi from '../../../apiCalls/editPlacementTestApi';

function* editPlacementPlacementTestSession(data) {
  let results;
  if (!data.placementTest) {
    results = yield editClassroomApi(data.orgId, data.placementTestSessionId, {
      name: data.placementTestSessionNameValue
    });
  } else if (!data.placementTestSessionNameValue) {
    results = yield editPlacementTestApi(data.orgId, data.sessionId, data.placementTest);
  } else {
    results = yield editPlacementTestApi(data.orgId, data.sessionId, data.placementTest);
    if (results?.status === 'success') {
      yield editClassroomApi(data.orgId, data.sessionId, {
        name: data.placementTestSessionNameValue
      });
    }
  }

  if (results?.status === 'success') {
    yield put(updatePlacementTestSessionRequestSuccess());
    return yield put(setPlacementTestSessionNameSuccess({ classId: data.sessionId }));
  }

  return yield put(setPlacementTestSessionNameError());
}

export default editPlacementPlacementTestSession;
