import { put, select } from 'redux-saga/effects';
import actions from '../../../../actions';

import validateStartEndDates from '../../../utils/validateStartEndDates';
import { ISO_DATE } from '../../../../../globals/dateFormats';

function* validateInputInvoiceReport() {
  const { startDate, endDate } = yield select(state => state.invoiceReport.formData);

  const bodyErrors = {};

  if (startDate !== undefined || endDate !== undefined) {
    const dateErrors =
      startDate && endDate ? validateStartEndDates({ startDate, endDate }, { startDate, endDate }, ISO_DATE, true) : {};
    const errors = { ...bodyErrors, ...dateErrors };
    yield put(actions.invoiceReportValidated(errors));
  }
}

export default validateInputInvoiceReport;
