import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

import colors from '../../globals/colors.js';
import UrlControlledTabs from '../../components/UrlControlledTabs/UrlControlledTabs.js';
import SupportSearchPage from './SupportSearch/SupportSearchPage.js';
import ENTITY_TYPES from '../../globals/entityTypes';
import EntityPageHeader from '../../components/EntityPageHeader/EntityPageHeader.js';
import ContactUsPage from '../../pages/ContactUsPage';
import SupportArticlePage from './SupportArticle/SupportArticlePage';
import withLocalizedContent from '../../language/withLocalizedContent.js';
import { isOrbMode } from '../../utils/platform.js';

const isMflTabSelected = params => {
  const mflTabs = ['mfl', 'mfl-contact-us'];
  return mflTabs.includes(params?.tabName);
};

function SupportHomePage({ localizedContent }) {
  const location = useLocation();
  const params = useParams();
  const query = new URLSearchParams(location.search);

  const panelName = params.panelName;
  const content = localizedContent.supportPage;
  const isMfl = isMflTabSelected(params);

  return (
    <div style={{ padding: '0 0 4rem' }}>
      <Helmet title={content.document_head_title_text} />
      <EntityPageHeader entityType={ENTITY_TYPES.SUPPORT} entityTitle={content.page_title} />

      <UrlControlledTabs
        tabName={params.tabName}
        pathname={location.pathname}
        backgroundColor={colors.SUPPORT}
        items={[
          ...(!isOrbMode() && (params.tabName === 'index' || !query.get('platform'))
            ? [
                {
                  urlName: isMfl ? 'mfl' : 'index',
                  tabText: content.support_main_help_tab_text,
                  color: colors.LEARNERS,
                  panelContent: isMfl ? (
                    <SupportArticlePage isMfl={isMfl} />
                  ) : (
                    <SupportSearchPage pathname={location.pathname} panelName={panelName} />
                  )
                }
              ]
            : []),
          {
            urlName: isMfl ? 'mfl-contact-us' : 'contact-us',
            tabText: content.contact_us_tab_text,
            color: colors.LEARNERS,
            panelContent: <ContactUsPage />,
            skipA11yPanelHeading: true
          }
        ]}
      />
    </div>
  );
}

SupportHomePage.propTypes = {
  localizedContent: PropTypes.object
};

export default withLocalizedContent('supportPage')(SupportHomePage);
