import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal.js';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import content from '../../utils/cmsContent.js';

class SessionExpiredPopup extends Component {
  static handleSignIn = async () => {
    const url = await getIdpLoginUrl(null, { withReturnTo: false });
    window.location.href = url;
  };

  render() {
    const CMS = content.sessionExpiredPopup || {};
    const { refreshFailed } = this.props;

    return (
      refreshFailed && (
        <ConfirmationModal
          title={CMS.title}
          body={CMS.body}
          positiveClickText={CMS.buttonText}
          positiveClick={SessionExpiredPopup.handleSignIn}
        />
      )
    );
  }
}

SessionExpiredPopup.propTypes = {
  refreshFailed: PropTypes.bool.isRequired
};

export default connect(state => ({
  refreshFailed: state.identity.refreshFailed
}))(SessionExpiredPopup);
