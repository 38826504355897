import { put } from 'redux-saga/effects';
import { homeworkRequestSuccess, homeworkRequestFailure } from '../../../../actions/ngsStudentHomework';
import getStudentHomeworkApiCall from '../../../apiCalls/ngs/getStudentHomework';

function* getAggregatedHomework(payload) {
  const response = yield getStudentHomeworkApiCall(payload);

  if (response.status === 'success') {
    yield put(homeworkRequestSuccess(response.data));
    return;
  }

  yield put(homeworkRequestFailure(response.message));
}

export default getAggregatedHomework;
