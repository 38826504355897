import { put } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch';
import {
  generateManagedUserPasswordSuccess,
  generateManagedUserPasswordFailure
} from '../../../../actions/managedUser';

function* generateManagedUserPassword(orgId) {
  const response = yield signedFetch(
    'generateManagedUserPassword',
    `${__API_BASE__}/org/${orgId}/managed-user/generate-password`
  );
  try {
    const status = response.status.toLowerCase();
    if (status === 'success') {
      const password = response.data.password;
      yield put(generateManagedUserPasswordSuccess({ password }));
    } else if (status === 'error') {
      yield put(generateManagedUserPasswordFailure({ errors: { message: response.errorMessages } }));
    }
  } catch (e) {
    yield put(generateManagedUserPasswordFailure({ errors: { message: e } }));
  }
}

export default generateManagedUserPassword;
