import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getProductsRequest from '../../../api/getProducts';

function* getProducts(platform) {
  const response = yield getProductsRequest(platform);
  if (response.status !== 'success') {
    yield put(actions.getProductsFailure(response.error));
    return;
  }
  yield put(actions.getProductsSuccess(response));
}

export default getProducts;
