import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { ISO_DATE } from '../../globals/dateFormats';
import styles from '../TextInputField/TextInputField.scss';

import TextInputLabel from '../TextInputLabel/TextInputLabel';
import CustomDateInput from './CustomDateInput/CustomDateInput';

export default class DateField extends Component {
  /**
   *
   * @param {string} value
   * @returns {moment} Moment object
   */
  static parse = (value = '') => {
    const result = value ? new Date(value) : null;
    return result;
  };

  _onChange = value => {
    const { onChange } = this.props;
    const formattedDate = moment(value || Date.now()).format(ISO_DATE);
    if (onChange) onChange(formattedDate);
  };

  _onBlur = () => {
    const { name, onBlur } = this.props;
    if (onBlur) onBlur(name);
  };

  render() {
    const {
      id,
      name,
      label,
      placeholder,
      value = '',
      min = '',
      max = '',
      displayDateFormat = 'dd/MM/yyyy',
      required = false,
      disabled = false,
      readOnly = false,
      showMonthDropdown = false,
      showYearDropdown = false,
      customClassName,
      autoComplete = 'on'
    } = this.props;

    const ref = React.createRef();
    const selected = value ? DateField.parse(value) : null;

    return (
      <div className={customClassName}>
        {label && <TextInputLabel id={id} label={label} />}
        {readOnly ? (
          <div> {value} </div>
        ) : (
          <DatePicker
            calendarClassName={styles.calendarClassName}
            className={styles.textInputField}
            popperClassName={styles.datepickerPopper}
            id={id}
            name={name}
            value={selected}
            customInput={<CustomDateInput ref={ref} />}
            selected={selected}
            dateFormat={displayDateFormat}
            disabled={disabled}
            placeholderText={placeholder}
            adjustDateOnChange
            shouldCloseOnSelect
            onChange={this._onChange}
            onBlur={this._onBlur}
            onCalendarClose={this._onBlur}
            minDate={DateField.parse(min)}
            maxDate={DateField.parse(max)}
            required={required}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            scrollableYearDropdown
            dropdownMode="select"
            autoComplete={autoComplete}
            popperModifiers={[
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['bottom-start']
                }
              }
            ]}
          />
        )}
      </div>
    );
  }
}

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  displayDateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  customClassName: PropTypes.string,
  autoComplete: PropTypes.string
};
