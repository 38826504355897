import React from 'react';
import { compose } from 'recompose';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table, { columnTypes } from '../../../../components/Table/Table';
import tableStyles from '../../../../components/Table/Table.scss';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import styles from './ActCodeBatchSearchResult.scss';

function ActCodeBatchSearchResult({
  results,
  total,
  downloadBatchHandler,
  localizedContent: { activationCodeBatchSearch: content }
}) {
  const columns = [
    {
      heading: content.batch_name,
      type: columnTypes.TEXT,
      width: '30%'
    },
    {
      heading: content.product,
      type: columnTypes.TEXT,
      width: '25%'
    },
    {
      heading: content.product_group,
      type: columnTypes.TEXT,
      width: '25%'
    },
    {
      heading: content.license_type,
      type: columnTypes.TEXT,
      aligned: 'center'
    },
    {
      heading: content.created_date,
      type: columnTypes.TEXT,
      aligned: 'center'
    },
    {
      heading: content.start_date,
      type: columnTypes.TEXT,
      aligned: 'center'
    },
    {
      heading: content.end_date,
      type: columnTypes.TEXT,
      aligned: 'center'
    },
    {
      heading: content.actions,
      type: columnTypes.TEXT,
      aligned: 'center'
    }
  ];

  const getDate = raw => {
    let formated = '';
    if (!raw) {
      return formated;
    }
    try {
      const dateObj = moment(raw);
      formated = dateObj.local().format('YYYY-MM-DD');
    } catch (e) {
      console.error(e);
    }
    return formated;
  };

  const getTime = raw => {
    let formated = '';
    if (!raw) {
      return formated;
    }
    try {
      const dateObj = moment(raw);
      formated = dateObj.local().format('HH:mm:ss');
    } catch (e) {
      console.error(e);
    }
    return formated;
  };

  const rows = results.map((batch, index) => ({
    id: `row_${index}`,
    cells: [
      <div key={`batch_name_${batch.batchName}`}>{batch.batchName}</div>,
      <div key={`product_${batch.productId}`}>{batch.productId}</div>,
      <div key={`product_group_${batch.productGroupId}`}>{batch.productGroupName || batch.productGroupId}</div>,
      <div key={`license_type_${batch.licenseType}`}>{batch.licenseType}</div>,
      [
        <div key={`date_created_${index}`}>{getDate(batch.dateCreated)}</div>,
        <div key={`hour_created_${index}`}>{getTime(batch.dateCreated)}</div>
      ],
      [
        <div key={`date_from_${index}`}>{getDate(batch.validFrom)}</div>,
        <div key={`hour_from_${index}`}>{getTime(batch.validFrom)}</div>
      ],
      [
        <div key={`date_to_${index}`}>{getDate(batch.validTo)}</div>,
        <div key={`hour_to_${index}`}>{getTime(batch.validTo)}</div>
      ],
      <div key={`actions_${index}`} className={styles.actions}>
        <a key={`link_delete_${batch.productId}`}>
          <Button type={buttonTypes.CLOSE} />
        </a>
        <a key={`link_edit_${batch.productId}`}>
          <Button type={buttonTypes.PENCIL_REVIEW} />
        </a>
        <a key={`link_download_${batch.productId}`}>
          <Button
            type={buttonTypes.COPY_CLIPBOARD}
            onClick={() => downloadBatchHandler({ batchName: batch._id, structured: true })}
          />
        </a>
        <a key={`link_download_structured_${batch.productId}`}>
          <Button
            type={buttonTypes.DOWNLOAD}
            onClick={() => downloadBatchHandler({ batchName: batch._id, structured: false })}
          />
        </a>
      </div>
    ]
  }));

  return (
    <div>
      <p>{`We found ${total} ${total === 1 ? 'result' : 'results'} matching your criteria:`}</p>
      <Table columns={columns} rows={rows} customClass={tableStyles.leftAligned} />
    </div>
  );
}

ActCodeBatchSearchResult.propTypes = {
  results: PropTypes.array.isRequired,
  localizedContent: PropTypes.object,
  total: PropTypes.number,
  downloadBatchHandler: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('activationCodeBatchSearch'))(ActCodeBatchSearchResult);
