import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatPercentage } from '../../utils/number';
import ProgressDetails from '../ProgressDetails/ProgressDetails';
import AttemptOrdinal from '../AttemptOrdinal/AttemptOrdinal';
import styles from './DataCard.scss';

class DataCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingPreviousAttempts: false
    };
  }

  _showPreviousAttempts() {
    this.setState(state => ({ isShowingPreviousAttempts: !state.isShowingPreviousAttempts }));
  }

  _shouldRenderPreviousAttempts() {
    const { previousAttempts } = this.props;
    const { isShowingPreviousAttempts } = this.state;
    return previousAttempts && previousAttempts.length && isShowingPreviousAttempts;
  }

  render() {
    const { cardData, isCurrentLevel, previousAttempts } = this.props;
    const { isShowingPreviousAttempts } = this.state;

    if (cardData.skipped_level) {
      return (
        <div className={styles.attainmentFormat}>
          <p className={styles.subtitleGrey}>This level was skipped.</p>
        </div>
      );
    }

    return (
      <div className={styles.cardFormat}>
        <div className={styles.progressBar}>
          <h2 className={styles.progressHeader}>Progress</h2>
          {cardData.reset_date !== undefined ? (
            <p className="gin-bot2">
              {`Please note that this bookshelf was reset on the ${moment(cardData.reset_date).format(
                'Do'
              )} of ${moment(cardData.reset_date).format('MMMM')}`}
            </p>
          ) : null}
        </div>

        <ProgressDetails {...cardData} isCurrentLevel={isCurrentLevel} />

        {previousAttempts && previousAttempts.length ? (
          <button
            className={styles.previousAttemptsButton}
            type="submit"
            onClick={() => {
              this._showPreviousAttempts();
            }}
          >
            {isShowingPreviousAttempts ? 'Hide' : 'Show'} previous attempts
          </button>
        ) : null}

        {this._shouldRenderPreviousAttempts() ? (
          <div>
            {previousAttempts.length
              ? previousAttempts.map((attempt, key) => (
                  <div key={key}>
                    <hr className={styles.separator} />
                    <AttemptOrdinal
                      attemptNr={previousAttempts.length - key}
                      startDate={attempt.attemptStartDate}
                      endDate={attempt.attemptEndDate}
                    />
                    <div className={styles.progressBar}>
                      <h2 className={styles.progressHeader}>Progress</h2>
                      <ProgressDetails
                        progress_status={attempt.status}
                        days_at_level={attempt.days.count}
                        expected_days_at_level={attempt.days.expected}
                        quizzes_pass_score={formatPercentage(attempt.quizzes.passScore.percentage, { literal: true })}
                        quizzes_average={formatPercentage(attempt.quizzes.average.percentage, { literal: true })}
                        quizzes_expected={attempt.quizzes.expected}
                        quizzes_passed={attempt.quizzes.passed}
                        quizzes_completed={attempt.quizzes.completed}
                        isCurrentLevel={false}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
        ) : null}
      </div>
    );
  }
}

DataCard.propTypes = {
  cardData: PropTypes.shape({
    /** Number of days the student has spent at this level */
    days_at_level: PropTypes.number.isRequired,
    /** Expected number of days the student is to spend at this level */
    expected_days_at_level: PropTypes.number.isRequired,
    /** Amount of quizzes the student has passed */
    quizzes_passed: PropTypes.number.isRequired,
    /** Expected number of quizzes passed */
    quizzes_expected: PropTypes.number.isRequired,
    /** Number of quizzes the completed */
    quizzes_completed: PropTypes.number.isRequired,
    /** Quiz score average, in percent */
    quizzes_average: PropTypes.string.isRequired,
    /** Whether the student has skipped the level currently being viewed */
    skipped_level: PropTypes.bool.isRequired,
    /** If the currently viewed level has been reset, when was the date & time of the last reset */
    reset_date: PropTypes.string
  }),
  previousAttempts: PropTypes.arrayOf(
    PropTypes.shape({
      attemptEndDate: PropTypes.string,
      attemptId: PropTypes.number.isRequired,
      attemptStartDate: PropTypes.string,
      status: PropTypes.string.isRequired,
      days: PropTypes.shape({
        count: PropTypes.number,
        expected: PropTypes.number
      }),
      quizzes: PropTypes.shape({
        passScore: PropTypes.shape({
          percentage: PropTypes.string
        }),
        passed: PropTypes.number,
        average: PropTypes.shape({
          percentage: PropTypes.number
        }),
        completed: PropTypes.number,
        expected: PropTypes.number
      })
    })
  ),
  isCurrentLevel: PropTypes.bool.isRequired
};

export default DataCard;
