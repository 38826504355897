import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { featureIsEnabled } from '../../globals/envSettings.js';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import { getCurrentPlatform, isHubMode } from '../../utils/platform';
import styles from './LoginPage.scss';

function LoginPage({ envReady }) {
  const params = useParams();
  const platform = getCurrentPlatform() || 'CES';
  useEffect(async () => {
    if (platform && envReady) {
      let url = await getIdpLoginUrl(null, { platform });
      if (featureIsEnabled('supervised-users') && isHubMode()) {
        if (params?.orgId) {
          url = `${url}&org-id=${params.orgId}`;
        }
      }
      window.location.href = url;
    }
  }, [platform, envReady]);
  const showLoadingSpinner = () => (
    <div className={styles.loadingSpinnerContainer}>
      <PopoutPanelIconHeading type={types.LOADING} title="Loading" />
    </div>
  );

  return <div>{showLoadingSpinner()}</div>;
}
LoginPage.propTypes = {
  envReady: PropTypes.bool
};
export default connect(
  state => ({
    envReady: state.app.envReady
  }),
  {}
)(LoginPage);
