import { put } from 'redux-saga/effects';
import updateAdaptedTestRequest from '../../../apiCalls/hub/updateAdaptedTest';
import { updateAdaptedTestFailure, setAdaptedTestsNeedToBeUpdated } from '../../../../actions/adaptedTests';

function* updateAdaptedTest(body, adaptedTestId) {
  const response = yield updateAdaptedTestRequest(body, adaptedTestId);

  if (response.status === 'success') {
    yield put(setAdaptedTestsNeedToBeUpdated(true));
  }
  yield put(updateAdaptedTestFailure());
}

export default updateAdaptedTest;
