import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ragStatuses from '../../globals/ragStatuses';
import LoadingSpinner, { spinnerSizes } from '../LoadingSpinner/LoadingSpinner';
import ReadingBuddyTable from '../ReadingBuddyTable/ReadingBuddyTable';
import ErrorStrip from '../ErrorStrip/ErrorStrip';

class LevelDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationIndicies: 0
    };
  }

  getLevelIndex = () => {
    const { level = [] } = this.props;

    const levelsData = (level[0] || {}).levelsData || [];
    const viewingLevel = (levelsData[0] || {}).level || {};

    return this._getStudentLevels().indexOf(viewingLevel.id);
  };

  _isCurrentLevel = () => {
    const { student, level = [] } = this.props;

    const levelsData = (level[0] || {}).levelsData || [];
    const viewingLevel = (levelsData[0] || {}).level || {};

    if (!viewingLevel.id) {
      return false;
    }

    return !!student.levels.find(({ levelId, status }) => viewingLevel.id === levelId && status);
  };

  /**
   * Increments/decrements through an array of levels. Levels may stop and start anywhere or be missing.
   * Infinite navigation: If at end or start of array then loop back to the other end.
   * @param {String} direction 'up', or 'down'.
   */
  _findNextLevel = direction => {
    const { changeActiveItem } = this.props;
    const currentLevelIndex = this.getLevelIndex();
    const currentLevels = this._getStudentLevels();
    const navigateUpLevel =
      currentLevelIndex + 1 === currentLevels.length ? currentLevels[0] : currentLevels[currentLevelIndex + 1];
    const navigateDownLevel =
      currentLevelIndex === 0 ? currentLevels[currentLevels.length - 1] : currentLevels[currentLevelIndex - 1];
    const newLevelNumber = direction === 'up' ? navigateUpLevel : navigateDownLevel;
    changeActiveItem(newLevelNumber);
    return newLevelNumber;
  };

  /**
   * Checks store for selected student's avaliable levels. Then commits a sorted array of these
   * level numbers to state.
   */
  _getStudentLevels = () => {
    const { student } = this.props;
    return sortBy(student.levels.map(({ levelId }) => levelId));
  };

  _getStudentName = () => {
    const { student } = this.props;
    return student.name;
  };

  /**
   * Increments/decriments through possible student/class levels. Stops at start or end of array length.
   * @param {String} direction 'up', or 'down'.
   */
  navigateLevel = direction => {
    const { getLevelPanelFunc, student } = this.props;
    getLevelPanelFunc(student.id, this._findNextLevel(direction));
  };

  render() {
    const {
      student,
      loading = true,
      errors,
      level,
      onLevelUpConfirmation,
      onLevelDownConfirmation,
      onLevelChangeDelay,
      onLevelReset
    } = this.props;
    const { navigationIndicies } = this.state;

    if (errors) {
      return <ErrorStrip />;
    }

    if (!loading) {
      const responseDataLocation = level[navigationIndicies].levelsData[0] || {};
      const outputResponseDataLocation = {
        level: {
          dateCompleted: undefined,
          name: 'n/a',
          expected: 'n/a',
          ...(responseDataLocation.level || {})
        },
        attempts: [
          ...(responseDataLocation.attempts && responseDataLocation.attempts.length
            ? responseDataLocation.attempts
            : [
                {
                  status: 'n/a',
                  quizzes: {
                    passed: 0,
                    expected: 0,
                    completed: 0,
                    average: { percentage: 0 }
                  },
                  days: {
                    expected: 0,
                    count: 0
                  }
                }
              ])
        ]
      };

      const levelOutput = outputResponseDataLocation.level;
      const [attempt] = outputResponseDataLocation.attempts;
      const quizzes = attempt.quizzes;
      const levelChange = levelOutput.levelChange;
      const previousAttempts = outputResponseDataLocation.attempts.slice(1);

      const props = {
        navigation_data: {
          previous_attempt: false,
          currently_viewed_level: parseInt(levelOutput.id, 10),
          current_level: levelOutput.id,
          current_level_name: levelOutput.name,
          level_expected: levelOutput.expected,
          viewing_current_level: this._isCurrentLevel(),
          date_completed: levelOutput.dateCompleted
        },
        levelChange,
        datacard_data: {
          days_at_level: attempt.days.count,
          expected_days_at_level: attempt.days.expected,
          progress_status: attempt.status,
          quizzes_passed: quizzes.passed,
          quizzes_expected: quizzes.expected,
          quizzes_completed: quizzes.completed,
          quizzes_pass_score: quizzes.passScore.percentage,
          quizzes_average: quizzes.average.percentage,
          skipped_level: attempt.status === ragStatuses.SKIPPED,
          completeness: attempt.completeness
        },
        previousAttempts,
        number_of_attempt: 0,
        onNavigateLeft: () => this.navigateLevel('down', 'navigationIndicies'),
        onNavigateRight: () => this.navigateLevel('up', 'navigationIndicies'),
        isCurrentLevel: this._isCurrentLevel(),
        studentName: this._getStudentName(),
        onLevelUpConfirmation: () => onLevelUpConfirmation([student.id]),
        onLevelDownConfirmation: () => onLevelDownConfirmation([student.id]),
        onLevelChangeDelay: () => onLevelChangeDelay([student.id]),
        onLevelReset: () => onLevelReset([student.id])
      };
      return <ReadingBuddyTable {...props} />;
    }

    return <LoadingSpinner text="Loading level..." size={spinnerSizes.SMALL} />;
  }
}

LevelDetails.propTypes = {
  student: PropTypes.object.isRequired,
  level: PropTypes.array,
  loading: PropTypes.bool,
  onNavigateLeft: PropTypes.func,
  onNavigateRight: PropTypes.func,
  getLevelPanelFunc: PropTypes.func,
  changeActiveItem: PropTypes.func,
  studentName: PropTypes.string,
  onLevelUpConfirmation: PropTypes.func,
  onLevelDownConfirmation: PropTypes.func,
  onLevelChangeDelay: PropTypes.func,
  onLevelReset: PropTypes.func.isRequired,
  errors: PropTypes.string
};

export default LevelDetails;
