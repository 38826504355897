import { put, select } from 'redux-saga/effects';
import {
  removeAssignmentRequestSuccess,
  removeAssignmentRequestFailure,
  getBasketAssignmentsRequest
} from '../../../../../actions/ngsCourse';
import removeAssignmentApiCall from '../../../../apiCalls/ngs/assignments/deleteAssignments';

function* removeAssignment(data) {
  const response = yield removeAssignmentApiCall(data);
  const { meta: { refetch, contentIds } = { refetch: false } } = data;
  const orgId = yield select(state => state.identity.currentOrganisationId);

  if (response.status === 'success') {
    yield put(
      removeAssignmentRequestSuccess({
        ...response.data,
        assignments: data.assignments,
        removedContentIds: contentIds
      })
    );
    if (refetch) {
      yield put(
        getBasketAssignmentsRequest({
          orgId,
          courseId: data.courseId,
          audience: 'self'
        })
      );
    }
    return;
  }

  yield put(removeAssignmentRequestFailure(response.message));
}

export default removeAssignment;
