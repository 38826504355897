const ENTITY_TYPES = {
  ACTIVITY: 'ACTIVITY',
  ARCHIVED_CLASS: 'ARCHIVED_CLASS',
  ARCHIVED_ORGANISATION: 'ARCHIVED_ORGANISATION',
  ARCHIVED_PROFILE: 'ARCHIVED_PROFILE',
  CLASS: 'CLASS',
  ORGANISATION: 'ORGANISATION',
  PENDING_ORGANISATION: 'PENDING_ORGANISATION',
  PERSON: 'PERSON',
  PROFILE: 'PROFILE',
  REGISTER: 'REGISTER',
  RESOURCES: 'RESOURCES',
  SUPPORT: 'SUPPORT'
};

export default ENTITY_TYPES;
export const VALID_ENTITY_TYPES = Object.keys(ENTITY_TYPES);
