import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './HeaderPopup.scss';
import PopupOverlay from '../PopupOverlay/PopupOverlay.js';

/** This provides the PopupOverlay and compe consistent styling the several Popup shown in the header */
function HeaderPopup({ visible, children, id, className, onTogglePopup, aria, buttonElementId }) {
  return (
    <PopupOverlay
      id={id}
      visible={visible}
      onTogglePopup={onTogglePopup}
      hangRight
      customClassName={classnames(styles.headerPopup, className)}
      aria={aria}
      buttonElementId={buttonElementId}
    >
      {children}
    </PopupOverlay>
  );
}

HeaderPopup.propTypes = {
  /** Html id of the component */
  id: PropTypes.string.isRequired,
  /** Boolean flag to show the popup or not */
  visible: PropTypes.bool.isRequired,
  /** Method to show/hide the popup (Used to close the popup when use clicks outside or on a link) */
  onTogglePopup: PropTypes.func,
  /** Popup specific content */
  children: PropTypes.any.isRequired,
  /** Classname for the content to override the default stylings */
  className: PropTypes.string,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object,
  /** This is the ID of the button used to toggle the popup. Click to this element are ignored when closing the popup */
  buttonElementId: PropTypes.string
};

export default HeaderPopup;
