import * as t from '../actionTypes';

export const storeLineItemsRequest = (payload, callback) => ({
  type: t.NGI_STORE_LINE_ITEMS_REQUEST,
  payload,
  callback
});

export const storeLineItemsSuccess = payload => ({
  type: t.NGI_STORE_LINE_ITEMS_SUCCESS,
  payload
});

export const storeLineItemsFailure = error => ({
  type: t.NGI_STORE_LINE_ITEMS_FAILURE,
  payload: error
});
