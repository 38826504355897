/** People reducer
 *  This reducer will store the information about people in the system.
 *  This should be the single source of their information,  all other
 *  references to people should be by ID only and pull their data from here.
 */

const STORE_PEOPLE = 'people/STORE_PEOPLE';
import transformUsers from '../../utils/transformUsers.js';
import { CLEAR_MISSING_FIELDS } from '../sharedActions.js';

const initialState = {
  // TODO: Fetch this from server for current org.
  // TODO: A map of users with their userIds as keys would enable faster lookup.
  data: {}
};

export default function people(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_PEOPLE: {
      // Make sure we only UPDATE each person, not overwrite them: (Because the incoming data may have missing values)
      const updatedPeople = Object.keys(action.people).reduce((results, userId) => {
        // eslint-disable-next-line no-param-reassign
        results[userId] = {
          ...state.data[userId],
          ...action.people[userId]
        };
        return results;
      }, {});

      return {
        ...state,
        data: {
          ...state.data,
          ...updatedPeople
        }
      };
    }

    case CLEAR_MISSING_FIELDS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.userId]: {
            ...state.data[action.userId],
            email: action.email
          }
        }
      };

    default:
      return state;
  }
}

// Expects a map of id:{person} pairs:
export const storePeople = peopleData => ({
  type: STORE_PEOPLE,
  people: transformUsers(peopleData)
});
