import * as t from '../actionTypes';

export const editOrgGetDetailsRequest = id => ({
  type: t.EDIT_ORG_GET_DETAILS_REQUEST,
  payload: id
});

export const editOrgGetDetailsSuccess = org => ({
  type: t.EDIT_ORG_GET_DETAILS_SUCCESS,
  payload: org
});

export const editOrgGetDetailsFailure = reason => ({
  type: t.EDIT_ORG_GET_DETAILS_FAILURE,
  payload: reason
});

export const editOrgValidateInput = input => ({
  type: t.EDIT_ORG_VALIDATE_INPUT,
  payload: input
});

export const editOrgValidateCompleted = input => ({
  type: t.EDIT_ORG_VALIDATE_COMPLETED,
  payload: input
});

export const editOrgValidateError = () => ({
  type: t.EDIT_ORG_VALIDATE_ERROR,
  payload: null
});

export const editOrgRequest = (orgId, input) => ({
  type: t.EDIT_ORG_REQUEST,
  payload: { orgId, input }
});

export const editOrgSuccess = orgId => ({
  type: t.EDIT_ORG_SUCCESS,
  payload: orgId
});

export const editOrgFailure = reason => ({
  type: t.EDIT_ORG_FAILURE,
  payload: { reason }
});

export const editOrgClearFailure = () => ({
  type: t.EDIT_ORG_FAILURE_CLEAR,
  payload: null
});

export const editOrgReset = () => ({
  type: t.EDIT_ORG_RESET
});
