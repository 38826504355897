import * as t from '../actionTypes';

const initialState = {
  userId: '',
  activity: {},
  redeemed: {},
  launched: {}
};

export default function userPlatformStatistics(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_USER_PLATFORM_STATISTICS_SUCCESS:
      return {
        ...state,
        error: false,
        ...payload
      };
    default:
      return state;
  }
}
