import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PageWrapper from '../PageWrapper/PageWrapper';
import Button, { buttonTypes } from '../Button/Button';
import { featureIsEnabled, isLocal } from '../../globals/envSettings';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './OrganisationAdmin.scss';
import {
  isAdminSupportOrContent as isAdminSupportOrContentSelector,
  isELTReviewer as isELTReviewerSelector
} from '../../redux/selectors/authorization/user';

function AdminCoursesPage({
  localizedContent: { organisationAdmin: content },
  isAdminSupportOrContent,
  isELTReviewer
}) {
  return (
    <PageWrapper>
      <Helmet title={content.helmet_products_title} />
      <h1>{content.courses_title}</h1>
      <span className={styles.subheading}>{content.subtitle}</span>
      <div className={styles.linkContainer}>
        {featureIsEnabled('oup-data-upload') && (
          <>
            {isAdminSupportOrContent ? (
              <Button
                to="/courses/oup-data-upload"
                type={buttonTypes.SECONDARY}
                text={content.upload_course_spreadsheet}
                fullWidth
                customClassName={styles.button}
              />
            ) : null}

            {isELTReviewer ? (
              <Button
                to="/courses/preview"
                type={buttonTypes.SECONDARY}
                text={content.preview_course}
                fullWidth
                customClassName={styles.button}
              />
            ) : null}

            {isAdminSupportOrContent && isLocal() ? (
              <Button
                to="/courses/publish"
                type={buttonTypes.SECONDARY}
                text={content.publish_course}
                fullWidth
                customClassName={styles.button}
              />
            ) : null}
          </>
        )}
      </div>
    </PageWrapper>
  );
}

AdminCoursesPage.propTypes = {
  localizedContent: PropTypes.object,
  isAdminSupportOrContent: PropTypes.bool.isRequired,
  isELTReviewer: PropTypes.bool.isRequired
};

export default compose(
  connect(state => ({
    isAdminSupportOrContent: isAdminSupportOrContentSelector(state),
    isELTReviewer: isELTReviewerSelector(state)
  })),
  withLocalizedContent('organisationAdmin')
)(AdminCoursesPage);
