import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { safePanelLink, safePanelUnlink } from '../../../../utils/links/panelLinks.js';
import ListPageControls from '../../../../components/ListPageControls/ListPageControls.js';
import PopoutPanel from '../../../../components/PopoutPanel/PopoutPanel.js';
import AddToLibrary from './panels/AddToLibrary/AddToLibrary.js';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon.js';
import Button from '../../../../components/Button/Button.js';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal.js';
import Table /* , { columnTypes } */ from '../../../../components/Table/Table.js';
import PaginationButtons from '../../../../components/PaginationButtons/PaginationButtons.js';
import EntityRepresentation, {
  SIZES as thumbnailSizes
} from '../../../../components/EntityRepresentation/EntityRepresentation.js';
import RocketLolly from '../../../../components/RocketLolly/RocketLolly.js';
import ProductDetails from '../../../../components/ProductDetails/ProductDetails.js';
import { sanitizeUrl } from '../../../../utils/url.js';

import userRoles from '../../../../globals/userRoles.js';
import colors from '../../../../globals/colors';
import styles from './LibraryTab.scss';

import { searchProductSortOptions } from '../../../../globals/searchFilters.js';

import { setSearchBy } from '../../../../redux/reducers/library/library.reducer.js';
import { setTerm, triggerSearch, setSort, setPage } from '../../../../redux/reducers/data/search.reducer.js';
import { showClosePanelModal } from '../../../../redux/reducers/archiveUsers.reducer.js';

import withSearchInitialiser from '../../../../components/SearchInitialiser/withSearchInitialiser';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import withDataRecency from '../../../../dataRecency/withDataRecency';
import SubSectionSkeletonLoader from '../../../../components/SkeletonLoader/SubSectionSkeletonLoader';

const rolesAllowedToAddLicences = [
  userRoles.TEACHER_ADMIN,
  userRoles.ORG_ADMIN,
  userRoles.OUP_ADMIN,
  userRoles.OUP_SUPPORT,
  userRoles.OUP_SERVICE_MANAGER
];

class LibraryTab extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.orgLicencesDataRecency.synced) {
      this.triggerSearchActionFunc();
    }
  }

  componentWillUnmount() {
    const { setSearchByAction } = this.props;
    setSearchByAction('libProducts');
  }

  setSortActionFunc = sort => {
    const { searchBy, setSortAction } = this.props;
    setSortAction(searchBy, sort[0]);
  };

  setPageActionFunc = page => {
    const { searchBy, setPageAction } = this.props;
    setPageAction(searchBy, page);
  };

  setSearchTermFunc = value => {
    const { setSearchTerm } = this.props;
    setSearchTerm('libProducts', value);
    setSearchTerm('libCodes', value);
  };

  triggerSearchActionFunc = () => {
    const { searchBy, triggerSearchAction } = this.props;
    triggerSearchAction(searchBy);
  };

  searchInputOnKeyUp = event => {
    const { searchBy, triggerSearchAction } = this.props;
    if (event.key === 'Enter') {
      triggerSearchAction(searchBy);
    }
  };

  render() {
    const {
      localizedContent: { mySchoolLibraryTab: content, removeLearningMaterialModal: rlmModalContent },
      params,
      userCanAddLicences,
      products,
      searchTerm,
      sort,
      page,
      loadingResults,
      searchFailed,
      resultIds,
      totalResults,
      searchBy,
      orgName,
      setSearchByAction,
      history,
      orgIsArchived,
      showModalAction,
      showConfirmModal,
      orgLicencesDataRecency
    } = this.props;

    const tableHeadings = [
      { heading: content.e_book_heading },
      { heading: content.licence_usage_heading },
      { heading: content.details_heading }
    ];

    return (
      <div>
        <ListPageControls
          idPrefix="product"
          searchInputLabel={content.search_library_label_text}
          searchInputPlaceholder={
            searchBy === 'libProducts' ? content.search_library_placeholder_text : '1111-2222-3333'
          }
          searchInputValue={searchTerm}
          disabled={orgIsArchived}
          searchInputOnChange={this.setSearchTermFunc}
          searchInputOnSubmit={this.triggerSearchActionFunc}
          searchInputOnKeyUp={this.searchInputOnKeyUp}
          dropdownProps={{
            id: 'productSearchBy',
            label: content.search_dropdown_option_label,
            options: [
              { value: 'libProducts', text: content.search_by_product },
              { value: 'libCodes', text: content.search_by_access_code }
            ],
            value: searchBy,
            onChange: setSearchByAction
          }}
          sortOnChange={this.setSortActionFunc}
          sortOptions={searchProductSortOptions('libraryProductSearch', sort)}
          newButtonText={content.button_add_to_library}
          newButtonTo={userCanAddLicences ? safePanelLink('addLicences') : null}
          ariaControls="searchResults"
          loading={orgLicencesDataRecency.syncing}
          loadingMessage={content.loading_message}
          loaded={orgLicencesDataRecency.synced}
          loadedMessage={content.loaded_message}
          showSkeletonLoader={loadingResults}
        />

        {loadingResults ? (
          <SubSectionSkeletonLoader
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED}
            backgroundColor={colors.COLOR_WHITE}
          />
        ) : null}
        {loadingResults && (
          <div style={{ textAlign: 'center' }}>
            <div style={{ width: '5rem', margin: '2rem auto' }}>
              <SVGIcon glyph={GLYPHS.ICON_LOADING} />
            </div>
          </div>
        )}

        {searchFailed && (
          <div style={{ textAlign: 'center' }} id="searchResults" role="region" aria-live="polite" aria-atomic="true">
            <div style={{ width: '5rem', margin: '2rem auto 0' }}>
              <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
            </div>
            <p className="gin2">{content.search_results_loading_error_text}</p>
            <Button text="Retry" onClick={this.triggerSearchActionFunc} />
          </div>
        )}

        {!loadingResults && !searchFailed && resultIds.length === 0 && searchBy !== 'libCodes' && (
          <div className="grid">
            <div className="row">
              <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
                <p className="gin-top1 gin-bot1">{content.search_no_results_text}</p>
              </div>
            </div>
          </div>
        )}

        {!loadingResults && !searchFailed && resultIds.length === 0 && searchBy === 'libCodes' && (
          <div className="grid">
            <div className="row">
              <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
                <p className="gin-top1 gin-bot1">{content.product_code_not_exact_match_text}</p>
              </div>
            </div>
          </div>
        )}

        {!(searchFailed || loadingResults) && resultIds.length > 0 && (
          <div className="grid">
            <div className="row">
              <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col gin-top2">
                <Table
                  customClass={styles.libTab}
                  columns={tableHeadings}
                  rows={resultIds.map(productId => {
                    const product = products[productId];
                    const cells = [
                      <div key={0}>
                        <EntityRepresentation
                          thumbnailSize={thumbnailSizes.TABLE}
                          thumbnailImageSrc={`${sanitizeUrl(product.coverImage)}`}
                          thumbnailSquareImg
                          line1Text={product.title}
                          line2Text={product.subtitle}
                          // linkTo={product.launchUrl}
                          externalLink
                        />
                      </div>,
                      <div key={1}>
                        <RocketLolly
                          items={[
                            {
                              text: content.product_started_text,
                              value: product.redeemedCount
                            },
                            {
                              text: content.product_not_started_text,
                              value: product.assignedCount
                            },
                            {
                              text: content.product_avalible_text,
                              value: product.availableCount
                            }
                          ]}
                        />
                      </div>,
                      `${content.show_details_text}`
                    ];

                    return {
                      id: `product${productId}`,
                      revealableContent: (
                        <ProductDetails
                          id={`productDetails-${productId}`}
                          title={product.title}
                          subtitle={product.subtitle}
                          description={product.description}
                          listData={[
                            { label: content.product_series_title_text, text: product.series },
                            { label: content.product_ISBN_title_text, text: product.isbn },
                            { label: `${content.product_Author_title_text}${' '}`, text: product.author }
                          ]}
                        />
                      ),
                      cells
                    };
                  })}
                />

                {totalResults > 10 && (
                  <div className="gin-top2">
                    <PaginationButtons
                      idPrefix="libProductSearch"
                      value={page}
                      numberOfPages={Math.ceil(totalResults / 10)}
                      onClick={this.setPageActionFunc}
                      aria={{ 'aria-controls': 'searchResults' }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <PopoutPanel
          id="addToLibrary"
          ariaLabel={content.aria_label_add_licences}
          isOpen={params.panelName === 'addLicences'}
        >
          <AddToLibrary
            closePanel={() => showModalAction(true)}
            orgName={orgName}
            onComplete={() => {
              history.push(safePanelUnlink('addLicences'));
            }}
          />
        </PopoutPanel>
        {showConfirmModal && (
          <ConfirmationModal
            title={rlmModalContent.title}
            body={rlmModalContent.body}
            positiveClickText={rlmModalContent.positiveClickText}
            negativeClickText={rlmModalContent.negativeClickText}
            positiveClick={() => {
              // Close the Popout panel
              history.push(safePanelUnlink('addLicences'));
              showModalAction(false);
            }}
            negativeClick={() => showModalAction(false)}
          />
        )}
      </div>
    );
  }
}

LibraryTab.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  params: PropTypes.any,
  userCanAddLicences: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  products: PropTypes.object.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  searchFailed: PropTypes.bool.isRequired,
  resultIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,
  searchBy: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  orgLicencesDataRecency: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setSearchByAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  orgIsArchived: PropTypes.bool.isRequired,
  showModalAction: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.bool.isRequired
};

export default compose(
  compose(
    withSearchInitialiser({
      searchSource: 'libProducts',
      initialFilters: { showOnlyRedeemed: true }
    })
  ),
  withRouter,
  withLocalizedContent('mySchoolLibraryTab', 'removeLearningMaterialModal'),
  withDataRecency('orgLicences'),
  connect(
    (state, { orgId }) => {
      const organisation = state.organisations.data[orgId] || {};
      return {
        userCanAddLicences: rolesAllowedToAddLicences.includes(state.identity.role),
        products: state.products.data,
        searchTerm: state.search[state.library.instance].term,
        sort: state.search[state.library.instance].sort,
        page: state.search[state.library.instance].page,
        loadingResults: state.search[state.library.instance].loading,
        searchFailed: state.search[state.library.instance].error,
        resultIds: state.search[state.library.instance].ids,
        totalResults: state.search[state.library.instance].totalResults,
        searchBy: state.library.instance,
        orgName: organisation.name,
        orgIsArchived: organisation.archived,
        showConfirmModal: state.archiveUsers.showModal
      };
    },
    {
      setSearchByAction: setSearchBy,
      setSearchTerm: setTerm,
      triggerSearchAction: triggerSearch,
      setSortAction: setSort,
      setPageAction: setPage,
      showModalAction: showClosePanelModal
    }
  )
)(LibraryTab);
