import React from 'react';
import PropTypes from 'prop-types';
import styles from './Illustration.scss';

function Illustration({ illustrationSrc, illustrationAltText }) {
  return (
    <div className={styles.illustrationContainer}>
      <img src={illustrationSrc} alt={illustrationAltText} />
    </div>
  );
}

Illustration.propTypes = {
  illustrationSrc: PropTypes.string.isRequired,
  illustrationAltText: PropTypes.string
};

export default Illustration;
