import { takeLatest } from 'redux-saga/effects';

import {
  BYPASS_PRODUCT_SELECTION,
  LOAD_LICENCE_DATA,
  SELECT_PRODUCT,
  SUBMIT_ASSIGNMENT,
  SET_TEACHER_ASSIGN_LICENCES,
  GO_TO_REVIEW_LICENCES
} from '../../../../../reducers/assignLearningMaterial.reducer.js';

import handleProductSelection from './handleProductSelection.js';
import handleProductSelectionMultiple from './handleProductSelectionMultiple.js';
import { featureIsEnabled } from '../../../../../../globals/envSettings';

import handleAssignmentSubmission from './handleAssignmentSubmission.js';

export default function* assignLearningMaterial() {
  console.log('[assignLearningMaterial] Starting');

  // Start the search handler in isolation
  yield takeLatest(
    [GO_TO_REVIEW_LICENCES, SELECT_PRODUCT, LOAD_LICENCE_DATA, BYPASS_PRODUCT_SELECTION, SET_TEACHER_ASSIGN_LICENCES],
    featureIsEnabled('add-multiple-learning-material') ? handleProductSelectionMultiple : handleProductSelection
  );
  yield takeLatest(SUBMIT_ASSIGNMENT, handleAssignmentSubmission);
}
