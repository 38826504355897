import PropTypes from 'prop-types';
import React from 'react';
import ClassRepresentation from '../ClassRepresentation/ClassRepresentation.js';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIconHeading, { types as iconHeadingTypes } from '../PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../ScrollContainer/ScrollContainer.js';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail.js';
import UserConfirmationList from '../UserConfirmationList/UserConfirmationList';
import withLocalizedContent from '../../language/withLocalizedContent';
import { orgRoles } from '../../globals/orgRoles';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import { isHubMode } from '../../utils/platform';
import getClassLink from '../../structure/HubDashboardLayout/Services/getClassLink';
import getProgressLink from '../../structure/HubDashboardLayout/Services/getProgressLink';

function getIconHeading(content, hubContent, classroomName, createdClassroomId, failedUserIds) {
  if (!createdClassroomId) {
    return {
      title: content.creating_class_error,
      type: iconHeadingTypes.ERROR,
      illustrationSrc: HubIllustrationConstants.ERROR,
      HubIllustrationAltText: HubIllustrationAltText.ERROR
    };
  }

  if (failedUserIds && failedUserIds.length) {
    return {
      title: (content.creating_class_partial_error || '').replace('{className}', classroomName),
      type: iconHeadingTypes.WARNING,
      illustrationSrc: HubIllustrationConstants.WARNING,
      HubIllustrationAltText: HubIllustrationAltText.WARNING
    };
  }
  return {
    title: (content.creating_class_success || '').replace('{className}', classroomName),
    type: iconHeadingTypes.VALID,
    subtitle: isHubMode() && hubContent.add_students_and_materials_to_class,
    illustrationSrc: HubIllustrationConstants.SUCCESS,
    HubIllustrationAltText: HubIllustrationAltText.SUCCESS
  };
}

function AddClassroomConfirmation({
  localizedContent: { addClassPanel: content, hubGlossary: hubContent },
  orgRole,
  orgId,
  createdClassroomId,
  classroomName,
  teachers = {},
  students = {},
  failedUserIds,
  onAddMaterialClick,
  onDoneClick,
  goToMyClass,
  goToNameInputPanel,
  formState
}) {
  return (
    <ScrollContainer
      formState={formState}
      headerContent={
        <div>
          {isHubMode() ? (
            <PopoutPanelIllustrationHeading
              {...getIconHeading(content, hubContent, classroomName, createdClassroomId, failedUserIds)}
            />
          ) : (
            <PopoutPanelIconHeading
              {...getIconHeading(content, hubContent, classroomName, createdClassroomId, failedUserIds)}
            />
          )}
        </div>
      }
      footerContent={
        isHubMode() ? (
          <PopoutActionFooter
            primaryButtonText={createdClassroomId ? hubContent.go_to_my_class : hubContent.try_again}
            primaryButtonAction={() => {
              if (createdClassroomId) {
                return goToMyClass(getProgressLink(getClassLink(createdClassroomId), orgId));
              }
              return goToNameInputPanel();
            }}
          />
        ) : (
          <PopoutActionFooter
            secondaryButtonText={
              createdClassroomId && failedUserIds.length === 0 && orgRole !== orgRoles.PRIMARY_SCHOOL
                ? content.assign_material_to_class
                : null
            }
            secondaryButtonAction={orgRole !== orgRoles.PRIMARY_SCHOOL ? onAddMaterialClick : null}
            primaryButtonText={content.complete_panel_text}
            primaryButtonAction={onDoneClick}
          />
        )
      }
    >
      {!isHubMode() && (
        <div>
          <div style={{ padding: '1rem', backgroundColor: '#E5E5E0' }}>
            <ClassRepresentation id="classroomName" thumbnailSize={thumbnailSizes.MEDIUM} name={classroomName} />
          </div>

          <UserConfirmationList
            heading={content.heading_teachers_text}
            id="classroomTeachers"
            users={teachers}
            successUserStatusText={content.confirm_teacher_success_status}
            warningUserStatusText={content.confirm_teacher_warning_status}
            noUsersText={content.no_teachers_added_text}
            failedIds={!createdClassroomId ? Object.keys(teachers) : failedUserIds}
          />
          <UserConfirmationList
            heading={content.heading_students_text}
            id="classroomStudents"
            users={students}
            successUserStatusText={content.confirm_students_success_status}
            warningUserStatusText={content.confirm_students_warning_status}
            noUsersText={content.no_students_added_text}
            failedIds={!createdClassroomId ? Object.keys(students) : failedUserIds}
          />
        </div>
      )}
    </ScrollContainer>
  );
}

AddClassroomConfirmation.propTypes = {
  classroomName: PropTypes.string.isRequired,
  orgRole: PropTypes.any,
  orgId: PropTypes.string,
  createdClassroomId: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  teachers: PropTypes.object,
  students: PropTypes.object,
  failedUserIds: PropTypes.arrayOf(PropTypes.string),
  onAddMaterialClick: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func,
  goToMyClass: PropTypes.func.isRequired,
  goToNameInputPanel: PropTypes.func.isRequired,
  formState: PropTypes.string
};

export default withLocalizedContent('addClassPanel', 'hubGlossary')(AddClassroomConfirmation);
