import * as t from '../../actionTypes';

export const mode = {
  NOOP: 'NOOP',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

const initialState = {
  subid: null,
  expiry: null,
  mode: mode.NOOP
};

export default function inspectionCopy(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_SUBSCRIPTION_ID_REQUEST:
      return {
        ...state,
        mode: mode.LOADING,
        subid: null,
        expiry: null,
        errors: {}
      };
    case t.GET_SUBSCRIPTION_ID_SUCCESS:
      return {
        ...state,
        mode: mode.SUCCESS,
        subid: payload.subscriptionID,
        expiry: payload.expiry,
        errors: {}
      };
    case t.GET_SUBSCRIPTION_ID_FAILURE:
      return {
        ...state,
        mode: mode.ERROR,
        subid: null,
        expiry: null,
        errors: payload.errors
      };
    case t.GET_SUBSCRIPTION_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
