const { getApiBase } = require('./api');

async function getProductsByIsbn(fetch, isbns = []) {
  if (isbns.length > 60) {
    throw new Error('ISBN lists this large are unsupported due to limit of URL length, please batch requests');
  }

  if (!isbns || isbns.length === 0) {
    console.warn(`Cannot search if you do not supply isbns`, isbns);
    return {};
  }

  const productsUrl = `${getApiBase()}/open/products?isbn=${isbns.join(',')}`;
  const productsResponse = await fetch(productsUrl);
  const { data: results } = await productsResponse.json();
  return results;
}

async function getProductMetadata(fetch, isbns = [], batchSize = 50) {
  const promises = [];
  const batches = [];

  // ensure only one lookup per ISBN
  const uniqueIsbns = [...new Set(isbns)];

  // same approach as for eacProduct
  while (uniqueIsbns.length) {
    batches.push(uniqueIsbns.splice(0, batchSize));
  }

  batches.forEach(async batch => {
    promises.push(getProductsByIsbn(fetch, batch));
  });

  const results = await Promise.all(promises);

  const mergedIdMap = {};

  results.forEach(isbnProductIdMap => {
    Object.keys(isbnProductIdMap).forEach(index => {
      const product = isbnProductIdMap[index];
      mergedIdMap[product.isbn] = product;
    });
  });

  return mergedIdMap;
}

/**
 * Randomly and unhelpfully, someone decided to rename various properties of our canonical product metadata ("VPIMS format")
 * so now we have to support this extra format which includes confusing use of "user role" to mean user type
 * thanks a bunch whoever decided to do that, really useful.
 */
function clientFormatOfProductMetadata(product) {
  if (!product) {
    console.warn('No product sent for formatting');
    return null;
  }
  const p = { ...product };
  if (p._id) delete p._id; // not relevant to the client where it exists
  p.cefrLevel = product.cefr_level || null;
  delete p.cefr_level;

  p.productLaunchUrl = product.productlaunchurl || '';
  delete p.productlaunchurl;

  p.deepLinkUrl = product.deeplinkurl || '';
  delete p.deeplinkurl;

  p.productUserRole = product.target_usertype || null;
  delete p.target_usertype;

  p.comingSoonUntil = product.coming_soon_until || null;
  delete p.coming_soon_until;

  p.vstDomain = product['vst:subdomain'] || null;
  delete p['vst:subdomain'];

  p.image = {
    // Because once in the object isn't enough somehow, we have the title of the product nested inside the "image"
    // object that has been created. The leaking through into the data model of the client side use of that
    // data is all too apparent. No, this is not necessary but is left because otherwise things will break
    // but a refactor is surely overdue to remove the redundant duplication
    title: product.title,
    // is there a greater expression of the futility of human enterprise than this pointless repetition?
    altText: product.title,
    // definitely a better name, right? - way clearer than "cover" - who would understand THAT?!
    defaultUrl: product.cover
  };
  delete p.cover;
  return p;
}
module.exports = {
  getProductMetadata,
  clientFormatOfProductMetadata
};
