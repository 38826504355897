import signedFetch, { JSON_HEADERS } from './util/signedFetch.js';

/**
 * This takes the VST activation code as input and checks activation code validity
 * activationcode - String
 */
export default data =>
  signedFetch('getVstLicenceDetails', `${__API_BASE__}/open/check-vst-licence`, 'POST', data.body, {
    ...JSON_HEADERS,
    'temp-client-id': data.headers.tempClientId
  });
