/* eslint-disable import/prefer-default-export */
import getScreenSizeAndPlatform from '../utils/browser/screenSizeAndPlatform';
import { BROWSER_PLATFORMS } from './appConstants';

export const setFavicons = iconFolder => {
  const appleIcon = {
    rel: 'apple-touch-icon',
    type: 'image/png',
    sizes: '180x180',
    href: `/media/favicons/${iconFolder}/apple-touch-icon.png`
  };

  const shortcutIcon = {
    rel: 'shortcut icon',
    type: 'image/x-icon',
    href: `/media/favicons/${iconFolder}/favicon.ico`
  };

  const icon16 = {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: `/media/favicons/${iconFolder}/favicon-16.png`
  };

  const icon32 = {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: `/media/favicons/${iconFolder}/favicon-32.png`
  };

  const icon48 = {
    rel: 'icon',
    type: 'image/png',
    sizes: '48x48',
    href: `/media/favicons/${iconFolder}/favicon-48.png`
  };

  let icons = [];
  const platform = getScreenSizeAndPlatform();
  switch (platform) {
    case BROWSER_PLATFORMS.DEKSTOP:
      if (iconFolder === 'hub') {
        icons = [icon48];
      } else {
        icons = [icon16];
      }
      break;
    case BROWSER_PLATFORMS.MOBILE_ANDROID:
      icons = [icon32];
      break;
    case BROWSER_PLATFORMS.MOBILE_APPLE:
      icons = [appleIcon];
      break;
    default:
      icons = [shortcutIcon];
  }

  return icons;
};
