// Return a copy of the object, filtered to only have the whitelisted keys:
// If keys is empty then the object is copied with all keys.
// Inspired by https://lodash.com/docs/4.17.4#pick
export default function pick(map = {}, keys = []) {
  return keys && keys.length
    ? Array.from(keys).reduce((result, key) => {
        if (key in map) result[key] = map[key];
        return result;
      }, {})
    : { ...map };
}
