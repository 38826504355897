import { featureIsEnabled } from '../../../globals/envSettings';

const getTestDropdownItems = (
  content,
  {
    previewTest = null,
    adaptInTestBuilderAction = null,
    setTestAction = null,
    downloadPrintVersionAction = null,
    deleteAdaptedTest = null
  },
  { isAdapted = false, hasPrint = true }
) => {
  const menuItems = [
    {
      title: content.preview,
      action: previewTest
    }
  ];
  if (featureIsEnabled('hub-set-test') && setTestAction) {
    menuItems.push({
      title: content.set_test_online,
      action: setTestAction
    });
  }
  if (featureIsEnabled('hub-get-adapted-tests') && adaptInTestBuilderAction) {
    menuItems.push({
      title: content.adapt_in_test_builder,
      action: adaptInTestBuilderAction
    });
  }
  if (hasPrint) {
    menuItems.push({
      title: content.download_prin_version,
      action: downloadPrintVersionAction
    });
  }
  if (isAdapted) {
    menuItems.push({
      title: content.delete_test,
      action: deleteAdaptedTest
    });
  }
  return menuItems;
};

export default getTestDropdownItems;
