import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';

function CopyToClipboard({ id, label, value, buttonTitle = 'Copy', buttonText = 'Copy', multiline = false }) {
  return (
    <TextInputWithButton
      id={id}
      label={label}
      value={value}
      onValueChange
      buttonTitle={buttonTitle}
      buttonText={buttonText}
      buttonAction={() => copy(value)}
      onChange={() => {}}
      readOnly
      readonlyMultiline={multiline}
    />
  );
}

CopyToClipboard.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  buttonTitle: PropTypes.any,
  buttonText: PropTypes.any,
  multiline: PropTypes.bool
};

export default CopyToClipboard;
