import * as t from '../../actionTypes';

const requestStatusOptions = { success: {}, isLoading: true, error: {} };
const successStatusOptions = { success: {}, isLoading: false, error: {} };
const failureStatusOptions = { success: {}, isLoading: false, error: {} };

const initialState = {
  isLoading: false,
  error: {},
  success: {}
};

function createProduct(state = initialState, action) {
  switch (action.type) {
    case t.CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        ...requestStatusOptions,
        error: {}
      };
    case t.CREATE_PRODUCT_SUCCESS:
      return { ...state, ...successStatusOptions, success: action.payload, error: {} };
    case t.CREATE_PRODUCT_FAILURE:
      return { ...state, ...failureStatusOptions, error: action.payload, success: {} };
    default:
      return state;
  }
}

export default createProduct;
