import { useEffect, useState } from 'react';

const useMediaQuery = query => {
  /**
   * @query parameter expects a data of type string which should be a media feature of the css @media rule, e.g. '(max-width: 560px)'
   */
  const [currentMedia, setCurrentMedia] = useState(false);
  useEffect(() => {
    const newMedia = window.matchMedia(query);
    if (newMedia.matches !== currentMedia) {
      setCurrentMedia(newMedia.matches);
    }

    const listener = () => setCurrentMedia(newMedia.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [currentMedia, query]);

  return currentMedia;
};

export default useMediaQuery;
