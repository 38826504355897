import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './OteSocialNetworkBar.scss';
import cmsContent from '../../../../utils/cmsContent.js';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';

export default class OteSocialNetworkBar extends Component {
  constructor({ className }) {
    super({ className });
    this.state = { CMS: {} };
    this.className = className;
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.oteHomepage || {} });
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.oteHomepage || {} });
  }

  render() {
    const { CMS } = this.state;

    return (
      <div className={`${styles.OteSocialNetworkBar} ${this.className}`} data-testid="SOCIAL_NETWORKS_BAR">
        <div className={styles.OteSocialNetworkBarContainer}>
          <div className={styles.iconsContainer}>
            <a
              href={CMS.twitter_url}
              target="_blank"
              rel="noreferrer"
              className={styles.iconContainer}
              aria-label="twitter"
            >
              <SVGIcon glyph={GLYPHS.ICON_TWITTER_LOGO} className={styles.logo} />
            </a>
            <a
              href={CMS.facebook_url}
              target="_blank"
              rel="noreferrer"
              className={styles.iconContainer}
              aria-label="facebook"
            >
              <SVGIcon glyph={GLYPHS.ICON_FACEBOOK_LOGO} className={styles.logo} />
            </a>
            <a
              href={CMS.youtube_url}
              target="_blank"
              rel="noreferrer"
              className={styles.iconContainer}
              aria-label="youtube"
            >
              <SVGIcon glyph={GLYPHS.ICON_YOUTUBE_LOGO} className={styles.logo} />
            </a>
            <a href={CMS.blog_url} target="_blank" rel="noreferrer" className={styles.iconContainer} aria-label="blog">
              <SVGIcon glyph={GLYPHS.ICON_BLOG} className={styles.logo} />
            </a>
            <a
              href={CMS.instagram_url}
              target="_blank"
              rel="noreferrer"
              className={styles.iconContainer}
              aria-label="instagram"
            >
              <SVGIcon glyph={GLYPHS.ICON_INSTAGRAM_LOGO} className={styles.logo} />
            </a>
            <a
              href={CMS.linkedin_url}
              target="_blank"
              rel="noreferrer"
              className={styles.iconContainer}
              aria-label="linkedin"
            >
              <SVGIcon glyph={GLYPHS.ICON_LINKEDIN_LOGO} className={styles.logo} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

OteSocialNetworkBar.propTypes = {
  className: PropTypes.string
};
