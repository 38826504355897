import { put } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch.js';
import { resendInvitationFailure, resendInvitationSuccess } from '../../../../actions/resendInvitation';

const getResendOrgInviteUrl = (orgId, userId) => `${__API_BASE__}/org/${orgId}/user/${userId}/email/org-invite`;

function* resendOrgAdminInvitation({ orgId, userId }) {
  const response = yield signedFetch('resendOrgInvite', getResendOrgInviteUrl(orgId, userId), 'POST');
  if (response.status.toLowerCase() === 'success') {
    yield put(resendInvitationSuccess());
    return;
  }
  const errors = Object.values(response.message);
  yield put(resendInvitationFailure(errors));
}

export default resendOrgAdminInvitation;
