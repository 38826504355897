import * as t from '../actionTypes';

// -- organization
export const oicTestCreditsOrgInput = input => ({
  type: t.OIC_TEST_CREDITS_ORG_INPUT,
  payload: input
});

export const oicTestCreditsOrgValidate = input => ({
  type: t.OIC_TEST_CREDITS_ORG_VALIDATE,
  payload: input
});

export const oicTestCreditsOrgValidated = input => ({
  type: t.OIC_TEST_CREDITS_ORG_VALIDATED,
  payload: input
});

export const oicTestCreditsOrgState = input => ({
  type: t.OIC_TEST_CREDITS_ORG_STATE,
  payload: input
});

// test credits

export const createOicTestCreditsInput = input => ({
  type: t.CREATE_OIC_TEST_CREDITS_INPUT,
  payload: input
});

export const getOicTestCreditsRequest = input => ({
  type: t.GET_OIC_TEST_CREDITS_REQUEST,
  payload: input
});

export const getOicTestCreditsSuccess = input => ({
  type: t.GET_OIC_TEST_CREDITS_SUCCESS,
  payload: input
});

export const getOicTestCreditsEmptySuccess = input => ({
  type: t.GET_OIC_TEST_CREDITS_EMPTY_SUCCESS,
  payload: input
});

export const getOicTestCreditsFailure = errors => ({
  type: t.GET_OIC_TEST_CREDITS_FAILURE,
  payload: errors
});

export const getOicTestCreditsProducts = input => ({
  type: t.GET_TEST_CREDITS_PRODUCTS,
  payload: input
});

export const oicTestCreditsReset = () => ({
  type: t.OIC_TEST_CREDITS_RESET,
  payload: null
});

// -- create
export const createOicTestCreditsRequest = input => ({
  type: t.CREATE_OIC_TEST_CREDITS_REQUEST,
  payload: input
});

export const createOicTestCreditsSuccess = input => ({
  type: t.CREATE_OIC_TEST_CREDITS_SUCCESS,
  payload: input
});

export const createOicTestCreditsFailure = input => ({
  type: t.CREATE_OIC_TEST_CREDITS_FAILURE,
  payload: input
});

// -- update
export const updateOicTestCreditsRequest = input => ({
  type: t.UPDATE_OIC_TEST_CREDITS_REQUEST,
  payload: input
});

export const updateOicTestCreditsSuccess = input => ({
  type: t.UPDATE_OIC_TEST_CREDITS_SUCCESS,
  payload: input
});

export const updateOicTestCreditsFailure = errors => ({
  type: t.UPDATE_OIC_TEST_CREDITS_FAILURE,
  payload: errors
});
