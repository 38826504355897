import { put, select } from 'redux-saga/effects';
import { parse as parseQueryString } from 'query-string';
import actions from '../../../../actions';
import editCurrentUser from '../../../api/editCurrentUser';
import { getUserDetails } from '../user/userProfile.saga';
import validateEditUserInput from './validateEditUserInput';
import { getCurrentPlatform } from '../../../../../utils/platform.js';

function* editUserIdentity(input) {
  yield put(actions.editUserInitialConsent());
  const { currentOrganisationId } = yield select(state => state.identity);
  const errors = yield validateEditUserInput(currentOrganisationId, input);
  const hasErrors = Object.values(errors).some(Boolean);
  if (hasErrors) {
    yield put(actions.editUserValidateInputError());
    return;
  }
  input.orgId = currentOrganisationId;
  input.platformCode = getCurrentPlatform();
  const response = yield editCurrentUser(input);
  if (response.status.toLowerCase() === 'success') {
    const queryString = parseQueryString(window.location.search) || {};
    const redirectTo = decodeURIComponent(queryString.target_url || '');

    if (redirectTo && input.tsAndCsAccepted) {
      yield put(actions.acceptOlbTermsSuccess());
    } else {
      yield put(actions.editUserGetDetailsRequest());
      yield put(actions.editUserSuccess());
      yield getUserDetails();
    }

    return;
  }
  yield put(actions.editUserFailure());
}

export default editUserIdentity;
