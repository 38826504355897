import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getStudentReport from '../../../api/getStudentReport';

function* getStudentProgressItems(orgId, studentId, locationId) {
  const response = yield getStudentReport('orb/progress-items', orgId, studentId, locationId);
  if (response.status === 'success') {
    yield put(actions.studentProgressItemsSuccess(response.data.items));
    return;
  }
  yield put(actions.studentProgressItemsFailure(response.message));
}

export default getStudentProgressItems;
