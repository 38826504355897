import { select } from 'redux-saga/effects';
import { getCurrentPlatform } from '../../../../../../utils/platform';
import userRoles from '../../../../../../globals/userRoles';
import archiveLearningAssignmentsApi from '../../../../apiCalls/library/archiveLearningAssignments.api';

export default function* removeLearningMaterialRequest() {
  let searchInstance;

  const { context, userRole } = yield select(state => ({
    context: state.removeLearningMaterial.context,
    userRole: state.identity.role
  }));

  if (context === 'ORG') {
    searchInstance = 'orgAssignments';
  } else if (context === 'CLASS') {
    searchInstance = 'classAssignments';
  } else if (context === 'USER') {
    searchInstance = 'userAssignments';
  }

  const { orgId, notifyLearners, selectedAssignments, data } = yield select(state => ({
    orgId: userRole === userRoles.OUP_ADMIN ? state.organisationPage.orgId : state.identity.currentOrganisationId,
    groupId: state.removeLearningMaterial.groupId,
    notifyLearners: state.removeLearningMaterial.notifyLearners,
    selectedAssignments: state.removeLearningMaterial.assignments,
    data: state.search[searchInstance].data || {}
  }));

  const platformCode = getCurrentPlatform().toUpperCase();
  // Group assignments for the same groupId where the assignment isn't for a specific user
  const groups = {};
  const individualAssignments = [];

  // Keep only active once else will return an error
  const assignments = selectedAssignments.filter(assignment =>
    data[assignment.productId].archiveStatus ? data[assignment.productId].archiveStatus === 'ACTIVE' : true
  );

  assignments.forEach(({ groupId, assignmentId, userId }) => {
    if (userId) {
      individualAssignments.push({ groupId, assignmentId, userId });
    } else if (!groups[groupId]) {
      groups[groupId] = [assignmentId];
    } else {
      groups[groupId].push(assignmentId);
    }
  });

  const groupAssignments = Object.entries(groups).map(entry => ({
    groupId: entry[0],
    assignmentIds: entry[1]
  }));

  const body = {
    groups: [...groupAssignments, ...individualAssignments],
    notifyLearners,
    platformCode
  };

  console.log('[removeLearningMaterialRequest Saga] Submitting request to remove learning material:', body);
  return yield archiveLearningAssignmentsApi(orgId, body);
}
