import { put } from 'redux-saga/effects';
import getXApiLaunchTokenFetch from '../../../apiCalls/cptStructuredContentPlayer/getXApiLaunchToken';
import { getXApiLaunchTokenSuccess, getXApiLaunchTokenFailure } from '../../../../actions/structuredContentPlayer';

function* getXApiLaunchToken(params) {
  const response = yield getXApiLaunchTokenFetch(params);
  if (response.status === 'success') {
    yield put(getXApiLaunchTokenSuccess(response.data));
  } else {
    yield put(getXApiLaunchTokenFailure('Failed to get CPT xAPI launch token'));
  }
}

export default getXApiLaunchToken;
