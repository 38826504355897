import { put, select } from 'redux-saga/effects';
import { loadCourseSuccess, loadCourseFailure } from '../../../../actions/ngsCourse';
import getCourses from '../../../apiCalls/ngs/getCourses';

function* loadCourse(orgId, region, courseId) {
  let courses = yield select(state => state.ngsLibrary.items);

  if (!courses.length) {
    const response = yield getCourses(orgId, region, courseId);

    if (response.status === 'success') {
      courses = response.data;
    }
  }

  const course = courses.find(({ id }) => id === courseId);

  if (course) {
    yield put(loadCourseSuccess(course));
  } else {
    yield put(loadCourseFailure('Course not found'));
  }
}

export default loadCourse;
