/** Classroom reducer
 *  This reducer contains state related to the current app classroom. This
 *  includes details of the classroom's app account if the classroom is logged in.
 */
import cmsContent from '../../utils/cmsContent.js';
import { isHubMode } from '../../utils/platform.js';

export const LOAD_CLASSROOM_EDIT = 'classroomEdit/LOAD_CLASSROOM_EDIT';

// Name input actions
const VALIDATED_CLASSROOM_NAME = 'classroomEdit/VALIDATED_CLASSROOM_NAME';
const VALIDATED_CLASSROOM_CODE = 'classroomEdit/VALIDATED_CLASSROOM_CODE';
export const SET_CLASSROOM_NAME = 'classroomEdit/SET_CLASSROOM_NAME';
export const SET_IS_CLASS_CODE_ENABLE = 'classroomEdit/SET_IS_CLASS_CODE_ENABLE';
export const SET_CLASS_CODE = 'classroomEdit/SET_CLASS_CODE';

// Selection submit search actions
const SUBMIT_TEACHERS = 'classroomEdit/SUBMIT_TEACHERS';
const SUBMIT_STUDENTS = 'classroomEdit/SUBMIT_STUDENTS';

// User removal actions
const REMOVE_TEACHER = 'classroomEdit/REMOVE_TEACHER';
const REMOVE_STUDENT = 'classroomEdit/REMOVE_STUDENT';

// Student Limit actions
const SET_ENABLE_SEAT_LIMIT = 'classroomEdit/SET_ENABLE_SEAT_LIMIT';
const SET_SEAT_LIMIT = 'classroomEdit/SET_SEAT_LIMIT';

// Update email choice
const SET_NOTIFY_WITH_EMAIL = 'classroomEdit/SET_NOTIFY_WITH_EMAIL';

// Form page change actions
const GOTO_TEACHER_SELECTION = 'classroomEdit/GOTO_TEACHER_SELECTION';
const GOTO_STUDENT_SELECTION = 'classroomEdit/GOTO_STUDENT_SELECTION';
const GOTO_REVIEW_SELECTIONS = 'classroomEdit/GOTO_REVIEW_SELECTIONS';
export const GOTO_ARCHIVE = 'classroomEdit/GOTO_ARCHIVE';

// Set calculated list diffs on submit
const SET_IDS_FOR_SUBMISSION = 'classroomEdit/SET_IDS_FOR_SUBMISSION';

// Form submission and response
export const EDIT_CLASS = 'classroomEdit/EDIT_CLASS';
const SHOW_CONFIRMATION = 'classroomEdit/SHOW_CONFIRMATION';

// Reset the form
export const CLOSE_FORM = 'classroomEdit/CLOSE_FORM';

export const formStates = {
  REVIEW_SELECTIONS: 'REVIEW_SELECTIONS',
  ARCHIVE_CLASS: 'ARCHIVE_CLASS',
  CHOOSE_TEACHERS: 'CHOOSE_TEACHERS',
  CHOOSE_STUDENTS: 'CHOOSE_STUDENTS',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION' // Success|Fail feedback after SUBMITTING
};

const initialState = {
  formState: formStates.REVIEW_SELECTIONS,

  // Classroom Name and its many validation states
  classroomNameValue: null,
  classroomNameValidationMessage: '',
  classroomNameValidationPending: false,
  classroomNameValidationIsInvalid: false,
  classroomNameValidationIsWarning: false,
  classroomNameValidationIsValid: false,

  // Class Code validation states
  classroomCodeValidationPending: false,
  classroomJoiningCodeValidationIsvalid: false,
  classroomJoiningCodeValidationIsInvalid: false,
  classroomJoiningCodeValidationMessage: null,

  // List of selected teachers and students
  selectedTeacherIds: null,
  selectedStudentIds: null,

  joiningCode: null,

  // Optional student limit
  enableStudentLimit: false,
  studentLimit: 1,

  // Email notifications
  notifyWithEmailValue: false,

  // List of changed IDs calculated at the point of submission
  teachersAdded: [],
  studentsAdded: [],
  teachersRemoved: [],
  studentsRemoved: [],

  isFormEdited: false,

  // Results of the edit attempt
  requestFailed: false,
  failedIds: []
};

export default function classroomEdit(state = initialState, action = {}) {
  const CMS = cmsContent.classroomState || {};
  switch (action.type) {
    // Page load and clear actions
    case LOAD_CLASSROOM_EDIT: {
      const newFormState =
        state.formState === formStates.CONFIRMATION ? formStates.CONFIRMATION : formStates.REVIEW_SELECTIONS;
      return {
        ...state,
        formState: newFormState,
        orgId: action.orgId,
        classroomNameValue: action.classroomName,
        selectedTeacherIds: action.selectedTeacherIds,
        selectedStudentIds: action.selectedStudentIds,
        enableStudentLimit: Boolean(action.enableStudentLimit),
        notifyWithEmailValue: false,
        studentLimit: action.studentLimit || 1,
        joiningCode: action.joiningCode,

        // Resetting Validations
        classroomNameValidationMessage: '',
        classroomNameValidationPending: false,
        classroomNameValidationIsInvalid: false,
        classroomNameValidationIsWarning: false,
        classroomNameValidationIsValid: false,

        // Class Code validation states
        classroomCodeValidationPending: false,
        classroomJoiningCodeValidationIsvalid: false,
        classroomJoiningCodeValidationIsInvalid: false,
        classroomJoiningCodeValidationMessage: null
      };
    }
    case SET_CLASSROOM_NAME: {
      const actualClassroomNameLength = action.classroomName.trim().length;

      return {
        ...state,
        classroomNameValue: action.classroomName,

        classroomNameValidationPending: actualClassroomNameLength > 0,
        classroomNameValidationIsInvalid: actualClassroomNameLength === 0,
        classroomNameValidationIsWarning: false,
        classroomNameValidationIsValid: false,
        classroomNameValidationMessage: actualClassroomNameLength === 0 ? CMS.class_length_error : '',
        isFormEdited: true
      };
    }
    case SET_IS_CLASS_CODE_ENABLE: {
      const actualClassroomCodeLength = state.joiningCode && state.joiningCode.code.trim().length;
      const isEnabled = action.isEnabled;
      return {
        ...state,
        joiningCode: { ...state.joiningCode, enabled: action.isEnabled },
        isFormEdited: true,
        classroomJoiningCodeValidationIsInvalid: isEnabled && actualClassroomCodeLength < 5
      };
    }
    case SET_CLASS_CODE: {
      const actualClassroomCodeLength = action.classCode.trim().length;
      const isEnabled = state.joiningCode && state.joiningCode.enabled;
      return {
        ...state,
        joiningCode: { ...state.joiningCode, code: action.classCode },
        classroomCodeValidationPending: actualClassroomCodeLength > 4,
        classroomJoiningCodeValidationIsInvalid: isEnabled && actualClassroomCodeLength < 5,
        classroomJoiningCodeValidationMessage: actualClassroomCodeLength < 5 ? CMS.joining_code_length_error : '',
        isFormEdited: true
      };
    }
    case VALIDATED_CLASSROOM_NAME: {
      let errorMessage = '';

      // If the name is in use set a message
      if (action.exists) {
        errorMessage = CMS.class_name_duplicate;
      } else if (!isHubMode() && action.invalidClassName) {
        errorMessage = CMS.class_name_special_character_error;
      } else if (isHubMode() && action.specialCharacterError) {
        errorMessage = CMS.class_name_special_character_error;
      } else if (action.apiError) {
        errorMessage = CMS.unable_to_check_class_name;
      }

      return {
        ...state,
        classroomNameValidationPending: false,
        classroomNameValidationIsInvalid: false || action.invalidClassName,
        classroomNameValidationIsWarning: action.exists || action.apiError,
        classroomNameValidationIsValid: !action.exists && !action.apiError && !action.invalidClassName,
        classroomNameValidationMessage: errorMessage
      };
    }

    case VALIDATED_CLASSROOM_CODE: {
      let errorMessage = '';
      const allowedChars = !!action.classCode.match(/^[a-zA-Z0-9]+$/);

      // If the name is in use set a message
      if (action.exists) {
        errorMessage = CMS.class_code_duplicate;
      } else if (action.apiError) {
        errorMessage = CMS.unable_to_check_class_code;
      } else if (!allowedChars) {
        errorMessage = CMS.class_code_chars_not_allowed;
      }
      return {
        ...state,
        formState: formStates.REVIEW_SELECTIONS,
        classroomJoiningCodeValidationIsInvalid: action.exists || action.apiError || !allowedChars,
        classroomJoiningCodeValidationMessage: errorMessage,
        classroomJoiningCodeValidationIsvalid: !action.exists && !action.apiError && allowedChars,
        classroomCodeValidationPending: false
      };
    }

    // Submit selections
    case SUBMIT_TEACHERS:
      return {
        ...state,
        formState: formStates.REVIEW_SELECTIONS,
        selectedTeacherIds: action.selectedTeacherIds,
        isFormEdited: true
      };

    case SUBMIT_STUDENTS:
      return {
        ...state,
        formState: formStates.REVIEW_SELECTIONS,
        selectedStudentIds: action.selectedStudentIds,
        enableStudentLimit: action.enableStudentLimit,
        studentLimit: parseInt(action.studentLimit, 10) || null,
        isFormEdited: true
      };

    // Remove individual people in review
    case REMOVE_TEACHER:
      return {
        ...state,
        // This hack is necessary because state.selectedStudentIds may be null to start with
        selectedTeacherIds: (action.selectedTeacherIds || state.selectedTeacherIds).filter(id => id !== action.id),
        isFormEdited: true
      };

    case REMOVE_STUDENT:
      return {
        ...state,
        // This hack is necessary because state.selectedStudentIds may be null to start with
        selectedStudentIds: (action.selectedStudentIds || state.selectedStudentIds).filter(id => id !== action.id),
        isFormEdited: true
      };

    case SET_ENABLE_SEAT_LIMIT:
      return {
        ...state,
        enableStudentLimit: action.enableStudentLimit,
        isFormEdited: true,
        studentLimit:
          state.selectedStudentIds.length < state.studentLimit ? state.studentLimit : state.selectedStudentIds.length
      };
    case SET_SEAT_LIMIT:
      return {
        ...state,
        studentLimit: parseInt(action.studentLimit, 10) || 1,
        isFormEdited: true
      };

    // Update email choice
    case SET_NOTIFY_WITH_EMAIL:
      return {
        ...state,
        notifyWithEmailValue: action.notifyWithEmail
      };

    // Navigation actions
    case GOTO_REVIEW_SELECTIONS:
      return {
        ...state,
        formState: formStates.REVIEW_SELECTIONS
      };
    case GOTO_TEACHER_SELECTION:
      return {
        ...state,
        formState: formStates.CHOOSE_TEACHERS
      };
    case GOTO_STUDENT_SELECTION:
      return {
        ...state,
        formState: formStates.CHOOSE_STUDENTS
      };
    case GOTO_ARCHIVE:
      return {
        ...state,
        formState: formStates.ARCHIVE_CLASS
      };

    case SET_IDS_FOR_SUBMISSION:
      return {
        ...state,
        teachersAdded: action.teachersAdded,
        studentsAdded: action.studentsAdded,
        teachersRemoved: action.teachersRemoved,
        studentsRemoved: action.studentsRemoved
      };

    // Temporarily removed while dev-ing final step:
    case EDIT_CLASS:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };

    // After Class has been created:
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        requestFailed: action.requestFailed,
        failedIds: action.failedIds,
        editJoiningCodeData: action.editJoiningCodeData
      };
    case CLOSE_FORM:
      return {
        ...state,
        formState: formStates.LOAD_CLASSROOM_EDIT,
        isFormEdited: false
      };

    default:
      return state;
  }
}

// Init
export const loadClassroomEdit = origClassroom => ({
  type: LOAD_CLASSROOM_EDIT,
  classroomName: origClassroom.name,
  orgId: origClassroom.orgId,
  selectedTeacherIds: origClassroom.teacherIdList,
  selectedStudentIds: origClassroom.studentIdList,
  enableStudentLimit: Boolean(origClassroom.studentLimit),
  studentLimit: origClassroom.studentLimit,
  joiningCode: origClassroom.joiningCode
});

// Name Actions
export const setClassroomName = classroomName => ({
  type: SET_CLASSROOM_NAME,
  classroomName
});

export const setClassroomNameValidated = (exists, apiError, invalidClassName, specialCharacterError) => ({
  type: VALIDATED_CLASSROOM_NAME,
  exists,
  apiError,
  invalidClassName,
  specialCharacterError
});

export const setClassroomCodeValidated = (exists, apiError, classCode) => ({
  type: VALIDATED_CLASSROOM_CODE,
  exists,
  apiError,
  classCode
});

// Selection submission
export const submitTeachers = selectedTeacherIds => ({
  type: SUBMIT_TEACHERS,
  selectedTeacherIds
});

export const submitStudents = (selectedStudentIds, enableStudentLimit, studentLimit) => ({
  type: SUBMIT_STUDENTS,
  selectedStudentIds,
  enableStudentLimit,
  studentLimit
});

// Remove individual users
export const removeTeacher = (id, selectedTeacherIds) => ({
  type: REMOVE_TEACHER,
  id,
  selectedTeacherIds // This hack is necessary because state.selectedTeacherIds may be null to start with
});

export const removeStudent = (id, selectedStudentIds) => ({
  type: REMOVE_STUDENT,
  id,
  selectedStudentIds // This hack is necessary because state.selectedStudentIds may be null to start with
});

// Student Limit actions
export const setEnableStudentLimit = enableStudentLimit => ({
  type: SET_ENABLE_SEAT_LIMIT,
  enableStudentLimit
});

export const setStudentLimit = studentLimit => ({
  type: SET_SEAT_LIMIT,
  studentLimit
});

// Update email choice
export const setNotifyWithEmail = notifyWithEmail => ({
  type: SET_NOTIFY_WITH_EMAIL,
  notifyWithEmail
});

// Navigation Actions
export const gotoTeacherSelection = () => ({
  type: GOTO_TEACHER_SELECTION
});
export const gotoStudentSelection = () => ({
  type: GOTO_STUDENT_SELECTION
});
export const gotoReview = () => ({
  type: GOTO_REVIEW_SELECTIONS
});
export const gotoArchive = () => ({
  type: GOTO_ARCHIVE
});

export const setIdsForSubmission = (teachersAdded, studentsAdded, teachersRemoved, studentsRemoved) => ({
  type: SET_IDS_FOR_SUBMISSION,
  teachersAdded,
  studentsAdded,
  teachersRemoved,
  studentsRemoved
});

// Joining Code Actions
export const setIsClassCodeEnable = isEnabled => ({
  type: SET_IS_CLASS_CODE_ENABLE,
  isEnabled
});

export const setClassCode = classCode => ({
  type: SET_CLASS_CODE,
  classCode
});

// Form submission and response
export const editClass = () => ({
  type: EDIT_CLASS
});

export const showConfirmation = (requestFailed, failedIds, editJoiningCodeData) => ({
  type: SHOW_CONFIRMATION,
  requestFailed,
  failedIds,
  editJoiningCodeData
});

// Reset action
export const closeForm = () => ({
  type: CLOSE_FORM
});
