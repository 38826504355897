import { put } from 'redux-saga/effects';
import {
  getProductFinderProductsForSeriesSuccess,
  getProductFinderProductsForSeriesFailure
} from '../../../../actions/productFinderActions';

import { getProductMetadata } from '../../../../../../sharedNodeBrowser/productMetadata';

function* getProductFinderProductsInSeries(payload) {
  const response = yield getProductMetadata(fetch, payload.isbns);
  let products = Object.values(response);
  products = products.filter(product => product.isassignable === 'true');
  if (products.length) {
    yield put(getProductFinderProductsForSeriesSuccess(products));
  } else {
    yield put(getProductFinderProductsForSeriesFailure('Failed to get series'));
  }
}

export default getProductFinderProductsInSeries;
