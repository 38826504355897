import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ragStatuses from '../../globals/ragStatuses';
import { bgClass } from '../../utils/colorClassNames';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './ProgressText.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
import interpolateContent from '../../utils/interpolateContent';

const STATUS_GLYPHS = {
  [ragStatuses.VERY_BEHIND]: GLYPHS.ICON_HEXAGON,
  [ragStatuses.BEHIND]: GLYPHS.ICON_DIAMOND,
  [ragStatuses.EXPECTED]: GLYPHS.ICON_CIRCLE,
  [ragStatuses.AHEAD]: GLYPHS.ICON_STAR
};

function ProgressText({
  type,
  localizedContent: { progressText },
  status = ragStatuses.EXPECTED,
  customIconClass = null,
  variables = {}
}) {
  let l10nKey = status.replace('-', '_');
  if (type) l10nKey = `${type}_${l10nKey}`;

  const interpolatedStatusMessage = interpolateContent(progressText[l10nKey], variables || {});

  return (
    <div className={styles.attainmentFormat}>
      <div className={classnames('gin-right1', styles.icon, bgClass(status), customIconClass)}>
        <SVGIcon glyph={STATUS_GLYPHS[status]} />
      </div>
      <p className={styles.subtitleNormal}>{interpolatedStatusMessage}</p>
    </div>
  );
}

ProgressText.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  type: PropTypes.string,
  status: PropTypes.string.isRequired,
  customIconClass: PropTypes.string,
  variables: PropTypes.object
};

export default withLocalizedContent('progressText')(ProgressText);
