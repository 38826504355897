import { put } from 'redux-saga/effects';
import { loadOfflineProfiles } from '../../../../actions/offlineContentPlayer';

function* getOfflineProfiles() {
  const storedValue = localStorage.getItem('offline_profiles');
  const profiles = storedValue ? JSON.parse(storedValue) : {};

  yield put(loadOfflineProfiles(profiles));
}

export default getOfflineProfiles;
