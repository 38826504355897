import * as t from '../actionTypes';

export const getAdaptedTests = data => ({
  type: t.GET_ADAPTED_TESTS_FOR_ASSESSMENT,
  payload: data
});

export const updateAdaptedTest = (payload, adaptedTestId) => ({
  type: t.UPDATE_ADAPTED_TEST,
  payload,
  adaptedTestId
});

export const updateAdaptedTestFailure = () => ({
  type: t.UPDATE_ADAPTED_TEST_FAILURE
});

export const getAdaptedTestsSuccess = data => ({
  type: t.GET_ADAPTED_TESTS_FOR_ASSESSMENT_SUCCESS,
  payload: data
});

export const getAdaptedTestsFailure = () => ({
  type: t.GET_ADAPTED_TESTS_FOR_ASSESSMENT_FAILURE
});

export const setAdaptedTestsUpdated = () => ({
  type: t.SET_ADAPTED_TESTS_UPDATED
});

export const setAdaptedTestsPolling = data => ({
  type: t.SET_ADAPTED_TESTS_POLLING,
  payload: data
});

export const setAdaptedTestsNeedToBeUpdated = data => ({
  type: t.SET_ADAPTED_TESTS_NEED_TO_BE_UPDATED,
  payload: data
});
