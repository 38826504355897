import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { XAPI_ENDPOINT } from '../../globals/cptConstants';
import ResourceFormHidden from '../../structure/HubProductLayout/Resources/ResourceFormHidden.js';
import ResourceModal from '../ResourceModal/ResourceModal.js';
import MobileGamesErrorPopup from '../MobileGamesErrorPopup/MobileGamesErrorPopup.js';

import PopoutPanel from '../PopoutPanel/PopoutPanel';
import useMediaQuery from '../../utils/mediaQuery.js';
import breakpoints from '../../globals/breakpoints';
import { addXapiParams } from '../../globals/urls';
import {
  toggleResourceModal,
  getXApiLaunchTokenRequest,
  toggleMobileGamesErrorPopup
} from '../../redux/actions/structuredContentPlayer';
import { hubDownloadResource, hubDownloadResourceReset } from '../../redux/actions/hubResource';

function ContentPlayerResourceModal({
  xApiLaunchToken,
  isMobileGamePopupOpen,
  toggleMobileGamesError,
  toggleResourceModalAction,
  resourceModalData,
  hubDownloadResourceResetAction,
  hubDownloadResourceAction,
  getXApiLaunchTokenRequestData
}) {
  const { type, resourceIdValue, resources, cptContent, isGame } = resourceModalData;

  const [resourceId, setResourceId] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const isMobile = useMediaQuery('(max-width: 920px)');
  const lorNeededTypes = ['media', '360_presentation'];
  const isLoType = type === 'lo';

  let url = resourceModalData.url;

  useEffect(() => {
    if (!isLoaded) {
      setResourceId(resourceIdValue);

      if (lorNeededTypes.includes(type)) {
        hubDownloadResourceAction(resources, 'play');
      }
      setIsLoaded(true);
    }
  }, []);

  if (isLoType) {
    let token = xApiLaunchToken;

    if (token === null) {
      token = getXApiLaunchTokenRequestData({
        activityId: resources.itemId
      });
    }

    url = addXapiParams(resourceIdValue, {
      token,
      endpoint: `${__API_BASE__}${XAPI_ENDPOINT}`
    });
  }

  const closePlayModal = () => {
    setResourceId('');
    setIsLoaded(false);

    if (isGame && isMobile) {
      toggleMobileGamesError();
    }

    if (lorNeededTypes.includes(type)) {
      hubDownloadResourceResetAction();
    }

    toggleResourceModalAction();
  };

  return isGame && isMobile ? (
    <div>
      <PopoutPanel id="mobilePopup" ariaLabel="mobilePopup" isOpen={isMobileGamePopupOpen} panelSize="fullWidth">
        <MobileGamesErrorPopup closePlayModal={closePlayModal} cptContent={cptContent} />
      </PopoutPanel>
    </div>
  ) : (
    <div>
      <ResourceFormHidden />
      <ResourceModal
        breakpoint={breakpoints.LG}
        resourceId={resourceId}
        closeModal={closePlayModal}
        downloadResource={null}
        resource={resources}
        hubContent={cptContent}
        downloadIsLoading={false}
        url={url}
      />
    </div>
  );
}

ContentPlayerResourceModal.propTypes = {
  xApiLaunchToken: PropTypes.object,
  isMobileGamePopupOpen: PropTypes.bool,
  toggleMobileGamesError: PropTypes.func,
  toggleResourceModalAction: PropTypes.func,
  resourceModalData: PropTypes.object,
  hubDownloadResourceResetAction: PropTypes.func,
  hubDownloadResourceAction: PropTypes.func,
  getXApiLaunchTokenRequestData: PropTypes.func
};

const mapStateToProps = ({
  structuredContentPlayer: { isMobileGamePopupOpen, resourceModalData, xApiLaunchToken }
}) => ({
  isMobileGamePopupOpen,
  xApiLaunchToken,
  resourceModalData
});

const mapDispatchToProps = {
  toggleResourceModalAction: toggleResourceModal,
  hubDownloadResourceResetAction: hubDownloadResourceReset,
  hubDownloadResourceAction: hubDownloadResource,
  getXApiLaunchTokenRequestData: getXApiLaunchTokenRequest,
  toggleMobileGamesError: toggleMobileGamesErrorPopup
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentPlayerResourceModal);
