import { pick } from 'lodash';
import * as t from '../actionTypes';

const initialState = {
  serviceResults: [],
  submitting: false,
  success: false,
  errors: {}
};

function productServices(state = initialState, { type, payload }) {
  switch (type) {
    case t.RESET_GET_PRODUCT_SERVICES:
      return {
        ...initialState
      };
    case t.GET_PRODUCT_SERVICES_REQUEST:
      return {
        ...initialState,
        submitting: true
      };
    case t.GET_PRODUCT_SERVICES_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        errors: payload
      };
    case t.GET_PRODUCT_SERVICES_SUCCESS:
      return {
        ...state,
        submitting: false,
        errors: {},
        success: true,
        ...pick((payload || {}).data, ['serviceResults'])
      };
    default:
      return state;
  }
}

export default productServices;
