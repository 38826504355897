import React from 'react';
import moment from 'moment';
// Components
import InformLabel from '../components/InformLabel/InformLabel.js';

export function getInfoLabel(infoLabel, buttonText, infoLabelContainer, glyph, action) {
  return (
    <div className={infoLabelContainer}>
      <InformLabel
        message={infoLabel}
        glyphIcon={glyph}
        buttonText={buttonText}
        buttonAction={action}
        customClassName="classTestStudentView"
        showFirstButtonInformLabel
      />
    </div>
  );
}

export function everyStudentHasLicence(test, selectedStudentIds = [], endDate) {
  const studentsWithoutLicence = selectedStudentIds.filter(
    studentId =>
      test?.licenceStudentsList &&
      (!Object.keys(test.licenceStudentsList).includes(studentId) ||
        (Object.keys(test.licenceStudentsList).includes(studentId) &&
          moment(test.licenceStudentsList[studentId]?.expiresOn).diff(endDate, 'days', true) < 0))
  );

  if (studentsWithoutLicence.length) {
    return false;
  }

  return true;
}

export function onlineTestCancelTitle(assessmentTitle, testTitle, content, parametersToShow) {
  return `${assessmentTitle} ${testTitle} ${content.online_test_cancel_for} ${parametersToShow.className} (${parametersToShow.numberOfSelectedStudents} ${content.online_test_out_of} ${parametersToShow.numberOfStudents} ${content.online_test_students})`;
}
