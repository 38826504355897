import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PopoutPanelIconHeading, {
  types
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';

import content from '../../../../../../utils/cmsContent.js';

function EnrolUserBulkSubmitting({ fileName }) {
  const CMS = content.enrolUserBulkPage || {};
  return (
    <PopoutPanelIconHeading
      type={types.LOADING}
      title={CMS.processing_message_wait_heading_text || 'Please wait...'}
      subtitle={(CMS.processing_message_wait_subHeading_text || 'Uploading...').replace('{fileName}', fileName)}
    />
  );
}

EnrolUserBulkSubmitting.propTypes = {
  fileName: PropTypes.string.isRequired
};

export default connect(state => ({
  fileName: state.enrolUser.file.name
}))(EnrolUserBulkSubmitting);
