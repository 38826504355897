export const CHECK_SELECTED_USERS = 'archiveUsers/CHECK_SELECTED_USERS';
export const ARCHIVE_USERS = 'archiveUsers/ARCHIVE_USERS';
const SHOW_SELECTION_REVIEW = 'archiveUsers/SHOW_SELECTION_REVIEW';
export const SET_STUDENT_LIST = 'archiveUsers/SET_STUDENT_LIST';
export const SET_STAFF_LIST = 'archiveUsers/SET_STAFF_LIST';
const SET_PREFLIGHT_CHECK_RESULTS = 'archiveUsers/SET_PREFLIGHT_CHECK_RESULTS';
const SET_RESULT = 'archiveUsers/SET_RESULT';
const SET_FOUND_LAST_OUP_ADMIN = 'archiveUsers/SET_FOUND_LAST_OUP_ADMIN';
const SET_FOUND_LAST_ORG_ADMIN = 'archiveUsers/SET_FOUND_LAST_ORG_ADMIN';
const SET_FOUND_LAST_TEACHER = 'archiveUsers/SET_FOUND_LAST_TEACHER';
const SET_NOTIFY_WITH_EMAIL = 'archiveUsers/SET_NOTIFY_WITH_EMAIL';
const SET_REQUEST_FAILED = 'archiveUsers/SET_REQUEST_FAILED';
const SHOW_REMOVAL_PENDING = 'archiveUsers/SHOW_REMOVAL_PENDING';
const SHOW_MODAL = 'archiveUsers/SHOW_MODAL';
const CLOSE_FORM = 'archiveUsers/CLOSE_FORM';

export const formStates = {
  CHECKING_SELECTED_USERS: 'CHECKING_SELECTED_USERS',
  REVIEW_SELECTIONS: 'REVIEW_SELECTIONS',
  SHOW_TASK_PENDING: 'SHOW_TASK_PENDING'
};

const initialState = {
  formState: formStates.CHECKING_SELECTED_USERS,
  notifyWithEmail: false,
  userIds: [],
  preFlightCheckResults: {},
  checkOupAdmin: false,
  foundLastOupAdmin: false,
  foundLastOrgAdmin: false,
  foundLastTeacher: false,
  result: [],
  showRemovalPending: false,
  requestFailed: false,
  showModal: false
};

export default function archiveUsers(state = initialState, action) {
  switch (action.type) {
    case SET_STUDENT_LIST:
    case SET_STAFF_LIST: {
      return {
        ...state,
        userIds: action.userIds,
        formState: formStates.CHECKING_SELECTED_USERS,
        checkOupAdmin: action.checkOupAdmin
      };
    }
    case SET_NOTIFY_WITH_EMAIL:
      return {
        ...state,
        notifyWithEmail: action.notifyWithEmail
      };
    case SET_PREFLIGHT_CHECK_RESULTS:
      return {
        ...state,
        preFlightCheckResults: action.preFlightCheckResults
      };
    case SET_RESULT:
      return {
        ...state,
        result: action.result
      };
    case SET_FOUND_LAST_OUP_ADMIN:
      return {
        ...state,
        foundLastOupAdmin: action.foundLastOupAdmin
      };
    case SET_FOUND_LAST_ORG_ADMIN:
      return {
        ...state,
        foundLastOrgAdmin: action.foundLastOrgAdmin
      };
    case SET_FOUND_LAST_TEACHER:
      return {
        ...state,
        foundLastTeacher: action.foundLastTeacher
      };
    case SET_REQUEST_FAILED:
      return {
        ...state,
        requestFailed: action.requestFailed,
        formState: formStates.SHOW_TASK_PENDING
      };
    case SHOW_SELECTION_REVIEW:
      return {
        ...state,
        formState: formStates.REVIEW_SELECTIONS
      };
    case SHOW_REMOVAL_PENDING:
      return {
        ...state,
        showRemovalPending: action.showRemovalPending
      };

    case SHOW_MODAL:
      return {
        ...state,
        showModal: action.showModal
      };
    case CLOSE_FORM:
      return {
        ...initialState,
        formState: formStates.REVIEW_SELECTIONS
      };
    default:
      return state;
  }
}

export const setNotifyWithEmail = notifyWithEmail => ({
  type: SET_NOTIFY_WITH_EMAIL,
  notifyWithEmail
});

// Form submission and response
export const doArchiveUsers = () => ({
  type: ARCHIVE_USERS
});

export const setStudentsToArchive = userIds => ({
  type: SET_STUDENT_LIST,
  userIds: Array.isArray(userIds) ? userIds : [userIds],
  checkOupAdmin: false
});

export const setStaffToArchive = (userIds, checkOupAdmin = false) => ({
  type: SET_STAFF_LIST,
  userIds: Array.isArray(userIds) ? userIds : [userIds],
  checkOupAdmin
});

export const checkSelectedUsers = () => ({
  type: CHECK_SELECTED_USERS
});

export const showSelectionReview = () => ({
  type: SHOW_SELECTION_REVIEW
});

export const setPreFlightCheckResults = data => ({
  type: SET_PREFLIGHT_CHECK_RESULTS,
  preFlightCheckResults: data
});

export const setResult = result => ({
  type: SET_RESULT,
  result
});

export const setFoundLastOupAdmin = foundLastOupAdmin => ({
  type: SET_FOUND_LAST_OUP_ADMIN,
  foundLastOupAdmin
});

export const setFoundLastOrgAdmin = foundLastOrgAdmin => ({
  type: SET_FOUND_LAST_ORG_ADMIN,
  foundLastOrgAdmin
});

export const setFoundLastTeacher = foundLastTeacher => ({
  type: SET_FOUND_LAST_TEACHER,
  foundLastTeacher
});

export const setRequestFailed = requestFailed => ({
  type: SET_REQUEST_FAILED,
  requestFailed
});

export const showClosePanelModal = showModal => ({
  type: SHOW_MODAL,
  showModal
});

export const showRemovalPending = showPending => ({
  type: SHOW_REMOVAL_PENDING,
  showRemovalPending: showPending
});

// Reset action
export const closeForm = () => ({
  type: CLOSE_FORM
});
