import { takeLatest, takeEvery } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getProducts from './getProducts';
import getOrgProducts from './getOrgProducts';
import getGBProducts from './getGBProducts';
import getProductServices from './getProductServices';
import oicGetTestCredits from './oicGetTestCredits';
import oicCreateTestCredits from './oicCreateTestCredits';
import oicUpdateTestCredits from './oicUpdateTestCredits';
import oicValidateTestCreditsOrg from './oicValidateTestCreditsOrg';
import getTestCredits from './getTestCredits';

function* root() {
  yield takeLatest(t.GET_PRODUCTS_REQUEST, ({ payload: platform }) => getProducts(platform));
  yield takeLatest(t.GET_ORG_PRODUCTS_REQUEST, ({ payload: orgId }) => getOrgProducts(orgId));
  yield takeLatest(t.GET_GB_PRODUCTS_REQUEST, ({ payload: products }) => getGBProducts(products));
  yield takeLatest(t.GET_PRODUCT_SERVICES_REQUEST, ({ payload: parameters }) => getProductServices(parameters));
  yield takeLatest(t.OIC_TEST_CREDITS_ORG_VALIDATE, ({ payload: input }) => oicValidateTestCreditsOrg(input));
  yield takeEvery(t.GET_OIC_TEST_CREDITS_REQUEST, ({ payload: input }) => oicGetTestCredits(input));
  yield takeEvery(t.GET_TEST_CREDITS_REQUEST, ({ payload: input }) => getTestCredits(input));
  yield takeLatest(t.UPDATE_OIC_TEST_CREDITS_REQUEST, ({ payload: input }) => oicUpdateTestCredits(input));
  yield takeLatest(t.CREATE_OIC_TEST_CREDITS_REQUEST, ({ payload: input }) => oicCreateTestCredits(input));
}

export default root;
