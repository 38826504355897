import { put } from 'redux-saga/effects';

import { API_VERSIONS, JSON_ENCODED } from '@oup/shared-node-browser/constants';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

function* getOrgDetails(id) {
  const response = yield signedFetch('getOrgDetails', `${__API_BASE__}/org/${id}`, 'GET', undefined, {
    Accept: API_VERSIONS.API_VERSION_0_2,
    'Content-Type': JSON_ENCODED
  });
  if (response.status === 'success') {
    yield put(actions.getOrgSuccess(response.data));
    return;
  }
  yield put(actions.getOrgFailure(response.errorMessages));
}

export default getOrgDetails;
