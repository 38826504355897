import PropTypes from 'prop-types';
import React from 'react';

import CorrelationChart from '../CorrelationChart/CorrelationChart';
import styles from './CorrelationChartTab.scss';

function CorrelationChartTab({ locationId }) {
  switch (locationId) {
    case 'au':
      return <CorrelationChart localLevelName="australian" />;
    case 'cn':
    case 'ae':
      return <CorrelationChart localLevelName="dragonfly" />;
    default:
      return (
        <div>
          <div className={styles.localLevelName}>Infant</div>
          <CorrelationChart localLevelName="ks1" />
          <div className={styles.localLevelName}>Junior</div>
          <CorrelationChart localLevelName="ks2" />
        </div>
      );
  }
}

CorrelationChartTab.propTypes = {
  locationId: PropTypes.string
};

export default CorrelationChartTab;
