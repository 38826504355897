import * as t from '../actionTypes';

export const teacherResourcesRequest = titleIds => ({
  type: t.TEACHER_RESOURCES_REQUEST,
  payload: titleIds
});

export const teacherResourcesSuccess = resourcesData => ({
  type: t.TEACHER_RESOURCES_SUCCESS,
  payload: resourcesData
});

export const teacherResourcesFailure = error => ({
  type: t.TEACHER_RESOURCES_FAILURE,
  payload: error
});

export const teacherResourceRequest = (titleId, resourceId) => ({
  type: t.TEACHER_RESOURCE_REQUEST,
  payload: { titleId, resourceId }
});

export const teacherResourceSuccess = consumer => ({
  type: t.TEACHER_RESOURCE_SUCCESS,
  payload: consumer
});

export const teacherResourceFailure = error => ({
  type: t.TEACHER_RESOURCE_FAILURE,
  payload: error
});

export const teacherOpenContent = (contentId, openQuizPanel, downloadable, studentId) => ({
  type: t.TEACHER_OPEN_CONTENT,
  payload: { id: contentId, openQuizPanel, downloadable, studentId }
});

export const teacherCloseContent = () => ({
  type: t.TEACHER_CLOSE_CONTENT,
  payload: null
});
