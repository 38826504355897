import { put, select } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch.js';

import getPlacementTest from '../../../apiCalls/getPlacementTest.js';
import {
  loadJoiningCodeDetailsSuccess,
  loadJoiningCodeDetailsFailure,
  getSessionTestCreditValue
} from '../../../../actions/placementTests.js';

function* loadJoiningCodeDetailsAndCredits() {
  const { classId, orgId, productId } = yield select(state => ({
    classId: state.placementTestSessionCreate.placementTestSessionId,
    orgId: state.identity.currentOrganisationId,
    productId: state.placementTestSessionCreate.placementTest.productId
  }));

  const response = yield getPlacementTest(orgId, classId);
  const creditsResponse = yield signedFetch(
    'oicGetTestCredits',
    `${__API_BASE__}/org/${orgId}/oic/test-credits/${productId}`
  );

  if (creditsResponse.status === 'success') {
    if (typeof creditsResponse.data?.credits === 'number') {
      yield put(getSessionTestCreditValue(creditsResponse.data.credits));
    }
  }

  if (response.status === 'success') {
    yield put(loadJoiningCodeDetailsSuccess(response.data.joiningCode));
  } else {
    yield put(loadJoiningCodeDetailsFailure(response.message));
  }
}

export default loadJoiningCodeDetailsAndCredits;
