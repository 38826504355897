import React from 'react';
import PropTypes from 'prop-types';
import Button, { buttonTypes } from '../Button/Button';
import styles from './ConfirmationBox.scss';

function ConfirmationBox({ fileName, updateConfirmationFunc }) {
  return (
    <div className={styles.confirmationWindow}>
      <div className={styles.confirmationContainer}>
        <h3>Do you want to update {fileName}?</h3>
        <div className={styles.confirmationButtonsContainer}>
          <Button
            id="updateConfirmation_no"
            type={buttonTypes.SECONDARY}
            text="No"
            onClick={() => updateConfirmationFunc(false)}
          />
          <Button
            id="updateConfirmation_yes"
            type={buttonTypes.PRIMARY}
            text="Yes"
            onClick={() => updateConfirmationFunc(true)}
          />
        </div>
      </div>
    </div>
  );
}

ConfirmationBox.propTypes = {
  fileName: PropTypes.string,
  updateConfirmationFunc: PropTypes.func
};

export default ConfirmationBox;
