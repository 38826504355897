import { put } from 'redux-saga/effects';
import { userPreferencesSuccess, userPreferencesFailure } from '../../../../actions/orbUserPreferences';
import getUserPreferencesApiCall from '../../../apiCalls/orb/getUserPreferences';

function* getUserPreferences(orgId, userId) {
  const response = yield getUserPreferencesApiCall(orgId, userId);

  if (response.status === 'success') {
    yield put(userPreferencesSuccess(response.data));
    return;
  }

  yield put(userPreferencesFailure(response.message));
}

export default getUserPreferences;
