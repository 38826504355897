import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getActivationCodeDetailsApi from '../../../apiCalls/getActivationCodeDetails';

function* getActivationCodeDetails(action) {
  const apiResults = yield getActivationCodeDetailsApi(action.data.code);
  if (apiResults.error) {
    yield put(actions.setActivationCodeDetailsErrorForDashboard(apiResults.error));
  } else {
    yield put(actions.setActivationCodeDetailsForDashboard(apiResults.data));
  }
}

export default getActivationCodeDetails;
