import classnames from 'classnames';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import prefixKeys from '../../utils/object/prefixKeys';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

import { colTypes, rowTypes } from '../../globals/gradebookTableConstants';

import styles from './SortHeader.scss';

/**
 * Drills down to get the highest/first instance of plain
 * text inside a react component for sorting.
 *
 * @param {Object} component A react component
 * @return {String} A sortable entity
 */
const getComponentContent = component => {
  if (component.props.text) return component.props.text.toLowerCase();

  return component.props.children && Array.isArray(component.props.children)
    ? getComponentContent(component.props.children[0]) // Go deeper
    : component.props.children.toLowerCase();
};

/**
 * Gets the raw cell contents.
 *
 * @param {Object} row The current row object
 * @param {String} accessor The dot-separated string accessor for the object
 * @return {String} The cell contents as a string
 */
const getCellContents = (row, accessor) => {
  const contents = accessor.includes('.')
    ? accessor.split('.').reduce((a, b) => (a && a[b]) || false, row)
    : row[accessor];

  if (contents.props) return getComponentContent(contents);

  return contents.toString().toLowerCase();
};

/**
 * Sorts a table's data based on the column index
 * and and sorting direction. Takes a the `rows` argument
 * as a specific format accepted by most tables in the
 * project including: `Table`, and `ReadersTable`.
 *
 * @param {Object} rows The original dataset
 * @param {Number} accessor The accessor for the table `rows` object
 * @param {Boolean} descending Weather the column is to be sorted asc/desc
 * @return {Object} The sorted data
 */
export const tableSort = (rows, sortConfig) => {
  if (!sortConfig) return rows;

  const { accessor, descending } = sortConfig;

  const sortedRows = rows.sort((a, b) => {
    const aContents = getCellContents(a, accessor);
    const bContents = getCellContents(b, accessor);

    // Keep average row at top if sorting names
    if (accessor === colTypes.NAME.key) {
      if (a.meta === rowTypes.AVERAGE.key) return -1;
      if (b.meta === rowTypes.AVERAGE.key) return 1;
    }

    if (accessor === 'cells.1') {
      // Move empties to top or to the bottom
      if (aContents === 'Never') return descending ? 1 : -1;
      if (bContents === 'Never') return descending ? 1 : -1;
      // Sort date for lastAccessed
      return descending
        ? new Date(b.lastAccessed) - new Date(a.lastAccessed)
        : new Date(a.lastAccessed) - new Date(b.lastAccessed);
    }

    // Move empties to the bottom
    if (aContents === '') return 1;
    if (bContents === '') return -1;

    // Sort with numbers if present
    if (!Number.isNaN(parseInt(aContents, 10))) {
      return descending
        ? numeral(bContents).value() - numeral(aContents).value()
        : numeral(aContents).value() - numeral(bContents).value();
    }

    // Else we just sort on the content strings
    if (aContents > bContents) return descending ? 1 : -1;
    if (aContents < bContents) return descending ? -1 : 1;

    return 0;
  });

  return sortedRows;
};

export default class SortHeader extends Component {
  /**
   * Click handler for sort switch.
   */
  handleClick = () => {
    const { onSwitch, accessor, descending } = this.props;
    return onSwitch(accessor, !descending);
  };

  render() {
    const { accessor, descending } = this.props;
    const { text, group, disabled, id = `${group}-${accessor}`, aria } = this.props;

    // If aria attributes were supplied, ensure they all have a prefix of "aria-":
    const ariaAttrs = prefixKeys(aria, 'aria-');
    const sorted = descending !== null;
    const sortGlyph = descending ? GLYPHS.CHEVRON_DOWN_THICK : GLYPHS.CHEVRON_UP_THICK;

    return (
      <label
        htmlFor={id}
        className={classnames(styles.sortHeader, sorted && styles['sortHeader--sorted'])}
        {...ariaAttrs}
        disabled={disabled}
      >
        <input
          id={id}
          type="radio"
          name={group}
          onClick={this.handleClick}
          defaultChecked={descending !== null}
          className={styles.sortHeader__input}
        />
        <div>
          <span>{text}</span>
          <SVGIcon className={styles.sortHeader__icon} glyph={sorted ? sortGlyph : GLYPHS.ICON_DROPLIST} />
        </div>
      </label>
    );
  }
}

SortHeader.propTypes = {
  /** The text within the header */
  text: PropTypes.string,
  /** The grouping name */
  group: PropTypes.string,
  /** The onSwitch function of the button */
  onSwitch: PropTypes.func,
  /** This toggles if the button is disabled */
  disabled: PropTypes.bool,
  /** String key for accessing columns */
  accessor: PropTypes.string,
  /** This is how the sorting state is passed down */
  descending: PropTypes.bool,
  /** The html ID of the element. */
  id: PropTypes.string,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object
};
