import querySelectorAllFocusable from './querySelectorAllFocusable.js';

// Helper to return the element that can receive focus prior to the current one:
// Typically used while Shift-Tabbing between fields.
// Note when we call querySelectorAllFocusable we tell it to include the activeElement
// in the results so that we can find its position in the resulting array.
// This is necessary when the activeElement is no longer deemed focusable for some reason.
export default function querySelectorPrevFocusable(container = document) {
  const elements = querySelectorAllFocusable(container, document.activeElement);
  const index = elements.indexOf(document.activeElement);
  const lastIndex = elements.length - 1;

  // Cycle around to the last element if focus is currently on the first one:
  return index === -1 ? null : (index === 0 && elements[lastIndex]) || elements[index - 1];
}
