import { put } from 'redux-saga/effects';
import { setIsEmailTaken } from '../../../../actions/editCurrentUserDetails.js';
import checkGlobalUserEmailApi from '../../../apiCalls/users/checkGlobalUserEmail.api.js';

function* checkIsEmailTaken(email) {
  const response = yield checkGlobalUserEmailApi(email.trim());
  const emailExists = response.status === 'success' && response.data.exists === true;

  yield put(setIsEmailTaken({ email, emailExists }));
}

export default checkIsEmailTaken;
