import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import ContentPreview from './ContentPreview';

// This page is WIP, logic & glossaries will be added later.
function LorContentPage({ localizedContent: { contentPreview: content } }) {
  return (
    <PageWrapper>
      <Helmet title={content.page_title} />
      <ContentPreview />
    </PageWrapper>
  );
}

LorContentPage.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('contentPreview'))(LorContentPage);
