// Prevents the page from growing beyond the viewport when it's contained in an iframe in Mobile
import browser from 'browser-detect';

const userBrowser = browser();

if (userBrowser.mobile || userBrowser.os.includes('Android')) {
  /* Some Android tablets are not identified as mobile devices, so we have to filter the OS as well */
  document.documentElement.style.setProperty('--vh100', `${window.innerHeight}px`);
  document.documentElement.classList.add('mobile');
}
