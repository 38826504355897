import * as t from '../actionTypes';

const initialState = {
  log: [],
  totalResults: 0,
  isLoading: false,
  hasFailed: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.GET_ACTIVITY_REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case t.GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        totalResults: action.payload.totalResults,
        log: Object.entries(action.payload.activities).reduce(
          (carry, [type, log]) => [
            ...carry,
            ...log.map(entry => ({
              ...entry,
              type
            }))
          ],
          []
        ),
        isLoading: false
      };
    case t.GET_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasFailed: true
      };
    default:
      return state;
  }
};
