import * as t from '../../actionTypes.js';

const initialState = {
  loading: false,
  error: null,
  products: [],
  filteredProducts: [],
  searchedValue: ''
};

const oxfordEnglishHubProduct = {
  cover: `/static/images/ngi/oxford-course-logo.png`,
  platform: 'hub',
  subdomain: '',
  isbn: '-',
  bid: '',
  title: 'Oxford English Hub',
  author: '',
  level: '',
  usertypetxt: '',
  productid: '-'
};

export default function getOpenProducts(state = initialState, action) {
  switch (action.type) {
    case t.OPEN_PRODUCTS_FETCH_REQUEST:
      return { ...state, loading: true, error: null, products: [], filteredProducts: [] };
    case t.OPEN_PRODUCTS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: [oxfordEnglishHubProduct, ...action.payload],
        filteredProducts: [oxfordEnglishHubProduct, ...action.payload]
      };
    case t.OPEN_PRODUCTS_FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload, products: [], filteredProducts: [] };
    case t.OPEN_PRODUCTS_FILTER:
      return {
        ...state,
        loading: false,
        filteredProducts: action.payload
          ? state.products.filter(
              product =>
                product?.title?.toLowerCase().includes(action.payload.toLowerCase()) ||
                product?.isbn?.toLowerCase().includes(action.payload.toLowerCase()) ||
                product?.author?.toLowerCase().includes(action.payload.toLowerCase()) ||
                product?.cefr_level?.toLowerCase().includes(action.payload.toLowerCase()) ||
                product?.platform?.toLowerCase().includes(action.payload.toLowerCase())
            )
          : state.products
      };
    case t.OPEN_PRODUCTS_SEARCHED_VALUE:
      return {
        ...state,
        searchedValue: action.payload
      };
    default:
      return state;
  }
}
