import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../css/AddLicenseContentPage.scss';

import LookupProducts from '../../../../../components/LookupProducts/LookupProducts.js';
import withLocalizedContent from '../../../../../language/withLocalizedContent';

function ProductInput({ selectedProduct, action, localizedContent: { activationCodeBatch: content } }) {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const setOverlay = param => {
    setIsOverlayOpen(param);
  };

  return (
    <div className={styles.productDetailsContainer}>
      <div className={styles.productRequired}>
        <span>{content.product_span}</span> <span className={styles.redStar}>*</span>
      </div>
      <LookupProducts
        returnProductOrId
        productSelected={product => {
          action(product);
        }}
        productSelectedValue={
          selectedProduct.productName || selectedProduct.productGroupName || selectedProduct.productInput
        }
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setOverlay}
      />
    </div>
  );
}

ProductInput.propTypes = {
  action: PropTypes.func,
  localizedContent: PropTypes.object,
  selectedProduct: PropTypes.string
};
export default withLocalizedContent('activationCodeBatch')(ProductInput);
