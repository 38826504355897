export const SET_IDENTITY_DETAILS = 'sharedAction/SET_IDENTITY_DETAILS';
export const CLEAR_MISSING_FIELDS = 'identity/CLEAR_MISSING_FIELDS';

export const setIdentityDetails = (organisations, missingFields, isSocial, registrationStatus = null) => ({
  type: SET_IDENTITY_DETAILS,
  organisations,
  missingFields,
  isSocial,
  registrationStatus
});

export const clearMissingFields = (userId, email) => ({
  type: CLEAR_MISSING_FIELDS,
  userId,
  email
});
