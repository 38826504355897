import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from '../css/ProductCreationPage.scss';
import withLocalizedContent from '../../../../../language/withLocalizedContent';
import { externalIdsRequest } from '../../../../../redux/actions/createProduct';
import Table, { columnTypes } from '../../../../../components/Table/Table';
import Button, { buttonTypes } from '../../../../../components/Button/Button';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import TextInput from '../../../../../components/TextInput/TextInput';
import AlertModal from './AlertModal.js';

function ExternalIdsTab({
  productDetails,
  setProductDetails,
  getExternalIds,
  externalIds,
  localizedContent: { productCreate: content }
}) {
  const [systemId, setSystemId] = useState(null);
  const [systemTypeId, setSystemTypeId] = useState(null);
  const [externalId, setExternalId] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [message, setMessage] = useState('');
  const setDetails = setProductDetails();

  useEffect(() => {
    getExternalIds();
  }, []);

  const getSystemTypeIds = sysId => {
    const matchedSystemId = externalIds.find(extId => Number(extId.id) === sysId);

    if (matchedSystemId && matchedSystemId.types) {
      return matchedSystemId.types;
    }

    return [];
  };

  const getSystemById = sysId => {
    const system = externalIds.find(extId => Number(extId.id) === sysId);

    if (system) {
      return system;
    }

    return null;
  };

  const getTypeById = (sysId, typeId) => {
    const system = externalIds.find(extId => Number(extId.id) === sysId);

    if (system) {
      const type = system.types.find(t => Number(t.id) === typeId);

      return type;
    }

    return null;
  };

  const resetAllFields = () => {
    setExternalId('');
    setSystemId('-1');
    setSystemTypeId(null);
  };

  const findDuplicate = (id, typeId) => {
    const duplicate = productDetails.externalIds.find(extId => Number(extId.typeId) === Number(typeId));

    return duplicate;
  };

  const addExternalId = () => {
    if (findDuplicate(externalId, systemTypeId)) {
      setMessage(content.item_already_added);
      setIsAlertOpen(true);
      return;
    }

    const newExternalId = {
      id: externalId,
      systemId,
      typeId: systemTypeId
    };

    setDetails.setExternalIdsFunction([...productDetails.externalIds, newExternalId]);
    resetAllFields();
  };

  const removeExternalId = index => {
    const newExtIds = [...productDetails.externalIds];
    newExtIds.splice(index, 1);

    setDetails.setExternalIdsFunction(newExtIds);
  };

  const getSystemIdOptions = () => [
    { value: '-1', text: '' },
    ...externalIds.map(option => ({ value: option.id, text: option.name }))
  ];

  const getSystemTypeIdOptions = sysId => [
    { value: null, text: '' },
    ...getSystemTypeIds(sysId).map(option => ({ value: option.id, text: option.name }))
  ];

  const renderTableRows = () => {
    if (productDetails.externalIds.length === 0) {
      return [];
    }

    return productDetails.externalIds.map((extId, index) => ({
      id: extId.id,
      cells: [
        extId.id,
        getSystemById(extId.systemId)?.name,
        getTypeById(extId.systemId, extId.typeId)?.name,
        <Button key={index} type={buttonTypes.NO_BORDER} onClick={() => removeExternalId(index)} text="Delete" />
      ]
    }));
  };

  const renderAlertModal = () => {
    if (isAlertOpen) {
      return <AlertModal message={message} isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} />;
    }
    return <> </>;
  };

  return (
    <div className="container">
      <article className={styles.licenseDetails}>
        <span className={styles.productSummary}>{content.external_ids_cta}</span>
        <div className={styles.formContainer}>
          <TextInput label={content.id_th} value={externalId} onChange={value => setExternalId(value)} />
          <Dropdown
            label={content.system_id_th}
            value={systemId ? systemId.toString() : null}
            options={getSystemIdOptions()}
            onChange={value => setSystemId(Number(value))}
          />
          <Dropdown
            label={content.system_type_th}
            value={systemTypeId ? systemTypeId.toString() : null}
            disabled={!systemId}
            options={systemId ? getSystemTypeIdOptions(systemId) : []}
            onChange={value => setSystemTypeId(Number(value))}
          />
          <Button
            text={content.add}
            disabled={!(externalId && systemId !== '-1' && systemTypeId)}
            onClick={addExternalId}
          />
        </div>
      </article>
      {productDetails.externalIds.length > 0 && (
        <article className={styles.licenseDetails}>
          <span className={styles.productSummary}>{content.external_ids}</span>
          <Table
            columns={[
              { heading: content.id_th, type: columnTypes.TEXT },
              { heading: content.system_id_th, type: columnTypes.TEXT },
              { heading: content.system_type_th, type: columnTypes.TEXT },
              { heading: content.actions_th, type: columnTypes.BUTTON }
            ]}
            rows={renderTableRows()}
          />
        </article>
      )}
      {renderAlertModal(message)}
    </div>
  );
}

ExternalIdsTab.propTypes = {
  productDetails: PropTypes.object,
  setProductDetails: PropTypes.func,
  getExternalIds: PropTypes.func,
  localizedContent: PropTypes.object,
  externalIds: PropTypes.array
};

const mapDispatchToProps = dispatch => ({
  getExternalIds: () => {
    dispatch(externalIdsRequest());
  }
});

export default connect(
  ({ getExternalIds: { isLoading, error, success } }) => ({
    isLoading,
    error,
    externalIds: success
  }),
  mapDispatchToProps
)(withLocalizedContent('productCreate')(ExternalIdsTab));
