import { put } from 'redux-saga/effects';

import reCAPTCHAExample from '../../../api/reCAPTCHAExample.js';

import actions from '../../../../actions';

function* submit(data) {
  const { response, status, message } = yield reCAPTCHAExample(data);
  const errMessage = message || response.error;
  switch (true) {
    case status !== 'success':
      yield put(actions.reCAPTCHAFailure(errMessage));
      break;
    case response.isBot:
      yield put(actions.reCAPTCHABot());
      break;
    default:
      yield put(actions.reCAPTCHASuccess());
      break;
  }
}

export default submit;
