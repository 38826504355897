import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { validColors } from '../../globals/colors';
import { bgClass, darkBdClass, darkBgHoverClass } from '../../utils/colorClassNames';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Badge.scss';

/** renders a simple button. When onClick prop is provided, a 'pill' button in rendered */
function Badge({ backgroundColor, value, buttonId, onClick, noCustomStyles }) {
  const Tag = onClick ? 'label' : 'span';
  return (
    <Tag
      className={classnames(styles.badge, styles.button, bgClass(backgroundColor), {
        [styles.noCustomStyles]: noCustomStyles
      })}
      htmlFor={onClick && buttonId}
    >
      <span className={classnames(styles.value, { [styles.hasOnClick]: onClick }, darkBdClass(backgroundColor))}>
        {value}
      </span>
      {onClick ? (
        <button
          id={buttonId}
          type="button"
          onClick={onClick}
          className={classnames(styles.button, bgClass(backgroundColor), darkBgHoverClass(backgroundColor))}
        >
          <SVGIcon glyph={GLYPHS.ICON_RIGHT} />
        </button>
      ) : (
        ''
      )}
    </Tag>
  );
}

Badge.propTypes = {
  /** The background color to be used if using the text or icon variants of this component. */
  backgroundColor: PropTypes.oneOf(validColors).isRequired,
  /** The value of the badge. */
  value: PropTypes.number.isRequired,
  /** The id of the badge button */
  buttonId: PropTypes.string,
  /** The callback handler for a click, optional */
  onClick: PropTypes.func,
  noCustomStyles: PropTypes.bool
};

export default Badge;
