import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../Button/Button';
import Card from '../Card/Card';
import ControlledForm from '../ControlledForm/ControlledForm';
import DateField from '../DateField/DateField';
import PageHeading from '../PageHeading/PageHeading';
import TextInput from '../TextInput/TextInput';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import styles from './UpdateOrgSubscriptionForm.scss';

function UpdateOrgSubscriptionForm({
  localizedContent: { updateOrgSubscriptionForm: localizedContent },
  orgId = '',
  orgName = '',
  subscriptionId = '',
  currentStartDate = '',
  currentEndDate = '',
  endDate = '',
  errors = {},
  createChangeHandler = () => {},
  createBlurHandler = () => {},
  onSubmit = () => {}
}) {
  errors.startDate = undefined; // remove this validation error if present.

  const errorValues = Object.values(errors);
  const isSubmitDisabled =
    !errorValues.length || errorValues.some(Boolean) || ![orgId, subscriptionId, endDate].every(Boolean);

  let errorMessage = '';
  if (errors.orgId) {
    errorMessage = localizedContent.org_id_error_message;
  }
  if (errors.orgNotFound) {
    errorMessage = localizedContent.org_not_found_error_message;
  }
  if (errors.isBlocked) {
    errorMessage = localizedContent.org_is_blocked;
  }

  const formatDateToUTCString = dateString => {
    if (!dateString) return '';

    const date = new Date(dateString);

    if (date && !Number.isNaN(date.getTime())) {
      return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())).toLocaleDateString(
        'en-GB'
      );
    }

    return 'n/a';
  };

  return (
    <ControlledForm>
      <div className="grid pad-top4 pad-bot4">
        <div className="row gin-bot3">
          <div className="col sm10 pad-bot2 gin-bot4">
            <PageHeading title={localizedContent.page_title} subtitle={localizedContent.page_subtitle} />
          </div>
          <div className="col md8">
            <div className="gin-bot3">
              <Card headingText={localizedContent.org_card_title} headingColor={colors.ORGANIZATION}>
                <ValidationStatus
                  forId="orgId"
                  isActive={errors.orgId || errors.orgNotFound || errors.isBlocked}
                  message={errorMessage}
                >
                  <TextInputWithButton
                    id="orgId"
                    name="orgId"
                    label={localizedContent.org_id_field_label}
                    value={orgId}
                    placeholder=""
                    onChange={createChangeHandler('orgId')}
                    onBlur={createBlurHandler('orgId')}
                    buttonIcon={GLYPHS.ICON_TICK}
                    buttonType="button"
                    buttonAction={createBlurHandler('orgId')}
                  />
                </ValidationStatus>
                {orgName && (
                  <TextInput
                    id="orgName"
                    name="orgName"
                    label={localizedContent.org_name_field_label}
                    value={orgName}
                    readOnly
                  />
                )}
              </Card>
            </div>
            <Card headingText={localizedContent.licence_card_title} headingColor={colors.PROFILE}>
              <ValidationStatus
                forId="subscriptionId"
                // Hide the validation when subscriptionId is false and  we aren't sure whether the licence exist or not (subscriptionNotFound is undefined)
                isActive={errors.subscriptionId || errors.subscriptionNotFound}
                message={
                  errors.subscriptionId
                    ? localizedContent.licence_id_error_text
                    : localizedContent.licence_not_found_error_message
                }
              >
                <TextInputWithButton
                  id="subscriptionId"
                  name="subscriptionId"
                  label={localizedContent.licence_id}
                  value={subscriptionId}
                  placeholder=""
                  onChange={createChangeHandler('subscriptionId')}
                  onBlur={createBlurHandler('subscriptionId')}
                  buttonType="button"
                  buttonIcon={GLYPHS.ICON_TICK}
                  buttonAction={createBlurHandler('subscriptionId')}
                />
              </ValidationStatus>

              {currentStartDate && (
                <div className="row gin-bot3">
                  <div className="col xs6 ">
                    <TextInput
                      id="currentStartDate"
                      name="currentStartDate"
                      label={localizedContent.licence_current_start_date}
                      value={new Date(currentStartDate).toLocaleDateString('en-GB')}
                      readOnly
                    />
                  </div>
                  <div className="col xs6">
                    <TextInput
                      id="currentEndDate"
                      name="currentEndDate"
                      label={localizedContent.licence_current_end_date}
                      value={formatDateToUTCString(currentEndDate)}
                      readOnly
                    />
                  </div>
                </div>
              )}

              <ValidationStatus
                forId="endDate"
                isActive={errors.endDate}
                message={localizedContent.licence_date_error_text}
                containerClassName={styles.endDateContainer}
              >
                <DateField
                  id="endDate"
                  value={endDate}
                  label={localizedContent.licence_date_label_end}
                  placeholder={localizedContent.licence_date_placeholder}
                  displayDateFormat="dd/MM/yyyy"
                  onChange={createChangeHandler('endDate')}
                  onBlur={createBlurHandler('endDate')}
                  max="2050-01-01"
                />
              </ValidationStatus>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div>
              <span className="gin-right2">
                <Button
                  type={buttonTypes.PRIMARY}
                  text={localizedContent.form_submit_button}
                  disabled={isSubmitDisabled}
                  onClick={onSubmit}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </ControlledForm>
  );
}

UpdateOrgSubscriptionForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string,
  orgName: PropTypes.string,
  subscriptionId: PropTypes.string,
  currentStartDate: PropTypes.string,
  currentEndDate: PropTypes.string,
  endDate: PropTypes.string,
  errors: PropTypes.object,
  createChangeHandler: PropTypes.func.isRequired,
  createBlurHandler: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default withLocalizedContent('updateOrgSubscriptionForm')(UpdateOrgSubscriptionForm);
