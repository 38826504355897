import React from 'react';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import styles from './RedeemFallback.scss';

function RedeemFallbackView() {
  return (
    <div className="grid">
      <div className="row">
        <div className={`col ${styles.redeemFallback}`}>
          <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
          <h1>Page under construction</h1>
          <p className={styles.messageText}>
            We are currently working on this page for the launch of the{' '}
            <i>Oxford Advanced Learner’s Dictionary, 10th edition </i>, and you will be able to redeem your code here
            from January 15, 2020.
          </p>
        </div>
      </div>
    </div>
  );
}

export default RedeemFallbackView;
