import { createSelector } from 'reselect';

/**
 * A selector returning a sorted array of organisations belonging to the currently authenticated user.
 *
 * @param {Object} state
 *
 * @return {Array.{<string id>, <string name>}}
 */
const getCurrentUserOrganisations = createSelector(
  state => state.organisations.data,
  state => state.identity.orgRoleMap,
  (organisations, orgRoleMap) =>
    Object.keys(orgRoleMap || {})
      .map(id => ({
        id,
        name: (organisations[id] || {}).name,
        isBlocked: (organisations[id] || {}).isBlocked
      }))
      .sort(({ name: nameA }, { name: nameB }) => (nameA || '').localeCompare(nameB))
);

export default getCurrentUserOrganisations;
