import { put } from 'redux-saga/effects';
import bodySchema from '../../../../../../static/schema/draft-4/add-org-subscription-request-body.json';
import paramSchema from '../../../../../../static/schema/draft-4/add-org-subscription-request-params.json';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import validateStartEndDates from '../../../utils/validateStartEndDates';
import { ISO_DATE } from '../../../../../globals/dateFormats';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

function* createOrgSubscription(input) {
  const { orgId, ...body } = input;

  const paramErrors = yield validateInputUsingSchema(paramSchema, null, input);
  const bodyErrors = yield validateInputUsingSchema(bodySchema, null, input);

  const { startDate, endDate } = body;
  const dateErrors = startDate && endDate ? validateStartEndDates(input, { startDate, endDate }, ISO_DATE) : {};
  const errors = { ...bodyErrors, ...dateErrors };

  Object.keys(paramErrors).forEach(key => {
    if (!errors[key]) {
      errors[key] = paramErrors[key];
    }
  });

  const hasErrors = Object.values(errors).some(Boolean);
  if (hasErrors) {
    yield put(actions.createOrgSubscriptionValidated(errors));
    return;
  }

  const response = yield signedFetch(
    'createOrgSubscription',
    `${__API_BASE__}/org/${orgId}/subscription`,
    'POST',
    body
  );

  if (response.status === 'success') {
    yield put(actions.createOrgSubscriptionSuccess(response.data.subscriptionId));
  } else {
    yield put(actions.createOrgSubscriptionFailure(response.message));
  }
}

export default createOrgSubscription;
