import PropTypes from 'prop-types';

export const getLevelsCompletedSortKey = level => `$LEVEL__${level}__COMPLETED`;
export const getLevelsScoreSortKey = level => `$LEVEL__${level}__SCORE`;

export const isLevelsSortKey = sortKey => sortKey.indexOf('$LEVEL__') === 0;
export const parseLevelSortKey = sortKey => {
  const [, level, field] = sortKey.split('__');
  return { level, field };
};
export const getLevelData = records => {
  const isActivityLevel = records.some(({ isActivityLevel: isLevelActivityLevel }) => isLevelActivityLevel);
  let levels = {};

  records.forEach(({ levels: recordLevels = [] }) => {
    recordLevels.forEach(
      /*
        # Sample `level`
        {
          levelName: "Unit 1"
          levelScoreAchieved: 70
          levelScoreAvailable: 100
          submittedNumberOfActivities: 48
          totalNumberOfActivities: 58
        }

        # Sample `activity`
        {
          activityName: "Ex1A1"
          totalNumberOfAttempts: 1
          totalScoreAchieved: 6
          totalScoreAvailable: 10
        }
      */
      ({
        uId,
        levelName,
        activityName,
        isActivity,
        levelScoreAchieved = 0,
        levelScoreAvailable = 0,
        submittedNumberOfActivities = 0,
        totalNumberOfActivities = 0,
        allActivityLevelScoreAvailable = 0,
        allActivityTotalScoreAvailable = 0,
        totalNumberOfAttempts = 0,
        totalScoreAchieved = 0,
        totalScoreAvailable = 0,
        locked
      }) => {
        const name = levelName || activityName;

        if (!levels[uId])
          levels[uId] = {
            uId,
            name,
            isActivity,
            levelScoreAvailable: 0,
            allActivityLevelScoreAvailable: 0,
            totalNumberOfActivities: isActivityLevel ? 1 : totalNumberOfActivities,
            totalStudents: 0,
            totalStudentsThatSubmitted: 0,
            totalSubmittedNumberOfActivities: 0,
            sumLevelScoreAchieved: 0,
            locked
          };

        levels[uId].totalStudents += 1;
        levels[uId].totalStudentsThatSubmitted += submittedNumberOfActivities > 0 || totalNumberOfAttempts > 0 ? 1 : 0;
        levels[uId].totalSubmittedNumberOfActivities += submittedNumberOfActivities || totalNumberOfAttempts;
        levels[uId].levelScoreAvailable += isActivityLevel ? totalScoreAvailable : levelScoreAvailable;
        levels[uId].allActivityLevelScoreAvailable += isActivityLevel
          ? allActivityTotalScoreAvailable
          : allActivityLevelScoreAvailable;
        levels[uId].sumLevelScoreAchieved += isActivityLevel ? totalScoreAchieved : levelScoreAchieved;
      }
    );
  });

  levels = Object.values(levels).map(
    ({
      name,
      uId,
      isActivity,
      totalStudents,
      totalNumberOfActivities,
      totalStudentsThatSubmitted,
      totalSubmittedNumberOfActivities,
      sumLevelScoreAchieved,
      levelScoreAvailable,
      allActivityLevelScoreAvailable,
      locked
    }) => ({
      name,
      uId,
      isActivity,
      totalNumberOfActivities,
      totalSubmittedNumberOfActivities,
      totalStudentsThatSubmitted,
      sumLevelScoreAchieved,
      levelScoreAvailable,
      allActivityLevelScoreAvailable,
      locked,
      averageSubmittedNumberOfActivities: totalSubmittedNumberOfActivities / totalStudents,
      averageLevelScoreAchieved:
        totalStudentsThatSubmitted === 0 ? 0 : sumLevelScoreAchieved / totalStudentsThatSubmitted,
      averageAttemptsSubmitted: totalSubmittedNumberOfActivities / totalStudents
    })
  );

  return { levels: levels.length > 0 ? levels : [], isActivityLevel };
};

/**
 * Iterates over each record and builds up a uniform list of levels (units),
 * including various totals for each (per level, not aggregate of all levels).
 */
function Levels({ records, children }) {
  return children(getLevelData(records));
}
Levels.propTypes = {
  records: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired
};

export default Levels;
