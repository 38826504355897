import jsonFetch from './jsonFetch.js';

// Common HTTP Headers for JSON:
export const JSON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export default function signedFetch(
  apiName,
  url,
  method,
  requestBody,
  requestHeaders = JSON_HEADERS,
  includeHeaders = false,
  includeXAPIKey = true,
  includeStatusCode = false
) {
  // For convenience, the requestBody may be supplied as an object or as a string such as json:
  const requestBodyText =
    typeof requestBody === 'string' || requestBody instanceof FormData ? requestBody : JSON.stringify(requestBody);

  return jsonFetch(
    apiName,
    [
      url,
      {
        method,
        headers: requestHeaders,
        body: requestBodyText
      }
    ],
    includeHeaders,
    includeXAPIKey,
    includeStatusCode
  );
}
