import { put } from 'redux-saga/effects';
import { generateDownloadLinksFailure, generateDownloadLinksSuccess } from '../../../../actions/generateDownloadLinks';
import generateDownloadLinksApi from '../../../apiCalls/product/generateDownloadLinksApi';

function* generateDownloadLinks(action) {
  let apiResults = {};

  apiResults = yield generateDownloadLinksApi(action.payload);

  if (apiResults.error || apiResults.status !== 'success') {
    yield put(generateDownloadLinksFailure({ error: apiResults.message }));
  } else {
    yield put(generateDownloadLinksSuccess({ success: apiResults.data }));
  }
}

export default generateDownloadLinks;
