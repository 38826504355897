import qs from 'query-string';
import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
const getUserLicenceUrl = queryParams => `${__API_BASE__}/user-licence?${qs.stringify(queryParams)}`;

function getUserLicencesRequest(criteria, value) {
  const queryParams = {};
  queryParams[criteria] = value;

  return signedFetch('getUserLicences', `${getUserLicenceUrl(queryParams)}`);
}

export default getUserLicencesRequest;
