import * as t from '../actionTypes';

export const studentReportRequest = (orgId, studentId, locationId) => ({
  type: t.STUDENT_REPORT_REQUEST,
  payload: {
    orgId,
    studentId,
    locationId
  }
});

export const studentReportFailure = payload => ({ type: t.STUDENT_REPORT_FAILURE, payload });

export const studentAttainmentReportRequest = (orgId, studentId, locationId) => ({
  type: t.STUDENT_ATTAINMENT_REPORT_REQUEST,
  payload: {
    orgId,
    studentId,
    locationId
  }
});

export const studentAttainmentReportFailure = reason => ({
  type: t.STUDENT_ATTAINMENT_REPORT_FAILURE,
  payload: reason
});

export const studentAttainmentReportSuccess = report => ({
  type: t.STUDENT_ATTAINMENT_REPORT_SUCCESS,
  payload: report
});

export const studentProgressReportRequest = (orgId, studentId, locationId) => ({
  type: t.STUDENT_PROGRESS_REPORT_REQUEST,
  payload: {
    orgId,
    studentId,
    locationId
  }
});

export const studentProgressReportFailure = reason => ({
  type: t.STUDENT_PROGRESS_REPORT_FAILURE,
  payload: reason
});

export const studentProgressReportSuccess = report => ({
  type: t.STUDENT_PROGRESS_REPORT_SUCCESS,
  payload: report
});

export const studentEngagementReportRequest = (orgId, studentId, locationId) => ({
  type: t.STUDENT_ENGAGEMENT_REPORT_REQUEST,
  payload: {
    orgId,
    studentId,
    locationId
  }
});

export const studentEngagementReportFailure = reason => ({
  type: t.STUDENT_ENGAGEMENT_REPORT_FAILURE,
  payload: reason
});

export const studentEngagementReportSuccess = report => ({
  type: t.STUDENT_ENGAGEMENT_REPORT_SUCCESS,
  payload: report
});

export const studentProgressItemsRequest = (orgId, studentId, locationId) => ({
  type: t.STUDENT_PROGRESS_ITEMS_REQUEST,
  payload: {
    orgId,
    studentId,
    locationId
  }
});

export const studentProgressItemsFailure = reason => ({
  type: t.STUDENT_PROGRESS_ITEMS_FAILURE,
  payload: reason
});

export const studentProgressItemsSuccess = items => ({
  type: t.STUDENT_PROGRESS_ITEMS_SUCCESS,
  payload: items
});
