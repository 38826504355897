import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import * as t from '../../../../actionTypes';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import getCreateOrgRequestApiContext from './getCreateOrgRequestApiContext';

function* validateOrgInput(input, branch) {
  const { schema, body } = yield getCreateOrgRequestApiContext(input, branch);
  const errors = yield validateInputUsingSchema(schema, t.VALIDATE_ORG_COMPLETED, body);
  const hasErrors = Object.values(errors).includes(true);
  if (hasErrors) {
    yield put(actions.validateOrgError());
  }
  return hasErrors;
}

export default validateOrgInput;
