import * as t from '../../actionTypes';

const initialState = {
  content: {},
  consumer: {},
  submitting: false,
  success: false,
  error: false,
  receivedAt: null
};

function ngsLTIOpener(state = initialState, { type, payload }) {
  switch (type) {
    case t.NGS_RETRIEVE_LTI_CONSUMER_REQUEST:
      return { ...state, content: payload.content, consumer: {}, submitting: true, receivedAt: null };
    case t.NGS_RETRIEVE_LTI_CONSUMER_SUCCESS:
      return { ...state, consumer: payload, submitting: false, success: true, receivedAt: Date.now() };
    case t.NGS_RETRIEVE_LTI_CONSUMER_FAILURE:
      return { ...state, submitting: false, error: true, receivedAt: null };
    default:
      return state;
  }
}

export default ngsLTIOpener;
