import { get } from 'lodash';
import { createSelector } from 'reselect';

/**
 * A selector returning a function to access the current user's role for a
 * given organisation ID.
 *
 * @param {Object} state
 *
 * @return {(orgId: string, default?: string) => ?string}
 */
const createGetUserRoleForOrg = createSelector(
  state => get(state, ['identity', 'orgRoleMap'], []),
  orgRoleMap => get.bind(null, orgRoleMap)
);

export default createGetUserRoleForOrg;
