import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import styles from './PanelNavigationLink.scss';
import { GLYPHS } from '../SVGIcon/SVGIcon';

// Important: Beware of changing "panelNavLink" because it's used by PopoutPanel to identify the close button:

function PanelNavigationLink({ id, text, action, customClassName, isLhs, disabled, noGlyph = false }) {
  return (
    <LinkWithIcon
      id={id || 'panelNavLink'}
      text={text}
      glyph={!noGlyph ? GLYPHS[isLhs ? 'ICON_LEFT_THICK' : 'ICON_CLOSE'] : ''}
      action={action}
      isLhs={isLhs}
      customClassName={classnames('panelNavLink', styles.panelNavigationLink, customClassName)}
      disabled={disabled}
    />
  );
}

PanelNavigationLink.propTypes = {
  /** The text within the PanelNavigationLink */
  text: PropTypes.string.isRequired,
  /** The action within the PanelNavigationLink */
  action: PropTypes.func.isRequired,
  /** The customClassName within the PanelNavigationLink */
  customClassName: PropTypes.string,
  /** The position within the PanelNavigationLink */
  isLhs: PropTypes.bool,
  /** The html ID of the link. */
  id: PropTypes.string,
  /** Set to true to make link or button un-tabbable */
  disabled: PropTypes.bool,
  /** Set to true to make glyph visible. By default is false */
  noGlyph: PropTypes.bool
};

export default PanelNavigationLink;
