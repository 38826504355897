import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ListPageControls from '../../../components/ListPageControls/ListPageControls.js';

import { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';
import PaginationButtons from '../../../components/PaginationButtons/PaginationButtons.js';
import Table from '../../../components/Table/Table.js';
import Thumbnail, { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail.js';
import ConnectedClassRepresentation from '../../../components/ClassRepresentation/ConnectedClassRepresentation.js';

import TimeTag from '../../../components/TimeTag/TimeTag.js';
import {
  triggerSearch,
  setTerm,
  setSort,
  setPage,
  setFilterClass,
  setPageClass,
  setSortClass,
  setFilter
} from '../../../redux/reducers/data/search.reducer.js';
import SearchStatus from '../../../components/SearchStatus/SearchStatus.js';
import userRoles from '../../../globals/userRoles.js';

import cmsContent from '../../../utils/cmsContent.js';
import { featureIsEnabled } from '../../../globals/envSettings';

function MyProfileClassSearch({
  userId,
  userRole,
  orgId,
  classrooms,
  searchTerm,
  sort,
  page,
  filters,
  resultIds,
  totalResults,
  triggerSearchAction,
  setSearchTerm,
  setSortAction,
  setPageAction,
  setFilterAction,
  loadingResults,
  paginatedClass
}) {
  const CMS = cmsContent.myProfileClassSearchTab || {};
  const row = featureIsEnabled('client-side-pagination') ? Object.keys(paginatedClass) : resultIds;
  return (
    <div>
      <ListPageControls
        idPrefix="profileClassesSearch"
        searchInputLabel={CMS.search_classes_label}
        searchInputPlaceholder={CMS.search_classes_label}
        searchInputValue={searchTerm}
        searchInputOnChange={setSearchTerm}
        searchInputOnSubmit={triggerSearchAction}
        filterOptions={[
          {
            text: CMS.active_classes_label,
            id: 'searchFilter-by-active-classes',
            name: 'searchFilter-by-active-classes',
            value: 'active',
            checked: filters.active,
            onChange: setFilterAction
          },
          {
            text: CMS.archived_classes_label,
            id: 'searchFilter-by-archived-classes',
            name: 'searchFilter-by-archived-classes',
            value: 'archived',
            checked: filters.archived,
            onChange: setFilterAction
          }
        ]}
        sortOnChange={setSortAction}
        sortOptions={[
          {
            text: CMS.sort_by_name_ASC,
            id: 'searchOrder-by-name-ASC',
            name: 'searchOrder',
            value: 'name:asc',
            checked: sort === 'name:asc'
          },
          {
            text: CMS.sort_by_name_DESC,
            id: 'searchOrder-by-name-DESC',
            name: 'searchOrder',
            value: 'name:desc',
            checked: sort === 'name:desc'
          },
          {
            text: CMS.sort_created_newest,
            id: 'searchOrder-by-createdDate-DESC',
            name: 'searchOrder',
            value: 'date:desc',
            checked: sort === 'date:desc'
          },
          {
            text: CMS.sort_created_oldest,
            id: 'searchOrder-by-createdDate-ASC',
            name: 'searchOrder',
            value: 'date:asc',
            checked: sort === 'date:asc'
          }
        ]}
        ariaControls="searchResults"
        showSkeletonLoader={loadingResults}
      />

      <SearchStatus
        searchSource="profileClasses"
        noResultsFoundContent={
          <div className="grid">
            <div className="row">
              <div role="region" aria-live="polite" aria-atomic="true" className="col">
                <p id="searchResults-classes-noResults" className="gin-top1 gin-bot1">
                  {CMS.no_search_results_text}
                </p>
              </div>
            </div>
          </div>
        }
      />
      {loadingResults ? null : (
        <div className="grid">
          <div className="row">
            <div
              id="searchResults"
              className="col"
              role="region"
              aria-live="polite"
              aria-atomic="true"
              aria-label="My profile - classrooms list"
            >
              {row.length > 0 && (
                <div className="gin-top2">
                  <Table
                    columns={[
                      { heading: CMS.heading_text_classes },
                      { heading: CMS.heading_text_status },
                      { heading: CMS.heading_text_joined_Class }
                    ]}
                    rows={row.map(id => {
                      const classroom = classrooms[id] || {};

                      const cells = [
                        <div key={0}>
                          <ConnectedClassRepresentation
                            id={`searchResults-item-${id}`}
                            entityId={id}
                            linkIdPrefix="linkToClassroom"
                            linkTo={
                              userRole === userRoles.LEARNER || classroom.orgId !== orgId
                                ? null
                                : `/org/${orgId}/class/${id}`
                            }
                            userRole={userRole}
                            thumbnailSize={thumbnailSizes.TABLE}
                            deletedStyle={classroom.archived}
                            orgId={orgId}
                          />
                        </div>,

                        <div
                          key={1}
                          id={`searchResults-archive-status-${id}`}
                          data-tooltip={classroom.archived ? CMS.archived_text : CMS.active_text}
                        >
                          <div className="a11y-hide">{classroom.archived ? CMS.archived_text : CMS.active_text}</div>
                          <Thumbnail
                            size={thumbnailSizes.MEDIUM}
                            glyph={classroom.archived ? GLYPHS.ICON_REMOVED_CIRCLE : GLYPHS.ICON_CHECK_CIRCLE}
                          />
                        </div>,

                        <div
                          key={2}
                          id={`searchResults-date-${id}`}
                          data-tooltip={classroom[`userJoinedDate-${userId}`] ? CMS.heading_text_joined_Class : ''}
                        >
                          <TimeTag dateString={classroom[`userJoinedDate-${userId}`]} />
                        </div>
                      ];

                      return {
                        id: `class${id}`,
                        cells
                      };
                    })}
                  />
                </div>
              )}

              {totalResults > 10 && (
                <div className="gin-top2">
                  <PaginationButtons
                    idPrefix="profileClassesSearch"
                    value={page}
                    numberOfPages={Math.ceil(totalResults / 10)}
                    onClick={setPageAction}
                    aria={{ 'aria-controls': 'searchResults' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

MyProfileClassSearch.propTypes = {
  orgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  classrooms: PropTypes.object.isRequired,

  searchTerm: PropTypes.string.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  resultIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,

  triggerSearchAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  loadingResults: PropTypes.bool,
  paginatedClass: PropTypes.object.isRequired
};

export default connect(
  state => ({
    userId: state.identity.userId,
    userRole: state.identity.role,
    classrooms: state.classrooms.data,
    orgId: state.identity.currentOrganisationId,

    searchTerm: state.search.profileClasses.term,
    sort: state.search.profileClasses.sort,
    page: state.search.profileClasses.page,
    filters: state.search.profileClasses.filters,
    resultIds: state.search.profileClasses.ids,
    paginatedClass: state.search.profileClasses.paginatedClassList,
    totalResults: state.search.profileClasses.totalResults,
    loadingResults: state.search.profileClasses ? state.search.profileClasses.loading : false
  }),
  {
    setSearchTerm: term => setTerm('profileClasses', term),
    setSortAction: sort =>
      featureIsEnabled('client-side-pagination')
        ? setSortClass('profileClasses', sort[0])
        : setSort('profileClasses', sort[0]),
    setPageAction: page =>
      featureIsEnabled('client-side-pagination')
        ? setPageClass('profileClasses', page)
        : setPage('profileClasses', page),
    setFilterAction: (valueName, value) =>
      featureIsEnabled('client-side-pagination')
        ? setFilterClass('profileClasses', valueName, value)
        : setFilter('profileClasses', valueName, value),
    triggerSearchAction: triggerSearch.bind(null, 'profileClasses')
  }
)(MyProfileClassSearch);
