import { put } from 'redux-saga/effects';
import { libraryRequestSuccess, libraryRequestFailure } from '../../../../actions/ngsLibrary';
import getCourses from '../../../apiCalls/ngs/getCourses';

function* getLibrary(orgId, region) {
  const response = yield getCourses(orgId, region);

  if (response.status === 'success') {
    yield put(libraryRequestSuccess(response.data));
    return;
  }

  yield put(libraryRequestFailure(response.message));
}

export default getLibrary;
