import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classnames from 'classnames';

import styles from './ContentPlayerSidebar.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import { GlyphIcon, EmblemSize } from '../IconEmblem/IconEmblem';
import {
  closeMobileMenuModal,
  toggleContentsSubmenuModal,
  clearCurrentContent
} from '../../redux/actions/structuredContentPlayer';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import useMediaQuery from '../../utils/mediaQuery';
import UnitLessonLevelMenu from './UnitLessonLevelMenu';
import { removeQueryParams } from '../../globals/urls';
import { onKeyDownHandler } from '../../services/cptHelperMethods';

function ContentPlayerSidebar({
  title,
  toggleModal,
  clearCurrentContentData,
  closeMobileMenuModalAction,
  cptContent,
  isContentPlayerSidebarOpen
}) {
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleToggleModal = () => {
    removeQueryParams(true);
    clearCurrentContentData(null);
    closeMobileMenuModalAction();
    toggleModal();
  };

  return (
    <div className={styles.container}>
      {isMobile ? (
        <LinkWithIcon
          isLhs
          id="PopoutNavFooterBackLink"
          action={toggleModal}
          text={cptContent.back_button_text}
          glyph={GLYPHS.ICON_LEFT}
          customClassName={styles.backButton}
          aria-hidden={isContentPlayerSidebarOpen ? 'false' : 'true'}
        />
      ) : (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDownHandler(toggleModal)}
          onClick={toggleModal}
          aria-label={cptContent.close_menu}
          className={classnames(EmblemSize.MEDIUM, styles.cpCloseButton)}
          aria-hidden={isContentPlayerSidebarOpen ? 'false' : 'true'}
        >
          <SVGIcon glyph={GlyphIcon.ICON_CLOSE_BUTTON_SMALL} />
        </div>
      )}

      <div className={styles.titleContainer}>
        <h2>{title}</h2>
        <div
          className={styles.menuItem}
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDownHandler(handleToggleModal)}
          onClick={handleToggleModal}
        >
          <p>{cptContent.home}</p>
        </div>
        <UnitLessonLevelMenu key={title} cptContent={cptContent} />
      </div>
    </div>
  );
}

ContentPlayerSidebar.propTypes = {
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  clearCurrentContentData: PropTypes.func.isRequired,
  closeMobileMenuModalAction: PropTypes.func.isRequired,
  cptContent: PropTypes.object,
  isContentPlayerSidebarOpen: PropTypes.bool
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  toggleModal: toggleContentsSubmenuModal,
  clearCurrentContentData: clearCurrentContent,
  closeMobileMenuModalAction: closeMobileMenuModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentPlayerSidebar);
