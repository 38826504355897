/* eslint no-undef: "error" */

import { put, takeLatest } from 'redux-saga/effects';
import getAppSettingsApi from '../../apiCalls/settings/getAppSettings.api.js';
import { setCmsContent } from '../../../../utils/cmsContent.js';
import { cmsReady, appReady, appNotReady, SET_LANGUAGE } from '../../../reducers/app.reducer.js';
import { setAppSettings } from '../../../../globals/appSettings.js';
// import appSettings from '../../../../globals/appSettings.js';
import { isDev } from '../../../../globals/envSettings.js';
import { isOteMode, isRvsMode, isOicMode } from '../../../../utils/platform';
import loadLocalizedAmContent from '../../appFunctions/authorisedFunctions/ote/amLocalizedContent';
import loadLocalizedOicContent from '../../appFunctions/authorisedFunctions/oic/oicLocalizedContent.js';

import { storeArticles } from '../../../reducers/data/supportArticles.reducer.js';
import cmsArticlesApi from '../../apiCalls/cms/cmsSupportArticles.api.js';

const moduleName = '[loadContent Saga]';

export function* loadContent() {
  try {
    console.log(moduleName, 'Starting');

    if (isRvsMode() && !localStorage.getItem('language')) {
      localStorage.setItem('language', navigator.language);
    }

    const settingsResult = yield getAppSettingsApi();

    console.log(moduleName, 'Settings result:', settingsResult);

    const contentLoadedSuccessfully =
      settingsResult && settingsResult.status === 'success' && typeof settingsResult.data.rules === 'object';

    if (!contentLoadedSuccessfully) {
      const cmsFailureWarningMessage = `App Settings could not be loaded: ${JSON.stringify(settingsResult)}`;
      // console.debug('In loadContent.saga.js - isDev is set to <%s>', isDev());
      if (isDev()) {
        console.warn(cmsFailureWarningMessage);
        setCmsContent({});
      } else {
        console.error(moduleName, 'ERROR: Settings could not be applied to the app');
        throw new Error(cmsFailureWarningMessage);
      }
    }

    setAppSettings(settingsResult.data.rules);
    setCmsContent(settingsResult.data.content);

    yield put(cmsReady());

    if (isOteMode()) {
      yield loadLocalizedAmContent();
    }

    if (isOicMode()) {
      yield loadLocalizedOicContent();
    }

    console.log(moduleName, 'Complete');
  } catch (err) {
    console.log(moduleName, err);
  }
}

function* prepareForLoadingContent() {
  // called appNotReady and appReady in order to show the loading screen and force a re-render of the layout component
  yield put(appNotReady());
  yield loadContent();
  const data = yield cmsArticlesApi();
  yield put(storeArticles(data.data));
  yield put(appReady());
}

export default function* root() {
  yield takeLatest(SET_LANGUAGE, prepareForLoadingContent);
}
