import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

function* getTestCredits(input) {
  const { orgId } = input;
  const response = yield signedFetch('getTestCredits', `${__API_BASE__}/org/${orgId}/test-credits`);

  if (response.status === 'success') {
    if (response.data) {
      return yield put(actions.getTestCreditsSuccess({ orgId, testCredits: response.data }));
    }
    return yield put(actions.getTestCreditsEmptySuccess({}));
  }
  return yield put(actions.getTestCreditsFailure({ errors: response.message }));
}

export default getTestCredits;
