import * as t from '../actionTypes';

export const getRecentlyAccessedRequest = payload => ({
  type: t.GET_RECENTLY_ACCESSED_REQUEST,
  payload
});

export const getRecentlyAccessedSuccess = payload => ({
  type: t.GET_RECENTLY_ACCESSED_SUCCESS,
  payload
});

export const getRecentlyAccessedError = () => ({
  type: t.GET_RECENTLY_ACCESSED_ERROR
});

export const updateRecentlyAccessedRequest = (payload, params) => ({
  type: t.UPDATE_RECENTLY_ACCESSED_REQUEST,
  payload,
  params
});

export const updateRecentlyAccessedSuccess = () => ({
  type: t.UPDATE_RECENTLY_ACCESSED_SUCCESS
});

export const updateRecentlyAccessedError = payload => ({
  type: t.UPDATE_RECENTLY_ACCESSED_ERROR,
  payload
});
