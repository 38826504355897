import { put } from 'redux-saga/effects';
import loadCourseContentFetch from '../../../apiCalls/loadCourse';
import { loadCourseContentFailure, loadCourseContentSuccess } from '../../../../actions/coursePreview';

function* loadCourseContent(params) {
  const response = yield loadCourseContentFetch(params);
  if (response.status === 'success') {
    yield put(loadCourseContentSuccess(response.data));
  } else {
    yield put(loadCourseContentFailure('Failed to load course content data'));
  }
}

export default loadCourseContent;
