import * as t from '../actionTypes';

export const getWordOfTheDayRequest = payload => ({
  type: t.WORD_OF_THE_DAY_REQUEST,
  payload
});

export const getWordOfTheDaySuccess = payload => ({
  type: t.WORD_OF_THE_DAY_SUCCESS,
  payload
});

export const getWordOfTheDayError = () => ({
  type: t.WORD_OF_THE_DAY_ERROR
});
