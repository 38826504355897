import { URL_HIERARCHY_DELIMITER } from '@oup/shared-node-browser/constants';

const { sortNumericTitles } = require('@oup/shared-node-browser/sortNumericTitles');

/**
 * This will sort @activities for 'LearnerProgress' component (ascending)
 *
 * @param {Array} activities Array to be sorted
 *
 * @returns {Array} The sorted array
 */

export const sortActivitiesByTitleRecursively = activities => {
  if (!Array.isArray(activities) || activities.length === 0) {
    return activities;
  }

  // create a sorted array of strings based on activityName prop
  const sortedActivityNames = activities.map(a => a.activityName).sort(sortNumericTitles);

  // create a new activities array based on sortedActivityNames order
  const sortedActivities = sortedActivityNames.map(key =>
    activities.find(a => {
      // sort nested activities as well
      if (a.activityScore) {
        a.activityScore = sortActivitiesByTitleRecursively(a.activityScore);
      }
      return a.activityName === key;
    })
  );

  return sortedActivities;
};

/**
 * This translates the data format from the raw format coming in
 * to the format accepted by the `GradebookTable` component.
 *
 * @param {Object} data The raw data coming in from CES
 *
 * @returns {Array} The formatted array.
 */

const dataFormatter = data => {
  const { score } = data;

  if (!score) {
    return null;
  }

  const levels = {};

  const dataObject = Object.entries(data.score).reduce((carry, [sectionNames, { activityScore }]) => {
    const [, firstLevelName, secondLevelName, thirdLevelName] = sectionNames.split(URL_HIERARCHY_DELIMITER);
    const secondLevelKey = `${firstLevelName}-${secondLevelName}`;

    if (thirdLevelName) {
      levels[secondLevelKey] = levels[secondLevelKey]
        ? [
            ...levels[secondLevelKey],
            {
              activityName: thirdLevelName,
              activityScore
            }
          ]
        : [
            {
              activityName: thirdLevelName,
              activityScore
            }
          ];
    } else {
      levels[secondLevelKey] = levels[secondLevelKey] ? [...levels[secondLevelKey], ...activityScore] : activityScore;
    }

    levels[firstLevelName] = levels[firstLevelName]
      ? {
          ...levels[firstLevelName],
          [secondLevelName]: {
            activityScore: levels[secondLevelKey]
          }
        }
      : {
          [secondLevelName]: {
            activityScore: levels[secondLevelKey]
          }
        };

    const firstLevelDetails = Object.entries(levels[firstLevelName]).map(([key, value]) => ({
      activityName: key,
      activityScore: value.activityScore
    }));

    return {
      ...carry,
      [firstLevelName]: {
        activityScore: firstLevelDetails
      }
    };
  }, {});

  const formattedData = Object.entries(dataObject).map(([name, activityData]) => ({
    activityName: name,
    ...activityData
  }));

  return formattedData; // No sort required, as it is implicitly taken care in CMS data
};
export default dataFormatter;
