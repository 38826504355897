import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button, { GLYPHS, buttonTypes } from '../Button/Button';
import styles from './CorrelationChart.scss';
import withLocalizedContent from '../../language/withLocalizedContent';

const levelMapping = {
  ks1: [
    { localLevel: 'Pre-1', oxfordLevels: ['1'], color: '#b283b9' },
    { localLevel: '1', oxfordLevels: ['1+'], color: '#f1887f' },
    { localLevel: '2', oxfordLevels: ['2'], color: '#c12f1f', extraClassName: styles.lightText },
    { localLevel: '3', oxfordLevels: ['3'], color: '#fbe000' },
    { localLevel: '4', oxfordLevels: ['4'], color: '#6ac8eb' },
    { localLevel: '5', oxfordLevels: ['5'], color: '#008b3e', extraClassName: styles.lightText },
    { localLevel: '6', oxfordLevels: ['6'], color: '#ef7d00' },
    { localLevel: '7', oxfordLevels: ['7'], color: '#009e8d' },
    { localLevel: '8', oxfordLevels: ['8'], color: '#673e91', extraClassName: styles.lightText },
    { localLevel: '9', oxfordLevels: ['9'], color: '#f0ae00' },
    { localLevel: '10', oxfordLevels: ['10'], color: '#ffffff' },
    { localLevel: '11', oxfordLevels: ['11'], color: '#c8d300' },
    { localLevel: '11', oxfordLevels: ['12'], color: '#95c11f' }
  ],

  ks2: [
    { localLevel: '12', oxfordLevels: ['8', '9', '10', '11'], color: '#b05c00', extraClassName: styles.lightText },
    { localLevel: '13', oxfordLevels: ['12', '13', '14'], color: '#70777a', extraClassName: styles.lightText },
    { localLevel: '14', oxfordLevels: ['15', '16'], color: '#00538f', extraClassName: styles.lightText },
    { localLevel: '15', oxfordLevels: ['17', '18'], color: '#9e1510', extraClassName: styles.lightText },
    { localLevel: '15', oxfordLevels: ['19', '20'], color: '#7d1206', extraClassName: styles.lightText }
  ],

  dragonfly: [
    { localLevel: '1', oxfordLevels: ['1+', '2', '3'], color: '#EBD417' },
    { localLevel: '2', oxfordLevels: ['4', '5', '6'], color: '#BFD730' },
    { localLevel: '3', oxfordLevels: ['7', '8'], color: '#23A5B9' },
    { localLevel: '4', oxfordLevels: ['9', '10'], color: '#00409A', extraClassName: styles.lightText },
    { localLevel: '5', oxfordLevels: ['11', '12'], color: '#5F6DB3', extraClassName: styles.lightText },
    { localLevel: '6', oxfordLevels: ['13', '14'], color: '#8C2C6A', extraClassName: styles.lightText },
    { localLevel: '7', oxfordLevels: ['15', '16'], color: '#D91378', extraClassName: styles.lightText },
    { localLevel: '8', oxfordLevels: ['17'], color: '#CE181E', extraClassName: styles.lightText },
    { localLevel: '9', oxfordLevels: ['18'], color: '#f47920' }
  ],

  australian: [
    { localLevel: '1', oxfordLevels: ['1'] },
    { localLevel: '1', oxfordLevels: ['1+'] },
    { localLevel: '2', oxfordLevels: ['2'] },
    { localLevel: '3-5', oxfordLevels: ['3'] },
    { localLevel: '6-9', oxfordLevels: ['4'] },
    { localLevel: '10-14', oxfordLevels: ['5'] },
    { localLevel: '15-16', oxfordLevels: ['6'] },
    { localLevel: '17', oxfordLevels: ['7'] },
    { localLevel: '18-19', oxfordLevels: ['8'] },
    { localLevel: '20-21', oxfordLevels: ['9'] },
    { localLevel: '22-24', oxfordLevels: ['10'] },
    { localLevel: '25-26', oxfordLevels: ['11'] },
    { localLevel: '27', oxfordLevels: ['12'] },
    { localLevel: '27', oxfordLevels: ['13'] },
    { localLevel: '28', oxfordLevels: ['14'] },
    { localLevel: '29', oxfordLevels: ['15'] },
    { localLevel: '30', oxfordLevels: ['16'] },
    { localLevel: '30+', oxfordLevels: ['17'] },
    { localLevel: '30+', oxfordLevels: ['18'] },
    { localLevel: '30+', oxfordLevels: ['19'] },
    { localLevel: '30+', oxfordLevels: ['20'] }
  ]
};

const STEP = 100;
const MIN_CELL_WIDTH = 45;

class CorrelationChart extends Component {
  constructor() {
    super();
    this.state = {
      isOverflow: false
    };
  }

  componentDidMount() {
    if (!this.elementRef) {
      return;
    }

    this.elementTotalWidth = this.elementRef.scrollWidth;
    // Need to re-render one time based on rendered width to show arrow button if it is overflow
    if (this.elementTotalWidth > this.elementRef.clientWidth) {
      this.setState({ isOverflow: true });
    }
  }

  setRef = elementRef => {
    this.elementRef = elementRef;
  };

  moveLeft = () => {
    const newLeft = this.elementRef.scrollLeft - STEP;
    this.elementRef.scrollLeft = newLeft < 0 ? 0 : newLeft;
  };

  moveRight = () => {
    const newLeft = this.elementRef.scrollLeft + STEP;
    this.elementRef.scrollLeft =
      newLeft > this.elementTotalWidth ? this.elementRef.clientWidth - this.elementTotalWidth : newLeft;
  };

  render() {
    const {
      localizedContent: { correlationChart: localizedContent },
      localLevelName
    } = this.props;
    const { isOverflow } = this.state;

    const numberOfCells = levelMapping[localLevelName].reduce((result, item) => result + item.oxfordLevels.length, 0);
    const cellWidth = 100 / numberOfCells;

    return (
      <div className={styles.correlationChart}>
        <div className={styles.levelTitle}>
          <div className={styles.oxfordLevelsLevelTitle}>{localizedContent.oxford_level}</div>
          <div className={styles.localLevelTitle}>{localizedContent[`${localLevelName}_level`]}</div>
        </div>
        {isOverflow ? (
          <Button
            type={buttonTypes.ROUNDED}
            onClick={this.moveLeft}
            glyph={GLYPHS.ICON_LEFT_THICK}
            customClassName={styles.arrowButton}
          />
        ) : null}
        <div className={styles.matrixContainer} ref={this.setRef}>
          <table className={styles.levelMatrix} style={{ minWidth: `${numberOfCells * MIN_CELL_WIDTH}px` }}>
            <tbody>
              <tr>
                {levelMapping[localLevelName].reduce(
                  (accumulators, { oxfordLevels }) => [
                    ...accumulators,
                    ...oxfordLevels.map((oxfordLevel, index) => (
                      <td
                        key={oxfordLevel}
                        className={classnames(styles.oxfordLevelItem, {
                          [styles.cellSeparator]: index === oxfordLevels.length - 1
                        })}
                        style={{ width: `${cellWidth}%` }}
                      >
                        {oxfordLevel}
                      </td>
                    ))
                  ],
                  []
                )}
              </tr>
              <tr>
                {levelMapping[localLevelName].map(({ localLevel, oxfordLevels, color, extraClassName }, index) => (
                  <td
                    key={index}
                    colSpan={oxfordLevels.length}
                    className={classnames(styles.localLevelItem, extraClassName)}
                    style={{ backgroundColor: color }}
                  >
                    {localLevel}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        {isOverflow ? (
          <Button
            type={buttonTypes.ROUNDED}
            onClick={this.moveRight}
            glyph={GLYPHS.ICON_RIGHT_THICK}
            customClassName={styles.arrowButton}
          />
        ) : null}
      </div>
    );
  }
}

CorrelationChart.propTypes = {
  localLevelName: PropTypes.oneOf(Object.keys(levelMapping)).isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('correlationChart')(CorrelationChart);
