import * as t from '../actionTypes';

const initialState = {
  workbook: {
    errors: {},
    loading: true,
    success: false,
    failure: false,
    navigation: null,
    products: {},
    group: {},
    learner: {}
  },
  gradedReaders: {
    errors: {},
    loading: true,
    success: false,
    failure: false,
    products: {},
    group: {},
    learner: {}
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.GRADED_READERS_RESULTS_REQUEST:
      return {
        ...state,
        gradedReaders: {
          ...state.gradedReaders,
          loading: true,
          success: false,
          failure: false
        }
      };
    case t.GRADED_READERS_RESULTS_FAILURE:
      return {
        ...state,
        gradedReaders: {
          ...state.gradedReaders,
          loading: false,
          failure: true,
          [payload.page]: {},
          errors: {
            ...state.gradedReaders.errors,
            ...payload
          }
        }
      };
    case t.GRADED_READERS_RESULTS_SUCCESS:
      return {
        ...state,
        gradedReaders: {
          ...state.gradedReaders,
          loading: false,
          success: true,
          [payload.page]: payload.data
        }
      };
    case t.GRADED_READERS_RESULTS_LOADING:
      return {
        ...state,
        gradedReaders: {
          ...state.gradedReaders,
          loading: true
        }
      };
    default:
      return state;
  }
};
