import * as t from '../actionTypes';

const initialState = {
  status: '',
  results: ''
};

function productServices(state = initialState, { type, payload }) {
  switch (type) {
    case t.PRESEED_ORG_IN_PROGRESS:
      return {
        ...initialState,
        status: payload
      };
    case t.PRESEED_ORG_SUCCESS:
      return {
        ...initialState,
        status: payload,
        results: ''
      };
    case t.PRESEED_ORG_FAILURE:
      return {
        ...initialState,
        status: payload.status,
        results: payload
      };
    case t.PRESEED_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default productServices;
