import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getPlatformsApi from '../../../apiCalls/getPlatformsApi';

function* getPlatforms() {
  let apiResults = {};

  apiResults = yield getPlatformsApi();

  if (apiResults.error || apiResults.status !== 'success') {
    yield put(actions.platformsCallFailure({ error: apiResults.message }));
  } else {
    yield put(actions.platformsCallSuccess({ success: apiResults.data.platforms }));
  }
}

export default getPlatforms;
