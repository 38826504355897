import React from 'react';
import { compose } from 'recompose';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table, { columnTypes } from '../../../../components/Table/Table';
import tableStyles from '../../../../components/Table/Table.scss';
import styles from './UserSearchResults.scss';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function UserSearchResults({ results, total, localizedContent: { userSearchResults: content }, onEditUserAction }) {
  const columns = [
    {
      heading: content.username,
      type: columnTypes.TEXT
    },
    {
      heading: content.email,
      type: columnTypes.TEXT
    },
    {
      heading: content.firstName,
      type: columnTypes.TEXT
    },
    {
      heading: content.lastName,
      type: columnTypes.TEXT
    },
    {
      heading: content.licenses,
      type: columnTypes.TEXT,
      aligned: 'center'
    },
    {
      heading: content.createdDate,
      type: columnTypes.TEXT,
      aligned: 'center'
    },
    {
      heading: content.userId,
      type: columnTypes.TEXT
    },
    {
      heading: content.actions,
      type: columnTypes.TEXT,
      aligned: 'center'
    }
  ];

  const getDate = raw => {
    let formated = '';
    if (!raw) {
      return formated;
    }
    try {
      const dateObj = moment(raw);
      formated = dateObj.local().format('YYYY-MM-DD');
    } catch (e) {
      console.error(e);
    }
    return formated;
  };

  const getTime = raw => {
    let formated = '';
    if (!raw) {
      return formated;
    }
    try {
      const dateObj = moment(raw);
      formated = dateObj.local().format('HH:mm:ss');
    } catch (e) {
      console.error(e);
    }
    return formated;
  };

  const rows = results.map((user, index) => ({
    id: `row_${index}`,
    cells: [
      <div key={`username_${user.username}`}>{user.username}</div>,
      <div key={`email_${user.email}`}>{user.email}</div>,
      <div key={`firstName_${user.firstName}`}>{user.firstName}</div>,
      <div key={`lastName_${user.lastName}`}>{user.lastName}</div>,
      <div key={`licenses_${user.licenses}`}>{user.licenses}</div>,
      [
        <div key={`date_${index}`}>{getDate(user.dateCreated)}</div>,
        <div key={`hour_${index}`}>{getTime(user.dateCreated)}</div>
      ],
      <div key={`oupId_${user.userId}`}>{user.userId}</div>,
      <div key={`actions_${index}`} className={styles.actions}>
        <a key={`link_delete_${user.username}`}>
          <Button type={buttonTypes.CLOSE} />
        </a>
        <a key={`link_edit_${user.username}`}>
          <Button type={buttonTypes.PENCIL_REVIEW} onClick={() => onEditUserAction(user)} />
        </a>
      </div>
    ]
  }));

  return (
    <div>
      <p>{`We found results(s) ${total} matching your criteria:`}</p>
      <Table columns={columns} rows={rows} customClass={tableStyles.leftAligned} />
    </div>
  );
}

UserSearchResults.propTypes = {
  results: PropTypes.array.isRequired,
  localizedContent: PropTypes.object,
  total: PropTypes.number,
  onEditUserAction: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('userSearchResults'))(UserSearchResults);
