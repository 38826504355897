import React from 'react';
import PropTypes from 'prop-types';

import styles from './TextWithData.scss';

function TextWithData({ header, subText, boldHeader }) {
  return (
    <div className="pad-right4">
      {boldHeader ? (
        <p className={styles.readingDataTitles}>
          <em>{header}</em>
        </p>
      ) : (
        <p className={styles.readingDataTitles}>{header}</p>
      )}
      <p className={styles.readingData}>{subText}</p>
    </div>
  );
}

TextWithData.propTypes = {
  /** The text that populates the header */
  header: PropTypes.string.isRequired,
  /** Test that populates the sub=header */
  subText: PropTypes.node.isRequired,
  /** If true the header is bold */
  boldHeader: PropTypes.bool
};

TextWithData.defaultProps = {
  boldHeader: false
};
export default TextWithData;
