import { JSON_HEADERS } from './util/signedFetch';
import jsonFetch from './util/jsonFetch';
import signedRequest from './util/signedRequest';
import { hydrateAndWrap } from './hub/getCourses';

export async function loadCourseContent(filters) {
  const URL = `${__API_BASE__}/open/elt/courses/`;
  const headers = JSON_HEADERS;
  let filterParam = '';
  if (filters) {
    if (filters.courseCode) {
      filterParam = `${filters.courseCode}`;
    }
  }

  const response = await jsonFetch(
    'LoadCourseContent',
    [URL + filterParam, signedRequest(URL + filterParam, 'GET', headers)],
    false
  );

  if (response && response.data) {
    const course = response.data;
    const courses = {};
    courses[course.courseCode] = course;

    course.products = course.products.allIds.map(isbn => ({
      isbn,
      started: 1,
      licenced: 1,
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString()
    }));

    const simulatedCoursesApiResponse = await hydrateAndWrap(courses, course.products, filters.platform);
    return simulatedCoursesApiResponse;
  }

  return {
    status: 'error',
    message: 'No response form course content load'
  };
}

function* yielded(func, parameters) {
  // return the results of an async function
  return yield func(parameters);
}

export default parameters => yielded(async param => loadCourseContent(param), parameters);
