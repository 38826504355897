import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ElementWithText.scss';

function ElementWithText({ text = '', className = '', children = '' }) {
  return (
    <div className={classnames(styles.containerFormat, className)}>
      {children}
      <p>{text}</p>
    </div>
  );
}
ElementWithText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

export default ElementWithText;
