import { toQueryString } from '../../../../utils/url';
import signedFetch from '../util/signedFetch';

// eslint-disable-next-line func-names
export default function*(params) {
  return yield signedFetch(
    'getGradebookProducts',
    `${__API_BASE__}/gradebook/org/${params.orgId}/class/${params.classId}/gradebook-products${toQueryString({
      'product-ids': encodeURIComponent(params.productIds.join(','))
    })}`
  );
}
