import * as t from '../../actionTypes';

const requestStatusOptions = { success: [], isLoading: true, error: {} };
const successStatusOptions = { success: [], isLoading: false, error: {} };
const failureStatusOptions = { success: [], isLoading: false, error: {} };

const initialState = {
  isLoading: false,
  error: {},
  success: []
};

function getExternalIds(state = initialState, action) {
  switch (action.type) {
    case t.CREATE_PRODUCT_GET_EXTERNAL_IDS:
      return {
        ...state,
        ...requestStatusOptions,
        error: {}
      };
    case t.CREATE_PRODUCT_EXTERNAL_IDS_SUCCESS:
      return { ...state, ...successStatusOptions, success: action.data.success, error: {} };
    case t.CREATE_PRODUCT_EXTERNAL_IDS_FAILURE:
      return { ...state, ...failureStatusOptions, error: action.data, success: [] };
    default:
      return state;
  }
}

export default getExternalIds;
