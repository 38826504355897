import PropTypes from 'prop-types';
import moment from 'moment';
import { isLevelsSortKey, parseLevelSortKey } from './Levels';

const sortRecordsByField = (field, direction) => (recordA, recordB) => {
  const [fieldA = '', fieldB = ''] = [recordA[field], recordB[field]];

  if (field === 'lastAccessed' || field === 'lastOpened') {
    const dateA = moment(fieldA).valueOf() || 0;
    const dateB = moment(fieldB).valueOf() || 0;
    return (dateA - dateB) * (direction === 'desc' ? 1 : -1);
  }
  return (recordA[field].toLowerCase() > recordB[field].toLowerCase() ? 1 : -1) * (direction === 'desc' ? -1 : 1);
};

const sortRecordsByLevel = (level, field, direction, isActivityLevel) => (recordA, recordB) => {
  let formatA;
  let formatB;
  let recordFieldNumerator;
  let recordFieldDenominator;

  if (level === 'TOTAL') {
    // Sort by level=TOTAL, field=SCORE
    if (field === 'SCORE') {
      formatA = recordA.totalLevelsScore / recordA.totalLevelsScoreAvailable || 0;
      formatB = recordB.totalLevelsScore / recordB.totalLevelsScoreAvailable || 0;
      return (formatA - formatB) * (direction === 'desc' ? -1 : 1);
    }

    // Sort by level=TOTAL, field=COMPLETED
    if (isActivityLevel) {
      formatA = recordA.numberOfCompletedActivities / recordA.totalNumberOfActivities || 0;
      formatB = recordB.numberOfCompletedActivities / recordB.totalNumberOfActivities || 0;
    } else {
      formatA = recordA.totalLevelsCompleted / recordA.totalNumberOfActivities || 0;
      formatB = recordB.totalLevelsCompleted / recordB.totalNumberOfActivities || 0;
    }
    return (formatA - formatB) * (direction === 'desc' ? -1 : 1);
  }

  if (isActivityLevel && field === 'COMPLETED') {
    formatA = recordA.levels[level].totalNumberOfAttempts;
    formatB = recordB.levels[level].totalNumberOfAttempts;
    return (formatA - formatB) * (direction === 'desc' ? -1 : 1);
  }

  if (field === 'SCORE' && !isActivityLevel) {
    recordFieldNumerator = 'levelScoreAchieved';
    recordFieldDenominator = 'levelScoreAvailable';
  } else if (field === 'SCORE' && isActivityLevel) {
    recordFieldNumerator = 'totalScoreAchieved';
    recordFieldDenominator = 'totalScoreAvailable';
  } else {
    recordFieldNumerator = 'submittedNumberOfActivities';
    recordFieldDenominator = 'totalNumberOfActivities';
  }

  formatA = recordA.levels[level][recordFieldNumerator] / recordA.levels[level][recordFieldDenominator] || 0;
  formatB = recordB.levels[level][recordFieldNumerator] / recordB.levels[level][recordFieldDenominator] || 0;
  return (formatA - formatB) * (direction === 'desc' ? -1 : 1);
};

export const getSortedRecords = (records, sortKey, direction, isActivityLevel) => {
  if (sortKey === 'none' || direction === 'none') {
    return records;
  }

  if (isLevelsSortKey(sortKey)) {
    const { level, field } = parseLevelSortKey(sortKey);
    return records.sort(sortRecordsByLevel(level, field, direction, isActivityLevel));
  }
  return records.sort(sortRecordsByField(sortKey, direction));
};

function SortRecords({ records, sortKey = 'none', direction = 'none', children, isActivityLevel = false }) {
  return children(getSortedRecords(records, sortKey, direction, isActivityLevel));
}
SortRecords.propTypes = {
  records: PropTypes.array.isRequired,
  sortKey: PropTypes.string,
  direction: PropTypes.string,
  children: PropTypes.func.isRequired,
  isActivityLevel: PropTypes.bool
};

export default SortRecords;
