import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Component imports
import PanelHeading from '../../../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../components/PopoutNavFooter/PopoutNavFooter.js';

import TextInput from '../../../../../components/TextInput/TextInput.js';
import Validation from '../../../../../components/Validation/Validation.js';

import {
  classCodeOnBlur,
  setClassCodeValue,
  submitForm
} from '../../../../../redux/reducers/studentRequestToJoinAClass.reducer.js';

function JoinAClassInputting(props) {
  const {
    // Input Values
    classCodeValue,
    classCodeIsValid,
    classCodeIsInvalid,
    submitFormAction,
    setClassCode,
    classCodeOnBlurAction,
    closePanel,
    localizedContent: { studentRequestToJoinAClass: content }
  } = props;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={content.close_panel_text} action={closePanel} />
            </div>
            <PanelHeading title={content.pageHeading} subtitle={content.pageSubheading} />
          </div>
        }
        footerContent={
          <PopoutNavFooter
            nextButtonText={content.button_next_text}
            nextAction={submitFormAction}
            nextButtonDisabled={!classCodeIsValid}
          />
        }
      >
        <div style={{ margin: '1rem' }}>
          <Validation
            isValid={classCodeIsValid}
            isError={classCodeIsInvalid}
            message={classCodeIsInvalid ? content.invalid_class_code_error : ''}
            forId="classcode"
          >
            <TextInput
              label={content.input_class_code_label}
              id="classcode"
              name="classcode"
              maxLength={36}
              placeholder=" "
              value={classCodeValue}
              onChange={setClassCode}
              onBlur={classCodeOnBlurAction}
            />
          </Validation>
        </div>
      </ScrollContainer>
    </form>
  );
}

JoinAClassInputting.propTypes = {
  classCodeValue: PropTypes.string,
  classCodeIsValid: PropTypes.bool,
  classCodeIsInvalid: PropTypes.bool,
  submitFormAction: PropTypes.func.isRequired,
  setClassCode: PropTypes.func.isRequired,
  classCodeOnBlurAction: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default connect(
  state => ({
    // Form inputs
    classCodeValue: state.studentRequestToJoinAClass.classCodeValue,
    classCodeIsValid: state.studentRequestToJoinAClass.classCodeIsValid,
    classCodeIsInvalid: state.studentRequestToJoinAClass.classCodeIsInvalid
  }),
  {
    // Validated input onBlurs
    classCodeOnBlurAction: classCodeOnBlur,
    // Input onChange actions
    setClassCode: setClassCodeValue,

    // Submit button
    submitFormAction: submitForm
  }
)(JoinAClassInputting);
