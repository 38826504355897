import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

import retrieveLTIConsumer from './retrieveLTIConsumer';

function* root() {
  yield takeLatest(t.NGS_RETRIEVE_LTI_CONSUMER_REQUEST, ({ payload: { content } }) => retrieveLTIConsumer(content));
}

export default root;
