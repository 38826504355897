import { USERNAME_MAX_SEGMENT_LENGTH } from '../globals/appSettings';

/**
 * Formats first/last name parts and removes special characters not valid in username context
 *
 * @param {string} firstName
 * @param {string} lastName
 *
 * @return {array} [firstName, lastName]
 */
export function formatUsernameSegments(firstName = '', lastName = '') {
  return [firstName, lastName].map(segment =>
    segment
      .replace(/[^\w]/g, '')
      .slice(0, USERNAME_MAX_SEGMENT_LENGTH)
      .toLowerCase()
  );
}

/**
 * Create a new username string from a supplied first name and last name.
 *
 * @param {string} firstName
 * @param {string} lastName
 *
 * @return {string}
 */
function buildUsername(firstName = '', lastName = '') {
  return formatUsernameSegments(firstName, lastName).join('.');
}

export default buildUsername;
