function getLeafsList(tree) {
  const content = [];
  function getAllLeafs(subtree) {
    const nodes = Object.values(subtree);

    nodes.forEach(node => {
      if (node.isActivity && node.isClickable) {
        content.push({
          id: node.id,
          order: node.order,
          parentId: node.parentId,
          printView: node.printView,
          title: node.title,
          type: node.type,
          url: node.url
        });
      }
      if (node.activities) {
        getAllLeafs(node.activities);
      }
      if (node.children) {
        getAllLeafs(node.children);
      }
    });
  }
  getAllLeafs(tree.children);
  return content;
}

function findNodeById(tree, id) {
  const nodes = Object.values(tree);
  const nodesLen = nodes.length;

  for (let i = 0; i < nodesLen; i += 1) {
    const node = nodes[i];

    if (node.id === id) {
      return node;
    }

    if (node.children && Object.keys(node.children).length > 0) {
      const foundInChildren = findNodeById(node.children, id);
      if (foundInChildren) {
        return foundInChildren;
      }
    }

    const activitiesLen = node.activities?.length;
    // The activities are always at the tree's leaf level.
    if (node.activities && activitiesLen > 0) {
      for (let j = 0; j < activitiesLen; j += 1) {
        const activity = node.activities[j];
        if (activity.id === id) {
          return activity;
        }
      }
    }
  }

  return null;
}

function getAncestorsIds(tree, node, ancestors = []) {
  if (!node) {
    return ancestors;
  }

  ancestors.unshift(node.id);

  if (node.parentId) {
    const parentNode = findNodeById(tree, node.parentId);
    getAncestorsIds(tree, parentNode, ancestors);
  }

  return ancestors;
}

function invokeActionOnNodes(node, callbackFct) {
  callbackFct(node);

  if (node.children && Object.keys(node.children).length > 0) {
    Object.values(node.children).forEach(subNode => invokeActionOnNodes(subNode, callbackFct));
  }

  if (node.activities && node.activities.length > 0) {
    node.activities.forEach(activity => invokeActionOnNodes(activity, callbackFct));
  }
}

export { getLeafsList, getAncestorsIds, findNodeById, invokeActionOnNodes };
