import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Children } from 'react';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './ManagedUserAuthLayout.scss';

export function Banner({ title, detail }) {
  return (
    <article className={styles.banner}>
      <h2 className={styles.bannerHeading}>{title}</h2>
      <p>{detail}</p>
    </article>
  );
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  detail: PropTypes.string
};

export function Footer({ actionLabel, actionHref, onActionClick, actionDisabled = false, children }) {
  return (
    <div className={styles.footer}>
      <input type="checkbox" id="donotcache" name="donotcache" value="1" style={{ display: 'none' }} />
      {!actionHref && onActionClick ? (
        <button
          type="submit"
          id="signin-button"
          name="_eventId_proceed"
          className={actionDisabled ? classNames(styles.footerAction, styles.disabled) : styles.footerAction}
          onClick={onActionClick}
        >
          {actionLabel}
        </button>
      ) : null}
      {!onActionClick && actionHref ? (
        <a className={styles.footerAction} href={actionHref} disabled={actionDisabled}>
          {actionLabel}
        </a>
      ) : null}
      {children ? <div className={styles.footerContent}>{children}</div> : null}
    </div>
  );
}

Footer.propTypes = {
  actionLabel: PropTypes.string,
  actionHref: PropTypes.string,
  onActionClick: PropTypes.func,
  actionDisabled: PropTypes.bool,
  children: PropTypes.node
};

// TODO: use a specific domain.
const returnToDashboard = () => {
  window.parent.postMessage('goToDashboard', '*');
};

/**
 * A page layout for standalone managed user authentication views. Can contain
 * any child content, optionally including a `Banner` or `Footer`.
 */
function ManagedUserAuthLayout({ content, narrow = false, children, hideBackButton, platform }) {
  return (
    <div className={styles.background}>
      {platform === 'ereader' ? (
        <div
          className={hideBackButton ? styles.hidden : classNames(styles.back, styles.link)}
          role="button"
          tabIndex="0"
          onClick={returnToDashboard}
          onKeyPress={returnToDashboard}
        >
          <SVGIcon className={styles.backIcon} glyph={GLYPHS.ICON_ARROW_BACK} />
          {content.back_to_dashboard_link_text}
        </div>
      ) : (
        ''
      )}
      <main className={classNames(styles.panel, { [styles.panelNarrow]: narrow })}>
        <div className={styles.header}>
          <SVGIcon className={styles.headerIcon} glyph={GLYPHS.ICON_SMILEY_HAPPY} />
        </div>
        <div className={styles.content}>
          <h1 className={styles.heading}>{content.title}</h1>
          {Children.toArray(children).filter(child => child.type !== Footer)}
        </div>
        {Children.toArray(children).filter(child => child.type === Footer)}
      </main>
      <footer>
        <SVGIcon className={styles.brand} glyph={GLYPHS.OUP_LOGO_WHITE} />
      </footer>
    </div>
  );
}

ManagedUserAuthLayout.propTypes = {
  content: PropTypes.object.isRequired,
  narrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hideBackButton: PropTypes.bool,
  platform: PropTypes.string
};

export default ManagedUserAuthLayout;
