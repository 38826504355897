import * as t from '../actionTypes';

const requestStatusOptions = { isLoading: true, success: false, error: false };
const successStatusOptions = { isLoading: false, success: true, error: false };
const failureStatusOptions = { isLoading: false, success: false, error: true };

export const initialState = {
  isLoading: false
};
export default function bulkPasswordReset(state = initialState, { type }) {
  switch (type) {
    case t.BULK_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        ...requestStatusOptions
      };
    case t.BULK_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        ...successStatusOptions
      };
    case t.BULK_PASSWORD_RESET_ERROR:
      return { ...state, ...failureStatusOptions };
    case t.BULK_PASSWORD_RESET_STATE_RESET:
      return { ...initialState };
    default:
      return state;
  }
}
