import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import LookupProducts from '../../../../components/LookupProducts/LookupProducts';
import Card from '../../../../components/Card/Card';
import TextInput from '../../../../components/TextInput/TextInput';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import FormCalendar from '../../../../components/FormCalendar/FormCalendar';
import IteratorPagination from '../IteratorPagination/IteratorPagination';
import useIteratorPagination from '../IteratorPagination/useIteratorPagination';
import colors from '../../../../globals/colors';
import styles from './ActivationCodeBatchSearch.scss';

import {
  searchForActCodeBatch,
  stopSearchForActCodeBatch,
  emptySearchResultsForActCodeBatch,
  downloadCodeBatch
} from '../../../../redux/actions/activationCodeBatchDashboard';
import ActCodeBatchSearchResult from './ActCodeBatchSearchResult';

function ActivationCodeBatchSearch({
  localizedContent: { activationCodeBatchSearch: content },
  searchBatch,
  stopSearch,
  downloadCodes,
  emptyResults,
  loading,
  results,
  total
}) {
  const [batchNameFilter, setBatchNameFilter] = useState('');
  const [productIdFilter, setProductIdFilter] = useState('');
  const [activationCodeFilter, setActivationCodeFilter] = useState('');
  const [validDateFilter, setValidDateFilter] = useState('');
  const [licenseTypeFilter, setLicenseTypeFilter] = useState('ANY');

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [searchMade, setSearchMade] = useState(false);
  const numberPerPage = 10;

  const [goToFirst] = useIteratorPagination();

  const setOverlay = param => {
    setIsOverlayOpen(param);
  };

  const [productSelectedType, setProductSelectedType] = useState(null);

  const search = query => {
    const request = {
      batchName: batchNameFilter,
      activationCode: activationCodeFilter,
      validDate: validDateFilter,
      numberPerPage,
      pagination: query || goToFirst(numberPerPage)
    };

    if (productSelectedType === 'productGroup') {
      request.productGroupId = productIdFilter;
    } else {
      request.productId = productIdFilter;
    }

    if (licenseTypeFilter !== 'ANY') {
      request.licenseType = licenseTypeFilter;
    }

    searchBatch(request);
    setSearchMade(true);
  };

  const resetForm = () => {
    setBatchNameFilter('');
    setProductIdFilter('');
    setActivationCodeFilter('');
    setLicenseTypeFilter('');
    setValidDateFilter('');
    setLicenseTypeFilter('ANY');
    stopSearch();
    emptyResults();
    setOverlay(false);

    const elements = document.getElementsByTagName('input');
    Array.from(elements).forEach(e => {
      e.value = '';
    });

    setSearchMade(false);
  };

  const isValidSearch = () => !!batchNameFilter || !!productIdFilter || !!activationCodeFilter;

  const renderLoading = () => {
    if (loading) {
      return (
        <div>
          <LoadingSpinner />
        </div>
      );
    }
    return <div />;
  };

  const renderForm = () => (
    <div className={styles.searchForm}>
      <div className={styles.searchCriteriaContainer}>
        <Card headingText={content.enter} headingTag="h2" showHeadingLabel headingColor={colors.ARCHIVED}>
          <div className={styles.cardRow}>
            <span>{content.batch_name}</span>
            <TextInput
              labelHidden
              disableAutoComplete
              onChange={e => setBatchNameFilter(e)}
              value={batchNameFilter}
              id="batch_name"
              name="batch_name"
              placeholder=""
              label=""
            />
          </div>

          <div className={styles.cardRow}>
            <span>{content.product}</span>
            <LookupProducts
              className={styles.flexDiv}
              productSelected={(id, productType) => {
                setProductSelectedType(productType);
                setProductIdFilter(id);
              }}
              productSelectedValue={productIdFilter}
              isOverlayOpen={isOverlayOpen}
              setIsOverlayOpen={setOverlay}
            />
          </div>

          <div className={styles.cardRow}>
            <span>{content.activation_code}</span>
            <TextInput
              labelHidden
              disableAutoComplete
              onChange={e => setActivationCodeFilter(e)}
              value={activationCodeFilter}
              id="activation_code"
              name="activation_code"
              placeholder=""
              label=""
            />
          </div>

          <div className={styles.cardRow}>
            <span>{content.valid_date}</span>
            <div>
              <FormCalendar
                dateFormat="YYYY-MM-DD"
                dateSelectedHandler={e => setValidDateFilter(e)}
                uniqueId="cal-valid-date"
              />
            </div>
          </div>

          <div className={styles.cardRow}>
            <span>{content.license_type}</span>
            <div>
              <select
                value={licenseTypeFilter}
                onChange={e => {
                  setLicenseTypeFilter(e.target.value);
                }}
              >
                <option value="ANY">{content.any}</option>
                <option value="CONCURRENT">{content.concurent}</option>
                <option value="USAGE">{content.usage}</option>
                <option value="ROLLING">{content.rolling}</option>
              </select>
            </div>
          </div>
        </Card>
      </div>
      <div className={styles.searchButtonsContainer}>
        <div>
          <Button
            type={buttonTypes.PRIMARY}
            text={content.search}
            onClick={() => search()}
            disabled={!isValidSearch()}
          />
          <Button type={buttonTypes.PRIMARY} text={content.reset} onClick={() => resetForm()} />
        </div>
      </div>
    </div>
  );

  const renderResults = () => {
    if (!loading && searchMade && results.length === 0) {
      return <p>No results found matching your criteria.</p>;
    }

    if (!loading && results.length)
      return (
        <ActCodeBatchSearchResult
          results={results}
          total={total}
          downloadBatchHandler={data => {
            downloadCodes(data);
          }}
        />
      );

    return <div />;
  };

  const renderPagination = () => {
    if (results.length) {
      return (
        <div>
          <div className={styles.paginationContainer}>
            <IteratorPagination
              paginationCallback={query => search(query)}
              items={results}
              numberPerPage={numberPerPage}
              idField="_id"
              lastPage={Math.ceil(total / numberPerPage)}
            />
          </div>
        </div>
      );
    }
    return <div />;
  };

  const renderInstructions = () => (
    <div>
      <div>
        <h1>{content.title}</h1>
      </div>
      <div>{content.facility}</div>
      <div>{content.facility2}</div>
      <ul className={styles.fieldsList}>
        <li>{content.batch_name}</li>
      </ul>
    </div>
  );

  return (
    <div className={styles.searchContainer}>
      <div>{renderInstructions()}</div>
      <div>{renderForm()}</div>
      <div>{renderLoading()}</div>
      <div className={styles.resultsContainer}>{renderResults()}</div>
      <div>{renderPagination()}</div>
    </div>
  );
}

ActivationCodeBatchSearch.propTypes = {
  localizedContent: PropTypes.object,
  searchBatch: PropTypes.func,
  stopSearch: PropTypes.func,
  downloadCodes: PropTypes.func,
  emptyResults: PropTypes.func,
  loading: PropTypes.any,
  results: PropTypes.any,
  total: PropTypes.any
};

const mapStateToProps = state => {
  const {
    activationCodeBatchDashboard: { loading, results, total }
  } = state;
  return { loading, results, total };
};

const mapDispatchToProps = dispatch => ({
  searchBatch: filters => {
    dispatch(searchForActCodeBatch(filters));
  },
  stopSearch: () => {
    dispatch(stopSearchForActCodeBatch());
  },
  emptyResults: () => {
    dispatch(emptySearchResultsForActCodeBatch());
  },
  downloadCodes: data => {
    dispatch(downloadCodeBatch(data));
  }
});

export default compose(
  withLocalizedContent('activationCodeBatchSearch'),
  connect(mapStateToProps, mapDispatchToProps)
)(ActivationCodeBatchSearch);
