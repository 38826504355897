import * as t from '../../actionTypes';

export const initialState = {
  courseContentsDetails: null,
  isSuccess: false
};

export default function courseContents(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_COURSE_CONTENTS_REQUEST:
      return {
        ...state
      };
    case t.GET_COURSE_CONTENTS_SUCCESS: {
      return {
        ...state,
        courseContentsDetails: payload,
        isSuccess: true
      };
    }
    case t.GET_COURSE_CONTENTS_FAILURE:
      return {
        ...state,
        courseContentsDetails: null,
        error: payload
      };
    default:
      return { ...state };
  }
}
