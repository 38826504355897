import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { trimStart, trimEnd } from 'lodash';
import { setFieldValue } from '../../../redux/reducers/data/fields.reducer.js';
import { setFieldValidity } from '../../../redux/reducers/data/forms.reducer.js';

class ConnectedField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // This stores the value to be compared against when reporting validity to the form
      initialValue: props.initialValue || '',
      // This stores the isInvalid value to be updated on blur
      isInvalid: props.negativeValidationFunction && props.negativeValidationFunction(props.initialValue || ''),
      // This value is used to guarantee that the latest async validation event is always used
      acceptedAsyncValidation: null
    };
  }

  componentWillMount() {
    const { field, initialise } = this.props;
    // Only set the initial value if no value exists yet
    if (!field) {
      initialise();
    }
  }

  onChange = (originalValue, secondaryValue) => {
    const {
      dontTrimValue,
      negativeValidationFunction,
      positiveValidationFunction,
      initialValueIsValid,
      asyncValidationFunction,
      setValue,
      setFormFieldValidity,
      getValidationMessage,
      reportErrorImmediately
    } = this.props;

    const { initialValue } = this.state;
    // Clear any pending async validation
    this.setState({ acceptedAsyncValidation: null });

    let valueToShow = originalValue;
    let valueToValidate = originalValue;
    let secondaryValueToValidate = secondaryValue;

    // Trim the value if it is a string input
    if (!dontTrimValue && typeof originalValue === 'string') {
      valueToShow = trimStart(originalValue);
      valueToValidate = trimEnd(valueToShow);
    }
    if (secondaryValue && !dontTrimValue && typeof secondaryValue === 'string') {
      secondaryValueToValidate = trimStart(trimEnd(secondaryValue));
    }

    const hasChanged = valueToValidate !== initialValue;
    const isInvalid = negativeValidationFunction
      ? negativeValidationFunction(valueToValidate, secondaryValueToValidate)
      : null;

    // Only check isValid if not already deemed invalid and the value has changed
    const isValid =
      positiveValidationFunction && !isInvalid && (initialValueIsValid || hasChanged)
        ? positiveValidationFunction(valueToValidate, secondaryValueToValidate)
        : null;

    // If the value passed non-async validation and an async validation function is given then we trigger that
    if (isValid && asyncValidationFunction) {
      // Mark the field as invalid but with the isWaiting flag
      this.setState({ isInvalid: false });
      setValue(valueToShow, null, false, false, false, true);
      setFormFieldValidity(false, true, hasChanged);
      this.handleAsyncValidation(valueToValidate, valueToShow, hasChanged);
    } else {
      const message = getValidationMessage ? getValidationMessage(valueToValidate, secondaryValueToValidate) : null;

      if (!reportErrorImmediately) {
        this.setState({ isInvalid });
      }
      setValue(valueToShow, message, isValid, reportErrorImmediately && isInvalid);

      // if (field.isValid !== newIsValid) {
      setFormFieldValidity(isValid, isInvalid, hasChanged);
      // }
    }
  };

  onBlur = () => {
    const { isInvalid } = this.state;
    const { field, getValidationMessage, setValue } = this.props;
    if (isInvalid) {
      setValue(
        field.value,
        field.message || (getValidationMessage ? getValidationMessage(field.value) : null),
        false,
        true
      );
    }
  };

  onPaste = event => {
    const { pasteErrorMessage, setValue, field } = this.props;
    if (pasteErrorMessage) {
      event.stopPropagation();
      event.preventDefault();
      setValue(field.value, pasteErrorMessage, false, true);
    }
  };

  handleAsyncValidation = (valueToValidate, valueToShow, hasChanged) => {
    const { asyncValidationFunction, reportErrorImmediately, setValue, setFormFieldValidity } = this.props;
    const { acceptedAsyncValidation } = this.state;

    // Use the current timestamp as an ID for this async request in order to prevent it from being applied
    // incorrectly in place of a later and more up to date request
    const timestamp = Date.now();
    this.setState({ acceptedAsyncValidation: timestamp });

    asyncValidationFunction(valueToValidate).then(({ isValid, isInvalid, isWarning, message }) => {
      // Check that this validation is still valid
      if (acceptedAsyncValidation === timestamp) {
        if (!reportErrorImmediately) {
          this.setState({ isInvalid });
        }

        setValue(valueToShow, message, isValid, reportErrorImmediately && isInvalid, isWarning, false);

        setFormFieldValidity(isValid, isInvalid, hasChanged, isWarning);
      }
    });
  };

  render() {
    const { field, render, fieldName } = this.props;
    return field && render
      ? render(
          fieldName,
          field.value,
          this.onChange,
          this.onBlur,
          field.message,
          field.isValid,
          field.isInvalid,
          this.onPaste,
          field.isWaiting,
          field.isWarning
        )
      : null;
  }
}

ConnectedField.propTypes = {
  // Props sourced values
  formName: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  fieldName: PropTypes.string.isRequired,
  initialValue: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  initialValueIsValid: PropTypes.bool,
  render: PropTypes.func,

  // Validation functions
  dontTrimValue: PropTypes.bool,
  reportErrorImmediately: PropTypes.bool,
  positiveValidationFunction: PropTypes.func,
  negativeValidationFunction: PropTypes.func,
  getValidationMessage: PropTypes.func,
  pasteErrorMessage: PropTypes.string,
  asyncValidationFunction: PropTypes.func,

  // State sourced values
  field: PropTypes.shape({
    value: PropTypes.oneOf([PropTypes.string, PropTypes.bool]).isRequired,
    message: PropTypes.string,
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    isWarning: PropTypes.bool,
    isWaiting: PropTypes.bool
  }),

  // Actions
  initialise: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  setFormFieldValidity: PropTypes.func.isRequired
};

// Extract the state of the current form instance
export default connect(
  (state, { formName, fieldName }) => ({
    field: state.fields[`${formName}.${fieldName}`]
  }),
  (dispatch, { formName, fieldName, initialValue }) => ({
    initialise: () => dispatch(setFieldValue(formName, fieldName, initialValue)),
    setValue: (...args) => dispatch(setFieldValue(formName, fieldName, ...args)),
    setFormFieldValidity: (...args) => dispatch(setFieldValidity(formName, fieldName, ...args))
  })
)(ConnectedField);
