import moment from 'moment';
import { completeLogout } from '../../../appFunctions/authorisedFunctions/auth/handleLogout.saga.js';
import { isEmbeddedInIframe } from '../../../../../utils/platform';
import session from '../../../../../utils/session';

export default function* getStoredCredentials() {
  console.log('[getStoredCredentials] Attempting to get stored credentials');

  let credentials;

  if (session.exists()) {
    console.log('[getStoredCredentials] Found value in storage');
    credentials = session.get();

    if (!isEmbeddedInIframe()) {
      const systemClockOffset = localStorage.getItem('systemClockOffset');
      const serverTimeNow = moment.utc().add(systemClockOffset, 'milliseconds');
      console.log(
        `[getStoredCredentials] systemClockOffset :${systemClockOffset}, serverTimeNow :${serverTimeNow.toString()}`
      );

      // If nothing is passed to moment#isBefore, it will default to the current time.
      // Use current servertime(not localtime) when comparing to credentials expiryTime(a server time).
      // An issue not likely to be a concern in this case, but worth a mention:- https://momentjscom.readthedocs.io/en/latest/moment/05-query/01-is-before/
      if (
        moment(credentials.expiryTime)
          .subtract(1, 'minutes')
          .isBefore(serverTimeNow)
      ) {
        console.log(
          '[getStoredCredentials] Credentials have expired or will expire in the next 1 minutes so force a logout to clear up session'
        );
        yield completeLogout();
      }
    }
  }

  // Return true if found
  return credentials;
}
