import { put, select } from 'redux-saga/effects';
import editSchema from '../../../../../../static/schema/draft-4/edit-managed-user-request-body-schema.json';
import updateSchema from '../../../../../../static/schema/draft-4/update-managed-user-request-body-schema.json';
import {
  setManagedUserEditFailure,
  setManagedUserPasswordFailure,
  setManagedUserEditSuccess,
  setYearGroupRequired
} from '../../../../actions/managedUserEdit';
import { MANAGED_USER_EDIT_VALIDATE_SET_RESULT } from '../../../../actionTypes';
import signedFetch from '../../../apiCalls/util/signedFetch';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import { setUserDetails } from '../../../../reducers/user/userProfile.reducer.js';
import { pollOrgUsersUpdated } from '../dataRecency/pollOrgUsers';
import getManagedUserDetails from '../../../apiCalls/users/getManagedUserDetails.api.js';
/**
 * Validate and submit a request to update a managed user record.
 *
 * @param {string} orgId
 * @param {string} userId
 * @param {Object} user
 * @param {Array} fields
 *
 * @yield {Object} The next Redux effect description
 */

function* submit(orgId, userId, user, fields) {
  try {
    const putManagedUserApi = yield select(state => state.managedUserEdit.putManagedUserApi);
    const dataToBeValidated = putManagedUserApi ? { data: user, fields } : user;
    const schema = putManagedUserApi ? updateSchema : editSchema;

    if (!(yield* validateInputUsingSchema(schema, MANAGED_USER_EDIT_VALIDATE_SET_RESULT, dataToBeValidated))) {
      return;
    }

    if (putManagedUserApi && fields && fields.includes('editableUserName')) {
      const index = fields.indexOf('editableUserName');
      fields[index] = 'username';
    }

    const response = putManagedUserApi
      ? yield signedFetch('UpdateManagedUser', `${__API_BASE__}/ngs/org/${orgId}/managed-user/${userId}`, 'PUT', {
          data: user,
          fields
        })
      : yield signedFetch('editManagedUser', `${__API_BASE__}/org/${orgId}/managed-user/${userId}`, 'PUT', user);

    if (response.status === 'success') {
      let managedUserDetails = {};
      if (putManagedUserApi) {
        const userData = yield getManagedUserDetails(orgId, [userId], true);
        managedUserDetails = userData.data.successUsers[0];
      }
      yield put(setUserDetails(user, managedUserDetails));
      yield put(setManagedUserEditSuccess());
      if (!putManagedUserApi) {
        yield pollOrgUsersUpdated(userId);
      }
    } else if (response.message.indexOf('1044') !== -1) {
      yield put(setManagedUserPasswordFailure(true));
    } else if (response.message.indexOf('yearGroup is required') !== -1) {
      yield put(setYearGroupRequired(true));
    } else {
      throw new Error(response.errorMessages);
    }
  } catch (e) {
    yield put(setManagedUserEditFailure({ errors: { message: e } }));
  }
}

export default submit;
