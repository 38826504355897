import { put } from 'redux-saga/effects';
import { getClassesAndStudentsSuccess, getClassesAndStudentsFailure } from '../../../../actions/ngsCourse';
import getClassesAndStudentsApiCall from '../../../apiCalls/ngs/getClassesAndStudents';

function* getClassesAndStudents(orgId) {
  const response = yield getClassesAndStudentsApiCall(orgId);

  if (response.status === 'success') {
    yield put(getClassesAndStudentsSuccess(response.data));
    return;
  }

  yield put(getClassesAndStudentsFailure(response.message));
}

export default getClassesAndStudents;
