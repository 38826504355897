import { select, put } from 'redux-saga/effects';

import bulkUsersUploadApi from '../../../../apiCalls/bulkUsersUploadApi.js';
import { bulkShowConfirmation, showErrorReview } from '../../../../../reducers/enrolUser.reducer.js';
import { getCurrentPlatform } from '../../../../../../utils/platform.js';

export default function* bulkUsersUploadRequest() {
  console.log('[bulkUsersUpload Saga] Uploading file contents...');
  const { orgId, fileContents, fileName, fileType } = yield select(state => ({
    orgId: state.organisationPage.orgId,
    fileContents: state.enrolUser.file.data,
    fileName: state.enrolUser.file.name,
    fileType: state.enrolUser.file.type
  }));

  const result = yield bulkUsersUploadApi(orgId, {
    orgId,
    fileContents,
    fileName,
    fileType,
    platformCode: getCurrentPlatform()
  });

  if (result.status === 'success') {
    console.log('[bulkUsersUpload Saga] Showing confirmation screen');
    yield put(bulkShowConfirmation());
  } else {
    const errors = Object.entries(result.data).map(error => ({
      row: error[0],
      cmsErrorKeys: error[1]
    }));
    yield put(showErrorReview(errors));
  }
}
