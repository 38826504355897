import * as t from '../actionTypes';

export const homeworkRequest = payload => ({
  type: t.NGS_COURSE_HOMEWORK_REQUEST,
  payload
});

export const homeworkRequestSuccess = payload => ({
  type: t.NGS_COURSE_HOMEWORK_SUCCESS,
  payload
});

export const homeworkRequestFailure = payload => ({
  type: t.NGS_COURSE_HOMEWORK_FAILURE,
  payload
});
