import * as t from '../actionTypes';

export const csvUserUploadFileParseRequest = payload => ({
  type: t.CSVUSER_DATA_UPLOAD_PARSE_FILE_REQUEST,
  payload
});

export const csvUserUploadFileParseCompleted = payload => ({
  type: t.CSVUSER_DATA_UPLOAD_PARSE_FILE_COMPLETED,
  payload
});

export const csvUserUploadFileParseFailure = (type, reason) => ({
  type: t.CSVUSER_DATA_UPLOAD_PARSE_FILE_FAILURE,
  payload: { type, reason }
});

export const csvUserUpload = payload => ({
  type: t.CSVUSER_DATA_UPLOAD_START_UPLOAD,
  payload
});

export const csvUserInputUpdate = payload => ({
  type: t.CSVUSER_INPUT_UPDATE,
  payload
});

export const csvUserUploadSuccess = payload => ({
  type: t.CSVUSER_DATA_UPLOAD_SUCCESS,
  payload
});

export const csvUserUploadFailure = payload => ({
  type: t.CSVUSER_DATA_UPLOAD_FAILURE,
  payload
});

export const csvUserUploadReset = () => ({
  type: t.CSVUSER_DATA_UPLOAD_INIT
});
