import { put } from 'redux-saga/effects';
import { generateUsernameSuccess, generateUsernameFailure } from '../../../../reducers/enrolUser.reducer';
import generateUsernameApi from '../../../apiCalls/users/generateUsername.api';

function* generateUsername({ payload }) {
  const { orgId = '', firstName = '', lastName = '' } = payload;
  const response = yield generateUsernameApi(orgId, firstName, lastName);

  if (response.status.toLowerCase() === 'success') {
    yield put(generateUsernameSuccess(response.data));
    return;
  }
  const errorMsg = Object.values(response.message);
  yield put(generateUsernameFailure(errorMsg));
}

export default generateUsername;
