import dot from 'dot-object';
import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../Button/Button';
import Card from '../Card/Card';
import ControlledForm from '../ControlledForm/ControlledForm';
import PageHeading from '../PageHeading/PageHeading';
import TextInput from '../TextInput/TextInput';
import ValidationStatus, { validationStatusCodes } from '../ValidationStatus/ValidationStatus';
import styles from './RegisterOrgAdmin.scss';

function RegisterOrgAdmin({
  localizedContent: { registerOrgAdminComponent: content },
  firstName = '',
  lastName = '',
  email = '',
  emailLoading,
  emailUnavailable,
  errors = {},
  createChangeHandler = () => {},
  createBlurHandler = () => {},
  onSubmit = () => {},
  onSkipStep = () => {}
}) {
  const errorValues = Object.values(dot.dot(errors));
  const isSubmitDisabled = !errorValues.length || errorValues.some(Boolean) || emailLoading;
  return (
    <div className="grid pad-top2 pad-bot4">
      <div className="row gin-bot3">
        <div className="col sm10 pad-bot2">
          <PageHeading title={content.title} subtitle={content.subtitle} />
        </div>
        <div className="col md8 pad-bot2">
          <Card headingText={content.header} headingColor={colors.PROFILE}>
            <ControlledForm>
              <ValidationStatus forId="firstName" isActive={errors.firstName} message={content.firstname_error}>
                <TextInput
                  id="firstName"
                  label={content.firstname_label}
                  placeholder={content.firstname_placeholder}
                  value={firstName}
                  onChange={createChangeHandler('firstName')}
                  onBlur={createBlurHandler('firstName')}
                />
              </ValidationStatus>
              <ValidationStatus forId="lastName" isActive={errors.lastName} message={content.lastname_error}>
                <TextInput
                  id="lastName"
                  label={content.lastname_label}
                  placeholder={content.lastname_placeholder}
                  value={lastName}
                  onChange={createChangeHandler('lastName')}
                  onBlur={createBlurHandler('lastName')}
                />
              </ValidationStatus>
              <div>
                <ValidationStatus
                  forId="email"
                  {...(emailLoading
                    ? {
                        type: validationStatusCodes.WAITING_STATUS,
                        isActive: true
                      }
                    : {
                        isActive: errors.email || emailUnavailable,
                        message: emailUnavailable ? content.email_unavailable : content.email_error
                      })}
                >
                  <TextInput
                    id="email"
                    label={content.email_label}
                    placeholder={content.email_placeholder}
                    value={email}
                    onChange={createChangeHandler('email')}
                    onBlur={createBlurHandler('email')}
                  />
                </ValidationStatus>
                <p className="gin-top1">{content.email_description}</p>
              </div>
            </ControlledForm>
            <p className="gin-top1">{content.email_caption}</p>
          </Card>
        </div>
        <div className="col md4">
          <Card headingText={content.aside_header} headingColor={colors.DASHBOARD}>
            <h1 className={styles.asideHeading}>{content.aside_heading}</h1>
            <p>{content.aside_content}</p>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div>
            <Button
              customClassName="gin-right2 gin-bot2"
              type={buttonTypes.PRIMARY}
              text={content.submit_button}
              onClick={onSubmit}
              disabled={isSubmitDisabled}
            />
            <Button
              customClassName="gin-right2 gin-bot2"
              type={buttonTypes.SECONDARY}
              text={content.skip_step_button}
              onClick={onSkipStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

RegisterOrgAdmin.propTypes = {
  localizedContent: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  emailLoading: PropTypes.bool,
  emailUnavailable: PropTypes.bool,
  errors: PropTypes.object,
  createChangeHandler: PropTypes.func,
  createBlurHandler: PropTypes.func,
  onSubmit: PropTypes.func,
  onSkipStep: PropTypes.func
};

export default withLocalizedContent('registerOrgAdminComponent')(RegisterOrgAdmin);
