import * as t from '../actionTypes';

const initialState = {
  loading: false,
  success: false,
  error: false,
  publishedCourseData: null,
  results: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.GET_COURSE_PUBLISH_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        results: []
      };
    case t.GET_COURSE_PUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        results: payload,
        success: true,
        error: false
      };
    case t.GET_COURSE_PUBLISH_FAILURE:
      return {
        ...state,
        loading: false,
        results: [],
        success: false,
        error: payload
      };
    case t.CLEAR_PUBLISHED_COURSE_DATA:
      return {
        ...state,
        publishedCourseData: null
      };
    case t.PUBLISH_COURSE_REQUEST:
      return {
        ...state
      };
    case t.PUBLISH_COURSE_SUCCESS:
      return {
        ...state,
        publishedCourseData: payload
      };
    case t.PUBLISH_COURSE_FAILURE:
      return {
        ...state,
        publishedCourseData: null,
        error: payload
      };
    default:
      return {
        ...state
      };
  }
};
