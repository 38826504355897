import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { groupBy, map } from 'lodash';
import classnames from 'classnames';

import style from './StudentProgress.scss';

import withLocalizedContent from '../../language/withLocalizedContent';
import RelativeDate from '../RelativeDate/RelativeDate';
import StudentProgressBookReadItem from '../StudentProgressBookReadItem/StudentProgressBookReadItem';
import StudentProgressWiderReadingItem from '../StudentProgressWiderReadingItem/StudentProgressWiderReadingItem';
import StudentProgressLevelChangeItem from '../StudentProgressLevelChangeItem/StudentProgressLevelChangeItem';

function prepareItems(items) {
  const currentTime = moment();
  const additionalItems = [];

  items
    .filter(({ type }) => type === 'book_read')
    .forEach(bookRead => {
      if (bookRead.attempts.length > 1) {
        // generate book_read items from attempts (ignore first attempt - because it is already latest one)
        bookRead.attempts.slice(1).forEach((attempt, index) => {
          additionalItems.push({
            ...bookRead,
            date: attempt.date,
            attempts: bookRead.attempts.slice(index + 1)
          });
        });
      }
    });

  const itemsGroupedByDate = groupBy([...items, ...additionalItems], item =>
    currentTime.diff(moment(item.date), 'days')
  );

  // eslint-disable-next-line no-shadow
  return map(itemsGroupedByDate, (items, daysDiff) => ({ daysDiff: parseInt(daysDiff, 10), items }));
}

function renderTimelineMessage(item, index) {
  if (!item.date && !item.message) return null;

  return (
    <div className={classnames('avoid-print-break', style.timelineHeaderWrapper)}>
      <p className={style.timelineHeader} key={index}>
        {item.message ? item.message : <RelativeDate date={item.date} />}
      </p>
    </div>
  );
}

class StudentProgress extends Component {
  renderItem = (item, index) => {
    const { openQuiz } = this.props;

    if (item.type === 'timeline_message') {
      return renderTimelineMessage(item, index);
    }
    if (item.type === 'book_read') {
      return <StudentProgressBookReadItem item={item} key={index} openQuiz={openQuiz} />;
    }
    if (item.type === 'book_feedback') {
      return <StudentProgressWiderReadingItem item={item} key={index} />;
    }
    if (item.type === 'level_change') {
      return <StudentProgressLevelChangeItem item={item} key={index} />;
    }
    return null;
  };

  renderGroup = (group, index, groups) => (
    <div className="row" key={index}>
      <div className={classnames('col', groups.length - 1 === index ? style.lastGroup : null)}>
        {group.items.sort((a, b) => moment(b.date) - moment(a.date)).map(this.renderItem)}
      </div>
    </div>
  );

  render() {
    const {
      localizedContent: { studentProgressComponent: content }
    } = this.props;
    const groups = [];

    const { items } = this.props;
    prepareItems(items)
      .sort((a, b) => a.daysDiff - b.daysDiff)
      .forEach((group, index) => {
        if (group.items.length > 1 || group.daysDiff <= 30 || index === 0) {
          groups.push({ items: [{ type: 'timeline_message', date: group.items[0].date }] });
        }

        groups.push(group);
      });

    return (
      <div>
        <h1 className={style.title}>{content.title}</h1>
        {groups.map(this.renderGroup)}
      </div>
    );
  }
}

StudentProgress.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  openQuiz: PropTypes.func.isRequired
};

export default withLocalizedContent('studentProgressComponent')(StudentProgress);
