module.exports = {
  ces: {
    // EPS pages hosted on {subdomain}.account.oup.com

    // CES (i.e. users, classes, orgs, licences)
    CES_BASE_URL: '',
    CES_SUPPORT_URL: '/support',
    CES_CONTACT_SUPPORT_URL: '/support/contact-us',
    CES_LIBRARY_URL: '/mySchool/library',
    CES_MY_CLASS_LIST_URL: '/myProfile/classes',
    CES_ADDLICENCES_URL: '/mySchool/library/addLicences',
    CES_ADDUSERS_URL: '/mySchool/students/addStudents',
    CES_ORGANISATION_URL: '/mySchool',
    CES_PASSWORD_RESET_URL: '/reset-password',
    CES_PROFILE_URL: '/myProfile',
    CES_LEARNING_MATERIAL_URL: '/myProfile/learningMaterial',
    CES_REGISTER_TOKEN_URL: '/register/',
    CES_REGISTRATION_URL: '/register',
    CES_SIGN_IN_URL: '/login',
    CES_TERMS_URL: '/support/articles/terms-and-conditions',
    CES_REGISTER_ORG_URL: '/org/register',
    CES_PARTIAL_REG_ORG_URL: '/org/{orgId}/complete-registration',
    CES_DECLINE_ORG_INVITE_TOKEN_URL: '/decline-invite/',
    ORB_CONTACT_US_URL: '/support/contact-us?product=ORB'
  },
  orb_unity: {
    // ORB pages hosted on {subdomain}.oxfordreadingbuddy.com

    ORB_UNITY_BASE_URL: '',
    ORB_TERMS_URL: '/terms-and-conditions',
    ORB_CONTACT_US_URL: '/contact-us'
  },
  orb_eps: {
    // ORB pages hosted on {subdomain}.oxfordreadingbuddy.oup.com

    ORB_EPS_BASE_URL: '',
    MY_CLASS_LIST_URL: '/myProfile/classes',
    ORB_PARTIAL_REG_ORG_URL: '/org/{orgId}/complete-registration'
  },
  olb: {
    // OLB pages hosted on {subdomain}.oxfordlearnersbookshelf.com

    OLB_BASE_URL: ''
  },
  oald: {
    OALD_BASE_URL: ''
  },
  elt: {
    ELT_BASE_URL: ''
  },
  auth0: {
    OIDC_IDP_URL: ''
  },
  ote: {
    // OTE pages hosted on {subdomain}.oxfordtestofenglish.oup.com
    OTE_BASE_URL: '',
    OTE_REGISTER_TOKEN_URL: '/register/',
    OTE_DECLINE_ORG_INVITE_TOKEN_URL: '/decline-invite/',
    OTE_PARTIAL_REG_ORG_URL: '/org/{orgId}/complete-registration'
  },
  opt: {
    // OPT pages hosted on {subdomain}.placement.edu.oup.com
    OPT_BASE_URL: '',
    OPT_REGISTER_TOKEN_URL: '/register/',
    OPT_DECLINE_ORG_INVITE_TOKEN_URL: '/decline-invite/',
    OPT_PARTIAL_REG_ORG_URL: '/org/{orgId}/complete-registration'
  },
  oic: {
    OIC_BASE_URL: '',
    OIC_REGISTER_TOKEN_URL: '/register/',
    OIC_DECLINE_ORG_INVITE_TOKEN_URL: '/decline-invite/',
    OIC_PARTIAL_REG_ORG_URL: '/org/{orgId}/complete-registration'
  },
  rvs: {
    RVS_BASE_URL: ''
  },
  ocp: {
    OCP_BASE_URL: ''
  },
  hub: {
    HUB_BASE_URL: '',
    HUB_REGISTER_TOKEN_URL: '/register/',
    HUB_SIGN_IN_URL: '/login',
    HUB_DECLINE_ORG_INVITE_TOKEN_URL: '/decline-invite/',
    HUB_PASSWORD_RESET_URL: '/reset-password',
    HUB_TERMS_URL: '/support/articles/terms-and-conditions',
    HUB_DASHBOARD_URL: '/myDashboard',
    HUB_PROFILE_URL: '/myProfile'
  }
};
