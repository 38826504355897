import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ListPageControls, PaginationButtons, ProductListing, SearchStatus, SVGIcon } from '../../../../components';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import { PAGE_SIZE } from '../../../../globals/pagination';
import { standardSortOptions } from '../../../../globals/searchFilters';
import colors from '../../../../globals/colors';
import { triggerSearch, setTerm, setSort, setFilter, setPage } from '../../../../redux/reducers/data/search.reducer';
import styles from './SubscriptionsTab.scss';

import withLocalizedContent from '../../../../language/withLocalizedContent';
import withSearchInitialiser from '../../../../components/SearchInitialiser/withSearchInitialiser';
import SubSectionSkeletonLoader from '../../../../components/SkeletonLoader/SubSectionSkeletonLoader';

function SubscriptionsTab({
  localizedContent: { mySchoolSubscriptionsTab: localizedContent },
  orgId,
  subscriptions,
  resultIds,
  term,
  filters,
  sort,
  updateTerm,
  filter,
  totalResults,
  page,
  loading,
  errors,
  reorder,
  paginate,
  search
}) {
  return (
    <div>
      <ListPageControls
        idPrefix="orgSubscriptions"
        searchInputLabel={localizedContent.search_input_label}
        searchInputPlaceholder={`${localizedContent.search_input_placeholder} ${localizedContent.search_input_all_oup_placeholder}`}
        searchInputValue={term}
        searchInputOnChange={updateTerm}
        searchInputOnSubmit={search}
        filterOptions={[
          {
            text: localizedContent.filter_active,
            id: 'orgSubscriptions-filterActive',
            name: 'orgSubscriptions-filterActive',
            value: 'active',
            checked: filters.active,
            onChange: filter
          },
          {
            text: localizedContent.filter_not_started,
            id: 'orgSubscriptions-filterNotStarted',
            name: 'orgSubscriptions-filterNotStarted',
            value: 'notStarted',
            checked: filters.notStarted,
            onChange: filter
          },
          {
            text: localizedContent.filter_expiring,
            id: 'orgSubscriptions-filterExpiring',
            name: 'orgSubscriptions-filterExpiring',
            value: 'expiring',
            checked: filters.expiring,
            onChange: filter
          },
          {
            text: localizedContent.filter_expired,
            id: 'orgSubscriptions-filterExpired',
            name: 'orgSubscriptions-filterExpired',
            value: 'expired',
            checked: filters.expired,
            onChange: filter
          }
        ]}
        sortOnChange={reorder}
        sortOptions={standardSortOptions(sort)}
        ariaControls="searchResults"
        showSkeletonLoader={loading}
      />

      <div className="grid horizantal-scroll-mobile">
        <div className="row">
          <div className="col">
            {loading ? (
              <SubSectionSkeletonLoader
                speed={2}
                foregroundColor={colors.COLOR_GREY_DISABLED}
                backgroundColor={colors.COLOR_WHITE}
              />
            ) : (
              <SearchStatus
                searchSource="orgSubscriptions"
                noResultsFoundContent={
                  <section className={styles.empty}>
                    <div className={styles.icon}>
                      <SVGIcon className={styles.glyph} glyph={GLYPHS.SUBSCRIPTION} />
                    </div>
                    <h1 className="gin-top1 gin-bot1">{localizedContent.no_search_results_heading}</h1>
                    <p className="gin-top1 gin-bot1">{localizedContent.no_search_results_message}</p>
                  </section>
                }
              />
            )}
          </div>

          {!(loading || errors) && resultIds.length ? (
            <div id="searchResults" className="col gin-top2" role="region" aria-live="polite" aria-atomic="true">
              <ProductListing
                items={pick(subscriptions, resultIds)}
                productTypeHeading={localizedContent.heading_subscriptions}
                showDates
                orgId={orgId}
              />
              {totalResults > PAGE_SIZE ? (
                <div className="gin-top2">
                  <div className={styles.pagination}>
                    <PaginationButtons
                      idPrefix="orgSubscriptions"
                      value={page}
                      numberOfPages={Math.ceil(totalResults / PAGE_SIZE)}
                      onClick={paginate}
                      aria={{ 'aria-controls': 'searchResults' }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

SubscriptionsTab.propTypes = {
  orgId: PropTypes.string.isRequired,
  subscriptions: PropTypes.object.isRequired,
  resultIds: PropTypes.array.isRequired,
  term: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  sort: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.bool.isRequired,
  totalResults: PropTypes.number.isRequired,
  updateTerm: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  reorder: PropTypes.func.isRequired,
  paginate: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('mySchoolSubscriptionsTab'),
  withSearchInitialiser({
    searchSource: 'orgSubscriptions',
    initialFilters: { active: true, notStarted: true, expiring: true, expired: true }
  }),
  connect(
    state => ({
      ...pick(state, ['subscriptions']),
      resultIds: state.search.orgSubscriptions.ids,
      ...pick(state.search.orgSubscriptions, ['term', 'filters', 'sort', 'page', 'totalResults', 'loading', 'errors'])
    }),
    dispatch => ({
      updateTerm: term => {
        dispatch(setTerm('orgSubscriptions', term));
      },
      filter: (name, value) => {
        dispatch(setFilter('orgSubscriptions', name, value));
      },
      reorder: sort => {
        dispatch(setSort('orgSubscriptions', sort[0]));
      },
      paginate: page => {
        dispatch(setPage('orgSubscriptions', page));
      },
      search: () => {
        dispatch(triggerSearch('orgSubscriptions'));
      }
    })
  )
)(SubscriptionsTab);
