import { put } from 'redux-saga/effects';
import {
  editCurrentUserDetailsSuccess,
  editCurrentUserDetailsError
} from '../../../../actions/editCurrentUserDetails.js';
import editCurrentUserDetailsApi from '../../../apiCalls/editCurrentUserDetails.api.js';

function* editCurrentUserDetails(newUserEmail) {
  const response = yield editCurrentUserDetailsApi(newUserEmail);

  if (response.status === 'success') {
    yield put(editCurrentUserDetailsSuccess());
  } else {
    yield put(editCurrentUserDetailsError());
  }
}

export default editCurrentUserDetails;
