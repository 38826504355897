import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './AssessmentFallbackImg.scss';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';

function AssessmentFallbackImg({ customClassName }) {
  return (
    <div className={classnames([styles.container, customClassName])}>
      <div className={styles.imgContainer}>
        <SVGIcon glyph={GLYPHS.OUP_ASSESSMENT_FALLBACK_IMG} />
      </div>
    </div>
  );
}

export default AssessmentFallbackImg;

AssessmentFallbackImg.propTypes = {
  customClassName: PropTypes.string
};
