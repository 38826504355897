import signedFetch from '../util/signedFetch.js';

/**
 * Get students ids by class details.
 *  Request params:
 *  - orgId: Integer - The organisation to check for user membership and invitation status in
 *  - classIds: Array - The class id
 */
export default (orgId, classIds) =>
  signedFetch('getStudentsByClass', `${__API_BASE__}/org/${encodeURIComponent(orgId)}/class/students`, 'POST', {
    classIds
  });
