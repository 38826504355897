import PropTypes from 'prop-types';
import React from 'react';
import { isExternalLink } from '../../globals/validations';
import styles from './ArticleThumbnailLinkList.scss';

function ArticleThumbnailLinkList({ title: heading, links }) {
  return (
    <div className={styles.ArticleThumbnailLinkList}>
      <h2>{heading}</h2>

      <ul className={styles.ArticleThumbnailLinkList}>
        {links.map((item, i) => (
          <li key={i}>
            <a href={item.link_href} target={isExternalLink(item.link_href) ? '_blank' : null} rel="noreferrer">
              <div
                className={styles.thumbnail}
                style={{ backgroundImage: `url(${item.img_src})` }}
                aria-hidden="true"
              />
              <div className={styles.text}>
                <div className={styles.linkText}>{item.link_text}</div>
                <div className={styles.linkHref}>{item.link_href}</div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

ArticleThumbnailLinkList.propTypes = {
  title: PropTypes.string.isRequired, // Heading
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link_text: PropTypes.string.isRequired,
      link_href: PropTypes.string.isRequired,
      img_src: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ArticleThumbnailLinkList;
