import PropTypes from 'prop-types';
import React from 'react';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer';
import PopoutActionFooter from '../../components/PopoutActionFooter/PopoutActionFooter';

function ErrorSignInCardPanel({ content, onDone, errorMsg }) {
  return (
    <ScrollContainer
      headerContent={
        <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.ERROR}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: errorMsg ? content.sign_in_card_error_response_title : content.sign_in_card_error_title
            }}
          />
        </PopoutPanelIconHeading>
      }
      footerContent={
        <PopoutActionFooter
          primaryButtonText={content.sign_in_card_try_again_button_text}
          primaryButtonAction={onDone}
        />
      }
    />
  );
}

ErrorSignInCardPanel.propTypes = {
  content: PropTypes.object,
  onDone: PropTypes.func.isRequired,
  errorMsg: PropTypes.string
};

export default ErrorSignInCardPanel;
