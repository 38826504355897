import PropTypes from 'prop-types';
import React from 'react';
import PanelNavigationLink from '../../PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIllustrationHeading from '../../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';

function ProductFinderMultipleError({ closePanel, onAddAnotherClick, productFinderContent, contextName = '' }) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={productFinderContent.close_panel_text} action={closePanel} />
          </div>
          <PopoutPanelIllustrationHeading
            title={productFinderContent.multiple_assignments_error_title}
            subtitle={`${productFinderContent.multiple_assignments_error_subtitle} ${contextName}. ${productFinderContent.please_try_again}`}
            illustrationSrc={HubIllustrationConstants.ERROR}
            illustrationAltText={HubIllustrationAltText.ERROR}
          />
        </div>
      }
      footerContent={
        <PopoutActionFooter
          primaryButtonText={productFinderContent.button_try_again_assignment}
          primaryButtonAction={onAddAnotherClick}
          primaryButtonTestHook="ASSIGN_LEARNING_MATERIAL_PANEL_ERROR_BUTTON"
        />
      }
    />
  );
}

ProductFinderMultipleError.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  onAddAnotherClick: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  contextName: PropTypes.string.isRequired
};

export default ProductFinderMultipleError;
