import * as t from '../actionTypes';

const initialState = {
  orgId: null,
  userId: null,
  status: null,
  errors: []
};

export const orgInviteStatusTypes = {
  SUBMITTING: 'submitting',
  SUCCESS: 'success',
  FAILURE: 'failure'
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.RESEND_INVITATION_REQUEST:
      return {
        ...initialState,
        status: orgInviteStatusTypes.SUBMITTING,
        ...payload
      };
    case t.RESEND_INVITATION_SUCCESS:
      return {
        ...state,
        status: orgInviteStatusTypes.SUCCESS
      };
    case t.RESEND_INVITATION_FAILURE:
      return {
        ...state,
        status: orgInviteStatusTypes.FAILURE,
        errors: [...state.errors, ...payload]
      };
    case t.RESEND_INVITATION_RESET:
      return { ...initialState };
    default:
      return state;
  }
};
