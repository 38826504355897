import amLocalizedContent from './amLocalizedContent';
import { isOteMode } from '../../../../../utils/platform';

function* root() {
  if (isOteMode()) {
    yield amLocalizedContent();
  }
}

export default root;
