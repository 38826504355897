/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './PanelHeading.scss';

/** This component consists of a title and subtitle. It may need to support a badge and thumnail in the future. */
function PanelHeading({ title, subtitle, children, link, linkText, isPrimaryTitle = false, customClassName = '' }) {
  return (
    <div
      id="panelHeading"
      className={customClassName || styles.panelHeading}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      {isPrimaryTitle ? <h1>{title}</h1> : <h2>{title}</h2>}
      {subtitle && (
        <p>
          <span dangerouslySetInnerHTML={{ __html: subtitle }} /> {link && <a href={link}>{linkText || 'click'}</a>}
        </p>
      )}
      {children}
    </div>
  );
}

PanelHeading.propTypes = {
  /** The main heading text. */
  title: PropTypes.string.isRequired,
  /** The second sub-heading text. */
  subtitle: PropTypes.string,
  /** Optionally display additional content such as "You will be emailed at ..." */
  children: PropTypes.any,
  link: PropTypes.string,
  linkText: PropTypes.string,
  customClassName: PropTypes.string,
  isPrimaryTitle: PropTypes.bool
};

export default PanelHeading;
