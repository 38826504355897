import uuid from 'uuid';
import { put, all, takeLatest } from 'redux-saga/effects';

import getContentSignedUrl from '../../../apiCalls/contentPackage/getContentSignedUrl';
import putContentSignedUrl from '../../../apiCalls/contentPackage/putContentSignedUrl';
import getContentUploadStatus from '../../../apiCalls/contentPackage/getContentUploadStatus';

import { POST_UPLOAD_CONTENT_PACKAGE_START } from '../../../../actionTypes';
import { pollStartAction, pollStopAction } from '../../../../reducers/polling.reducer';
import { postContentPackageSuccess, postContentPackageFailure } from '../../../../actions/assetsPage';

function* processHandler(packageId) {
  const res = yield getContentUploadStatus(packageId);

  if (res?.error) {
    yield all([put(pollStopAction()), put(postContentPackageFailure(res.error))]);
  } else if (res.uploaded) {
    yield all([put(pollStopAction()), put(postContentPackageSuccess(res))]);
  }
}

function* pollingStart(packageId) {
  yield all([processHandler(packageId), put(pollStartAction('POST_UPLOAD_CONTENT_PACKAGE_PROCESS'))]);
}

function* postContentPackage(params) {
  const file = params.get('file');
  const fileName = params.get('fileName');
  const packageType = params.get('packageType');

  const packageId = uuid.v4();

  const singedUrlResponse = yield getContentSignedUrl(fileName, packageId, packageType);
  if (singedUrlResponse?.error) {
    yield put(postContentPackageFailure(singedUrlResponse.error));
    return;
  }

  try {
    yield putContentSignedUrl(singedUrlResponse.url, file);
  } catch (error) {
    yield put(postContentPackageFailure(error.message));
    return;
  }

  yield pollingStart(packageId);
  yield takeLatest(POST_UPLOAD_CONTENT_PACKAGE_START, () => processHandler(packageId));
}

export default postContentPackage;
