import React from 'react';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../language/withLocalizedContent';
import EntityPageHeader from '../../components/EntityPageHeader/EntityPageHeader.js';
import ENTITY_TYPES from '../../globals/entityTypes';
import { isIos } from '../../utils/device';

function GeneralTermsAndConditions({ localizedContent: { myProfileInitialEditPanel: content } }) {
  return (
    <div>
      <EntityPageHeader
        entityType={ENTITY_TYPES.SUPPORT}
        entityTitle={content.my_profile_initial_consent_terms_and_conditions_header}
      />
      <div className="grid pad-top2 pad-bot4">
        <a
          target={isIos() ? undefined : '_blank'}
          href={content.my_profile_initial_consent_agreement_link_hub_prod}
          rel="noreferrer"
        >
          {content.my_profile_initial_consent_agreement_link_hub_header}
        </a>
      </div>
      <div className="grid pad-top2 pad-bot4">
        <a
          target={isIos() ? undefined : '_blank'}
          href={content.my_profile_initial_consent_agreement_link_olb}
          rel="noreferrer"
        >
          {content.my_profile_initial_consent_agreement_link_olb_header}
        </a>
      </div>
      <div className="grid pad-top2 pad-bot4">
        <a
          target={isIos() ? undefined : '_blank'}
          href={content.my_profile_initial_consent_agreement_link_ces_prod}
          rel="noreferrer"
        >
          {content.my_profile_initial_consent_agreement_link_ces_header}
        </a>
      </div>
    </div>
  );
}

GeneralTermsAndConditions.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('myProfileInitialEditPanel')(GeneralTermsAndConditions);
