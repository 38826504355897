import { takeLatest } from 'redux-saga/effects';

import * as t from '../../../../actionTypes';
import changeManagedUserPassword from './changeManagedUserPassword';
import generateManagedUserPassword from './generateManagedUserPassword';
import generateManagedUserSignInCard from './generateManagedUserSignInCard';
import generateUserNameForManagedUser from './generateUserNameForManagedUser';

import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import editManagedUserSchema from '../../../../../../static/schema/draft-4/edit-managed-user-auth-request-body.json';

function* root() {
  yield takeLatest(t.CHANGE_MANAGED_USER_PASSWORD_REQUEST, ({ payload: { orgId, userId } }) =>
    changeManagedUserPassword(orgId, userId)
  );
  yield takeLatest(t.GENERATE_MANAGED_USER_PASSWORD_REQUEST, ({ payload: orgId }) =>
    generateManagedUserPassword(orgId)
  );

  yield takeLatest(t.GENERATE_MANAGED_USER_SIGN_IN_CARD_REQUEST, ({ payload: { orgId, userId } }) =>
    generateManagedUserSignInCard(orgId, userId)
  );
  yield takeLatest(t.GENERATE_USERNAME_FOR_MANAGED_USER_REQUEST, ({ payload: { orgId, firstName, lastName } }) =>
    generateUserNameForManagedUser(orgId, firstName, lastName)
  );
  yield takeLatest(t.VALIDATE_CHANGE_MANAGED_USER_PASSWORD_INPUT, ({ payload: newPassword }) =>
    validateInputUsingSchema(editManagedUserSchema, t.VALIDATE_CHANGE_MANAGED_USER_PASSWORD_COMPLETED, { newPassword })
  );
}

export default root;
