import * as t from '../actionTypes.js';

export const getOrgUsageRequest = orgId => ({
  type: t.GET_ORG_USAGE_REQUEST,
  payload: orgId
});

export const getOrgUsageSuccess = payload => ({
  type: t.GET_ORG_USAGE_SUCCESS,
  payload
});
export const getOrgUsageError = payload => ({
  type: t.GET_ORG_USAGE_ERROR,
  payload
});
