import moment from 'moment';

/**
 * If input day is more than 14 days prior to to current local time return
 * date in '20th November 2018' format else return in 'n days ago' format.
 *
 * @param {number|string} rawDate The raw date in any form that moment.js
 * accepts. Praise be to moment.js, solver of headaches!
 */
export default function formatLastAccessed(rawDate) {
  const momentBase = moment(rawDate);
  const currentDate = moment();
  const isDayFormatRequired = Math.abs(momentBase.diff(currentDate, 'days')) > 1;
  const daysAgo = isDayFormatRequired
    ? `${Math.abs(momentBase.diff(currentDate, 'days'))} days ago`
    : moment(rawDate).fromNow();
  const dateIsValid = rawDate && moment(rawDate).isValid();
  const dateIsInFuture = momentBase.isAfter(currentDate);
  const isBeforeMinDate = !momentBase.isBefore(currentDate.subtract(14, 'days'));
  const formattedDate = isBeforeMinDate ? daysAgo : momentBase.format('Do MMMM YYYY');

  if (dateIsInFuture) {
    return 'Error: date in future';
  }
  if (dateIsValid) {
    return formattedDate;
  }
  return 'Never';
}
