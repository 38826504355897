import { put, takeLatest } from 'redux-saga/effects';

import {
  ORG_DETAILS_LOADING,
  orgDetailsLoaded,
  orgDetailsLoadError
} from '../../../../reducers/organisationPage.reducer.js';

import { storeOrganisations } from '../../../../reducers/data/organisations.reducer.js';

import getOrgDetailsApi from '../../../apiCalls/organisations/getOrgDetails.api.js';

function* callLoadOrgDetailsApi({ orgId }) {
  console.log('[orgPageLoader Saga] Loading org for ID: ', orgId);

  const apiResults = yield getOrgDetailsApi(orgId);
  // yield new Promise(resolve => setTimeout(resolve, 2000));

  if (apiResults.error || apiResults.status !== 'success') {
    console.log('[orgPageLoader Saga] Error loading org details', apiResults);
    yield put(orgDetailsLoadError(orgId));
  } else {
    console.log('[orgPageLoader Saga] Loaded org details', apiResults);
    yield put(storeOrganisations({ [orgId]: { ...apiResults.data } }));
    yield put(orgDetailsLoaded(orgId, apiResults.data));
  }
}

export default function* orgPageLoader() {
  console.log('[orgPageLoader Saga] Registering action listeners');
  yield takeLatest([ORG_DETAILS_LOADING], callLoadOrgDetailsApi);
}
