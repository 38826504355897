import * as t from '../../actionTypes';

export const initialState = {
  code: 'activationCode',
  allowedUsage: 1,
  batchName: 'undefinded',
  errorOccurred: false,
  errorMessage: null,
  success: false,
  successMessage: null,
  activationCodeDetails: null,
  productLookupData: [],
  productLookupError: null,
  activationCodeDetailError: null,
  activationCodeSearchResults: null,
  searchLoading: false
};

export default function activationCodeDashboard(state = initialState, action) {
  switch (action.type) {
    case t.SET_ACT_CODE_FOR_DASHBOARD:
      return {
        ...state,
        code: action.data.code
      };
    case t.SET_ALLOWED_USAGE_FOR_DASHBOARD:
      return {
        ...state,
        allowedUsage: action.data.allowedUsage
      };
    case t.SET_BATCH_NAME_FOR_DASHBOARD:
      return {
        ...state,
        batchName: action.data.batchName
      };
    case t.SEARCH_ACT_CODE_FOR_DASHBOARD:
      return {
        ...state
      };
    case t.SET_ERROR_FOR_ACT_CODE_DASHBOARD:
      return {
        ...state,
        errorOccurred: true,
        success: false,
        errorMessage: action.data.error,
        searchLoading: false
      };
    case t.SET_RESPONSE_FOR_ACT_CODE_DASHBOARD:
      return {
        ...state,
        error: false,
        success: true,
        activationCodeSearchResults: action.data,
        searchLoading: false
      };
    case t.SET_ACT_CODE_DETAILS_FOR_DASHBOARD:
      return {
        ...state,
        activationCodeDetails: action.data
      };
    case t.SET_ACT_CODE_DETAILS_ERROR_FOR_DASHBOARD:
      return {
        ...state,
        activationCodeDetailError: action.data
      };
    case t.LOOKUP_PRODUCT_SET_DATA:
      return {
        ...state,
        productLookupData: action.data
      };
    case t.LOOKUP_PRODUCT_SET_ERROR:
      return {
        ...state,
        productLookupError: true
      };
    case t.RESET_SEARCH_ACT_CODES:
      return {
        ...state,
        activationCodeSearchResults: null,
        searchLoading: false
      };
    case t.SEARCH_ACT_CODES_START:
      return {
        ...state,
        searchLoading: true
      };
    default:
      return state;
  }
}
