/**
 * Replaces all words that are wrapped in curly brackets with their value in the data object
 *
 * @param {string} templateString
 * @param {Object} data
 *
 * @return {string}
 */

function interpolateContent(templateString = '', data = {}) {
  return Object.entries(data).reduce(
    (text, [placeholder, value]) => text.replace(new RegExp(`\\\${${placeholder}}`, 'g'), value),
    templateString
  );
}

export default interpolateContent;
