import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getGBProductsRequest from '../../../apiCalls/gradebook/getGBProducts.api';

export default function* getGBProducts(params) {
  console.log('[getGBProducts] Waiting for the "GET_GB_PRODUCTS"');
  const apiResults = yield getGBProductsRequest(params);
  console.log(apiResults);
  yield put(actions.getGBProductsSuccess(apiResults));
}
