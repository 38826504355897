import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../components/Button/Button';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../language/withLocalizedContent';

function ErrorView({ title, detail, onRetry, iconType = types.ERROR, localizedContent: { errorView: content } }) {
  return (
    <div className="text-center pad-top8 gin-bot8">
      <PopoutPanelIconHeading type={iconType || types.ERROR} title={title} subtitle={detail} />
      <div className="text-center gin-top2">
        {onRetry ? <Button id="reset-password" text={content.button_retry} onClick={onRetry} /> : null}
      </div>
    </div>
  );
}

ErrorView.propTypes = {
  title: PropTypes.string.isRequired,
  detail: PropTypes.string,
  onRetry: PropTypes.func,
  iconType: PropTypes.string,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('errorView')(ErrorView);
