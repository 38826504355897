import * as t from '../../actionTypes';

export const initialState = {
  loading: false,
  results: [],
  error: null,
  total: null
};

export default function activationCodeBatchDashboard(state = initialState, action) {
  switch (action.type) {
    case t.SEARCH_ACT_CODE_BATCH:
      return {
        ...state,
        loading: true
      };
    case t.STOP_SEARCH_ACT_CODE_BATCH:
      return {
        ...state,
        loading: false
      };
    case t.SET_RESULT_ACT_CODE_BATCH:
      return {
        ...state,
        results: action.data.success.batches,
        total: action.data.success.total,
        loading: false
      };
    case t.SET_ERROR_ACT_CODE_BATCH:
      return {
        ...state,
        error: action.data.error,
        loading: false,
        results: [],
        total: null
      };
    case t.EMPTY_SEARCH_ACT_CODE_BATCH_RESULTS:
      return {
        ...state,
        results: [],
        total: null
      };
    default:
      return state;
  }
}
