import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './ErrorStrip.scss';
import SVGIcon from '../SVGIcon/SVGIcon';

function ErrorStrip({
  message = 'There has been an error loading data',
  errorCustomClass = '',
  glyph = '',
  glyphCustomClass = ''
}) {
  return (
    <p className={errorCustomClass ? classnames(errorCustomClass, styles.error) : styles.error}>
      {glyph && <SVGIcon className={glyphCustomClass} glyph={glyph} />}
      {message}
    </p>
  );
}

ErrorStrip.propTypes = {
  message: PropTypes.string,
  glyph: PropTypes.string,
  glyphCustomClass: PropTypes.string,
  errorCustomClass: PropTypes.string
};

export default ErrorStrip;
