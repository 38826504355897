import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getStudentReport from './getStudentReport';
// import getStudentAttainmentReport from './getStudentAttainmentReport';
// import getStudentProgressReport from './getStudentProgressReport';
// import getStudentEngagementReport from './getStudentEngagementReport';
import getStudentProgressItems from './getStudentProgressItems';

function* root() {
  yield takeLatest(t.STUDENT_REPORT_REQUEST, ({ payload: { orgId, studentId, locationId } }) =>
    getStudentReport(orgId, studentId, locationId)
  );

  // yield takeLatest(t.STUDENT_ATTAINMENT_REPORT_REQUEST, ({ payload: { orgId, studentId, locationId } }) =>
  //   getStudentAttainmentReport(orgId, studentId, locationId)
  // );

  // yield takeLatest(t.STUDENT_PROGRESS_REPORT_REQUEST, ({ payload: { orgId, studentId, locationId } }) =>
  //   getStudentProgressReport(orgId, studentId, locationId)
  // );

  // yield takeLatest(t.STUDENT_ENGAGEMENT_REPORT_REQUEST, ({ payload: { orgId, studentId, locationId } }) =>
  //   getStudentEngagementReport(orgId, studentId, locationId)
  // );

  yield takeLatest(t.STUDENT_PROGRESS_ITEMS_REQUEST, ({ payload: { orgId, studentId, locationId } }) =>
    getStudentProgressItems(orgId, studentId, locationId)
  );
}

export default root;
