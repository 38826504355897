import React from 'react';
import PropTypes from 'prop-types';
import {
  EntityPageHeader,
  ErrorStatus,
  ManagedUserUploadListing,
  PageWrapper,
  PopoutPanelIconHeading
} from '../components';
import ENTITY_TYPES from '../globals/entityTypes';
import { ErrorStatusIconTypes } from '../components/ErrorStatus/ErrorStatus';
import { types as PopoutPanelIconHeadingTypes } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../language/withLocalizedContent';

class ActivityView extends React.Component {
  _handleSetPage = async newPage => {
    const { searchParams, onChangeSearchParam, onUpdateSearchResults } = this.props;

    const newStart = searchParams.size * (newPage - 1);
    await onChangeSearchParam('page', newPage);
    await onChangeSearchParam('start', newStart);
    onUpdateSearchResults({ page: newPage, start: newStart });
  };

  _getContent() {
    const {
      localizedContent: { activityView: content },
      managedUserUploadActivity = {},
      numberOfPages,
      searchParams,
      isLoading,
      hasFailed,
      onUpdateSearchResults
    } = this.props;
    const hasNoActivities = !Object.keys(managedUserUploadActivity).length;

    switch (true) {
      case isLoading:
        return <PopoutPanelIconHeading type={PopoutPanelIconHeadingTypes.LOADING} title={content.loading_title} />;
      case hasFailed:
        return (
          <ErrorStatus
            title={content.error_title}
            buttonText={content.error_button}
            buttonOnClickHandler={() => onUpdateSearchResults()}
          />
        );
      case hasNoActivities:
        return (
          <ErrorStatus
            type={ErrorStatusIconTypes.VALID}
            title={content.no_activities_title}
            buttonText={content.no_activities_button}
            buttonOnClickHandler={() => onUpdateSearchResults()}
          />
        );
      default:
        return (
          <ManagedUserUploadListing
            activity={managedUserUploadActivity}
            page={searchParams.page}
            numberOfPages={numberOfPages}
            onSetPageClick={this._handleSetPage}
          />
        );
    }
  }

  render() {
    return (
      <div>
        <EntityPageHeader entityType={ENTITY_TYPES.ACTIVITY} entityTitle="Activity Log" />
        <PageWrapper>{this._getContent()}</PageWrapper>
      </div>
    );
  }
}

ActivityView.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  managedUserUploadActivity: PropTypes.object,
  searchParams: PropTypes.object.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  hasFailed: PropTypes.bool,
  onChangeSearchParam: PropTypes.func.isRequired,
  onUpdateSearchResults: PropTypes.func.isRequired
};

export default withLocalizedContent('activityView')(ActivityView);
