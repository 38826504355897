import { put } from 'redux-saga/effects';
import { getBasketAssignmentsSuccess, getBasketAssignmentsFailure } from '../../../../../actions/ngsCourse';
import getBasketAssignmentsApiCall from '../../../../apiCalls/ngs/assignments/getAssignments';

function* getBasketAssignments(params) {
  const response = yield getBasketAssignmentsApiCall(params);

  if (response.status === 'success') {
    yield put(getBasketAssignmentsSuccess(response.data));
    return;
  }

  yield put(getBasketAssignmentsFailure(response.message));
}

export default getBasketAssignments;
