import React from 'react';
import colors from '../../../../globals/colors';
import SubSectionSkeletonLoader from '../../../../components/SkeletonLoader/SubSectionSkeletonLoader';

function RequestComponent() {
  return (
    <SubSectionSkeletonLoader
      speed={2}
      foregroundColor={colors.COLOR_GREY_DISABLED}
      backgroundColor={colors.COLOR_WHITE}
    />
  );
}

export default RequestComponent;
