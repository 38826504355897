import { COURSE_CONSTANTS } from '@oup/shared-node-browser/course';
import { HubLayoutConstants } from '../../../globals/hubConstants';

export const hasLockableKeywords = keywords =>
  keywords?.includes(COURSE_CONSTANTS.EVALUATION_MODE_LOCKED) || keywords?.includes(HubLayoutConstants.LOCKED_KEYWORD);

export const hasHideableKeywords = keywords => keywords?.includes(COURSE_CONSTANTS.NORMAL_MODE_HIDDEN);
export function isLockedNode(course, folder, ignoreResources = false) {
  let locked = true;
  if (folder.resources && folder.resources.length) {
    for (let i = 0; i < folder.resources.length; i += 1) {
      if (folder.resources[i].type === HubLayoutConstants.ASSESSMENT_TYPES.INTERACTIVE_FOLDER) {
        if (locked && course?.interactiveFolders?.[folder.resources[i].id]) {
          locked = locked && hasLockableKeywords(course.interactiveFolders[folder.resources[i].id].keywords);
        }
      } else if (!ignoreResources && folder.resources[i].type === HubLayoutConstants.ASSESSMENT_TYPES.RESOURCE) {
        if (locked && course?.resources?.[folder.resources[i].id]) {
          locked = locked && hasLockableKeywords(course.resources[folder.resources[i].id].keywords);
        }
      }
    }
  }

  if (locked) {
    Object.keys(folder).forEach(key => {
      if (locked && folder[key]?.title) {
        locked = locked && isLockedNode(course, folder[key]);
      }
    });
  }
  return locked;
}
