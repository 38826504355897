import React from 'react';
import PropTypes from 'prop-types';
import styles from './GradedReadersBanner.scss';
import withLocalizedContent from '../../language/withLocalizedContent';

function GradedReadersBanner({
  mostReadLevel,
  booksFinishedAverage,
  localizedContent: { gradedReadersBanner: localizedContent }
}) {
  return (
    <div className={styles.GradedReadersBanner}>
      <table className={styles.GradebookCard__table}>
        <thead>
          <tr>
            <td colSpan={2}>Class Average</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className={styles.total}>{booksFinishedAverage}</div>
              <div className={styles.totalLabel}>{localizedContent.book_finshed_label}</div>
            </td>
            <td>
              <div className={styles.total}>{mostReadLevel}</div>
              <div className={styles.totalLabel}>{localizedContent.level_label}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

GradedReadersBanner.propTypes = {
  mostReadLevel: PropTypes.string,
  booksFinishedAverage: PropTypes.string,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('gradedReadersBanner')(GradedReadersBanner);
