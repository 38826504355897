import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import withLocalizedContent from '../../language/withLocalizedContent';
import OteSocialNetworkBar from '../OteHomepage/parts/OteSocialNetworkBar/OteSocialNetworkBar';
import Button, { buttonTypes } from '../../components/Button/Button.js';

import styles from './SEBSignedOut.scss';

function SEBSignedOut({ localizedContent: { sebSignedOut: content } }) {
  return (
    <div>
      <div className={styles.SEBSignedOutContainer}>
        <div className={styles.container}>
          <p>{content.description_text}</p>
          <Button
            id="signin-button"
            type={buttonTypes.PRIMARY}
            text={content.button}
            onClick={() => {
              window.location.href = '/sebOnline';
            }}
          />
          <span>
            <p className={styles.no_account_text}>{content.no_account_text}</p>
            <a className={styles.register_text} href="/register-choice">
              <div>{content.register_text}</div>
            </a>
          </span>
        </div>
      </div>
      <div className={styles.bottom}>
        <OteSocialNetworkBar />
      </div>
    </div>
  );
}
SEBSignedOut.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('sebSignedOut'))(SEBSignedOut);
