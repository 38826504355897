import { put } from 'redux-saga/effects';

import {
  generateManagedUserSignInCardSuccess,
  generateManagedUserSignInCardFailure
} from '../../../../actions/managedUser';
import signedFetch from '../../../apiCalls/util/signedFetch';

import { featureIsEnabled } from '../../../../../globals/envSettings';

function* generateManagedUserSignInCard(orgId, userId) {
  const allDetails = true;
  let response;
  if (featureIsEnabled('lock-account')) {
    response = yield signedFetch(
      'getManagedUserDetails',
      `${__API_BASE__}/ngs/org/${encodeURIComponent(orgId)}/signin-card-details`,
      'POST',
      { userIds: userId, allDetails }
    );
  } else {
    response = yield signedFetch(
      'generateSignedURL',
      `${__API_BASE__}/org/${orgId}/managed-user/${userId}/generate-signed-url`,
      'GET'
    );
  }

  if (response.status === 'success') {
    yield put(generateManagedUserSignInCardSuccess(response.data.signedURL));
    return;
  }

  yield put(generateManagedUserSignInCardFailure());
}

export default generateManagedUserSignInCard;
