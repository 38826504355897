import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { validColors } from '../../globals/colors';
import { bgClass } from '../../utils/colorClassNames';
import styles from './RocketLolly.scss';

function RocketLolly(props) {
  const { items = [] } = props;
  const numberOfSegments = items.length;
  const indexToSlice = numberOfSegments === 3 ? 1 : 0;
  const total = items.slice(indexToSlice).reduce((sum, item) => sum + (item.value || 1), 0);
  const ratios = items.map((item, i) =>
    numberOfSegments === 3 ? i > 0 && (100 * (item.value || 1)) / total : (100 * (item.value || 1)) / total
  );

  return (
    <ul className={styles.rocketLolly}>
      {items.map((item, i) => (
        <li
          key={i}
          className={classnames(styles.stripe, item.color ? bgClass(item.color) : styles.defaultColor)}
          style={{ flexBasis: ratios[i] && `${ratios[i]}%` }}
          data-tooltip={item.text}
        >
          <em className="value">{item.value}</em>
        </li>
      ))}
    </ul>
  );
}

RocketLolly.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** The label to use in this stripe of the lolly */
      text: PropTypes.string.isRequired,
      /** The numeric value of this stripe of the lolly */
      value: PropTypes.number.isRequired,
      /** Optionally specify a color name to override the default color */
      color: PropTypes.oneOf(validColors)
    })
  ).isRequired
};

export default RocketLolly;
