import { put, select } from 'redux-saga/effects';

import baseSchema from '../../../../../../static/schema/draft-4/bulk-upload-managedusers-request-body.json';
import actions from '../../../../actions';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';

export const schema = {
  items: {
    ...baseSchema.items,
    properties: {
      ...baseSchema.items.properties,
      userName: {
        type: 'string',
        description: 'The unprefixed username value entered',
        pattern: '^[A-Za-z0-9]{1,44}\\.?[A-Za-z0-9]{0,44}$'
      }
    }
  }
};

function* validateImportUsers(id, input) {
  const schemaErrors = yield validateInputUsingSchema(schema, null, input);
  const archivedUsers = yield select(state => state.importUsers.archived);
  const errors = {};
  for (let i = 0; i < input.length; i += 1) {
    errors[input[i].id] = archivedUsers.includes(input[i].id)
      ? { ...schemaErrors[i], userName: true }
      : schemaErrors[i];
  }
  yield put(actions.validateImportUsersListCompleted(id, errors));
}

export default validateImportUsers;
