import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { ErrorStatus, PageWrapper } from '../../../components';
import actions from '../../../redux/actions';

import PageHeading from '../../../components/PageHeading/PageHeading';
import UserLicenceRemoveByUserForm from '../../../components/UserLicenceRemoveByUserForm/UserLicenceRemoveByUserForm';
import UserLicenceRemoveByLicenceForm from '../../../components/UserLicenceRemoveByLicenceForm/UserLicenceRemoveByLicenceForm';
import RemoveUserLicenceSuccess from '../../../components/RemoveUserLicenceSuccess/RemoveUserLicenceSuccess';

const defaultState = { licenceId: '' };

class RemoveUserLicencePage extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  _reset = () => {
    const { reset } = this.props;
    this.setState(defaultState);
    reset();
  };

  _sendRemoveUserLicence = licenceId => {
    const { sendRemoveUserLicence } = this.props;
    sendRemoveUserLicence(licenceId);
  };

  render() {
    const {
      localizedContent: { removeUserLicencePage: content },
      success,
      failure
    } = this.props;

    return (
      <PageWrapper>
        <Helmet title={content.title} />
        <PageHeading className="pad-bot4" title={content.page_title} />

        {(() => {
          switch (true) {
            case success:
              return <RemoveUserLicenceSuccess secondaryButtonAction={this._reset} />;
            case failure:
              return (
                <ErrorStatus
                  title={content.error_title}
                  buttonText={content.error_button}
                  buttonOnClickHandler={this._reset}
                />
              );
            default:
              return (
                <div>
                  <UserLicenceRemoveByUserForm removeLicence={this._sendRemoveUserLicence} />
                  <UserLicenceRemoveByLicenceForm removeLicence={this._sendRemoveUserLicence} />
                </div>
              );
          }
        })()}
      </PageWrapper>
    );
  }
}

RemoveUserLicencePage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  success: PropTypes.bool,
  failure: PropTypes.bool,
  sendRemoveUserLicence: PropTypes.func,
  reset: PropTypes.func
};

export default compose(
  withLocalizedContent('removeUserLicencePage'),
  connect(
    state => state.removeUserLicence,
    dispatch => ({
      sendRemoveUserLicence: licenceId => {
        dispatch(actions.sendRemoveUserLicenceRequest(licenceId));
      },
      reset: () => {
        dispatch(actions.sendRemoveUserLicenceReset());
        dispatch(actions.getUserLicenceReset());
        dispatch(actions.getUserLicencesReset());
      }
    })
  )
)(RemoveUserLicencePage);
