import { put } from 'redux-saga/effects';
import getCourseContentsFetch from '../../../apiCalls/cptStructuredContentPlayer/getCourseContents';
import { loadCourseContentsSuccess, loadCourseContentsFailure } from '../../../../actions/courseContents';

function* getCourseContents(params) {
  const response = yield getCourseContentsFetch(params);
  if (response.status === 'success') {
    yield put(loadCourseContentsSuccess(response.data));
  } else {
    yield put(loadCourseContentsFailure('Failed to get course contents'));
  }
}

export default getCourseContents;
