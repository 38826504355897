import { put } from 'redux-saga/effects';

import actions from '../../../actions';
import signedFetch from '../../apiCalls/util/signedFetch';

function* getPlatformCountryCodes(role) {
  const platformCode = role === 'PRIMARY_SCHOOL' ? 'ORB' : 'OLB';
  const response = yield signedFetch('getPlatformCountryCodes', `${__API_BASE__}/open/countrycodes`, 'POST', {
    platformCode
  });
  if (response.status === 'success') {
    yield put(actions.getPlatformCountryCodesSuccess(response.data.countryCodes));
  }
}

export default getPlatformCountryCodes;
