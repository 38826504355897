import { HubLayoutConstants } from '../../../globals/hubConstants';

export default function folderHasTests(folderData) {
  let hasTests = false;
  if (folderData.resources) {
    hasTests = folderData.resources.some(
      resource => resource.type === HubLayoutConstants.ASSESSMENT_TYPES.INTERACTIVE_FOLDER
    );
  }

  if (!hasTests) {
    Object.keys(folderData).forEach(key => {
      if (!hasTests && folderData[key]?.title) {
        hasTests = folderHasTests(folderData[key]);
      }
    });
  }
  return hasTests;
}
