import { takeLatest } from 'redux-saga/effects';
import * as types from '../../../../actionTypes';
import submit from './submit';
import validateUsername from './validateUsername';

function* managedUserEdit() {
  yield takeLatest(types.MANAGED_USER_CHANGE_USERNAME_VALIDATE_USERNAME, ({ payload: { orgId, username } }) =>
    validateUsername(orgId, username)
  );
  yield takeLatest(types.MANAGED_USER_CHANGE_USERNAME_SUBMIT, ({ payload: { orgId, userId, user } }) =>
    submit(orgId, userId, user)
  );
}

export default managedUserEdit;
