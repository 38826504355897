import PropTypes from 'prop-types';
import React from 'react';
import colors, { validColors } from '../../globals/colors';
import EntityRepresentation from '../EntityRepresentation/EntityRepresentation';
import { GLYPHS, validGlyphs } from '../SVGIcon/SVGIcon';
import { SIZES, validSizes } from '../Thumbnail/Thumbnail';

/** This component consists of a persons initials in a thumbnail, their name and their email. The name can be a link */
function PersonRepresentation({
  thumbnailColor,
  thumbnailSize,
  secondaryThumbnailSize,
  secondaryThumbnailGlyph,
  initials,
  linkTo,
  name,
  headerSizeName,
  email,
  statusText,
  status,
  deletedStyle,
  id,
  locked = false,
  yearGroup,
  userUpdateSuccess = false
}) {
  let lockedProps = {};

  if (locked) {
    lockedProps = {
      line1Badge: 'Locked',
      line1Color: colors.ERROR,
      smallCircleBackgroundColor: colors.ERROR,
      smallCircleGlyph: GLYPHS.ICON_LOCK
    };
  }

  return (
    <EntityRepresentation
      thumbnailColor={thumbnailColor || colors.PROFILE}
      thumbnailSize={thumbnailSize || SIZES.TABLE}
      thumbnailText={initials || name}
      secondaryThumbnailSize={secondaryThumbnailSize || SIZES.MEDIUM}
      secondaryThumbnailGlyph={secondaryThumbnailGlyph}
      id={id}
      linkTo={linkTo}
      headerSizeName={headerSizeName}
      line1Text={name}
      line2Text={email}
      line3Text={statusText}
      status={status}
      deletedStyle={deletedStyle}
      {...lockedProps}
      line2Badge={yearGroup}
      userUpdateSuccess={userUpdateSuccess}
    />
  );
}

PersonRepresentation.propTypes = {
  /** The color of the thumbnail. Only required if not an image */
  thumbnailColor: PropTypes.oneOf(validColors),
  /** The size of the thumbnail. */
  thumbnailSize: PropTypes.oneOf(validSizes),
  /** The size of the secondary thumbnail. */
  secondaryThumbnailSize: PropTypes.oneOf(validSizes),
  /** The glyph of the secondary thumbnail. */
  secondaryThumbnailGlyph: PropTypes.oneOf(validGlyphs),
  /** Persons initials to be displayed in the thumbnail. Will be derived from name if omitted */
  initials: PropTypes.string,
  /** Target of the link if the entity contains a link */
  linkTo: PropTypes.string,
  /** Persons name */
  name: PropTypes.string.isRequired,
  headerSizeName: PropTypes.bool,
  /** Person's email */
  email: PropTypes.string,
  /** Person's status, eg "Added to Class 1B" */
  statusText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Person's status, eg SUCCESS, WARNING, ERROR */
  status: PropTypes.string,
  /** This applies a "deleted" style to the element */
  deletedStyle: PropTypes.bool,
  /** The html ID of the element. */
  id: PropTypes.string,
  /** Show locked badge next to the name */
  locked: PropTypes.bool,
  yearGroup: PropTypes.string,
  userUpdateSuccess: PropTypes.bool
};

export default PersonRepresentation;
