import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './StartTips.scss';
import animationStyles from './Animations.scss';
import Button from '../../components/Button/Button';
import withLocalizedContent from '../../language/withLocalizedContent';

const FINAL_STEP_NUMBER = 3;

function Steps({ step, buttonClick, localizedContent: { orbStartupPages } }) {
  const stepDetails = [
    {
      page: {
        strongText: orbStartupPages.step1_page_strong_text,
        ordinaryText: orbStartupPages.step_page_ordinary_text
      },
      title: orbStartupPages.step1_title,
      description: {
        ordinaryText1: orbStartupPages.step1_description_ordinary_text1,
        strongText: orbStartupPages.step1_description_strong_text,
        ordinaryText2: orbStartupPages.step1_description_ordinary_text2
      },
      imgURL: orbStartupPages.step1_image_url,
      showStep: false
    },
    {
      page: {
        strongText: orbStartupPages.step2_page_strong_text,
        ordinaryText: orbStartupPages.step_page_ordinary_text
      },
      title: orbStartupPages.step2_title,
      description: {
        ordinaryText1: orbStartupPages.step2_description_ordinary_text1,
        strongText: orbStartupPages.step2_description_strong_text,
        ordinaryText2: ''
      },
      imgURL: orbStartupPages.step2_image_url,
      showStep: false
    },
    {
      page: {
        strongText: orbStartupPages.step3_page_strong_text,
        ordinaryText: orbStartupPages.step_page_ordinary_text
      },
      title: orbStartupPages.step3_title,
      description: {
        ordinaryText1: orbStartupPages.step3_description_ordinary_text1,
        strongText: orbStartupPages.step3_description_strong_text,
        ordinaryText2: orbStartupPages.step3_description_ordinary_text2
      },
      imgURL: orbStartupPages.step3_image_url,
      showStep: false
    },
    {
      page: {
        strongText: orbStartupPages.step4_page_strong_text,
        ordinaryText: orbStartupPages.step_page_ordinary_text
      },
      title: orbStartupPages.step4_title,
      description: {
        ordinaryText1: orbStartupPages.step4_description_ordinary_text1,
        strongText: '',
        ordinaryText2: ''
      },
      imgURL: orbStartupPages.step4_image_url,
      showStep: false
    }
  ];

  stepDetails[step].showStep = true;
  return (
    <div className={styles.startTips}>
      {stepDetails.map((stepDetail, index) => (
        <div key={index}>
          {stepDetail.showStep ? (
            <div className={classnames('grid', animationStyles.animated, animationStyles.bounceInRight)}>
              <div className="row">
                <div className="col">
                  <h1>{orbStartupPages.step_heading}</h1>
                </div>
              </div>
              <div className={classnames('row', styles.item)}>
                <div className={classnames('col', styles.page)}>
                  <p>
                    <strong>{stepDetail.page.strongText}</strong> {stepDetail.page.ordinaryText}
                  </p>
                  <h3 className={styles.title}>{stepDetail.title}</h3>
                  <p className={styles.description}>
                    {stepDetail.description.ordinaryText1} <strong>{stepDetail.description.strongText}</strong>{' '}
                    {stepDetail.description.ordinaryText2}
                  </p>
                  <img src={stepDetail.imgURL} alt="img" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className={styles.stepsAction}>
                    <Button
                      text={
                        step === FINAL_STEP_NUMBER ? orbStartupPages.step_done_button : orbStartupPages.step_next_button
                      }
                      onClick={() => {
                        buttonClick();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
}

Steps.propTypes = {
  buttonClick: PropTypes.func,
  step: PropTypes.number,
  localizedContent: PropTypes.object
};

export default withLocalizedContent('orbStartupPages')(Steps);
