import PropTypes from 'prop-types';
import React from 'react';
import PopoutActionFooter from '../../../../../../components/PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading, {
  types as headingIconTypes
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer';
import UserConfirmationList from '../../../../../../components/UserConfirmationList/UserConfirmationList';

export const HeadingTypes = {
  VALID: headingIconTypes.VALID,
  WARNING: headingIconTypes.WARNING,
  ERROR: headingIconTypes.ERROR
};

function ClassUserRequestConfirmation({
  localizedContent: content,
  title,
  type = HeadingTypes.VALID,
  infoMessage = '',
  users = {},
  failedIds = [],
  onAddAnother,
  onDoneClick,
  status
}) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          <PopoutPanelIconHeading title={title} type={type} />
        </div>
      }
      footerContent={
        <PopoutActionFooter
          {...(onAddAnother
            ? {
                secondaryButtonText: content.confirmation_add_another_button,
                secondaryButtonAction: onAddAnother
              }
            : {})}
          primaryButtonText={content.confirmation_done_button}
          primaryButtonAction={onDoneClick}
        />
      }
    >
      {infoMessage ? (
        <div className="gin2">
          <em>{infoMessage}</em>
        </div>
      ) : null}
      <UserConfirmationList
        users={users}
        failedIds={failedIds}
        warningUserStatusText={content.confirmation_warning_user_status_text}
        successUserStatusText={
          status === 'ACCEPTED'
            ? content.confirmation_success_user_accepted_text
            : content.confirmation_success_user_rejected_text
        }
        noUsersText={content.confirmation_no_users_text}
      />
    </ScrollContainer>
  );
}

ClassUserRequestConfirmation.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  users: PropTypes.object,
  failedIds: PropTypes.array,
  type: PropTypes.oneOf(Object.values(HeadingTypes)),
  infoMessage: PropTypes.string,
  onAddAnother: PropTypes.func,
  onDoneClick: PropTypes.func.isRequired,
  status: PropTypes.string
};

export default ClassUserRequestConfirmation;
