import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import useIteratorPagination from './useIteratorPagination';
import styles from './IteratorPagination.scss';

function IteratorPagination({
  paginationCallback,
  disableLast,
  disableNext,
  disablePrevious,
  lastPage,
  numberPerPage,
  items,
  idField,
  eventCallback
}) {
  const [goToFirst, goBackward, goForward, goToLast] = useIteratorPagination(numberPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const doPagination = e => {
    // setLimit(numberPerPage);
    switch (e) {
      case 'prev':
        setCurrentPage(currentPage - 1);
        paginationCallback(goBackward(items, idField, numberPerPage));
        break;
      case 'next':
        setCurrentPage(currentPage + 1);
        paginationCallback(goForward(items, idField, numberPerPage));
        break;
      case 'first':
        setCurrentPage(1);
        paginationCallback(goToFirst(numberPerPage));
        break;
      case 'last':
        setCurrentPage(lastPage);
        paginationCallback(goToLast(numberPerPage));
        break;
      default:
        console.log('incorect pagination command');
    }
  };

  return (
    <nav>
      <ol className={styles.paginationContainer}>
        <li>
          <Button
            id="iterator-first"
            type={buttonTypes.GROUP}
            text="1"
            onClick={() => {
              if (eventCallback) {
                eventCallback('first');
              } else {
                doPagination('first');
              }
            }}
          />
        </li>
        <li>
          <Button
            id="iterator-prevPage"
            type={buttonTypes.GROUP}
            glyph={GLYPHS.ICON_LEFT_THICK}
            text="<"
            iconOnly
            onClick={() => {
              if (eventCallback) {
                eventCallback('prev');
              } else {
                doPagination('prev');
              }
            }}
            disabled={disablePrevious || currentPage === 1}
          />
        </li>
        <li>
          <Button
            id="iterator-nextPage"
            type={buttonTypes.GROUP}
            glyph={GLYPHS.ICON_RIGHT_THICK}
            text=">"
            iconOnly
            onClick={() => {
              if (eventCallback) {
                eventCallback('next');
              } else {
                doPagination('next');
              }
            }}
            disabled={disableNext || currentPage === lastPage}
          />
        </li>
        <li>
          <Button
            id="itterator-last"
            type={buttonTypes.GROUP}
            text={lastPage}
            disable={disableLast || currentPage === lastPage}
            onClick={() => {
              if (eventCallback) {
                eventCallback('last');
              } else {
                doPagination('last');
              }
            }}
          />
        </li>
      </ol>
    </nav>
  );
}

IteratorPagination.propTypes = {
  paginationCallback: PropTypes.func,
  eventCallback: PropTypes.func,
  numberPerPage: PropTypes.number,
  items: PropTypes.array,
  idField: PropTypes.string,
  disableNext: PropTypes.bool,
  disableLast: PropTypes.bool,
  disablePrevious: PropTypes.bool,
  lastPage: PropTypes.number.isRequired
};

export default IteratorPagination;
