import jsonFetch from '../util/jsonFetch';

export default async ({ token }) => {
  const payload = { token };
  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify(payload);
  const url = `${__API_BASE__}/open/ltitool1v3/content-selection/status`;

  const response = await jsonFetch('getContentSelectorStatus', [url, { method: 'POST', headers, body }], false);
  return response;
};
