import * as t from '../actionTypes';

const initialState = {
  errors: {},
  submitting: false,
  success: false,
  failure: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.VALIDATE_ORG_ADMIN_COMPLETED:
      return {
        ...state,
        ...(payload.email ? { emailUnavailable: undefined } : null),
        errors: {
          ...state.errors,
          ...payload
        }
      };
    case t.VALIDATE_ORG_ADMIN_ERROR:
      return {
        ...state,
        submitting: false
      };
    case t.STORE_ORG_ADMIN_INPUT_EMAIL_AVAILABILITY:
      return {
        ...state,
        emailUnavailable: payload,
        emailLoading: false,
        errors: {
          ...state.errors,
          email: payload
        }
      };
    case t.STORE_ORG_ADMIN_INPUT_EMAIL_LOADING:
      return {
        ...state,
        emailLoading: true,
        errors: {
          ...state.errors
        }
      };
    case t.REGISTER_ORG_ADMIN_SUBMITTING:
      return {
        ...initialState,
        submitting: true
      };
    case t.REGISTER_ORG_ADMIN_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true
      };
    case t.REGISTER_ORG_ADMIN_FAILURE:
      return {
        ...state,
        submitting: false,
        failure: true
      };
    case t.REGISTER_ORG_ADMIN_FAILURE_CLEAR:
      return {
        ...state,
        failure: false
      };
    case t.REGISTER_ORG_ADMIN_RESET:
      return initialState;
    default:
      return state;
  }
};
