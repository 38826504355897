import { select } from 'redux-saga/effects';
import downloadStudentDataApi from './downloadStudentDataApi.js';

export default function* downloadStudentDataRequest() {
  const { currentOrganisationId: orgId } = yield select(state => state.identity);

  console.log(`[downloadStudentDataRequest Saga] Submitting request to get student data for orgId: ${orgId}.`);

  return yield downloadStudentDataApi(orgId);
}
