import { put } from 'redux-saga/effects';
import createClassroomApi from '../../../apiCalls/createClassroomApi';
import { getCurrentPlatform } from '../../../../../utils/platform';
import { setClassNameSuccess, setClassNameError } from '../../../../actions/classOnboardingWizard';

function* createClass(data) {
  const payload = {
    creatingTeacher: data.userId,
    name: data.classNameValue,
    teachers: [data.userId],
    students: [],
    platformCode: getCurrentPlatform() || ''
  };
  const results = yield createClassroomApi(data.orgId, payload);

  if (results?.status === 'success') {
    return yield put(setClassNameSuccess({ classId: results.data.groupId, joiningCode: results.data.joiningCode }));
  }
  return yield put(setClassNameError());
}

export default createClass;
