import React from 'react';
import PropTypes from 'prop-types';

function ButtonMenuItem({ menuItem = {}, toggled }) {
  return (
    <button
      data-testid={menuItem.testHook || ''}
      type="button"
      onClick={menuItem.action}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.stopPropagation();
          menuItem.action();
        }
      }}
      tabIndex={toggled ? 0 : -1}
    >
      <span>{menuItem.title}</span>
      {menuItem.subtitle ? <span>{menuItem.subtitle}</span> : null}
    </button>
  );
}

ButtonMenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  toggled: PropTypes.bool.isRequired
};

export default ButtonMenuItem;
