import PropTypes from 'prop-types';
import React from 'react';

/**
 * Return a new component provided with the class name for the current locale
 * as the property `getLocalizedErrorMessage`.
 *
 * @param {...string} className
 *
 * @return {React.Component}
 */
const withLocalizedErrors = className => Component => {
  function WithLocalizedErrors(props, context) {
    const { localizedContent } = context;
    const errorMessages = (localizedContent || {}).errorMessages || {};

    const getLocalizedErrorMessage = ({ code, message }) => {
      if (code) {
        return errorMessages[`error_${className}-${code}`];
      }
      if (message) {
        return errorMessages[message] || message;
      }
      return null;
    };

    return <Component {...props} getLocalizedErrorMessage={getLocalizedErrorMessage} />;
  }

  WithLocalizedErrors.contextTypes = { localizedContent: PropTypes.object.isRequired };
  WithLocalizedErrors.propTypes = { getLocalizedErrorMessage: PropTypes.func };

  return WithLocalizedErrors;
};

export default withLocalizedErrors;
