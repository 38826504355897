import React from 'react';
import PropTypes from 'prop-types';

import withLocalizedContent from '../../language/withLocalizedContent';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';

function ImportUsersCheckingData({ localizedContent: { importUsersCheckingData: content } }) {
  return <PopoutPanelIconHeading type={types.LOADING} title={content.heading} />;
}

ImportUsersCheckingData.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('importUsersCheckingData')(ImportUsersCheckingData);
