import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import getCurrentUser from '../../redux/selectors/getCurrentUser';
import ClassProgress from '../../components/ClassProgress/ClassProgress';
import mapLearnerGroupToRecords from '../../components/GradebookTable/mapLearnerGroupToRecords';
import flipSortDirection from '../../components/GradebookTable/flipSortDirection';
import actions from '../../redux/actions';
import { getProductLocksRequest } from '../../redux/actions/productLocks.js';
import { loadSavedSettings, storeSavedSettings } from '../../redux/reducers/savedSettings';
import { OPTIONS as SPACING_OPTIONS } from '../../components/SpacingOptions/SpacingOptions';
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';
import getIndividualAndLinkedProducts from './Services/getIndividualAndLinkedProducts';

class ClassProgressPage extends Component {
  constructor(props) {
    super(props);
    const { hierarchy } = this.props;
    this.state = {
      usePercentages: !(hierarchy || '').includes('Section'),
      useCompletedOnly: !(hierarchy || '').includes('Section')
    };
  }

  componentDidMount() {
    const {
      loadSavedSettingsAction,
      loadGradebookDetailsAction,
      getProductLocksRequestAction,
      sectionUid,
      params,
      products,
      productId
    } = this.props;
    console.log('[ClassProgressPage] Initialising page with props: ', this.props);
    loadSavedSettingsAction();
    loadGradebookDetailsAction(sectionUid, 'group', params);
    const productIsbn = products?.[productId]?.isbn ?? '';
    getProductLocksRequestAction({ orgId: params.orgId, classId: params.classroomId, isbn: productIsbn });
  }

  componentDidUpdate(prevProps) {
    const {
      loadGradebookDetailsAction,
      getProductLocksRequestAction,
      sectionUid,
      params,
      products,
      productId
    } = this.props;
    if (sectionUid !== prevProps.sectionUid) {
      loadGradebookDetailsAction(sectionUid, 'group', params);
    }
    if (prevProps.params.itemId !== productId) {
      const productIsbn = products?.[productId]?.isbn ?? '';
      getProductLocksRequestAction({ orgId: params.orgId, classId: params.classroomId, isbn: productIsbn });
    }
  }

  componentWillUnmount() {
    const { gradebookDetailsLoadingAction } = this.props;
    gradebookDetailsLoadingAction();
  }

  /**
   * Sets both state and the URL serarch query string with the same values
   *
   * @param {Object} key The object to set
   */
  _loadGradebookClass = () => {
    const { loadGradebookDetailsAction, sectionUid, params } = this.props;
    return loadGradebookDetailsAction(sectionUid, 'group', params);
  };

  _loadProductLocks = () => {
    const { products, params, productId, getProductLocksRequestAction } = this.props;
    const productIsbn = products?.[productId]?.isbn ?? '';
    return getProductLocksRequestAction({ orgId: params.orgId, classId: params.classroomId, isbn: productIsbn });
  };

  _setStateAndQueryString = key => {
    const newState = {
      sortKey: key,
      sortDirection: flipSortDirection(key)
    };
    // eslint-disable-next-line no-restricted-globals
    history.pushState(newState, 'Sorting', `?${qs.stringify(newState)}${window.location.hash}`);
    this.setState(newState);
  };

  /**
   * Switches the spacing config for gradebook.
   *
   * @param {Object} target The target spacing button
   */
  switchSpacing = ({ target }) => {
    const { storeSavedSettingsAction } = this.props;

    return storeSavedSettingsAction({ tableSpacing: target.id });
  };

  /**
   * Switches and updates the attempt.
   *
   * @param {String} attemptFilter The key value for the attempt
   */
  switchAttempt = attemptFilter => {
    const {
      storeSavedSettingsAction,
      loadGradebookDetailsAction,
      getProductLocksRequestAction,
      sectionUid,
      params,
      products,
      productId
    } = this.props;
    storeSavedSettingsAction({ attemptFilter });
    loadGradebookDetailsAction(sectionUid, 'group', params);
    const productIsbn = products?.[productId]?.isbn ?? '';
    getProductLocksRequestAction({ orgId: params.orgId, classId: params.classroomId, isbn: productIsbn });
  };

  /**
   * Slide for update highlight scores of gradebook.
   *
   * @param {String} value update the state of range value
   */
  sliderOnchange = value => {
    const { storeSavedSettingsAction } = this.props;
    storeSavedSettingsAction({ rangeValue: value });
  };

  addProductLocks = () => {
    const { gradebookData = {}, productLockedNodes = [] } = this.props;
    Object.values(gradebookData).forEach(user => {
      ['levelAggregation', 'activityScore'].forEach(key => {
        if (Array.isArray(user[key])) {
          user[key].forEach(entry => {
            if (productLockedNodes.some(node => entry.uId.includes(node))) {
              entry.locked = true;
            }
          });
        }
      });
    });
    return gradebookData;
  };

  render() {
    const {
      gradebookLoading,
      failure,
      tableSpacing = SPACING_OPTIONS.MEDIUM,
      attemptFilter,
      classTitle = 'Class',
      hierarchy,
      productTitle = 'Product',
      params,
      teacherFullName,
      rangeValue,
      useFilterByScore,
      initialiseSearch,
      classTeachers,
      classTeachersLoading,
      products,
      classAssignments,
      profileAssignments,
      role,
      productType,
      storeSavedSettingsAction
    } = this.props;
    const { usePercentages, useCompletedOnly } = this.state;
    const { sortKey = 'none', sortDirection = 'none' } = qs.parse(window.location.search);
    const allProducts = getIndividualAndLinkedProducts(products, classAssignments, profileAssignments);
    const gradebookDataRecords = this.addProductLocks();

    return (
      <ClassProgress
        role={role}
        params={params}
        products={allProducts}
        records={mapLearnerGroupToRecords(gradebookDataRecords)}
        sortKey={sortKey}
        sortDirection={sortDirection}
        sortOnChange={key => this._setStateAndQueryString(key)}
        tableSpacing={tableSpacing}
        tableSpacingOnChange={this.switchSpacing}
        rangeValue={rangeValue}
        rangeValueOnchange={this.sliderOnchange}
        usePercentages={usePercentages}
        useCompletedOnly={useCompletedOnly}
        useFilterByScore={useFilterByScore}
        onUseFilterByScoreOnChange={() => storeSavedSettingsAction({ useFilterByScore: !useFilterByScore })}
        onUsePercentagesOnChange={() => this.setState({ usePercentages: !usePercentages })}
        onUseCompletedOnlyOnChange={() => this.setState({ useCompletedOnly: !useCompletedOnly })}
        attemptFilter={attemptFilter}
        attemptFilterOnChange={this.switchAttempt}
        isLoading={gradebookLoading}
        failure={failure}
        classTitle={classTitle}
        hierarchy={hierarchy}
        productTitle={productTitle}
        teacherFullName={teacherFullName}
        initialiseSearch={initialiseSearch}
        classTeachers={classTeachers}
        classTeachersLoading={classTeachersLoading}
        loadGradebookClass={this._loadGradebookClass}
        loadProductLocks={this._loadProductLocks}
        productType={productType}
      />
    );
  }
}

ClassProgressPage.propTypes = {
  params: PropTypes.object.isRequired,
  gradebookLoading: PropTypes.bool.isRequired,
  gradebookData: PropTypes.object.isRequired,
  failure: PropTypes.bool.isRequired,
  tableSpacing: PropTypes.string.isRequired,
  attemptFilter: PropTypes.string.isRequired,
  sectionUid: PropTypes.string.isRequired,
  loadSavedSettingsAction: PropTypes.func.isRequired,
  storeSavedSettingsAction: PropTypes.func.isRequired,
  loadGradebookDetailsAction: PropTypes.func.isRequired,
  getProductLocksRequestAction: PropTypes.func.isRequired,
  gradebookDetailsLoadingAction: PropTypes.func.isRequired,
  classTitle: PropTypes.string,
  hierarchy: PropTypes.string,
  productTitle: PropTypes.string,
  teacherFullName: PropTypes.string,
  rangeValue: PropTypes.number,
  useFilterByScore: PropTypes.bool,
  initialiseSearch: PropTypes.func,
  classTeachers: PropTypes.array,
  classTeachersLoading: PropTypes.bool,
  products: PropTypes.object,
  classAssignments: PropTypes.object,
  profileAssignments: PropTypes.object,
  role: PropTypes.string,
  productType: PropTypes.string,
  productId: PropTypes.string,
  productLockedNodes: PropTypes.array
};

export default connect(
  (state, { sectionUid, params }) => {
    const currentUserData = getCurrentUser(state);
    return {
      params,
      products: state.products.data,
      classAssignments: state.search.classAssignments?.data,
      profileAssignments: state.search.profileAssignments?.data,
      sectionUid,
      gradebookLoading: state.gradebookClassReport.loading,
      gradebookData: state.gradebookClassReport.group,
      failure: state.gradebookClassReport.failure,
      hierarchy: state.gradebookClassReport.hierarchy,
      tableSpacing: state.savedSettings.settings.tableSpacing,
      attemptFilter: state.savedSettings.settings.attemptFilter,
      productTitle: state.gradebookClassReport.selectedProduct,
      teacherFullName: `${currentUserData.firstname} ${currentUserData.lastname}`,
      rangeValue: Number(state.savedSettings.settings.rangeValue),
      useFilterByScore: state.savedSettings.settings.useFilterByScore,
      classTeachers:
        state.search.classTeachers && state.people.data
          ? Object.values(pick(state.people.data, state.search.classTeachers.ids))
          : [],
      classTeachersLoading: typeof state.search.classTeachers === 'object' ? state.search.classTeachers.loading : true,
      productType: state.gradebookClassReport.productType,
      productId: params.itemId,
      platform: state.products?.data[params.itemId]?.platform,
      productLockedNodes: state.productLocks.productLocks.lockedNodes
    };
  },
  {
    loadSavedSettingsAction: loadSavedSettings,
    storeSavedSettingsAction: storeSavedSettings,
    loadGradebookDetailsAction: actions.gradebookClassReportRequest,
    gradebookDetailsLoadingAction: actions.gradebookClassReportLoading,
    getProductLocksRequestAction: getProductLocksRequest,
    initialiseSearch: initialiseInstance
  }
)(ClassProgressPage);
