import signedFetch from '../util/signedFetch.js';
import { getCurrentPlatform } from '../../../../utils/platform';

/**
 *  - orgId: Integer - The organisation to check for user membership and invitation status in
 *  - userId: Integer - The user’s id
 *  - updateAction: CANCEL or REMIND
 *  - platformCode: CES, OLB, ORB, HUB
 */
export default (updateAction, orgId, userId) => {
  const platformCode = getCurrentPlatform().toUpperCase() || '';
  return signedFetch(
    'updateUserInvitation',
    `${__API_BASE__}/org/${encodeURIComponent(orgId)}/user/${encodeURIComponent(userId)}/invite`,
    'PUT',
    {
      updateAction,
      platformCode
    }
  );
};
