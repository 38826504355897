import { put } from 'redux-saga/effects';
import { orgSubscriptionType } from '@oup/shared-node-browser/subscriptions';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import validateStartEndDates from '../../../utils/validateStartEndDates';
import { ISO_DATE } from '../../../../../globals/dateFormats';
import getOrgDetailsApi from '../../../apiCalls/organisations/getOrgDetails.api.js';
import searchSubscriptionsApi from '../../../apiCalls/search/searchSubscriptions.api';
import { updateFormData } from '../../../../actions/orgSubscription';
import { getProductsRequest } from '../../../../actions/products';

function* validateOrgSubscriptionInputUsingSchema(
  paramSchema,
  bodySchema,
  completedActionType,
  input,
  additionalProps = {}
) {
  const paramErrors = yield validateInputUsingSchema(paramSchema, null, input);
  const bodyErrors = yield validateInputUsingSchema(bodySchema, null, input);
  const { startDate, endDate, orgId = input.orgId, subscriptionId = input.subscriptionId } = additionalProps;
  const dateErrors = startDate && endDate ? validateStartEndDates(input, { startDate, endDate }, ISO_DATE) : {};
  const errors = { ...bodyErrors, ...dateErrors };

  Object.keys(paramErrors).forEach(key => {
    if (!errors[key]) {
      errors[key] = paramErrors[key];
    }
  });

  errors.isBlocked = false;
  if (input.orgId !== undefined) {
    if (input.orgId) {
      // Hide licence subscriptionNotFound validation since the result for subscriptionNotFound is no longer valid
      if (typeof subscriptionId === 'string') errors.subscriptionNotFound = undefined;
      if (!errors.orgId) {
        const response = yield getOrgDetailsApi(orgId);
        errors.orgNotFound = response.status === 'error';

        if (response.data) {
          if (response.data?.isBlocked) {
            errors.isBlocked = true;
          }
          const subscriptionType = orgSubscriptionType(response.data);
          yield put(updateFormData({ orgName: response.data.name, subscriptionType }));
          yield put(getProductsRequest(subscriptionType));
        }
      }
    } else {
      yield put(updateFormData({ orgName: '' }));
    }
  }

  if (
    (input.orgId || input.subscriptionId) &&
    orgId &&
    subscriptionId &&
    !errors.orgId &&
    !errors.orgNotFound &&
    !errors.subscriptionId
  ) {
    const subscriptionsResult = yield searchSubscriptionsApi({
      filters: {
        orgId,
        active: true,
        notStarted: true,
        expired: true,
        expiring: true
      },
      term: '',
      size: 10000, // max AWS cloud search size limit
      start: 0
    });

    errors.subscriptionNotFound = false; // reset a previous error

    if (subscriptionsResult.status === 'success') {
      const { subscriptions } = subscriptionsResult.data;
      const subscriptionDetails = Object.values(subscriptions).find(item => item.subscriptionId === subscriptionId);
      if (subscriptionDetails) {
        yield put(
          updateFormData({
            currentStartDate: subscriptionDetails.startDate,
            currentEndDate: subscriptionDetails.endDate
          })
        );
      } else {
        errors.subscriptionNotFound = true;
      }
    }
  }

  yield put({
    type: completedActionType,
    payload: errors
  });
  return errors;
}

export default validateOrgSubscriptionInputUsingSchema;
