import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './IconEmblem.scss';
import colors from '../../styles/constants/_colors.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

export const GlyphIcon = GLYPHS;
export const Color = colors;
export const EmblemSize = {
  SMALL: styles.SMALL,
  MEDIUM: styles.MEDIUM,
  LARGE: styles.LARGE,
  LARGE_DOUBLE: styles.LARGE_DOUBLE
};
export const EmblemShape = {
  CIRCLE: styles.CIRCLE,
  SQUARE: styles.SQUARE
};

function IconEmblem({
  icon = GlyphIcon.ICON_TICK,
  iconColour = Color.background,
  size = EmblemSize.SMALL,
  shape = EmblemShape.CIRCLE,
  colour = Color.primary,
  className = null
}) {
  return (
    <div
      style={{ backgroundColor: colour, fill: iconColour }}
      className={classnames(styles.emblem, size, shape, className)}
    >
      <SVGIcon className={styles.icon} glyph={icon} />
    </div>
  );
}
IconEmblem.propTypes = {
  icon: PropTypes.oneOf(Object.values(GlyphIcon)),
  iconColour: PropTypes.oneOf(Object.values(Color)),
  size: PropTypes.oneOf(Object.values(EmblemSize)),
  shape: PropTypes.oneOf(Object.values(EmblemShape)),
  colour: PropTypes.oneOf(Object.values(Color)),
  className: PropTypes.string
};

export default IconEmblem;
