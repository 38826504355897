import { put } from 'redux-saga/effects';
import updateOrgSubscriptionRequestBodySchema from '../../../../../../static/schema/draft-4/edit-org-subscriptions-request-body.json';
import updateOrgSubscriptionRequestParamsSchema from '../../../../../../static/schema/draft-4/edit-org-subscriptions-request-params.json';
import actions from '../../../../actions';
import * as t from '../../../../actionTypes';
import signedFetch from '../../../apiCalls/util/signedFetch';
import validateOrgSubscriptionInputUsingSchema from './validateOrgSubscriptionInputUsingSchema';

function* updateOrgSubscription(input) {
  const { orgId, subscriptionId, ...body } = input;
  const errors = yield validateOrgSubscriptionInputUsingSchema(
    updateOrgSubscriptionRequestParamsSchema,
    updateOrgSubscriptionRequestBodySchema,
    t.VALIDATE_UPDATE_ORG_SUBSCRIPTION_COMPLETED,
    input
  );
  const hasErrors = Object.values(errors).some(Boolean);
  if (hasErrors) {
    yield put(actions.validateUpdateOrgSubscriptionInputError());
    return;
  }
  const response = yield signedFetch(
    'updateOrgSubscription',
    `${__API_BASE__}/org/${orgId}/subscription/${subscriptionId}`,
    'PUT',
    body
  );
  if (response.status === 'success') {
    yield put(actions.updateOrgSubscriptionSuccess(response.data));
    return;
  }
  yield put(actions.updateOrgSubscriptionFailure(response.message));
}

export default updateOrgSubscription;
