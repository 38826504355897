import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { bgClass } from '../../utils/colorClassNames';
import PendingMarkIcon from '../PendingMarkIcon/PendingMarkIcon';
import userRoles, { roleIsAtLeast } from '../../globals/userRoles';
import styles from './ProgressBar.scss';
import { featureIsEnabled } from '../../globals/envSettings';
import PencilReviewButton from '../PencilReviewButton/PencilReviewButton';

/** NOTE: This component isn't built with the HTML5 <progress> element for the following reasons:
 *  - Differing browser styling support
 *    - No transition in FireFox
 *    - No inner-element border-radius in IE
 *  - ARIA labels allow use to describe plain <div> elements as progress bars
 */
export const progressTypes = {
  PRIMARY: 'PRIMARY', // Blue style default bar
  SECONDARY: 'SECONDARY' // Secondary flat style
};

/** The component renders a white box with a colored top border and heading label. Contains generic content. */
function ProgressBar({
  type = progressTypes.PRIMARY,
  percentage,
  label,
  ariaLabelText,
  color,
  customClassName = '',
  content = {},
  displayShortLabelOnMobile = false,
  pendingMark = false,
  role,
  addPencilReview = false,
  getActivityLink
}) {
  // Create a unique ID value for the aria label
  // Just strip out all non-letter characters
  const progressLabelId = `pl-${ariaLabelText.replace(/[^a-zA-Z]/g, '')}`;
  return (
    <div className={`${styles.progressBar} ${customClassName}`}>
      {/* Paragraph element is only visible to screen readers */}
      <p id={progressLabelId} className="a11y-hide">
        {ariaLabelText}
      </p>
      <div className={styles.progressBarMainContainer}>
        {featureIsEnabled('hub-gradebook-teacher-marked-icons') &&
        pendingMark &&
        roleIsAtLeast(userRoles.TEACHER, role) ? (
          <PendingMarkIcon customClassName={styles.pendingIconContainer} />
        ) : null}
        <div className={styles.progressBarContainer}>
          <div
            className={classnames(
              styles.progressBar__container,
              styles[`type-${type}`],
              label && styles['progressBar__container--with-label']
            )}
            role="progressbar"
            aria-valuenow={percentage}
            aria-valuemin="0"
            aria-valuemax="100"
            aria-labelledby={progressLabelId}
          >
            <div
              className={classnames(styles.progressBar__value, color ? bgClass(color) : styles.defaultColor)}
              style={{ width: `${percentage}%` }}
            />
          </div>

          {addPencilReview ? (
            <div className={styles.progressBarWithPencil}>
              <PencilReviewButton getActivityLink={getActivityLink} />
              {label}
            </div>
          ) : (
            <span className={styles.progressBar__label}>{label}</span>
          )}

          {displayShortLabelOnMobile && (
            <p className={styles.shortLabel}>
              {roleIsAtLeast(userRoles.LEARNER, role) ? content.scoreShort : content.averageScoreShortLabel}{' '}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  /** The style type of bar */
  type: PropTypes.string,
  /** The percentage of the progress bar to be filled. */
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** String label */
  label: PropTypes.string,
  /** The ARIA label text should be given to describe the process being completed. E.G. File upload percentage */
  ariaLabelText: PropTypes.string.isRequired,
  /** Color of the progress bar */
  color: PropTypes.string,
  /** Custom style */
  customClassName: PropTypes.string,
  content: PropTypes.object,
  displayShortLabelOnMobile: PropTypes.bool,
  pendingMark: PropTypes.bool,
  role: PropTypes.string,
  addPencilReview: PropTypes.bool,
  getActivityLink: PropTypes.func
};

export default ProgressBar;
