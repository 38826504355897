import * as t from '../actionTypes';

const initialState = {
  orgDetails: {
    id: null,
    name: '',
    academicYear: null,
    locationId: null,
    classrooms: [],
    loading: false,
    success: false,
    failure: false
  },
  errors: {},
  loading: false,
  success: false,
  failure: false,
  group: {},
  learner: {},
  levelLoading: true,
  level: null
};

const _getLevels = dataObj => {
  const newDataObj = dataObj;
  if (newDataObj.levels.list.length !== 0) {
    newDataObj.levels.list.forEach(levelObj => {
      levelObj.name = levelObj.name.split(' ').pop();
    });
    newDataObj.levels.list = newDataObj.levels.list.sort((prev, curr) => prev.id - curr.id);
  }
  return newDataObj;
};

const _mapClassrooms = data =>
  Object.entries(data.classes).map(([id, classroom]) => ({ id, name: classroom.name, archived: classroom.archived }));

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_REQUEST:
      return {
        ...state,
        orgDetails: {
          ...state.orgDetails,
          loading: true,
          success: false,
          failure: false
        }
      };
    case t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_SUCCESS:
      return {
        ...state,
        orgDetails: {
          ...payload,
          loading: false,
          success: true,
          failure: false
        }
      };
    case t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE:
      return {
        ...state,
        orgDetails: {
          ...state.orgDetails,
          loading: false,
          success: false,
          failure: true
        }
      };
    case t.GRADEBOOK_GROUP_CLASS_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        failure: false
      };
    case t.GRADEBOOK_GROUP_CLASS_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failure: true,
        group: {},
        errors: {
          ...state.errors,
          ...payload
        }
      };
    case t.GRADEBOOK_GROUP_CLASS_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        group: _getLevels(payload)
      };
    case t.GRADEBOOK_LEVEL_PANEL_REQUEST:
      return {
        ...state,
        levelLoading: true,
        levelPanelRequest: payload
      };
    case t.GRADEBOOK_LEVEL_PANEL_ERROR:
      return {
        ...state,
        levelLoading: false,
        errors: {
          ...state.errors,
          ...payload
        }
      };
    case t.GRADEBOOK_LEVEL_PANEL_SUCCESS:
      return {
        ...state,
        levelLoading: false,
        level: {
          ...state.level,
          ...payload
        }
      };
    case t.GET_CLASSROOMS_REQUEST:
      return {
        ...state,
        orgDetails: {
          ...state.orgDetails,
          loading: true,
          success: false,
          failure: false
        }
      };
    case t.GET_CLASSROOMS_SUCCESS:
      return {
        ...state,
        orgDetails: {
          ...state.orgDetails,
          classrooms: _mapClassrooms(payload),
          loading: false,
          success: true,
          failure: false
        }
      };
    case t.GET_CLASSROOMS_FAILURE:
      return {
        ...state,
        orgDetails: {
          ...state.orgDetails,
          loading: false,
          success: false,
          failure: true
        }
      };
    default:
      return state;
  }
};
