/*
 * Initial practice to define error code is :
 * APINAME_ERROR_CODENUMBER : ERROR_CODE
 */

export const ERROR_CONSTANTS = {
  ADD_ORG_LICENCE_ERROR_3157: '3157',
  ADD_ORG_LICENCE_ERROR_2007: '2007'
};

export default ERROR_CONSTANTS;
