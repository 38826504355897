import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styles from './LTIOpener.scss';

import { retrieveLTIConsumerRequest } from '../../redux/actions/ngsLTIOpener';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

class LTIOpener extends Component {
  constructor() {
    super();
    this.state = {
      locked: false
    };
  }

  componentDidMount() {
    const { content, retrieveLTIConsumer } = this.props;
    retrieveLTIConsumer(content);
  }

  shouldComponentUpdate() {
    const { locked } = this.state;
    return !locked;
  }

  componentDidUpdate({ content: previousContent, consumer: previousConsumer }) {
    const { content, consumer, retrieveLTIConsumer, consumerReceivedAt, preventUpdate } = this.props;
    const clickSecondsLimit = 2;

    if (content.external_id !== previousContent.external_id) {
      retrieveLTIConsumer(content);
    } else if (consumer && consumer.lti) {
      const lastTimeOpened = consumerReceivedAt || Date.now();
      const actualTime = Date.now();
      const differenceInSeconds = Math.abs(Math.floor((lastTimeOpened - actualTime) / 1000));

      if (differenceInSeconds > clickSecondsLimit) {
        retrieveLTIConsumer(content);
      }
    }

    if (consumer && consumer !== previousConsumer && consumer.lti && consumer.lti !== (previousConsumer || {}).lti) {
      this.populateAndSubmitForm(consumer);
      if (preventUpdate) {
        this.setState({ locked: true });
      }
    }
  }

  _formRef = form => {
    this.ltiForm = form;
  };

  populateAndSubmitForm(consumer) {
    const { formTarget } = this.props;

    if (this.ltiForm) {
      this.ltiForm.querySelectorAll('input').forEach(input => input.remove());

      Object.entries(consumer.lti).map(([name, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;
        return this.ltiForm.appendChild(input);
      });

      this.ltiForm.action = consumer.form.uri;
      this.ltiForm.method = 'POST';
      this.ltiForm.target = formTarget;
      this.ltiForm.submit();
      if (formTarget !== 'iframe') {
        window.postMessage({ actionCode: 'BOOK_CLOSED' }, window.location.origin);
      }
    }
  }

  render() {
    const { className, formTarget, loading, error } = this.props;

    if (error) {
      return <p className="error-paragraph">Something went wrong</p>;
    }

    if (loading && formTarget === 'iframe') {
      return <LoadingSpinner text="Loading content..." />;
    }

    return (
      <div className={classnames(styles.container, className, formTarget === 'iframe' ? styles.iframeOpener : '')}>
        <form ref={this._formRef} />
        <iframe title="iframe" name="iframe" scrolling="auto" />
      </div>
    );
  }
}

LTIOpener.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
  formTarget: PropTypes.string.isRequired,
  consumer: PropTypes.object,
  preventUpdate: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  consumerReceivedAt: PropTypes.number,
  retrieveLTIConsumer: PropTypes.func.isRequired
};

export default connect(
  state => {
    const {
      ngsLTIOpener: { consumer, success, error, receivedAt }
    } = state;

    return {
      consumer,
      loading: !success,
      error,
      consumerReceivedAt: receivedAt
    };
  },
  dispatch => ({
    retrieveLTIConsumer: content => {
      dispatch(retrieveLTIConsumerRequest(content));
    }
  })
)(LTIOpener);
