/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/namespace */
/* eslint-disable import/default */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ORG_REGISTRATION_STATUS } from '@oup/shared-node-browser/constants';
import ENTITY_TYPES from '../../../globals/entityTypes';
import EntityPageHeader from '../../../components/EntityPageHeader/EntityPageHeader';
import UrlControlledTabs from '../../../components/UrlControlledTabs/UrlControlledTabs';
import withDataRecency from '../../../dataRecency/withDataRecency';
import colors from '../../../globals/colors';
import withLocalizedContent from '../../../language/withLocalizedContent';
import OrganisationsTab from '../tabs/OrganisationsTab/OrganisationsTab';
import OupStaffTab from '../tabs/StaffTab/OupStaffTab';

function AdminOrganisations({
  localizedContent: { mySchoolPage: content },
  location,
  openEditPanel,
  organisationCustomId,
  organisationName,
  orgsDataRecency,
  oupStaffDataRecency,
  params
}) {
  const items = [
    {
      urlName: 'organisations',
      tabText: content.tabs_organisation_text,
      isContentLoading: orgsDataRecency.syncing,
      isContentLoaded: orgsDataRecency.synced,
      panelContent: <OrganisationsTab tabName={params.tabName} panelName={params.panelName} />
    },
    // Hide the all users tab
    // {
    //   urlName: 'otherUsers',
    //   tabText: content.tabs_otherUsers_text,
    //   isContentLoading: othersDataRecency.syncing,
    //   isContentLoaded: othersDataRecency.synced,
    //   panelContent: <OtherUsersTab tabName={params.tabName} />
    // },
    {
      urlName: 'oupStaff',
      tabText: content.tabs_oupStaff_text,
      isContentLoading: oupStaffDataRecency.syncing,
      isContentLoaded: oupStaffDataRecency.synced,
      panelContent: <OupStaffTab tabName={params.tabName} panelName={params.panelName} />
    }
  ];

  return (
    <div className="pad-bot8">
      <div className="color-primary-dark">
        <EntityPageHeader
          entityType={ENTITY_TYPES.ORGANISATION}
          entityTitle={organisationName}
          entitySubtitle={organisationCustomId}
          editButtonOnClick={openEditPanel}
          displayEditButton
        />
      </div>
      <UrlControlledTabs
        backgroundColor={colors.PRIMARY_BLUE}
        tabName={params.tabName}
        pathname={location.pathname}
        items={items}
      />
    </div>
  );
}

AdminOrganisations.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  location: PropTypes.any,
  openEditPanel: PropTypes.func,
  organisationCustomId: PropTypes.string.isRequired,
  organisationName: PropTypes.string.isRequired,
  orgsDataRecency: PropTypes.object,
  oupStaffDataRecency: PropTypes.object,
  params: PropTypes.any
};

export default compose(
  withDataRecency('orgs', 'oupStaff', 'others'),
  withLocalizedContent('mySchoolPage'),
  connect((state, props) => {
    const organisation = state.organisations.data[props.organisationId] || {};
    return {
      organisationName: organisation.name || '',
      organisationArchived: organisation.archived,
      organisationCustomId: organisation.customId || '',
      orgPending: state.editOrg.registrationStatus === ORG_REGISTRATION_STATUS.PARTIALLY_REGISTERED,
      confirmCloseModal: state.organisationPage.showModal
    };
  })
)(AdminOrganisations);
