/* eslint-disable */
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';

class SearchInitialiser extends Component {
  componentWillMount() {
    const {
      orgLoading,
      initialise,
      isInstanceLoading,
      searchSource,
      isAsYouType,
      initialFilters,
      pageSize,
      sort
    } = this.props;
    if ((!orgLoading && orgLoading !== undefined && !isInstanceLoading) || (orgLoading && !isInstanceLoading)) {
      console.log(`%c::SearchInitialiser::-searchInstance-${searchSource}`, 'padding:2px;background:green;color:white');
      initialise(searchSource, isAsYouType, initialFilters, pageSize, sort);
    }
  }

  render() {
    const { instanceReady, children } = this.props;

    // Render the wrapped children when ready.
    return instanceReady ? children : null;
  }
}

SearchInitialiser.propTypes = {
  // Prop Defined
  searchSource: PropTypes.string.isRequired,
  isAsYouType: PropTypes.bool,
  initialFilters: PropTypes.object.isRequired,
  sort: PropTypes.string.isRequired,
  pageSize: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,

  // State Defined
  instanceReady: PropTypes.bool.isRequired,

  // Actions
  initialise: PropTypes.func.isRequired,
  orgLoading: PropTypes.bool,
  isInstanceLoading: PropTypes.bool
};

export default connect(
  (state, props) => ({
    instanceReady: !!state.search[props.searchSource],
    orgLoading: state.organisationPage.loading,
    isInstanceLoading:
      state.search[props.searchSource] && state.search[props.searchSource].instanceLoadingStatus
        ? false
        : Object.keys(state.search).length &&
          state.search[props.searchSource] &&
          state.search[props.searchSource].loading
  }),
  {
    initialise: initialiseInstance
  }
)(SearchInitialiser);

SearchInitialiser.propTypes = {
  // Prop Defined
  searchSource: PropTypes.string.isRequired,
  isAsYouType: PropTypes.bool,
  initialFilters: PropTypes.object.isRequired,
  // pageSize: PropsTypes.string,
  children: PropTypes.element.isRequired,

  // State Defined
  instanceReady: PropTypes.bool.isRequired,

  // Actions
  initialise: PropTypes.func.isRequired,
  orgLoading: PropTypes.bool,
  isInstanceLoading: PropTypes.bool
};
