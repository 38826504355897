import React from 'react';
import PropTypes from 'prop-types';

import ActionList, { Action } from '../ActionList/ActionList';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import EntityRepresentation from '../EntityRepresentation/EntityRepresentation';
import PopoutPanelIconHeading, { types as iconHeadingTypes } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';

import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './EditOrgSuccess.scss';
import { isHubMode } from '../../utils/platform';

function EditOrgSuccess({
  localizedContent: { editOrgSuccessComponent: content },
  name,
  orgUniqueId,
  primaryButtonLabel,
  secondaryButtonLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick
}) {
  return (
    <ScrollContainer
      headerContent={
        isHubMode() ? (
          <PopoutPanelIllustrationHeading
            type={iconHeadingTypes.VALID}
            title={content.title}
            illustrationSrc={HubIllustrationConstants.SUCCESS}
            illustrationAltText={HubIllustrationAltText.SUCCESS}
          />
        ) : (
          <PopoutPanelIconHeading type={iconHeadingTypes.VALID} title={content.title} />
        )
      }
      footerContent={
        <ActionList title={content.actions_title}>
          <Action label={secondaryButtonLabel} onClick={onSecondaryButtonClick} />
          <Action label={primaryButtonLabel} primary onClick={onPrimaryButtonClick} />
        </ActionList>
      }
    >
      <div className={styles.entity}>
        <EntityRepresentation
          id="editOrgSuccess"
          thumbnailSize={thumbnailSizes.MEDIUM}
          thumbnailColor={colors.ORGANIZATION}
          thumbnailGlyph={GLYPHS.ICON_ORG}
          line1Text={name}
          line2Text={orgUniqueId}
        />
      </div>
    </ScrollContainer>
  );
}

EditOrgSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  orgUniqueId: PropTypes.string.isRequired,
  primaryButtonLabel: PropTypes.string.isRequired,
  secondaryButtonLabel: PropTypes.string.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  onSecondaryButtonClick: PropTypes.func.isRequired
};

export default withLocalizedContent('editOrgSuccessComponent')(EditOrgSuccess);
