import React from 'react';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../language/withLocalizedContent';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Tooltip from '../Tooltip/Tooltip';
import styles from './StatusIcon.scss';

/**
 * An enumeration representing status types displayable by StatusIcon.
 *
 * @type {Object}
 */
export const StatusType = {
  ACTIVE: Symbol('ACTIVE'),
  ARCHIVED: Symbol('ARCHIVED'),
  DELETED: Symbol('DELETED'),
  PENDING: Symbol('PENDING'),
  PROCESSING: Symbol('PROCESSING'),
  PRODUCT_VALID: Symbol('PRODUCT_VALID'),
  PRODUCT_EXPIRING: Symbol('PRODUCT_EXPIRING'),
  PRODUCT_EXPIRED: Symbol('PRODUCT_EXPIRED'),
  PRODUCT_NO_LICENCE: Symbol('PRODUCT_NO_LICENCE'),
  PRODUCT_NO_LICENCE_TO_ASSIGN: Symbol('PRODUCT_NO_LICENCE_TO_ASSIGN'),
  USER_INVITED: Symbol('USER_INVITED'),
  USER_REQUESTED: Symbol('USER_REQUESTED'),
  USER_REMOVED: Symbol('USER_REMOVED'),
  ALL_REQUIRED: Symbol('ALL_REQUIRED'),
  SOME_REQUIRED: Symbol('SOME_REQUIRED'),
  NONE_REQUIRED: Symbol('NONE_REQUIRED'),
  INFO: Symbol('INFO')
};

export const STATUS_ICONS = {
  [StatusType.ACTIVE]: GLYPHS.ICON_CHECK_CIRCLE,
  [StatusType.ARCHIVED]: GLYPHS.ICON_ARCHIVED,
  [StatusType.DELETED]: GLYPHS.ICON_DELETED,
  [StatusType.PENDING]: GLYPHS.ICON_PENDING,
  [StatusType.PROCESSING]: GLYPHS.ICON_LOADING,
  [StatusType.USER_REQUESTED]: GLYPHS.REQUESTED,
  [StatusType.USER_REMOVED]: GLYPHS.ICON_REMOVED_CIRCLE,

  [StatusType.PRODUCT_VALID]: GLYPHS.ICON_CHECK_CIRCLE,
  [StatusType.PRODUCT_EXPIRING]: GLYPHS.ICON_WARNING_CIRCLE,
  [StatusType.PRODUCT_EXPIRED]: GLYPHS.ICON_ERROR_CIRCLE,
  [StatusType.PRODUCT_NO_LICENCE_TO_ASSIGN]: GLYPHS.ICON_ERROR_CIRCLE,
  [StatusType.PRODUCT_NO_LICENCE]: GLYPHS.ICON_ERROR_CIRCLE,
  [StatusType.USER_INVITED]: GLYPHS.ICON_INVITED,
  [StatusType.NONE_REQUIRED]: GLYPHS.ICON_CHECK_CIRCLE,
  [StatusType.SOME_REQUIRED]: GLYPHS.ICON_WARNING_CIRCLE,
  [StatusType.ALL_REQUIRED]: GLYPHS.ICON_ERROR_CIRCLE,
  [StatusType.INFO]: GLYPHS.ICON_INFORMATION_CIRCLE
};

const STATUS_TITLE_KEYS = {
  [StatusType.ACTIVE]: 'active',
  [StatusType.ARCHIVED]: 'archived',
  [StatusType.DELETED]: 'deleted',
  [StatusType.PENDING]: 'pending',
  [StatusType.PROCESSING]: 'processing',
  [StatusType.USER_REQUESTED]: 'user_requested',
  [StatusType.PRODUCT_VALID]: 'active',
  [StatusType.PRODUCT_EXPIRING]: 'product_expiring',
  [StatusType.PRODUCT_EXPIRED]: 'product_expired',
  [StatusType.PRODUCT_NO_LICENCE_TO_ASSIGN]: 'product_no_licence',
  [StatusType.PRODUCT_NO_LICENCE]: 'product_no_licence',
  [StatusType.USER_INVITED]: 'user_invited'
};

/**
 * An indicator icon with tooltip to represent the status of a record.
 */
function StatusIcon({ type, title, localizedContent: { statusIcon: content }, ...props }) {
  return STATUS_ICONS[type] ? (
    <Tooltip title={title || content[STATUS_TITLE_KEYS[type]]} className={styles.icon}>
      <SVGIcon glyph={STATUS_ICONS[type]} {...props} />
    </Tooltip>
  ) : null;
}

StatusIcon.propTypes = {
  type: PropTypes.symbol.isRequired,
  title: PropTypes.string,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('statusIcon')(StatusIcon);
