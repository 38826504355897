import * as t from '../../actionTypes';

export const initialState = {
  success: false,
  loading: false
};

export default function userConsoleSearchEdit(state = initialState, action) {
  switch (action.type) {
    case t.SEARCH_USER_EDIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case t.SEARCH_USER_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };
    case t.SEARCH_USER_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false
      };
    default:
      return state;
  }
}
