import React, { Component } from 'react';

import breakpoints from '../globals/breakpoints.js';
import debounce from '../utils/debounce.js';

// Returns breakpoint based on window width
const detectBreakpoint = () => {
  let breakpoint;
  const { innerWidth: windowWidth } = window;

  if (window.innerWidth < 560) {
    breakpoint = breakpoints.XXS;
  } else if (window.innerWidth < 640) {
    breakpoint = breakpoints.XS;
  } else if (window.innerWidth < 972) {
    breakpoint = breakpoints.SM;
  } else if (window.innerWidth < 1180) {
    breakpoint = breakpoints.MD;
  } else {
    breakpoint = breakpoints.LG;
  }

  return { breakpoint, windowWidth };
};

/** This function provides a decorator which makes the current breakpoint available as a prop to the ComposedComponent */
export default ComposedComponent =>
  class WithBreakpoint extends Component {
    updateBreakpoint = debounce(() => {
      this.setState({ ...detectBreakpoint() });
    }, 250);

    constructor() {
      super();

      this.state = {
        ...detectBreakpoint()
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateBreakpoint, true);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateBreakpoint, true);
    }

    render() {
      const { breakpoint, windowWidth } = this.state;
      return <ComposedComponent {...this.props} breakpoint={breakpoint} windowWidth={windowWidth} />;
    }
  };
