import { put } from 'redux-saga/effects';

import actions from '../../../../actions';

function* updateImportUsers(updates) {
  yield put(actions.storeImportUsersUpdatesRequest(updates));
  yield put(actions.checkImportUsersRequest());
}

export default updateImportUsers;
