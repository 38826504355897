import { getSelfSelectedRole } from '../../../globals/userRoles';

import {
  MENU_ORIENTATION,
  MODAL_CLOSE_LINK_KEY,
  SESSION_MENU_ORIENTATION,
  SESSION_PROD_LINKS,
  MILISECONDS_TO_AUTOSAVE,
  selfSelectedUserRoles
} from '../../../globals/cptConstants';

import * as t from '../../actionTypes';
import { getAncestorsIds, getLeafsList, findNodeById, invokeActionOnNodes } from './treeHelperMethods';
import { isOcpMode } from '../../../utils/platform.js';

let activityCounter;
const startActivityCounter = state => {
  activityCounter = setTimeout(() => {
    const productLinks = JSON.parse(localStorage.getItem(SESSION_PROD_LINKS)) || {};
    productLinks[state.contentId] = window.location.pathname + window.location.search;
    localStorage.setItem(SESSION_PROD_LINKS, JSON.stringify(productLinks));
  }, MILISECONDS_TO_AUTOSAVE);
};

export const initialState = {
  contentId: '',
  title: '',
  subtitle: '',
  navigation: {
    currentActivity: '',
    currentNodesIds: [],
    currentActivities: [],
    currentActivityIndexInLesson: 0,
    isFirstActivityInLesson: false,
    isLastActivityInLesson: false,
    previousActivity: null,
    nextActivity: null,
    grandparentLevelTitle: '',
    parentLevelTitle: ''
  },
  currentPrintView: '',
  modalCloseLink: '',
  modalBasePathLink: '',
  isModalOpen: false,
  levelContents: [],
  resources: {
    categories: [],
    files: [],
    folders: [],
    items: [],
    categoriesWithData: [],
    foldersWithData: []
  },
  productVariant: null,
  isContentPlayerSidebarOpen: false,
  isResourcesSidebarOpen: false,
  mobileMenuOpen: false,
  menuOrientation: MENU_ORIENTATION.LEFT,
  cptContent: null,
  eReaderBookSignedUrl: null,
  error: false,
  isPrintViewModalOpen: false,
  isDictionaryModalOpen: false,
  lastLaunchedContents: {},
  xApiLaunchToken: null,
  isMobileGamePopupOpen: false,
  isPreviewMode: false,
  previewModeRole: null,
  splashScreen: null,
  dictionarySearchOrder: null,
  dictionaryData: null,
  dictionaryError: null,
  dictionaryEnabled: false,
  progressBarNavigation: '',
  defaultProgressBarNavigation: '',
  hasLicense: true,
  type: null,
  features: {},
  isSwitchBookOpen: false,
  lockedNodes: [],
  isResourceModalPlayerOpen: false,
  resourceModalData: {},
  hideActivities: false,
  isLoading: false
};

export default function structuredContentPlayer(state = initialState, action = {}) {
  switch (action.type) {
    case t.SET_CONTENT: {
      const { levelContents } = action.payload;
      state.splashScreen = action.payload?.splashScreen;
      state.dictionaryEnabled = action.payload?.dictionaryEnabled;
      state.progressBarNavigation = action.payload?.progressBarNavigation;
      state.defaultProgressBarNavigation = action.payload?.progressBarNavigation;
      state.hasLicense = action.payload?.hasLicense;
      state.dictionarySearchOrder = action.payload?.dictionarySearchOrder;
      state.type = action.payload?.type;
      state.features = { ...action.payload?.features };
      state.lockedNodes = action.payload?.lockedNodes;

      if (action.payload.hideActivities) {
        state.hideActivities = true;
      }

      if (state.productVariant !== selfSelectedUserRoles.SELF_TEACHER) {
        invokeActionOnNodes(levelContents, node => {
          if (node.activities && node.activities && node.activities.length > 0) {
            node.activities = node.activities.filter(activity => !activity.isTeacherOnly);
          }
        });
      }

      const response = {
        ...state,
        levelContents
      };

      if (action.payload.resources && action.payload.resources.contents) {
        const resources = action.payload.resources.contents;

        if (state.productVariant !== selfSelectedUserRoles.SELF_TEACHER) {
          resources.items = resources.items.filter(res => res.isTeacherOnly === false);
        }

        // prepare available data structure Folders / Categories
        const parentIds = [...new Set(resources.items.map(item => item.parentId))];
        resources.categoriesWithData = resources.categories.filter(cat => parentIds.indexOf(cat.id) !== -1);
        const folderIds = [...new Set(resources.categoriesWithData.map(cat => cat.parentId))];
        resources.foldersWithData = resources.folders.filter(
          fld => folderIds.indexOf(fld.id) !== -1 || parentIds.indexOf(fld.id) !== -1
        );

        response.resources = resources;
      }

      return response;
    }

    case t.SET_CURRENT_CONTENT: {
      clearTimeout(activityCounter);
      startActivityCounter(state);

      delete state.navigation.disableNext;
      delete state.navigation.disablePrevious;

      const hierarchicalContent = state.cptContent.contents;
      const hierarchicalContentChildren = hierarchicalContent.children;
      const currentLeafObj = findNodeById(hierarchicalContentChildren, action.payload);

      if (currentLeafObj) {
        const currentNodesIds = getAncestorsIds(hierarchicalContentChildren, currentLeafObj);

        const parentNode = findNodeById(hierarchicalContentChildren, currentLeafObj.parentId);
        const grandParentNode = findNodeById(hierarchicalContentChildren, parentNode.parentId);

        const siblingLeafs = parentNode.activities.filter(activity => activity.isClickable);
        const currentLeafIndexInSubtree = siblingLeafs.findIndex(activity => activity.id === currentLeafObj.id);

        const leafIndex = siblingLeafs.findIndex(obj => obj.id === currentLeafObj.id);
        const isFirstLeaf = leafIndex === 0;
        const isLastLeaf = leafIndex === siblingLeafs.length - 1;

        const allLeafs = getLeafsList(hierarchicalContent);

        const currentLeafIndex = allLeafs.findIndex(activity => activity.id === currentLeafObj.id);

        const previousLeafIndex = currentLeafIndex - 1;
        const previousLeaf = allLeafs[previousLeafIndex];
        const previousLeafParent = previousLeaf && findNodeById(hierarchicalContentChildren, previousLeaf.parentId);
        const previousLeafAncestors = previousLeaf && getAncestorsIds(hierarchicalContentChildren, previousLeaf);
        const previousLeafRootParent =
          previousLeafAncestors && findNodeById(hierarchicalContentChildren, previousLeafAncestors[0]);

        if (previousLeafRootParent) {
          previousLeafRootParent.isLocked = state.lockedNodes.indexOf(previousLeafRootParent.id) !== -1;
        }

        const nextLeafIndex = currentLeafIndex + 1;
        const nextLeaf = allLeafs[nextLeafIndex];
        const nextLeafParent = nextLeaf && findNodeById(hierarchicalContentChildren, nextLeaf.parentId);
        const nextLeafAncestors = nextLeaf && getAncestorsIds(hierarchicalContentChildren, nextLeaf);
        const nextLeafRootParent = nextLeafAncestors && findNodeById(hierarchicalContentChildren, nextLeafAncestors[0]);

        if (nextLeafRootParent) {
          nextLeafRootParent.isLocked = state.lockedNodes.indexOf(nextLeafRootParent.id) !== -1;
        }

        return {
          ...state,
          navigation: {
            ...state.navigation,
            currentNodesIds,
            currentActivity: currentLeafObj.id,
            currentActivities: siblingLeafs,
            currentActivityIndexInLesson: currentLeafIndexInSubtree,
            isFirstActivityInLesson: isFirstLeaf,
            isLastActivityInLesson: isLastLeaf,
            previousActivity: previousLeafIndex >= 0 ? allLeafs[previousLeafIndex] : null,
            nextActivity: nextLeafIndex < allLeafs.length ? allLeafs[nextLeafIndex] : null,
            parentLevelTitle: parentNode.title,
            grandparentLevelTitle: grandParentNode?.title,
            previousLessonTitle: previousLeafParent?.title,
            nextLessonTitle: nextLeafParent?.title,
            ...(previousLeafRootParent && previousLeafRootParent.isLocked && isFirstLeaf && { disablePrevious: true }),
            ...(nextLeafRootParent && nextLeafRootParent.isLocked && isLastLeaf && { disableNext: true })
          }
        };
      }

      return {
        ...state
      };
    }

    case t.SET_CURRENT_TITLES:
      return {
        ...state,
        isSwitchBookOpen: false,
        title: action.payload.title,
        subtitle: action.payload.subtitle
      };

    case t.SET_MODAL_CLOSE_LINK:
      if (action.payload !== '') {
        sessionStorage.setItem(MODAL_CLOSE_LINK_KEY, action.payload);
      } else {
        sessionStorage.removeItem(MODAL_CLOSE_LINK_KEY);
      }
      return {
        ...state,
        modalCloseLink: action.payload
      };

    case t.SET_BASE_PATH:
      return {
        ...state,
        modalBasePathLink: new URL(action.payload)
      };

    case t.CLEAR_CURRENT_CONTENT:
      clearTimeout(activityCounter);
      return {
        ...state,
        navigation: {
          currentActivity: '',
          currentNodesIds: [],
          currentActivities: [],
          currentActivityIndexInLesson: 0,
          isFirstActivityInLesson: false,
          isLastActivityInLesson: false,
          previousActivity: null,
          nextActivity: null
        }
      };

    case t.OPEN_MOBILE_MENU_MODAL:
      return {
        ...state,
        mobileMenuOpen: true
      };
    case t.CLOSE_MOBILE_MENU_MODAL:
      return {
        ...state,
        mobileMenuOpen: false
      };
    case t.TOGGLE_CONTENTS_SUBMENU_MODAL:
      return {
        ...state,
        isContentPlayerSidebarOpen: !state.isContentPlayerSidebarOpen
      };
    case t.OPEN_CONTENTS_SUBMENU_MODAL:
      return {
        ...state,
        isContentPlayerSidebarOpen: true
      };
    case t.GET_CPT_CONTENT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case t.GET_MOCK_CPT_CONTENT_REQUEST:
      return {
        ...state
      };
    case t.GET_CPT_CONTENT_SUCCESS: {
      const content = action.payload;
      const hierarchicalContent = content.contents;

      if (hierarchicalContent) {
        const allActivities = getLeafsList(hierarchicalContent);
        allActivities.forEach(activity => {
          activity.type =
            getSelfSelectedRole() !== selfSelectedUserRoles.SELF_TEACHER && activity.type !== 'presentation'
              ? 'submit2practice'
              : 'presentation';
          if (isOcpMode()) {
            activity.type = 'presentation';
          }
        });
      }

      return {
        ...state,
        cptContent: content,
        success: true,
        isLoading: false
      };
    }
    case t.GET_CPT_CONTENT_FAILURE:
      return {
        ...state,
        cptContent: null,
        error: action.payload,
        isLoading: false
      };
    case t.GET_EREADER_CONTENT_REQUEST:
      return {
        ...state
      };
    case t.GET_EREADER_CONTENT_SUCCESS: {
      const content = action.payload;
      const eReaderBookSignedUrl = content.signedUrl;

      return {
        ...state,
        eReaderBookSignedUrl,
        success: true
      };
    }
    case t.GET_EREADER_CONTENT_FAILURE:
      return {
        ...state,
        eReaderBookSignedUrl: null,
        error: action.payload
      };
    case t.GET_DICTIONARY_DATA_REQUEST:
      return {
        ...state
      };
    case t.GET_DICTIONARY_DATA_SUCCESS:
      return {
        ...state,
        dictionaryData: action.payload,
        dictionaryError: null
      };
    case t.GET_DICTIONARY_DATA_FAILURE:
      return {
        ...state,
        dictionaryData: null,
        dictionaryError: action.payload
      };
    case t.GET_PLAYER_LAUNCH_REQUEST:
      return {
        ...state
      };
    case t.GET_PLAYER_LAUNCH_SUCCESS:
      return {
        ...state,
        lastLaunchedContents: {
          ...state.lastLaunchedContents,
          [action.payload.contextCode]: action.payload.contentId ?? ''
        },
        success: true
      };
    case t.GET_PLAYER_LAUNCH_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case t.OPEN_STRUCTURED_CONTENT_PLAYER_MODAL:
      return {
        ...state,
        isModalOpen: true
      };
    case t.CLOSE_STRUCTURED_CONTENT_PLAYER_MODAL:
      clearTimeout(activityCounter);
      return {
        ...initialState,
        lastLaunchedContents: state.lastLaunchedContents,
        isModalOpen: false,
        isSwitchBookOpen: false
      };
    case t.TOGGLE_RESOURCES_SUBMENU_MODAL:
      return {
        ...state,
        isResourcesSidebarOpen: !state.isResourcesSidebarOpen
      };
    case t.SET_MENU_ORIENTATION:
      localStorage.setItem(SESSION_MENU_ORIENTATION, action.payload);
      return {
        ...state,
        menuOrientation: action.payload
      };
    case t.TOGGLE_PRINT_VIEW_MODAL:
      return {
        ...state,
        isPrintViewModalOpen: !state.isPrintViewModalOpen
      };
    case t.TOGGLE_DICTIONARY_MODAL:
      return {
        ...state,
        dictionaryData: null,
        isDictionaryModalOpen: !state.isDictionaryModalOpen
      };
    case t.SET_MODAL_CONTENT_ID: {
      return {
        ...state,
        productVariant: state.isPreviewMode ? state.previewModeRole : getSelfSelectedRole(),
        contentId: action.payload
      };
    }
    case t.UPDATE_PRODUCT_LINK:
      return {
        ...state
      };
    case t.GET_XAPI_LAUNCH_TOKEN_REQUEST:
      return {
        ...state
      };
    case t.GET_XAPI_LAUNCH_TOKEN_SUCCESS:
      return {
        ...state,
        xApiLaunchToken: action.payload,
        success: true
      };
    case t.GET_XAPI_LAUNCH_TOKEN_FAILURE:
      return {
        ...state,
        xApiLaunchToken: null,
        error: action.payload
      };
    case t.TOGGLE_MOBILE_GAMES_POPUP:
      return {
        ...state,
        isMobileGamePopupOpen: !state.isMobileGamePopupOpen
      };
    case t.SET_PREVIEW_ROLE: {
      const role = action.payload;
      return {
        ...state,
        isPreviewMode: true,
        previewModeRole: role
      };
    }
    case t.SET_PRODUCT_VARIANT: {
      return {
        ...state,
        productVariant: state.isPreviewMode ? state.previewModeRole : getSelfSelectedRole()
      };
    }
    case t.PROGRESS_BAR_NAVIGATION_RESET_TO_DEFAULT: {
      return {
        ...state,
        progressBarNavigation: state.defaultProgressBarNavigation
      };
    }
    case t.PROGRESS_BAR_NAVIGATION_ENABLE: {
      return {
        ...state,
        progressBarNavigation: 'show'
      };
    }
    case t.PROGRESS_BAR_NAVIGATION_DISABLE: {
      return {
        ...state,
        progressBarNavigation: 'hide'
      };
    }
    case t.TOGGLE_SWITCH_BOOK:
      return {
        ...state,
        isSwitchBookOpen: !state.isSwitchBookOpen
      };
    case t.TOGGLE_RESOURCE_MODAL:
      return {
        ...state,
        isResourceModalPlayerOpen: !state.isResourceModalPlayerOpen
      };
    case t.SET_RESOURCE_MODAL_DATA:
      return {
        ...state,
        resourceModalData: action.payload
      };
    default:
      return { ...state };
  }
}
