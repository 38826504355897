import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Button, { buttonTypes } from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Validation.scss';

const glyphFor = {
  ERROR: GLYPHS.ICON_ERROR_CIRCLE,
  WARNING: GLYPHS.ICON_WARNING_CIRCLE,
  NOTICE: GLYPHS.ICON_INFORMATION_CIRCLE,
  WAITING: GLYPHS.ICON_LOADING,
  VALID: GLYPHS.ICON_CHECK_CIRCLE
};

/** The component renders a text input label and field */
function Validation({
  children,
  isError,
  isWarning,
  isNotice,
  isWaiting,
  isValid,
  message,
  buttonText,
  buttonLink,
  buttonOnClick,
  noIcon,
  customClassName,
  rightHandInput,
  forId,
  noLabelAboveField,
  noInputErrorTopSpacing,
  childrenContainerCustomClass,
  iconCustomClass,
  hideField,
  buttonLoading,
  dataTestId = '',
  showValidationIconInsideInput,
  withSubLabelAboveInput
}) {
  // Decide which validation status to show, if applicable:
  // Note the order of precidence applies when more than one isWhatever flag is specified.
  const statusIndication =
    (isError && 'ERROR') ||
    (isWarning && 'WARNING') ||
    (isNotice && 'NOTICE') ||
    (isWaiting && 'WAITING') ||
    (isValid && 'VALID') ||
    '';

  return (
    <div
      data-testid={dataTestId}
      style={{ display: hideField ? 'none' : 'auto' }}
      className={classnames(styles.validation, {
        [styles.isValid]: isValid,
        [styles.noLabelAboveField]: noLabelAboveField,
        [styles.noInputErrorTopSpacing]: noInputErrorTopSpacing,
        [styles.showValidationIconInsideInput]: showValidationIconInsideInput,
        [styles.withSubLabelAboveInput]: withSubLabelAboveInput
      })}
    >
      <div className={styles.topRow}>
        {/* Pass aria-invalid attribute to the field when validation isError: */}
        {/* Assumes children will pass aria attribute to the actual field element */}
        <div
          className={`${styles.childrenContainer} ${
            childrenContainerCustomClass ? styles[childrenContainerCustomClass] : ''
          }`}
        >
          {React.Children.map(children, child => {
            // Pimp child element with aria attributes:
            // More info: https://www.w3.org/TR/WCAG20-TECHS/ARIA21.html
            const invalid = isError;
            const describedby = statusIndication && message ? `validationMessageFor-${forId}` : null;
            const aria = { ...child.props.aria, invalid, describedby };
            return React.cloneElement(child, { aria });
          })}
        </div>

        {!noIcon && (
          <div
            id={`validationIconFor-${forId}`}
            className={`${styles.iconSpace} ${iconCustomClass ? styles[iconCustomClass] : ''}`}
          >
            {statusIndication && <SVGIcon glyph={glyphFor[statusIndication]} />}
          </div>
        )}
      </div>

      {/* Only show the error message markup if there is one */}
      {statusIndication && message && (
        <p
          id={`validationMessageFor-${forId}`}
          className={classnames({
            [styles.errorMessage]: statusIndication === 'ERROR',
            [styles.warningMessage]: statusIndication === 'WARNING',
            [styles.noticeMessage]: statusIndication === 'NOTICE',
            [styles.noIcon]: noIcon,
            [styles.rightHandInput]: rightHandInput,
            [styles[customClassName]]: customClassName
          })}
          role="alert"
          aria-atomic="true"
          aria-live={isError ? 'assertive' : 'polite'}
        >
          {buttonText && (buttonLink || buttonOnClick) ? (
            <span className={classnames({ row: true })}>
              <span className={classnames({ col: true, sm10: true })}>{message}</span>
              <span className={classnames({ col: true, sm2: true, [styles.jCenter]: true })}>
                {buttonLoading ? (
                  <SVGIcon className={styles.loading} glyph={glyphFor.WAITING} />
                ) : (
                  <Button
                    type={buttonTypes.PRIMARY}
                    text={buttonText}
                    onClick={buttonOnClick}
                    to={buttonLink}
                    disableExternalBehaviour
                  />
                )}
              </span>
            </span>
          ) : (
            message
          )}
        </p>
      )}
    </div>
  );
}

Validation.propTypes = {
  /** Content to wrap with the validation messages */
  children: PropTypes.any.isRequired,
  /* should this field be hidden completely? Do make sure it's not required, not in requiredFields and does have a default value... */
  hideField: PropTypes.bool,
  /** This it the validity state of the validation. undefined = unchecked, false = ok, true = ✅ valid */
  isValid: PropTypes.bool,
  /** This it the error state of the validation. undefined = unchecked, false = ok, true = ❎ error */
  isError: PropTypes.bool,
  /** This it the warning state of the validation. undefined = unchecked, false = ok, true = ⚠️ warning */
  isWarning: PropTypes.bool,
  /** This it the notice state of the validation. undefined = unchecked, false = ok, true = (i) notice */
  isNotice: PropTypes.bool,
  /** This it the waiting state of the validation. undefined = unchecked, false = ok, true = ♻️ waiting */
  isWaiting: PropTypes.bool,
  /** The error or warning message to be displayed */
  message: PropTypes.string,
  /** The error or warning message to be displayed */
  buttonText: PropTypes.string,
  /** The error or warning message to be displayed */
  buttonLink: PropTypes.string,
  /** This is a function for invitation send email */
  buttonOnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /** This is a function for invitation send email */
  buttonLoading: PropTypes.bool,
  /** Setting this allows you to just get an error message without the icon */
  noIcon: PropTypes.bool,
  customClassName: PropTypes.string,
  /** Setting this moves the error message arrow to the right of the element */
  rightHandInput: PropTypes.bool,
  /** This is the value of the Id in the wrapped form element. It is used for test targets */
  forId: PropTypes.string.isRequired,
  /** Set this flag when the Validation Icon needs to be higher that usual */
  noLabelAboveField: PropTypes.bool,
  /** Set this flag when the Validation Icon and validation message top padding needs to remove */
  noInputErrorTopSpacing: PropTypes.bool,
  childrenContainerCustomClass: PropTypes.string,
  iconCustomClass: PropTypes.string,
  dataTestId: PropTypes.string,
  showValidationIconInsideInput: PropTypes.bool,
  withSubLabelAboveInput: PropTypes.bool
};

export default Validation;
