import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { GLYPHS, buttonTypes } from '../Button/Button';
import ControlledForm from '../ControlledForm/ControlledForm';
import SearchOptions from '../SearchOptions/SearchOptions';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import TextInputWithButtonAndDropdown from '../TextInputWithButtonAndDropdown/TextInputWithButtonAndDropdown';
import styles from './ListPageControls.scss';
import ListPageControlsSkeleton from './ListPageControlsSkeleton';

function ListPageControls({
  idPrefix,
  // Search props
  searchInputLabel,
  searchInputPlaceholder,
  searchInputValue,
  searchInputOnChange,
  searchInputOnSubmit,
  searchInputOnKeyUp,

  // Dropdown props
  dropdownProps,

  filterOptions,
  sortOptions,
  sortOnChange,

  // New Button props
  newButtonText,
  newButtonTo,
  newButtonAction,
  ariaControls,
  disabled,

  loading,
  loaded,
  loadingMessage,
  loadedMessage,
  showSkeletonLoader = false,

  localizedContent: { appComponents: localizedContent }
}) {
  return (
    <ControlledForm className={styles.listPageControls}>
      <div className="grid">
        <div className="row">
          <div className={classnames('col', styles.controls)}>
            {loading ? (
              <div>
                <h3 className={styles.loadingTitle}>{loaded ? loadedMessage : loadingMessage}</h3>
                {!loaded ? <div className={styles.loadingSubtitle}>{localizedContent.loading_subtitle}</div> : null}
              </div>
            ) : (
              <div>
                {!showSkeletonLoader ? (
                  <div className={styles.controls}>
                    {dropdownProps ? (
                      <TextInputWithButtonAndDropdown
                        id={`${idPrefix}-searchTerm`}
                        name={`${idPrefix}-searchTerm`}
                        label={searchInputLabel}
                        labelHidden
                        placeholder={searchInputPlaceholder}
                        inputValue={searchInputValue}
                        inputOnChange={searchInputOnChange}
                        inputOnKeyUp={searchInputOnKeyUp}
                        inputRequired={false}
                        buttonIcon={GLYPHS.ICON_SEARCH}
                        buttonAction={() => searchInputOnSubmit(searchInputValue)}
                        buttonText="Search"
                        dropdownId={dropdownProps.id}
                        dropdownLabel={dropdownProps.label}
                        dropdownOptions={dropdownProps.options}
                        dropdownValue={dropdownProps.value}
                        dropdownOnChange={dropdownProps.onChange}
                        ariaControls={ariaControls}
                        role="searchbox"
                      />
                    ) : (
                      <TextInputWithButton
                        id={`${idPrefix}-searchTerm`}
                        name={`${idPrefix}-searchTerm`}
                        label={searchInputLabel}
                        labelHidden
                        placeholder={searchInputPlaceholder}
                        value={searchInputValue}
                        onChange={searchInputOnChange}
                        buttonAction={() => searchInputOnSubmit(searchInputValue)}
                        buttonText={localizedContent.search_button}
                        ariaControls={ariaControls}
                        role="searchbox"
                        required={false}
                      />
                    )}
                    {filterOptions ? (
                      <SearchOptions
                        buttonId={`${idPrefix}-filterButton`}
                        buttonText={localizedContent.filter_button}
                        buttonGlyph={GLYPHS.ICON_FILTER}
                        customClassName="filterButton"
                        inputType="checkbox"
                        options={filterOptions}
                        a11yHeading={localizedContent.filter_list_heading || 'Filter by'}
                        ariaControls={ariaControls}
                      />
                    ) : null}
                    <SearchOptions
                      buttonId={`${idPrefix}-sortButton`}
                      buttonText={localizedContent.sort_button}
                      buttonGlyph={GLYPHS.ICON_SORT}
                      customClassName="sortButton"
                      inputType="radio"
                      onChange={sortOnChange}
                      options={sortOptions}
                      a11yHeading={localizedContent.sort_list_heading || 'Sort by'}
                      ariaControls={ariaControls}
                    />
                    <Button
                      type={buttonTypes.ROUNDED}
                      text="Refresh"
                      iconOnly
                      glyph={GLYPHS.ICON_REFRESH}
                      customClassName={styles.refreshButton}
                      onClick={() => searchInputOnSubmit(searchInputValue)}
                    />
                  </div>
                ) : (
                  <ListPageControlsSkeleton />
                )}
              </div>
            )}
            {newButtonTo || (newButtonAction && !showSkeletonLoader) ? (
              <div className={styles.addButtonContainer}>
                <Button
                  type={buttonTypes.PRIMARY}
                  text={newButtonText}
                  to={newButtonTo}
                  disabled={disabled}
                  onClick={newButtonAction}
                  glyph={GLYPHS.ICON_PLUS}
                  floater
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </ControlledForm>
  );
}

ListPageControls.propTypes = {
  idPrefix: PropTypes.string,

  // Search props
  searchInputLabel: PropTypes.string.isRequired,
  searchInputPlaceholder: PropTypes.string.isRequired,
  searchInputValue: PropTypes.string.isRequired,
  searchInputOnChange: PropTypes.func.isRequired,
  searchInputOnSubmit: PropTypes.func.isRequired,
  searchInputOnKeyUp: PropTypes.func,
  /** This toggles if the button is disabled */
  disabled: PropTypes.bool,

  /** Object holding the dropdown props */
  dropdownProps: PropTypes.shape({
    /** the id of the input field needs to be unique */
    id: PropTypes.string.isRequired,
    /** the inputs text label */
    label: PropTypes.string.isRequired,
    /** Options are required */
    options: PropTypes.array.isRequired,
    /** Controlled value of the dropdown */
    value: PropTypes.any,
    /** function that if the input is touched, will be called */
    onChange: PropTypes.func.isRequired
  }),

  // Filters
  filterOptions: PropTypes.array,
  sortOptions: PropTypes.array,
  sortOnChange: PropTypes.func,

  // New Button props
  newButtonText: PropTypes.string,
  newButtonTo: PropTypes.string,
  newButtonAction: PropTypes.func,

  // the id(s) of the controlled elements
  ariaControls: PropTypes.string,

  // To handle the loading state
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  loadingMessage: PropTypes.string.isRequired,
  loadedMessage: PropTypes.string.isRequired,

  localizedContent: PropTypes.object,
  showSkeletonLoader: PropTypes.bool
};

export default withLocalizedContent('appComponents')(ListPageControls);
