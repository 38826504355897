import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import sendRemoveUserLicenceRequest from '../../../api/removeUserLicence';

function* sendRemoveUserLicence(licenceId) {
  const response = yield sendRemoveUserLicenceRequest(licenceId);
  if (response.status !== 'success') {
    yield put(actions.sendRemoveUserLicenceFailure(response.error));
    return;
  }
  yield put(actions.sendRemoveUserLicenceSuccess(response));
}

export default sendRemoveUserLicence;
