import { put } from 'redux-saga/effects';
import { getOrgUsageSuccess, getOrgUsageError } from '../../../../actions/orgUsage';
import orgUsageApi from '../../../apiCalls/orgs/orgUsage.api';

function* getOrgUsage(payload) {
  const response = yield orgUsageApi(payload);

  if (response.status === 'success') {
    yield put(getOrgUsageSuccess(response.data));
  } else {
    yield put(getOrgUsageError());
  }
}

export default getOrgUsage;
