import React, { Component } from 'react';
import Button, { buttonTypes } from '../../../components/Button/Button.js';
import styles from './OrbRegionDemoPage.scss';
import envSettings from '../../../globals/envSettings';

class OrbRegionDemoPage extends Component {
  constructor() {
    super();
    // This is a demo page - so the beta region details are hardcoded - This will never work in case of login from home screen
    const betaRegionName = 'EUROPE_BETA';
    const betaRegionValue = {
      idp: 'auth0',
      orb: 'uk-beta',
      label: 'Europe Beta'
    };
    // load the state from local storage
    this.state = {
      selected: localStorage.getItem('idpRegion')
    };
    // Since this is demo page and is temporary we have configured regions here.
    const regionalSettings = envSettings.regions;
    this.regions = [];
    if (envSettings.regions) {
      Object.keys(envSettings.regions).forEach(region => {
        // Modifed to display the label property in the dropdown list - instead of key
        const regionData = {
          id: region,
          value: regionalSettings[region].label
        };
        this.regions.push(regionData);
      });

      const auth0RegionList = this.regions.filter(region => region.id === betaRegionName);
      if (auth0RegionList.length === 0) {
        const regionData = {
          id: betaRegionName,
          value: betaRegionValue.label
        };
        this.regions.push(regionData);
      }
    }
  }

  onLocationSelect = e => {
    const selectedRegion = e.target.value;
    if (this.state.selected !== selectedRegion) {
      localStorage.setItem('idpRegion', selectedRegion);
      this.setState({ selected: selectedRegion });
    }
  };

  onButtonClick = () => {
    window.location.href = '/';
  };

  render() {
    const selected = this.state.selected;
    return (
      <div className={`${styles.demoPage} grid`}>
        <div className="row">
          <div className="col">
            <h1>ORB Region Simulator</h1>
            <label htmlFor="region-dropdown">
              <b style={{ marginRight: '1em' }}>User Region</b>
              <select id="location-dropdown" value={selected} onChange={this.onLocationSelect}>
                <option value="">Please select location</option>
                {this.regions.map((region, index) => (
                  <option value={region.id} key={index}>
                    {region.value}
                  </option>
                ))}
              </select>
            </label>
            <br /> <br />
            <Button
              id="register-button"
              to="/"
              onClick={this.onButtonClick}
              type={buttonTypes.PRIMARY}
              text={'View Home Page'}
              disabled={!selected}
              dataTestId="GOTO_REGISTER"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OrbRegionDemoPage;
