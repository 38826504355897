import * as t from '../actionTypes';

const initialState = {
  editAccountModalOpen: false
};

function hubUi(state = initialState, { type }) {
  switch (type) {
    case t.OPEN_EDIT_ACCOUNT_MODAL:
      return {
        ...state,
        editAccountModalOpen: true
      };
    case t.CLOSE_EDIT_ACCOUNT_MODAL:
      return {
        ...state,
        editAccountModalOpen: false
      };
    default:
      return state;
  }
}

export default hubUi;
