import { put, select } from 'redux-saga/effects';

import { setFormResult } from '../../../../reducers/data/forms.reducer.js';
import requestUpdateUserNameApi from '../../../apiCalls/requestUpdateUserNameApi.js';
import { getFieldValue, requestFailed } from '../formUtil.js';

export default function* submitRequestUpdateUserName() {
  const payload = yield select(state => ({
    username: getFieldValue(state, 'requestChangeUsername', 'userName'),
    newUsername: getFieldValue(state, 'requestChangeUsername', 'newUserName'),
    password: getFieldValue(state, 'requestChangeUsername', 'password')
  }));

  const apiResult = yield requestUpdateUserNameApi(payload);
  const failed = requestFailed(apiResult);
  if (failed) {
    yield put(setFormResult('requestChangeUsername', 'FAILED', failed));
  } else {
    yield put(setFormResult('requestChangeUsername', 'CONFIRMATION', failed));
  }
}
