import PropTypes from 'prop-types';
import React from 'react';
import PopoutActionFooter from '../../../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import cmsContent from '../../../../../../utils/cmsContent.js';
import { isHubMode } from '../../../../../../utils/platform.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../globals/hubConstants.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';

let CMS = {};

const getIconHeading = (requestFailed, failedIds) => {
  if (requestFailed) {
    return {
      title: CMS.archive_class_error_title,
      subtitle: CMS.archive_class_error_subtitle,
      type: iconHeadingTypes.ERROR
    };
  }
  if (failedIds && failedIds.length) {
    return {
      title: CMS.archive_class_partial_error_title,
      type: iconHeadingTypes.WARNING
    };
  }
  return {
    title: CMS.archive_class_success_title,
    type: iconHeadingTypes.PROCESSING
  };
};

const getIllustrationHeading = (requestFailed, failedIds) => {
  if (requestFailed || failedIds?.length) {
    return {
      title: CMS.archive_class_error_title,
      subtitle: CMS.archive_class_error_subtitle,
      illustrationSrc: HubIllustrationConstants.ERROR,
      illustrationAltText: HubIllustrationAltText.ERROR
    };
  }
  if (failedIds && failedIds.length) {
    return {
      title: CMS.archive_class_partial_error_title,
      illustrationSrc: HubIllustrationConstants.WARNING,
      illustrationAltText: HubIllustrationAltText.WARNING
    };
  }
  return {
    title: CMS.archive_class_success_title,
    illustrationSrc: HubIllustrationConstants.SUCCESS,
    illustrationAltText: HubIllustrationAltText.SUCCESS
  };
};

function ArchiveClassroomSubmitting({ requestFailed, failedIds, onDoneClick }) {
  CMS = cmsContent.archiveClassPanel || {};

  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={onDoneClick} />
          </div>
          {isHubMode() ? (
            <PopoutPanelIllustrationHeading {...getIllustrationHeading(requestFailed, failedIds)}>
              {!requestFailed && CMS.email_complete_body_text_1}
            </PopoutPanelIllustrationHeading>
          ) : (
            <PopoutPanelIconHeading {...getIconHeading(requestFailed, failedIds)}>
              {!requestFailed && CMS.email_complete_body_text_1}
            </PopoutPanelIconHeading>
          )}
        </div>
      }
      footerContent={
        <PopoutActionFooter primaryButtonText={CMS.archive_complete_button_text} primaryButtonAction={onDoneClick} />
      }
    />
  );
}

ArchiveClassroomSubmitting.propTypes = {
  requestFailed: PropTypes.bool,
  failedIds: PropTypes.arrayOf(PropTypes.string),
  onDoneClick: PropTypes.func.isRequired
};

export default ArchiveClassroomSubmitting;
