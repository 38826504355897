import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ValidationStatus.scss';
import { validationStatusCodes, glyphFor } from './ValidationStatusCodes';
import { validationStatusPropType } from '../../types/propTypeDefinitions';

export { validationStatusCodes };

function ValidationStatus({
  children,
  message,
  forId,
  type = validationStatusCodes.ERROR_STATUS,
  isActive = null,
  hideIcon = false,
  isHtml = false,
  containerClassName,
  className,
  arrowClassName,
  validationStatus,
  ...props
}) {
  const describedby = isActive ? `validationMessageFor-${forId}` : null;
  let status;
  switch (isActive) {
    case false:
      status = validationStatusCodes.VALID_STATUS;
      break;
    case true:
      status = type;
      break;
    default:
      status = '';
  }

  return (
    <div {...props} className={containerClassName}>
      <div className={`${styles.container} ${className}`}>
        <div className={styles.children}>
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              aria: {
                ...child.props.aria,
                invalid: isActive,
                describedby
              }
            })
          )}
        </div>
        {!hideIcon && [true, false].includes(isActive) ? (
          <SVGIcon className={styles.icon} glyph={glyphFor[status]} />
        ) : null}
      </div>
      {message && isActive ? (
        <div
          id={describedby}
          className={classnames(
            styles.status,
            styles[status],
            { [styles.iconHidden]: hideIcon },
            arrowClassName ? styles[arrowClassName] : ''
          )}
          role="alert"
        >
          {/* eslint-disable-next-line react/no-danger */}
          {isHtml ? <div className={styles.htmlNotice} dangerouslySetInnerHTML={{ __html: message }} /> : message}
        </div>
      ) : null}
    </div>
  );
}

ValidationStatus.propTypes = {
  children: PropTypes.any.isRequired,
  message: PropTypes.string,
  forId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(glyphFor)),
  isActive: PropTypes.bool,
  hideIcon: PropTypes.bool,
  isHtml: PropTypes.bool,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  arrowClassName: PropTypes.string,
  validationStatus: validationStatusPropType
};

export default ValidationStatus;
