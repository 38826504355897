import * as t from '../actionTypes';

export const lockedAccountRefreshRequest = () => ({
  type: t.LOCKED_ACCOUNTS_REFRESH_REQUEST
});

export const lockedAccountRefreshResponse = ({ userIdsLocked, userIdsNotLocked }) => ({
  type: t.LOCKED_ACCOUNTS_REFRESH_RESPONSE,
  payload: { userIdsLocked, userIdsNotLocked }
});
