import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// Components:
import { useHistory } from 'react-router-dom';
import { safePanelLink, safePanelUnlink } from '../../../../utils/links/panelLinks.js';
import colors from '../../../../globals/colors.js';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon.js';
import Badge from '../../../../components/Badge/Badge.js';
import LinkWithIcon from '../../../../components/LinkWithIcon/LinkWithIcon.js';
import PopoutPanel from '../../../../components/PopoutPanel/PopoutPanel.js';
import SlideupPanel from '../../../../components/SlideupPanel/SlideupPanel.js';

// Page components:
import OrgClassSearch from './OrgClassSearch.js';
import AddClassroom from './panels/AddClassroom/AddClassroom.js';
import ArchiveClassroom from './panels/ArchiveClassroom/ArchiveClassroom.js';

// Actions:
import {
  setClassroomSelected,
  showClosePanelModal,
  clearSelectedUser
} from '../../../../redux/reducers/organisationPage.reducer.js';
import { setClassroomsToArchive } from '../../../../redux/reducers/classroomArchive.js';

import withSearchInitialiser from '../../../../components/SearchInitialiser/withSearchInitialiser';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import {
  createAuthorizeArchiveClassBasedOnRole,
  createAuthorizeDownloadSignInCard
} from '../../../../redux/selectors/authorization/user';
import ManagedUserSignInCardPanel from '../../../../panels/ManagedUserSignInCard/ManagedUserSignInCardPanel';

import { orgRoles } from '../../../../globals/orgRoles';
import userRoles from '../../../../globals/userRoles';

import { setClassRoomStudents } from '../../../../redux/reducers/userSignInCard.reducer';

function ClassroomsTab({
  localizedContent: { mySchoolClassTab: content },
  orgId,
  panelName,
  orgRole,
  selectedClassroomIds,
  setClassroomsToArchiveAction,
  showConfirmCloseModalAction,
  selectableIds,
  canRemoveUserBasedOnRole = false,
  canDownloadSignInCard = false,
  setClassRoomStudentsAction,
  clearSelectedUserAction
}) {
  const history = useHistory();

  return (
    <div>
      <OrgClassSearch orgId={orgId} selectableIds={selectableIds} setArchiveAction={setClassroomsToArchiveAction} />

      <SlideupPanel isOpen={!!selectedClassroomIds.length}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '2rem' }}>
            <Badge backgroundColor={colors.DASHBOARD} value={selectedClassroomIds.length} />
            <span style={{ marginLeft: '0.5rem', verticalAlign: 'middle' }}>{content.selected_item_text}</span>
          </div>
          {canDownloadSignInCard ? (
            <LinkWithIcon
              id="viewSignInCardLink"
              to={safePanelLink('downloadSignInCard')}
              action={() => setClassRoomStudentsAction(selectedClassroomIds)}
              text={content.download_sign_in_card}
              subtext={content.download_sign_in_card_subtext}
              glyph={GLYPHS.ICON_RIGHT}
            />
          ) : null}
          {canRemoveUserBasedOnRole && (
            <LinkWithIcon
              id="openArchiveClasses"
              to={safePanelLink('archiveClass')}
              action={() => setClassroomsToArchiveAction(selectedClassroomIds, orgId)}
              preventDefault={false}
              text={content.archive_classes_text}
              glyph={GLYPHS.ICON_RIGHT}
            />
          )}
        </div>
      </SlideupPanel>

      {/* Note: panelName is set via the router */}
      <PopoutPanel
        id="addClassPopout"
        ariaLabel={content.aria_label_add_class_to_school}
        isOpen={panelName === 'addClass'}
        closeAction={() => {
          showConfirmCloseModalAction(true);
        }}
      >
        <AddClassroom
          orgId={orgId}
          orgRole={orgRole}
          closePopoutAction={() => {
            showConfirmCloseModalAction(true);
          }}
          onComplete={() => {
            history.push(safePanelUnlink('addClass'));
          }}
        />
      </PopoutPanel>

      {/* Note: panelName is set via the router */}
      <PopoutPanel id="archiveClassPopout" ariaLabel={content.archive_class_text} isOpen={panelName === 'archiveClass'}>
        <ArchiveClassroom
          closeArchiveAction={() => {
            showConfirmCloseModalAction(true);
          }}
          onComplete={() => {
            history.push(safePanelUnlink('archiveClass'));
            clearSelectedUserAction();
          }}
        />
      </PopoutPanel>
      {canDownloadSignInCard ? (
        <ManagedUserSignInCardPanel
          id="ClassRoomStudentCards"
          ariaLabel="SignIncardPanel"
          orgId={orgId}
          open={panelName === 'downloadSignInCard'}
          onClosePanel={() => showConfirmCloseModalAction(true)}
          onComplete={() => {
            history.push(safePanelUnlink('downloadSignInCard'));
            clearSelectedUserAction();
          }}
        />
      ) : null}
    </div>
  );
}

ClassroomsTab.propTypes = {
  orgId: PropTypes.any,
  orgRole: PropTypes.any,
  panelName: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  selectedClassroomIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setClassroomsToArchiveAction: PropTypes.func.isRequired,
  showConfirmCloseModalAction: PropTypes.func.isRequired,
  selectableIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  canRemoveUserBasedOnRole: PropTypes.bool,
  canDownloadSignInCard: PropTypes.bool,
  setClassRoomStudentsAction: PropTypes.func.isRequired,
  clearSelectedUserAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('mySchoolClassTab'),
  withSearchInitialiser({
    searchSource: 'orgClasses',
    initialFilters: { active: true, isOrgClassTab: true }
  }),
  connect(
    (state, { orgId }) => {
      const selectableClasses = Object.entries(state.search.orgClasses.paginatedClassList).reduce(
        (classroom, [key, val]) => {
          if (!val.archived) {
            classroom.push(key);
          }
          return classroom;
        },
        []
      );
      const isPrimarySchool = state.organisations.data[orgId].role === orgRoles.PRIMARY_SCHOOL;
      const targetUserRole = isPrimarySchool ? userRoles.MANAGED_USER : userRoles.LEARNER;
      return {
        userRole: state.identity.role,
        classroomSearchTerm: state.organisationPage.classroomSearchTerm,
        selectedClassroomIds: state.organisationPage.selectedClassroomIds,
        confirmCloseModal: state.organisationPage.showModal,
        selectableIds: selectableClasses,
        canRemoveUserBasedOnRole: createAuthorizeArchiveClassBasedOnRole(state)({ orgId, targetUserRole }),
        canDownloadSignInCard: createAuthorizeDownloadSignInCard(state)({ orgId, targetUserRole })
      };
    },
    {
      setClassroomSelectedAction: setClassroomSelected,
      setClassroomsToArchiveAction: setClassroomsToArchive,
      showConfirmCloseModalAction: showClosePanelModal,
      clearSelectedUserAction: clearSelectedUser,
      setClassRoomStudentsAction: setClassRoomStudents
    }
  )
)(ClassroomsTab);
