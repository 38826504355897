import React from 'react';
import content from '../../utils/cmsContent';
import PopoutPanelIconHeading, { types as iconHeadingTypes } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';

function InvitationActionLoading() {
  return (
    <PopoutPanelIconHeading
      type={iconHeadingTypes.LOADING}
      title={content.invitationBanner.invitation_action_loading_title}
      subtitle={content.invitationBanner.invitation_action_loading_subtitle}
    />
  );
}

export default InvitationActionLoading;
