/* eslint-disable react/prop-types */
import React from 'react';
// Style
import { Link } from 'react-router-dom';
import styles from './carousel.scss';
// React router
// Components
import CarouselBullets from './CarouselBullets';

function Carousel({ bullet, bulletsLength, onNext, onPrev, onSetPage }) {
  function getBullets() {
    const arr = [];
    for (let i = 0; i < bulletsLength; i += 1) {
      arr.push(i);
    }
    return arr;
  }

  return (
    bulletsLength > 1 && (
      <div className={styles.carousel}>
        <div className="row cols-edge align-center">
          <div className="col">
            <Link to="#prev" onClick={onPrev} disabled={bullet <= 1}>
              {'<'}
            </Link>
          </div>
          <div>
            {getBullets().map((dot, index) => (
              <CarouselBullets key={dot} bullet={bullet} index={index + 1} onClick={onSetPage} />
            ))}
          </div>
          <div className="col">
            <Link to="#next" onClick={onNext} disabled={bullet >= bulletsLength}>
              {'>'}
            </Link>
          </div>
        </div>
      </div>
    )
  );
}

export default Carousel;
