import session from './session';
import user from './user';
import { isLtiMode } from './platform.js';

export function storeTemporaryCredentials(cognitoId = '') {
  if (typeof cognitoId !== 'string' || cognitoId === '') {
    console.warn('Expected a non-empty string for cognitoId');
    localStorage.removeItem('cognitoId');
  }
  localStorage.setItem('cognitoId', cognitoId);
}

export function removeTemporaryCredentials() {
  localStorage.removeItem('cognitoId');
}

/**
 * Remove stored user credential data from localStorage and sessionStorage.
 *
 * @param {?boolean} all
 */
// eslint-disable-next-line import/prefer-default-export
export function clearCredentialData(all) {
  session.clear();
  user.clear();
  localStorage.removeItem('ces-jwt');
  if (isLtiMode()) {
    localStorage.removeItem('ces-creds');
  }
  sessionStorage.removeItem('goto-path');
  localStorage.removeItem('oauthVerifier');

  if (all) {
    removeTemporaryCredentials();
  }
}
