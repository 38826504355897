import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../globals/colors';
import { featureIsEnabled } from '../../globals/envSettings';
import { isEltAssessmentMode } from '../../utils/platform';
import content from '../../utils/cmsContent';
import { getExpiryInformation } from '../../utils/licences';
import Button, { buttonTypes } from '../Button/Button';
import EntityRepresentation, { SIZES as thumbnailSizes } from '../EntityRepresentation/EntityRepresentation';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './BannerInvitation.scss';
import isTestingUser from '../../utils/verifyUser';

// const content = {
//   invitationBanner: {
//     heading_pendingInvitations_none: 'You have no invitations pending',
//     heading_pendingInvitations_singular: 'You have 1 invitation pending. ',
//     heading_pendingInvitations_plural: 'You have {n} invitations pending. ',
//     button_text_previous: 'Previous',
//     button_text_next: 'Next',
//     button_text_accept: 'Accept',
//     button_text_decline: 'Decline',
//     button_text_dismiss: 'Dismiss',
//     invitation_heading: 'You have been invited to join {orgName} as a {orgRole}',
//     invitation_summary_singular: 'Invitation sent on {sentDate}',
//     invitation_summary_plural: 'Invitation {page} of {pages} sent on {sentDate}',
//     invitation_expiring_warning: 'This invitation will expire in {n} days',
//     invitation_expired_warning: 'This invitation has expired',
//     invitation_expiry_warning_period_days: 5,
//     button_learn_more: 'Learn more',
//     // ProfileBannerInvitation from here
//     button_text_remind: 'Send an invitation reminder',
//     button_text_remind_disabled: 'Reminder sent recently',
//     button_text_cancel: 'Cancel invitation',
//     button_text_done: 'Done',
//     profile_invitation_heading: '{firstName} has been invited to join {orgName} as a {orgRole}',
//     profile_invitation_summary_singular: 'Invitation sent on {sentDate}',
//     profile_invitation_expiring_warning: 'This invitation will expire in {n} days',
//     profile_invitation_expired_warning: 'This invitation has expired',
//     profile_invitation_expiry_warning_period_days: 10,
//     done_heading: 'You have successfully sent a reminder to {firstName}'
//   }
// };

class ProfileBannerInvitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const CMS = content.invitationBanner || {};
    const { userId, onRemind, onCancel, onDone, showDoneButton, reminderDisabled, invite } = this.props;
    const invites = invite || {};

    // Separate pending and expired invitation:
    const isPendingInvite = invites.status === 'PENDING' && !invites.expired;
    const isExpiredInvite = invites.status === 'EXPIRED' || invites.expired;

    // Allow for paging: (Not needed in this version of the invitation banner)
    const currentInvite = invites;

    // We'll need this for the Accept/Decline/Dismiss actions:
    const { orgId } = currentInvite;

    const expiryInfo = getExpiryInformation(currentInvite.expiryDate, CMS.invitation_expiry_warning_period_days);

    // Set a flag to make subsequent code more readable:
    currentInvite.expiring = expiryInfo.isExpiring;
    // set expired flag to true if the invite hadn't expired when it was originally fetched but has subsequently expired based on expiration timestamp
    if (expiryInfo.hasExpired) {
      currentInvite.expired = true;
    }
    // Title of this individual invitation:
    const inviteHeading = CMS.profile_invitation_heading
      .replace('{firstName}', currentInvite.firstName)
      .replace('{lastName}', currentInvite.lastName)
      .replace('{orgName}', currentInvite.orgName)
      .replace('{orgRole}', content.sharedTerms[currentInvite.roleName]);

    let doneHeading = CMS.done_heading.replace('{firstName}', currentInvite.firstName);
    let thumbnailGlyph = GLYPHS.ICON_CHECK_CIRCLE;

    if (invite.cancelInvitationState === 'ERROR') {
      doneHeading = invite.errorMessage;
      thumbnailGlyph = GLYPHS.ICON_ERROR_CIRCLE;
    }

    // Description of this individual invitation:
    const inviteSummary = CMS.invitation_summary_singular.replace(
      '{sentDate}',
      moment(currentInvite.sentDate).format('ll')
    );

    const invitationExpiryWarning = CMS.profile_invitation_expiring_warning;
    const expiringWarning =
      expiryInfo.daysLeft.toString().length === 1
        ? invitationExpiryWarning.slice(0, 38) + invitationExpiryWarning.slice(39)
        : CMS.profile_invitation_expiring_warning;

    // Only needed when invitation is expiring or expired:
    const inviteWarning = (currentInvite.expired ? CMS.profile_invitation_expired_warning : expiringWarning).replace(
      '{n}',
      expiryInfo.daysLeft
    );

    return isPendingInvite || isExpiredInvite ? (
      <div id="invitationBanner" className={styles.bannerInvitation}>
        <div className="grid">
          <div className="pad-top2">
            {!showDoneButton ? (
              <div className="row sm-cols-edge">
                <div className="col gin-bot1">
                  <EntityRepresentation
                    thumbnailSize={thumbnailSizes.TABLE}
                    thumbnailColor={colors.ORGANIZATION}
                    thumbnailGlyph={GLYPHS.ICON_ORG}
                    line1Text={inviteHeading}
                    line2Text={inviteSummary}
                    line3Text={currentInvite.expired || currentInvite.expiring ? inviteWarning : undefined}
                    status="ERROR"
                    line1Color={colors.PRIMARY_BLUE}
                    deletedStyle={currentInvite.expired}
                  />
                </div>

                <div className="col">
                  <div className={classnames(styles.bannerButtons, 'row cols-right')}>
                    {!currentInvite.expired &&
                    !(
                      featureIsEnabled('opt-and-ote-emails-disabled') &&
                      isEltAssessmentMode() &&
                      isTestingUser(currentInvite.email)
                    ) ? (
                      <div className="col gin-bot1">
                        <Button
                          id="invitationRemindButton"
                          type={buttonTypes.PRIMARY}
                          onClick={() => onRemind(userId, orgId)}
                          text={reminderDisabled ? CMS.button_text_remind_disabled : CMS.button_text_remind}
                          disabled={reminderDisabled}
                        />
                      </div>
                    ) : null}

                    {!currentInvite.expired ? (
                      <div className="col">
                        <Button
                          id="invitationCancelButton"
                          type={buttonTypes.SECONDARY}
                          onClick={() => onCancel(userId, orgId)}
                          text={CMS.button_text_cancel}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            {showDoneButton ? (
              <div className="row sm-cols-edge">
                <div className="col gin-bot1">
                  <EntityRepresentation
                    thumbnailSize={thumbnailSizes.TABLE}
                    thumbnailColor={colors.DEFAULT}
                    thumbnailGlyph={thumbnailGlyph}
                    line1Text={doneHeading}
                    line1Color={colors.PRIMARY_BLUE}
                  />
                </div>
                <div className="col">
                  <div className="row cols-right">
                    <div className="col">
                      <Button id="doneButton" type={buttonTypes.PRIMARY} onClick={onDone} text={CMS.button_text_done} />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  }
}

ProfileBannerInvitation.propTypes = {
  /** The user ID to get the invites for. Typically the current logged-in user. */
  userId: PropTypes.string,
  showDoneButton: PropTypes.bool,
  /** Pending invitation, if any */
  invite: PropTypes.shape({
    firstName: PropTypes.string,
    email: PropTypes.string,
    orgId: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    roleName: PropTypes.string.isRequired,
    sentDate: PropTypes.string.isRequired,
    expiryDate: PropTypes.string.isRequired,
    expired: PropTypes.bool.isRequired,
    cancelInvitationState: PropTypes.string,
    errorMessage: PropTypes.string
  }),

  onRemind: PropTypes.func,
  onCancel: PropTypes.func,
  onDone: PropTypes.func,
  reminderDisabled: PropTypes.bool
};

export default ProfileBannerInvitation;
