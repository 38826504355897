/* eslint-disable class-methods-use-this */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import TextInput from '../../components/TextInput/TextInput.js';
import Button, { buttonTypes } from '../../components/Button/Button.js';
import {
  checkActivationCodeRequest,
  setActivationCode,
  setVstActivationCode,
  validateActivationCode,
  validateVstActivationCode,
  resetErrorMessageCode
} from '../../redux/actions/activationCode';
import { reCAPTCHASetToken } from '../../redux/actions/reCAPTCHA.js';
import Validation from '../../components/Validation/Validation.js';
import styles from './RedeemCode.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
import withLocalizedErrors from '../../language/withLocalizedErrors';
import { safePanelLink } from '../../utils/links/panelLinks';
import { DATE_SHORT } from '../../globals/dateFormats';
import { getLabelMessage, getMessageAttributes } from './getErrorMessage';

class RedeemCode extends Component {
  componentDidMount() {
    this.submit();
  }

  componentDidUpdate(prevProps) {
    const { googleReCaptchaProps, errorOccurred } = this.props;
    if (prevProps.googleReCaptchaProps !== googleReCaptchaProps || prevProps.errorOccurred !== errorOccurred) {
      this.submit();
    }
  }

  submit = async () => {
    const { googleReCaptchaProps, setReCAPTCHATokenAction, skipGoogleReCaptchaProps } = this.props;
    if (!skipGoogleReCaptchaProps) {
      const token = await googleReCaptchaProps.executeRecaptcha(APP_CONSTANTS.RECAPTCHA_ACTIONS.REDEEM_CODE);
      setReCAPTCHATokenAction(token);
    }
  };

  setRedeemCode = activationCode => {
    localStorage.setItem('acivationCode', activationCode);
  };

  setRedeemCodeValueInLS = (redeemCodeKey, redeemCodeValue, isVstCode = false) => {
    localStorage.setItem(redeemCodeKey, redeemCodeValue);
    localStorage.setItem('isVstActivationCode', isVstCode);
  };

  getLicenceStatus = (errorMessageData, getLocalizedErrorMessage) => {
    const { resetErrorMessageCodeAction } = this.props;
    let errorMessage = '';
    if (errorMessageData) {
      errorMessage = getLocalizedErrorMessage(errorMessageData);
      if (typeof errorMessageData === 'object' && errorMessageData.activationDate) {
        errorMessage = errorMessage.replace('{1}', moment(errorMessageData.activationDate).format(DATE_SHORT));
      }
    } else {
      resetErrorMessageCodeAction();
    }
    return errorMessage;
  };

  getClassNameForValidation = (redeemCodeRole, redeemCodeValueIsValid, errorMessageText, messageAttributes) => {
    if (!redeemCodeRole || !redeemCodeValueIsValid) {
      return '';
    }
    if (!messageAttributes?.isInvalidCodeError && errorMessageText !== messageAttributes.incorrectErrorMessage) {
      return 'vstRoleMessage';
    }
    return 'errorMessage';
  };

  render() {
    const {
      checkCodeAction,
      redeemCodeValue,
      setVstRedeemCodeAction,
      validateVstRedeemCodeAction,
      redeemCodeValueValidationMessage,
      redeemCodeRole,
      isVstCode,
      redeemCodeValueIsValid,
      redeemCodeValueIsError,
      localizedContent: { redeemCodePage },
      getLocalizedErrorMessage,
      history,
      formState,
      errorMessage,
      errorOccurred
    } = this.props;

    this.setRedeemCode(redeemCodeValue.replace(/-/g, ''));
    switch (formState) {
      case 'SUBMITTED':
        if (redeemCodeValueValidationMessage === 'VALID') {
          this.setRedeemCodeValueInLS(APP_CONSTANTS.REDEEM_CODE, redeemCodeValue, isVstCode);
          const queryString = window.location.search;
          history.push(safePanelLink(queryString ? `code${queryString}` : 'code'));
        }
        break;
      default:
    }

    const messageAttributes = getMessageAttributes(
      redeemCodeValue,
      redeemCodeValueIsError,
      redeemCodePage.incorrect_format,
      isVstCode ? redeemCodePage.code_with_role_max_length : redeemCodePage.code_max_length,
      this.getLicenceStatus(errorMessage, getLocalizedErrorMessage),
      redeemCodePage.incorrect_format,
      redeemCodePage.access_code_for_teachers,
      redeemCodePage.access_code_for_students,
      '',
      '',
      errorOccurred
    );

    const messageText = getLabelMessage(messageAttributes, null, redeemCodeRole, true);

    return (
      <div className={styles.redeemCodeBox}>
        <div className="text-center" />

        <Validation
          forId="enterRedeemCode"
          isValid={redeemCodeValueIsValid}
          isError={redeemCodeValueIsError || errorOccurred}
          rightHandInput="true"
          message={messageText}
          className={styles.validationWrapper}
          noLabelAboveField="false"
          customClassName={this.getClassNameForValidation(
            redeemCodeRole,
            redeemCodeValueIsValid,
            messageText,
            messageAttributes
          )}
        >
          <TextInput
            id="enterRedeemCode"
            placeholder={redeemCodePage.placeholder_text}
            maxLength={14}
            value={redeemCodeValue}
            onChange={value => {
              setVstRedeemCodeAction(value);
            }}
            onBlur={() => {
              validateVstRedeemCodeAction();
            }}
          />
        </Validation>
        <div className={styles.linkItem} />
        <div className={styles.content}>
          <Button
            id="redeemCodeButton"
            type={buttonTypes.PRIMARY}
            text={redeemCodePage.check_button_text}
            onClick={checkCodeAction}
            disabled={!redeemCodeValueIsValid || errorMessage}
          />
        </div>
      </div>
    );
  }
}
RedeemCode.propTypes = {
  checkCodeAction: PropTypes.func.isRequired,
  redeemCodeValue: PropTypes.string.isRequired,
  setVstRedeemCodeAction: PropTypes.func,
  validateVstRedeemCodeAction: PropTypes.func,
  redeemCodeRole: PropTypes.string,
  isVstCode: PropTypes.bool,
  redeemCodeValueIsValid: PropTypes.bool.isRequired,
  redeemCodeValueIsError: PropTypes.bool.isRequired,
  redeemCodeValueValidationMessage: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  errorOccurred: PropTypes.bool,
  errorMessage: PropTypes.object,
  getLocalizedErrorMessage: PropTypes.func.isRequired,
  formState: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  resetErrorMessageCodeAction: PropTypes.func.isRequired,
  setReCAPTCHATokenAction: PropTypes.func.isRequired,
  googleReCaptchaProps: PropTypes.object.isRequired,
  skipGoogleReCaptchaProps: PropTypes.bool
};

export default compose(
  withRouter,
  withLocalizedContent('redeemCodePage'),
  withLocalizedErrors('eps-redeem-code'),
  connect(
    state => ({
      redeemCodeValue: state.activationCode.redeemCodeValue,
      redeemCodeValueIsValid: state.activationCode.redeemCodeValueIsValid,
      redeemCodeValueIsError: state.activationCode.redeemCodeValueIsError,
      redeemCodeValueValidationMessage: state.activationCode.redeemCodeValueValidationMessage,
      errorOccurred: state.activationCode.errorOccurred,
      errorMessage: state.activationCode.errorMessage,
      formState: state.activationCode.formState,
      redeemCodeRole: state.activationCode.redeemCodeRole,
      isVstCode: state.activationCode.isVstCode
    }),
    {
      checkCodeAction: checkActivationCodeRequest,
      setRedeemCodeAction: setActivationCode,
      setVstRedeemCodeAction: setVstActivationCode,
      validateRedeemCodeAction: validateActivationCode,
      validateVstRedeemCodeAction: validateVstActivationCode,
      resetErrorMessageCodeAction: resetErrorMessageCode,
      setReCAPTCHATokenAction: reCAPTCHASetToken
    }
  )
)(withGoogleReCaptcha(RedeemCode));
