import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import prefixKeys from '../../utils/object/prefixKeys';
import styles from './ButtonGroup.scss';

/** Contains the style for buttonGroups (defaults to HTML standard vertical grouping) */
export const ButtonGroupType = {
  HORIZONTAL_GROUP: styles.HORIZONTAL_GROUP
};

function ButtonGroup({ id, children = [], groupLabel = '', groupType = '', role = 'radiogroup', aria = {} }) {
  const ariaAttrs = prefixKeys(aria, 'aria-');

  return (
    <div>
      {groupLabel ? <h3 className={styles.groupLabel}>{groupLabel}</h3> : null}
      <div id={id} className={classnames(styles.defaultGroupFormat, groupType)} type={role} {...ariaAttrs}>
        {children}
      </div>
    </div>
  );
}
ButtonGroup.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  groupLabel: PropTypes.string,
  groupType: PropTypes.string,
  aria: PropTypes.object,
  role: PropTypes.string
};

export default ButtonGroup;
