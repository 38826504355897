import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { isPlacementCentre } from '@oup/shared-node-browser/org.js';
import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import ContentWithThumbnail from '../ContentWithThumbnail/ContentWithThumbnail';
import Dropdown from '../Dropdown/Dropdown';
import PopupOverlay from '../PopupOverlay/PopupOverlay';
import Button, { buttonTypes } from '../Button/Button';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import styles from './AccountMenu.scss';
import { toInitials } from '../../utils/string';
import { isOrbMode, isHubMode, isOteMode, isLtiMode, isOicMode, isEbookSampleMode } from '../../utils/platform.js';
import { orgRoles } from '../../globals/orgRoles';
import { featureIsEnabled, getEpsAdminDashboardBaseUrl } from '../../globals/envSettings';
import { HubLayoutConstants } from '../../globals/hubConstants';
import getUrl from '../../globals/urls';
import TopBanner from '../../structure/SiteHeader/TopBanner/TopBanner';
import userRoles from '../../globals/userRoles';

const AccountMenu = function AccountMenu({
  buttonElementId,
  orgId,
  orgConfig = {},
  orgName = '',
  orgRole = '',
  customId = '',
  userFirstName = '',
  userLastName = '',
  userName,
  // userEmail,
  friendlyUserRole,
  userRole,
  userOrganisations = [],
  onOrgRegisterClick,
  onEditAccountClick,
  onTogglePopup,
  onLogout,
  onOrgChange,
  myOrgUrl,
  localizedContent: { accountMenuComponent: content }
}) {
  const editAccountLink = getUrl({ type: 'EDIT_USER', orgId, userRole });
  const isImpersonationMode = !!localStorage.getItem('impersonation-token');
  const allowOrgSwitching =
    userOrganisations.length > 1 && !isImpersonationMode && !isLtiMode() && !isEbookSampleMode();
  const isModalBasedEditAccount = isHubMode() || isOicMode();
  const isOrgPlacementCentre = featureIsEnabled('opt-main-features') && isPlacementCentre(orgConfig);

  const handleExitImpersonation = () => {
    localStorage.removeItem('impersonation-token');
    localStorage.removeItem('user-info');
    localStorage.removeItem('lti-token'); // just in case...
    window.location.href = `${getEpsAdminDashboardBaseUrl()}/dashboard/user/impersonate`;
  };

  function shouldDisplayMyOrganizationsSection(role) {
    if (!orgId) return false;
    if (isEbookSampleMode()) return false;
    if (!isHubMode()) return true;
    if (HubLayoutConstants.OUP_ROLES.includes(role)) return false;
    if (isLtiMode() && ![userRoles.TEACHER, userRoles.ORG_ADMIN].includes(role)) return false;
    return true;
  }

  return (
    <PopupOverlay
      id="accountWidget"
      visible
      hangRight
      customClassName={classnames(styles.headerPopup, styles.accountWidget)}
      onTogglePopup={onTogglePopup}
      aria={{
        label: 'Account Menu'
      }}
      role="listbox"
      buttonElementId={buttonElementId}
      customStyle={{ maxHeight: `calc(100vh - ${document.getElementById('fixedHeader').clientHeight}px)` }}
    >
      <nav className={classnames(styles.accountWidget, styles.profileMenu)}>
        <section className={styles.myDetails}>
          <h3> {content.myProfile_heading} </h3>

          {isImpersonationMode && (
            <Button
              onClick={handleExitImpersonation}
              text="Exit impersonation"
              type={buttonTypes.PRIMARY}
              customClassName={styles.exitImpersonationButton}
            />
          )}

          <ContentWithThumbnail
            glyph={isImpersonationMode ? GLYPHS.ICON_IMPERSONATION : null}
            size={thumbnailSizes.SMALL}
            text={!isImpersonationMode ? toInitials(userFirstName, userLastName) : null}
            backgroundColor={colors.PROFILE}
            thumbnailClassName={
              isImpersonationMode ? styles.impersonationDetailsThumbnail : styles.userDetailsThumbnail
            }
          >
            <div>
              <p>
                {userFirstName} {userLastName}
              </p>
              <p className={styles.smallSubText}> {isLtiMode() ? content.ltiUser : userName} </p>
            </div>
          </ContentWithThumbnail>

          {featureIsEnabled('edit-my-account-button') && !isLtiMode() && !isEbookSampleMode()
            ? orgRole !== orgRoles.PRIMARY_SCHOOL && (
                <Button
                  onClick={isModalBasedEditAccount ? onEditAccountClick : null}
                  to={!isModalBasedEditAccount ? editAccountLink : ''}
                  text={content.editMyAccountButton}
                  type={buttonTypes.GHOST}
                  customClassName={classnames(styles.editAccountButton, {
                    [styles.modalBasedEditAccountButton]: isModalBasedEditAccount
                  })}
                  dataTestId="NAV_HEADER_OPEN_EDIT_ACCOUNT"
                />
              )
            : null}
        </section>
        {featureIsEnabled('show-hub-redirect-banner') && !isOicMode() ? (
          <section className={styles.topBanner}>
            <TopBanner widgetOption="accountMenu" />
          </section>
        ) : null}
        {shouldDisplayMyOrganizationsSection(userRole) ? (
          <section className={styles.myOrganizations}>
            <h3> {content.myOrg_heading} </h3>
            <Link to={myOrgUrl || '/'} id="accountWidget-myOrg" className={styles.myOrgLink} onClick={onTogglePopup}>
              <ContentWithThumbnail
                size={thumbnailSizes.SMALL}
                glyph={GLYPHS.ICON_ORG}
                backgroundColor={colors.ORGANIZATION}
              >
                <div>
                  <div id="accountWidget-myOrgName">
                    <span className="a11y-hide"> {content.myOrg_name_title} </span> {orgName}
                  </div>
                  {customId ? (
                    <div className={styles.smallSubText}>
                      {content.myOrg_id_title} {customId}
                    </div>
                  ) : null}
                  <div className={styles.smallSubText}>
                    {content.myOrg_role_title}
                    {friendlyUserRole}
                  </div>
                </div>
              </ContentWithThumbnail>
            </Link>
            {allowOrgSwitching ? (
              <Dropdown
                id="accountWidget-switchOrg"
                name="accountWidget-switchOrg"
                label={content.switchOrg_heading}
                value={orgId}
                options={userOrganisations.map(org => ({
                  value: org.id,
                  text: org.isBlocked ? `${org.name} ${content.read_only_mode}` : org.name
                }))}
                customClassName={styles.dropdown}
                onChange={onOrgChange}
              />
            ) : null}
          </section>
        ) : null}
        {!isOrbMode() &&
        !isOteMode() &&
        onOrgRegisterClick &&
        !isLtiMode() &&
        !isOicMode() &&
        !isEbookSampleMode() &&
        !isOrgPlacementCentre ? (
          <section>
            <Link id="accountWidget-registerOrg" className={styles.itemLink} onClick={onOrgRegisterClick}>
              <ContentWithThumbnail
                size={thumbnailSizes.SMALL}
                glyph={GLYPHS.ICON_PLUS}
                backgroundColor={colors.PRIMARY}
              >
                {content.registerOrg_link}
              </ContentWithThumbnail>
            </Link>
          </section>
        ) : null}
        {isOrbMode() && (
          <section>
            <Link
              id="accountWidget-contactPreferences"
              className={styles.itemLink}
              to="/teacherHome/contactPreferences"
            >
              {content.contactPreferences_link}
            </Link>
          </section>
        )}
        <section>
          <Link id="accountWidget-signOut" className={styles.itemLink} onClick={onLogout}>
            {content.logOut_link}
          </Link>
        </section>
      </nav>
    </PopupOverlay>
  );
};

AccountMenu.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  /** This is the ID of the button used to toggle the popup. Click to this element are ignored when closing the popup */
  buttonElementId: PropTypes.string.isRequired,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userName: PropTypes.string.isRequired,
  friendlyUserRole: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  // userEmail: PropTypes.string,
  userOrganisations: PropTypes.array,
  orgName: PropTypes.string,
  orgRole: PropTypes.string,
  orgId: PropTypes.string,
  orgConfig: PropTypes.object,
  customId: PropTypes.string,
  onTogglePopup: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onOrgChange: PropTypes.func.isRequired,
  onOrgRegisterClick: PropTypes.func,
  myOrgUrl: PropTypes.string,
  onEditAccountClick: PropTypes.func
};

export default withLocalizedContent('accountMenuComponent')(AccountMenu);
