import * as t from '../actionTypes';

export const sendRemoveUserLicenceRequest = licenceId => ({
  type: t.SEND_REMOVE_USER_LICENCE_REQUEST,
  payload: licenceId
});

export const sendRemoveUserLicenceSuccess = userLicence => ({
  type: t.SEND_REMOVE_USER_LICENCE_SUCCESS,
  payload: userLicence
});

export const sendRemoveUserLicenceFailure = errors => ({
  type: t.SEND_REMOVE_USER_LICENCE_FAILURE,
  payload: errors
});

export const sendRemoveUserLicenceReset = () => ({
  type: t.SEND_REMOVE_USER_LICENCE_RESET,
  payload: null
});
