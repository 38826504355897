import { pull } from 'lodash';

export const LOAD_CLASS_DETAILS = 'classrooms/LOAD_CLASS_DETAILS';
const CLASS_DETAILS_LOAD_ERROR = 'classrooms/CLASS_DETAILS_LOAD_ERROR';
export const CLASS_DETAILS_LOADED = 'classrooms/CLASS_DETAILS_LOADED';

const STORE_CLASSROOMS = 'classrooms/STORE_CLASSROOMS';
const CLEAR_STORE_CLASSROOMS = 'classrooms/CLEAR_STORE_CLASSROOMS';
const ARCHIVE_CLASSROOMS = 'classrooms/ARCHIVE_CLASSROOMS';

const UPDATE_CLASSROOM_NAME = 'classrooms/UPDATE_CLASSROOM_NAME';
const UPDATE_CLASS_JOINING_CODE = 'classrooms/UPDATE_CLASS_JOINING_CODE';
const UPDATE_CLASSROOM_LIMIT = 'classrooms/UPDATE_CLASSROOM_LIMIT';
const ADD_STUDENTS_TO_CLASSROOM = 'classrooms/ADD_STUDENTS_TO_CLASSROOM';
export const UPDATE_STUDENTS_TO_CLASSROOM = 'classrooms/UPDATE_STUDENTS_TO_CLASSROOM';
const ADD_TEACHERS_TO_CLASSROOM = 'classrooms/ADD_TEACHERS_TO_CLASSROOM';
const REMOVE_STUDENTS_FROM_CLASSROOM = 'classrooms/REMOVE_STUDENTS_FROM_CLASSROOM';
const REMOVE_TEACHERS_FROM_CLASSROOM = 'classrooms/REMOVE_TEACHERS_FROM_CLASSROOM';

const initialState = {
  data: {}
};

const initialClassDetails = {
  loading: true,
  errorLoading: null,

  name: 'Loading Class...',
  teacherIdList: [],
  studentIdList: []
};

export default function classrooms(state = initialState, action = {}) {
  let studentIdList = [];
  let teacherIdList = [];
  switch (action.type) {
    case LOAD_CLASS_DETAILS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.classId]: {
            ...initialClassDetails,
            ...state.data[action.classId]
          }
        }
      };
    case CLASS_DETAILS_LOAD_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          [action.classId]: {
            ...state.data[action.classId],
            loading: false,
            errorLoading: true
          }
        }
      };
    case CLASS_DETAILS_LOADED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.classId]: {
            ...state.data[action.classId],
            loading: false,
            amountOfTeachers: action.classData.teacherIdList.length,
            amountOfLearners: action.classData.studentIdList.length,
            ...action.classData
          }
        }
      };
    case STORE_CLASSROOMS:
      return {
        ...state,
        data: {
          ...state.data,
          ...Object.keys(action.classrooms).reduce((results, id) => {
            // eslint-disable-next-line no-param-reassign
            results[id] = {
              ...state.data[id],
              ...action.classrooms[id]
            };

            return results;
          }, {})
        }
      };
    case CLEAR_STORE_CLASSROOMS:
      return initialState;
    case ARCHIVE_CLASSROOMS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.classes.reduce((results, classroomId) => {
            results[classroomId] = {
              ...state.data[classroomId],
              archived: true
            };
            return results;
          }, {})
        }
      };
    case UPDATE_CLASSROOM_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          [action.classroomId]: {
            ...state.data[action.classroomId],
            name: action.classroomName
          }
        }
      };
    case UPDATE_CLASS_JOINING_CODE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.classroomId]: {
            ...state.data[action.classroomId],
            joiningCode: { ...state.data[action.classroomId].joiningCode, ...action.joiningCode }
          }
        }
      };
    case UPDATE_CLASSROOM_LIMIT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.classroomId]: {
            ...state.data[action.classroomId],
            studentLimit: action.studentLimit
          }
        }
      };
    case ADD_STUDENTS_TO_CLASSROOM:
      studentIdList = [...state.data[action.classroomId].studentIdList, ...action.addedStudents];
      return {
        ...state,
        data: {
          ...state.data,
          [action.classroomId]: {
            ...state.data[action.classroomId],
            studentIdList,
            amountOfLearners: studentIdList.length
          }
        }
      };
    case UPDATE_STUDENTS_TO_CLASSROOM:
      studentIdList = [...state.data[action.classroomId].studentIdList, ...action.addedStudents];
      studentIdList = [...new Set(studentIdList)];
      return {
        ...state,
        data: {
          ...state.data,
          [action.classroomId]: {
            ...state.data[action.classroomId],
            studentIdList,
            amountOfLearners: studentIdList.length
          }
        }
      };
    case ADD_TEACHERS_TO_CLASSROOM:
      teacherIdList = [...state.data[action.classroomId].teacherIdList, ...action.addedTeachers];
      return {
        ...state,
        data: {
          ...state.data,
          [action.classroomId]: {
            ...state.data[action.classroomId],
            teacherIdList,
            amountOfTeachers: teacherIdList.length
          }
        }
      };
    case REMOVE_STUDENTS_FROM_CLASSROOM:
      studentIdList = pull(state.data[action.classroomId].studentIdList, ...action.removedStudents);
      return {
        ...state,
        data: {
          ...state.data,
          [action.classroomId]: {
            ...state.data[action.classroomId],
            studentIdList,
            amountOfLearners: studentIdList.length
          }
        }
      };
    case REMOVE_TEACHERS_FROM_CLASSROOM:
      teacherIdList = pull(state.data[action.classroomId].teacherIdList, ...action.removedTeachers);
      return {
        ...state,
        data: {
          ...state.data,
          [action.classroomId]: {
            ...state.data[action.classroomId],
            teacherIdList,
            amountOfTeachers: teacherIdList.length
          }
        }
      };
    default:
      return state;
  }
}

export const loadClassDetails = (orgId, classId) => ({
  type: LOAD_CLASS_DETAILS,
  orgId,
  classId
});
export const classDetailsLoadError = classId => ({
  type: CLASS_DETAILS_LOAD_ERROR,
  classId
});
export const classDetailsLoaded = (classId, classData) => ({
  type: CLASS_DETAILS_LOADED,
  classId,
  classData
});

export const storeClassrooms = classroomData => ({
  type: STORE_CLASSROOMS,
  classrooms: classroomData
});

export const clearStoreClassrooms = () => ({
  type: CLEAR_STORE_CLASSROOMS
});
export const archiveClassrooms = classes => ({
  type: ARCHIVE_CLASSROOMS,
  classes
});

export const updateClassroomName = (classroomId, classroomName) => ({
  type: UPDATE_CLASSROOM_NAME,
  classroomId,
  classroomName
});
export const updateClassJoiningCode = (classroomId, joiningCode) => ({
  type: UPDATE_CLASS_JOINING_CODE,
  classroomId,
  joiningCode
});

export const updateClassroomLimit = (classroomId, studentLimit) => ({
  type: UPDATE_CLASSROOM_LIMIT,
  classroomId,
  studentLimit
});

export const addStudentsToClassroom = (classroomId, addedStudents) => ({
  type: ADD_STUDENTS_TO_CLASSROOM,
  classroomId,
  addedStudents
});

export const updateStudentsToClassroom = (classroomId, addedStudents) => ({
  type: UPDATE_STUDENTS_TO_CLASSROOM,
  classroomId,
  addedStudents
});

export const addTeachersToClassroom = (classroomId, addedTeachers) => ({
  type: ADD_TEACHERS_TO_CLASSROOM,
  classroomId,
  addedTeachers
});

export const removeStudentsFromClassroom = (classroomId, removedStudents) => ({
  type: REMOVE_STUDENTS_FROM_CLASSROOM,
  classroomId,
  removedStudents
});

export const removeTeachersFromClassroom = (classroomId, removedTeachers) => ({
  type: REMOVE_TEACHERS_FROM_CLASSROOM,
  classroomId,
  removedTeachers
});
