import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import formatLastAccessed from '../../utils/date/formatLastAccessed';
import { secToTime, timeToSec } from '../../utils/time';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import ErrorStrip from '../ErrorStrip/ErrorStrip';
import GradedReadersClassViewTable from './GradedReadersClassViewTable';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import SpacingOptions, { OPTIONS as SPACING_OPTIONS } from '../SpacingOptions/SpacingOptions';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './GradedReadersClassView.scss';
import content from '../../utils/cmsContent';
import { featureIsEnabled } from '../../globals/envSettings';

momentDurationFormatSetup(moment);

export default class GradedReadersClassView extends Component {
  /**
   * Formats the raw data for use with the table
   *
   * @param {Object} data The raw data
   * @return {Array} The formatted data for the table
   */
  static formatData = data => {
    const dataValues = Object.values(data);

    const formattedData = Object.entries(data).map(([learnerId, learner]) => ({
      name: (
        <LinkWithIcon
          text={`${learner.firstname} ${learner.lastname}`}
          glyph={GLYPHS.ICON_RIGHT}
          to={`${window.location.pathname}/${learnerId}${window.location.search}`}
        />
      ),
      accessed:
        featureIsEnabled('replacing-last-accessed-with-last-opened') && learner.lastOpened
          ? formatLastAccessed(learner.lastOpened)
          : formatLastAccessed(learner.lastAccessed),
      title: learner.currentProduct || '-',
      currentLevel: learner.currentLevel || '-',
      booksFinished: learner.productsCompleted || 0,
      wordsRead: numeral(learner.wordsRead).format('0,0'),
      readingTime: moment.duration(learner.readingTime).format('h [hrs] m [min]'),
      lastAccessed:
        featureIsEnabled('replacing-last-accessed-with-last-opened') && learner.lastOpened
          ? learner.lastOpened
          : learner.lastAccessed,
      readingTimeDateFormat: learner.readingTime
    }));
    // Define initial carry with some common occurances
    const initialCarry = {
      meta: 'average',
      name: 'Class Average',
      accessed: '-',
      title: '-',
      currentLevel: '-',
      lastAccessed: '',
      readingTimeDateFormat: ''
    };

    const averageRow = dataValues.reduce((carry, current, index) => {
      const rollingCarry = {
        ...initialCarry,
        booksFinished: current.productsCompleted + (carry.booksFinished || 0),
        wordsRead: current.wordsRead + (carry.wordsRead || 0),
        readingTime: timeToSec(current.readingTime) + (carry.readingTime || 0)
      };

      // Complete the average calculation
      if (index + 1 === dataValues.length) {
        rollingCarry.booksFinished = numeral(rollingCarry.booksFinished / dataValues.length).format('0.0');
        rollingCarry.wordsRead = numeral(rollingCarry.wordsRead / dataValues.length).format('0,0.0');
        rollingCarry.readingTimeDateFormat = new Date((rollingCarry.readingTime / dataValues.length) * 1000)
          .toISOString()
          .substr(11, 8);
        rollingCarry.readingTime = moment
          .duration(secToTime(rollingCarry.readingTime / dataValues.length))
          .format('h [hrs] m [min]');
      }

      return rollingCarry;
    }, {});

    return [averageRow, ...formattedData];
  };

  render() {
    const {
      data,
      sortKey,
      sortDirection,
      saveSort,
      switchSpacing,
      tableSpacing = SPACING_OPTIONS.MEDIUM,
      tableLoading,
      failure,
      classTitle
    } = this.props;
    const CMS = content.gradedReadersClassView || {};
    const lastOpenOrAccessed = featureIsEnabled('replacing-last-accessed-with-last-opened')
      ? 'Last opened'
      : 'Last accessed';
    const columns = [
      { Header: 'Student', accessor: 'name', stickyColumn: true, sortable: true },
      { Header: lastOpenOrAccessed, accessor: 'accessed', sortable: true },
      { Header: 'Current title', accessor: 'title', sortable: true },
      { Header: 'Current level', accessor: 'currentLevel', sortable: true },
      { Header: 'Books finished', accessor: 'booksFinished', sortable: true },
      { Header: 'Words read', accessor: 'wordsRead', sortable: true },
      { Header: 'Reading time', accessor: 'readingTime', sortable: true }
    ];

    return (
      <div className="grid gin-top2">
        <div className="row">
          <div className="col">
            <Breadcrumbs
              paths={[
                {
                  pathname: window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/reader')),
                  text: CMS.button_back_to_text
                }
              ]}
            />

            <div className={styles.header}>
              <h1 className={styles.topMarginFlex}>{CMS.grade_reader_title_text}</h1>
              <SpacingOptions onSwitch={switchSpacing} selected={tableSpacing} customClassName="gin-top1" />
            </div>

            {tableLoading ? (
              <LoadingSpinner text={CMS.grade_reader_loading_text} />
            ) : (
              <div>
                <GradedReadersClassViewTable
                  records={GradedReadersClassView.formatData(data)}
                  columns={columns}
                  sortKey={sortKey}
                  sortDirection={sortDirection}
                  sortOnChange={saveSort}
                  tableSpacing={tableSpacing}
                  isLoading={tableLoading}
                  classTitle={classTitle}
                />
              </div>
            )}
            {failure ? <ErrorStrip /> : null}
          </div>
        </div>
      </div>
    );
  }
}

GradedReadersClassView.propTypes = {
  data: PropTypes.object.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  saveSort: PropTypes.func.isRequired,
  switchSpacing: PropTypes.func.isRequired,
  tableSpacing: PropTypes.oneOf(Object.values(SPACING_OPTIONS)),
  tableLoading: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  classTitle: PropTypes.string.isRequired
};
