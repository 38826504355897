import AbstractStorage from './Abstract';

class SessionStorage extends AbstractStorage {
  constructor(key) {
    super();
    this.key = key;
  }

  set(data) {
    if (!window.sessionStorage) {
      console.error('Session storage not available!');
      return;
    }

    super.set(this.key, data);

    window.sessionStorage.setItem(this.key, JSON.stringify(data));
  }

  get() {
    if (!window.sessionStorage) {
      console.error('Session storage not available!');
      return null;
    }

    const storageData = window.sessionStorage.getItem(this.key);

    if (!storageData) {
      return null;
    }

    try {
      return JSON.parse(storageData);
    } catch (error) {
      console.error('Error parsing JSON');
      return null;
    }
  }

  exists() {
    if (!window.sessionStorage) {
      console.error('Session storage not available!');
      return false;
    }

    return !!window.sessionStorage.getItem(this.key);
  }

  clear() {
    if (!window.sessionStorage) {
      console.error('Session storage not available!');
      return;
    }

    super.clear();

    window.sessionStorage.removeItem(this.key);
  }
}

export default SessionStorage;
