import * as t from '../actionTypes';

export const loadCourses = () => ({
  type: t.HUB_GET_COURSES
});

export const setCourses = payload => ({
  type: t.HUB_SET_COURSES,
  payload
});

export const loadCoursesSuccess = payload => ({
  type: t.HUB_GET_COURSES_SUCCESS,
  payload
});

export const loadCoursesFailure = payload => ({
  type: t.HUB_GET_COURSES_FAILURE,
  payload
});

// export const addAdaptedTestToAssessment = payload =>
