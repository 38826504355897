// Page load and clear actions
export const CLEAR_FORM = 'manageJoinAClassRequest/CLEAR_FORM';

const SET_USERS = 'manageJoinAClassRequest/SET_USERS';

// Update email choice
// const SET_NOTIFY_WITH_EMAIL = 'manageJoinAClassRequest/SET_NOTIFY_WITH_EMAIL';

// Nav actions
export const SUBMIT_FORM = 'manageJoinAClassRequest/SUBMIT_FORM';
const SUBMISSION_COMPLETE = 'manageJoinAClassRequest/SUBMISSION_COMPLETE';

export const formStates = {
  REVIEW_SELECTIONS: 'REVIEW_SELECTIONS',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION' // Success|Fail feedback after SUBMITTING
};

const initialState = {
  formState: formStates.REVIEW_SELECTIONS,

  // List submitted for removal
  submittedIds: [],
  requestStatus: '',
  requested: false,
  // List of users who were not removed from the class after submission
  failedIds: [],

  // General error catch all
  requestFailed: null
};

export default function manageJoinAClassRequest(state = initialState, action = {}) {
  switch (action.type) {
    // Page load and clear actions
    case CLEAR_FORM:
      return {
        ...initialState
      };

    // Set users to remove
    case SET_USERS:
      return {
        ...state,
        submittedIds: action.submittedIds
      };

    // Update email choice
    // case SET_NOTIFY_WITH_EMAIL:
    //   return {
    //     ...state,
    //     notifyWithEmail: action.notifyWithEmail
    //   };

    // Nav actions
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING,
        requestStatus: action.requestStatus,
        requested: action.requested
      };
    case SUBMISSION_COMPLETE:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        requestFailed: action.requestFailed,
        failedIds: action.failedIds
      };
    default:
      return state;
  }
}

// Page load and clear actions
export const clearForm = () => ({
  type: CLEAR_FORM
});

export const setUsers = submittedIds => ({
  type: SET_USERS,
  submittedIds
});

// Update email choice
// export const setNotifyWithEmail = notifyWithEmail => ({
//   type: SET_NOTIFY_WITH_EMAIL,
//   notifyWithEmail
// });

// Nav actions
export const submitForm = (requestStatus, requested) => ({
  type: SUBMIT_FORM,
  requestStatus,
  requested
});
export const submissionComplete = (requestFailed, failedIds) => ({
  type: SUBMISSION_COMPLETE,
  requestFailed,
  failedIds
});
