import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styles from './LevelJumpDropdown.scss';
import Dropdown from '../Dropdown/Dropdown';

function LevelJumpDropdown({ levels = {}, selected, router }) {
  const _redirectTo = newUId => {
    const lastSlash = window.location.pathname.lastIndexOf('/');
    const baseUrl = window.location.pathname.slice(0, lastSlash);
    const newUrl = `${baseUrl}/${newUId}`;

    router.replace(newUrl);
  };

  const _rawLevelsToArray = rawLevels =>
    Object.entries(rawLevels).map(([key, value]) => ({
      text: `${value.firstname} ${value.lastname}`,
      value: key
    }));

  return (
    <div className={styles.LevelJumpDropdown}>
      <Dropdown
        id="levelJumpDropdown"
        name="levelJumpDropdown"
        label="Show results for"
        options={_rawLevelsToArray(levels)}
        onChange={value => _redirectTo(value)}
        value={selected}
      />
    </div>
  );
}

LevelJumpDropdown.propTypes = {
  levels: PropTypes.object,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  router: PropTypes.object
};

export default withRouter(LevelJumpDropdown);
