import * as t from '../actionTypes';

export const checkProductExistsRequest = (formData, selectedPlatformCode) => ({
  type: t.CHECK_PRODUCT_EXISTS_REQUEST,
  formData,
  selectedPlatformCode
});

export const checkProductExistsSuccess = data => ({
  type: t.CHECK_PRODUCT_EXISTS_SUCCESS,
  payload: data
});

export const checkProductExistsFailure = data => ({
  type: t.CHECK_PRODUCT_EXISTS_FAILURE,
  payload: data
});

export const checkProductClear = data => ({
  type: t.CHECK_PRODUCT_EXISTS_CLEAR,
  payload: data
});
