import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DOMPurify from 'dompurify';
import ROLES, { userRoleFriendlyNames } from '../../globals/userRoles';
import { DropdownOption as DropdownOptionPropType } from '../../types';
import content from '../../utils/cmsContent';
import prefixKeys from '../../utils/object/prefixKeys';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Dropdown.scss';
import { glyphFor, validationStatusCodes } from '../ValidationStatus/ValidationStatusCodes';
import { validationStatusPropType } from '../../types/propTypeDefinitions';

function Dropdown({
  id,
  name,
  label,
  options,
  value,
  onChange,
  onBlur,
  labelHidden,
  labelLeft,
  customClassName,
  inline = false,
  disabled,
  required,
  aria,
  readOnly,
  validationStatus
}) {
  // If aria attributes were supplied, ensure they all have a prefix of "aria-":
  const ariaAttrs = prefixKeys(aria, 'aria-');

  const mapValue = value && options.find(option => option.value === value);
  const textValue = mapValue ? mapValue.text : 'n/a';

  return (
    <div
      className={classnames(
        styles.dropdown,
        labelLeft && styles.labelLeft,
        inline && styles['dropdown--inline'],
        customClassName
      )}
    >
      <label htmlFor={id} className={labelHidden ? 'a11y-hide' : ''} aria-label={label}>
        {label}
      </label>
      {readOnly ? (
        <div>{textValue}</div>
      ) : (
        <div {...(!!validationStatus && { style: { display: 'flex' } })}>
          {/* eslint-disable-next-line no-restricted-globals */}
          <div className={classnames(styles.dropdownContainer, styles[status])}>
            <SVGIcon glyph={GLYPHS.ICON_DROPLIST} />
            <select
              id={id}
              name={name}
              value={value}
              onChange={event => onChange(event.target.value, event.target.name)}
              onBlur={onBlur ? event => onBlur(event.target.name) : null}
              disabled={disabled}
              required={required}
              {...ariaAttrs}
            >
              {options.map((option, key) => (
                <option
                  key={key}
                  value={option.value}
                  disabled={option.disabled}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option.text) }}
                />
              ))}
            </select>
          </div>
          {Object.values(validationStatusCodes).includes(validationStatus) && (
            <SVGIcon
              style={{
                minWidth: '30px',
                maxWidth: '30px',
                marginLeft: '10px',
                position: 'initial',
                transform: 'none'
              }}
              glyph={glyphFor[validationStatus]}
            />
          )}
        </div>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** the name of the input field needs to be unique */
  name: PropTypes.string.isRequired,
  /** the inputs text label */
  label: PropTypes.string.isRequired,
  /** Options are required */
  options: PropTypes.arrayOf(DropdownOptionPropType).isRequired,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.any,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func.isRequired,
  /** Function to bind to the native input onBlur event */
  onBlur: PropTypes.func,
  /** The Dropdown label can be hidden to users and only visible to screen readers */
  labelHidden: PropTypes.bool,
  /** The Dropdown label can be placed to the left of the field instead of above */
  labelLeft: PropTypes.bool,
  /** Allows extending the base Dropdown styling */
  customClassName: PropTypes.string,
  /** Set this to true to include inline specific style */
  inline: PropTypes.bool,
  /** Set this to true to disable any interaction on the select field */
  disabled: PropTypes.bool,
  /** Set this to true to make field mandatory for screenreaders etc */
  required: PropTypes.bool,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object,
  readOnly: PropTypes.bool,
  validationStatus: validationStatusPropType
};

Dropdown.defaultsFor = () => {
  const CMS = content.appComponents || {};
  return {
    userRole: {
      id: 'userRole',
      label: `${CMS.dropdown_label}${':'}`,
      placeholder: CMS.dropdown_placeholder,
      options: Object.keys(ROLES).map(key => ({
        value: key,
        text: userRoleFriendlyNames(key) || ROLES[key]
      }))
    }
  };
};

export default Dropdown;
