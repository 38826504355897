import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import unlinkSocialAccountsFetch from '../../../api/unlinkSocialAccounts';
import { RESPONSE_STATUS, ERROR_UNLINK_INVALID_CURRENT_PASSWORD } from '../../../../../globals/appConstants.js';

function* unlinkSocialAccounts(userId, input) {
  yield put(actions.editUserUnlinkAccountsSubmitting());
  const response = yield unlinkSocialAccountsFetch(userId, input);
  if (
    response &&
    response.status &&
    response.status.toLowerCase() !== 'success' &&
    (response.message === ERROR_UNLINK_INVALID_CURRENT_PASSWORD || response.code === 403) &&
    response.status === RESPONSE_STATUS.ERROR
  ) {
    yield put(actions.editUserUnlinkAccountsPasswordFailure(response.code, response.message));
  } else {
    yield put(actions.editUserSuccess());
  }
}

export default unlinkSocialAccounts;
