import { put } from 'redux-saga/effects';
import {
  userPreferencesRequest,
  setUserPreferencesSuccess,
  setUserPreferencesFailure
} from '../../../../actions/orbUserPreferences';
import setUserPreferencesApiCall from '../../../apiCalls/orb/setUserPreferences';

function* setUserPreferences(orgId, userId, preferences) {
  const response = yield setUserPreferencesApiCall(orgId, userId, preferences);

  if (response.status === 'success') {
    yield put(setUserPreferencesSuccess(response.data));
    yield put(userPreferencesRequest(orgId, userId));
    return;
  }

  yield put(setUserPreferencesFailure(response.message));
}

export default setUserPreferences;
