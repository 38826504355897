import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from '../Dropdown/Dropdown';
import { validGlyphs } from '../SVGIcon/SVGIcon';
import TextInputButton from '../TextInputButton/TextInputButton';
import TextInputField from '../TextInputField/TextInputField';
import TextInputLabel from '../TextInputLabel/TextInputLabel';
import styles from './TextInputWithButtonAndDropdown.scss';

/** The component renders a text input label field and button */
function TextInputWithButtonAndDropdown({
  // Text input props
  id,
  label,
  labelHidden,
  placeholder,
  inputValue,
  inputOnChange,
  inputOnKeyUp,
  inputType,
  inputRequired,
  // Button props
  buttonAction,
  buttonIcon,
  buttonText,
  // Options props
  dropdownId,
  dropdownLabel,
  dropdownOptions,
  dropdownValue,
  dropdownOnChange,
  role,
  aria,
  ariaControls
}) {
  return (
    <div className={classnames(styles.textInputWithButtonAndDropdown)}>
      <TextInputLabel id={id} label={label} labelHidden={labelHidden} />
      <div>
        <Dropdown
          id={dropdownId}
          label={dropdownLabel}
          options={dropdownOptions}
          value={dropdownValue}
          onChange={dropdownOnChange}
          labelHidden
          customClassName={styles.dropdown}
        />
        <TextInputField
          id={id}
          placeholder={placeholder}
          value={inputValue}
          onChange={inputOnChange}
          onKeyUp={inputOnKeyUp}
          type={inputType}
          required={inputRequired}
          aria={aria}
          role={role}
        />
        <TextInputButton
          id={`${id}-button`}
          icon={buttonIcon}
          text={buttonText}
          action={buttonAction}
          ariaControls={ariaControls}
        />
      </div>
    </div>
  );
}

TextInputWithButtonAndDropdown.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** the inputs text label */
  label: PropTypes.string.isRequired,
  /** The label can be hidden to users and only visible to screen readers */
  labelHidden: PropTypes.bool,
  /** the inputs placeholder text */
  placeholder: PropTypes.string.isRequired,
  /** Actual value of the input. If not preset then the placeholder is shown */
  inputValue: PropTypes.any,
  /** function that if the input is touched, will be called */
  inputOnChange: PropTypes.func.isRequired,
  /** function that will be called on ky up in the text input, e.g. to run the search when pressing enter */
  inputOnKeyUp: PropTypes.func.isRequired,
  /** The type attribute of the <input> element. Restricted to text looking inputs (ie not checkbox etc). */
  inputType: PropTypes.oneOf(['text', 'number', 'date']),
  /** Set this to false to prevent textbox from defaulting to required, for screenreaders etc */
  inputRequired: PropTypes.bool,

  /** Action button icon or text */
  buttonIcon: PropTypes.oneOf(validGlyphs),
  buttonText: PropTypes.string,
  /** button onclick */
  buttonAction: PropTypes.func.isRequired,

  /** the id of the input field needs to be unique */
  dropdownId: PropTypes.string.isRequired,
  /** the inputs text label */
  dropdownLabel: PropTypes.string.isRequired,
  /** Options are required */
  dropdownOptions: PropTypes.array.isRequired,
  /** Controlled value of the dropdown */
  dropdownValue: PropTypes.any,
  /** function that if the input is touched, will be called */
  dropdownOnChange: PropTypes.func.isRequired,

  /** the id(s) of the controlled elements */
  ariaControls: PropTypes.string,
  aria: PropTypes.object,
  role: PropTypes.string
};

export default TextInputWithButtonAndDropdown;
