import { put } from 'redux-saga/effects';
import { searchUsersSuccess, searchUsersFailure } from '../../../../actions/searchUsers';
import searchUsersApi from '../../../apiCalls/search/searchUsersEnrollmentData.api.js';

function* searchUsers(payload) {
  const response = yield searchUsersApi(payload);

  if (response.status === 'success') {
    yield put(searchUsersSuccess(response.data));
  } else {
    yield put(searchUsersFailure('Failed to search users'));
  }
}

export default searchUsers;
