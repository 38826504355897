import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import TextInput from '../TextInput/TextInput';
import PopupOverlay from '../PopupOverlay/PopupOverlay';
import Calendar from '../Calendar/Calendar';
import styles from './FormCalendar.scss';

function FormCalendar({ dateFormat, dateSelectedHandler, uniqueId }) {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [day, setDay] = useState(new Date());

  const handleOnChange = event => {
    const formatedDate = moment(event).format(dateFormat);
    dateSelectedHandler(formatedDate);
  };

  const renderCalendar = () => (
    <div className={styles.calendarWrapper}>
      <Calendar
        onDateSelectedHandler={d => {
          setDay(d);
          const formatedDate = moment(d).format(dateFormat);
          const input = document.querySelector(`#${uniqueId} input`);
          input.value = formatedDate;
          setIsOverlayOpen(false);
          dateSelectedHandler(formatedDate);
        }}
        selectedDate={day}
        availableCellsOnCalendar={42}
      />
    </div>
  );

  const renderOverlay = () => {
    if (isOverlayOpen) {
      return (
        <PopupOverlay
          id="calendar"
          ariaLabel={` `}
          visible={isOverlayOpen}
          panelSize="fullWidth"
          customClassName={styles.popUp}
          inline
          onTogglePopup={() => {}}
        >
          {renderCalendar()}
        </PopupOverlay>
      );
    }
    return <> </>;
  };

  return (
    <div className={styles.calendarContainer} id={uniqueId}>
      <TextInput
        onChange={handleOnChange}
        onClick={() => setIsOverlayOpen(true)}
        data-reset-id="calendar"
        labelHidden
      />
      {renderOverlay()}
    </div>
  );
}

FormCalendar.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  uniqueId: PropTypes.string.isRequired,
  dateSelectedHandler: PropTypes.func.isRequired
};

export default FormCalendar;
