import signedFetch from '../util/signedFetch.js';

export default data => {
  const { orgId, term: activationCode, start, size, sort } = data;
  return signedFetch(
    'searchProductsByActivationCode',
    `${__API_BASE__}/org/${encodeURIComponent(orgId)}/product/activation-code-query`,
    'POST',
    { activationCode, start, size, sort }
  );
};
