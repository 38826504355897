import PropTypes from 'prop-types';
import React from 'react';
import PopoutPanelListHeading from '../PopoutPanelListHeading/PopoutPanelListHeading';
import styles from './PopoutConfirmationList.scss';

function PopoutConfirmationList({ heading = '', children, id }) {
  return (
    <div id={id} className={styles.popoutConfirmationList}>
      {heading ? <PopoutPanelListHeading text={heading} /> : null}
      {children}
    </div>
  );
}

PopoutConfirmationList.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired
};

export default PopoutConfirmationList;
