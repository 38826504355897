import moment from 'moment';
import by from '../../utils/array/filterBy.js';

export const INITIALISE = 'invitationsBanner/INITIALISE';
export const FETCH_INVITES = 'invitationsBanner/FETCH_INVITES';
export const SET_INVITES = 'invitationsBanner/SET_INVITES';
export const SET_INVITES_FAILED = 'invitationsBanner/SET_INVITES_FAILED';
export const RESET_INVITES = 'invitationsBanner/RESET_INVITES';
export const DO_ACCEPT = 'invitationsBanner/DO_ACCEPT';
export const DO_DECLINE = 'invitationsBanner/DO_DECLINE';
export const DO_DISMISS = 'invitationsBanner/DO_DISMISS';
export const SHOW_SUBMITTING = 'invitationsBanner/SHOW_SUBMITTING';
export const SHOW_CONFIRMATION = 'invitationsBanner/SHOW_CONFIRMATION';
export const REDIRECT_TO_IDP = 'invitationsBanner/REDIRECT_TO_IDP';
export const SET_REQUEST_FAILED = 'invitationsBanner/SET_REQUEST_FAILED';
export const SHOW_INVITES = 'invitationsBanner/SHOW_INVITES';
export const SHOW_REVIEWING = 'invitationsBanner/SHOW_REVIEWING';

const EXPIRY_PERIOD_DAYS = 30;

export const formStates = {
  NO_INVITES: 'NO_INVITES',
  FETCHING: 'FETCHING',
  INPUTTING: 'INPUTTING',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION',
  REVIEWING: 'REVIEWING'
};

export const inviteStates = {
  DO_UPDATE: 'DO_UPDATE',
  UPDATING: 'UPDATING',
  UPDATED: 'UPDATED'
};

const initialState = {
  formState: formStates.FETCHING,
  invites: [],
  index: 0,
  orgJoined: false
};

// Helper to prepare the invites for the UI:
function toUiInviteFormat(invite) {
  return {
    orgId: invite.group.id,
    orgName: invite.group.name,
    groupType: invite.group.type,
    groupRole: invite.group.role,
    roleName: invite.roleName,
    status: invite.invitationStatus || (invite.expired ? 'EXPIRED' : 'PENDING'),
    sentDate: invite.date || '',
    expiryDate:
      invite.expiryDate ||
      (invite.date &&
        moment(invite.date)
          .add(EXPIRY_PERIOD_DAYS, 'days')
          .toJSON()) ||
      '',
    expired: invite.expired,
    dismissed: invite.dismissed,
    requestFailed: null,
    failureReason: null,
    isOptOrg: invite.group.isOptOrg,
    ...(invite.group?.classId ? { classId: invite.group.classId } : {})
  };
}

export default function invitationsBanner(state = initialState, action = {}) {
  switch (action.type) {
    case INITIALISE:
      return {
        ...initialState,
        formState: formStates.NO_INVITES
      };

    case FETCH_INVITES:
      return {
        ...state,
        formState: formStates.FETCHING,
        userId: action.userId
      };

    case SET_INVITES:
      return {
        ...state,
        formState: formStates.INPUTTING,
        userId: action.userId,
        // Reformat each invite into a flatter shape that the UI is expecting:
        invites: action.invites.map(toUiInviteFormat)
      };
    case RESET_INVITES:
      return {
        ...state,
        formState: formStates.INPUTTING
      };

    case SET_INVITES_FAILED:
      return {
        ...state,
        formState: formStates.INPUTTING
      };
    case DO_ACCEPT: {
      const orgId = action.orgId;
      const invites = state.invites;
      const invite = invites.find(by({ orgId }));

      if (invite) {
        invite.newStatus = action.invitationStatus;
        invite.state = inviteStates.DO_UPDATE;
        return {
          ...state,
          invites,
          orgId: action.orgId,
          orgJoined: true
        };
      }
      // else
      return state;
    }
    case DO_DISMISS: {
      const orgId = action.orgId;
      const invites = state.invites;
      const invite = invites.find(by({ orgId }));

      if (invite) {
        invite.newStatus = action.invitationStatus;
        invite.state = inviteStates.DO_UPDATE;
        return {
          ...state,
          invites,
          orgId: action.orgId,
          orgJoined: false
        };
      }
      // else
      return state;
    }
    case DO_DECLINE: {
      const orgId = action.orgId;
      const invites = state.invites;
      const invite = invites.find(by({ orgId }));

      if (invite) {
        invite.newStatus = action.invitationStatus;
        invite.state = inviteStates.DO_UPDATE;
        return {
          ...state,
          invites,
          orgJoined: false
        };
      }
      // else
      return state;
    }

    case SHOW_SUBMITTING: {
      const orgId = action.orgId;
      const invites = state.invites;
      const invite = invites.find(by({ orgId }));

      if (invite) {
        invite.state = inviteStates.UPDATING;
        return {
          ...state,
          invites,
          formState: formStates.SUBMITTING
        };
      }
      // else
      return state;
    }

    case SHOW_CONFIRMATION: {
      const orgId = action.orgId;
      const invites = state.invites;
      const invite = invites.find(by({ orgId }));

      if (invite) {
        invite.state = inviteStates.UPDATED;
        return {
          ...state,
          invites,
          formState: formStates.CONFIRMATION
        };
      }

      return {
        ...state,
        formState: formStates.CONFIRMATION
      };
    }

    case SHOW_REVIEWING:
      return {
        ...state,
        formState: formStates.REVIEWING,
        orgId: action.orgId
      };

    case SHOW_INVITES:
      return {
        ...state,
        formState: formStates.INPUTTING
      };

    case SET_REQUEST_FAILED: {
      const orgId = action.orgId;
      const invites = state.invites;
      const invite = invites.find(by({ orgId }));
      invite.requestFailed = action.failed;
      invite.failureReason = action.reason;
      return {
        ...state,
        invites
      };
    }

    default:
      return state;
  }
}

export const getInvites = userId => ({
  type: FETCH_INVITES,
  userId
});

export const setInvites = (userId, invites) => ({
  type: SET_INVITES,
  userId,
  invites
});

export const resetInvites = () => ({
  type: RESET_INVITES
});

export const setInvitesFailed = () => ({
  type: SET_INVITES_FAILED
});

export const doAccept = (userId, orgId) => ({
  type: DO_ACCEPT,
  orgId,
  invitationStatus: 'ACCEPTED'
});

export const doDecline = (userId, orgId) => ({
  type: DO_DECLINE,
  userId,
  orgId,
  invitationStatus: 'REJECTED'
});

export const doDismiss = (userId, orgId) => ({
  type: DO_DISMISS,
  userId,
  orgId,
  invitationStatus: 'DISMISS'
});

export const setInviteUpdating = (userId, orgId, invitationStatus) => ({
  type: SHOW_SUBMITTING,
  userId,
  orgId,
  invitationStatus
});

export const setInviteUpdated = (userId, orgId, invitationStatus) => ({
  type: SHOW_CONFIRMATION,
  userId,
  orgId,
  invitationStatus
});

export const showSubmitting = () => ({
  type: SHOW_SUBMITTING
});

export const showConfirmation = orgId => ({
  type: SHOW_CONFIRMATION,
  orgId
});

export const showInvites = () => ({
  type: SHOW_INVITES
});

export const showReviewing = orgId => ({
  type: SHOW_REVIEWING,
  orgId
});

// To trigger a refresh of role details after accepting an invite:
export const redirectToIdp = () => ({
  type: REDIRECT_TO_IDP
});

export const setRequestFailed = (orgId, failed, reason) => ({
  type: SET_REQUEST_FAILED,
  orgId,
  failed,
  reason
});
