import PropTypes from 'prop-types';
import React from 'react';
import ActionList, { Action } from '../../components/ActionList/ActionList';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer';
import { buttonTypes } from '../../components/Button/Button';

function DownloadSignInCardPanel({ content, hasFailed = false, onDone, onDownload, size }) {
  return (
    <ScrollContainer
      headerContent={
        hasFailed ? (
          <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.ERROR} title={content.sign_in_card_error_title} />
        ) : (
          <PopoutPanelIconHeading
            type={popoutPanelIconHeadingTypes.VALID}
            title={content.sign_in_card_success_title}
            subtitle={content.sign_in_card_success_subtitle}
          />
        )
      }
      footerContent={
        <ActionList>
          <Action
            label={(content.sign_in_card_download_button_text || '').replace('{kb}', size)}
            type={buttonTypes.DOWNLOAD}
            onClick={onDownload}
          />
          <Action label={content.sign_in_card_complete_button_text} primary onClick={onDone} />
        </ActionList>
      }
    />
  );
}

DownloadSignInCardPanel.propTypes = {
  content: PropTypes.object,
  hasFailed: PropTypes.bool,
  size: PropTypes.any,
  onDone: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired
};

export default DownloadSignInCardPanel;
