import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

// Style
import styles from './HubEmptyState.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
// Components
import Thumbnail from '../Thumbnail/Thumbnail';
import Button, { buttonTypes } from '../Button/Button';
import Link from '../Link/Link';

function HubEmptyState({
  glyphIcon,
  glyphSize,
  glyphBackgroundColour,
  title,
  subTitle,
  customGlyphClassName = '',
  customClassName = '',
  links = [],
  buttons = []
}) {
  return (
    <div className={`${styles.emptyStateContainer} ${styles[customClassName]}`}>
      <Thumbnail glyph={glyphIcon} size={glyphSize} backgroundColor={glyphBackgroundColour} />
      <h1>{title}</h1>
      <p>{subTitle}</p>
      <p>
        {!!links.length && (
          <span>
            {links.map((element, index) => (
              <span key={index}>
                <Link openInNewTab className={styles.links} to={element.link}>
                  {element.text}
                  <SVGIcon className={classnames(styles.linksGlyph, customGlyphClassName)} glyph={GLYPHS.ICON_LINK} />
                </Link>
              </span>
            ))}
          </span>
        )}
      </p>
      {!!buttons.length && (
        <div className={styles.redeemContainer}>
          {buttons.map((button, index) => {
            if (button.showButton) {
              return (
                <div
                  key={index}
                  className={`${styles.buttonContainer} ${button.isPrimaryButton ? styles.primaryButtonContainer : ''}`}
                >
                  <Button
                    to={button.link}
                    target={button.target || (button.link && '_blank')}
                    onClick={button.action}
                    text={button.text}
                    type={button.isPrimaryButton ? buttonTypes.PRIMARY : buttonTypes.ACTION_BLOCK_NO_ICON}
                    fullWidth={false}
                    dataTestId={button.testHook}
                  />
                  {button.link && !button.isPrimaryButton && (
                    <div className={styles.iconContainer}>
                      <SVGIcon glyph={GLYPHS.ICON_LINK} />
                    </div>
                  )}
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}

export default HubEmptyState;

HubEmptyState.propTypes = {
  glyphIcon: PropTypes.string.isRequired,
  glyphSize: PropTypes.string.isRequired,
  glyphBackgroundColour: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  customClassName: PropTypes.string,
  customGlyphClassName: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string
    })
  ),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      showButton: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
      link: PropTypes.string,
      action: PropTypes.func
    })
  )
};
