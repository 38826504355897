import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { PageWrapper } from '../../../components';
import PageHeading from '../../../components/PageHeading/PageHeading';
import SystemNotificationSettings from '../../../components/SystemNotification/SystemNotificationSettings';
import SystemNotification from '../../../components/SystemNotification/SystemNotification';

import updateSystemNotification from '../../../redux/actions/systemNotification';
import { featureIsEnabled } from '../../../globals/envSettings';

function SystemNotificationPage({
  systemNotification,
  updateSystemNotificationAction,
  localizedContent: { systemNotification: content }
}) {
  return (
    <PageWrapper>
      <Helmet title={content.title} />
      <div className="col">
        <PageHeading className="pad-bot4" title={content.title} />
        {featureIsEnabled('granular-platform-system-notifications') ? (
          <SystemNotificationSettings
            systemNotification={systemNotification}
            updateSystemNotificationAction={updateSystemNotificationAction}
            content={content}
          />
        ) : (
          <SystemNotification
            systemNotification={systemNotification}
            updateSystemNotificationAction={updateSystemNotificationAction}
            content={content}
          />
        )}
      </div>
    </PageWrapper>
  );
}

SystemNotificationPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  systemNotification: PropTypes.array.isRequired,
  updateSystemNotificationAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('systemNotification'),
  connect(
    state => ({
      systemNotification: state.app.systemNotification
    }),
    {
      updateSystemNotificationAction: updateSystemNotification
    }
  )
)(SystemNotificationPage);
