import React from 'react';
import PropTypes from 'prop-types';
import styles from './PopoutPanelListHeading.scss';

function PopoutPanelListHeading({ text }) {
  return (
    <div className={styles.popoutPanelListHeading}>
      <h3>{text}</h3>
    </div>
  );
}

PopoutPanelListHeading.propTypes = {
  /** Heading text */
  text: PropTypes.string.isRequired
};

export default PopoutPanelListHeading;
