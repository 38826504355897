import * as t from '../actionTypes';
import { StatusEnum } from '../sagas/appFunctions/authorisedFunctions/csvProductStructureUpload/csvProductStructureUpload';

const initialState = {
  status: '',
  fileStatus: '',
  results: [],
  isFormValid: false
};

function csvProductStructureUploadServices(state = initialState, { type, payload }) {
  switch (type) {
    case t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_INIT:
      return {
        ...initialState
      };
    case t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_START_UPLOAD:
      return {
        ...state,
        status: StatusEnum.InProgress
      };
    case t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_SUCCESS:
      return {
        ...state,
        status: payload.status
      };
    case t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_FAILURE:
      return {
        ...state,
        status: payload.status
      };
    case t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_PARSE_FILE_REQUEST:
      return {
        ...state,
        fileStatus: payload.status,
        results: ''
      };
    case t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_PARSE_FILE_COMPLETED:
      return {
        ...state,
        fileStatus: payload.status,
        results: payload.fileData
      };
    case t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_PARSE_FILE_FAILURE:
      return {
        ...state,
        fileStatus: payload.status,
        results: '',
        isFormValid: false
      };
    default:
      return state;
  }
}

export default csvProductStructureUploadServices;
