import * as t from '../actionTypes';

export const requestStatus = {
  SUCCESS: 'success',
  ERROR: 'error'
};

const requestStatusOptions = { isLoading: true };
const successStatusOptions = { isLoading: false, status: requestStatus.SUCCESS };
const failureStatusOptions = { isLoading: false, status: requestStatus.ERROR };

export const initialState = {
  productStructure: []
};

export default function productStructure(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_PRODUCT_STRUCTURE_REQUEST:
      return {
        ...state,
        ...requestStatusOptions
      };
    case t.GET_PRODUCT_STRUCTURE_SUCCESS:
      return {
        ...state,
        productStructure: payload.productStructure,
        ...successStatusOptions
      };
    case t.GET_PRODUCT_STRUCTURE_ERROR: {
      return { ...state, ...failureStatusOptions };
    }
    case t.PRODUCT_STRUCTURE_RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
