import * as t from '../../actionTypes.js';

const initialState = {
  loading: false,
  error: null,
  callback: null
};

export default function invalidateContentSelectorGroup(state = initialState, action) {
  switch (action.type) {
    case t.NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_REQUEST:
      return { ...state, loading: true, error: null, callback: action.payload.callback };
    case t.NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        callback: null
      };
    case t.NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_FAILURE:
      return { ...state, loading: false, error: action.payload, callback: null };
    default:
      return state;
  }
}
