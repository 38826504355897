import { isOrbInUnityFrame } from './platform';

function getOrbReportingBase() {
  if (isOrbInUnityFrame()) {
    return `/orb/teacher/reporting`;
  }
  return `/teacherHome/reporting`;
}

export function getOrbSchoolReportLink(orgId) {
  return `${getOrbReportingBase()}/${orgId}/school`;
}

export function getOrbClassReportLink(orgId, groupId) {
  return `${getOrbReportingBase()}/${orgId}/classroom/${groupId}`;
}

export function getOrbStudentReportLink(orgId, groupId, userId) {
  return `${getOrbReportingBase()}/${orgId}/classroom/${groupId}/student/${userId}`;
}
