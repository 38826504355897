import PropTypes from 'prop-types';
import React from 'react';
import { SVGIcon } from '..';
import { GLYPHS } from '../Button/Button';
import styles from './DataRefresher.scss';

function DataRefresherButton({ onClick, label }) {
  return (
    <button aria-label="" className={styles.refreshButton} id="dataRefresh" onClick={onClick} type="button">
      <div className={styles.refreshIcon}>
        <SVGIcon glyph={GLYPHS.ICON_REFRESH_CIRCLE} />
      </div>
      <div className={styles.refreshText}>{label}</div>
    </button>
  );
}

DataRefresherButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string
};

export default DataRefresherButton;
