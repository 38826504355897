import React from 'react';

import FormController from '../../CreateOrganisation/formUtil/FormController.js';

import ExampleTabInputting from './ExampleTabInputting.js';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import ExampleTabConfirmation from './ExampleTabConfirmation.js';

import cmsContent from '../../../utils/cmsContent.js';

function ExampleTabForm() {
  const CMS = cmsContent.supportPage || {};
  return (
    <FormController
      formName="contactUs"
      initialFormState="INPUTTING"
      formStateMapping={{
        INPUTTING: <ExampleTabInputting />,
        SUBMITTING: <PopoutPanelIconHeading type={types.LOADING} title={CMS.contact_us_form_submitting_text} />,
        CONFIRMATION: <ExampleTabConfirmation />
      }}
    />
  );
}

export default ExampleTabForm;
