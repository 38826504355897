import PropTypes from 'prop-types';
import React from 'react';
import TextInput from '../TextInput/TextInput';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import styles from '../../routes/MyProfile/MyProfile.scss';

function InitialConsentFormBody({
  localizedContent: content,
  firstName = '',
  lastName = '',
  formInputErrors,
  onChange,
  onBlur
}) {
  const handlers = { onChange, onBlur };

  const _getNameErrors = (name, isFirstName) => {
    if (name.length === 0) {
      return isFirstName
        ? content.my_profile_initial_consent_firstname_error
        : content.my_profile_initial_consent_lastname_error;
    }
    return content.name_invalid_error;
  };

  return (
    <div className={styles.consentContainerInputsContainer}>
      <div className={styles.consentContainerHeader}>{content.my_profile_initial_consent_name_header}</div>
      <div className={styles.consentContainerHeaderDescription}>
        {content.my_profile_initial_consent_name_subheader}
      </div>
      <ValidationStatus
        forId="firstName"
        isActive={formInputErrors.firstName}
        message={_getNameErrors(firstName, true)}
      >
        <TextInput
          {...handlers}
          id="firstName"
          name="firstName"
          label={content.firstname_label}
          placeholder={content.my_profile_initial_consent_firstname_placeholder}
          value={firstName}
        />
      </ValidationStatus>

      <ValidationStatus forId="lastName" isActive={formInputErrors.lastName} message={_getNameErrors(lastName, false)}>
        <TextInput
          {...handlers}
          id="lastName"
          name="lastName"
          label={content.my_profile_initial_consent_lastname_header}
          placeholder={content.my_profile_initial_consent_lastname_placeholder}
          value={lastName}
        />
      </ValidationStatus>
    </div>
  );
}

InitialConsentFormBody.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  formInputErrors: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export default InitialConsentFormBody;
