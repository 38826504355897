import * as t from '../actionTypes';

export const setContent = contents => ({
  type: t.SET_CONTENT,
  payload: contents
});

export const setCurrentContent = currentActivityId => ({
  type: t.SET_CURRENT_CONTENT,
  payload: currentActivityId
});

export const openMobileMenuModal = () => ({
  type: t.OPEN_MOBILE_MENU_MODAL
});

export const closeMobileMenuModal = () => ({
  type: t.CLOSE_MOBILE_MENU_MODAL
});

export const toggleContentsSubmenuModal = () => ({
  type: t.TOGGLE_CONTENTS_SUBMENU_MODAL
});

export const openContentsSubmenuModal = () => ({
  type: t.OPEN_CONTENTS_SUBMENU_MODAL
});

export const toggleResourcesSubmenuModal = () => ({
  type: t.TOGGLE_RESOURCES_SUBMENU_MODAL
});

export const togglePrintViewModal = () => ({
  type: t.TOGGLE_PRINT_VIEW_MODAL
});

export const toggleDictionaryModal = () => ({
  type: t.TOGGLE_DICTIONARY_MODAL
});

export const clearCurrentContent = () => ({
  type: t.CLEAR_CURRENT_CONTENT
});

export const setTitles = data => ({
  type: t.SET_CURRENT_TITLES,
  payload: data
});

export const loadCptContentRequest = data => ({
  type: t.GET_CPT_CONTENT_REQUEST,
  payload: data
});

export const loadMockCptContentRequest = data => ({
  type: t.GET_MOCK_CPT_CONTENT_REQUEST,
  payload: data
});

export const loadCptContentSuccess = data => ({
  type: t.GET_CPT_CONTENT_SUCCESS,
  payload: data
});

export const loadCptContentFailure = data => ({
  type: t.GET_CPT_CONTENT_FAILURE,
  payload: data
});

export const loadDictionaryDataRequest = data => ({
  type: t.GET_DICTIONARY_DATA_REQUEST,
  payload: data
});

export const loadDictionaryDataSuccess = data => ({
  type: t.GET_DICTIONARY_DATA_SUCCESS,
  payload: data
});

export const loadDictionaryDataFailure = data => ({
  type: t.GET_DICTIONARY_DATA_FAILURE,
  payload: data
});

export const loadPlayerLaunchRequest = data => ({
  type: t.GET_PLAYER_LAUNCH_REQUEST,
  payload: data
});

export const loadPlayerLaunchSuccess = data => ({
  type: t.GET_PLAYER_LAUNCH_SUCCESS,
  payload: data
});

export const loadPlayerLaunchFailure = data => ({
  type: t.GET_PLAYER_LAUNCH_FAILURE,
  payload: data
});

export const setModalCloseLink = link => ({
  type: t.SET_MODAL_CLOSE_LINK,
  payload: link
});

export const setModalBasePath = path => ({
  type: t.SET_BASE_PATH,
  payload: path
});

export const openStructuredContentPlayerModal = () => ({
  type: t.OPEN_STRUCTURED_CONTENT_PLAYER_MODAL
});

export const closeStructuredContentPlayerModal = () => ({
  type: t.CLOSE_STRUCTURED_CONTENT_PLAYER_MODAL
});

export const setMenuOrientation = menuOrientation => ({
  type: t.SET_MENU_ORIENTATION,
  payload: menuOrientation
});

export const setModalContentId = contentId => ({
  type: t.SET_MODAL_CONTENT_ID,
  payload: contentId
});

export const getXApiLaunchTokenRequest = data => ({
  type: t.GET_XAPI_LAUNCH_TOKEN_REQUEST,
  payload: data
});

export const getXApiLaunchTokenSuccess = data => ({
  type: t.GET_XAPI_LAUNCH_TOKEN_SUCCESS,
  payload: data
});

export const getXApiLaunchTokenFailure = data => ({
  type: t.GET_XAPI_LAUNCH_TOKEN_FAILURE,
  payload: data
});

export const toggleMobileGamesErrorPopup = () => ({
  type: t.TOGGLE_MOBILE_GAMES_POPUP
});

export const setPreviewRole = role => ({
  type: t.SET_PREVIEW_ROLE,
  payload: role
});

export const setProductVariant = () => ({
  type: t.SET_PRODUCT_VARIANT
});

export const progressBarNavigationCheck = url => ({
  type: t.PROGRESS_BAR_NAVIGATION_CHECK,
  payload: url
});

export const progressBarNavigationResetToDefault = () => ({
  type: t.PROGRESS_BAR_NAVIGATION_RESET_TO_DEFAULT
});

export const progressBarNavigationEnable = () => ({
  type: t.PROGRESS_BAR_NAVIGATION_ENABLE
});

export const progressBarNavigationDisable = () => ({
  type: t.PROGRESS_BAR_NAVIGATION_DISABLE
});

export const toggleSwitchBook = () => ({
  type: t.TOGGLE_SWITCH_BOOK
});

export const toggleResourceModal = () => ({
  type: t.TOGGLE_RESOURCE_MODAL
});

export const setResourceModalData = data => ({
  type: t.SET_RESOURCE_MODAL_DATA,
  payload: data
});
