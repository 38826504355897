import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PLATFORMS } from '@oup/shared-node-browser/constants';
import Button from '@oup/shared-front-end/src/components/Button';
import { Link as RouterLink } from 'react-router-dom';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import isExternalLink from '@oup/shared-front-end/src/utils/isExternalLink';
import ConnectedField from '../CreateOrganisation/formUtil/ConnectedField.js';
import FormValidity from '../CreateOrganisation/formUtil/FormValidity.js';
import { setFormState, setFieldValidity } from '../../redux/reducers/data/forms.reducer.js';
import { setFieldValue } from '../../redux/reducers/data/fields.reducer.js';

import Validation from '../../components/Validation/Validation.js';
import TextInput from '../../components/TextInput/TextInput.js';
import PageHeading from '../../components/PageHeading/PageHeading.js';
import colors from '../../globals/colors.js';
import Card from '../../components/Card/Card.js';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import appSettings from '../../globals/appSettings.js';
import { storeTemporaryCredentials } from '../../utils/credentials.js';

import cmsContent from '../../utils/cmsContent.js';
import {
  getPlatformDashboardUrl,
  useInternalAuthProcess,
  getIntegrationPlatformKey,
  getPlatformOaldLoginUrl
} from '../../globals/envSettings';
import { isIosWebview, isAndroidWebview } from '../../utils/device';
import { isOlbOdsMode } from '../../utils/platform';
import styles from '../Register/RegisterPage.scss';

class RequestPasswordResetInputting extends Component {
  componentWillMount() {
    const { email, providerId, cognitoId, setInitialValue } = this.props;

    if (email) {
      setInitialValue('userName', email);
    }
    if (providerId) {
      setInitialValue('platform', providerId);
    }
    if (cognitoId) {
      setInitialValue('cognitoId', cognitoId);
    }
  }

  static setCognitoId = cognitoId => {
    storeTemporaryCredentials(cognitoId);
  };

  handleSignIn = async () => {
    const { providerId } = this.props;

    if (
      (isIosWebview() || isAndroidWebview() || isOlbOdsMode()) &&
      window &&
      window.olbOfflineClient &&
      typeof window.olbOfflineClient.reloadSignInWebpage === 'function'
    ) {
      window.olbOfflineClient.reloadSignInWebpage();
      return;
    }

    // redirect to dashboard workaround https://oupagile.atlassian.net/browse/EPS-6767
    if (providerId) {
      const platformKey = getIntegrationPlatformKey(providerId);
      if (useInternalAuthProcess(platformKey)) {
        let url = '';
        if (PLATFORMS.OALD === providerId) {
          url = getPlatformOaldLoginUrl(platformKey);
        } else {
          url = getPlatformDashboardUrl(platformKey);
        }
        window.location.href = url;
        return;
      }

      // Native apps
      if (
        [
          PLATFORMS.OALD_ANDROID,
          PLATFORMS.OALD_IOS,
          PLATFORMS.DIZIO_ANDROID,
          PLATFORMS.DIZIO_IOS,
          PLATFORMS.OLD_ANDROID,
          PLATFORMS.OLD_IOS
        ].includes(providerId)
      ) {
        window.history.back();
        return;
      }
    }
    const url = await getIdpLoginUrl(null, { withReturnTo: false, platform: providerId });
    window.location.href = url;
  };

  render() {
    const { submitFormAction, helpAndSupportUrl, cognitoId } = this.props;
    const CMS = cmsContent.registerPasswordReset || {};
    const emailRegExp = appSettings.EMAIL_REGEX;
    RequestPasswordResetInputting.setCognitoId(cognitoId);

    return (
      <div className="pad-top8 pad-bot8">
        <div className="grid">
          <div className="row">
            <div className="col">
              <PageHeading title={CMS.forgotten_username_password_title} />
            </div>

            <div className="col md8">
              <div className="row">
                <div className="col pad-top2">
                  <Card headingText={CMS.pageHeading} headingColor={colors.REGISTER}>
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        submitFormAction();
                      }}
                    >
                      <ConnectedField
                        formName="requestPasswordReset"
                        fieldName="userName"
                        positiveValidationFunction={value => emailRegExp.exec(value)}
                        negativeValidationFunction={value => !emailRegExp.exec(value)}
                        getValidationMessage={value => {
                          if (!emailRegExp.exec(value)) {
                            return CMS.please_enter_valid_username;
                          }
                          return null;
                        }}
                        render={(id, value, onChange, onBlur, message, isValid, isError) => (
                          <Validation forId={id} isValid={isValid} isError={isError} message={message}>
                            <TextInput
                              id={id}
                              label={CMS.enter_username_label_text}
                              placeholder={CMS.enter_username_placeholder_text}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </Validation>
                        )}
                      />

                      <div className={styles.usernameHelperContainer}>
                        <p className="pad-top2">{CMS.username_entry_helper_text}</p>
                        <p className="pad-top2 pad-bot2">
                          <span>{CMS.forgotten_your_email_address}</span> {CMS.username_additional_helper_text_1}{' '}
                          <TextLink
                            component={isExternalLink(helpAndSupportUrl) ? false : RouterLink}
                            to={helpAndSupportUrl}
                            target="_blank"
                          >
                            {CMS.customer_support_text}
                          </TextLink>{' '}
                          {CMS.username_additional_helper_text_2}
                        </p>
                        <p className="pad-bot2">
                          <span>{CMS.do_not_have_an_email_address}</span> {CMS.username_additional_helper_text_3}
                        </p>
                      </div>

                      <div className={`pad-bot2 ${styles.displayInline}`}>
                        <FormValidity
                          formName="requestPasswordReset"
                          requiredFields={['userName']}
                          render={valid => (
                            <Button
                              variant="filled"
                              text={CMS.button_continue_text}
                              onClick={submitFormAction}
                              disabled={!valid}
                            />
                          )}
                        />
                        <Button onClick={this.handleSignIn} text={CMS.cancel_link} variant="outline" />
                      </div>
                    </form>
                  </Card>
                </div>
              </div>
            </div>

            <div className="col md4">
              <div className="row">
                <div className="col pad-top2">
                  <Card headingText={CMS.support_heading_text} headingColor={colors.SUPPORT_ICON}>
                    <h2 id="help-and-support-heading">{CMS.support_body_text}</h2>
                    <p>
                      {CMS.contact_text}{' '}
                      <TextLink
                        component={isExternalLink(helpAndSupportUrl) ? false : RouterLink}
                        to={helpAndSupportUrl}
                        target="_blank"
                      >
                        {CMS.customer_support_text}
                      </TextLink>
                    </p>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RequestPasswordResetInputting.propTypes = {
  submitFormAction: PropTypes.func.isRequired,
  setInitialValue: PropTypes.func.isRequired,
  email: PropTypes.string,
  providerId: PropTypes.string,
  cognitoId: PropTypes.string,
  helpAndSupportUrl: PropTypes.string.isRequired
};

export default connect(null, dispatch => ({
  submitFormAction: () => dispatch(setFormState('requestPasswordReset', 'SUBMITTING')),
  setInitialValue: (fieldName, value) => {
    dispatch(setFieldValue('requestPasswordReset', fieldName, value, null, true, false));
    dispatch(setFieldValidity('requestPasswordReset', fieldName, true, false, true));
  }
}))(RequestPasswordResetInputting);
