import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button, { buttonTypes } from '../Button/Button';
import PopoutPanelIconHeading, {
  types as PopoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import styles from './ErrorStatus.scss';
import { featureIsEnabled } from '../../globals/envSettings';

export const ErrorStatusIconTypes = { ...PopoutPanelIconHeadingTypes };

function ErrorStatus({
  type = ErrorStatusIconTypes.WARNING_ERROR,
  title,
  subtitle,
  appErrorReference,
  buttonText,
  isPasswordFailure,
  buttonFullWidth = false,
  buttonOnClickHandler
}) {
  return (
    <div className={styles.errorContainer}>
      <div>
        <PopoutPanelIconHeading type={type} title={title} subtitle={subtitle} />
        {appErrorReference && (
          <p className={classnames('row cols-center gin-top2', styles.almostInvisible)}>
            REF: {appErrorReference.includes('----') ? appErrorReference.split('----')[0] : appErrorReference}
          </p>
        )}
      </div>
      {!featureIsEnabled('lock-account') && (
        <div className={styles.errorFooter}>
          <Button
            id="appFailedReload"
            type={isPasswordFailure ? buttonTypes.ACTION_NO_ICON : buttonTypes.PRIMARY}
            fullWidth={buttonFullWidth}
            text={buttonText}
            onClick={() => buttonOnClickHandler()}
          />
        </div>
      )}
      {appErrorReference && (
        <p className={classnames('row', featureIsEnabled('show-app-failed-errors') ? null : styles.almostInvisible)}>
          {appErrorReference.includes('----') ? appErrorReference.split('----')[1] : 'testasas'}
        </p>
      )}
    </div>
  );
}

ErrorStatus.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  appErrorReference: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonFullWidth: PropTypes.bool,
  buttonOnClickHandler: PropTypes.func.isRequired,
  isPasswordFailure: PropTypes.bool
};

export default ErrorStatus;
