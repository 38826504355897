import { put } from 'redux-saga/effects';
import { teacherResourcesSuccess, teacherResourcesFailure } from '../../../../actions/orbTeacherResources';
import getTeacherResourcesApiCall from '../../../apiCalls/orb/getTeacherResources';

function* getTeacherResources(orgId, locationId, isbns) {
  const response = yield getTeacherResourcesApiCall(orgId, locationId, isbns);

  if (response.status === 'success') {
    yield put(teacherResourcesSuccess(response.data));
    return;
  }

  yield put(teacherResourcesFailure(response.message));
}

export default getTeacherResources;
