import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './AssessmentEditPencil.scss';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';

function AssessmentEditPencil({ active, className }) {
  const activeClassName = active && styles.active;
  return (
    <div className={classnames(activeClassName, styles.imageContainer, className)}>
      <SVGIcon glyph={GLYPHS.ICON_ASSESSMENT} />
    </div>
  );
}

export default AssessmentEditPencil;

AssessmentEditPencil.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.any
};
