import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import contactUsSchema from '../../../../../../static/schema/draft-4/contact-us-submission-OLB-request-body.json';
import submit from './submit';

// eslint-disable-next-line func-names
export default function*() {
  yield takeLatest(t.CONTACT_US_REQUEST, ({ payload }) => submit(payload));
  yield takeLatest(t.CONTACT_US_VALIDATE_INPUT, ({ payload }) => {
    const input = {
      ...payload
    };
    if (payload.email) input.ces10 = payload.email;

    return validateInputUsingSchema(contactUsSchema, t.CONTACT_US_VALIDATE_COMPLETED, input);
  });
}
