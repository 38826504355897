import { takeEvery } from 'redux-saga/effects';

import loginManagedUserSchema from '../../../../../../static/schema/draft-4/loginManagedUser.request.schema.json';
import * as t from '../../../../actionTypes';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';

function* root() {
  yield takeEvery(t.VALIDATE_MANAGED_USER_LOGIN_INPUT, ({ payload: input }) =>
    validateInputUsingSchema(loginManagedUserSchema, t.VALIDATE_MANAGED_USER_LOGIN_COMPLETED, input)
  );
}

export default root;
