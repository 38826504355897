import { select } from 'redux-saga/effects';
import generateReportApi from './generateReportApi';

function formatDateToLocalISOString(date, type) {
  if (!date) return null;

  const formattedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  switch (type) {
    case 'startDate':
      formattedDate.setUTCHours(0, 0, 0, 0);
      return formattedDate.toISOString().slice(0, -1);
    case 'endDate':
      formattedDate.setUTCHours(23, 59, 59, 999);
      return formattedDate.toISOString().slice(0, -1);
    default:
      return null;
  }
}

export default function* schoolReport() {
  console.log(`[generateReport Saga] Submitting request to get report.`);

  const { reportType, options } = yield select(state => {
    state.schoolReport.formData.options = {
      ...state.schoolReport.formData.options,
      startDate: formatDateToLocalISOString(state?.schoolReport?.formData?.options?.startDate, 'startDate'),
      endDate: formatDateToLocalISOString(state?.schoolReport?.formData?.options?.endDate, 'endDate')
    };

    return {
      reportType: state.schoolReport.reportType,
      options: state.schoolReport.formData
    };
  });

  return yield generateReportApi(reportType, options);
}
