class LocalStorageWithCache {
  constructor() {
    this.cache = [];
  }

  getKey = key => {
    const { cache } = this;

    if (cache[key] && cache[key] !== '') return cache[key];

    cache[key] = localStorage.getItem(key);
    return cache[key];
  };

  setKey = (key, value) => {
    const { cache } = this;

    cache[key] = value;
    localStorage.setItem(key, value);
  };

  removeKey = key => {
    const { cache } = this;

    delete cache[key];
    localStorage.removeItem(key);
  };
}

const localStorageWithCache = new LocalStorageWithCache();
export default localStorageWithCache;
