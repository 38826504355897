import React from 'react';
import PropTypes from 'prop-types';
import { SortDirection as SortDirectionPropType } from '../../types';
import GradebookTable from './GradebookTable';
import SortLabel from './SortLabel';

function SortHeading({ children, sortKey, onClick, direction = 'none', ...otherProps }) {
  return (
    <GradebookTable.Header key={sortKey} {...otherProps}>
      <SortLabel group="class-progress" name={sortKey} direction={direction} onClick={onClick}>
        {children}
      </SortLabel>
    </GradebookTable.Header>
  );
}
SortHeading.propTypes = {
  children: PropTypes.node,
  sortKey: PropTypes.any,
  onClick: PropTypes.func,
  direction: SortDirectionPropType,
  shaded: PropTypes.bool
};

export default SortHeading;
