import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classnames from 'classnames';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { GLYPHS, buttonTypes } from '../Button/Button';
import breakpoints from '../../globals/breakpoints';
import ControlledForm from '../ControlledForm/ControlledForm';
import HubFilterOptions from '../HubFilterOptions/HubFilterOptions';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import Label from '../Label/Label';
import styles from './HubFilterBar.scss';

function HubFilterBar({
  idPrefix,
  filterOptions,
  sortOptions,
  sortOnChange,
  sortValue,
  ariaControls,
  filterButtonText,
  overlayLabelText,
  breakpoint,
  hasSortButton = true,
  sortLabel = '',
  localizedContent: { hubGlossary: hubContent },
  customClassNameContainer,
  withSearchBar = false,
  searchTerm,
  searchTermOnChange,
  searchPlaceholder
}) {
  const commonSearchOptionsProps = {
    ariaControls,
    showLegend: false,
    overlayHangRight: false,
    overlayCustomClass: styles.filterDropdownOverlay,
    buttonCustomClassName: styles.filterBarBtn
  };

  const [_sortValue, setSortValue] = useState(sortValue);

  const [ascKey, descKey] = [':asc', ':desc'];
  const reverseSortOrder = sort => {
    if (sort) {
      let reversedSort;
      if (sort.includes(ascKey)) reversedSort = sort.replace(ascKey, descKey);
      else reversedSort = sort.replace(descKey, ascKey);
      sortOnChange([reversedSort]);
      setSortValue(reversedSort);
    }
  };

  const getSelectedSortOption = sortOptionsParam => {
    const [sortSelected] = sortOptionsParam.filter(o => o.checked);
    return sortSelected?.text;
  };

  return (
    <div className="row">
      <div className={styles.filtersContainer}>
        <ControlledForm
          className={
            customClassNameContainer
              ? classnames(styles.filterBarControls, customClassNameContainer)
              : styles.filterBarControls
          }
        >
          <div className="grid">
            <div className="row">
              <div className={styles.controls}>
                {filterOptions ? (
                  <>
                    <Label
                      text={hubContent.filter_list_heading_wo_colon}
                      className={styles.filterBarLabel}
                      htmlFor={`${idPrefix}-filterButton`}
                    />

                    <HubFilterOptions
                      {...commonSearchOptionsProps}
                      buttonId={`${idPrefix}-filterButton`}
                      buttonText={filterButtonText}
                      inputType="checkbox"
                      options={filterOptions}
                      showLegend={breakpoint === breakpoints.XXS}
                      a11yHeading={overlayLabelText}
                    />
                  </>
                ) : null}
                {sortOptions ? (
                  <div className={styles.sortOptionsContainer}>
                    {sortLabel !== null && (
                      <Label
                        text={sortLabel || hubContent.sort_list_heading_wo_colon}
                        className={styles.filterBarLabel}
                        htmlFor={`${idPrefix}-sortButton`}
                      />
                    )}
                    <HubFilterOptions
                      {...commonSearchOptionsProps}
                      buttonId={`${idPrefix}-sortButton`}
                      buttonText={getSelectedSortOption(sortOptions) || hubContent.sort_button}
                      inputType="radio"
                      onChange={sortOnChange}
                      options={sortOptions}
                      a11yHeading={hubContent.sort_list_heading_wo_colon}
                    />
                    {hasSortButton && (
                      <Button
                        type={buttonTypes.ROUNDED}
                        text={hubContent.sort_button_reverse_text}
                        title={hubContent.sort_button_reverse_text}
                        iconOnly
                        glyph={GLYPHS.ICON_SORT}
                        customClassName={classnames(styles.reverseSortBtn, {
                          [styles.reversed]: _sortValue.includes(descKey)
                        })}
                        onClick={() => reverseSortOrder(sortValue)}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </ControlledForm>
        {withSearchBar ? (
          <div className={styles.searchContainer}>
            <TextInputWithButton
              id="selectionSearchTerm"
              placeholder={searchPlaceholder}
              onChange={searchString => searchTermOnChange(searchString)}
              value={searchTerm}
              buttonText="Search"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

HubFilterBar.propTypes = {
  idPrefix: PropTypes.string,

  // Filters
  filterOptions: PropTypes.array,
  sortOptions: PropTypes.array,
  sortOnChange: PropTypes.func,
  sortValue: PropTypes.string,
  hasSortButton: PropTypes.bool,
  sortLabel: PropTypes.string,
  // the id(s) of the controlled elements
  ariaControls: PropTypes.string,
  filterButtonText: PropTypes.string,
  sortButtonText: PropTypes.string,
  overlayLabelText: PropTypes.string,
  breakpoint: PropTypes.string,
  searchTermOnChange: PropTypes.func,
  customClassNameContainer: PropTypes.string,
  localizedContent: PropTypes.object,
  withSearchBar: PropTypes.bool,
  searchTerm: PropTypes.string,
  searchPlaceholder: PropTypes.string
};

export default withLocalizedContent('hubGlossary')(HubFilterBar);
