import { put, takeLatest } from 'redux-saga/effects';

import * as t from '../../../../actionTypes';
import actions from '../../../../actions';

import getSchoolDialReportApi from '../../../api/getSchoolDialReport';
import { featureIsEnabled } from '../../../../../globals/envSettings';

export function* getSchoolDialReport(orgId, locationId) {
  const response = yield getSchoolDialReportApi(orgId, locationId);
  if (response.status === 'success') {
    if (featureIsEnabled('mat-report-change-for-reducers')) {
      yield put(actions.gradebookSchoolDialReportSuccess(response.data, orgId));
    } else {
      yield put(actions.gradebookSchoolDialReportSuccessDeprecated(response.data));
    }
    return;
  }
  if (featureIsEnabled('mat-report-change-for-reducers')) {
    yield put(actions.gradebookSchoolDialReportFailure(orgId, response.message));
  } else {
    yield put(actions.gradebookSchoolDialReportFailureDeprecated(response.message));
  }
}

export default function* root() {
  yield takeLatest(t.GRADEBOOK_SCHOOL_DIAL_REPORT_REQUEST, ({ payload: { orgId, locationId } }) =>
    getSchoolDialReport(orgId, locationId)
  );
}
