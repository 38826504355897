import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PopoutPanel from '../../components/PopoutPanel/PopoutPanel.js';
import { showClosePanelModal } from '../../redux/reducers/joinClass.reducer.js';
import JoinClass from './JoinClass';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './JoinClass.scss';

class JoinClassPage extends Component {
  componentDidMount() {}

  render() {
    const {
      showModalAction,
      showConfirmModal,
      localizedContent: { joinClass: content }
    } = this.props;

    return (
      <div className={styles.joinClassPage}>
        <Helmet title={content.join_class_title} />
        <PopoutPanel id="joinClass" ariaLabel="JoinClass" isOpen={showConfirmModal}>
          <JoinClass closePanel={() => showModalAction(false)} />
        </PopoutPanel>
      </div>
    );
  }
}

JoinClassPage.propTypes = {
  showModalAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.bool
};

export default compose(
  withLocalizedContent('joinClass'),
  connect(
    state => ({
      showConfirmModal: state.joinClass.showModal
    }),
    {
      showModalAction: showClosePanelModal
    }
  )
)(JoinClassPage);
