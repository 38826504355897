import authSettings from '../../../../../../../globals/authSettings';
import initAuth0 from '../../../../../../../globals/oidcSettings';
import session from '../../../../../../../utils/session';

import { completeLogout } from '../../../../../appFunctions/authorisedFunctions/auth/handleLogout.saga';

// Use this to start only 1 timeout, resets only on success
let UPDATE_SESSION_TIMEOUT = null;

export async function updateSession(cacheMode = 'off', options = {}) {
  const masqueradeUserId = localStorage.getItem('masquerade-user-id');
  const impersonationToken = localStorage.getItem('impersonation-token');
  const ltiUser = localStorage.getItem('lti-token');

  if (masqueradeUserId || ltiUser || impersonationToken) {
    return;
  }

  const auth0 = await initAuth0(false, options || {});

  if (!(await auth0.isAuthenticated())) {
    session.clear();
    return;
  }

  try {
    const token = await auth0.getTokenSilently({ cacheMode });
    const claims = await auth0.getIdTokenClaims();
    const expiresIn = claims.exp - claims.iat;
    const expiresTime = Date.now() + expiresIn * 1000;

    const sessionInfo = session.get();

    // Legacy properties
    sessionInfo.oidcIdToken = claims.__raw;
    sessionInfo.oidcAccessToken = token;
    sessionInfo.oidcExpiresIn = expiresIn;
    sessionInfo.oidcSessionClock = expiresTime;
    // sessionInfo.oidcRefreshToken = null;

    // New properties
    sessionInfo.oidc.idToken = claims.__raw;
    sessionInfo.oidc.accessToken = token;
    sessionInfo.oidc.expiresIn = expiresIn;
    sessionInfo.oidc.sessionClock = expiresTime;
    // sessionInfo.oidc.refreshToken = null;

    session.set(sessionInfo);

    clearTimeout(UPDATE_SESSION_TIMEOUT);
    UPDATE_SESSION_TIMEOUT = null;
  } catch (error) {
    const genLogout = completeLogout();
    await genLogout.next();
  }
}

/**
 * Tries to update the session.
 * If it fails, it won't create a new timeout.
 */
function keepSessionAlive() {
  if (!UPDATE_SESSION_TIMEOUT) {
    const sessionExpireMs = session.get().oidc.sessionClock - Date.now() - authSettings.oidcIdleSessionPreCallValue;
    UPDATE_SESSION_TIMEOUT = setTimeout(async () => {
      await updateSession();
      keepSessionAlive();
    }, sessionExpireMs);
  }
}

/**
 * On page load, try to update the session if it's expired.
 * Start `keepSessionAlive` to try and keep the session alive.
 */
export async function startAuth0Interval() {
  // On page load, update session
  const sessionExpireMs = session.get().oidc.sessionClock - Date.now() - authSettings.oidcIdleSessionPreCallValue;
  if (sessionExpireMs <= 0) {
    await updateSession();
  }

  keepSessionAlive();
}

/**
 * Process Auth0 response.
 */
export default function* processAuthUniversalAuth0(handleRedirect = true, options = {}) {
  if (handleRedirect) {
    const auth0 = yield initAuth0(false, options || {});

    // Throws error if can't login with sucess.
    yield auth0.handleRedirectCallback();
  }

  yield updateSession('on', options || {});
}
