class AbstractStorage {
  data = {};

  set(key, value) {
    this.data[key] = value;
  }

  get(key) {
    return this.data[key];
  }

  exists(key) {
    return !!this.data[key];
  }

  clear() {
    this.data = {};
  }
}

export default AbstractStorage;
