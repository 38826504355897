import * as t from '../actionTypes';

export const sendOicEmailsRequest = (template = '') => ({
  type: t.SEND_OIC_EMAILS_REQUEST,
  payload: template
});

export const sendOicEmailsSuccess = response => ({
  type: t.SEND_OIC_EMAILS_SUCCESS,
  payload: response
});

export const sendOicEmailsFailure = errors => ({
  type: t.SEND_OIC_EMAILS_FAILURE,
  payload: errors
});

export const sendOicEmailsReset = () => ({
  type: t.SEND_OIC_EMAILS_RESET,
  payload: null
});
