const useIteratorPagination = () => {
  const goForward = (items, idField, limit) => {
    const last = items[items.length - 1];
    const lastId = last[idField];
    return `&sort=-1&limit=${limit}&after=${lastId}`;
  };

  const goBackward = (items, idField, limit) => {
    const first = items[0];
    const firstId = first[idField];
    return `&sort=1&limit=${limit}&after=${firstId}`;
  };

  const goToFirst = limit => `&sort=-1&limit=${limit}`;

  const goToLast = limit => `&sort=1&limit=${limit}`;

  return [goToFirst, goBackward, goForward, goToLast];
};

export default useIteratorPagination;
