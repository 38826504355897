import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import ActionList, { Action } from '../ActionList/ActionList';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { orgInviteStatusTypes } from '../../redux/reducers/resendInvitation';

function ResendInvitationOutcome({
  localizedContent: { resendInvitationOutcome: content },
  firstName,
  lastName,
  status,
  onDone
}) {
  switch (status) {
    case orgInviteStatusTypes.SUBMITTING:
      return (
        <ScrollContainer>
          <PopoutPanelIconHeading
            type={popoutPanelIconHeadingTypes.LOADING}
            title={content.resend_invitation_title_submitting}
          />
        </ScrollContainer>
      );
    case orgInviteStatusTypes.FAILURE:
      return (
        <ScrollContainer
          footerContent={
            <ActionList>
              <Action label="Done" onClick={onDone} primary />
            </ActionList>
          }
        >
          <PopoutPanelIconHeading
            type={popoutPanelIconHeadingTypes.ERROR}
            title={content.resend_invitation_title_failure}
          />
        </ScrollContainer>
      );
    case orgInviteStatusTypes.SUCCESS:
      return (
        <ScrollContainer
          footerContent={
            <ActionList>
              <Action label="Done" onClick={onDone} primary />
            </ActionList>
          }
        >
          <PopoutPanelIconHeading
            type={popoutPanelIconHeadingTypes.VALID}
            title={content.resend_invitation_title_success.replace('{name}', `${firstName} ${lastName}`)}
          />
        </ScrollContainer>
      );

    default:
      return null;
  }
}

ResendInvitationOutcome.propTypes = {
  localizedContent: PropTypes.shape({
    resendInvitationOutcome: PropTypes.object.isRequired
  }).isRequired,
  status: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired
};

export default withLocalizedContent('resendInvitationOutcome')(ResendInvitationOutcome);
