import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styles from './ResourcesPlayerSidebar.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import { GlyphIcon, EmblemSize } from '../IconEmblem/IconEmblem';
import {
  closeMobileMenuModal,
  toggleResourcesSubmenuModal,
  clearCurrentContent
} from '../../redux/actions/structuredContentPlayer';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import useMediaQuery from '../../utils/mediaQuery';
import CategoriesLevelMenu from './CategoriesLevelMenu';
import FileLevelMenu from './FileLevelMenu';
import { RESOURCES_FOLDERS, NO_CATEGORY_ITEMS_KEY } from '../../globals/cptConstants';
import { onKeyDownHandler } from '../../services/cptHelperMethods';

function ResourcesPlayerSidebar({ toggleModal, cptContent, isResourcesSidebarOpen, resources }) {
  const [title, setTitle] = useState('Resources');
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const contents = resources;

  useEffect(() => {
    if (!isResourcesSidebarOpen) {
      setSelectedFolderId('');
      setTitle('Resources');
    }
  }, [isResourcesSidebarOpen]);

  const handleToggleModal = folder => {
    setSelectedFolderId(folder.id);
  };

  const getGlyphForFolder = folderName => {
    switch (folderName) {
      case RESOURCES_FOLDERS.SONGS:
        return GlyphIcon.ICON_RESOURCES_SONGS_CPT;
      case RESOURCES_FOLDERS.VIDEOS:
        return GlyphIcon.ICON_RESOURCES_PLAY_CPT;
      case RESOURCES_FOLDERS.GAMES:
        return GlyphIcon.ICON_RESOURCES_GAMES_CPT;
      case RESOURCES_FOLDERS.VOCABULARY:
        return GlyphIcon.ICON_RESOURCES_VOCABULARY_CPT;
      case RESOURCES_FOLDERS.LO:
        return GlyphIcon.ICON_RESOURCES_LEARNING_OBJECT_CPT;
      case RESOURCES_FOLDERS.PDF:
        return GlyphIcon.ICON_RESOURCES_PDF_CPT;
      case RESOURCES_FOLDERS.PRESENTATION:
        return GlyphIcon.ICON_RESOURCES_360_PRESENTATIONS_CPT;
      default:
        return GlyphIcon.ICON_RESOURCES_CPT;
    }
  };

  const getMappedItemsFromFolder = folderId => {
    const categories = contents.categoriesWithData.filter(category => category.parentId === folderId);
    const mappedItems = {};
    if (categories.length === 0) {
      const noCategoryItems = contents.items.filter(item => item.parentId === folderId);
      mappedItems[NO_CATEGORY_ITEMS_KEY] = { items: noCategoryItems, title: null, value: true };
    }
    categories.forEach(category => {
      const items = contents.items.filter(item => item.parentId === category.id);
      mappedItems[category.id] = { items, title: category.title, value: false };
    });
    return mappedItems;
  };

  const renderFolders = () =>
    contents.foldersWithData.map((folder, folderIndex) => (
      <>
        <div
          className={styles.menuItem}
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDownHandler(() => handleToggleModal(folder))}
          onClick={() => handleToggleModal(folder)}
          key={`${folder.id}_${folderIndex}`}
        >
          <SVGIcon glyph={getGlyphForFolder(folder.icon)} className={styles.icon} />
          <p>{folder.title}</p>
        </div>
        {selectedFolderId === folder.id && (
          <CategoriesLevelMenu
            cptContent={cptContent}
            items={getMappedItemsFromFolder(folder.id)}
            glyph={getGlyphForFolder(folder.icon)}
          />
        )}
      </>
    ));

  const renderFiles = () =>
    contents.files.map(item => (
      <FileLevelMenu
        key={item.resourceId}
        fileName={item.title}
        glyph={item.type === 'media' ? GlyphIcon.ICON_RESOURCES_PLAY_CPT : GlyphIcon.ICON_FILE_CPT}
        type={item.type}
        resourceIdValue={item.resourceId}
        subtitleResourceIdValue={item.subtitleResourceId}
        itemId={item.id}
        cptContent={cptContent}
      />
    ));

  return (
    <div className={styles.container}>
      {isMobile ? (
        <LinkWithIcon
          isLhs
          id="PopoutNavFooterBackLink"
          action={toggleModal}
          text={cptContent.back_button_text}
          glyph={GLYPHS.ICON_LEFT}
          customClassName={styles.backButton}
          aria-hidden={isResourcesSidebarOpen ? 'false' : 'true'}
        />
      ) : (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDownHandler(toggleModal)}
          onClick={toggleModal}
          aria-label={cptContent.close_menu}
          className={classnames(EmblemSize.MEDIUM, styles.cpCloseButton)}
          aria-hidden={isResourcesSidebarOpen ? 'false' : 'true'}
        >
          <SVGIcon glyph={GlyphIcon.ICON_CLOSE_BUTTON_SMALL} />
        </div>
      )}
      <div className={styles.titleContainer} aria-hidden={isResourcesSidebarOpen ? 'false' : 'true'}>
        <h2>{title}</h2>
        {contents.files.length > 0 && (
          <>
            <p className={styles.sections}>Files</p>
            {renderFiles()}
          </>
        )}

        <div className={styles.foldersWrapper}>
          <p className={styles.sections}>Folders</p>
          {renderFolders()}
        </div>
      </div>
    </div>
  );
}

ResourcesPlayerSidebar.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  cptContent: PropTypes.object,
  isResourcesSidebarOpen: PropTypes.bool,
  resources: PropTypes.object
};

const mapStateToProps = state => ({
  isResourcesSidebarOpen: state.structuredContentPlayer.isResourcesSidebarOpen,
  resources: state.structuredContentPlayer.resources
});

const mapDispatchToProps = {
  toggleModal: toggleResourcesSubmenuModal,
  clearCurrentContentData: clearCurrentContent,
  closeMobileMenuModalAction: closeMobileMenuModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ResourcesPlayerSidebar);
