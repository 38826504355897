import * as t from '../actionTypes';

export const gradedReadersResultsRequest = (page, params) => ({
  type: t.GRADED_READERS_RESULTS_REQUEST,
  payload: {
    page,
    params
  }
});

export const gradedReadersResultsFailure = (error, page) => ({
  type: t.GRADED_READERS_RESULTS_FAILURE,
  payload: {
    error,
    page
  }
});

export const gradedReadersResultsSuccess = (data, page) => ({
  type: t.GRADED_READERS_RESULTS_SUCCESS,
  payload: {
    data,
    page
  }
});

export const gradedReadersResultsLoading = () => ({
  type: t.GRADED_READERS_RESULTS_LOADING
});
