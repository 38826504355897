import * as t from '../actionTypes';

export const oicLinkOrgToJanisonRequest = input => ({
  type: t.OIC_LINK_ORG_TO_JANISON_REQUEST,
  payload: input
});

export const oicLinkOrgToJanisonSuccess = input => ({
  type: t.OIC_LINK_ORG_TO_JANISON_SUCCESS,
  payload: input
});

export const oicLinkOrgToJanisonFailure = input => ({
  type: t.OIC_LINK_ORG_TO_JANISON_FAILURE,
  payload: input
});
