import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { pick } from 'lodash';
// Redux
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import { setSelectedStudents } from '../../redux/reducers/addStudentsToClassroom';
import { overwriteSelection } from '../../redux/reducers/selection';
// Utils
import withLocalizedContent from '../../language/withLocalizedContent';
import { getTestTitle } from '../../structure/HubCourseAssessments/Services/getTestData';
// Constants
import USER_ROLES from '../../globals/userRoles';
// Components
import StudentSelectionPanel from '../../panels/StudentSelectionPanel';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { CONTEXT_OPTIONS } from '../../views/UserSelectionView';
import HubEmptyState from '../HubEmptyState/HubEmptyState';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import PopoutNavFooter from '../PopoutNavFooter/PopoutNavFooter';
// Style
import colors from '../../globals/colors';

class OnlineTestStudents extends Component {
  componentDidUpdate(prevProps) {
    const {
      allStudentIds,
      initialStudentsSet,
      setInitialTestStudents,
      overwriteSelectionAction,
      setOnlineTestStudents,
      selectedStudentIds,
      setOnlineTestAllStudents,
      isOpen
    } = this.props;
    if (selectedStudentIds && selectedStudentIds.length && !initialStudentsSet && isOpen) {
      setInitialTestStudents();
      overwriteSelectionAction(selectedStudentIds);
    } else if (
      prevProps.allStudentIds &&
      !prevProps.allStudentIds.length &&
      allStudentIds.length &&
      !initialStudentsSet
    ) {
      setInitialTestStudents();
      setOnlineTestStudents(allStudentIds);
      setOnlineTestAllStudents(allStudentIds);
      overwriteSelectionAction(allStudentIds);
    }
  }

  render() {
    const {
      test,
      assessmentTitle,
      next,
      prev,
      orgId,
      localizedContent: { hubSetTestOnline: content },
      classroom,
      classroomEditingState,
      classId,
      setSelectedStudentsAction,
      setOnlineTestStudents,
      searchIsLoading,
      nrOfStudentsInTheClass
    } = this.props;

    const isEmptyState = () => !searchIsLoading && nrOfStudentsInTheClass === 0;

    return (
      <ScrollContainer
        headerContent={
          <header>
            <div>
              <h2>{content.online_test_title}</h2>
              <div>{test ? `${assessmentTitle} ${getTestTitle(test)}` : ''}</div>
            </div>
          </header>
        }
        footerContent={
          isEmptyState() && (
            <PopoutNavFooter
              backAction={data => prev(data)}
              nextAction={data => next(data)}
              nextButtonText={content.online_test_choose_students}
              nextButtonDisabled
            />
          )
        }
      >
        {(searchIsLoading || (!searchIsLoading && nrOfStudentsInTheClass !== 0)) && (
          <StudentSelectionPanel
            orgId={orgId}
            backAction={(selectedStudentIds, enableStudentLimit, studentLimit) => {
              prev();
              setSelectedStudentsAction(selectedStudentIds, enableStudentLimit, studentLimit);
              setOnlineTestStudents(selectedStudentIds);
            }}
            submitAction={(selectedStudentIds, enableStudentLimit, studentLimit) => {
              next();
              setSelectedStudentsAction(selectedStudentIds, enableStudentLimit, studentLimit);
              setOnlineTestStudents(selectedStudentIds);
            }}
            context={CONTEXT_OPTIONS.ADD_STUDENTS}
            existingClassroom={classroom}
            classroomEditingState={classroomEditingState}
            initialRoles={[USER_ROLES.LEARNER]}
            initialLimitToggleValue={false}
            classId={classId}
            isOnlineTestStudentsPanel
            initialLimitValue={100}
            testLicenceStudentsList={test && (test.licenceStudentsList ? test.licenceStudentsList : null)}
          />
        )}
        {isEmptyState() && (
          <HubEmptyState
            glyphIcon={GLYPHS.ICON_CLASS}
            glyphSize={thumbnailSizes.HEADING}
            glyphBackgroundColour={colors.TEACHER}
            title={content.list_students_panel_empty_text}
            subTitle={content.list_students_panel_empty_text_subtitle}
          />
        )}
      </ScrollContainer>
    );
  }
}

export default compose(
  withLocalizedContent('hubSetTestOnline'),
  connect(
    state => {
      const { addStudentsToClassroom, classrooms, people, onlineTest } = state;
      const searchInstance = state.search.userSelection || {};
      const classId = state.onlineTest.classId;
      const studentIds = onlineTest.selectedStudentIds;
      const students = pick(people.data, studentIds);
      const classroom = classrooms ? classrooms.data[classId] : {};

      return {
        searchIsLoading: state.search && state.search.userSelection ? searchInstance.loading : true,
        selectedStudentIds: onlineTest.selectedStudentIds,
        initialStudentsSet: onlineTest.initialStudentsSet,
        classroomEditingState: addStudentsToClassroom,
        classroom: classroom || {},
        orgId: state.identity.currentOrganisationId,
        students,
        nrOfStudentsInTheClass: classroom?.amountOfLearners
      };
    },
    {
      setSelectedStudentsAction: setSelectedStudents,
      setOnlineTestAllStudents: actions.setOnlineTestAllStudents,
      setOnlineTestStudents: actions.setOnlineTestStudents,
      setInitialTestStudents: actions.setInitialOnlineTestStudents,
      overwriteSelectionAction: overwriteSelection
    }
  )
)(OnlineTestStudents);

OnlineTestStudents.defaultProps = {
  assessmentTitle: ''
};

OnlineTestStudents.propTypes = {
  localizedContent: PropTypes.object,
  next: PropTypes.func,
  prev: PropTypes.func,
  test: PropTypes.object,
  assessmentTitle: PropTypes.string,
  orgId: PropTypes.string,
  classId: PropTypes.string,
  classroom: PropTypes.object.isRequired,
  classroomEditingState: PropTypes.object.isRequired,
  setSelectedStudentsAction: PropTypes.func.isRequired,
  initialStudentsSet: PropTypes.bool,
  allStudentIds: PropTypes.array,
  selectedStudentIds: PropTypes.array,
  setOnlineTestStudents: PropTypes.func,
  setInitialTestStudents: PropTypes.func,
  overwriteSelectionAction: PropTypes.func,
  setOnlineTestAllStudents: PropTypes.func,
  isOpen: PropTypes.bool,
  searchIsLoading: PropTypes.bool,
  nrOfStudentsInTheClass: PropTypes.number
};
