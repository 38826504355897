import { put } from 'redux-saga/effects';
import {
  createAssignmentRequestSuccess,
  getBasketAssignmentsRequest,
  createAssignmentRequestFailure
} from '../../../../../actions/ngsCourse';
import createAssignmentApiCall from '../../../../apiCalls/ngs/assignments/createAssignments';

function* createAssignment(data) {
  const response = yield createAssignmentApiCall(data);

  console.log(data);

  if (response.status === 'success') {
    yield put(createAssignmentRequestSuccess(response.data));
    yield put(
      getBasketAssignmentsRequest({
        orgId: data.orgId,
        courseId: data.courseId,
        audience: 'self',
        meta: data.meta
      })
    );

    return;
  }

  yield put(createAssignmentRequestFailure(response.message));
}

export default createAssignment;
