import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import InvitationUpdateSubmitting from './InvitationUpdateSubmitting.js';
import InvitationUpdateConfirmation from './InvitationUpdateConfirmation.js';

const getContentForState = formState => {
  switch (formState) {
    case 'SUBMITTING':
      return <InvitationUpdateSubmitting />;
    case 'CONFIRMATION':
      return <InvitationUpdateConfirmation />;
    default:
      // This only happens when someone links to /invitation page manually:
      console.log(`Error. Missing a Registration form for {formState}`);
      return <p>There is no invitation being updated at present.</p>;
  }
};

function RegisterForm({ formState }) {
  return <div>{getContentForState(formState)}</div>;
}

RegisterForm.propTypes = {
  formState: PropTypes.string.isRequired
};

export default connect(state => ({
  formState: state.invites.formState
}))(RegisterForm);
