import { isLinuxElectron } from '../utils/device.js';
import { SOURCES } from './contentPreviewConstants';

const envSettings = {
  environmentSettingsLoaded: false
};

function throwErrorIfEnvSettingsNotReady() {
  if (!envSettings.environmentSettingsLoaded) {
    throw new Error('Environment settings are not loaded yet');
  }
}

export function getEnvType() {
  return envSettings.envType || 'PROD';
}

export function isLocal() {
  // we don't need to wait for the API for this
  return window.location.hostname === 'localhost' || window.location.hostname.includes('127.0.0');
}

export function isInfra() {
  // if envSettings are not loaded then determine if dev using hostname
  return envSettings.environmentSettingsLoaded
    ? envSettings.appMode === 'DEVELOPMENT'
    : window.location.hostname.startsWith('localhost') || window.location.hostname.startsWith('infra.');
}

export function isDev() {
  // if envSettings are not loaded then determine if dev using hostname
  return envSettings.environmentSettingsLoaded
    ? envSettings.appMode === 'DEVELOPMENT'
    : window.location.hostname.startsWith('localhost') || window.location.hostname.startsWith('dev.');
}

export function isTest() {
  // if envSettings are not loaded then determine if TEST using hostname
  return envSettings.environmentSettingsLoaded
    ? envSettings.envType === 'TEST'
    : window.location.hostname.startsWith('test.');
}

export function isUat() {
  return envSettings.environmentSettingsLoaded
    ? envSettings.envType === 'UAT'
    : window.location.hostname.startsWith('uat.');
}

export function isProd() {
  return getEnvType().toLowerCase() === SOURCES.PROD.toLowerCase();
}

export function featureIsEnabled(featureName) {
  // this is intentionally almost identical to the identically named feature flag check in lambda/...
  throwErrorIfEnvSettingsNotReady();

  const featureEnabled = envSettings.features[featureName];
  if (featureEnabled === undefined) {
    console.warn(
      `Feature '${featureName}' has no feature flag.
      Check it is named correctly against feature JSON definitions.
      Please remove this feature flag check if the feature is now permanently enabled.`
    );
  }
  return featureEnabled !== false;
}

export function getIntegratedOrigins() {
  throwErrorIfEnvSettingsNotReady();
  const origins = [];

  Object.keys(envSettings.integrations).forEach(platformCode => {
    const { baseUrl } = envSettings.integrations[platformCode];
    if (!baseUrl) return;

    origins.push(baseUrl);

    const baseUrlWithoutWww = baseUrl.replace('//www.', '//');

    if (baseUrlWithoutWww !== baseUrl) {
      origins.push(baseUrlWithoutWww);
    }
  });

  return origins;
}

export function setEnvironmentSettings(settingsObject) {
  Object.keys(settingsObject).forEach(key => {
    if (envSettings[key] !== undefined) {
      console.warn(`Overwriting environmentSetting ${key} with: '${settingsObject[key]}'`);
    }
    envSettings[key] = settingsObject[key];
  });

  // trigger loaded event
  Object.assign(envSettings, {
    environmentSettingsLoaded: true
  });
}

const envTypesInOrder = ['LOCAL', 'LOCALMOCKTEST', 'INFRA', 'DEV', 'TEST', 'UAT', 'PERF', 'PREPROD', 'PROD'];

export const localEpsCanonicalBaseUrl = 'http://localhost:7700';
export const localEpsCanonicalApiBaseUrl = 'http://localhost:7800';

export const envTypes = envTypesInOrder.reduce((carry, envType) => ({ ...carry, [envType]: envType }), {});

export const currentEnvTypeIsAtLeast = envType =>
  envTypesInOrder.slice(envTypesInOrder.indexOf(envType)).includes(envSettings.envType);

export default envSettings;

/**
 * The account.oup.com domain is often referred to as "CES" and it currently
 * also includes the EPS Admin Dashboard.
 *
 * It returns a similar answer to getPlatformBaseUrl('ces') but is not reliant on the
 * pre-loading of the environment API.
 */
export function getEpsCanonicalBaseUrl() {
  const envType = getEnvType();
  const epsCanonicalHost = 'account.oup.com';
  if (isLocal()) {
    return localEpsCanonicalBaseUrl;
  }
  if (envType === 'PROD') {
    return `https://${epsCanonicalHost}`;
  }
  return `https://${envType.toLowerCase()}.${epsCanonicalHost}`;
}

/**
 * This anticipates the EPS Admin Dashboard getting its own domain
 * separate from account.oup.com
 */
export function getEpsAdminDashboardBaseUrl() {
  return getEpsCanonicalBaseUrl();
}

/**
 * This is the canonical base URL for API calls e.g. for external integrations.
 *
 * Typically an EPS client will call its own api base e.g. Hub can call
 * [env.]englishhub.oup.com/api which amounts to the same thing but via
 * a different CloudFront. The same Lambdas are targeted regardless.
 */
export function getEpsApiBase() {
  if (isLocal()) {
    return localEpsCanonicalApiBaseUrl;
  }
  return `${getEpsCanonicalBaseUrl()}/api`;
}

export function getProviderByRegion(regions, defaultIDP) {
  let selectedIDP = null;
  const selectedRegion = localStorage.getItem('idpRegion');

  if (selectedRegion) {
    Object.keys(regions).forEach(region => {
      if (selectedRegion === region) {
        selectedIDP = regions[region].idp;
      }
    });
  }
  return selectedIDP || defaultIDP;
}

export function getProviderByPlatformCode(platformCode) {
  return envSettings.integrations[platformCode] && envSettings.integrations[platformCode].idp;
}

export function getOidcClientIdByPlatformCode(platformCode) {
  return (envSettings.integrations[platformCode] && envSettings.integrations[platformCode].oidcClientId) || '';
}

export function getPlatformBaseUrl(platformCode) {
  return (envSettings.integrations[platformCode] && envSettings.integrations[platformCode].baseUrl) || null;
}

export function getPlatformLoginBaseUrl(platformCode) {
  return (envSettings.integrations[platformCode] && envSettings.integrations[platformCode].loginBaseUrl) || null;
}

export function getPlatformDashboardUrl(platformCode) {
  return (envSettings.integrations[platformCode] && envSettings.integrations[platformCode].dashboardUrl) || '';
}

export function getPlatformOaldLoginUrl(platformCode) {
  return (
    (envSettings.integrations[platformCode] &&
      `${envSettings.integrations[platformCode].dashboardUrl}/account/login?callbackUrl=/`) ||
    ''
  );
}

export function getPlatformSupportUrl(platformCode) {
  return (envSettings.integrations[platformCode] && envSettings.integrations[platformCode].supportUrl) || '';
}

export function useInternalAuthProcess(platformCode) {
  return (
    (envSettings.integrations[platformCode] && envSettings.integrations[platformCode].useInternalAuthProcess) || false
  );
}

export function getPlatformTermsUrl(platformCode) {
  return (envSettings.integrations[platformCode] && envSettings.integrations[platformCode].termsUrl) || '';
}

export function getPlatformAmStudentUrl(platformCode) {
  return (envSettings.integrations[platformCode] && envSettings.integrations[platformCode].amStudentUrl) || '';
}

export function getPlatformAmStaffUrl(platformCode) {
  return (envSettings.integrations[platformCode] && envSettings.integrations[platformCode].amStaffUrl) || '';
}

// temporary workaround until OLB changes the providerId param from olb_mobile to olb_offline
export function getIntegrationPlatformKey(platformCode) {
  if (platformCode.toLowerCase() === 'olb_mobile') {
    return 'olb_offline';
  }
  return platformCode.toLowerCase();
}

export function oidcEnabledByRegionForPlatformCode(platformCode) {
  return (envSettings.integrations[platformCode] && envSettings.integrations[platformCode].oidcByRegion) || false;
}

export function socialIdpIsEnabled(platformCode) {
  return (
    (!(isLinuxElectron() && featureIsEnabled('olb-ods-linux-changes')) &&
      envSettings.integrations[platformCode] &&
      envSettings.integrations[platformCode].useSocialIdp) ||
    false
  );
}

export function isOIDCStandard(standard) {
  return standard === envSettings.idp.auth0.standard;
}

export function getSocialProvider(standard = 'auth0', providerKey = '') {
  return (
    envSettings.idp[standard] &&
    envSettings.idp[standard].socialProviders &&
    envSettings.idp[standard].socialProviders[providerKey]
  );
}

export function getSocialProviderName(standard = 'auth0', providerKey = '') {
  const provider = getSocialProvider(standard, providerKey);
  return provider && (provider.name || null);
}

export function getSocialProviderConnectionName(standard = 'auth0', providerKey = '') {
  const provider = getSocialProvider(standard, providerKey);
  return provider && (provider.connection || null);
}

export function getGoogleConnectionName(standard = 'auth0') {
  return getSocialProviderConnectionName(standard, 'google');
}

export function getGoogleProviderName(standard = 'auth0') {
  return getSocialProviderName(standard, 'google');
}

export function getMicrosoftConnectionName(standard = 'auth0') {
  return getSocialProviderConnectionName(standard, 'microsoft');
}

export function getMicrosoftProviderName(standard = 'auth0') {
  return getSocialProviderName(standard, 'microsoft');
}

export function getAppleConnectionName(standard = 'auth0') {
  return getSocialProviderConnectionName(standard, 'apple');
}

export function getAppleProviderName(standard = 'auth0') {
  return getSocialProviderName(standard, 'apple');
}

export function extractCredentials(response) {
  const credentails = {
    // Credentials
    accessKeyId: response.accessKeyId || null,
    secretAccessKey: response.secretAccessKey || null,
    sessionToken: response.sessionToken || null,
    expiryTime: response.expiryTime || null,
    rightSuiteToken: response.rightSuiteToken || null,
    // User Details
    email: response.email || null,
    userId: response.userId || null,
    currentRole: response.currentRole || null,
    orgRoleMap: response.orgRoleMap || null,
    currentOrganisationId: response.currentOrganisationId || null,
    // OIDC Credentials
    oidcIdToken: response.id_token || null,
    oidcAccessToken: response.access_token || null,
    oidcRefreshToken: response.refresh_token || null,
    oidcExpiresIn: response.expires_in || null
  };
  return credentails;
}
