import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import SVGIcon, { validGlyphs } from '../SVGIcon/SVGIcon';
import styles from './TextInputButton.scss';

/** This component is for the input styled button only. It should always be used with TextInputField. */
function TextInputButton({
  id,
  type,
  icon,
  title = '',
  text = '',
  disabled = false,
  ariaControls,
  action,
  isPartOfMultilineGroup = false
}) {
  return (
    <button
      id={id}
      type={type === 'button' ? 'button' : 'submit'}
      className={classnames({
        [styles.textInputButton]: true,
        [styles.isPartOfMultilineGroup]: isPartOfMultilineGroup
      })}
      disabled={disabled}
      onClick={action}
      aria-controls={ariaControls}
    >
      <span className="a11y-hide">{text}</span>
      {icon ? <SVGIcon glyph={icon} /> : null}
      {title ? <span className={styles.buttonTitle}>{title}</span> : null}
    </button>
  );
}

TextInputButton.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** html button type */
  type: PropTypes.oneOf(['button', 'submit']),
  /** Optional button title to display */
  title: PropTypes.string,
  /** Action button accessibility text */
  text: PropTypes.string,
  disabled: PropTypes.bool,
  /** Action button icon */
  icon: PropTypes.oneOf(validGlyphs),
  /** button onclick */
  action: PropTypes.func,
  /** the id(s) of the controlled elements */
  ariaControls: PropTypes.string,

  isPartOfMultilineGroup: PropTypes.bool
};

export default TextInputButton;
