const downloadSignInCard = async signInCardUrl => {
  try {
    const anchor = document.createElement('a');
    anchor.href = signInCardUrl;
    anchor.target = '_blank'; // for firefox because is automatically opening pdf in the same tab.

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } catch (e) {
    console.log(e);
  }
};

export default downloadSignInCard;
