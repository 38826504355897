import Cookies from 'universal-cookie';

// eslint-disable-next-line import/no-mutable-exports
let cookies;

if (!cookies) {
  cookies = new Cookies();
}

export default cookies;
