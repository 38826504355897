import { pick, values, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import colors from '../globals/colors';
import withLocalizedContent from '../language/withLocalizedContent.js';
import PageHeading from '../components/PageHeading/PageHeading';
import Card from '../components/Card/Card';
import OicOrganizationForm from '../components/OicOrganizationForm/OicOrganizationForm.js';
import OicManageTestCreditsForm from '../components/OicManageTestCreditsForm/OicManageTestCreditForm.js';
import { mode as opMode } from '../redux/reducers/oicTestCredits.reducer.js';
import Button from '../components/Button/Button';

function OicCreateTestCreditsPage({
  localizedContent: { oicCreateTestCreditsForm: localizedContent },
  organization,
  subscriptions
}) {
  const [subscriptionsList, setSubscriptionsList] = useState([]);

  useEffect(() => {
    setSubscriptionsList(uniqBy(values(subscriptions), 'productId'));
  }, [subscriptions]);

  const SubscriptonsComponent = {
    [opMode.SUCCESS]:
      subscriptionsList?.length > 0 ? (
        <Card headingText={localizedContent.test_credits_card_title} headingColor={colors.PROFILE}>
          {subscriptionsList.map(subscription => (
            <OicManageTestCreditsForm
              key={subscription.subscriptionId}
              orgId={organization.formData.orgId}
              subscription={subscription}
            />
          ))}
        </Card>
      ) : (
        <div className="row align-center cols-center">
          <p className="gin-right1 subtitle">{localizedContent.no_subscriptions_subtitle}</p>
          <Button
            size="small"
            text={localizedContent.add_subscriptions_button}
            to={`/dashboard/org/${organization.formData.orgId}/licence`}
          />
        </div>
      )
  }[organization.mode];

  return (
    <>
      <Helmet title={localizedContent.page_title} />
      <div className="grid pad-top4 pad-bot4">
        <div className="row gin-bot3">
          <div className="col sm10 pad-bot2 gin-bot4">
            <PageHeading title={localizedContent.page_title} subtitle={localizedContent.page_subtitle} />
          </div>
          <div className="col md8">
            <OicOrganizationForm organization={organization} />
            {SubscriptonsComponent}
          </div>
        </div>
      </div>
    </>
  );
}

OicCreateTestCreditsPage.propTypes = {
  subscriptions: PropTypes.object,
  organization: PropTypes.object,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('oicCreateTestCreditsForm'),
  connect(state => ({
    ...pick(state.oicTestCredits, ['organization']),
    ...pick(state, ['subscriptions'])
  }))
)(OicCreateTestCreditsPage);
