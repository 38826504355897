import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ProductFinderSeries from './ProductFinderSeries';
import usePagination from '../../utils/hooks/usePagination';
import PaginationButtons from '../PaginationButtons/PaginationButtons.js';

import { setSearchState } from '../../redux/actions/productFinderActions';
import { searchTypes } from '../../redux/reducers/productFinder.reducer.js';

import styles from './ProductFinder.scss';

function ProductFinderSeriesResults({ series, onSeriesClick, searchState, setSearchStateAction }) {
  const RESULTS_PER_PAGE = 10;
  const initialPage = searchState[searchTypes.SERIES].page;

  const { currentPage, setPage, totalPages } = usePagination(series, RESULTS_PER_PAGE, initialPage);

  const hasPagination = series.length > RESULTS_PER_PAGE;

  const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
  const endIndex = startIndex + RESULTS_PER_PAGE;
  const currentSeries = series.slice(startIndex, endIndex);

  return (
    <>
      <ol>
        {currentSeries.map(s => {
          const randomNumber = Math.floor(Math.random() * 9999);
          const hyphentatedSeriesName = s?.name?.replace(/[_\s]/g, '-').toLowerCase();
          const key = `${hyphentatedSeriesName}-${randomNumber}`;

          return <ProductFinderSeries key={key} series={s} onClick={selectedSeries => onSeriesClick(selectedSeries)} />;
        })}
      </ol>

      {hasPagination ? (
        <div className={styles.textContainer}>
          <PaginationButtons
            idPrefix="productFinder"
            value={currentPage}
            numberOfPages={totalPages}
            onClick={page => {
              setSearchStateAction({ [searchTypes.SERIES]: { page } });
              setPage(page);
            }}
          />
        </div>
      ) : null}
    </>
  );
}

ProductFinderSeriesResults.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSeriesClick: PropTypes.func.isRequired,
  searchState: PropTypes.object.isRequired,
  setSearchStateAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    searchState: state.productFinder.searchState
  }),
  {
    setSearchStateAction: setSearchState
  }
)(ProductFinderSeriesResults);
