import PropTypes from 'prop-types';
import { isLevelsSortKey, parseLevelSortKey } from './Levels';
import sumOf from '../../utils/array/sumOf';
import { getFormattedAttemptsLevels } from './StudentRow';
import formatFraction, { formatToPercent } from './formatFraction';

const LOCK_TO_TOP_SORT_KEYS = ['name', 'lastAccessed', 'none'];

const formatValue = value => {
  if (Number.isNaN(Number(value))) {
    return value === '-' ? 0 : parseFloat(value.replace(/%/g, ''));
  }
  return value;
};

const toFixedNumber = value => (value ? parseFloat(value.toFixed(1)) : 0);

function AverageRowIndex({
  recordsWithTotals,
  levels,
  averageTotals,
  sortKey,
  sortDirection,
  isActivityLevel,
  children
}) {
  if (LOCK_TO_TOP_SORT_KEYS.indexOf(sortKey) > -1) {
    return children(0);
  }

  let index = 0;

  if (isLevelsSortKey(sortKey)) {
    const { level, field } = parseLevelSortKey(sortKey);
    let values = [];
    let averageValue;

    if (level === 'TOTAL') {
      if (field === 'SCORE') {
        averageValue = toFixedNumber(
          (averageTotals.totalLevelScoreAchieved / averageTotals.totalLevelScoreAvailable) * 100
        );
      } else {
        const formattedTotalAverageCompleted = toFixedNumber(
          (sumOf(levels, 'totalStudentsThatSubmitted') / recordsWithTotals.length / levels.length) * 100
        );
        averageValue = isActivityLevel
          ? formattedTotalAverageCompleted
          : toFixedNumber((averageTotals.totalAverageLevelsCompleted / averageTotals.totalNumberOfActivities) * 100);
      }
      values = recordsWithTotals.map(record => {
        const {
          totalLevelsCompleted,
          totalLevelsScore,
          totalLevelsScoreAvailable,
          numberOfCompletedActivities,
          totalNumberOfActivities
        } = record;

        const { totalAttemptsFormatted, totalLevelsFormatted } = getFormattedAttemptsLevels(
          {
            isActivityLevel,
            levels,
            totalLevelsCompleted,
            totalLevelsScore,
            numberOfCompletedActivities,
            totalNumberOfActivities,
            totalLevelsScoreAvailable
          },
          true
        );
        return field === 'SCORE' ? formatValue(totalLevelsFormatted) : formatValue(totalAttemptsFormatted);
      });
    } else {
      if (field === 'SCORE') {
        averageValue = formatFraction(levels[level].sumLevelScoreAchieved, levels[level].levelScoreAvailable, true);
      } else if (isActivityLevel) {
        const formattedAverageAttemptsSubmitted =
          typeof levels[level].averageAttemptsSubmitted === 'number'
            ? levels[level].averageAttemptsSubmitted.toFixed(2)
            : levels[level].averageAttemptsSubmitted;
        averageValue = formattedAverageAttemptsSubmitted;
      } else {
        averageValue = formatToPercent(
          levels[level].averageSubmittedNumberOfActivities / levels[level].totalNumberOfActivities
        );
      }

      values = recordsWithTotals.map(record => {
        const {
          levelScoreAchieved,
          levelScoreAvailable,
          submittedNumberOfActivities,
          totalNumberOfActivities,
          totalNumberOfAttempts,
          totalScoreAvailable,
          totalScoreAchieved
        } = record.levels[level];

        const levelCompletionSlashAttempts = isActivityLevel
          ? totalNumberOfAttempts
          : formatFraction(submittedNumberOfActivities, totalNumberOfActivities, true);

        const submittedActivitiesOrAttempts = isActivityLevel ? totalNumberOfAttempts : submittedNumberOfActivities;
        const levelSlashActivityScore =
          submittedActivitiesOrAttempts === 0
            ? '-'
            : formatFraction(
                isActivityLevel ? totalScoreAchieved : levelScoreAchieved,
                isActivityLevel ? totalScoreAvailable : levelScoreAvailable,
                true
              );
        return field === 'SCORE' ? formatValue(levelSlashActivityScore) : formatValue(levelCompletionSlashAttempts);
      });
    }
    values.push(formatValue(averageValue));
    index = values
      .sort((a, b) => (a > b ? 1 : -1) * (sortDirection === 'desc' ? -1 : 1))
      .indexOf(formatValue(averageValue));
  }

  return children(index);
}

AverageRowIndex.propTypes = {
  recordsWithTotals: PropTypes.array.isRequired,
  levels: PropTypes.array.isRequired,
  averageTotals: PropTypes.shape({
    totalAverageLevelsScore: PropTypes.number.isRequired,
    totalAverageLevelsCompleted: PropTypes.number.isRequired
  }).isRequired,
  sortKey: PropTypes.string.isRequired,
  sortDirection: PropTypes.oneOf(['none', 'asc', 'desc']).isRequired,
  children: PropTypes.func.isRequired
};

export default AverageRowIndex;
