import * as t from '../actionTypes';

export const getUserLicenceRequest = licenceId => ({
  type: t.GET_USER_LICENCE_REQUEST,
  payload: licenceId
});

export const getUserLicenceSuccess = userLicence => ({
  type: t.GET_USER_LICENCE_SUCCESS,
  payload: userLicence
});

export const getUserLicenceFailure = errors => ({
  type: t.GET_USER_LICENCE_FAILURE,
  payload: errors
});

export const getUserLicenceReset = () => ({
  type: t.GET_USER_LICENCE_RESET,
  payload: null
});
