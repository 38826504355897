import moment from 'moment';

import { licenceExpiryDates } from '../globals/hubConstants';

export default function isInThePast(expirationTime) {
  if (expirationTime === licenceExpiryDates.NEVER) {
    return false;
  }

  if (expirationTime === undefined || !moment(expirationTime).isValid()) {
    return true;
  }

  return moment(expirationTime).isBefore(moment());
}
