import { put, select, takeLatest } from 'redux-saga/effects';
import { isHubMode, isCesMode, isOrbMode } from '../../../../../utils/platform';

import {
  GET_USER_DETAILS,
  SEND_INVITE_REMINDER,
  CANCEL_INVITATION,
  setUserDetails,
  showUserNotFoundPage,
  cancelInvitationPending,
  cancelInvitationSuccess,
  cancelInvitationError,
  cancelInvitationErrorWithMessage
} from '../../../../reducers/user/userProfile.reducer.js';

import { storePeople } from '../../../../reducers/data/people.js';
import { storeOrganisations } from '../../../../reducers/data/organisations.reducer.js';

import getUserDetailsApi from '../../../apiCalls/users/getUserDetails.api.js';
import getManagedUserDetails from '../../../apiCalls/users/getManagedUserDetails.api';
import updateUserInvitation from './updateUserInvitation.saga.js';
import { pollOrgUsersRemoved } from '../dataRecency/pollOrgUsers';

import userRoles from '../../../../../globals/userRoles';

const moduleName = '[userProfile Saga]';

export function* getUserDetails() {
  console.log(moduleName, 'Fetching user details');
  const { orgId, userId } = yield select(state => ({
    // Context IDs:
    orgId: state.userProfile.orgId,
    userId: state.userProfile.userId
  }));

  const result = orgId ? yield getUserDetailsApi(orgId, userId) : {};
  const getUserDetailsFromDynamo =
    result.data && result.data.roleName === userRoles.MANAGED_USER
      ? yield getManagedUserDetails(orgId, [userId], true)
      : undefined;
  const resultDynamo =
    getUserDetailsFromDynamo && getUserDetailsFromDynamo.data.successUsers.length
      ? getUserDetailsFromDynamo.data.successUsers[0]
      : result.data;
  console.log(`${moduleName} result`, result);
  if (!result.error && result.data) {
    yield put(storePeople({ [userId]: result.data }));
    yield put(storeOrganisations({ [orgId]: { name: result.data.orgName } }));
    yield put(setUserDetails(result.data, resultDynamo));
  } else {
    yield put(showUserNotFoundPage());
  }
}
function* sendInvitationAction({ type, history }) {
  console.log(moduleName, 'Updating invitation with action: ', type);

  const updateAction = type === SEND_INVITE_REMINDER ? 'REMIND' : 'CANCEL';

  if (isHubMode()) {
    yield put(cancelInvitationPending());
  }

  const result = yield updateUserInvitation(updateAction);
  console.log(`${moduleName} Action result`, result);

  if (updateAction === 'CANCEL' && !isHubMode() && !isCesMode() && !isOrbMode()) {
    // Redirect user away while request completes
    history.push('/mySchool');
  }

  if (isHubMode()) {
    if (result.status === 'success' && !result.error) {
      yield put(cancelInvitationSuccess());

      const { userId } = yield select(state => ({
        userId: state.userProfile.userId
      }));
      if (updateAction === 'CANCEL') {
        yield pollOrgUsersRemoved(userId);
      }
    }
    if (result.error) {
      yield put(cancelInvitationError());
    }
  }

  if (isCesMode() || isOrbMode()) {
    if (result.error) {
      yield put(cancelInvitationErrorWithMessage(result.message));
    } else if (updateAction === 'CANCEL') {
      // redirect cancel action in case of success
      history.push('/mySchool');
    }
  }
}

export default function* userProfileSaga() {
  console.log(moduleName, 'Beginning');
  yield takeLatest(GET_USER_DETAILS, getUserDetails);
  yield takeLatest([SEND_INVITE_REMINDER, CANCEL_INVITATION], sendInvitationAction);
}
