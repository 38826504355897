import classnames from 'classnames';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import colors, { hexCodes } from '../../globals/colors';
import styles from './ProgressGauge.scss';

class ProgressGauge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      small: false
    };
  }

  componentDidMount() {
    this._resizeFont();
    window.addEventListener('resize', this._resizeFont);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeFont);
  }

  static getColour = percentage => {
    switch (true) {
      case percentage > 70 && percentage < 80:
        return hexCodes[colors.WARNING];
      case percentage >= 80 && percentage < 90:
        return hexCodes[colors.CORRECT];
      case percentage >= 90:
        return hexCodes[colors.CLASSROOM];
      default:
        return hexCodes[colors.ERROR];
    }
  };

  statusColoursSorter = percentage => {
    const { colouredBars, error } = this.props;
    if (colouredBars && !error) {
      return ProgressGauge.getColour(percentage);
    }
    if (error) {
      return hexCodes[colors.ARCHIVED];
    }
    return hexCodes[colors.PRIMARY];
  };

  _setGaugeRefs = element => {
    if (!element) return;
    this.gauge = element;
  };

  _resizeFont = () => {
    this.setState({ small: this.gauge.offsetWidth < 200 });
  };

  render() {
    const { percentage, label = null, options = {}, error } = this.props;

    const { small } = this.state;
    return (
      <div className={styles.progressGauge} ref={this._setGaugeRefs}>
        <DoughnutChart
          data={{
            datasets: [
              {
                backgroundColor: [this.statusColoursSorter(percentage), hexCodes[colors.BORDER]],
                borderColor: hexCodes[colors.BACKGROUND],
                data: [percentage, 100 - percentage]
              }
            ],
            labels: [label || 'Progress', 'Remainder']
          }}
          options={{
            ...{
              cutoutPercentage: 90,
              tooltips: { enabled: false },
              legend: { display: false },
              rotation: Math.PI,
              circumference: Math.PI
            },
            ...options
          }}
        />

        {error ? (
          <div
            className={classnames(styles.errorDisplay, {
              [styles.small]: small
            })}
          >
            <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} className={styles.warningSvg} />
            <span className={styles.errorLabel}>Please add year group data for all students</span>
          </div>
        ) : (
          <div
            className={classnames(styles.display, {
              [styles.small]: small
            })}
          >
            <span className={styles.label}>{label}</span>
            <div className={styles.percentage}>{numeral(percentage / 100).format('0%')}</div>
          </div>
        )}
      </div>
    );
  }
}

ProgressGauge.propTypes = {
  percentage: PropTypes.number.isRequired,
  label: PropTypes.string,
  options: PropTypes.object,
  colouredBars: PropTypes.bool,
  error: PropTypes.bool
};

export default ProgressGauge;
