import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../Button/Button';
import Card from '../Card/Card';
import ControlledForm from '../ControlledForm/ControlledForm';
import TextInput from '../TextInput/TextInput';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import { PopoutPanelIconHeading } from '..';
import actions from '../../redux/actions';

const defaultState = { licenceId: '' };

class UserLicenceRemoveByLicenceForm extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  getUserLicence = () => {
    const { getUserLicence } = this.props;
    const { licenceId } = this.state;
    getUserLicence(licenceId);
  };

  changeLicenceHandler = value => this.setState({ licenceId: value });

  render() {
    const {
      localizedContent: { userLicenceRemoveByLicenceForm: content },
      userLicence = [],
      loadingUserLicence,
      success,
      errors,
      removeLicence = () => {}
    } = this.props;

    const { licenceId = '' } = this.state;

    return (
      <ControlledForm>
        <div className="col md8">
          <div className="gin-bot3">
            <Card headingText={content.title} headingColor={colors.PROFILE}>
              <div className="row">
                <div className="col">
                  <ValidationStatus
                    forId="userLicenceRemoveByLicencePage-licenceId"
                    isActive={errors.search}
                    message={errors.search}
                  >
                    <TextInput
                      id="userLicenceRemoveByLicencePage-licenceId"
                      label={content.licenceId_heading}
                      value={licenceId}
                      onChange={value => this.changeLicenceHandler(value)}
                      onBlur={this.getUserLicence}
                    />
                  </ValidationStatus>
                </div>
              </div>
              <div>
                {(() => {
                  switch (true) {
                    case loadingUserLicence:
                      return <PopoutPanelIconHeading type={types.LOADING} title={content.loading} />;
                    case success:
                      return (
                        <TextInput
                          id="userLicenceRemoveByLicencePage-licence"
                          label={content.licence_heading}
                          value={userLicence.productName}
                          disabled={success}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </div>

              <div className="pad-top1">
                <Button
                  type={buttonTypes.PRIMARY}
                  text={content.remove_licence_button}
                  onClick={() => {
                    removeLicence(licenceId);
                  }}
                  disabled={!success}
                />
              </div>
            </Card>
          </div>
        </div>
      </ControlledForm>
    );
  }
}

UserLicenceRemoveByLicenceForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  userLicence: PropTypes.object,
  getUserLicence: PropTypes.func,
  removeLicence: PropTypes.func,
  loadingUserLicence: PropTypes.bool,
  success: PropTypes.bool,
  errors: PropTypes.object
};

export default compose(
  withLocalizedContent('userLicenceRemoveByLicenceForm'),
  connect(
    state => state.userLicenceRemoveByLicence,
    dispatch => ({
      getUserLicence: licenceId => {
        dispatch(actions.getUserLicenceRequest(licenceId));
      }
    })
  )
)(UserLicenceRemoveByLicenceForm);
