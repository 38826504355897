import { put, select, takeLatest } from 'redux-saga/effects';
import userRoles from '../../../../globals/userRoles';
import { SET_INFORMATION_POPUP_VIEWED, setInformationPopupViewed } from '../../../reducers/app.reducer';

function* root() {
  yield takeLatest(SET_INFORMATION_POPUP_VIEWED, action =>
    localStorage.setItem('informationPopupViewed', action.informationPopupViewed)
  );

  const { role } = yield select(state => state.identity);
  // NOTE: this (almost?) always ends up being true because users are rarely if ever userRoles.USER
  const blockInformationPopup = window.self !== window.top || role !== userRoles.USER;
  const informationPopupViewed = !!localStorage.getItem('informationPopupViewed');
  yield put(setInformationPopupViewed(blockInformationPopup || informationPopupViewed));
}

export default root;
