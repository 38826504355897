import React from 'react';
import PropTypes from 'prop-types';
// Components
import AssessmentFolder from '../../structure/HubCourseAssessments/UtilComponents/AssessmentFolder';
import TestList from '../TestList/TestList';
import ExpandableFolder from '../ExpandableFolder/ExpandableFolder';
// Utils
import { isLockedNode } from '../../structure/HubCourseAssessments/Utils/isLockedNode';
import folderHasTests from '../OnlineTestAssessments/Utils/folderHasTests';
// Style
import styles from './CourseAssessmentFolder.scss';

function CourseAssessmentFolder({
  id,
  title,
  assessmentData = {},
  course = {},
  isExpanded,
  setExpandedAssessment,
  hubContent,
  downloadAssessment,
  onSubfolderClick,
  selectTest
}) {
  const getFolders = () => {
    const folders = [];
    if (assessmentData) {
      Object.keys(assessmentData).forEach(key => {
        if (assessmentData[key]?.title) {
          const folder = { ...assessmentData[key] };
          const hasTests = folderHasTests(folder);
          if (!hasTests) return;

          folder.locked = isLockedNode(course, assessmentData[key], true);
          folders.push({ ...folder, subFolderId: key });
        }
      });
    }
    return folders;
  };

  const renderFolders = (folders = []) => {
    if (!folders.length) return null;

    return (
      <div className={styles.subfoldersContainer}>
        {folders.map(folder => (
          <AssessmentFolder
            locked={folder.locked}
            key={folder.subFolderId}
            title={folder.title}
            customClassname={styles.assessmentSubFolderWidth}
            onClick={() => onSubfolderClick(folder.subFolderId)}
          />
        ))}
      </div>
    );
  };

  const folders = getFolders();

  return (
    <div>
      <ul className={styles.assessmentFolderContainer}>
        <li key={id} className={styles.courseAssessmentFolder}>
          <ExpandableFolder
            id={id}
            title={title}
            isExpanded={isExpanded}
            onExpandAction={() => setExpandedAssessment(id)}
          />
        </li>
      </ul>

      {isExpanded ? (
        <div className={styles.folderItemsContainer}>
          <TestList
            assessmentData={assessmentData}
            course={course}
            hubContent={hubContent}
            downloadAssessment={downloadAssessment}
            selectTest={selectTest}
          />
          {renderFolders(folders)}
        </div>
      ) : null}
    </div>
  );
}

CourseAssessmentFolder.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  assessmentData: PropTypes.object.isRequired,
  onSubfolderClick: PropTypes.func,
  course: PropTypes.object,
  isExpanded: PropTypes.bool.isRequired,
  setExpandedAssessment: PropTypes.func.isRequired,
  hubContent: PropTypes.object,
  downloadAssessment: PropTypes.func,
  selectTest: PropTypes.func.isRequired
};
export default CourseAssessmentFolder;
