import * as t from '../../actionTypes';

const initialState = {
  data: {},
  submitting: false,
  success: false,
  error: false
};

function ngsStudentMarks(state = initialState, { type, payload }) {
  switch (type) {
    case t.NGS_STUDENT_MARKS_REQUEST:
      return {
        ...state,
        submitting: true,
        success: false,
        error: false
      };
    case t.NGS_STUDENT_MARKS_SUCCESS:
      return {
        ...state,
        data: payload,
        submitting: false,
        success: true,
        error: false
      };
    case t.NGS_STUDENT_MARKS_FAILURE:
      return {
        ...state,
        submitting: false,
        error: true,
        success: false
      };
    default:
      return state;
  }
}

export default ngsStudentMarks;
