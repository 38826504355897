import { put } from 'redux-saga/effects';
import { reportsRequestSuccess, reportsRequestFailure } from '../../../../actions/ngsTeacherReports';
import getReportsApiCall from '../../../apiCalls/ngs/getReports';

function* getReports(orgId) {
  const response = yield getReportsApiCall(orgId);

  if (response.status === 'success') {
    yield put(reportsRequestSuccess(response.data));
    return;
  }

  yield put(reportsRequestFailure(response.message));
}

export default getReports;
