import { put, select } from 'redux-saga/effects';

import { setFormState } from '../../../../reducers/data/forms.reducer.js';
import getUserDetailsFromTokenApi from '../../../apiCalls/users/getUserDetailsFromToken.api.js';
import { getFieldValue, requestFailed } from '../formUtil.js';

export default function* loadResetPassword() {
  const payload = yield select(state => ({
    userToken: getFieldValue(state, 'resetPassword', 'token'),
    tokenType: 'UPDATE_AUTH'
  }));

  const apiResult = yield getUserDetailsFromTokenApi(payload);

  const failed = requestFailed(apiResult);

  // eslint-disable-next-line no-nested-ternary
  const nextFormState = failed
    ? 'LOADING_ERROR'
    : apiResult.data.tokenStatus !== 'ACTIVE'
    ? 'TOKEN_EXPIRED'
    : 'INPUTTING';

  yield put(setFormState('resetPassword', nextFormState));
}
