import * as t from '../actionTypes.js';

export const invalidateContentSelectorGroupRequest = (params = {}, callback) => ({
  type: t.NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_REQUEST,
  payload: params,
  callback
});

export const invalidateContentSelectorGroupSuccess = data => ({
  type: t.NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_SUCCESS,
  payload: data
});

export const invalidateContentSelectorGroupFailure = error => ({
  type: t.NGI_INVALIDATE_CONTENT_SELECTOR_GROUP_FAILURE,
  payload: error
});
