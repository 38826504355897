import { customAlphabet } from 'nanoid';

const ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz-';
const ID_LENGTH = 27;

const createCustomTempId = customAlphabet(ALPHABET, ID_LENGTH);
export default function getLocalStorageTempClientId(platform) {
  let tempClientId = localStorage.getItem(`${platform}-temp-client-id`);

  if (!tempClientId) {
    tempClientId = createCustomTempId();
    localStorage.setItem(`${platform}-temp-client-id`, tempClientId);
  }
  return tempClientId;
}
