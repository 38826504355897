import { select } from 'redux-saga/effects';

import checkUserEmailApi from '../../../../apiCalls/users/checkUserEmail.api.js';

export default function* checkUserStatusRequest() {
  const { orgId, email } = yield select(state => ({
    orgId: state.organisationPage.orgId || state.identity.currentOrganisationId,
    email: state.enrolUser.emailValue
  }));

  console.log(`[enrolUser Saga] Checking user status: orgId: ${orgId}, email: ${email}.`);
  return yield checkUserEmailApi(orgId, email);
}
