import { put } from 'redux-saga/effects';
import getPlacementTestResults from '../../../apiCalls/hub/getPlacementTestResults';
import {
  downloadPlacementTestResultsSuccess,
  downloadPlacementTestResultsFailure
} from '../../../../actions/placementTestResults';

function* loadPlacementTestResults(payload) {
  const { sessionId, userId } = payload;
  const response = yield getPlacementTestResults({ sessionId, userId });
  if (response.status === 'success') {
    yield put(downloadPlacementTestResultsSuccess(response));
  } else {
    yield put(downloadPlacementTestResultsFailure(response));
  }
}

export default loadPlacementTestResults;
