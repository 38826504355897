import * as t from '../../actionTypes';

const initialState = {
  ids: [],
  adaptedTests: {},
  adaptedTestsRecentlyUpdated: true,
  adaptedTestsNeedToBeUpdated: false,
  isPollingInProgress: false,
  listenerInitialized: false
};

function hubAdaptedTests(state = initialState, { type, payload }) {
  switch (type) {
    case t.SET_ADAPTED_TESTS_POLLING:
      return {
        ...state,
        isPollingInProgress: payload
      };
    case t.SET_ADAPTED_TESTS_NEED_TO_BE_UPDATED:
      return {
        ...state,
        adaptedTestsNeedToBeUpdated: payload
      };
    case t.GET_ADAPTED_TESTS_FOR_ASSESSMENT:
      return {
        ...state,
        listenerInitialized: true
      };
    case t.SET_ADAPTED_TESTS_UPDATED:
      return {
        ...state,
        adaptedTestsRecentlyUpdated: true,
        isPollingInProgress: false
      };
    case t.GET_ADAPTED_TESTS_FOR_ASSESSMENT_SUCCESS:
      return {
        ...state,
        ids: payload && payload.ids,
        adaptedTests: payload && payload.adaptedTests,
        adaptedTestsRecentlyUpdated: false
      };
    default:
      return state;
  }
}

export default hubAdaptedTests;
