import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import UserProfile from './UserProfile.js';
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader';

import { getUserDetails, componentStates } from '../../redux/reducers/user/userProfile.reducer.js';
import cmsContent from '../../utils/cmsContent.js';

class UserProfileInitialising extends Component {
  componentWillMount() {
    const { getUserDetailsAction, match } = this.props;
    getUserDetailsAction(match.params.orgId, match.params.userId);
  }

  render() {
    const CMS = cmsContent.userProfilePage || {};
    const {
      match: { params },
      location,
      componentState
    } = this.props;

    let content;
    switch (componentState) {
      case componentStates.FETCHING:
        content = <SkeletonLoader loadingResults />;
        break;
      case componentStates.PROFILE_COMPLETE:
      case componentStates.DISPLAY_MODAL:
        content = <UserProfile params={params} location={location} />;
        break;
      case componentStates.USER_NOT_FOUND:
        content = <PopoutPanelIconHeading type={types.ERROR} title={CMS.user_profile_not_found_text} />;
        break;
      default:
        content = `${CMS.user_profile_missing_component} ${componentState}, ${componentStates.FETCHING}`;
    }

    return <div>{content}</div>;
  }
}

UserProfileInitialising.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  componentState: PropTypes.string.isRequired,
  getUserDetailsAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    componentState: state.userProfile.componentState
  }),
  {
    getUserDetailsAction: getUserDetails
  }
)(UserProfileInitialising);
