import { all, put, select } from 'redux-saga/effects';
import editUserSchema from '../../../../../../static/schema/draft-4/edit-user-request.schema.json';
import checkUsername from '../../../api/checkUsername';
import checkUserEmail from '../../../api/checkUserEmail';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import * as t from '../../../../actionTypes';
import actions from '../../../../actions';

function* checkIsUserNameTaken(orgId, userName) {
  let isUserNameTaken = false;
  if (userName === undefined) {
    isUserNameTaken = yield select(state => state.editUser.isUserNameTaken);
  } else {
    yield put(actions.editUserCheckingUserName());
    const errors = yield validateInputUsingSchema(editUserSchema, null, { userName });
    if (errors.userName === false) {
      const { status, data } = yield checkUsername(userName);
      const userIdentityUsername = yield select(state => state.identity.email);
      isUserNameTaken = status.toLowerCase() === 'success' && data.exists && userIdentityUsername !== userName;
    }
    yield put(actions.editUserCheckingUserNameCompleted(isUserNameTaken));
  }
  return isUserNameTaken;
}

function* checkIsEmailTaken(orgId, email) {
  let isEmailTaken = false;
  if (email === undefined) {
    isEmailTaken = yield select(state => state.editUser.isEmailTaken);
  } else if (orgId) {
    yield put(actions.editUserCheckingEmail());
    const errors = yield validateInputUsingSchema(editUserSchema, null, { email });
    if (errors.email === false) {
      const { status, data } = yield checkUserEmail(orgId, email);
      const userIdentityUsername = yield select(state => state.identity.email);
      isEmailTaken = status.toLowerCase() === 'success' && data.exists && userIdentityUsername !== email;
    }
    yield put(actions.editUserCheckingEmailCompleted(isEmailTaken));
  }
  return isEmailTaken;
}

function* validateEditUserInput(orgId, input = {}) {
  const errors = yield validateInputUsingSchema(editUserSchema, t.EDIT_USER_VALIDATE_INPUT_COMPLETED, input);
  const { userName, email } = input;
  const { isUserNameTaken, isEmailTaken } = yield all({
    isUserNameTaken: checkIsUserNameTaken(orgId, userName),
    isEmailTaken: checkIsEmailTaken(orgId, email)
  });
  yield put(
    actions.editUserValidateInputCompleted({
      ...errors,
      ...(isUserNameTaken ? { userName: isUserNameTaken } : {}),
      ...(isEmailTaken ? { email: isEmailTaken } : {})
    })
  );
  return errors;
}

export default validateEditUserInput;
