import signedFetch from '../../../apiCalls/util/signedFetch.js';

export default function* productUploadCheckApi(formData, platformCode) {
  let selectedPlatformCode = platformCode;
  if (selectedPlatformCode) {
    selectedPlatformCode = selectedPlatformCode.toLowerCase();
  }

  return yield signedFetch(
    'file',
    `${__API_BASE__}/checkFile/product-metadata?platform=${selectedPlatformCode}`,
    'POST',
    formData,
    null,
    false,
    true
  );
}
