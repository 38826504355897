import * as t from '../actionTypes';
import transformUsers from '../utils/transformUsers';

export const setClassNameRequest = data => ({
  type: t.ONBOARDING_WIZARD_SET_CREATE_CLASS_REQUEST,
  payload: data
});
export const setClassNameSuccess = payload => ({
  type: t.ONBOARDING_WIZARD_SET_CREATE_CLASS_SUCCESS,
  payload
});
export const setClassNameError = payload => ({
  type: t.ONBOARDING_WIZARD_SET_CREATE_CLASS_ERROR,
  payload
});
export const getClassStudents = payload => ({
  type: t.ONBOARDING_WIZARD_GET_CLASS_STUDENTS,
  payload
});
export const getClassStudentsSuccess = payload => ({
  type: t.ONBOARDING_WIZARD_GET_CLASS_STUDENTS_SUCCESS,
  payload: { ...payload, students: transformUsers(payload.students) }
});
export const getClassStudentsError = () => ({
  type: t.ONBOARDING_WIZARD_GET_CLASS_STUDENTS_ERROR
});
export const saveLearningMaterialsSuccess = payload => ({
  type: t.ONBOARDING_WIZARD_SAVE_LEARNING_MATERIALS_SUCCESS,
  payload
});
export const saveLearningMaterialsError = () => ({
  type: t.ONBOARDING_WIZARD_SAVE_LEARNING_MATERIALS_ERROR
});
export const clearWizardState = () => ({
  type: t.ONBOARDING_WIZARD_CLEAR_STATE
});
