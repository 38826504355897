import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class ResourceFormHidden extends Component {
  componentDidUpdate({ downloadResourceId = '', isError: prevIsError, consumer: prevConsumer }) {
    const { consumer, isError } = this.props;
    if (
      typeof downloadResourceId === 'string' &&
      downloadResourceId !== '' &&
      consumer !== prevConsumer &&
      isError !== !prevIsError
    ) {
      this.populateAndSubmitForm();
    }
  }

  formRef = form => {
    this.ltiForm = form;
  };

  populateAndSubmitForm = () => {
    const { form, isError, consumer } = this.props;
    if (!form || isError) return;
    this.ltiForm.innerHTML = '';
    Object.entries(consumer).map(([name, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = value;
      return this.ltiForm.appendChild(input);
    });
    this.ltiForm.action = form;
    this.ltiForm.method = 'POST';
    this.ltiForm.submit();
  };

  render() {
    const {
      props: { resourceAction },
      formRef
    } = this;

    return <form ref={formRef} name="lti" title="lti" target={resourceAction === 'play' ? 'player' : undefined} />;
  }
}

const mapStateToProps = state => ({
  resourceAction: state.hubResourceDownload.resourceAction,
  consumer: state.hubResourceDownload.resource !== undefined ? state.hubResourceDownload.resource.lti : {},
  form: state.hubResourceDownload.resource !== undefined ? state.hubResourceDownload.resource.form.uri : '',
  downloadResourceId: state.hubResourceDownload.downloadResourceId || state.hubResourceDownload.assessmentEntitlement,
  isError: state.hubResourceDownload.isError
});

ResourceFormHidden.propTypes = {
  resourceAction: PropTypes.string.isRequired,
  consumer: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  downloadResourceId: PropTypes.string,
  isError: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, null)(ResourceFormHidden);
