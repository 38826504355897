import { call, cancel, fork, put, select, take, delay } from 'redux-saga/effects';
import {
  RESET_FORM,
  setProductDetails,
  setProductFetchError,
  showConfirmation,
  SUBMIT_FORM
} from '../../../../../reducers/addToLibrary.reducer';
import searchProductsByActivationCodeApi from '../../../../apiCalls/search/searchProductsByActivationCode.api';
import { pollOrgLicencesAdded } from '../../dataRecency/pollOrgLicences';
import singleInputToLibraryRequest from './singleInputToLibraryRequest.saga';

const MAX_SEARCH_ATTEMPTS = 20;

function* getProductDetails(activationCode) {
  let isSuccess = false;
  const orgId = yield select(state => state.organisationPage.orgId);

  for (let i = 0; i < MAX_SEARCH_ATTEMPTS && !isSuccess; i += 1) {
    console.log(`[singleInputToLibrary:getProductDetails] Attempt ${i + 1}`);

    try {
      const apiResponse = yield call(searchProductsByActivationCodeApi, { orgId, term: activationCode });
      if (apiResponse.data.totalResults > 0) {
        console.log('[singleInputToLibrary:getProductDetails] Got details');
        yield put(setProductDetails(apiResponse.data.products));
        isSuccess = true;
      }
    } catch (error) {
      console.log('[singleInputToLibrary:getProductDetails] Error loading products:', error);
    }

    // Delay next attempt
    yield delay(10000);
  }

  if (!isSuccess) {
    console.log('[singleInputToLibrary Saga] failed fetching product details, showing error');
    yield put(setProductFetchError());
  }
}

export default function* singleInputToLibrary() {
  console.log('[singleInputToLibrary Saga] Beginning');

  while (true) {
    console.log('[singleInputToLibrary Saga] Waiting for user to submit form');
    yield take(SUBMIT_FORM);

    console.log('[singleInputToLibrary Saga] Submitting addTolLibrary request');
    const result = yield singleInputToLibraryRequest();

    console.log(result);

    const errorOccurred = result.status !== 'success';
    let errorCode = {};

    if (errorOccurred && result?.message.match(/\d+/)) {
      errorCode.code = result.message.match(/\d+/)[0];
    } else if (errorOccurred && result.code) {
      errorCode.code = result.code;
    } else {
      errorCode = '';
    }

    if (typeof errorCode === 'object') {
      const codeActivationDate = result.message.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
      if (codeActivationDate) {
        errorCode.activationDate = codeActivationDate[0];
      }
    }

    let getProductDetailsTask;
    if (!errorOccurred) {
      getProductDetailsTask = yield fork(getProductDetails, result.data.activationCode);
    }

    if (!errorOccurred && result.data && result.data.activationCode) {
      yield pollOrgLicencesAdded([result.data.activationCode]);
    }

    yield put(
      // Get ERROR based on code from API
      showConfirmation(errorOccurred, errorCode)
    );

    yield take(RESET_FORM);
    if (getProductDetailsTask && getProductDetailsTask.isRunning()) {
      console.log('[singleInputToLibrary Saga] Panel closed, cancelling product details fetch');
      yield cancel(getProductDetailsTask);
    }
    console.log('[singleInputToLibrary Saga] Resetting singleInputToLibrary state and saga');
  }
}
