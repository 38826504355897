import * as t from '../actionTypes';

export const setRegenerateSignInCardRequestData = data => ({
  type: t.SET_REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_DATA,
  payload: data
});
export const regenerateSignInCardRequest = data => ({
  type: t.REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_REQUEST,
  payload: data
});

export const regenerateSignInCardSuccess = payload => ({
  type: t.REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_SUCCESS,
  payload
});
export const regenerateSignInCardError = payload => ({
  type: t.REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_ERROR,
  payload
});
export const resetRegenerateSignInCardState = () => ({
  type: t.RESET_REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_STATE
});
