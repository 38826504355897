import { connect } from 'react-redux';
import { parse as parseQueryString } from 'query-string';
import { setFormState } from '../../redux/reducers/data/forms.reducer';
import ResetPasswordConfirmation from '../../components/ResetPasswordConfirmation/ResetPasswordConfirmation';
import { getPlatformUrl } from '../../utils/platform';

export default connect(
  state => {
    const queryString = parseQueryString(window.location.search);
    const firstName = queryString.firstName || '';
    const platform = (queryString.platform || '').toLowerCase();
    const platformCode = platform.includes('mobile') ? platform.split('_')[0] : platform;
    const loginUrl = getPlatformUrl(platformCode);

    return {
      firstName,
      platform,
      loginUrl,
      failed: state.forms.resetPassword.failed
    };
  },
  {
    onResetClick: () => setFormState('resetPassword', 'LOADING')
  }
)(ResetPasswordConfirmation);
