import * as t from '../actionTypes';

export const getJwtAndDeepLinkRequest = (confirmedProducts, callBack) => ({
  type: t.NGI_FETCH_JWT_AND_DEEPLINK_REQUEST,
  payload: confirmedProducts,
  callBack
});

export const getJwtAndDeepLinkSuccess = jwtAndDeepLinkReturnUrl => ({
  type: t.NGI_FETCH_JWT_AND_DEEPLINK_SUCCESS,
  payload: jwtAndDeepLinkReturnUrl
});

export const getJwtAndDeepLinkFailure = error => ({
  type: t.NGI_FETCH_JWT_AND_DEEPLINK_FAILURE,
  payload: error
});
