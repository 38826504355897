import PropTypes from 'prop-types';
import React from 'react';
import EntityRepresentation from '../EntityRepresentation/EntityRepresentation';
import { validSizes } from '../Thumbnail/Thumbnail';
import { ALIGNMENT } from '../../globals/appConstants';

/** This component renders a learning materials image thumbnail, name and some subtext. The name can be a link */
function MaterialRepresentation({
  thumbnailSize,
  imageSrc,
  linkTo,
  name,
  subtext,
  statusText,
  textAlignment,
  statusColor,
  teacherTagText,
  deletedStyle,
  onClick,
  headerSizeName,
  ellipsisHeading,
  statusIndication,
  nameColor,
  helpAndSupportLink,
  helpAndSupportLinkTo
}) {
  return (
    <EntityRepresentation
      thumbnailSize={thumbnailSize}
      thumbnailImageSrc={imageSrc}
      thumbnailSquareImg
      linkTo={linkTo}
      line1Text={name}
      line1Color={nameColor}
      line2Text={subtext}
      line3Text={statusText}
      line4Text={teacherTagText}
      textAlignment={textAlignment}
      status={statusColor}
      deletedStyle={deletedStyle}
      onClick={onClick}
      headerSizeName={headerSizeName}
      ellipsisHeading={ellipsisHeading}
      statusIndication={statusIndication}
      helpAndSupportLink={helpAndSupportLink}
      helpAndSupportLinkTo={helpAndSupportLinkTo}
    />
  );
}

MaterialRepresentation.propTypes = {
  /** The size of the thumbnail. */
  thumbnailSize: PropTypes.oneOf(validSizes),
  /** Materials image to be displayed in the thumbnail */
  imageSrc: PropTypes.string,
  /** Target of the link if the entity contains a link */
  linkTo: PropTypes.string,
  /** Materials name */
  name: PropTypes.string.isRequired,
  /** Materials name color */
  nameColor: PropTypes.string,
  /** Some subtext */
  subtext: PropTypes.string,
  /** Some statusText */
  statusText: PropTypes.string,
  /** Optionally customise the status color of the line3Text. */
  statusColor: PropTypes.oneOf(['TEXT', 'SUCCESS', 'WARNING', 'ERROR']),
  teacherTagText: PropTypes.string,
  /** This applies a "deleted" style to the element */
  deletedStyle: PropTypes.bool,
  onClick: PropTypes.func,
  headerSizeName: PropTypes.bool,
  ellipsisHeading: PropTypes.bool,
  statusIndication: PropTypes.bool,
  helpAndSupportLink: PropTypes.string,
  helpAndSupportLinkTo: PropTypes.string,
  textAlignment: PropTypes.oneOf(Object.values(ALIGNMENT))
};

export default MaterialRepresentation;
