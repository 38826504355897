import React from 'react';
import PropTypes from 'prop-types';
import styles from './PopoutPanelIconHeading.scss';

import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import PanelHeading from '../PanelHeading/PanelHeading';

export const types = {
  VALID: 'VALID',
  WARNING: 'WARNING',
  WARNING_ERROR: 'WARNING_ERROR',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  PROCESSING: 'PROCESSING',
  INFO: 'INFO'
};

export const validTypes = Object.keys(types);

export const typeToIconMap = {
  VALID: GLYPHS.ICON_CHECK_CIRCLE,
  WARNING: GLYPHS.ICON_WARNING_CIRCLE,
  WARNING_ERROR: GLYPHS.ICON_ERROR_CIRCLE,
  ERROR: GLYPHS.ICON_ERROR_CIRCLE,
  LOADING: GLYPHS.ICON_LOADING,
  PROCESSING: GLYPHS.PROCESSING,
  INFO: GLYPHS.ICON_INFORMATION_CIRCLE
};

function PopoutPanelIconHeading({ type, title, subtitle, children, link, linkText }) {
  return (
    <div id="popoutPanelIconHeading" className={styles.popoutPanelIconHeading}>
      <SVGIcon glyph={typeToIconMap[type]} />
      <PanelHeading title={title} subtitle={subtitle} link={link} linkText={linkText}>
        {children}
      </PanelHeading>
    </div>
  );
}

PopoutPanelIconHeading.propTypes = {
  /** Which icon to show above the heading. */
  type: PropTypes.oneOf(validTypes).isRequired,
  /** The main heading text. */
  title: PropTypes.string.isRequired,
  /** The second sub-heading text. */
  subtitle: PropTypes.string,
  /** Optionally display additional content such as "You will be emailed at ..." */
  children: PropTypes.any,
  link: PropTypes.string,
  linkText: PropTypes.string
};

export default PopoutPanelIconHeading;
