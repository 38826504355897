import * as t from '../actionTypes';

export const setReCaptchaSiteKey = (input = null) => ({
  type: t.RECAPTCHA_SET_SITE_KEY,
  payload: input
});

export const reCAPTCHASetToken = (input = null) => ({
  type: t.RECAPTCHA_SET_TOKEN,
  payload: input
});

export const reCAPTCHASubmit = (input = null) => ({
  type: t.RECAPTCHA_SUBMIT,
  payload: input
});

export const reCAPTCHAReset = () => ({
  type: t.RECAPTCHA_RESET
});

export const reCAPTCHASuccess = () => ({
  type: t.RECAPTCHA_SUCCESS
});

export const reCAPTCHABot = () => ({
  type: t.RECAPTCHA_BOT
});

export const reCAPTCHAFailure = errorMessages => ({
  type: t.RECAPTCHA_FAILURE,
  payload: { errorMessages }
});
