import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextInput from '../../../../components/TextInput/TextInput';
import ButtonGroup, { ButtonGroupType } from '../../../../components/ButtonGroup/ButtonGroup';
import RadioInput from '../../../../components/RadioInput/RadioInput';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Card from '../../../../components/Card/Card';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import colors from '../../../../globals/colors';
import styles from './ProductSearchPage.scss';
import { emptySearchProductResults, searchProductRequest } from '../../../../redux/actions/searchProduct';
import ProductSearchResults from './ProductSearchResults';
import { organizationalUnitsRequest, platformsRequest } from '../../../../redux/actions/createProduct';
import PaginationButtons from '../../../../components/PaginationButtons/PaginationButtons';

function ProductSearchPage({
  localizedContent: { productSearch: content },
  result,
  error,
  isLoading,
  searchProducts,
  emptySearchProductsResults,
  getOrganizationalUnits,
  isOrganizationalUnitsLoading,
  organizationalUnits,
  getPlatforms,
  isPlatformsLoading,
  platforms
}) {
  const searchByOptions = {
    searchName: content.radio_product_name,
    searchOupId: content.radio_product_id,
    searchExternalId: content.radio_external_id
  };

  const [searchFilter, setSearchFilter] = useState(null);
  const [searchByFilter, setSearchByFilter] = useState('searchName');
  const [organisationalUnit, setOrganisationalUnit] = useState(null);
  const [platformCode, setPlatformCode] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(10);

  const performSearch = page => {
    const filters = {
      searchFilter,
      searchBy: searchByFilter,
      organisationalUnit,
      currentPage,
      platformCode,
      limit: numberPerPage,
      offset: numberPerPage * (page - 1)
    };

    searchProducts(filters);
  };

  const performReset = () => {
    setSearchFilter(null);
    setSearchByFilter('searchName');
    setOrganisationalUnit(null);
    setPlatformCode(null);
    setCurrentPage(1);
  };

  useEffect(() => {
    performSearch(currentPage);
  }, [currentPage]);

  useEffect(() => {
    getOrganizationalUnits();
    getPlatforms();

    return () => {
      performReset();
      emptySearchProductsResults();
    };
  }, []);

  const getOrganizationalUnitOptions = () => {
    if (isOrganizationalUnitsLoading) {
      return [];
    }

    return [
      { value: '-1', text: '' },
      ...organizationalUnits.map(orgUnit => ({ value: orgUnit.division_type, text: orgUnit.division_type }))
    ];
  };

  const getPlatformOptions = () => {
    if (isPlatformsLoading) {
      return [];
    }

    return [
      { value: '-1', text: '' },
      ...platforms.map(plat => ({ value: plat.platform_code, text: plat.platform_code }))
    ];
  };

  const getResultsPerPageOptions = () => [10, 20].map(v => ({ value: v, text: v }));

  const renderPagination = () => {
    if (isLoading) {
      return <> </>;
    }

    if (result) {
      const { products, total } = result;
      if (products && products.length) {
        return (
          <div className={styles.paginationContainer}>
            <PaginationButtons
              idPrefix="productId"
              value={currentPage}
              numberOfPages={Math.ceil(total / numberPerPage)}
              onClick={page => {
                setCurrentPage(page);
              }}
            />
          </div>
        );
      }
    }

    return <div />;
  };

  const totalResults = () => {
    if (isLoading) {
      return <> </>;
    }
    let total = 0;
    if (result) {
      total = result.total;
    }

    const results = total === 1 ? content.one_record : content.multiple_records.replace('{records}', total);

    return <div> {content.found_results.replace('{results}', results)} </div>;
  };

  const renderSearchResult = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (result) {
      const { products } = result;

      if (products && !products.length) {
        return (
          <div>
            <div className={styles.errorMsg}>{content.no_results}</div>
          </div>
        );
      }

      if (products && products.length) {
        return (
          <div>
            <ProductSearchResults products={products} />
          </div>
        );
      }
    }

    if (error) {
      return (
        <div>
          <div className={styles.errorMsg}>{content.err_msg}</div>
        </div>
      );
    }

    return <> </>;
  };

  return (
    <div className={styles.searchContainer}>
      <div className={styles.searchForm}>
        <div className={styles.searchCriteriaContainer}>
          <Card headingText={content.enter_criteria} headingTag="h2" showHeadingLabel headingColor={colors.ARCHIVED}>
            <div className={styles.formContainer}>
              <TextInput
                label={content.search_for}
                disableAutoComplete
                onChange={value => setSearchFilter(value)}
                value={searchFilter || ''}
              />
              <div className={styles.cardRowRadio}>
                <ButtonGroup labelHidden groupType={ButtonGroupType.HORIZONTAL_GROUP}>
                  {Object.keys(searchByOptions).map((searchByOption, index) => (
                    <RadioInput
                      key={index}
                      onChange={value => {
                        if (value) {
                          setSearchByFilter(searchByOption);
                        }
                      }}
                      name="statusFilter"
                      value={searchByOption}
                      checked={searchByFilter === searchByOption}
                      label={searchByOptions[searchByOption]}
                    />
                  ))}
                </ButtonGroup>
              </div>
              <Dropdown
                label={content.org_unit}
                disabled={isOrganizationalUnitsLoading}
                value={organisationalUnit || '-1'}
                options={getOrganizationalUnitOptions()}
                onChange={value => setOrganisationalUnit(value === '-1' ? null : value)}
              />
              <Dropdown
                label={content.platform_code}
                disabled={isPlatformsLoading}
                value={platformCode || '-1'}
                options={getPlatformOptions()}
                onChange={value => setPlatformCode(value === '-1' ? null : value)}
              />
              <TextInput label={content.example_url} />
              <Dropdown
                label={content.results_per_page}
                value={numberPerPage}
                options={getResultsPerPageOptions()}
                onChange={value => setNumberPerPage(value)}
              />
            </div>
          </Card>
        </div>
        <div className={styles.searchButtonsContainer}>
          <div>
            <Button
              type={buttonTypes.PRIMARY}
              text={content.button_search}
              disabled={!searchFilter}
              onClick={() => (currentPage === 1 ? performSearch(currentPage) : setCurrentPage(1))}
            />
            <Button type={buttonTypes.PRIMARY} text={content.button_reset} onClick={performReset} />
          </div>
        </div>
      </div>
      <div className={styles.resultsArea}>{totalResults()}</div>
      <div className={styles.resultsArea}>{renderSearchResult()}</div>
      <div style={{ marginBottom: 10 }}>{renderPagination()}</div>
    </div>
  );
}

ProductSearchPage.propTypes = {
  localizedContent: PropTypes.object,
  result: PropTypes.object,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  searchProducts: PropTypes.func,
  emptySearchProductsResults: PropTypes.func,
  organizationalUnits: PropTypes.array,
  getOrganizationalUnits: PropTypes.func,
  isOrganizationalUnitsLoading: PropTypes.bool,
  platforms: PropTypes.array,
  getPlatforms: PropTypes.func,
  isPlatformsLoading: PropTypes.bool
};

const mapStateToProps = ({
  searchProduct: { isLoading, error, result },
  getOrganizationalUnits: {
    isLoading: isOrganizationalUnitsLoading,
    error: organizationalUnitsError,
    success: organizationalUnits
  },
  getPlatforms: { isLoading: isPlatformsLoading, error: platformsError, success: platforms }
}) => ({
  isLoading,
  error,
  result,
  isOrganizationalUnitsLoading,
  organizationalUnitsError,
  organizationalUnits,
  isPlatformsLoading,
  platformsError,
  platforms
});

const mapDispatchToProps = dispatch => ({
  searchProducts: filters => {
    dispatch(searchProductRequest(filters));
  },
  emptySearchProductsResults: () => {
    dispatch(emptySearchProductResults());
  },
  getOrganizationalUnits: () => {
    dispatch(organizationalUnitsRequest());
  },
  getPlatforms: () => {
    dispatch(platformsRequest());
  }
});

export default compose(
  withLocalizedContent('productSearch'),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductSearchPage);
