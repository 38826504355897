// Page load and clear actions
export const LOAD_CLASSROOM_PAGE = 'classroomPage/LOAD_CLASSROOM_PAGE';
const CLEAR_CLASSROOM_PAGE = 'classroomPage/CLEAR_CLASSROOM_PAGE';
const CLEAR_USER_SELECTION = 'classroomPage/CLEAR_USER_SELECTION';

// Update list selection actions
const SET_STUDENT_SELECTED = 'classroomPage/SET_STUDENT_SELECTED';
const SET_TEACHER_SELECTED = 'classroomPage/SET_TEACHER_SELECTED';

const SET_SELECT_ALL_STUDENTS = 'classroomPage/SET_SELECT_ALL_STUDENTS';
const SET_SELECT_ALL_TEACHERS = 'classroomPage/SET_SELECT_ALL_TEACHERS';
const CHECK_IF_ALL_STUDENTS_ARE_SELECTED = 'classroomPage/CHECK_IF_ALL_STUDENTS_ARE_SELECTED';
const CHECK_IF_ALL_TEACHERS_ARE_SELECTED = 'classroomPage/CHECK_IF_ALL_TEACHERS_ARE_SELECTED';
const SET_ALL_STUDENTS_ARE_SELECTED = 'classroomPage/SET_ALL_STUDENTS_ARE_SELECTED';
const SET_ALL_TEACHERS_ARE_SELECTED = 'classroomPage/SET_ALL_TEACHERS_ARE_SELECTED';

const REMOVE_SELECTED_STUDENT_IDS = 'classroomPage/REMOVE_SELECTED_STUDENT_IDS';
const REMOVE_SELECTED_TEACHER_IDS = 'classroomPage/REMOVE_SELECTED_TEACHER_IDS';

const initialState = {
  // TODO: Currently viewed Class ID should probably only be sourced from the URL
  orgId: null,
  classroomId: null,

  // Students Tab
  studentSearchTerm: '',
  selectedStudentIds: [],
  areStudentsSelected: false,

  // Teachers Tab
  teacherSearchTerm: '',
  selectedTeacherIds: [],
  areTeachersSelected: false
};

function addOrRemove(selectedIds, id, isSelected) {
  let selIds = [];
  if (isSelected) {
    if (Array.isArray(id)) {
      selIds = [...selectedIds, ...id];
    } else {
      selIds = [...selectedIds, id];
    }
  } else if (Array.isArray(id)) {
    selIds = selectedIds.filter(sId => id.indexOf(sId) === -1);
  } else {
    selIds = selectedIds.filter(sId => id !== sId);
  }
  return selIds;
}

export default function classroomPage(state = initialState, action = {}) {
  switch (action.type) {
    // Page load and clear actions
    case LOAD_CLASSROOM_PAGE:
      return {
        ...initialState,
        orgId: action.orgId,
        classroomId: action.classroomId
      };
    case CLEAR_CLASSROOM_PAGE:
      return {
        ...initialState
      };
    case CLEAR_USER_SELECTION:
      return {
        ...state,
        studentSearchTerm: '',
        selectedStudentIds: [],
        areStudentsSelected: false,
        teacherSearchTerm: '',
        selectedTeacherIds: [],
        areTeachersSelected: false
      };

    // Update list selection actions
    case SET_STUDENT_SELECTED: {
      const selectedStudentIds = addOrRemove(state.selectedStudentIds, action.studentId, action.isSelected);
      return {
        ...state,
        selectedStudentIds
      };
    }
    case CHECK_IF_ALL_STUDENTS_ARE_SELECTED:
      if (state.selectedStudentIds.length === action.studentIds.length) {
        return {
          ...state,
          areStudentsSelected: true
        };
      }

      return {
        ...state,
        areStudentsSelected: false
      };
    case CHECK_IF_ALL_TEACHERS_ARE_SELECTED:
      if (state.selectedTeacherIds.length === action.teacherIds.length) {
        return {
          ...state,
          areTeachersSelected: true
        };
      }

      return {
        ...state,
        areTeachersSelected: false
      };
    case SET_SELECT_ALL_STUDENTS:
      if (!state.areStudentsSelected) {
        return {
          ...state,
          selectedStudentIds: [...new Set([...state.selectedStudentIds, ...action.studentIds])],
          areStudentsSelected: true
        };
      }
      return {
        ...state,
        areStudentsSelected: false,
        selectedStudentIds: state.selectedStudentIds.filter(id => !action.studentIds.includes(id))
      };
    case SET_SELECT_ALL_TEACHERS:
      if (!state.areTeachersSelected) {
        return {
          ...state,
          selectedTeacherIds: action.teacherIds,
          areTeachersSelected: true
        };
      }
      return {
        ...state,
        areTeachersSelected: false,
        selectedTeacherIds: []
      };
    case SET_ALL_STUDENTS_ARE_SELECTED:
      return {
        ...state,
        areStudentsSelected: action.areAllStudentsSelected
      };
    case SET_ALL_TEACHERS_ARE_SELECTED:
      return {
        ...state,
        areTeachersSelected: action.areAllTeachersSelected
      };
    case SET_TEACHER_SELECTED: {
      const selectedTeacherIds = addOrRemove(state.selectedTeacherIds, action.teacherId, action.isSelected);
      return {
        ...state,
        selectedTeacherIds
      };
    }
    case REMOVE_SELECTED_STUDENT_IDS:
      return {
        ...state,
        selectedStudentIds: state.selectedStudentIds.filter(id => !action.idsToRemove.includes(id))
      };
    case REMOVE_SELECTED_TEACHER_IDS:
      return {
        ...state,
        selectedTeacherIds: state.selectedTeacherIds.filter(id => !action.idsToRemove.includes(id))
      };
    default:
      return state;
  }
}

// Page load and clear actions
export const setActiveClassId = (orgId, classroomId) => ({
  type: LOAD_CLASSROOM_PAGE,
  orgId,
  classroomId
});
export const clearClassroomPage = () => ({
  type: CLEAR_CLASSROOM_PAGE
});

// Update list selection actions
export const setStudentSelected = (studentId, isSelected) => ({
  type: SET_STUDENT_SELECTED,
  studentId,
  isSelected
});
export const setSelectAllStudents = (studentIds, areSelected) => ({
  type: SET_SELECT_ALL_STUDENTS,
  studentIds,
  areSelected
});
export const setAllStudentsSelected = areAllStudentsSelected => ({
  type: SET_ALL_STUDENTS_ARE_SELECTED,
  areAllStudentsSelected
});
export const setAllTeachersSelected = areAllTeachersSelected => ({
  type: SET_ALL_TEACHERS_ARE_SELECTED,
  areAllTeachersSelected
});

export const checkIfAllStudentsAreSelected = studentIds => ({
  type: CHECK_IF_ALL_STUDENTS_ARE_SELECTED,
  studentIds
});
export const checkIfAllTeachersAreSelected = teacherIds => ({
  type: CHECK_IF_ALL_TEACHERS_ARE_SELECTED,
  teacherIds
});
export const setSelectAllTeachers = (teacherIds, areSelected) => ({
  type: SET_SELECT_ALL_TEACHERS,
  teacherIds,
  areSelected
});
export const setTeacherSelected = (teacherId, isSelected) => ({
  type: SET_TEACHER_SELECTED,
  teacherId,
  isSelected
});

export const removeSelectedStudentIds = idsToRemove => ({
  type: REMOVE_SELECTED_STUDENT_IDS,
  idsToRemove
});
export const removeSelectedTeacherIds = idsToRemove => ({
  type: REMOVE_SELECTED_TEACHER_IDS,
  idsToRemove
});

export const clearSelectedUser = () => ({
  type: CLEAR_USER_SELECTION
});
