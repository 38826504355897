import * as t from '../actionTypes';

export const generateOrgUniqueIdRequest = () => ({
  type: t.GENERATE_ORG_UNIQUE_ID_REQUEST
});

export const generateOrgUniqueIdSuccess = id => ({
  type: t.GENERATE_ORG_UNIQUE_ID_SUCCESS,
  payload: id
});

export const generateOrgUniqueIdFailure = reason => ({
  type: t.GENERATE_ORG_UNIQUE_ID_FAILURE,
  payload: { reason }
});
