import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { HUB_SUPPORT_REGISTER_ORGANISATION_URL } from '@oup/shared-node-browser/constants';
import { projectedSize } from '@oup/shared-node-browser/org';
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import TextInput from '@oup/shared-front-end/src/components/TextInput';
import Select from '@oup/shared-front-end/src/components/Select';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup';
import { ICON_ORG_SMALL, ICON_ORG_LARGE } from '@oup/shared-front-end/src/svg/oup/index';
// Services
import Toggle from '@oup/shared-front-end/src/components/Toggle/Toggle';
import withLocalizedContent from '../../language/withLocalizedContent';
import { featureIsEnabled } from '../../globals/envSettings';
import { optInLti, isHubMode } from '../../utils/platform';
// Components
import InformationBanner from '../InformationBanner/InformationBanner';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
// Styles
import styles from './RegisterOrg.scss';

function RegisterOrg({
  localizedContent: { registerOrgComponent: content },
  formData,
  countries = {},
  errors = {},
  onSubmit = () => {},
  onChange = () => {},
  formFields = {}
}) {
  const filteredFormFields = useMemo(() => {
    const { PROJECTED_SIZE, IS_LMS_LTI_TOOL, ...rest } = formFields;
    return rest;
  }, []);

  const isAnyFieldEmpty = Object.values(filteredFormFields).some(field => !formData[field]);
  const isSubmitDisabled = isAnyFieldEmpty || Object.values(errors).some(value => value === true);

  const getFieldState = fieldName => {
    if (errors[fieldName]) {
      return 'invalid';
    }
    if (errors[fieldName] === false) {
      return 'valid';
    }
    return 'default';
  };

  const getForm = () => (
    <div className={styles.fieldContainer}>
      <TextInput
        id={formFields.NAME}
        label={content.name_label}
        placeholder={content.name_placeholder}
        name={formFields.NAME}
        value={formData.name}
        onChange={onChange}
        state={getFieldState(formFields.NAME)}
        validationMessage={getFieldState(formFields.NAME) === 'invalid' ? content.name_error : ''}
      />

      <div className={styles.fieldContainer}>
        <Select
          id={formFields.COUNTRY_CODE}
          name={formFields.COUNTRY_CODE}
          label={content.country_label}
          value={formData.countryCode}
          options={[
            { value: '', text: content.country_default_option },
            ...Object.entries(countries).map(([value, text]) => ({ text, value }))
          ]}
          onChange={onChange}
          state={getFieldState(formFields.COUNTRY_CODE)}
          validationMessage={getFieldState(formFields.COUNTRY_CODE) === 'invalid' ? content.country_region_error : ''}
        />
      </div>

      <div className={styles.fieldContainer}>
        <TextInput
          id={formFields.PRIMARY_EMAIL}
          name={formFields.PRIMARY_EMAIL}
          label={content.organization_primary_email_address}
          subLabel={content.primary_email_description_2}
          placeholder={content.primary_email_placeholder}
          value={formData.primaryEmail}
          onChange={onChange}
          state={getFieldState(formFields.PRIMARY_EMAIL)}
          validationMessage={getFieldState(formFields.PRIMARY_EMAIL) === 'invalid' ? content.primary_email_error : ''}
        />
      </div>

      {!optInLti() ? (
        <>
          <div className={`${styles.fieldContainer} ${styles.radioButtonContainer}`}>
            <p>{content.organization_size}</p>
            <p>{content.organization_size_subtitle}</p>
            <RadioButtonGroup
              variant="outline"
              name={formFields.PROJECTED_SIZE}
              legend={content.organization_size}
              checkedValue={[formData[formFields.PROJECTED_SIZE]]}
              radioGroup={[
                {
                  id: 'projectedSizeRegular',
                  label: content.organization_size_option_1,
                  value: projectedSize.REGULAR,
                  image: <ICON_ORG_SMALL className={styles.RadiobuttonImage} aria-hidden="true" />,
                  onChange
                },
                {
                  id: 'projectedSizeLarge',
                  label: content.organization_size_option_2,
                  value: projectedSize.LARGE,
                  image: <ICON_ORG_LARGE className={styles.RadiobuttonImage} aria-hidden="true" />,
                  onChange
                }
              ]}
            />
          </div>
          {formData.projectedSize === projectedSize.LARGE ? (
            <div className={styles.infoBannerContainer}>
              <InformationBanner>
                <div className={styles.messageContainer}>
                  <p>{content.large_org_info}</p>
                  <TextLink to={content.org_limit_support_link} target="_blank">
                    {content.learn_more_about_limits}
                  </TextLink>
                </div>
              </InformationBanner>
            </div>
          ) : null}
        </>
      ) : null}

      {featureIsEnabled('hub-self-service') && optInLti() && isHubMode() ? (
        <div className={`${styles.fieldContainer} ${styles.lmsIntegrationContainer}`}>
          <p>{content.lms_integration}</p>
          <p id="lms-toggle-description">{content.lms_lti_tool_description}</p>
          <ValidationStatus forId="isLmsLtiTool" isActive={errors.isLmsLtiTool} className={styles.validationStatus}>
            <label htmlFor="useCompletedOnly" className={styles.toggleLabel}>
              <Toggle
                name={formFields.IS_LMS_LTI_TOOL}
                toggled={!!formData.isLmsLtiTool}
                onChange={value => onChange({ target: { value, name: formFields.IS_LMS_LTI_TOOL } })}
              />
              {content.lms_lti_tool_agreement}
            </label>
          </ValidationStatus>
        </div>
      ) : null}
    </div>
  );

  return (
    <div className={styles.registerOrgContainer}>
      <div className={styles.registerOrgContentContainer}>
        <div>
          <div className={styles.pageHeading}>
            <h1 className={styles.title}>{content.tell_us_about_your_org}</h1>
            <p>{content.create_org_subtitle}</p>
          </div>
          <div>
            <div>{getForm()}</div>
          </div>
        </div>
        <div className={styles.helpContainer}>
          <div className={styles.asideContainer}>
            <h3>{content.aside_heading}</h3>
            <p>{content.aside_content}</p>
            <TextLink to={HUB_SUPPORT_REGISTER_ORGANISATION_URL} target="_blank">
              {content.aside_link_label}
            </TextLink>
          </div>
        </div>
      </div>
      <div className={styles.submitButtonContainer}>
        <Button variant="filled" text={content.submit_button} disabled={isSubmitDisabled} onClick={onSubmit} />
      </div>
    </div>
  );
}

RegisterOrg.propTypes = {
  localizedContent: PropTypes.object,
  formData: PropTypes.object,
  countries: PropTypes.object,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  formFields: PropTypes.shape({
    NAME: PropTypes.string,
    COUNTRY_CODE: PropTypes.string,
    PRIMARY_EMAIL: PropTypes.string,
    PROJECTED_SIZE: PropTypes.string,
    IS_LMS_LTI_TOOL: PropTypes.bool
  })
};

export default withLocalizedContent('registerOrgComponent')(RegisterOrg);
