import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import itemStyle from '../StudentProgress/StudentProgressItem.scss';

import withLocalizedContent from '../../language/withLocalizedContent';
import { format } from '../../utils/number';
import RelativeDate from '../RelativeDate/RelativeDate';
import Button from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

const RATING_ICONS = {
  good: GLYPHS.ICON_POSITIVE_SMILE,
  ok: GLYPHS.ICON_NEUTRAL_SMILE,
  bad: GLYPHS.ICON_NEGATIVE_SMILE
};

class StudentProgressBookReadItem extends Component {
  onOpenQuiz = () => {
    const { openQuiz, item } = this.props;
    return openQuiz && openQuiz('quiz', item.quiz.id);
  };

  static renderBookCover(item) {
    return (
      <div className={itemStyle.cover}>
        {item.book.coaching_book && <SVGIcon className={itemStyle.coachingBook} glyph={GLYPHS.ICON_BUDDY_BOOK} />}
        <img className={itemStyle.bookCover} src={item.book.cover_url} alt="" />
      </div>
    );
  }

  renderBookInfo(item) {
    const {
      localizedContent: { studentProgressBookReadItemComponent: content }
    } = this.props;

    return (
      <div className={itemStyle.info}>
        <p className={itemStyle.lozenge}>{item.book.level_name}</p>
        <h2>{item.book.title}</h2>
        {item.book.author && (
          // eslint-disable-next-line no-template-curly-in-string
          <p className={itemStyle.lead}>{(content.author || '').replace('${author}', item.book.author)}</p>
        )}
      </div>
    );
  }

  renderResults(item) {
    const {
      localizedContent: { studentProgressBookReadItemComponent: content }
    } = this.props;
    const latestResult = item.attempts[0];
    const previousResult = item.attempts[1];

    return (
      <table className={itemStyle.results}>
        <tbody>
          <tr>
            <td className={itemStyle.noWrap}>{content.quiz_result}</td>
            <td className={itemStyle.fullWidth}>
              <strong>{latestResult.result}%</strong>
            </td>
          </tr>
          <tr>
            <td className={itemStyle.noWrap}>{content.quiz_date}</td>
            <td className={itemStyle.fullWidth}>
              <strong>
                <RelativeDate date={latestResult.date} />
              </strong>
            </td>
          </tr>
          <tr>
            <td className={itemStyle.noWrap}>{content.attempt}</td>
            <td className={itemStyle.fullWidth}>
              <strong>{format(latestResult.attempt, '0o')}</strong>&nbsp;
              {/* eslint-disable-next-line no-template-curly-in-string */}
              {previousResult && (content.previous_result || '').replace('${result}', `${previousResult.result}`)}
            </td>
          </tr>
          {latestResult.rating && (
            <tr>
              <td className={itemStyle.noWrap}>{content.rating}</td>
              <td className={classnames(itemStyle.fullWidth, itemStyle.noPadding)}>
                <SVGIcon
                  glyphViewBox="5 5 30 30"
                  className={itemStyle.ratingIcon}
                  glyph={RATING_ICONS[latestResult.rating]}
                />
              </td>
            </tr>
          )}
          <tr>
            <td colSpan="2" className={itemStyle.actionsCell}>
              {item.quiz && <Button type="SECONDARY" text="Go to quiz" onClick={this.onOpenQuiz} />}
              {/* <Button type="SECONDARY" text="Open-ended quiz question" /> */}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const { item } = this.props;

    return (
      <div className={itemStyle.itemContainer}>
        <div className={classnames('row', itemStyle.content)}>
          <div className="col sm2">{StudentProgressBookReadItem.renderBookCover(item)}</div>
          <div className="col sm3">{this.renderBookInfo(item)}</div>
          <div className="col sm7">{this.renderResults(item)}</div>
        </div>
      </div>
    );
  }
}

StudentProgressBookReadItem.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  openQuiz: PropTypes.func.isRequired
};

export default withLocalizedContent('studentProgressBookReadItemComponent')(StudentProgressBookReadItem);
