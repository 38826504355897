import * as t from '../actionTypes';

const initialState = {
  token: '',
  submitting: false,
  success: false,
  failure: false,
  bot: false,
  errors: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.RECAPTCHA_SET_SITE_KEY:
      return {
        ...state,
        siteKey: payload
      };
    case t.RECAPTCHA_SET_TOKEN:
      return {
        ...state,
        token: payload
      };
    case t.RECAPTCHA_SUBMIT:
      return {
        ...state,
        submitting: true
      };
    case t.RECAPTCHA_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true
      };
    case t.RECAPTCHA_FAILURE:
      return {
        ...state,
        submitting: false,
        failure: true,
        errors: payload
      };
    case t.RECAPTCHA_RESET:
      return {
        ...state,
        submitting: false,
        success: false,
        failure: false,
        bot: false
      };
    case t.RECAPTCHA_BOT:
      return {
        ...state,
        submitting: false,
        success: false,
        bot: true
      };
    default:
      return state;
  }
};
