import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { validColors } from '../../globals/colors';
import { fgClass } from '../../utils/colorClassNames';
import styles from './Tabs.scss';
import SVGIcon, { validGlyphs, GLYPHS } from '../SVGIcon/SVGIcon';
import HorizontalScrollContainer from '../HorizontalScrollContainer/HorizontalScrollContainer';
import { isCesMode } from '../../utils/platform';
import { isAdminOrSupport as isAdminOrSupportSelector } from '../../redux/selectors/authorization/user';
import { isLocal } from '../../globals/envSettings';

function Tabs({ current, items, backgroundColor, isAdminOrSupport, customClasses, truncate }) {
  const getButtonClass = index => {
    if (!customClasses?.buttons) {
      return index === current ? styles.selected : styles.unselected;
    }
    return index === current ? customClasses.buttons.selected : customClasses.buttons.unselected;
  };
  return (
    <div className={customClasses?.container ? customClasses.container : ''}>
      <HorizontalScrollContainer
        className={
          !customClasses?.tabsContainer
            ? 'scroll-container'
            : classnames('scroll-container', customClasses.tabsContainer)
        }
        backgroundColor={backgroundColor}
      >
        <div className="grid">
          <div className={!customClasses?.tabsContainerRow ? 'row' : classnames('row', customClasses.tabsContainerRow)}>
            <div className={classnames(styles.tabList, 'col')}>
              <ul className={customClasses?.tabsContainerTabList}>
                {!isCesMode() && isAdminOrSupport
                  ? null
                  : items.map(
                      (
                        {
                          action,
                          glyphIcon,
                          glyphColor,
                          tabText,
                          tabNumber,
                          tabTextCaption,
                          isContentLoading,
                          isContentLoaded
                        },
                        index
                      ) => (
                        <li
                          key={index}
                          id={`tab${index}`}
                          aria-controls={`panel${index}`}
                          aria-current={index === current ? 'true' : 'false'}
                        >
                          <aside
                            role="presentation"
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            tabIndex="0"
                            id={`tabButton${index}`}
                            className={
                              glyphColor
                                ? classnames(getButtonClass(index), {
                                    [styles.truncate]: truncate
                                  })
                                : classnames(getButtonClass(index), styles.onlyTabTitle)
                            }
                            onClick={() => {
                              if (!isLocal()) {
                                window.newrelic.interaction().actionText('Open tab');
                              }
                              action();
                            }}
                            onKeyUp={e => {
                              if (e.key === ' ' || e.key === 'Enter') {
                                if (!isLocal()) {
                                  window.newrelic.interaction().actionText('Open tab');
                                }
                                action();
                              }
                            }}
                          >
                            {glyphIcon ? (
                              <SVGIcon
                                glyph={glyphIcon}
                                className={classnames(styles.glyphFormating, {
                                  [fgClass(glyphColor)]: glyphColor
                                })}
                              />
                            ) : null}
                            {tabText}
                            {!!tabNumber && <span className={styles.numberIcon}>{tabNumber}</span>}
                            {isContentLoading || isContentLoaded ? (
                              <span className={styles.tabIcon}>
                                <SVGIcon
                                  glyph={isContentLoading ? GLYPHS.ICON_LOADING : GLYPHS.ICON_CHECK_CIRCLE}
                                  className={styles.tabIconSvg}
                                />
                              </span>
                            ) : null}
                            {tabTextCaption ? <div className={styles.secondLine}>{tabTextCaption}</div> : null}
                          </aside>
                        </li>
                      )
                    )}
              </ul>
            </div>
          </div>
        </div>
      </HorizontalScrollContainer>
      <div
        className={
          !customClasses?.tabPanelContainer
            ? classnames(styles.tabPanelContainer, {
                [styles.roundAllCorners]: current !== 0
              })
            : classnames(styles.tabPanelContainer, customClasses.tabPanelContainer, {
                [styles.roundAllCorners]: current !== 0
              })
        }
      >
        {!isCesMode() && isAdminOrSupport
          ? null
          : items.map(({ tabText, panelContent, skipA11yPanelHeading }, index) => (
              <div
                key={index}
                role="tabpanel"
                id={`panel${index}`}
                aria-labelledby={`tab${index}`}
                aria-hidden={index === current ? 'false' : 'true'}
                className={classnames(styles.tabPanel, {
                  [styles.selected]: index === current
                })}
              >
                {!skipA11yPanelHeading && <h2 className="a11y-hide">{tabText}</h2>}
                {index === current && panelContent}
              </div>
            ))}
      </div>
    </div>
  );
}

Tabs.propTypes = {
  current: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** The handler to call when tab is clicked */
      action: PropTypes.func.isRequired,
      /** Content to displayed in the tab label for this item */
      tabText: PropTypes.string.isRequired,
      /** A second line of content to displayed in the tab label */
      tabTextCaption: PropTypes.node,
      /** The actual content for the item to be displayed under the tab/carousel controls */
      panelContent: PropTypes.element.isRequired,
      /** A glyph icon to be displayed */
      glyphIcon: PropTypes.oneOf(validGlyphs),
      /** By default, each panel will be given a <h2 className="a11y-hide">. Set to true if content already has heading */
      skipA11yPanelHeading: PropTypes.bool,
      tabNumber: PropTypes.number
    })
  ).isRequired,
  /** The color to be used for the background of the tab list. */
  backgroundColor: PropTypes.oneOf(validColors),
  customClasses: PropTypes.object,
  isAdminOrSupport: PropTypes.bool.isRequired,
  /** The styling will change if this is set  to true, the tab buttons will be smaller and title will be truncated */
  truncate: PropTypes.bool
};

export default compose(
  connect(state => ({
    isAdminOrSupport: isAdminOrSupportSelector(state)
  }))
)(Tabs);
