import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import styles from './AssetsPage.scss';
import { postContentPackageRequest } from '../../../../redux/actions/assetsPage';
import Card from '../../../../components/Card/Card';
import TextInputLabel from '../../../../components/TextInputLabel/TextInputLabel';
import FileUploadInput from '../../../../components/FileUploadInput/FileUploadInput';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import PopoutPanelIconHeading from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import { STATUS_TYPE } from '../../../../globals/appConstants';
import { CONTENT_PACKAGE_TYPES } from '../../../../globals/cptConstants';

function AssetsPage({
  localizedContent: { assetsPage: content },
  postContentPackageRequestData,
  loading,
  success,
  successMessage,
  errorMessage,
  error
}) {
  const [zipFile, setZipFile] = useState(null);
  const supportedFileTypes = ['.zip'];

  const handleFileChange = selectedFile => setZipFile(selectedFile);

  const handleSubmit = type => {
    const formData = new FormData();
    if (zipFile) {
      let packageType = type;
      if (type === CONTENT_PACKAGE_TYPES.APPS && zipFile.name.toLowerCase().includes(CONTENT_PACKAGE_TYPES.ONECLICK)) {
        packageType = CONTENT_PACKAGE_TYPES.ONECLICK;
      }

      formData.append('file', zipFile);
      formData.append('fileName', zipFile.name);
      formData.append('packageType', packageType);

      postContentPackageRequestData(formData);
      setZipFile(null);
    }
  };

  return (
    <PageWrapper>
      <Helmet title={content.helmet_title} />
      <h1>{content.title}</h1>
      <span className={styles.subheading}>{content.subtitle}</span>
      <div className={styles.linkContainer}>
        <div className={styles.uploadInputCardContainer}>
          <Card showHeadingLabel={false}>
            <TextInputLabel id="fileZipUploadInputLabel" label={content.fileInputTextLabel} />
            <FileUploadInput
              id="fileZipUploadInput"
              label={content.upload_label}
              buttonText={content.upload_button_text}
              supportedFileTypes={supportedFileTypes}
              handleFile={file => handleFileChange(file)}
            />
          </Card>
        </div>
        <Button
          type={buttonTypes.PRIMARY}
          text={content.upload_standard_content_package_text}
          fullWidth={false}
          customClassName={styles.button}
          disabled={!zipFile}
          onClick={() => handleSubmit(CONTENT_PACKAGE_TYPES.STANDARD)}
        />
        <Button
          type={buttonTypes.PRIMARY}
          text={content.upload_games_or_apps_package_text}
          fullWidth={false}
          disabled={!zipFile}
          customClassName={styles.button}
          onClick={() => handleSubmit(CONTENT_PACKAGE_TYPES.APPS)}
        />
        {loading && <LoadingSpinner text={content.upload_in_progress_text} />}
        {success && (
          <>
            <PopoutPanelIconHeading type={STATUS_TYPE.VALID} />
            <p className="text-center gin-bot3">
              {(!successMessage.warnings || (successMessage.warnings && successMessage.warnings.length === 0)) && (
                <h2>{successMessage.message}</h2>
              )}
              {successMessage.warnings && successMessage.warnings.length > 0 && (
                <>
                  <h2>{successMessage.message}</h2>
                  {successMessage.warnings.map(item => (
                    <div className={styles.warning} key={item}>
                      {item}
                    </div>
                  ))}
                </>
              )}
            </p>
          </>
        )}
        {error && <PopoutPanelIconHeading type={STATUS_TYPE.ERROR} title={errorMessage} />}
      </div>
    </PageWrapper>
  );
}

AssetsPage.propTypes = {
  localizedContent: PropTypes.object,
  postContentPackageRequestData: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  successMessage: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string
};

const mapStateToProps = state => {
  const {
    assetsPage: { loading, success, successMessage, error, errorMessage }
  } = state;
  return { loading, success, successMessage, error, errorMessage };
};

const mapDispatchToProps = {
  postContentPackageRequestData: postContentPackageRequest
};

export default compose(withLocalizedContent('assetsPage'), connect(mapStateToProps, mapDispatchToProps))(AssetsPage);
