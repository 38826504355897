import React from 'react';
import DialsSkeletonLoader from './DialsSkeletonLoader';
import OrbSubSectionSkeleton from './OrbSubSectionSkeleton';

function OrbSchoolReportLoader() {
  return (
    <div>
      <DialsSkeletonLoader />
      <OrbSubSectionSkeleton />
    </div>
  );
}

export default OrbSchoolReportLoader;
