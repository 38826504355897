import { createSelector } from 'reselect';

export const getSelectedResources = (state, titleId) => state.teacherResources.resources[titleId] || {};

export default createSelector(getSelectedResources, ({ resources = [] }) =>
  resources.reduce((allResources, resource) => {
    const categoryIds = resource.categories.reduce(
      (categories, { categoryId, parentId = null }) => [...categories, categoryId, ...(parentId ? [parentId] : [])],
      []
    );

    return [
      ...allResources,
      {
        ...resource,
        tags: [resource.unitUuid, ...categoryIds]
      }
    ];
  }, [])
);
