import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import ControlledForm from '../ControlledForm/ControlledForm';
import ConnectedPersonRepresentation from '../PersonRepresentation/ConnectedPersonRepresentation';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading, { types as iconHeadingTypes } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { GLYPHS, SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import { featureIsEnabled } from '../../globals/envSettings';
import { isOrbMode, isHubMode } from '../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';

class UserArchiveReviewPane extends Component {
  _handleNotifyByEmailChange = () => {
    const { notifyWithEmailValue, onNotifyWithEmailChange } = this.props;

    onNotifyWithEmailChange(!notifyWithEmailValue);
  };

  render() {
    const {
      orgId,
      userIds,
      userType,
      notifyWithEmailValue,
      hideNotifyOption = false,
      preFlightCheckResults,
      foundLastOupAdmin,
      isPrimarySchool,
      foundLastOrgAdmin,
      onSubmit,
      localizedContent: { userArchivePanel: content }
    } = this.props;

    const title = content.review_heading_title.replace('{userType}', userType);
    const subtitle = (featureIsEnabled('remove-restore-archived-manageduser') && isPrimarySchool
      ? content.review_heading_subtitle_short
      : content.review_heading_subtitle
    ).replace('{userType}', userType);

    const toggleRender = featureIsEnabled('class-toggle-remove') ? !isOrbMode() : true;
    return (
      <ControlledForm>
        <ScrollContainer
          headerContent={
            <div className="row">
              <div className="col">
                {isHubMode() ? (
                  <PopoutPanelIllustrationHeading
                    title={
                      /* eslint-disable no-nested-ternary */
                      foundLastOrgAdmin
                        ? content.review_error_heading_title
                        : foundLastOupAdmin
                        ? content.review_error_oup_admin_heading_title
                        : title
                    }
                    subtitle={foundLastOrgAdmin || foundLastOupAdmin ? '' : subtitle}
                    illustrationSrc={
                      foundLastOrgAdmin || foundLastOupAdmin
                        ? HubIllustrationConstants.ERROR
                        : HubIllustrationConstants.WARNING
                    }
                    illustrationAltText={
                      foundLastOrgAdmin || foundLastOupAdmin
                        ? HubIllustrationAltText.ERROR
                        : HubIllustrationAltText.WARNING
                    }
                  />
                ) : (
                  <PopoutPanelIconHeading
                    type={foundLastOrgAdmin || foundLastOupAdmin ? iconHeadingTypes.ERROR : iconHeadingTypes.WARNING}
                    title={
                      /* eslint-disable no-nested-ternary */
                      foundLastOrgAdmin
                        ? content.review_error_heading_title
                        : foundLastOupAdmin
                        ? content.review_error_oup_admin_heading_title
                        : title
                    }
                    subtitle={foundLastOrgAdmin || foundLastOupAdmin ? '' : subtitle}
                  />
                )}
              </div>
            </div>
          }
          footerContent={
            userType === content.students_text ? (
              <PopoutActionFooter
                primaryButtonText={content.review_primary_button_text}
                primaryButtonAction={onSubmit}
                {...(hideNotifyOption || !toggleRender
                  ? {}
                  : {
                      toggleSwitchId: 'notifyWithEmail',
                      toggleSwitchLabel: content.review_notification_switch_label,
                      toggleSwitchValue: notifyWithEmailValue,
                      toggleSwitchOnChange: this._handleNotifyByEmailChange
                    })}
              />
            ) : (
              <PopoutActionFooter
                primaryButtonText={content.review_primary_button_text}
                primaryButtonAction={onSubmit}
                primaryButtonDisabled={foundLastOrgAdmin || foundLastOupAdmin}
              />
            )
          }
        >
          <div className="gin-bot2">
            {userIds
              ? userIds.map(userId => {
                  let status = null;
                  let statusText = null;
                  let secondaryThumbnailGlyph = null;
                  const secondaryThumbnailSize = thumbnailSizes.MEDIUM;

                  if (
                    userType === 'staff' &&
                    preFlightCheckResults &&
                    preFlightCheckResults[userId] &&
                    preFlightCheckResults[userId].isActiveTeacher
                  ) {
                    statusText = content.review_active_teacher_notification;
                    status = 'WARNING';
                    secondaryThumbnailGlyph = GLYPHS.ICON_WARNING_CIRCLE;
                  }

                  if (
                    userType === 'staff' &&
                    preFlightCheckResults &&
                    preFlightCheckResults[userId] &&
                    preFlightCheckResults[userId].isOnlyOupAdmin
                  ) {
                    statusText = content.review_error_oup_admin_heading_title;
                    status = 'ERROR';
                    secondaryThumbnailGlyph = GLYPHS.ICON_ERROR_CIRCLE;
                  }

                  if (
                    userType === 'staff' &&
                    preFlightCheckResults &&
                    preFlightCheckResults[userId] &&
                    preFlightCheckResults[userId].isOnlyOrgAdmin
                  ) {
                    statusText = content.review_error_heading_title;
                    status = 'ERROR';
                    secondaryThumbnailGlyph = GLYPHS.ICON_ERROR_CIRCLE;
                  }

                  return (
                    <div className="pad2" style={{ borderBottom: '1px solid colors.BORDER' }} key={userId}>
                      <ConnectedPersonRepresentation
                        id={`classroom-${userId}`}
                        thumbnailSize={thumbnailSizes.MEDIUM}
                        secondaryThumbnailSize={secondaryThumbnailSize}
                        secondaryThumbnailGlyph={secondaryThumbnailGlyph}
                        entityId={userId}
                        statusText={statusText}
                        status={status}
                        orgId={orgId}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </ScrollContainer>
      </ControlledForm>
    );
  }
}

UserArchiveReviewPane.propTypes = {
  orgId: PropTypes.string.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  userType: PropTypes.string.isRequired,
  notifyWithEmailValue: PropTypes.bool.isRequired,
  hideNotifyOption: PropTypes.bool,
  preFlightCheckResults: PropTypes.object.isRequired,
  foundLastOupAdmin: PropTypes.bool.isRequired,
  foundLastOrgAdmin: PropTypes.bool.isRequired,
  isPrimarySchool: PropTypes.bool.isRequired,
  onNotifyWithEmailChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('userArchivePanel')(UserArchiveReviewPane);
