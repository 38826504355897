import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './HubFilterOptions.scss';

function OptionIcon({ text, labelHidden, inputType, checked, programmaticId }) {
  let radioType = false;
  let glyph = checked ? GLYPHS.ICON_CHECKBOX_CHECKED : GLYPHS.ICON_CHECKBOX_UNCHECKED;
  if (inputType === 'radio') {
    glyph = checked ? GLYPHS.ICON_RADIO_CHECKED : GLYPHS.ICON_RADIO_UNCHECKED;
    radioType = true;
  }
  return (
    <label className={classnames(styles.label, { [styles['a11-hide-label']]: labelHidden })} htmlFor={programmaticId}>
      <SVGIcon
        className={classnames(
          styles.svg,
          { [styles.radioType]: radioType },
          { [styles.checkboxType]: inputType === 'checkbox' }
        )}
        glyph={glyph}
      />
      <span className={classnames(styles.text, { [styles['a11-hide-text']]: labelHidden })}>{text}</span>
    </label>
  );
}

export default OptionIcon;

OptionIcon.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  labelHidden: PropTypes.bool,
  checked: PropTypes.bool,
  inputType: PropTypes.string,
  programmaticId: PropTypes.string
};
