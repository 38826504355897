import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import GradedReadersLearnerView from '../../components/GradedReadersLearnerView/GradedReadersLearnerView';
import actions from '../../redux/actions';
import { loadSavedSettings, storeSavedSettings } from '../../redux/reducers/savedSettings';
import flipSortDirection from '../../components/GradebookTable/flipSortDirection';

class GradedReadersLearnerPage extends Component {
  componentDidMount() {
    const { learnerId, classroomId, loadSavedSettingsAction, gradedReadersRequestAction, params } = this.props;
    console.log('[GradedReadersLearnerPage] Initialising page with IDs: ', classroomId, learnerId);
    loadSavedSettingsAction();
    gradedReadersRequestAction('learner', params);
  }

  componentDidUpdate(prevProps) {
    const { learnerId, gradedReadersRequestAction, params } = this.props;
    if (learnerId !== prevProps.learnerId) {
      gradedReadersRequestAction('learner', params);
    }
  }

  componentWillUnmount() {
    const { gradedReadersLoadingAction } = this.props;
    gradedReadersLoadingAction();
  }

  /**
   * Sets both state and the URL serarch query string with the same values
   *
   * @param {Object} params The object to set
   */
  _setStateAndQueryString = key => {
    const newState = {
      sortKey: key,
      sortDirection: flipSortDirection(key)
    };
    // eslint-disable-next-line no-restricted-globals
    history.pushState(newState, 'Sorting', `?${qs.stringify(newState)}${window.location.hash}`);
    this.setState(newState);
  };

  /**
   * Switches the spacing config for gradebook.
   *
   * @param {Object} target The target spacing button
   */
  switchSpacing = ({ target }) => {
    const { storeSavedSettingsAction } = this.props;
    storeSavedSettingsAction({ tableSpacing: target.id });
  };

  render() {
    const { loading, data, learners, tableSpacing, learnerId, classData = {} } = this.props;
    const { sortKey = 'none', sortDirection = 'none' } = qs.parse(window.location.search);

    return data && Object.keys(data).length !== 0 ? (
      <GradedReadersLearnerView
        switchSpacing={this.switchSpacing}
        tableSpacing={tableSpacing}
        learnerId={learnerId}
        tableLoading={loading}
        learners={learners}
        data={data}
        classData={classData}
        sortOnChange={(key, direction) => this._setStateAndQueryString(key, direction)}
        sortKey={sortKey}
        sortDirection={sortDirection}
      />
    ) : null;
  }
}

GradedReadersLearnerPage.propTypes = {
  params: PropTypes.object.isRequired,
  classroomId: PropTypes.string.isRequired,
  learnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  tableSpacing: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  learners: PropTypes.object.isRequired,
  loadSavedSettingsAction: PropTypes.func.isRequired,
  storeSavedSettingsAction: PropTypes.func.isRequired,
  gradedReadersRequestAction: PropTypes.func.isRequired,
  gradedReadersLoadingAction: PropTypes.func.isRequired,
  classData: PropTypes.object.isRequired
};

export default connect(
  (state, { params }) => ({
    classroomId: params.classroomId,
    learnerId: params.itemId || false,
    tableSpacing: state.savedSettings.settings.tableSpacing,
    loading: state.results.gradedReaders.loading,
    learners: state.results.gradedReaders.group || false,
    data: state.results.gradedReaders.learner,
    classData: state.classrooms.data[params.classroomId].userDetails
  }),
  {
    loadSavedSettingsAction: loadSavedSettings,
    storeSavedSettingsAction: storeSavedSettings,
    gradedReadersRequestAction: actions.gradedReadersResultsRequest,
    gradedReadersLoadingAction: actions.gradedReadersResultsLoading
  }
)(GradedReadersLearnerPage);
