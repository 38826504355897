import { call, put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes.js';
import {
  getContentSelectorStatusSuccess,
  getContentSelectorStatusFailure
} from '../../../../actions/getContentSelectorStatus.actions.js';
import getContentSelectorStatusApi from '../../../apiCalls/ngi/getContentSelectorStatus.js';

function* fetchGetContentSelectorStatus(action) {
  try {
    const response = yield call(getContentSelectorStatusApi, action.payload);
    yield put(getContentSelectorStatusSuccess(response));
  } catch (error) {
    yield put(getContentSelectorStatusFailure('Failed to determine if session was incomplete'));
  }
}

function* fetchGetContentSelectorStatusSaga() {
  yield takeLatest(t.NGI_GET_CONTENT_SELECTOR_STATUS_REQUEST, fetchGetContentSelectorStatus);
}

export default fetchGetContentSelectorStatusSaga;
