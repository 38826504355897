import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Action imports
import { setFormState } from '../../redux/reducers/data/forms.reducer.js';
import { switchOrg } from '../../redux/reducers/identity.reducer.js';

import userRoles from '../../globals/userRoles.js';

// Component imports
import Button, { buttonTypes } from '../../components/Button/Button.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';

import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';

import cmsContent from '../../utils/cmsContent.js';
import { isHubMode } from '../../utils/platform.js';

function CreateOrgConfirmation({ orgName, createFailed, buttonAction }) {
  const CMS = cmsContent.createOrganisation || {};
  let variables;

  if (createFailed) {
    variables.type = iconHeadingTypes.ERROR;
    variables.title = CMS.create_organisation_error;
    variables.subtitle = CMS.create_organisation_failure;
    variables.illustrationSrc = HubIllustrationConstants.ERROR;
    variables.illustrationAltText = HubIllustrationAltText.ERROR;
  } else {
    variables.type = iconHeadingTypes.VALID;
    variables.title = CMS.create_organisation_success;
    variables.subtitle = (CMS.create_organisation_success_role || '').replace('{organisationName}', orgName);
    variables.illustrationSrc = HubIllustrationConstants.SUCCESS;
    variables.illustrationAltText = HubIllustrationAltText.SUCCESS;
  }
  return (
    <div className="row">
      <div className="col sm3" />
      <div className="col sm6">
        {isHubMode() ? (
          <PopoutPanelIllustrationHeading
            type={variables.type}
            title={variables.title}
            subtitle={variables.subtitle}
            illustrationSrc={variables.illustrationSrc}
            illustrationAltText={variables.illustrationAltText}
          />
        ) : (
          <PopoutPanelIconHeading type={variables.type} title={variables.title} subtitle={variables.subtitle} />
        )}

        <div className="gin-top2 text-center">
          <Button
            id={createFailed ? 'createOrgFailedBtn' : 'createOrgSucceededBtn'}
            text={
              createFailed ? CMS.button_back_text : (CMS.button_done_text || '').replace('{organisationName}', orgName)
            }
            type={buttonTypes.PRIMARY}
            preventDefault={false}
            onClick={buttonAction}
          />
        </div>
      </div>
    </div>
  );
}

CreateOrgConfirmation.propTypes = {
  orgName: PropTypes.string.isRequired,
  createFailed: PropTypes.bool,

  buttonAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    // Connect values
    orgName: state.fields['orgCreate.orgName'].value,
    createFailed: state.forms.orgCreate.failed,
    groupId: (state.forms.orgCreate.data || {}).groupId
  }),
  dispatch => ({
    buttonAction: (createFailed, groupId) => {
      if (createFailed) {
        dispatch(setFormState('orgCreate', 'EDITING'));
      } else {
        dispatch(switchOrg(groupId, userRoles.ORG_ADMIN));
      }
    }
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    dispatchProps,
    buttonAction: () => dispatchProps.buttonAction(stateProps.createFailed, stateProps.groupId)
  })
)(CreateOrgConfirmation);
