import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Styles
import styles from './enrollUser.scss';

// Constants
import enrollUserConstants from './enrolUserConstants';

// Components
import Button, { buttonTypes } from '../../../../../../components/Button/Button';
import ClassroomNameInput from '../../../ClassroomsTab/panels/AddClassroom/ClassroomNameInput.js';

// Action imports
import { storeClassrooms } from '../../../../../../redux/reducers/data/classrooms.js';
import { addResultsOrgClass } from '../../../../../../redux/reducers/data/search.reducer.js';
import { setClassroomSelected } from '../../../../../../redux/reducers/organisationPage.reducer.js';

function EnrolUserAddToClassForm({
  content,
  toggleClassForm,
  classroomNameValue,
  classroomNameIsInvalid,
  classroomNamePending,
  storeClassroomsAction,
  addResultsOrgClassAction,
  editTempNewClassId,
  setClassroomNameEditAction,
  addSelectedClassIdAction,
  orgId,
  isMaxSelectionExceed,
  handleTempNewClassAdded
}) {
  const _enrolUserCreateClassAction = () => {
    const tempNewClassId = editTempNewClassId || Date.now().toString();
    const tempNewClassData = {
      [tempNewClassId]: {
        amountOfLearners: 0,
        amountOfTeachers: 0,
        archived: false,
        name: classroomNameValue,
        tempNewClass: true,
        orgId
      }
    };
    storeClassroomsAction(tempNewClassData);
    addResultsOrgClassAction({
      instance: enrollUserConstants.ORG_CLASS,
      ids: tempNewClassId,
      classIds: tempNewClassData
    });
    toggleClassForm();
    setClassroomNameEditAction('', '');
    if (!isMaxSelectionExceed) {
      if (!editTempNewClassId) addSelectedClassIdAction(tempNewClassId);
    }
    handleTempNewClassAdded();
  };
  return (
    <div className={styles.enrollUserFormContainer}>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (classroomNameValue && !classroomNameIsInvalid && !classroomNamePending) _enrolUserCreateClassAction();
        }}
      >
        <ClassroomNameInput classNameLabel={content.classNameLabel} />
        <div className={styles.enrollUserAddClassButtons}>
          <Button
            type={buttonTypes.CANCEL}
            text={content.enroll_user_add_classname_cancel_button}
            onClick={toggleClassForm}
          />
          {editTempNewClassId ? (
            <Button
              onClick={_enrolUserCreateClassAction}
              type={buttonTypes.PRIMARY}
              text={content.enroll_user_add_classname_update_button}
              disabled={!classroomNameValue || classroomNameIsInvalid || classroomNamePending}
            />
          ) : (
            <Button
              onClick={_enrolUserCreateClassAction}
              type={buttonTypes.PRIMARY}
              text={content.enroll_user_add_classname_create_button}
              disabled={!classroomNameValue || classroomNameIsInvalid || classroomNamePending}
            />
          )}
        </div>
      </form>
    </div>
  );
}
EnrolUserAddToClassForm.propTypes = {
  content: PropTypes.func.isRequired,
  toggleClassForm: PropTypes.any,
  classroomNameValue: PropTypes.string.isRequired,
  classroomNameIsInvalid: PropTypes.bool.isRequired,
  classroomNamePending: PropTypes.bool.isRequired,
  storeClassroomsAction: PropTypes.func.isRequired,
  addResultsOrgClassAction: PropTypes.func.isRequired,
  editTempNewClassId: PropTypes.string,
  setClassroomNameEditAction: PropTypes.func.isRequired,
  addSelectedClassIdAction: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  isMaxSelectionExceed: PropTypes.bool.isRequired,
  handleTempNewClassAdded: PropTypes.func.isRequired
};

export default connect(
  state => ({
    classroomNameValue: state.classroomCreate.classroomNameValue,
    editTempNewClassId: state.classroomCreate.editTempNewClassId,
    classroomNameIsInvalid: state.classroomCreate.classroomNameValidationIsInvalid,
    classroomNamePending: state.classroomCreate.classroomNameValidationPending
  }),
  {
    storeClassroomsAction: storeClassrooms,
    addResultsOrgClassAction: addResultsOrgClass,
    addSelectedClassIdAction: id => setClassroomSelected(id, true)
  }
)(EnrolUserAddToClassForm);
