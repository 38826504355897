import React from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';

import AddLicenseContentPage from './AddLicenseContentPage';

function ActivationCodeBatchPage() {
  return (
    <PageWrapper>
      <Helmet title="Activation code batch" />
      <AddLicenseContentPage />
    </PageWrapper>
  );
}
export default ActivationCodeBatchPage;
