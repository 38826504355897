import React from 'react';
import PropTypes from 'prop-types';
import styles from './ArticleTable.scss';

function ArticleTable({ title: heading, thead_row: theadRow, tbody_rows: tBodyRows }) {
  return (
    <div className={styles.ArticleTable}>
      <h2>{heading}</h2>

      <table>
        <thead>
          <tr>
            {theadRow.split(',').map((cell, i) => (
              <th key={i}>{cell}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tBodyRows.map((row, r) => (
            <tr key={r}>
              {row.split(',').map((cell, i) => (
                <td key={`${r}-${i}`}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

ArticleTable.propTypes = {
  title: PropTypes.string.isRequired, // link text
  thead_row: PropTypes.string.isRequired,
  tbody_rows: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ArticleTable;
