import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'lodash';
// Redux
import { connect } from 'react-redux';
import { setOnlineTestData } from '../../redux/actions/onlineTestActions';
import { hubDownloadResource } from '../../redux/actions/hubResource';
// Utils
import withLocalizedContent from '../../language/withLocalizedContent';
import getAssessmentProductList from './Utils/getAssessmentProductList';
import { getTestPrintableResourceId } from '../../structure/HubCourseAssessments/Services/getTestData';
import downloadOrPreviewAssessment from '../../structure/HubCourseAssessments/Services/downloadOrPreviewAssessment';
// Constants
import { HubLayoutConstants, LTI_ACTIONS, HubIllustrationConstants } from '../../globals/hubConstants';
// Components
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import OnlineTestAssessmentsList from '../OnlineTestAssessmentsList/OnlineTestAssessmentsList';
import OnlineTestAssessmentSubfolder from '../OnlineTestAssessmentSubfolder/OnlineTestAssessmentSubfolder';
// Style
import styles from './OnlineTestAssessments.scss';
import HubEmptyStateRestyle from '../HubEmptyStateRestyle/HubEmptyStateRestyle';

class OnlineTestAssessments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedAssessmentId: '',
      selectedSubfolderId: ''
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen, resetPanel } = this.props;
    if (prevProps.isOpen !== isOpen && isOpen === false) {
      resetPanel();
    }
  }

  onSubfolderClick = subfolderId => {
    this.setState({ selectedSubfolderId: subfolderId });
  };

  onClickBackLink = () => {
    this.setState({ selectedSubfolderId: '' });
  };

  setExpandedAssessment = id => {
    const { expandedAssessmentId } = this.state;
    if (expandedAssessmentId === id) {
      this.setState({
        expandedAssessmentId: ''
      });
    } else {
      this.setState({
        expandedAssessmentId: id
      });
    }
  };

  getContent = assessments => {
    const {
      props: {
        courses = {},
        localizedContent: { hubGlossary: hubContent, hubSetTestOnline: setOnlineTestContent }
      },
      state: { selectedSubfolderId, expandedAssessmentId },
      setExpandedAssessment,
      onSubfolderClick,
      onClickBackLink,
      selectTest,
      downloadAssessment
    } = this;

    if (selectedSubfolderId && expandedAssessmentId) {
      const selectedAssessment = assessments[expandedAssessmentId];
      return (
        <OnlineTestAssessmentSubfolder
          assessmentData={selectedAssessment}
          folderId={selectedSubfolderId}
          course={courses[selectedAssessment.courseId]}
          onClickBackLink={onClickBackLink}
          downloadAssessment={downloadAssessment}
          content={setOnlineTestContent}
          hubContent={hubContent}
          selectTest={selectTest}
        />
      );
    }
    return (
      <OnlineTestAssessmentsList
        assessments={assessments}
        courses={courses}
        expandedAssessmentId={expandedAssessmentId}
        setExpandedAssessment={setExpandedAssessment}
        hubContent={hubContent}
        setOnlineTestContent={setOnlineTestContent}
        downloadAssessment={downloadAssessment}
        onSubfolderClick={onSubfolderClick}
        selectTest={selectTest}
      />
    );
  };

  downloadAssessment = (testData, isAdaptedTest = false) => {
    const { hubDownloadResourceAction } = this.props;
    const lorUserRole = HubLayoutConstants.ASSESSMENT_USER_ROLES.INSTRUCTOR;
    const printableResourceId = getTestPrintableResourceId(testData, isAdaptedTest);
    downloadOrPreviewAssessment(
      hubDownloadResourceAction,
      LTI_ACTIONS.DOWNLOAD,
      printableResourceId,
      isAdaptedTest,
      lorUserRole
    );
  };

  selectTest = (testId, test, productAssessmentTitle) => {
    const { next, setOnlineTestDataAction } = this.props;
    const steps = HubLayoutConstants.ONLINE_TEST_STEPS;

    next({
      page: steps.CHOOSE_STUDENTS_PAGE,
      testId
    });
    setOnlineTestDataAction({ test, productAssessmentTitle });
  };

  render() {
    const {
      props: {
        localizedContent: { hubSetTestOnline: content },
        classId,
        profileClasses,
        courses
      },
      getContent
    } = this;

    const assessmentsList = getAssessmentProductList(courses);

    return (
      <ScrollContainer
        headerContent={
          <header>
            <div>
              <h2>{content.online_test_title}</h2>
              <div>{get(profileClasses, [classId, 'name'], '')}</div>
            </div>
          </header>
        }
      >
        <div className={styles.mainContentContainer}>
          {Object.values(assessmentsList)?.length ? (
            <>{getContent(assessmentsList)}</>
          ) : (
            <HubEmptyStateRestyle
              iconSrc={HubIllustrationConstants.TEST}
              title={content.choose_test_panel_empty_state_title}
              bodyText={content.choose_test_panel_empty_state_subtitle}
            />
          )}
        </div>
      </ScrollContainer>
    );
  }
}

export default compose(
  withLocalizedContent('hubSetTestOnline', 'hubGlossary'),
  connect(
    ({ search: { profileClasses: { currentClassList = {} } = {} } = {}, hubCourses: { courses = {} } = {} }) => ({
      profileClasses: currentClassList,
      courses
    }),
    {
      setOnlineTestDataAction: setOnlineTestData,
      hubDownloadResourceAction: hubDownloadResource
    }
  )
)(OnlineTestAssessments);

OnlineTestAssessments.propTypes = {
  classId: PropTypes.string.isRequired,
  profileClasses: PropTypes.object,
  courses: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired,
  next: PropTypes.func,
  hubDownloadResourceAction: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  resetPanel: PropTypes.func.isRequired,
  setOnlineTestDataAction: PropTypes.func.isRequired
};

OnlineTestAssessments.defaultProps = {};
