import { JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../util/signedFetch.js';

const URL = `${__API_BASE__}/user/products/recently-accessed`;

export const updateRecentlyAccessedRequest = body => {
  const headers = { 'Content-Type': JSON_ENCODED, Accept: JSON_ENCODED };

  return signedFetch('updateRecentlyAccessed', URL, 'PUT', body, headers);
};

export const getRecentlyAccessedRequest = () => signedFetch('getRecentlyAccessed', URL, 'GET');
