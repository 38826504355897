import { takeLatest, select } from 'redux-saga/effects';
import editManagedUser from '../../../../../../static/schema/draft-4/edit-managed-user-request-body-schema.json';
import updateManagedUser from '../../../../../../static/schema/draft-4/update-managed-user-request-body-schema.json';
import * as types from '../../../../actionTypes';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import submit from './submit';

function* validateManagedUserEditForm(payload) {
  const putManagedUserApi = yield select(state => state.managedUserEdit.putManagedUserApi);
  const dataToBeValidated = putManagedUserApi ? { data: payload, fields: [] } : payload;
  const schema = putManagedUserApi ? updateManagedUser : editManagedUser;
  yield validateInputUsingSchema(schema, types.MANAGED_USER_EDIT_VALIDATE_SET_RESULT, dataToBeValidated);
}

function* managedUserEdit() {
  yield takeLatest(types.MANAGED_USER_EDIT_VALIDATE, ({ payload }) => validateManagedUserEditForm(payload));
  yield takeLatest(types.MANAGED_USER_EDIT_PASSWORD, ({ payload }) => validateManagedUserEditForm(payload));
  yield takeLatest(types.MANAGED_USER_EDIT_SUBMIT, ({ payload: { orgId, userId, user, fields } }) =>
    submit(orgId, userId, user, fields)
  );
}

export default managedUserEdit;
