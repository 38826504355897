import { put } from 'redux-saga/effects';
import { userImpersonationSuccess, userImpersonationFailure } from '../../../../actions/impersonateUser';
import { appReady } from '../../../../reducers/app.reducer';
import impersonateUserApi from '../../../apiCalls/users/impersonateUser.api';
import { getPlatformBaseUrl } from '../../../../../globals/envSettings';

function* impersonateUser({ payload }) {
  const { userId = '', orgId = '', platformCode = '' } = payload;
  const response = yield impersonateUserApi(userId, orgId, platformCode);

  if (response.status === 'success') {
    yield put(userImpersonationSuccess());

    const platformBaseUrl = getPlatformBaseUrl(platformCode.toLowerCase());
    const redirectUrl = `${platformBaseUrl}?impersonation-token=${response.impersonationToken}`;
    // we need to clear application state before redirecting, to ensure that the impersonating users details are always written correctly into local storage once the redirect has completed. If we don't do this, we will maintain some application state from the impersonation user, which is bad.
    localStorage.clear();
    window.location.href = redirectUrl;
  } else {
    yield put(userImpersonationFailure('Failed to impersonate user'));
    yield put(appReady());
  }
}

export default impersonateUser;
