import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './DynamicTitleMenuItem.scss';

function DynamicTitleMenuItem({ menuItem = {} }) {
  const [buttonLabel, setButtonLabel] = useState('');

  const handleOnClick = e => {
    e.preventDefault();
    e.stopPropagation();
    menuItem.action();
    setButtonLabel(menuItem.label);
    setTimeout(() => setButtonLabel(''), 5000);
  };

  const onKeyDownHandler = e => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleOnClick(e);
    }
  };

  return (
    <button type="submit" onClick={e => handleOnClick(e)} onKeyDown={onKeyDownHandler}>
      <span>{menuItem.title}</span>
      {buttonLabel && <span className={styles.menuItemLabel}>{buttonLabel}</span>}
    </button>
  );
}

DynamicTitleMenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired
};

export default DynamicTitleMenuItem;
