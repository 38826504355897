const dictionaryTypes = {
  OED: {
    name: 'OED',
    fullName: 'Oxford Essential Dictionary',
    pathCode: 'mono_essential'
  },
  OWD: {
    name: 'OWD',
    fullName: 'Oxford Word Power',
    pathCode: 'english_intermediate'
  },
  OALD: {
    name: 'OALD',
    fullName: 'Oxford Advanced Learners Dictionary',
    pathCode: 'english'
  }
};

export default dictionaryTypes;
