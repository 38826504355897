import * as t from '../actionTypes';

const requestStatusOptions = { isLoading: true, success: false, error: false };
const successStatusOptions = { isLoading: false, success: true, error: false };
const failureStatusOptions = { isLoading: false, success: false, error: true };

export const formStates = {
  EDITING: 'EDITING',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION',
  ERROR: 'ERROR'
};

export const initialState = {
  formState: formStates.EDITING,
  email: '',
  isEmailTaken: false
};

export default function editCurrentUserDetails(state = initialState, { type, payload }) {
  switch (type) {
    case t.SET_IS_EMAIL_TAKEN:
      return {
        ...state,
        email: payload.email,
        isEmailTaken: payload.emailExists
      };
    case t.EDIT_CURRENT_USER_DETAILS_REQUEST:
      return {
        ...state,
        ...requestStatusOptions,
        formState: formStates.SUBMITTING
      };
    case t.EDIT_CURRENT_USER_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        ...successStatusOptions,
        formState: formStates.CONFIRMATION
      };
    case t.EDIT_CURRENT_USER_DETAILS_REQUEST_ERROR:
      return { ...state, ...failureStatusOptions, formState: formStates.ERROR };
    case t.RESET_EDIT_CURRENT_USER_DETAILS_REQUEST_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
