import getSAML from '../../../apiCalls/auth/getSAML.api.js';
import redirectToIdP from './redirectToIdP.saga.js';
import { isPublicPath } from '../../../../../globals/authSettings.js';
import getIdtokenApi from '../../../apiCalls/oidc/getIdtokenApi.js';
import { extractCredentials } from '../../../../../globals/envSettings';
import { completeLogout } from '../../../appFunctions/authorisedFunctions/auth/handleLogout.saga.js';
import getCognitoId from './getCognitoId.saga.js';
import session from '../../../../../utils/session';

export default function* getNewCredentials(authID, existingCredentials, isOIDCFlow) {
  console.log('[getNewCredentials] Starting');
  const result = {};
  let credentialResult;
  let responseData;
  const resultText = isOIDCFlow ? 'authorize code' : 'JWT';

  const isShibbolethFlow = !isOIDCFlow;
  if (!authID) {
    console.log(`[getNewCredentials] No ${resultText} found.`);
    console.log('[getNewCredentials] User has no auth and is not currently in the process of getting auth.');
    if (isPublicPath(window.location.pathname)) {
      console.log('[getNewCredentials] User is accessing a public URL so we will let them proceed');
      return {
        bypassAuth: true
      };
    }
    console.log('[getNewCredentials] User is accessing a restricted URL so they need to log in.');
    console.log('[getNewCredentials] Redirecting to IdP...');

    const urlParams = window.location.search;
    const navigateTo = urlParams && (urlParams.match(/navigateTo=([^&$]+)/) || [])[1];
    const targetUrl = urlParams && (urlParams.match(/target_url=([^&$]+)/) || [])[1];
    const accountLinking = urlParams && (urlParams.match(/account_linking=([^&$]+)/) || [])[1];

    const options = {};

    if (navigateTo) {
      options.withReturnTo = false;
      options.navigateTo = navigateTo;

      if (accountLinking) {
        options.accountLinking = true;
      }
    } else if (targetUrl) {
      options.withReturnTo = false;
      options.redirect_uri = window.location.href;
    }

    yield redirectToIdP(null, options);
  } else {
    console.log(`[getNewCredentials] ${resultText} found, clearing from URL`);
    console.log(window.location.href);
    window.history.replaceState(
      '',
      document.title,
      `${window.location.href.split('?')[0]}?${window.location.search.split('?')[1]}`
    );

    console.log('[getNewCredentials] Attempting to collect cognitoId...');
    const cognitoId = getCognitoId();

    console.log(`[getNewCredentials] Collecting credentials using ${resultText} and random string ${cognitoId}`);
    if (isOIDCFlow) {
      try {
        responseData = yield getIdtokenApi(authID);
        if (responseData && responseData.error) {
          console.log(`error during token exchange - ${responseData.error}`);
          yield completeLogout(); // Logging out without handle
        }
      } catch (err) {
        console.log(`error during token exchange - ${err}`);
        yield completeLogout(); // Logging out without handle
      }
    } else {
      responseData = yield getSAML(authID, cognitoId);
    }
    credentialResult = extractCredentials(responseData);
    // Check if we are currently being loaded in an iframe with query string including "auth-refresh" in which case we are
    // just being used to refresh creds

    if (window.self !== window.parent && window.location.search.indexOf('auth-refresh') >= 0) {
      console.log('[getNewCredentials] Reporting new credentials to parent frame');
      try {
        window.parent.postMessage({ type: 'AUTH_REFRESH', credentials: credentialResult }, window.location.origin);
        console.log('[getNewCredentials] post successful');
        const sessionInfo = session.get();
        session.set({ ...sessionInfo, ...credentialResult });
        if (isShibbolethFlow) localStorage.setItem('ces-jwt', authID);
        console.log('[getNewCredentials] stored new values');
      } catch (err) {
        console.log('[getNewCredentials] post failed with error : ', err);
      }
      // Block while page is killed
      yield new Promise(() => {});
    } else {
      if (existingCredentials && existingCredentials.currentOrganisationId) {
        // Org Switch flag must have been set so use the stored org id
        credentialResult.currentOrganisationId = existingCredentials.currentOrganisationId;
        credentialResult.doOrgSwitch = false;
      }

      if (credentialResult.error) {
        console.error('[getNewCredentials] Error loading credentials');
      } else {
        console.log('[getNewCredentials] Collected credentials, storing...');
        const sessionInfo = session.get();
        session.set({ ...sessionInfo, ...credentialResult });
        localStorage.setItem('app-mode', 'default');
        // localStorage.setItem('credCalled', true);
        if (isShibbolethFlow) localStorage.setItem('ces-jwt', authID);
        result.credentials = credentialResult;
      }
    }
  }

  return result;
}
