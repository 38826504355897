import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import PanelNavigationLink from '../PanelNavigationLink/PanelNavigationLink';
import PopoutPanel from '../PopoutPanel/PopoutPanel';
import styles from './SidePanel.scss';

/**
 * An overlay panel presented over the right-hand side of the page.
 *
 * This extends `PopoutPanel` by displaying a close button and receiving
 * a close action to be controlled with.
 */
class SidePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmClose: false
    };
  }

  _onClose = () => {
    this.setState({ showConfirmClose: true });
  };

  _onConfirmClose = () => {
    const { onClose } = this.props;
    this.setState({ showConfirmClose: false });
    onClose();
  };

  _onCancel = () => {
    this.setState({ showConfirmClose: false });
  };

  render() {
    const {
      onClose,
      children,
      localizedContent: { sidePanel: content },
      confirmBeforeClose = false,
      panelCloseComponent,
      ...props
    } = this.props;
    const { showConfirmClose } = this.state;

    return (
      <div>
        <PopoutPanel {...props}>
          <div>
            {(children.props || {}).hideCloseButton || props.hideCloseButton ? null : (
              <nav aria-label="Close" className={styles.closeToolbar}>
                {panelCloseComponent || (
                  <PanelNavigationLink
                    text={content.close_action_text}
                    isLhs={false}
                    action={confirmBeforeClose ? this._onClose : onClose}
                  />
                )}
              </nav>
            )}
            {children}
          </div>
        </PopoutPanel>
        {showConfirmClose ? (
          <ConfirmationModal
            title={content.confirm_close_title}
            body={content.confirm_close_details}
            positiveClickText={content.confirm_close_continue_button}
            negativeClickText={content.confirm_close_cancel_button}
            positiveClick={this._onConfirmClose}
            negativeClick={this._onCancel}
          />
        ) : null}
      </div>
    );
  }
}

SidePanel.propTypes = {
  localizedContent: PropTypes.shape({
    sidePanel: PropTypes.shape({
      close_action_text: PropTypes.string,
      confirm_close_title: PropTypes.string,
      confirm_close_details: PropTypes.string,
      confirm_close_continue_button: PropTypes.string,
      confirm_close_cancel_button: PropTypes.string
    })
  }).isRequired,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  confirmBeforeClose: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  panelCloseComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default withLocalizedContent('sidePanel')(SidePanel);
