import { put } from 'redux-saga/effects';
import { getProductFinderSeriesSuccess } from '../../../../actions/productFinderActions';
import getProductFinderSeriesRequest from '../../../apiCalls/hub/getProductFinderSeries';

function* getProductFinderSeries(payload) {
  const response = yield getProductFinderSeriesRequest(payload);
  yield put(getProductFinderSeriesSuccess(response));
}

export default getProductFinderSeries;
