import * as t from '../actionTypes';
import transformUser from '../utils/transformUser';

export const editUserGetDetailsRequest = (orgId, userId) => ({
  type: t.EDIT_USER_GET_DETAILS_REQUEST,
  payload: {
    orgId,
    userId
  }
});

export const editUserGetDetailsSuccess = user => ({
  type: t.EDIT_USER_GET_DETAILS_SUCCESS,
  payload: transformUser(user)
});

export const editUserGetDetailsFailure = () => ({
  type: t.EDIT_USER_GET_DETAILS_FAILURE
});

export const editUserCheckingUserName = () => ({
  type: t.EDIT_USER_CHECKING_USERNAME
});

export const editUserCheckingUserNameCompleted = isUserNameTaken => ({
  type: t.EDIT_USER_CHECKING_USERNAME_COMPLETED,
  payload: isUserNameTaken
});

export const editUserCheckingEmail = () => ({
  type: t.EDIT_USER_CHECKING_EMAIL
});

export const editUserCheckingEmailCompleted = isEmailTaken => ({
  type: t.EDIT_USER_CHECKING_EMAIL_COMPLETED,
  payload: isEmailTaken
});

export const editUserValidateInput = (orgId, input) => ({
  type: t.EDIT_USER_VALIDATE_INPUT,
  payload: {
    orgId,
    input
  }
});

export const editUserValidateInitial = input => ({
  type: t.EDIT_USER_VALIDATE_INPUT,
  payload: {
    input
  }
});

export const editUserValidateInputCompleted = errors => ({
  type: t.EDIT_USER_VALIDATE_INPUT_COMPLETED,
  payload: errors
});

export const editUserValidateInputError = () => ({
  type: t.EDIT_USER_VALIDATE_INPUT_ERROR
});

export const editUserIdentityRequest = input => ({
  type: t.EDIT_USER_IDENTITY_REQUEST,
  payload: input
});

export const editUserUnlinkSocialAccounts = (userId, input) => ({
  type: t.EDIT_USER_UNLINK_SOCIAL_ACCOUNTS,
  payload: { userId, input }
});

export const editUserAuthRequest = input => ({
  type: t.EDIT_USER_AUTH_REQUEST,
  payload: input
});

export const editUserInOrgRequest = (orgId, userId, input) => ({
  type: t.EDIT_USER_IN_ORG_REQUEST,
  payload: {
    orgId,
    userId,
    input
  }
});

export const editUserSuccess = () => ({
  type: t.EDIT_USER_SUCCESS
});

export const editUserGetSocialIdentitiesRequest = () => ({
  type: t.EDIT_USER_GET_SOCIAL_IDENTITIES_REQUEST
});

export const editUserGetSocialIdentitiesSuccess = userDetails => ({
  type: t.EDIT_USER_GET_SOCIAL_IDENTITIES_SUCCESS,
  payload: userDetails
});

export const editUserFailure = errorCode => ({
  type: t.EDIT_USER_FAILURE,
  payload: { errorCode }
});

export const editUserUnlinkAccountsPasswordFailure = (unlinkErrorCode, unlinkErrorMessage) => ({
  type: t.EDIT_USER_UNLINK_SOCIAL_ACCOUNTS_PASS_FAILURE,
  payload: { unlinkErrorCode, unlinkErrorMessage }
});

export const editUserUnlinkAccountsSubmitting = () => ({
  type: t.EDIT_USER_UNLINK_SOCIAL_ACCOUNTS_SUBMITTING
});

export const editUserInitialConsent = () => ({
  type: t.EDIT_USER_INITIAL_CONSENT
});

export const editUserReset = () => ({
  type: t.EDIT_USER_RESET
});

export const acceptOlbTerms = userId => ({
  type: t.EDIT_USER_ACCEPT_OLB_TERMS,
  payload: { userId }
});

export const acceptOlbTermsSuccess = userDetails => ({
  type: t.EDIT_USER_ACCEPT_OLB_TERMS_SUCCESS,
  payload: userDetails
});
