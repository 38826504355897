import React from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import withSearchInitialiser from '../../../../components/SearchInitialiser/withSearchInitialiser';
import RequestComponent from './RequestComponent';
import ResultComponent from './ResultComponent';

function OicProductTab({
  localizedContent: { oicMySchoolProductsTab: localizedContent },
  orgId,
  isOicOrg,
  subscriptions,
  resultIds,
  loading,
  error
}) {
  return loading ? (
    <RequestComponent localizedContent={localizedContent} />
  ) : (
    <div className="grid">
      <div className="row">
        <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
          <ResultComponent
            localizedContent={localizedContent}
            orgId={orgId}
            isOicOrg={isOicOrg}
            resultIds={resultIds}
            products={subscriptions}
            requestFailed={error}
          />
        </div>
      </div>
    </div>
  );
}

OicProductTab.propTypes = {
  subscriptions: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  isOicOrg: PropTypes.bool.isRequired,
  resultIds: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('oicMySchoolProductsTab'),
  withSearchInitialiser({
    searchSource: 'orgSubscriptions',
    initialFilters: { active: true, notStarted: true, expiring: true, expired: true },
    pageSize: 1000
  }),
  connect(state => ({
    ...pick(state, ['subscriptions']),
    resultIds: state.search.orgSubscriptions.ids,
    ...pick(state.search.orgSubscriptions, ['totalResults', 'loading', 'error'])
  }))
)(OicProductTab);
