import React from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import ContentPreview from '../LorContentPage/ContentPreview';

function EReaderContentPage() {
  return (
    <PageWrapper>
      <Helmet title="eReader" />
      <h1>eReader Content</h1>
      <ContentPreview isEreaderContentArea />
    </PageWrapper>
  );
}

export default EReaderContentPage;
