import React from 'react';
import PropTypes from 'prop-types';
// Components
import OrganisationLozenge from '../../../components/OrganisationLozenge/OrganisationLozenge';
import UserImpersonationLauncher from './UserImpersonationLauncher';
// Utils
import { isOupContent } from '../../../globals/userRoles';
import { INVITATION_STATUS } from '../../../globals/appConstants';
// Styles
import styles from './UserImpersonationPage.scss';

function UserDataTable({ usersData, content }) {
  const columns = [
    { id: 'firstName', title: content.first_name },
    { id: 'lastName', title: content.last_name },
    { id: 'userEmail', title: content.email },
    { id: 'userName', title: content.username }
  ];

  const getHeadingColumns = () => (
    <div className={styles.headingContainer}>
      {columns.map(item => (
        <div className={`${styles.headingItem} ${styles[item.id]}`} key={item.id}>
          {item.title}
        </div>
      ))}
    </div>
  );

  const getImpersonationlauncher = (userDetails, orgDetails) => (
    <UserImpersonationLauncher content={content} org={orgDetails} user={userDetails} />
  );

  const getOrgLozenge = (orgDetails = {}, userDetails = {}) => {
    const invitationStatus = orgDetails.invitation[0].invitationStatus;
    const orgNameNode = (
      <>
        <span className={styles.boldText}>{content.organization}: </span>
        <span>{orgDetails.orgName}</span>
      </>
    );
    if (isOupContent(orgDetails.userRole[0])) {
      const notAvailableNode = <span>{content.oup_admin_not_available}</span>;
      return <OrganisationLozenge firstColumn={orgNameNode} rightColumn={notAvailableNode} isError />;
    }

    if (invitationStatus !== INVITATION_STATUS.ACCEPTED && invitationStatus !== INVITATION_STATUS.NONE) {
      const notAvailableNode = <span>{content.oup_user_invite_not_accepted}</span>;
      return <OrganisationLozenge firstColumn={orgNameNode} rightColumn={notAvailableNode} isError />;
    }

    const orgIdNode = (
      <>
        <span className={styles.boldText}>{content.id}: </span>
        <span>{orgDetails.orgFriendlyId}</span>
      </>
    );
    return (
      <OrganisationLozenge
        firstColumn={orgNameNode}
        secondColumn={orgIdNode}
        rightColumn={getImpersonationlauncher(userDetails, orgDetails)}
      />
    );
  };

  const getOrganisationsContent = user => {
    const noOrganisationNode = <span>{content.without_organisation}</span>;

    if (user.enrolledOrganisations.length === 0) {
      return <OrganisationLozenge firstColumn={noOrganisationNode} rightColumn={getImpersonationlauncher(user, '')} />;
    }

    // If the user has OUP Content role and is part of OUP_MASTER_GROUP org only, then display additional No organisation lozenge
    if (user.enrolledOrganisations.length === 1) {
      const orgDetails = user.enrolledOrganisations[0];
      if (isOupContent(orgDetails.userRole[0])) {
        return (
          <>
            {getOrgLozenge(orgDetails, user)}
            <OrganisationLozenge firstColumn={noOrganisationNode} />
          </>
        );
      }
    }

    return user.enrolledOrganisations.map(org => getOrgLozenge(org, user));
  };

  const getUserDetails = user => (
    <>
      <div className={styles.rowContainer}>
        <div className={`${styles.rowItem} ${styles.firstName}`}>{user.firstName || ''}</div>
        <div className={`${styles.rowItem} ${styles.lastName}`}>{user.lastName || ''}</div>
        <div className={`${styles.rowItem} ${styles.userEmail}`}>{user.userEmail || ''}</div>
        <div className={`${styles.rowItem} ${styles.userName}`}>{user.userName || ''}</div>
      </div>
      {getOrganisationsContent(user)}
    </>
  );

  return (
    <div className={styles.userDataContainer}>
      {getHeadingColumns()}
      {usersData.map(user => getUserDetails(user))}
    </div>
  );
}

UserDataTable.propTypes = {
  usersData: PropTypes.array.isRequired,
  content: PropTypes.object.isRequired
};

export default UserDataTable;
