import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Return a new component provided with the selected set of language glossaries
 * for the current locale as the property `localizedContent`.
 *
 * @param {...string} glossaries
 *
 * @return {React.Component}
 */
const withLocalizedContent = (...glossaries) => Component => {
  function WithLocalizedContent({ localizedContent = {}, ...props }, context) {
    const { localizedContent: localizedContentContext } = context;
    // TODO: Allow a new locale to be loaded (using an API call to content with appropriate headers or query string)
    const selectedGlossaries = pick(localizedContentContext || {}, glossaries);

    return <Component {...props} localizedContent={{ ...localizedContent, ...selectedGlossaries }} />;
  }

  WithLocalizedContent.contextTypes = { localizedContent: PropTypes.object.isRequired };
  WithLocalizedContent.propTypes = { localizedContent: PropTypes.object };

  return WithLocalizedContent;
};

export default withLocalizedContent;
