import PropTypes from 'prop-types';
import React from 'react';
import { ICON_VALIDATION_WARNING_WHITE, ICON_GREEN_TICK } from '@oup/shared-front-end/src/svg/oup';

import styles from './DataRefresher.scss';

function DataRefresherLabel({ timer, interval, label, colorClass }) {
  return (
    <div className={styles[colorClass]}>
      {!interval || timer > 300 ? (
        <div className={styles.tickOrangeIcon}>
          <ICON_VALIDATION_WARNING_WHITE />
        </div>
      ) : (
        <div className={styles.tickGreenIcon}>
          <ICON_GREEN_TICK />
        </div>
      )}
      <div className={styles.refreshText}>{label}</div>
    </div>
  );
}

DataRefresherLabel.propTypes = {
  timer: PropTypes.number,
  interval: PropTypes.any,
  label: PropTypes.string,
  colorClass: PropTypes.string
};

export default DataRefresherLabel;
