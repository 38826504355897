import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../ActivationCodeBatchPage/css/AddLicenseContentPage.scss';
import withLocalizedContent from '../../../../../language/withLocalizedContent';
import OrganizationalDropdown from './OrganizationalDropdown';

function DefinitionTab({ productDetails, setProductDetails, localizedContent: { productCreate: content } }) {
  const { productName, state, email } = productDetails;
  const setDetails = setProductDetails();
  return (
    <div className="container">
      <article className={styles.licenseDetails}>
        <div className="inputs">
          <label htmlFor="productName" className={styles.inputContainer}>
            <span className={styles.names}>
              {content.productName}
              <span className={styles.redStar}>*</span>
            </span>
            <input
              type="text"
              id="productName"
              required
              value={productName}
              onChange={e => {
                setDetails.setProductNameFunction(e.target.value);
              }}
            />
          </label>
          <OrganizationalDropdown productDetails={productDetails} setProductDetails={setProductDetails} />
          <label htmlFor="state" className={styles.inputContainer}>
            <span className={styles.names}>{content.state}</span>
            <select
              id="state"
              type="checbox"
              defaultValue={state}
              onChange={e => {
                setDetails.setStateFunction(e.target.value);
              }}
            >
              <option value="ACTIVE">{content.active}</option>
              <option value="SUSPENDED">{content.suspended}</option>
              <option value="RETIRED">{content.retired}</option>
              <option value="REMOVED">{content.removed}</option>
            </select>
          </label>
          <label htmlFor="email" className={styles.inputContainer}>
            <span className={styles.names}>
              {content.email}
              <span className={styles.redStar}>*</span>
            </span>
            <input
              type="text"
              id="email"
              required
              value={email}
              onChange={e => {
                setDetails.setEmailFunction(e.target.value);
              }}
            />
          </label>
        </div>
      </article>
    </div>
  );
}
DefinitionTab.propTypes = {
  productDetails: PropTypes.object,
  setProductDetails: PropTypes.func,
  localizedContent: PropTypes.object
};

export default withLocalizedContent('productCreate')(DefinitionTab);
