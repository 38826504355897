import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './HorizontalCarouselScroller.scss';
import { isHubMode } from '../../utils/platform.js';

function safelyGetBoundingClientProp(domElement, prop, defaultValue = 100) {
  if (domElement && typeof domElement.getBoundingClientRect === 'function') {
    return domElement.getBoundingClientRect()[prop];
  }
  return defaultValue;
}

class HorizontalCarouselScroller extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topHeight: { top: '20%' },
      showLeftShadow: false,
      showRightShadow: true
    };

    this.scrollElement = null;
    this.buttonElement = null;
    this.continuousScroller = null;
  }

  componentDidMount() {
    const { scrollTag } = this.props;

    const buttonMargin = Math.abs(parseInt(getComputedStyle(this.buttonElement).marginTop.replace(/[^-\d.]/g, ''), 10));
    const buttonHeight = safelyGetBoundingClientProp(this.buttonElement, 'height');

    this.scrollElement.focus();

    if (scrollTag === 'table') {
      this.parentTopHeight = this.scrollElement.offsetParent
        ? safelyGetBoundingClientProp(this.scrollElement.offsetParent, 'top')
        : 0;
      this.topHeight =
        safelyGetBoundingClientProp(this.scrollElement.getElementsByTagName('thead')[0], 'height') + buttonMargin;
      this.bottomHeight =
        safelyGetBoundingClientProp(this.scrollElement.getElementsByTagName('tfoot')[0], 'top') -
        this.parentTopHeight -
        (buttonHeight - buttonMargin);
    }
  }

  setScrollElementRef = element => {
    this.scrollElement = element;
  };

  setButtonElementRef = element => {
    this.buttonElement = element;
  };

  scrollingElement = type => {
    const { scrollingPixel } = this.props;

    if (type === 'left') {
      this.scrollElement.scrollLeft -= scrollingPixel;
    } else {
      this.scrollElement.scrollLeft += scrollingPixel;
    }
  };

  keepScrollingElement = (status, type) => {
    clearInterval(this.continuousScroller);
    if (status === 'START') {
      this.continuousScroller = setInterval(() => this.scrollingElement(type), 500);
    }
  };

  movePointer = event => {
    const { scrollTag } = this.props;

    let ePosy = event.pageY;
    if (scrollTag === 'table' && isHubMode()) {
      this.parentTopHeight = this.scrollElement.offsetParent
        ? safelyGetBoundingClientProp(this.scrollElement.offsetParent, 'top')
        : 0;
    }
    if (this.parentTopHeight) {
      ePosy = this.parentTopHeight;
    }

    let toPos = event.pageY - ePosy;

    if (toPos <= this.topHeight) {
      toPos = this.topHeight;
    }

    if (toPos >= this.bottomHeight) {
      toPos = this.bottomHeight;
    }

    this.setState({
      topHeight: { top: `${toPos}px` }
    });
  };

  scrolling = () => {
    this.setState({
      showLeftShadow: this.scrollElement.scrollLeft > 0,
      showRightShadow:
        this.scrollElement.scrollLeft <
        this.scrollElement.scrollWidth - safelyGetBoundingClientProp(this.scrollElement, 'width')
    });
  };

  render() {
    const { children } = this.props;
    const { topHeight, showLeftShadow, showRightShadow } = this.state;

    return (
      <div className={styles.horizontalCarouselScroller}>
        <div
          className={classnames(styles.scrollerContainer, {
            [styles.leftShadow]: showLeftShadow,
            [styles.rightShadow]: showRightShadow
          })}
          ref={this.setScrollElementRef}
          onMouseMove={this.movePointer}
          onScroll={this.scrolling}
        >
          {children}
        </div>
        <button
          type="submit"
          className={styles.left}
          style={topHeight}
          onClick={() => this.scrollingElement('left')}
          onMouseDown={() => this.keepScrollingElement('START', 'left')}
          onMouseUp={() => this.keepScrollingElement('STOP', 'left')}
          ref={this.setButtonElementRef}
        >
          &#10094;
        </button>
        <button
          type="submit"
          className={styles.right}
          style={topHeight}
          onClick={() => this.scrollingElement('right')}
          onMouseDown={() => this.keepScrollingElement('START', 'right')}
          onMouseUp={() => this.keepScrollingElement('STOP', 'right')}
        >
          &#10095;
        </button>
      </div>
    );
  }
}

HorizontalCarouselScroller.defaultProps = {
  scrollingPixel: 50
};

HorizontalCarouselScroller.propTypes = {
  scrollingPixel: PropTypes.number,
  scrollTag: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default HorizontalCarouselScroller;
