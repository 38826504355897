import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SortDirection as SortDirectionPropType } from '../../types';
import prefixKeys from '../../utils/object/prefixKeys';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

import styles from './SortLabel.scss';

export function SortIcon({ direction = 'none' }) {
  return direction === 'asc' || direction === 'desc' ? (
    <SVGIcon
      className={styles.SortLabel__icon}
      glyph={direction === 'asc' ? GLYPHS.CHEVRON_UP_THICK : GLYPHS.CHEVRON_DOWN_THICK}
    />
  ) : (
    <SVGIcon className={styles.SortLabel__icon} glyph={GLYPHS.ICON_DROPLIST} />
  );
}
SortIcon.propTypes = {
  direction: SortDirectionPropType
};

function SortLabel({ children, group, name, disabled, onClick, direction = 'none', aria = {}, customClassName }) {
  const activeSort = direction !== 'none';

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={classnames(styles.SortLabel, customClassName, activeSort && styles['SortLabel--sorted'])}
      {...prefixKeys(aria, 'aria-')}
      disabled={disabled}
    >
      <input
        type="radio"
        name={group}
        value={name}
        onClick={onClick}
        defaultChecked={activeSort}
        className={styles.SortLabel__input}
      />
      <div>
        {children}
        <SortIcon direction={direction} />
      </div>
    </label>
  );
}

SortLabel.propTypes = {
  children: PropTypes.node,
  customClassName: PropTypes.string,
  name: PropTypes.string,
  group: PropTypes.string,
  direction: SortDirectionPropType,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  aria: PropTypes.shape({
    role: PropTypes.string,
    live: PropTypes.string
  })
};

export default SortLabel;
