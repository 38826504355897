import signedFetch from '../util/signedFetch.js';

// TODO: candidate function to include in utils?
const normalize = data => {
  if (Array.isArray(data)) {
    return data.join(',');
  }
  return data;
};

export default (orgId, courseId, nodeId, resourceId, format = null, sort = null, page = {}) => {
  let url = `${__API_BASE__}/ngs/org/${orgId}/courses/${courseId}/resources`;

  const queryParams = [];
  if (nodeId) queryParams.push(`node-id=${nodeId}`);
  if (resourceId) queryParams.push(`resource-ids=${resourceId}`);
  if (page.size) queryParams.push(`size=${page.size}`);
  if (page.start) queryParams.push(`start=${page.start}`);
  if (format) queryParams.push(`format=${normalize(format)}`);
  if (sort) queryParams.push(`sort=${sort}`);
  if (queryParams.length) url += `?${queryParams.join('&')}`;

  return signedFetch('ngsGetCourseResources', url);
};
