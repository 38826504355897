import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { courseResourceIsTeacherOnly } from '@oup/shared-node-browser/course.js';

// Style
import styles from './ResourceSubFolder.scss';
// Components
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import Resource from '../../../../components/Resource/Resource';
// Constants
import { HubLayoutConstants } from '../../../../globals/hubConstants';
// Services
import { hasHideableKeywords, hasLockableKeywords } from '../../../HubCourseAssessments/Utils/isLockedNode';

function ResourceSubFolder({
  folderDetails,
  resources,
  id,
  downloadResource,
  openModal,
  hubContent,
  downloadIsLoading,
  hasLicence,
  isExpired,
  startedLicence,
  openExpiredModal,
  breakpoint,
  openNotStartedModal,
  resourcesView,
  resourceIdsDownloading
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const keyDownHandler = callback => e => {
    if (e.key === 'Enter') callback(e);
  };

  const getFilteredResources = () =>
    resourcesView === HubLayoutConstants.COURSE_USER_ROLES.TEACHER
      ? folderDetails.resources
      : folderDetails.resources?.length &&
        folderDetails.resources.filter(resourceId => !courseResourceIsTeacherOnly(resources[resourceId]));

  return (
    <div>
      <ul className={styles.resourceFolderContainer}>
        <li key={id} className={styles.resourceFolder}>
          <div
            id={id}
            role="button"
            tabIndex={0}
            className={styles.folderContainer}
            onKeyDown={() => {
              keyDownHandler(() => {
                setIsCollapsed(!isCollapsed);
              });
            }}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <button
              type="button"
              className={`${styles.thumbnailContainer} ${isCollapsed ? styles.collapsedThumbnail : ''}`}
            >
              <SVGIcon glyph={GLYPHS.CHEVRON_DOWN_THICK} />
            </button>
            <div>{folderDetails.title}</div>
          </div>
        </li>
      </ul>
      {isCollapsed && (
        <ul>
          {folderDetails.resources?.length
            ? getFilteredResources().map(
                resourceId =>
                  resources[resourceId].keywords &&
                  !hasHideableKeywords(resources[resourceId].keywords) && (
                    <div key={resourceId} className={styles.adaptedResourceContainer}>
                      <Resource
                        key={resourceId}
                        resourceId={resourceId}
                        resource={resources[resourceId]}
                        downloadResource={downloadResource}
                        openModal={openModal}
                        hubContent={hubContent}
                        downloadIsLoading={downloadIsLoading}
                        hasLicence={hasLicence}
                        isExpired={
                          isExpired ||
                          (resources[resourceId].keywords && hasLockableKeywords(resources[resourceId].keywords))
                        }
                        startedLicence={startedLicence}
                        openExpiredModal={() => {
                          openExpiredModal(resourceId);
                        }}
                        breakpoint={breakpoint}
                        openNotStartedModal={() => {
                          openNotStartedModal(resourceId);
                        }}
                        resourceIdsDownloading={resourceIdsDownloading}
                      />
                    </div>
                  )
              )
            : null}
        </ul>
      )}
    </div>
  );
}

ResourceSubFolder.propTypes = {
  folderDetails: PropTypes.object,
  resources: PropTypes.object,
  id: PropTypes.string,
  downloadResource: PropTypes.func,
  openModal: PropTypes.func,
  openNotStartedModal: PropTypes.func,
  openExpiredModal: PropTypes.func,
  hubContent: PropTypes.object,
  downloadIsLoading: PropTypes.bool,
  hasLicence: PropTypes.bool,
  isExpired: PropTypes.bool,
  startedLicence: PropTypes.bool,
  breakpoint: PropTypes.string,
  resourcesView: PropTypes.string,
  resourceIdsDownloading: PropTypes.object
};
export default ResourceSubFolder;
