/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames';
import styles from './SkeletonLoader.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

const defaultSkeleton = ({ largeRectWidth = '35%', smallRectWidth = '15%', ...props }) =>
  Array(3)
    .fill('')
    .map((e, i) => (
      <div className={`row ${styles.row}`} key={i}>
        <div className="col">
          <ContentLoader
            height="74"
            width="100%"
            viewBox="0 0 1184 74"
            className={classnames(styles.loader, styles.large)}
            {...props}
          >
            <circle cx="40" cy="37" r="25" fill="white" />
            <rect x="80" y="16" rx="5" ry="5" width={largeRectWidth} height="25" />
            <rect x="80" y="46" rx="5" ry="5" width={smallRectWidth} height="15" />
          </ContentLoader>
          <ContentLoader
            height="74"
            width="100%"
            viewBox="0 0 890 74"
            className={classnames(styles.loader, styles.medium)}
            {...props}
          >
            <circle cx="40" cy="37" r="25" fill="white" />
            <rect x="80" y="16" rx="5" ry="5" width={largeRectWidth} height="25" />
            <rect x="80" y="46" rx="5" ry="5" width={smallRectWidth} height="15" />
          </ContentLoader>
          <ContentLoader
            height="74"
            width="100%"
            viewBox="0 0 513 74"
            className={classnames(styles.loader, styles.small)}
            {...props}
          >
            <circle cx="40" cy="37" r="25" fill="white" />
            <rect x="80" y="16" rx="5" ry="5" width={largeRectWidth} height="25" />
            <rect x="80" y="46" rx="5" ry="5" width={smallRectWidth} height="15" />
          </ContentLoader>
        </div>
      </div>
    ));

const getLoadingSkeletonComponent = ({ tabName, panelName, learnerId, customEnhancedComponent, ...props }) => {
  let component;

  if (tabName === 'gradebook' && !panelName && !learnerId) {
    component = (
      <div>
        <SVGIcon glyph={GLYPHS.GB_PROD_SKELETON} className={classnames(styles.gbProdSkeleton, styles.large)} />
        <SVGIcon glyph={GLYPHS.GB_PROD_SKELETON} className={classnames(styles.gbProdSkeleton, styles.medium)} />
        <SVGIcon glyph={GLYPHS.GB_PROD_SKELETON} className={classnames(styles.gbProdSkeleton, styles.small)} />
      </div>
    );
  } else if (tabName === 'gradebook' && panelName === 'workbook' && !learnerId) {
    component = (
      <div>
        <SVGIcon glyph={GLYPHS.GB_TABLE_SKELETON} className={classnames(styles.gbTableSkeleton, styles.large)} />
        <SVGIcon glyph={GLYPHS.GB_TABLE_SKELETON} className={classnames(styles.gbTableSkeleton, styles.medium)} />
        <SVGIcon glyph={GLYPHS.GB_TABLE_SKELETON} className={classnames(styles.gbTableSkeleton, styles.small)} />
      </div>
    );
  } else if (tabName === 'gradebook' && panelName === 'workbook' && learnerId) {
    component = (
      <SVGIcon glyph={GLYPHS.GB_TABLE_STUDENT_DETAILS_SKELETON} className={styles.gbTableStudentDetailsSkeleton} />
    );
  } else if (customEnhancedComponent) {
    component = (
      <div className="row">
        {customEnhancedComponent}
        {defaultSkeleton(props)}
      </div>
    );
  } else {
    component = defaultSkeleton(props);
  }

  return component;
};

function SubSectionSkeletonLoader(props) {
  const { isOrb } = props;
  return (
    <div className={styles.skeletonLoader}>
      <div className={styles.subSection}>
        <div className={!isOrb ? 'grid' : ''}>{getLoadingSkeletonComponent(props)}</div>
      </div>
    </div>
  );
}

SubSectionSkeletonLoader.propTypes = {
  // Prop Defined
  speed: PropTypes.number,
  foregroundColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  isOrb: PropTypes.bool,
  tabName: PropTypes.string,
  panelName: PropTypes.string,
  learnerId: PropTypes.string,
  largeRectWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  smallRectWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customEnhancedComponent: PropTypes.element
};

export default SubSectionSkeletonLoader;
