// Helper to prevent focus from leaving a modal while tabbing.
// This also handles Prev/Next in panels and places focus on first item in new panel content.
// How it works:
// For each container element (AKA Modal or Popup) passed to trapFocusIn(myPopup) we bind
// a focusout event to keep track of when the user tabs in and out of the modal or the page.
// If it looks like the focus is escaping our container, we bring it back into the modal.
// It maintains a "stack" of Last-in-first-out containers in order to handle modals on modals.
// Usage:
// - Enable new trap: trapFocusIn(myPopup)
// - Disable latest trap: trapFocusIn(false)

import querySelectorAllFocusable from './querySelectorAllFocusable';

// Map of container:handler pairs used to remember the current modal element(s) and their focus-trap handlers:
const containerStack = new Map();
const USE_CAPTURE = false;
let isTabbingBack;

// Private handler used (by trapFocusIn) as a bound function so "this" refers to a container element
// and fieldFinder is a custom function for finding all tabbable fields in the container.
function _onBlur(fieldFinder, e) {
  const container = this;
  const prevElem = e.target;
  const nextElem = e.relatedTarget;

  if (container && nextElem) {
    // Using a timeout ensures React has finished re-rendering children:
    setTimeout(() => {
      if (
        !prevElem ||
        nextElem === document.body ||
        !container.contains(nextElem) ||
        !container.contains(document.activeElement)
      ) {
        // Attempt to identify all elements in the container that are able to receive focus:
        const focusables = fieldFinder(container);

        // Move focus to first or last element, depending on forward or backward tabbing respectively:
        if (focusables.length > 1) {
          // Futile attempt to stop event bubbling:
          e.preventDefault();
          e.stopImmediatePropagation();

          // Get index of first or last field in the container:
          const nextIndex = isTabbingBack ? focusables.length - 1 : 0;
          focusables[nextIndex].focus();
        }
      }
    }, 0);
  }
}

// This handler keeps track state of SHIFT KEY because we can't detect it in a focus event:
function onKeyUpDown(e) {
  isTabbingBack = e.shiftKey;
}

export default function trapFocusIn(container, fieldFinder = querySelectorAllFocusable) {
  // UNBIND current container trap first:
  if (!container && containerStack.size) {
    const containers = Array.from(containerStack.keys());
    const unbindContainer = containers.pop();

    // console.log('UNBIND', unbindContainer);
    window.removeEventListener('focusout', containerStack.get(unbindContainer), USE_CAPTURE);
    containerStack.delete(unbindContainer);

    if (containerStack.size) {
      // REBIND whichever container remains at top of stack:
      const rebindContainer = containers[containerStack.size - 1];
      // console.log('REBIND', rebindContainer);
      window.addEventListener('focusout', containerStack.get(rebindContainer), USE_CAPTURE);
    } else {
      // When Stack is empty we don't need to detect Shift key anymore:
      window.removeEventListener('keydown', onKeyUpDown, USE_CAPTURE);
      window.removeEventListener('keyup', onKeyUpDown, USE_CAPTURE);
    }
  }

  // BIND new container trap if specified:
  if (container) {
    if (containerStack.size) {
      // Unbind whichever container is at top of Stack:
      const containers = Array.from(containerStack.keys());
      const unbindContainer = containers.pop();
      // console.log('UNBIND', unbindContainer);
      window.removeEventListener('focusout', containerStack.get(unbindContainer), USE_CAPTURE);
    } else {
      // When adding the first container to the stack then we'll need to start monitoring the Shift key:
      window.addEventListener('keydown', onKeyUpDown, USE_CAPTURE);
      window.addEventListener('keyup', onKeyUpDown, USE_CAPTURE);
    }

    // console.log('BIND', container);
    const newHandler = _onBlur.bind(container, fieldFinder);
    containerStack.set(container, newHandler);
    window.addEventListener('focusout', newHandler, USE_CAPTURE);
  }
}
