import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';

function JoinClassCodeSubmitting({ content }) {
  return (
    <PopoutPanelIconHeading
      type={types.LOADING}
      title={content.loading_page_title}
      subtitle={content.loading_page_subtitle}
    />
  );
}

JoinClassCodeSubmitting.propTypes = {
  content: PropTypes.object.isRequired
};

export default connect(() => ({}))(JoinClassCodeSubmitting);
