import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './MobileGamesErrorPopup.scss';

function MobileGamesErrorPopup({ closePlayModal, cptContent }) {
  return (
    <div className={styles.mobileErrorContainter}>
      <div className={styles.warningIconContainer}>
        <SVGIcon glyph={GLYPHS.ICON_WARNING_TRIANGLE} className={styles.warningIcon} />
      </div>
      <div className={styles.warningText}> {cptContent.mobile_games_error} </div>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={closePlayModal}
        onClick={closePlayModal}
        className={styles.warningButton}
      >
        <SVGIcon glyph={GLYPHS.ICON_LEFT_THICK} className={styles.backIcon} />
        <p>{cptContent.mobile_popup_back_button}</p>
      </div>
    </div>
  );
}

MobileGamesErrorPopup.propTypes = {
  closePlayModal: PropTypes.func.isRequired,
  cptContent: PropTypes.object
};
export default MobileGamesErrorPopup;
