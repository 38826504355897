import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orgRoles } from '../globals/orgRoles';

const withRole = connect((state, ownProps) => ({
  identityRole: state.identity.role,
  ...ownProps,
  orgRole: state.organisations.data[state.identity.currentOrganisationId]?.role
}));

export default function restrictByOrgUser(Component) {
  class ProtectedComponent extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      history: PropTypes.any,
      identityRole: PropTypes.string,
      orgRole: PropTypes.oneOf(Object.keys(orgRoles))
    };

    componentWillMount() {
      const { orgRole, history } = this.props;
      if (orgRole === orgRoles.TEST_CENTER) {
        history.replace('/');
      }
    }

    render() {
      // Render only occurs if componentWillMount passed without redirect
      const { identityRole, ...props } = this.props;
      return <Component {...props} />;
    }
  }

  return React.createElement(withRouter(withRole(ProtectedComponent)));
}
