import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../Button/Button';
import ControlledForm from '../ControlledForm/ControlledForm';
import PanelScrollContainer from '../PanelScrollContainer/PanelScrollContainer';
import PopoutNavFooter from '../PopoutNavFooter/PopoutNavFooter';
import ImportUsersGuidance from './ImportUsersGuidance';
import ExportUsers from '../ExportUsers/ExportUsers';

export const COPY_AND_PASTE = 'CopyAndPaste';
export const FILE_UPLOAD = 'FileUpload';

class ImportUsersOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null
    };
  }

  _onSelect = option => {
    this.setState({ selected: option });
  };

  _onNext = () => {
    const { selected } = this.state;
    const { onCopyAndPasteSelected, onFileUploadSelected } = this.props;
    if (selected === COPY_AND_PASTE) {
      onCopyAndPasteSelected();
    } else if (selected === FILE_UPLOAD) {
      onFileUploadSelected();
    }
  };

  render() {
    const { selected } = this.state;
    const {
      localizedContent: { importUsersOptions: content },
      curriculumType
    } = this.props;
    const isCopyAndPasteSelected = selected === COPY_AND_PASTE;
    const isFileUploadSelected = selected === FILE_UPLOAD;
    return (
      <ControlledForm>
        <PanelScrollContainer
          heading={content.panel_heading}
          subHeading={content.panel_subheading}
          footer={
            <PopoutNavFooter
              nextButtonText={content.next_button_text}
              nextAction={this._onNext}
              nextButtonDisabled={!isCopyAndPasteSelected && !isFileUploadSelected}
            />
          }
        >
          <div className="pad2">
            <p className="gin-bot1">{content.select_option_label}</p>
            <div className="gin-bot2">
              <Button
                id={COPY_AND_PASTE}
                type={buttonTypes.GROUP}
                text={content.copy_and_paste_button_text}
                onClick={() => this._onSelect(COPY_AND_PASTE)}
                active={isCopyAndPasteSelected}
              />
              <Button
                id={FILE_UPLOAD}
                type={buttonTypes.GROUP}
                text={content.file_upload_button_text}
                onClick={() => this._onSelect(FILE_UPLOAD)}
                active={isFileUploadSelected}
              />
            </div>
            {isCopyAndPasteSelected || isFileUploadSelected ? (
              <div>
                {isCopyAndPasteSelected ? <p>{content.copy_and_paste_selected_pre_text}</p> : null}
                {isFileUploadSelected ? <p>{content.file_upload_selected_pre_text}</p> : null}
                <p>{content.guidance_heading_pre_text}</p>

                <ImportUsersGuidance heading={content.data_format_heading} curriculumType={curriculumType} />

                {isCopyAndPasteSelected ? <p>{content.copy_and_paste_selected_post_text}</p> : null}
              </div>
            ) : null}
            <div className="gin-bot2">
              <p>
                <b>{content.please_note_text}</b> {content.upload_warn_text}
              </p>
            </div>
            <ExportUsers />
          </div>
        </PanelScrollContainer>
      </ControlledForm>
    );
  }
}

ImportUsersOptions.propTypes = {
  onCopyAndPasteSelected: PropTypes.func,
  onFileUploadSelected: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  curriculumType: PropTypes.string
};

export default withLocalizedContent('importUsersOptions')(ImportUsersOptions);
