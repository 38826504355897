import { put, select } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import getManagedUserDetails from '../../../apiCalls/users/getManagedUserDetails.api';
import {
  userEnrolDownloadSigninCardSuccess,
  userEnrolDownloadSigninCardFail,
  userEnrolSetUserDetails
} from '../../../../actions/userEnrol';
import generateSignInCard from '../../../../../globals/generateSignInCard';

function* getUserDetails() {
  const currentOrganisationId = yield select(state => state.identity.currentOrganisationId);
  const userId = yield select(state => state.userEnrol.userId);
  const { status, data } = yield getManagedUserDetails(currentOrganisationId, [userId]);
  if (status === 'success') {
    yield put(userEnrolSetUserDetails(data.successUsers));
    const currentOrg = yield select(state => state.organisations.data[currentOrganisationId]);
    const schoolCode = currentOrg && currentOrg.customId;
    const pdf = yield generateSignInCard({ successUsers: data.successUsers, archiveUsers: [] }, schoolCode);
    yield put(userEnrolDownloadSigninCardSuccess(pdf.docSize));
  } else {
    yield put(userEnrolDownloadSigninCardFail());
  }
}

export default function* triggerDownloadSigninCard() {
  const { userDetails, currentOrganisationId } = yield select(state => ({
    userDetails: state.userEnrol.userDetails,
    currentOrganisationId: state.identity.currentOrganisationId
  }));
  if (!isEmpty(userDetails)) {
    const currentOrg = yield select(state => state.organisations.data[currentOrganisationId]);
    const schoolCode = currentOrg && currentOrg.customId;
    const pdf = yield generateSignInCard({ successUsers: userDetails, archiveUsers: [] }, schoolCode);
    yield put(userEnrolDownloadSigninCardSuccess(pdf.docSize));
  } else {
    yield getUserDetails();
  }
}
