import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import styles from '../SkeletonLoader.scss';

function OrbSubSectionSkeleton({ speed = 2, foregroundColor = '#dcdcdcff', backgroundColor = `#fff` }) {
  return (
    <div className={styles.skeletonLoader}>
      <div className={styles.orbSubSection}>
        {Array(3)
          .fill('')
          .map((e, i) => (
            <div className={`row ${styles.row}`} key={i}>
              <div className="col">
                <ContentLoader
                  height="74"
                  width="100%"
                  viewBox="0 0 100% 50"
                  className={styles.loader}
                  speed={speed}
                  foregroundColor={foregroundColor}
                  backgroundColor={backgroundColor}
                >
                  <rect x="43%" y="33%" rx="15" ry="15" width="13%" height="40%" />
                </ContentLoader>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

OrbSubSectionSkeleton.propTypes = {
  // Prop Defined
  speed: PropTypes.number,
  foregroundColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired
};

export default OrbSubSectionSkeleton;
