import React from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './label.scss';

export default function Label({ text, htmlFor, className }) {
  return (
    <label className={`${styles[className] ? styles[className] : ''} ${className}`} htmlFor={htmlFor}>
      {text}
    </label>
  );
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
  htmlFor: PropTypes.string,
  className: PropTypes.string
};
