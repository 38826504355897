import * as t from '../actionTypes';

export const getFilteredProfileCourseMaterials = filters => ({
  type: t.HUB_GET_FILTERED_PROFILE_COURSE_MATERIALS,
  filters
});

export const resetFiltersProfileCourseMaterials = courseId => ({
  type: t.HUB_RESET_FILTERS_PROFILE_COURSE_MATERIALS,
  courseId
});

export const setUnfilteredProfileCourseMaterials = payload => ({
  type: t.HUB_SET_UNFILTERED_PROFILE_COURSE_MATERIALS,
  payload
});

export const setSortProfileCourseMaterials = sort => ({
  type: t.HUB_SET_SORT_PROFILE_COURSE_MATERIALS,
  sort
});
