/* eslint-disable import/prefer-default-export */

import { createSelector } from 'reselect';
import { orgRoles } from '../../../globals/orgRoles';
import userRoles from '../../../globals/userRoles';
import createGetUserRoleForOrg from '../identity/createGetUserRoleForOrg';
import { isAdminOrSupport } from './user';

/**
 * A selector returning a function resolving if the authenticated user can view
 * a given organisation.
 *
 * @param {Object} state
 *
 * @return {Function}
 */

export const limitToOrgRoles = orgRolesLimitArray =>
  createSelector(
    state => state.organisations.data,
    state => state.identity.role,
    createGetUserRoleForOrg,
    (organizations, role, getUserRoleForOrg) => ({ orgId }) => {
      const organization = organizations[orgId];
      return (
        ([userRoles.OUP_ADMIN, userRoles.OUP_SUPPORT].includes(role) ||
          [userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN, userRoles.TEACHER].includes(getUserRoleForOrg(orgId))) &&
        (orgRolesLimitArray !== undefined && Array.isArray(orgRolesLimitArray) && organization
          ? orgRolesLimitArray.includes(organization.role)
          : true)
      );
    }
  );

/**
 * A selector returning a function resolving if the authenticated user can
 * update assignments on users within a given organisation.
 *
 * @param {Object} state
 *
 * @return {Function}
 */
export const createAuthorizeUpdateAssignments = createSelector(
  state => state.organisations.data,
  createGetUserRoleForOrg,
  isAdminOrSupport,
  (organizations, getUserRoleForOrg, isOupRole) => ({ orgId }) => {
    const userOrgRole = getUserRoleForOrg(orgId);
    const organization = organizations[orgId];

    return (
      isOupRole ||
      (userOrgRole &&
        organization &&
        !(
          [userRoles.TEACHER, userRoles.TEACHER_ADMIN, userRoles.ORG_ADMIN].includes(userOrgRole) &&
          organization.role === orgRoles.PRIMARY_SCHOOL
        ) &&
        ![userRoles.TEACHER].includes(userOrgRole) &&
        organization.role === orgRoles.SECONDARY_SCHOOL)
    );
  }
);
