import * as t from '../actionTypes';

export const validateOrgInput = input => ({
  type: t.VALIDATE_ORG_INPUT,
  payload: input
});

export const validateOrgError = () => ({
  type: t.VALIDATE_ORG_ERROR
});

export const reviewOrgInput = (input, branch) => ({
  type: t.REVIEW_ORG_INPUT,
  branch,
  payload: input
});

export const reviewOrgCompleted = confirm => ({
  type: t.REVIEW_ORG_COMPLETED,
  payload: confirm
});

export const reviewOrgError = () => ({
  type: t.REVIEW_ORG_ERROR
});

export const changeOrgInput = () => ({
  type: t.CHANGE_ORG_INPUT
});

export const registerOrgRequest = (input, branch) => ({
  type: t.REGISTER_ORG_REQUEST,
  branch,
  payload: input
});

export const registerOrgSuccess = orgId => ({
  type: t.REGISTER_ORG_SUCCESS,
  payload: orgId
});

export const registerOrgFailure = reason => ({
  type: t.REGISTER_ORG_FAILURE,
  payload: reason
});

export const registerOrgReset = () => ({
  type: t.REGISTER_ORG_RESET
});

export const getPartialOrgRequest = id => ({
  type: t.GET_PARTIAL_ORG_REQUEST,
  payload: id
});

export const getPartialOrgSuccess = org => ({
  type: t.GET_PARTIAL_ORG_SUCCESS,
  payload: org
});

export const getPartialOrgFailure = reason => ({
  type: t.GET_PARTIAL_ORG_FAILURE,
  payload: reason
});

export const registerOrgClearFailure = () => ({
  type: t.REGISTER_ORG_FAILURE_CLEAR
});

export const getOrgRequest = id => ({
  type: t.GET_ORG_REQUEST,
  payload: id
});

export const getOrgSuccess = org => ({
  type: t.GET_ORG_SUCCESS,
  payload: org
});

export const getOrgFailure = reason => ({
  type: t.GET_ORG_FAILURE,
  payload: reason
});

export const getOrgReset = () => ({
  type: t.GET_ORG_RESET
});
