import PropTypes from 'prop-types';
import React from 'react';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../../../../../components/PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';

function AddToLibraryBulkConfirmation({
  email,
  orgName,
  closePanel,
  onComplete,
  renderAddLicencesPanel,
  localizedContent: { addToLibrary: content }
}) {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={content.close_panel_text} action={closePanel} />
            </div>
            <PopoutPanelIconHeading type="PROCESSING" title={content.processing_codes_text_title} />
          </div>
        }
        footerContent={
          <PopoutActionFooter
            primaryButtonAction={onComplete}
            primaryButtonText={content.complete_panel_text}
            secondaryButtonAction={renderAddLicencesPanel}
            secondaryButtonText={content.add_more_licences}
          />
        }
      >
        <div>
          <div className="gin-top2 gin-left4 gin-right4 text-center">
            <p className="gin-top4">{content.processing_codes_text_body.replace('{orgName}', orgName)}</p>
            <p id="user-email" className="gin-top4">
              {content.processing_codes_email_body_start}
            </p>
            <p style={{ padding: '1rem', backgroundColor: '#b3aaaa' }}>
              <em>{email}</em>
            </p>
            <p>{content.processing_codes_email_body_end}</p>
            <p className="gin-top4">{content.processing_codes_text_body_end}</p>
          </div>
        </div>
      </ScrollContainer>
    </form>
  );
}

AddToLibraryBulkConfirmation.propTypes = {
  orgName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  renderAddLicencesPanel: PropTypes.func.isRequired
};

export default withLocalizedContent('addToLibrary')(AddToLibraryBulkConfirmation);
