import { TEST_STATUS } from '../../../globals/hubConstants';

// Constants
import userRoles from '../../../globals/userRoles';

const getClassTestActionButtons = (
  content,
  { previewTest = null, viewTestInfo = null, editTestSettings = null, cancelTest = null, viewScores = null },
  { testStatus = '', hasLicence = false },
  userRole
) => {
  if (testStatus === TEST_STATUS.ACTIVE) {
    if (hasLicence) {
      return {
        [userRoles.ORG_ADMIN]: [
          {
            title: content.preview_test,
            action: previewTest
          },
          {
            title: content.view_test_info,
            action: viewTestInfo
          }
        ],
        [userRoles.TEACHER_ADMIN]: [
          {
            title: content.preview_test,
            action: previewTest
          },
          {
            title: content.view_test_info,
            action: viewTestInfo
          }
        ],
        [userRoles.TEACHER]: [
          {
            title: content.preview_test,
            action: previewTest
          },
          {
            title: content.view_test_info,
            action: viewTestInfo
          }
        ],
        [userRoles.LEARNER]: [
          {
            title: content.launch_test,
            action: previewTest
          },
          {
            title: content.view_test_info,
            action: viewTestInfo
          }
        ]
      };
    }

    return {
      [userRoles.ORG_ADMIN]: [
        {
          title: content.preview_test,
          action: previewTest
        },
        {
          title: content.view_test_info,
          action: viewTestInfo
        }
      ],
      [userRoles.TEACHER_ADMIN]: [
        {
          title: content.preview_test,
          action: previewTest
        },
        {
          title: content.view_test_info,
          action: viewTestInfo
        }
      ],
      [userRoles.TEACHER]: [
        {
          title: content.preview_test,
          action: previewTest
        },
        {
          title: content.view_test_info,
          action: viewTestInfo
        }
      ],
      [userRoles.LEARNER]: [
        {
          title: content.view_test_info,
          action: viewTestInfo
        }
      ]
    };
  }

  if (testStatus === TEST_STATUS.UPCOMING) {
    return {
      [userRoles.ORG_ADMIN]: [
        {
          title: content.preview_test,
          action: previewTest
        },
        {
          title: content.edit_test_settings,
          action: editTestSettings
        },
        {
          title: content.view_test_info,
          action: viewTestInfo
        },
        {
          title: content.cancel_test,
          action: cancelTest
        }
      ],
      [userRoles.TEACHER_ADMIN]: [
        {
          title: content.preview_test,
          action: previewTest
        },
        {
          title: content.edit_test_settings,
          action: editTestSettings
        },
        {
          title: content.view_test_info,
          action: viewTestInfo
        },
        {
          title: content.cancel_test,
          action: cancelTest
        }
      ],
      [userRoles.TEACHER]: [
        {
          title: content.preview_test,
          action: previewTest
        },
        {
          title: content.edit_test_settings,
          action: editTestSettings
        },
        {
          title: content.view_test_info,
          action: viewTestInfo
        },
        {
          title: content.cancel_test,
          action: cancelTest
        }
      ],
      [userRoles.LEARNER]: [
        {
          title: content.view_test_info,
          action: viewTestInfo
        }
      ]
    };
  }

  if (testStatus === TEST_STATUS.COMPLETED) {
    if (hasLicence && userRole === userRoles.LEARNER) {
      return {
        [userRoles.LEARNER]: [
          {
            title: content.review_test,
            action: previewTest
          },
          {
            title: content.view_test_info,
            action: viewTestInfo
          }
        ]
      };
    }

    return {
      [userRoles.ORG_ADMIN]: [
        {
          title: content.view_progress,
          action: viewScores
        },
        {
          title: content.view_test_info,
          action: viewTestInfo
        }
      ],
      [userRoles.TEACHER_ADMIN]: [
        {
          title: content.view_progress,
          action: viewScores
        },
        {
          title: content.view_test_info,
          action: viewTestInfo
        }
      ],
      [userRoles.TEACHER]: [
        {
          title: content.view_progress,
          action: viewScores
        },
        {
          title: content.view_test_info,
          action: viewTestInfo
        }
      ],
      [userRoles.LEARNER]: [
        {
          title: content.view_test_info,
          action: viewTestInfo
        }
      ]
    };
  }

  return {};
};

export default getClassTestActionButtons;
