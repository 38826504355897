import { sortBy } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Badge from '../../../components/Badge/Badge';
import Button, { buttonTypes } from '../../../components/Button/Button';
import ExpandableList from '../../../components/ExpandableList/ExpandableList';
import HorizontalScrollContainer from '../../../components/HorizontalScrollContainer/HorizontalScrollContainer';
import SlideupPanel from '../../../components/SlideupPanel/SlideupPanel';
import panelStyles from '../../../components/SlideupPanel/SlideupPanel.scss';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import colors from '../../../globals/colors';
import styles from './ChangeLevelsPanel.scss';

class ChangeLevelsPanel extends Component {
  static propTypes = {
    selectedLearners: PropTypes.array.isRequired,
    toggleModal: PropTypes.func.isRequired,
    actions: PropTypes.array.isRequired
  };

  state = {
    expanded: false
  };

  _handleToggleModal = id => () => this.props.toggleModal(id);

  render() {
    const { selectedLearners, actions } = this.props;
    const sortedActions = sortBy(actions, 'position');

    return (
      <SlideupPanel isOpen={selectedLearners.length > 0}>
        <div className="flex-row">
          <div className={classnames('gin-top1', panelStyles.left)}>
            <span>
              <Badge backgroundColor={colors.PRIMARY} value={selectedLearners.length} /> selected
            </span>
          </div>

          <div className={classnames(panelStyles.right, panelStyles.horizontalContainer)}>
            <HorizontalScrollContainer className={styles.horizontalScrollContainer}>
              {sortedActions.map(({ label, id }, $0) => (
                <span key={$0} className="gin-left1">
                  <Button onClick={this._handleToggleModal(id)} type={buttonTypes.DRILLDOWN} text={label} />
                </span>
              ))}
            </HorizontalScrollContainer>
          </div>

          <div className={classnames('sm-hide', panelStyles.right)}>
            <span>
              <Button
                glyph={GLYPHS.ICON_SETTINGS}
                type={buttonTypes.DROPDOWN}
                text="Actions"
                onClick={() =>
                  this.setState(prevState => ({
                    expanded: !prevState.expanded
                  }))
                }
                active={!this.state.expanded}
              />
            </span>
          </div>
        </div>

        <ExpandableList
          className={panelStyles.expandableList}
          expanded={this.state.expanded}
          items={sortedActions.map(({ label, id }, i) => (
            <Button key={i} onClick={this._handleToggleModal(id)} type={buttonTypes.ACTION_BLOCK} text={label} />
          ))}
        />
      </SlideupPanel>
    );
  }
}

export default ChangeLevelsPanel;
