import { put, takeLatest } from 'redux-saga/effects';

import { LOAD_SAVED_SETTINGS, STORE_SAVED_SETTINGS, storeSavedSettings } from '../../../../reducers/savedSettings';

export const SETTINGS_STORAGE_KEY = 'oup-settings';

export function getSettings(key = false) {
  const settings = JSON.parse(sessionStorage.getItem(SETTINGS_STORAGE_KEY));

  if (!key) return settings;
  if (settings && settings[key]) return settings[key];

  return false;
}

function* loadSavedSettings() {
  const settings = getSettings();

  console.log('[loadSavedSettings] Loading saved settings from session storage: ', settings);

  if (settings) yield put(storeSavedSettings(settings));
}

function* handleSettingsChange({ settings }) {
  console.log('[handleSettingsChange] Updating settings in session storage:', settings);

  if (settings) {
    const existingSettings = getSettings();

    const updatedSettings = {
      ...existingSettings,
      ...settings
    };

    if (settings.classReport) {
      updatedSettings.classReport = {
        ...existingSettings?.classReport,
        ...settings.classReport
      };
    }

    if (settings.schoolReport) {
      updatedSettings.schoolReport = {
        ...existingSettings?.schoolReport,
        ...settings.schoolReport
      };
    }

    yield sessionStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify(updatedSettings));
  }
}

export default function* savedSettingsLoader() {
  console.log('[savedSettingsLoader] Registering action listeners');
  yield takeLatest([LOAD_SAVED_SETTINGS], loadSavedSettings);
  yield takeLatest([STORE_SAVED_SETTINGS], handleSettingsChange);
}
