import { put, select } from 'redux-saga/effects';
import { isNil, omitBy, pick } from 'lodash';
import qs from 'query-string';

import { PLATFORMS } from '@oup/shared-node-browser/constants';
import schema from '../../../../../../static/schema/draft-4/bulk-upload-managedusers-request-body.json';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { MANAGED_USER_ACCOUNT_STATUS } from '../../../../../globals/appConstants';
import { embeddedByPlatform, isOrbMode } from '../../../../../utils/platform';

function* uploadImportUsers() {
  const { users, orgId, queue, existing } = yield select(state => ({
    ...pick(state.importUsers, ['users']),
    ...pick(state.organisationPage, ['orgId']),
    ...pick(state.importUsers, ['queue']),
    ...pick(state.importUsers, ['existing'])
  }));
  const { customId } = yield select(state => state.organisations.data[orgId]);

  const data = Object.values(users).map(user => ({
    ...omitBy(pick(user, Object.keys(schema.items.properties)), isNil),
    userName: `${customId}_${user.userName}`,
    status: existing.includes(user.id) ? MANAGED_USER_ACCOUNT_STATUS.EXISTS : MANAGED_USER_ACCOUNT_STATUS.NEW
  }));

  const platformCode = isOrbMode() ? PLATFORMS.ORB : embeddedByPlatform();

  try {
    const bulkUserUrl = queue ? 'managed-user-queue' : 'managed-user/upload';
    const response = yield signedFetch(
      'importUsers',
      `${__API_BASE__}/org/${orgId}/${bulkUserUrl}?${qs.stringify({
        platformId: platformCode || PLATFORMS.CES
      })}`,
      'POST',
      data
    );
    if (response.status !== 'success') {
      throw new Error(response.errorMessages);
    }

    yield put(actions.uploadImportUsersSuccess(response.data.batchId, data.length));
  } catch (e) {
    yield put(actions.uploadImportUsersFailure());
  }
}

export default uploadImportUsers;
