import userRoles, { roleIsAtLeast } from '../../../globals/userRoles';

export const REDEEM_CODE_REGEX = /^(([a-zA-Z\d]{4}-){2}[a-zA-Z\d]{4}|[a-zA-Z\d]{12})$/;
export const REDEEM_VST_CODE_REGEX = /^([ASTast]){1}(((-[\d]{3}){2}-[\d]{4})|([\d]{10}))$/;
export const INCLUDES_LETTERS_REDEEM_CODE_REGEX = /^[^a-zA-Z]*$/;
export const INCLUDES_LETTERS_VST_REDEEM_CODE_REGEX = /^[^b-ru-zB-RU-Z]*$/; // all letters except A, S and T

export const vstRedeemCodeRole = {
  ADMIN: {
    name: 'ADMIN',
    letter: 'A'
  },
  TEACHER: {
    name: 'TEACHER',
    letter: 'T'
  },
  STUDENT: {
    name: 'LEARNER',
    letter: 'S'
  }
};

export const isAdminRole = codeValue =>
  codeValue.toUpperCase().indexOf(vstRedeemCodeRole.ADMIN.letter) === 0 && vstRedeemCodeRole.ADMIN.name;

export const getRoleBasedOnValue = codeValue =>
  (codeValue.toUpperCase().indexOf(vstRedeemCodeRole.ADMIN.letter) === 0 && vstRedeemCodeRole.ADMIN.name) ||
  (codeValue.toUpperCase().indexOf(vstRedeemCodeRole.TEACHER.letter) === 0 && vstRedeemCodeRole.TEACHER.name) ||
  (codeValue.toUpperCase().indexOf(vstRedeemCodeRole.STUDENT.letter) === 0 && vstRedeemCodeRole.STUDENT.name) ||
  '';

export const isRoleInvalidForUser = (
  roleBasedOnCode,
  roleBasedOnIdentity,
  studentRoleMismatch,
  teacherRoleMismatch
) => {
  if (
    roleBasedOnIdentity &&
    roleBasedOnCode &&
    roleBasedOnIdentity === userRoles.LEARNER &&
    roleBasedOnIdentity !== roleBasedOnCode
  ) {
    return teacherRoleMismatch;
  }
  if (
    roleBasedOnIdentity &&
    roleBasedOnCode &&
    roleIsAtLeast(userRoles.TEACHER, roleBasedOnIdentity) &&
    roleBasedOnCode === userRoles.LEARNER
  ) {
    return studentRoleMismatch;
  }
  return false;
};

export const validateBothTypeOfCodes = (state, role) => {
  const vstCodeRole = getRoleBasedOnValue(state.redeemCodeValue);
  if (vstCodeRole) {
    return {
      ...state,
      redeemCodeValueIsValid: REDEEM_VST_CODE_REGEX.test(state.redeemCodeValue) && !isAdminRole(state.redeemCodeValue),
      redeemCodeValueIsError:
        !REDEEM_VST_CODE_REGEX.test(state.redeemCodeValue) ||
        isAdminRole(state.redeemCodeValue) ||
        isRoleInvalidForUser(vstCodeRole, role, true, true),
      isVstCode: true,
      redeemCodeRole: vstCodeRole
    };
  }
  return {
    ...state,
    redeemCodeValueIsValid: REDEEM_CODE_REGEX.test(state.redeemCodeValue),
    redeemCodeValueIsError: !REDEEM_CODE_REGEX.test(state.redeemCodeValue),
    isVstCode: !!vstCodeRole,
    redeemCodeRole: null
  };
};
