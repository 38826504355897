import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Button, { buttonTypes } from '../Button/Button';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import styles from './CsvProductStructure.scss';
import { COURSE_MODAL_CLOSE_LINK_KEY } from '../../globals/cptConstants';
import preventBodyScroll from '../../utils/dom/preventBodyScroll';

function CsvProductStructureUploadSuccess({ content, goBackToDashboard, warnings }) {
  useEffect(() => {
    sessionStorage.setItem(COURSE_MODAL_CLOSE_LINK_KEY, window.location.pathname);
    preventBodyScroll(false);
  });

  return (
    <div className="grid pad-bot4">
      <div className="row">
        <div className="col">
          <PopoutPanelIconHeading type={types.VALID} />
          <p className="text-center gin-bot3">
            {(!warnings || (warnings && warnings.length === 0)) && content.successMessage}
            {warnings && warnings.length > 0 && (
              <>
                {content.successMessageWithWarnings}
                {warnings.map(item => (
                  <div className={styles.warning} key={item}>
                    {item}
                  </div>
                ))}
              </>
            )}
          </p>
          <div className="row cols-center">
            <span className="gin-right2">
              <div className={styles.buttonsWrapper}>
                <Button
                  id="back-to-courses-button"
                  type={buttonTypes.SECONDARY}
                  text={content.backButton}
                  onClick={goBackToDashboard}
                />
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

CsvProductStructureUploadSuccess.propTypes = {
  content: PropTypes.object.isRequired,
  goBackToDashboard: PropTypes.func.isRequired,
  warnings: PropTypes.string
};

export default CsvProductStructureUploadSuccess;
