/* eslint-disable react/prefer-stateless-function */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import actions from '../../redux/actions';
import { toggleAssessmentLicenceTab } from '../../redux/actions/hubUi';
// Constants
import {
  HOURREGEX,
  ALLOWEDHOURREGEX,
  dateFormat,
  getRoundHour,
  setHourOnDate,
  getStringHour
} from '../../globals/dateFormats';
// Style
import styles from './OnlineTestTime.scss';
// Components
import withLocalizedContent from '../../language/withLocalizedContent';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutNavFooter from '../PopoutNavFooter/PopoutNavFooter';
import DateField from '../DateField/DateField';
import TextInputField from '../TextInputField/TextInputField';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import Tooltip from '../Tooltip/Tooltip';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import OnlineTestDropdownOption from '../OnlineTestDropdownOption/OnlineTestDropdownOption';
// Utils
import {
  getDaysHoursMinutesFromMinutes,
  getMinutesFromDaysHourMinute
} from '../../utils/getDaysHoursMinutesFromMinutes';
import { getTestTitle } from '../../structure/HubCourseAssessments/Services/getTestData';

class OnlineTestTime extends Component {
  hasErrorFields = {
    startHour: false,
    endDate: false,
    endHour: false,
    durationTest: false
  };

  constructor(props) {
    super(props);
    const { durationTest, isDurationTestSet } = this.props;
    const { days, hours, minutes } = getDaysHoursMinutesFromMinutes(durationTest);
    this.state = {
      showSetDurationTest: isDurationTestSet,
      durationMinutes: minutes,
      durationHours: hours,
      durationDays: days
    };
  }

  componentWillMount() {
    const { timeSet, timeGotSet } = this.props;
    if (!timeSet) {
      this._setDefaultStartTime();
      timeGotSet();
    }
  }

  componentWillUnmount() {
    const { isPlacementTest } = this.props;
    if (isPlacementTest) this._setDefaultStartTime();
  }

  _toggleSetDurationSet = () => {
    const { showSetDurationTest } = this.state;
    const { showSetDuration } = this.props;

    this.setState({
      showSetDurationTest: !showSetDurationTest
    });
    showSetDuration(!showSetDurationTest);
  };

  _getValidationStartDate = () => {
    const {
      startHour,
      localizedContent: { hubSetTestOnline: content },
      setHasErrors,
      hasErrors
    } = this.props;
    let params = {};
    switch (true) {
      case !startHour.match(HOURREGEX):
        this.hasErrorFields.startHour = true;
        params = {
          isActive: this.hasErrorFields.startHour,
          message: content.online_test_time_error_hour_format,
          arrowClassName: 'statusArrowOnSecondField'
        };
        break;
      default:
        this.hasErrorFields.startHour = false;
        params = {
          isActive: this.hasErrorFields.startHour,
          message: ''
        };
    }
    const hasErrorsLocal = this._hasErrors();
    if (hasErrors !== hasErrorsLocal) {
      setHasErrors(hasErrorsLocal);
    }
    return params;
  };

  _getValidationEndDate = () => {
    const {
      startDate,
      endDate,
      startHour,
      endHour,
      localizedContent: { hubSetTestOnline: content },
      setHasErrors,
      hasErrors
    } = this.props;
    let params = {};
    switch (true) {
      case dateFormat(startDate) === dateFormat(endDate) && startHour === endHour:
        this.hasErrorFields.endHour = true;
        params = {
          isActive: this.hasErrorFields.endHour,
          message: content.online_test_time_enddate_error_hour_same,
          arrowClassName: 'statusArrowOnSecondField'
        };
        break;
      case endDate.getTime && endDate.getTime() < Date.now():
        this.hasErrorFields.endDate = true;
        params = {
          isActive: this.hasErrorFields.endDate,
          message: content.online_test_time_enddate_past_now
        };
        break;
      case !endHour.match(HOURREGEX):
        this.hasErrorFields.endHour = true;
        params = {
          isActive: this.hasErrorFields.endHour,
          message: content.online_test_time_error_hour_format,
          arrowClassName: 'statusArrowOnSecondField'
        };
        break;
      case dateFormat(startDate) > dateFormat(endDate):
        this.hasErrorFields.endDate = true;
        params = {
          isActive: this.hasErrorFields.endDate,
          message: content.online_test_time_enddate_error_date_earlier
        };
        break;
      case startDate.getTime && endDate.getTime && startDate.getTime() > endDate.getTime():
        this.hasErrorFields.endHour = true;
        params = {
          isActive: this.hasErrorFields.endHour,
          message: content.online_test_time_enddate_error_hour_earlier,
          arrowClassName: 'statusArrowOnSecondField'
        };
        break;
      default:
        this.hasErrorFields.endDate = false;
        this.hasErrorFields.endHour = false;
        params = {
          isActive: false,
          message: ''
        };
    }
    const hasErrorsLocal = this._hasErrors();
    if (hasErrors !== hasErrorsLocal) {
      setHasErrors(hasErrorsLocal);
    }
    return params;
  };

  _getValidationDurationTest = () => {
    const { durationDays, durationHours, durationMinutes } = this.state;
    const {
      setHasErrors,
      hasErrors,
      localizedContent: { hubSetTestOnline: content }
    } = this.props;

    let params;

    if (durationDays + durationHours + durationMinutes === 0) {
      this.hasErrorFields.durationTest = true;

      params = {
        isActive: true,
        message: content.online_test_duration_error,
        arrowClassName: 'statusWithoutArrow'
      };
    } else {
      this.hasErrorFields.durationTest = false;
      params = {
        isActive: false,
        message: ''
      };
    }

    const hasErrorsLocal = this._hasErrors();
    if (hasErrors !== hasErrorsLocal) {
      setHasErrors(hasErrorsLocal);
    }

    return params;
  };

  _hasErrors = () => Object.values(this.hasErrorFields).some(value => value === true);

  _nextBtnDisabled = () => {
    const { showSetDurationTest } = this.state;
    const allErrors = this.hasErrorFields;
    if (
      (allErrors.durationTest && showSetDurationTest) ||
      allErrors.endDate ||
      allErrors.startHour ||
      allErrors.endHour
    ) {
      return true;
    }
    return false;
  };

  _setDefaultStartTime = () => {
    const { setStartDate, setEndDate, setStartHour, setEndHour } = this.props;
    const startDate = new Date(Date.now());
    const endDate = new Date(Date.now() + 3600 * 1000 * 24);
    let startHour = [startDate.getHours(), ':', startDate.getMinutes()].join('');
    startHour = getRoundHour(startHour);
    setHourOnDate(startDate, startHour);
    setHourOnDate(endDate, startHour);
    setStartHour(startHour);
    setEndHour(startHour);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  _setDaysTestDuration = durationDays => {
    this.setState({ durationDays });
    this._updateTestDuration({ ...this.state, durationDays });
  };

  _setHoursTestDuration = durationHours => {
    this.setState({ durationHours });
    this._updateTestDuration({ ...this.state, durationHours });
  };

  _setMinutesTestDuration = durationMinutes => {
    this.setState({ durationMinutes });
    this._updateTestDuration({ ...this.state, durationMinutes });
  };

  _updateTestDuration = ({ durationDays, durationHours, durationMinutes }) => {
    const { setDuration } = this.props;
    const durationTest = getMinutesFromDaysHourMinute(durationDays, durationHours, durationMinutes);
    setDuration(durationTest);
  };

  _renderBody = () => {
    const {
      localizedContent: { hubSetTestOnline: content },
      startDate,
      endDate,
      startHour,
      endHour,
      setStartDate,
      setEndDate,
      setStartHour,
      setEndHour,
      showTimeLimitToggle,
      showTooltipStartDate,
      showTooltipEndDate,
      isPlacementTest
      // toggleAssessmentLicenceCodeTab,
      // selectedStudentIds = [],
      // test,
      // takeDataFromTest = false
    } = this.props;

    const { showSetDurationTest, durationDays, durationHours, durationMinutes } = this.state;

    return (
      <div className={isPlacementTest ? styles.placementTestContainer : ''}>
        <div>
          <div className={styles.fieldsRowContainer}>
            <div className={styles.dateInfoRow}>
              <h2>{content.online_test_time_startdate_header}</h2>
              {showTooltipStartDate ? <p>{content.online_test_time_tooltip_startdate}</p> : null}
            </div>
            <ValidationStatus
              forId="startDate"
              containerClassName={styles.validationContainer}
              {...this._getValidationStartDate()}
            >
              <div className={styles.fieldsRow}>
                <div className={styles.fieldsElement}>
                  <DateField
                    id="startDate"
                    value={startDate}
                    label=""
                    placeholder=""
                    displayDateFormat="dd/MM/yyyy"
                    onChange={value => {
                      if (value && value !== 'Invalid date') {
                        const newDate = setHourOnDate(new Date(value), getStringHour(startDate));
                        setStartDate(newDate);
                      }
                    }}
                    onBlur={() => {}}
                    max="2050-01-01"
                  />
                </div>
                <div className={styles.fieldsElement}>
                  <TextInputField
                    id="startHour"
                    value={startHour}
                    onChange={value => {
                      if (value.match(ALLOWEDHOURREGEX)) {
                        setStartHour(value);
                        if (value.match(HOURREGEX)) {
                          setStartDate(setHourOnDate(startDate, value));
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </ValidationStatus>
          </div>
        </div>
        <div>
          <div className={styles.fieldsRowContainer}>
            <div className={styles.dateInfoRow}>
              <h2>{content.online_test_time_enddate_header}</h2>
              {showTooltipEndDate ? <p>{content.online_test_time_tooltip_enddate}</p> : null}
            </div>
            <ValidationStatus
              forId="endDate"
              containerClassName={styles.validationContainer}
              {...this._getValidationEndDate()}
            >
              <div className={styles.fieldsRow}>
                <div className={styles.fieldsElement}>
                  <DateField
                    id="endDate"
                    value={endDate}
                    label=""
                    placeholder=""
                    displayDateFormat="dd/MM/yyyy"
                    onChange={value => {
                      if (value && value !== 'Invalid date') {
                        const newDate = setHourOnDate(new Date(value), getStringHour(endDate));
                        setEndDate(newDate);
                      }
                    }}
                    onBlur={() => {}}
                    max="2050-01-01"
                  />
                </div>
                <div className={styles.fieldsElement}>
                  <TextInputField
                    id="endHour"
                    value={endHour}
                    onChange={value => {
                      if (value.match(ALLOWEDHOURREGEX)) {
                        setEndHour(value);
                        if (value.match(HOURREGEX)) {
                          setEndDate(setHourOnDate(endDate, value));
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </ValidationStatus>
          </div>
        </div>
        {showTimeLimitToggle && (
          <div>
            <div className={styles.fieldsRowContainer}>
              <div className={styles.fieldsRow}>
                <div>
                  <ToggleSwitch
                    id="showTestDurationToggle"
                    value={showSetDurationTest}
                    label={content.online_test_duration_toggle}
                    onChange={() => this._toggleSetDurationSet()}
                  />
                </div>
                <Tooltip title={content.online_test_duration_tooltip} className={styles.tooltipContainer}>
                  <SVGIcon glyph={GLYPHS.ICON_HELP_CIRCLE} className={styles.infoIcon} />
                </Tooltip>
              </div>
              {showSetDurationTest && (
                <ValidationStatus
                  forId="durationTest"
                  containerClassName={styles.validationContainer}
                  {...this._getValidationDurationTest()}
                >
                  <div className={styles.fieldsRow}>
                    <div className={styles.durationContainer}>
                      <div className={styles.durationSection}>
                        <OnlineTestDropdownOption
                          defaultValue={durationDays}
                          setUpdatedValue={this._setDaysTestDuration}
                          typeOption="day"
                          title={content.online_test_days}
                        />
                      </div>
                      <div className={styles.durationSection}>
                        <OnlineTestDropdownOption
                          defaultValue={durationHours}
                          setUpdatedValue={this._setHoursTestDuration}
                          typeOption="hour"
                          title={content.online_test_hours}
                        />
                      </div>
                      <div className={styles.durationSection}>
                        <OnlineTestDropdownOption
                          defaultValue={durationMinutes}
                          setUpdatedValue={this._setMinutesTestDuration}
                          typeOption="minute"
                          title={content.online_test_minutes}
                        />
                      </div>
                    </div>
                  </div>
                </ValidationStatus>
              )}
            </div>
          </div>
        )}
        <div>
          <div className={styles.fieldsRowContainer} />
        </div>
        {/*
        The info label is temporarily disabled (EPS-10154).
        The function everyStudentHasLicence() relies on 'licenceStudentsList' state which was previously mocked.
        We will have an implementation for setting the correct data to that field but until then this label is disabled
        {!everyStudentHasLicence(test, takeDataFromTest ? test.selectedStudentIds : selectedStudentIds, endDate) &&
          getInfoLabel(
            content.online_test_info_label,
            content.online_test_button_text_students_without_licence,
            styles.infoLabelContainer,
            GLYPHS.ICON_WARNING_CIRCLE,
            toggleAssessmentLicenceCodeTab
          )}
        */}
      </div>
    );
  };

  render() {
    const {
      test,
      assessmentTitle,
      next,
      prev,
      localizedContent: { hubSetTestOnline: content },
      onlyBody = false
    } = this.props;

    if (onlyBody) {
      return this._renderBody();
    }
    return (
      <ScrollContainer
        headerContent={
          <header>
            <div>
              <h2>{content.online_test_title}</h2>
              <div>{test ? `${assessmentTitle} ${getTestTitle(test)}` : ''}</div>
            </div>
          </header>
        }
        footerContent={
          <PopoutNavFooter
            backAction={data => prev(data)}
            nextAction={data => {
              next(data);
            }}
            nextButtonDisabled={this._nextBtnDisabled()}
            nextButtonText={content.online_test_next}
          />
        }
      >
        {this._renderBody()}
      </ScrollContainer>
    );
  }
}

export default compose(
  withLocalizedContent('hubSetTestOnline'),
  connect(
    ({ onlineTest }) => ({
      hasErrors: onlineTest.hasErrors,
      startDate: onlineTest.startDate,
      endDate: onlineTest.endDate,
      startHour: onlineTest.startHour,
      endHour: onlineTest.endHour,
      timeSet: onlineTest.timeSet,
      selectedStudentIds: onlineTest.selectedStudentIds,
      durationTest: onlineTest.durationInMinutes,
      isDurationTestSet: onlineTest.durationTestSet
    }),
    {
      setHasErrors: actions.setHasErrors,
      setStartDate: actions.setStartDate,
      setEndDate: actions.setEndDate,
      setStartHour: actions.setStartHour,
      setEndHour: actions.setEndHour,
      timeGotSet: actions.timeGotSet,
      toggleAssessmentLicenceCodeTab: toggleAssessmentLicenceTab,
      setDuration: actions.setDuration,
      showSetDuration: actions.showSetDuration
    }
  )
)(OnlineTestTime);

OnlineTestTime.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  next: PropTypes.func,
  prev: PropTypes.func,
  test: PropTypes.object,
  assessmentTitle: PropTypes.string,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  startHour: PropTypes.any,
  endHour: PropTypes.any,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  setStartHour: PropTypes.func,
  setEndHour: PropTypes.func,
  timeSet: PropTypes.bool,
  timeGotSet: PropTypes.func,
  onlyBody: PropTypes.bool,
  hasErrors: PropTypes.bool,
  setHasErrors: PropTypes.func,
  selectedStudentIds: PropTypes.array,
  toggleAssessmentLicenceCodeTab: PropTypes.func,
  takeDataFromTest: PropTypes.bool,
  setDuration: PropTypes.func,
  showSetDuration: PropTypes.func,
  durationTest: PropTypes.number,
  isDurationTestSet: PropTypes.bool,
  showTimeLimitToggle: PropTypes.bool,
  showTooltipStartDate: PropTypes.bool,
  showTooltipEndDate: PropTypes.bool,
  isPlacementTest: PropTypes.bool
};

OnlineTestTime.defaultProps = {
  assessmentTitle: '',
  showTimeLimitToggle: true,
  showTooltipStartDate: true,
  showTooltipEndDate: true,
  isPlacementTest: false
};
