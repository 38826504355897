import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userRoleFriendlyNames } from '../../../globals/userRoles.js';

import { switchOrg } from '../../../redux/reducers/identity.reducer.js';
import { inviteStates } from '../../../redux/reducers/invitationsBanner.reducer.js';
// Component imports
import Button, { buttonTypes } from '../../../components/Button/Button.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import styles from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.scss';

import cmsContent from '../../../utils/cmsContent.js';

let CMS = {};

const FAILED_INVITE = {
  requestFailed: true,
  orgName: CMS.organisation_text,
  failureReason: CMS.organisation_invite_failure_text
};

// Helper to decide which success/partial/error heading+icon to show:
const getIconHeading = (firstNameValue, orgNameValue, roleNameValue, requestFailed, failureReason) => {
  if (requestFailed) {
    // Invitation update FAILED
    return {
      type: iconHeadingTypes.ERROR,
      title: CMS.error_title_text,
      subtitle: (CMS.error_title_subtext || '')
        .replace('{organisationName}', orgNameValue)
        .replace('{failureReason}', failureReason)
    };
  }
  // Invitation updated SUCCESSFULLY :)
  return {
    type: iconHeadingTypes.VALID,
    title: (CMS.success_title_text || '')
      .replace('{firstName}', firstNameValue)
      .replace('{organisationName}', orgNameValue)
      .replace('{role}', userRoleFriendlyNames(roleNameValue) || roleNameValue)
  };
};

function InvitationConfirmation({ firstNameValue, invites, orgId, switchOrgAction }) {
  // Figure out which invitation has just been updated:
  const updated = (invites || []).find(invite => invite.state === inviteStates.UPDATED && invite.orgId === orgId);
  const updating = (invites || []).find(invite => invite.state === inviteStates.UPDATING && invite.orgId === orgId);
  const invite = updated || updating || FAILED_INVITE;
  const { orgName, roleName, requestFailed, failureReason } = invite;
  CMS = cmsContent.invitationConfirmationPage || {};

  const isNGS = /^\/ngs/.test(window.location.pathname);
  const _registerURLValidation = () => {
    if (requestFailed) {
      return '/register';
    }
    if (isNGS) {
      return '/ngs';
    }
    return '/';
  };
  return (
    <div className="row">
      <div className="col sm3" />
      <div className="col sm6">
        <PopoutPanelIconHeading {...getIconHeading(firstNameValue, orgName, roleName, requestFailed, failureReason)} />
        <div className={`gin-top4 text-center ${styles.buttonsContainerCenter}`}>
          {/* // If success then defer to root IndexRedirectHandler to redirect to a role-appropriate page: */}
          <Button
            id="redirectButton"
            text={requestFailed ? CMS.button_fail_text : CMS.button_success_text}
            to={_registerURLValidation()}
            onClick={
              requestFailed
                ? undefined
                : () => {
                    console.log('REDIRECT to IDP');
                    switchOrgAction(invite.orgId, invite.roleName, true);
                  }
            }
            type={buttonTypes.PRIMARY}
            preventDefault={!requestFailed}
          />
        </div>
      </div>
    </div>
  );
}

InvitationConfirmation.propTypes = {
  firstNameValue: PropTypes.string.isRequired,
  invites: PropTypes.array,
  orgId: PropTypes.string.isRequired,
  switchOrgAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    // Connect values
    firstNameValue: (state.people.data[state.identity.userId] || {}).firstname,
    invites: state.invites.invites,
    orgId: state.invites.orgId
  }),
  {
    switchOrgAction: switchOrg
  }
)(InvitationConfirmation);
