import React from 'react';
import PropTypes from 'prop-types';
// Constants
import { HubLayoutConstants, licenceStatus } from '../../../globals/hubConstants';
import breakpoints from '../../../globals/breakpoints';
// Components
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import Tooltip from '../../../components/Tooltip/Tooltip';

function HubLicenceStatusItem({
  licencesContext = '',
  styles,
  statusDetails: {
    text = '',
    fill = '',
    icon = '',
    date = '',
    availableCount = 0,
    isLicenceRemoved = false,
    status = '',
    archiveDate = null,
    manageClassData: { manageIcon = '', manageFill: managefill = '', manageText = '' } = {}
  } = {},
  hubContent,
  breakpoint
}) {
  const {
    LICENCES_CONTEXT: { CLASS, CLASSWORK_MANAGE, CLASSWORK, ORG, MY_COURSES }
  } = HubLayoutConstants;

  const mobileExpireStatus = (expiryStatus, expiryDate) => {
    if (!expiryStatus) {
      return null;
    }
    if (expiryStatus === licenceStatus.NO_LICENCE) {
      return `${hubContent.no_licence}`;
    }

    return expiryStatus === licenceStatus.EXPIRED
      ? `${hubContent.licence_expired_on} ${expiryDate}`
      : `${hubContent.licence_expires_on} ${expiryDate}`;
  };

  const renderUserStatusLicenceItem = () => (
    <div
      className={`${styles.licenceContextContainer} ${
        status === licenceStatus.NOT_STARTED ? styles.licenceContextContainerOpacified : ''
      } `}
    >
      <div
        className={styles.svgContainer}
        data-tooltip={breakpoint === breakpoints.XXS ? mobileExpireStatus(status, date) : null}
      >
        <SVGIcon glyph={icon} fill={fill} />
      </div>
      {breakpoint !== breakpoints.XXS && (
        <div className={styles.textContainerLicenceContext}>
          <span>{text}</span>
          <span
            style={{
              color: fill
            }}
          >
            {date}
          </span>
        </div>
      )}
    </div>
  );

  const renderOrgStatusLicenceItem = () => {
    let title = '';

    switch (icon) {
      case 'ICON_CHECK_CIRCLE':
        title = hubContent.many_licences_text;
        break;
      case 'ICON_WARNING_CIRCLE':
        title = hubContent.few_licences_text;
        break;
      case 'ICON_ERROR_CIRCLE':
        title = hubContent.no_licence_text;
        break;
      default:
        break;
    }

    return (
      <>
        <div className={styles.svgContainer}>
          <Tooltip className={styles.orgStatusLicenceItemTooltip} title={title}>
            <SVGIcon glyph={icon} fill={fill} />
          </Tooltip>
        </div>
        <div className={styles.textContainer}>
          <span
            className={styles.availableCount}
            style={{
              color: fill,
              fontSize: '1.2rem'
            }}
          >
            {availableCount}
          </span>
        </div>
      </>
    );
  };

  const renderRemovedLicenceItem = () => (
    <div className={styles.removedLicenceContainer}>
      <div
        className={styles.svgContainer}
        data-tooltip={
          breakpoint === breakpoints.XXS ? `${hubContent.classwork_removed_from_class} ${archiveDate}` : null
        }
      >
        <SVGIcon glyph={manageIcon} fill={managefill} />
      </div>
      {breakpoint !== breakpoints.XXS && (
        <div className={`${styles.manageStatusTextContainer} ${styles.textContainerRemovedLicence}`}>
          <span>{manageText}</span>
          <span>{archiveDate}</span>
        </div>
      )}
    </div>
  );

  const renderClassworkManageLicenceItem = () => {
    if (isLicenceRemoved) {
      return renderRemovedLicenceItem();
    }
    return (
      <div className={styles.manageLicenceStatusItem}>
        <div className={styles.svgContainer} data-tooltip={breakpoint === breakpoints.XXS ? manageText : null}>
          <SVGIcon glyph={manageIcon} fill={managefill} />
        </div>
        {breakpoint !== breakpoints.XXS && (
          <div className={styles.manageStatusTextContainer}>
            <span
              style={{
                color: managefill
              }}
            >
              {manageText}
            </span>
          </div>
        )}
      </div>
    );
  };

  const renderLicenceStatusItem = () => {
    switch (licencesContext) {
      case ORG:
        return renderUserStatusLicenceItem();
      case CLASS:
        return renderUserStatusLicenceItem();
      case CLASSWORK:
        return renderUserStatusLicenceItem();
      case MY_COURSES:
        return renderUserStatusLicenceItem();
      case CLASSWORK_MANAGE:
        return renderClassworkManageLicenceItem();
      default:
        return renderOrgStatusLicenceItem();
    }
  };

  return renderLicenceStatusItem();
}

HubLicenceStatusItem.defaultProps = {
  licencesContext: '',
  styles: {},
  breakpoint: ''
};

HubLicenceStatusItem.propTypes = {
  licencesContext: PropTypes.string.isRequired,
  breakpoint: PropTypes.string.isRequired
};

export default HubLicenceStatusItem;
