import { omit, pick } from 'lodash';

import * as t from '../actionTypes';

export const initialState = {
  parsing: false,
  failure: false,
  checking: false,
  uploading: false,
  validating: false,
  required: ['firstName', 'lastName'],
  source: null,
  users: {},
  existing: [],
  duplicates: [],
  archived: [],
  errors: {},
  queue: false,
  batchId: null,
  importRecordsCount: null,
  unsavedUsers: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.PARSE_IMPORT_USERS_SOURCE_REQUEST:
      return {
        ...state,
        parsing: true,
        source: payload
      };
    case t.PARSE_IMPORT_USERS_SOURCE_COMPLETED:
      return {
        ...state,
        parsing: false,
        failure: false,
        users: payload
      };
    case t.PARSE_IMPORT_USERS_SOURCE_FAILURE:
      return {
        ...state,
        parsing: false,
        failure: payload
      };
    case t.PARSE_IMPORT_USERS_SOURCE_RETRY:
      return {
        ...state,
        failure: false
      };
    case t.STORE_IMPORT_USERS_UPDATES_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          ...Object.keys(payload).reduce(
            (carry, id) => ({
              ...carry,
              [id]: {
                ...state.users[id],
                ...payload[id]
              }
            }),
            {}
          )
        }
      };
    case t.REMOVE_IMPORT_USERS_REQUEST:
      return {
        ...state,
        users: omit(state.users, [payload]),
        errors: omit(state.errors, [payload])
      };
    case t.CHECK_IMPORT_USERS_REQUEST:
      return {
        ...state,
        checking: true
      };
    case t.CHECK_IMPORT_USERS_COMPLETED:
      return {
        ...state,
        checking: false,
        users: payload.existing.reduce(
          (carry, id) => ({
            ...carry,
            [id]: {
              ...state.users[id]
            }
          }),
          state.users
        ),
        ...pick(payload, ['existing', 'duplicates', 'archived'])
      };
    case t.UPLOAD_IMPORT_USERS_REQUEST:
      return {
        ...state,
        batchId: null,
        uploading: true,
        queue: payload
      };
    case t.UPLOAD_IMPORT_USERS_SUCCESS:
      return {
        ...initialState,
        uploading: false,
        batchId: payload.batchId,
        importRecordsCount: payload.importRecordsCount
      };
    case t.VALIDATE_IMPORT_USERS_REQUEST:
      return {
        ...state,
        validating: true
      };
    case t.VALIDATE_IMPORT_USERS_COMPLETED:
      return {
        ...state,
        validating: false,
        errors: {
          ...pick(state.errors, Object.keys(state.users)),
          [payload.id]: {
            ...(state.errors[payload.id] || {}),
            ...payload.errors
          }
        }
      };
    case t.VALIDATE_IMPORT_USERS_LIST_COMPLETED:
      return {
        ...state,
        validating: false,
        errors: {
          ...pick(state.errors, Object.keys(state.users)),
          ...payload.errors
        }
      };
    case t.STORE_UNSAVED_USERS:
      return {
        ...state,
        unsavedUsers: payload.users
      };
    default:
      return state;
  }
};
