import { put, take } from 'redux-saga/effects';

import bowser from 'bowser';
import { isDev } from '../../../globals/envSettings.js';

import {
  CLOSE_COMPATIBILITY_BANNER,
  setBrowserIncompatible,
  setCompatibilityAck,
  closeCompatibilityBanner
} from '../../reducers/app.reducer.js';
// List of lowest browser supported version:
// IE 11
// Edge V39 (Edge HTML 15)
// Chrome 57
// Firefox 52
// Safari 10
// Chrome mobile 58
// The following npm package (bowser) is what is being used to check the users browser and version.
// Information: https://www.npmjs.com/package/bowser
// Source: https://github.com/lancedikson/bowser

export default function* browserCompatibility() {
  const compatibilityBannerAcknowledged = sessionStorage.getItem('compatibilityBannerAcknowledged');

  console.log(
    '[browserCompatibility] Attempting to read compatibility banner acknowledgement from storage',
    compatibilityBannerAcknowledged
  );

  // Create an object of the users browser name, version and operating system
  const usersBrowser = { name: bowser.name, version: bowser.version, os: bowser.osname };
  console.log('[browserCompatibility] Browser info:', usersBrowser);

  if (isDev) {
    sessionStorage.setItem('browserInfoOS', usersBrowser.os);
    sessionStorage.setItem('browserInfoName', usersBrowser.name);
    sessionStorage.setItem('browserInfoVersion', usersBrowser.version);
  }

  // Check if the browser is one of the supported types and vesion numbers
  if (
    (usersBrowser.name === 'Internet Explorer' && usersBrowser.version >= 11) ||
    (usersBrowser.name === 'Microsoft Edge' && usersBrowser.version >= 15) ||
    (usersBrowser.name === 'Chrome' && usersBrowser.version >= 57) ||
    (usersBrowser.name === 'Chrome' && usersBrowser.version >= 58 && usersBrowser.os === 'Android') ||
    (usersBrowser.name === 'Firefox' && usersBrowser.version >= 52) ||
    (usersBrowser.name === 'Safari' && usersBrowser.version >= 10) ||
    (usersBrowser.name === 'Safari' && typeof usersBrowser.version === 'undefined' && usersBrowser.os === 'iOS') ||
    (usersBrowser.name === 'Mozilla' && usersBrowser.version >= 5)
  ) {
    yield put(setBrowserIncompatible(false));
    yield put(closeCompatibilityBanner());
  } else {
    sessionStorage.setItem('browserIncompatible', true);
    yield put(setBrowserIncompatible(true));
    if (compatibilityBannerAcknowledged) {
      yield put(setCompatibilityAck());
    } else {
      while (true) {
        console.log('[browserCompatibility] Waiting for user to close the compatibility banner...');
        yield take(CLOSE_COMPATIBILITY_BANNER);
        sessionStorage.setItem('compatibilityBannerAcknowledged', true);
      }
    }
  }
}
