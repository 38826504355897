import React, { useState } from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ResourcesPlayerSidebar.scss';
import { GlyphIcon } from '../IconEmblem/IconEmblem';
import SVGIcon from '../SVGIcon/SVGIcon';
import FileLevelMenu from './FileLevelMenu';
import { NO_CATEGORY_ITEMS_KEY } from '../../globals/cptConstants';
import { setContent, setCurrentContent, toggleContentsSubmenuModal } from '../../redux/actions/structuredContentPlayer';
import { onKeyDownHandler } from '../../services/cptHelperMethods';

function CategoriesLevelMenu({ items, glyph, cptContent }) {
  const [isOpen, setIsOpen] = useState(items);

  function handleUnitToggle(unitId) {
    const newData = isOpen[unitId];
    newData.value = !newData.value;
    setIsOpen({ ...isOpen, [unitId]: newData });
  }

  const renderItems = category => {
    if (isOpen[category].value) {
      return isOpen[category].items.map((item, itemIndex) => (
        <div className={styles.fileLevelMenuWrapper} key={`${item.title}_${itemIndex}`}>
          <FileLevelMenu
            fileName={item.title}
            glyph={glyph}
            type={item.type}
            resourceIdValue={item.resourceId}
            subtitleResourceIdValue={item.subtitleResourceId}
            itemId={item.id}
            cptContent={cptContent}
          />
        </div>
      ));
    }
    return null;
  };

  const renderCategories = category => (
    <div
      className={classnames(styles.menuItem, styles.categoryItem)}
      role="button"
      tabIndex={0}
      onClick={() => handleUnitToggle(category)}
      onKeyDown={onKeyDownHandler(() => handleUnitToggle(category))}
    >
      <p>{isOpen[category].title}</p>
      <div
        role="button"
        className={isOpen[category].value ? `${styles.caratIcon} ${styles.caratIconReverse}` : `${styles.caratIcon}`}
      >
        <SVGIcon glyph={GlyphIcon.ICON_CARAT} className={styles.icon} />
      </div>
    </div>
  );

  return (
    <div className={styles.subMenuContainer}>
      {Object.keys(isOpen).map((category, categoryIndex) => (
        <div key={`${category}_${categoryIndex}`}>
          {category !== NO_CATEGORY_ITEMS_KEY ? renderCategories(category) : null}
          {renderItems(category)}
        </div>
      ))}
    </div>
  );
}

CategoriesLevelMenu.propTypes = {
  items: PropTypes.object.isRequired,
  glyph: PropTypes.string.isRequired,
  cptContent: PropTypes.object
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setContentFromData: setContent,
  setCurrentContentData: setCurrentContent,
  toggleModal: toggleContentsSubmenuModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CategoriesLevelMenu);
