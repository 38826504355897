import { put } from 'redux-saga/effects';
import unsignedFetch from '../../../apiCalls/util/unsignedFetch.js';
import actions from '../../../../actions';
import PersistentStorage from '../../../../../utils/storage/Persistent';
import getRVSApiBase from '../../../utils/rvs/getRVSApiBase';
import { addToValidCertChannel, addToInvalidCertChannel } from '../../../utils/rvs/certificateChannels';
import { getCurrentPlatform } from '../../../../../utils/platform.js';
import getLocalStorageTempClientId from '../../../utils/clientId.js';

function* submit(data) {
  const payload = data;
  const apiBase = getRVSApiBase();

  const platform = getCurrentPlatform();
  const tempClientId = getLocalStorageTempClientId(platform);
  const response = yield unsignedFetch(
    'rvsUpload',
    `${apiBase}/open/file/certificate`,
    'POST',
    payload,
    {
      'temp-client-id': tempClientId
    },
    true
  );

  if (response.status === 'success') {
    if (
      typeof response.data.verification_unavailable !== 'undefined' &&
      response.data.verification_unavailable === true
    ) {
      yield put(actions.rvsUploadServiceUnavailable());
    } else if (response.data.valid) {
      addToValidCertChannel(response.data.certificate);
      yield put(actions.rvsUploadSuccess());
    } else if (response.data.certificate) {
      addToInvalidCertChannel(response.data.certificate);
      yield put(actions.rvsUploadInvalid());
    } else {
      yield put(actions.rvsUploadError());
    }
  } else {
    const uploadErrorPersistentStorage = new PersistentStorage('rvsCertificateUploadError');
    uploadErrorPersistentStorage.set(response.message);

    yield put(actions.rvsUploadError());
  }
}

export default submit;
