import PropTypes from 'prop-types';
import React from 'react';
import PanelNavigationLink from '../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import ScrollContainer from '../../../../../components/ScrollContainer/ScrollContainer.js';
import content from '../../../../../utils/cmsContent.js';

const getIconHeading = (CMS, error, organisationName) => {
  if (error) {
    return {
      title: CMS.confirmation_error_title,
      subtitle: 'error',
      type: iconHeadingTypes.ERROR
    };
  }

  return {
    title: CMS.confirmation_success_title_userAdded.replace('{organisationName}', organisationName),
    subtitle: CMS.confirmation_success_subtitle,
    type: iconHeadingTypes.VALID
  };
};

function JoinAClassConfirmation({ orgClassDetails: { organisationName, className }, failed, closePanel, onComplete }) {
  const CMS = content.studentRequestToJoinAClass;
  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePanel} />
          </div>
          <PopoutPanelIconHeading {...getIconHeading(CMS, failed, organisationName)} />
        </div>
      }
      footerContent={
        <PopoutActionFooter primaryButtonAction={onComplete} primaryButtonText={CMS.confirmation_button_done} />
      }
    >
      <div>
        <div style={{ padding: '1rem' }}>
          <div className="text-center">
            <h2>{CMS.review_org_text}</h2>
            <p className="lead">{organisationName}</p>
            <h2>{CMS.review_class_text}</h2>
            <p className="lead">{className}</p>
          </div>
        </div>
      </div>
    </ScrollContainer>
  );
}

JoinAClassConfirmation.propTypes = {
  closePanel: PropTypes.func.isRequired,
  failed: PropTypes.bool.isRequired,
  apiError: PropTypes.object,
  onComplete: PropTypes.func,
  orgClassDetails: PropTypes.object
};

export default JoinAClassConfirmation;
