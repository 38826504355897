import { call, fork, select } from 'redux-saga/effects';
import authorisedFunctions from './authorisedFunctions/authorisedFunctions.saga';
import countryCodesRootSaga from './countryCodes';
import userRootSaga from './unauthorisedFunctions/user';
import unauthorisedFunctions from './unauthorisedFunctions/unauthorisedFunctions.saga';

export default function* appFunctions() {
  const userAuthorised = yield select(state => !!state.identity.userId);

  yield fork(countryCodesRootSaga);
  yield fork(userRootSaga);

  if (userAuthorised) {
    yield call(authorisedFunctions);
  } else {
    yield call(unauthorisedFunctions);
  }
}
