import moment from 'moment';

import { formatDateWithDayIncluded } from '@oup/shared-node-browser/dateTime.js';

export default function getAssessmentParameters(
  classroom = {},
  test = {},
  selectedStudentIds = [],
  totalNumberOfStudents = [],
  isCancelPanelFlow = false,
  startDate = '',
  endDate = '',
  startHour = '',
  endHour = ''
) {
  if (isCancelPanelFlow) {
    return {
      className: classroom?.name,
      numberOfSelectedStudents: test?.selectedStudentIds?.length || selectedStudentIds?.length,
      numberOfStudents: totalNumberOfStudents || [],
      startDate: test !== {} ? formatDateWithDayIncluded(test?.testStartDate) : '',
      endDate: test !== {} ? formatDateWithDayIncluded(test?.testEndDate) : '',
      startHour: test !== {} ? moment(test?.testStartDate).format('HH:mm') : '',
      endHour: test !== {} ? moment(test?.testEndDate).format('HH:mm') : ''
    };
  }

  return {
    className: classroom?.name,
    numberOfSelectedStudents: selectedStudentIds?.length,
    numberOfStudents: totalNumberOfStudents || [],
    startDate: startDate !== '' ? formatDateWithDayIncluded(startDate) : '',
    endDate: endDate !== '' ? formatDateWithDayIncluded(endDate) : '',
    startHour,
    endHour
  };
}
