import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import sendOicEmailsRequest from '../../../api/sendOicEmails';

function* sendOicEmails(template = '') {
  const response = yield sendOicEmailsRequest(template);
  if (response.status !== 'success') {
    yield put(actions.sendOicEmailsFailure(response.error));
    return;
  }
  yield put(actions.sendOicEmailsSuccess(response));
}

export default sendOicEmails;
