import { all, put, select } from 'redux-saga/effects';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import actions from '../../../../actions';

export const PollPlacementTestStudentOperation = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE'
};

export default function* pollPlacementTestStudents(ids, operation, students, placementTestId) {
  if (!featureIsEnabled('data-recency') || !featureIsEnabled('data-recency-class-students') || !ids.length) {
    return;
  }
  const { orgId } = yield select(state => ({
    orgId: state?.identity?.currentOrganisationId
  }));

  const polls = Object.entries(students).reduce(
    (carry, [userId, { roleName: role, lastUpdatedTimeStamp }]) => ({
      ...carry,
      [role]: {
        orgId,
        classId: placementTestId,
        role,
        collection: 'classStudents',
        users: [
          ...(carry[role] ? carry[role].users : []),
          { userId, ...(operation === PollPlacementTestStudentOperation.UPDATE ? { lastUpdatedTimeStamp } : {}) }
        ],
        operation,
        target: 'users',
        responseEntityKey: 'userPollingStatus',
        identifier: 'userId',
        search: 'classStudents'
      }
    }),
    {}
  );

  yield all(Object.values(polls).map(poll => put(actions.pollEntitiesSynced(poll))));
}

export function pollPlacementTestStudentsAdded(ids, students, placementTestId) {
  return pollPlacementTestStudents(ids, PollPlacementTestStudentOperation.ADD, students, placementTestId);
}

export function pollClassStudentsUpdated(ids, placementTestId) {
  return pollPlacementTestStudents(ids, PollPlacementTestStudentOperation.UPDATE, placementTestId);
}

export function pollClassStudentsRemoved(ids, placementTestId) {
  return pollPlacementTestStudents(ids, PollPlacementTestStudentOperation.REMOVE, placementTestId);
}
