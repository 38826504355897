import { put, select } from 'redux-saga/effects';
import { getBasketResourcesSuccess, getBasketResourcesFailure } from '../../../../../actions/ngsCourse';
import getBasketResourcesApiCall from '../../../../apiCalls/ngs/getCourseResources';

function* getBasketResources({ orgId, courseId, nodeId, resourceId, format, sort, nextPage }) {
  const page = { size: 20 };

  if (nextPage) {
    const numberOfResources = yield select(state => state.ngsCourse.assignmentBasket.resources.items.length);
    page.start = numberOfResources;
  }

  const response = yield getBasketResourcesApiCall(orgId, courseId, nodeId, resourceId, format, sort, page);

  if (response.status === 'success') {
    yield put(getBasketResourcesSuccess(response.data, nextPage));
    return;
  }

  yield put(getBasketResourcesFailure(response.message, nextPage));
}

export default getBasketResources;
