import getPeopleActionButtons from '../../../structure/HubClassLayout/Services/getPeopleActionButtons.js';
import getOrgClassesActionButtons from '../../../structure/HubDashboardLayout/Services/getOrgClassesActionButtons';
import getPlacementTestActionButtons from '../../../structure/HubDashboardLayout/Services/getPlacementTestActionButtons';
import getOrgLicencesActionButtons from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/getOrgLicencesActionButtons';
import getUserLicencesActionButtons from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/getUserlicencesActionButtons';
import getOrgPeopleDropdownItems from '../../../structure/HubOrganizationLayout/Services/getOrgPeopleDropdownItems';
import getClassworkLicencesActionButtons from '../../../structure/HubClassLayout/Classwork/services/getClassworkLicencesActionButtons';
import getTestDropdownItems from '../../../structure/HubDashboardLayout/Services/getTestDropdownItems';
import getClassTestActionButtons from '../../../structure/HubClassLayout/Services/getClassTestActionButtons.js';
import getCourseMaterialActionButtons from '../../../structure/HubCourseAssessments/Services/getCourseMaterialActionButtons';
import getOrgLMSConfigActionButtons from '../../../structure/HubDashboardLayout/Services/getOrgLMSConfigActionButtons';
import getProductLicencesActionButton from '../../../structure/HubDashboardLayout/Services/getProductLicencesActionButtons';

const getDropdownItems = (
  item,
  hubContent,
  dropdownOptions = {},
  dropdownActions = {},
  userRole,
  isSelfSelectedOrgAdmin,
  currentOrganisationLti = false,
  isMyTasksPage = false,
  placementTestsContent
) => {
  const data = {
    classTile: {
      menuItems: getOrgClassesActionButtons(hubContent, dropdownActions, dropdownOptions)[userRole],
      menuPanelAriaLabel: hubContent.archive_class_panel_menu_aria,
      menuButtonAriaLabel: hubContent.archive_class_dropdown_aria
    },
    people: {
      menuItems: getPeopleActionButtons({ hubContent, dropdownOptions, dropdownActions, currentOrganisationLti }),
      menuPanelAriaLabel: hubContent.people_panel_menu_aria,
      menuButtonAriaLabel: hubContent.people_dropdown_aria
    },
    classes: {
      menuItems: getOrgClassesActionButtons(hubContent, dropdownActions, dropdownOptions)[userRole],
      menuPanelAriaLabel: hubContent.org_classes_panel_menu_aria,
      menuButtonAriaLabel: hubContent.org_classes_dropdown_aria
    },
    placementTestSession: {
      menuItems: getPlacementTestActionButtons(
        hubContent,
        dropdownActions,
        dropdownOptions,
        isMyTasksPage,
        placementTestsContent
      ),
      menuPanelAriaLabel: hubContent.placement_test_session_panel_menu_aria,
      menuButtonAriaLabel: hubContent.placement_test_session_dropdown_aria
    },
    licences: {
      menuItems: getOrgLicencesActionButtons(hubContent, dropdownActions, dropdownOptions)[userRole],
      menuPanelAriaLabel: hubContent.org_licences_panel_menu_aria,
      menuButtonAriaLabel: hubContent.org_licences_dropdown_aria
    },
    userLicences: {
      menuItems: getUserLicencesActionButtons(hubContent, dropdownActions, dropdownOptions, currentOrganisationLti)[
        userRole
      ],
      menuPanelAriaLabel: hubContent.org_licences_panel_menu_aria,
      menuButtonAriaLabel: hubContent.org_licences_dropdown_aria
    },
    classworkLicences: {
      menuItems: getClassworkLicencesActionButtons(
        hubContent,
        dropdownActions,
        dropdownOptions,
        currentOrganisationLti
      )[userRole],
      menuPanelAriaLabel: hubContent.org_licences_panel_menu_aria,
      menuButtonAriaLabel: hubContent.org_licences_dropdown_aria
    },
    orgStudents: {
      menuItems: getOrgPeopleDropdownItems(
        hubContent,
        dropdownOptions,
        dropdownActions,
        isSelfSelectedOrgAdmin,
        currentOrganisationLti
      )[userRole],
      menuPanelAriaLabel: hubContent.org_students_panel_menu_aria,
      menuButtonAriaLabel: hubContent.org_students_dropdown_aria
    },
    orgStaff: {
      menuItems: getOrgPeopleDropdownItems(
        hubContent,
        dropdownOptions,
        dropdownActions,
        isSelfSelectedOrgAdmin,
        currentOrganisationLti
      )[userRole],
      menuPanelAriaLabel: hubContent.org_staff_panel_menu_aria,
      menuButtonAriaLabel: hubContent.org_staff_dropdown_aria
    },
    assessmentTests: {
      menuItems: getTestDropdownItems(hubContent, dropdownActions, dropdownOptions),
      menuPanelAriaLabel: hubContent.assessment_test_panel_menu_aria,
      menuButtonAriaLabel: hubContent.assessment_test_dropdown_aria
    },
    classAssessmentTests: {
      menuItems: getClassTestActionButtons(hubContent, dropdownActions, dropdownOptions, userRole)[userRole],
      menuPanelAriaLabel: hubContent.class_assessment_test_panel_menu_aria,
      menuButtonAriaLabel: hubContent.class_assessment_test_dropdown_aria
    },
    productList: {
      menuItems: getCourseMaterialActionButtons(hubContent, dropdownActions),
      menuPanelAriaLabel: hubContent.product_list_dropdown_panel_aria,
      menuButtonAriaLabel: hubContent.product_list_dropdown_button_aria
    },
    lmsConfigTools: {
      menuItems: getOrgLMSConfigActionButtons(hubContent, dropdownActions, dropdownOptions)[userRole],
      menuPanelAriaLabel: hubContent.org_classes_panel_menu_aria,
      menuButtonAriaLabel: hubContent.org_classes_dropdown_aria
    },
    productLicences: {
      menuItems: getProductLicencesActionButton(hubContent, dropdownActions),
      menuPanelAriaLabel: hubContent.product_licences_menu_aria,
      menuButtonAriaLabel: hubContent.product_licences_dropdown_aria
    }
  };
  return data[item];
};

export default getDropdownItems;
