import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import styles from './OicTopRow.scss';
import LandingImage from './LandingImage.js';

export default function OicTopRow({ localizedContent }) {
  return (
    <div className={classnames(styles.OicHomeTopRow, 'grid pad-top2')}>
      <div className="row justify-content-between">
        <div className="col md6 sm8 gin-bot2">
          <div>
            <h3 className={styles.title}>{localizedContent.homepage_headline}</h3>
            <p className={classnames('gin-top2 gin-bot2', styles.description)}>
              {localizedContent.homepage_description}
            </p>
            <ul>
              <li>
                <strong>{localizedContent.homepage_list_first_element_strong}</strong>{' '}
                {localizedContent.homepage_list_first_element}
              </li>
              <li>
                <strong>{localizedContent.homepage_list_second_element_strong}</strong>{' '}
                {localizedContent.homepage_list_second_element}
              </li>
              <li>
                <strong>{localizedContent.homepage_list_third_element_strong}</strong>{' '}
                {localizedContent.homepage_list_third_element}
              </li>
            </ul>
            <Button
              text={localizedContent.homepage_call_to_action}
              customClassName={classnames('gin-top2 gin-bot4', styles.cta)}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              to={localizedContent.homepage_call_to_action_url}
              hidePostIcon
            />
          </div>
        </div>
        <div className={classnames(styles.kv, 'gin-top2 col md5 sm4')}>
          <LandingImage
            urls={{
              webp: '/static/images/oic/landing-image/oisa.webp',
              default: '/static/images/oic/landing-image/oisa.jpg'
            }}
            className={classnames(styles.landingImage)}
          />
        </div>
      </div>
    </div>
  );
}

OicTopRow.propTypes = {
  localizedContent: PropTypes.object
};
