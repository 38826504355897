import numeral from 'numeral';

/**
 * Converts a time string in the format 'HH:mm:ss'
 * to a time, in seconds, as a number.
 *
 * @param {String} time String representation of time
 *
 * @return {Number} Time in seconds
 */
export const timeToSec = time => time.split(':').reduce((carry, segment) => 60 * carry + +segment);

/**
 * Converts a number (of seconds) into a string
 * representation of time as 'HH:mm:ss'.
 *
 * @param {Number} sec Seconds as a number
 *
 * @return {String} Time as string
 */
export const secToTime = sec => numeral(sec).format('00:00:00');
