import moment from 'moment';

// Page load actions
export const LOAD_SAVED_SETTINGS = 'savedSettings/LOAD_SAVED_SETTINGS';
export const STORE_SAVED_SETTINGS = 'savedSettings/STORE_SAVED_SETTINGS';

export const initialState = {
  loaded: false,
  settings: {
    attemptFilter: 'best',
    tableSpacing: 'medium',
    rangeValue: 50,
    useFilterByScore: false,
    orbClassReportTab: 0,
    classReport: {
      dateIsSelected: true,
      startDate: new Date(moment().subtract('7', 'days')),
      endDate: new Date(moment()),
      userChangedDate: false
    },
    schoolReport: {
      dateIsSelected: true,
      startDate: new Date(moment().subtract('7', 'days')),
      endDate: new Date(moment()),
      userChangedDate: false
    }
  }
};

export default function savedSettings(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SAVED_SETTINGS:
      return {
        ...initialState,
        loaded: true
      };

    case STORE_SAVED_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.settings,
          classReport: {
            ...state.settings.classReport,
            ...action.settings.classReport
          },
          schoolReport: {
            ...state.settings.schoolReport,
            ...action.settings.schoolReport
          }
        }
      };

    default:
      return state;
  }
}

export const loadSavedSettings = () => ({
  type: LOAD_SAVED_SETTINGS
});

export const storeSavedSettings = settings => ({
  type: STORE_SAVED_SETTINGS,
  settings
});
