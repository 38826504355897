import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import { formStates, resetForm } from '../../../../../redux/reducers/addToProfile.reducer';
import AddToProfileInputting from './AddToProfileInputting';
import AddToProfileSubmitting from './AddToProfileSubmitting';
import AddToProfileErrorReviewing from './AddToProfileErrorReviewing';
import AddToProfileConfirmation from './AddToProfileConfirmation';

class AddToProfile extends React.Component {
  static clearRedeemCodeValueFromLS = redeemCodeKey => {
    localStorage.removeItem(redeemCodeKey);
  };

  handleClose = () => {
    const { resetAction, closePanel } = this.props;

    resetAction();
    closePanel();
  };

  handleOnComplete = () => {
    const { resetAction, onComplete, errorOccurred } = this.props;

    resetAction();
    if (!errorOccurred) {
      onComplete();
    }
  };

  renderRedeemcodePanel = () => {
    const { resetAction, showProfileFormAction } = this.props;
    resetAction();
    showProfileFormAction();
  };

  render() {
    const {
      productData,
      productFetchError,
      productDetailsLoading,
      errorOccurred,
      errorMessage,
      closePanel,
      formState,
      onRedeemed
    } = this.props;

    let content;

    switch (formState) {
      case formStates.INPUTTING:
        content = <AddToProfileInputting closePanel={closePanel} />;
        AddToProfile.clearRedeemCodeValueFromLS(APP_CONSTANTS.REDEEM_CODE);
        break;
      case formStates.SUBMITTING:
        content = <AddToProfileSubmitting />;
        break;
      case formStates.CONFIRMATION:
        content = (
          <AddToProfileConfirmation
            productData={productData}
            productFetchError={productFetchError}
            productDetailsLoading={productDetailsLoading}
            errorOccurred={errorOccurred}
            errorMessage={errorMessage}
            closePanel={this.handleClose}
            onComplete={this.handleOnComplete}
            onRedeemed={onRedeemed}
            renderRedeemcodePanel={this.renderRedeemcodePanel}
          />
        );
        break;

      case formStates.ERROR_REVIEWING:
        content = <AddToProfileErrorReviewing closePanel={closePanel} />;
        break;

      default:
        content = (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }

    return <div>{content}</div>;
  }
}

AddToProfile.propTypes = {
  formState: PropTypes.string.isRequired,
  productData: PropTypes.object,
  productFetchError: PropTypes.bool,
  productDetailsLoading: PropTypes.bool,
  errorOccurred: PropTypes.bool,
  errorMessage: PropTypes.object,
  resetAction: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onRedeemed: PropTypes.func,
  showProfileFormAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    formState: state.addToProfile.formState,
    email: state.identity.email,
    errorOccurred: state.addToProfile.errorOccurred,
    errorMessage: state.addToProfile.errorMessage,
    productData: state.addToProfile.productData,
    productFetchError: state.addToProfile.productFetchError,
    productDetailsLoading: state.addToProfile.productDetailsLoading,
    instance: state.library.instance
  }),
  {
    resetAction: resetForm
  }
)(AddToProfile);
