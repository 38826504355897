import * as t from '../actionTypes';

export const getUserLicencesRequest = (criteria, value) => ({
  type: t.GET_USER_LICENCES_REQUEST,
  payload: { criteria, value }
});

export const getUserLicencesSuccess = userLicences => ({
  type: t.GET_USER_LICENCES_SUCCESS,
  payload: userLicences
});

export const getUserLicencesFailure = errors => ({
  type: t.GET_USER_LICENCES_FAILURE,
  payload: errors
});

export const getUserLicencesReset = () => ({
  type: t.GET_USER_LICENCES_RESET,
  payload: null
});
