import { put } from 'redux-saga/effects';
import getApiContentFetch from '../../../apiCalls/cptStructuredContentPlayer/getDictionaryData';
import { loadDictionaryDataSuccess, loadDictionaryDataFailure } from '../../../../actions/structuredContentPlayer';

function* getDictionaryData(params) {
  const response = yield getApiContentFetch(params);
  if (response.status === 'success') {
    yield put(loadDictionaryDataSuccess(response.data));
  } else {
    yield put(loadDictionaryDataFailure(response.error));
  }
}

export default getDictionaryData;
