import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import styles from './ORBRegionLayout.scss';

import cmsContent from '../../utils/cmsContent.js';
import Feature from '../../environment/Feature';

import NotFoundView from '../../views/errors/NotFoundView';
import ConnectedBannerInvitation from '../../components/BannerInvitation/ConnectedBannerInvitation.js';
import HeadersAndBanners from '../HeadersAndBanners/HeadersAndBanners.js';
import SessionExpiredPopup from '../SessionExpiredPopup/SessionExpiredPopup.js';
import SitewideProductPopup from '../SitewideProductPopup/SitewideProductPopup.js';
import ORBSiteHeader from '../ORBSiteHeader/ORBSiteHeader';

function ORBRegionLayout({ embedded, children }) {
  const CMS = cmsContent.appLayout || {};

  const isDemoLoginPage = false;
  const isCESPage = true;
  console.log('CHILDREN :', children);

  return (
    <div id="orbRegionLayout" className={classnames(styles.app, isCESPage ? styles.white : '')}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={CMS.pageTitle || 'Loading'}
        titleTemplate={CMS.titleTemplate || '%s | Oxford University Press'}
      />
      <div>
        <HeadersAndBanners
          customSiteHeaderComponent={ORBSiteHeader}
          isEmbedded={embedded}
          hideSubHeader={isDemoLoginPage}
          hideRegisterLink={isDemoLoginPage}
          hideSignInLink={isDemoLoginPage}
          hideWidgetDropDown={isDemoLoginPage}
          hideHelpLink={isDemoLoginPage}
          disableLogoLink
        />
        <div
          className={classnames(styles.pageContainer, {
            [styles.pageContainerEmbedded]: embedded,
            [styles.pageContainerWithoutNavbar]: isDemoLoginPage
          })}
        >
          <ConnectedBannerInvitation urlPrefix="/" />
          <main id="maincontent">
            <Feature render={() => children} renderFallback={NotFoundView} />
            <SitewideProductPopup />
            <SessionExpiredPopup />
          </main>
        </div>
      </div>
    </div>
  );
}

ORBRegionLayout.propTypes = {
  embedded: PropTypes.bool.isRequired,
  children: PropTypes.any
};

export default compose(
  connect(state => {
    const {
      identity: { role: userRole }
    } = state;

    return {
      userRole,
      orgId: state.identity.currentOrganisationId,
      isAtLeastTeacher: true
    };
  })
)(ORBRegionLayout);
