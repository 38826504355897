import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const sendDeferredEmailsUrl = ({ orgId, userRole }) =>
  `${__API_BASE__}/user/deferred-email/release/${orgId}/${userRole}`;

function sendDeferredEmails(payload) {
  return signedFetch('releaseDeferredEmails', sendDeferredEmailsUrl(payload));
}

export default sendDeferredEmails;
