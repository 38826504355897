import React from 'react';
import PropTypes from 'prop-types';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import RangeSlider from '../RangeSlider/RangeSlider';
import withLocalizedContent from '../../language/withLocalizedContent';

function FilterByScore({
  localizedContent: { classProgress: localizedContent },
  rangeValue,
  rangeValueOnchange,
  useFilterByScore,
  onUseFilterByScoreOnChange
}) {
  return (
    <div className="row" style={{ alignItems: 'center' }}>
      <div className="col sm4">
        <ToggleSwitch
          id="notifyWithEmail"
          value={useFilterByScore}
          label={localizedContent.highlight_by_score}
          onChange={onUseFilterByScoreOnChange}
        />
      </div>
      <div className="col sm8">
        <RangeSlider
          id="rangeValues"
          name="rangSlider"
          min={0}
          max={100}
          step={1}
          value={rangeValue}
          onChange={rangeValueOnchange}
          label={localizedContent.highlight_scores_below.replace('{scoresValue}', rangeValue)}
          disabled={!useFilterByScore}
        />
      </div>
    </div>
  );
}

FilterByScore.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  rangeValue: PropTypes.string.isRequired,
  rangeValueOnchange: PropTypes.func.isRequired,
  useFilterByScore: PropTypes.bool.isRequired,
  onUseFilterByScoreOnChange: PropTypes.func.isRequired
};

export default withLocalizedContent('classProgress')(FilterByScore);
