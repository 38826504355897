import React from 'react';
import PropTypes from 'prop-types';
import PopoutPanelIconHeading, { types } from '../../PopoutPanelIconHeading/PopoutPanelIconHeading.js';

function ProductFinderSubmitting({ productFinderContent }) {
  return (
    <PopoutPanelIconHeading
      type={types.LOADING}
      title={productFinderContent.loading_page_title}
      subtitle={productFinderContent.loading_page_subtitle}
    />
  );
}

ProductFinderSubmitting.propTypes = {
  productFinderContent: PropTypes.object.isRequired
};

export default ProductFinderSubmitting;
