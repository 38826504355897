import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/actions';

import { GLYPHS } from '../SVGIcon/SVGIcon.js';
import Button, { buttonTypes } from '../Button/Button';

const RESOURCE_TYPES_GLYPHS = {
  flashcard: GLYPHS.ICON_FLASHCARD,
  book: GLYPHS.ICON_EBOOK,
  quiz: GLYPHS.ICON_QUIZ,
  worksheet: GLYPHS.ICON_WORKSHEET,
  teaching_note: GLYPHS.ICON_TEACHING_NOTES,
  generic: GLYPHS.ICON_TEACHING_NOTES
};

class OrbLearningObjectLink extends React.Component {
  renderRegularLOLink() {
    const { content, book, title, standalone, teacherOpenContent } = this.props;

    let dataProps = {};
    if (content.type === 'quiz' && !standalone) {
      dataProps = {
        'data-open-quiz-panel': true,
        'data-id': book.id,
        'data-url': `/contents/${book.id}`
      };
    } else {
      dataProps = {
        'data-id': content.id,
        'data-url': `/contents/${content.id}`
      };
    }

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in content.options) {
      dataProps[`data-${key.replace(/_/g, '-')}`] = content.options[key];
    }

    return (
      <Button
        glyph={RESOURCE_TYPES_GLYPHS[content.type]}
        glyphViewBox={'15 0 10 40'}
        onClick={() => teacherOpenContent(dataProps['data-id'], !!dataProps['data-open-quiz-panel'], false)}
        type={buttonTypes.ORB_LEARNING_OBJECT}
        text={title || content.title}
        {...dataProps}
      />
    );
  }

  renderDownloadableLOLink() {
    const { content, teacherOpenContent } = this.props;

    return (
      <Button
        glyph={RESOURCE_TYPES_GLYPHS[content.type]}
        glyphViewBox={'15 0 10 40'}
        onClick={() => teacherOpenContent(content.id, false, true)}
        target="_blank"
        rel="noopener noreferrer"
        type={buttonTypes.ORB_LEARNING_OBJECT}
        text={content.title}
      />
    );
  }

  render() {
    const { downloadable } = this.props;
    return downloadable ? this.renderDownloadableLOLink() : this.renderRegularLOLink();
  }
}

OrbLearningObjectLink.propTypes = {
  content: PropTypes.object.isRequired,
  className: PropTypes.string,
  downloadable: PropTypes.bool,
  standalone: PropTypes.bool,
  teacherOpenContent: PropTypes.func,
  book: PropTypes.object,
  title: PropTypes.string
};

OrbLearningObjectLink.defaultProps = {
  downloadable: false
};

export default connect(
  () => ({}),
  dispatch => ({
    teacherOpenContent: (contentId, openQuizPanel, downloadable) => {
      dispatch(actions.teacherOpenContent(contentId, openQuizPanel, downloadable));
    },
    teacherCloseContent: () => {
      dispatch(actions.teacherCloseContent());
    }
  })
)(OrbLearningObjectLink);
