import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { MODAL_CLASSES } from '../../globals/cptConstants';
import PopoutPanel from '../../components/PopoutPanel/PopoutPanel';
import {
  closeStructuredContentPlayerModal,
  openStructuredContentPlayerModal,
  setModalBasePath,
  setModalCloseLink,
  setModalContentId
} from '../../redux/actions/structuredContentPlayer';

import { loadEreaderContentRequest } from '../../redux/actions/ereaderContentPlayer';
import EreaderContentPlayer from '../../components/StructuredContentPlayer/EreaderContentPlayer';
import withLocalizedContent from '../../language/withLocalizedContent';
import { openRedeemModal, closeMissingLicenseModal } from '../../redux/actions/hubUi';

function EreaderContentPlayerBlankPage({
  isModalOpen,
  openStructuredContentPlayerModalAction,
  closeStructuredContentPlayerModalAction,
  setModalBasePathAction,
  loadEreaderContentRequestData,
  setModalContentIdAction
}) {
  const params = useParams();
  const history = useHistory();
  // set Data from external API call
  useEffect(() => {
    loadEreaderContentRequestData({ book: params.book, theme: params.theme });
    setModalContentIdAction(params.book);
  }, []);

  // open modal and set the basePath for it
  useEffect(() => {
    openStructuredContentPlayerModalAction();
    setModalBasePathAction(window.location);
  }, []);

  const handleCloseModal = () => {
    history.push('/oup/content/resources/ereader');
    closeStructuredContentPlayerModalAction();
  };

  return (
    <div>
      <Helmet title="Content Player" />
      <PopoutPanel
        id="ereaderContentPlayer"
        ariaLabel="EreaderContentPlayer"
        ariaLive="assertive"
        isOpen={isModalOpen}
        panelSize="fullWidth"
        className={MODAL_CLASSES.OVERWRITE}
      >
        <EreaderContentPlayer closePanel={handleCloseModal} params={params} />
      </PopoutPanel>
    </div>
  );
}

EreaderContentPlayerBlankPage.propTypes = {
  isModalOpen: PropTypes.bool,
  openStructuredContentPlayerModalAction: PropTypes.func,
  closeStructuredContentPlayerModalAction: PropTypes.func,
  setModalBasePathAction: PropTypes.func,
  loadEreaderContentRequestData: PropTypes.func,
  setModalContentIdAction: PropTypes.func
};

const mapStateToProps = ({
  structuredContentPlayer: { modalCloseLink, isModalOpen, menuOrientation, previewModeRole },
  hubUi: { redeemModalOpen, missingLicenseModalOpen }
}) => ({
  modalCloseLink,
  isModalOpen,
  menuOrientation,
  previewModeRole,
  redeemModalOpen,
  missingLicenseModalOpen
});

const mapDispatchToProps = {
  openStructuredContentPlayerModalAction: openStructuredContentPlayerModal,
  closeStructuredContentPlayerModalAction: closeStructuredContentPlayerModal,
  setModalCloseLinkAction: setModalCloseLink,
  setModalBasePathAction: setModalBasePath,
  loadEreaderContentRequestData: loadEreaderContentRequest,
  setModalContentIdAction: setModalContentId,
  openRedeemModalAction: openRedeemModal,
  closeMissingLicenseModalAction: closeMissingLicenseModal
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalizedContent('hubGlossary')
)(EreaderContentPlayerBlankPage);
