import { select } from 'redux-saga/effects';
import invoiceReportApi from './monthlyInvoiceReportApi';
import { dateFormat } from '../../../../../globals/dateFormats';

export default function* invoiceReportRequest() {
  console.log(`[generateReport Saga] Submitting request to get report.`);

  const { toDate, fromDate } = yield select(state => ({
    toDate: dateFormat(state.invoiceReport.toDate),
    fromDate: dateFormat(state.invoiceReport.fromDate)
  }));

  return yield invoiceReportApi(fromDate, toDate);
}
