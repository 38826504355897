import { put, select } from 'redux-saga/effects';

import userPlatformStatisticsApi from '../../../apiCalls/userPlatformStatisticsApi';
import storeUserPlatformStatistics from '../../../../actions/userPlatformStatisticsActions';

function* root() {
  const userId = yield select(state => state.identity.userId);
  const result = yield userPlatformStatisticsApi(userId);

  yield put(storeUserPlatformStatistics(result));
}

export default root;
