import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import FormController from './formUtil/FormController.js';
import CreateOrgForm from './CreateOrgForm.js';
import CreateOrgReview from './CreateOrgReview.js';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import CreateOrgConfirmation from './CreateOrgConfirmation.js';

// Action imports
import { resetForm } from '../../redux/reducers/data/forms.reducer.js';
import { resetFields } from '../../redux/reducers/data/fields.reducer.js';

import cmsContent from '../../utils/cmsContent.js';

function FullPageWrapper({ children }) {
  return (
    <div style={{ padding: '2rem 0 4rem' }}>
      <div className="grid">
        <div className="row">
          <div className="col">{children}</div>
        </div>
      </div>
    </div>
  );
}

FullPageWrapper.propTypes = {
  children: PropTypes.any.isRequired
};

class CreateOrg extends React.Component {
  componentWillMount() {
    const { resetformAction, resetFieldsAction } = this.props;
    resetformAction('orgCreate', 'EDITING');
    resetFieldsAction('orgCreate', ['orgName', 'orgRole', 'orgEmail', 'orgWebsite']);
  }

  render() {
    const CMS = cmsContent.createOrganisation || {};

    return (
      <div>
        <Helmet title={CMS.tabTitle} />
        <FullPageWrapper>
          <FormController
            formName="orgCreate"
            initialFormState="EDITING"
            formStateMapping={{
              EDITING: <CreateOrgForm />,
              REVIEWING: <CreateOrgReview />,
              SUBMITTING: (
                <PopoutPanelIconHeading
                  type={types.LOADING}
                  title={CMS.loading_page_title}
                  subtitle={CMS.loading_page_subtitle}
                />
              ),
              CONFIRMATION: <CreateOrgConfirmation />
            }}
          />
        </FullPageWrapper>
      </div>
    );
  }
}

CreateOrg.propTypes = {
  resetformAction: PropTypes.func.isRequired,
  resetFieldsAction: PropTypes.func.isRequired
};

export default connect(null, {
  resetformAction: resetForm,
  resetFieldsAction: resetFields
})(CreateOrg);
