import * as t from '../../actionTypes';
import { RESOURCE_TYPE } from '../../../globals/hubConstants';

export const initialState = {
  downloadResourceId: '',
  downloadUrl: '',
  downloadIsLoading: false,
  resourceIdsDownloading: {},
  resourceAction: '',
  isError: false,
  downloadType: '',
  assignmentId: '',
  roles: null,
  resourceType: RESOURCE_TYPE.COURSE_RESOURCE,
  isAdaptTestFlow: false,
  assessmentEntitlement: null,
  returnPath: null,
  isAdaptedTest: false,
  assessmentTimeLimit: '',
  subtitleResourceId: '',
  title: ''
};

function hubDownloads(
  state = initialState,
  {
    type,
    resourceId,
    url,
    resource,
    isError,
    downloadType,
    resourceAction,
    product,
    domain,
    assignmentId,
    roles,
    resourceType,
    contextId,
    activityId,
    studentId,
    isAdaptTestFlow,
    assessmentEntitlement,
    productTitle,
    returnPath,
    isAdaptedTest,
    assessmentTimeLimit,
    assessmentId,
    courseCode,
    courseId,
    subtitleResourceId,
    title
  } = {}
) {
  switch (type) {
    case t.HUB_DOWNLOAD_IS_LOADING:
      return {
        ...state,
        downloadIsLoading: true
      };
    case t.HUB_DOWNLOAD_RESOURCE:
      return {
        ...state,
        downloadResourceId: resourceId,
        downloadUrl: url,
        resourceAction,
        product,
        domain,
        assignmentId,
        roles,
        resourceType,
        contextId,
        activityId,
        studentId,
        isAdaptTestFlow,
        assessmentEntitlement,
        productTitle,
        returnPath,
        isAdaptedTest,
        downloadIsLoading: true,
        resourceIdsDownloading: { ...state.resourceIdsDownloading, [resourceId]: resourceId },
        assessmentTimeLimit,
        assessmentId,
        courseCode,
        courseId,
        subtitleResourceId,
        title
      };
    case t.HUB_DOWNLOAD_RESOURCE_FAILURE: {
      const currentResourceIdsDownloading = { ...state.resourceIdsDownloading };
      if (currentResourceIdsDownloading[resourceId]) {
        delete currentResourceIdsDownloading[resourceId];
      }
      return {
        ...initialState,
        downloadResourceId: resourceId,
        isError,
        downloadType,
        assignmentId,
        resourceType,
        downloadIsLoading: false,
        resourceIdsDownloading: currentResourceIdsDownloading
      };
    }
    case t.HUB_DOWNLOAD_REMOVE_ERROR_MODAL:
      return {
        ...initialState
      };
    case t.HUB_DOWNLOAD_RESOURCE_SUCCESS: {
      const currentResourceIdsDownloading = { ...state.resourceIdsDownloading };
      if (currentResourceIdsDownloading[resourceId]) {
        delete currentResourceIdsDownloading[resourceId];
      }
      return {
        ...state,
        resource,
        downloadIsLoading: false,
        isError: false,
        downloadType: '',
        resourceType,
        resourceIdsDownloading: currentResourceIdsDownloading
      };
    }
    case t.HUB_DOWNLOAD_RESOURCE_RESET: {
      return initialState;
    }
    default:
      return state;
  }
}

export default hubDownloads;
