import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getDeferredEmails from './getDeferredEmails';
import sendDeferredEmails from './sendDeferredEmails';

function* root() {
  yield takeLatest(t.GET_DEFERRED_EMAILS_REQUEST, ({ payload: userRole }) => getDeferredEmails(userRole));
  yield takeLatest(t.SEND_DEFERRED_EMAILS_REQUEST, ({ payload: orgId }) => sendDeferredEmails(orgId));
}

export default root;
