import React from 'react';
import PropTypes from 'prop-types';
import styles from './StudentAnswerView.scss';
import LTIOpener from '../LTIOpener/LTIOpener';
import { featureIsEnabled } from '../../globals/envSettings';

function StudentAnswerViewLTI({ bid, uid, learnerId, orgId, attemptId }) {
  return (
    <div className={`${styles.studentAnswerViewLTI}`}>
      {featureIsEnabled('gradebook-first-and-last-answer') ? (
        <LTIOpener
          className={styles.ltiIframe}
          content={{ platform: 'olb', bid, uid, learnerId, orgId, attemptId }}
          formTarget="iframe"
        />
      ) : (
        <LTIOpener
          className={styles.ltiIframe}
          content={{ platform: 'olb', bid, uid, learnerId, orgId }}
          formTarget="iframe"
        />
      )}
    </div>
  );
}

StudentAnswerViewLTI.propTypes = {
  bid: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  learnerId: PropTypes.string.isRequired,
  orgId: PropTypes.string,
  attemptId: PropTypes.string
};

export default StudentAnswerViewLTI;
