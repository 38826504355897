import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Action imports
import { setClassroomName } from '../../redux/reducers/classroomEdit.js';

// Component imports
import TextInput from '../../components/TextInput/TextInput.js';
import Validation from '../../components/Validation/Validation.js';

import cmsContent from '../../utils/cmsContent.js';

// Diddy form for adding name of NEW CLASS:
function ClassroomNameInput({
  // Input values
  classroomNameValue,
  classroomNameValidationMessage,
  classroomNameValidationPending,
  classroomNameValidationIsInvalid,
  classroomNameValidationIsWarning,
  classroomNameValidationIsValid,
  // Input onChanges
  setClassroomNameAction
}) {
  const CMS = cmsContent.classEditPanel || {};
  return (
    <Validation
      isError={classroomNameValidationIsInvalid}
      isWarning={classroomNameValidationIsWarning}
      isWaiting={classroomNameValidationPending}
      isValid={classroomNameValidationIsValid}
      message={classroomNameValidationMessage}
      forId="classroomName"
    >
      {/* Note how we hide the browser's native autoComplete suggestions while displaying error or warning: */}
      <TextInput
        id="classroomName"
        label={CMS.class_name_label}
        placeholder={CMS.class_name_placeholder}
        maxLength={255}
        value={classroomNameValue}
        onChange={setClassroomNameAction}
        disableAutoComplete={
          classroomNameValidationIsInvalid || classroomNameValidationIsWarning || classroomNameValidationPending
        }
      />
      <p style={{ color: '#666666' }}>{CMS.class_name_help_text}</p>
    </Validation>
  );
}

ClassroomNameInput.propTypes = {
  // Input values
  classroomNameValue: PropTypes.string.isRequired,
  classroomNameValidationMessage: PropTypes.string.isRequired,
  classroomNameValidationPending: PropTypes.bool.isRequired,
  classroomNameValidationIsInvalid: PropTypes.bool.isRequired,
  classroomNameValidationIsWarning: PropTypes.bool.isRequired,
  classroomNameValidationIsValid: PropTypes.bool.isRequired,

  // Input onChanges
  setClassroomNameAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    // Connect values
    // When modifying an existing class we use its name from state.classrooms.data:
    classroomNameValue: state.classroomEdit.classroomNameValue,
    classroomNameValidationMessage: state.classroomEdit.classroomNameValidationMessage,
    classroomNameValidationPending: state.classroomEdit.classroomNameValidationPending,
    classroomNameValidationIsInvalid: state.classroomEdit.classroomNameValidationIsInvalid,
    classroomNameValidationIsWarning: state.classroomEdit.classroomNameValidationIsWarning,
    classroomNameValidationIsValid: state.classroomEdit.classroomNameValidationIsValid
  }),
  {
    // Connect onChange actions
    setClassroomNameAction: setClassroomName
  }
)(ClassroomNameInput);
