import { select } from 'redux-saga/effects';
import productPublishApi from './productPublishApi';

export default function* productPublishRequest() {
  console.log(`[productPublishRequest Saga] Submitting request to get productPublish.`);

  const { selectedPlatformCode } = yield select(state => ({
    selectedPlatformCode: state.productPublish.selectedPlatformCode
  }));

  return yield productPublishApi(selectedPlatformCode);
}
