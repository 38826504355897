import * as t from '../actionTypes';

export const getNotifications = (userId, orgId) => ({
  type: t.ORB_GET_NOTIFICATIONS,
  payload: {
    userId,
    orgId
  }
});

export const getNotificationsSuccess = payload => ({
  type: t.ORB_GET_NOTIFICATIONS_SUCCESS,
  payload
});

export const markNotificationAsRead = (userId, orgId, notificationId) => ({
  type: t.ORB_GET_NOTIFICATIONS_MARK_AS_VIEWED,
  payload: {
    userId,
    orgId,
    notificationId
  }
});

export const markNotificationAsReadSuccess = payload => ({
  type: t.ORB_GET_NOTIFICATIONS_MARK_AS_VIEWED_SUCCESS,
  payload
});
