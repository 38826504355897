import { mergeDeep } from '../managedUserEdit.js';

const STORE_PRODUCTS = 'products/STORE_PRODUCTS';

const initialState = {
  data: {}
};

export default function products(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_PRODUCTS:
      return {
        ...state,
        data: mergeDeep(state.data, action.products)
      };
    default:
      return state;
  }
}

export const storeProducts = productData => ({
  type: STORE_PRODUCTS,
  products: productData
});
