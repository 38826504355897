import * as t from '../actionTypes';

export const mode = {
  INPUT: 'INPUT',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  EMPTY: 'EMPTY'
};

const initialState = {
  organization: {
    mode: mode.INPUT,
    formData: {},
    errors: {}
  },
  products: {}
};

function oicTestCredits(state = initialState, { type, payload }) {
  switch (type) {
    case t.OIC_TEST_CREDITS_ORG_STATE:
      return {
        ...state,
        organization: {
          ...state.organization,
          mode: payload
        }
      };
    case t.OIC_TEST_CREDITS_ORG_INPUT:
      return {
        ...state,
        organization: {
          ...state.organization,
          mode: mode.INPUT,
          formData: {
            ...state.organization.formData,
            [payload.field]: payload.value || ''
          }
        }
      };
    case t.OIC_TEST_CREDITS_ORG_VALIDATED:
      return {
        ...state,
        organization: {
          ...state.organization,
          errors: {
            ...state.organization.errors,
            ...payload
          }
        }
      };
    case t.GET_TEST_CREDITS_PRODUCTS:
      return {
        ...state,
        products: payload
      };
    case t.CREATE_OIC_TEST_CREDITS_INPUT:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            mode: mode.INPUT,
            formData: {
              ...state.products[payload.productId]?.formData,
              [payload.field]: payload.value || ''
            },
            errors: ''
          }
        }
      };
    case t.GET_OIC_TEST_CREDITS_REQUEST:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            ...state.products[payload.productId],
            mode: mode.LOADING
          }
        }
      };
    case t.GET_OIC_TEST_CREDITS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            mode: mode.INPUT,
            formData: payload
          }
        }
      };
    case t.GET_OIC_TEST_CREDITS_EMPTY_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            ...state.products[payload.productId],
            mode: mode.EMPTY
          }
        }
      };
    case t.GET_OIC_TEST_CREDITS_FAILURE:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            ...state.products[payload.productId],
            mode: mode.ERROR,
            errors: payload.errors
          }
        }
      };
    case t.CREATE_OIC_TEST_CREDITS_REQUEST:
    case t.UPDATE_OIC_TEST_CREDITS_REQUEST:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            ...state.products[payload.productId],
            mode: mode.LOADING
          }
        }
      };
    case t.CREATE_OIC_TEST_CREDITS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            formData: payload,
            mode: mode.SUCCESS,
            errors: ''
          }
        }
      };
    case t.UPDATE_OIC_TEST_CREDITS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            ...state.products[payload.productId],
            mode: mode.SUCCESS,
            errors: ''
          }
        }
      };
    case t.CREATE_OIC_TEST_CREDITS_FAILURE:
    case t.UPDATE_OIC_TEST_CREDITS_FAILURE:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            ...state.products[payload.productId],
            mode: mode.ERROR,
            errors: payload.errors
          }
        }
      };
    case t.OIC_TEST_CREDITS_RESET:
      return initialState;
    default:
      return state;
  }
}

export default oicTestCredits;
