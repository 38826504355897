import React from 'react';
import PropTypes from 'prop-types';

import styles from './SimpleHeader.scss';

import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';

function SimpleHeader({ customLogoComponent }) {
  return (
    <header className={styles.simpleHeader}>
      {/* For keyboard & screenreader users: (Hidden accessibly. Visible on focus) */}
      <a className="a11y-hide-focusable" href="#maincontent">
        Skip to main content
      </a>

      <div className="grid">
        <div className="row">
          <div id="navHeader" className="col">
            <nav aria-label="Primary">
              <ul>
                {/* OUP Logo */}
                <li className={styles.oxfordLogo}>
                  {customLogoComponent || (
                    <div>
                      <SVGIcon glyph={GLYPHS.OUP_LOGO_BLUE} width="105" height="30" />
                    </div>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

SimpleHeader.propTypes = {
  customLogoComponent: PropTypes.element
};

export default SimpleHeader;
