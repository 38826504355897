import { put } from 'redux-saga/effects';
import { updateMyCoursesSuccess, updateMyCoursesFailure } from '../../../../actions/ngsLibrary';
import updateMyCoursesApiCall from '../../../apiCalls/ngs/updateMyCourses';

function* updateMyCourses(orgId, courseIds) {
  const response = yield updateMyCoursesApiCall(orgId, courseIds);

  if (response.status === 'success') {
    yield put(updateMyCoursesSuccess(response.data));
    return;
  }

  yield put(updateMyCoursesFailure(response.message));
}

export default updateMyCourses;
