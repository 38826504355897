import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withLocalizedContent from '../../language/withLocalizedContent';
import withDataRecency from '../../dataRecency/withDataRecency';
import withSearchInitialiser from '../SearchInitialiser/withSearchInitialiser.js';
import { triggerSearch } from '../../redux/reducers/data/search.reducer';
import { oicLinkOrgToJanisonRequest } from '../../redux/actions/oicLinkOrgToJanison';
import RequestComponent from './RequestComponent';
import ResultComponent from './ResultComponent';

const searchSource = 'oicUlinkedJanisonOrgs';

function OicLinkOrganizationsToJanisonList({
  localizedContent: { oicLinkOrganizationsToJanison: localizedContent = {} },
  orgs = [],
  userRole,
  resultIds,
  loadingResults = false,
  requestFailed,
  orgsDataRecency,
  triggerSearchAction,
  oicLinkOrgToJanison,
  oicLinkOrgToJanisonAction
}) {
  const handleSearch = () => triggerSearchAction(searchSource);

  return (
    <div className="grid pad-top4 pad-bot4">
      <div className="row gin-bot3">
        <div className="col">
          {loadingResults ? (
            <RequestComponent
              requestFailed={requestFailed}
              localizedContent={localizedContent}
              triggerSearch={handleSearch}
            />
          ) : (
            <ResultComponent
              localizedContent={localizedContent}
              userRole={userRole}
              resultIds={resultIds}
              orgs={orgs}
              orgsDataRecency={orgsDataRecency}
              oicLinkOrgToJanison={oicLinkOrgToJanison}
              oicLinkOrgToJanisonAction={oicLinkOrgToJanisonAction}
            />
          )}
        </div>
      </div>
    </div>
  );
}

OicLinkOrganizationsToJanisonList.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  orgs: PropTypes.array.isRequired,
  resultIds: PropTypes.array.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  orgsDataRecency: PropTypes.object.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  oicLinkOrgToJanison: PropTypes.object.isRequired,
  oicLinkOrgToJanisonAction: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  withLocalizedContent('oicLinkOrganizationsToJanison'),
  withDataRecency('orgs'),
  withSearchInitialiser({
    searchSource,
    initialFilters: {
      active: true
    }
  }),
  connect(
    state => ({
      userRole: state.identity.role,
      orgs: state.organisations.data,
      resultIds: state.search[state.search.instance]?.ids || [],
      loadingResults: state.search[state.search.instance]?.loading,
      requestFailed: state.search[state.search.instance]?.error,
      oicLinkOrgToJanison: state.oicLinkOrgToJanison
    }),
    {
      triggerSearchAction: triggerSearch,
      oicLinkOrgToJanisonAction: oicLinkOrgToJanisonRequest
    }
  )
)(OicLinkOrganizationsToJanisonList);
