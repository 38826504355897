import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './NGSLogo.scss';

import withTheme from '../../../theme/withTheme';

import SVGIcon, { GLYPHS_NGS } from '../../../components/SVGIcon/SVGIcon.js';

const NGSLogo = ({ themeOptions }) => {
  const { logoGlyph, logoMark } = themeOptions || {};
  if (!logoGlyph) return null;
  if (!logoMark) return null;

  return (
    <Link to="/ngs">
      <h1 className="a11y-hide">Kerboodle</h1>
      <SVGIcon glyph={GLYPHS_NGS[logoGlyph]} className={styles.logo} />
      <SVGIcon glyph={GLYPHS_NGS[logoMark]} className={styles.mobile_logo} />
    </Link>
  );
};

NGSLogo.propTypes = {
  themeOptions: PropTypes.object
};

export default withTheme(NGSLogo);
