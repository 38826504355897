import * as t from '../actionTypes';

const requestStatusOptions = { loading: true, success: false, error: false };
const successStatusOptions = { loading: false, success: true, error: false };
const failureStatusOptions = { loading: false, success: false, error: true };

const initialState = {
  resultsFileUrl: ''
};

function loadPlacementTestsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case t.DOWNLOAD_PLACEMENT_TEST_RESULTS:
      return {
        ...initialState,
        ...requestStatusOptions
      };
    case t.DOWNLOAD_PLACEMENT_TEST_RESULTS_SUCCESS:
      return {
        ...state,
        resultsFileUrl: payload.file,
        ...successStatusOptions
      };
    case t.DOWNLOAD_PLACEMENT_TEST_RESULTS_FAILURE:
      return { ...state, ...failureStatusOptions };
    default:
      return { ...state, success: false };
  }
}

export default loadPlacementTestsReducer;
