import signedFetch, { JSON_HEADERS } from '../util/signedFetch.js';

const includeHeaders = false;
const includeXAPIKey = true;
const includeStatusCode = true;
const multipartRequest = false;

export default (orgId, email) =>
  signedFetch(
    'checkUserEmail',
    `${__API_BASE__}/org/${encodeURIComponent(orgId)}/user/check-email?emailAddress=${encodeURIComponent(
      email.trim()
    )}`,
    'GET',
    undefined,
    JSON_HEADERS,
    includeHeaders,
    multipartRequest,
    includeXAPIKey,
    includeStatusCode
  );
