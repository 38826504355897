import checkGlobalUserEmailApi from '../../../apiCalls/users/checkGlobalUserEmail.api.js';

export default userName =>
  checkGlobalUserEmailApi(userName)
    .then(result => {
      if ((!result || !result.data) && !(result.data.exists === false || result.data.exists === true)) {
        return 'ERROR';
      }
      if (result.data.exists === false) {
        return 'VALID';
      }
      return 'INVALID';
    })
    .catch(() => 'ERROR');
