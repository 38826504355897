import { put, select } from 'redux-saga/effects';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import actions from '../../../../actions';

export const PollOrgLicenceOperation = { ADD: 'ADD', UPDATE: 'UPDATE' };

export default function* pollOrgLicences(codes, operation) {
  if (!featureIsEnabled('data-recency') || !featureIsEnabled('data-recency-org-licences') || !codes.length) {
    return;
  }
  const { orgId } = yield select(state => state.classroomPage);
  const { instance } = yield select(state => state.search);
  const collection = operation === PollOrgLicenceOperation.ADD ? 'orgLicences' : 'classAssignments';
  yield put(
    actions.pollEntitiesSynced({
      orgId,
      activationCodes: codes.map(activationCode => ({
        activationCode,
        ...(operation === PollOrgLicenceOperation.UPDATE ? { lastUpdatedTimeStamp: new Date() } : {})
      })),
      operation,
      collection,
      target: 'activationCodes',
      responseEntityKey: 'activationCodePollingStatus',
      identifier: 'activationCode',
      search: instance
    })
  );
}

export function pollOrgLicencesAdded(ids) {
  return pollOrgLicences(ids, PollOrgLicenceOperation.ADD);
}

export function pollOrgLicencesUpdated(ids) {
  return pollOrgLicences(ids, PollOrgLicenceOperation.UPDATE);
}
