import * as t from '../actionTypes';

const initialState = {
  attainment: 0, // to de deleted after mat-report-change-for-reducers is enabled
  progress: 0, // to de deleted after mat-report-change-for-reducers is enabled
  engagement: 0, // to de deleted after mat-report-change-for-reducers is enabled
  data: {},
  levels: {},
  submitting: false,
  success: false,
  error: false
};

function gradebookSchoolDialReport(state = initialState, { type, payload }) {
  switch (type) {
    case t.GRADEBOOK_SCHOOL_DIAL_REPORT_REQUEST:
      return {
        ...state,
        submitting: true,
        success: false,
        error: false
      };
    case t.GRADEBOOK_SCHOOL_DIAL_REPORT_FAILURE_DEPRECATED:
      return {
        ...state,
        submitting: false,
        success: false,
        error: true
      };
    case t.GRADEBOOK_SCHOOL_DIAL_REPORT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            submitting: false,
            success: false,
            error: true,
            attainment: 0,
            progress: 0,
            engagement: 0,
            levels: {}
          }
        }
      };
    case t.GRADEBOOK_SCHOOL_DIAL_REPORT_SUCCESS_DEPRECATED: // to de deleted after mat-report-change-for-reducers is enabled
      return {
        ...state,
        submitting: false,
        error: false,
        success: true,
        attainment: (payload.attainment || {}).percentage || 0,
        progress: (payload.progress || {}).percentage || 0,
        engagement: (payload.engagement || {}).percentage || 0,
        levels: payload.levels
      };
    case t.GRADEBOOK_SCHOOL_DIAL_REPORT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            attainment: (payload.data.attainment || {}).percentage || 0,
            progress: (payload.data.progress || {}).percentage || 0,
            engagement: (payload.data.engagement || {}).percentage || 0,
            levels: payload.data.levels,
            submitting: false,
            error: false,
            success: true
          }
        }
      };
    default:
      return state;
  }
}

export default gradebookSchoolDialReport;
