import { put } from 'redux-saga/effects';
import { courseReportsRequestSuccess, courseReportsRequestFailure } from '../../../../actions/ngsCourse';
import getReportsApiCall from '../../../apiCalls/ngs/getReports';

function* getCourseReports(orgId, courseId) {
  const response = yield getReportsApiCall(orgId, courseId);

  if (response.status === 'success') {
    yield put(courseReportsRequestSuccess(response.data));
    return;
  }

  yield put(courseReportsRequestFailure(response.message));
}

export default getCourseReports;
