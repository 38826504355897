import * as t from '../actionTypes';

export const mode = {
  INPUT: 'INPUT',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  EMPTY: 'EMPTY'
};

const initialState = {
  organization: {
    mode: mode.INPUT,
    errors: {}
  },
  products: {}
};

function testCredits(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_TEST_CREDITS_REQUEST:
      return {
        ...state,
        products: [],
        organization: {
          ...state.organization,
          mode: mode.LOADING
        }
      };
    case t.GET_TEST_CREDITS_SUCCESS:
      return {
        ...state,
        products: payload.testCredits,
        organization: {
          ...state.organization,
          mode: mode.SUCCESS
        }
      };
    case t.GET_TEST_CREDITS_FAILURE:
      return {
        ...state,
        products: [],
        organization: {
          ...state.organization,
          mode: mode.ERROR,
          errors: payload.errors
        }
      };
    default:
      return state;
  }
}

export default testCredits;
