import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import lookupProductsApi from '../../../apiCalls/lookupProdutcsApi';

function* lookupProducts(action) {
  const apiResults = yield lookupProductsApi(action.data.id);
  if (apiResults.status === 'error') {
    yield put(actions.setLookUpErrors(apiResults.error));
  } else {
    yield put(actions.setLookUpdata(apiResults.data));
  }
}

export default lookupProducts;
