import React, { Component } from 'react';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import '../../styles/base/_index.scss';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import CardWithThumbnail from '../../components/CardWithThumbnail/CardWithThumbnail.js';
import colors from '../../globals/colors.js';
import RedeemCode from './RedeemCode.js';
import Feature from '../../environment/Feature';
import RedeemFallbackView from './RedeemFallbackPage';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './RedeemCode.scss';
import { isHubMode, isMflMode } from '../../utils/platform';
import mountLiveChat, { unmountLiveChat } from '../../utils/chat/liveChat';

class RedeemCodePage extends Component {
  componentDidMount() {
    if (isHubMode()) mountLiveChat();
  }

  componentWillUnmount() {
    if (isHubMode()) unmountLiveChat();
  }

  render() {
    const {
      localizedContent: { redeemCodePage }
    } = this.props;

    let redeemCodeHelpLink = '';
    if (isHubMode()) {
      redeemCodeHelpLink = APP_CONSTANTS.HUB_REDEEM_CODE_HELP_LINK;
    } else if (isMflMode()) {
      redeemCodeHelpLink = APP_CONSTANTS.MFL_SUPPORT_LINK;
    } else {
      redeemCodeHelpLink = APP_CONSTANTS.REDEEM_CODE_HELP_LINK;
    }

    return (
      <Feature
        name="new-redeem-page"
        render={() => (
          <div className={styles.mainContent}>
            <Helmet title={redeemCodePage.page_title} />
            <div className="grid">
              <div className="bg">
                <div className="grid">
                  <div className={`row ${styles.row}`}>
                    <div className={`col sm7 md6 lg5 ${styles.redeemCodePage}`}>
                      <CardWithThumbnail thumbnailColor={colors.PRIMARY_BLUE_HOVER}>
                        <div className={styles.checkRedeemCode}>
                          <div className={styles.content}>
                            <h1>{redeemCodePage.check_your_code}</h1>
                            <p>{redeemCodePage.get_started_content}</p>
                          </div>
                          <RedeemCode />
                        </div>
                        <hr />
                        <div className={styles.linkItems}>
                          <div className={styles.content}>
                            <a target="_blank" rel="noreferrer noopener" href={redeemCodeHelpLink}>
                              {redeemCodePage.help_and_support}
                            </a>
                          </div>

                          {!isMflMode() && (
                            <div className={styles.content}>
                              <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href={APP_CONSTANTS.REDEEM_CODE_PURCHASE_LINK}
                              >
                                {redeemCodePage.purchase_code}
                              </a>
                            </div>
                          )}
                        </div>
                      </CardWithThumbnail>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        renderFallback={RedeemFallbackView}
      />
    );
  }
}

RedeemCodePage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('redeemCodePage'))(RedeemCodePage);
