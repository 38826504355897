import PropTypes from 'prop-types';
import React, { Children } from 'react';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './SelectionList.scss';

/** The component renders box with a link and list of Lozenges in it */
function SelectionList({ linkId, linkText, linkTo, linkAction, children }) {
  return (
    <div className={styles.selectionList}>
      <LinkWithIcon
        id={linkId}
        glyph={GLYPHS.ICON_RIGHT}
        text={linkText}
        top={linkTo}
        action={linkAction}
        customClassName={styles.link}
      />
      {children && children.length ? (
        <ul className={styles.childrenContainer}>
          {Children.map(children, (child, index) => (
            <li key={index}>{child}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
SelectionList.propTypes = {
  /** ID on the link */
  linkId: PropTypes.string,
  /** Text to be displayed in the link */
  linkText: PropTypes.string.isRequired,
  /** Link location to be used by the link, if not using an action */
  linkTo: PropTypes.string,
  /** Action to be triggered by the link, if not using an "to" location */
  linkAction: PropTypes.func,
  /** Children should either be nothing, a single Lozenge or a list of Lozenges */
  children: PropTypes.any
};

export default SelectionList;
