import { LTI_ACTIONS, HubLayoutConstants, RESOURCE_TYPE } from '../../../globals/hubConstants';

export default function downloadOrPreviewAssessment(
  callback,
  action = LTI_ACTIONS.PLAY,
  resourceId = '',
  isAdaptedTest = false,
  role = HubLayoutConstants.ASSESSMENT_USER_ROLES.INSTRUCTOR,
  assessmentTimeLimit = '',
  courseCode = '',
  courseId = '',
  assessmentId = ''
) {
  const testData = {
    resourceId,
    resourceAction: action,
    roles: role,
    isAdaptedTest,
    resourceType: RESOURCE_TYPE.ASSESSMENT,
    assessmentTimeLimit,
    courseCode,
    courseId,
    assessmentId
  };

  callback({ ...testData }, action);
}
