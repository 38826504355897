import * as t from '../actionTypes';

export const multiSchoolDialReportRequest = (orgs, locationId) => ({
  type: t.MULTI_SCHOOL_DIAL_REPORT_REQUEST,
  payload: { orgs, locationId }
});

export const multiSchoolDialReportAllOrgsLoaded = () => ({
  type: t.MULTI_SCHOOL_DIAL_REPORT_ALL_ORGS_ARE_LOADED
});

export const multiSchoolDialReportFailure = () => ({
  type: t.MULTI_SCHOOL_DIAL_REPORT_FAILURE
});

export const multiSchoolDialReportUpdateLoadedOrgs = () => ({
  type: t.MULTI_SCHOOL_DIAL_REPORT_UPDATE_LOADED_ORGS
});

export const multiSchoolDialReportUpdateSummary = summary => ({
  type: t.MULTI_SCHOOL_DIAL_REPORT_UPDATE_SUMMARY,
  payload: summary
});
