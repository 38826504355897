/**
 * Sanitize a column heading row to the given input using headTransform function provided
 * Prepend default heading if omitted and if columnHeadings is provided
 *
 * @param {string} contents
 * @param {function} headTransform
 * @param {array} columnHeadings
 *
 * @return {string}
 */
function sanitizeCSV(contents, headTransform, columnHeadings) {
  let sanitizedContents = contents.replace(/\r/g, '');
  const ROW_DELIMITER = /[,;\t]/;

  const firstNewLinePosition = sanitizedContents.indexOf('\n');
  const headingRow = sanitizedContents.substring(0, firstNewLinePosition) || sanitizedContents;
  const [delimiter = ','] = headingRow.match(ROW_DELIMITER) || [];

  sanitizedContents = sanitizedContents.replace(new RegExp(`[ ]*${delimiter}[ ]*`, 'g'), delimiter);

  const hasHeadingRow = columnHeadings
    ? headingRow.split(delimiter).every(heading => columnHeadings.find(val => val === headTransform(heading).trim()))
    : false;

  if (hasHeadingRow) {
    const sourceWithoutHeadingRow = sanitizedContents.substring(firstNewLinePosition + 1);
    return [
      headingRow
        .split(delimiter)
        .map(heading => headTransform(heading))
        .join(delimiter),
      sourceWithoutHeadingRow
    ].join('\n');
  }

  return [sanitizedContents].join('\n');
}

export default sanitizeCSV;
