import { put, select } from 'redux-saga/effects';

import { setFormResult } from '../../../../reducers/data/forms.reducer.js';
import contactUsSubmissionApi from '../../../apiCalls/contactUsSubmissionApi.js';
import { getFieldValue } from '../formUtil.js';

export default function* submitContactUs() {
  const payload = yield select(state => ({
    name: getFieldValue(state, 'contactUs', 'name'),
    ces10: getFieldValue(state, 'contactUs', 'ces10'),
    country: getFieldValue(state, 'contactUs', 'country'),
    message: getFieldValue(state, 'contactUs', 'comment'),
    email: getFieldValue(state, 'contactUs', 'email')
  }));

  yield contactUsSubmissionApi(payload);

  yield put(setFormResult('contactUs', 'CONFIRMATION'));
}
