/* eslint-disable import/no-import-module-exports */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer.js';
// eslint-disable-next-line import/no-cycle
import saga from './saga.js';

const sagaMiddleware = createSagaMiddleware();

// Support for redux dev tools chrome extension in development
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(saga);

// do not try to use the isDev() function to determine this - environmentSettings are not loaded at this point
const envFromSubdomain = window.location.hostname.split('.')[0];
const devTestOrLocal = ['dev', 'localhost'];
const isDev = devTestOrLocal.indexOf(envFromSubdomain) >= 0;
// console.debug('In store - isDev is set to <%s>', isDev);

/* global module */
if (isDev && module.hot) {
  module.hot.accept('./reducer.js', () => {
    store.replaceReducer(require('./reducer.js').default); // eslint-disable-line global-require
  });
}

export default store;
