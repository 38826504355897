import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import searchUsersForAdmin from '../../../apiCalls/searchUsersForAdmin';

const buildRequestString = action => {
  const rawRequest = {
    userEmail: action.data.userEmail,
    userName: action.data.userName,
    userId: action.data.userId,
    firstName: action.data.firstName,
    lastName: action.data.lastName,
    createdDateTo: action.data.createdDateTo,
    createdDateFrom: action.data.createdDateFrom
  };
  // Sometimes filters can come in null, we need to avoid sending those to the backend
  const cleanedRequest = Object.entries(rawRequest).filter(
    ([, v]) => v !== null && v !== 'null' && v !== ' ' && v !== ''
  );
  let requestString = '?';
  cleanedRequest.forEach((keyValuePair, index) => {
    const [key, value] = keyValuePair;
    if (index === 0) {
      requestString += `${key}=${value}`;
    } else {
      requestString += `&${key}=${value}`;
    }
  });
  requestString += action.data.pagination;
  return requestString;
};

function* searchUserForConsole(action) {
  let apiResults = {};
  const requestString = buildRequestString(action);
  apiResults = yield searchUsersForAdmin(requestString);

  if (apiResults.error || apiResults.status !== 'success') {
    yield put(actions.setUserSearchErrorForConsole({ error: apiResults.message }));
  } else {
    yield put(actions.setUserSearchResultForConsole({ success: apiResults.data }));
  }
}

export default searchUserForConsole;
