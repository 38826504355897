const mapName = name => {
  const notNullName = name === 'null' ? ' ' : name;
  return notNullName || ' ';
};

const mapUserSearchResults = rawData => {
  let processedData = [];
  processedData = rawData.map(raw => ({
    username: raw.userName || ' ',
    email: raw.userEmail || ' ',
    firstName: mapName(raw.firstName),
    lastName: mapName(raw.lastName),
    licenses: raw.registrations || 0,
    dateCreated: raw.dateCreated,
    userId: raw._id
  }));
  return processedData;
};

export default mapUserSearchResults;
