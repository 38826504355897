import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Dropdown from '../Dropdown/Dropdown';
import PanelHeading from '../PanelHeading/PanelHeading';
import ContentDivider from '../ContentDivider/ContentDivider';
import withLocalizedContent from '../../language/withLocalizedContent';

function ContactUsProductPicker({
  localizedContent: { contactUsProductPicker: localizedContent },
  handleSelection,
  selectedProduct
}) {
  return (
    <div>
      <div className="grid">
        <Helmet title={localizedContent.document_head_title_text} />
        <div className="row">
          <div className="col lg12 pad-bot2">
            <PanelHeading title={localizedContent.contact_us_title} subtitle={localizedContent.contact_us_subtitle} />
            <Dropdown
              id="selectOne"
              label=""
              options={[
                {
                  value: '',
                  text: 'Please select'
                },
                {
                  value: 'OLB',
                  text: "Oxford Learner's Bookshelf"
                },
                {
                  value: 'ORB',
                  text: 'Oxford Reading Buddy'
                },
                {
                  value: 'OTE',
                  text: 'Oxford Test of English'
                },
                {
                  value: 'OIC',
                  text: 'Oxford International Standardized Assessments'
                },
                {
                  value: 'OWL',
                  text: 'Oxford Owl'
                },
                {
                  value: 'OE',
                  text: 'Oxford Education'
                }
              ]}
              value={selectedProduct}
              onChange={value => handleSelection(value)}
            />
          </div>
        </div>
      </div>
      <div className="pad-bot2">
        <ContentDivider />
      </div>
    </div>
  );
}

ContactUsProductPicker.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  handleSelection: PropTypes.func.isRequired,
  selectedProduct: PropTypes.string.isRequired
};

export default withLocalizedContent('contactUsProductPicker')(ContactUsProductPicker);
