import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import withLocalizedContent from '../../../../language/withLocalizedContent';

import ActionList from '../../../../components/ActionList/ActionList';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import ControlledForm from '../../../../components/ControlledForm/ControlledForm';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import TextInput from '../../../../components/TextInput/TextInput';
import ValidationStatus from '../../../../components/ValidationStatus/ValidationStatus';

import { searchUserEdit } from '../../../../redux/actions/userAdminDashboard';

import appSettings from '../../../../globals/appSettings.js';
import { NAME_MAX_LIMIT } from '../../../../globals/validations';
import { toInitials } from '../../../../utils/string';

import { UserEditSuccess, UserEditFailure, UserEditProcessing } from '../../../../components';

import styles from './EditUserForm.scss';

function EditUserForm({
  userData: { userId, firstName, lastName, userName, email },
  localizedContent: { userAdminEdit: content },
  editUser,
  loading,
  success,
  onCloseAction
}) {
  const [firstNameValue, setFirstNameValue] = useState(firstName ?? '');
  const [firstNameValidation, setFirstNameValidation] = useState({ isActive: !firstName, message: '' });
  const [lastNameValue, setLastNameValue] = useState(lastName ?? '');
  const [lastNameValidation, setLastNameValidation] = useState({ isActive: !lastName, message: '' });
  const [emailValue, setEmailValue] = useState(email ?? '');
  const [emailValidation, setEmailValidation] = useState({ isActive: !email, message: '' });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isFormDisplayed, setIsFormDisplayed] = useState(true);

  useEffect(() => {
    if (!firstNameValidation.isActive && !lastNameValidation.isActive && !emailValidation.isActive) {
      setIsSubmitDisabled(false);

      return;
    }

    setIsSubmitDisabled(true);
  }, [firstNameValidation, lastNameValidation, emailValidation]);

  const handleFirstNameChange = value => {
    let validationMessage = '';

    if (!value) {
      validationMessage = content.first_name_error;
    }

    if (value && value.length > NAME_MAX_LIMIT) {
      validationMessage = content.first_name_max_limit_error;
    }

    if (value && value.length <= NAME_MAX_LIMIT) {
      validationMessage = '';
    }

    setFirstNameValidation({ isActive: !!validationMessage, message: validationMessage });
    setFirstNameValue(value);
  };

  const handleLastNameChange = value => {
    let validationMessage = '';

    if (!value) {
      validationMessage = content.last_name_error;
    }

    if (value && value.length > NAME_MAX_LIMIT) {
      validationMessage = content.last_name_max_limit_error;
    }

    if (value && value.length <= NAME_MAX_LIMIT) {
      validationMessage = '';
    }

    setLastNameValidation({ isActive: !!validationMessage, message: validationMessage });
    setLastNameValue(value);
  };

  const handleEmailChange = value => {
    const isEmailValid = appSettings.EMAIL_REGEX.test(value);
    let validationMessage = '';

    if (!isEmailValid && !emailValidation.isActive) {
      validationMessage = content.email_error;
    }

    setEmailValidation({ isActive: !!validationMessage, message: validationMessage });
    setEmailValue(value);
  };

  const handleSubmit = () => {
    editUser({
      userId,
      firstName: firstNameValue,
      lastName: lastNameValue,
      email: emailValue
    });
    setIsFormDisplayed(false);
  };

  return (
    <ScrollContainer
      headerContent={
        <header className={styles.header}>
          <div className={styles.initials}>{toInitials(firstName, lastName)}</div>
          <div>
            <h2 className={styles.heading}>{`${firstName} ${lastName}`}</h2>
            <div className={styles.userName}>{userName}</div>
          </div>
        </header>
      }
      footerContent={
        <ActionList>
          {isFormDisplayed && (
            <Button
              type={buttonTypes.PRIMARY}
              text={content.save_button}
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
            />
          )}
        </ActionList>
      }
    >
      {!isFormDisplayed && loading && <UserEditProcessing isLoading />}
      {!isFormDisplayed && !success && (
        <UserEditFailure
          failureMessage={false}
          unlinkErrorWrongPassword={false}
          wrongCurrentPassword={false}
          onBack={() => onCloseAction({})}
          onDone={() => onCloseAction({})}
        />
      )}
      {!isFormDisplayed && success && (
        <UserEditSuccess
          isSuccessful
          firstName={firstNameValue}
          lastName={lastNameValue}
          userName={userName}
          /* eslint-disable */
          onDone={() =>
            onCloseAction({ userId, firstName: firstNameValue, lastName: lastNameValue, email: emailValue })
          }
          onBack={() =>
            onCloseAction({ userId, firstName: firstNameValue, lastName: lastNameValue, email: emailValue })
          }
          /* eslint-enable */
        />
      )}
      {isFormDisplayed && (
        <ControlledForm className="pad2">
          <div className="gin-top3">
            <ValidationStatus
              forId="firstName"
              isActive={firstNameValidation.isActive}
              message={firstNameValidation.message}
            >
              <TextInput
                id="firstName"
                name="firstName"
                label={content.first_name_label}
                placeholder=""
                value={firstNameValue}
                onChange={e => handleFirstNameChange(e)}
              />
            </ValidationStatus>
            <ValidationStatus
              forId="lastName"
              isActive={lastNameValidation.isActive}
              message={lastNameValidation.message}
            >
              <TextInput
                id="lastName"
                name="lastName"
                label={content.last_name_label}
                placeholder=""
                value={lastNameValue}
                onChange={e => handleLastNameChange(e)}
              />
            </ValidationStatus>
            <ValidationStatus forId="email" isActive={emailValidation.isActive} message={emailValidation.message}>
              <TextInput
                id="email"
                name="email"
                label={content.email_label}
                placeholder=""
                value={emailValue}
                onChange={e => handleEmailChange(e)}
              />
            </ValidationStatus>
            <hr />
          </div>
        </ControlledForm>
      )}
    </ScrollContainer>
  );
}

EditUserForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  editUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  onCloseAction: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    userConsoleSearchEdit: { loading, success }
  } = state;
  return { loading, success };
};

const mapDispatchToProps = dispatch => ({
  editUser: data => {
    dispatch(searchUserEdit(data));
  }
});

export default compose(
  withLocalizedContent('userAdminEdit'),
  connect(mapStateToProps, mapDispatchToProps)
)(EditUserForm);
