import * as t from '../actionTypes';

export const validatePassword = password => ({
  type: t.RESET_PASSWORD_VALIDATE_INPUT,
  payload: password
});

export const resetPasswordRequest = email => ({
  type: t.RESET_PASSWORD_REQUEST,
  payload: email
});

export const resetPasswordSuccess = () => ({
  type: t.RESET_PASSWORD_SUCCESS
});

export const resetPasswordFailure = () => ({
  type: t.RESET_PASSWORD_FAILURE
});

export const resetPasswordReset = () => ({
  type: t.RESET_PASSWORD_RESET
});
