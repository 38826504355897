import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './NumberWithOrdinal.scss';

function ordinalKey(number) {
  if (number > 3 && number < 21) return 'default';

  switch (number % 10) {
    case 1:
      return '1';
    case 2:
      return '2';
    case 3:
      return '3';
    default:
      return 'default';
  }
}

function NumberWithOrdinal({
  number,
  className,
  numberClassName,
  ordinalClassName,
  localizedContent: { ordinal: cardinalOrdinalMap }
}) {
  const ordinal = cardinalOrdinalMap[ordinalKey(number)];

  return (
    <span className={className}>
      <span className={numberClassName}>{number}</span>
      <span className={classnames(styles.ordinal, ordinalClassName)}>{ordinal}</span>
    </span>
  );
}

NumberWithOrdinal.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  className: PropTypes.string,
  numberClassName: PropTypes.string,
  ordinalClassName: PropTypes.string
};

export default withLocalizedContent('ordinal')(NumberWithOrdinal);
