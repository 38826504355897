import { createSelector } from 'reselect';

/**
 * Takes the raw TR panel resources GET response and spits out the following object structure for use in the
 * vertial radio buttons group.
 *
  {
  childList: {
    Assessment: {
      tests: "1234-1234-1234-1234",
      tests2: "1234-1234-1234-1234"
    }...
  },
  parentList: {
    "Multimedia resources": "1234-1234-1234-1234",
    "Class resources": "1234-1234-1234-1234",
    }
  }
 */
export default createSelector(
  state => state.teacherResources.resources,
  (resources = {}) =>
    Object.keys(resources)[0]
      ? Object.values(resources)
          .reduce((accum, resourceValue) => {
            if ((resourceValue.status || '').toLowerCase() === 'success') {
              let categories = [];
              if (Object.keys(accum).length !== 0) {
                categories = [...(accum || []), ...resourceValue.categories];
              } else {
                categories = [...resourceValue.categories];
              }
              return categories;
            }
            return accum;
          }, {})
          .reduce(
            (accum, category) => {
              accum.parentList[category.parentName] = category.parentId;
              accum.childList[category.parentName] = {
                ...(accum.childList[category.parentName] || []),
                [category.name]: category.categoryId
              };
              return accum;
            },
            { parentList: {}, childList: {} }
          )
      : null
);
