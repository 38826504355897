import { get } from 'lodash';

export default function getAssessmentProductList(courses = {}) {
  const coursesAssessments = {};

  Object.values(courses).forEach(course => {
    const courseAssessment = get(course, ['assessmentFolders', 'assessments'], {});
    const hasLicence = courseAssessment?.hasLicence;
    if (hasLicence && Object.values(courseAssessment).length) {
      Object.assign(coursesAssessments, {
        [course.assessmentFolders?.teacherIsbn]: { ...courseAssessment, courseId: course.id }
      });
    }
  });

  return coursesAssessments;
}
