import React, { Component } from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Action imports
import { PRODUCT_TARGET_USERTYPE } from '@oup/shared-node-browser/constants';
import {
  backToSearch,
  setAssignLicences,
  submitAssignment,
  loadLicenceData,
  setTeacherAssignLicences,
  toggleDetailsPopupWithId,
  setProductSelected
} from '../../../redux/reducers/assignLearningMaterial.reducer.js';

// Component imports
import ScrollContainer from '../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../components/PopoutActionFooter/PopoutActionFooter.js';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail.js';
import MaterialRepresentation from '../../../components/MaterialRepresentation/MaterialRepresentation.js';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';
import Button, { buttonTypes } from '../../../components/Button/Button.js';
import { sanitizeUrl } from '../../../utils/url.js';

import AssignmentSummary from './ReviewLicencesViews/AssignmentSummaryMultiple.js';
import UserAssignments from './ReviewLicencesViews/UserAssignments.js';
import cmsContent from '../../../utils/cmsContent.js';
import PanelHeading from '../../../components/PanelHeading/PanelHeading.js';
import styles from './AssignLearningMaterial.scss';
import colors from '../../../globals/colors';
import { isHubMode } from '../../../utils/platform.js';

class AssignLearningMaterialMultipleReview extends Component {
  constructor() {
    super();
    this.state = {
      licenceViewOpen: false
    };
  }

  toggleView = licenceViewDetails => {
    const { licenceViewOpen } = this.state;
    this.setState({ licenceViewDetails, licenceViewOpen: !licenceViewOpen });
  };

  renderUserAssignments = () => {
    const { licenceViewDetails } = this.state;
    const productId = licenceViewDetails.productId;
    if (!productId) return null;
    const {
      assignLicencesMultiple,
      teacherAssignLicencesMultiple,
      proposedAssignments,
      usersExistingLicenceDetails,
      userIdsWithoutPermission,
      usersIdsWithLicences,
      userIdsInOrder,
      people,
      teacherIds,
      learnerIds,
      usersWithoutAssignments,
      selectedProducts: productsList
    } = this.props;

    const assignLicencesForSingleProduct = assignLicencesMultiple[productId];
    const userIdsInOrderForSingleProduct = userIdsInOrder[productId];
    const usersWithoutAssignmentsForSingleProduct = usersWithoutAssignments[productId];

    const userIdsToNotAssignLicencesTo = assignLicencesForSingleProduct
      ? usersWithoutAssignmentsForSingleProduct
      : userIdsInOrderForSingleProduct.concat(usersWithoutAssignmentsForSingleProduct);

    const userIdsToAssignLicencesTo = assignLicencesForSingleProduct ? userIdsInOrderForSingleProduct : [];

    const productDetails = productsList[productId];
    const productTargetUsertype = productDetails.target_usertype;

    return (
      <UserAssignments
        users={people}
        newLicenceAssignments={proposedAssignments[productId]}
        existingLicenceAssignments={usersExistingLicenceDetails[productId]}
        userIdsWithExistingLicence={usersIdsWithLicences[productId]}
        userIdsToAssignLicencesTo={userIdsToAssignLicencesTo}
        userIdsToNotAssignLicencesTo={userIdsToNotAssignLicencesTo}
        teacherIds={teacherIds[productId]}
        learnerIds={learnerIds[productId]}
        toggleView={this.toggleView}
        shouldTeacherAssignLicences={teacherAssignLicencesMultiple[productId].teacherAssignLicences}
        userIdsWithoutPermission={userIdsWithoutPermission[productId]}
        productTargetUsertype={productTargetUsertype}
      />
    );
  };

  render() {
    const {
      existingAssignmentId,
      closePopoutAction,
      loadingLicences,
      loadingLicencesFailed,
      teacherAssignLicencesMultiple,
      assignLicencesMultiple,
      licencesAvailable,
      toggleDetails,
      setAssignLicencesAction,
      setTeacherAssignLicencesAction,
      backToSearchAction,
      submitAssignmentAction,
      triggerLicenceLoad,
      context,
      selectedProducts: productsList,
      contextName,
      setProductSelectedAction,
      licencesAssignDetails,
      isVisibleWhoIsGettingLicence,
      defaultFilterUsertype = '',
      learnerIds,
      teacherIds,
      usersIdsWithLicences
    } = this.props;

    const CMS = cmsContent.assignLearningMaterialPanel || {};
    const { licenceViewOpen, licenceViewDetails } = this.state;
    const licenceViewState = licenceViewOpen && licenceViewDetails.productId;
    const blockAssignmentByUserType = isHubMode();

    // Check if any products has the right target usertype with user who get assigned
    let validateTargetUserType = true;
    if (blockAssignmentByUserType) {
      let k = 0;
      while (k < Object.keys(productsList).length && validateTargetUserType) {
        const productId = Object.keys(productsList)[k];
        const productTargetUsertype = productsList[productId]?.target_usertype;
        const learnersInProductAssignment = learnerIds[productId]?.length;
        const teachersInProductAssignment = teacherIds[productId]?.length;

        // if we have both teachers and learners in the assignment process then disable the usertype limit.
        if (learnersInProductAssignment && teachersInProductAssignment) break;

        // if teachers in list || teacher usertype filter is checked
        // for each product we verify if target_type is  valid for each teacher (target_type = teacher)
        if (defaultFilterUsertype.includes(PRODUCT_TARGET_USERTYPE.TEACHER) || teachersInProductAssignment) {
          validateTargetUserType = productTargetUsertype === PRODUCT_TARGET_USERTYPE.TEACHER || !productTargetUsertype;
          if (!validateTargetUserType) break;
        }
        // if students in list || student usertype filter is checked
        // for each product we verify if target_type is  valid for each students (target_type = NULL || student)
        if (defaultFilterUsertype.includes(PRODUCT_TARGET_USERTYPE.STUDENT) || learnersInProductAssignment) {
          validateTargetUserType = productTargetUsertype !== PRODUCT_TARGET_USERTYPE.TEACHER;
          if (!validateTargetUserType) break;
        }
        k += 1;
      }
    }
    // Prevent confirming the assignment +when loading, +when target user type is not valid for user role +if no products are selected.
    const canAssignMaterial = loadingLicences || !validateTargetUserType || Object.keys(productsList).length < 1;

    return (
      <form onSubmit={e => e.preventDefault()}>
        <ScrollContainer
          headerContent={
            licenceViewState ? (
              <div>
                <div className="text-right">
                  <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePopoutAction} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '1rem',
                    alignItems: 'center'
                  }}
                >
                  <MaterialRepresentation
                    thumbnailSize={thumbnailSizes.TABLE}
                    imageSrc={sanitizeUrl(productsList[licenceViewDetails.productId].coverImage)}
                    name={productsList[licenceViewDetails.productId].title}
                    subtext={productsList[licenceViewDetails.productId].subtitle}
                    statusText={(CMS.assign_material_for || '').replace('{assigneeName}', contextName)}
                    headerSizeName
                    ellipsisHeading
                  />
                  <div style={{ alignSelf: 'flex-start' }}>
                    <Button
                      type={buttonTypes.INFO_NO_BORDER}
                      glyph={GLYPHS.ICON_INFORMATION_CIRCLE}
                      iconOnly
                      onClick={() => toggleDetails(licenceViewDetails.productId, null, null)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="text-right">
                  <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePopoutAction} />
                </div>
                <div className={styles.heading}>
                  <PanelHeading
                    title={CMS.assign_material_licence_options}
                    subtitle={(CMS.assign_material_for || '').replace('{assigneeName}', contextName)}
                  />
                </div>
              </div>
            )
          }
          footerContent={
            licenceViewState ? (
              <div className={styles.footerContainer}>
                <PopoutActionFooter
                  secondaryButtonText={CMS.button_back_text}
                  secondaryButtonAction={this.toggleView}
                  wizardButtons
                />
              </div>
            ) : (
              <PopoutActionFooter
                secondaryButtonText={CMS.button_back_text}
                secondaryButtonAction={
                  context === 'USER_PROFILE' || existingAssignmentId ? closePopoutAction : backToSearchAction
                }
                primaryButtonText={CMS.assign_learning_material}
                primaryButtonAction={submitAssignmentAction}
                wizardButtons
                primaryButtonDisabled={canAssignMaterial}
              />
            )
          }
        >
          <div>
            {loadingLicences ? (
              <div className={classnames('pad-top4 pad-bot4', styles.loading)}>
                <div className={styles.loaderSvg}>
                  <SVGIcon glyph={GLYPHS.ICON_LOADING} />
                </div>
                <p className="gin-top2">{CMS.loading_licences_text}</p>
              </div>
            ) : (
              <div>
                {loadingLicencesFailed ? (
                  <div className={styles.loadingError}>
                    <div className={styles.svgIcon}>
                      <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
                    </div>
                    <p className="gin2">{CMS.loading_licences_error_text}</p>
                    <Button text={CMS.retry_button_text} onClick={triggerLicenceLoad} />
                  </div>
                ) : (
                  <div>
                    {productsList && Object.keys(productsList).length === 0 ? (
                      <div className={styles.noLearningMaterials}>
                        <div className={styles.svgIcon}>
                          <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
                        </div>
                        <div>
                          <p>{CMS.no_learning_materials}</p>
                        </div>
                        <div className={styles.addLearningMaterial}>
                          <button type="button" onClick={backToSearchAction}>
                            {CMS.add_learning_material}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {licenceViewState
                          ? this.renderUserAssignments()
                          : (Object.keys(productsList) || []).map(productId => {
                              const productDetails = productsList[productId];
                              const productTargetUsertype = productDetails.target_usertype;
                              const teacherTagText =
                                productTargetUsertype === PRODUCT_TARGET_USERTYPE.TEACHER ? CMS.teacher_material : '';

                              return (
                                <div key={productId} className={styles.learningMaterialReview}>
                                  <div className={styles.materialRepresentation}>
                                    <MaterialRepresentation
                                      thumbnailSize={thumbnailSizes.TABLE}
                                      imageSrc={sanitizeUrl(productDetails.coverImage)}
                                      name={productDetails.title}
                                      nameColor={colors.TEXT}
                                      teacherTagText={teacherTagText}
                                    />
                                    <div className={styles.infoIcon}>
                                      <Button
                                        type={buttonTypes.INFO_NO_BORDER}
                                        glyph={GLYPHS.ICON_INFORMATION_CIRCLE}
                                        iconOnly
                                        onClick={() => toggleDetails(productId)}
                                      />
                                    </div>
                                  </div>

                                  <AssignmentSummary
                                    licencesAssignDetails={licencesAssignDetails[productId]}
                                    productId={productId}
                                    shouldAssignLicences={assignLicencesMultiple[productId]}
                                    teacherAssignLicencesDetails={teacherAssignLicencesMultiple[productId]}
                                    hasLicencesAvailable={licencesAvailable[productId]}
                                    setAssignLicencesAction={setAssignLicencesAction}
                                    setTeacherAssignLicencesAction={setTeacherAssignLicencesAction}
                                    context={context}
                                    productSelectionAction={setProductSelectedAction}
                                    toggleView={this.toggleView}
                                    isVisibleWhoIsGettingLicence={isVisibleWhoIsGettingLicence}
                                    productTargetUsertype={productTargetUsertype}
                                    productPlatform={productDetails.platform}
                                    userIdsWithExistingLicence={usersIdsWithLicences[productId]}
                                    teacherIds={teacherIds[productId]}
                                  />
                                </div>
                              );
                            })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </ScrollContainer>
      </form>
    );
  }
}

AssignLearningMaterialMultipleReview.propTypes = {
  existingAssignmentId: PropTypes.string,
  closePopoutAction: PropTypes.func.isRequired,
  loadingLicences: PropTypes.bool.isRequired,
  loadingLicencesFailed: PropTypes.bool.isRequired,
  licencesAvailable: PropTypes.bool,
  backToSearchAction: PropTypes.func.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  setAssignLicencesAction: PropTypes.func.isRequired,
  setTeacherAssignLicencesAction: PropTypes.func.isRequired,
  submitAssignmentAction: PropTypes.func.isRequired,
  triggerLicenceLoad: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  licencesAssignDetails: PropTypes.object.isRequired,
  assignLicencesMultiple: PropTypes.object.isRequired,
  teacherAssignLicencesMultiple: PropTypes.object.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  contextName: PropTypes.string.isRequired,
  setProductSelectedAction: PropTypes.func.isRequired,
  proposedAssignments: PropTypes.object.isRequired,
  usersExistingLicenceDetails: PropTypes.object.isRequired,
  userIdsWithoutPermission: PropTypes.object.isRequired,
  usersIdsWithLicences: PropTypes.object.isRequired,
  usersWithoutAssignments: PropTypes.object.isRequired,
  userIdsInOrder: PropTypes.object.isRequired,
  people: PropTypes.object.isRequired,
  teacherIds: PropTypes.array,
  learnerIds: PropTypes.array,
  isVisibleWhoIsGettingLicence: PropTypes.bool.isRequired,
  defaultFilterUsertype: PropTypes.string
};

export default connect(
  state => {
    const {
      usersWithoutAssignments = [],
      teacherIdsInOrder = [],
      learnerUserIdsInOrder = [],
      assignLicencesMultiple,
      selectedProductIds = [],
      usersIdsWithLicences = [],
      licencesAssignDetails = {},
      teacherAssignLicencesMultiple = {}
    } = state.assignLearningMaterial;

    const selectedProducts = pick(state.products.data, selectedProductIds);

    return {
      ...pick(state.assignLearningMaterial, [
        'proposedAssignments',
        'usersExistingLicenceDetails',
        'usersIdsWithLicences',
        'userIdsWithoutPermission',
        'usersWithoutAssignments',
        'userIdsInOrder',
        'existingAssignmentId',
        'loadingLicences',
        'loadingLicencesFailed',
        'licencesAvailable',
        'context',
        'isVisibleWhoIsGettingLicence'
      ]),
      usersIdsWithLicences,
      assignLicencesMultiple,
      teacherAssignLicencesMultiple,
      licencesAssignDetails,
      usersWithoutAssignments,
      product: state.products.data[state.assignLearningMaterial.productId],
      people: state.people.data || {},
      teacherIds: teacherIdsInOrder,
      learnerIds: learnerUserIdsInOrder,
      userRoleName: state.identity.role,
      selectedProducts
    };
  },
  {
    backToSearchAction: backToSearch,
    toggleDetails: toggleDetailsPopupWithId,
    setAssignLicencesAction: setAssignLicences,
    submitAssignmentAction: submitAssignment,
    triggerLicenceLoad: loadLicenceData,
    setTeacherAssignLicencesAction: setTeacherAssignLicences,
    setProductSelectedAction: setProductSelected
  }
)(AssignLearningMaterialMultipleReview);
