import moment from 'moment';
import { PropTypes } from 'prop-types';
import React from 'react';
import { SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS } from '@oup/shared-node-browser/constants';
import StatusIcon, { StatusType } from '../StatusIcon/StatusIcon';

export const getStatusType = (licenceDetails, toBeAssigned, productFinder) => {
  const end = licenceDetails ? moment(licenceDetails.expiryDate || licenceDetails.endDate) : null;
  const now = moment();

  switch (true) {
    case productFinder && !licenceDetails:
      return StatusType.INFO;
    case !licenceDetails && toBeAssigned:
      return StatusType.PRODUCT_NO_LICENCE_TO_ASSIGN;
    case !licenceDetails:
      return StatusType.PRODUCT_NO_LICENCE;
    case licenceDetails.endDate && end.isBefore(now):
      return StatusType.PRODUCT_EXPIRED;
    case licenceDetails.endDate && end.diff(now, 'days') <= SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS:
      return StatusType.PRODUCT_EXPIRING;
    case !!licenceDetails:
      return StatusType.PRODUCT_VALID;
    default:
      return StatusType.PRODUCT_NO_LICENCE;
  }
};

function LicenceStatusIcon({ licenceDetails, statusMessage, toBeAssigned, productFinder }) {
  const statusType = getStatusType(licenceDetails, toBeAssigned, productFinder);
  return <StatusIcon type={statusType} title={statusMessage} />;
}

LicenceStatusIcon.propTypes = {
  licenceDetails: PropTypes.shape({
    endDate: PropTypes.string,
    unitType: PropTypes.string,
    timePeriod: PropTypes.number
  }),
  toBeAssigned: PropTypes.bool,
  productFinder: PropTypes.bool,
  statusMessage: PropTypes.string
};

export default LicenceStatusIcon;
