import { put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

export const getNofificationsUrl = (userId, orgId) => `${__API_BASE__}/org/${orgId}/user/${userId}/orb/notifications`;

function* getNotifications(userId, orgId) {
  const response = yield signedFetch('getNotifications', getNofificationsUrl(userId, orgId), 'GET');
  if (response.status === 'success') {
    yield put(actions.getNotificationsSuccess(response.data));
  }
}

export default function* root() {
  yield takeLatest(t.ORB_GET_NOTIFICATIONS, ({ payload: { userId, orgId } }) => getNotifications(userId, orgId));
}
