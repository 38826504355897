/**
 * Trigger `pageview` custom event on Google Tag Manager
 */
let cacheTitle;
export default function gtmPageview(params) {
  window.dataLayer = window.dataLayer || [];
  const isLoading = document.title.toLowerCase().includes('loading');

  if (cacheTitle !== document.title && !isLoading) {
    cacheTitle = document.title;

    window.dataLayer.push({
      event: 'pageview',
      pageTitle: cacheTitle,
      pagePath: window.location.pathname,
      userId: params?.userId || 'Visitor',
      userRole: params?.userRole || 'Visitor',
      orgId: params?.orgId || ''
    });
  }
}
