import console from '../../../../utils/console/asyncConsoleGroup.js';

// asyncConsole is used to batch up a group of async console.logs and output them all at once.
// It prevents noise from other console.logs from appearing inside a console.group.
// The downside is that no part of the group will output until groupEnd is called (or timeout).

export const TIMEOUT_ERROR = 'TIMEOUT_ERROR';

// Wrapper so we can fake a timeout feature on fetch requests:
// Sadly a fetch can't be aborted either (until FetchController reaches browsers)
function timeoutPromise(promise, timeout, error = TIMEOUT_ERROR) {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject(error), timeout);
    promise.then(resolve, reject);
  });
}

// fetch reponse headers
const responseHeaders = {};
function getHeaders(response) {
  [...response.headers.entries()].forEach(header => {
    responseHeaders[header[0]] = header[1];
  });
}

export default function genericFetch(apiName, fetchArgs, includeHeaders = false, includeStatusCode = false) {
  const logGroupName = `[genericFetch:${apiName}]`;
  let httpStatus;

  const REQUEST_URI = 0;
  const REQUEST_OPTIONS = 1;

  const fetchUri = fetchArgs[REQUEST_URI];
  const fetchOptions = fetchArgs[REQUEST_OPTIONS] || {};
  const timeout = fetchOptions.timeout; // overloading Request options with this custom property is confusing...
  delete fetchOptions.timeout; // ... tidy up the mess we created

  // Wrap fetch in a ticking promise if a timeout is needed:
  const doFetch = timeout ? timeoutPromise(fetch(fetchUri, fetchOptions), timeout) : fetch(fetchUri, fetchOptions);

  // Output a standard log message to mark when this logGroupName actually started:
  console.log(logGroupName, '[Starting async console.group]');

  // Start console group:
  console.groupCollapsed(logGroupName, 'ASYNC');
  console.log(logGroupName, 'Parameters:', fetchArgs);

  return doFetch
    .then(response => {
      getHeaders(response);
      console.log(logGroupName, 'Response:', response);
      httpStatus = response.status;
      return response.text();
    })
    .then(text => {
      console.log(logGroupName, 'Response text:', text);
      return text;
    })
    .catch(error => {
      const output = {};
      output.error = error;
      if (includeHeaders) output.headers = responseHeaders;
      if (includeStatusCode) output.statusCode = httpStatus;

      console.error(logGroupName, 'Error:', error);
      return output;
    })
    .then(result => {
      console.groupEnd(logGroupName);
      return result;
    });
}
