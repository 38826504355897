import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './SegmentBar.scss';

class SegmentBar extends Component {
  static getFullSegments = (fullSegments, barSize) => {
    const barSegments = [];
    for (let i = 0; i < fullSegments; i += 1) {
      barSegments.push(
        <div
          key={`segmentBar${i}`}
          className={styles.intervalFull}
          style={{ width: `${SegmentBar.fullSegmentSize(barSize)}}%` }}
        />
      );
    }
    return barSegments;
  };

  static partialSegmentSize = barSize => {
    const fullSegments = Math.floor(barSize);
    return fullSegments + 1 > 70 ? 0 : ((barSize - fullSegments) * 10).toFixed();
  };

  static fullSegmentSize = barSize => (Math.floor(barSize) + 1 < 10 ? 10 : Math.ceil(100 / Math.ceil(barSize)));

  static fullSegmentAmount = barSize => (Math.ceil(barSize) > 70 ? 70 : Math.floor(barSize));

  static buildSegmentBar = barSize => {
    const fullSegments = SegmentBar.fullSegmentAmount(barSize);
    const barSegments = SegmentBar.getFullSegments(fullSegments, barSize);
    const isSingleBar = barSize <= 1;

    if (Math.floor(barSize) > 70) {
      barSegments.push(
        <p key="Bar segment" className={styles.ellipsis}>
          ...
        </p>
      );
    }

    if (isSingleBar) {
      barSegments.push(
        <div
          key="segmentBarSingle"
          className={styles.singleBar}
          style={{ width: `${SegmentBar.partialSegmentSize(barSize)}%` }}
        />
      );
    }

    if (barSize - fullSegments !== 0 && !isSingleBar) {
      barSegments.push(
        <div
          key="segmentBarPartial"
          className={styles.intervalSegment}
          style={{ width: `${SegmentBar.partialSegmentSize(barSize)}%` }}
        />
      );
    }

    return barSegments;
  };

  render() {
    const { barSize } = this.props;
    return (
      <div>
        <div className={styles.intervalBar}>{SegmentBar.buildSegmentBar(barSize)}</div>
      </div>
    );
  }
}

SegmentBar.propTypes = {
  barSize: PropTypes.number.isRequired
};

export default SegmentBar;
