import { put } from 'redux-saga/effects';
import { getWordOfTheDaySuccess, getWordOfTheDayError } from '../../../../actions/wordOfTheDay';
import getWordOfTheDayApi from '../../../apiCalls/getWordOfTheDayApi';

function* getWordOfTheDay() {
  const response = yield getWordOfTheDayApi();
  if (response.status === 'success') {
    yield put(getWordOfTheDaySuccess(response.data));
  } else {
    yield put(getWordOfTheDayError());
  }
}

export default getWordOfTheDay;
