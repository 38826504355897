import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../Button/Button';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import ControlledForm from '../ControlledForm/ControlledForm';
import PanelScrollContainer from '../PanelScrollContainer/PanelScrollContainer';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './ImportUsersReview.scss';
import ImportUsersTable from './ImportUsersTable';

export const VALID_STATUS = 0;
export const WARNING_STATUS = 1;
export const ERROR_STATUS = 2;

export const ALL_ROWS = 0;
export const WARNING_ROWS = 1;

class ImportUsersReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      filter: false,
      hidden: []
    };
  }

  componentDidUpdate(prevProps) {
    const { numberOfErrors } = this.props;
    const { editing } = this.state;
    if (prevProps.numberOfErrors !== numberOfErrors && !editing) {
      const filter = numberOfErrors ? WARNING_ROWS : ALL_ROWS;
      this._filter(filter);
    }
  }

  _getFilterCount = filter => {
    const { users } = this.props;
    const usersArray = Object.values(users);
    return usersArray.filter(({ status: { code } }) => code === filter).length;
  };

  _filter = filter => {
    const { users } = this.props;
    this.setState({
      filter,
      hidden: Object.values(users)
        .filter(({ status: { code } }) => code < filter)
        .map(({ id }) => id)
    });
  };

  render() {
    const { filter, hidden, editing } = this.state;
    const {
      localizedContent: { importUsersReview: content },
      users,
      yearGroupOptions,
      numberOfErrors,
      update = () => {},
      destroy = () => {},
      validate = () => {},
      onBack = () => {},
      onNext = () => {},
      onExportCsv,
      storeUnsavedUsers,
      unsavedUsers
    } = this.props;
    const warnings = this._getFilterCount(WARNING_STATUS);
    const errors = this._getFilterCount(ERROR_STATUS);
    const subheading = `${Object.keys(users).length} ${content.panel_subheading}`;

    return (
      <ControlledForm>
        <PanelScrollContainer
          heading={content.panel_heading}
          subHeading={subheading}
          footer={
            <PopoutActionFooter>
              <div className={styles.actionsContainer}>
                <LinkWithIcon text={content.back_button_text} action={onBack} glyph={GLYPHS.ICON_LEFT} isLhs />
                <div>
                  <Button
                    text={content.export_csv_text}
                    onClick={() => onExportCsv(omit(users, hidden))}
                    type={buttonTypes.SECONDARY}
                    customClassName="gin-right2"
                    disabled={editing}
                  />
                  <Button
                    text={content.next_button_text}
                    onClick={onNext}
                    type={buttonTypes.PRIMARY}
                    disabled={editing || !!numberOfErrors}
                  />
                </div>
              </div>
            </PopoutActionFooter>
          }
        >
          <ScrollContainer
            headerContent={
              <div>
                {!errors && !warnings ? (
                  <span className={styles.icon}>
                    <SVGIcon glyph={GLYPHS.ICON_CHECK_CIRCLE} className={styles.glyph} />
                    {content.no_errors_found_text}
                  </span>
                ) : (
                  <div className={styles.summary}>
                    <div>
                      <span className={styles.icon}>
                        <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} className={styles.glyph} /> {errors}{' '}
                        {errors === 1 ? content.error_found_text : content.errors_found_text}
                      </span>
                      <span className={styles.icon}>
                        <SVGIcon glyph={GLYPHS.ICON_WARNING_CIRCLE} className={styles.glyph} /> {warnings}{' '}
                        {warnings === 1 ? content.warning_found_text : content.warnings_found_text}
                      </span>
                    </div>
                    <div className={styles.filters}>
                      <Button
                        type={buttonTypes.GROUP}
                        text={content.all_rows_filter_button_text}
                        active={filter === ALL_ROWS}
                        onClick={() => this._filter(ALL_ROWS)}
                      />
                      <Button
                        type={buttonTypes.GROUP}
                        text={content.errors_rows_filter_button_text}
                        active={filter === WARNING_ROWS}
                        onClick={() => this._filter(WARNING_ROWS)}
                      />
                    </div>
                  </div>
                )}
              </div>
            }
          >
            <ImportUsersTable
              users={omit(users, hidden)}
              yearGroups={yearGroupOptions}
              onChange={() => {
                this.setState({ editing: true });
              }}
              update={changes => {
                update(changes);
                this.setState({ editing: false });
              }}
              destroy={destroy}
              validate={validate}
              storeUnsavedUsers={storeUnsavedUsers}
              unsavedUsers={unsavedUsers}
            />
          </ScrollContainer>
        </PanelScrollContainer>
      </ControlledForm>
    );
  }
}

ImportUsersReview.propTypes = {
  users: PropTypes.object,
  yearGroupOptions: PropTypes.object.isRequired,
  numberOfErrors: PropTypes.number,
  update: PropTypes.func,
  destroy: PropTypes.func,
  validate: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onExportCsv: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  storeUnsavedUsers: PropTypes.func,
  unsavedUsers: PropTypes.object
};

export default withLocalizedContent('importUsersReview')(ImportUsersReview);
