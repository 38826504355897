const mapIconsToFileType = {
  ICON_AUDIO_RESOURCE: ['mp3'],
  ICON_VIDEO_RESOURCE: ['mp4'],
  ICON_FILE_RESOURCE: ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'ppx', 'txt', 'xls', 'xlsx'],
  ICON_FOLDER: ['zip'],
  ICON_IMAGE: ['png', 'jpg', 'jpeg']
};

const defaultResourceIcon = 'ICON_FOLDER';

const getResourceIcon = (format = '') =>
  Object.keys(mapIconsToFileType)
    .map(mapping => (mapIconsToFileType[mapping].includes(format.toLowerCase()) ? mapping : undefined))
    .reduce((acc, val) => (val === undefined ? acc : val), '') || defaultResourceIcon;

export default getResourceIcon;
