import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { EntityPageHeader, ErrorStatus, PageWrapper, PopoutPanelIconHeading } from '../components';
import { types as PopoutPanelIconHeadingTypes } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import RadioButton, { RadioAppearence } from '../components/RadioButton/RadioButton';
import RadioInput, { InputType, RadioSize } from '../components/RadioInput/RadioInput';
import { Filter, ReactiveFilters, ResourcesTable } from '../components/TeacherResources';
import ENTITY_TYPES from '../globals/entityTypes';
import withLocalizedContent from '../language/withLocalizedContent';
import breakpoints from '../styles/constants/_breakpoints.scss';
import Color from '../styles/constants/_colors.scss';
import { TeacherResource as TeacherResourcePropType } from '../types';
import interpolateContent from '../utils/interpolateContent';
import styles from './TeacherResourcesView.scss';

class TeacherResourcesView extends Component {
  componentDidUpdate({ consumer: previousConsumer }) {
    const { consumer } = this.props;
    if (consumer !== previousConsumer) {
      this._populateAndSubmitForm(consumer);
    }
  }

  _formRef = form => {
    this.ltiForm = form;
  };

  _populateAndSubmitForm = consumer => {
    Object.entries(consumer).map(([name, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = value;
      return this.ltiForm.appendChild(input);
    });
    this.ltiForm.action = `${__API_BASE__}/open/lti/provider`;
    this.ltiForm.method = 'POST';
    this.ltiForm.submit();
  };

  _renderHorizontalButtons = () => {
    const { horizontalOptions } = this.props;

    return Object.keys(horizontalOptions)[0]
      ? horizontalOptions.radioNames.map(radioButton => (
          <RadioButton
            key={radioButton.uuid}
            name="horizontal-filters"
            inputType={InputType.CHECKBOX}
            value={radioButton.uuid}
            label={radioButton.name}
            color={Color['tr-secondary']}
            appearence={RadioAppearence.ROUNDED}
          />
        ))
      : null;
  };

  _renderVerticalButtons = () => {
    const { verticalOptions } = this.props;

    return verticalOptions && Object.keys(verticalOptions)[0]
      ? Object.entries(verticalOptions.parentList).reduce(
          (radioInputList, [name, uuid]) => [
            ...(radioInputList || []),
            <RadioInput key={uuid} name="vertical-filters" value={uuid} label={name} color={Color['tr-primary']} />,
            ...(Object.entries(verticalOptions.childList[name]).map(([childName, childUuid]) => (
              <RadioInput
                key={childUuid}
                name="vertical-filters"
                value={childUuid}
                label={childName}
                color={Color['tr-primary']}
                size={RadioSize.SMALL}
                indented
              />
            )) || [])
          ],
          [<RadioInput key="all" name="vertical-filters" value="" label="Everything" color={Color['tr-primary']} />]
        )
      : null;
  };

  _getContent = () => {
    const {
      localizedContent: { teacherResourcesView: content },
      resources,
      isLoading,
      hasFailed,
      errors,
      clearFailure,
      onCategoryFilterChange,
      onUnitFilterChange,
      categoryFilter = null,
      unitFilter = null
    } = this.props;

    switch (true) {
      case isLoading:
        return <PopoutPanelIconHeading type={PopoutPanelIconHeadingTypes.LOADING} title={content.loading_title} />;
      case hasFailed: {
        const errorMessages = Object.values(errors)
          .map(error => error.message || content.error_unknown)
          .join(', ');

        return (
          <ErrorStatus
            title={content.error_title}
            subtitle={errorMessages}
            buttonText={content.error_button}
            buttonOnClickHandler={clearFailure}
          />
        );
      }
      default:
        return (
          <div className="row">
            <div className="col sm3">
              <ReactiveFilters
                label={content.primary_filter_label}
                breakpoint={breakpoints.smMedia}
                onChange={onCategoryFilterChange}
                selected={categoryFilter}
                defaultDropdownText="Choose type of content"
              >
                {this._renderVerticalButtons()}
              </ReactiveFilters>
            </div>
            <div className="col sm9">
              <ReactiveFilters
                label={content.secondary_filter_label}
                breakpoint={breakpoints.smMdMedia}
                onChange={onUnitFilterChange}
                selected={unitFilter}
                defaultDropdownText="Choose some content"
                clearDropdownOption={{ text: 'Show all', value: '' }}
                horizontal
              >
                {this._renderHorizontalButtons()}
              </ReactiveFilters>
              <Filter collection={resources} tags={[unitFilter, categoryFilter]} properties={['downloadable']}>
                {filteredResources => (
                  <div className="gin-top4">
                    {filteredResources.length ? (
                      <ResourcesTable
                        resources={filteredResources}
                        {...pick(this.props, [
                          'categories',
                          'downloadResource',
                          'downloadIsLoading',
                          'downloadHasFailed',
                          'currentDownload'
                        ])}
                      />
                    ) : (
                      <p>{content.no_results_label}</p>
                    )}
                  </div>
                )}
              </Filter>
            </div>
          </div>
        );
    }
  };

  render() {
    const {
      localizedContent: { teacherResourcesView: content },
      productName,
      productImage,
      isLoading,
      hasFailed
    } = this.props;

    return (
      <div>
        <Helmet title={interpolateContent(content.page_title, { productName })} />
        <EntityPageHeader
          customClass={styles.header}
          entityType={ENTITY_TYPES.RESOURCES}
          entityTitle={interpolateContent(content.header_title, { productName })}
          entitySubtitle={
            !isLoading && !hasFailed ? interpolateContent(content.header_subtitle, { productName }) : null
          }
          entityImage={productImage}
          displayEditButton={false}
        />
        <PageWrapper>{this._getContent()}</PageWrapper>
        <form ref={this._formRef} />
      </div>
    );
  }
}

TeacherResourcesView.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  resources: PropTypes.arrayOf(TeacherResourcePropType).isRequired,
  productName: PropTypes.string.isRequired,
  productImage: PropTypes.string,
  isLoading: PropTypes.bool,
  hasFailed: PropTypes.bool,
  downloadIsLoading: PropTypes.bool,
  downloadHasFailed: PropTypes.bool,
  currentDownload: PropTypes.string,
  consumer: PropTypes.object,
  errors: PropTypes.object,
  clearFailure: PropTypes.func.isRequired,
  downloadResource: PropTypes.func.isRequired,
  horizontalOptions: PropTypes.object,
  verticalOptions: PropTypes.object,
  categories: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onCategoryFilterChange: PropTypes.func.isRequired,
  onUnitFilterChange: PropTypes.func.isRequired,
  categoryFilter: PropTypes.string,
  unitFilter: PropTypes.string
};

export default withLocalizedContent('teacherResourcesView')(TeacherResourcesView);
