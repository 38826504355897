// eslint-disable-next-line import/no-cycle
import getCredentials from './getCredentials.saga.js';
import getIdentity from './getIdentity.saga.js';

export default function* initialiseAuth() {
  // NOTE: authSettings was already loaded in preRender loadEnvironment before initialiseAuth call

  console.log('[initialiseAuth] Starting, loading credentials');
  const credentialsLoaded = yield getCredentials();

  if (credentialsLoaded) {
    console.log('[initialiseAuth] Loaded credentials, getting identity...');
    yield getIdentity();
  }

  console.log('[initialiseAuth] Complete');
}
