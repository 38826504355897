import PropTypes from 'prop-types';
import React from 'react';
import Button, { buttonTypes } from '../Button/Button';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';

function CsvUserUploadSuccess({ content, email, goBackToDashboard }) {
  return (
    <div className="grid pad-bot4">
      <div className="row">
        <div className="col">
          <PopoutPanelIconHeading type={types.VALID} />
          <p className="text-center gin-bot3">{content.successMessage.replace('{email}', email)}</p>

          <div className="row cols-center">
            <span className="gin-right2">
              <Button
                id="secondary-button"
                type={buttonTypes.SECONDARY}
                text={content.closeButton}
                onClick={goBackToDashboard}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

CsvUserUploadSuccess.propTypes = {
  content: PropTypes.object.isRequired,
  goBackToDashboard: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired
};

export default CsvUserUploadSuccess;
