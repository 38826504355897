/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PRODUCT_TARGET_USERTYPE } from '@oup/shared-node-browser/constants.js';
// Action imports
import { getProductFinderLicencesRequest, goToPreviousFormState } from '../../../redux/actions/productFinderActions';
// Component imports
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import PanelNavigationLink from '../../PanelNavigationLink/PanelNavigationLink';
import MaterialRepresentation from '../../MaterialRepresentation/MaterialRepresentation';
import ProductFinderLicenceAssignmentInfo from '../reviewLicences/ProductFinderLicenceAssignmentInfo';
import ProductFinderLicencesFailed from '../reviewLicences/ProductFinderLicencesFailed';
import ProductFinderLoadingLicences from '../reviewLicences/ProductFinderLoadingLicences';
// Utils and globals
import { SIZES as thumbnailSizes } from '../../Thumbnail/Thumbnail';
import { sanitizeUrl } from '../../../utils/url';
import { STATUS_TYPE } from '../../../globals/appConstants';
// Styles
import styles from './ProductFinderFormStates.scss';

const licenceAvailabilityText = (productFinderContent, availableCount) => {
  if (availableCount > 0) {
    return `${availableCount} ${productFinderContent.count_licences_available_text}`;
  }
  return productFinderContent.no_licences_available_text;
};
const licenceAvailabilityStatus = availableCount => {
  if (availableCount > 0) {
    return STATUS_TYPE.SUCCESS;
  }
  return STATUS_TYPE.VALID;
};

function ProductFinderViewLicenceInformation({
  productFinderContent,
  product,
  closePopoutAction,
  goToPreviousFormStateAction,
  loadingLicences,
  loadingLicencesFailed,
  getProductFinderLicencesRequestAction,
  teacherIdList = [],
  studentIdList = [],
  people,
  licencesTypes,
  proposedAssignments,
  usersIdsWithLicences,
  usersExistingLicenceDetails,
  userIdsToAssignLicencesTo,
  userIdsToNotAssignLicencesTo
}) {
  const [availableCount, setAvailableCount] = useState(0);

  const getUserTypeLabel = () => {
    if (product?.target_usertype === PRODUCT_TARGET_USERTYPE.STUDENT) return productFinderContent.student_material;
    if (product?.target_usertype === PRODUCT_TARGET_USERTYPE.TEACHER) return productFinderContent.teacher_material;
    return null;
  };

  useEffect(() => {
    if (licencesTypes?.length) {
      let totalAvailableCount = 0;
      licencesTypes.forEach(licence => {
        totalAvailableCount += licence.availableCount;
      });
      setAvailableCount(totalAvailableCount);
    }
  }, [licencesTypes]);

  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        headerContent={
          <div>
            <div className={styles.textRight}>
              <PanelNavigationLink
                isLhs={false}
                text={productFinderContent.close_panel_text}
                action={closePopoutAction}
              />
            </div>
            <div className={styles.productContainer}>
              <MaterialRepresentation
                thumbnailSize={thumbnailSizes.TABLE}
                imageSrc={sanitizeUrl(product?.cover || product?.coverImage || '', null)}
                name={product.title}
                subtext={product.subtitle}
                statusText={licenceAvailabilityText(productFinderContent, availableCount)}
                statusColor={licenceAvailabilityStatus(availableCount)}
                headerSizeName
                ellipsisHeading
                statusIndication
                teacherTagText={getUserTypeLabel()}
                helpAndSupportLink={productFinderContent.learn_about_licences}
                helpAndSupportLinkTo={productFinderContent.what_is_a_licence}
              />
            </div>
          </div>
        }
      >
        <div className={styles.licencesContainer}>
          {loadingLicences ? (
            <ProductFinderLoadingLicences productFinderContent={productFinderContent} />
          ) : (
            <div>
              {loadingLicencesFailed ? (
                <ProductFinderLicencesFailed
                  productFinderContent={productFinderContent}
                  getProductFinderLicences={getProductFinderLicencesRequestAction}
                />
              ) : (
                <div>
                  <ProductFinderLicenceAssignmentInfo
                    productFinderContent={productFinderContent}
                    users={people}
                    newLicenceAssignments={proposedAssignments}
                    existingLicenceAssignments={usersExistingLicenceDetails}
                    userIdsWithExistingLicence={usersIdsWithLicences}
                    userIdsToAssignLicencesTo={userIdsToAssignLicencesTo}
                    userIdsToNotAssignLicencesTo={userIdsToNotAssignLicencesTo}
                    teacherIdList={teacherIdList}
                    studentIdList={studentIdList}
                    backAction={goToPreviousFormStateAction}
                    productTargetUsertype={product?.target_usertype}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </ScrollContainer>
    </form>
  );
}

export default connect(
  state => {
    const productId = state.productFinder?.product?.productid;
    const {
      usersWithoutAssignments = [],
      licencesTypes = [],
      userIdsInOrder = [],
      proposedAssignments,
      usersIdsWithLicences,
      usersExistingLicenceDetails,
      userIdsWithoutPermission = [],
      teacherIds,
      studentIds
    } = state.productFinder?.licenceStructure?.[productId] || {};

    const { existingAssignmentId, loadingLicences, loadingLicencesFailed, assignLicences } = state.productFinder;
    return {
      assignLicences,
      existingAssignmentId,
      loadingLicences,
      loadingLicencesFailed,
      licencesTypes,
      proposedAssignments,
      usersIdsWithLicences,
      usersExistingLicenceDetails,
      userIdsToAssignLicencesTo: assignLicences ? userIdsInOrder : [],
      userIdsToNotAssignLicencesTo: assignLicences
        ? usersWithoutAssignments
        : userIdsInOrder.concat(usersWithoutAssignments),
      usersWithoutAssignments,
      people: state.people.data || {},
      teacherIdList: teacherIds,
      studentIdList: studentIds,
      userIdsWithoutPermission,
      userRoleName: state.identity.role
    };
  },
  {
    getProductFinderLicencesRequestAction: getProductFinderLicencesRequest,
    goToPreviousFormStateAction: goToPreviousFormState
  }
)(ProductFinderViewLicenceInformation);

ProductFinderViewLicenceInformation.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  product: PropTypes.object,
  closePopoutAction: PropTypes.func,
  loadingLicences: PropTypes.bool,
  loadingLicencesFailed: PropTypes.bool,
  getProductFinderLicencesRequestAction: PropTypes.func,
  teacherIdList: PropTypes.array,
  studentIdList: PropTypes.array,
  people: PropTypes.object.isRequired,
  licencesTypes: PropTypes.array,
  proposedAssignments: PropTypes.object,
  usersIdsWithLicences: PropTypes.array,
  usersExistingLicenceDetails: PropTypes.object,
  userIdsToAssignLicencesTo: PropTypes.array.isRequired,
  userIdsToNotAssignLicencesTo: PropTypes.array.isRequired,
  goToPreviousFormStateAction: PropTypes.func.isRequired
};
