import React from 'react';
import PropTypes from 'prop-types';
import Button, { buttonTypes } from '../Button/Button';
import styles from './PencilReviewButton.scss';

function PencilReviewButton({ buttonActive = false, getActivityLink = () => {} }) {
  const buttonId = `pencilReview`;
  const addScoreLabel = 'Add score';
  const needReviewLabel = 'Needs review';
  return (
    <Button
      className={styles.pencilReviewButton}
      id={buttonId}
      iconOnly
      title={buttonActive ? addScoreLabel : needReviewLabel}
      onClick={e => {
        getActivityLink();
        e.stopPropagation();
      }}
      type={buttonActive ? buttonTypes.PENCIL_REVIEW_ACTIVE : buttonTypes.PENCIL_REVIEW}
      preventDefault
    />
  );
}

PencilReviewButton.propTypes = {
  buttonActive: PropTypes.bool,
  getActivityLink: PropTypes.func
};

export default PencilReviewButton;
