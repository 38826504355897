import userRoles from '../../../globals/userRoles';
import { isLtiMode } from '../../../utils/platform';

const getOrgClassesActionButtons = (
  content,
  { setClassToArchive = null, redirectToProgress = null, copyClassCode = null },
  { isClassCodeEnabled = false }
) => {
  if (isClassCodeEnabled) {
    return {
      [userRoles.ORG_ADMIN]: [
        {
          title: content.view_class_gradebook_text,
          action: redirectToProgress
        },
        {
          title: content.copy_class_code,
          label: content.copied,
          action: copyClassCode
        },
        ...(isLtiMode()
          ? []
          : [
              {
                title: content.archive_class,
                action: setClassToArchive,
                testHook: 'HUB_DROPDOWN_ARCHIVE_CLASS_ACTION'
              }
            ])
      ],
      [userRoles.TEACHER_ADMIN]: [
        {
          title: content.view_class_gradebook_text,
          action: redirectToProgress
        },
        {
          title: content.copy_class_code,
          label: content.copied,
          action: copyClassCode
        },
        ...(isLtiMode()
          ? []
          : [
              {
                title: content.archive_class,
                action: setClassToArchive
              }
            ])
      ],
      [userRoles.TEACHER]: [
        {
          title: content.view_class_gradebook_text,
          action: redirectToProgress
        },
        ...(isLtiMode()
          ? []
          : [
              {
                title: content.archive_class,
                action: setClassToArchive
              }
            ])
      ]
    };
  }

  return {
    [userRoles.ORG_ADMIN]: [
      {
        title: content.view_class_gradebook_text,
        action: redirectToProgress
      },
      ...(isLtiMode()
        ? []
        : [
            {
              title: content.archive_class,
              action: setClassToArchive
            }
          ])
    ],
    [userRoles.TEACHER_ADMIN]: [
      {
        title: content.view_class_gradebook_text,
        action: redirectToProgress
      },
      ...(isLtiMode()
        ? []
        : [
            {
              title: content.archive_class,
              action: setClassToArchive
            }
          ])
    ],
    [userRoles.TEACHER]: [
      {
        title: content.view_class_gradebook_text,
        action: redirectToProgress
      },
      ...(isLtiMode()
        ? []
        : [
            {
              title: content.archive_class,
              action: setClassToArchive
            }
          ])
    ]
  };
};

export default getOrgClassesActionButtons;
