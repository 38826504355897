import { put } from 'redux-saga/effects';
import editJoiningCode from '../../../apiCalls/editJoiningCodeApi';

import { submitEditJoiningCodeSuccess, submitEditJoiningCodeFailure } from '../../../../actions/placementTests';

function* submitJoiningCodeUpdates({ payload }) {
  const { orgId, joiningCodeId, data } = payload;

  const response = yield editJoiningCode(orgId, joiningCodeId, data);
  if (response.status === 'success') {
    yield put(submitEditJoiningCodeSuccess());
  } else {
    yield put(submitEditJoiningCodeFailure(response.message));
  }
}

export default submitJoiningCodeUpdates;
