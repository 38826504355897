import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withLocalizedContent from '../../language/withLocalizedContent';

function RelativeDate({ date, localizedContent: { relativeDate: i18n } }) {
  const targetDate = moment(date);
  const daysDiff = moment()
    .startOf('day')
    .diff(targetDate.clone().startOf('day'), 'days', true);
  let relativeDate;

  if (targetDate.isSame(moment(), 'day')) {
    relativeDate = i18n.today;
  } else if (targetDate.isSame(moment().subtract(1, 'day'), 'day')) {
    relativeDate = i18n.yesterday;
  } else if (daysDiff <= 30) {
    // eslint-disable-next-line no-template-curly-in-string
    relativeDate = (i18n.days_ago || '').replace('${number}', Math.ceil(daysDiff));
  } else {
    relativeDate = targetDate.format('D/M/Y');
  }

  return <span>{relativeDate}</span>;
}

RelativeDate.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired
};

export default withLocalizedContent('relativeDate')(RelativeDate);
