import React from 'react';
import PropTypes from 'prop-types';
import styles from './PieChart.scss';

function PieChart({ percentage = 0 }) {
  return <div className={styles.pie} style={{ '--percentage': percentage }} />;
}

PieChart.propTypes = {
  percentage: PropTypes.number
};

export default PieChart;
