import { put } from 'redux-saga/effects';
import schema from '../../../../../../static/schema/draft-4/edit-managed-user-auth-request-body.json';
import { MANAGED_USER_CHANGE_USERNAME_SET_VALIDATION_RESULT } from '../../../../actionTypes';
import { setManagedUserChangeUsernameValidationResult } from '../../../../actions/managedUserChangeUsername';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import checkUsername from '../../../api/checkUsername';

/**
 * Validate whether a new username for a managed user is of the valid format
 * and is not currently taken within the organisation.
 *
 * @param {string} orgId
 * @param {string} username
 *
 * @yield {Object} The next Redux effect description
 */
function* validateUsername(orgId, username) {
  const errors = yield validateInputUsingSchema(schema, MANAGED_USER_CHANGE_USERNAME_SET_VALIDATION_RESULT, {
    newUserName: username
  });

  if (Object.values(errors).includes(true)) {
    return;
  }

  try {
    const response = yield checkUsername(username);
    const usernameTaken = response.data.exists;

    if (response.status === 'ERROR') {
      throw new Error();
    }

    yield put(
      setManagedUserChangeUsernameValidationResult({
        newUserName: usernameTaken,
        usernameTaken
      })
    );
  } catch (e) {
    yield put(
      setManagedUserChangeUsernameValidationResult({
        newUserName: false,
        usernameTaken: false
      })
    );
  }
}

export default validateUsername;
