import PropTypes from 'prop-types';
import React from 'react';
import HorizontalScrollContainer from '../HorizontalScrollContainer/HorizontalScrollContainer';
import styles from './StudentAttainmentTable.scss';

function StudentAttainmentTable({ title, rows, columns, emptyMessage }) {
  return !rows.length && emptyMessage ? (
    <p>{emptyMessage}</p>
  ) : (
    <div className={styles.wrapper}>
      <HorizontalScrollContainer backgroundColor="white">
        <table className={styles.table}>
          {title ? <thead className={styles.title}>{title}</thead> : null}
          <tbody>
            <tr>
              {columns.map((column, colIndex) => (
                <th className={styles.columnHeader} key={`columnHeader${colIndex}`}>
                  {column.label}
                </th>
              ))}
            </tr>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td key={`tableEntry${colIndex}`} className={styles.entry}>
                    {row[column.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </HorizontalScrollContainer>
    </div>
  );
}

StudentAttainmentTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  emptyMessage: PropTypes.string
};

export default StudentAttainmentTable;
