import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button, { buttonTypes } from '../../../components/Button/Button';
import styles from './TabCaption.scss';

function TabCaption({ children, onButtonClick, active }) {
  return (
    <span className={classnames({ [styles.tabCaption]: onButtonClick })}>
      <div className={styles.tabCaptionContent}>{children}</div>
      {onButtonClick ? (
        <Button
          ariaLabel="more"
          role="button"
          onClick={onButtonClick}
          type={buttonTypes.DROPDOWN_SHORT}
          text=""
          active={active}
        />
      ) : null}
    </span>
  );
}

TabCaption.propTypes = {
  children: PropTypes.node,
  onButtonClick: PropTypes.func,
  active: PropTypes.bool,
  styleSmall: PropTypes.bool
};

export default TabCaption;
