import * as t from '../actionTypes';

export const RESET_FORM = 'createLMSConfig/RESET_FORM';

// -- create
export const createLMSConfigRequest = (orgId, toolId = '', payload) => ({
  type: t.CREATE_LMS_CONFIG_DEPLOYMENT_REQUEST,
  orgId,
  toolId,
  payload
});

export const createLMSConfigSuccess = payload => ({
  type: t.CREATE_LMS_CONFIG_DEPLOYMENT_SUCCESS,
  payload
});

export const createLMSConfigFailure = payload => ({
  type: t.CREATE_LMS_CONFIG_DEPLOYMENT_FAILURE,
  payload
});

export const toggleOxfordLTIConfig = () => ({
  type: t.LMS_CONFIG_TOGGLE_OXFORD_LTI
});

export const setFormFieldData = (field, value) => ({
  type: t.LMS_CONFIG_SET_FORM_DATA,
  field,
  value
});

export const validateFormFieldData = field => ({
  type: t.LMS_CONFIG_DEPLOYMENT_VALIDATE_INPUT,
  field
});

export const checkIfFormIsValid = fields => ({
  type: t.LMS_CONFIG_CHECK_IF_FORM_IS_VALID,
  fields
});

export const editLMSConfigTool = tool => ({
  type: t.LMS_CONFIG_TOOL_EDIT,
  tool
});

export const archiveLMSConfigTool = tool => ({
  type: t.LMS_CONFIG_TOOL_ARCHIVE,
  tool
});

export const toggleEditOrgConfig = initialFormState => ({
  type: t.LMS_CONFIG_TOGGLE_ORG_CONFIG_EDIT,
  initialFormState
});

export const resetForm = () => ({
  type: t.LMS_CONFIG_TOOL_RESET
});

// -- delete
export const deleteLMSConfigRequest = (orgId, toolId) => ({
  type: t.DELETE_LMS_CONFIG_DEPLOYMENT_REQUEST,
  payload: { orgId, toolId }
});

export const deleteLMSConfigSuccess = (orgId, clientId, deploymentId) => ({
  type: t.DELETE_LMS_CONFIG_DEPLOYMENT_SUCCESS,
  payload: { orgId, clientId, deploymentId }
});

export const deleteLMSConfigFailure = payload => ({
  type: t.DELETE_LMS_CONFIG_DEPLOYMENT_FAILURE,
  payload
});

export const getLMSOrgConfig = payload => ({
  type: t.GET_LMS_ORG_CONFIG,
  payload
});
