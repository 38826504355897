import { put } from 'redux-saga/effects';
import createClassroomApi from '../../../apiCalls/createClassroomApi';
import { getCurrentPlatform } from '../../../../../utils/platform';
import {
  setPlacementTestSessionNameSuccess,
  setPlacementTestSessionNameError
} from '../../../../actions/placementTestOnboardingWizard';
import editPlacementTestApi from '../../../apiCalls/editPlacementTestApi';

function* createPlacementTestSession(data) {
  const payload = {
    creatingTeacher: data.userId,
    name: data.placementTestSessionNameValue,
    teachers: [data.userId],
    students: [],
    platformCode: getCurrentPlatform() || '',
    classType: 'PLACEMENT_GROUP'
  };
  const results = yield createClassroomApi(data.orgId, payload);

  if (results?.status === 'success') {
    const editPayload = {
      ...data.placementTest
    };
    const editResults = yield editPlacementTestApi(data.orgId, results.data.groupId, editPayload);
    if (editResults?.status === 'success')
      return yield put(
        setPlacementTestSessionNameSuccess({ classId: results.data.groupId, joiningCode: results.data.joiningCode })
      );
  }
  return yield put(setPlacementTestSessionNameError());
}

export default createPlacementTestSession;
