import * as t from '../actionTypes';

const initialState = {
  userLicence: {},
  loadingUserLicence: false,
  success: false,
  errors: {}
};

function userLicence(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_USER_LICENCE_REQUEST:
      return {
        ...initialState,
        loadingUserLicence: true
      };
    case t.GET_USER_LICENCE_FAILURE:
      return {
        ...state,
        loadingUserLicence: false,
        success: false,
        errors: { search: payload }
      };
    case t.GET_USER_LICENCE_SUCCESS:
      return {
        ...state,
        loadingUserLicence: false,
        errors: {},
        success: true,
        userLicence: payload.data
      };
    case t.GET_USER_LICENCE_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default userLicence;
