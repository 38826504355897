import { put } from 'redux-saga/effects';
import { setResults, setError } from '../../../../reducers/data/search.reducer';
import getClassAssessments from '../../../apiCalls/hub/getClassAssessments';

const instance = 'orgClassAssessments';

function* loadClassAssessments(payload) {
  const response = yield getClassAssessments(payload);
  if (response.status === 'success') {
    yield put(setResults(instance, response.data.testIds, response.data.testIds.length, response.data.classTests));
  } else {
    yield put(setError(instance));
  }
}

export default loadClassAssessments;
