import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import colors from '../../../../../../globals/colors.js';

// Action imports
import {
  gotoTeacherSelection,
  gotoStudentSelection,
  removeTeacher,
  removeStudent,
  setEnableStudentLimit,
  setStudentLimit,
  createClass
} from '../../../../../../redux/reducers/classroomCreate.js';

// Component imports
import ClassroomNameInput from './ClassroomNameInput.js';
import Lozenge from '../../../../../../components/Lozenge/Lozenge.js';
import ConnectedLozenge from '../../../../../../components/Lozenge/ConnectedLozenge.js';
import PanelHeading from '../../../../../../components/PanelHeading/PanelHeading.js';
import SelectionList from '../../../../../../components/SelectionList/SelectionList.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import Validation from '../../../../../../components/Validation/Validation.js';
import ToggleWithNumberInput from '../../../../../../components/ToggleWithNumberInput/ToggleWithNumberInput.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';

import cmsContent from '../../../../../../utils/cmsContent.js';
import { featureIsEnabled } from '../../../../../../globals/envSettings';
import { isOrbMode } from '../../../../../../utils/platform';

function AddClassroomReview({
  closePopoutAction,

  // Validation
  classroomNameIsInvalid,
  classroomNamePending,
  // Input values
  classroomNameValue,
  selectedTeacherIds,
  selectedStudentIds,
  enableStudentLimit,
  studentLimit,

  currentTeacherId,

  // Nav actions
  gotoTeacherSelectionAction,
  gotoStudentSelectionAction,

  // Remove individual users actions
  removeTeacherAction,
  removeStudentAction,

  // Limit Actions
  enableLimitAction,
  setLimitAction,

  // Form submission action
  createClassAction
}) {
  const toggleRender = featureIsEnabled('class-toggle-remove') ? !isOrbMode() : true;
  const CMS = cmsContent.addClassPanel || {};
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePopoutAction} />
            </div>
            <PanelHeading
              title={CMS.review_panel_text}
              subtitle={(CMS.class_review_subtitle_text || '').replace('{className}', classroomNameValue)}
            />
          </div>
        }
        footerContent={
          <PopoutNavFooter
            backAction={gotoStudentSelectionAction}
            nextAction={createClassAction}
            nextButtonText={CMS.add_class_text}
            nextButtonDisabled={
              classroomNameIsInvalid ||
              classroomNamePending ||
              (enableStudentLimit && studentLimit < selectedStudentIds.length)
            }
          />
        }
      >
        <div style={{ margin: '1rem' }}>
          <ClassroomNameInput />

          <div>
            <h3>{CMS.teachers_header_text}</h3>
            <SelectionList linkText={CMS.select_teachers_text} linkAction={gotoTeacherSelectionAction}>
              {selectedTeacherIds.length > 10 ? (
                <Lozenge
                  text={`${selectedTeacherIds.length}${' '}${CMS.teachers_text}`}
                  backgroundColor={colors.TEACHER}
                  buttonAction={gotoTeacherSelectionAction}
                  useArrowIcon
                />
              ) : (
                selectedTeacherIds.map(id => (
                  <ConnectedLozenge
                    key={id}
                    personId={id}
                    backgroundColor={colors.TEACHER}
                    buttonAction={id !== currentTeacherId ? () => removeTeacherAction(id) : null}
                  />
                ))
              )}
            </SelectionList>
          </div>

          <div>
            <h3>{CMS.students_header_text}</h3>
            <SelectionList linkText={CMS.select_students_text} linkAction={gotoStudentSelectionAction}>
              {selectedStudentIds.length > 10 ? (
                <Lozenge
                  text={`${selectedStudentIds.length}${' '}${CMS.students_text}`}
                  backgroundColor={colors.LEARNERS}
                  buttonAction={gotoStudentSelectionAction}
                  useArrowIcon
                />
              ) : (
                selectedStudentIds.map(id => (
                  <ConnectedLozenge
                    key={id}
                    personId={id}
                    backgroundColor={colors.LEARNERS}
                    buttonAction={() => removeStudentAction(id)}
                  />
                ))
              )}
            </SelectionList>
          </div>
          {toggleRender ? (
            <Validation
              isWarning={enableStudentLimit && studentLimit < selectedStudentIds.length}
              message={CMS.class_seat_limit_error_message}
              rightHandInput
              forId="numberOfSeats"
              noLabelAboveField
            >
              <ToggleWithNumberInput
                toggleId="numberOfSeatsToggle"
                toggleLabel={CMS.set_class_limit_label}
                toggleValue={enableStudentLimit}
                toggleOnChange={enableLimitAction}
                numberInputId="numberOfSeats"
                numberInputLabel={CMS.set_class_size_label}
                numberInputValue={studentLimit}
                numberInputOnChange={setLimitAction}
                numberInputMin={1}
              />
            </Validation>
          ) : null}
        </div>
      </ScrollContainer>
    </form>
  );
}

AddClassroomReview.propTypes = {
  closePopoutAction: PropTypes.func.isRequired,

  // Input values
  classroomNameValue: PropTypes.string.isRequired,
  classroomNameIsInvalid: PropTypes.bool.isRequired,
  classroomNamePending: PropTypes.bool.isRequired,
  selectedTeacherIds: PropTypes.arrayOf(PropTypes.string),
  selectedStudentIds: PropTypes.arrayOf(PropTypes.string),
  enableStudentLimit: PropTypes.bool.isRequired,
  studentLimit: PropTypes.number,

  // Current user shouldn't be de-selectable if TEACHER
  currentTeacherId: PropTypes.string,

  // Navigation actions
  gotoTeacherSelectionAction: PropTypes.func.isRequired,
  gotoStudentSelectionAction: PropTypes.func.isRequired,

  removeTeacherAction: PropTypes.func.isRequired,
  removeStudentAction: PropTypes.func.isRequired,
  enableLimitAction: PropTypes.func.isRequired,
  setLimitAction: PropTypes.func.isRequired,
  createClassAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    // Connect values
    classroomNameValue: state.classroomCreate.classroomNameValue,
    classroomNameIsInvalid: state.classroomCreate.classroomNameValidationIsInvalid,
    classroomNamePending: state.classroomCreate.classroomNameValidationPending,

    selectedTeacherIds: state.classroomCreate.selectedTeacherIds,
    selectedStudentIds: state.classroomCreate.selectedStudentIds,
    enableStudentLimit: state.classroomCreate.enableStudentLimit,
    studentLimit: state.classroomCreate.studentLimit,
    currentTeacherId: state.identity.role === 'TEACHER' ? state.identity.userId : null
  }),
  {
    // Navigation actions
    gotoTeacherSelectionAction: gotoTeacherSelection,
    gotoStudentSelectionAction: gotoStudentSelection,

    // Connect onChange actions
    removeTeacherAction: removeTeacher,
    removeStudentAction: removeStudent,
    enableLimitAction: setEnableStudentLimit,
    setLimitAction: setStudentLimit,
    createClassAction: createClass
  }
)(AddClassroomReview);
