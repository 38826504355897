import * as t from '../actionTypes';

export const gradebookModifyStudentFailure = error => ({
  type: t.GRADEBOOK_MODIFY_STUDENT_FAILURE,
  payload: error
});

export const gradebookModifyStudentSuccess = () => ({
  type: t.GRADEBOOK_MODIFY_STUDENT_SUCCESS
});
