import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import TextInput from '@oup/shared-front-end/src/components/TextInput';
import RadioButton from '@oup/shared-front-end/src/components/RadioButton';
import Checkbox from '@oup/shared-front-end/src/components/Checkbox';
import Button from '@oup/shared-front-end/src/components/Button/Button';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import styles from './SystemNotificationSettings.scss';
import {
  SYSTEM_NOTIFICATION_PLATFORMS_NEW,
  messageType,
  displayOnTypes,
  displayOnMapping
} from '../../globals/appConstants';
import formatMaintenanceMessage from './formatMaintenanceMessage';

const PlacementPreview = {
  [displayOnTypes.homepage]: 'notification-home.svg',
  [displayOnTypes.login]: 'notification-login.svg',
  [displayOnTypes.postLogin]: 'notification-post-login.svg'
};

function PlatformSettings({ initialState, content, updateSystemNotificationAction }) {
  const [activePreview, setActivePreview] = useState('DEFAULT');
  const [state, setState] = useState({
    messageType: null,
    displayOn: {
      [displayOnTypes.homepage]: false,
      [displayOnTypes.login]: false,
      [displayOnTypes.postLogin]: false
    },
    messageDetails: {
      maintenanceStartDate: undefined,
      maintenanceEndDate: undefined
    },
    ...initialState
  });
  const [openModal, setOpenModal] = useState(false);
  const prefix = state.platformCode;

  const handleStateUpdate = (key, value) =>
    setState(prev => ({
      ...prev,
      [key]: value
    }));

  const togglePlacementStateUpdate = key =>
    setState(prev => ({
      ...prev,
      displayOn: {
        ...prev.displayOn,
        [key]: !prev.displayOn[key]
      }
    }));

  const handleDateUpdate = ({ target }) =>
    setState(prev => ({
      ...prev,
      messageDetails: {
        ...prev.messageDetails,
        [target.name]: `${target.value}:00Z`
      }
    }));

  const toggleState = () => setState(prev => ({ ...prev, enabled: !prev.enabled }));
  const confirmAction = e => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleForm = () => {
    let platforms = [];

    if (state.platformCode === 'allPlatforms') {
      platforms = SYSTEM_NOTIFICATION_PLATFORMS_NEW.map(platformCode => ({
        ...state,
        platformCode
      }));
    } else {
      platforms = [state];
    }

    updateSystemNotificationAction({
      systemNotification: platforms
    });
  };

  const _onConfirmClose = () => {
    handleForm();
    setOpenModal(false);
  };

  const _onCancel = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (state.messageType === messageType.MAINTENANCE) return;

    setState(prev => ({
      ...prev,
      messageDetails: {
        maintenanceStartDate: undefined,
        maintenanceEndDate: undefined
      }
    }));
  }, [state.messageType]);

  const hasValidDates = state?.messageDetails?.maintenanceStartDate && state?.messageDetails?.maintenanceEndDate;
  const maintenanceMessage = toBeReplaced => {
    if (!hasValidDates) return content.label_pick_dates;
    return formatMaintenanceMessage(
      state.messageDetails?.maintenanceStartDate,
      state.messageDetails?.maintenanceEndDate,
      toBeReplaced
    );
  };

  const isMaintenance = state.messageType === messageType.MAINTENANCE;
  const hasDisplaySelection = Object.values(state?.displayOn).some(v => v);
  const isValidForm = isMaintenance
    ? state.messageType &&
      hasDisplaySelection &&
      state?.messageDetails.maintenanceStartDate &&
      state.messageDetails?.maintenanceEndDate
    : state.messageType && hasDisplaySelection;

  const message = {
    DEFAULT: {
      NOOP: content.label_no_selection
    },
    [displayOnTypes.homepage]: {
      [messageType.GENERIC]: content.home_generic_message,
      [messageType.MAINTENANCE]: maintenanceMessage(content.home_maintenance_message)
    },
    [displayOnTypes.login]: {
      [messageType.GENERIC]: content.login_generic_message,
      [messageType.MAINTENANCE]: content.login_generic_message
    },
    [displayOnTypes.postLogin]: {
      [messageType.GENERIC]: content.post_login_generic_message,
      [messageType.MAINTENANCE]: maintenanceMessage(content.post_login_maintenance_message)
    }
  }[activePreview][state?.messageType || 'NOOP'];

  const modalDetailsMessage = content.confirm_close_details.replace('{platformCode}', state.platformCode);

  return (
    <>
      <form onSubmit={confirmAction} className={classnames('flex flex-row', styles.form)}>
        <div className={classnames(styles['w-1/2'], styles.wrapper)}>
          <strong className="pad-bot2 block">{content.label_status}</strong>
          <ToggleSwitch
            id={`sytem_notification_${state.platformCode}`}
            name={state.platformCode}
            value={state.enabled}
            onChange={toggleState}
            label={
              state.enabled
                ? content.label_status_enabled.replace('{platformCode}', state.platformCode)
                : content.label_status_disabled.replace('{platformCode}', state.platformCode)
            }
          />
          <strong className="pad-bot2 pad-top2 block">{content.label_message_type}</strong>
          <div className={classnames(styles['gap-1'], 'flex flex-row')}>
            {Object.keys(messageType).map((type, i) => {
              const groupId = `${prefix}_${type}`;
              return (
                <RadioButton
                  key={i}
                  label={type}
                  id={groupId}
                  checked={state.messageType === type}
                  name={`${prefix}_messageType`}
                  value={type}
                  onChange={({ target }) => handleStateUpdate('messageType', target.value)}
                />
              );
            })}
          </div>
          {state?.messageType === messageType.MAINTENANCE && (
            <div className="pad-top2">
              <div className={classnames('flex flex-row', styles['gap-1'], styles.dateTimeWrapper)}>
                <TextInput
                  type="datetime-local"
                  id="startDateTime"
                  label={content.label_start_date}
                  max="2050-01-01"
                  value={state.messageDetails?.maintenanceStartDate?.replace(':00Z', '') || ''}
                  name="maintenanceStartDate"
                  onChange={handleDateUpdate}
                />
                <TextInput
                  type="datetime-local"
                  id="endDateTime"
                  label={content.label_end_date}
                  min={state.messageDetails?.maintenanceStartDate?.replace(':00Z', '')}
                  max="2050-01-01"
                  disabled={!state.messageDetails?.maintenanceStartDate}
                  name="maintenanceEndDate"
                  value={state.messageDetails?.maintenanceEndDate?.replace(':00Z', '') || ''}
                  onChange={handleDateUpdate}
                />
              </div>
            </div>
          )}

          <strong className="pad-top2 pad-bot2 block">{content.label_display_on}</strong>
          <div className={classnames(styles['gap-1'], 'flex flex-row')}>
            {Object.keys(displayOnTypes).map((type, i) => {
              const checked = state?.displayOn[type] === true;
              return (
                <div key={i} className="flex-1/3" onFocus={() => null} onMouseOver={() => setActivePreview(type)}>
                  <div className={classnames(styles.preview, { [styles.previewSelected]: checked })}>
                    <img src={`/static/images/edu/${PlacementPreview[type]}`} alt={type} />
                  </div>
                  <Checkbox
                    id={type}
                    label={displayOnMapping(content, type)}
                    checked={checked}
                    onChange={() => togglePlacementStateUpdate(type)}
                    className={styles.checkbox}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div
          className={classnames(
            styles['w-1/2'],
            styles.wrapper,
            styles.previewWrapper,
            'flex flex-column justify-content-between'
          )}
        >
          <strong className="pad-bot2 block">{content.label_notification_preview}</strong>
          <p className={styles.messagePreview}>{message}</p>
          <Button type="submit" text={content.button_save_notification} disabled={!isValidForm} />
        </div>
      </form>
      {openModal && (
        <ConfirmationModal
          title={content.confirm_close_title}
          body={modalDetailsMessage}
          positiveClickText={content.confirm_close_continue_button}
          negativeClickText={content.confirm_close_cancel_button}
          positiveClick={_onConfirmClose}
          negativeClick={_onCancel}
          customWrapperClass={styles.confirmationModalWrapper}
          customModalClass={styles.confirmationModal}
        />
      )}
    </>
  );
}

PlatformSettings.propTypes = {
  initialState: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  updateSystemNotificationAction: PropTypes.func.isRequired
};

export default PlatformSettings;
