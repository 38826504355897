import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { SIZES, validSizes } from '../Thumbnail/Thumbnail';
import OrgRepresentation from './OrgRepresentation';

/** This component consists of a persons initials in a thumbnail, their name and their email. The name can be a link */
function ConnectedOrgRepresentation({
  id,
  entityId,
  thumbnailSize,
  linkIdPrefix,
  linkTo,
  subtext,
  deletedStyle,
  // Redux connected props:
  name,
  orgUniqueId
}) {
  return (
    <OrgRepresentation
      id={id || `orgEntity-${entityId}`}
      name={name}
      subtext={subtext || `Organization ID: ${orgUniqueId || 'NA'}`}
      thumbnailSize={thumbnailSize || SIZES.TABLE}
      linkTo={linkTo}
      linkId={linkIdPrefix ? `${linkIdPrefix}-${entityId}` : null}
      deletedStyle={deletedStyle}
    />
  );
}

ConnectedOrgRepresentation.propTypes = {
  /** Id for this component */
  id: PropTypes.string,
  /** Id to look up in store  */
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.string]).isRequired, // eslint-disable-line react/no-unused-prop-types
  /** The size of the thumbnail. */
  thumbnailSize: PropTypes.oneOf(validSizes),
  /** Context prefix for link IDs */
  linkIdPrefix: PropTypes.string,
  /** Target of the link if the entity contains a link */
  linkTo: PropTypes.string,
  /** Second line text */
  subtext: PropTypes.string,
  /** This applies a "deleted" style to the element */
  deletedStyle: PropTypes.bool,

  // Redux connected props:
  /** Org name */
  name: PropTypes.string.isRequired,
  /** orgUniqueId will be shown when subtext is not specified */
  orgUniqueId: PropTypes.string
};

export default connect((state, { entityId }) => pick(state.organisations.data[entityId], ['name', 'orgUniqueId']))(
  ConnectedOrgRepresentation
);
