import * as t from '../actionTypes';

export const initialState = {
  errors: {},
  isCheckingUserNameTaken: false,
  isUserNameTaken: false,
  isGeneratingSignedUrl: false,
  isAddToClassView: false,
  isSubmitting: false,
  isSuccessful: false,
  isConfirmation: false,
  hasFailed: false,
  isDownloadingSuccess: false,
  isDownloadingFail: false,
  isDownloading: false,
  userDetails: [],
  size: 0
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.USER_ENROL_VALIDATE_INPUT_COMPLETED:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload
        }
      };
    case t.USER_ENROL_CHECKING_USERNAME:
      return {
        ...state,
        isCheckingUserNameTaken: true,
        isUserNameTaken: false
      };
    case t.USER_ENROL_CHECKING_USERNAME_COMPLETED:
      return {
        ...state,
        isCheckingUserNameTaken: false,
        isUserNameTaken: payload
      };
    case t.USER_ENROL_VALIDATE_INPUT_SUCCESS:
      return {
        ...state,
        isSubmitting: true
      };
    case t.USER_ENROL_SUCCESS:
      return {
        ...state,
        userId: payload,
        isSubmitting: false,
        isConfirmation: true
      };
    case t.USER_ENROL_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        isConfirmation: true,
        hasFailed: true
      };
    case t.USER_ENROL_RESET:
      return { ...initialState };
    case t.USER_ENROL_ADD_TO_CLASS_VIEW:
      return {
        ...state,
        isAddToClassView: true,
        isSubmitting: false
      };
    case t.USER_ENROL_ADD_TO_CLASS_VIEW_CONFIRMATION:
      return {
        ...state,
        isAddToClassView: false
      };
    case t.USER_ENROL_REQUEST:
      return {
        ...state,
        ...payload.user
      };
    case t.USER_ENROL_DOWNLOAD_SIGN_IN_CARD:
      return {
        ...state,
        isDownloading: true,
        isConfirmation: false
      };
    case t.USER_ENROL_DOWNLOAD_SIGN_IN_CARD_SUCCESS:
      return {
        ...state,
        size: payload.size,
        isDownloading: false,
        isDownloadingSuccess: true
      };
    case t.USER_ENROL_DOWNLOAD_SIGN_IN_CARD_SET_USER_DETAILS:
      return {
        ...state,
        userDetails: payload.user
      };
    case t.USER_ENROL_DOWNLOAD_SIGN_IN_CARD_FAIL:
      return {
        ...state,
        isDownloadingFail: true,
        isDownloading: false
      };
    default:
      return state;
  }
};
