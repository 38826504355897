import * as t from '../actionTypes';

export const getDeferredEmailsRequest = userRole => ({
  type: t.GET_DEFERRED_EMAILS_REQUEST,
  payload: userRole
});

export const getDeferredEmailsSuccess = deferredEmails => ({
  type: t.GET_DEFERRED_EMAILS_SUCCESS,
  payload: deferredEmails
});

export const getDeferredEmailsFailure = errors => ({
  type: t.GET_DEFERRED_EMAILS_FAILURE,
  payload: errors
});
