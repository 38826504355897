import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import updateProductLocksGenerator from './updateProductLocks';
import getProductLocks from './getProductLocks';

function* root() {
  yield takeLatest(t.GET_PRODUCT_LOCKS_REQUEST, ({ payload }) => getProductLocks(payload));
  yield takeLatest(t.UPDATE_PRODUCT_LOCKS_REQUEST, ({ payload, params }) =>
    updateProductLocksGenerator(payload, params)
  );
}

export default root;
