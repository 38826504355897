import React from 'react';
import PropTypes from 'prop-types';
import styles from '../css/ProductCreationPage.scss';
import withLocalizedContent from '../../../../../language/withLocalizedContent';
import Button, { buttonTypes } from '../../../../../components/Button/Button';
import PopupOverlay from '../../../../../components/PopupOverlay/PopupOverlay';

function AlertModal({ message, isAlertOpen, setIsAlertOpen, localizedContent: { productCreate: content } }) {
  return (
    <PopupOverlay
      id="alertOverlay"
      ariaLabel={` `}
      visible={isAlertOpen}
      panelSize="fullWidth"
      customClassName={styles.popUpAlert}
      onTogglePopup={() => {}}
    >
      <div className={styles.popUpAlertInner}>
        {message}
        <Button
          customClassName={styles.cancelPlatformButton}
          onClick={() => setIsAlertOpen(false)}
          type={buttonTypes.PRIMARY_THIN}
          text={content.cancel}
        />
      </div>
    </PopupOverlay>
  );
}

AlertModal.propTypes = {
  message: PropTypes.string,
  setIsAlertOpen: PropTypes.func,
  isAlertOpen: PropTypes.bool,
  localizedContent: PropTypes.object
};
export default withLocalizedContent('productCreate')(AlertModal);
