import * as t from '../../actionTypes';

const requestStatusOptions = { loading: true, success: false, error: false };
const successStatusOptions = { loading: false, success: true, error: false };
const failureStatusOptions = { loading: false, success: false, error: true };

const initialState = {
  classTests: {},
  testIds: []
};

function classAssessments(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_CLASS_ASSESSMENTS:
      return {
        ...initialState,
        ...requestStatusOptions
      };
    case t.GET_CLASS_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        classTests: payload.classTests,
        testIds: payload.testIds,
        ...successStatusOptions
      };
    case t.GET_CLASS_ASSESSMENTS_FAILURE:
      return { ...state, ...failureStatusOptions };
    default:
      return state;
  }
}

export default classAssessments;
