import { takeLatest } from 'redux-saga/effects';
import { GET_COURSE_PREVIEW_REQUEST, LOAD_COURSE_CONTENT_REQUEST } from '../../../../actionTypes';
import getCoursePreview from './getCoursePreview';
import loadCourseContent from './loadCourseContent';

function* root() {
  yield takeLatest(GET_COURSE_PREVIEW_REQUEST, ({ payload }) => getCoursePreview(payload));
  yield takeLatest(LOAD_COURSE_CONTENT_REQUEST, ({ payload }) => loadCourseContent(payload));
}

export default root;
