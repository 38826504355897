import React from 'react';
import PropTypes from 'prop-types';

import AnimateHeight from 'react-animate-height';
import styles from './ExpandableList.scss';

/** The component renders an expandable list */
function ExpandableList({ items, expanded = false, className = '', duration = 500 }) {
  return (
    <div className={className}>
      <AnimateHeight duration={duration} height={expanded ? 'auto' : 0}>
        <ul>
          {items.map((item, index) => (
            <li key={index} className={styles.listItem}>
              {item}
            </li>
          ))}
        </ul>
      </AnimateHeight>
    </div>
  );
}

ExpandableList.propTypes = {
  /** The items for the list */
  items: PropTypes.array.isRequired,
  /** Whether the component is in it's expanded state */
  expanded: PropTypes.bool,
  /** Extra class names for the component */
  className: PropTypes.string,
  /** Duration of the anumation in ms */
  duration: PropTypes.number
};

export default ExpandableList;
