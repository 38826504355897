/* eslint no-undef: "error" */

import { put } from 'redux-saga/effects';
import { parse as parseQueryString } from 'query-string';
import { AUTH0 } from '@oup/shared-node-browser/constants';
import moment from 'moment';
import getEnvironmentSettingsApi from '../../apiCalls/settings/getEnvironmentSettings.api.js';
import getSystemStatusApi from '../../apiCalls/settings/getSystemStatus.api.js';
import { envReady, setSystemClockOffset, setSystemNotification } from '../../../reducers/app.reducer.js';
import {
  setEnvironmentSettings,
  getProviderByRegion,
  oidcEnabledByRegionForPlatformCode,
  getProviderByPlatformCode,
  getOidcClientIdByPlatformCode,
  isOIDCStandard,
  featureIsEnabled
} from '../../../../globals/envSettings.js';
import { setAuthSettings } from '../../../../globals/authSettings.js';
import { getCurrentPlatform } from '../../../../utils/platform';
import session from '../../../../utils/session';

const moduleName = '[loadEnvironment Saga]';

// If user is in the process of login or already loged in - find the identity provider
const getCurrentIdentityProvider = defaultIdpName => {
  let selectedIDP = '';
  const authString = window.location.search.split('?')[2] || window.location.search;
  const parsedQuery = parseQueryString(authString);
  const { jwt, code } = parsedQuery;

  // check if we receive either jwt or code in query param

  if (jwt || code) {
    selectedIDP = jwt ? defaultIdpName : AUTH0;
  } else if (featureIsEnabled('single-client-session-store')) {
    const { saml, oidc } = session.get();

    if (saml.sessionToken || oidc.accessToken) {
      selectedIDP = saml.sessionToken ? defaultIdpName : AUTH0;
    }
  } else {
    const { sessionToken, oidcAccessToken } = session.get();

    if (sessionToken || oidcAccessToken) {
      selectedIDP = sessionToken ? defaultIdpName : AUTH0;
    }
  }
  return selectedIDP;
};

export default function* loadEnvironment() {
  console.log(moduleName, 'Starting');

  const settingsResult = yield getEnvironmentSettingsApi();

  console.log(moduleName, 'Environment settings result :', settingsResult);

  if (settingsResult && settingsResult.status === 'success') {
    const settings = settingsResult.data;
    const platformCode = getCurrentPlatform().toLowerCase();

    setEnvironmentSettings(settings);

    let identityProvider = getCurrentIdentityProvider(settings.sso.DefaultIdpName);

    if (!identityProvider) {
      const oidcEnabledByRegion = oidcEnabledByRegionForPlatformCode(platformCode);

      identityProvider = settings.sso.DefaultIdpName;

      if (featureIsEnabled('platformcode-oidc-idp') && !oidcEnabledByRegion) {
        identityProvider = getProviderByPlatformCode(platformCode) || settings.sso.DefaultIdpName;
      } else if (featureIsEnabled('oidc-idp')) {
        identityProvider = getProviderByRegion(
          settings.regions,
          getProviderByPlatformCode(platformCode) || settings.sso.DefaultIdpName
        );
      }
    }

    const selectedIDP = settings.idp[identityProvider];
    const authSettings = {
      idpUrl: selectedIDP.idpUrl,
      idpStandard: selectedIDP.standard,
      gatewayPath: selectedIDP.gatewayPath,
      cognitoPoolId: settings.sso.cognitoPoolId,
      cognitoUrl: settings.sso.cognitoUrl,
      getCredentialsUrl: settings.sso.credentialsUrl,
      rightSuiteLogoutUrl: settings.sso.logoutUrl,
      cesApiKey: settings.ces.apiKey,
      gwId: settings.sso.gwId,
      gwEnv: settings.sso.gwEnv,
      oidcAuthorizeUrl: '',
      oidcTokenUrl: '',
      oidcLogoutUrl: '',
      oidcClientId: '',
      oidcIdleSessionTimeout: '',
      oidcIdleSessionPreCallValue: ''
    };

    if (isOIDCStandard(selectedIDP.standard)) {
      Object.assign(authSettings, {
        oidcAuthorizeUrl: `${selectedIDP.idpUrl}/authorize`,
        oidcTokenUrl: `${selectedIDP.idpUrl}/oauth/token`,
        oidcLogoutUrl: `${selectedIDP.idpUrl}/v2/logout`,
        oidcClientId: featureIsEnabled('platformcode-oidc-idp')
          ? getOidcClientIdByPlatformCode(platformCode)
          : selectedIDP.clientId,
        oidcIdleSessionTimeout: selectedIDP.idleSessionTimeout,
        oidcIdleSessionPreCallValue: selectedIDP.idleSessionTimeoutPreCall
      });
    }

    setAuthSettings(authSettings);

    yield put(envReady(settings));
    // injectGoogleAnalytics(); No longer used, GA added back to index.html due to clashes with NR and hashing issues
  } else {
    const envSettingsFailureWarningMessage = `Env Settings could not be loaded: ${JSON.stringify(settingsResult)}`;

    throw new Error(envSettingsFailureWarningMessage);
  }

  // TODO: move this call to a separate saga when we add more details to response.
  const systemStatus = yield getSystemStatusApi();
  if (systemStatus && systemStatus.status === 'success') {
    const serverDate = systemStatus.data?.date;
    const systemNotification = systemStatus.data?.systemNotification;

    if (systemNotification && Array.isArray(systemNotification)) {
      const platformNotifications = featureIsEnabled('granular-platform-system-notifications')
        ? systemNotification
        : systemNotification.map(platform => ({ platform, showNotification: true }));
      yield put(setSystemNotification(platformNotifications));
    }
    if (serverDate) {
      const serverTime = moment(serverDate).valueOf();
      const timeNow = moment.utc().valueOf();
      const systemClockOffset = serverTime - timeNow;

      localStorage.setItem('systemClockOffset', systemClockOffset);
      yield put(setSystemClockOffset(systemClockOffset));
    }
  } else {
    console.warn(`Failed to fetch date from server, api calls may fail if your system clock is skewed`);
  }

  console.log(moduleName, 'Complete');
}
