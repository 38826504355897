import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import RadioInput from '../RadioInput/RadioInput';
import styles from './RadioButton.scss';

export const RadioAppearence = {
  DEFAULT: Symbol('default'),
  ROUNDED: Symbol('rounded')
};

export const RadioAppearenceMap = {
  [RadioAppearence.DEFAULT]: styles.radioButton,
  [RadioAppearence.ROUNDED]: styles['radioButton-rounded']
};

function RadioButton({ appearence = RadioAppearence.DEFAULT, ...props }) {
  return (
    <div
      className={classnames(RadioAppearenceMap[appearence], {
        [styles['radioButton-checked']]: props.checked
      })}
      style={{
        borderColor: props.checked ? props.color : null,
        backgroundColor: props.checked ? props.color : null
      }}
    >
      <RadioInput {...props} />
    </div>
  );
}

RadioButton.propTypes = {
  ...RadioInput.propTypes,
  appearence: PropTypes.oneOf(Object.values(RadioAppearence))
};

export default RadioButton;
