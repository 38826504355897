import dot from 'dot-object';
import PropTypes from 'prop-types';
import React from 'react';
import { subscriptionTypes } from '@oup/shared-node-browser/subscriptions';
import { PLATFORMS } from '@oup/shared-node-browser/constants';
import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../Button/Button';
import Card from '../Card/Card';
import ControlledForm from '../ControlledForm/ControlledForm';
import DateField from '../DateField/DateField';
import Dropdown from '../Dropdown/Dropdown';
import PageHeading from '../PageHeading/PageHeading';
import TextInput from '../TextInput/TextInput';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import styles from './CreateOrgSubscriptionForm.scss';

function addYears(date, years) {
  date.setFullYear(date.getFullYear() + years);
  return date;
}

function formatDate(date) {
  return date.toISOString().split('T')[0];
}

function CreateOrgSubscriptionForm({
  localizedContent,
  orgId = '',
  orgName = '',
  subscriptionType = '',
  products = [],
  orgProducts = [],
  productId = '',
  startDate = '',
  endDate = '',
  errors = {},
  onChangeHandler = () => {},
  onBlurHandler = () => {},
  onSubmit = () => {}
}) {
  const errorValues = Object.values(dot.dot(errors));
  const isSubmitDisabled =
    !errorValues.length || errorValues.some(Boolean) || ![orgId, productId, startDate, endDate].every(Boolean);

  const subscriptionTypesDropdown = Object.values(subscriptionTypes).map(value => ({
    value,
    text: localizedContent[value],
    disabled: orgId && subscriptionType !== value,
    selected: subscriptionType === value
  }));

  const productsDropdown = [
    { value: '', text: localizedContent.product_default_option },
    ...products.map(({ productId: value, productName: text }) => {
      const productAlreadyAdded = orgProducts.find(id => id === value);
      return {
        value,
        text: `${text} ${productAlreadyAdded ? ` (${localizedContent.product_added})` : ''}`,
        disabled: productAlreadyAdded
      };
    })
  ];

  const onChangeStartDateHandler = value => {
    onChangeHandler('startDate')(value);

    if (subscriptionType === PLATFORMS.OIC.toLowerCase()) {
      const defaultEndDateExtension = 2;
      const startDateValue = new Date(value);

      let newEndDate = addYears(startDateValue, defaultEndDateExtension);

      newEndDate = formatDate(newEndDate);
      onChangeHandler('endDate')(newEndDate);
    }
  };

  let errorMessage = '';
  if (errors.orgId) {
    errorMessage = localizedContent.org_id_error_message;
  }
  if (errors.orgNotFound) {
    errorMessage = localizedContent.org_not_found_error_message;
  }
  if (errors.isBlocked) {
    errorMessage = localizedContent.org_is_blocked;
  }

  return (
    <ControlledForm>
      <div className="grid pad-top4 pad-bot4">
        <div className="row gin-bot3">
          <div className="col sm10 pad-bot2 gin-bot4">
            <PageHeading title={localizedContent.page_title} subtitle={localizedContent.page_subtitle} />
          </div>
          <div className="col md8">
            <div className="gin-bot3">
              <Card headingText={localizedContent.org_card_title} headingColor={colors.ORGANIZATION}>
                <ValidationStatus
                  isActive={errors.orgId || errors.orgNotFound || errors.isBlocked}
                  message={errorMessage}
                >
                  <TextInputWithButton
                    id="orgId"
                    name="orgId"
                    label={localizedContent.org_id_label}
                    value={orgId}
                    onChange={onChangeHandler('orgId')}
                    onBlur={onBlurHandler('orgId')}
                    buttonType="button"
                    buttonIcon={GLYPHS.ICON_TICK}
                    buttonAction={onBlurHandler('orgId')}
                  />
                </ValidationStatus>
                {orgName && <TextInput label={localizedContent.org_name_label} value={orgName} readOnly />}
              </Card>
            </div>
            <Card headingText={localizedContent.licence_card_title} headingColor={colors.PROFILE}>
              <ValidationStatus
                isActive={errors.subscriptionType}
                message={localizedContent.subscription_error_message}
              >
                <Dropdown
                  id="subscriptionType"
                  value={subscriptionType}
                  label={localizedContent.subscription_type_label}
                  options={subscriptionTypesDropdown}
                  onChange={onChangeHandler('subscriptionType')}
                  onBlur={onBlurHandler('subscriptionType')}
                />
              </ValidationStatus>
              <ValidationStatus isActive={errors.productId} message={localizedContent.product_error_message}>
                <Dropdown
                  id="productId"
                  value={productId}
                  label={localizedContent.product_label}
                  options={productsDropdown}
                  onChange={onChangeHandler('productId')}
                  onBlur={onBlurHandler('productId')}
                />
              </ValidationStatus>

              <ValidationStatus
                containerClassName={styles.startDateContainer}
                isActive={errors.startDate}
                message={localizedContent.start_date_error_message}
                onClick={onBlurHandler('startDate')}
              >
                <DateField
                  id="startDate"
                  value={startDate}
                  label={localizedContent.start_date_label}
                  placeholder={localizedContent.start_date_placeholder}
                  displayDateFormat="dd/MM/yyyy"
                  max="2050-01-01"
                  onChange={onChangeStartDateHandler}
                />
              </ValidationStatus>
              <ValidationStatus
                containerClassName={styles.endDateContainer}
                isActive={errors.endDate}
                message={localizedContent.end_date_error_message}
                onClick={onBlurHandler('endDate')}
              >
                <DateField
                  id="endDate"
                  name="endDate"
                  value={endDate}
                  label={localizedContent.end_date_label}
                  placeholder={localizedContent.end_date_placeholder}
                  displayDateFormat="dd/MM/yyyy"
                  onChange={onChangeHandler('endDate')}
                  max="2050-01-01"
                />
              </ValidationStatus>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span className="gin-right2">
              <Button
                type={buttonTypes.PRIMARY}
                text={localizedContent.form_submit_button}
                onClick={onSubmit}
                disabled={isSubmitDisabled}
              />
            </span>
          </div>
        </div>
      </div>
    </ControlledForm>
  );
}

CreateOrgSubscriptionForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string,
  orgName: PropTypes.string,
  products: PropTypes.array,
  orgProducts: PropTypes.array,
  productId: PropTypes.string,
  subscriptionType: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  errors: PropTypes.object,
  onChangeHandler: PropTypes.func,
  onBlurHandler: PropTypes.func,
  onSubmit: PropTypes.func
};

export default withLocalizedContent('createOrgSubscriptionForm')(CreateOrgSubscriptionForm);
