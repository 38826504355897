import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './PendingMarkIcon.scss';

function PendingMarkIcon({ customClassName }) {
  return (
    <div
      className={
        customClassName ? classnames(customClassName, styles.pendingIconContainer) : styles.pendingIconContainer
      }
    >
      <SVGIcon glyph={GLYPHS.ICON_OUP_ASSESSMENT_EDIT_PENCIL} />
    </div>
  );
}

PendingMarkIcon.propTypes = {
  customClassName: PropTypes.string
};
export default PendingMarkIcon;
