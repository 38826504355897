import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

import { isExternalLink } from '../../globals/validations';
import Color from '../../styles/constants/_colors.scss';
import prefixKeys from '../../utils/object/prefixKeys';
import SVGIcon, { GLYPHS, validGlyphs } from '../SVGIcon/SVGIcon';
import styles from './Button.scss';

export const buttonTypes = {
  PRIMARY: 'PRIMARY', // Blue style button
  PRIMARY_REVERSED: 'PRIMARY_REVERSED',
  PRIMARY_THIN: 'PRIMARY_THIN', // Thin blue style button
  SECONDARY: 'SECONDARY', // White style button
  SELECTED: 'SELECTED', // Green style button
  THERE_BE_DRAGONS: 'THERE_BE_DRAGONS', // Scary red variation of the primary button
  CANCEL: 'CANCEL', // Slightly less scary version that turns red on hover
  ACTION: 'ACTION', // RHS Panel style
  ACTION_BLOCK: 'ACTION_BLOCK', // RHS Panel style
  ACTION_NO_ICON: 'ACTION_NO_ICON', // RHS Panel style
  ACTION_BLOCK_NO_ICON: 'ACTION_BLOCK_NO_ACTION',
  ACCENT: 'ACCENT',
  BACK: 'BACK',
  BLUE: 'BLUE', // Blue button, no text, curved borders
  BREADCRUMB: 'BREADCRUMB',
  CLOSE: 'CLOSE', // Same as drilldown but with an X SVGIcon
  CLOSE_BOLD: 'CLOSE_BOLD', // Same as drilldown but with an X SVGIcon
  CLOSE_NO_BORDER: 'CLOSE_NO_BORDER',
  CLOSE_BOLD_HOVER: 'CLOSE_BOLD_HOVER',
  CLOSE_WHITE: 'CLOSE_WHITE',
  CLOSE_BORDER_GRAY: 'CLOSE_BORDER_GRAY',
  COPY_CLIPBOARD: 'COPY_CLIPBOARD',
  PENCIL_REVIEW: 'PENCIL_REVIEW',
  PENCIL_REVIEW_ACTIVE: 'PENCIL_REVIEW_ACTIVE',
  DOWNLOAD: 'DOWNLOAD',
  DOWNLOAD_RESOURCE: 'DOWNLOAD_RESOURCE',
  DROPDOWN: 'DROPDOWN',
  DROPDOWN_SHORT: 'DROPDOWN_SHORT',
  DROPDOWN_JUMBO: 'DROPDOWN_JUMBO',
  DROPDOWN_NO_BORDER: 'DROPDOWN_NO_BORDER',
  DRILLDOWN: 'DRILLDOWN',
  FULLWIDTH: 'FULLWIDTH',
  // Group buttons must be adjacent to eachother. Eg: In Paging component or NumberInput.
  GROUP: 'GROUP', // White with grey border. Only the first and last have rounded corners.
  GHOST: 'GHOST',
  GHOST_INVERTED: 'GHOST_INVERTED',
  GHOST_NEUTRAL: 'GHOST_NEUTRAL',
  GHOST_ACCENT: 'GHOST_ACCENT',
  INFO_NO_BORDER: 'INFO_NO_BORDER',
  LAUNCH: 'LAUNCH', // NGS Launch button with 5px rounded corners, top left corner should be 20px rounded
  NO_BORDER: 'NO_BORDER', // No border, should really be icon only
  ROUNDED: 'ROUNDED',
  ROUNDED_SMALL: 'ROUNDED_SMALL',
  WHITE: 'WHITE', // White button, no text, curved borders,
  RED: 'RED', // Red button text, No background
  ORB_LEARNING_OBJECT: 'ORB_LEARNING_OBJECT',
  OIC_GREEN: 'OIC_GREEN',
  OIC_BLUE: 'OIC_BLUE'
};

export const validButtonTypes = Object.values(buttonTypes);
export { GLYPHS };

/** The component renders buttons */
function Button({
  type = buttonTypes.PRIMARY,
  text = '',
  subText = '',
  title,
  glyph,
  glyphClassName = null,
  glyphFill = null,
  glyphRounded = false,
  glyphViewBox,
  onClick,
  to,
  target,
  rel,
  disabled,
  download = null,
  fullWidth,
  floater,
  id,
  hidePostIcon = false,
  iconOnly,
  active,
  loading = false,
  preventDefault = true,
  aria,
  disableExternalBehaviour,
  customClassName = null,
  preventNativeDisable = false,
  tabIndex = null,
  tabIndexPrevIconSvg,
  buttonIconText = false,
  ariaLabel = '',
  isTruncateName,
  onBlur,
  dataAttribute,
  dataTestId = '',
  hasGlyphContainer = false,
  showActionTextAndIcon = true,
  role = null
}) {
  // Detect external link:
  const isExternal = !disableExternalBehaviour && isExternalLink(to);

  // Is this a button or Link: (Use <Link> component for internal links)
  const Tag = (!to && 'button') || (isExternalLink(to) || download ? 'a' : Link);

  const handleClick =
    onClick &&
    (preventDefault
      ? event => {
          event.preventDefault();
          onClick(event);
        }
      : onClick);

  // External links must open in new tab:
  const targetVal = to ? (isExternal && (target || '_blank')) || target : null;
  const relVal = to ? rel : null;

  // If aria attributes were supplied, ensure they all have a prefix of "aria-":
  const ariaAttrs = prefixKeys(aria, 'aria-');

  const _renderPrevIcon = (
    <SVGIcon
      className={classnames(styles.leftSvg, {
        [glyphClassName]: glyphClassName
      })}
      glyph={glyph || GLYPHS.ICON_PLUS}
      glyphViewBox={glyphViewBox}
      fill={glyphFill}
      rounded={glyphRounded}
      tabIndex={tabIndexPrevIconSvg}
    />
  );
  const _renderPostIcon = buttonType => {
    if (loading) {
      return <SVGIcon className={styles.loading} glyph={GLYPHS.ICON_LOADING} fill={glyphFill} />;
    }
    if (hidePostIcon) {
      return null;
    }

    switch (buttonType) {
      case buttonTypes.ACTION:
      case buttonTypes.ACTION_BLOCK:
        return (
          showActionTextAndIcon && <SVGIcon className={styles.actionArrow} glyph={GLYPHS.ICON_RIGHT} fill={glyphFill} />
        );
      case buttonTypes.BACK:
        return <SVGIcon className={styles.back} glyph={GLYPHS.BACK} fill={glyphFill} />;
      case buttonTypes.DRILLDOWN:
        return <SVGIcon className={styles.drilldownArrow} glyph={GLYPHS.ICON_RIGHT_THICK} fill={glyphFill} />;
      case buttonTypes.CLOSE_BOLD:
      case buttonTypes.CLOSE_BOLD_HOVER:
      case buttonTypes.CLOSE_WHITE:
        return <SVGIcon className={styles.close} glyph={GLYPHS.ICON_CLOSE_BOLD} fill={glyphFill} />;
      case buttonTypes.CLOSE_BORDER_GRAY:
        return <SVGIcon className={styles.close} glyph={GLYPHS.ICON_CLOSE_BOLD} fill={glyphFill} />;
      case buttonTypes.CLOSE:
        return <SVGIcon className={styles.close} glyph={GLYPHS.ICON_CLOSE} fill={glyphFill} />;
      case buttonTypes.CLOSE_NO_BORDER:
        return <SVGIcon className={styles.close} glyph={GLYPHS.CLOSE_ALT} fill={glyphFill} />;
      case buttonTypes.COPY_CLIPBOARD:
        return <SVGIcon className={styles.copyClipboard} glyph={GLYPHS.ICON_COPY} fill={glyphFill} />;
      case buttonTypes.PENCIL_REVIEW:
        return <SVGIcon className={styles.pencilReview} glyph={GLYPHS.ICON_PENCIL_REVIEW} fill={glyphFill} />;
      case buttonTypes.PENCIL_REVIEW_ACTIVE:
        return <SVGIcon className={styles.pencilReview} glyph={GLYPHS.ICON_PENCIL_REVIEW} fill={glyphFill} />;
      case buttonTypes.DROPDOWN:
      case buttonTypes.DROPDOWN_SHORT:
      case buttonTypes.DROPDOWN_JUMBO:
        return <SVGIcon className={styles.dropdownArrow} glyph={GLYPHS.CHEVRON_DOWN_THICK} fill={glyphFill} />;
      case buttonTypes.DOWNLOAD:
        return <SVGIcon className={styles.actionArrow} glyph={GLYPHS.ICON_DOWNLOAD} fill={glyphFill} />;
      case buttonTypes.ROUNDED_SMALL:
        return <SVGIcon className={styles.actionArrow} glyph={GLYPHS.ICON_ARROW_BLUE} fill={glyphFill} />;
      default:
        if (isExternal)
          return <SVGIcon className={styles.dropdownArrow} glyph={GLYPHS.ICON_EXTERNAL} fill={glyphFill} />;
        return null;
    }
  };

  return (
    <Tag
      onClick={handleClick}
      onBlur={onBlur}
      id={id}
      to={Tag !== 'a' ? to : null}
      href={Tag === 'a' ? to : null}
      target={targetVal}
      rel={relVal}
      disabled={preventNativeDisable ? null : disabled}
      download={download}
      className={classnames(styles.button, styles[`type-${type}`], {
        [styles.fullWidth]: fullWidth,
        [styles.iconOnly]: iconOnly,
        [styles.active]: active,
        [styles.disabled]: disabled,
        [styles.floater]: floater,
        [styles.external]: isExternal,
        [styles.hasGlyphContainer]: hasGlyphContainer,
        [customClassName]: customClassName
      })}
      type={to ? undefined : 'button'}
      tabIndex={tabIndex}
      data-tooltip={title}
      {...ariaAttrs}
      aria-label={!title ? `${text} ${ariaLabel}` : `${title}, ${ariaLabel}`}
      data-filename={dataAttribute}
      data-testid={dataTestId}
      role={role}
    >
      {/* Icon & text: */}
      {!hasGlyphContainer && (glyph || floater) && _renderPrevIcon}

      {/* Container & Icon: */}
      {hasGlyphContainer && glyph && <div className={styles.glyphContainer}>{_renderPrevIcon}</div>}

      {buttonIconText && <span className={styles.buttonIconText}>{buttonIconText}</span>}
      <span
        className={classnames({
          'a11y-hide': iconOnly,
          [styles.truncName]: isTruncateName
        })}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
      >
        {subText ? <small>{subText}</small> : null}
      </span>

      {_renderPostIcon(type)}
    </Tag>
  );
}

Button.propTypes = {
  /** The type of the button. */
  type: PropTypes.oneOf(Object.keys(buttonTypes)),
  /** The text within the button */
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** The optional tooltip */
  title: PropTypes.string,
  /** The icon of the button */
  glyph: PropTypes.oneOf(validGlyphs),
  glyphClassName: PropTypes.string,
  glyphFill: PropTypes.oneOf(Object.values(Color)),
  glyphRounded: PropTypes.bool,
  glyphViewBox: PropTypes.string,
  hidePostIcon: PropTypes.bool,
  /** The onClick function of the button */
  onClick: PropTypes.func,
  /** The onClick function will assume we wish to preventDefault() unless this is false */
  preventDefault: PropTypes.bool,
  /** If the Button is a Link disguised as a button, use this prop instead of the onClick */
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** If the 'to' prop is defined then optionally render link 'target' prop */
  target: PropTypes.string,
  /** If the 'to' prop is defined then optionally render link 'rel' prop */
  rel: PropTypes.string,
  /** This toggles if the button is disabled */
  disabled: PropTypes.bool,
  download: PropTypes.string,
  /** This toggles if the button is full width of its parent container */
  fullWidth: PropTypes.bool,
  /** When true, the button will change to a round Floating Action Button */
  floater: PropTypes.bool,
  /** The html ID of the element. */
  id: PropTypes.string,
  role: PropTypes.string,
  /** This hides the text accessibly to display icon only. */
  iconOnly: PropTypes.bool,
  /** Set this when button must appear to be in a selected state. (Applies to GROUP buttons only) */
  active: PropTypes.bool,
  loading: PropTypes.bool,
  /** map of aria attribute names and values, eg: aria={{ role: 'textbox', live: 'assertive' }} */
  aria: PropTypes.object,
  /** Disable the automatic external link behaviour */
  disableExternalBehaviour: PropTypes.bool,
  customClassName: PropTypes.string,
  preventNativeDisable: PropTypes.bool,
  tabIndex: PropTypes.number,
  buttonIconText: PropTypes.bool,
  ariaLabel: PropTypes.string,
  isTruncateName: PropTypes.bool,
  onBlur: PropTypes.func,
  dataAttribute: PropTypes.string,
  dataTestId: PropTypes.string,
  hasGlyphContainer: PropTypes.bool,
  tabIndexPrevIconSvg: PropTypes.number,
  showActionTextAndIcon: PropTypes.bool
};

export default Button;
