import * as t from '../actionTypes';

export const rvsUploadSubmit = (input = null) => ({
  type: t.RVS_UPLOAD_SUBMIT,
  payload: input
});

export const rvsUploadSuccess = () => ({
  type: t.RVS_UPLOAD_SUCCESS
});

export const rvsUploadError = () => ({
  type: t.RVS_UPLOAD_ERROR
});

export const rvsUploadInvalid = () => ({
  type: t.RVS_UPLOAD_INVALID
});

export const rvsUploadReset = () => ({
  type: t.RVS_UPLOAD_RESET
});

export const rvsUploadServiceUnavailable = () => ({
  type: t.RVS_UPLOAD_SERVICE_UNAVAILABLE
});
