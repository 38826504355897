import { put, take } from 'redux-saga/effects';
import {
  showsuccessMessage,
  PRODUCT_UPLOAD_REQUEST,
  showproductUploadError
} from '../../../../reducers/productUpload.reducer.js';
import productUploadRequest from './productUploadRequest.js';

export default function* productUploadData() {
  console.log('[productUploadLogs Saga] Beginning');

  while (true) {
    yield take(PRODUCT_UPLOAD_REQUEST);

    console.log('[productUploadLogs Saga] Start Request');
    const result = yield productUploadRequest();
    const status = result.status ? result.status.toLowerCase() : 'error';

    const failed = !!result.error || status !== 'success';
    const apiError = result.error || status !== 'success' ? result.message : null;

    if (!failed) {
      console.log('[productUploadLogs Saga] Request completed, showing results');
      yield put(showsuccessMessage(result.data));
    } else {
      console.log('[productUploadLogs Saga] Request completed, but failed, showing error');
      yield put(showproductUploadError(apiError));
    }
  }
}
