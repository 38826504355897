import * as t from '../../actionTypes';

const initialState = {
  items: [],
  submitting: false,
  success: false,
  error: false,
  loading: false
};

function ngsStudentHomework(state = initialState, { type, payload }) {
  switch (type) {
    case t.NGS_STUDENT_HOMEWORK_REQUEST:
      return {
        ...state,
        submitting: true,
        success: false,
        error: false,
        loading: true
      };
    case t.NGS_STUDENT_HOMEWORK_SUCCESS:
      return {
        ...state,
        items: payload,
        submitting: false,
        success: true,
        error: false,
        loading: false
      };
    case t.NGS_STUDENT_HOMEWORK_FAILURE:
      return {
        ...state,
        submitting: false,
        error: false,
        loading: false,
        success: false
      };
    default:
      return state;
  }
}

export default ngsStudentHomework;
