import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import styles from './ContactUsDetails.scss';
import { isHubMode } from '../../utils/platform.js';
import Button, { buttonTypes } from '../Button/Button';

const addressElem = [
  'oup',
  'division',
  'sales_country',
  'address_line_1',
  'address_line_2',
  'address_city',
  'county',
  'address_postcode',
  'address_region',
  'address_country'
];

function ContactUsOtherWays({
  glossary,
  hideAddress = false,
  showChat = false,
  chatCallback = false,
  showChatText = false
}) {
  const defaultChat = () => {};
  const chatFunction = () => chatCallback() || defaultChat;
  // If the chat button is required the text is also required but the chat text
  // can be required sometimes without the button.
  const displayChatText = showChat || showChatText;

  return (
    <div className={styles.contentContainer}>
      <Helmet title={glossary.contact_us_information_head} />
      <div className={classnames(styles.emailBox, 'gin-top2')}>
        <em>{isHubMode() ? glossary.contact_information_hub_email : glossary.contact_information_email}</em>
      </div>
      {glossary.contact_information_intro_text && (
        <div className="gin-top2">
          <p className="sub-text">{glossary.contact_information_intro_text}</p>
        </div>
      )}
      <div className="gin-top2">
        <h3>{glossary.contact_information_telephone_text}</h3>
        <p>{glossary.contact_information_telephone_number}</p>
      </div>
      <p className="sub-text gin-top2">
        {isHubMode()
          ? glossary.contact_information_hub_availability_text
          : glossary.contact_information_availability_text}
      </p>
      <div className="gin-top2">
        <h3>
          {glossary.contact_information_online_chat !== '' ? glossary.contact_information_online_chat_text : null}
        </h3>
        <p>{glossary.contact_information_online_chat}</p>
      </div>
      {!hideAddress ? (
        <div className="gin-top2">
          <h3>{glossary.address_line_1 !== '' ? glossary.contact_information_address_text : null}</h3>
          <address className={styles.address}>
            {addressElem.map(elem => (glossary[elem] ? <div key={elem}>{glossary[elem]}</div> : null))}
          </address>
        </div>
      ) : null}
      {isHubMode() && (
        <div className="gin-top2">
          <h3>{glossary.contact_information_live_chat_title}</h3>
          <p>{glossary.contact_information_live_chat_info}</p>
        </div>
      )}

      <div className="gin-top2">
        {displayChatText && (
          <>
            <h3>{glossary.contact_information_live_chat_title}</h3>
            <p>{glossary.contact_information_live_chat_info}</p>
            <br />
          </>
        )}
        {showChat && (
          <Button
            type={buttonTypes.PRIMARY}
            text={glossary.contact_information_live_chat_button}
            onClick={chatFunction}
          />
        )}
      </div>
    </div>
  );
}

ContactUsOtherWays.propTypes = {
  glossary: PropTypes.object.isRequired,
  hideAddress: PropTypes.bool,
  showChat: PropTypes.bool,
  chatCallback: PropTypes.any,
  showChatText: PropTypes.bool
};

export default ContactUsOtherWays;
