import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
// Utils and globals
import { isLicenceExpiresEarly } from '@oup/shared-node-browser/licenceHelper.js';
import { getExpiresOnText, getLicencePeriodDetails } from '../../../utils/licences.js';
import { StatusType } from '../../StatusIcon/StatusIcon';
import withLocalizedContent from '../../../language/withLocalizedContent';
import cmsContent from '../../../utils/cmsContent.js';
// Components
import UserRepresentation from '../../UserRepresentation/UserRepresentation';
import PopoutPanelListHeading from '../../PopoutPanelListHeading/PopoutPanelListHeading';
import LicenceStatusIcon, { getStatusType } from '../../LicenceStatusIcon/LicenceStatusIcon';
// Styles
import styles from '../../UserLicenceListing/UserLicenceListing.scss';

function ProductFinderUserLicenceListing({
  heading,
  users,
  userLicences = {},
  localizedContent: { userLicenceListingComponent: content }
}) {
  return (
    <div>
      <div className="flex-row">
        <div className="flex-column flex-3/4">
          <PopoutPanelListHeading text={heading} />
        </div>

        <div className={`flex-column flex-1/4 align-items-center ${styles.borderBottom}`}>
          <h3>{content.licences_header_text} </h3>
        </div>
      </div>
      {Object.entries(users).map(([userId, user]) => {
        let userLicence = null;

        if (userLicences[userId]) {
          userLicence = userLicences[userId].status === 'ARCHIVED' ? null : userLicences[userId];
        }
        const isExistingLicence = userLicence ? userLicence.isAssigned && userLicence.status !== 'ARCHIVED' : false;
        let isExistingLicenceActive = false;
        if (isExistingLicence) {
          const licenceType = getStatusType(userLicence);
          isExistingLicenceActive = ![StatusType.PRODUCT_EXPIRED, StatusType.PRODUCT_NO_LICENCE].includes(licenceType);
        }
        const CMS = cmsContent.assignLearningMaterialSummary || {};
        let statusMessage = content.no_licence.replace('{username}', user.firstname);

        if (userLicence) {
          userLicence.licenceStartDate = userLicence.startDate;
          userLicence.licenceEndDate = userLicence.endDate;
          statusMessage = getLicencePeriodDetails(CMS, userLicence);

          statusMessage = isLicenceExpiresEarly(userLicence, true)
            ? `${statusMessage} (${getExpiresOnText(CMS, userLicence.licenceEndDate || '')})`
            : statusMessage;
        }
        if (isExistingLicenceActive) {
          statusMessage = content.user_name_already_using_licence.replace('{username}', user.firstname);
        }

        return (
          <div key={userId} className={styles.row}>
            <div className="gin2 flex-row align-items-center">
              <div className={classNames('flex-row', 'flex-3/4')}>
                <UserRepresentation
                  firstName={user.firstname}
                  lastName={user.lastname}
                  identifier={user.email}
                  role={user.roleName}
                />
              </div>

              <div id={`licenceStatus-${userId}`} className="flex-column flex-1/4 align-items-center">
                <div className={styles.licenceIconWrapper}>
                  <LicenceStatusIcon
                    statusMessage={statusMessage}
                    licenceDetails={userLicence}
                    toBeAssigned
                    productFinder
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

ProductFinderUserLicenceListing.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired,
  userLicences: PropTypes.object
};

export default withLocalizedContent('userLicenceListingComponent')(ProductFinderUserLicenceListing);
