import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import { toInitials } from '../../utils/string';
import ActionList, { Action } from '../ActionList/ActionList';
import EntityRepresentation from '../EntityRepresentation/EntityRepresentation';
import ContentWithSVGIcon from '../ContentWithSVGIcon/ContentWithSVGIcon';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { isHubMode } from '../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import styles from './UserEditSuccess.scss';

function UserEditSuccess({
  localizedContent: { userEditSuccessComponent: content },
  firstName,
  lastName,
  userName,
  subtitle = '',
  onBack,
  onDone,
  isSuccessful = false,
  initialConsentPanel
}) {
  const getHeadingContent = () => {
    if (isHubMode()) {
      return (
        <PopoutPanelIllustrationHeading
          title={content.title}
          subtitle={subtitle}
          illustrationSrc={HubIllustrationConstants.SUCCESS}
          illustrationAltText={HubIllustrationAltText.SUCCESS}
        />
      );
    }
    return !initialConsentPanel ? (
      <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.VALID} title={content.title} subtitle={subtitle} />
    ) : (
      <div />
    );
  };

  return (
    <ScrollContainer
      headerContent={getHeadingContent()}
      footerContent={
        <ActionList title={content.actions_title}>
          {isSuccessful ? null : <Action label={content.back_button} onClick={onBack} />}
          {onDone ? (
            <Action
              className={`${styles.button} ${styles.doneButton}`}
              label={content.done_button}
              dataTestId="USER_EDIT_SUCCESS_DONE"
              primary
              onClick={onDone}
            />
          ) : null}
        </ActionList>
      }
    >
      {firstName && lastName && userName && (
        <div className={styles.updateUserContainer}>
          <ContentWithSVGIcon glyph={GLYPHS.ICON_CHECK_CIRCLE}>
            <EntityRepresentation
              userUpdateSuccess
              id="MyProfileEditForm"
              thumbnailSize={thumbnailSizes.MEDIUM}
              thumbnailColor={colors.PROFILE}
              thumbnailText={toInitials(firstName, lastName)}
              line1Text={`${firstName} ${lastName}`}
              line1Color=""
              line2Text={userName}
              line3Text={content.updated_user_text}
            />
          </ContentWithSVGIcon>
        </div>
      )}
    </ScrollContainer>
  );
}

UserEditSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userName: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  isSuccessful: PropTypes.bool,
  initialConsentPanel: PropTypes.bool
};

export default withLocalizedContent('userEditSuccessComponent')(UserEditSuccess);
