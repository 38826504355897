import { takeLatest, put } from 'redux-saga/effects';
import { TOKEN_STATUS, INVITATION_STATUS, ARCHIVE_STATUS } from '../../../../globals/appConstants';

import actions from '../../../actions';

import { DECLINED_ORG_DETAILS_INIT } from '../../../actionTypes';

import getDeclineOrgInviteApi from '../../apiCalls/declineOrgInvite/getDeclineOrgInviteApi';

function* callDeclineOrgInviteDetailsApi({ token }) {
  console.log('[declineOrgInviteDetails Saga] Loading Details for token: ', token);

  const apiResults = yield getDeclineOrgInviteApi(token);
  // yield new Promise(resolve => setTimeout(resolve, 2000));

  if (
    apiResults.error ||
    apiResults.status !== 'success' ||
    !(
      apiResults.data.invitationArchiveStatus === ARCHIVE_STATUS.ACTIVE &&
      apiResults.data.tokenStatus === TOKEN_STATUS.ACTIVE &&
      apiResults.data.invitationStatus === INVITATION_STATUS.PENDING
    )
  ) {
    console.log('[declineOrgInviteDetails Saga] Error loading details', apiResults);
    yield put(actions.initDeclineOrgInvitePageError(apiResults.data || {}));
  } else {
    console.log('[declineOrgInviteDetails Saga] Loaded details', apiResults);
    yield put(actions.initDeclineOrgInvitePageSuccess({ ...apiResults.data }));
  }
}

export default function* watchActionDeclineOrgInviteDetails() {
  console.log('[declineOrgInviteDetails Saga] Registering action listeners');
  yield takeLatest(DECLINED_ORG_DETAILS_INIT, action => callDeclineOrgInviteDetailsApi(action));
}
