import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import PanelHeading from '../PanelHeading/PanelHeading';
import style from './SignInCardDownloadForm.scss';
import Dropdown from '../Dropdown/Dropdown';
import InfoNotice from '../InfoNotice/InfoNotice';
import SampleOrgUniqueId from '../SampleOrgUniqueId/SampleOrgUniqueId';
import Button, { buttonTypes } from '../Button/Button';

export default class SignInCardDownloadForm extends Component {
  constructor() {
    super();
    this.state = {
      emailOptionOpen: false
    };
  }

  _handleEmailOption = () => {
    const { emailOptionOpen } = this.state;
    this.setState({ emailOptionOpen: !emailOptionOpen });
  };

  _renderEmailForm() {
    const { emailSubmitStatus, authUserEmail, onSendEmail, localizedContent } = this.props;

    switch (emailSubmitStatus) {
      case 'SENDING':
        return 'Sending...';
      case 'SENT':
        return (
          <div className={classnames('gin-top2', style.center)}>
            {localizedContent.email_sent_notice}:<div className={style.emailBox}>{authUserEmail}</div>
          </div>
        );
      case 'FAILED':
        return 'Failed to send';
      default:
        return (
          <div className={classnames(style.center, 'gin-top2')}>
            {`${localizedContent.email_send_to}: ${authUserEmail}`}
            <div className="gin-top2">
              <span className="pad-right1 pad-left1">
                <Button id="emailPDFYes" type={buttonTypes.PRIMARY} text="Yes" onClick={onSendEmail} />
              </span>
              <span className="pad-right1 pad-left1">
                <Button
                  id="emailPDFNo"
                  type={buttonTypes.SECONDARY}
                  text="No"
                  onClick={() => this._handleEmailOption()}
                />
              </span>
            </div>
          </div>
        );
    }
  }

  render() {
    const {
      firstName,
      lastName,
      templates,
      onSelectedTemplateChange,
      onNext,
      localizedContent,
      selectedTemplate
    } = this.props;
    const { emailOptionOpen } = this.state;

    const template = selectedTemplate || Object.keys(templates)[0];

    return (
      <ScrollContainer
        headerContent={
          <div>
            <PanelHeading title={localizedContent.title} subtitle={`Sign in card for: ${firstName} ${lastName}`} />
          </div>
        }
        footerContent={
          <PopoutActionFooter primaryButtonText={localizedContent.done_button} primaryButtonAction={onNext} />
        }
      >
        <div className="pad2">
          <div className="gin-bot2">
            <SampleOrgUniqueId />
            <h2 className={style.center}>{localizedContent.sample_sign_in_card_label}</h2>
          </div>
          <InfoNotice leadNotice={localizedContent.lead_notice} notice={localizedContent.notice} />
          <div className="gin-bot2">
            <Dropdown
              id="signincard"
              label={`${localizedContent.download_template_label}:`}
              options={Object.keys(templates).map(key => ({ value: key, text: key }))}
              value={template}
              onChange={onSelectedTemplateChange}
            />
          </div>
          <p className="gin-bot2">{localizedContent.template_type_label}</p>
          <span className="pad-right2">
            <Button
              id="downloadPDF"
              type={buttonTypes.PRIMARY}
              text={localizedContent.download_pdf_button}
              to={templates[template]}
            />
          </span>
          <span className="pad-right2">
            <Button
              id="emailPDF"
              type={buttonTypes.SECONDARY}
              text={localizedContent.email_button}
              onClick={() => this._handleEmailOption()}
            />
            {emailOptionOpen ? this._renderEmailForm() : null}
          </span>
        </div>
      </ScrollContainer>
    );
  }
}

SignInCardDownloadForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  templates: PropTypes.object.isRequired,
  selectedTemplate: PropTypes.string,
  onSelectedTemplateChange: PropTypes.func,
  authUserEmail: PropTypes.string.isRequired,
  onSendEmail: PropTypes.func,
  onNext: PropTypes.func,
  emailSubmitStatus: PropTypes.oneOf(['SENDING', 'SENT', 'FAILED']),
  localizedContent: PropTypes.object.isRequired
};
