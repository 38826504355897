import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import createProductApi from '../../../apiCalls/createProductApi';

function* createProduct(data = false) {
  if (data && typeof data !== 'object') {
    throw new Error('createProduct parameter must be an Object');
  }
  let body = {};

  if (data?.payload && Object.keys(data.payload).length) {
    body = {
      ...data.payload,
      license_type: 2,
      allowed_usage: 1,
      user_concurrency: 1,
      total_concurrency: 1,
      timeperiod: 1,
      unittype: 1,
      beginon: 1,
      landing_page: 'https://www.google.com',
      sla: 'Z_something',
      home_page: 'https://www.google.com'
    };

    let apiResults = {};

    apiResults = yield createProductApi(body);
    if (apiResults.error || apiResults.status !== 'success') {
      yield put(actions.createProductFailure({ error: apiResults.message }));
    } else {
      yield put(actions.createProductSuccess({ success: apiResults.data }));
    }
  }
}

export default createProduct;
