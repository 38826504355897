import PropTypes from 'prop-types';
import React from 'react';
import ContentWithSVGIcon from '../../ContentWithSVGIcon/ContentWithSVGIcon';
import MaterialRepresentation from '../../MaterialRepresentation/MaterialRepresentation';
import PanelNavigationLink from '../../PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading from '../../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import { sanitizeUrl } from '../../../utils/url.js';
import { isHubMode } from '../../../utils/platform';
import { GLYPHS } from '../../SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../../Thumbnail/Thumbnail';
import { LICENSE_STATUS_COLOR } from '../../../globals/appConstants';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';

function ProductFinderConfirmation({
  userIdsInOrder,
  assignLicences,
  contextName,
  product,
  closePanel,
  onAddAnotherClick,
  onComplete,
  productFinderContent
}) {
  let successMessage = '';
  successMessage = productFinderContent?.assignment_success_text;
  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={productFinderContent.close_panel_text} action={closePanel} />
          </div>
          {isHubMode() ? (
            <PopoutPanelIllustrationHeading
              title={productFinderContent.learning_material_added}
              subtitle={successMessage.replace('{assigneeName}', contextName)}
              illustrationSrc={HubIllustrationConstants.SUCCESS}
              illustrationAltText={HubIllustrationAltText.SUCCESS}
            />
          ) : (
            <PopoutPanelIconHeading
              type="VALID"
              title={productFinderContent.learning_material_added}
              subtitle={successMessage.replace('{assigneeName}', contextName)}
            />
          )}
        </div>
      }
      footerContent={
        <PopoutActionFooter
          secondaryButtonAction={onAddAnotherClick}
          secondaryButtonText={productFinderContent.button_add_another_assignment}
          primaryButtonText={productFinderContent.complete_panel_text}
          primaryButtonAction={onComplete}
          primaryButtonTestHook="ASSIGN_LEARNING_MATERIAL_PANEL_CONFIRMATION_BUTTON"
        />
      }
    >
      <div>
        <div className="pad-top1 pad-bot1 pad-left2 pad-right2">
          <ContentWithSVGIcon glyph={GLYPHS.ICON_CHECK_CIRCLE}>
            <MaterialRepresentation
              thumbnailSize={thumbnailSizes.TABLE}
              imageSrc={sanitizeUrl(product.coverImage || product?.cover || '')}
              name={product.title}
              subtext={product.subtitle}
              statusColor={assignLicences ? LICENSE_STATUS_COLOR.SUCCESS : LICENSE_STATUS_COLOR.WARNING}
              statusText={
                assignLicences
                  ? `${userIdsInOrder.length} ${productFinderContent.review_library_assignment_count}`
                  : productFinderContent.review_required_access_code
              }
            />
          </ContentWithSVGIcon>
        </div>
      </div>
    </ScrollContainer>
  );
}

ProductFinderConfirmation.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  assignLicences: PropTypes.bool.isRequired,
  userIdsInOrder: PropTypes.array,
  contextName: PropTypes.string.isRequired,
  product: PropTypes.any,
  onComplete: PropTypes.func,
  onAddAnotherClick: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired
};

export default ProductFinderConfirmation;
