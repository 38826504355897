import { put } from 'redux-saga/effects';
import { searchUserEditSuccess, searchUserEditFailure } from '../../../../actions/userAdminDashboard.js';
import searchUserEditApi from '../../../apiCalls/adminDashboard/searchUserEdit.api.js';

function* searchUserEdit(payload) {
  const response = yield searchUserEditApi(payload);

  if (response.status === 'success') {
    yield put(searchUserEditSuccess());
  } else {
    yield put(searchUserEditFailure());
  }
}

export default searchUserEdit;
