import { put } from 'redux-saga/effects';
import { getIdpLoginUrl } from './getCredentials/redirectToIdP.saga.js';
import { appNotReady } from '../../../reducers/app.reducer';
import { getMicrosoftConnectionName } from '../../../../globals/envSettings';

export default function* checkMicrosoftRegistration() {
  // NOTE: authSettings was already loaded in preRender loadEnvironment before checkMicrosoftRegistration call

  console.log(
    '[checkMicrosoftRegistration] Starting,',
    'checking if error_description is present and has the right value to redirect the user to MS login'
  );

  if (
    window.location.search &&
    window.location.search.indexOf(`error_description=${encodeURIComponent('Error retrieving AzureAD profile')}`) > -1
  ) {
    // workaround to solve the Auth0 known issue about Microsoft registration
    // https://support.auth0.com/tickets/00498298

    try {
      const url = yield getIdpLoginUrl(null, {
        withReturnTo: false,
        isSignUp: true,
        connection: getMicrosoftConnectionName()
      });
      window.location.href = url;
      yield put(appNotReady());
    } catch (error) {
      console.log('[checkMicrosoftRegistration] Error:', error);
    }
  }

  console.log('[checkMicrosoftRegistration] Complete');
}
