import { select, put } from 'redux-saga/effects';

import getProductLicencesApi from '../../../apiCalls/library/getProductLicences.api';

import { sortLicence } from '../../../../../../sharedNodeBrowser/licenceHelper.js';
import cmsContent from '../../../../../utils/cmsContent.js';
import { getLicencePeriodDetails, isConcurrentLicence } from '../../../../../utils/licences.js';
import {
  getProductFinderLicencesFailure,
  getProductFinderLicencesSuccess
} from '../../../../actions/productFinderActions';

import orderUserIds from './utils/orderUserIds.js';
import { LICENCE_CONSTANTS } from '../../../../../globals/appConstants.js';
import { PRODUCT_TARGET_USERTYPE } from '../../../../../../sharedNodeBrowser/constants';
import { featureIsEnabled } from '../../../../../globals/envSettings.js';

const ASSIGNMENT_ARCHIVED_STATUS = 'ARCHIVED';
const CMS = cmsContent.assignLearningMaterialSummary || {};

/*
function that determines whether or not licences are assignable for the given product
reasons vary for which licences couldn't be assigned:
the users might not be eligible ( students cannot be assigned teacher products )
there are not enough licences for both students and teachers ( so only students get priority in this scenario )
at the end this only represents the eligibility of assigning licences, not the actual intent
the "intent" is represented at a more global level, outside the licenceStructure, controlled by a single toggle
*/
const couldAssignLicences = ({
  productLicences,
  licencesTypes,
  userIdsToAssign,
  usersAssigned,
  productTargetType,
  usersIdsWithLicences,
  teacherIdList,
  studentIdList,
  userIdsToAssignToInOrder
}) => {
  const licencesAvailableCount = licencesTypes.reduce((total, currentValue) => total + currentValue.availableCount, 0);
  const enoughLicencesAvailable = userIdsToAssign.length + usersAssigned.length <= licencesAvailableCount;
  const teachersMightBeAssigned =
    !productTargetType && usersIdsWithLicences.length < teacherIdList.length + studentIdList.length;
  return (
    !!Object.keys(productLicences).length > 0 &&
    enoughLicencesAvailable &&
    (!!userIdsToAssignToInOrder.length || teachersMightBeAssigned)
  );
};

/*
  based on the user assignments and licences ( which could be expired),
  it determines which users already have valid licences
*/
const getUsersWithLicences = (licencesTypes, userIds, productId, selfRedeemedLicences = {}) => {
  const usersIdsWithLicences = [];
  const usersExistingLicenceDetails = {};

  licencesTypes.forEach(licence => {
    licence.assignments.forEach(assignment => {
      if (
        !usersIdsWithLicences.includes(assignment.userId) &&
        userIds.includes(assignment.userId) &&
        assignment.status !== ASSIGNMENT_ARCHIVED_STATUS
      ) {
        usersIdsWithLicences.push(assignment.userId);
      }
      if (
        (licence.licenceEndDate || licence.unitType || isConcurrentLicence(licence)) &&
        assignment.status !== ASSIGNMENT_ARCHIVED_STATUS
      ) {
        usersExistingLicenceDetails[assignment.userId] = {
          licenceType: licence.licenceType,
          beginOn: licence.beginOn,
          timePeriod: licence.timePeriod,
          unitType: licence.unitType,
          createdDate: licence.createdDate,
          startDate: licence.licenceStartDate,
          endDate: licence.licenceEndDate,
          status: assignment.status
        };
      }
    });
    licence.assignmentsFromDifferentActivationCode.forEach(assignment => {
      if (
        !usersIdsWithLicences.includes(assignment.userId) &&
        userIds.includes(assignment.userId) &&
        (assignment.status !== ASSIGNMENT_ARCHIVED_STATUS ||
          (assignment.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.CONCURRENT && !assignment.status)) &&
        (assignment.licenceEndDate ||
          assignment.unitType ||
          assignment.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.CONCURRENT)
      ) {
        usersIdsWithLicences.push(assignment.userId);
      }
      if (
        (assignment.licenceEndDate ||
          assignment.unitType ||
          assignment.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.CONCURRENT) &&
        (assignment.status !== ASSIGNMENT_ARCHIVED_STATUS ||
          (assignment.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.CONCURRENT && !assignment.status))
      ) {
        usersExistingLicenceDetails[assignment.userId] = {
          licenceType: assignment.licenceType,
          beginOn: assignment.beginOn,
          timePeriod: assignment.timePeriod,
          unitType: assignment.unitType,
          createdDate: assignment.createdDate,
          startDate: assignment.licenceStartDate,
          endDate: assignment.licenceEndDate,
          status: assignment.status
        };
      }
    });
  });

  // we need to check if any data came through for individual licences from getOrgLicences
  // otherwise individual licences will not be seen even if all teachers/students inside the class have licences - EPS-15672
  if (!licencesTypes.length) {
    Object.keys(selfRedeemedLicences).forEach(el => {
      if (selfRedeemedLicences[el].find(lic => lic.productId === productId)) {
        usersIdsWithLicences.push(el);
        const licence = selfRedeemedLicences[el].find(lic => lic.productId === productId);
        usersExistingLicenceDetails[licence.userId] = {
          licenceType: licence.licenceType,
          beginOn: licence.beginOn,
          timePeriod: licence.timePeriod,
          unitType: licence.unitType,
          createdDate: licence.createdDate,
          startDate: licence.licenceStartDate,
          endDate: licence.licenceEndDate,
          status: licence.status
        };
      }
    });
  }

  return { usersIdsWithLicences, usersExistingLicenceDetails };
};

// it filters from the productLicences the one that matches the productId
// it then constructs the licenceTypes based on the productLicence, sorts them based on the date they expire
// afterwards it calculates the number of still available licences, based on the total number and the number already assigned
const processLicencesTypes = (productLicences, productId) => {
  const licencesForProduct = Object.values(productLicences).filter(licence => licence.productId === productId);
  let licencesTypes = licencesForProduct.map(productLicence => ({
    id: productLicence.activationCode,
    ...productLicence
  }));
  licencesTypes = sortLicence(licencesTypes);

  // Count available assignments and remove any which have none left
  licencesTypes.forEach(licence => {
    const assignmentCount =
      licence.assignments.length === 0
        ? licence.assignments.length
        : licence.assignments.filter(assign => assign.status !== ASSIGNMENT_ARCHIVED_STATUS).length;
    const allowedUsage = licence.allowedUsages ?? 0;
    licence.availableCount = parseInt(allowedUsage, 10) - assignmentCount;
  });
  return licencesTypes;
};

// based on the productTargetType we calculate which users need to be assigned to
// if its a student material, only students get assigned
// if its a teacher material, only teachers get assigned
// by default ( if it doesn't have target_usertype) we assign to students only if it's a
// class assignment context and assignLicenceToTeacher is falsey ( either not specified, or specified to be false)
// or to all users ( if it's not a class assignment)
const getUsersToAssignTo = ({
  productTargetType,
  classId,
  assignLicenceToTeacher,
  studentIdList,
  teacherIdList,
  userIds
}) => {
  let allUserIdsToAssign = [];

  switch (productTargetType) {
    case PRODUCT_TARGET_USERTYPE.STUDENT:
      return studentIdList;
    case PRODUCT_TARGET_USERTYPE.TEACHER:
      return teacherIdList;
    default:
      allUserIdsToAssign = !!classId && !assignLicenceToTeacher ? studentIdList : userIds;
  }
  return allUserIdsToAssign;
};
/*
  generating the licence structure for a given product
  the licence structure represents an object containing all the relevant information about the licences
  a given array of users could use for the products selected.
  it contains things such as the licenceTypes that could be used for assigning ( with available count, expiry dates, type of licence)
  it also contains the users which have already a licence, the users that are in the process of getting assigned
  and the users that don't have the permission to be assigned.
  */
const getLicenceStructure = ({
  productId,
  products,
  classId,
  assignLicenceToTeacher,
  productLicences,
  userIds,
  teacherIdList,
  studentIdList,
  usersOrderedByName,
  productAvailableCount,
  selfRedeemedLicences
}) => {
  const product = products.filter(filteredProduct => filteredProduct.productid === productId)[0];
  const productTargetType = product.target_usertype;

  let licencesTypes = processLicencesTypes(productLicences, productId);
  const { usersIdsWithLicences, usersExistingLicenceDetails } = getUsersWithLicences(
    licencesTypes,
    userIds,
    productId,
    selfRedeemedLicences
  );

  const userIdsWithoutPermission = [];
  const allUserIdsToAssign = getUsersToAssignTo({
    productTargetType,
    classId,
    assignLicenceToTeacher,
    studentIdList,
    teacherIdList,
    userIds
  });

  const usersToBeAssigned = usersOrderedByName.filter(id => allUserIdsToAssign.indexOf(id) !== -1);
  const userIdsToAssignToInOrder = !usersToBeAssigned.length
    ? []
    : usersToBeAssigned.filter(id => !(usersIdsWithLicences.includes(id) || userIdsWithoutPermission.includes(id)));

  // condense licence types (eg when having two 1 YEAR licences with different allowed usages etc.)
  licencesTypes = licencesTypes.reduce((condensed, current) => {
    if (current.availableCount < 0) {
      return condensed;
    }
    const existingIndex = condensed.findIndex(
      type => getLicencePeriodDetails(CMS, type) === getLicencePeriodDetails(CMS, current)
    );
    if (existingIndex !== -1) {
      // Type already in the list so add to totals
      condensed[existingIndex].availableCount += current.availableCount;
      condensed[existingIndex].activationCodes.push(current.activationCode);
    } else {
      // Add new item to list
      condensed.push({
        licenceType: current.licenceType,
        beginOn: current.beginOn,
        timePeriod: current.timePeriod,
        unitType: current.unitType,
        availableCount: current.availableCount,
        activationCodes: [current.activationCode],
        createdDate: current.createdDate,
        licenceStartDate: current.licenceStartDate,
        licenceEndDate: current.licenceEndDate
      });
    }

    return condensed;
  }, []);

  let userIdsToAssign = [...userIdsToAssignToInOrder];
  const proposedAssignments = {};
  licencesTypes.forEach(licence => {
    licence.amountAssigning = Math.min(licence.availableCount, userIdsToAssign.length);
    if (licence.amountAssigning && userIdsToAssign.length) {
      // Take the users from the front that we can assign
      const usersForThisLicence = userIdsToAssign.slice(0, licence.amountAssigning);
      userIdsToAssign = userIdsToAssign.slice(licence.amountAssigning);

      // Store their proposed licence info
      usersForThisLicence.forEach(userId => {
        proposedAssignments[userId] = {
          licenceType: licence.licenceType,
          beginOn: licence.beginOn,
          timePeriod: licence.timePeriod,
          unitType: licence.unitType,
          createdDate: licence.createdDate,
          startDate: licence.licenceStartDate,
          endDate: licence.licenceEndDate
        };
      });
    }
  });

  const usersAssigned =
    userIdsToAssignToInOrder.length > productAvailableCount
      ? userIdsToAssignToInOrder
      : Object.keys(proposedAssignments);

  const canAssignLicences = couldAssignLicences({
    productLicences,
    licencesTypes,
    userIdsToAssign,
    usersAssigned,
    productTargetType,
    usersIdsWithLicences,
    assignLicenceToTeacher,
    teacherIdList,
    studentIdList,
    userIdsToAssignToInOrder
  });

  return {
    classId,
    assignLicencesForProduct: canAssignLicences,
    canAssignLicences,
    licencesAvailable: Object.keys(productLicences).length > 0,
    licencesTypes,
    userIdsInOrder: usersAssigned,
    proposedAssignments,
    usersIdsWithLicences,
    usersExistingLicenceDetails,
    usersWithoutAssignments: userIdsToAssign,
    userIdsWithoutPermission,
    teacherIds: teacherIdList,
    studentIds: studentIdList
  };
};

export default function* getProductFinderLicences(payload) {
  const {
    orgId,
    classId,
    product = {},
    selectedProducts = [],
    selectedUsers,
    assignLicenceToTeacher,
    productAvailableCount = 0
  } = payload;
  let productIds = [];
  let products = [];
  if (featureIsEnabled('product-finder-multi-select') && selectedProducts.length) {
    productIds = selectedProducts.map(prod => prod.productid);
    products = selectedProducts;
  } else {
    const { productid: productId } = product;
    productIds = [productId];
    products = [product];
  }

  const { studentIdList, teacherIdList } = selectedUsers;
  const userIds = [...studentIdList, ...teacherIdList];

  // getting all the licences that the given users have for the products
  let productLicences = yield getProductLicencesApi(orgId, {
    productId: [...productIds],
    userIds
  });

  // add also individual licences
  // and pass them to getLicenceStructure - EPS-15672
  const selfRedeemedLicences = productLicences?.data?.selfRedeemedLicences;

  if (productLicences.error || !productLicences.data) {
    yield put(getProductFinderLicencesFailure());
  } else {
    productLicences = productLicences.data.productLicences;

    const licenceStructure = {};
    const usersOrderedByName = yield select(state => orderUserIds(state, userIds));

    productIds.forEach(prodId => {
      licenceStructure[prodId] = getLicenceStructure({
        productId: prodId,
        products,
        classId,
        assignLicenceToTeacher,
        productLicences,
        userIds,
        teacherIdList,
        studentIdList,
        usersOrderedByName,
        productAvailableCount,
        selfRedeemedLicences
      });
    });

    yield put(getProductFinderLicencesSuccess(licenceStructure));
  }
}
