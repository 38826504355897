import PropTypes from 'prop-types';
import React from 'react';
import Button from '@oup/shared-front-end/src/components/Button';
import content from '../../utils/cmsContent';
import { INVITATION_STATUS, TOKEN_STATUS, ARCHIVE_STATUS } from '../../globals/appConstants';
import generateLoginUrl from '../../globals/generateLoginUrl';

import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import styles from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.scss';
import PanelHeading from '../../components/PanelHeading/PanelHeading';
import { isHubMode } from '../../utils/platform';
import PopoutPanelIllustrationHeading from '../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../globals/hubConstants';

function DeclineOrgInvitationConfirmation({
  requestFailed,
  tokenStatus,
  currentInvitationStatus,
  invitationArchiveStatus,
  responseDate,
  platformName,
  orgName,
  offeredRole
}) {
  let panelData = {
    type: types.VALID,
    title: content.invitationBanner.decline_invite_success_title,
    subtitle: content.invitationBanner.decline_invite_success_subtitle,
    second_subtitle: content.invitationBanner.decline_invite_success_second_subtitle,
    show_signin: false,
    illustrationSrc: HubIllustrationConstants.VALID,
    illustrationAltText: HubIllustrationAltText.VALID
  };
  if (
    requestFailed ||
    !(tokenStatus === TOKEN_STATUS.ACTIVE && currentInvitationStatus === INVITATION_STATUS.PENDING)
  ) {
    // Invitation update FAILED
    switch (true) {
      case tokenStatus === TOKEN_STATUS.ACTIVE && currentInvitationStatus === INVITATION_STATUS.ACCEPTED:
        panelData = {
          type: types.INFO,
          title: content.invitationBanner.decline_invite_already_accepted_error_title
            .replace('{orgName}', orgName)
            .replace('{joinDate}', responseDate),
          subtitle: content.invitationBanner.decline_invite_already_accepted_error_subtitle,
          second_subtitle: content.invitationBanner.decline_invite_already_accepted_error_second_subtitle,
          show_signin: true,
          illustrationSrc: HubIllustrationConstants.INFORMATION,
          illustrationAltText: HubIllustrationAltText.INFORMATION
        };
        break;

      case tokenStatus === TOKEN_STATUS.ACTIVE &&
        invitationArchiveStatus === ARCHIVE_STATUS.ARCHIVED &&
        currentInvitationStatus === INVITATION_STATUS.PENDING:
        panelData = {
          type: types.VALID,
          title: content.invitationBanner.decline_invite_success_title,
          subtitle: content.invitationBanner.decline_invite_success_subtitle,
          second_subtitle: content.invitationBanner.decline_invite_success_second_subtitle,
          show_signin: false,
          illustrationSrc: HubIllustrationConstants.VALID,
          illustrationAltText: HubIllustrationAltText.VALID
        };
        break;

      case tokenStatus === TOKEN_STATUS.ACTIVE && currentInvitationStatus === INVITATION_STATUS.REJECTED:
        panelData = {
          type: types.VALID,
          title: content.invitationBanner.decline_invite_success_title,
          subtitle: content.invitationBanner.decline_invite_success_subtitle,
          second_subtitle: content.invitationBanner.decline_invite_success_second_subtitle,
          show_signin: false,
          illustrationSrc: HubIllustrationConstants.VALID,
          illustrationAltText: HubIllustrationAltText.VALID
        };
        break;

      case tokenStatus === TOKEN_STATUS.ACTIVE && currentInvitationStatus === INVITATION_STATUS.CANCELLED:
        panelData = {
          type: types.VALID,
          title: content.invitationBanner.decline_invite_success_title,
          subtitle: content.invitationBanner.decline_invite_success_subtitle,
          second_subtitle: content.invitationBanner.decline_invite_success_second_subtitle,
          show_signin: false,
          illustrationSrc: HubIllustrationConstants.VALID,
          illustrationAltText: HubIllustrationAltText.VALID
        };
        break;

      case tokenStatus === TOKEN_STATUS.ACTIVE && currentInvitationStatus === INVITATION_STATUS.EXPIRED:
        panelData = {
          type: types.ERROR,
          title: content.invitationBanner.decline_invite_token_expired_title,
          subtitle: content.invitationBanner.decline_invite_token_expired_subtitle.replace('{orgName}', orgName),
          second_subtitle: '',
          show_signin: false,
          illustrationSrc: HubIllustrationConstants.ERROR,
          illustrationAltText: HubIllustrationAltText.ERROR
        };
        break;

      case tokenStatus === TOKEN_STATUS.INACTIVE:
        panelData = {
          type: types.ERROR,
          title: content.invitationBanner.decline_invite_token_expired_title,
          subtitle: content.invitationBanner.decline_invite_token_expired_subtitle.replace('{orgName}', orgName),
          second_subtitle: '',
          show_signin: false,
          illustrationSrc: HubIllustrationConstants.ERROR,
          illustrationAltText: HubIllustrationAltText.ERROR
        };
        break;

      default:
        panelData = {
          type: types.ERROR,
          title: content.invitationBanner.invitation_error_title_text,
          subtitle: '',
          second_subtitle: '',
          show_signin: false,
          illustrationSrc: HubIllustrationConstants.ERROR,
          illustrationAltText: HubIllustrationAltText.ERROR
        };
        break;
    }
  }

  return (
    <div className="row">
      <div className="col sm3" />
      <div className="col sm6">
        {isHubMode() ? (
          <PopoutPanelIllustrationHeading
            title={panelData.title}
            subtitle={panelData.subtitle}
            illustrationSrc={panelData.illustrationSrc}
            illustrationAltText={panelData.illustrationAltText}
          />
        ) : (
          <PopoutPanelIconHeading type={panelData.type} title={panelData.title} subtitle={panelData.subtitle} />
        )}
        <div className={`gin-top4 text-center ${styles.buttonsContainerCenter}`}>
          {panelData.second_subtitle !== '' && <PanelHeading title="" subtitle={panelData.second_subtitle} />}
        </div>
        <div className={`gin-top4 text-center ${styles.buttonsContainerCenter}`}>
          {panelData.show_signin && (
            <Button
              variant="filled"
              text={content.invitationBanner.decline_invite_sign_in_button_text}
              onClick={async () => {
                const url = await generateLoginUrl(offeredRole, platformName);
                window.location.href = url;
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

DeclineOrgInvitationConfirmation.propTypes = {
  requestFailed: PropTypes.bool.isRequired,
  tokenStatus: PropTypes.string,
  currentInvitationStatus: PropTypes.string,
  invitationArchiveStatus: PropTypes.string,
  responseDate: PropTypes.string,
  platformName: PropTypes.string,
  orgName: PropTypes.string,
  offeredRole: PropTypes.string
};

export default DeclineOrgInvitationConfirmation;
