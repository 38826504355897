/**
 *
 * This file helps those rare contexts where we want to fetch
 * irrespective of client or server context - in such cases
 * we must pass the Node/browser fetch to our function as
 * an argument
 */

function isNode() {
  return !!process && Object.keys(process.env).length !== 0;
}

function isLocalApiServer() {
  return process.env.LOCAL_API_SERVER === 'true';
}

function getCanonicalEpsOriginByEnvType(envType) {
  const subdomain = envType === 'PROD' ? '' : `${envType.toLowerCase()}.`;
  const canonicalEpsHost = 'account.oup.com';
  const origin = `https://${subdomain}${canonicalEpsHost}`;
  return origin;
}

function getCanonicalEpsOrigin() {
  const envType = process.env.ENV_TYPE;
  return getCanonicalEpsOriginByEnvType(envType);
}

const canonicalStagedContentEnvType = 'UAT';
const canonicalPublishedContentEnvType = 'PROD';

function getEpsContentOrigin() {
  const envType = process.env.ENV_TYPE;
  const usePublishedContent = envType === 'PREPROD' || envType === 'PROD';
  if (usePublishedContent) {
    return getCanonicalEpsOriginByEnvType(canonicalPublishedContentEnvType);
  }
  return getCanonicalEpsOriginByEnvType(canonicalStagedContentEnvType);
}

function getApiBase() {
  if (isNode()) {
    // LOCAL LAMBDA SERVER
    if (isLocalApiServer()) {
      // if this is the API server itself, consume APIs from that local server rather than (say) DEV
      const port = process.env.PORT || '7800';
      return `http://localhost:${port}/edu`;
    }

    // LAMBDA and LOCAL TESTS
    return `${getCanonicalEpsOrigin()}/api/edu`;
  }

  // CLIENT

  // @ts-ignore
  return __API_BASE__;
}

module.exports = {
  getCanonicalEpsOrigin,
  getEpsContentOrigin,
  getApiBase
};
