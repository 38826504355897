import React from 'react';
import { useSelector } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';

const getReCaptchaSiteKey = () => useSelector(state => state.reCAPTCHA.siteKey);

function ReCAPTCHA({ children }) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={getReCaptchaSiteKey()} useEnterprise>
      {children}
    </GoogleReCaptchaProvider>
  );
}

ReCAPTCHA.propTypes = {
  children: PropTypes.any
};

export default ReCAPTCHA;
