import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './ImportUsersOptions.scss';
import CurriculumTermsTable from '../CurriculumTermsTable/CurriculumTermsTable';

/**
 * The ordered set of column keys to display content for.
 *
 * @type {array}
 */
// prettier-ignore
const COLUMNS = [
  'first_name',
  'last_name',
  'username',
  'password',
  'year_group',
  'curriculum_terms',
  'class'
];

function ImportUsersGuidance({
  heading,
  subheading,
  localizedContent: { importUsersOptions: content },
  curriculumType
}) {
  return (
    <div>
      <p className="gin-top2">
        <em>{heading || content.guidance_heading}</em>
      </p>
      <p className="gin-bot1">{subheading || content.guidance_subheading}</p>
      <ol className={classnames(styles.list, 'gin-top2', 'gin-bot2')}>
        {COLUMNS.map(key =>
          key === 'curriculum_terms' ? (
            <CurriculumTermsTable
              key={key}
              curriculumType={curriculumType}
              headingYearGroup={content.curriculum_table_heading_year_group}
              headingYearGroupNumber={content.curriculum_table_heading_year_group_number}
            />
          ) : (
            <li key={key} className={classnames(styles.item, 'gin-bot1')}>
              <em>{content[`guidance_column_${key}`]}</em>
              {content[`guidance_column_${key}_description`] ? (
                <span>: {content[`guidance_column_${key}_description`]}</span>
              ) : null}
            </li>
          )
        )}
      </ol>
    </div>
  );
}

ImportUsersGuidance.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  curriculumType: PropTypes.string
};

export default withLocalizedContent('importUsersOptions')(ImportUsersGuidance);
