import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { isExternalLink, isScrollToIdLink } from '../../globals/validations';
import styles from './Link.scss';

function Link({
  to = '',
  disabled,
  className,
  onClick,
  children,
  underlined,
  dataTestId = '',
  openInNewTab = false,
  ...props
}) {
  const isExternal = isExternalLink(to) || openInNewTab;

  return React.createElement(
    isExternal ? 'a' : RouterLink,
    {
      ...(disabled ? {} : { [isExternal || isScrollToIdLink(to) ? 'href' : 'to']: to, onClick }),
      ...(isExternal ? { target: '_blank' } : {}),
      className: classNames(styles.link, className, {
        [styles.linkDisabled]: disabled,
        [styles.underlined]: underlined
      }),
      'data-testid': dataTestId,
      ...props
    },
    children
  );
}

Link.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object
    })
  ]),
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  underlined: PropTypes.bool,
  onClick: PropTypes.func,
  openInNewTab: PropTypes.bool,
  dataTestId: PropTypes.string
};

export default Link;
