import PropTypes from 'prop-types';
import React from 'react';

import withLocalizedContent from '../../language/withLocalizedContent';

import OrbLearningObjectLink from '../OrbLearningObjectLink/OrbLearningObjectLink.jsx';
import OrbTeacherContentMetadata from '../OrbTeacherContentMetadata/OrbTeacherContentMetadata.jsx';

class OrbTeacherFlashcard extends React.PureComponent {
  render() {
    const {
      content,
      localizedContent: { orbTeacherResourcesPage: i18n }
    } = this.props;

    return (
      <div className="orb-card-container">
        <div className="row orb-card-header">
          <div className="col sm12">
            <div>
              <OrbLearningObjectLink
                content={content}
                title={`${i18n[content.resource_type]} | <strong>${content.title}</strong>`}
              />
            </div>
          </div>
        </div>
        <div className="row orb-card-content">
          <div className="col sm2">
            <h2 className="orb-book-cover">{content.title}</h2>
          </div>
          <div className="col sm10">
            <div>
              <OrbTeacherContentMetadata content={content} />
            </div>
            {content.description && <div className="orb-book-description">{content.description}</div>}
          </div>
        </div>
      </div>
    );
  }
}

OrbTeacherFlashcard.propTypes = {
  content: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('orbTeacherResourcesPage')(OrbTeacherFlashcard);
