import jsonFetch from '../util/jsonFetch';

export default async ({ groupGuid, token }) => {
  const url = `${__API_BASE__}/open/ltitool1v3/content-selection/invalidate`;
  const payload = { token, groupGuid };
  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify(payload);

  const response = await jsonFetch('invalidateContentSelectorGroup', [url, { method: 'POST', headers, body }], false);
  return response;
};
