import * as t from '../actionTypes';

export const postContentPackageRequest = file => ({
  type: t.POST_UPLOAD_CONTENT_PACKAGE_REQUEST,
  payload: file
});

export const postContentPackageStart = () => ({
  type: t.POST_UPLOAD_CONTENT_PACKAGE_START
});

export const postContentPackageSuccess = data => ({
  type: t.POST_UPLOAD_CONTENT_PACKAGE_SUCCESS,
  payload: data
});

export const postContentPackageFailure = error => ({
  type: t.POST_UPLOAD_CONTENT_PACKAGE_FAILURE,
  payload: error
});
