import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createCodeBatchRequest } from '../../../../redux/actions/createCodeBatch';
import styles from './css/AddLicenseContentPage.scss';
import AddLicenseDetails from './components/AddLicenseDetails';
import ToggleLicenseBatch from './components/ToggleLicenseBatch';
import BatchDetails from './components/BatchDetails';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

import useBatchState from './components/hooks/useBatchState';
import useLicenseState from './components/hooks/useLicenseState';

function AddLicenseContentPage({
  localizedContent: { activationCodeBatch: content },
  createCodeBatchRequestAction,
  isLoading,
  success,
  error,
  user
}) {
  const [togglePage, setTogglePage] = useState(true);
  const [displayMessagesAndClass, setDisplayMessagesAndClass] = useState({
    messages: [],
    class: true
  });

  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [product, setProduct] = useState({});
  const [batchDetails, setBatchDetails] = useBatchState();
  const [licenseDetails, setLicenseDetails] = useLicenseState();

  const setBatchDetailsFunctions = setBatchDetails();
  const setLicenseDetailsFunctions = setLicenseDetails();
  const setToggle = useCallback(
    param => {
      setTogglePage(param);
    },
    [togglePage]
  );

  const addProductToState = useCallback(
    param => {
      setProduct(param);
      if (param.productId || param.productGroupId) {
        setEnableSaveButton(false);
      }
    },
    [product]
  );

  const sliceDate = param => new Date(param);

  const createBatch = () => {
    const validationErrors = [];

    const { batchName, numberOfTokens, batchAllowedUsage, tokenStartDate, tokenEndDate, format } = batchDetails;

    const {
      licenseStartDate,
      licenseEndDate,
      licenseType,
      totalConcurrency,
      userConcurrency,
      beginOn,
      unitType,
      timePeriod,
      licenseAllowedUsage
    } = licenseDetails;

    const payload = {
      productId: product.erightsId,
      productGroupId: product.productGroupserightsId,
      format,
      licenseType
    };

    if (batchName) {
      payload.batchName = batchName;
    }
    if (numberOfTokens) {
      payload.numberOfTokens = Number(numberOfTokens);
    }
    if (batchAllowedUsage) {
      payload.batchAllowedUsage = Number(batchAllowedUsage);
    }

    if (tokenStartDate) {
      payload.tokenStartDate = sliceDate(tokenStartDate);
    }
    if (tokenEndDate) {
      payload.tokenEndDate = sliceDate(tokenEndDate);
    }
    if (licenseStartDate) {
      payload.licenseStartDate = sliceDate(licenseStartDate);
    }
    if (licenseEndDate) {
      payload.licenseEndDate = sliceDate(licenseEndDate);
    }

    if (licenseType === 'ROLLING') {
      payload.timePeriod = Number(timePeriod);
      payload.unitType = unitType === 'Year' ? 'year' : unitType;
      payload.beginOn = beginOn === 'First Use' ? 'first_use' : beginOn;
    } else if (licenseType === 'CONCURRENT') {
      payload.totalConcurrency = Number(totalConcurrency);
      payload.userConcurrency = Number(userConcurrency);
    } else if (licenseType === 'USAGE') {
      if (licenseAllowedUsage) {
        payload.licenseAllowedUsage = Number(licenseAllowedUsage);
      }
    }

    if (licenseEndDate < licenseStartDate) {
      validationErrors.push("License start date can't be after License end date");
    }

    if (tokenEndDate < tokenStartDate) {
      validationErrors.push("Token start date can't be after Token end date");
    }

    if (Number.isNaN(parseInt(numberOfTokens, 10))) {
      validationErrors.push('Number of tokens must be a number');
    }

    if (Number.isNaN(parseInt(batchAllowedUsage, 10))) {
      validationErrors.push('Batch allowed usages must be a number');
    }

    if (licenseAllowedUsage && Number.isNaN(parseInt(licenseAllowedUsage, 10))) {
      validationErrors.push('License allowed usages must be a number');
    }

    if (totalConcurrency && Number.isNaN(parseInt(totalConcurrency, 10))) {
      validationErrors.push('Total concurrency must be a number');
    }

    if (userConcurrency && Number.isNaN(parseInt(userConcurrency, 10))) {
      validationErrors.push('User concurrency must be a number');
    }

    if (validationErrors.length === 0) {
      createCodeBatchRequestAction(payload);
    } else {
      setDisplayMessagesAndClass(prevParam => ({
        ...prevParam,
        messages: validationErrors,
        class: false
      }));
    }
  };

  useEffect(() => {
    if (error?.error) {
      setDisplayMessagesAndClass(prevParam => ({
        ...prevParam,
        messages: [`${error.error}`],
        class: false
      }));
      setTimeout(() => {
        setDisplayMessagesAndClass({
          messages: [],
          class: true
        });
      }, 7000);
    }
    if (success?.success) {
      setBatchDetailsFunctions.resetAllFields();
      setLicenseDetailsFunctions.resetAllFields();

      setToggle(true);
      setProduct({});
      setEnableSaveButton(true);
      setDisplayMessagesAndClass(prevParam => ({
        ...prevParam,
        messages: [`${content.successMessage} ${user.email}`],
        class: true
      }));

      setTimeout(() => {
        setDisplayMessagesAndClass({
          messages: [],
          class: true
        });
      }, 7000);
    }
  }, [success, error]);

  const renderMessages = useCallback(messages => messages.map((message, key) => <p key={key}>{message}</p>), [
    displayMessagesAndClass
  ]);

  return (
    <div className={styles.mainContentContainer}>
      <div className={styles.createBatchHeader}>{content.create_batch}</div>
      {Array.isArray(displayMessagesAndClass.messages) && displayMessagesAndClass.messages.length > 0 && (
        <div className={`${displayMessagesAndClass.class ? styles.successMessage : styles.errorMessage}`}>
          {renderMessages(displayMessagesAndClass.messages)}
        </div>
      )}
      <div className={styles.licenseContainer}>
        <ToggleLicenseBatch toggleButton={togglePage} setToggle={setToggle} />

        <div className="licneseTab">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="productContainer">
              {togglePage ? (
                <AddLicenseDetails
                  product={product}
                  addProductToState={addProductToState}
                  setLicenseDetails={setLicenseDetails}
                  licenseDetails={licenseDetails}
                />
              ) : (
                <BatchDetails batchDetails={batchDetails} setBatchDetails={setBatchDetails} />
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {' '}
        <button
          className={`${enableSaveButton ? styles.disabledSaveButton : styles.saveButton}`}
          disabled={enableSaveButton}
          type="button"
          onClick={createBatch}
        >
          {content.saveButton}
        </button>
      </div>
    </div>
  );
}

AddLicenseContentPage.propTypes = {
  localizedContent: PropTypes.object,
  createCodeBatchRequestAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  success: PropTypes.object,
  error: PropTypes.object,
  user: PropTypes.object
};

export default connect(
  ({ identity: user, createActivationCodeBatch: { isLoading, error, success } }) => ({
    user,
    isLoading,
    error,
    success
  }),

  {
    createCodeBatchRequestAction: createCodeBatchRequest
  }
)(withLocalizedContent('activationCodeBatch')(AddLicenseContentPage));
