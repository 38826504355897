import React from 'react';
import PropTypes from 'prop-types';
// Styles
import classnames from 'classnames';
import styles from './enrollUser.scss';
// Components
import { SIZES as thumbnailSizes } from '../../../../../../components/Thumbnail/Thumbnail.js';
import Checkbox from '../../../../../../components/Checkbox/Checkbox.js';
import ConnectedClassRepresentation from '../../../../../../components/ClassRepresentation/ConnectedClassRepresentation.js';

function EnrolUserClassList({
  classList,
  classrooms,
  setSelectionAction,
  selectedIds,
  editClass,
  isMaxSelectionExceed
}) {
  return (
    <ol id="assignmentResultList">
      {classList.map(id => {
        const classroom = classrooms[id] || {};
        const selectedStatus = (selectedIds || []).includes(id);
        return (
          <li
            key={id}
            className={classnames(styles.enrolUsersClassList, 'pad-top1 pad-bot1 pad-left2 pad-right2', {
              [styles.selected]: selectedStatus,
              [styles.disabledClass]: isMaxSelectionExceed && !selectedStatus
            })}
          >
            <Checkbox
              id={`searchResults-checkbox-select-${id}`}
              label={classroom.name || ''}
              labelHidden
              value={selectedStatus}
              onChange={() => {
                setSelectionAction(id, !selectedStatus);
              }}
              disabled={isMaxSelectionExceed && !selectedStatus}
            >
              <ConnectedClassRepresentation
                id={`searchResults-item-${id}`}
                entityId={id}
                linkIdPrefix="linkToClassroom"
                thumbnailSize={thumbnailSizes.TABLE}
                deletedStyle={classroom.archived}
                showAmountStudents
                isNewEntity={classroom.tempNewClass}
                isSelected={selectedStatus}
                editEntity={classroom.tempNewClass ? e => editClass(e, id) : false}
              />
              {classroom.tempNewClass && (
                <button type="button" className={styles.editBtn} onClick={e => editClass(e, id)}>
                  Edit
                </button>
              )}
            </Checkbox>
          </li>
        );
      })}
    </ol>
  );
}

EnrolUserClassList.propTypes = {
  classList: PropTypes.array.isRequired,
  classrooms: PropTypes.object.isRequired,
  selectedIds: PropTypes.array.isRequired,
  editClass: PropTypes.func.isRequired,
  setSelectionAction: PropTypes.func.isRequired,
  isMaxSelectionExceed: PropTypes.bool.isRequired
};

export default EnrolUserClassList;
