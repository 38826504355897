import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * Conditionally render or redirect away from a component based on whether it
 * should be accessible by the currently authenticated user.
 *
 * @param {Function} getIsAuthorized A selector either returning a boolean
 *     indicating whether the action is authorized for the current user, or
 *     returning a function to resolve access to a resource for the
 *     authenticated user using the current route context. This is of the
 *     signature `(context: Object) => boolean`, for which the current route
 *     params are the available context.
 * @param {string}   redirectUrl     The URL to redirect to if the route is
 *     unauthorised.
 *
 * @return {Function}
 */
const withAuthorization = (Component, getIsAuthorized, routePath, isOrgRoot = true) => {
  class AuthorizedComponent extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      history: PropTypes.object.isRequired,
      isOrgStaff: PropTypes.bool.isRequired
    };

    componentWillMount() {
      const { history, isOrgStaff } = this.props;

      if (!isOrgStaff || !isOrgRoot) {
        history.replace(routePath);
      }
    }

    render() {
      const { isOrgStaff, ...props } = this.props;

      return <Component {...props} />;
    }
  }

  return React.createElement(
    withRouter(
      connect((state, ownProps) => {
        const isAuthorized = getIsAuthorized(state);

        return {
          isOrgStaff: typeof isAuthorized === 'function' ? isAuthorized(ownProps.match.params) : isAuthorized
        };
      })(AuthorizedComponent)
    )
  );
};

export default withAuthorization;
