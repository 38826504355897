import { select } from 'redux-saga/effects';
// import actions from '../../../../actions';
import deleteActivationCodeApi from '../../../apiCalls/deleteActivationCodeApi';

function* deleteActivationCode() {
  const { codetoDelete } = yield select(state => ({
    codetoDelete: state.activationCodeDashboard.code
  }));

  let apiResults = {};
  const activationCode = codetoDelete.replace(/-/g, '');

  apiResults = yield deleteActivationCodeApi({ activationCode });
  console.log(apiResults);
}

export default deleteActivationCode;
