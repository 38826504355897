import PropTypes from 'prop-types';
import React from 'react';
import { validColors } from '../../globals/colors';
import ContentWithThumbnail from '../ContentWithThumbnail/ContentWithThumbnail';
import { validGlyphs } from '../SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import styles from './PageHeading.scss';

/** This component consists of a title and subtitle. It also supports an optional Thumbnail */
function PageHeading({ title, subtitle, thumbnailColor, thumbnailGlyph, thumbnailClassName }) {
  const headings = (
    <div className={styles.pageHeading}>
      <h2 className={styles.title}>{title}</h2>
      {/* eslint-disable-next-line react/no-danger */}
      {subtitle && <p className="lead" dangerouslySetInnerHTML={{ __html: subtitle }} />}
    </div>
  );

  return thumbnailColor && thumbnailGlyph ? ( // If a color and glyph are set then wrap the headings in a ContentWithThumbnail
    <ContentWithThumbnail
      size={thumbnailSizes.HEADING}
      backgroundColor={thumbnailColor}
      glyph={thumbnailGlyph}
      thumbnailClassName={thumbnailClassName}
    >
      {headings}
    </ContentWithThumbnail>
  ) : (
    // Else we just return the headings
    headings
  );
}

PageHeading.propTypes = {
  /** The main heading text. */
  title: PropTypes.string.isRequired,
  /** The second sub-heading text. */
  subtitle: PropTypes.string,
  /** The color of the optional thumbnail. */
  thumbnailColor: PropTypes.oneOf(validColors),
  /** The icon to be used by the optional thumbnail. */
  thumbnailGlyph: PropTypes.oneOf(validGlyphs),
  /** This is an optional classname for the element which wraps the Thumbnail. Used by the ListPageHeading. */
  thumbnailClassName: PropTypes.string
};

export default PageHeading;
