import * as t from '../actionTypes';

export const deleteProductRequest = filters => ({
  type: t.DELETE_PRODUCT_REQUEST,
  payload: filters
});

export const deleteProductSuccess = data => ({
  type: t.DELETE_PRODUCT_SUCCESS,
  payload: data
});

export const deleteProductFailure = data => ({
  type: t.DELETE_PRODUCT_FAILURE,
  payload: data
});

export const clearDeleteState = () => ({
  type: t.CLEAR_DELETE_STATE
});
