/* eslint-disable react/prefer-stateless-function */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
// Redux
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import { setSendEmailPreference } from '../../redux/actions/onlineTestActions';
// Constants
import { setHourOnDate } from '../../globals/dateFormats';
import { HubLayoutConstants, ONLINE_TEST_PANEL_CONTEXT } from '../../globals/hubConstants';
// Utils
import withLocalizedContent from '../../language/withLocalizedContent';
import getEllipsedText from '../../structure/HubClassLayout/Utils/getEllipsedText';
import { getTestTitle } from '../../structure/HubCourseAssessments/Services/getTestData';
// Components
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import ActionList, { Action } from '../ActionList/ActionList';
import Lozenge from '../Lozenge/Lozenge';
// Style
import styles from './OnlineTestEditSettings.scss';
import colors from '../../globals/colors';
import { isLtiMode } from '../../utils/platform';
import OnlineTestTime from '../OnlineTestTime/OnlineTestTime';
import OnlineTestCommPreference from '../OnlineTestCommPreference/OnlineTestCommPreference';

class OnlineTestEditSettings extends Component {
  constructor(props) {
    super(props);
    const {
      setStartDate,
      setEndDate,
      setStartHour,
      setEndHour,
      isUpdateTestFlow,
      test,
      timeGotSet,
      toggleSendEmailPreference
    } = this.props;

    if (isUpdateTestFlow) {
      const startDate = new Date(test.testStartDate);
      const endDate = new Date(test.testEndDate);
      const startMinutes =
        startDate.getMinutes() / 10 < 1 ? [0, startDate.getMinutes()].join('') : startDate.getMinutes();
      const endMinutes = endDate.getMinutes() / 10 < 1 ? [0, endDate.getMinutes()].join('') : endDate.getMinutes();
      const startHour = [startDate.getHours(), ':', startMinutes].join('');
      const endHour = [endDate.getHours(), ':', endMinutes].join('');
      setHourOnDate(startDate, startHour);
      setHourOnDate(endDate, endHour);
      setStartHour(startHour);
      setEndHour(endHour);
      setStartDate(startDate);
      setEndDate(endDate);
      timeGotSet();

      toggleSendEmailPreference({ sendEmailToStudents: test.sendEmailToStudents });
    }
  }

  componentDidUpdate(prevProps) {
    const { editTestSuccess } = this.props;
    if (editTestSuccess !== prevProps.editTestSuccess) {
      const { switchToPage, isUpdateTestFlow = false } = this.props;
      const steps = HubLayoutConstants.ONLINE_TEST_STEPS;

      if (isUpdateTestFlow) {
        if (editTestSuccess) {
          switchToPage({ page: steps.CONFIRMATION_PAGE });
        } else {
          switchToPage({ page: steps.EDIT_TEST_FAILURE });
        }
      }
    }
  }

  getDescriptionContent = () => {
    const {
      isUpdateTestFlow,
      classroom,
      selectedStudentIds,
      localizedContent: { hubSetTestOnline: content },
      totalNumberOfStudents,
      switchToPage,
      test,
      assessmentTitle,
      context,
      resetPanel,
      classId
    } = this.props;

    const steps = HubLayoutConstants.ONLINE_TEST_STEPS;
    const testTitle = getTestTitle(test);

    if (isUpdateTestFlow) {
      return (
        <>
          <div className={styles.boldTextContainer}>
            {content.online_test} {test ? `${assessmentTitle} ${testTitle}` : ''}
          </div>
          <div className={styles.boldTextContainer}>
            {content.online_test_class} {classroom.name}{' '}
            {`(${test.selectedStudentIds.length} ${content.online_test_out_of} ${classroom.amountOfLearners} ${content.online_test_students})`}
          </div>
        </>
      );
    }
    if (context === ONLINE_TEST_PANEL_CONTEXT.CLASS_CONTEXT) {
      return (
        <>
          <div>
            <span className={styles.boldTextContainer}>{content.online_test}</span>
            <div className={`${styles.lozengeContainer} ${styles.containerWithPadding}`}>
              <Lozenge
                id="changeTestButtonId"
                text={`${getEllipsedText(assessmentTitle)} : ${testTitle}`}
                backgroundColor={colors.COURSE}
                buttonAction={() => {
                  resetPanel();
                  switchToPage({ page: steps.CHOOSE_TEST_PAGE, classId });
                }}
              />
            </div>
          </div>
          <div>
            <span className={styles.boldTextContainer}>{content.online_test_class}</span>
            <span className={styles.containerWithPadding}>{classroom.name}</span>
          </div>
        </>
      );
    }
    return (
      <>
        <div className={styles.boldTextContainer}>{test ? `${assessmentTitle} ${testTitle}` : ''}</div>
        <div className={styles.boldTextContainer}>
          {classroom.name}
          <div
            className={styles.studentNumberLabel}
          >{`(${selectedStudentIds.length} ${content.online_test_out_of} ${totalNumberOfStudents} ${content.online_test_students})`}</div>
        </div>
        <button
          type="button"
          className={styles.editClassButton}
          onClick={() => {
            switchToPage({ page: steps.CHOOSE_CLASS_PAGE });
          }}
        >
          <div className={styles.editClassText}>
            {content.online_test_edit_class}
            <SVGIcon glyph={GLYPHS.ICON_RIGHT} />
          </div>
        </button>
      </>
    );
  };

  render() {
    const {
      test,
      assessmentTitle,
      next,
      prev,
      localizedContent: { hubSetTestOnline: content },
      hasErrors,
      isUpdateTestFlow
    } = this.props;

    const testTitle = getTestTitle(test);

    return (
      <ScrollContainer
        headerContent={
          <header>
            <div>
              <h2>{content.online_test_edit_title}</h2>
              {!isUpdateTestFlow ? (
                <div>{test ? `${assessmentTitle} ${testTitle}` : ''}</div>
              ) : (
                <div>{content.online_test_edit_subtitle}</div>
              )}
            </div>
          </header>
        }
        footerContent={
          <ActionList>
            <Action label={content.online_test_class_cancel_test} onClick={() => prev()} />
            <Action
              disabled={hasErrors}
              label={isUpdateTestFlow ? content.online_test_save : content.online_test_title}
              primary
              onClick={() => next()}
            />
          </ActionList>
        }
      >
        <div>
          {this.getDescriptionContent()}
          <OnlineTestTime takeDataFromTest test={test} onlyBody />
          {!isLtiMode() && <OnlineTestCommPreference test={test} onlyBody />}
        </div>
      </ScrollContainer>
    );
  }
}

export default compose(
  withLocalizedContent('hubSetTestOnline'),
  connect(
    ({ onlineTest, classrooms, search }) => {
      const classroom = classrooms ? classrooms.data[onlineTest.classId] : {};

      return {
        hasErrors: onlineTest.hasErrors,
        classroom: classroom || {},
        classId: onlineTest.classId,
        totalNumberOfStudents: search.userSelection?.totalResults || [],
        selectedStudentIds: onlineTest.selectedStudentIds || [],
        startDate: onlineTest.startDate || '',
        endDate: onlineTest.endDate || '',
        startHour: onlineTest.startHour || '',
        endHour: onlineTest.endHour || '',
        editTestSuccess: onlineTest.success || null
      };
    },
    {
      switchToPage: actions.switchToPage,
      setStartDate: actions.setStartDate,
      setEndDate: actions.setEndDate,
      setStartHour: actions.setStartHour,
      setEndHour: actions.setEndHour,
      timeGotSet: actions.timeGotSet,
      toggleSendEmailPreference: setSendEmailPreference
    }
  )
)(OnlineTestEditSettings);

OnlineTestEditSettings.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  next: PropTypes.func,
  prev: PropTypes.func,
  test: PropTypes.object,
  assessmentTitle: PropTypes.string,
  switchToPage: PropTypes.func,
  classroom: PropTypes.object,
  selectedStudentIds: PropTypes.array,
  totalNumberOfStudents: PropTypes.array,
  hasErrors: PropTypes.bool,
  isUpdateTestFlow: PropTypes.bool,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  setStartHour: PropTypes.func,
  setEndHour: PropTypes.func,
  timeGotSet: PropTypes.func,
  editTestSuccess: PropTypes.bool,
  toggleSendEmailPreference: PropTypes.func,
  context: PropTypes.string,
  resetPanel: PropTypes.func,
  classId: PropTypes.string
};

OnlineTestEditSettings.defaultProps = {
  assessmentTitle: ''
};
