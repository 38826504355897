import * as t from '../actionTypes';

export const getFilteredCourseMaterials = filters => ({
  type: t.HUB_GET_FILTERED_COURSE_MATERIALS,
  filters
});

export const resetFiltersCourseMaterials = payload => ({
  type: t.HUB_RESET_FILTERS_COURSE_MATERIALS,
  payload
});

export const setUnfilteredCourseMaterials = payload => ({
  type: t.HUB_SET_UNFILTERED_COURSE_MATERIALS,
  payload
});
