import { put, select } from 'redux-saga/effects';
// Used for importing Regex
import appSettings from '../../../../../../globals/appSettings.js';

// TODO: fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  setFirstNameValidation,
  setLastNameValidation,
  setEmailValidation,
  setRoleValidation,
  setFullFormValidation
} from '../../../../../reducers/enrolUser.reducer.js';

import userRoles from '../../../../../../globals/userRoles.js';

export function validateFirstName(firstName) {
  if (!firstName) {
    return {
      invalid: true,
      errorMessage: 'Please enter a first name.'
    };
  }
  if (
    appSettings.INVALID_CHARACTERS_REGEX.test(firstName) ||
    appSettings.INVALID_PERIOD_PLACEMENT_REGEX.test(firstName)
  ) {
    return {
      invalid: true,
      errorMessage: 'Names cannot contain invalid characters.'
    };
  }
  return {
    invalid: false,
    errorMessage: null
  };
}

export function validateSchoolCode(schoolCode) {
  if (!schoolCode) {
    return {
      invalid: true,
      errorMessage: 'Please enter a school code.'
    };
  }
  return {
    invalid: false,
    errorMessage: null
  };
}
export function validateLastName(lastName) {
  if (!lastName) {
    return {
      invalid: true,
      errorMessage: 'Please enter a last name.'
    };
  }
  if (
    appSettings.INVALID_CHARACTERS_REGEX.test(lastName) ||
    appSettings.INVALID_PERIOD_PLACEMENT_REGEX.test(lastName)
  ) {
    return {
      invalid: true,
      errorMessage: 'Names cannot contain invalid characters.'
    };
  }
  return {
    invalid: false,
    errorMessage: null
  };
}

export function validateEmail(email, isOUPStaff, context, roleValue, usernameGenerated) {
  const REGEX = appSettings.EMAIL_REGEX;
  const emailIsOUP = email.endsWith('@oup.com');

  // const REGEX = isOUPStaff && context === 'OUP_STAFF'
  //   ? appSettings.EMAIL_REGEX.endsWith('@oup.com')
  //   : appSettings.EMAIL_REGEX;
  if (usernameGenerated) {
    return {
      invalid: false,
      errorMessage: null
    };
  }
  if (!email) {
    return {
      invalid: true,
      errorMessage: 'Please enter an email address.'
    };
  }
  if (
    !REGEX.test(email) ||
    (context === 'OUP_STAFF' && !emailIsOUP && roleValue.length && roleValue !== userRoles.EXTERNAL_CONTENT)
  ) {
    return {
      invalid: true,
      errorMessage: `Please enter a valid ${
        !emailIsOUP &&
        isOUPStaff &&
        context === 'OUP_STAFF' &&
        roleValue.length &&
        roleValue !== userRoles.EXTERNAL_CONTENT
          ? 'oup.com '
          : ''
      }email address.`
    };
  }

  return {
    invalid: false,
    errorMessage: null
  };
}

export function validateRole(role) {
  if (!role) {
    return {
      invalid: true,
      errorMessage: 'Please assign a role to this user.'
    };
  }
  return {
    invalid: false,
    errorMessage: null
  };
}

export function* validateFirstNameOnBlur() {
  console.log('[enrolUser Saga] Validating `firstname` onBlur');

  const firstNameValue = yield select(state => state.enrolUser.firstNameValue);
  const validationResult = validateFirstName(firstNameValue);

  yield put(setFirstNameValidation(validationResult.invalid, validationResult.errorMessage));
}

export function* validateLastNameOnBlur() {
  console.log('[enrolUser Saga] Validating `lastname` onBlur');

  const lastNameValue = yield select(state => state.enrolUser.lastNameValue);
  const validationResult = validateLastName(lastNameValue);

  yield put(setLastNameValidation(validationResult.invalid, validationResult.errorMessage));
}

export function* validateEmailOnBlur() {
  console.log('[enrolUser Saga] Validating `email` onBlur');

  const { emailValue, isOUPStaff, context, roleValue } = yield select(state => ({
    emailValue: state.enrolUser.emailValue,
    isOUPStaff: state.identity.role === userRoles.OUP_ADMIN,
    context: state.enrolUser.context,
    roleValue: state.enrolUser.roleValue
  }));
  const validationResult = validateEmail(emailValue, isOUPStaff, context, roleValue);

  yield put(setEmailValidation(validationResult.invalid, validationResult.errorMessage));

  return validationResult;
}

export function* validateRoleOnBlur() {
  console.log('[enrolUser Saga] Validating `role` onBlur');

  const roleValue = yield select(state => state.enrolUser.roleValue);
  const validationResult = validateRole(roleValue);

  yield put(setRoleValidation(validationResult.invalid, validationResult.errorMessage));
}

export function* validateFullForm({ context, isOUPStaff, firstname, lastname, email, role, usernameGenerated }) {
  const firstNameValidation = validateFirstName(firstname);
  const lastNameValidation = validateLastName(lastname);
  const emailValidation = validateEmail(email, isOUPStaff, context, role, usernameGenerated);
  const roleValidation = validateRole(role);

  // Update the full form's validation state
  yield put(
    setFullFormValidation(
      firstNameValidation.invalid,
      firstNameValidation.errorMessage,
      lastNameValidation.invalid,
      lastNameValidation.errorMessage,
      emailValidation.invalid,
      emailValidation.errorMessage,
      roleValidation.invalid,
      roleValidation.errorMessage
    )
  );

  // Return true if any are invalid
  return firstNameValidation.invalid || lastNameValidation.invalid || emailValidation.invalid || roleValidation.invalid;
}
