import * as t from '../../actionTypes';

const initialFieldState = {
  value: '',
  message: null,
  isValid: null,
  isInvalid: null,
  isWarning: null,
  isWaiting: null
};

const initialState = {};

export default function fields(state = initialState, action = {}) {
  switch (action.type) {
    case t.SET_FIELD_VALUE: {
      return {
        ...state,
        [`${action.formName}.${action.fieldName}`]: {
          ...(state[`${action.formName}.${action.fieldName}`] || initialFieldState),
          value: action.value,
          message: action.message,
          isValid: action.isValid,
          isInvalid: action.isInvalid,
          isWarning: action.isWarning,
          isWaiting: action.isWaiting
        }
      };
    }

    case t.RESET_FIELDS:
      return {
        ...state,
        ...action.fieldNames.reduce(
          (object, fieldName) => Object.assign(object, { [`${action.formName}.${fieldName}`]: undefined }),
          {}
        )
      };

    default:
      return state;
  }
}

export const setFieldValue = (formName, fieldName, value = '', message, isValid, isInvalid, isWarning, isWaiting) => ({
  type: t.SET_FIELD_VALUE,
  formName,
  fieldName,
  value,
  message,
  isValid,
  isInvalid,
  isWarning,
  isWaiting
});

export const resetFields = (formName, fieldNames) => ({
  type: t.RESET_FIELDS,
  formName,
  fieldNames
});
