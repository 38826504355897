import { select } from 'redux-saga/effects';

import { upperFirst } from 'lodash';
import bulkUploadApi from '../../../../apiCalls/bulkUploadApi.js';
import sanitizeCSV from '../../../../../../utils/sanitizeCSV';
import { getCurrentPlatform } from '../../../../../../utils/platform';

export default function* bulkUploadRequest() {
  const { orgId, actioningUserId, actioningUserEmail, fileContents } = yield select(state => ({
    orgId: state.organisationPage.orgId,
    fileContents: state.addToLibrary.file.data,

    actioningUserId: state.identity.userId,
    actioningUserEmail: state.identity.email
  }));

  const processedFileContents = sanitizeCSV(fileContents, upperFirst);
  const platformCode = getCurrentPlatform().toUpperCase() || '';

  console.log(
    `[bulkUploadToLibrary Saga] Submitting request to upload licences: ${JSON.stringify(processedFileContents)}.`
  );
  return yield bulkUploadApi(orgId, {
    actioningUserId,
    actioningUserEmail,
    fileContents: processedFileContents,
    platformCode
  });
}
