import { put, select } from 'redux-saga/effects';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import actions from '../../../../actions';

export const PollRedemptionOperation = {
  ADD: 'ADD'
};

export default function* pollRedemption(assignmentIds, operation) {
  if (!featureIsEnabled('data-recency') || !assignmentIds.length) {
    return;
  }

  const { userId } = yield select(state => state.identity);

  yield put(
    actions.pollEntitiesSynced({
      userId,
      redemption: assignmentIds.map(assignmentId => ({ assignmentId })),
      operation,
      collection: 'userAssignments',
      target: 'redemption',
      responseEntityKey: 'pollingStatus',
      identifier: 'assignmentId',
      search: 'profileAssignments'
    })
  );
}

export function pollRedemptionAdded(ids) {
  return pollRedemption(ids, PollRedemptionOperation.ADD);
}
