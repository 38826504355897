import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import appSettings from '../../globals/appSettings';
import Dropdown from '../Dropdown/Dropdown.js';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from '../../routes/MyProfile/MyProfile.scss';
import DropdownSearchModal from '../DropdownSearchModal/DropdownSearchModal';
import TextInputFederatedAccess from '../TextInputFederatedAccess/TextInputFederatedAccess.js';
import { inspJobTitles } from '../../globals/inspConstants';
import inspContent from '../../../language/glossaries/en/eps-client/inspectionCopies.glossary.json';
import { editUserValidateInitial } from '../../redux/actions/editUser.js'; // Import your action creator

function MissingFieldsFormBody({
  localizedContent: { countryCodes, ...content },
  claimedSchool = '',
  countryCode = '',
  selfSelectedRole = '',
  formInputErrors,
  editUserValidateInput
}) {
  const [formData, setFormData] = useState({
    claimedSchool,
    countryCode,
    selfSelectedRole
  });

  const _getErrorMessage = (value, type) => {
    const trimmedValue = value.trim();
    const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(trimmedValue);
    const errorType = invalidCharacters ? 'invalid_error' : 'error';
    const errorMessageKey = `missing_fields_form_body_${type}_${errorType}`;
    return content[errorMessageKey] || '';
  };

  const handleInputChange = field => value => {
    const updatedData = {
      ...formData,
      [field]: value
    };
    setFormData(updatedData);
    editUserValidateInput({ [field]: value });
  };

  const handleInputValidation = field => () => {
    const value = formData[field];
    editUserValidateInput({ [field]: value });
  };

  const countryOptions = Object.keys(countryCodes).map(code => ({ text: countryCodes[code], value: code }));
  const roleOptions = inspJobTitles?.options || [];

  return (
    <div className={styles.consentContainerInputsContainer}>
      <ValidationStatus
        message={_getErrorMessage(formData.selfSelectedRole, 'self_selected_role')}
        forId="selfSelectedRole"
        isActive={formInputErrors?.selfSelectedRole}
        containerClassName={styles.dropdownSearchContainer}
      >
        <DropdownSearchModal
          id="selfSelectedRole"
          label={content.missing_fields_form_body_selfSelectedRole_header}
          options={roleOptions}
          buttonText={inspContent.glossary.dropdown_modal_button_placeholder}
          searchPlaceholder={inspContent.glossary.dropdown_modal_search_placeholder}
          modalTitle={inspContent.glossary.dropdown_modal_search_modal_title}
          popupOverSlider
          disableAutoComplete
          value={formData.selfSelectedRole}
          required
          onSetAction={handleInputChange('selfSelectedRole')}
          onValidationAction={handleInputValidation('selfSelectedRole')}
        />
      </ValidationStatus>

      <ValidationStatus
        message={_getErrorMessage(formData.claimedSchool, 'claimed_school')}
        forId="claimedSchool"
        isActive={formInputErrors?.claimedSchool}
      >
        <TextInputFederatedAccess
          id="claimedSchool"
          name="claimedSchool"
          label={content.missing_fields_form_body_claimedSchool_header}
          placeholder={content.missing_fields_form_body_claimedSchool_placeholder}
          value={formData.claimedSchool}
          onChange={handleInputChange('claimedSchool')}
          onBlur={handleInputValidation('claimedSchool')}
        />
      </ValidationStatus>

      <ValidationStatus
        message={_getErrorMessage(formData.countryCode, 'country_code')}
        forId="countryCode"
        isActive={formInputErrors?.countryCode}
      >
        <Dropdown
          id="countryCode"
          name="countryCode"
          label={content.missing_fields_form_body_countryCode_header}
          options={[{ text: content.missing_fields_form_body_countryCode_placeholder, value: '' }, ...countryOptions]}
          placeholder={content.missing_fields_form_body_countryCode_placeholder}
          value={formData.countryCode}
          onChange={handleInputChange('countryCode')}
          onBlur={handleInputValidation('countryCode')}
        />
      </ValidationStatus>
    </div>
  );
}

MissingFieldsFormBody.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  formInputErrors: PropTypes.object,
  editUserValidateInput: PropTypes.func.isRequired,
  claimedSchool: PropTypes.string,
  countryCode: PropTypes.string,
  selfSelectedRole: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  editUserValidateInput: input => dispatch(editUserValidateInitial(input))
});

export default compose(withLocalizedContent('countryCodes'), connect(null, mapDispatchToProps))(MissingFieldsFormBody);
