import * as t from '../../actionTypes';
import getUserLicencesStatusData from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/getUserLicencesStatusData';
import { licenceStatus as LICENCE_STATUS } from '../../../globals/hubConstants.js';

const FILTER_VALUES = {
  NOT_STARTED: 'notStarted',
  ACTIVE: 'active',
  EXPIRING_SOON: 'expiringSoon',
  EXPIRED: 'expired',
  NO_LICENCE: 'noLicence'
};
const initialFilters = [
  FILTER_VALUES.NOT_STARTED,
  FILTER_VALUES.ACTIVE,
  FILTER_VALUES.EXPIRING_SOON,
  FILTER_VALUES.EXPIRED,
  FILTER_VALUES.NO_LICENCE
];
const initialState = {
  data: {},
  initialData: {},
  isDataSet: false,
  userId: '',
  sort: 'name:asc',
  filters: initialFilters
};

const getLicenceStatus = product => {
  const licenceDetails = product.licenceDetails ? { hasLicence: true, expiryDate: product.endDate } : {};
  if (product.licenceNotStartedDetails) {
    licenceDetails.licenceNotStartedDetails = product.licenceNotStartedDetails;
  }
  const statusDetails = getUserLicencesStatusData(licenceDetails);
  return statusDetails.status;
};

const shouldBeAdded = (filters, licenceStatus) => {
  const { EXPIRED, NO_LICENCE, ACTIVE, EXPIRING, NOT_STARTED } = LICENCE_STATUS;
  switch (true) {
    case filters.indexOf(FILTER_VALUES.NOT_STARTED) !== -1 && licenceStatus === NOT_STARTED:
      return true;
    case filters.indexOf(FILTER_VALUES.ACTIVE) !== -1 && (licenceStatus === ACTIVE || licenceStatus === EXPIRING):
      return true;
    case filters.indexOf(FILTER_VALUES.EXPIRING_SOON) !== -1 && licenceStatus === EXPIRING:
      return true;
    case filters.indexOf(FILTER_VALUES.EXPIRED) !== -1 && licenceStatus === EXPIRED:
      return true;
    case filters.indexOf(FILTER_VALUES.NO_LICENCE) !== -1 && licenceStatus === NO_LICENCE:
      return true;
    default:
      return false;
  }
};

const filtersAreNeutral = filters => filters.length === Object.keys(FILTER_VALUES).length || filters.length === 0;

const reduceAssignments = assignments =>
  assignments.reduce((accumulator, currentValue) => {
    accumulator[currentValue.id] = currentValue.data;
    return accumulator;
  }, {});

const sortData = (data, sort) => {
  const userAssignments = Object.keys(data).map(id => ({ id, data: data[id] }));
  const ascSorted = userAssignments.sort((a, b) => {
    if (a.data.productDetails.title < b.data.productDetails.title) {
      return -1;
    }
    if (a.data.productDetails.title > b.data.productDetails.title) {
      return 1;
    }
    return 0;
  });
  const descSorted = JSON.parse(JSON.stringify(ascSorted)).reverse();
  switch (sort) {
    case 'name:desc':
      return reduceAssignments(descSorted);
    default:
      return reduceAssignments(ascSorted);
  }
};
const filterProducts = (state, filters) => {
  const initialData = state.initialData;
  const filteredData = {};
  const filteredIsbns = [];

  if (filtersAreNeutral(filters)) {
    return initialData;
  }

  const isbns = Object.keys(initialData);
  for (let i = 0; i < isbns.length; i += 1) {
    const product = initialData[isbns[i]];
    const licenceStatus = getLicenceStatus(product);
    if (filteredIsbns.indexOf(isbns[i]) === -1 && shouldBeAdded(filters, licenceStatus)) {
      filteredIsbns.push(isbns[i]);
    }
  }
  for (let i = 0; i < filteredIsbns.length; i += 1) {
    filteredData[filteredIsbns[i]] = initialData[filteredIsbns[i]];
  }
  return filteredData;
};

function hubProfileCourseMaterialFilters(state = initialState, { type, filters, payload, sort }) {
  switch (type) {
    case t.HUB_SET_SORT_PROFILE_COURSE_MATERIALS:
      return {
        ...state,
        data: sortData(state.data, sort),
        sort
      };
    case t.HUB_RESET_FILTERS_PROFILE_COURSE_MATERIALS:
      return {
        ...state,
        filters: initialFilters,
        data: sortData(filterProducts(state, initialFilters), state.sort)
      };
    case t.HUB_GET_FILTERED_PROFILE_COURSE_MATERIALS:
      return {
        ...state,
        filters,
        data: sortData(filterProducts(state, filters), state.sort)
      };
    case t.HUB_RESET_FILTERS_COURSE_MATERIALS: {
      const initialData = state.initialData;
      return { ...initialState, initialData, ...payload };
    }
    case t.HUB_SET_UNFILTERED_PROFILE_COURSE_MATERIALS:
      return {
        ...state,
        data: sortData(payload.data, state.sort),
        isDataSet: payload.isDataSet,
        userId: payload.userId,
        initialData: payload.initialData
      };
    default:
      return state;
  }
}
export default hubProfileCourseMaterialFilters;
