import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import colors from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../Button/Button';
import Card from '../Card/Card';
import ControlledForm from '../ControlledForm/ControlledForm';
import Dropdown from '../Dropdown/Dropdown';
import TextInput from '../TextInput/TextInput';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import { PopoutPanelIconHeading } from '..';
import actions from '../../redux/actions';

const defaultState = { userLicenceId: '', userSearchCriteria: 'userId', userSearchValue: '' };
class UserLicenceRemoveByUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  getUserLicences = () => {
    const { getUserLicences } = this.props;
    const { userSearchCriteria, userSearchValue } = this.state;
    getUserLicences(userSearchCriteria, userSearchValue);
  };

  changeUserLicenceHandler = value => this.setState({ userLicenceId: value });

  changeUserSearchCriteriaHandler = userSearchCriteria => this.setState({ userSearchCriteria });

  changeUserSearchValueHandler = userSearchValue => this.setState({ userSearchValue });

  render() {
    const {
      localizedContent: { userLicenceRemoveByUserForm: content },
      userLicences = [],
      loadingUserLicences,
      errors,
      removeLicence = () => {}
    } = this.props;

    const { userSearchCriteria = '', userSearchValue = '', userLicenceId = '' } = this.state;

    return (
      <ControlledForm>
        <div className="col md8">
          <div className="gin-bot3">
            <Card headingText={content.title} headingColor={colors.PROFILE}>
              <div className="row">
                <div className="col">
                  <Dropdown
                    id="userLicenceRemoveByUserPage-searchCriteria"
                    name="userLicenceRemoveByUserPage-searchCriteria"
                    label={content.chooseCriteria_heading}
                    value={userSearchCriteria}
                    options={[
                      { value: 'userId', text: content.chooseCriteria_byUserId },
                      { value: 'userName', text: content.chooseCriteria_byUsername }
                    ]}
                    onChange={value => this.changeUserSearchCriteriaHandler(value)}
                  />

                  <ValidationStatus
                    forId="userLicenceRemoveByUserPage-searchValue"
                    isActive={errors.search}
                    message={errors.search}
                  >
                    <TextInput
                      id="userLicenceRemoveByUserPage-searchValue"
                      label={content.searchValue_heading}
                      value={userSearchValue}
                      onChange={value => this.changeUserSearchValueHandler(value)}
                      onBlur={this.getUserLicences}
                    />
                  </ValidationStatus>
                </div>
              </div>
              <div>
                {(() => {
                  switch (true) {
                    case loadingUserLicences:
                      return <PopoutPanelIconHeading type={types.LOADING} title={content.loading} />;
                    case userLicences.length > 0:
                      return (
                        <Dropdown
                          id="userLicenceRemoveByUserPage-chooseLicence"
                          name="userLicenceRemoveByUserPage-chooseLicence"
                          label={content.chooseLicence_heading}
                          value={userLicenceId}
                          options={[
                            { value: '', text: content.chooseLicence_defaultOption },
                            ...userLicences.map(licence => ({
                              value: licence.licenceId,
                              text: licence.productName
                            }))
                          ]}
                          onChange={this.changeUserLicenceHandler}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </div>
              <div className="pad-top1">
                <Button
                  type={buttonTypes.PRIMARY}
                  text={content.remove_licence_button}
                  onClick={() => {
                    removeLicence(userLicenceId);
                  }}
                  disabled={!userLicenceId}
                />
              </div>
            </Card>
          </div>
        </div>
      </ControlledForm>
    );
  }
}

UserLicenceRemoveByUserForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  userLicences: PropTypes.array,
  getUserLicences: PropTypes.func,
  removeLicence: PropTypes.func,
  loadingUserLicences: PropTypes.bool,
  errors: PropTypes.object
};

export default compose(
  withLocalizedContent('userLicenceRemoveByUserForm'),
  connect(
    state => state.userLicenceRemoveByUser,
    dispatch => ({
      getUserLicences: (criteria, value) => {
        dispatch(actions.getUserLicencesRequest(criteria, value));
      }
    })
  )
)(UserLicenceRemoveByUserForm);
