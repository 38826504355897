import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './SampleSignInCard.scss';

function SampleSignInCard({ className, localizedContent: { sampleSignInCard: content } }) {
  return (
    <div className={classNames(styles.card, className)} role="presentation">
      <p className={styles.row}>{content.school_code_label}</p>
      <p className={styles.row}>{content.username_label}</p>
      <p className={styles.row}>{content.password_label}</p>
    </div>
  );
}

SampleSignInCard.propTypes = {
  className: PropTypes.string,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('sampleSignInCard')(SampleSignInCard);
