import React from 'react';
import PropTypes from 'prop-types';
import { orgIsPartialyRegistered, orgIsActive } from '@oup/shared-node-browser/org';
import Tooltip from '../Tooltip/Tooltip';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './OrganizationStatus.scss';

function OrganizationStatus({ registrationStatus, status }) {
  const statusIcon = () => {
    if (orgIsPartialyRegistered(registrationStatus)) return GLYPHS.ICON_PENDING;

    return orgIsActive(status) ? GLYPHS.ICON_CHECK_CIRCLE : GLYPHS.ICON_ARCHIVED;
  };
  const statusInfo = orgIsPartialyRegistered(registrationStatus) ? registrationStatus : status;

  return (
    <Tooltip title={statusInfo} className={styles.tooltip}>
      <SVGIcon glyph={statusIcon()} className={styles.icon} />
    </Tooltip>
  );
}

OrganizationStatus.propTypes = {
  registrationStatus: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default OrganizationStatus;
