import * as t from '../../../../../actionTypes';

const mapReduxAction = (fieldName, errorType = null) => {
  const data = { value: !!errorType, type: errorType };
  const payload = {
    [fieldName]: data
  };

  return {
    type: t.RVS_FORM_VALIDATE_COMPLETED,
    payload
  };
};

export default mapReduxAction;
