import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getUserLicenceRequest from '../../../api/getUserLicence';

function* getUserLicence(licenceId) {
  const response = yield getUserLicenceRequest(licenceId);
  if (response.status !== 'success') {
    yield put(actions.getUserLicenceFailure(response.message));
    return;
  }
  yield put(actions.getUserLicenceSuccess(response));
}

export default getUserLicence;
