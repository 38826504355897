import PropTypes from 'prop-types';
import React from 'react';
import Media from 'react-media';
import breakpoints from '../../styles/constants/_breakpoints.scss';
import { DropdownOption as DropdownOptionPropType } from '../../types';
import randomString from '../../utils/randomString';
import ButtonGroup, { ButtonGroupType } from '../ButtonGroup/ButtonGroup';
import Dropdown from '../Dropdown/Dropdown';
import styles from './ReactiveFilters.scss';

function ReactiveFilters({
  label = '',
  breakpoint = breakpoints.smMedia,
  onChange = () => {},
  selected = false,
  horizontal = false,
  defaultDropdownText = 'Select',
  clearDropdownOption = null,
  children
}) {
  const id = randomString(8);
  const defaultOption = {
    text: defaultDropdownText,
    value: randomString(8),
    disabled: true
  };
  const clearOption = {
    ...{
      text: 'Clear',
      value: randomString(8)
    },
    ...clearDropdownOption
  };
  const dropdownOptions = [
    defaultOption,
    ...(clearDropdownOption ? [clearOption] : []),
    ...(children
      ? children.map(child => ({
          text: child.props.label,
          value: child.props.value
        }))
      : [])
  ];

  const btnGroup = (
    <ButtonGroup id={id} groupLabel={label} groupType={horizontal ? ButtonGroupType.HORIZONTAL_GROUP : null}>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          onChange: () => onChange(child.props.value),
          checked: child.props.value === selected
        })
      )}
    </ButtonGroup>
  );

  const customDropdown = (
    <Dropdown
      customClassName={styles.dropdown}
      name={id}
      id={id}
      label={label}
      options={dropdownOptions}
      onChange={onChange}
      value={selected || selected === '' ? selected : defaultOption.value}
    />
  );

  return <Media query={JSON.parse(breakpoint)}>{matches => (matches ? btnGroup : customDropdown)}</Media>;
}

ReactiveFilters.propTypes = {
  label: PropTypes.string,
  breakpoint: PropTypes.oneOf(Object.values(breakpoints)),
  onChange: PropTypes.func,
  selected: PropTypes.string,
  horizontal: PropTypes.bool,
  defaultDropdownText: PropTypes.string,
  clearDropdownOption: DropdownOptionPropType,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default ReactiveFilters;
