import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './HubLabel.scss';

function HubLabel({ text = '', PrefixIcon, isCollapsed = false, customClassName = '' }) {
  return (
    <div
      className={classnames(
        isCollapsed ? `${styles.flagContainer} ${styles.isCollapsed}` : styles.flagContainer,
        customClassName
      )}
    >
      {PrefixIcon && PrefixIcon}
      <span className={styles.flagTitle}>{text}</span>
    </div>
  );
}

HubLabel.propTypes = {
  PrefixIcon: PropTypes.any,
  isCollapsed: PropTypes.bool,
  text: PropTypes.string.isRequired,
  customClassName: PropTypes.string
};

export default HubLabel;
