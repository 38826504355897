import { API_VERSIONS, PLATFORMS } from '@oup/shared-node-browser/constants';
import signedRequest from './util/signedFetch';
import jsonFetch from './util/jsonFetch';
import { getUserData } from './getCoursePreview';
import { isCesMode } from '../../../utils/platform';

export async function getContentPreview(filters) {
  let filterParam = '';
  if (filters) {
    if (filters.id) {
      filterParam = `&id=${filters.id}`;
    } else if (filters.title) {
      filterParam = `&title=${filters.title}`;
    }
  }

  let source = `${filters.source}.`;

  const envSourceOptions = ['prod.', 'test.', 'uat.', 'dev.'];
  const isSourceaAPlatform = !envSourceOptions.includes(source);

  if (source === 'prod.') {
    source = '';
  }

  let URL = `https://${source}account.oup.com/api/edu/open/lor/resources?type=asset&max=${filters.max}${filterParam}`;
  if (source === 'localhost.') {
    URL = `http://localhost:7800/edu/open/lor/resources?type=asset&max=${filters.max}${filterParam}`;
  }

  if (isSourceaAPlatform) {
    source = source.slice(0, -1);
    URL = `${__API_BASE__}/open/products?platform=${source}`;
    if (source === PLATFORMS.ELTCORE.toLowerCase()) {
      URL += `&include=all`;
    }
  }

  const headers = { Accept: API_VERSIONS.API_VERSION_BASE };

  const products = await jsonFetch('getContentPreview', [URL, signedRequest(URL, 'GET', headers)], false);

  if (!isCesMode()) {
    return products;
  }

  const userData = await getUserData(products.data);

  if (userData.error) {
    return products;
  }

  const data = products.data.map(product => {
    const createdById = product.entityEvent?.createdBy || '';
    const userDataCreatedById = userData.data[createdById] || '';

    const modifiedById = product.entityEvent?.modifiedBy || '';
    const userDataModifiedById = modifiedById ? userData.data[modifiedById] : '';

    if (userDataCreatedById) {
      product.entityEvent.createdByUserDetails = userDataCreatedById.userDetails;
    }

    if (userDataModifiedById) {
      product.entityEvent.modifiedByUserDetails = userDataModifiedById.userDetails;
    }

    return product;
  });
  const response = {
    status: 'success',
    data
  };

  return response;
}

function* yielded(func, params) {
  return yield func(params);
}

export default params => yielded(async () => getContentPreview(params));
