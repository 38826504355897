import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

function* oicCreateTestCredits(input) {
  const { orgId, productId, testCredits: credits } = input;

  const response = yield signedFetch(
    'oicCreateTestCredits',
    `${__API_BASE__}/org/${orgId}/oic/test-credits/${productId}`,
    'POST',
    {
      credits: parseInt(credits, 10)
    }
  );

  if (response.status === 'success') {
    return yield put(actions.createOicTestCreditsSuccess(input));
  }

  return yield put(actions.createOicTestCreditsFailure({ productId, errors: response.message }));
}

export default oicCreateTestCredits;
