import * as t from '../actionTypes';

export const sendDeferredEmailsRequest = (orgId, userRole) => ({
  type: t.SEND_DEFERRED_EMAILS_REQUEST,
  payload: { orgId, userRole }
});

export const sendDeferredEmailsSuccess = () => ({
  type: t.SEND_DEFERRED_EMAILS_SUCCESS
});

export const sendDeferredEmailsFailure = errors => ({
  type: t.SEND_DEFERRED_EMAILS_FAILURE,
  payload: errors
});

export const sendDeferredEmailsReset = () => ({
  type: t.SEND_DEFERRED_EMAILS_RESET,
  payload: null
});
