import classnames from 'classnames';
import { mapValues } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Bowser from 'bowser';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import { subIsCurriculumType } from '@oup/shared-node-browser/subscriptions';
import { sanitizeUrl } from '../../utils/url';
import EntityListing from '../EntityListing/EntityListing';
import withExpandableItemState from '../EntityListing/withExpandableItemState';
import ProductDetails from '../ProductDetails/ProductDetails';
import Lozenge from '../Lozenge/Lozenge';
import StatusIcon, { StatusType } from '../StatusIcon/StatusIcon';
import LicenceStatusIcon from '../LicenceStatusIcon/LicenceStatusIcon';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './ProductListing.scss';
import colors from '../../globals/colors';
import USER_ROLES, { isTeacherAdminOrAbove, isTeacherOrAbove } from '../../globals/userRoles.js';
import { LICENCE_CONSTANTS } from '../../globals/appConstants.js';
import { setMaterialToRemove } from '../../redux/reducers/removeLearningMaterial.reducer';
import { safePanelLink } from '../../utils/links/panelLinks';
import { getAvailableFromText } from '../../utils/licences';
import Button, { buttonTypes } from '../Button/Button';
import Badge from '../Badge/Badge';
import { toggleDetailsPopupWithId } from '../../redux/reducers/assignLearningMaterial.reducer.js';
import interpolateContent from '../../utils/interpolateContent';
import EditOicTestCredits from '../OicTestCredits/EditOicTestCredits';
import ShowOicTestCredits from '../OicTestCredits/ShowOicTestCredits';
import CopyToClipboardButton from '../CopyToClipboardButton/CopyToClipboardButton';

const noCover = 'N/A';

export const ProductStatus = {
  NOT_STARTED: Symbol('NOT_STARTED'),
  STARTED: Symbol('STARTED'),
  EXPIRING: Symbol('EXPIRING'),
  EXPIRED: Symbol('EXPIRED'),
  PRODUCT_NO_LICENCE: Symbol('PRODUCT_NO_LICENCE')
};

const ProductStatusIconTypes = {
  [ProductStatus.NOT_STARTED]: StatusType.PRODUCT_VALID,
  [ProductStatus.STARTED]: StatusType.ACTIVE,
  [ProductStatus.EXPIRING]: StatusType.PRODUCT_EXPIRING,
  [ProductStatus.EXPIRED]: StatusType.PRODUCT_EXPIRED,
  [ProductStatus.PRODUCT_NO_LICENCE]: StatusType.PRODUCT_NO_LICENCE
};

/**
 * @method getProductStatus
 * @description To determine the product status based on it's start and end date. It does
 * return readonly status.
 * @param  {Object} Object containing startDate and endDate
 *
 * @returns {Symbol}
 */
export function getProductStatus({ startDate, endDate }) {
  if (!startDate && !endDate) {
    return ProductStatus.PRODUCT_NO_LICENCE;
  }
  const start = moment(startDate);
  const end = moment(endDate);
  const now = moment();

  if (end.isBefore(now)) {
    return ProductStatus.EXPIRED;
  }
  if (start.isAfter(now)) {
    return ProductStatus.NOT_STARTED;
  }
  if (
    Math.ceil(moment(endDate).diff(moment(), 'days', true)) <= APP_CONSTANTS.SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS
  ) {
    return ProductStatus.EXPIRING;
  }

  return ProductStatus.STARTED;
}

const getLicenceStatusInfo = (licenceInfo, licenceContent, toBeAssigned, isSubscription) => {
  const { expiryDate, endDate, timePeriod, unitType = '', beginOn } = licenceInfo || {};
  const end = moment(expiryDate || endDate);
  const now = moment();
  const licenceIsLimited = !!timePeriod && !!unitType;
  const dateFormat = isSubscription ? 'DD/MM/YYYY' : 'DD MMM YYYY';

  switch (true) {
    // no licence available to assign
    case !licenceInfo && toBeAssigned:
      return licenceContent.status_caption_no_licence_to_assign;

    // no licence
    case !licenceInfo:
      return licenceContent.status_caption_no_licence;

    // concurrent licence - no end date
    case !endDate && !licenceIsLimited:
      return licenceContent.status_caption_unlimited_licence;

    // rolling licence - no end date and first use
    case !endDate && licenceIsLimited && beginOn === APP_CONSTANTS.FIRST_USE:
      return licenceContent.status_caption_unlimited_licence;

    // rolling licence - no end date
    case !endDate && licenceIsLimited:
      return interpolateContent(licenceContent.status_caption_limited_licence, {
        timePeriod,
        unitType: unitType.toLowerCase()
      });

    // expired licence
    case endDate && end.isBefore(now):
      return interpolateContent(licenceContent.status_caption_expired, { date: end.format(dateFormat) });

    // concurrent licence - with end date
    case endDate && !licenceIsLimited:
      return interpolateContent(licenceContent.status_caption_expiring, {
        fromNow: end.fromNow()
      });

    // rolling licence - end date greater than licence period
    case endDate && licenceIsLimited && end.diff(now, unitType.toLowerCase()) >= timePeriod:
      return interpolateContent(licenceContent.status_caption_limited, {
        timePeriod,
        unitType: unitType.toLowerCase()
      });

    // rolling licence - end date sooner than licence period
    case endDate && licenceIsLimited && end.diff(now, unitType.toLowerCase()) <= timePeriod:
      return interpolateContent(licenceContent.status_caption_expiring, {
        fromNow: end.fromNow()
      });

    default:
      return licenceContent.status_caption_no_licence;
  }
};
class ProductListing extends Component {
  get _localizedContent() {
    const {
      localizedContent: { productListing }
    } = this.props;

    return productListing;
  }

  get _isSubscription() {
    const { items } = this.props;

    return Object.values(items).some(
      item => !!((item.subscriptions && item.subscriptions.length) || item.subscriptionId)
    );
  }

  _handleRemoveUserAssignment = (productId, assignmentId, groupId) => {
    const {
      setMaterialToRemoveAction,
      history,
      userDetail: { userId }
    } = this.props;
    const assignments = [
      {
        productId,
        assignmentId,
        groupId,
        userId
      }
    ];
    setMaterialToRemoveAction(assignments);
    history.push(safePanelLink('archiveAssignment'));
  };

  _getRepresentation = (
    status,
    {
      title,
      coverImage = noCover,
      productLaunchUrl,
      iosAppLink,
      androidAppLink,
      type,
      platform,
      totalLinkedProducts,
      renewalLicenceUrl
    },
    statusMessage
  ) => {
    const { enableExternalLinks = false, onProductUrlClick, toggleModal } = this.props;
    const { mobile, osname } = Bowser;
    const oaldaProduct = platform === APP_CONSTANTS.PLATFORMS.OALD;
    const isOaldMobileContent = oaldaProduct && (iosAppLink || androidAppLink);
    const oaldProductModal = oaldaProduct && isOaldMobileContent && !mobile;
    let statusTextStyle;

    if (status === ProductStatus.EXPIRED) {
      statusTextStyle = styles.expired;
    } else if (status === ProductStatus.EXPIRING) {
      statusTextStyle = styles.expiring;
    } else if (status === ProductStatus.PRODUCT_NO_LICENCE) {
      statusTextStyle = styles.noLicence;
    } else {
      statusTextStyle = '';
    }
    let oaldProductLink;

    if (isOaldMobileContent && mobile) {
      if (osname === APP_CONSTANTS.OS_IOS) {
        oaldProductLink = iosAppLink;
      } else if (osname === APP_CONSTANTS.OS_ANDROID) {
        oaldProductLink = androidAppLink;
      }
    }
    const customProductLaunchUrl = oaldaProduct && oaldProductLink ? oaldProductLink : productLaunchUrl;
    // eslint-disable-next-line one-var
    let productTitle, badgesOaldMobile;
    if (oaldProductModal) {
      productTitle = (
        <button type="button" onClick={() => toggleModal('toggleModal', coverImage, title, iosAppLink, androidAppLink)}>
          {title}
        </button>
      );
    } else if (customProductLaunchUrl && enableExternalLinks && status !== ProductStatus.EXPIRED) {
      productTitle = (
        // IDM asked us to exclude the rel="noreferrer" to make Auth0 SSO working on their platform (handling it based on the referrer header)
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={sanitizeUrl(customProductLaunchUrl)}
          target={onProductUrlClick ? '_top' : '_blank'}
          rel={oaldaProduct ? undefined : 'noreferrer'}
          onClick={evt => this._handleProductUrlClick(sanitizeUrl(customProductLaunchUrl), evt)}
        >
          {title} <SVGIcon className={styles.titleIcon} glyph={GLYPHS.ICON_EXTERNAL} />
        </a>
      );
    } else {
      productTitle = title;
    }

    if (
      mobile &&
      oaldaProduct &&
      oaldProductLink &&
      (osname === APP_CONSTANTS.OS_IOS || osname === APP_CONSTANTS.OS_ANDROID)
    ) {
      const src = osname === APP_CONSTANTS.OS_IOS ? 'app_store_badge_img_src' : 'google_play_badge_img_src';
      const alt = osname === APP_CONSTANTS.OS_IOS ? 'app_store_badge_img_alt' : 'google_play_badge_img_src';
      badgesOaldMobile = (
        // IDM asked us to exclude the rel="noreferrer" to make Auth0 SSO working on their platform (handling it based on the referrer header)
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={sanitizeUrl(customProductLaunchUrl)}
          target={onProductUrlClick ? '_top' : '_blank'}
          onClick={evt => this._handleProductUrlClick(sanitizeUrl(customProductLaunchUrl), evt)}
        >
          <img src={this._localizedContent[src]} width="100px" height="29px" alt={this._localizedContent[alt]} />
        </a>
      );
    }

    return (
      <div
        className={classnames(styles.subscription, {
          [styles.translucent]: [ProductStatus.EXPIRED].includes(status)
        })}
      >
        <div className={styles.image}>
          {coverImage === noCover ? null : (
            <img
              src={sanitizeUrl(coverImage)}
              className={
                type && type === APP_CONSTANTS.COLLECTION_LIST && totalLinkedProducts > 0 ? styles.collectionBook : null
              }
              width="100%"
              alt={`${title}-img`}
            />
          )}
        </div>
        <div className={styles.titleWrap}>
          <div className={styles.title}>{productTitle && productTitle}</div>
          {type && type === APP_CONSTANTS.COLLECTION_LIST ? (
            <div>
              <Badge backgroundColor={colors.PRIMARY} value={`${totalLinkedProducts} Products `} />
            </div>
          ) : (
            !this._isSubscription &&
            (status === ProductStatus.EXPIRED && renewalLicenceUrl ? (
              <span className={classnames(statusTextStyle, styles.titleSub)}>
                {statusMessage} :
                <a className={styles.linkSpace} href={renewalLicenceUrl} target="_blank" rel="noreferrer">
                  Renew licence
                </a>
              </span>
            ) : (
              <span className={classnames(statusTextStyle, styles.titleSub)}>{statusMessage}</span>
            ))
          )}
        </div>
        {badgesOaldMobile}
        {oaldProductModal && (
          <div className={styles.badgeWrap}>
            <button
              type="button"
              onClick={() => toggleModal('toggleModal', coverImage, title, iosAppLink, androidAppLink)}
            >
              <img
                src={this._localizedContent.google_play_badge_img_src}
                alt={this._localizedContent.google_play_badge_img_alt}
              />
            </button>
            <button
              type="button"
              onClick={() => toggleModal('toggleModal', coverImage, title, iosAppLink, androidAppLink)}
            >
              <img
                src={this._localizedContent.app_store_badge_img_src}
                alt={this._localizedContent.app_store_badge_img_alt}
              />
            </button>
          </div>
        )}
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  _removeDuplicatesFromArray = arrayWithDuplicates => {
    const lozengeNameWithoutDuplicates = [];
    arrayWithDuplicates.forEach(element => {
      if (lozengeNameWithoutDuplicates.find(ld => ld.orgName === element.orgName) === undefined) {
        lozengeNameWithoutDuplicates.push(element);
      }
    });
    return lozengeNameWithoutDuplicates;
  };

  _renderProductList = id => {
    const { items, productTypeHeading, renderAction, toBeAssignedForLinkedProduct, ...props } = this.props;
    const { linkedProductDetails } = items[id];
    return (
      <EntityListing
        {...props}
        items={mapValues(linkedProductDetails, (linkedProduct, productId) => {
          const {
            localizedContent,
            toggleDetailsWithId,
            userDetail,
            roleName,
            userDetail: { userId }
          } = this.props;
          const licences = this._getLicencesFromItem(items[id]);
          const lozengeName = [];
          const linkedListParentId = id;
          const productDetails = linkedProduct;
          let showRemoveLicenceIcon;
          licences.forEach(org => {
            let removeLicenceDetails = [];
            const lozengeDetails = {};
            showRemoveLicenceIcon =
              org.groupDetails.type === APP_CONSTANTS.ASSIGNMENT_GROUP && isTeacherAdminOrAbove(roleName);
            if (showRemoveLicenceIcon) {
              const {
                learningAssignmentId,
                groupDetails: { groupId }
              } = org;
              removeLicenceDetails = [
                {
                  productId: id,
                  assignmentId: learningAssignmentId,
                  groupId,
                  userId
                }
              ];
            }
            lozengeDetails.orgName =
              org?.groupDetails?.type === APP_CONSTANTS.ASSIGNMENT_GROUP && isTeacherOrAbove(roleName)
                ? `${userDetail.firstName} ${userDetail.lastName}`
                : org?.groupDetails?.name?.split('_AG_')[0];
            lozengeDetails.removeLearningMaterialDetails = removeLicenceDetails;
            lozengeName.push(lozengeDetails);
          });
          const licenceDetailsNotStarted = {};
          const linkedProductStatus =
            items[id].learningAssignments &&
            items[id].learningAssignments.some(
              assignment =>
                (assignment.activationCode &&
                  !(
                    getProductStatus(assignment) === ProductStatus.EXPIRING ||
                    getProductStatus(assignment) === ProductStatus.EXPIRED
                  )) ||
                (assignment.activationCode && !assignment.endDate)
            )
              ? ProductStatus.NOT_STARTED
              : getProductStatus(items[id]);

          // Add end date from learning assignments, if product licence details not available and expiring product
          if (
            linkedProductStatus === ProductStatus.EXPIRING &&
            !items[id].licenceDetails &&
            Array.isArray(items[id].learningAssignments)
          ) {
            items[id].learningAssignments.forEach(assignment => {
              if (assignment.endDate) {
                licenceDetailsNotStarted.endDate = assignment.endDate;
              }
            });
          }
          const statusMessageForLinkedProduct =
            (items[id].startDate && items[id].endDate) ||
            linkedProductStatus === ProductStatus.STARTED ||
            (items[id].learningAssignments &&
              items[id].learningAssignments.some(assignment => assignment.activationCode))
              ? this._getCaption(linkedProductStatus, items[id].endDate, items[id].licenceNotStartedDetails)
              : getLicenceStatusInfo(
                  items[id].licenceDetails || items[id].licenceNotStartedDetails,
                  localizedContent.licenceStatusIconComponent,
                  toBeAssignedForLinkedProduct,
                  this._isSubscription
                );
          return {
            [productTypeHeading]: this._getRepresentation(
              linkedProductStatus,
              productDetails,
              statusMessageForLinkedProduct
            ),
            [this._localizedContent.heading_details]: (
              <Button
                type={buttonTypes.INFO_NO_BORDER}
                glyph={GLYPHS.ICON_INFORMATION_CIRCLE}
                text={this._localizedContent.heading_details}
                iconOnly
                onClick={() => {
                  toggleDetailsWithId(linkedListParentId, this._removeDuplicatesFromArray(lozengeName), productId);
                }}
              />
            )
          };
        })}
        type={APP_CONSTANTS.PRODUCT_LIST}
        className={styles.productListing}
        expandable
      />
    );
  };

  _renderItemContent = id => {
    const { items, showLicenceDetailContent } = this.props;
    const {
      productDetails: { title, series, author, isbn, cefrLevel, description, platform },
      learningAssignments = [],
      subscriptions = [],
      productId
    } = items[id];
    const { userDetail, roleName, orgId, expandedItems } = this.props;
    const subscriptionId = id;
    const orgNames = [
      ...new Set(subscriptions.length || !learningAssignments.length ? subscriptions : learningAssignments)
    ];
    const isOicProduct = subIsCurriculumType(platform.toLowerCase());
    const isOupAdmin = USER_ROLES.OUP_ADMIN === roleName;
    const copyButton = (
      <CopyToClipboardButton
        classCode={id}
        label={this._localizedContent.action_copy}
        customClassname={styles.copyCodeButton}
      />
    );
    return (
      <ProductDetails
        id={id}
        title={title}
        subtitle={cefrLevel}
        description={description}
        listData={[
          {
            label: this._localizedContent.details_series_title_text,
            text: series
          },
          { label: this._localizedContent.details_ISBN_title_text, text: isbn },
          {
            label: this._localizedContent.details_author_title_text,
            text: author
          },
          {
            label: this._localizedContent.details_licence_title_text,
            text: subscriptionId,
            copyText: copyButton
          },
          ...(isOicProduct
            ? [
                {
                  label: this._localizedContent.details_test_credits_title_text,
                  text: isOupAdmin ? (
                    <EditOicTestCredits orgId={orgId} productId={productId} expanded={expandedItems.includes(id)} />
                  ) : (
                    <ShowOicTestCredits orgId={orgId} productId={productId} expanded={expandedItems.includes(id)} />
                  )
                }
              ]
            : [])
        ]}
      >
        {showLicenceDetailContent && orgNames.length ? (
          <div>
            <h3 className="gin0">{this._localizedContent.related_content_text}</h3>
            <nav>
              {orgNames.map((orgName, i) => (
                <Lozenge
                  key={i}
                  className="gin-right1"
                  text={
                    orgName?.groupDetails?.type === this._localizedContent.product_assignment_group &&
                    !(roleName === USER_ROLES.LEARNER || roleName === USER_ROLES.MANAGED_USER)
                      ? `${userDetail.firstName} ${userDetail.lastName}`
                      : orgName?.groupDetails?.name?.split('_AG_')[0]
                  }
                  backgroundColor={colors.ORGANIZATION}
                  {...(orgName.groupDetails.type === this._localizedContent.product_assignment_group &&
                  !(
                    roleName === USER_ROLES.LEARNER ||
                    roleName === USER_ROLES.MANAGED_USER ||
                    roleName === USER_ROLES.TEACHER
                  )
                    ? {
                        buttonAction: () => {
                          this._handleRemoveUserAssignment(
                            subscriptionId,
                            orgName.learningAssignmentId,
                            orgName.groupDetails.groupId
                          );
                        }
                      }
                    : {})}
                />
              ))}
            </nav>
          </div>
        ) : null}
      </ProductDetails>
    );
  };

  _getCaption = (status, endDate, licenceDetails) => {
    const { timePeriod, unitType = '', beginOn = '', startDate, expiryDate } = licenceDetails || {};

    const licenceIsLimited = !!timePeriod && !!unitType;
    const isRollingFirstUseNoEndDate =
      timePeriod && unitType && !endDate && beginOn === LICENCE_CONSTANTS.BEGIN_ON.FIRST_USE;
    const dateFormat = this._isSubscription ? 'DD/MM/YYYY' : 'DD MMM YYYY';
    const _licenceExpiresCaption = () => {
      // licences type creation without an end date and user have not yet been started
      if (beginOn === LICENCE_CONSTANTS.BEGIN_ON.CREATION && status === ProductStatus.NOT_STARTED && !endDate) {
        return this._localizedContent.status_caption_first_use.replace(
          '{date}',
          `${timePeriod} ${(unitType || '').toLowerCase()}`
        );
      }

      if (beginOn === LICENCE_CONSTANTS.BEGIN_ON.FIRST_USE && !isRollingFirstUseNoEndDate) {
        return this._localizedContent.status_caption_expires.replace(
          '{date}',
          `${moment(expiryDate || endDate).format(dateFormat)}`
        );
      }
      if ((!endDate && !licenceIsLimited) || isRollingFirstUseNoEndDate) {
        return this._localizedContent.status_caption_unlimited_licence;
      }
      return this._localizedContent.status_caption_expires.replace('{date}', `${moment(endDate).fromNow(true)}`);
    };

    const licenceExpiresCaption = `${_licenceExpiresCaption()}${getAvailableFromText(
      this._localizedContent.available_from_text,
      startDate
    )}`;

    switch (status) {
      case ProductStatus.NOT_STARTED:
        return this._isSubscription ? this._localizedContent.status_caption_not_started : licenceExpiresCaption;
      case ProductStatus.STARTED:
        return this._isSubscription ? this._localizedContent.status_caption_started : licenceExpiresCaption;
      case ProductStatus.EXPIRING:
        return Math.ceil(moment(endDate).diff(moment(), 'days', true)) ===
          APP_CONSTANTS.SHOW_EXPIRES_TODAY_MESSAGE_PERIOD_DAY
          ? this._localizedContent.status_caption_expiring_today
          : this._localizedContent.status_caption_expiring.replace(
              '{date}',
              Math.ceil(moment(endDate).diff(moment(), 'days', true))
            );
      case ProductStatus.EXPIRED:
        return this._localizedContent.status_caption_expired.replace(
          '{date}',
          moment(endDate)
            .utc()
            .format(dateFormat)
        );
      default:
        return null;
    }
  };

  _customiseStatusMsg = message =>
    (message || '').replace(this._localizedContent.licence, '').replace(/^\w/, c => c.toUpperCase());

  // eslint-disable-next-line class-methods-use-this
  _getLicencesFromItem = item => {
    const { learningAssignments: assignments = [], subscriptions: subscription = [] } = item;
    const subscriptions = subscription.length ? subscription : null;
    const learningAssignments = assignments.length ? assignments : null;
    return [...new Set(subscriptions || learningAssignments || [])];
  };

  // eslint-disable-next-line class-methods-use-this
  _getEndDate = (status, endDate) => {
    let className;
    switch (status) {
      case ProductStatus.EXPIRING:
        className = styles.expiring;
        break;
      case ProductStatus.EXPIRED:
        className = styles.expired;
        break;
      default:
        className = null;
        break;
    }
    return (
      <div className={className}>
        {moment(endDate)
          .utc()
          .format('DD/MM/YYYY')}
      </div>
    );
  };

  _handleProductUrlClick = (url, event) => {
    const { onProductUrlClick } = this.props;

    if (onProductUrlClick) {
      event.preventDefault();
      onProductUrlClick(url);
    }
  };

  render() {
    const {
      items,
      productTypeHeading,
      renderAction,
      showDates = false,
      assignedMaterialsInCurrentOrg = {},
      roleName,
      toggleDetailsWithId,
      type = null,
      userDetail: { userId },
      toBeAssigned,
      ...props
    } = this.props;

    const { expandedItems = [], onItemExpandToggle } = this.props;

    return (
      <EntityListing
        {...props}
        items={mapValues(items, (product, id) => {
          const licences = this._getLicencesFromItem(product);
          const { userDetail, localizedContent } = this.props;
          const lozengeName = [];
          const customLicenceDetails = product.licenceDetails || product.licenceNotStartedDetails;
          let showRemoveLicenceIcon;
          licences.forEach(org => {
            let removeLicenceDetails = [];
            const lozengeDetails = {};
            showRemoveLicenceIcon =
              org.groupDetails.type === APP_CONSTANTS.ASSIGNMENT_GROUP && isTeacherAdminOrAbove(roleName);
            if (showRemoveLicenceIcon) {
              const {
                learningAssignmentId,
                groupDetails: { groupId }
              } = org;
              removeLicenceDetails = [
                {
                  productId: id,
                  assignmentId: customLicenceDetails ? customLicenceDetails.licenceId : learningAssignmentId,
                  groupId,
                  userId
                }
              ];
            }
            lozengeDetails.orgName =
              org?.groupDetails?.type === APP_CONSTANTS.ASSIGNMENT_GROUP && isTeacherOrAbove(roleName)
                ? `${userDetail.firstName} ${userDetail.lastName}`
                : org?.groupDetails?.name?.split('_AG_')[0];
            lozengeDetails.removeLearningMaterialDetails = removeLicenceDetails;
            lozengeName.push(lozengeDetails);
          });
          const licenceDetailsNotStarted = {};
          const status =
            product.learningAssignments &&
            product.learningAssignments.some(
              assignment =>
                (assignment.activationCode &&
                  !(
                    getProductStatus(assignment) === ProductStatus.EXPIRING ||
                    getProductStatus(assignment) === ProductStatus.EXPIRED
                  )) ||
                (assignment.activationCode && !assignment.endDate)
            )
              ? ProductStatus.NOT_STARTED
              : getProductStatus(product);
          if (
            status === ProductStatus.EXPIRING &&
            !product.licenceDetails &&
            Array.isArray(product.learningAssignments)
          ) {
            product.learningAssignments.forEach(assignment => {
              if (assignment.endDate) {
                licenceDetailsNotStarted.endDate = assignment.endDate;
              }
            });
          }

          const { productDetails: details, startDate, endDate, linkedProducts: products } = product;

          if (products && products.length > 0) {
            details.type = type;
            details.totalLinkedProducts = products.length;
          }

          const statusMessage =
            (product.startDate && product.endDate) ||
            status === ProductStatus.STARTED ||
            (assignedMaterialsInCurrentOrg[id] && assignedMaterialsInCurrentOrg[id].length) ||
            (product.learningAssignments && product.learningAssignments.some(assignment => assignment.activationCode))
              ? this._getCaption(
                  status,
                  product.endDate || licenceDetailsNotStarted.endDate,
                  product.licenceNotStartedDetails || product.licenceDetails
                )
              : getLicenceStatusInfo(
                  product.licenceDetails || product.licenceNotStartedDetails,
                  localizedContent.licenceStatusIconComponent,
                  toBeAssigned,
                  this._isSubscription
                );

          return {
            [productTypeHeading]: this._getRepresentation(status, details, statusMessage),
            ...(renderAction
              ? {
                  [this._localizedContent.heading_actions]: renderAction(id, product)
                }
              : {}),
            [this._localizedContent.heading_status]:
              (product.startDate && product.endDate) ||
              (assignedMaterialsInCurrentOrg[id] && assignedMaterialsInCurrentOrg[id].length) ||
              (product.learningAssignments &&
                product.learningAssignments.some(assignment => assignment.activationCode)) ? (
                <StatusIcon
                  type={ProductStatusIconTypes[status]}
                  className={classnames({
                    [styles.translucent]: [ProductStatus.NOT_STARTED].includes(status)
                  })}
                  title={this._customiseStatusMsg(statusMessage)}
                />
              ) : (
                <LicenceStatusIcon
                  licenceDetails={product.licenceDetails}
                  statusMessage={this._customiseStatusMsg(statusMessage)}
                  toBeAssigned
                />
              ),
            ...(showDates
              ? {
                  [this._localizedContent.heading_start_date]: moment(startDate).format('DD/MM/YYYY'),
                  [this._localizedContent.heading_end_date]: this._getEndDate(status, endDate)
                }
              : {}),
            ...(type
              ? {
                  [this._localizedContent.heading_details]:
                    product.linkedProducts && product.linkedProducts.length > 0 ? (
                      <Button
                        type={buttonTypes.DROPDOWN_NO_BORDER}
                        text={this._localizedContent.heading_expand}
                        glyph={GLYPHS[expandedItems.includes(id) ? 'CHEVRON_UP_THICK' : 'CHEVRON_DOWN_THICK']}
                        iconOnly
                        onClick={() => onItemExpandToggle(id)}
                      />
                    ) : (
                      <Button
                        type={buttonTypes.INFO_NO_BORDER}
                        text={this._localizedContent.heading_details}
                        glyph={GLYPHS.ICON_INFORMATION_CIRCLE}
                        iconOnly
                        onClick={() => toggleDetailsWithId(id, this._removeDuplicatesFromArray(lozengeName), null)}
                      />
                    ),
                  ...(type
                    ? {
                        [this._localizedContent.category]:
                          product.linkedProducts && product.linkedProducts.length > 0
                            ? APP_CONSTANTS.COLLECTION_LIST
                            : APP_CONSTANTS.PRODUCT_LIST
                      }
                    : {})
                }
              : {})
          };
        })}
        expandable
        type={type}
        className={styles.productListing}
        renderItemContent={this._renderItemContent}
        renderItemList={this._renderProductList}
      />
    );
  }
}

ProductListing.propTypes = {
  items: PropTypes.object.isRequired,
  assignedMaterialsInCurrentOrg: PropTypes.object,
  productTypeHeading: PropTypes.string.isRequired,
  showDates: PropTypes.bool,
  enableExternalLinks: PropTypes.bool,
  renderAction: PropTypes.func,
  showLicenceDetailContent: PropTypes.bool,
  onProductUrlClick: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  userDetail: PropTypes.object.isRequired,
  roleName: PropTypes.string,
  setMaterialToRemoveAction: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  type: PropTypes.string,
  expandedItems: PropTypes.arrayOf(PropTypes.string),
  onItemExpandToggle: PropTypes.func,
  toggleDetailsWithId: PropTypes.func.isRequired,
  toggleModal: PropTypes.func,
  statusMessage: PropTypes.string,
  toBeAssigned: PropTypes.bool,
  statusMessageForLinkedProduct: PropTypes.string,
  toBeAssignedForLinkedProduct: PropTypes.bool,
  orgId: PropTypes.string,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withLocalizedContent('productListing', 'licenceStatusIconComponent'),
  connect(
    state => ({
      // Connect values
      roleName: state.identity.role,
      userDetail: state.userProfile
    }),
    {
      setMaterialToRemoveAction: setMaterialToRemove,
      toggleDetailsWithId: toggleDetailsPopupWithId
    }
  ),
  withExpandableItemState
)(ProductListing);
