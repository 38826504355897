import * as t from '../actionTypes';

export const getTestCreditsRequest = data => ({
  type: t.GET_TEST_CREDITS_REQUEST,
  payload: data
});

export const getTestCreditsSuccess = input => ({
  type: t.GET_TEST_CREDITS_SUCCESS,
  payload: input
});

export const getTestCreditsEmptySuccess = payload => ({
  type: t.GET_TEST_CREDITS_EMPTY_SUCCESS,
  payload
});

export const getTestCreditsFailure = errors => ({
  type: t.GET_TEST_CREDITS_FAILURE,
  payload: errors
});

export const getTestCreditsProducts = payload => ({
  type: t.GET_TEST_CREDITS_PRODUCTS,
  payload
});
