import { put, select } from 'redux-saga/effects';
import actions from '../../../../actions';
import getSocialIdentitiesFetch from '../../../api/getSocialIdentities';

function* getSocialIdentities() {
  const { userId } = yield select(state => state.identity);

  if (!userId) {
    yield put(actions.editUserFailure());
    return;
  }

  const primaryUserId = `auth0|${userId}`;
  const response = yield getSocialIdentitiesFetch(primaryUserId);

  if (response && response.status && response.status.toLowerCase() === 'success' && response.data) {
    const finalResp = {
      primaryUserId,
      userAccount: response.data
    };
    console.log('[getSocialIdentities] editUserGetSocialIdentitiesSuccess data', finalResp);
    yield put(actions.editUserGetSocialIdentitiesSuccess(finalResp));
  }
}

export default getSocialIdentities;
