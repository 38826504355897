import numeral from 'numeral';

const formatFraction = (numerator, denominator, usePercentages) => {
  if (usePercentages) {
    return numeral(numerator / Math.round(denominator)).format('0.[0]%');
  }
  return `${Math.round(numerator)}/${Math.round(denominator)}`;
};

export const formatToPercent = number => numeral(number).format('0.[0]%');

export default formatFraction;
