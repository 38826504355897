import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import createClass from './createClass';
import getClassStudents from './getClassStudents';

function* root() {
  yield takeLatest(t.ONBOARDING_WIZARD_SET_CREATE_CLASS_REQUEST, ({ payload }) => createClass(payload));
  yield takeLatest(t.ONBOARDING_WIZARD_GET_CLASS_STUDENTS, ({ payload }) => getClassStudents(payload));
}

export default root;
