import {
  DECLINED_ORG_DETAILS_INIT,
  DECLINED_ORG_DETAILS_LOAD_SUCCESS,
  DECLINED_ORG_DETAILS_LOAD_ERROR,
  DECLINED_ORG_INVITE_UPDATING,
  DECLINED_ORG_INVITE_UPDATE_SUCCESS,
  DECLINED_ORG_INVITE_UPDATE_ERROR
} from '../actionTypes';

// Initialise page
export const initDeclineOrgInvitePage = token => ({
  type: DECLINED_ORG_DETAILS_INIT,
  token
});

// Initialise page success
export const initDeclineOrgInvitePageSuccess = data => ({
  type: DECLINED_ORG_DETAILS_LOAD_SUCCESS,
  payload: {
    platform: data.platform,
    orgName: data.orgName,
    offeredRole: data.offeredRole,
    tokenStatus: data.tokenStatus,
    currentInvitationStatus: data.invitationStatus,
    invitationArchiveStatus: data.invitationArchiveStatus,
    responseDate: data.responseDate
  }
});

// Initialise page error
export const initDeclineOrgInvitePageError = data => ({
  type: DECLINED_ORG_DETAILS_LOAD_ERROR,
  payload: {
    platform: data.platform,
    orgName: data.orgName,
    offeredRole: data.offeredRole,
    tokenStatus: data.tokenStatus,
    currentInvitationStatus: data.invitationStatus,
    invitationArchiveStatus: data.invitationArchiveStatus,
    responseDate: data.responseDate
  }
});

// Updating invitation
export const updateDeclineOrgInviteSubmit = () => ({
  type: DECLINED_ORG_INVITE_UPDATING
});

// Update invitation success
export const updateDeclineOrgInviteSuccess = () => ({
  type: DECLINED_ORG_INVITE_UPDATE_SUCCESS
});

// Update invitation error
export const updateDeclineOrgInviteError = () => ({
  type: DECLINED_ORG_INVITE_UPDATE_ERROR
});
