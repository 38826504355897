import { put, select } from 'redux-saga/effects';
import { courseResourcesRequestSuccess, courseResourcesRequestFailure } from '../../../../actions/ngsCourse';
import getCourseResourcesApiCall from '../../../apiCalls/ngs/getCourseResources';

function* getCourseResources(orgId, courseId, nodeId, resourceId, format, sort, nextPage) {
  const page = { size: 20 };

  if (nextPage) {
    const numberOfResources = yield select(state => state.ngsCourse.resources.items.length);
    page.start = numberOfResources;
  }

  const response = yield getCourseResourcesApiCall(orgId, courseId, nodeId, resourceId, format, sort, page);

  if (response.status === 'success') {
    yield put(courseResourcesRequestSuccess(response.data, nextPage));
    return;
  }

  yield put(courseResourcesRequestFailure(response.message, nextPage));
}

export default getCourseResources;
