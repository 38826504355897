import { JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const getSocialIdentitiesFetchUrl = userId =>
  `${__API_BASE__}/user/auth0-identities?userId=${encodeURIComponent(userId)}`;

export default function* getSocialIdentitiesFetch(userId) {
  return yield signedFetch('getSocialIdentities', getSocialIdentitiesFetchUrl(userId), 'GET', undefined, {
    Accept: JSON_ENCODED,
    'Content-Type': JSON_ENCODED
  });
}
