import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import EntityRepresentation from '../../../../../components/EntityRepresentation/EntityRepresentation';
import MaterialRepresentation from '../../../../../components/MaterialRepresentation/MaterialRepresentation';
import PopoutActionFooter from '../../../../../components/PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading, { types } from '../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../../../../../components/ScrollContainer/ScrollContainer';
import { sanitizeUrl } from '../../../../../utils/url.js';
import { GLYPHS } from '../../../../../components/SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../../../../../components/Thumbnail/Thumbnail';
import withLocalizedContent from '../../../../../language/withLocalizedContent';
import withLocalizedErrors from '../../../../../language/withLocalizedErrors';
import { DATE_SHORT } from '../../../../../globals/dateFormats';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../globals/hubConstants.js';
import Button, { buttonTypes } from '../../../../../components/Button/Button';
import styles from './AddToProfile.scss';
import { isHubMode } from '../../../../../utils/platform.js';

const customisedErrorMessage = (errorObject, errorMessageString) => {
  let newErrorMessage = errorMessageString;
  if (typeof errorObject === 'object' && errorObject.activationDate) {
    newErrorMessage = errorMessageString.replace('{1}', moment(errorObject.activationDate).format(DATE_SHORT));
  }
  return newErrorMessage;
};

function AddToProfileConfirmation({
  localizedContent: { addToProfile: content },
  errorOccurred = false,
  errorMessage = { code: '' },
  productData = {},
  productFetchError = false,
  productDetailsLoading = false,
  closePanel,
  onComplete,
  renderRedeemcodePanel,
  getLocalizedErrorMessage,
  ...props
}) {
  const headerErrorMessage =
    errorMessage && errorMessage.code === '2004'
      ? content.invalid_access_code_header_error
      : content.add_to_profile_error_message;
  const subtitleErrorMessage =
    errorMessage && errorMessage.code === '2004'
      ? content.invalid_access_code_subtitle_error
      : customisedErrorMessage(errorMessage, getLocalizedErrorMessage(errorMessage));

  if (isHubMode() && !errorOccurred && props.onRedeemed) props.onRedeemed();

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div className={styles.header}>
            <div className={styles.close}>
              <Button text={content.close_panel_text} onClick={closePanel} type={buttonTypes.CLOSE_BOLD} />
              {/* <PanelNavigationLink isLhs={false} text={content.close_panel_text} action={closePanel} /> */}
            </div>
            {isHubMode() ? (
              <PopoutPanelIllustrationHeading
                title={errorOccurred ? headerErrorMessage : content.add_to_profile_success_message}
                illustrationSrc={errorOccurred ? HubIllustrationConstants.ERROR : HubIllustrationConstants.SUCCESS}
                illustrationAltText={errorOccurred ? HubIllustrationAltText.ERROR : HubIllustrationAltText.SUCCESS}
              />
            ) : (
              <PopoutPanelIconHeading
                type={errorOccurred ? types.WARNING_ERROR : types.VALID}
                title={errorOccurred ? headerErrorMessage : content.add_to_profile_success_message}
              />
            )}
            <div className="text-center">
              {errorOccurred ? subtitleErrorMessage : content.add_to_profile_success_message_info}
            </div>
          </div>
        }
        footerContent={
          errorOccurred ? (
            <PopoutActionFooter
              primaryButtonAction={renderRedeemcodePanel}
              primaryButtonText={content.try_again_panel_text}
            />
          ) : (
            <PopoutActionFooter
              primaryButtonAction={onComplete}
              primaryButtonText={content.complete_panel_text}
              secondaryButtonText={content.redeem_another_code_panel_text}
              secondaryButtonAction={renderRedeemcodePanel}
            />
          )
        }
      >
        {!errorOccurred && (
          <div>
            {productDetailsLoading && (
              <PopoutPanelIconHeading
                type={types.LOADING}
                title={content.loading_products_title}
                subtitle={content.loading_products_subtitle}
              />
            )}

            {!productDetailsLoading &&
              !!Object.keys(productData).length &&
              Object.values(productData).map((product, i) => (
                <div style={{ margin: '1rem' }} key={i}>
                  <MaterialRepresentation
                    thumbnailSize={thumbnailSizes.TABLE}
                    imageSrc={sanitizeUrl(product.coverImage)}
                    name={product.title}
                    subtext={(content.avalible_licences_text || '').replace(
                      '{availableLicences}',
                      product.availableCount
                    )}
                  />
                </div>
              ))}

            {!productDetailsLoading && productFetchError && (
              <EntityRepresentation
                thumbnailGlyph={GLYPHS.ICON_ERROR_CIRCLE}
                thumbnailSize={thumbnailSizes.TABLE}
                line1Text={content.product_details_error}
              />
            )}
          </div>
        )}
      </ScrollContainer>
    </form>
  );
}

AddToProfileConfirmation.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  errorMessage: PropTypes.object,
  productData: PropTypes.object,
  errorOccurred: PropTypes.bool,
  productFetchError: PropTypes.bool,
  productDetailsLoading: PropTypes.bool,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  getLocalizedErrorMessage: PropTypes.func.isRequired,
  onRedeemed: PropTypes.func,
  renderRedeemcodePanel: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('addToProfile'),
  withLocalizedErrors('eps-redeem-code')
)(AddToProfileConfirmation);
