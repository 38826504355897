import * as t from '../actionTypes';

export const marksRequest = (orgId) => ({
  type: t.NGS_STUDENT_MARKS_REQUEST,
  payload: { orgId }
});

export const marksRequestSuccess = payload => ({
  type: t.NGS_STUDENT_MARKS_SUCCESS,
  payload
});

export const marksRequestFailure = payload => ({
  type: t.NGS_STUDENT_MARKS_FAILURE,
  payload
});
