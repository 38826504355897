import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../../components/PageWrapper/PageWrapper';
import Button, { buttonTypes } from '../../../../../components/Button/Button';
import withLocalizedContent from '../../../../../language/withLocalizedContent';
import styles from './ActCodesBatchDashboard.scss';

function ActCodesBatchDashboard({ localizedContent: { actCodesBatchDashboard: content } }) {
  const renderBatchCreate = () => (
    <Button
      to="/dashboard_eps/act_code_batch_create"
      type={buttonTypes.SECONDARY}
      text={content.create}
      fullWidth
      customClassName={styles.button}
    />
  );

  const renderBatchSearch = () => (
    <Button
      to="/dashboard_eps/act_code_batch_search"
      type={buttonTypes.SECONDARY}
      text={content.search}
      fullWidth
      customClassName={styles.button}
    />
  );

  return (
    <PageWrapper>
      <Helmet title={content.helmet_title} />
      {renderBatchCreate()}
      {renderBatchSearch()}
    </PageWrapper>
  );
}

ActCodesBatchDashboard.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('actCodesBatchDashboard'))(ActCodesBatchDashboard);
