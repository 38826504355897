/**
 *  Functions must consider all possible 11 contexts:
 *
 *  Groups:
 *   CLASS - Assigment for whole class from Assignments Tab
 *
 *  Panels:
 *   CLASS_CREATION - Assignment for GROUP from end of class creation panel
 *   CLASS_ADD_STUDENTS - Assignment for USERS from end of add students panel
 *
 *  Selected Users:
 *   CLASS_TEACHERS - Assignment for USERS selected on class teachers tab
 *   CLASS_STUDENTS - Assignment for USERS selected on class students tab
 *   CLASS_USERS - Assignment for USERS selected on class people tab (hub)
 *   ORG_STAFF - Assignment for USERS selected on org staff tab
 *   ORG_STUDENTS - Assignment for USERS selected on org learners tab
 *
 *  Licence Renews:
 *   CLASS_RENEW - Renewed assignment for users missing licence from class assignments tab
 *   USER - Renewed assignment for user from profile page
 *   USER_PROFILE - Renewed assignment for user from profile page
 *
 */

export const assignmentContexts = {
  ORG_STAFF: 'ORG_STAFF',
  ORG_STUDENTS: 'ORG_STUDENTS',
  CLASS: 'CLASS',
  CLASS_CREATION: 'CLASS_CREATION',
  CLASS_ADD_STUDENTS: 'CLASS_ADD_STUDENTS',
  CLASS_TEACHERS: 'CLASS_TEACHERS',
  CLASS_STUDENTS: 'CLASS_STUDENTS',
  CLASS_USERS: 'CLASS_USERS',
  CLASS_RENEW: 'CLASS_RENEW',
  USER_PROFILE: 'USER_PROFILE',
  USER: 'USER',
  CLASS_ONBOARDING_WIZARD: 'CLASS_ONBOARDING_WIZARD'
};

export const assignmentInstance = {
  ASSIGNMENT_PRODUCTS: 'assignmentProducts',
  USER_ASSIGNMENTS: 'userAssignments',
  CLASS_ASSIGNMENTS: 'classAssignments'
};
