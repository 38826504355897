import * as t from '../actionTypes';

export const loadEreaderContentRequest = data => ({
  type: t.GET_EREADER_CONTENT_REQUEST,
  payload: data
});

export const loadEreaderContentSuccess = data => ({
  type: t.GET_EREADER_CONTENT_SUCCESS,
  payload: data
});

export const loadEreaderContentFailure = data => ({
  type: t.GET_EREADER_CONTENT_FAILURE,
  payload: data
});
