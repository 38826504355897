export const EVENT_POLLING_START = 'EVENT_POLLING_START';
export const EVENT_POLLING_STOP = 'EVENT_POLLING_STOP';
export const GET_DATA_FAILURE = 'GET_DATA_FAILURE';

const initialState = {
  polling: false,
  pollingFor: null
};

export default function clientPolling(state = initialState, action) {
  switch (action.type) {
    case EVENT_POLLING_START:
      return {
        ...state,
        polling: true,
        pollingFor: action.pollingFor
      };
    case EVENT_POLLING_STOP:
      return {
        ...state,
        polling: false,
        pollingFor: null
      };
    default:
      return state;
  }
}

export const pollStartAction = pollingFor => ({ type: EVENT_POLLING_START, pollingFor });
export const pollStopAction = () => ({ type: EVENT_POLLING_STOP });
export const getDataFailureAction = payload => ({ type: GET_DATA_FAILURE, payload });
