import { put, take } from 'redux-saga/effects';
import {
  showproductUploadLogsData,
  PRODUCT_UPLOAD_LOGS_REQUEST,
  showproductUploadLogsError
} from '../../../../reducers/productUploadLogs.reducer.js';
import productUploadLogsRequest from './productUploadLogsRequest.js';

export default function* productUploadLogsData() {
  console.log('[productUploadLogs Saga] Beginning');

  while (true) {
    yield take(PRODUCT_UPLOAD_LOGS_REQUEST);

    console.log('[productUploadLogs Saga] Start Request');
    const result = yield productUploadLogsRequest();
    const status = result.status ? result.status.toLowerCase() : 'error';

    const failed = !!result.error || status !== 'success';
    const apiError = result.error || status !== 'success' ? result.message : null;

    if (!failed) {
      console.log('[productUploadLogs Saga] Request completed, showing results');
      yield put(showproductUploadLogsData(result.data));
    } else {
      console.log('[productUploadLogs Saga] Request completed, but failed, showing error');
      yield put(showproductUploadLogsError(apiError));
    }
  }
}
