import { select, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { clearProductSelection } from '../../../../../reducers/library/library.reducer';
import {
  REMOVE_LEARNING_MATERIAL,
  RESET_STATE,
  setResultStatus,
  setRemovedCount,
  setReturnedCount,
  setResults,
  showConfirmation
} from '../../../../../reducers/removeLearningMaterial.reducer';
import { pollClassAssignmentsRemoved, pollClassAssignmentsArchived } from '../../dataRecency/pollClassAssignments';
import removeLearningMaterialRequest from './removeLearningMaterialRequest';
import { assignmentContexts } from '../../../../../../globals/assignmentConstants';
import userRoles from '../../../../../../globals/userRoles';

const moduleName = '[removeLearningMaterial Saga]';

function* handleMaterialRemoval() {
  console.log(moduleName, 'Removing learning material...');
  const result = yield removeLearningMaterialRequest();
  console.log(moduleName, 'result', result);

  if (result.error || result.status === 'error') {
    // show error status
    yield put(setResultStatus('ERROR'));
  } else if (result.status === 'success' && result.data) {
    try {
      // determine overall status
      const overallStatus = result.data.failedAssignments.length > 0 ? 'WARNING' : 'SUCCESS';
      yield put(setResultStatus(overallStatus));

      // set total learning material removed
      const totalRemoved = result.data.archivedAssignments.length;
      yield put(setRemovedCount(totalRemoved));

      // calculate and set total licences released to the pool
      const totalReturned = result.data.archivedAssignments.reduce((sum, currentAssignment) => {
        const releasedObj = currentAssignment.activationCodeCountDetails.find(element =>
          Object.keys(element).includes('released')
        );
        return sum + (parseInt(releasedObj.released, 10) || 0);
      }, 0);
      yield put(setReturnedCount(totalReturned));

      // set assignment removal results
      const results = result.data.archivedAssignments.concat(result.data.failedAssignments);
      yield put(setResults(results));

      const assignmentIds = result.data.archivedAssignments.map(archivedAssignment => ({
        licenseId: archivedAssignment.licenseId,
        groupId: archivedAssignment.groupId,
        userId: archivedAssignment.userId
      }));

      const userRole = yield select(state => state.identity.role);

      const { orgId, isClassroom, currentContext, pollOperation } = yield select(state => ({
        orgId: userRole === userRoles.OUP_ADMIN ? state.organisationPage.orgId : state.identity.currentOrganisationId,
        isClassroom: state.removeLearningMaterial.context === assignmentContexts.CLASS,
        currentContext: state.removeLearningMaterial.context,
        pollOperation: state.removeLearningMaterial.pollOperation
      }));

      if (pollOperation === 'remove') {
        yield pollClassAssignmentsRemoved(assignmentIds, { orgId, isClassroom, currentContext });
      } else {
        yield pollClassAssignmentsArchived(assignmentIds, { orgId, isClassroom, currentContext });
      }
    } catch (e) {
      console.log(moduleName, 'caught exception while reading response data, cleaning up...', e);
      yield put(setRemovedCount(null));
      yield put(setReturnedCount(null));
      yield put(setResults([]));
      yield put(setResultStatus('ERROR'));
    }
  }

  yield put(showConfirmation());
}

function* clearSelectedProducts() {
  yield put(clearProductSelection());
}

export default function* removeLearningMaterial() {
  console.log(moduleName, 'Starting');
  yield takeEvery(RESET_STATE, clearSelectedProducts);
  yield takeLatest(REMOVE_LEARNING_MATERIAL, handleMaterialRemoval);
}
