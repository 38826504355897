import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getAdaptedTests from './getAdaptedTests';
import updateAdaptedTest from './updateAdaptedTest';

function* root() {
  yield takeLatest(t.GET_ADAPTED_TESTS_FOR_ASSESSMENT, ({ payload }) => getAdaptedTests(payload));
  yield takeLatest(t.UPDATE_ADAPTED_TEST, ({ payload, adaptedTestId }) => updateAdaptedTest(payload, adaptedTestId));
}

export default root;
