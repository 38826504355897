// Debounce utility function
export default (func, delay = 50) => {
  // Closure scoped timeoutId
  let timeoutId;

  // Note we don't use an arrow function here because it might need "this" context:
  return (...args) => {
    const context = this;

    // Clear any existing queued function
    clearTimeout(timeoutId);

    // Create future function
    const runFunc = () => {
      timeoutId = null;
      func.apply(context, args);
    };

    // Set timeout for future function
    timeoutId = setTimeout(runFunc, delay);
  };
};
