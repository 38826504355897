import * as t from '../actionTypes';

export const appDownloadRequest = (userId, os, arch) => ({
  type: t.ORB_APP_DOWNLOAD_URL_REQUEST,
  payload: { userId, os, arch }
});

export const appDownloadSuccess = payload => ({
  type: t.ORB_APP_DOWNLOAD_URL_SUCCESS,
  payload
});

export const appDownloadFailure = error => ({
  type: t.ORB_APP_DOWNLOAD_URL_FAILURE,
  payload: error
});
