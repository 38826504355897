import { put, select } from 'redux-saga/effects';
import registerManagedUserSchema from '../../../../../../static/schema/draft-4/register-managed-user-request-body.json';
import {
  userEnrolCheckingUserName,
  userEnrolCheckingUserNameCompleted,
  userEnrolValidateInputCompleted
} from '../../../../actions/userEnrol';
import * as t from '../../../../actionTypes';
import checkUsername from '../../../api/checkUsername';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';

function* checkIsUserNameTaken(userNameWithCustomId) {
  let isUserNameTaken = false;

  if (userNameWithCustomId === undefined) {
    isUserNameTaken = yield select(state => state.userEnrol.isUserNameTaken);
  } else {
    yield put(userEnrolCheckingUserName());
    const errors = yield validateInputUsingSchema(registerManagedUserSchema, null, { userName: userNameWithCustomId });
    if (errors.userName === false) {
      const { status, data } = yield checkUsername(userNameWithCustomId);
      isUserNameTaken = status.toLowerCase() === 'success' && data.exists;
    }
    yield put(userEnrolCheckingUserNameCompleted(isUserNameTaken));
  }
  return isUserNameTaken;
}

function* validateUserEnrolInput(orgId, user = {}) {
  const { customId } = yield select(state => state.organisations.data[orgId]);
  const input = {
    ...user,
    ...('userName' in user
      ? {
          // @ts-ignore
          userName: user.userName.length ? `${customId}_${user.userName}` : ''
        }
      : {})
  };
  const errors = yield validateInputUsingSchema(
    registerManagedUserSchema,
    t.USER_ENROL_VALIDATE_INPUT_COMPLETED,
    input
  );
  const isUserNameTaken = yield checkIsUserNameTaken(input.userName);
  yield put(
    userEnrolValidateInputCompleted({
      ...errors,
      ...(isUserNameTaken ? { userName: isUserNameTaken } : {})
    })
  );
  return errors;
}

export default validateUserEnrolInput;
