import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classnames from 'classnames';

import PropTypes from 'prop-types';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../Button/Button';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import styles from './ExportUsers.scss';
import { startDownload } from '../../redux/reducers/downloadStudentData.reducer.js';

class ExportUsers extends Component {
  get _localizedContent() {
    const { localizedContent } = this.props;
    return localizedContent.exportUsers;
  }

  _onDownloadSelect = () => {
    const { startDownloadAction } = this.props;
    startDownloadAction();
  };

  render() {
    const { loading, errorOccurred } = this.props;

    return (
      <div className={classnames(styles.downloadStudentsContainer, 'pad2')}>
        <p className="gin-bot2">
          <b>{this._localizedContent.export_query_text}</b>
        </p>

        {loading ? (
          <PopoutPanelIconHeading type={types.LOADING} title="Loading" />
        ) : (
          <div>
            {errorOccurred ? (
              <p className="gin-bot2">
                <b>{this._localizedContent.something_went_wrong}</b>
              </p>
            ) : null}
            <Button
              type={buttonTypes.PRIMARY}
              text={this._localizedContent.download_student_data}
              onClick={() => this._onDownloadSelect()}
            />
          </div>
        )}
      </div>
    );
  }
}

ExportUsers.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  startDownloadAction: PropTypes.func,
  loading: PropTypes.bool,
  errorOccurred: PropTypes.bool
};

export default compose(
  withLocalizedContent('exportUsers'),
  connect(
    state => ({
      // Connect values
      loading: state.downloadStudentData.loading,
      errorOccurred: state.downloadStudentData.errorOccurred
    }),
    {
      startDownloadAction: startDownload
    }
  )
)(ExportUsers);
