import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import csvUserUpload from './csvUserUpload';
import parseFileData from './parseFileData';

function* root() {
  yield takeLatest(t.CSVUSER_DATA_UPLOAD_PARSE_FILE_REQUEST, ({ payload }) => parseFileData(payload));
  yield takeLatest(t.CSVUSER_DATA_UPLOAD_START_UPLOAD, ({ payload }) => csvUserUpload(payload));
}

export default root;
