import * as t from '../actionTypes';

const initialState = {
  items: [],
  submitting: false,
  success: false,
  error: false
};

function studentProgressItems(state = initialState, { type, payload }) {
  switch (type) {
    case t.STUDENT_PROGRESS_ITEMS_REQUEST:
      return {
        ...state,
        submitting: true,
        success: false,
        error: false
      };
    case t.STUDENT_PROGRESS_ITEMS_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        error: true
      };
    case t.STUDENT_PROGRESS_ITEMS_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: false,
        success: true,
        items: payload
      };
    default:
      return state;
  }
}

export default studentProgressItems;
