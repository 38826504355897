import * as t from '../actionTypes';

export const userEnrolRequest = (orgId, user) => ({
  type: t.USER_ENROL_REQUEST,
  payload: {
    orgId,
    user
  }
});

export const userEnrolAddToClassView = () => ({
  type: t.USER_ENROL_ADD_TO_CLASS_VIEW
});

export const userEnrolAddToClassViewConfirmation = () => ({
  type: t.USER_ENROL_ADD_TO_CLASS_VIEW_CONFIRMATION
});

export const userEnrolSuccess = userId => ({
  type: t.USER_ENROL_SUCCESS,
  payload: userId
});

export const userEnrolFailure = () => ({
  type: t.USER_ENROL_FAILURE
});

export const userEnrolCheckingUserName = () => ({
  type: t.USER_ENROL_CHECKING_USERNAME
});

export const userEnrolCheckingUserNameCompleted = isUserNameTaken => ({
  type: t.USER_ENROL_CHECKING_USERNAME_COMPLETED,
  payload: isUserNameTaken
});

export const userEnrolValidateInput = (orgId, input) => ({
  type: t.USER_ENROL_VALIDATE_INPUT,
  payload: {
    orgId,
    input
  }
});

export const userEnrolValidateInputCompleted = errors => ({
  type: t.USER_ENROL_VALIDATE_INPUT_COMPLETED,
  payload: errors
});

export const userEnrolValidateInputSuccess = () => ({
  type: t.USER_ENROL_VALIDATE_INPUT_SUCCESS
});

export const userEnrolReset = () => ({
  type: t.USER_ENROL_RESET
});

export const userEnrolDownloadSigninCard = () => ({
  type: t.USER_ENROL_DOWNLOAD_SIGN_IN_CARD
});

export const userEnrolDownloadSigninCardSuccess = size => ({
  type: t.USER_ENROL_DOWNLOAD_SIGN_IN_CARD_SUCCESS,
  payload: {
    size
  }
});

export const userEnrolDownloadSigninCardFail = () => ({
  type: t.USER_ENROL_DOWNLOAD_SIGN_IN_CARD_FAIL
});

export const userEnrolSetUserDetails = user => ({
  type: t.USER_ENROL_DOWNLOAD_SIGN_IN_CARD_SET_USER_DETAILS,
  payload: { user }
});
