import { put, take } from 'redux-saga/effects';
import {
  showSuccessMessage,
  PRODUCT_PUBLISH_REQUEST,
  showProductPublishError
} from '../../../../reducers/productPublish.reducer';
import productPublishRequest from './productPublishRequest';

export default function* productPublish() {
  console.log('[productPublish Saga] Beginning');

  while (true) {
    yield take(PRODUCT_PUBLISH_REQUEST);

    console.log('[productPublish Saga] Start Request');
    const result = yield productPublishRequest();
    const status = result.status ? result.status.toLowerCase() : 'error';

    const failed = !!result.error || status !== 'success';
    const apiError = result.error || status !== 'success' ? result.message : null;

    if (!failed) {
      console.log('[productPublish Saga] Request completed, showing results');
      yield put(showSuccessMessage(result.data));
    } else {
      console.log('[productPublish Saga] Request completed, but failed, showing error');
      yield put(showProductPublishError(apiError));
    }
  }
}
