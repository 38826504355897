import PropTypes from 'prop-types';
import React from 'react';
import Button, { buttonTypes, validButtonTypes } from '../Button/Button';

function ArticleLink({ title: text, link_href: href, button_type: buttonType }) {
  return (
    <div className="gin-bot2">
      <Button type={(buttonType || '').toUpperCase() || buttonTypes.PRIMARY} to={href} text={text} />
    </div>
  );
}

ArticleLink.propTypes = {
  title: PropTypes.string.isRequired, // link text
  link_href: PropTypes.string.isRequired,
  button_type: PropTypes.oneOf(validButtonTypes)
};

export default ArticleLink;
