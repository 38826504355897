import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import PieChart from '../PieChart/PieChart';
import styles from './PieChartProgressDetails.scss';
import userRoles, { roleIsAtLeast } from '../../globals/userRoles';

function PieChartProgressDetails({
  completed: completedRaw,
  total: totalRaw,
  usePercentages,
  content = {},
  customClassName = '',
  displayShortLabelOnMobile = false,
  role
}) {
  const percent = !!completedRaw && !!totalRaw ? (completedRaw / totalRaw) * 100 : 0;
  const completed = Math.round(completedRaw);
  const total = Math.round(totalRaw);
  return (
    <div className={`${styles.progressContainer} ${customClassName}`}>
      <div className={styles.pieChartContainer}>
        <PieChart percentage={percent} />
      </div>

      <div className={styles.progressText}>
        <span>
          {!total ? (
            content?.no_data_available || '0.0%'
          ) : (
            <span>{usePercentages ? `${numeral(completed / total).format('0.0%')}` : `${completed}/${total}`}</span>
          )}
        </span>
        {displayShortLabelOnMobile && (
          <p className={styles.shortLabel}>
            {' '}
            {roleIsAtLeast(userRoles.LEARNER, role)
              ? content.completionShort
              : content.averageCompletionShortLabel}{' '}
          </p>
        )}
      </div>
    </div>
  );
}

PieChartProgressDetails.propTypes = {
  completed: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  usePercentages: PropTypes.bool,
  content: PropTypes.object,
  customClassName: PropTypes.string,
  displayShortLabelOnMobile: PropTypes.bool,
  role: PropTypes.string
};

export default PieChartProgressDetails;
