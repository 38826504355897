/** Edit User reducer
 *  This handles the form for editing a user.
 *  Note the form is different depending on the user's role.
 */

// import sortBy from '../../../utils/array/sortBy.js';
import * as t from '../../actionTypes';

export const GET_ARTICLES = 'supportSearch/GET_ARTICLES';

export const formStates = {
  INPUTTING: 'INPUTTING',
  SUBMITTING: 'SUBMITTING'
};

const initialState = {
  formState: formStates.INPUTTING,
  searchInputValue: '',
  searchResultsValue: []
};

function searchify(text) {
  if (typeof text !== 'string') {
    return '';
  }
  return text.toLowerCase().replace(/\s+/g, ' ');
}

function byKeyword(keyword) {
  return article => article.keywords && searchify(article.keywords).indexOf(searchify(keyword)) >= 0;
}

// function byContent(term) {
//   return article => searchify(`${article.title} ${article.summary} ${article.content}`).indexOf(searchify(term)) >= 0;
// }

// function byFeatured() {
//   return article => article.isFeatured;
// }

export default function supportSearch(state = initialState, action = {}) {
  switch (action.type) {
    // Get articles to show before user performs a search:
    // The saga listens for GET_ARTICLES and fetches the articles or returns cached ones from state
    case t.GET_ARTICLES:
      return {
        formState: formStates.SUBMITTING
      };

    case t.RESET_SEARCH_FORM: {
      const articles = action.articles || state.articles;
      const searchResultsValue = articles && Object.values(articles).sort();

      return {
        ...initialState,
        articles,
        searchResultsValue
      };
    }

    case t.SET_SEARCH_TERM: {
      const searchInputValue = action.value.trim();
      const { articles, searchResultsValue } = state;

      return {
        ...state,
        searchInputValue,
        // Revert to "featured" articles when searchInputValue is blank:
        searchResultsValue: searchInputValue ? searchResultsValue : Object.values(articles).sort()
      };
    }

    case t.SUBMIT_FORM: {
      return {
        ...state,
        searchResultsValue:
          state.articles &&
          (state.searchInputValue
            ? // Search articles by keyword:
              Object.values(state.articles)
                .filter(byKeyword(state.searchInputValue))
                .sort()
            : // Revert to "featured" articles when searchInputValue is blank:
              Object.values(state.articles).sort())
      };
    }

    case t.SET_SEARCH_RESULTS: {
      return {
        ...state,
        formState: formStates.COMPLETE,
        searchResultsValue: action.value
      };
    }

    default:
      return state;
  }
}

export const setSearchTerm = value => ({ type: t.SET_SEARCH_TERM, value });
export const setSearchResults = (value, errorOccurred) => ({
  type: t.SET_SEARCH_RESULTS,
  value,
  errorOccurred
});

// Form state actions
export const initForm = () => ({
  type: t.GET_ARTICLES
});

export const submitForm = () => ({
  type: t.SUBMIT_FORM
});

export const resetForm = articles => ({
  type: t.RESET_SEARCH_FORM,
  articles
});
