import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import CoursePreview from './CoursePreview';
import { closeCoursePreviewModal } from '../../../../redux/actions/coursePreview';

function CoursePreviewPage({ localizedContent: { coursePreviewPage: content }, closeCoursePreviewModalAction }) {
  useEffect(() => {
    closeCoursePreviewModalAction();
  }, []);
  return (
    <PageWrapper>
      <Helmet title={content.helmet_title} />
      <CoursePreview />
    </PageWrapper>
  );
}

CoursePreviewPage.propTypes = {
  localizedContent: PropTypes.object,
  closeCoursePreviewModalAction: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  closeCoursePreviewModalAction: closeCoursePreviewModal
};

export default compose(
  withLocalizedContent('coursePreviewPage'),
  connect(mapStateToProps, mapDispatchToProps)
)(CoursePreviewPage);
