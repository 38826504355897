import * as t from '../actionTypes';

export const initialState = {
  contentAlreadyExists: null,
  error: null,
  formData: {},
  selectedPlatformCode: ''
};

export default function productUploadCheckLogs(state = initialState, action = {}) {
  switch (action.type) {
    case t.CHECK_PRODUCT_EXISTS_REQUEST:
      return {
        ...state,
        formData: action.formData,
        selectedPlatformCode: action.selectedPlatformCode
      };
    case t.CHECK_PRODUCT_EXISTS_SUCCESS:
      return {
        ...state,
        contentAlreadyExists: !action.payload.isFirstTimeUploaded
      };
    case t.CHECK_PRODUCT_EXISTS_FAILURE:
      return {
        ...state,
        contentAlreadyExists: null,
        error: action.payload
      };
    case t.CHECK_PRODUCT_EXISTS_CLEAR:
      return {
        ...state,
        contentAlreadyExists: null
      };
    default:
      return state;
  }
}
