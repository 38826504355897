// Helper to return an array of child elements that can receive focus.
// Useful for locating, say, the first field in a form or popup.
// Optionally prevent a specific element or array of elements from being filtered out.
// Note: The include arg only works on elements that have a unique ID.

import isFocusable, { focusables } from './isFocusable.js';

const byHasId = elem => elem.id;
const toIdSelectors = elem => `#${elem.id}`;

export default function querySelectorAllFocusable(container = document, include) {
  const includes = (Array.isArray(include) ? include : (include && [include]) || []).filter(byHasId);
  const includables = includes.length ? `,${includes.map(toIdSelectors).join(',')}` : '';
  const focusable = !include ? isFocusable : elem => includes.includes(elem) || isFocusable(elem);

  return container ? Array.from(container.querySelectorAll(focusables + includables)).filter(focusable) : [];
}
