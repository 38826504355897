import { omit, pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import UserRoles from '../../globals/userRoles';
import withLocalizedContent from '../../language/withLocalizedContent';
import { toInitials } from '../../utils/string';
import ActionList, { Action } from '../ActionList/ActionList';
import ControlledForm from '../ControlledForm/ControlledForm';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import UserFormBody from '../UserFormBody/UserFormBody';
import styles from './OrgUserEditForm.scss';
import { validationStatusCodes } from '../ValidationStatus/ValidationStatus';

function OrgUserEditForm(props) {
  const {
    localizedContent: { orgUserEditFormComponent: content },
    firstName,
    lastName,
    userName,
    email,
    formInputChanges = {},
    formInputErrors = {},
    isCheckingUserNameTaken = false,
    isCheckingEmailTaken = false,
    sendPasswordResetEmail,
    archive,
    edit,
    canRemoveUser,
    isAdminOrSupport,
    isLastTeacherInClass = false,
    roleName,
    hidden
  } = props;
  const currentUserName = formInputChanges.userName || userName;
  const isOrgUserOup = [UserRoles.OUP_ADMIN, UserRoles.OUP_SUPPORT].includes(formInputChanges.roleName || roleName);
  const isOupUserEmailInvalid = isOrgUserOup ? !currentUserName.endsWith('@oup.com') : false;
  const hiddenVal = [
    ...(hidden || []),
    'oldPassword',
    'password',
    'newPassword',
    'confirmPassword',
    'yearGroup',
    'social',
    'social-unlink-confirm'
  ];
  const errorValues = Object.values(omit(formInputErrors, hiddenVal));
  const disabled =
    isCheckingUserNameTaken ||
    isCheckingEmailTaken ||
    !errorValues.length ||
    errorValues.some(Boolean) ||
    isOupUserEmailInvalid;

  return (
    <ScrollContainer
      headerContent={
        <header className={styles.header}>
          <div className={styles.initials}>{toInitials(firstName, lastName)}</div>
          <div>
            <h2 className={styles.heading}>{`${firstName} ${lastName}`}</h2>
            <div className={styles.userName}>{userName}</div>
          </div>
        </header>
      }
      footerContent={
        <ActionList>
          <Action label={content.send_password_reset_button} onClick={sendPasswordResetEmail} />
          {canRemoveUser && <Action label={content.archive_user_button} onClick={archive} />}
          <Action label={content.submit_button} primary disabled={disabled} onClick={edit} />
        </ActionList>
      }
    >
      <ControlledForm className="pad2">
        <UserFormBody
          {...pick(props, [
            'roles',
            'firstName',
            'displayName',
            'lastName',
            'userName',
            'email',
            'roleName',
            'formInputErrors',
            'isCheckingUserNameTaken',
            'isUserNameTaken',
            'isCheckingEmailTaken',
            'isEmailTaken',
            'isEmailUsingUserName',
            'onChange',
            'onBlur',
            'isOupRole'
          ])}
          isOupUserEmailInvalid={isOupUserEmailInvalid}
          {...formInputChanges}
          origUserName={userName}
          origEmail={email}
          localizedContent={content}
          hidden={hiddenVal}
          validationStatuses={
            isLastTeacherInClass && !formInputErrors.roleName
              ? {
                  roleName: {
                    isActive: true,
                    message: content.last_teacher_in_class_warning,
                    type: validationStatusCodes.WARNING_STATUS
                  }
                }
              : null
          }
          showAppropriatePermissionsNotice={isAdminOrSupport}
        />
      </ControlledForm>
    </ScrollContainer>
  );
}

OrgUserEditForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  roleName: PropTypes.string.isRequired,
  hidden: PropTypes.arrayOf(PropTypes.string),
  formInputChanges: PropTypes.object,
  formInputErrors: PropTypes.object,
  isCheckingUserNameTaken: PropTypes.bool,
  isUserNameTaken: PropTypes.bool,
  isCheckingEmailTaken: PropTypes.bool,
  isEmailTaken: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  sendPasswordResetEmail: PropTypes.func.isRequired,
  archive: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  canRemoveUser: PropTypes.bool.isRequired,
  isAdminOrSupport: PropTypes.bool.isRequired,
  displayName: PropTypes.string.isRequired,
  isOupRole: PropTypes.bool,
  isLastTeacherInClass: PropTypes.bool
};

export default withLocalizedContent('orgUserEditFormComponent')(OrgUserEditForm);
