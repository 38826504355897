import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Calendar.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

function Calendar({ onDateSelectedHandler, selectedDate, availableCellsOnCalendar, title }) {
  const [displayedMonth, setDisplayedMonth] = useState(selectedDate);
  const {
    calendarWrapper,
    calendarHeader,
    calendarBody,
    calendarRow,
    calendarRowMiddle,
    calendarCol,
    calendarColStart,
    calendarColCenter,
    calendarColEnd,
    calendarIcon,
    calendarWeekDays,
    calendarCell,
    calendarSelectedDay,
    calendarGrayText,
    calendarActionArrow
  } = styles;

  const daysOfWeek = () => {
    let day = new Date(1970, 1, 2);
    const dayOfWeekList = [];

    for (let dayIterator = 0; dayIterator < 7; dayIterator += 1) {
      dayOfWeekList.push(day.toLocaleString(window.navigator.language, { weekday: 'narrow' }));
      day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1);
    }

    return dayOfWeekList;
  };

  const generateDaysArray = date => {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dayOnWhichMonthstarts = startOfMonth.getDay() === 0 ? 7 : startOfMonth.getDay();
    const noOfDaysDisplayedFromPrevMonth = dayOnWhichMonthstarts - 1;
    const days = [];

    for (let prevMonthIndex = dayOnWhichMonthstarts - 2; prevMonthIndex >= 0; prevMonthIndex -= 1) {
      days.push(new Date(startOfMonth.getFullYear(), startOfMonth.getMonth(), -prevMonthIndex));
    }

    for (
      let currentMonthIndex = 1;
      currentMonthIndex <= availableCellsOnCalendar - noOfDaysDisplayedFromPrevMonth;
      currentMonthIndex += 1
    ) {
      days.push(new Date(startOfMonth.getFullYear(), startOfMonth.getMonth(), currentMonthIndex));
    }
    return days;
  };

  const selectDayHandler = day => {
    setDisplayedMonth(day);
    onDateSelectedHandler(day);
  };

  const prevMonthHandler = () => {
    setDisplayedMonth(new Date(displayedMonth.getFullYear(), displayedMonth.getMonth() - 1, 1));
  };
  const nextMonthHandler = () => {
    setDisplayedMonth(new Date(displayedMonth.getFullYear(), displayedMonth.getMonth() + 1, 1));
  };

  useEffect(() => {
    setDisplayedMonth(selectedDate);
  }, [selectedDate]);

  const renderDayShortNames = () =>
    daysOfWeek().map((day, index) => (
      <div key={index} className={`${calendarCol} ${calendarColCenter}`}>
        {day}
      </div>
    ));

  const renderDays = () =>
    generateDaysArray(displayedMonth).map((day, index) => {
      const isSelectedDay =
        selectedDate.getMonth() === day.getMonth() &&
        selectedDate.getDate() === day.getDate() &&
        selectedDate.getFullYear() === day.getFullYear();

      const dayClass = classnames(calendarCell, {
        [`${calendarGrayText}`]: day.getMonth() !== displayedMonth.getMonth(),
        [`${calendarSelectedDay}`]: isSelectedDay
      });

      return (
        <div
          role="button"
          tabIndex={0}
          aria-hidden
          key={index}
          className={dayClass}
          onClick={() => selectDayHandler(day)}
        >
          {day.getDate()}
        </div>
      );
    });

  return (
    <>
      {title && <h2 data-testid="CALENDAR_HEADING">{title}</h2>}
      <div className={calendarWrapper}>
        <div className={`${calendarHeader} ${calendarRow} ${calendarRowMiddle}`}>
          <div className={`${calendarCol} ${calendarColStart}`}>
            <div
              id="arrowLeft"
              role="button"
              tabIndex={0}
              aria-hidden
              className={calendarIcon}
              onClick={() => prevMonthHandler()}
            >
              <SVGIcon className={calendarActionArrow} glyph={GLYPHS.ICON_LEFT} fill="#5c5c5c" />
            </div>
          </div>
          <div className={`${calendarCol} ${calendarColCenter}`}>
            <span>{`${displayedMonth.toLocaleString(window.navigator.language, {
              month: 'long'
            })} ${displayedMonth.getFullYear()}`}</span>
          </div>
          <div className={`${calendarCol} ${calendarColEnd}`}>
            <div
              id="arrowRight"
              role="button"
              tabIndex={0}
              aria-hidden
              className={calendarIcon}
              onClick={() => nextMonthHandler()}
            >
              <SVGIcon className={calendarActionArrow} glyph={GLYPHS.ICON_RIGHT} fill="#5c5c5c" />
            </div>
          </div>
        </div>
        <div className={`${calendarWeekDays} ${calendarRow}`}>{renderDayShortNames()}</div>
        <div className={calendarBody}>{renderDays()}</div>
      </div>
    </>
  );
}

Calendar.propTypes = {
  onDateSelectedHandler: PropTypes.func,
  availableCellsOnCalendar: PropTypes.number,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  title: PropTypes.string
};

Calendar.defaultProps = {
  onDateSelectedHandler: () => {},
  availableCellsOnCalendar: 42
};

export default Calendar;
