import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import colors from '../../globals/colors.js';
import UrlControlledTabs from '../../components/UrlControlledTabs/UrlControlledTabs.js';

// Tab contents:
import ENTITY_TYPES from '../../globals/entityTypes';
import EntityPageHeader from '../../components/EntityPageHeader/EntityPageHeader.js';
import ExampleTabPage from './ExampleTab/ExampleTabPage.js';

import cmsContent from '../../utils/cmsContent.js';

function SupportHomePage() {
  // const panelName = params.panelName;
  const CMS = cmsContent.examplePage || {};
  const params = useParams();
  const location = useLocation();

  return (
    <div style={{ padding: '0 0 4rem' }}>
      <Helmet title={CMS.document_head_title_text} />
      <EntityPageHeader entityType={ENTITY_TYPES.SUPPORT} entityTitle={CMS.pageTitle} />

      <UrlControlledTabs
        tabName={params.tabName}
        pathname={location.pathname}
        backgroundColor={colors.SUPPORT}
        items={[
          // {
          //   urlName: 'index',
          //   tabText: CMS.support_main_help_tab_text,
          //   color: colors.LEARNERS,
          //   panelContent: <SupportSearchPage pathname={location.pathname} panelName={panelName} />
          // },
          {
            urlName: 'contact-us',
            tabText: CMS.contact_us_tab_text,
            color: colors.LEARNERS,
            panelContent: <ExampleTabPage />
          }
        ]}
      />
    </div>
  );
}

SupportHomePage.propTypes = {
  // formState: PropTypes.oneOf(Object.values(formStates))
};

export default connect(
  // Destructure only the bits we need from the global state:
  () => ({
    // formState: state.formState
  }),
  {}
)(SupportHomePage);
