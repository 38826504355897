import { createSelector } from 'reselect';

const getAssignedMaterialsInCurrentOrg = createSelector(
  state => state.search.userAssignments.data,
  results =>
    Object.entries(results).reduce(
      (acc, [id, product]) => ({
        ...acc,
        [id]: ((product && product.learningAssignments) || []).filter(
          ({ activationCode, groupDetails }) => groupDetails && groupDetails.currentOrg && activationCode
        )
      }),
      {}
    )
);

export default getAssignedMaterialsInCurrentOrg;
