import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PopupOverlay from '../../PopupOverlay/PopupOverlay';
import ButtonMenuItem from '../ButtonMenuItem/ButtonMenuItem';
import LinkMenuItem from '../LinkMenuItem/LinkMenuItem';
import DynamicTitleMenuItem from '../DynamicTitleMenuItem/DynamicTitleMenuItem';
import styles from './DropdownPanel.scss';

function DropdownPanel({
  menuItems,
  onToggleDropdown,
  toggled,
  menuButtonId,
  menuPanelId,
  menuPanelAriaLabel,
  dropdownTop,
  panelCustomClassname = '',
  isAssessmentTestComponent = false,
  isPlacementTests
}) {
  const getMenuItem = menuItem => {
    if (menuItem.to) {
      return <LinkMenuItem menuItem={menuItem} />;
    }
    if (menuItem.label) {
      return <DynamicTitleMenuItem menuItem={menuItem} />;
    }
    return <ButtonMenuItem menuItem={menuItem} toggled={toggled} />;
  };

  return (
    <PopupOverlay
      id={menuPanelId}
      visible={toggled}
      customClassName={classnames([styles.dropdownPanel], {
        [styles.opened]: toggled,
        [styles.dropdownTop]: dropdownTop && !isAssessmentTestComponent && !isPlacementTests,
        [styles.dropdownTopClassAssessmentTests]: dropdownTop && isAssessmentTestComponent && !isPlacementTests,
        [styles.dropdownTopPlacementTests]: dropdownTop && !isAssessmentTestComponent && isPlacementTests
      })}
      onTogglePopup={onToggleDropdown}
      aria={{
        label: menuPanelAriaLabel,
        hidden: !toggled
      }}
      buttonElementId={menuButtonId}
    >
      <ul className={`${styles.panelContainer} ${panelCustomClassname}`}>
        {menuItems.map(
          menuItem =>
            menuItem.title && (
              <li
                className={`${styles.menuItem} ${menuItem.subtitle ? styles.menuItemWithSubtitle : ''}`}
                key={menuItem.title}
                aria-label={menuItem.title}
              >
                {getMenuItem(menuItem)}
              </li>
            )
        )}
      </ul>
    </PopupOverlay>
  );
}

export default DropdownPanel;

DropdownPanel.propTypes = {
  menuItems: PropTypes.array.isRequired,
  onToggleDropdown: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired,
  menuButtonId: PropTypes.string.isRequired,
  menuPanelId: PropTypes.string.isRequired,
  menuPanelAriaLabel: PropTypes.string.isRequired,
  dropdownTop: PropTypes.bool,
  panelCustomClassname: PropTypes.string,
  isAssessmentTestComponent: PropTypes.bool,
  isPlacementTests: PropTypes.bool
};
