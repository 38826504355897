import signedFetch from '../util/signedFetch.js';

export default ({ orgId, status, sortBy }) => {
  const queryParams = new URLSearchParams();

  if (status) queryParams.set('status', status);
  if (sortBy) queryParams.set('sortBy', sortBy);

  const queryString = queryParams.toString();
  const URL = `${__API_BASE__}/org/${orgId}/placementTest${queryString ? `?${queryString}` : ''}`;

  return signedFetch('loadPlacementTests', URL, 'GET');
};
