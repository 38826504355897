import React from 'react';
import PropTypes from 'prop-types';
import DetailReportGrid from '../DetailReportGrid/DetailReportGrid';
import withLocalizedContent from '../../language/withLocalizedContent';

function EngagementDetails({
  localizedContent: { engagementDetailsComponent: content },
  forceRowDisplay = false,
  lastLogin,
  bookReadAtCurrentLevel,
  bookReadThisYear
}) {
  return (
    <DetailReportGrid
      forceRowDisplay={forceRowDisplay}
      gridData={[
        {
          header: content.last_login,
          content: lastLogin
        },
        {
          header: content.books_read_at_current_level,
          content: bookReadAtCurrentLevel
        },
        {
          header: content.books_read_this_year,
          content: bookReadThisYear
        }
      ]}
    />
  );
}

EngagementDetails.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  forceRowDisplay: PropTypes.bool,
  lastLogin: PropTypes.node.isRequired,
  bookReadAtCurrentLevel: PropTypes.number.isRequired,
  bookReadThisYear: PropTypes.number.isRequired
};

export default withLocalizedContent('engagementDetailsComponent')(EngagementDetails);
