import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import searchSubscriptionsApi from '../../../apiCalls/search/searchSubscriptions.api';

function* getOrgProducts(orgId) {
  const subscriptionsResult = yield searchSubscriptionsApi({
    filters: {
      orgId,
      active: true,
      notStarted: true,
      expired: true,
      expiring: true
    },
    term: '',
    size: 10000, // max AWS cloud search size limit
    start: 0
  });

  if (subscriptionsResult.status === 'success') {
    const { subscriptions } = subscriptionsResult.data;
    // get the list of product ids
    const productsAdded = Object.values(subscriptions).map(item => item.productId);

    yield put(actions.getOrgProductsSuccess(productsAdded));
  }
}

export default getOrgProducts;
