import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import SearchListBox from '../../../components/SearchListBox/SearchListBox';

const PanelContent = ({ tabName, revealMore, listData, children }) => (
  <div className="grid">
    {listData ? (
      <AnimateHeight duration={500} height={revealMore ? 'auto' : 0}>
        <div className="gin-top3">
          <SearchListBox placeholder={`${upperFirst(tabName)} search`} items={listData} autoFocus={revealMore} />
        </div>
      </AnimateHeight>
    ) : null}
    <div>{children}</div>
  </div>
);

PanelContent.propTypes = {
  tabName: PropTypes.string,
  revealMore: PropTypes.bool,
  listData: PropTypes.array,
  children: PropTypes.node
};

export default PanelContent;
