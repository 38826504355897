import { put, select } from 'redux-saga/effects';

import { setFormResult } from '../../../../reducers/data/forms.reducer.js';
import orgEditApi from '../../../apiCalls/orgs/orgEdit.api.js';
import { getFieldValue, requestFailed } from '../formUtil.js';

// Persist actions after successful edit
import { updateOrganisation } from '../../../../reducers/data/organisations.reducer.js';

export default function* submitOrgEdit() {
  const { orgId, ogName, name, role, primaryEmail, webAddress } = yield select(state => ({
    // Context IDs:
    orgId: state.organisationPage.orgId,

    // Original name for confirmation page
    ogName: state.organisations.data[state.organisationPage.orgId].name,

    // Details
    name: getFieldValue(state, 'orgEdit', 'orgName'),
    role: getFieldValue(state, 'orgEdit', 'orgRole'),
    primaryEmail: getFieldValue(state, 'orgEdit', 'orgEmail'),
    webAddress: getFieldValue(state, 'orgEdit', 'orgWebsite')
  }));

  const apiResult = yield orgEditApi(orgId, { name, role, primaryEmail, webAddress });

  const failed = requestFailed(apiResult);

  yield put(setFormResult('orgEdit', 'CONFIRMATION', failed, { ...apiResult.data, ogName }));

  if (!failed) {
    yield put(updateOrganisation(orgId, name, role, primaryEmail, webAddress));
  }
}
