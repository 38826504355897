import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './ScrollContainer.scss';
import addToLibraryConfirmationStyles from '../../routes/MySchool/tabs/LibraryTab/panels/AddToLibrary/AddToLibraryConfirmation.scss';
import { isHubMode } from '../../utils/platform';

function ScrollContainer({
  headerContent,
  footerContent,
  children,
  modalFooter,
  formState = '',
  enhancedHeader = false,
  containerClassName,
  headerClassName
}) {
  return (
    <div
      className={classnames(styles.scrollContainer, modalFooter && styles.modalFooter, {
        [addToLibraryConfirmationStyles.hubScrollContainer]: isHubMode()
      })}
    >
      {headerContent && (
        <div
          className={classnames(
            enhancedHeader ? styles.enhancedHeader : styles.header,
            {
              [styles.hubHeaderConfirmation]: isHubMode() && formState === 'CONFIRMATION'
            },
            {
              [headerClassName]: !!headerClassName
            }
          )}
        >
          {headerContent}
        </div>
      )}
      <div
        id="scrollContainerViewport"
        role="tabpanel"
        aria-label="Scrollable Content"
        tabIndex="0"
        className={classnames(
          isHubMode() ? addToLibraryConfirmationStyles.children : styles.children,
          containerClassName
        )}
      >
        {children}
      </div>
      {footerContent && (
        <div className={isHubMode() ? [addToLibraryConfirmationStyles.footer] : [styles.footer]}>{footerContent}</div>
      )}
    </div>
  );
}

ScrollContainer.propTypes = {
  /** Content to be displayed before the scrollable area. Can be an element or list of elements */
  headerContent: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  /** Content to be displayed after the scrollable area */
  footerContent: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  /** Content to be displayed in the scrollable area */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  /** Set to true to mask header & children with an overlay to make only the footer interactive  */
  modalFooter: PropTypes.bool,
  formState: PropTypes.string,
  enhancedHeader: PropTypes.bool,
  containerClassName: PropTypes.string,
  headerClassName: PropTypes.string
};

export default ScrollContainer;
