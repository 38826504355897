import { createSelector } from 'reselect';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';

export default createSelector(
  state => state.importUsers.users,
  state => state.importUsers.existing,
  state => state.importUsers.duplicates,
  state => state.importUsers.archived,
  state => state.importUsers.errors,
  (users, existing, duplicates, archived, errors) =>
    Object.keys(users).reduce((carry, id) => {
      const exists = existing.includes(id);
      const duplicate = duplicates.includes(id);
      const archive = archived.includes(id);
      const isError = Object.values(errors[id] || {}).some(Boolean);
      let status;
      switch (true) {
        case isError:
          status = {
            code: 2,
            message: 'Error(s) found',
            icon: GLYPHS.ICON_ERROR_CIRCLE
          };
          break;
        case duplicate:
          status = {
            code: 1,
            message: 'May be a duplicate',
            icon: GLYPHS.ICON_WARNING_CIRCLE
          };
          break;
        default:
          status = {
            code: 0,
            message: 'Valid user data',
            icon: GLYPHS.ICON_CHECK_CIRCLE
          };
          break;
      }
      return {
        ...carry,
        [id]: {
          ...users[id],
          exists,
          duplicate,
          archive,
          errors: {
            firstName: null,
            lastName: null,
            userName: null,
            password: null,
            class: null,
            yearGroup: null,
            ...(errors[id] || {})
          },
          status
        }
      };
    }, {})
);
