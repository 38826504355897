import { put } from 'redux-saga/effects';
import { updateRecentlyAccessedSuccess, updateRecentlyAccessedError } from '../../../../actions/recentlyAccessed';
import { updateRecentlyAccessedRequest } from '../../../apiCalls/product/recentlyAccessedApi';

function* updateRecentlyAccessed(payload) {
  const response = yield updateRecentlyAccessedRequest(payload);

  if (response.status === 'success') {
    yield put(updateRecentlyAccessedSuccess());
  } else {
    yield put(updateRecentlyAccessedError('Failed to update recently accessed'));
  }
}

export default updateRecentlyAccessed;
