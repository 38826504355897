import * as t from '../../actionTypes';
// Constants

const initialState = {
  page: 1,
  startSlice: 0,
  endSlice: 10
};

function hubPeople(state = initialState, { type, page }) {
  switch (type) {
    case t.HUB_HANDLE_PEOPLE_PAGE_SELECTION:
      if (page === 1) {
        return {
          ...initialState,
          page
        };
      }

      return {
        ...state,
        page,
        startSlice: page * 10 - 10,
        endSlice: page * 10
      };
    case t.HUB_RESET_PAGINATION:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default hubPeople;
