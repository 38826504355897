import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Link from '../Link/Link';
import SVGIcon, { validGlyphs } from '../SVGIcon/SVGIcon';
import styles from './LinkWithIcon.scss';

/** This component renders a text link with an icon next to it. This is either a button of React Router Link depending on the props */
function LinkWithIcon({
  id,
  text,
  glyph,
  to,
  externalLink,
  action,
  customClassName,
  isLhs,
  disabled,
  tagOverride,
  subtext
}) {
  // If the "to" prop was passed then render a Link, else button
  const Tag = tagOverride || (to ? Link : 'button');

  return (
    <Tag
      id={id}
      className={classnames(styles.linkWithIcon, customClassName)}
      to={to}
      onClick={action}
      type={to ? null : 'button'}
      target={externalLink ? '_blank' : null}
      tabIndex={disabled ? '-1' : undefined}
      disabled={disabled}
    >
      {isLhs && glyph && <SVGIcon className={classnames(styles.icon, styles.iconLeft)} glyph={glyph} />}
      <span className={styles.text}>
        {text}
        {subtext && <small>{subtext}</small>}
      </span>
      {!isLhs && glyph && (
        <SVGIcon className={classnames(styles.icon, styles.iconLeft, styles.iconRight)} glyph={glyph} />
      )}
    </Tag>
  );
}

LinkWithIcon.propTypes = {
  /** Id for debug purposes */
  id: PropTypes.string,
  /** The text within the LinkWithIcon */
  text: PropTypes.string.isRequired,
  /** The glyph within the LinkWithIcon */
  glyph: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(validGlyphs)]).isRequired,
  /** to property */
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object
    })
  ]),
  externalLink: PropTypes.bool,
  /** The function to be called */
  action: PropTypes.func,
  /** Custom classnames within the LinkWithIcon */
  customClassName: PropTypes.string,
  /** left hand side or right hand side buttons */
  isLhs: PropTypes.bool,
  /** Set to true to make link or button un-tabbable */
  disabled: PropTypes.bool,
  /** Override to make link not a link - just styling */
  tagOverride: PropTypes.string,
  subtext: PropTypes.string
};

export default LinkWithIcon;
