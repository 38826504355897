import * as t from '../actionTypes';

export const retrieveLTIConsumerRequest = (content) => ({
  type: t.NGS_RETRIEVE_LTI_CONSUMER_REQUEST,
  payload: { content }
});

export const retrieveLTIConsumerSuccess = payload => ({
  type: t.NGS_RETRIEVE_LTI_CONSUMER_SUCCESS,
  payload
});

export const retrieveLTIConsumerFailure = payload => ({
  type: t.NGS_RETRIEVE_LTI_CONSUMER_FAILURE,
  payload
});
