import React from 'react';
import { parse as parseQueryString } from 'query-string';
import { getAbsoluteAndRelativeDateStrings } from '@oup/shared-node-browser/dateTime';
import PropTypes from 'prop-types';
import { getEpsAdminDashboardBaseUrl } from '../../globals/envSettings';

// Styles
import styles from './LaunchTokenLogoutPage.scss';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';

const typeToIconMap = {
  SIGNED: GLYPHS.ICON_LOCKED,
  SESSION: GLYPHS.ICON_TIMED_OUT
};

function SessionExpiredPanel({ type, title, subtitle, text }) {
  return (
    <div className={styles.popoutPanelIconHeading}>
      <SVGIcon glyph={typeToIconMap[type]} />
      <div id="panelHeading" className={styles.panelHeading}>
        <h2>{title}</h2>
        <p>{subtitle}</p>
        <p className={styles.subText}>{text}</p>
      </div>
    </div>
  );
}

function LaunchTokenLogoutPage() {
  const queryStringAsObject = parseQueryString(window.location.search);
  const ltiToken = localStorage.getItem('lti-token');
  const impersonationToken = localStorage.getItem('impersonation-token');

  const token = ltiToken || impersonationToken;
  // const ltiTokenReflectsQuery = token === queryStringAsObject['token-status'];

  window.history.pushState({}, '', '?token-status=notified');

  let info;
  let title;
  let type = 'SIGNED';
  const text = 'Please close this window when ready.';
  const subtitle = 'Sign in again from your LMS.';

  function clearTokensAndRedirectToAdmin() {
    const adminBaseUrl = getEpsAdminDashboardBaseUrl();
    const adminLink = `${adminBaseUrl}/dashboard/user/impersonate`;
    localStorage.removeItem('user-info');
    localStorage.removeItem('impersonation-token');
    localStorage.removeItem('lti-token');
    window.location.href = adminLink;
  }

  if (token === 'no-session-found') {
    info = `You do not seem to be logged in`;
    title = 'You are not logged in.';
  } else if (token === 'terminated') {
    const timeTerminated = localStorage.getItem('lti-token-last-terminated');
    const { relative } = getAbsoluteAndRelativeDateStrings(timeTerminated);
    info = `You logged out ${timeTerminated ? `${relative}` : ''}`;
    title = 'You signed out.';
  } else {
    info = `Your session expired ${queryStringAsObject.phrasing || ''}`;
    type = 'SESSION';
    title = 'Session timed out.';
  }

  if (impersonationToken) {
    // actively remove the previous token now we have notified
    // or if they visit Hub/CES again the old token may block SSO access
    // (in the case of LTI this is fine because they have to use LTI tokens not SSO)
    return (
      <div className={styles.expiry}>
        <p>{info}</p>
        <p>
          Go to{' '}
          {/* On click through, clear the impersonation token - this is a
          hacky way to achieve this but otherwise we end up in a loop where we cannot
          know when to show expiry once and once only. */}
          <a href="#" onClick={clearTokensAndRedirectToAdmin}>
            Support Dashboard
          </a>{' '}
          to start another session.
        </p>
        <p>You are logged out of a previous impersonation session.</p>
        <p>You may still be logged in to your own OUP Support account.</p>
      </div>
    );
  }
  return (
    <div>
      <SessionExpiredPanel type={type} title={title} subtitle={subtitle} text={text} />;
    </div>
  );
}

SessionExpiredPanel.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string
};

export default LaunchTokenLogoutPage;
