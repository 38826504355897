import signedFetch from '../util/signedFetch.js';

export default (orgId, className) =>
  signedFetch(
    'checkClassName',
    `${__API_BASE__}/org/${encodeURIComponent(orgId)}/class/check-name?name=${encodeURIComponent(
      className
        .trim()
        .replace(/\\/g, '\\\\')
        .replace(/\s+/g, ' ')
    )}`,
    'GET'
  );
