import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { validColors } from '../../globals/colors';
import { bdClass, bgClass } from '../../utils/colorClassNames';
import styles from './Card.scss';

/** The component renders a white box with a colored top border and heading label. Contains generic content. */
function Card({ className, headingText, headingColor, headingTag, showHeadingLabel = true, children }) {
  const HeadingTag = headingTag || 'span';
  const borderClassName = bdClass(headingColor);
  const lablelBackgroundClassName = bgClass(headingColor);

  return (
    <section className={classnames(className, styles.card, borderClassName)}>
      {showHeadingLabel && (
        <div className={classnames(styles.headingLabel, lablelBackgroundClassName)}>
          <div className={classnames(styles.roundOff, lablelBackgroundClassName)} />
          <HeadingTag className={styles.headingText}>{headingText}</HeadingTag>
          <div className={classnames(styles.roundOff, lablelBackgroundClassName)} />
        </div>
      )}
      <div className={styles.content}>{children}</div>
    </section>
  );
}

Card.propTypes = {
  /** The heading of the card. */
  headingText: PropTypes.string.isRequired,
  /** The color to be used for the border and heading at the top of the Card. */
  headingColor: PropTypes.oneOf(validColors).isRequired,
  /** The tag for the heading to be wrapped in. Either a h1->h4 or a paragraph tag (default). */
  headingTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h3', 'p']),
  /** The show heading Label will display label within the Card. */
  showHeadingLabel: PropTypes.bool.isRequired,
  /** The content to be displayed within the Card. */
  children: PropTypes.any.isRequired,

  className: PropTypes.string
};

export default Card;
