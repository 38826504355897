import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Heading from '@oup/shared-front-end/src/components/Heading';
// Redux
import { MY_OTHER_PRODUCTS_COURSE_ID } from '@oup/shared-node-browser/course.js';
import { toggleDetailsPopupWithId } from '../../../redux/reducers/assignLearningMaterial.reducer';
import { resetFiltersCourseMaterials } from '../../../redux/actions/hubCourseMaterialFilters';
import { openRedeemModal } from '../../../redux/actions/hubUi';
import { loadPlayerLaunchRequest, setProductVariant } from '../../../redux/actions/structuredContentPlayer';
// Components
import ProductListItem from '../../../components/ListItems/ProductListItem/ProductListItem';
import HubEmptyStateRestyle from '../../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import ResourceFormHidden from '../Resources/ResourceFormHidden';
import AssessmentProductListItem from '../../../components/AssessmentProductListItem/AssessmentProductListItem';
import HubListHeader from '../../HubListPage/HubListHeader';
import HubCourseMaterialFilters from '../../../components/HubCourseMaterialFilters/HubCourseMaterialFilters';
import { prepareProductListItem } from '../../../components/StructuredContentPlayer/StructuredContentPlayer';
// Services
import getCourseLevel from '../../HubDashboardLayout/Services/getCourseLevel';
import { featureIsEnabled, isDev } from '../../../globals/envSettings';
// Constants
import { HubLayoutConstants, HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';
import { isLtiMode } from '../../../utils/platform';
// Style
import styles from './CourseMaterial.scss';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';

function CourseMaterial({
  courseId = '',
  courses = {},
  hubContent,
  productData,
  breakpoint,
  resetFiltersCourseMaterialsAction,
  toggleDetailsPopupWithIdAction,
  location,
  openRedeemModalAction,
  loadPlayerLaunchRequestAction,
  lastLaunchedContents,
  isAssessmentShown,
  productVariant,
  setProductVariantAction,
  isPreview = false
}) {
  // TODO Remove this method call when the products will be fully configured and visible in Dashboard
  const [demoCPT, setDemoCPT] = useState([]);

  useEffect(() => {
    setProductVariantAction();

    const products = prepareProductListItem(productVariant);
    const productsArr = [];
    const contextIds = products.map(p => p.id);

    loadPlayerLaunchRequestAction(contextIds);

    Object.keys(products).forEach(key => {
      const product = products[key];
      if (
        Object.prototype.hasOwnProperty.call(lastLaunchedContents, product.id) &&
        lastLaunchedContents[product.id].length > 0
      ) {
        product.productLaunchUrl += `?activityId=${lastLaunchedContents[product.id]}`;
      }
      productsArr.push(product);
    });

    setDemoCPT(productsArr);
  }, [JSON.stringify(lastLaunchedContents), productVariant]);

  const isReadyCPTLoading = () => demoCPT.length !== 0 && demoCPT.length === Object.keys(lastLaunchedContents).length;

  const getProductsAlphabetically = () => {
    const unsortedProductsArray = Object.values(productData);
    const productsArraySortedAlphabetically = unsortedProductsArray.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
    return productsArraySortedAlphabetically;
  };

  const getProductsSortedByUserType = () => {
    const unsortedProductsArray = Object.values(productData);
    const productsArraySortedAlphabetically = unsortedProductsArray.sort((a, b) => {
      const targetOrder = { teacher: 1, student: 2, null: 3 };
      const productA = a.productUserRole;
      const productB = b.productUserRole;
      return targetOrder[productA] - targetOrder[productB];
    });
    return productsArraySortedAlphabetically;
  };

  const course = get(courses, [courseId]);
  const unfilteredProductData = get(course, ['products', 'data'], {});
  const isMyOtherProductsPage = !!location.pathname.includes(MY_OTHER_PRODUCTS_COURSE_ID);
  const products = isMyOtherProductsPage ? getProductsAlphabetically() : getProductsSortedByUserType();
  const productIds = (products && products.length && products.map(product => product.isbn)) || [];
  const courseUserRole = get(course, 'userRole', '');

  const hasVstProducts = (productIds && productIds.some(cId => productData && productData[cId].vstDomain)) || false;
  const courseTitle = course && `${get(course, ['title'], '')} ${getCourseLevel(get(course, ['properties']))}`;
  const pageTitle = course ? `${courseTitle} | ${hubContent.course_material_page_title}` : 'Loading';
  const assessmentTitle = course?.assessmentFolders?.assessments?.title;
  const shouldAssessmentCourseMaterialShow = (role, nCourse) =>
    role === HubLayoutConstants.COURSE_USER_ROLES.TEACHER && nCourse && nCourse.assessmentFolders;
  const showHeading = featureIsEnabled('navigation-changes');
  const headingArgs = {
    text: hubContent.course_materials,
    size: 'small',
    variant: 'h1'
  };
  return (
    <div>
      <Helmet title={pageTitle} />
      {showHeading && <Heading {...headingArgs} />}
      {hasVstProducts && <ResourceFormHidden />}
      <HubCourseMaterialFilters
        hubContent={hubContent}
        assessment={course?.assessmentFolders?.assessments}
        courseId={courseId}
        products={unfilteredProductData}
      />
      <div className={styles.courseMaterialList}>
        <HubListHeader
          item={{
            name: showHeading ? hubContent.course_materials : hubContent.course_material,
            status: hubContent.licence_button,
            buttonText: hubContent.add,
            action: openRedeemModalAction
          }}
          showCheckboxHeader={false}
          hasHeaderButtonAccess={!isLtiMode()}
          customClassName="completedTest"
          isPreview={isPreview}
        />
        {shouldAssessmentCourseMaterialShow(courseUserRole, course) && assessmentTitle && isAssessmentShown && (
          <AssessmentProductListItem
            breakpoint={breakpoint}
            title={assessmentTitle}
            assessment={course.assessmentFolders.assessments}
            courseId={courseId}
            hubContent={hubContent}
          />
        )}
        {productIds && productIds.length && productData
          ? productIds.map(id => (
              <ProductListItem
                key={id}
                product={productData && productData[id]}
                productId={id}
                hubContent={hubContent}
                breakpoint={breakpoint}
                dropdownActions={{ toggleDetails: toggleDetailsPopupWithIdAction }}
                isPreview={isPreview}
              />
            ))
          : null}

        {isDev() &&
          featureIsEnabled('structured-content-player-demo') &&
          window.location.pathname.indexOf(MY_OTHER_PRODUCTS_COURSE_ID) !== -1 && (
            <div>
              {isReadyCPTLoading()
                ? demoCPT.map(product => (
                    <ProductListItem
                      key={product.id}
                      product={product}
                      productId={product.id}
                      hubContent={hubContent}
                      breakpoint={breakpoint}
                      dropdownActions={{ toggleDetails: toggleDetailsPopupWithIdAction }}
                    />
                  ))
                : null}
            </div>
          )}
      </div>
      {productIds && courseTitle && !isAssessmentShown && !productIds.length && (
        <HubEmptyStateRestyle
          iconSrc={HubIllustrationConstants.SEARCH}
          iconAlt={HubIllustrationAltText.SEARCH}
          title={hubContent.hub_course_material_filters_no_results}
          bodyText={hubContent.hub_course_material_filters_explanation}
          btnFilledBase={{
            text: hubContent.hub_course_material_filters_reset_filters,
            icon: GLYPHS.ICON_REFRESH,
            action: () => resetFiltersCourseMaterialsAction({ courseId })
          }}
          btnOutlineBase={{
            isPrimaryButton: true,
            text: hubContent.add_course_material,
            icon: GLYPHS.ICON_PLUS,
            action: openRedeemModalAction
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => {
  const { hubCourseMaterialFilters, identity, structuredContentPlayer } = state;
  return {
    productData: hubCourseMaterialFilters.data,
    isAssessmentShown: hubCourseMaterialFilters.shouldAssessmentShow,
    userRole: identity.role,
    userId: identity.userId,
    lastLaunchedContents: structuredContentPlayer.lastLaunchedContents,
    productVariant: structuredContentPlayer.productVariant
  };
};

const mapDispatchToProps = {
  resetFiltersCourseMaterialsAction: resetFiltersCourseMaterials,
  toggleDetailsPopupWithIdAction: toggleDetailsPopupWithId,
  openRedeemModalAction: openRedeemModal,
  loadPlayerLaunchRequestAction: loadPlayerLaunchRequest,
  setProductVariantAction: setProductVariant
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseMaterial);

CourseMaterial.propTypes = {
  courseId: PropTypes.string,
  courses: PropTypes.object,
  hubContent: PropTypes.object,
  productData: PropTypes.object,
  breakpoint: PropTypes.string,
  resetFiltersCourseMaterialsAction: PropTypes.func,
  location: PropTypes.object,
  toggleDetailsPopupWithIdAction: PropTypes.func,
  openRedeemModalAction: PropTypes.func,
  loadPlayerLaunchRequestAction: PropTypes.func,
  lastLaunchedContents: PropTypes.object,
  isAssessmentShown: PropTypes.bool,
  setProductVariantAction: PropTypes.func,
  productVariant: PropTypes.string,
  isPreview: PropTypes.bool
};
