import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './ToggleSwitch.scss';

export const toggleTypes = {
  DEFAULT: 'DEFAULT', // Default style toggle
  STRING_TOGGLE: 'STRING_TOGGLE', // String switch style,
  THEMED_TOGGLE: 'THEMED_TOGGLE'
};

/** The component renders the toggle switch */
function ToggleSwitch({
  id = '',
  type = toggleTypes.DEFAULT,
  value,
  onChange,
  labelBefore,
  blockLabelBefore,
  label,
  stringOn,
  stringOff,
  disabled,
  additionalStyle
}) {
  return (
    <label
      htmlFor={id}
      className={classnames(
        styles.toggleSwitch,
        styles[`type-${type}`],
        { [styles.disabled]: disabled },
        additionalStyle
      )}
    >
      <input
        id={id}
        type="checkbox"
        value={value}
        checked={!!value}
        onChange={() => onChange(!value)}
        disabled={disabled}
      />
      {blockLabelBefore && <div className={styles.blockLabelBefore}>{labelBefore}</div>}
      <div className={styles.labelBeforeText}>
        {!blockLabelBefore && labelBefore}
        {stringOn && (
          <span className={classnames(styles.onOffString, !value && styles['onOffString--active'])}>
            {!blockLabelBefore && ' '} {stringOn}
          </span>
        )}
      </div>
      <div className={classnames(styles.controlShape, { [styles.selected]: value })}>
        {type !== toggleTypes.STRING_TOGGLE && <SVGIcon glyph={GLYPHS.ICON_SWITCH_TICK} />}
        <div className={classnames(styles.toggleSlider, styles.round)} />
      </div>
      {stringOff && (
        <span className={classnames(styles.onOffString, value && styles['onOffString--active'])}>
          {stringOff}&nbsp;
        </span>
      )}
      <div className={styles.labelText}>{label}</div>
    </label>
  );
}

ToggleSwitch.propTypes = {
  /** The id of the component. */
  id: PropTypes.string,
  /** Toggle type */
  type: PropTypes.string,
  /** The status (bool) of the component. */
  value: PropTypes.bool.isRequired,
  /** The label for the component (before and after). */
  label: PropTypes.string,
  labelBefore: PropTypes.string,
  /** Label before is block or inline */
  blockLabelBefore: PropTypes.bool,
  /** Before and after active strings for the STRING_TOGGLE type */
  stringOn: PropTypes.string,
  stringOff: PropTypes.string,
  /** The function change of the component. */
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  additionalStyle: PropTypes.string
};

export default ToggleSwitch;
