import * as activityActions from './activity';
import * as bulkPasswordReset from './bulkPasswordReset';
import * as contactUsActions from './contactUs';
import * as countryCodesActions from './countryCodes';
import * as dataRecencyActions from './dataRecency';
import * as editUserActions from './editUser';
import * as gradebookClassReport from './gradebookClassReport';
import * as gradebookModifyStudent from './gradebookModifyStudent';
import * as gradebookSchoolDialReportActions from './gradebookSchoolDialReport';
import * as importUsersActions from './importUsers';
import * as managedUserActions from './managedUser';
import * as managedUserEditActions from './managedUserEdit';
import * as orgActions from './org';
import * as orgAdminActions from './orgAdmin';
import * as orgEditActions from './orgEdit';
import * as orgSubscriptionActions from './orgSubscription';
import * as orgUniqueIdActions from './orgUniqueId';
import * as productsActions from './products';
import * as productServicesActions from './productServices';
import * as resendInvitationActions from './resendInvitation';
import * as resetPasswordActions from './resetPassword';
import * as results from './results';
import * as subscriptionsActions from './subscriptions';
import * as studentReportActions from './studentReport';
import * as teacherResourcesActions from './teacherResources';
import * as activationCode from './activationCode';
import * as notifications from './notifications';
import * as declineOrgInvite from './declineOrgInvite.action';
import * as preseedOrgs from './preseedOrgData';
import * as amLocalizedContent from './amLocalizedContent';
import * as onlineTestActions from './onlineTestActions';
import * as csvUserUpload from './csvUserUpload';
import * as csvProductStructureUpload from './csvProductStructureUpload';
import * as courseAssessmentActions from './courseAssessmentsActions';
import * as hubCourseMaterialFilters from './hubCourseMaterialFilters';
import * as hubProfileCourseMaterialFilters from './hubProfileCourseMaterialFilters';
import * as adaptedTests from './adaptedTests';
import * as deferredEmails from './deferredEmails';
import * as releaseDeferredEmails from './sendDeferredEmails';
// import * as oicEmailsTemplates from './oicEmailsTemplates';
import * as sendOicEmails from './sendOicEmails';
import * as rvsUpload from './rvsUpload';
import * as rvsForm from './rvsForm';
import * as contentPreview from './contentPreview';
import * as lmsConfig from './lmsConfig';
import * as userLicenceRemoveByUser from './userLicenceRemoveByUser';
import * as userLicenceRemoveByLicence from './userLicenceRemoveByLicence';
import * as removeUserLicence from './removeUserLicence';
import * as oicTestCredits from './oicTestCredits';
import * as testCredits from './testCredits';
import * as matReportSummary from './matReportSummary';
import * as activationCodeDashboard from './activationCodeDashboard';
import * as activationCodeBatchDashboard from './activationCodeBatchDashboard';
import * as oicLinkOrgToJanison from './oicLinkOrgToJanison';
import * as userAdminDashboard from './userAdminDashboard';
import * as createCodeBatch from './createCodeBatch';
import * as createProduct from './createProduct';
import * as searchProduct from './searchProduct';
import * as structuredContentPlayer from './structuredContentPlayer';

import * as invoiceReport from './invoiceReport';
import * as reCAPTCHA from './reCAPTCHA';

export default {
  ...activityActions,
  ...bulkPasswordReset,
  ...contactUsActions,
  ...countryCodesActions,
  ...dataRecencyActions,
  ...editUserActions,
  ...gradebookClassReport,
  ...gradebookModifyStudent,
  ...gradebookSchoolDialReportActions,
  ...importUsersActions,
  ...managedUserActions,
  ...managedUserEditActions,
  ...orgActions,
  ...orgAdminActions,
  ...orgEditActions,
  ...orgSubscriptionActions,
  ...orgUniqueIdActions,
  ...productsActions,
  ...productServicesActions,
  ...resendInvitationActions,
  ...resetPasswordActions,
  ...results,
  ...subscriptionsActions,
  ...studentReportActions,
  ...teacherResourcesActions,
  ...activationCode,
  ...notifications,
  ...declineOrgInvite,
  ...preseedOrgs,
  ...amLocalizedContent,
  ...onlineTestActions,
  ...csvUserUpload,
  ...csvProductStructureUpload,
  ...courseAssessmentActions,
  ...hubCourseMaterialFilters,
  ...hubProfileCourseMaterialFilters,
  ...adaptedTests,
  ...deferredEmails,
  ...releaseDeferredEmails,
  ...sendOicEmails,
  ...rvsUpload,
  ...rvsForm,
  ...contentPreview,
  ...lmsConfig,
  ...userLicenceRemoveByUser,
  ...userLicenceRemoveByLicence,
  ...removeUserLicence,
  ...oicTestCredits,
  ...testCredits,
  ...matReportSummary,
  ...activationCodeDashboard,
  ...oicLinkOrgToJanison,
  ...userAdminDashboard,
  ...createCodeBatch,
  ...activationCodeBatchDashboard,
  ...createProduct,
  ...structuredContentPlayer,
  ...searchProduct,
  ...invoiceReport,
  ...reCAPTCHA
};
