import * as t from '../actionTypes.js';

export const getContentSelectorStatusRequest = (params = {}) => ({
  type: t.NGI_GET_CONTENT_SELECTOR_STATUS_REQUEST,
  payload: params
});

export const getContentSelectorStatusSuccess = data => ({
  type: t.NGI_GET_CONTENT_SELECTOR_STATUS_SUCCESS,
  payload: data
});

export const getContentSelectorStatusFailure = error => ({
  type: t.NGI_GET_CONTENT_SELECTOR_STATUS_FAILURE,
  payload: error
});
