import { put, select } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch.js';
import { setSystemNotification } from '../../../../reducers/app.reducer.js';
import { featureIsEnabled } from '../../../../../globals/envSettings';

function* updateSystemNotification(data) {
  const response = yield signedFetch('systemNotification', `${__API_BASE__}/system-notification`, 'PUT', data);
  if (response.status.toLowerCase() === 'success' && data.systemNotification) {
    if (featureIsEnabled('granular-platform-system-notifications')) {
      const systemNotification = yield select(state => state.app.systemNotification);
      const updated = systemNotification.map(el => {
        const updatedPlatform = data.systemNotification.find(({ platformCode }) => platformCode === el.platformCode);
        return updatedPlatform || el;
      });

      yield put(setSystemNotification(updated));
    } else {
      const systemNotification = yield select(state => state.app.systemNotification);
      const immutableSystemNotification = { ...systemNotification };

      (data.systemNotification || []).forEach(element => {
        immutableSystemNotification[element.platformCode] = element.enabled;
      });
      const updateNotificationData = [];

      Object.keys(immutableSystemNotification || {}).forEach(platformCode => {
        if (immutableSystemNotification[platformCode])
          updateNotificationData.push({ platform: platformCode, showNotification: true });
      });

      yield put(setSystemNotification(updateNotificationData));
    }
  }
}

export default updateSystemNotification;
