import { select } from 'redux-saga/effects';

import archiveUsersFromOrgApi from '../../../apiCalls/users/archiveUsersFromOrg.api.js';
import { getCurrentPlatform } from '../../../../../utils/platform';

export default function* archiveUsersFromOrgRequest() {
  const { userIds, orgId, notifyUsers } = yield select(state => ({
    userIds: state.archiveUsers.userIds,
    orgId: String(state.organisationPage.orgId),
    notifyUsers: state.archiveUsers.notifyWithEmail
  }));
  const platformCode = getCurrentPlatform();
  const userIdAndRoleNamePairs = [];
  yield select(state => {
    userIds.forEach(userId => {
      userIdAndRoleNamePairs.push({ userId, roleName: state.people.data[userId].roleName });
    });
  });
  console.log('[archiveUsers Saga] Archiving users:', {
    orgId,
    userIdAndRoleNamePairs,
    notifyUsers,
    platformCode
  });
  return yield archiveUsersFromOrgApi({ orgId, userIdAndRoleNamePairs, notifyUsers, platformCode });
}
