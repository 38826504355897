import * as t from '../actionTypes';

const initialState = {
  isSubmitting: false,
  isSuccessful: false,
  hasFailed: false,
  errors: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.RESET_PASSWORD_VALIDATE_INPUT_COMPLETED:
      return {
        ...state,
        errors: payload
      };
    case t.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        hasFailed: false,
        isSubmitting: true
      };
    case t.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isSuccessful: true
      };
    case t.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        hasFailed: true
      };
    case t.RESET_PASSWORD_RESET:
      return { ...initialState };
    default:
      return state;
  }
};
