import { put } from 'redux-saga/effects';
import { getProductLocksSuccess, getProductLocksError } from '../../../../actions/productLocks';
import getProductLocksApi from '../../../apiCalls/product/getProductLocksApi';

function* getProductLocks(payload) {
  const productLock = yield getProductLocksApi(payload);

  if (productLock.status === 'success') {
    yield put(getProductLocksSuccess({ productLocks: productLock.data }));
  } else {
    yield put(getProductLocksError());
  }
}

export default getProductLocks;
