export const INITIALISE = 'productPublish/INITIALISE';
export const PRODUCT_PUBLISH_REQUEST = 'productPublish/PRODUCT_PUBLISH_REQUEST';
export const PRODUCT_PUBLISH_SUCCESS = 'productPublish/PRODUCT_PUBLISH_SUCCESS';
export const PRODUCT_PUBLISH_FAILURE = 'productPublish/PRODUCT_PUBLISH_FAILURE';

export const initialState = {
  inProgress: true,
  loading: false,
  errorOccurred: false,
  errorMessage: null,
  success: false,
  productPublishResult: null
};

export default function productPublish(state = initialState, action = {}) {
  switch (action.type) {
    // Initialise action
    case INITIALISE:
      return {
        ...initialState
      };
    case PRODUCT_PUBLISH_REQUEST:
      return {
        ...state,
        inProgress: false,
        loading: true,
        errorOccurred: false,
        success: false,
        formData: action.formData,
        selectedPlatformCode: action.selectedPlatformCode
      };
    case PRODUCT_PUBLISH_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: false,
        errorOccurred: true,
        inProgress: false,
        success: false
      };
    case PRODUCT_PUBLISH_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        success: true,
        productPublishResult: action.productPublishResult,
        loading: false,
        errorOccurred: false,
        inProgress: false
      };
    default:
      return state;
  }
}

export const startProductPublishRequest = selectedPlatformCode => ({
  type: PRODUCT_PUBLISH_REQUEST,
  selectedPlatformCode
});

export const showSuccessMessage = productPublishResult => ({
  type: PRODUCT_PUBLISH_SUCCESS,
  productPublishResult
});

export const showProductPublishError = errorMessage => ({
  type: PRODUCT_PUBLISH_FAILURE,
  errorMessage
});

export const reset = () => ({
  type: INITIALISE
});
