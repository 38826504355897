import * as t from '../actionTypes';

export const initialState = {
  classNameValue: '',
  classNamePending: false,
  classCreated: false,
  classCreationError: false,
  classCreationLoading: false,
  classId: '',
  joiningCode: {},
  userDetails: {
    students: {},
    studentsDataError: false
  },
  learningMaterialDetails: {
    learningMaterials: [],
    learningMaterialsDataError: false
  }
};

export default function classOnboardingWizard(state = initialState, action = {}) {
  switch (action.type) {
    case t.ONBOARDING_WIZARD_SET_CREATE_CLASS_REQUEST:
      return {
        ...state,
        classNameValue: action.payload.classNameValue,
        classCreationError: false,
        classCreationLoading: true,
        classNamePending: true
      };
    case t.ONBOARDING_WIZARD_SET_CREATE_CLASS_SUCCESS:
      return {
        ...state,
        classCreated: true,
        classCreationError: false,
        classCreationLoading: false,
        classNamePending: false,
        classId: action.payload.classId,
        joiningCode: action.payload.joiningCode,
        userDetails: {
          ...state.userDetails,
          ...action.payload.userDetails
        }
      };
    case t.ONBOARDING_WIZARD_SET_CREATE_CLASS_ERROR:
      return {
        ...state,
        classNamePending: false,
        classCreationError: true,
        classCreationLoading: false,
        classCreated: false
      };
    case t.ONBOARDING_WIZARD_GET_CLASS_STUDENTS_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          students: action.payload.students
        }
      };
    case t.ONBOARDING_WIZARD_GET_CLASS_STUDENTS_ERROR:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          studentsDataError: true
        }
      };
    case t.ONBOARDING_WIZARD_SAVE_LEARNING_MATERIALS_SUCCESS: {
      const newLearningMaterials = [...state.learningMaterialDetails.learningMaterials, ...action.payload];
      return {
        ...state,
        learningMaterialDetails: {
          learningMaterials: newLearningMaterials,
          learningMaterialsDataError: false
        }
      };
    }
    case t.ONBOARDING_WIZARD_SAVE_LEARNING_MATERIALS_ERROR:
      return {
        ...state,
        learningMaterialDetails: {
          ...state.learningMaterialDetails,
          learningMaterialsDataError: true
        }
      };
    case t.ONBOARDING_WIZARD_CLEAR_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
