import { put, select } from 'redux-saga/effects';
import { setFormResult } from '../../../../reducers/data/forms.reducer.js';
import orgArchiveApi from '../../../apiCalls/orgs/orgArchive.api.js';
import { getFieldValue, requestFailed } from '../formUtil.js';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import actions from '../../../../actions';

export default function* submitOrgArchive() {
  const payload = yield select(state => ({
    // Context IDs
    actioningUserId: state.identity.userId,
    // Data to change
    orgs: [getFieldValue(state, 'orgArchive', 'orgId')],
    notifyLearners: getFieldValue(state, 'orgArchive', 'emailNotify')
  }));

  const apiResult = yield orgArchiveApi(payload);

  const failed = requestFailed(apiResult);
  // !apiResult.data.orgStatuses ||
  // !apiResult.data.orgStatuses.length ||
  // apiResult.data.orgStatuses[0].status !== 'SCHEDULED';

  if (apiResult.status === 'success' && featureIsEnabled('data-recency') && featureIsEnabled('data-recency-orgs')) {
    const successfullyRemovedOrgIds = apiResult.data.orgStatuses;
    if (successfullyRemovedOrgIds.length) {
      console.log('[submitOrgArchive Saga] Poll entities syncing');
      yield put(
        actions.pollEntitiesSynced(
          {
            organisations: successfullyRemovedOrgIds.map(({ groupId }) => ({ orgId: groupId, operation: 'REMOVE' })),
            collection: 'orgs',
            target: 'organisations',
            responseEntityKey: 'userPollingStatus',
            identifier: 'orgId',
            search: 'oupOrgs'
          },
          ['role']
        )
      );
    }
  }

  yield put(setFormResult('orgArchive', 'CONFIRMATION', failed));
}
