import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../globals/colors';
import { fgClass } from '../../utils/colorClassNames';

// NOTE: This component's styles are provided by the global typography styling

/** Simple component for displaying a HTML data list based on an array of data */
function DataList({ dataPairs }) {
  return (
    <dl>
      {dataPairs.map(({ title, value, status }, index) => [
        <dt key={2 * index}>{title}</dt>,
        <dd className={fgClass(colors[status] || colors.TEXT)} key={2 * index + 1}>
          {value}
        </dd>
      ])}
    </dl>
  );
}

DataList.propTypes = {
  /** The pairs of titles and values for the DataList. */
  dataPairs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      status: PropTypes.string
    })
  ).isRequired
};

export default DataList;
