// Page load and clear actions
const SET_SELECTED_TEACHERS = 'addTeachersToClassroom/SET_SELECTED_TEACHERS';
export const CLEAR_FORM = 'addTeachersToClassroom/CLEAR_FORM';

// Remove individual Teacher
const REMOVE_TEACHER = 'addTeachersToClassroom/REMOVE_TEACHER';

// Nav actions
export const ASSIGN_MATERIAL = 'addTeachersToClassroom/ASSIGN_MATERIAL';
const GOTO_TEACHER_SELECTION = 'addTeachersToClassroom/GOTO_TEACHER_SELECTION';
export const SUBMIT_FORM = 'addTeachersToClassroom/SUBMIT_FORM';
const SUBMISSION_COMPLETE = 'addTeachersToClassroom/SUBMISSION_COMPLETE';

export const formStates = {
  SELECTING: 'SELECTING',
  REVIEW_SELECTIONS: 'REVIEW_SELECTIONS',
  SUBMITTING: 'SUBMITTING',
  ASSIGNMATERIAL: 'ASSIGNMATERIAL',
  CONFIRMATION: 'CONFIRMATION' // Success|Fail feedback after SUBMITTING
};

const initialState = {
  formState: formStates.SELECTING,

  // Selected users
  selectedTeacherIds: [],

  // List of users who were not removed from the class after submission
  failedIds: [],

  // General error catch all
  requestFailed: null
};

export default function addTeachersToClassroom(state = initialState, action = {}) {
  switch (action.type) {
    // Page load and clear actions
    case SET_SELECTED_TEACHERS:
      return {
        ...state,
        formState: formStates.REVIEW_SELECTIONS,
        selectedTeacherIds: action.selectedTeacherIds
      };
    case CLEAR_FORM:
      return {
        ...initialState
      };

    case REMOVE_TEACHER:
      return {
        ...state,
        selectedTeacherIds: state.selectedTeacherIds.filter(id => id !== action.id)
      };

    // Nav actions
    case ASSIGN_MATERIAL:
      return {
        ...state,
        formState: formStates.ASSIGNMATERIAL
      };
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case GOTO_TEACHER_SELECTION:
      return {
        ...state,
        formState: formStates.SELECTING
      };
    case SUBMISSION_COMPLETE:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        requestFailed: action.requestFailed,
        failedIds: action.failedIds
      };
    default:
      return state;
  }
}

// Page load and clear actions
export const setSelectedTeachers = selectedTeacherIds => ({
  type: SET_SELECTED_TEACHERS,
  selectedTeacherIds
});
export const clearForm = () => ({
  type: CLEAR_FORM
});

export const removeTeacher = id => ({
  type: REMOVE_TEACHER,
  id
});

// Nav actions
export const gotoTeacherSelection = () => ({
  type: GOTO_TEACHER_SELECTION
});
export const submitForm = () => ({
  type: SUBMIT_FORM
});
export const submissionComplete = (requestFailed, failedIds) => ({
  type: SUBMISSION_COMPLETE,
  requestFailed,
  failedIds
});
export const assignMaterial = classId => ({
  type: ASSIGN_MATERIAL,
  classId
});
