import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import cmsContent from '../../utils/cmsContent';
import Button, { buttonTypes } from '../Button/Button';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Table.scss';

export const rowTypes = {
  PROCESSING: 'PROCESSING'
};

export const columnTypes = {
  TEXT: 'TEXT',
  ICON: 'ICON',
  BUTTON: 'BUTTON',
  STATUS: 'STATUS'
};

export const validColumnTypes = Object.keys(columnTypes);

const MISSING_COL = {
  heading: '(Missing columns propType)',
  type: columnTypes.TEXT
};

function hasRevealableContent(rowProps) {
  return rowProps.revealableContent;
}

PropTypes.tableRow = PropTypes.shape({
  id: PropTypes.string.isRequired,
  colSpan: PropTypes.number,
  hoverableClass: PropTypes.bool,
  cells: PropTypes.arrayOf(PropTypes.node),
  /** Optionally define content for hidden "popop" row:
  (Requires corresponsding extra item in cells prop to define text for toggle button) */
  revealableContent: PropTypes.any
});

class Table extends Component {
  constructor(props) {
    super(props);
    const { revealControl } = this.props;
    this.state = {
      // This array will keep track of which rows to show/hide: (All hidden by default)
      revealRow: revealControl || []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.revealControl) {
      // setState needs to be fired here for any parent components
      // that have control over the reveal status.
      this.setState({ revealRow: nextProps.revealControl });
    }
  }

  // Toggle show/hide state of specified row:
  onToggleClick = id => {
    const { revealRow } = this.state;
    let newRevealRow = revealRow;

    if (newRevealRow.includes(id)) {
      newRevealRow = newRevealRow.filter(rowId => rowId !== id);
    } else {
      newRevealRow.push(id);
    }

    this.setState({ revealRow: newRevealRow });
  };

  render() {
    const CMS = cmsContent.searchResults || {};
    const {
      id = 'searchResults-table',
      caption = null,
      columns,
      rows,
      toolbarRow = null,
      customClass = null,
      revealControl = null,
      hideTableHeading,
      secondTabLabel,
      focusRows = [],
      isBreakpointAtTablet,
      fixedFirstColumnWidth = false,
      persistantColumns = false,
      selectAll = false
    } = this.props;
    const { revealRow } = this.state;

    // Figure out if any of the rows have more cols defined than the columns prop.
    // That way we can show a missing-prop header instead of mystery "undefined" errors:
    const maxColCount = rows.reduce((count, row) => Math.max(count, (row.cells && row.cells.length) || 0), 0);
    const maxCols = new Array(Math.max(columns.length, maxColCount)).fill(0);

    // If ANY rows have revealableContent then we'll need to make a special column for the toggle arrow thing:
    const isRevealomaticTable = rows.some(hasRevealableContent);

    const allRows = toolbarRow ? [toolbarRow, ...rows] : rows;

    return (
      <table
        id={id}
        className={classnames(styles.table, customClass, {
          [styles.tabletBreakpoint]: isBreakpointAtTablet,
          [styles.forceTightWrap]: fixedFirstColumnWidth,
          [styles.persistantColumns]: persistantColumns
        })}
      >
        {/* Fix: CES-2209: Hide <caption> using custom styles because some techniques cause
        bug where caption is read out as row2. Weird. (Including our standard class="a11y-hide") */}
        <caption id={`${id}-caption`}>{caption || CMS.defaultCaption}</caption>

        <thead>
          <tr>
            {maxCols.map((n, index) => {
              const { aligned, centeredHeader, heading: headingText, headingHidden, noBorderHeader, width } =
                columns[index] || MISSING_COL;
              const removeborderHeader = !noBorderHeader && !headingHidden && !index;
              return (
                // This markup is weird because Voiceover was reading each column heading twice.
                // Using aria-label and hiding the actual <th> content seems to help
                <th
                  key={index}
                  scope="col"
                  aria-label={headingText}
                  className={classnames({
                    [styles.firstHeaderCell]: !index,
                    [styles[`${aligned}Aligned`]]: !!aligned
                  })}
                  style={{
                    ...(width && { width })
                  }}
                >
                  {hideTableHeading ? (
                    <span className="a11y-hide" aria-hidden="true">
                      &nbsp;
                    </span>
                  ) : (
                    <div>
                      <span
                        className={classnames({
                          'a11y-hide': headingHidden,
                          [styles.borderHeader]: removeborderHeader,
                          [styles.headerSelectAll]: selectAll,
                          [styles.centeredHeader]: centeredHeader
                        })}
                      >
                        {headingText}
                      </span>
                      {secondTabLabel && index === 0 ? (
                        <span className={styles.labelWrapper}>{secondTabLabel}</span>
                      ) : null}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {allRows.map((row, rowIndex) => [
            // We use .hoverable to change colour of Thumbnails when TR is hovered. See colors.scss.
            <tr
              key={row.id}
              id={row.id}
              className={classnames({
                hoverable: row.hoverableClass == null ? true : row.hoverableClass,
                [styles.rowFocus]: focusRows[rowIndex],
                [styles[`row-${row.type}`]]: row.type
              })}
            >
              {row.cells.map((cell, index) => {
                const colProp = columns[index];
                const { aligned, width } = colProp;
                const cellTypeClassname = `cell-${(colProp || MISSING_COL).type}`;

                // Decide whether this cell is a special row-toggler cell to reveal hidden row:
                const isToggleCell = isRevealomaticTable && index === row.cells.length - 1;

                return (
                  <td
                    key={index}
                    colSpan={row.colSpan}
                    className={classnames({
                      [styles[cellTypeClassname]]: index && styles[cellTypeClassname],
                      [styles.selectableItem]: !index,
                      [styles.topBorder]: rowIndex,
                      [styles[`${aligned}Aligned`]]: !!aligned
                    })}
                    style={{
                      ...(width && { width })
                    }}
                  >
                    {isToggleCell && !revealControl ? (
                      <div
                        className={classnames(styles.revealButton, {
                          [styles.revealButtonOpen]: revealRow.includes(row.id)
                        })}
                      >
                        <Button
                          iconOnly
                          id={`reveal-${row.id}`}
                          text={cell}
                          type={buttonTypes.NO_BORDER}
                          glyph={GLYPHS.ICON_RIGHT_THICK}
                          onClick={() => this.onToggleClick(row.id)}
                          aria={{ expanded: revealRow.includes(row.id) }}
                        />
                      </div>
                    ) : (
                      cell
                    )}
                  </td>
                );
              })}
            </tr>,

            // This is only relevant when Table has revealableContent defined:
            <tr key={`popupRow-${rowIndex}`} className={styles.popupRow}>
              <td colSpan={maxCols.length}>
                <AnimateHeight duration={500} height={revealRow.includes(row.id) ? 'auto' : 0}>
                  <div className={classnames(styles.topBorder, styles.cellPadding)}>{row.revealableContent}</div>
                </AnimateHeight>
              </td>
            </tr>
          ])}
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {
  id: PropTypes.string,
  /* Table caption text. Recommended. Defaults to cmsContent.searchResults.defaultCaption */
  caption: PropTypes.string,
  /** The headings of the Table */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object // Can be a sorting header
      ]).isRequired,
      type: PropTypes.oneOf(validColumnTypes),
      aligned: PropTypes.string,
      width: PropTypes.string
    })
  ).isRequired,
  hideTableHeading: PropTypes.bool,
  /** The data of the Table */
  rows: PropTypes.arrayOf(PropTypes.tableRow).isRequired,
  /** A toolbar top row */
  // eslint-disable-next-line react/no-typos
  toolbarRow: PropTypes.tableRow,
  /** Custom class name */
  customClass: PropTypes.string,
  /** Delegates the reveal control to props */
  revealControl: PropTypes.array,
  /** Whether the second heading should be a label for the third (a circumstantial ORB class report prop) */
  secondTabLabel: PropTypes.string,
  /** Array map of any focused rows */
  focusRows: PropTypes.array,
  /** Whether the table breaks at a tablet width or before */
  isBreakpointAtTablet: PropTypes.bool,
  fixedFirstColumnWidth: PropTypes.bool,
  persistantColumns: PropTypes.bool,
  selectAll: PropTypes.bool
};

export default Table;
