import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import actions from '../redux/actions';

class GradebookClassReportPage extends Component {
  componentDidMount() {
    const { getClassReport, params } = this.props;
    getClassReport(params || false);
  }

  render() {
    const { classId, error, loading, success, failure, report } = this.props;

    // We have a web of dependencies here that need resolving before this new structure is implemented.
    switch (true) {
      case success:
        return (
          <div>
            <h1>Class {classId}</h1>
            <ul>
              {report.learners.map((learner, i) => (
                <li key={i}>{`${learner.firstname} ${learner.surname}`}</li>
              ))}
            </ul>
          </div>
        );
      case failure:
        return <div>An error occurred. {error}</div>;
      case loading:
      default:
        return <div>Loading...</div>;
    }
  }
}

GradebookClassReportPage.propTypes = {
  params: PropTypes.object.isRequired,
  classId: PropTypes.string.isRequired,
  error: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  report: PropTypes.object.isRequired,
  getClassReport: PropTypes.func.isRequired
};

export default connect(
  state => pick(state.gradebookClassReport, ['classId', 'error', 'loading', 'success', 'failure', 'report']),
  dispatch => ({
    getClassReport: params => {
      dispatch(actions.gradebookClassReportRequest(params));
    }
  })
)(GradebookClassReportPage);
