import { put, all } from 'redux-saga/effects';
import getPlayerLaunchFetch from '../../../apiCalls/cptStructuredContentPlayer/getPlayerLaunch';
import { loadPlayerLaunchFailure, loadPlayerLaunchSuccess } from '../../../../actions/structuredContentPlayer';

function* getPlayerLaunch(params) {
  const calls = params.map(p => getPlayerLaunchFetch(p));
  const responses = yield all(calls);

  for (let i = 0; i < responses.length; i += 1) {
    if (responses[i].status === 'success') {
      yield put(loadPlayerLaunchSuccess(responses[i]));
    } else {
      yield put(loadPlayerLaunchFailure('Failed to get player last activity launch'));
    }
  }
}

export default getPlayerLaunch;
