import { JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../util/signedFetch.js';

export default (body, params) => {
  const URL = `${__API_BASE__}/org/${params.orgId}/class/${params.classId}/product/${params.isbn}/locks`;

  const headers = { 'Content-Type': JSON_ENCODED, Accept: JSON_ENCODED };

  return signedFetch('updateProductLocks', URL, 'PUT', body, headers);
};
