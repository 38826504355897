import { put } from 'redux-saga/effects';
import { getProductStructureSuccess, getProductStructureError } from '../../../../actions/productStructure';
import getOpenProductStructure from '../../../apiCalls/product/getOpenProductStructure';

function* getProductStructure(payload) {
  const productStructure = yield getOpenProductStructure(payload);

  if (productStructure.status === 'success') {
    yield put(getProductStructureSuccess({ productStructure: productStructure.data }));
  } else {
    yield put(getProductStructureError());
  }
}

export default getProductStructure;
