import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import csvProductStructureUpload from './csvProductStructureUpload';
import parseImportProductStructureSource from './parseFileDataProductStructure';

const platform = 'eps';
const sourcePlatform = 'olb';
function* root() {
  yield takeLatest(t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_PARSE_FILE_REQUEST, ({ payload }) =>
    parseImportProductStructureSource(payload)
  );
  yield takeLatest(t.CSVPRODUCT_STRUCTURE_DATA_UPLOAD_START_UPLOAD, ({ payload }) =>
    csvProductStructureUpload(payload, platform, sourcePlatform)
  );
}

export default root;
