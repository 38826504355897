import { call, put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes.js';
import { fetchProductsSuccess, fetchProductsFailure } from '../../../../actions/getOpenProductsActions.js';
import fetchProductsApi from '../../../apiCalls/ngi/fetchProductsApi.js';

function* fetchProducts(action) {
  try {
    const response = yield call(fetchProductsApi, action.payload);
    yield put(fetchProductsSuccess(response.data));
  } catch (error) {
    yield put(fetchProductsFailure('Failed to fetch products'));
  }
}

function* fetchProductsSaga() {
  yield takeLatest(t.OPEN_PRODUCTS_FETCH_REQUEST, fetchProducts);
}

export default fetchProductsSaga;
