import signedFetch from '../apiCalls/util/signedFetch';

function* teacherResourceConsumer(body) {
  const queryString = Object.keys(body)
    .map(key => `${key}=${body[key]}`)
    .join('&');
  const url = `${__API_BASE__}/lti/consumer?${queryString}`;

  return yield signedFetch('teacherResourceConsumer', url, 'POST');
}

export default teacherResourceConsumer;
