import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import ActionList, { Action } from '../ActionList/ActionList';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import styles from './UserEditLinkAccountsFailure.scss';
import { isHubMode } from '../../utils/platform';

function UserEditLinkAccountsFailure({ localizedContent: { userEditFailureComponent: content }, onBack, onDone }) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          {isHubMode() ? (
            <PopoutPanelIllustrationHeading
              title={content.edit_user_link_accounts_failure_title}
              type={popoutPanelIconHeadingTypes.ERROR}
              illustrationSrc={HubIllustrationConstants.ERROR}
              HubIllustrationAltText={HubIllustrationAltText.ERROR}
            />
          ) : (
            <PopoutPanelIconHeading
              title={content.edit_user_link_accounts_failure_title}
              type={popoutPanelIconHeadingTypes.ERROR}
            />
          )}
        </div>
      }
      footerContent={
        <ActionList>
          <Action back label={content.back_button} onClick={onBack} />
          <Action label={content.done_button} primary onClick={onDone} />
        </ActionList>
      }
    >
      <div className={`${styles.editUserFailure} pad2`}>
        <div className="gin2">
          <div className={styles.editUserFailureText}>{content.edit_user_link_accounts_failure_top_text}</div>
          <div className={styles.editUserFailureText}>
            <div>{content.edit_user_link_accounts_failure_bottom_text_start}</div>
            <span className={styles.editUserFailureTextBold}>
              {content.edit_user_link_accounts_failure_bottom_text_end}
            </span>
          </div>
        </div>
      </div>
    </ScrollContainer>
  );
}

UserEditLinkAccountsFailure.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default withLocalizedContent('userEditFailureComponent')(UserEditLinkAccountsFailure);
