import PropTypes from 'prop-types';
import React from 'react';
import { REDEEM_CODE, PLATFORMS } from '@oup/shared-node-browser/constants';
import Button from '@oup/shared-front-end/src/components/Button';
import { isCesMode, isHubMode } from '../../utils/platform';
import { getPlatformDashboardUrl, getIntegrationPlatformKey } from '../../globals/envSettings';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';

import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './ResetPasswordConfirmation.scss';

function getPopoutPanelHeading(failed, content, firstName) {
  if (isHubMode()) {
    if (!failed) {
      return (
        <PopoutPanelIllustrationHeading
          title={content.password_reset_success_text.replace('{firstName}', firstName)}
          illustrationSrc={HubIllustrationConstants.SUCCESS}
          illustrationAltText={HubIllustrationAltText.SUCCESS}
        />
      );
    }
    return (
      <PopoutPanelIllustrationHeading
        title={content.password_reset_error_title}
        subtitle={content.password_reset_error_detail}
        illustrationSrc={HubIllustrationConstants.ERROR}
        illustrationAltText={HubIllustrationAltText.ERROR}
      />
    );
  }
  if (!failed) {
    return (
      <PopoutPanelIconHeading
        type={types.VALID}
        title={content.password_reset_success_text.replace('{firstName}', firstName)}
      />
    );
  }
  return (
    <PopoutPanelIconHeading
      type={types.ERROR}
      title={content.password_reset_error_title}
      subtitle={content.password_reset_error_detail}
    />
  );
}
function ResetPasswordConfirmation({
  firstName,
  failed,
  platform,
  loginUrl,
  onResetClick,
  localizedContent: { resetPasswordConfirmationComponent: content }
}) {
  return (
    <div className={`row ${styles.resetPasswordConfirmationRow}`}>
      <div className="col">
        {!failed ? (
          <div>
            {getPopoutPanelHeading(failed, content, firstName)}
            <div className={`text-center gin-top2 gin-bot2 ${styles.continueButtonContainer}`}>
              <Button
                id="link-to-login"
                variant="filled"
                text={
                  platform.toUpperCase() === 'OLB' || platform.toUpperCase() === PLATFORMS.OLB_MOBILE
                    ? content.button_sign_in_text_olb
                    : content.button_sign_in_text
                }
                onClick={
                  // Handle code redemption to autopopulate when routed from redeem page and password reset
                  () => {
                    if (isCesMode() && localStorage.getItem(REDEEM_CODE)) {
                      sessionStorage.setItem('goto-path', '/myProfile/learningMaterial/addCode');
                    }
                    if (
                      platform.toUpperCase() === 'OLB' ||
                      platform.toUpperCase() === PLATFORMS.OLB_MOBILE ||
                      platform.toUpperCase() === 'OWL'
                    ) {
                      window.location.href = getPlatformDashboardUrl(getIntegrationPlatformKey(platform));
                    } else {
                      window.location.href = loginUrl;
                    }
                  }
                }
              />
            </div>
          </div>
        ) : (
          <div>
            {getPopoutPanelHeading(failed, content, firstName)}
            <div className={`text-center gin-top2 gin-bot2 ${styles.errorButtonContainer}`}>
              <Button
                id="reset-password"
                variant="filled"
                text={content.button_reset_password_text}
                onClick={onResetClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ResetPasswordConfirmation.propTypes = {
  firstName: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  loginUrl: PropTypes.string.isRequired,
  failed: PropTypes.bool.isRequired,
  onResetClick: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('resetPasswordConfirmationComponent')(ResetPasswordConfirmation);
