import querySelectorAllFocusable from './querySelectorAllFocusable.js';

// Helper to return the element that can receive focus after the current one:
// Typically used while Tabbing between fields.
// Note when we call querySelectorAllFocusable we tell it to include the activeElement
// in the results so that we can find its position in the resulting array.
// This is necessary when the activeElement is no longer deemed focusable for some reason.
export default function querySelectorNextFocusable(container = document) {
  const elements = querySelectorAllFocusable(container, document.activeElement);
  const index = elements.indexOf(document.activeElement);
  const lastIndex = elements.length - 1;

  // Cycle around to the first element if focus is currently on the last one:
  return index === -1 ? null : (index === lastIndex && elements[0]) || elements[index + 1];
}
