import qs from 'query-string';
import signedFetch from '../util/signedFetch';
import { getProductMetadata } from '../../../../utils/platform';

export const getClassDialsUrl = (orgId, classId, locationId, subject, academicYear) =>
  `${__API_BASE__}/gradebook/org/${orgId}/class/${classId}/dials?${qs.stringify({
    locationId,
    subject,
    viewType: 'latest',
    cache: true,
    academicYear
  })}`;

export default function* getClassReportDials(orgId, classId, locationId, academicYear) {
  const subject = getProductMetadata('orb', 'subject');

  return yield signedFetch(
    'getClassReportDials',
    getClassDialsUrl(orgId, classId, locationId, subject, academicYear),
    'GET'
  );
}
