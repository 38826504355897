/* eslint-disable camelcase */
import { put } from 'redux-saga/effects';

import Base64 from 'crypto-js/enc-base64';
import { PLATFORMS } from '@oup/shared-node-browser/constants.js';
import { appError } from '../../../../reducers/app.reducer.js';

import authSettings from '../../../../../globals/authSettings.js';
import getCognitoId from './getCognitoId.saga.js';
import randomString from '../../../../../utils/randomString';
import envSettings, {
  getPlatformDashboardUrl,
  isOIDCStandard,
  getProviderByRegion,
  getOidcClientIdByPlatformCode,
  featureIsEnabled,
  getIntegrationPlatformKey,
  getPlatformBaseUrl,
  isDev
} from '../../../../../globals/envSettings';
import { isAndroid, isIos } from '../../../../../utils/device';
import {
  epsPlatformOlbOffline,
  getCurrentPlatform,
  getPlatformByCodeOrDomain,
  isHubMode,
  isLtiMode
} from '../../../../../utils/platform';
import { PARENT_PLATFORM } from '../../../../../globals/appConstants';
// TODO: fix dependency cycle
// eslint-disable-next-line import/no-cycle
import initAuth0 from '../../../../../globals/oidcSettings';
import session from '../../../../../utils/session';

export const replaceCharacters = (buffer, useCrypto = false) => {
  let verifier;
  if (useCrypto) {
    verifier = Base64.stringify(buffer);
  } else {
    verifier = buffer.toString('base64');
  }
  return verifier
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

const getTargetUrl = (platform = '') => {
  const { location } = window;
  const integrationKey = getIntegrationPlatformKey(platform);
  const platformDashboardUrl = getPlatformDashboardUrl(integrationKey);
  const defaultUrl = PARENT_PLATFORM.includes(platform.replace(/_MOBILE$/i, '').toUpperCase())
    ? `${location.protocol}//${location.host}`
    : platformDashboardUrl;
  let targetUrl = platformDashboardUrl || defaultUrl;

  if (location.hostname === 'localhost') {
    targetUrl = defaultUrl;
  } else if (platform === epsPlatformOlbOffline && (isAndroid() || isIos())) {
    targetUrl = getPlatformBaseUrl(integrationKey);
  }

  console.log(`targetUrl set to: ${targetUrl}`);
  if (platform === PLATFORMS.OTE) {
    targetUrl = `${targetUrl}?redirectToAM=1`;
  }
  return targetUrl;
};

const getTargetParameter = (roleName, withReturnTo, authRefreshOnly, targetUrl) => {
  const cognitoId = getCognitoId();
  const location = window.location;
  const pathname = location.pathname;
  const returnTo = withReturnTo ? pathname : '';
  let urlParams;

  if (authRefreshOnly) {
    urlParams = '?auth-refresh=1';
  } else if (returnTo) {
    urlParams = `?returnTo=${encodeURIComponent(returnTo)}`;
  } else {
    urlParams = '';
  }

  const roleParam = roleName ? `##${roleName}` : ''; // Only used when switching orgs

  // RelayState pattern: UnAuthID##RandomString##targetUrl
  return `${cognitoId}##${randomString(32)}##${targetUrl}${urlParams}${roleParam}`;
};

async function captureUrl(auth0, params) {
  let capturedUrl = null;

  await auth0.loginWithRedirect({
    async openUrl(urlreturn) {
      // Append params to the url
      const urlWithParams = new URL(urlreturn);
      Object.keys(params).forEach(key => urlWithParams.searchParams.append(key, params[key]));

      capturedUrl = urlWithParams.toString();
    }
  });

  return capturedUrl;
}

export const getOIDCIDPUrl = async (options = {}) => {
  const { platform = getPlatformByCodeOrDomain(options.navigateTo) } = options;
  const redirect_uri = options.redirect_uri ? options.redirect_uri : getTargetUrl(platform);
  const client_id = featureIsEnabled('platformcode-oidc-idp')
    ? getOidcClientIdByPlatformCode(getIntegrationPlatformKey(platform))
    : options.clientId;
  const audience = options.audience || null;

  const authorizationUrl = await initAuth0(false, { client_id, redirect_uri, audience }).then(async auth0 => {
    const params = {};
    const allowedParams = ['userType', 'isSignUp', 'connection', 'providerId'];

    allowedParams.forEach(allowedParam => {
      if (options[allowedParam]) {
        params[allowedParam] = options[allowedParam];
      }
    });
    const url = await captureUrl(auth0, params);

    return url;
  });

  return authorizationUrl;
};

const getIDPUrl = (apiUrl, roleName, options) => {
  const {
    withReturnTo = true,
    authRefreshOnly = false,
    platform = getPlatformByCodeOrDomain(options.navigateTo),
    idpType = 'idp'
  } = options;

  const targetUrl = options.targetUrl || getTargetUrl(platform);
  const targetParameter = getTargetParameter(roleName, withReturnTo, authRefreshOnly, targetUrl);
  console.info(`[getIdpLoginUrl] targetUrl = ${targetUrl}, platform = ${platform}`);
  // Note we have to re-encode the targetParameter
  const target = encodeURIComponent(encodeURIComponent(targetParameter));
  // Redirect to IDP
  return `${apiUrl}/${idpType}/profile/SAML2/Unsolicited/SSO?providerId=${platform}&target=${target}`;
};

export const getIdpLoginUrl = async (roleName, options = {}) => {
  const { location } = window;
  let url;

  if (isOIDCStandard(authSettings.idpStandard)) {
    // https://dev.oxfordreadingbuddy.oup.com/org/5171e600-76b1-11eb-897e-b9a0aa5f1582/batchId/65159e0a-52de-4956-aa0a-bab90067fc74/records/2
    // If user logs into auth0 with the above url - previously we are redirecting to the home screen as only the domainname will be sent as redirectionurl to Auth0
    // Since auth0 wont support this, we will maintian the resource url in session storage  and once user is sucecssfully authenticated, we will redirect to the resource
    const { withReturnTo = true, targetUrl, redirect_uri: redirectUri } = options;
    const audience = envSettings.idp.auth0.audience;
    if (!session.exists()) {
      if (targetUrl) {
        sessionStorage.setItem('oidcReturnToPath', targetUrl);
      } else if (withReturnTo && location.pathname && location.pathname !== '/') {
        sessionStorage.setItem('oidcReturnToPath', location.pathname);
      } else if (redirectUri && location.search) {
        sessionStorage.setItem('oidcReturnToPath', `${location.pathname}${location.search}`);
        delete options.redirect_uri;
      }
    }

    url = await getOIDCIDPUrl({
      idpUrl: authSettings.oidcAuthorizeUrl,
      clientId: authSettings.oidcClientId,
      audience,
      ...options
    });
  } else {
    url = getIDPUrl(authSettings.idpUrl, roleName, options);
  }

  if (featureIsEnabled('supervised-users') && isHubMode()) {
    url = `${url}&oup-idp-ui=${getCurrentPlatform()}`;
  }

  return url;
};

export const getRegionalIDP = (roleName, options = {}) => {
  console.log(envSettings);
  const idpName = getProviderByRegion(envSettings.regions, envSettings.sso.DefaultIdpName);
  const selectedIDP = envSettings.idp[idpName];

  // OIDC / Auth0 support
  if (isOIDCStandard(selectedIDP.standard)) {
    const { idpUrl } = envSettings.idp[idpName];
    const platformCode = getPlatformByCodeOrDomain().toLowerCase();

    const clientId = featureIsEnabled('platformcode-oidc-idp')
      ? getOidcClientIdByPlatformCode(platformCode)
      : envSettings.idp[idpName].clientId;

    const idpUrlWithPath = `${idpUrl}/authorize`;
    const audience = envSettings.idp.auth0.audience;
    return getOIDCIDPUrl({ ...options, idpUrl: idpUrlWithPath, clientId, audience });
  }

  // default legacy behaviour using SAML and Shibboleth
  options.idpType = options.userType === 'student' ? 'idp2' : 'idp';
  return getIDPUrl(selectedIDP.idpUrl, roleName, options);
};

// The optional roleName param is used by the Org Switcher and will be appended to the targetParameter:
export default function* redirectToIdP(roleName, options) {
  if (isDev()) {
    const masqueradeUserId = localStorage.getItem('masquerade-user-id');
    if (masqueradeUserId) {
      console.info('Bypass redirect to IDP. We are impersonating', masqueradeUserId);
      console.info('To disable impersonation session, remove impersonate-user localStorage value');
      return;
    }
  }

  const ltiToken = localStorage.getItem('lti-token');

  if (isLtiMode() && ltiToken) {
    console.log('LMS LTI session is active - cannot redirect to IDP (other than LMS itself)');
    return;
  }

  const impersonationToken = localStorage.getItem('impersonation-token');

  if (impersonationToken) {
    console.log('we are impersonating a user - cannot redirect to IDP');
    return;
  }

  console.log('[redirectToIdP] Starting. Attempting to collect cognitoId...');
  const cognitoId = getCognitoId();
  if (!cognitoId) {
    console.error('[redirectToIdP] Failed to retrieve Cognito ID. Unable to proceed.');
    yield put(appError('---- [redirectToIdP] Failed to retrieve Cognito ID. Unable to proceed.'));
  } else {
    console.log('[redirectToIdP] Got cognitoId. Redirecting...');
    const url = yield getIdpLoginUrl(roleName, options);
    window.location.href = url;

    // Block while page is redirected
    yield new Promise(() => {});
  }
}
