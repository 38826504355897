import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
// import getDeferredEmails from './getDeferredEmails';
import sendOicEmails from './sendOicEmails';

function* root() {
  // yield takeLatest(t.GET_DEFERRED_EMAILS_REQUEST, ({ payload: userRole }) => getDeferredEmails(userRole));
  yield takeLatest(t.SEND_OIC_EMAILS_REQUEST, ({ payload: template }) => sendOicEmails(template));
}

export default root;
