import { groupBy, isNil, omit, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button, { buttonTypes } from '../Button/Button';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import withLocalizedContent from '../../language/withLocalizedContent';
import PanelScrollContainer from '../PanelScrollContainer/PanelScrollContainer';
import PersonRepresentation from '../PersonRepresentation/PersonRepresentation';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Table, { columnTypes } from '../Table/Table';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import styles from './ImportUsersConfirmation.scss';

class ImportUsersConfirmation extends Component {
  _renderTable = (users, heading = null) => {
    const {
      localizedContent: { importUsersConfirmation: content },
      yearGroupOptions
    } = this.props;
    return (
      <Table
        columns={[
          { heading: heading || content.list_no_class_heading },
          {
            heading: content.list_status_heading,
            type: columnTypes.ICON
          }
        ]}
        rows={Object.entries(users).map(([id, { firstName, lastName, yearGroup, status: { icon } }]) => ({
          id,
          cells: [
            <PersonRepresentation
              key={0}
              thumbnailSize={thumbnailSizes.TABLE}
              initials={`${firstName.charAt(0)}${lastName.charAt(0)}`}
              name={`${firstName} ${lastName}`}
              yearGroup={Object.values(yearGroupOptions).find(option => option.value === yearGroup).name}
            />,
            <SVGIcon key={1} glyph={icon} />
          ]
        }))}
        hideTableHeading={heading === undefined}
      />
    );
  };

  render() {
    const {
      users,
      onNext,
      onBack,
      localizedContent: { importUsersConfirmation: content }
    } = this.props;
    const unassignedStudents = pickBy(users, user => isNil(user.class) || !user.class);
    const classes = groupBy(Object.values(omit(users, Object.keys(unassignedStudents))), 'class');
    return (
      <PanelScrollContainer
        heading={content.panel_heading}
        subHeading={content.panel_subheading}
        footer={
          <PopoutActionFooter>
            <div className={styles.actionsContainer}>
              <LinkWithIcon text={content.back_button_text} action={onBack} glyph={GLYPHS.ICON_LEFT} isLhs />
              <div>
                <Button text={content.next_button_text} onClick={() => onNext(true)} type={buttonTypes.PRIMARY} />
              </div>
            </div>
          </PopoutActionFooter>
        }
      >
        {Object.keys(unassignedStudents).length ? (
          <section>
            <div className="gin-left2 gin-right2">
              <h3>{content.unassigned_class_heading}</h3>
              <p>{content.unassigned_class_detail}</p>
            </div>
            <div className={styles.table}>{this._renderTable(unassignedStudents)}</div>
          </section>
        ) : null}
        {Object.keys(classes).length ? (
          <section className="gin-top3">
            <div className="gin-left2 gin-right2">
              <h3>{content.assigned_class_heading}</h3>
              <p>{content.assigned_class_detail}</p>
            </div>
            {Object.entries(classes).map(([className, students]) => (
              <div key={className} className={styles.table}>
                {this._renderTable(students, className)}
              </div>
            ))}
          </section>
        ) : null}
      </PanelScrollContainer>
    );
  }
}

ImportUsersConfirmation.propTypes = {
  users: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  yearGroupOptions: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('importUsersConfirmation')(ImportUsersConfirmation);
