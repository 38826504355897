import * as t from '../actionTypes';

export const loginManagedUserRequest = credentials => ({
  type: t.LOGIN_MANAGED_USER_REQUEST,
  payload: credentials
});

export const loginManagedUserSuccess = () => ({
  type: t.LOGIN_MANAGED_USER_SUCCESS
});

export const loginManagedUserFailure = errors => ({
  type: t.LOGIN_MANAGED_USER_FAILURE,
  payload: errors
});

export const validateManagedUserLoginInput = input => ({
  type: t.VALIDATE_MANAGED_USER_LOGIN_INPUT,
  payload: input
});

export const generateManagedUserPasswordRequest = orgId => ({
  type: t.GENERATE_MANAGED_USER_PASSWORD_REQUEST,
  payload: orgId
});

export const generateManagedUserPasswordSuccess = password => ({
  type: t.GENERATE_MANAGED_USER_PASSWORD_SUCCESS,
  payload: password
});

export const generateManagedUserPasswordFailure = errors => ({
  type: t.GENERATE_MANAGED_USER_PASSWORD_FAILURE,
  payload: errors
});

export const changeManagedUserPasswordRequest = (orgId, userId) => ({
  type: t.CHANGE_MANAGED_USER_PASSWORD_REQUEST,
  payload: { orgId, userId }
});

export const changeManagedUserPasswordSuccess = () => ({
  type: t.CHANGE_MANAGED_USER_PASSWORD_SUCCESS
});

export const changeManagedUserPasswordFailure = errors => ({
  type: t.CHANGE_MANAGED_USER_PASSWORD_FAILURE,
  payload: errors
});

export const changeManagedUserPasswordClearFailure = () => ({
  type: t.CHANGE_MANAGED_USER_PASSWORD_CLEAR_FAILURE
});

export const validateManagedUserPasswordInput = password => ({
  type: t.VALIDATE_CHANGE_MANAGED_USER_PASSWORD_INPUT,
  payload: password
});

export const validateManagedUserPasswordCompleted = errors => ({
  type: t.VALIDATE_CHANGE_MANAGED_USER_PASSWORD_COMPLETED,
  payload: errors
});

export const setManagedUser = user => ({
  type: t.SET_MANAGED_USER,
  payload: user
});

export const navigateManagedUserDownloadCard = () => ({
  type: t.MANAGED_USER_NAVIGATE_DOWNLOAD_CARD
});

/**
 * Create an action to navigate to the profile page for a managed user.
 *
 * @param {string} payload.userId
 *
 * @return {Object}
 */
export const navigateManagedUserProfile = payload => ({
  type: t.MANAGED_USER_NAVIGATE_PROFILE,
  payload
});

export const generateManagedUserSignInCardRequest = (orgId, userId) => ({
  type: t.GENERATE_MANAGED_USER_SIGN_IN_CARD_REQUEST,
  payload: { orgId, userId }
});

export const generateManagedUserSignInCardSuccess = signedURL => ({
  type: t.GENERATE_MANAGED_USER_SIGN_IN_CARD_SUCCESS,
  payload: signedURL
});

export const generateManagedUserSignInCardFailure = () => ({
  type: t.GENERATE_MANAGED_USER_SIGN_IN_CARD_FAILURE,
  payload: null
});
