import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ThemeProvider from '../../../theme/ThemeProvider';
import ErrorPage from '../../../structure/StatePages/ErrorPage/ErrorPage';
import LoadingPage from '../../../structure/StatePages/LoadingPage/LoadingPage';
import NGSLogo from '../../../structure/NGSLayout/NGSLogo/NGSLogo';
import ORBRegionLayout from '../../../structure/ORBLayout/ORBRegionLayout';

class OrbRegionDemoRoot extends Component {
  static propTypes = {
    children: PropTypes.any,
    appError: PropTypes.bool.isRequired,
    appErrorReference: PropTypes.string,
    appReady: PropTypes.bool.isRequired
  };

  static defaultProps = {
    embedded: false
  };

  componentWillUnmount() {
    document.body.removeAttribute('data-theme');
    document.body.removeAttribute('data-region');
  }

  render() {
    const { appError, appErrorReference, appReady, children } = this.props;
    const embedded = false;

    if (appError) {
      return <ErrorPage appErrorReference={appErrorReference} showHeader={!embedded} />;
    } else if (!appReady) {
      return <LoadingPage showHeader={false} customLogoComponent={<NGSLogo />} />;
    }

    return (
      <ThemeProvider themeOptions={{}}>
        <ORBRegionLayout embedded={embedded}>{children}</ORBRegionLayout>
      </ThemeProvider>
    );
  }
}

export default connect(state => {
  const {
    app: { appError, appErrorReference, appReady }
  } = state;

  return {
    appError,
    appErrorReference,
    appReady
  };
})(OrbRegionDemoRoot);
