import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function FormValidity({ render, valid }) {
  return render(valid);
}

FormValidity.propTypes = {
  // Props sourced values
  formName: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  render: PropTypes.func.isRequired,

  // State sourced values
  valid: PropTypes.bool.isRequired
};

// Extract the state of the current form instance
export default connect((state, { formName, requiredFields }) => ({
  // Must not be any invalid fields and all required fields must be valid
  valid:
    state.forms[formName].invalidFields.length === 0 &&
    state.forms[formName].changedFields.length > 0 &&
    (!requiredFields || requiredFields.every(fieldName => state.forms[formName].validFields.includes(fieldName)))
}))(FormValidity);
