import * as t from '../actionTypes';

export const bulkPasswordResetRequest = data => ({
  type: t.BULK_PASSWORD_RESET_REQUEST,
  payload: data
});

export const bulkPasswordResetSuccess = () => ({
  type: t.BULK_PASSWORD_RESET_SUCCESS
});
export const bulkPasswordResetError = () => ({
  type: t.BULK_PASSWORD_RESET_ERROR
});
export const bulkPasswordResetStateReset = () => ({
  type: t.BULK_PASSWORD_RESET_STATE_RESET
});
