import { select } from 'redux-saga/effects';
import signedFetch from '../apiCalls/util/signedFetch';
import { getProductMetadata } from '../../../utils/platform';

// @ts-ignore
export const ModifyORBLevelUrl = () => `${__API_BASE__}/orb/student-level`;
/**
 * Modify student level
 */
export default function* ModifyORBLevel(action, users, orgId) {
  const userId = yield select(state => state.identity.userId);
  const payload = { action, teacherId: userId, orgId, users };

  const subject = getProductMetadata('orb', 'subject');
  if (subject) payload.subject = subject;

  return yield signedFetch('ModifyORBLevel', ModifyORBLevelUrl(), 'PUT', JSON.stringify(payload));
}
