import { takeLatest, put, select } from 'redux-saga/effects';

import { updateDeclineOrgInviteSuccess, updateDeclineOrgInviteError } from '../../../actions/declineOrgInvite.action';

import { DECLINED_ORG_INVITE_UPDATING } from '../../../actionTypes';

import putDeclineOrgInviteApi from '../../apiCalls/declineOrgInvite/putDeclineOrgInviteApi';
import { getCurrentPlatform } from '../../../../utils/platform';

function* callDeclineOrgInviteUpdateApi() {
  const platformCode = getCurrentPlatform() || '';
  const { token, invitationStatus } = yield select(state => ({
    token: state.declineOrgInvitation.token,
    invitationStatus: state.declineOrgInvitation.invitationStatus
  }));

  console.log('[declineOrgInviteUpdate Saga] Loading Update for params: ', token);
  console.log('[declineOrgInviteUpdate Saga] Loading Update for invitation status: ', invitationStatus);
  const apiResults = yield putDeclineOrgInviteApi(token, { invitationStatus, platformCode });
  // yield new Promise(resolve => setTimeout(resolve, 2000));

  if (apiResults.error || apiResults.status !== 'success') {
    console.log('[declineOrgInviteUpdate Saga] Error updating', apiResults);
    yield put(updateDeclineOrgInviteError());
  } else {
    console.log('[declineOrgInviteUpdate Saga] Loaded update result', apiResults);
    yield put(updateDeclineOrgInviteSuccess());
  }
}

export default function* callDeclineOrgInviteUpdate() {
  console.log('[declineOrgInviteUpdate Saga] Registering action listeners');
  yield takeLatest(DECLINED_ORG_INVITE_UPDATING, callDeclineOrgInviteUpdateApi);
}
