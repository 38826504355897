import * as t from '../actionTypes';

export const pollEntitiesSynced = (payload, operationParams) => ({
  type: t.ENTITIES_SYNCED_REQUEST,
  payload,
  operationParams
});

export const pollEntitiesSyncedSuccess = (collection, ids) => ({
  type: t.ENTITIES_SYNCED_SUCCESS,
  payload: {
    collection,
    ids
  }
});

export const pollEntitiesSyncedTimeout = collection => ({
  type: t.ENTITIES_SYNCED_TIMEOUT,
  payload: collection
});

export const pollEntitiesSyncedRetry = collection => ({
  type: t.ENTITIES_SYNCED_RETRY,
  payload: collection
});

export const pollEntitiesSyncedCancel = collection => ({
  type: t.ENTITIES_SYNCED_CANCEL,
  payload: collection
});

export const pollEntitiesSyncedFlush = () => ({
  type: t.ENTITIES_SYNCED_FLUSH
});

export const pollEntitiesSyncedFailure = collection => ({
  type: t.ENTITIES_SYNCED_FAILURE,
  payload: collection
});
