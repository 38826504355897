export const INITIALISE = 'productUpload/INITIALISE';
export const PRODUCT_UPLOAD_REQUEST = 'productUpload/PRODUCT_UPLOAD_REQUEST';
export const PRODUCT_UPLOAD_SUCCESS = 'productUpload/PRODUCT_UPLOAD_SUCCESS';
export const PRODUCT_UPLOAD_FAILURE = 'productUpload/PRODUCT_UPLOAD_FAILURE';

export const initialState = {
  inProgress: true,
  loading: false,
  errorOccurred: false,
  errorMessage: null,
  success: false,
  productUploadResult: null,
  formData: {},
  selectedPlatformCode: ''
};

export default function productUploadLogs(state = initialState, action) {
  switch (action.type) {
    // Initialise action
    case INITIALISE:
      return {
        ...initialState
      };
    case PRODUCT_UPLOAD_REQUEST:
      return {
        ...state,
        inProgress: false,
        loading: true,
        errorOccurred: false,
        success: false,
        formData: action.formData,
        selectedPlatformCode: action.selectedPlatformCode
      };
    case PRODUCT_UPLOAD_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: false,
        errorOccurred: true,
        inProgress: false,
        success: false
      };
    case PRODUCT_UPLOAD_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        success: true,
        productUploadResult: action.productUploadResult,
        loading: false,
        errorOccurred: false,
        inProgress: false
      };
    default:
      return state;
  }
}

export const startProductUploadRequest = (formData, selectedPlatformCode) => ({
  type: PRODUCT_UPLOAD_REQUEST,
  formData,
  selectedPlatformCode
});

export const showsuccessMessage = productUploadResult => ({
  type: PRODUCT_UPLOAD_SUCCESS,
  productUploadResult
});

export const showproductUploadError = errorMessage => ({
  type: PRODUCT_UPLOAD_FAILURE,
  errorMessage
});

export const resetForm = () => ({
  type: INITIALISE
});
