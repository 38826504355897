import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './RangeSlider.scss';

class RangeSlider extends Component {
  setSliderStyle = value => {
    const { id } = this.props;
    const color = `linear-gradient(90deg, #002147 ${value}%, #c0c0c0 ${value}% )`;
    const element = document.getElementById(id);
    if (element) element.style.background = color;
  };

  handleOnChange = e => {
    const { onChange } = this.props;
    const value = e.target.value;
    onChange(value);
    this.setSliderStyle(value);
  };

  render() {
    const { min, max, step, id, customClassName, required, disabled, label, name, value } = this.props;

    this.setSliderStyle(value);

    return (
      <div className={styles.rangeSlider}>
        <div className={classnames(styles.slider, customClassName, disabled ? styles.disabled : '')}>
          <label className={styles.sliderLabel} htmlFor={`label-${id}`}>
            {label}
          </label>
          <input
            placeholder="range"
            type="range"
            id={id}
            name={name}
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={this.handleOnChange}
            required={required}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

RangeSlider.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** the name of the input field needs to be unique */
  name: PropTypes.string.isRequired,
  /** the inputs text label */
  label: PropTypes.string.isRequired,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.string,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func.isRequired,
  /** Allows extending the base Dropdown styling */
  customClassName: PropTypes.string,
  /** Set this to true to disable any interaction on the select field */
  disabled: PropTypes.bool,
  /** Set this to true to make field mandatory for screenreaders etc */
  required: PropTypes.bool,
  /** Set this value for the step count */
  step: PropTypes.number,
  /** Set this value for the range to start */
  min: PropTypes.number,
  /** Set this value for the range to end */
  max: PropTypes.number
};

export default RangeSlider;
