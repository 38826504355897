import { put } from 'redux-saga/effects';
import {
  updateAssignmentRequestSuccess,
  updateAssignmentRequestFailure,
  getBasketAssignmentsRequest
} from '../../../../../actions/ngsCourse';
import updateAssignmentApiCall from '../../../../apiCalls/ngs/assignments/updateAssignments';

function* updateAssignment(data) {
  const response = yield updateAssignmentApiCall(data);

  if (response.status === 'success') {
    yield put(updateAssignmentRequestSuccess(response.data));
    yield put(
      getBasketAssignmentsRequest({
        orgId: data.orgId,
        courseId: data.courseId,
        audience: 'self'
      })
    );
    return;
  }

  yield put(updateAssignmentRequestFailure(response.message));
}

export default updateAssignment;
