import signedFetch from './util/signedFetch.js';

/**
 * This takes the following params
 *
  "batchName": "TestForEACConsoleMigration",
  "numberOfTokens": 1,
  "allowedUsages": 1,
  "tokenValidFrom": "2023-07-21",
  "tokenValidTo": "2024-07-10",
  "productId": "2621cfe3-bce1-462b-a980-755c4c21ab19",
  "licenseStartDate": "2023-07-10",
  "licenseEndDate": "2023-07-10",
  "licenseType": "ROLLING",
  "totalConcurrency": 2,
  "userConcurrency": 2,
  "quantity": 1,
  "timePeriod": 1,
  "unitType": "year",
  "beginOn": "creation"
 */
export default body =>
  signedFetch('createActivationCodes', `${__API_BASE__}/activation-code-batch-create`, 'POST', body);
