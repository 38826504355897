import React from 'react';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../language/withLocalizedContent';
import PanelScrollContainer from '../PanelScrollContainer/PanelScrollContainer';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import styles from './UserRestoreSuccessPane.scss';

function UserRestoreSuccessPane({
  email = '',
  onClosePanel = () => {},
  localizedContent: { userRestoreSuccessPane: content }
}) {
  return (
    <PanelScrollContainer
      closeText={content.close_button_text}
      closeAction={onClosePanel}
      footer={<PopoutActionFooter primaryButtonText={content.primary_button_text} primaryButtonAction={onClosePanel} />}
    >
      <div className={styles.content}>
        <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.PROCESSING} title={content.panel_heading_text} />
        <div className="pad4">
          {content.email_text1}
          <span className={styles.email}>
            <em>{email}</em>
          </span>
          {content.email_text2}
        </div>
        {content.instructions_primary}
      </div>
    </PanelScrollContainer>
  );
}

UserRestoreSuccessPane.propTypes = {
  localizedContent: PropTypes.object,
  email: PropTypes.string,
  onClosePanel: PropTypes.func
};

export default withLocalizedContent('userRestoreSuccessPane')(UserRestoreSuccessPane);
