const INITIALISE_FORM = 'addToProfile/INITIALISE_FORM';
const SHOW_ADD_TO_PROFILE_FORM = 'addToProfile/SHOW_ADD_TO_LIBRARY_FORM';
export const SHOW_CONFIRMATION = 'addToProfile/SHOW_CONFIRMATION';
const SET_PRODUCT_DETAILS = 'addToProfile/SET_PRODUCT_DETAILS';
const SET_PRODUCT_FETCH_ERROR = 'addToProfile/SET_PRODUCT_FETCH_ERROR';

export const SUBMIT_FORM = 'addToProfile/SUBMIT_FORM';
export const SHOW_SUBMITTING = 'addToProfile/SHOW_SUBMITTING';
export const SHOW_ERROR_REVIEW = 'addToProfile/ERROR_REVIEW';

// Reset the form
export const RESET_FORM = 'addToProfile/RESET_FORM';

const SET_ACCESS_CODE = 'addToProfile/SET_ACCESS_CODE';
const SET_VST_ACTIVATION_CODE = 'addToProfile/SET_VST_ACTIVATION_CODE';
const VALIDATE_ACCESS_CODE = 'addToProfile/VALIDATE_ACCESS_CODE';
const VALIDATE_VST_ACCESS_CODE = 'addToProfile/VALIDATE_VST_ACCESS_CODE';
const VALIDATE_VST_ACTIVATION_CODE = 'addToProfile/VALIDATE_VST_ACTIVATION_CODE';

import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import {
  REDEEM_CODE_REGEX,
  REDEEM_VST_CODE_REGEX,
  INCLUDES_LETTERS_REDEEM_CODE_REGEX,
  INCLUDES_LETTERS_VST_REDEEM_CODE_REGEX,
  validateBothTypeOfCodes,
  isAdminRole,
  getRoleBasedOnValue,
  isRoleInvalidForUser
} from './activationCode/activationCodeUtil';

const getRedeemCodeValueFromLS = redeemCodeKey => localStorage.getItem(redeemCodeKey);
const isVstCodeValueFromLS = () => localStorage.getItem('isVstActivationCode') === 'true';

export const formStates = {
  INPUTTING: 'INPUTTING',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION',
  ERROR_REVIEWING: 'ERROR_REVIEWING'
};

export const initialState = {
  formState: formStates.INPUTTING,
  file: null,
  // Access code input and validation values
  redeemCodeValue: getRedeemCodeValueFromLS(APP_CONSTANTS.REDEEM_CODE) || '',
  accessCodeValueValidationMessage: '',
  redeemCodeValueIsValid: !!getRedeemCodeValueFromLS(APP_CONSTANTS.REDEEM_CODE),
  redeemCodeValueIsError: false,
  redeemCodeRole: '',
  errorOccurred: null,
  errorMessage: null,
  productData: {},
  productDetailsLoading: true,
  isVstCode: isVstCodeValueFromLS() || false
};

export default function addToProfile(state = initialState, action) {
  switch (action.type) {
    // Initialise action
    case INITIALISE_FORM:
      return {
        ...state
      };
    case SHOW_ADD_TO_PROFILE_FORM:
      return {
        ...state,
        formState: formStates.INPUTTING
      };
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SET_ACCESS_CODE:
      return {
        ...state,
        redeemCodeValue: action.redeemCodeValue.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3'),
        redeemCodeValueIsValid: REDEEM_CODE_REGEX.test(action.redeemCodeValue),
        redeemCodeValueIsError: !INCLUDES_LETTERS_REDEEM_CODE_REGEX.test(action.redeemCodeValue)
      };
    case VALIDATE_ACCESS_CODE:
      return {
        ...state,
        redeemCodeValueIsValid: REDEEM_CODE_REGEX.test(state.redeemCodeValue),
        redeemCodeValueIsError: !REDEEM_CODE_REGEX.test(state.redeemCodeValue)
      };
    case SHOW_SUBMITTING:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SHOW_ERROR_REVIEW:
      return {
        ...state,
        formState: formStates.ERROR_REVIEWING,
        failedEntries: action.failedEntries
      };
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        errorOccurred: action.errorOccurred,
        errorMessage: action.errorMessage,
        productDetailsLoading: false
      };
    case SET_PRODUCT_DETAILS:
      return {
        ...state,
        productData: action.products,
        productDetailsLoading: false
      };
    case SET_PRODUCT_FETCH_ERROR:
      return {
        ...state,
        productFetchError: true,
        productDetailsLoading: false
      };

    // Reset the form
    case RESET_FORM:
      return {
        ...initialState,
        redeemCodeValue: '',
        redeemCodeValueIsValid: false
      };
    case SET_VST_ACTIVATION_CODE:
      return {
        ...state,
        redeemCodeValue: REDEEM_VST_CODE_REGEX.test(action.activationCode)
          ? action.activationCode.replace(/([ASTast]{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4')
          : action.activationCode.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3'),
        redeemCodeValueIsValid:
          (REDEEM_VST_CODE_REGEX.test(action.activationCode) || REDEEM_CODE_REGEX.test(action.activationCode)) &&
          !isAdminRole(action.activationCode),
        redeemCodeValueIsError:
          !INCLUDES_LETTERS_VST_REDEEM_CODE_REGEX.test(action.activationCode) ||
          !INCLUDES_LETTERS_REDEEM_CODE_REGEX.test(action.activationCode.slice(1)) ||
          isAdminRole(action.activationCode) ||
          isRoleInvalidForUser(getRoleBasedOnValue(action.activationCode), action.role, true, true),
        isVstCode: !!getRoleBasedOnValue(action.activationCode),
        redeemCodeRole: getRoleBasedOnValue(action.activationCode),
        errorMessage: null
      };
    case VALIDATE_VST_ACTIVATION_CODE:
      return validateBothTypeOfCodes(state, action.role);
    default:
      return state;
  }
}

// Initialise action
export const initialiseForm = () => ({
  type: INITIALISE_FORM
});

// Submission and result actions
export const showProfileForm = () => ({
  type: SHOW_ADD_TO_PROFILE_FORM
});
export const submitForm = () => ({
  type: SUBMIT_FORM
});

export const validateAccessCode = () => ({
  type: VALIDATE_ACCESS_CODE
});
export const validateVstAccessCode = () => ({
  type: VALIDATE_VST_ACCESS_CODE
});

export const setAccessCode = redeemCodeValue => ({
  type: SET_ACCESS_CODE,
  redeemCodeValue
});

export const showConfirmation = (errorOccurred, errorMessage) => ({
  type: SHOW_CONFIRMATION,
  errorOccurred,
  errorMessage
});

export const setProductDetails = products => ({
  type: SET_PRODUCT_DETAILS,
  products
});

export const setProductFetchError = () => ({
  type: SET_PRODUCT_FETCH_ERROR
});

export const showErrorReview = failedEntries => ({
  type: SHOW_ERROR_REVIEW,
  failedEntries
});

// Reset the form
export const resetForm = () => ({
  type: RESET_FORM
});

export const validateVstActivationCode = role => ({
  type: VALIDATE_VST_ACTIVATION_CODE,
  role
});

export const setVstActivationCode = (activationCode, role) => ({
  type: SET_VST_ACTIVATION_CODE,
  activationCode,
  role
});
