import PropTypes from 'prop-types';
import React from 'react';
import { isExternalLink } from '../../globals/validations';
import parseMarkdown from '../../utils/markdown/markdown';
import styles from './ArticleBulletList.scss';

// Custom function will be called while parsing any "inlineLink" regex in markdown:
// This receives the matches from the parser's regex, so it's pretty raw, but usable.
function openExternalLinksInNewTab(regexMatches) {
  const href = regexMatches[2];
  return isExternalLink(href) ? '_blank' : undefined;
}

// Restrict the markdown to parse links only, and don't wrap result in a tag:
// We also supply a map of custom attributes to add to any inlineLink that it parses.
const mdConfig = { only: ['inlineLink'], inlineLink: { target: openExternalLinksInNewTab } };

function ArticleBulletList({ title: heading, bullets }) {
  return (
    <div className={styles.ArticleBulletList}>
      <h2>{heading}</h2>

      <ol className={styles.ArticleBulletList}>
        {bullets.map((text, i) => (
          // eslint-disable-next-line react/no-danger
          <li key={i} dangerouslySetInnerHTML={{ __html: parseMarkdown(text, mdConfig) }} />
        ))}
      </ol>
    </div>
  );
}

ArticleBulletList.propTypes = {
  title: PropTypes.string.isRequired, // Heading
  bullets: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ArticleBulletList;
