import moment from 'moment';
import SHA256 from 'crypto-js/sha256';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import authSettings from '../../../../globals/authSettings.js';
import { isOIDCStandard } from '../../../../globals/envSettings';
import session from '../../../../utils/session';

const getSignatureKey = (secretAccessKey, dateStamp, regionName, serviceName) => {
  const kDate = HmacSHA256(dateStamp, `AWS4${secretAccessKey}`);
  const kRegion = HmacSHA256(regionName, kDate);
  const kService = HmacSHA256(serviceName, kRegion);
  const kSigning = HmacSHA256('aws4_request', kService);
  return kSigning;
};

const encodeToRFC3986 = urlEncodedString =>
  urlEncodedString.replace(
    /[!'/()*]/g,
    char =>
      `%${char
        .charCodeAt(0)
        .toString(16)
        .toUpperCase()}`
  );

const sortQueryStringByUnicode = urlEncodedString =>
  urlEncodedString
    .split('&')
    .sort()
    .join('&');

const getAuthHeaders = (
  url,
  method,
  requestHeaders,
  requestBody,
  region,
  service,
  accessKeyId,
  secretAccessKey,
  sessionToken,
  clockSkew = 0
) => {
  const payloadHash = SHA256(requestBody);

  // Need to parse url to pull out the host & path
  // Workaround because IE does not support URL() method so
  // instead we can use a fake <a> to do more or less the same thing. Cunning.
  // const requestUrl = new URL(url);
  const requestUrl = document.createElement('a');
  requestUrl.href = url;

  // Ensure the pathname is prefixed with slash: (Browsers vary)
  const requestUrlPathname = (requestUrl.pathname.indexOf('/') === 0 ? '' : '/') + requestUrl.pathname;
  const requestQueryString =
    requestUrl.search.indexOf('?') === 0 && requestUrl.search.length > 1 ? requestUrl.search.substring(1) : '';

  const apigHost = `${authSettings.gwId}.${service}.${region}.amazonaws.com`;

  // Sort query string by Unicode then encode characters defined by RFC 3986
  const sortedAndEncodedQS = encodeToRFC3986(sortQueryStringByUnicode(requestQueryString));

  // Timestamps
  const date = moment.utc().add(clockSkew, 'milliseconds');
  const dateTimeStamp = date.format('YYYYMMDD[T]HHmmss[Z]');
  const dateStamp = date.format('YYYYMMDD');

  // Generate the payload to hash and sign
  const canonicalRequest = `${method}
/${authSettings.gwEnv}${requestUrlPathname}
${sortedAndEncodedQS}
content-type:${requestHeaders['Content-Type']}
host:${apigHost}
x-amz-date:${dateTimeStamp}
x-amz-security-token:${sessionToken}

content-type;host;x-amz-date;x-amz-security-token
${payloadHash}`;
  const canonicalRequestHash = SHA256(canonicalRequest);

  // Generate the string to sign
  const credentialScope = `${dateStamp}/${region}/${service}/aws4_request`;
  const stringToSign = `AWS4-HMAC-SHA256
${dateTimeStamp}
${credentialScope}
${canonicalRequestHash}`;

  // Generate signature
  const signingKey = getSignatureKey(secretAccessKey, dateStamp, region, service);
  const signature = HmacSHA256(stringToSign, signingKey);
  const authorization = `AWS4-HMAC-SHA256 Credential=${accessKeyId}/${credentialScope}, SignedHeaders=content-type;host;x-amz-date;x-amz-security-token, Signature=${signature}`;
  const apiKey = authSettings.cesApiKey;

  // Append the Auth headers to the request
  return {
    Authorization: authorization,
    'X-Api-Key': apiKey,
    'X-Amz-Date': dateTimeStamp,
    'X-Access-Key-Id': accessKeyId,
    'X-Secret-Access-Key': secretAccessKey,
    'X-Amz-Security-Token': sessionToken
  };
};

export default function buildAndSignExecuteApiRequest(
  url,
  method,
  requestHeaders,
  requestBody,
  accessKeyId,
  secretAccessKey,
  sessionToken,
  clockSkew
) {
  const service = 'execute-api';
  const region = 'eu-west-1';

  let authHeaders = {};
  const masqueradeUserId = localStorage.getItem('masquerade-user-id');
  const impersonationToken = localStorage.getItem('impersonation-token');
  const ltiToken = localStorage.getItem('lti-token');

  if (impersonationToken) {
    authHeaders = {
      authorization: `Impersonate ${impersonationToken}`
    };
  } else if (masqueradeUserId) {
    authHeaders = {
      authorization: `Masquerade ${masqueradeUserId}`
    };
  } else if (ltiToken) {
    authHeaders = {
      authorization: `Launch ${ltiToken}`
    };
  } else if (isOIDCStandard(authSettings.idpStandard)) {
    const sessionInfo = session.get();
    authHeaders = {
      authorization: `Bearer ${sessionInfo.oidc.idToken || sessionInfo.oidcIdToken}`
    };
  } else {
    authHeaders = getAuthHeaders(
      url,
      method,
      requestHeaders,
      requestBody,
      region,
      service,
      accessKeyId,
      secretAccessKey,
      sessionToken,
      clockSkew
    );
  }

  return {
    method,
    credentials: 'same-origin',
    headers: new Headers({
      ...requestHeaders,
      ...authHeaders
    }),
    body: requestBody,
    service,
    region
  };
}
