import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import regenerateSupervisedUserSignInCard from './regenerateSupervisedUserSignInCard.saga';

function* root() {
  yield takeLatest(t.REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_REQUEST, ({ payload }) =>
    regenerateSupervisedUserSignInCard(payload)
  );
}

export default root;
