import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

export const StatusEnum = {
  Error: 'ERROR',
  InProgress: 'INPROGRESS',
  Success: 'SUCCESS'
};

function* postCsvUserUpload(formData) {
  // @ts-ignore
  const result = yield signedFetch('csvUserUploadData', `${__API_BASE__}/upload-csv-user-data`, 'POST', formData);

  if (result.status === 'error' || result.status === 'fail') {
    yield put(actions.csvUserUploadFailure({ status: StatusEnum.Error, result }));
  } else {
    yield put(actions.csvUserUploadSuccess(StatusEnum.Success));
  }
}

export default postCsvUserUpload;
