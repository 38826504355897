import * as t from '../actionTypes';

export const createCodeBatchRequest = payload => ({
  type: t.CREATE_CODE_BATCH_REQUEST,
  payload
});

export const createCodeBatchSuccess = payload => ({
  type: t.CREATE_CODE_BATCH_SUCCESS,
  payload
});

export const createCodeBatchFailure = payload => ({
  type: t.CREATE_CODE_BATCH_FAILURE,
  payload
});
