/** Identity reducer
 *  This reducer contains state related to the current app user. This
 *  includes details of the user's account if the user is logged in.
 *  This also holds information such as the user's name, email, current
 *  organisation and role in that organisation.
 */

import { SET_IDENTITY_DETAILS, CLEAR_MISSING_FIELDS } from './sharedActions.js';

export const SET_AUTH_CREDENTIALS = 'identity/SET_AUTH_CREDENTIALS';
export const SET_AUTH_DETAILS = 'identity/SET_AUTH_DETAILS';
export const SET_RENEWED_OIDC_AUTH_CREDENTIALS = 'identity/SET_RENEWED_OIDC_AUTH_CREDENTIALS';
export const SET_OIDC_SESSION_CLOCK = 'identity/SET_OIDC_SESSION_CLOCK';

export const SET_OIDC_AUTH_CREDENTIALS = 'identity/SET_OIDC_AUTH_CREDENTIALS';
export const SET_RENEWED_AUTH_CREDENTIALS = 'identity/SET_RENEWED_AUTH_CREDENTIALS';
export const SET_REFRESH_FAILED = 'identity/SET_REFRESH_FAILED';
export const TRIGGER_LOGOUT = 'identity/TRIGGER_LOGOUT';
export const SWITCH_ORG = 'identity/SWITCH_ORG';

const initialState = {
  // Auth Provided data
  email: null,
  userId: null,
  role: null,
  currentOrganisationId: null,
  currentOrganisationLti: false,
  registrationStatus: null,
  orgRoleMap: {},
  missingFields: [],
  isSocial: false,
  firstName: null,
  // Other data
  // firstname: null,
  // lastname: null,

  auth: {
    accessKeyId: null,
    secretAccessKey: null,
    sessionToken: null,
    expiryTime: null,
    rightSuiteToken: null,
    oidcIdToken: null,
    oidcAccessToken: null,
    oidcRefreshToken: null,
    oidcExpiresIn: null,
    oidcSessionClock: null
  },

  refreshFailed: false
};

export default function identity(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AUTH_CREDENTIALS:
      return {
        ...state,
        email: action.email,
        userId: action.userId,
        registrationStatus: action.registrationStatus,
        role: action.orgRoleMap[action.currentOrganisationId] || action.currentRole,
        currentOrganisationId: action.currentOrganisationId,
        orgRoleMap: action.orgRoleMap || state.orgRoleMap,
        auth: {
          accessKeyId: action.accessKeyId,
          secretAccessKey: action.secretAccessKey,
          sessionToken: action.sessionToken,
          expiryTime: action.expiryTime,
          rightSuiteToken: action.rightSuiteToken
        }
      };

    case SET_OIDC_AUTH_CREDENTIALS:
      return {
        ...state,
        email: action.email,
        userId: action.userId,
        registrationStatus: action.registrationStatus,
        role: action.orgRoleMap[action.currentOrganisationId] || action.currentRole,
        currentOrganisationId: action.currentOrganisationId,
        orgRoleMap: action.orgRoleMap || state.orgRoleMap,
        auth: {
          ...state.auth,
          oidcIdToken: action.oidcIdToken,
          oidcAccessToken: action.oidcAccessToken,
          oidcRefreshToken: action.oidcRefreshToken,
          oidcExpiresIn: action.oidcExpiresIn
        }
      };

    case SET_RENEWED_AUTH_CREDENTIALS:
      return {
        ...state,
        auth: {
          ...state.auth,
          accessKeyId: action.accessKeyId,
          secretAccessKey: action.secretAccessKey,
          sessionToken: action.sessionToken,
          expiryTime: action.expiryTime
        }
      };

    case SET_OIDC_SESSION_CLOCK:
      return {
        ...state,
        auth: {
          ...state.auth,
          oidcSessionClock: action.sessionClock
        }
      };

    case SET_RENEWED_OIDC_AUTH_CREDENTIALS:
      return {
        ...state,
        auth: {
          ...state.auth,
          oidcAccessToken: action.accessToken,
          oidcExpiresIn: action.expiresIn
        }
      };

    case SET_IDENTITY_DETAILS: {
      // Filter roles to those that our endpoint tells us that we are a member of
      const validOrgIds = action.organisations.map(({ id }) => String(id));
      console.log(validOrgIds);
      return {
        ...state,
        isSocial: action.isSocial,
        registrationStatus: action.registrationStatus,
        missingFields: action.missingFields,
        orgRoleMap: Object.keys(state.orgRoleMap)
          .filter(id => validOrgIds.includes(id))
          .reduce((map, id) => Object.assign(map, { [id]: state.orgRoleMap[id] }), {})
      };
    }

    case CLEAR_MISSING_FIELDS:
      return {
        ...state,
        missingFields: []
      };

    case SET_REFRESH_FAILED:
      return {
        ...state,
        refreshFailed: true
      };

    case SET_AUTH_DETAILS:
      return {
        ...state,
        email: action.email,
        userId: action.userId,
        role: action.role,
        orgRoleMap: action.orgRoleMap,
        currentOrganisationId: action.currentOrganisationId,
        currentOrganisationLti: action.currentOrganisationLti,
        userName: action.userName,
        firstName: action.firstName,
        selfSelectedRole: action.selfSelectedRole,
        claimedSchool: action.claimedSchool,
        countryCode: action.countryCode
      };
    default:
      return state;
  }
}

export const setAuthCredentials = (
  accessKeyId,
  secretAccessKey,
  sessionToken,
  expiryTime,
  rightSuiteToken,
  email,
  userId,
  currentRole,
  orgRoleMap,
  currentOrganisationId,
  registrationStatus = null
) => ({
  type: SET_AUTH_CREDENTIALS,
  accessKeyId,
  secretAccessKey,
  sessionToken,
  expiryTime,
  rightSuiteToken,
  email,
  userId,
  registrationStatus,
  currentRole,
  orgRoleMap,
  currentOrganisationId
});

export const setOidcAuthCredentials = ({
  oidcIdToken,
  oidcAccessToken,
  oidcRefreshToken,
  oidcExpiresIn,
  oidcSessionClock,
  email,
  userId,
  currentRole,
  orgRoleMap,
  currentOrganisationId,
  registrationStatus = null
}) => ({
  type: SET_OIDC_AUTH_CREDENTIALS,
  oidcIdToken,
  oidcAccessToken,
  oidcRefreshToken,
  oidcExpiresIn,
  oidcSessionClock,
  email,
  userId,
  registrationStatus,
  currentRole,
  orgRoleMap,
  currentOrganisationId
});

export const setAuthUserDetails = (
  email,
  userId,
  role,
  orgRoleMap,
  currentOrganisationId,
  currentOrganisationLti,
  registrationStatus = null,
  userName,
  firstName = null,
  selfSelectedRole,
  claimedSchool,
  countryCode
) => ({
  type: SET_AUTH_DETAILS,
  email,
  userId,
  registrationStatus,
  role,
  orgRoleMap,
  currentOrganisationId,
  currentOrganisationLti,
  userName,
  firstName,
  selfSelectedRole,
  claimedSchool,
  countryCode
});

export const setRenewedAuthCredentials = (accessKeyId, secretAccessKey, sessionToken, expiryTime) => ({
  type: SET_RENEWED_AUTH_CREDENTIALS,
  accessKeyId,
  secretAccessKey,
  sessionToken,
  expiryTime
});

export const setRenewedOIDCAuthCredentials = (accessToken, expiresIn) => ({
  type: SET_RENEWED_OIDC_AUTH_CREDENTIALS,
  accessToken,
  expiresIn
});

export const setOidcSessionClock = sessionClock => ({
  type: SET_OIDC_SESSION_CLOCK,
  sessionClock
});

export const triggerLogout = withoutRedirect => ({
  type: TRIGGER_LOGOUT,
  withoutRedirect
});

// The handleOrgSwitch.saga watches SWITCH_ORG and does all the work
// roleName is only needed when switching after accepting an invitation
// because it won't be available to look up in state.identity.orgRoleMap.
export const switchOrg = (orgId, roleName = null, returnBack = false, redirectPlatform = '') => ({
  type: SWITCH_ORG,
  orgId,
  roleName, // Optional. See comment above
  returnBack,
  redirectPlatform
});

export const setRefreshFailed = () => ({
  type: SET_REFRESH_FAILED
});
