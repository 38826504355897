export default function orderUserIds(state, userIds) {
  // Make a working copy
  let ids = [...userIds];

  // Sort by name
  ids = ids.sort((a, b) => {
    const lastNameA = state?.people?.data?.[a]?.lastname || '';
    const lastNameB = state?.people?.data?.[b]?.lastname || '';
    const firstNameA = state?.people?.data?.[a]?.firstname || '';
    const firstNameB = state?.people?.data?.[b]?.firstname || '';
    return lastNameA.localeCompare(lastNameB) || firstNameA.localeCompare(firstNameB);
  });

  return ids;
}
