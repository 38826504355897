import { put, select, take, takeLatest } from 'redux-saga/effects';
import {
  clearPreselectedTeacher,
  CLOSE_FORM,
  CREATE_PLACEMENT_TEST_SESSION,
  setPlacementTestSessionNameValidated,
  setPlacementTestSessionNameOnBlurValidation,
  setPreselectedTeacher,
  SET_PLACEMENT_TEST_SESSION_NAME,
  PLACEMENT_TEST_SESSION_NAME_ON_BLUR,
  showConfirmation
} from '../../../../reducers/placementTestSessionCreate';
import checkClassNameApi from '../../../apiCalls/classes/checkClassName.api';
import createClassroomApi from '../../../apiCalls/createClassroomApi';
import { pollOrgClassesAdded } from '../dataRecency/pollOrgClasses';
import { getCurrentPlatform, isHubMode } from '../../../../../utils/platform';

function byFail(userId, userStatuses) {
  return userStatuses[userId].status !== 'SUCCESS';
}

function* preselectThisUser() {
  // Get userId from identity
  const { userId, role } = yield select(state => ({
    userId: state.identity.userId,
    role: state.identity.role
  }));

  if (role === 'TEACHER') {
    yield put(setPreselectedTeacher(userId));
  } else {
    yield put(clearPreselectedTeacher());
  }
}

function* validatePlacementTestSession(action) {
  const placementTestSessionName = action.placementTestSessionName.trim().replace(/  +/g, ' ');

  const orgId = yield select(state =>
    isHubMode() ? state.identity.currentOrganisationId : state.organisationPage.orgId
  );

  // Only start validating once the input's length is greater than zero
  if (placementTestSessionName.length > 0) {
    const response = yield checkClassNameApi(orgId, placementTestSessionName);
    const failed = !response || !response.data || response.data.exists === null;
    const exists = response && response.data && response.data.exists;
    const specialCharacterError = response?.message?.data?.name === 'Name cannot contain invalid characters.';
    const invalidPlacementTestSessionName =
      response?.data?.name === 'Name cannot contain invalid characters.' || typeof response.data === 'undefined';

    // If exists is null then the second parameter to this action indicates an API error
    yield put(
      setPlacementTestSessionNameValidated({
        exists,
        apiError: failed,
        invalidPlacementTestSessionName,
        specialCharacterError
      })
    );
  }
}

function* validatePlacementTestSessionNameOnBlur() {
  const placementTestSessionNameValue = yield select(
    state => state.placementTestSessionCreate.placementTestSessionNameValue
  );

  if (!placementTestSessionNameValue) {
    const invalidPlacementTestSessionName = true;
    const emptyInput = true;

    yield put(setPlacementTestSessionNameOnBlurValidation({ invalidPlacementTestSessionName, emptyInput }));
  }
}

function* createPlacementTestSession() {
  const { orgId, creatingTeacher, name, teachers, students, managedUsers, limit } = yield select(state => ({
    // Context IDs:
    orgId: isHubMode() ? state.identity.currentOrganisationId : state.organisationPage.orgId,
    creatingTeacher: state.identity.userId,

    // Details of placement test session to create:
    name: state.placementTestSessionCreate.placementTestSessionNameValue.trim().replace(/\s+/g, ' '),
    teachers: state.placementTestSessionCreate.selectedTeacherIds,
    [state.organisations.data[state.organisationPage.orgId] &&
    state.organisations.data[state.organisationPage.orgId].role === 'PRIMARY_SCHOOL'
      ? 'managedUsers'
      : 'students']: state.placementTestSessionCreate.selectedStudentIds,
    ...(state.placementTestSessionCreate.enableStudentLimit
      ? { limit: state.placementTestSessionCreate.studentLimit }
      : {})
  }));

  // Assemble request body to send to API:
  const payload = {
    creatingTeacher,
    name,
    teachers,
    students,
    managedUsers,
    limit,
    platformCode: getCurrentPlatform() || '',
    classType: 'PLACEMENT_GROUP'
  };

  return yield createClassroomApi(orgId, payload);
}

export default function* placementTestSessionCreate() {
  // Preselect the current user's Id
  yield preselectThisUser();

  // Spin up a listener for placement test session name value inputs
  yield takeLatest(SET_PLACEMENT_TEST_SESSION_NAME, validatePlacementTestSession);
  yield takeLatest(PLACEMENT_TEST_SESSION_NAME_ON_BLUR, validatePlacementTestSessionNameOnBlur);

  while (true) {
    yield take(CREATE_PLACEMENT_TEST_SESSION);

    const resultOfCreate = yield createPlacementTestSession();

    let failedUserIds;

    if (!resultOfCreate || resultOfCreate.error || resultOfCreate.status !== 'success') {
      // append any failed ids returned by the lambda
      if (resultOfCreate.failedIds) {
        failedUserIds = [].concat(resultOfCreate.failedIds);
      }
    } else {
      // add failed teacher and student ids to an array
      const failedTeachers = Object.keys(resultOfCreate.data.teacherStatuses || []).filter(key =>
        byFail(key, resultOfCreate.data.teacherStatuses)
      );

      const failedStudents = Object.keys(resultOfCreate.data.studentStatuses || []).filter(key =>
        byFail(key, resultOfCreate.data.studentStatuses)
      );

      failedUserIds = [].concat(failedTeachers, failedStudents);
    }

    if (resultOfCreate.status === 'success' && resultOfCreate.data && resultOfCreate.data.groupId) {
      yield pollOrgClassesAdded([resultOfCreate.data.groupId]);
    }

    yield put(
      showConfirmation(
        resultOfCreate.data && resultOfCreate.data.groupId ? resultOfCreate.data.groupId : null,
        failedUserIds
      )
    );

    yield take(CLOSE_FORM);

    // Preselect the current user's Id
    yield preselectThisUser();
  }
}
