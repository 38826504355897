import React from 'react';
import PropTypes from 'prop-types';

import styles from './OnlineTestDropdownOption.scss';

function OnlineTestDropdownOption({ defaultValue, setUpdatedValue, typeOption, title }) {
  const defaultOption = '--';

  const parameters = {
    day: {
      startIndex: 2,
      endIndex: 7,
      increaseFactor: 1,
      type: 'day',
      firstOptionsIncluded: true
    },
    hour: {
      startIndex: 2,
      endIndex: 23,
      increaseFactor: 1,
      type: 'hour',
      firstOptionsIncluded: true
    },
    minute: {
      startIndex: 5,
      endIndex: 55,
      increaseFactor: 5,
      type: 'minute',
      firstOptionsIncluded: false
    }
  };

  const getOptions = ({ startIndex, endIndex, increaseFactor, type, firstOptionsIncluded }) => {
    const options = [];
    options.push(<option value={0}>{defaultOption}</option>);

    if (firstOptionsIncluded) {
      options.push(<option value={1}>{`1 ${type}`}</option>);
    }

    for (let index = startIndex; index <= endIndex; index += increaseFactor) {
      options.push(
        <option key={index} value={index}>
          {`${index} ${type}s`}
        </option>
      );
    }

    return options;
  };

  return (
    <>
      <h2>{title}</h2>
      <select
        defaultValue={defaultValue}
        onChange={event => setUpdatedValue(parseInt(event.target.value, 10))}
        className={styles.textInputField}
      >
        {getOptions(parameters[typeOption])}
      </select>
    </>
  );
}

OnlineTestDropdownOption.propTypes = {
  title: PropTypes.string,
  typeOption: PropTypes.string,
  defaultValue: PropTypes.number,
  setUpdatedValue: PropTypes.func
};

export default OnlineTestDropdownOption;
