import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import teacherResourceConsumer from '../../../api/teacherResourceConsumer';

function* getTeacherResource(titleId, resourceId) {
  const consumer = yield teacherResourceConsumer({ product: 'premium', titleId, resourceId });
  if (consumer.status === 'SUCCESS') {
    yield put(actions.teacherResourceSuccess(consumer.data));
    return;
  }
  yield put(actions.teacherResourceFailure(consumer));
}

export default getTeacherResource;
