import React from 'react';
import PropTypes from 'prop-types';
import TableAccordion from '../../../../components/TableAccordion/TableAccordion';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';

function ContentPreviewTable({ rows, columns, success, loading, customClass }) {
  if (success && rows && rows.length > 0) {
    return <TableAccordion columns={columns} rows={rows} customClass={customClass} />;
  }
  if (loading) {
    return <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.LOADING} />;
  }

  return null;
}

ContentPreviewTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  success: PropTypes.bool,
  loading: PropTypes.bool,
  customClass: PropTypes.string
};

export default ContentPreviewTable;
