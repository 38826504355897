import signedFetch from './util/signedFetch.js';

/**
 * This updated the specified parts of a class. The data payload can contain any of the following:
 *  - name: String - New Class name
 *  - teachers: Array<Int> - Teacher IDs to be added
 *  - students: Array<Int> - Student IDs to be added
 *  - limit: Int - New Student limit value. Disable (0) or enable (1-n)
 */
export default (orgId, data) =>
  signedFetch('createClass', `${__API_BASE__}/org/${encodeURIComponent(orgId)}/class`, 'POST', data);
