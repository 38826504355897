import { put } from 'redux-saga/effects';
import createUserSchema from '../../../../../../static/schema/draft-4/create-user-request-body.json';
import actions from '../../../../actions';
import * as t from '../../../../actionTypes';
import checkGlobalUserEmailApi from '../../../apiCalls/users/checkGlobalUserEmail.api';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';

function* validateOrgAdminInput(input) {
  const errors = yield validateInputUsingSchema(createUserSchema, t.VALIDATE_ORG_ADMIN_COMPLETED, input);
  if (input.email !== undefined && !errors.email) {
    yield put(actions.storeOrgAdminInputEmailLoading());
    const response = yield checkGlobalUserEmailApi(input.email);
    if (response.status === 'success') {
      const { exists } = response.data;
      yield put(actions.storeOrgAdminInputEmailAvailability(exists));
      return {
        ...errors,
        email: exists
      };
    }
  }
  return errors;
}

export default validateOrgAdminInput;
