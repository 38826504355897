import classnames from 'classnames';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import withLocalizedContent from '../../language/withLocalizedContent';
import ProgressText from '../ProgressText/ProgressText';
import styles from './NavigatorCard.scss';

class NavigatorCard extends Component {
  static checkForCurrentLevel = (skippedLevel, viewingCurrentLevel) => {
    // Checks whether the current level is the student's active level.
    let isCurrentLevel;
    if (!viewingCurrentLevel) {
      isCurrentLevel = 'old';
    } else if (viewingCurrentLevel) {
      isCurrentLevel = 'current';
    } else if (!skippedLevel) {
      isCurrentLevel = 'skipped';
    }
    return isCurrentLevel;
  };

  handleEnterPress = (e, direction) => {
    const { onNavigateLeft, onNavigateRight } = this.props;
    if (e.key === 'Enter' && direction === 'left') {
      onNavigateLeft();
    } else if (e.key === 'Enter' && direction === 'right') {
      onNavigateRight();
    }
  };

  returnText = (navigationData, datacardData, isCurrentLevel, numberOfAttempt) => {
    const { localizedContent } = this.props;

    // The following if block checks whether the currently viewed level is old (completed),
    // current, skipped, or is an old (uncompleted) attempt.
    if (isCurrentLevel === 'old' && !datacardData.skipped_level && !navigationData.previous_attempt) {
      const i18n = localizedContent.navigatorCard;
      const completenessKey = datacardData.completeness === false ? 'moved_on' : 'completed_on';

      return (
        <div>
          {this.renderNavigation(navigationData)}
          <div className={styles.navigatorCard}>
            {navigationData.date_completed ? (
              <Button
                id="completed"
                type="PRIMARY_THIN"
                tabIndex="-1"
                text={i18n[completenessKey].replace(
                  '{date}',
                  moment(navigationData.date_completed).format('DD/MM/YYYY')
                )}
              />
            ) : null}
          </div>
        </div>
      );
    }
    if (isCurrentLevel === 'current' && !datacardData.skipped_level && !navigationData.previous_attempt) {
      return (
        <div>
          {this.renderNavigation(navigationData)}
          <div className={styles.navigatorCard}>
            <h5 className={styles.subtitleGrey}>{navigationData.level_expected}</h5>
          </div>
        </div>
      );
    }
    if (isCurrentLevel === 'skipped' && !datacardData.skipped_level && !navigationData.previous_attempt) {
      return (
        <div>
          <div className={styles.navigatorFormat}>
            {this.renderNavigation(navigationData)}
            <div className={styles.navigatorCard}>
              <ProgressText />
            </div>
          </div>
        </div>
      );
    }
    if (datacardData.skipped_level) {
      return (
        <div>
          <div className={styles.navigatorFormat}>{this.renderNavigation(navigationData)}</div>
        </div>
      );
    }
    if (navigationData.previous_attempt) {
      return (
        <div className={styles.previousAttemptHeader}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */}
          <h1 className={styles.previousAttemptHeaderText} role="button" tabIndex={0}>
            {`${numeral(numberOfAttempt).format('0o')} attempt (${moment(
              navigationData.previous_attempt_date_start
            ).format('Do')} ${moment(navigationData.previous_attempt_date_start).format('MMMM')} - ${moment(
              navigationData.previous_attempt_date_end
            ).format('Do')} ${moment(navigationData.previous_attempt_date_end).format('MMMM')})`}
          </h1>
        </div>
      );
    }
    return null;
  };

  renderNavigation(navigationData) {
    const { navigateLeftDisabled = false, navigateRightDisabled = false, onNavigateLeft, onNavigateRight } = this.props;

    return (
      <div className={styles.navigatorCard}>
        <div
          className={classnames({
            [styles.leftArrow]: true,
            [styles.noCursor]: navigateLeftDisabled,
            [styles.clickCursor]: !navigateLeftDisabled
          })}
          tabIndex={0}
          role="button"
          onKeyPress={e => this.handleEnterPress(e, 'left')}
          onClick={onNavigateLeft}
        >
          <SVGIcon
            glyph={GLYPHS.ICON_LEFT_THICK}
            className={classnames({
              [styles.pointerSvg]: !navigateLeftDisabled,
              [styles.hidden]: navigateLeftDisabled
            })}
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */}
        <h2 className={styles.progressHeader} role="button" tabIndex={0}>
          {navigationData.current_level_name}
        </h2>
        <div
          className={classnames({
            [styles.rightArrow]: true,
            [styles.noCursor]: navigateRightDisabled,
            [styles.clickCursor]: !navigateRightDisabled
          })}
          tabIndex={0}
          role="button"
          onKeyPress={e => this.handleEnterPress(e, 'right')}
          onClick={onNavigateRight}
        >
          <SVGIcon
            glyph={GLYPHS.ICON_RIGHT_THICK}
            className={classnames({
              [styles.pointerSvg]: !navigateRightDisabled,
              [styles.hidden]: navigateRightDisabled
            })}
          />
        </div>
      </div>
    );
  }

  render() {
    // eslint-disable-next-line camelcase
    const { navigation_data, datacard_data, number_of_attempt } = this.props;
    return this.returnText(
      navigation_data,
      datacard_data,
      // eslint-disable-next-line camelcase
      NavigatorCard.checkForCurrentLevel(datacard_data.skipped_level, navigation_data.viewing_current_level),
      number_of_attempt
    );
  }
}

NavigatorCard.propTypes = {
  navigateLeftDisabled: PropTypes.bool,
  navigateRightDisabled: PropTypes.bool,
  onNavigateRight: PropTypes.func,
  onNavigateLeft: PropTypes.func,
  datacard_data: PropTypes.shape({
    /** Number of days the student has spent at this level */
    days_at_level: PropTypes.number.isRequired,
    /** Expected number of days the student is to spend at this level */
    expected_days_at_level: PropTypes.number.isRequired,
    /** Amount of quizzes the student has passed */
    quizzes_passed: PropTypes.number.isRequired,
    /** Expected number of quizzes passed */
    quizzes_expected: PropTypes.number.isRequired,
    /** Number of quizzes the completed */
    quizzes_completed: PropTypes.number.isRequired,
    /** Quiz score average, in percent */
    quizzes_average: PropTypes.string.isRequired,
    /** Whether the student has skipped the level currently being viewed */
    skipped_level: PropTypes.bool.isRequired,
    /** If the currently viewed level has been reset, when was the date & time of the last reset */
    reset_date: PropTypes.string
  }),
  navigation_data: PropTypes.shape({
    /** Whether the currently viewed data is of a previous attempt at a level */
    previous_attempt: PropTypes.bool,
    /** If the viewed data is a previous attempt at when did the attempt start & end */
    previous_attempt_date_start: PropTypes.string,
    previous_attempt_date_end: PropTypes.string,
    date_completed: PropTypes.string,
    /** The array of levels that the student has attempted */
    /** Number of the the level currently being viewed by the user */
    currently_viewed_level: PropTypes.number.isRequired,
    /** The current level of the actual student being viewed */
    current_level: PropTypes.number.isRequired,
    /** The current level name of the actual student being viewed */
    current_level_name: PropTypes.string.isRequired,
    /** If the currently viewed level is completed, at what date was it completed */
    current_level_completion_date: PropTypes.string,
    /** The level of expected attainment of the student */
    level_expected: PropTypes.string.isRequired,
    viewing_current_level: PropTypes.bool.isRequired
  }),
  number_of_attempt: PropTypes.number,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('navigatorCard', 'sharedTerms')(NavigatorCard);
