import * as t from '../actionTypes.js';

export const fetchLinkedProductsRequest = products => ({
  type: t.NGI_FETCH_LINKED_PRODUCTS_REQUEST,
  payload: products
});

export const fetchLinkedProductsSuccess = products => ({
  type: t.NGI_FETCH_LINKED_PRODUCTS_SUCCESS,
  payload: products
});

export const fetchLinkedProductsFailure = error => ({
  type: t.NGI_FETCH_LINKED_PRODUCTS_FAILURE,
  payload: error
});
