import PropTypes from 'prop-types';
import React from 'react';
import Toggle from '@oup/shared-front-end/src/components/Toggle/Toggle';
import NumberInput from '../NumberInput/NumberInput';
import styles from './ToggleWithNumberInput.scss';

function ToggleWithNumberInput({
  toggleId,
  toggleLabel,
  toggleValue,
  toggleOnChange,

  numberInputValue,
  numberInputId,
  numberInputLabel,
  numberInputOnChange,
  numberInputMin,
  numberInputMax
}) {
  return (
    <div className={styles.togglerWrapper}>
      <div>
        <Toggle className={styles.togglerButton} onChange={toggleOnChange} toggled={toggleValue} id={toggleId} />
        <label htmlFor={numberInputId}>{toggleLabel}</label>
      </div>
      {toggleValue && (
        <NumberInput
          id={numberInputId}
          value={numberInputValue}
          label={numberInputLabel}
          labelHidden
          onChange={numberInputOnChange}
          min={numberInputMin}
          max={numberInputMax}
        />
      )}
    </div>
  );
}

ToggleWithNumberInput.propTypes = {
  // Toggle props
  /** the id of the toggle field needs to be unique */
  toggleId: PropTypes.string.isRequired,
  /** The field label for the ToggleSwitch */
  toggleLabel: PropTypes.string.isRequired,
  /** Actual true/false value of the toggle */
  toggleValue: PropTypes.bool.isRequired,
  /** function that will be called if the ToggleSwitch is touched */
  toggleOnChange: PropTypes.func.isRequired,

  /** the id of the input field needs to be unique */
  numberInputId: PropTypes.string.isRequired,
  /** Actual numeric value of the number input */
  numberInputValue: PropTypes.number.isRequired,
  /** The field label for the NumberInput */
  numberInputLabel: PropTypes.string.isRequired,
  /** function that will be called if the NumberInput is touched */
  numberInputOnChange: PropTypes.func.isRequired,
  /** the minumum limit for numeric fields */
  numberInputMin: PropTypes.number,
  /** the maximum limit for numeric fields */
  numberInputMax: PropTypes.number
};

export default ToggleWithNumberInput;
