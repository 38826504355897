import { select, put } from 'redux-saga/effects';
import { getSchoolDialReport } from '../gradebook/getSchoolDialReport';
import actions from '../../../../actions';

const MAX_BATCH_REQUEST = 5;

function* getMatData(orgIds, locationId) {
  const step = orgIds.length < MAX_BATCH_REQUEST ? orgIds.length : MAX_BATCH_REQUEST;

  for (let i = 0; i < orgIds.length; i += step) {
    const reports = [];
    for (let j = 0; j < step; j += 1) {
      if (orgIds[i + j]) {
        reports.push(getSchoolDialReport(orgIds[i + j], locationId));
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const report of reports) {
      yield report;
    }
  }

  const orgsDialsData = Object.values(yield select(state => state.gradebookSchoolDialReport.data));
  const orgsSummary = {
    attainment: 0,
    progress: 0,
    engagement: 0
  };

  orgsDialsData.forEach(orgDialData => {
    orgsSummary.attainment += orgDialData.attainment;
    orgsSummary.progress += orgDialData.progress;
    orgsSummary.engagement += orgDialData.engagement;
  });

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(orgsSummary)) {
    if (value > 0) {
      orgsSummary[key] = value / orgsDialsData.length;
    }
  }

  yield put(actions.multiSchoolDialReportUpdateSummary(orgsSummary));

  yield put(actions.multiSchoolDialReportAllOrgsLoaded());
}

export default getMatData;
