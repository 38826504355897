import { put } from 'redux-saga/effects';
import { loadOfflineUnits } from '../../../../actions/offlineContentPlayer';

function filterUnits(units, searchWord) {
  if (!searchWord) {
    return units;
  }
  return units.filter(({ name, description }) => {
    if (
      name?.toLowerCase().includes(searchWord.toLowerCase()) ||
      description?.toLowerCase().includes(searchWord.toLowerCase())
    ) {
      return true;
    }
    return false;
  });
}

function* getOfflineUnits({ searchWord }) {
  const storedValue = localStorage.getItem('offline_units');
  const units = storedValue ? JSON.parse(storedValue) : [];

  const filteredUnits = filterUnits(units, searchWord);
  yield put(loadOfflineUnits(filteredUnits));
}

export default getOfflineUnits;
