import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import content from '../../utils/cmsContent';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import TextInputField from '../TextInputField/TextInputField';
import TextInputLabel from '../TextInputLabel/TextInputLabel';
import styles from './NumberInput.scss';

// const content = {
//   appComponents: {
//     archived_button: 'Archived',
//     edit_button: 'Edit',
//     search_button: 'Search',
//     filter_button: 'Filter',
//     sort_button: 'Sort',
//     help_and_support_button: 'Help & Support',
//     settings_button: 'Settings',
//     ally_subtract_1: 'Subtract 1',
//     ally_add_1: 'Add 1',
//     previous_page_text: 'Previous page',
//     aria_page_text: 'Page',
//     next_page_text: 'Next page',
//     password_error_text: ' Password must contain 1 uppercase, 1 lowercase and be between 6-15 characters',
//     added_to_class_success: 'Added to class',
//     added_to_class_warning: 'Not added to class',
//     removed_from_class_success: 'Removed from class',
//     removed_from_class_warning: 'Not removed from class',
//     error_loading_search_text: 'There was an error loading search results',
//     close_text: 'Close',
//     label_show_text: 'Show',
//     search_text: 'Search',
//     results_text: 'results',
//     spaces_avalible_text: 'spaces available',
//     selected_text: 'selected',
//     next_text: 'Next',
//     skip_text: 'Skip'
//   }
// };

// Helper for ensuring a value is between min/max limits, if any:
function isBetween(value, min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY) {
  return Math.min(Math.max(parseInt(value, 10) || 0, min), max);
}

export default class NumberInput extends Component {
  // Handle UP/DOWN keys:
  onKeyDown = e => {
    if (e.keyCode === 38) {
      this.doIncrement(e);
      e.preventDefault();
    } else if (e.keyCode === 40) {
      this.doDecrement(e);
      e.preventDefault();
    }
  };

  doDecrement = () => {
    const { onChange, value, min, max } = this.props;
    onChange(isBetween(value - 1, min, max));
  };

  doIncrement = () => {
    const { onChange, value, min, max } = this.props;
    onChange(isBetween(value + 1, min, max));
  };

  render() {
    const CMS = content.appComponents || {};
    const { id, label, labelHidden, value, placeholder, onChange, min, max } = this.props;

    // These could be used to change button style when value has reached min or max limit:
    // const isAtMinLimit =
    //   (parseInt(value, 10) || 0) <= (min === undefined ? Number.NEGATIVE_INFINITY : parseInt(min, 10));
    // const isAtMaxLimit =
    //   (parseInt(value, 10) || 0) >= (max === undefined ? Number.POSITIVE_INFINITY : parseInt(max, 10));

    return (
      <div className={styles.smallNumberInput}>
        <TextInputLabel id={id} label={label} labelHidden={labelHidden} />
        <div
          className={classnames(styles.fieldWrapper, {
            [styles.labelHidden]: labelHidden
          })}
        >
          <button
            id={`decrement-${id}`}
            type="button"
            onClick={this.doDecrement}
            onKeyDown={this.onKeyDown}
            aria-controls={id}
          >
            <SVGIcon glyph={GLYPHS.ICON_MINUS} />
            <span className="a11y-hide">{CMS.ally_subtract_1}</span>
          </button>

          <TextInputField
            id={id}
            placeholder={placeholder || ''}
            type="number"
            value={isBetween(value, min, max)}
            min={min}
            max={max}
            onChange={onChange}
            onKeyDown={this.onKeyDown}
            aria={{ live: 'assertive' }} // aria-live="assertive" means value will be spoken whenever it changes
          />

          <button
            type="button"
            id={`increment-${id}`}
            onClick={this.doIncrement}
            onKeyDown={this.onKeyDown}
            aria-controls={id}
          >
            <SVGIcon glyph={GLYPHS.ICON_PLUS} />
            <span className="a11y-hide">{CMS.ally_add_1}</span>
          </button>
        </div>
      </div>
    );
  }
}

NumberInput.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** the inputs text label */
  label: PropTypes.string.isRequired,
  /** The label can be visible to screen readers only */
  labelHidden: PropTypes.bool,
  /** the inputs placeholder text */
  placeholder: PropTypes.string,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.any,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func.isRequired,
  /** the minumum limit for numeric fields */
  min: PropTypes.number,
  /** the maximum limit for numeric fields */
  max: PropTypes.number
};
