import * as t from '../actionTypes';

export const initialState = {
  isSuccess: false,
  isLoading: false,
  orgs: [],
  loadedOrgs: 0,
  failedOrgs: 0,
  locationId: null,
  summary: {
    attainment: 0,
    progress: 0,
    engagement: 0
  }
};

export default function matReportSummary(state = initialState, { type, payload }) {
  switch (type) {
    case t.MULTI_SCHOOL_DIAL_REPORT_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        orgs: payload.orgs,
        locationId: payload.locationId
      };
    case t.MULTI_SCHOOL_DIAL_REPORT_FAILURE:
      return {
        ...state,
        failedOrgs: state.failedOrgs + 1
      };
    case t.MULTI_SCHOOL_DIAL_REPORT_ALL_ORGS_ARE_LOADED:
      return {
        ...state,
        isSuccess: true,
        isLoading: false
      };
    case t.MULTI_SCHOOL_DIAL_REPORT_UPDATE_LOADED_ORGS:
      return {
        ...state,
        isSuccess: true,
        loadedOrgs: state.loadedOrgs + 1
      };
    case t.MULTI_SCHOOL_DIAL_REPORT_UPDATE_SUMMARY:
      return {
        ...state,
        isLoading: false,
        summary: payload
      };
    default:
      return state;
  }
}
