import passwordReset from '../../../../../../static/schema/draft-4/password-reset-request-body.json';
import * as t from '../../../../actionTypes';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';

function* validateResetPasswordInput(password) {
  const errors = yield validateInputUsingSchema(passwordReset, t.RESET_PASSWORD_VALIDATE_INPUT_COMPLETED, {
    password
  });

  return errors;
}

export default validateResetPasswordInput;
