import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { orgRoleDescriptions } from '@oup/shared-node-browser/org';
import TableAccordion, { columnTypes } from '../TableAccordion/TableAccordion';
import { rowTypes } from '../Table/Table';
import TimeTag from '../TimeTag/TimeTag';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import CopyToClipboardButton from '../CopyToClipboardButton/CopyToClipboardButton';
import OrganizationStatus from '../OrganizationStatus/OrganizationStatus';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import styles from './ResultComponent.scss';

function NoResults({ localizedContent }) {
  return (
    <div className="grid">
      <div className="row">
        <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
          <p id="searchResults-orgs-noResults" className="gin-top1 gin-bot1">
            {localizedContent?.no_search_results_text}
          </p>
        </div>
      </div>
    </div>
  );
}

NoResults.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

const getRows = (localizedContent, resultIds, orgs, orgsDataRecency) =>
  resultIds.map(id => {
    const org = orgs[id] || {};
    const isProcessing = orgsDataRecency?.syncing && orgsDataRecency?.ids.includes(id);

    const cells = [
      <TextLink
        key={0}
        to={{ pathname: `/dashboard/org/${id}/organizations`, state: { keepSearchState: true } }}
        component={RouterLink}
      >
        {org.name}
      </TextLink>,
      <div key={2} className="flex-row align-items-center">
        <small className={styles.orgId}>
          <pre>{id}</pre>
        </small>
        <CopyToClipboardButton classCode={id} label={localizedContent.action_copy_organization_id} />
      </div>,
      <pre key={1}>{org.orgUniqueId || '-'}</pre>,
      <span key={3}>{orgRoleDescriptions[org.orgType] || org.orgType || '-'}</span>,
      <span key={4}>{org.orgCountryCode}</span>,
      <OrganizationStatus key={5} registrationStatus={org.registrationStatus} status={org.orgStatus} />,
      <TimeTag key={6} dateString={org?.createdDate} />,
      <LinkWithIcon
        key={7}
        to={`/dashboard/org/${id}/organizations`}
        customClassName={styles.link}
        text={localizedContent.action_view_organization}
        externalLink
        glyph={GLYPHS.ICON_LINK}
      />
    ];

    return {
      id: `org${id}`,
      cells,
      ...(isProcessing ? { type: rowTypes.PROCESSING } : {})
    };
  });

function ResultComponent({ localizedContent, resultIds, orgs, orgsDataRecency, showTruncatedResultsNotice }) {
  const [initialShowTruncatedResultNotice] = useState(showTruncatedResultsNotice);

  const columns = [
    {
      heading: localizedContent.table_head_organization,
      type: columnTypes.STATUS
    },
    {
      heading: localizedContent.table_head_id,
      type: columnTypes.TEXT
    },
    {
      heading: localizedContent.table_head_code,
      type: columnTypes.TEXT
    },
    {
      heading: localizedContent.table_head_type,
      type: columnTypes.TEXT
    },
    {
      heading: localizedContent.table_head_country_code,
      type: columnTypes.TEXT
    },

    {
      heading: localizedContent.table_head_status,
      type: columnTypes.TEXT
    },
    {
      heading: localizedContent.table_head_created_date,
      type: columnTypes.TEXT
    },
    {
      heading: '',
      type: columnTypes.BUTTON
    }
  ];

  const hasResults = resultIds.length > 0;
  const rows = getRows(localizedContent, resultIds, orgs, orgsDataRecency);

  return hasResults ? (
    <>
      {initialShowTruncatedResultNotice && (
        <p className="gin-bot2 flex-row align-items-center">
          <SVGIcon className={styles.noticeIcon} glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
          {localizedContent.search_by_user_email_domain_note}
        </p>
      )}
      <TableAccordion customClass={styles.orgsTable} columns={columns} rows={rows} />
    </>
  ) : (
    <NoResults localizedContent={localizedContent} />
  );
}

ResultComponent.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgs: PropTypes.array.isRequired,
  resultIds: PropTypes.array.isRequired,
  orgsDataRecency: PropTypes.object.isRequired,
  showTruncatedResultsNotice: PropTypes.bool.isRequired
};

export default ResultComponent;
