import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import styles from './SearchComponent.scss';

function SearchComponent({ localizedContent, setSearchBy, setSearchTerm, handleSearch, searchTerm, searchBy }) {
  const [state, setState] = useState({
    searchSource: searchBy,
    value: searchTerm
  });

  const handleOnChange = (value, searchSource) => setState({ searchSource, value });

  const handleSubmit = e => {
    e.preventDefault();
    setSearchBy(state.searchSource);
    setSearchTerm(state.searchSource, state.value || null);
  };

  useEffect(() => {
    if (searchBy && searchTerm !== '') handleSearch();
  }, [searchTerm, searchBy]);

  const isFocused = ctx => ctx === state.searchSource;

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames('gin-bot2 flex flex-row justify-content-between', styles.searchContainer)}
      noValidate
    >
      <TextInputWithButton
        placeholder={localizedContent.content_search_org_fields}
        value={isFocused('oupOrgs') ? state.value : ''}
        name="oupOrgs"
        onChange={handleOnChange}
      />
      <div>
        <TextInputWithButton
          placeholder={localizedContent.content_search_org_user_email}
          value={isFocused('oupOrgsEmail') ? state.value : ''}
          name="oupOrgsEmail"
          onChange={handleOnChange}
        />
      </div>
    </form>
  );
}

SearchComponent.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setSearchBy: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searchBy: PropTypes.string.isRequired
};

export default SearchComponent;
