import { useState, useEffect } from 'react';
import moment from 'moment';

export default function useCalendarDisplay() {
  const [toggleInput, setToggleInput] = useState({
    licenceStartDateModal: false,
    licenceEndDateModal: false,
    tokenValidityStartModal: false,
    tokenValidityEndModal: false
  });

  const excludeClasses = [
    'tokenValidityStartDate',
    'tokenValidityEndDate',
    'licenceStartDate',
    'licenceEndDate',
    'calendarHeader',
    'arrowLeft',
    'arrowRight'
  ];
  const { licenceStartDateModal, licenceEndDateModal, tokenValidityStartModal, tokenValidityEndModal } = toggleInput;

  const changeAllToFalse = () => {
    setToggleInput(prevState => ({
      ...prevState,
      licenceStartDateModal: false,
      licenceEndDateModal: false,
      tokenValidityStartModal: false,
      tokenValidityEndModal: false
    }));
  };

  const changeAllToFalseIfNotInput = e => {
    e.preventDefault();
    if (!excludeClasses.includes(e.target.id)) changeAllToFalse();
  };

  useEffect(() => {
    document.body.addEventListener('click', changeAllToFalseIfNotInput);
    return () => {
      document.body.removeEventListener('click', changeAllToFalseIfNotInput);
    };
  }, []);

  const toggleCalendarDisplay = (inputName, changedState) => {
    setToggleInput(prevState => ({
      ...prevState,
      [`${inputName}`]: changedState
    }));
  };

  const convertDate = paramDate => {
    if (!paramDate) return '';
    return moment(paramDate).format('DD/MM/YYYY');
  };
  return {
    toggleCalendarDisplay,
    changeAllToFalse,
    convertDate,
    licenceStartDateModal,
    licenceEndDateModal,
    tokenValidityStartModal,
    tokenValidityEndModal
  };
}
