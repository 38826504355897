import * as t from '../actionTypes';

const initialState = {
  submitting: false,
  success: false,
  failure: false,
  locked: false,
  errors: {
    orgUniqueId: false,
    username: false,
    password: false
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.LOGIN_MANAGED_USER_REQUEST:
      return {
        ...initialState,
        submitting: true
      };
    case t.LOGIN_MANAGED_USER_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true
      };
    case t.LOGIN_MANAGED_USER_FAILURE:
      return {
        ...state,
        submitting: false,
        failure: true
      };
    case t.VALIDATE_MANAGED_USER_LOGIN_INPUT:
      return {
        ...state,
        failure: false
      };
    case t.VALIDATE_MANAGED_USER_LOGIN_COMPLETED:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload
        }
      };
    default:
      return state;
  }
};
