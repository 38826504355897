import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { validColors } from '../../globals/colors';
import UrlControlledTabs from '../UrlControlledTabs/UrlControlledTabs';
import styles from './TabsAdvanced.scss';

function TabsAdvanced({ pathname, tabName, items, backgroundColor, truncate }) {
  return (
    <div className={styles.tabsAdvanced}>
      <UrlControlledTabs
        pathname={pathname}
        tabName={tabName}
        backgroundColor={backgroundColor}
        items={items}
        truncate={truncate}
      />
    </div>
  );
}

TabsAdvanced.propTypes = {
  /** The list of content items to be displayed */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** The parameter part of the url for the item */
      urlName: PropTypes.string.isRequired,
      /** This should represent any second-level config to store within the tab */
      selected: PropTypes.string,
      /** Content to displayed in the tab label for this item */
      tabText: PropTypes.string.isRequired,
      /** A second line of content to displayed in the tab label */
      tabTextCaption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      /** The actual content for the item to be displayed under the tab/carousel controls */
      panelContent: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]).isRequired,
      /** By default, each panel will be given a <h2 className="a11y-hide"> */
      skipA11yPanelHeading: PropTypes.bool
    })
  ).isRequired,
  /** This is the current URL location path without the tabName parameter value */
  pathname: PropTypes.string.isRequired,
  /** This is the current tabName url parameter given using React Router to the parent component */
  tabName: PropTypes.string,
  /** The color to be used for the background of the tab list */
  backgroundColor: PropTypes.oneOf(validColors).isRequired,
  /** The styling will change if this is set  to true, the tab buttons will be smaller and title will be truncated */
  truncate: PropTypes.bool
};

export default withRouter(TabsAdvanced);
