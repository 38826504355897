import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Home page components
import TopRow from './parts/TopRow/TopRow.js';
import MiddleRow from './parts/MiddleRow/MiddleRow.js';
import BottomRow from './parts/BottomRow/BottomRow.js';

import cmsContent from '../../utils/cmsContent.js';

class Homepage extends Component {
  constructor() {
    super();
    this.state = { CMS: {} };
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.homepage || {} });
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.homepage || {} });
  }

  render() {
    const { CMS } = this.state;

    return (
      <div>
        <Helmet title={CMS.pageTitle} />
        {/* The first row of the home page */}
        <TopRow />
        {/* The second row of the home page */}
        <MiddleRow />
        {/* The third row of the home page */}
        <BottomRow />
      </div>
    );
  }
}

Homepage.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  cmsReady: PropTypes.bool.isRequired
};

export default connect(state => ({
  cmsReady: state.app.cmsReady
}))(Homepage);
