import * as t from '../actionTypes';

export const resendInvitationRequest = payload => ({
  type: t.RESEND_INVITATION_REQUEST,
  payload
});

export const resendInvitationSuccess = () => ({
  type: t.RESEND_INVITATION_SUCCESS
});

export const resendInvitationFailure = errors => ({
  type: t.RESEND_INVITATION_FAILURE,
  payload: errors
});

export const resendInvitationReset = () => ({
  type: t.RESEND_INVITATION_RESET
});
