import moment from 'moment';
import { parse as parseQueryString } from 'query-string';

import { isLocal } from '../../../../../../../globals/envSettings';
import session from '../../../../../../../utils/session';

// eslint-disable-next-line import/no-cycle
import store from '../../../../../../store';
import { setRefreshFailed, triggerLogout } from '../../../../../../reducers/identity.reducer';

import getCognitoId from '../../../../auth/getCredentials/getCognitoId.saga';
import { getIdpLoginUrl } from '../../../../auth/getCredentials/redirectToIdP.saga';
import getSAML from '../../../../../apiCalls/auth/getSAML.api';

const IFRAME_ID = 'authRefresher';
let failTimeoutId;

function handleRefreshFailure() {
  console.log('[handleRefreshFailure] Auth refresh failed. User must log in again');

  // Delete iframe
  document.body.removeChild(document.getElementById(IFRAME_ID));

  // Set up message for when expiry hits
  const expiryTime = session.get().saml.expiryTime;
  const delay = moment(expiryTime)
    .subtract(10, 'seconds')
    .diff();

  console.log(`[handleRefreshFailure] User will be forced to refresh in ${delay}ms`);
  setTimeout(() => {
    store.dispatch(setRefreshFailed());
    store.dispatch(triggerLogout(true));
  }, delay);
}

async function performAuthRefresh() {
  console.log('[performAuthRefresh]');

  // Generate an iframe
  const iframe = document.createElement('iframe');
  iframe.id = IFRAME_ID;
  const url = await getIdpLoginUrl(null, { withReturnTo: false, authRefreshOnly: true });
  iframe.src = url;

  console.debug(`[performAuthRefresh] IDP URL is set to:${iframe.src}`);

  iframe.onload = event => console.log('onload', event);
  iframe.onerror = event => console.log('onerror', event);

  // Hide or show the iframe based on env
  // @ts-ignore
  iframe.style.cssText = __PROD__
    ? 'display: none;'
    : `
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 300px;
    height: 300px;
    background: #fff;
    border: 1px solid #000;
  `;

  // Add the iframe to the page to trigger the process
  document.body.appendChild(iframe);

  // Give the refresh 60 seconds to complete
  failTimeoutId = setTimeout(handleRefreshFailure, 60000);
}

//
function setRefreshTimeout() {
  const expiryTimeParm = session.get().saml.expiryTime;
  console.log(`[setRefreshTimeout] Parm : <${expiryTimeParm}>`);

  // Session expires in approx 26 mins
  // For local set refresh to minimum
  // For non dev set refresh to 26 - 6 = approx 20 mins
  // If refresh is out of bounds set to max refresh

  const refreshMin = 60000 * 5;
  const refreshMax = 60000 * 20;
  const refreshGap = 6;

  let refreshDelay = 0;

  const currentTime = moment();
  const expiryTime = moment(expiryTimeParm);

  if (isLocal()) {
    refreshDelay = refreshMin;
  } else {
    refreshDelay = expiryTime.subtract(refreshGap, 'minutes').diff(currentTime);

    if (refreshDelay < refreshMin || refreshDelay > refreshMax) {
      refreshDelay = refreshMax;
    }
  }

  const refreshDelayMins = (refreshDelay / (1000 * 60)).toFixed(1);
  console.log(`[setRefreshTimeout] Current time (client): ${currentTime.format()}`);
  console.log(`[setRefreshTimeout] Expiry time  (server): ${expiryTime.format()}`);
  console.log(`[setRefreshTimeout] Setting auth refresh timer for: ${refreshDelayMins}mins (${refreshDelay}ms)`);

  setTimeout(performAuthRefresh, refreshDelay);
}

//
function prepareAuthRefreshListener() {
  console.log('[Shibboleth] prepareAuthRefreshListener');
  window.addEventListener(
    'message',
    // @ts-ignore
    (event = {}) => {
      const sourceOrigin = event.origin;
      const payload = event.data;

      console.debug('[preparePostListener] window.location.origin :', window.location.origin);
      console.debug('[preparePostListener] event.origin           :', event.origin);
      console.debug('[preparePostListener] event.data             :', event.data);

      if (sourceOrigin === window.location.origin) {
        if (payload.type === 'AUTH_REFRESH') {
          console.log('[preparePostListener] Got new auth credentials post:', event);
          clearTimeout(failTimeoutId);

          // Delete iframe
          document.body.removeChild(document.getElementById(IFRAME_ID));

          // Update stored creds
          const sessionInfo = session.get();

          // Legacy properties
          sessionInfo.accessKeyId = payload.credentials.accessKeyId;
          sessionInfo.secretAccessKey = payload.credentials.secretAccessKey;
          sessionInfo.sessionToken = payload.credentials.sessionToken;
          sessionInfo.expiryTime = payload.credentials.expiryTime;
          sessionInfo.rightSuiteToken = payload.credentials.rightSuiteToken;

          // New properties
          sessionInfo.saml.accessKeyId = payload.credentials.accessKeyId;
          sessionInfo.saml.secretAccessKey = payload.credentials.secretAccessKey;
          sessionInfo.saml.sessionToken = payload.credentials.sessionToken;
          sessionInfo.saml.expiryTime = payload.credentials.expiryTime;
          sessionInfo.saml.rightSuiteToken = payload.credentials.rightSuiteToken;

          session.set(sessionInfo);

          // Prepare next timeout
          setRefreshTimeout();
        } else if (payload.type === 'LOGIN_REQUIRED') {
          console.log('[preparePostListener] Login required', event);
          handleRefreshFailure();
        }
      }
    },
    false
  );
}

function updateSession(
  data = {
    accessKeyId: null,
    rightSuiteToken: null,
    secretAccessKey: null,
    sessionToken: null,
    expiryTime: null
  }
) {
  console.log('[Shibboleth] Updating session', data);

  const sessionInfo = session.get();

  // Legacy properties
  sessionInfo.accessKeyId = data.accessKeyId;
  sessionInfo.secretAccessKey = data.secretAccessKey;
  sessionInfo.sessionToken = data.sessionToken;
  sessionInfo.expiryTime = data.expiryTime;
  sessionInfo.rightSuiteToken = data.rightSuiteToken;

  // New properties
  sessionInfo.saml.accessKeyId = data.accessKeyId;
  sessionInfo.saml.secretAccessKey = data.secretAccessKey;
  sessionInfo.saml.sessionToken = data.sessionToken;
  sessionInfo.saml.expiryTime = data.expiryTime;
  sessionInfo.saml.rightSuiteToken = data.rightSuiteToken;

  session.set(sessionInfo);
}

/**
 * Start setInterval to update the session.
 * Needs to be called only if the session is active.
 */
export function startShibbolethInterval() {
  prepareAuthRefreshListener();

  setRefreshTimeout();
}

/**
 * Process Shibboleth response.
 * Throw any error that needs to stop the login process.
 */
export default function* processAuthUniversalShibboleth() {
  const cognitoId = getCognitoId();
  const { jwt } = parseQueryString(window.location.search && window.location.search.substring(1));
  console.log(`[Shibboleth] Processing auth request using cognito ${cognitoId}`);

  const response = yield getSAML(jwt, cognitoId);
  if (!response.sessionToken) {
    throw new Error('Invalid creds');
  }

  updateSession(response);
}
