import React from 'react';
import PropTypes from 'prop-types';
import { ORG_STUDENTS } from '@oup/shared-node-browser/constants.js';
import PopoutPanelIconHeading, {
  types
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import content from '../../../../../../utils/cmsContent.js';
// const content = {
//   enrolUserPanel: {
//     submitting_title: 'Please wait...'
//     submitting_subtitle: 'Enrolling user...'
//   }
const EnrolUserSubmitting = function EnrolUserSubmitting({ context }) {
  const CMS = content.enrolUserPanel || {};
  return (
    <PopoutPanelIconHeading
      type={types.LOADING}
      title={CMS.submitting_title}
      subtitle={context === ORG_STUDENTS ? CMS.student_submitting_subtitle : CMS.staff_submitting_subtitle}
    />
  );
};

EnrolUserSubmitting.propTypes = {
  context: PropTypes.string.isRequired
};

export default EnrolUserSubmitting;
