import { take, put, select } from 'redux-saga/effects';
import { GET_ARTICLES, resetForm } from '../../../../reducers/support/supportSearch.reducer.js';
import { storeArticles } from '../../../../reducers/data/supportArticles.reducer.js';
import { isHubMode } from '../../../../../utils/platform.js';

// Helpers for calling lambdas:
import cmsArticlesApi from '../../../apiCalls/cms/cmsSupportArticles.api.js';

const moduleName = '[supportSearch Saga]';

const filteredArticles = (data = {}) => {
  const result = {};
  Object.keys(data).forEach(key => {
    if (data[key].category !== 'Hub General') result[key] = data[key];
  });

  return result;
};

function* searchSupportArticles() {
  // TODO: Fetch articles from CMS:
  console.log(moduleName, 'Fetching support articles.');
  let { data } = yield select(state => state.supportArticles);
  let filteredData = {};

  if (data) {
    console.log(moduleName, 'Using cached support articles from state.');
    if (!isHubMode()) {
      filteredData = filteredArticles(data);
      return data && filteredData;
    }
  } else {
    console.log(moduleName, 'Fetching support articles from CMS.');
    data = yield cmsArticlesApi();

    if (!isHubMode()) {
      filteredData = filteredArticles(data.data);
      return data && filteredData;
    }

    return data && data.data;
  }

  return data;
}

export default function* supportSearchSaga() {
  console.log(moduleName, 'Beginning');
  while (true) {
    yield take(GET_ARTICLES);
    const articles = yield searchSupportArticles();
    yield put(storeArticles(articles));
    yield put(resetForm(articles));
  }
}
