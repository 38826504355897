import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ContentLoader from 'react-content-loader';
import styles from '../SkeletonLoader.scss';
import StudentReportStyles from '../../StudentReport/StudentReport.scss';
import StudentReportCardStyles from '../../StudentReportCard/StudentReportCard.scss';

function ChildComponent(props) {
  return (
    <div className={StudentReportStyles.reportCard}>
      <div className={classnames(StudentReportCardStyles.titleRow, styles.mainSkeletonBackground, styles.titleRowHead)}>
        <strong className={StudentReportCardStyles.title}>A</strong>
      </div>
      <div className={classnames(StudentReportCardStyles.blockContainer, styles.mainSkeletonBackground)}>
        <div className={classnames('col', 'sm12')}>
          <ContentLoader height="100" width="100%" viewBox="0 0 100% 100" {...props}>
            <rect x="0" y="20%" rx="5" ry="5" width="20%" height="80" />
            <rect x="22%" y="0" rx="5" ry="5" width="85%" height="100" />
          </ContentLoader>
        </div>
      </div>
    </div>
  );
}

function StudentsReportLoader(props) {
  return (
    <div className={styles.skeletonLoader}>
      <div className={StudentReportStyles.tabWrapper}>
        <ChildComponent {...props} />
        <ChildComponent {...props} />
        <ChildComponent {...props} />
      </div>
    </div>
  );
}

StudentsReportLoader.propTypes = {
  // Prop Defined
  speed: PropTypes.number,
  foregroundColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired
};

export default StudentsReportLoader;
