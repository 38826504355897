import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './ExpandableFolder.scss';

function ExpandableFolder({ id, title, isExpanded, onExpandAction }) {
  const keyDownHandler = e => {
    if (e.key === 'Enter') onExpandAction(id);
  };

  return (
    <div
      id={id}
      role="button"
      tabIndex={0}
      className={styles.folderContainer}
      onKeyDown={keyDownHandler}
      onClick={onExpandAction}
      aria-label={`${title} button ${isExpanded ? 'expanded' : 'collapsed'}`}
    >
      <div className={styles.expandButtonContainer}>
        <button
          type="submit"
          className={`${styles.thumbnailContainer} ${isExpanded ? styles.collapsedThumbnail : ''}`}
          tabIndex={-1}
        >
          <SVGIcon glyph={GLYPHS.CHEVRON_DOWN_THICK} />
        </button>
      </div>
      <div className={styles.fallbackSvgContainer}>
        <SVGIcon glyph={GLYPHS.OUP_SYMBOL_REBRANDING} />
      </div>
      <div className={styles.folderTitle}>{title}</div>
    </div>
  );
}

ExpandableFolder.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onExpandAction: PropTypes.func.isRequired
};

export default ExpandableFolder;
