import signedFetch from '../util/signedFetch';

export default function* searchProductsApi(filters) {
  const body = {
    searchString: filters.searchFilter,
    searchProducts: true,
    searchProductGroups: false,
    searchName: true,
    searchOupId: false,
    searchExternalId: false
  };

  if (filters.searchBy === 'searchOupId') {
    body.searchName = false;
    body.searchOupId = true;
  }
  if (filters.searchBy === 'searchExternalId') {
    body.searchName = false;
    body.searchExternalId = true;
  }

  if (filters.organisationalUnit) {
    body.organisationalUnit = filters.organisationalUnit;
  }

  if (filters.platformCode) {
    body.platformCode = filters.platformCode;
  }

  if (filters.limit) {
    body.pageLimit = filters.limit.toString();
  }

  if (filters.offset) {
    body.pageNumber = filters.offset.toString();
  }

  const URL = `${__API_BASE__}/admin-console/product/lookup${filters.pagination ?? ''}`;
  return yield signedFetch('lookupProduct', URL, 'POST', body);
}
