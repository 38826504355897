// https://github.com/chjj/marked
import { marked } from 'marked';

export default function parseMarkdown(source, options = {}) {
  // by default we do not allow HTML in our Markdown output but this can be overridden via options if required
  marked.setOptions({ sanitizer: true });

  // TODO: Here we could define our own renderers for CUSTOM MARKUP.
  // See https://github.com/chjj/marked#renderer

  // Call third party parser to convert markdown to markup:
  return marked(source, options);
}

export function createAnchorToBlank(source) {
  return marked(source).replace('<a ', '<a target="_blank" ');
}
