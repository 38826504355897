import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link/Link';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import styles from './LinkMenuItem.scss';

function LinkMenuItem({ menuItem = {} }) {
  return (
    <Link className={styles.linkContainer} to={menuItem.to} onClick={e => e.stopPropagation()}>
      {menuItem.title}
      <SVGIcon glyph={GLYPHS.ICON_LINK} />
    </Link>
  );
}

LinkMenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired
};

export default LinkMenuItem;
