import * as t from '../actionTypes';

export const getSubscriptionIdRequest = input => ({
  type: t.GET_SUBSCRIPTION_ID_REQUEST,
  payload: input
});

export const getSubscriptionReset = input => ({
  type: t.GET_SUBSCRIPTION_RESET,
  payload: input
});

export const getSubscriptionIdSuccess = input => ({
  type: t.GET_SUBSCRIPTION_ID_SUCCESS,
  payload: input
});

export const getSubscriptionIdFailure = input => ({
  type: t.GET_SUBSCRIPTION_ID_FAILURE,
  payload: input
});

export const createUserActivityRequest = input => ({
  type: t.CREATE_USER_ACTIVITY_REQUEST,
  payload: input
});

export const createUserActivitySuccess = input => ({
  type: t.CREATE_USER_ACTIVITY_SUCCESS,
  payload: input
});

export const createUserActivityFailure = input => ({
  type: t.CREATE_USER_ACTIVITY_FAILURE,
  payload: input
});
