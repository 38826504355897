import React from 'react';
import PropTypes from 'prop-types';
import Button from '@oup/shared-front-end/src/components/Button';
import styles from './StartTips.scss';
import Link from '../../components/Link/Link';
import withLocalizedContent from '../../language/withLocalizedContent';

function TermsAndConditions({ buttonClick, localizedContent: { orbStartupPages } }) {
  return (
    <div className={styles.termsAndConditions}>
      <div className={styles.header}>
        <div className="grid">
          <div className="row">
            <div className="col">
              <h1>{orbStartupPages.step_welcome}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="row">
          <div className="col">
            <h3>{orbStartupPages.step_privacy_text1}</h3>
            <p>
              {orbStartupPages.step_privacy_text2}{' '}
              <Link openInNewTab to={orbStartupPages.step_privacy_url}>
                {orbStartupPages.step_privacy_text3}
              </Link>{' '}
              {orbStartupPages.step_privacy_text4}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className={styles.termsAction}>
              <Button
                text={orbStartupPages.step_continue_button}
                onClick={() => {
                  buttonClick();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
TermsAndConditions.propTypes = {
  buttonClick: PropTypes.func,
  localizedContent: PropTypes.object
};

export default withLocalizedContent('orbStartupPages')(TermsAndConditions);
