import { put } from 'redux-saga/effects';
import getCoursePreviewFetch from '../../../apiCalls/getCoursePreview';
import { getCoursePreviewSuccess, getCoursePreviewFailure } from '../../../../actions/coursePreview';

function* getCoursePreview(params) {
  const response = yield getCoursePreviewFetch(params);
  if (response.status === 'success') {
    yield put(getCoursePreviewSuccess(response.data));
  } else {
    yield put(getCoursePreviewFailure('Failed to get course preview data'));
  }
}

export default getCoursePreview;
