import userRoles from '../../../globals/userRoles';

export default function getOrgPeopleDropdownItems(
  hubContent,
  { isPending = false, canChangeRole = false, isSupervisedUser = false, isPlacementCentre = false },
  {
    cancelInvitation = null,
    sendInvitationReminder = null,
    removeUser = null,
    editUser = null,
    sendPasswordResetEmail = null,
    assignMaterial = null,
    regenerateSupervisedUserSignInCard = null
  },
  isSelfSelectedOrgAdmin = false,
  currentOrganisationLti = false
) {
  if (isPending)
    return {
      [userRoles.ORG_ADMIN]: [
        !isPlacementCentre
          ? {
              title: hubContent.send_invitation_reminder,
              action: sendInvitationReminder
            }
          : {},
        !isPlacementCentre
          ? {
              title: hubContent.cancel_invitation,
              action: cancelInvitation
            }
          : {},
        !isPlacementCentre && !currentOrganisationLti
          ? {
              title: hubContent.assign_learning_material,
              action: assignMaterial
            }
          : {},
        !currentOrganisationLti && canChangeRole
          ? {
              title: hubContent.change_user_role,
              action: editUser
            }
          : {},
        !isSupervisedUser
          ? {
              title: hubContent.send_password_reset_email,
              action: sendPasswordResetEmail
            }
          : {},
        {
          title: hubContent.remove_from_organization,
          action: removeUser
        }
      ],
      [userRoles.TEACHER_ADMIN]: [
        !isPlacementCentre
          ? {
              title: hubContent.assign_learning_material,
              action: assignMaterial
            }
          : {},
        {
          title: hubContent.send_password_reset_email,
          action: sendPasswordResetEmail
        }
      ],
      [userRoles.LEARNER]: [
        {
          title: hubContent.assign_learning_material,
          action: assignMaterial
        }
      ]
    };

  if (isSelfSelectedOrgAdmin) {
    if (currentOrganisationLti) {
      return {};
    }

    return {
      [userRoles.ORG_ADMIN]: [
        {
          title: hubContent.assign_learning_material,
          action: assignMaterial
        }
      ]
    };
  }

  if (currentOrganisationLti) {
    return {
      [userRoles.ORG_ADMIN]: [
        {
          title: hubContent.remove_from_organization,
          action: removeUser
        }
      ]
    };
  }

  return {
    [userRoles.ORG_ADMIN]: [
      !isPlacementCentre
        ? {
            title: hubContent.assign_learning_material,
            action: assignMaterial
          }
        : {},
      canChangeRole
        ? {
            title: hubContent.change_user_role,
            action: editUser
          }
        : {},
      !isSupervisedUser
        ? {
            title: hubContent.send_password_reset_email,
            action: sendPasswordResetEmail
          }
        : {
            title: hubContent.create_sign_in_card,
            action: regenerateSupervisedUserSignInCard,
            subtitle: hubContent.create_sign_in_card_label
          },
      {
        title: hubContent.remove_from_organization,
        action: removeUser
      }
    ],
    [userRoles.TEACHER_ADMIN]: [
      !isPlacementCentre
        ? {
            title: hubContent.assign_learning_material,
            action: assignMaterial
          }
        : {},
      !isSupervisedUser
        ? {
            title: hubContent.send_password_reset_email,
            action: sendPasswordResetEmail
          }
        : {
            title: hubContent.create_sign_in_card,
            action: regenerateSupervisedUserSignInCard,
            subtitle: hubContent.create_sign_in_card_label
          }
    ],
    [userRoles.LEARNER]: [
      {
        title: hubContent.assign_learning_material,
        action: assignMaterial
      }
    ]
  };
}
