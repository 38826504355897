import React from 'react';
// Style
import styles from './carousel.scss';

// eslint-disable-next-line react/prop-types
function CarouselBullets({ index, bullet, onClick }) {
  return (
    <button
      type="button"
      className={`${styles.dot} ${bullet === index ? styles.active : ''}`}
      onClick={() => onClick(index)}
    />
  );
}

export default CarouselBullets;
