// Helper for grouping array items (objects) by a spefified property on each item:
// Eg: myArray.reduce(groupBy('category'), {})
//   myArray = [
//     {name:"Alexa",category:"female"},
//     {name:"Dave",category:"male"}
//   ]
//   returns:
//   {
//     female:[{name:"Alexa",category:"female"}],
//     male:[{name:"Dave",category:"male"}]
//   }
export default function groupBy(prop) {
  return function reducer(results, item, i, arr) {
    const name = item[prop];

    // Allow for when the second arg (default {}) was not supplied to the reduce() method.
    // When omitted, js initialises the accumulator with item 0 and starts the loop at item 1.
    // eslint-disable-next-line no-param-reassign
    if (i === 1 && results === arr[0]) results = { [results[prop]]: [results] };

    // Add item to group: (After adding the grouping if not already present)
    if (results[name]) {
      results[name].push(item);
    } else {
      results[name] = [item];
    }

    return results;
  };
}
