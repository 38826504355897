import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import searchProductsApi from '../../../apiCalls/product/searchProductsApi';

function* searchProducts(action) {
  let apiResults = {};

  apiResults = yield searchProductsApi(action.payload);

  if (apiResults.error || apiResults.status !== 'success') {
    yield put(actions.searchProductFailure({ error: apiResults.message }));
  } else {
    yield put(
      actions.searchProductSuccess({ products: apiResults.data.products, total: apiResults.data.totalProducts })
    );
  }
}

export default searchProducts;
