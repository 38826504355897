import { put } from 'redux-saga/effects';
import {
  setManagedUserChangeUsernameSuccess,
  setManagedUserChangeUsernameFailure
} from '../../../../actions/managedUserChangeUsername';
import signedFetch from '../../../apiCalls/util/signedFetch';
import generateUserToken, { TokenType } from '../../../utils/generateUserToken';

/**
 * Submit a request to update the username for a managed user record against
 * the ManagedUserChangeUsernamePanel journey.
 *
 * @param {string} orgId
 * @param {string} userId
 * @param {Object} user
 *
 * @yield {Object} The next Redux effect description
 */
function* submit(orgId, userId, user) {
  try {
    const response = yield signedFetch(
      'editManagedUserAuth',
      `${__API_BASE__}/org/${orgId}/managed-user/${userId}/auth`,
      'PUT',
      {
        userToken: yield generateUserToken(orgId, userId, TokenType.UPDATE_AUTH),
        ...user
      }
    );

    if (response.status !== 'success') {
      throw new Error(response.errorMessages);
    }

    yield put(setManagedUserChangeUsernameSuccess());
  } catch (e) {
    yield put(setManagedUserChangeUsernameFailure({ errors: { message: e } }));
  }
}

export default submit;
