import { select } from 'redux-saga/effects';

import addOrgLicenceApi from '../../../../apiCalls/licences/addOrgLicence.api.js';
import { getCurrentPlatform } from '../../../../../../utils/platform.js';

export default function* singleInputToLibraryRequest() {
  const { orgId, userId, activationCode, reCAPTCHAToken } = yield select(state => ({
    orgId: state.organisationPage.orgId,
    userId: state.identity.userId,
    activationCode: state.addToLibrary.accessCodeValue,
    reCAPTCHAToken: state.reCAPTCHA.token
  }));

  console.log(`[singleInputToLibraryRequest Saga] Submitting request to upload licence: ${activationCode}.`);
  return yield addOrgLicenceApi(orgId, {
    userId,
    activationCode,
    reCAPTCHAToken,
    reCAPTCHATokenSource: getCurrentPlatform()
  });
}
