import { put, select } from 'redux-saga/effects';
import { parse as parseQueryString } from 'query-string';
import { determineLatestOrgId, tokenIsTerminated } from '@oup/shared-node-browser/userIdentity';
import { appError } from '../../../../reducers/app.reducer';
import { setAuthUserDetails } from '../../../../reducers/identity.reducer';
import { setIdentityDetails } from '../../../../reducers/sharedActions';
import { storePeople } from '../../../../reducers/data/people';
import { getIntegrationPlatformKey } from '../../../../../globals/envSettings';
import getIdentityDetails from '../../../apiCalls/auth/getIdentityDetails.api';
import {
  epsPlatformOlb,
  getCurrentPlatform,
  isOlbOdsMode,
  getPlatformFromQueryString,
  isEmbeddedInIframe,
  getParentUrl
} from '../../../../../utils/platform';
import user from '../../../../../utils/user';

// Used to stop this horrible saga crashing when we don't do its exact bidding
const nullUserResponse = {
  status: 'success',
  data: {
    user: {
      userId: '',
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      registrationStatus: 'REGISTRATION_COMPLETE',
      missingFields: [],
      highestRoleInAnyOrg: 'USER',
      isSocial: false
    },
    orgs: [
      {
        id: '',
        customId: '',
        name: '',
        type: 'ORGANISATION',
        role: '',
        roleName: 'USER',
        isDefault: true
      }
    ]
  }
};

const getPlatformForIdentity = () => {
  const isEmbedded = isEmbeddedInIframe() || sessionStorage.getItem('embedded-by-url') === 'true';
  const parentUrl = getParentUrl() || '';
  const nextPathFromSessionStorage = sessionStorage.getItem('goto-path');
  let platformByProviderId = false;
  if (nextPathFromSessionStorage) {
    const { providerId = '' } = parseQueryString(nextPathFromSessionStorage);
    platformByProviderId = decodeURIComponent(providerId).toLowerCase();
  }
  const isEpsPlatformOlb =
    isOlbOdsMode() ||
    (isEmbedded && /oxfordlearnersbookshelf/i.test(parentUrl)) ||
    getIntegrationPlatformKey(platformByProviderId || getPlatformFromQueryString()) === epsPlatformOlb;
  return isEpsPlatformOlb ? epsPlatformOlb : getCurrentPlatform();
};

export default function* getIdentity(authResult = {}) {
  console.groupCollapsed('[GetIdentity]');
  console.log('Auth result', authResult);

  const userInfo = user.get();

  // TODO: Check when this have data.
  const { userId, orgRoleMap } = yield select(state => ({
    userId: state.identity.userId,
    orgRoleMap: state.identity.orgRoleMap
  }));

  // TODO: Shouldn't this be 'olb_offline' ?
  const platform = getPlatformForIdentity();
  // Impersonation for testing
  const masqueradeUserId = localStorage.getItem('masquerade-user-id');
  // Impersonation for support agents
  const impersonationToken = localStorage.getItem('impersonation-token');
  const ltiToken = localStorage.getItem('lti-token');
  let appliedLaunchToken;

  let identityDetails;

  if (impersonationToken && !tokenIsTerminated(impersonationToken)) {
    localStorage.removeItem('lti-token');
    appliedLaunchToken = impersonationToken;
  }

  if (ltiToken && !tokenIsTerminated(impersonationToken)) {
    sessionStorage.removeItem('impersonation-token');
    appliedLaunchToken = ltiToken;
  }

  if (appliedLaunchToken) {
    const token = appliedLaunchToken;

    // NOTE: our token (which is used as authorization header) will be read (and verified) server side to determine the rest
    // because unlike elsewhere only one orgId is valid - why we ever send userId is unknown to me because this is always
    // determined on the server anyway. Platform means the EPS client context so we do send that

    // const atRootPage = window.location.pathname === '/';
    const onLoggedOutScreen = window.location.pathname.indexOf('/logged-out') === 0;

    const hasNoLaunchTokenSession = tokenIsTerminated(token);

    if (onLoggedOutScreen) {
      // no session and user has already been told, set identity to empty
      identityDetails = nullUserResponse;
    } else if (hasNoLaunchTokenSession) {
      // no session, redirect to a page that will tell the user about what happened
      const tokenStatus = token;
      window.location.href = `/logged-out?token-status=${tokenStatus}`;
      identityDetails = nullUserResponse;
    } else {
      // get the user identity, using the impersonation or lti token (those are used automatically when they are available in signedFetch)
      identityDetails = yield getIdentityDetails('self', undefined, undefined, platform);

      if (identityDetails?.authInfo?.needNewToken) {
        // we should be redirected automatically by jsonFetch before this, but just in case
        identityDetails = nullUserResponse;
      }
    }
  } else if (masqueradeUserId) {
    identityDetails = yield getIdentityDetails(masqueradeUserId);
  } else {
    identityDetails = yield getIdentityDetails(userId, Object.keys(orgRoleMap), userInfo.organisationId, platform);
  }

  if (identityDetails.status === 'error' || identityDetails.error || !identityDetails.data) {
    console.log('Identity request failed. Error: ', identityDetails.error || identityDetails.message);
    yield put(appError('----  [getIdentity] Identity details request failed.'));
    return identityDetails;
  }

  const { user: userIn, orgs: orgsFromIdentity } = identityDetails.data;

  const orgRolesKeyedByOrgId = {};

  const latestOrgId = determineLatestOrgId(orgsFromIdentity, userInfo);
  let latestOrgLti = false;

  orgsFromIdentity.forEach(org => {
    orgRolesKeyedByOrgId[org.id] = org.roleName;
    if (latestOrgId === org.id) {
      latestOrgLti = org.isLti;
    }
  });
  // TODO: a more suitable fallthrough is 'USER'
  const currentRole = orgRolesKeyedByOrgId[latestOrgId] || userIn.highestRoleInAnyOrg;

  // we are either writing the previous value or setting the default
  userInfo.organisationId = latestOrgId;
  userInfo.role = currentRole;

  user.set(userInfo);

  yield put(
    setAuthUserDetails(
      userIn.email,
      userIn.userId,
      currentRole,
      orgRolesKeyedByOrgId,
      latestOrgId,
      latestOrgLti,
      userIn.registrationStatus,
      userIn.userName,
      userIn.firstName,
      userIn.selfSelectedRole,
      userIn.claimedSchool,
      userIn.countryCode
    )
  );

  yield put(
    storePeople({
      [userIn.userId]: {
        firstname: userIn.firstName,
        lastname: userIn.lastName,
        email: userIn.email,
        username: userIn.userName || userIn.username
      }
    })
  );
  yield put(setIdentityDetails(orgsFromIdentity, userIn.missingFields, userIn.isSocial, userIn.registrationStatus));

  console.groupEnd();

  return identityDetails;
}
