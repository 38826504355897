import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// Redux
import { productFinderFormStates } from '../../redux/reducers/productFinder.reducer';
import { resetProductFinderState, setSelectedCountryCode } from '../../redux/actions/productFinderActions';

// Components
import ProductFinderMaterialSearch from './formStates/ProductFinderMaterialSearch';
import ProductFinderSeriesOverview from './formStates/ProductFinderSeriesOverview';
import ProductFinderReviewLicences from './formStates/ProductFinderReviewLicences';
import ProductFinderMultipleReviewLicences from './formStates/ProductFinderMultipleReviewLicences';
import ProductFinderViewLicenceInformation from './formStates/ProductFinderViewLicenceInformation';
import ProductFinderReview from './formStates/ProductFinderReview';
import ProductFinderSubmitting from './formStates/ProductFinderSubmitting';
import ProductFinderConfirmation from './formStates/ProductFinderConfirmation';
import ProductFinderMultipleConfirmation from './formStates/ProductFinderMultipleConfirmation';
import ProductFinderError from './formStates/ProductFinderError';
import ProductFinderMultipleError from './formStates/ProductFinderMultipleError';

import withLocalizedContent from '../../language/withLocalizedContent';
import withBreakpoint from '../../decorators/withBreakpoint';
import { featureIsEnabled } from '../../globals/envSettings';

function ProductFinderPanel({
  orgId,
  classId,
  product,
  selectedProducts,
  selectedUsers,
  productFinderFormState,
  contextName,
  localizedContent: { productFinder: productFinderContent },
  assignLicences,
  userIdsInOrder,
  errorOccurred,
  onComplete,
  onClose,
  resetProductFinderStateAction,
  setSelectedCountryCodeAction,
  selectedCountryCode,
  defaultCountryCode,
  breakpoint,
  context,
  userEmail
}) {
  const closePanel = () => {
    resetProductFinderStateAction();
    return onClose();
  };

  const completePanel = () => {
    resetProductFinderStateAction();
    return onComplete();
  };

  useEffect(() => {
    if (!selectedCountryCode) {
      setSelectedCountryCodeAction(defaultCountryCode);
    }
  }, [selectedCountryCode]);
  if (errorOccurred) {
    if (featureIsEnabled('product-finder-multi-select')) {
      return (
        <ProductFinderMultipleError
          productFinderContent={productFinderContent}
          onAddAnotherClick={resetProductFinderStateAction}
          closePanel={closePanel}
          contextName={contextName}
        />
      );
    }
    return (
      <ProductFinderError
        productFinderContent={productFinderContent}
        assignLicences={assignLicences}
        errorOccurred={errorOccurred}
        product={product}
        onAddAnotherClick={resetProductFinderStateAction}
        closePanel={closePanel}
      />
    );
  }

  if (productFinderFormState === productFinderFormStates.SEARCHING) {
    return (
      <ProductFinderMaterialSearch
        selectedUsers={selectedUsers}
        productFinderContent={productFinderContent}
        contextName={contextName}
        closePopoutAction={closePanel}
      />
    );
  }

  if (productFinderFormState === productFinderFormStates.SERIES_OVERVIEW) {
    return (
      <ProductFinderSeriesOverview
        selectedUsers={selectedUsers}
        productFinderContent={productFinderContent}
        contextName={contextName}
        closePopoutAction={closePanel}
        breakpoint={breakpoint}
      />
    );
  }

  if (productFinderFormState === productFinderFormStates.REVIEW_LICENCES) {
    if (featureIsEnabled('product-finder-multi-select')) {
      return (
        <ProductFinderMultipleReviewLicences
          productFinderContent={productFinderContent}
          selectedProducts={selectedProducts}
          selectedUsers={selectedUsers}
          classId={classId}
          orgId={orgId}
          contextName={contextName}
          closePopoutAction={closePanel}
          context={context}
        />
      );
    }
    return (
      <ProductFinderReviewLicences
        productFinderContent={productFinderContent}
        product={product}
        selectedUsers={selectedUsers}
        classId={classId}
        orgId={orgId}
        contextName={contextName}
        closePopoutAction={closePanel}
      />
    );
  }

  if (productFinderFormState === productFinderFormStates.VIEW_PRODUCT_LICENCE_INFO) {
    return (
      <ProductFinderViewLicenceInformation
        productFinderContent={productFinderContent}
        product={product}
        closePopoutAction={closePanel}
      />
    );
  }

  // TO DO: remove this step after the product-finder-multi-select ff is enabled
  if (productFinderFormState === productFinderFormStates.REVIEW) {
    return (
      <ProductFinderReview
        orgId={orgId}
        productFinderContent={productFinderContent}
        product={featureIsEnabled('product-finder-multi-select') ? selectedProducts[0] : product}
        classId={classId}
        contextName={contextName}
        closePopoutAction={closePanel}
        // needed for polling. will be removed when polling is removed
        context={context}
      />
    );
  }

  if (productFinderFormState === productFinderFormStates.SUBMITTING) {
    return <ProductFinderSubmitting productFinderContent={productFinderContent} />;
  }

  if (productFinderFormState === productFinderFormStates.CONFIRMATION) {
    if (featureIsEnabled('product-finder-multi-select')) {
      return (
        <ProductFinderMultipleConfirmation
          productFinderContent={productFinderContent}
          closePanel={closePanel}
          contextName={contextName}
          userEmail={userEmail}
          selectedProducts={selectedProducts}
        />
      );
    }
    return (
      <ProductFinderConfirmation
        productFinderContent={productFinderContent}
        contextName={contextName}
        product={product}
        assignLicences={assignLicences}
        userIdsInOrder={userIdsInOrder}
        onAddAnotherClick={resetProductFinderStateAction}
        closePanel={closePanel}
        onComplete={completePanel}
      />
    );
  }

  return (
    <div>
      <p>Error. Missing a state for `{productFinderFormState}`.</p>
    </div>
  );
}

export default compose(
  connect(
    state => {
      // product-finder-multi-select
      const firstProduct = state.productFinder.product || state.productFinder.selectedProducts[0];
      const licenceStructureForProduct = state.productFinder?.licenceStructure[firstProduct?.productid] || {};
      return {
        productFinderFormState: state.productFinder.formStateHistory[0],
        product: firstProduct,
        assignLicences: state.productFinder.assignLicences,
        userIdsInOrder: licenceStructureForProduct.userIdsInOrder || [],
        selectedProducts: state.productFinder.selectedProducts,
        errorOccurred: state.productFinder.errorOccurred,
        userEmail: state.identity.email,
        selectedCountryCode: state.productFinder.selectedCountryCode,
        defaultCountryCode:
          state.organisations?.data?.[state.identity?.currentOrganisationId]?.countryCode || 'UNIVERSAL'
      };
    },

    {
      resetProductFinderStateAction: resetProductFinderState,
      setSelectedCountryCodeAction: setSelectedCountryCode
    }
  ),
  withLocalizedContent('productFinder'),
  withBreakpoint
)(ProductFinderPanel);

ProductFinderPanel.propTypes = {
  orgId: PropTypes.string,
  selectedUsers: PropTypes.object,
  contextName: PropTypes.string,
  resetProductFinderStateAction: PropTypes.func,
  productFinderFormState: PropTypes.string,
  product: PropTypes.object,
  selectedProducts: PropTypes.array,
  localizedContent: PropTypes.object,
  assignLicences: PropTypes.bool,
  selectedCountryCode: PropTypes.string,
  defaultCountryCode: PropTypes.string,
  setSelectedCountryCodeAction: PropTypes.func,
  userIdsInOrder: PropTypes.array,
  errorOccurred: PropTypes.bool,
  breakpoint: PropTypes.string,
  classId: PropTypes.string,
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
  context: PropTypes.string,
  userEmail: PropTypes.string
};
