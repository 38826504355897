import { put } from 'redux-saga/effects';
import getContentPreviewFetch from '../../../apiCalls/getContentPreview';
import { getContentPreviewSuccess, getContentPreviewFailure } from '../../../../actions/contentPreview';

function* getContentPreview(params) {
  const response = yield getContentPreviewFetch(params);
  if (response.status === 'success') {
    yield put(getContentPreviewSuccess(response.data));
  } else {
    yield put(getContentPreviewFailure('Failed to get content preview data'));
  }
}

export default getContentPreview;
