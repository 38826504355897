/** Classroom reducer
 *  This reducer contains state related to the current app classroom. This
 *  includes details of the classroom's app account if the classroom is logged in.
 */

export const SET_CLASSROOM_LIST = 'classroomArchive/SET_CLASSROOM_LIST';
export const SELECT_CLASSROOM_FOR_ARCHIVE = 'classroomArchive/SELECT_CLASSROOM_FOR_ARCHIVE';
export const DESELECT_CLASSROOM_FOR_ARCHIVE = 'classroomArchive/DESELECT_CLASSROOM_FOR_ARCHIVE';

// Update email choice
const SET_NOTIFY_WITH_EMAIL = 'classroomArchive/SET_NOTIFY_WITH_EMAIL';

// Submit the form
export const ARCHIVE_CLASS = 'classroomArchive/ARCHIVE_CLASS';
export const SHOW_CONFIRMATION = 'classroomArchive/SHOW_CONFIRMATION';

// Reset the form
export const CLOSE_FORM = 'classroomArchive/CLOSE_FORM';

// Pagination
export const HANDLE_CLASSROOM_FOR_ARCHIVE_PAGE_SELECTION =
  'classroomArchive/HANDLE_CLASSROOM_FOR_ARCHIVE_PAGE_SELECTION';

export const formStates = {
  REVIEW_SELECTIONS: 'REVIEW_SELECTIONS',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION'
};

const initialState = {
  formState: formStates.REVIEW_SELECTIONS,

  // List of classes to archive:
  classroomIds: [],
  orgId: null,

  // Email notifications
  notifyWithEmail: false,

  // Pagination
  page: 1,
  startSlice: 0,
  endSlice: 10
};

export default function classroomArchive(state = initialState, action = {}) {
  switch (action.type) {
    // Set the list of classroom(s)
    case SET_CLASSROOM_LIST:
      return {
        ...state,
        classroomIds: action.classroomIds,
        orgId: action.orgId
      };

    // List of all selected classes for archive
    case SELECT_CLASSROOM_FOR_ARCHIVE:
      return {
        ...state,
        classroomIds: [...state.classroomIds, action.classroomId]
      };

    // Remove unchecked class from list
    case DESELECT_CLASSROOM_FOR_ARCHIVE: {
      const classIndex = state.classroomIds.indexOf(action.classroomId);
      const classroomIds = [...state.classroomIds];
      classroomIds.splice(classIndex, 1);
      return {
        ...state,
        classroomIds: [...classroomIds]
      };
    }

    // Update email choice
    case SET_NOTIFY_WITH_EMAIL:
      return {
        ...state,
        notifyWithEmail: action.notifyWithEmail
      };

    // Submit archive request
    // This is a bit like CONFIRMATION on other pages.
    case ARCHIVE_CLASS:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };

    // Pagination
    case HANDLE_CLASSROOM_FOR_ARCHIVE_PAGE_SELECTION:
      if (action.page === 1) {
        return {
          ...state,
          page: action.page,
          startSlice: 0,
          endSlice: 10
        };
      }

      return {
        ...state,
        page: action.page,
        startSlice: action.page * 10 - 10,
        endSlice: action.page * 10
      };

    // After Classes have been submitted:
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        requestFailed: action.requestFailed,
        failedIds: action.failedIds
      };

    case CLOSE_FORM:
      return {
        ...initialState,
        formState: formStates.REVIEW_SELECTIONS
      };

    default:
      return state;
  }
}

// Tell us which classrooms we're dealing with
export const setClassroomsToArchive = (classroomIds, orgId) => ({
  type: SET_CLASSROOM_LIST,
  classroomIds: Array.isArray(classroomIds) ? classroomIds : [classroomIds],
  orgId
});

export const selectClassForArchive = classroomId => ({
  type: SELECT_CLASSROOM_FOR_ARCHIVE,
  classroomId
});

export const deselectClassForArchive = classroomId => ({
  type: DESELECT_CLASSROOM_FOR_ARCHIVE,
  classroomId
});

// Update email choice
export const setNotifyWithEmail = notifyWithEmail => ({
  type: SET_NOTIFY_WITH_EMAIL,
  notifyWithEmail
});

// Form submission and response
export const archiveClass = () => ({
  type: ARCHIVE_CLASS
});

export const showConfirmation = (requestFailed, failedIds) => ({
  type: SHOW_CONFIRMATION,
  requestFailed,
  failedIds
});

// Reset action
export const closeForm = () => ({
  type: CLOSE_FORM
});

// Pagination
export const handleClassroomArchiveSelectionPage = page => ({
  type: HANDLE_CLASSROOM_FOR_ARCHIVE_PAGE_SELECTION,
  page
});
