import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import editOrgUser from '../../../api/editOrgUser';
import { getUserDetails } from '../user/userProfile.saga';
import validateEditUserInput from './validateEditUserInput';

function* editUserInOrg(orgId, userId, input) {
  const errors = yield validateEditUserInput(orgId, input);
  const hasErrors = Object.values(errors).some(Boolean);
  if (hasErrors) {
    yield put(actions.editUserValidateInputError());
    return;
  }
  const response = yield editOrgUser(orgId, userId, input);

  if (response.status.toLowerCase() === 'success') {
    yield put(actions.editUserGetDetailsRequest(orgId, userId));
    yield put(actions.editUserSuccess());
    yield getUserDetails();
    return;
  }
  yield put(actions.editUserFailure());
}

export default editUserInOrg;
