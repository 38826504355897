import { put } from 'redux-saga/effects';

import getCoursePreview from '../../../apiCalls/getCoursePreview';
import { getCoursePublishSuccess, getCoursePublishFailure } from '../../../../actions/coursePublish';

function* getCoursePublishList(params) {
  const response = yield getCoursePreview(params);
  if (response.status === 'success') {
    yield put(getCoursePublishSuccess(response.data));
  } else {
    yield put(getCoursePublishFailure('Failed to load course content data'));
  }
}

export default getCoursePublishList;
