import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Components
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import AssessmentTest from './AssessmentTest';
import Resource from '../Resource/Resource';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import HubProductErrorModal from '../../structure/HubProductLayout/HubProductErrorModal/HubProductErrorModal';
// Constants
import { ErrorModalType, HubLayoutConstants } from '../../globals/hubConstants';
// Style
import styles from './AssessmentTest.scss';
import colors from '../../globals/colors';
// Utils
import getAssessmentCourseTitle from '../../structure/HubCourseAssessments/Utils/getAssessmentCourseTitle';
import { hasHideableKeywords, hasLockableKeywords } from '../../structure/HubCourseAssessments/Utils/isLockedNode';

function AssessmentTestFolder({
  id,
  title,
  locked,
  course,
  isLocked,
  tests,
  hubContent,
  openOnlineTest,
  openPreviewModal,
  downloadAssessment,
  openTestBuilderModal,
  openPreviewResourceModal,
  downloadResource,
  breakpoint,
  level,
  hideDownloadButtonText = false,
  selectOnlineTest,
  downloadIsLoading,
  resourceIdsDownloading
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const keyDownHandler = callback => e => {
    if (e.key === 'Enter') callback(e);
  };
  const assessmentCourseTitle = getAssessmentCourseTitle(course);

  return (
    <div>
      <ul className={styles.testFolderContainer}>
        <li key={id} className={styles.assessmentTestFolder}>
          <div
            id={id}
            role="button"
            tabIndex={0}
            className={styles.folderContainer}
            onKeyDown={() => {
              keyDownHandler(() => {
                setIsCollapsed(!isCollapsed);
              });
            }}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <button
              type="button"
              className={`${styles.thumbnailContainer} ${!isCollapsed ? styles.collapsedThumbnail : ''}`}
            >
              <SVGIcon glyph={GLYPHS.CHEVRON_DOWN_THICK} />
            </button>
            {locked ? (
              <div className={styles.svgtestfolder}>
                <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
              </div>
            ) : null}
            <div className={`${styles.testFolderTitle} ${locked ? styles.lockedtest : ''}`}>{title}</div>
          </div>
        </li>
      </ul>
      {!isCollapsed && (
        <ul className={styles.assessmentTestFoldersList}>
          {tests.resources.map(key => {
            let assessment = {};
            const idKey = key.id;
            if (key.type === HubLayoutConstants.ASSESSMENT_TYPES.RESOURCE) {
              assessment = course.resources[idKey];
              return (
                assessment.title &&
                !hasHideableKeywords(assessment.keywords) && (
                  <div key={idKey} className={styles.testFolderPadding}>
                    <Resource
                      resourceId={idKey}
                      openModal={openPreviewResourceModal}
                      downloadResource={downloadResource}
                      startedLicence
                      hasLicence
                      isExpired={hasLockableKeywords(assessment.keywords)}
                      hubContent={hubContent}
                      resource={assessment}
                      breakpoint={breakpoint}
                      downloadIsLoading={downloadIsLoading}
                      resourceIdsDownloading={resourceIdsDownloading}
                    />
                  </div>
                )
              );
            }
            if (key.type === HubLayoutConstants.ASSESSMENT_TYPES.INTERACTIVE_FOLDER) {
              assessment = course.interactiveFolders[idKey];
              return (
                assessment.title &&
                !hasHideableKeywords(assessment.keywords) && (
                  <div key={key.id} className={styles.testFolderPadding}>
                    <AssessmentTest
                      assessmentId={idKey}
                      assessmentTest={assessment}
                      locked={hasLockableKeywords(assessment.keywords)}
                      title={assessment.title}
                      content={hubContent}
                      openModal={openPreviewModal}
                      breakpoint={breakpoint}
                      downloadAssessment={downloadAssessment}
                      openOnlineTest={openOnlineTest}
                      openTestBuilderModal={() => {
                        openTestBuilderModal(assessment.digitalResourceId);
                      }}
                      isAdapted={false}
                      hideDownloadButtonText={hideDownloadButtonText}
                      displayDropdown={!selectOnlineTest}
                      selectOnlineTest={
                        selectOnlineTest ? () => selectOnlineTest(idKey, assessment, assessmentCourseTitle) : null
                      }
                    />
                    {!locked &&
                      course.interactiveFolders &&
                      course.interactiveFolders[idKey] &&
                      course.interactiveFolders[idKey].adaptedTests &&
                      course.interactiveFolders[idKey].adaptedTests.map(
                        item =>
                          !hasHideableKeywords(item.keywords) && (
                            <div className={styles.adaptedTest} key={item.id}>
                              <SVGIcon className={styles.adaptedTestArrow} glyph={GLYPHS.ICON_CHILD} />
                              <AssessmentTest
                                assessmentId={idKey}
                                assessmentTest={item}
                                title={item.title}
                                locked={hasLockableKeywords(item.keywords)}
                                content={hubContent}
                                openModal={openPreviewModal}
                                openOnlineTest={openOnlineTest}
                                breakpoint={breakpoint}
                                downloadAssessment={downloadAssessment}
                                isAdapted
                                hideDownloadButtonText={hideDownloadButtonText}
                                openTestBuilderModal={() => {
                                  openTestBuilderModal(item.associated_resource_id);
                                }}
                                displayDropdown={!selectOnlineTest}
                                selectOnlineTest={
                                  selectOnlineTest
                                    ? () => selectOnlineTest(item.associated_resource_id, item, assessmentCourseTitle)
                                    : null
                                }
                              />
                            </div>
                          )
                      )}
                  </div>
                )
              );
            }
            return '';
          })}
          {Object.keys(tests).map(key => {
            let subFolder = {};
            if (tests[key] && tests.title) {
              subFolder = tests[key];
            }
            return (
              subFolder.title && (
                <div key={key} className={styles.testFolderPadding}>
                  <AssessmentTestFolder
                    id={key || ''}
                    title={subFolder.title || ''}
                    locked={isLocked(course, subFolder)}
                    tests={subFolder}
                    level={level + 1}
                    course={course}
                    isLocked={isLocked}
                    hubContent={hubContent}
                    openPreviewModal={openPreviewModal}
                    downloadAssessment={downloadAssessment}
                    openPreviewResourceModal={openPreviewResourceModal}
                    downloadResource={downloadResource}
                    openOnlineTest={openOnlineTest}
                    breakpoint={breakpoint}
                    hideDownloadButtonText={hideDownloadButtonText}
                    selectOnlineTest={selectOnlineTest}
                    downloadIsLoading={downloadIsLoading}
                    resourceIdsDownloading={resourceIdsDownloading}
                  />
                </div>
              )
            );
          })}
        </ul>
      )}
      <HubProductErrorModal
        isOpen={openModal}
        errorModalType={ErrorModalType.EXPIRED}
        hubContent={hubContent}
        glyphIcon={GLYPHS.ICON_CALENDAR}
        glyphSize={thumbnailSizes.SECTION}
        glyphBackgroundColour={colors.HUB_BLUE}
        title={hubContent.assessment_not_available_modal_title}
        subTitle={hubContent.assessment_not_available_modal_subtitle}
        showLink={false}
        actionLabel={hubContent.done_button}
        closeExpiredModal={() => setOpenModal(!openModal)}
      />
    </div>
  );
}

AssessmentTestFolder.propTypes = {
  id: PropTypes.string,
  course: PropTypes.object,
  title: PropTypes.string,
  locked: PropTypes.bool,
  tests: PropTypes.any,
  hubContent: PropTypes.object,
  openOnlineTest: PropTypes.func,
  openPreviewModal: PropTypes.func,
  downloadAssessment: PropTypes.func,
  openTestBuilderModal: PropTypes.func,
  openPreviewResourceModal: PropTypes.func,
  downloadResource: PropTypes.func,
  level: PropTypes.number,
  isLocked: PropTypes.func,
  breakpoint: PropTypes.string,
  hideDownloadButtonText: PropTypes.bool,
  selectOnlineTest: PropTypes.func,
  downloadIsLoading: PropTypes.bool,
  resourceIdsDownloading: PropTypes.object
};
export default AssessmentTestFolder;
