import { put, select, take } from 'redux-saga/effects';
import { removeSelectedTeacherIds } from '../../../../reducers/classroomPage';
import { removeTeachersFromClassroom as removeTeachersFromClassroomComplete } from '../../../../reducers/data/classrooms';
import { CLEAR_FORM, submissionComplete, SUBMIT_FORM } from '../../../../reducers/removeTeachersFromClassroom';
import editClassroomApi from '../../../apiCalls/editClassroomApi';
import { pollClassTeachersRemoved } from '../dataRecency/pollClassTeachers';
import { triggerLoadClassroomEdit } from './edit';
import { getCurrentPlatform } from '../../../../../utils/platform';

export default function* removeTeachersFromClassroom() {
  console.log('[removeTeachersFromClassroom Saga] Beginning');

  while (true) {
    console.log('[removeTeachersFromClassroom Saga] Waiting for user to submit form');
    yield take(SUBMIT_FORM);

    console.log('[removeTeachersFromClassroom Saga] Form submitted. capturing data from state...');
    const { actioningUserId, organisationId, classroomId, classroomName, teachersRemoved } = yield select(state => ({
      actioningUserId: state.identity.userId,
      organisationId: state.classroomPage.orgId,
      classroomId: state.classroomPage.classroomId,
      classroomName: state.classrooms.data[state.classroomPage.classroomId].name,
      teachersRemoved: state.removeTeachersFromClassroom.submittedIds
    }));

    console.log(
      `[removeTeachersFromClassroom Saga] Submitting request to remove Teacher IDs: ${teachersRemoved} from class with ID: ${classroomId}`
    );
    const response = yield editClassroomApi(organisationId, classroomId, {
      actioningUserId,
      name: classroomName,
      teachersRemoved,
      platformCode: getCurrentPlatform() || ''
    });

    const editResult = response || {};
    const requestFailed = editResult.status !== 'success';
    const failedIds = (editResult.data || {}).failedIds || [];

    console.log('[removeTeachersFromClassroom Saga] Edit Class request complete, showing confirmation page');
    yield put(submissionComplete(requestFailed, requestFailed ? teachersRemoved : failedIds));

    if (!requestFailed) {
      const successfullyRemovedTeacherIds = teachersRemoved.filter(id => !failedIds.includes(id));
      yield put(removeTeachersFromClassroomComplete(classroomId, successfullyRemovedTeacherIds));
      yield put(removeSelectedTeacherIds(successfullyRemovedTeacherIds));
      yield pollClassTeachersRemoved(successfullyRemovedTeacherIds);
    }

    yield triggerLoadClassroomEdit();

    yield take(CLEAR_FORM);
  }
}
