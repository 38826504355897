import cmsContent from './cmsContent.js';

export default function getTimePeriod(units, unitType) {
  const sharedTerms = cmsContent.sharedTerms || {};

  const noDetailsText = '';
  const numberOfUnits = parseInt(units, 10);

  if (!unitType) {
    console.warn(`Expected a time unit: ${unitType}`);
    return noDetailsText;
  }
  if (Number.isNaN(numberOfUnits)) {
    console.warn(`Not a number of time units: ${units}`);
    return noDetailsText;
  }

  const timeUnitEntity = unitType.toUpperCase();
  const validTimeUnits = ['MINUTE', 'HOUR', 'DAY', 'MONTH', 'WEEK', 'YEAR'];

  if (validTimeUnits.indexOf(timeUnitEntity) === -1) {
    console.warn(`Unknown time unit type: ${unitType}`);
    return noDetailsText;
  }

  const pluralisedTerm = timeUnitEntity + (numberOfUnits === 1 ? '' : 'S');
  const timeUnitTerm = sharedTerms[pluralisedTerm];

  if (!timeUnitTerm) {
    console.warn(`Unknown glossary term for entity: ${pluralisedTerm}`);
    return noDetailsText;
  }

  return `${numberOfUnits} ${timeUnitTerm}`;
}
