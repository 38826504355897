import { put } from 'redux-saga/effects';
import { loadOfflineProfiles } from '../../../../actions/offlineContentPlayer';

function* addOfflineProfiles(profilesToAdd) {
  const storedValue = localStorage.getItem('offline_profiles');
  const profiles = storedValue ? JSON.parse(storedValue) : {};

  profilesToAdd.forEach(profile => {
    const entries = Object.entries(profile);
    const [key, value] = entries[0];
    profiles[key] = value;
  });

  localStorage.setItem('offline_profiles', JSON.stringify(profiles));

  yield put(loadOfflineProfiles(profiles));
}

export default addOfflineProfiles;
