import * as t from '../actionTypes';

export const editCurrentUserDetailsRequest = payload => ({
  type: t.EDIT_CURRENT_USER_DETAILS_REQUEST,
  payload
});

export const editCurrentUserDetailsSuccess = () => ({
  type: t.EDIT_CURRENT_USER_DETAILS_REQUEST_SUCCESS
});

export const editCurrentUserDetailsError = () => ({
  type: t.EDIT_CURRENT_USER_DETAILS_REQUEST_ERROR
});

export const resetEditCurrentUserDetailsState = () => ({
  type: t.RESET_EDIT_CURRENT_USER_DETAILS_REQUEST_STATE
});

export const isEmailTakenRequest = payload => ({
  type: t.IS_EMAIL_TAKEN_REQUEST,
  payload
});

export const setIsEmailTaken = payload => ({
  type: t.SET_IS_EMAIL_TAKEN,
  payload
});
