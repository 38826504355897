import * as t from '../actionTypes';

export const intialState = {
  resources: {},
  isLoading: false,
  hasFailed: false,
  downloadIsLoading: false,
  downloadHasFailed: false,
  currentDownload: null,
  consumer: null,
  errors: {},
  viewerType: 'teacher'
};

export default (state = intialState, { type, payload, viewerType }) => {
  switch (type) {
    case t.TEACHER_RESOURCES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case t.TEACHER_RESOURCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resources: { ...state.resources, ...payload }
      };
    case t.TEACHER_RESOURCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasFailed: true,
        errors: { ...state.errors, ...payload }
      };
    case t.TEACHER_RESOURCE_REQUEST:
      return {
        ...state,
        downloadIsLoading: true,
        currentDownload: payload.resourceId
      };
    case t.TEACHER_RESOURCE_SUCCESS:
      return {
        ...state,
        downloadIsLoading: false,
        currentDownload: null,
        consumer: { ...state.consumer, ...payload }
      };
    case t.TEACHER_RESOURCE_FAILURE:
      return {
        ...state,
        downloadIsLoading: false,
        downloadHasFailed: true,
        errors: { ...state.errors, ...payload }
      };
    case t.HUB_CHANGE_ACTIVE_VIEW_TEACHER_STUDENT:
      return {
        ...state,
        viewerType
      };
    default:
      return state;
  }
};
