import moment from 'moment';

import { momentTimeUnit, LICENCE_CONSTANTS, isLicenceExpiresEarly } from '@oup/shared-node-browser/licenceHelper.js';
import getLocalisedTimePeriod from './getLocalisedTimePeriod.js';

/* eslint-disable import/prefer-default-export */
const getExpiryInformation = (expiryDateString, warningPeriodInDays) => {
  const defaultWarningPeriodDays = 10;
  let warningPeriod = parseInt(warningPeriodInDays, 10);

  if (Number.isNaN(Number(warningPeriod))) {
    warningPeriod = defaultWarningPeriodDays;
  }

  const now = new Date();
  const expiryDate = new Date(expiryDateString);
  const expiryWarningFromDate = moment(expiryDate)
    .subtract(warningPeriod, 'days')
    .toDate();
  const isExpiring = now > expiryWarningFromDate && now < expiryDate;
  const hasExpired = moment(expiryDate).isSameOrBefore(now);
  const daysLeft = Math.ceil(moment(expiryDate).diff(now, 'days', true));

  return {
    isExpiring,
    hasExpired,
    daysLeft
  };
};

const getAvailableFromText = (availableFromContent, licenceStartDate) => {
  let availableFromText = '';
  if (licenceStartDate && moment(licenceStartDate).isAfter(moment())) {
    availableFromText = ` (${availableFromContent} ${moment(licenceStartDate).format('D MMMM YYYY')})`;
  }
  return availableFromText;
};

// Used to generate the suffix message regarding the licence expiry for the assignment summary panel
const getExpiresOnText = (CMS, licenceEndDate) => {
  let expiresOnText = '';
  if (licenceEndDate) {
    expiresOnText = `${CMS.expires_on_text} ${moment(licenceEndDate).format('D MMMM YYYY')}`;
  }
  return expiresOnText;
};

// Used to generate the message regarding the licence for the assignment summary panel
const getLicencePeriodDetails = (CMS, licenceData) => {
  let licencePeriodText = CMS.unlimited_access;
  let startFrom = licenceData.licenceStartDate ? moment(licenceData.licenceStartDate) : moment();

  if (licenceData.beginOn === LICENCE_CONSTANTS.BEGIN_ON.CREATION && !licenceData.licenceStartDate) {
    startFrom = moment(licenceData.createdDate);
  }

  switch (licenceData.licenceType) {
    case LICENCE_CONSTANTS.LICENCE_TYPE.ROLLING:
      if (licenceData.beginOn === LICENCE_CONSTANTS.BEGIN_ON.CREATION) {
        if (!licenceData.licenceEndDate) {
          const timePeriod = getLocalisedTimePeriod(licenceData.timePeriod, licenceData.unitType);
          licencePeriodText = `${timePeriod} ${CMS.access_from_first_use_text}`;
          break;
        }
        // checking if time period is present and calculating the end date.
        // checking if licenceenddate is present and the timespan to expiry is bigger than the time period.
        if (
          (licenceData.timePeriod && licenceData.unitType && !licenceData.licenceEndDate) ||
          (licenceData.licenceEndDate && isLicenceExpiresEarly(licenceData))
        ) {
          licencePeriodText = `${CMS.expires_on_text} ${startFrom
            .add(parseInt(licenceData.timePeriod, 10), momentTimeUnit[licenceData.unitType])
            .format('D MMMM YYYY')}`;
        }
        // checking if licenceenddate is present and the timespan to expiry is smaller than the time period.
        if (licenceData.licenceEndDate && !isLicenceExpiresEarly(licenceData)) {
          licencePeriodText = `${CMS.expires_on_text} ${moment(licenceData.licenceEndDate).format('D MMMM YYYY')}`;
        }
      } else if (licenceData.beginOn === LICENCE_CONSTANTS.BEGIN_ON.FIRST_USE) {
        if (!licenceData.licenceEndDate) {
          licencePeriodText = CMS.unlimited_access;
          break;
        }
        if (licenceData.timePeriod && licenceData.unitType) {
          const timePeriod = getLocalisedTimePeriod(licenceData.timePeriod, licenceData.unitType);
          licencePeriodText = `${timePeriod} ${CMS.access_from_first_use_text}`;
        }
        // checking if licenceenddate is present and the timespan to expiry is smaller than the time period.
        if (licenceData.licenceEndDate && isLicenceExpiresEarly(licenceData)) {
          licencePeriodText = `${CMS.expires_in_text} ${moment(licenceData.licenceEndDate).from(startFrom, true)}`;
        }
      } else {
        console.log('Invalid Begin On status');
      }
      break;

    case LICENCE_CONSTANTS.LICENCE_TYPE.CONCURRENT:
      if (licenceData.licenceEndDate) {
        licencePeriodText = `${CMS.expires_on_text} ${moment(licenceData.licenceEndDate).format('D MMMM YYYY')}`;
      }
      break;

    case LICENCE_CONSTANTS.LICENCE_TYPE.USAGE:
      if (licenceData.licenceEndDate) {
        licencePeriodText = `${CMS.expires_on_text} ${moment(licenceData.licenceEndDate).format('D MMMM YYYY')}`;
      }
      break;

    default:
      console.log('Invalid Licence Type');
      break;
  }

  licencePeriodText += getAvailableFromText(CMS.available_from_text, licenceData.licenceStartDate);

  return licencePeriodText;
};

/**
 * Check licence is a concurrent licence or not
 * @param {Object} licence
 *
 * @returns {boolean} concurrent or not
 */
const isConcurrentLicence = licence => !(licence.timePeriod && licence.unitType);

export { getExpiryInformation, getAvailableFromText, getExpiresOnText, getLicencePeriodDetails, isConcurrentLicence };
