import { put, call, select, take, race, all, delay } from 'redux-saga/effects';

import {
  EVENT_POLLING_START,
  EVENT_POLLING_STOP,
  pollStartAction,
  pollStopAction,
  getDataFailureAction
} from '../../reducers/polling.reducer';

import { pollConfig, pollingEvents } from '../../../globals/pollingConfig';

const moduleName = '[polling Saga]';

/**
 * Poll Saga worker.
 */
function* pollingSaga() {
  console.log(moduleName, 'Inside pollingSaga');
  const { pollFor } = yield select(state => ({
    pollFor: state.clientPolling.pollingFor
  }));
  const poll = pollingEvents[pollFor];
  let retries = poll.retries || pollConfig.retries;
  let throttleIntervalReset = pollConfig.throttleIntervalReset;
  const stopAfterRetries = poll.stopAfterRetries || pollConfig.stopAfterRetries;
  const throttleInterval = poll.throttleInterval || pollConfig.throttleInterval;
  while (true) {
    try {
      console.log(moduleName, 'Inside pollingSaga try block');
      const throttleIntervalMs = throttleInterval.length
        ? throttleInterval[retries - 1] * 1000
        : throttleInterval * 1000;
      retries += 1;
      console.log(
        `%c::PollingSaga::-throttleIntervalMs-${throttleIntervalMs}-throttleIntervalReset-${throttleIntervalReset}`,
        'padding:2px;background:#e07700;color:white'
      );
      if (retries === throttleInterval.length) {
        retries = 1;
        throttleIntervalReset += 1;
      }
      if (throttleIntervalReset > stopAfterRetries) {
        yield all([put(pollStopAction()), put(poll.apiFailed)]);
      }
      yield delay(throttleIntervalMs);
      yield put(poll.conditionToFulfil);
      yield put(pollStartAction(pollFor));
    } catch (err) {
      yield all([put(pollStopAction()), put(poll.apiFailed), put(getDataFailureAction(err))]);
    }
  }
}

// Wait for polling action, then fire another request
// Cancel polling once condition is met

function* watchPoll() {
  while (true) {
    console.log(moduleName, 'Inside while watchPoll');
    yield take(EVENT_POLLING_START);
    yield race([call(pollingSaga), take(EVENT_POLLING_STOP)]);
  }
}

// Daemonize tasks in parallel
export default function* watchPollingSaga() {
  console.log(moduleName, 'Beginning');
  yield* [
    watchPoll()
    // other watchers here
  ];
}
