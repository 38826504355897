/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import TableAccordion, { columnTypes } from '../../../../components/TableAccordion/TableAccordion';
import CopyToClipboardButton from '../../../../components/CopyToClipboardButton/CopyToClipboardButton';
import ShowOicTestCredits from '../../../../components/OicTestCredits/ShowOicTestCredits';
import ContentWithThumbnail from '../../../../components/ContentWithThumbnail/ContentWithThumbnail';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import { SIZES } from '../../../../components/Thumbnail/Thumbnail';
import colors from '../../../../globals/colors';
import PageHeading from '../../../../components/PageHeading/PageHeading';
import styles from './ResultComponent.scss';

const getRows = (localizedContent, orgId, resultIds, products) =>
  resultIds.map(id => {
    const product = products[id] || {};
    const { productDetails } = product;

    const cells = [
      <div key={0}>{productDetails?.title}</div>,
      <div key={1} className="flex-row align-items-center">
        <pre>{productDetails?.isbn}</pre>
        <CopyToClipboardButton classCode={id} label={localizedContent.copy_action} />
      </div>,
      <div key={2}>{productDetails?.series}</div>,
      <div key={3} className="flex-row justify-content-around">
        <ShowOicTestCredits orgId={orgId} productId={product.productId} expanded />
      </div>
    ];

    return {
      id: `product-${id}`,
      cells
    };
  });

function ResultComponent({ localizedContent, orgId, isOicOrg, resultIds, products, requestFailed }) {
  if (resultIds.length > 0) {
    const columns = [
      {
        heading: localizedContent.heading_title,
        type: columnTypes.STATUS
      },
      {
        heading: localizedContent.heading_isbn,
        type: columnTypes.TEXT
      },
      {
        heading: localizedContent.heading_series,
        type: columnTypes.TEXT
      },
      {
        heading: localizedContent.heading_test_credits,
        type: columnTypes.TEXT
      }
    ];
    const rows = getRows(localizedContent, orgId, resultIds, products);

    return <TableAccordion customClass={styles.productsTable} columns={columns} rows={rows} />;
  }

  if (requestFailed) {
    return (
      <p id="searchResults-orgs-noResults" className="gin-top3 gin-bot1">
        {localizedContent.no_search_results_error}
      </p>
    );
  }

  return (
    <div className="pad4" style={{ textAlign: 'center' }}>
      <div className="row cols-center">
        <ContentWithThumbnail size={SIZES.HEADING} glyph={GLYPHS.ICON_LEARNING_MATERIAL} backgroundColor={colors.COURSE} />
      </div>
      {!isOicOrg ? (
        <PageHeading
          title={localizedContent.no_oic_organizations_page_title}
          subtitle={localizedContent.no_oic_organizations_page_subtitle}
        />
      ) : (
        <PageHeading
          title={localizedContent.no_assessment_licence_page_title}
          subtitle={localizedContent.no_assessment_licence_page_subtitle}
        />
      )}
    </div>
  );
}

ResultComponent.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  isOicOrg: PropTypes.bool.isRequired,
  resultIds: PropTypes.array.isRequired,
  products: PropTypes.object.isRequired,
  requestFailed: PropTypes.bool.isRequired
};

export default ResultComponent;
