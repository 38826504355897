import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Constants
import { PRODUCT_TARGET_USERTYPE as userTypes } from '@oup/shared-node-browser/constants';
import {
  HubIllustrationConstants,
  HubIllustrationAltText,
  productInformationContext
} from '../../../globals/hubConstants.js';
// Action imports
import {
  resetProductFinderState,
  getProductFinderLicencesRequest,
  setProductFinderAssignLicences,
  setAssignLicenceToTeacher,
  setFormState,
  goToPreviousFormState,
  removeProductFinderSelectedProduct,
  selectProductFinderProduct,
  assignMultipleProductsRequest,
  clearAssignmentInitialToggleValue,
  getProductFinderProducts
} from '../../../redux/actions/productFinderActions';
import { productFinderFormStates } from '../../../redux/reducers/productFinder.reducer';
import { toggleDetailsPopupWithId } from '../../../redux/reducers/assignLearningMaterial.reducer';
// Component imports
import Tabs from '../../Tabs/Tabs';
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import PanelNavigationLink from '../../PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../PopoutActionFooter/PopoutActionFooter';
import HubEmptyStateRestyle from '../../HubEmptyStateRestyle/HubEmptyStateRestyle';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';
import Tooltip from '../../Tooltip/Tooltip';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import ProductFinderLicence from '../ProductFinderLicence';
import ProductFinderLicencesFailed from '../reviewLicences/ProductFinderLicencesFailed';
import ProductFinderLoadingLicences from '../reviewLicences/ProductFinderLoadingLicences';
// Styles
import colors from '../../../globals/colors';
import styles from './ProductFinderFormStates.scss';
import productFinderStyles from '../ProductFinder.scss';

function ProductFinderMultipleReviewLicences({
  productFinderContent,
  orgId,
  classId,
  selectedUsers,
  contextName,
  closePopoutAction,
  goToPreviousFormStateAction,
  loadingLicences,
  loadingLicencesFailed,
  getProductFinderLicencesRequestAction,
  existingAssignmentId,
  setAssignLicencesAction,
  toggleProductDetailsAction,
  selectedProducts,
  licenceStructure,
  assignLicences,
  removeProductFinderSelectedProductAction,
  resetProductFinderStateAction,
  setAssignLicenceToTeacherAction,
  setFormStateAction,
  selectProductAction,
  assignMultipleProductsRequestAction,
  assignmentInitialToggleValue,
  clearInitialToggleAction,
  loadingProducts,
  error,
  products,
  getProductFinderProductsAction,
  context
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [studentProducts, setStudentProducts] = useState([]);
  const [teacherProducts, setTeacherProducts] = useState([]);
  const [shouldShowStudentProducts, setShouldShowStudentProductList] = useState(false);
  const [shouldShowTeacherProducts, setShouldShowTeacherProductList] = useState(false);
  const [shouldShowTabs, setShouldShowTabs] = useState(false);
  const [showToggle, setShowToggle] = useState(false);

  // Fetch the products for Manage licences scenario
  const productsLoaded = !loadingProducts && !error;
  const hasProductResults = productsLoaded && products?.length > 0;
  useEffect(() => {
    if (!hasProductResults) getProductFinderProductsAction();
  }, []);

  const licenceDropdownActions = id => ({
    viewLicenceInformation: () => {
      setFormStateAction(productFinderFormStates.VIEW_PRODUCT_LICENCE_INFO);
      selectProductAction(id);
    },
    viewProductInformation: () => {
      toggleProductDetailsAction(id, null, null, productInformationContext.PRODUCT_FINDER);
    },
    removeSelectedMaterial: () => {
      removeProductFinderSelectedProductAction(id);
    }
  });

  const getEmptyState = resetButtonAction => (
    <div className={styles.emptyStateProductFinder}>
      <HubEmptyStateRestyle
        iconSrc={HubIllustrationConstants.DIGITAL_LIBRARY}
        iconAlt={HubIllustrationAltText.DIGITAL_LIBRARY}
        title={productFinderContent.no_materials_selected_title}
        bodyText={productFinderContent.no_materials_selected_subtitle}
        btnFilledBase={{
          text: productFinderContent.select_material,
          action: () => resetButtonAction()
        }}
      />
    </div>
  );

  const sumOfAllLicencesForProduct = licence => {
    if (!licence?.licencesTypes) return 0;
    return licence.licencesTypes.map(licenceType => licenceType.availableCount).reduce((a, b) => a + b, 0);
  };

  // ordering the product based on the number of available licences for each
  const orderLicences = productLicences =>
    productLicences.sort((a, b) => {
      const firstProductLicences = sumOfAllLicencesForProduct(licenceStructure?.[a.productid]);
      const secondProductLicences = sumOfAllLicencesForProduct(licenceStructure?.[b.productid]);
      if (firstProductLicences < secondProductLicences) return 1;
      return -1;
    });

  const handleToggleChange = value => {
    setAssignLicencesAction(value);
    clearInitialToggleAction();
  };

  useEffect(() => {
    if (Object.keys(licenceStructure).length) {
      let canLicencesBeAssigned = false;
      // if for a single type of product, there are licences that can be assigned
      // we need to show to organisation toggle
      selectedProducts.forEach(product => {
        const productLicence = licenceStructure[product.productid];
        if (productLicence) {
          canLicencesBeAssigned = canLicencesBeAssigned || productLicence.canAssignLicences;
        }
      });
      setShowToggle(canLicencesBeAssigned);
      // by default we set it to true if possible
      setAssignLicencesAction(canLicencesBeAssigned && (assignmentInitialToggleValue || assignLicences));
      setAssignLicenceToTeacherAction(canLicencesBeAssigned);
    } else {
      setShowToggle(false);
    }
  }, [selectedProducts, loadingLicences]);

  useEffect(() => {
    const filteredStudentProducts = orderLicences(
      selectedProducts.filter(product => product.target_usertype !== userTypes.TEACHER)
    );
    const filteredTeacherProducts = orderLicences(
      selectedProducts.filter(product => product.target_usertype === userTypes.TEACHER)
    );
    setStudentProducts(filteredStudentProducts);
    setTeacherProducts(filteredTeacherProducts);
    setShouldShowTabs(!!(filteredTeacherProducts.length && filteredStudentProducts.length));
    setShouldShowStudentProductList(!!(!filteredTeacherProducts.length && filteredStudentProducts.length));
    setShouldShowTeacherProductList(!!(filteredTeacherProducts.length && !filteredStudentProducts.length));

    if (selectedProducts?.length) {
      getProductFinderLicencesRequestAction({
        orgId,
        classId,
        selectedUsers,
        selectedProducts,
        assignLicenceToTeacher: true
      });
    }
  }, [selectedProducts]);

  const renderProductLicences = productLicences => (
    <div className={styles.productLicencesContainer}>
      {productLicences.map(productLicence => (
        <ProductFinderLicence
          key={productLicence.productid}
          product={productLicence}
          content={productFinderContent}
          assignLicences={assignLicences}
          licenceDetails={licenceStructure?.[productLicence.productid] || {}}
          dropdownActions={licenceDropdownActions(productLicence.productid)}
        />
      ))}
    </div>
  );

  const renderTabs = () => (
    <Tabs
      items={[
        {
          action: () => {
            setSelectedTab(0);
          },
          tabText: productFinderContent.student_material,
          tabNumber: studentProducts.length,
          panelContent: renderProductLicences(studentProducts)
        },
        {
          action: () => {
            setSelectedTab(1);
          },
          tabText: productFinderContent.teacher_material,
          tabNumber: teacherProducts.length,
          panelContent: renderProductLicences(teacherProducts)
        }
      ]}
      current={selectedTab}
      backgroundColor={colors.WHITE}
      customClasses={{
        buttons: { selected: styles.selectedTab, unselected: styles.unselectedTab },
        tabsContainer: styles.tabsTopContainer,
        tabPanelContainer: styles.tabPanelContainer,
        container: styles.tabContainer,
        tabsContainerRow: styles.tabsContainerRow,
        tabsContainerTabList: styles.tabsContainerTabList
      }}
    />
  );

  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        headerContent={
          <div>
            <div className={styles.textRight}>
              <PanelNavigationLink
                isLhs={false}
                text={productFinderContent.close_panel_text}
                action={closePopoutAction}
              />
            </div>
            <div className={styles.headerContainer}>
              <h2>{productFinderContent.add_these_materials_header}</h2>
              <p>{`${productFinderContent.check_materials_before} ${contextName}`}</p>
            </div>
            {showToggle && (
              <div className={productFinderStyles.displayFlexBox}>
                <ToggleSwitch
                  value={assignLicences}
                  onChange={handleToggleChange}
                  label={productFinderContent.assign_licence_header}
                />
                <Tooltip
                  title={productFinderContent.org_licence_info_text}
                  className={productFinderStyles.largeTooltip}
                >
                  <SVGIcon glyph={GLYPHS.ICON_HELP_CIRCLE} className={productFinderStyles.questionIcon} />
                </Tooltip>
              </div>
            )}
          </div>
        }
        headerClassName={shouldShowTabs ? styles.borderlessHeader : ''}
        footerContent={
          <PopoutActionFooter
            secondaryButtonText={
              existingAssignmentId ? productFinderContent.cancel_button_text : productFinderContent.button_back_text
            }
            secondaryButtonAction={existingAssignmentId ? closePopoutAction : goToPreviousFormStateAction}
            primaryButtonText={productFinderContent.add_learning_material}
            primaryButtonAction={() => {
              assignMultipleProductsRequestAction({
                orgId,
                classId,
                selectedProducts,
                licenceStructure,
                existingAssignmentId,
                assignLicences,
                contextName,
                context
              });
            }}
            primaryButtonDisabled={!selectedProducts.length}
            primaryButtonTestHook="ASSIGN_LEARNING_MATERIAL_REVIEW_LICENCES_NEXT"
            wizardButtons
          />
        }
      >
        <div className={styles.multipleLicencesContainer}>
          {loadingLicences ? (
            <ProductFinderLoadingLicences productFinderContent={productFinderContent} />
          ) : (
            <div className={styles.scrollContainerLicences}>
              {loadingLicencesFailed && (
                <ProductFinderLicencesFailed
                  productFinderContent={productFinderContent}
                  getProductFinderLicences={getProductFinderLicencesRequestAction}
                />
              )}
              {!loadingLicencesFailed && !!selectedProducts.length && (
                <>
                  {shouldShowTabs && renderTabs()}
                  {shouldShowStudentProducts && renderProductLicences(studentProducts)}
                  {shouldShowTeacherProducts && renderProductLicences(teacherProducts)}
                </>
              )}
              {!loadingLicencesFailed && !selectedProducts.length && getEmptyState(resetProductFinderStateAction)}
            </div>
          )}
        </div>
      </ScrollContainer>
    </form>
  );
}

export default connect(
  state => {
    const {
      loadingLicences,
      loadingLicencesFailed,
      licenceStructure,
      existingAssignmentId,
      assignLicences,
      assignmentInitialToggleValue,
      loadingProducts,
      error,
      products
    } = state.productFinder;

    return {
      existingAssignmentId,
      loadingLicences,
      loadingLicencesFailed,
      assignLicences,
      assignmentInitialToggleValue,
      people: state.people.data || {},
      licenceStructure,
      loadingProducts,
      error,
      products
    };
  },
  {
    resetProductFinderStateAction: resetProductFinderState,
    setAssignLicencesAction: setProductFinderAssignLicences,
    toggleProductDetailsAction: toggleDetailsPopupWithId,
    goToPreviousFormStateAction: goToPreviousFormState,
    getProductFinderLicencesRequestAction: getProductFinderLicencesRequest,
    setAssignLicenceToTeacherAction: setAssignLicenceToTeacher,
    setFormStateAction: setFormState,
    removeProductFinderSelectedProductAction: removeProductFinderSelectedProduct,
    selectProductAction: selectProductFinderProduct,
    assignMultipleProductsRequestAction: assignMultipleProductsRequest,
    clearInitialToggleAction: clearAssignmentInitialToggleValue,
    getProductFinderProductsAction: getProductFinderProducts
  }
)(ProductFinderMultipleReviewLicences);

ProductFinderMultipleReviewLicences.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  classId: PropTypes.string,
  orgId: PropTypes.string,
  selectedUsers: PropTypes.object,
  selectedProducts: PropTypes.array,
  contextName: PropTypes.string,
  closePopoutAction: PropTypes.func,
  loadingLicences: PropTypes.bool,
  loadingLicencesFailed: PropTypes.bool,
  getProductFinderLicencesRequestAction: PropTypes.func,
  goToPreviousFormStateAction: PropTypes.func,
  assignLicences: PropTypes.bool,
  existingAssignmentId: PropTypes.string,
  setAssignLicencesAction: PropTypes.func,
  toggleProductDetailsAction: PropTypes.func,
  licenceStructure: PropTypes.object,
  removeProductFinderSelectedProductAction: PropTypes.func,
  resetProductFinderStateAction: PropTypes.func,
  setAssignLicenceToTeacherAction: PropTypes.func,
  setFormStateAction: PropTypes.func,
  selectProductAction: PropTypes.func,
  assignMultipleProductsRequestAction: PropTypes.func.isRequired,
  assignmentInitialToggleValue: PropTypes.bool,
  clearInitialToggleAction: PropTypes.func.isRequired,
  loadingProducts: PropTypes.bool,
  error: PropTypes.bool,
  products: PropTypes.array,
  getProductFinderProductsAction: PropTypes.func,
  context: PropTypes.string
};
