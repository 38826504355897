import { put } from 'redux-saga/effects';
import { marksRequestSuccess, marksRequestFailure } from '../../../../actions/ngsStudentMarks';
import getMarksApiCall from '../../../apiCalls/ngs/getMarks';

function* getAggregatedMarks(orgId) {
  const response = yield getMarksApiCall(orgId);

  if (response.status === 'success') {
    yield put(marksRequestSuccess(response.data));
    return;
  }

  yield put(marksRequestFailure(response.message));
}

export default getAggregatedMarks;
