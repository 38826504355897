import signedFetch from '../../../apiCalls/util/signedFetch.js';

export default function* productUploadApi(formData, platformCode) {
  return yield signedFetch(
    'file',
    `${__API_BASE__}/file/product-metadata?platform=${platformCode.toLowerCase()}`,
    'POST',
    formData,
    null,
    false,
    true
  );
}
