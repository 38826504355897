import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getOrganizationalUnitsApi from '../../../apiCalls/getOrganizationalUnitsApi';

function* getOrganizationalUnits() {
  let apiResults = {};

  apiResults = yield getOrganizationalUnitsApi();

  if (apiResults.error || apiResults.status !== 'success') {
    yield put(actions.organizationalUnitsFailure({ error: apiResults.message }));
  } else {
    yield put(actions.organizationalUnitsSuccess({ success: apiResults.data.orgUnit }));
  }
}

export default getOrganizationalUnits;
