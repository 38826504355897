import dot from 'dot-object';
import * as types from '../actionTypes';

const initialState = {
  user: {},
  errors: {},
  locked: false,
  submitting: false,
  success: false,
  failure: false,
  resetPassword: false,
  isUserNameTaken: false,
  userNameWithCustomId: '',
  putManagedUserApi: true, // supporting flag for put-managed-user-api feature,
  editedFieldState: null,
  editedField: null,
  passwordFailure: false,
  formState: {}
};

/**
 * Resolve the next state object for the managed user edit panel when an action
 * is invoked.
 *
 * @param {Object} state
 * @param {string} action.type
 * @param {?Object} action.payload
 *
 * @return {Object}
 */

export function mergeDeep(target, source) {
  const isObject = obj => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep({ ...targetValue }, sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

function objectAssign(...sources) {
  const target = {};
  for (let i = 0; i < sources.length; i += 1) {
    mergeDeep(target, sources[i]);
  }
  return target;
}

export default function getManagedUserEditState(state = initialState, { type, payload }) {
  switch (type) {
    case types.MANAGED_USER_EDIT_SET_RECORD:
      return {
        ...initialState,
        putManagedUserApi: state.putManagedUserApi,
        user: payload
      };
    case types.MANAGED_USER_EDIT_VALIDATE_SET_RESULT:
      return {
        ...state,
        errors: objectAssign(state.errors, payload)
      };
    case types.MANAGED_USER_EDIT_PASSWORD:
      return {
        ...state,
        user: {
          ...state.user,
          password: payload.password
        }
      };
    case types.MANAGED_USER_EDIT_SUBMIT:
      return {
        ...state,
        failure: false,
        submitting: true
      };
    case types.MANAGED_USER_EDIT_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true
      };
    case types.MANAGED_USER_EDIT_FAILURE:
      return {
        ...state,
        submitting: false,
        failure: true
      };
    case types.MANAGED_USER_PASSWORD_FAILURE:
      return {
        ...state,
        submitting: false,
        passwordFailure: true
      };
    case types.MANAGED_USER_YEAR_GROUP_FAILURE:
      return {
        ...state,
        submitting: false,
        yearGroupFailure: true
      };
    case types.MANAGED_USER_EDIT_RETRY:
      return {
        ...state,
        failure: false
      };
    case types.MANAGED_USER_VALIDATE_USERNAME_GLOBAL_COMPLETE:
      return {
        ...state,
        isUserNameTaken: payload.isUserNameTaken
      };
    case types.MANAGED_USER_VALIDATE_USERNAME_GLOBAL:
      return {
        ...state,
        userNameWithCustomId: payload.userNameWithCustomId
      };
    case types.MANAGED_USER_RESET_PASSWORD:
      return {
        ...state,
        resetPassword: true
      };
    case types.MANAGED_USER_TRIGGER_BACK:
      return {
        ...state,
        resetPassword: false
      };
    case types.PUT_MANAGED_USER_API:
      return {
        ...state,
        putManagedUserApi: payload
      };
    case types.MANAGED_USER_RESET_VALIDATION: {
      let errors;
      if (payload.key) {
        errors = { ...state.errors };
        dot.str(payload.key, undefined, errors);
      } else {
        errors = {};
      }
      return {
        ...state,
        errors
      };
    }
    case types.SET_MANAGED_USER_EDITED_FIELD_STATE:
      return {
        ...state,
        editedField: payload.editedField,
        editedFieldState: payload.editedFieldState
      };
    case types.SET_MANAGED_USER_EDITED_FORM_STATE:
      return {
        ...state,
        formState: { ...state.formState, ...{ [payload.editedField]: payload.editedFieldState } }
      };
    case types.SET_MANAGED_USER_EDITED_CLEAR_FORM_STATE:
      return {
        ...state,
        formState: {}
      };
    default:
      return state;
  }
}
