import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import UserRepresentation from '../UserRepresentation/UserRepresentation';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import PasswordInputManagedUser from '../PasswordInput/PasswordInputManagedUser';
import styles from './ManagedUserPasswordReset.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
import { ALLOWED_ONLY_ALPHANUMERIC } from '../../globals/validations';

class ManagedUserPasswordReset extends Component {
  constructor(props) {
    super(props);
    const { item, errors } = this.props;
    this.state = {
      user: {
        ...item
      },
      errors: {
        ...errors
      },
      passwordFocus: false
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps) {
      this.setState({
        user: newProps.item,
        errors: newProps.errors
      });
    }
  }

  _handleOnPasswordChange = (value, name) => {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  };

  _handleOnPasswordBlur = idx => {
    const { onBlur } = this.props;
    const { user } = this.state;
    this.setState({ passwordFocus: false });
    onBlur(idx, user);
  };

  _handleOnPasswordFocus = () => {
    this.setState({
      passwordFocus: true
    });
  };

  _validatePasswordStatus = () => {
    const {
      passwordFocus,
      errors,
      user: { password }
    } = this.state;
    const {
      index,
      localizedContent: { managedUserSignInCardPanel: content }
    } = this.props;

    switch (true) {
      case passwordFocus && !ALLOWED_ONLY_ALPHANUMERIC.test(password) && password.length > 0:
        return {
          type: 'error',
          isActive: true,
          message: content.password_no_special_characters
        };
      case passwordFocus:
        return {
          type: 'notice',
          isActive: true,
          message: content.password_info_text
        };
      case Object.keys(errors).length && errors[index] === undefined:
        return {
          type: null,
          isActive: null
        };
      case Object.keys(errors).length && errors[index].password:
        return {
          type: 'error',
          isActive: true,
          message: content.password_reset_input_error
        };
      case Object.keys(errors).length && !errors[index].password:
        return {
          type: 'valid',
          isActive: false
        };
      default:
        return {
          type: null,
          isActive: null
        };
    }
  };

  render() {
    const { label, index, isDisabled } = this.props;
    const { user } = this.state;
    return (
      <div className={styles.resetPasswordWrap}>
        <UserRepresentation
          firstName={user.firstName}
          lastName={user.lastName}
          identifier={user.userName.split('_')[1]}
        />
        <ValidationStatus forId={`password_${index}`} {...this._validatePasswordStatus()}>
          <PasswordInputManagedUser
            id={`password_${index}`}
            label={label}
            name="password"
            placeholder="aaaaaaa"
            value={user.password}
            disabled={isDisabled}
            onChange={this._handleOnPasswordChange}
            onBlur={() => this._handleOnPasswordBlur(index)}
            onFocus={() => this._handleOnPasswordFocus()}
            onTypeStop={this._validatePasswordStatus}
            required
          />
        </ValidationStatus>
      </div>
    );
  }
}

ManagedUserPasswordReset.propTypes = {
  localizedContent: PropTypes.object,
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onBlur: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  errors: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired
};

export default compose(withLocalizedContent('managedUserSignInCardPanel'))(ManagedUserPasswordReset);
