import { JSON_ENCODED } from '@oup/shared-node-browser/constants';
import { JSON_HEADERS } from './util/signedFetch';
import signedRequest from './util/signedRequest';
import jsonFetch from './util/jsonFetch';
import { isCesMode } from '../../../utils/platform';

export async function getUserData(courses) {
  const ids = [];
  courses.forEach(course => {
    const createdById = course?.entityEvent?.createdBy;
    const modifiedById = course?.entityEvent?.modifiedBy;

    if (createdById && !ids.includes(createdById)) {
      ids.push(createdById);
    }
    if (modifiedById && !ids.includes(modifiedById)) {
      ids.push(modifiedById);
    }
  });
  const payload = {
    ids,
    project: {
      firstName: 1,
      lastName: 1,
      userEmail: 1
    }
  };
  const headers = { 'Content-Type': JSON_ENCODED };
  const suffix = '/entity/user/id-lookup?customer=false';
  const url = `${__API_BASE__}${suffix}`;
  const response = await jsonFetch(
    'getUserData',
    [url, signedRequest(url, 'POST', headers, JSON.stringify(payload))],
    false
  );

  return response;
}

export async function getContentPreview(params) {
  // NOTE: This apiCall is used by CoursePreview and CoursePublish
  const URL = `${__API_BASE__}/content/courses`;

  let fetchParams = '';
  if (params) {
    const paramsArray = Object.entries(params).map(([key, value]) => `${key}=${value}`);
    fetchParams = `?${paramsArray.join('&')}`;
  }

  const headers = JSON_HEADERS;

  const courses = await jsonFetch('getCoursePreview', [URL + fetchParams, signedRequest(URL, 'GET', headers)], false);

  if (!isCesMode()) {
    return courses;
  }

  const userData = await getUserData(courses.data);

  if (userData.error) {
    return courses;
  }

  const data = courses.data.map(course => {
    const createdById = course.entityEvent.createdBy;
    const userDataCreatedById = userData.data[createdById];

    const modifiedById = course.entityEvent.modifiedBy;
    const userDataModifiedById = userData.data[modifiedById];

    if (userDataCreatedById) {
      course.entityEvent.createdByUserDetails = userDataCreatedById.userDetails;
    }

    if (userDataModifiedById) {
      course.entityEvent.modifiedByUserDetails = userDataModifiedById.userDetails;
    }

    return course;
  });
  const response = {
    status: 'success',
    data
  };

  return response;
}

function* yielded(func, params) {
  return yield func(params);
}

export default params => yielded(async () => getContentPreview(params));
