import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './ResultsBar.scss';
import PencilReviewButton from '../PencilReviewButton/PencilReviewButton';

function ResultsBar({ label = false, results, customClassName = '', addPencilReview = false, getActivityLink }) {
  return (
    <div className={`${styles.resultsBar} ${customClassName}`}>
      <ol className={styles.list}>
        {results.map((result, i) => {
          let pocketStyle;
          let detailsText;
          if (result === 1) {
            pocketStyle = styles.blockCorrect;
            detailsText = 'Correct';
          } else if (result < 0.5) {
            pocketStyle = styles.blockIncorrect;
            detailsText = 'Incorrect';
          } else {
            pocketStyle = styles.blockHalf;
            detailsText = 'Half';
          }
          return (
            <li
              key={`block-${i}`}
              className={classnames(styles.block, pocketStyle)}
              style={{ width: `${70 / results.length}%` }}
            >
              <span className="a11y-hide">{detailsText}</span>
            </li>
          );
        })}
      </ol>

      {label && (
        <div className={styles.label}>
          {addPencilReview ? (
            <PencilReviewButton getActivityLink={getActivityLink} buttonActive />
          ) : (
            <span>{label}</span>
          )}
        </div>
      )}
    </div>
  );
}

ResultsBar.propTypes = {
  /** An optional label for the bar */
  label: PropTypes.string,
  /** An array representation of the results */
  results: PropTypes.array.isRequired,
  /** Custom styles */
  customClassName: PropTypes.string,
  /** A boolean to manage when the Pencil icon has to appear on the left of the label */
  addPencilReview: PropTypes.bool,
  /** Function that returns the string with the link for the activity to be reviewed */
  getActivityLink: PropTypes.func
};

export default ResultsBar;
