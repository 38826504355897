import { put, select } from 'redux-saga/effects';
import { createUserActivitySuccess, createUserActivityFailure } from '../../../../actions/inspectionCopy';
import createUserActivityApi from '../../../apiCalls/createUserActivityApi';

export default function* createUserActivity() {
  const { userId, emailAddress, name, role, school, country, bookTitle } = yield select(state => ({
    userId: state.identity.userId,
    emailAddress: state.identity.email,
    name: state.identity.firstName,
    role: state.identity.selfSelectedRole,
    school: state.identity.claimedSchool,
    country: state.identity.countryCode,
    bookTitle: state.inspectionCopyBookTitle.bookTitle
  }));

  const body = {
    userId,
    bookTitle,
    name,
    emailAddress,
    role,
    school,
    country
  };

  const response = yield createUserActivityApi(body);

  if (response.status === 'success') {
    return yield put(createUserActivitySuccess(response));
  }

  return yield put(createUserActivityFailure({ errors: response.message }));
}
