import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import appSettings from '../../../../globals/appSettings';
import { createProductRequest } from '../../../../redux/actions/createProduct';
import styles from './css/ProductCreationPage.scss';
import ToggleTabs from './components/ToggleTabs';
import DefinitionTab from './components/DefinitionTab';
import PlatformsTab from './components/PlatformsTab';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

import useDefinitionState from './components/hooks/useDefinitionState';
import AssociationsTab from './components/AssociationsTab';
import UrlTab from './components/UrlTab';
import ExternalIdsTab from './components/ExternalIdsTab';

function ProductCreatePage({
  localizedContent: { productCreate: content },
  createProductRequestAction,
  isLoading,
  success,
  error
}) {
  const [selectedTab, setSelectedTab] = useState('definition');
  const [displayMessagesAndClass, setDisplayMessagesAndClass] = useState({
    messages: [],
    class: true
  });

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [productDetails, setProductDetails] = useDefinitionState();

  const setProductDetailsFunctions = setProductDetails();

  const setTab = useCallback(
    param => {
      setSelectedTab(param);
    },
    [selectedTab]
  );

  const createProduct = () => {
    const {
      productName,
      organizationalUnit,
      state,
      email,
      productPlatforms,
      associations,
      externalIds,
      protocol,
      host,
      path,
      query,
      fragment,
      expression
    } = productDetails;
    const payload = {
      product_name: productName,
      admin_email: email,
      platforms: productPlatforms.map(item => item.platform_id.toString(10)),
      associated_products: associations.map(item => item.productId),
      external_ids: externalIds.map(item => [item.typeId, item.id]),
      division_id: organizationalUnit,
      resources: [expression, protocol, host, path, query, fragment]
    };
    if (state) {
      payload.state = state;
    }
    const validationErrors = [];

    if (!productName) {
      validationErrors.push('Product name is required ');
    }

    if (!appSettings.EMAIL_REGEX.test(email)) {
      validationErrors.push('Email is invalid ');
    }

    if (validationErrors.length === 0) {
      createProductRequestAction(payload);
    } else {
      setDisplayMessagesAndClass(prevParam => ({
        ...prevParam,
        messages: validationErrors,
        class: false
      }));
    }
  };

  useEffect(() => {
    let timer;

    if (error?.error) {
      setDisplayMessagesAndClass(prevParam => ({
        ...prevParam,
        messages: [`${error.error}`],
        class: false
      }));
      timer = setTimeout(() => {
        setDisplayMessagesAndClass({
          messages: [],
          class: true
        });
      }, 7000);
    }
    if (success?.success) {
      setProductDetailsFunctions.resetAllFields();

      setTab('definition');
      setEnableSaveButton(false);
      setDisplayMessagesAndClass(prevParam => ({
        ...prevParam,
        messages: [`${content.successMessage}`],
        class: true
      }));

      timer = setTimeout(() => {
        setDisplayMessagesAndClass({
          messages: [],
          class: true
        });
      }, 7000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [success, error]);

  const renderMessages = useCallback(messages => messages.map((message, key) => <p key={key}>{message}</p>), [
    displayMessagesAndClass
  ]);

  return (
    <div className={styles.mainContentContainer}>
      <div className={styles.createBatchHeader}>{content.create_product}</div>
      {Array.isArray(displayMessagesAndClass.messages) && displayMessagesAndClass.messages.length > 0 && (
        <div className={`${displayMessagesAndClass.class ? styles.successMessage : styles.errorMessage}`}>
          {renderMessages(displayMessagesAndClass.messages)}
        </div>
      )}
      <div className={styles.licenseContainer}>
        <ToggleTabs selectedTab={selectedTab} setTab={setTab} />

        <div className="definitionTab">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="productContainer">
              <div className={selectedTab === 'definition' ? styles.productTab : styles.productTabHidden}>
                <DefinitionTab productDetails={productDetails} setProductDetails={setProductDetails} />
              </div>
              <div className={selectedTab === 'registration' ? styles.productTab : styles.productTabHidden}>
                Registration
              </div>
              <div className={selectedTab === 'associations' ? styles.productTab : styles.productTabHidden}>
                <AssociationsTab productDetails={productDetails} setProductDetails={setProductDetails} />
              </div>
              <div className={selectedTab === 'external' ? styles.productTab : styles.productTabHidden}>
                <ExternalIdsTab productDetails={productDetails} setProductDetails={setProductDetails} />
              </div>
              <div className={selectedTab === 'urls' ? styles.productTab : styles.productTabHidden}>
                <UrlTab productDetails={productDetails} setProductDetails={setProductDetails} />
              </div>
              <div className={selectedTab === 'platforms' ? styles.productTab : styles.productTabHidden}>
                <PlatformsTab productDetails={productDetails} setProductDetails={setProductDetails} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {' '}
        <button
          className={`${enableSaveButton ? styles.disabledSaveButton : styles.saveButton}`}
          disabled={enableSaveButton}
          type="button"
          onClick={createProduct}
        >
          {content.saveButton}
        </button>
      </div>
    </div>
  );
}

ProductCreatePage.propTypes = {
  localizedContent: PropTypes.object,
  createProductRequestAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  success: PropTypes.object,
  error: PropTypes.object
};

export default connect(
  ({ identity: user, createProduct: { isLoading, error, success } }) => ({
    user,
    isLoading,
    error,
    success
  }),

  {
    createProductRequestAction: createProductRequest
  }
)(withLocalizedContent('productCreate')(ProductCreatePage));
