/* eslint react/no-danger: 0 */

import React from 'react';
import { Helmet } from 'react-helmet';

import ExampleTabForm from './ExampleTabForm.js';

import cmsContent from '../../../utils/cmsContent.js';

import parseMarkdown from '../../../utils/markdown/markdown.js';

// Switch statement for Entering,Submitting,Complete

function ExampleTabPage() {
  const CMS = cmsContent.examplePage || {};
  return (
    <div>
      <Helmet title={CMS.pageTitle} />

      <div className="grid">
        <div className="row">
          <div className="col lg6">
            <ExampleTabForm />
          </div>

          <div className="col lg6">
            <div className="pad4">
              <p>{CMS.contact_information_body_text_title}</p>
              <p>{CMS.oup_contact_email}</p>
              <p>{CMS.contact_information_telephone_text}</p>
              <p>{CMS.oup_contact_phone}</p>
              <p>{CMS.contact_information_availability_text}</p>
              <p>{CMS.contact_information_address_text}</p>
              <p dangerouslySetInnerHTML={{ __html: parseMarkdown(CMS.oup_address_oxford_markdown) }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ExampleTabPage.propTypes = {};

export default ExampleTabPage;
