import { createSelector } from 'reselect';
import { formStates } from '../reducers/userSignInCard.reducer';

const getSignInCardStatus = createSelector(
  state => state.userSignInCard,
  ({ formState, userIds, size }) => {
    const isDownloadInProgress = formState === formStates.CHECKING_SELECTED_USERS && userIds.length > 0;
    const isDownloadCompleted = formState !== formStates.CHECKING_SELECTED_USERS;
    return {
      isDownloadInProgress,
      isDownloadCompleted,
      size
    };
  }
);

export default getSignInCardStatus;
