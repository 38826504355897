import signedFetch from '../apiCalls/util/signedFetch.js';

export const getUpdateOrgInviteUrl = (userId, orgId) =>
  // @ts-ignore
  `${__API_BASE__}/user/${encodeURIComponent(userId)}/invite/${encodeURIComponent(orgId)}`;
/**
 * Updates the status of a users invitation to a group
 * @param {string} userId userId to update invitation status for
 * @param {string} orgId organisation ID of the group the user is invited to
 * @param {object} body.invitationStatus new status of the invite
 * @param {object} body.roleName roleName of user within the invited group
 * @param {object} body.platformCode: CES, OLB, ORB, HUB
 * @return {Promise.<{data: object, status: string}, {error: object}>} confirmation
 */
export default function* updateOrgInvite(userId, orgId, body) {
  return yield signedFetch('updateOrgInvite', getUpdateOrgInviteUrl(userId, orgId), 'PUT', body);
}
