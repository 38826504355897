import * as t from '../../actionTypes';

const initialState = {
  courseId: '',
  resourceId: '',
  resource: {},
  submitting: false,
  success: false,
  error: false
};

function ngsCourseResource(state = initialState, { type, payload }) {
  switch (type) {
    case t.NGS_LOAD_COURSE_RESOURCE:
      return {
        ...initialState,
        courseId: payload.courseId,
        resourceId: payload.resourceId
      };
    case t.NGS_UNLOAD_COURSE_RESOURCE:
      return initialState;
    case t.NGS_LOAD_COURSE_RESOURCE_SUCCESS:
      return { ...state, resource: payload, submitting: false, success: true };
    case t.NGS_LOAD_COURSE_RESOURCE_FAILURE:
      return { ...state, submitting: false, error: true };
    default:
      return state;
  }
}

export default ngsCourseResource;
