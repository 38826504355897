import * as t from '../actionTypes';

const initialState = {
  submitting: false,
  success: false,
  errors: {},
  failure: false
};

function sendDeferredEmails(state = initialState, { type, payload }) {
  switch (type) {
    case t.SEND_DEFERRED_EMAILS_REQUEST:
      return {
        ...initialState,
        submitting: true
      };
    case t.SEND_DEFERRED_EMAILS_FAILURE:
      return {
        submitting: false,
        success: false,
        errors: payload,
        failure: true
      };
    case t.SEND_DEFERRED_EMAILS_SUCCESS:
      return {
        submitting: false,
        errors: {},
        success: true,
        failure: false
      };
    case t.SEND_DEFERRED_EMAILS_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default sendDeferredEmails;
