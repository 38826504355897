import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '../Table/Table';
import styles from './ProductSelection.scss';

/**
 * Quick component to display different content based
 * off breakpoints.
 */
function AdaptiveColumn({ children, large }) {
  return (
    <span>
      <span className="sm-hide">{children}</span>
      <span className="xs-hide sm-show">{large}</span>
    </span>
  );
}
/**
 * Component renders a progress bar based on a completed/total value.
 */
function ProductSelection({
  products,
  gradedReaders = false,
  heading,
  largeCompletionHeading,
  largeScoreHeading,
  shortCompletionText,
  localizedContent
}) {
  const workbookProductColumns = [
    { heading },
    { heading: <AdaptiveColumn large={largeCompletionHeading}>{shortCompletionText}</AdaptiveColumn> },
    { heading: <AdaptiveColumn large={largeScoreHeading}>{largeScoreHeading}</AdaptiveColumn> }
  ];

  const gradedReadersProductColumns = [
    { heading },
    {
      heading: (
        <AdaptiveColumn large={localizedContent.averageBooks}>{localizedContent.averageBooksShort}</AdaptiveColumn>
      )
    },
    {
      heading: (
        <AdaptiveColumn large={localizedContent.mostReadLevel}>{localizedContent.averageLevelShort}</AdaptiveColumn>
      )
    }
  ];

  const columns = gradedReaders ? gradedReadersProductColumns : workbookProductColumns;

  return (
    <div className="row">
      <Table
        columns={columns}
        rows={products}
        customClass={classnames(styles.productSelectionTable, {
          [styles.gradedReaders]: gradedReaders
        })}
        persistantColumns
      />
      {!products.length ? <span className="pad-top1">{localizedContent.noProductsFound}</span> : null}
    </div>
  );
}

ProductSelection.propTypes = {
  localizedContent: PropTypes.object,
  products: PropTypes.array.isRequired,
  gradedReaders: PropTypes.bool,
  heading: PropTypes.string,
  largeCompletionHeading: PropTypes.string,
  largeScoreHeading: PropTypes.string,
  shortCompletionText: PropTypes.string
};

AdaptiveColumn.propTypes = {
  children: PropTypes.string.isRequired,
  large: PropTypes.string
};

export default ProductSelection;
