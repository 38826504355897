import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ClassProgressPage from './ClassProgressPage';
import GradedReadersClassPage from './GradedReadersClassPage';
import GradedReadersLearnerPage from './GradedReadersLearnerPage';
import LearnerProgressPage from './LearnerProgressPage';
import ProductSelectionPage from './ProductSelectionPage';

function Gradebook({ params, myProgress = false, identity, classTitle }) {
  const sectionUid = window.location.hash.slice(1);
  switch (params.panelName) {
    case 'workbook':
      params.learnerId = myProgress ? identity.userId : params.learnerId;
      if (params.learnerId || myProgress) {
        return (
          <LearnerProgressPage
            params={params}
            sectionUid={sectionUid}
            myProgress={myProgress}
            classTitle={classTitle}
          />
        );
      }
      return <ClassProgressPage role={identity.role} params={params} sectionUid={sectionUid} classTitle={classTitle} />;
    case 'reader':
      if (params.itemId) {
        return <GradedReadersLearnerPage params={params} />;
      }
      return <GradedReadersClassPage params={params} classTitle={classTitle} />;
    case '':
    default:
      return <ProductSelectionPage role={identity.role} params={params} myProgress={myProgress} identity={identity} />;
  }
}

Gradebook.propTypes = {
  params: PropTypes.object.isRequired,
  myProgress: PropTypes.bool,
  identity: PropTypes.bool,
  classTitle: PropTypes.string
};

export default connect((state, { params }) => ({
  identity: state.identity,
  classTitle: (state.classrooms.data[params.classroomId] || {}).name
}))(Gradebook);
