import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { GLYPHS as Glyphs } from '../SVGIcon/SVGIcon';
import Color from '../../styles/constants/_colors.scss';
import prefixKeys from '../../utils/object/prefixKeys';
import randomString from '../../utils/randomString';
import IconEmblem from '../IconEmblem/IconEmblem';
import styles from './RadioInput.scss';

export const RadioSize = {
  SMALL: Symbol('small'),
  MEDIUM: Symbol('medium'),
  LARGE: Symbol('large')
};

export const InputType = {
  RADIO: Symbol('radio'),
  CHECKBOX: Symbol('checkbox')
};

export const RadioSizeMap = {
  [RadioSize.SMALL]: styles['radioInput-small'],
  [RadioSize.MEDIUM]: styles['radioInput-medium'],
  [RadioSize.LARGE]: styles['radioInput-large']
};

export const IconSizeMap = {
  [RadioSize.SMALL]: styles['icon-small'],
  [RadioSize.MEDIUM]: styles['icon-medium'],
  [RadioSize.LARGE]: styles['icon-large']
};

export const TextSizeMap = {
  [RadioSize.SMALL]: styles['text-small'],
  [RadioSize.MEDIUM]: styles['text-medium'],
  [RadioSize.LARGE]: styles['text-large']
};

export const InputTypeMap = {
  [InputType.RADIO]: 'radio',
  [InputType.CHECKBOX]: 'checkbox'
};

function RadioInput({
  name,
  label,
  value,
  onChange = () => {},
  inputType = InputType.RADIO,
  size = RadioSize.MEDIUM,
  glyph = Glyphs.ICON_TICK,
  color = Color.primary,
  checked = false,
  disabled = false,
  required = false,
  indented = false,
  aria = {}
}) {
  const ariaAttrs = prefixKeys(aria, 'aria-');
  const id = randomString(8);

  return (
    <label
      className={classnames(RadioSizeMap[size], {
        [styles.disabled]: disabled,
        [styles.indented]: indented
      })}
      htmlFor={id}
    >
      <input
        id={id}
        checked={checked}
        type={InputTypeMap[inputType]}
        name={name}
        value={value}
        className={styles.input}
        onChange={() => onChange(!checked)}
        disabled={disabled}
        required={required}
        {...ariaAttrs}
      />
      <IconEmblem
        className={classnames(IconSizeMap[size], {
          [styles['icon-checked']]: checked
        })}
        icon={glyph}
        colour={checked ? color : Color.background}
      />
      <span className={styles.text}>{label}</span>
    </label>
  );
}

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  inputType: PropTypes.oneOf(Object.values(InputType)),
  size: PropTypes.oneOf(Object.values(RadioSize)),
  glyph: PropTypes.oneOf(Object.values(Glyphs)),
  color: PropTypes.oneOf(Object.values(Color)),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  indented: PropTypes.bool,
  aria: PropTypes.object
};

export default RadioInput;
