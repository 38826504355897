import { put, select } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import editManagedUserAuth from '../../../../../../static/schema/draft-4/edit-managed-user-auth-request-body.json';

import { changeManagedUserPasswordSuccess, changeManagedUserPasswordFailure } from '../../../../actions/managedUser';
import signedFetch from '../../../apiCalls/util/signedFetch';
import generateUserToken, { TokenType } from '../../../utils/generateUserToken';
import validateGeneratedPasswordUsingSchema from '../../../utils/managedUser/validateGeneratedPasswordUsingSchema';
import { featureIsEnabled } from '../../../../../globals/envSettings';

function* changeManagedUserPassword(orgId, userId) {
  if (featureIsEnabled('lock-account')) {
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const user of userId) {
        const response = yield signedFetch('unlockManagedUser', `${__API_BASE__}/open/user/${user}/unlock`, 'GET');
        if (response.status !== 'success') {
          throw new Error(response.errorMessages);
        }
      }

      yield put(changeManagedUserPasswordSuccess());
    } catch (e) {
      yield put(changeManagedUserPasswordFailure());
    }
  } else {
    const schemaForNewPassword = { ...editManagedUserAuth, required: ['newPassword'] };
    const { password: newPassword } = yield select(state => state.managedUserChangePassword);

    const errors = yield validateGeneratedPasswordUsingSchema(
      schemaForNewPassword,
      t.CHANGE_MANAGED_USER_PASSWORD_FAILURE,
      { newPassword }
    );
    const hasErrors = Object.values(errors).some(Boolean);

    if (hasErrors) {
      yield put(changeManagedUserPasswordFailure());
      return;
    }

    try {
      const response = yield signedFetch(
        'editManagedUserAuth',
        `${__API_BASE__}/org/${orgId}/managed-user/${userId}/auth`,
        'PUT',
        {
          userToken: yield generateUserToken(orgId, userId, TokenType.UPDATE_AUTH),
          newPassword
        }
      );

      if (response.status !== 'success') {
        throw new Error(response.errorMessages);
      }

      yield put(changeManagedUserPasswordSuccess());
    } catch (e) {
      yield put(changeManagedUserPasswordFailure());
    }
  }
}

export default changeManagedUserPassword;
