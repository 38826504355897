import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import itemStyle from '../StudentProgress/StudentProgressItem.scss';
import style from './StudentProgressLevelChangeItem.scss';

import withLocalizedContent from '../../language/withLocalizedContent';
import RelativeDate from '../RelativeDate/RelativeDate';

class StudentProgressLevelChangeItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      localizedContent: { studentProgressLevelChangeItemComponent: content },
      item
    } = this.props;

    const labels = {
      up: content.move_up_label,
      down: content.move_down_label
    };

    const messages = {
      up: content.move_up_message,
      down: content.move_down_message
    };

    const message = messages[item.direction] || '';

    return (
      <div className={classnames(itemStyle.itemContainer, style.levelChange)}>
        <div className={classnames('row', itemStyle.content)}>
          <div className="col sm12">
            <div className={classnames(itemStyle.info, itemStyle.levelChange)}>
              <p className={itemStyle.lozenge}>{labels[item.direction]}</p>
              {/* eslint-disable-next-line no-template-curly-in-string */}
              <h2>{message.replace('${levelName}', item.current_level_name)}</h2>
              <p className={itemStyle.lead}>
                {content.date}
                <RelativeDate date={item.date} />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StudentProgressLevelChangeItem.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

export default withLocalizedContent('studentProgressLevelChangeItemComponent')(StudentProgressLevelChangeItem);
