import React from 'react';
import PropTypes from 'prop-types';
import PopoutPanelIconHeading, {
  types
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import content from '../../../../../../utils/cmsContent.js';

function ClassUserRequestSubmitting({ status }) {
  const CMS = content.manageJoinRequest || {};
  return (
    <PopoutPanelIconHeading
      type={types.LOADING}
      title={CMS.loading_page_title_text}
      subtitle={status === 'ACCEPTED' ? CMS.loading_page_accept_subtitle_text : CMS.loading_page_reject_subtitle_text}
    />
  );
}

ClassUserRequestSubmitting.propTypes = {
  status: PropTypes.string
};

export default ClassUserRequestSubmitting;
