import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { Link as RouterLink } from 'react-router-dom';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

// Component imports
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import PanelHeading from '../../../../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import TextInput from '../../../../../../components/TextInput/TextInput.js';
import ImportCTA from '../../../../../../components/ImportCTA/ImportCTA.js';

import Validation from '../../../../../../components/Validation/Validation.js';

import {
  submitForm,
  selectFile,
  showBulkInput,
  setAccessCode,
  validateAccessCode
} from '../../../../../../redux/reducers/addToLibrary.reducer.js';
import { reCAPTCHASetToken } from '../../../../../../redux/actions/reCAPTCHA.js';

import { isCesMode, isHubMode } from '../../../../../../utils/platform';
import cmsContent from '../../../../../../utils/cmsContent.js';

function AddToLibraryEditInputting({
  closePanel,
  submitFormAction,
  bulkInputAction,
  accessCodeValue,
  setAccessCodeAction,
  validateAccessCodeAction,
  accessCodeValueIsValid,
  accessCodeValueIsError,
  orgName,
  setReCAPTCHATokenAction,
  googleReCaptchaProps
}) {
  useEffect(async () => {
    const token = await googleReCaptchaProps.executeRecaptcha(APP_CONSTANTS.RECAPTCHA_ACTIONS.REDEEM_CODE);
    setReCAPTCHATokenAction(token);
  }, [googleReCaptchaProps]);

  const CMS = cmsContent.addToLibrary || {};
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePanel} />
            </div>
            <PanelHeading
              title={CMS.add_to_library_footer_text}
              subtitle={CMS.bulk_import_access_codes_subtitle_text.replace('{orgName}', orgName)}
            />
          </div>
        }
        footerContent={
          <PopoutNavFooter
            nextButtonText={CMS.add_to_library_footer_text}
            nextAction={submitFormAction}
            nextButtonDisabled={!accessCodeValueIsValid}
            backAction={closePanel}
          />
        }
      >
        <div style={{ padding: '1rem' }}>
          <ImportCTA
            importText={CMS.import_from_file_text}
            buttonText={CMS.button_import_from_file_text}
            bulkInputAction={bulkInputAction}
          />

          <Validation
            forId="enterAccessCode"
            isValid={accessCodeValueIsValid}
            isError={accessCodeValueIsError}
            message={accessCodeValueIsError ? CMS.invalid_access_code_error : null}
          >
            <TextInput
              id="enterAccessCode"
              placeholder="1111-2222-3333"
              maxLength={14}
              label={CMS.enter_access_code_text}
              value={accessCodeValue}
              onChange={setAccessCodeAction}
              onBlur={validateAccessCodeAction}
            />
          </Validation>
          <p className="gin-top2">{CMS.enter_access_code_body_text}</p>
          <p>
            {CMS.import_access_codes_start}
            <TextLink
              to="#import"
              onClick={e => {
                e.preventDefault();
                bulkInputAction();
              }}
            >
              {CMS.import_access_codes_text}
            </TextLink>
            {CMS.import_access_codes_end}
          </p>
          <br />
          {(isCesMode() || isHubMode()) && (
            <p>
              <TextLink
                to={
                  isCesMode() ? APP_CONSTANTS.REDEEM_CODE_HELP_LINK : APP_CONSTANTS.ADD_ORGANIZATION_LICENCES_HELP_LINK
                }
                target="_blank"
                rel="noreferrer noopener"
                component={isCesMode() ? RouterLink : null}
              >
                {CMS.help_link_text}
              </TextLink>
            </p>
          )}
          <br />
          {(isCesMode() || isHubMode()) && (
            <p>
              <TextLink to={APP_CONSTANTS.REDEEM_CODE_PURCHASE_LINK} target="_blank" rel="noreferrer noopener">
                {CMS.purchase_additional_codes_link_text}
              </TextLink>
            </p>
          )}
        </div>
      </ScrollContainer>
    </form>
  );
}

AddToLibraryEditInputting.propTypes = {
  closePanel: PropTypes.func.isRequired,
  submitFormAction: PropTypes.func.isRequired,
  accessCodeValue: PropTypes.string.isRequired,
  bulkInputAction: PropTypes.func.isRequired,
  setAccessCodeAction: PropTypes.func.isRequired,
  validateAccessCodeAction: PropTypes.func.isRequired,
  accessCodeValueIsValid: PropTypes.bool.isRequired,
  accessCodeValueIsError: PropTypes.bool.isRequired,
  orgName: PropTypes.string.isRequired,
  setReCAPTCHATokenAction: PropTypes.func.isRequired,
  googleReCaptchaProps: PropTypes.object.isRequired
};

export default connect(
  state => ({
    file: state.addToLibrary.file,
    isFileSelected: state.addToLibrary.isFileSelected,
    accessCodeValue: state.addToLibrary.accessCodeValue,
    accessCodeValueIsValid: state.addToLibrary.accessCodeValueIsValid,
    accessCodeValueIsError: state.addToLibrary.accessCodeValueIsError
  }),
  {
    submitFormAction: submitForm,
    selectFileAction: selectFile,
    bulkInputAction: showBulkInput,
    setAccessCodeAction: setAccessCode,
    validateAccessCodeAction: validateAccessCode,
    setReCAPTCHATokenAction: reCAPTCHASetToken
  }
)(withGoogleReCaptcha(AddToLibraryEditInputting));
