import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

export const StatusEnum = {
  Error: 'ERROR',
  InProgress: 'INPROGRESS',
  Success: 'SUCCESS'
};

function* postCsvProductStructureUpload(bidsData, platform, platformSource) {
  bidsData.bIds = bidsData.bIds.filter(item => item);
  // @ts-ignore
  const result = yield signedFetch(
    'sharedPublishEventRBAC',
    `${__API_BASE__}/content/publish/${platform}/${platformSource}`,
    'POST',
    bidsData
  );

  if (result.status === 'error' || result.status === 'fail') {
    yield put(actions.csvProductStructureUploadFailure({ status: StatusEnum.Error }));
  } else {
    yield put(actions.csvProductStructureUploadSuccess({ status: StatusEnum.Success }));
  }
}

export default postCsvProductStructureUpload;
