import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../ScrollContainer/ScrollContainer';

function PasswordResetProcessing({ localizedContent: { passwordResetProcessingComponent: content } }) {
  return (
    <ScrollContainer>
      <PopoutPanelIconHeading {...pick(content, ['title', 'subtitle'])} type={popoutPanelIconHeadingTypes.LOADING} />
    </ScrollContainer>
  );
}

PasswordResetProcessing.propTypes = { localizedContent: PropTypes.object.isRequired };

export default withLocalizedContent('passwordResetProcessingComponent')(PasswordResetProcessing);
