import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { GLYPHS, validGlyphs } from '../SVGIcon/SVGIcon';
import TextInputButton from '../TextInputButton/TextInputButton';
import TextInputField from '../TextInputField/TextInputField';
import TextInputLabel from '../TextInputLabel/TextInputLabel';
import styles from './TextInputWithButton.scss';

/** The component renders a text input label field and button */
function TextInputWithButton({
  id,
  name,
  label = '',
  labelHidden,
  placeholder,
  value,
  onChange,
  onBlur,
  onKeyDown,
  onTypeStop,
  type,
  autoFocus = false,
  disabled,
  readOnly,
  required,
  buttonType = 'submit',
  buttonAction,
  buttonDisabled,
  buttonIcon,
  buttonTitle,
  buttonText,
  ariaControls,
  onFocus,
  aria,
  role,
  readonlyMultiline = false
}) {
  return (
    <div className={classnames(styles.textInputWithButton)}>
      {!labelHidden ? <TextInputLabel id={`${id}-input`} label={label} labelHidden={labelHidden} /> : null}
      <div>
        <TextInputField
          id={`${id}-input`}
          autoFocus={autoFocus}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onTypeStop={onTypeStop}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          readOnlyPreserveStyle
          required={required}
          aria={{ ...aria, controls: ariaControls, ...(labelHidden ? { label } : {}) }}
          role={role}
          readonlyMultiline={readonlyMultiline}
        />
        <TextInputButton
          id={`${id}-button`}
          type={buttonType}
          icon={buttonTitle && !buttonIcon ? undefined : buttonIcon || GLYPHS.ICON_SEARCH}
          title={buttonTitle}
          text={buttonText}
          action={buttonAction}
          disabled={buttonDisabled}
          ariaControls={ariaControls}
          isPartOfMultilineGroup={readonlyMultiline}
        />
      </div>
    </div>
  );
}

TextInputWithButton.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  /** the inputs text label */
  label: PropTypes.string,
  /** The label can be hidden to users and only visible to screen readers */
  labelHidden: PropTypes.bool,
  /** the inputs placeholder text */
  placeholder: PropTypes.string,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.any,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func.isRequired,
  /** Function to bind to the native input onBlur event */
  onBlur: PropTypes.func,
  /** Function to bind to the native input onKeyDown event */
  onKeyDown: PropTypes.func,
  /** function that fires when typing stops */
  onTypeStop: PropTypes.func,
  /** The type attribute of the <input> element. Restricted to text looking inputs (ie not checkbox etc). */
  type: PropTypes.oneOf(['text', 'number', 'date']),
  autoFocus: PropTypes.bool,
  /** Set this to true to disable any interaction on the input field */
  disabled: PropTypes.bool,
  /** Set whether the field is for presentation and is not editable. */
  readOnly: PropTypes.bool,
  /** Set this to false to prevent textbox from defaulting to required, for screenreaders etc */
  required: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  /** Action button icon or text */
  buttonIcon: PropTypes.oneOf(validGlyphs),
  /** Optional button title to display */
  buttonTitle: PropTypes.string,
  /** Action button text */
  buttonText: PropTypes.string,
  /** html button type */
  buttonType: PropTypes.oneOf(['button', 'submit']),
  /** button onclick */
  buttonAction: PropTypes.func,
  /** the id(s) of the controlled elements */
  ariaControls: PropTypes.string,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object,
  role: PropTypes.string,
  onFocus: PropTypes.func,
  readonlyMultiline: PropTypes.bool
};

export default TextInputWithButton;
