/**
 * 8x8 live chat
 * Trigger `liveChat` custom event on homepage, redeem, help&support pages when needed
 */
import { isLocal } from '../../globals/envSettings';
import { extractRootDomain } from '../url';

export default function mountLiveChat() {
  const IMG_ALT_CHAT = 'Open Live Chat to speak to Oxford University Press Customer Support';
  const LINK_LABEL_CHAT = 'Live Chat';
  const IFRAME_TITLE = 'Oxford University Press Customer Support Live Chat';
  const CHAT_DOMAIN = '8x8.com';

  if (isLocal()) {
    return;
  }

  // @ts-ignore
  window.__8x8Chat = {
    uuid: 'script_162864932760fe7c9c31dfa7.24451536',
    tenant: 'b3hmb3JkdW5pdmVyc2l0eTAx',
    channel: 'ELTHub',
    domain: 'https://vcc-eu9.8x8.com',
    path: '/.',
    buttonContainerId: '__8x8-chat-button-container-script_162864932760fe7c9c31dfa7.24451536',
    align: 'right',
    style: 'position:fixed;top:50%;right:0;z-index:100;cursor:pointer'
  };

  function addAccessibilityControls(chatContainer) {
    // More Details https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
    // create an observer instance
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.target) {
          // @ts-ignore
          const linkNode = mutation.target.querySelector('a');
          if (linkNode) {
            // Update Button aria label attribute
            linkNode.setAttribute('aria-label', LINK_LABEL_CHAT);
          }

          // @ts-ignore
          const imgNode = mutation.target.querySelector('img');
          if (imgNode) {
            // Update Button Image alt attribute
            imgNode.setAttribute('alt', IMG_ALT_CHAT);

            // Identify chat Iframe by domain and add title attribute
            const iframeNodes = document.querySelectorAll('iframe');
            if (iframeNodes.length > 0) {
              iframeNodes.forEach(iframeNode => {
                const iframeDomain = extractRootDomain(iframeNode.getAttribute('src'));
                if (iframeDomain && iframeDomain === CHAT_DOMAIN) {
                  iframeNode.setAttribute('title', IFRAME_TITLE);
                }
              });

              // Stop observer if all tasks are done
              setTimeout(() => {
                observer.disconnect();
              }, 1);
            }
          }
        }
      });
    });
    // pass in the target node, as well as the observer options
    observer.observe(chatContainer, { childList: true });
  }

  (() => {
    const chatContainer = document.createElement('div');
    chatContainer.id = window.__8x8Chat.buttonContainerId;
    chatContainer.style.cssText = window.__8x8Chat.style;
    document.body.appendChild(chatContainer);
    // setTimeout(() => {
    //   div.style.opacity = '1';
    // }, 1750);

    const se = document.createElement('script');
    se.type = 'text/javascript';
    se.async = true;

    se.src = `${window.__8x8Chat.domain + window.__8x8Chat.path}/CHAT/common/js/chat.js`;
    const os = document.getElementsByTagName('script')[0];
    os.parentNode.insertBefore(se, os);
    // @ts-ignore
    se.onload = addAccessibilityControls(chatContainer);
  })();
}

export const unmountLiveChat = () => {
  if (isLocal()) {
    return;
  }

  const div = document.getElementById(window.__8x8Chat?.buttonContainerId);
  if (div.nextSibling) div.nextSibling.remove();
  if (div) div.remove();
};
