import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getSubsciptionId from './generateSubscriptionId.saga';
import createUserActivity from './createUserActivity.saga';

function* root() {
  yield takeLatest(t.GET_SUBSCRIPTION_ID_REQUEST, getSubsciptionId);
  yield takeLatest(t.CREATE_USER_ACTIVITY_REQUEST, createUserActivity);
}

export default root;
