import { featureIsEnabled } from '../../../globals/envSettings';

export default () => {
  if (featureIsEnabled('redirect-if-legacy-domain')) {
    const legacyAcesDomain = '.aces.the-infra.com';
    if (window.location.host.indexOf(legacyAcesDomain) >= 0) {
      window.location.replace(window.location, '.account.oup.com');
    }
  }
};
