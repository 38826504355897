import * as t from '../actionTypes';

export const getPlatformCountryCodesRequest = role => ({
  type: t.GET_PLATFORM_COUNTRY_CODES_REQUEST,
  payload: role
});

export const getPlatformCountryCodesSuccess = countryCodes => ({
  type: t.GET_PLATFORM_COUNTRY_CODES_SUCCESS,
  payload: countryCodes
});
