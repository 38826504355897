import { put } from 'redux-saga/effects';
import {
  assignMultipleProductsRequestSuccess,
  assignMultipleProductsRequestFailure,
  setFormState
} from '../../../../actions/productFinderActions';
import { saveLearningMaterialsSuccess } from '../../../../actions/classOnboardingWizard';
import { productFinderFormStates } from '../../../../reducers/productFinder.reducer';
import createMultipleLearningAssignmentApi from '../../../apiCalls/library/createMultipleLearningAssignments.api';
import { HubLayoutConstants } from '../../../../../globals/hubConstants';
import { assignmentContexts } from '../../../../../globals/assignmentConstants';

export default function* assignProductFinderProducts({
  orgId,
  classId,
  selectedProducts = [],
  licenceStructure = {},
  existingAssignmentId = '',
  contextName = '',
  context
}) {
  const data = {};

  selectedProducts.forEach(product => {
    const productId = product.productid;
    const licenceData = licenceStructure[productId] || {};
    let shouldAssignLicences = licenceData?.assignLicencesForProduct;

    let userIdsToAssignTo = [];
    // If it is a class assignment and licences should not be assigned, then we do not need the userIds
    if (!(classId && !shouldAssignLicences)) {
      const teacherIds =
        !classId ||
        (classId &&
          licenceData.assignLicenceToTeacher &&
          product.target_usertype !== HubLayoutConstants.TARGET_USERTYPE.STUDENT)
          ? licenceData.teacherIds
          : [];

      // check if targetUserType is not TEACHER so we do not assign teacher materials to students, only student and neutral - EPS-15476
      const studentIds =
        product.target_usertype !== HubLayoutConstants.TARGET_USERTYPE.TEACHER ? licenceData.studentIds : [];
      const allUsersIds = [...teacherIds, ...studentIds];
      if (shouldAssignLicences) {
        userIdsToAssignTo = [...allUsersIds].filter(userId => !licenceData.usersIdsWithLicences.includes(userId));
      } else {
        userIdsToAssignTo = allUsersIds;
      }
    }

    // If it is a class assignment and there are no users to assign licences to, then send the assignLicences as false
    if (!!classId && !userIdsToAssignTo?.length) {
      shouldAssignLicences = false;
    }

    data[productId] = {
      productName: product.title,
      existingAssignmentId,
      userIds: userIdsToAssignTo,
      assignLicences: shouldAssignLicences
    };
  });

  const payload = {
    classId,
    contextName,
    data
  };

  const assignmentResult = yield createMultipleLearningAssignmentApi(orgId, payload);

  if (assignmentResult.status === 'success') {
    if (context === assignmentContexts.CLASS_ONBOARDING_WIZARD) {
      yield put(saveLearningMaterialsSuccess(selectedProducts));
    }
    yield setFormState(productFinderFormStates.CONFIRMATION);
    return yield put(assignMultipleProductsRequestSuccess());
  }
  console.log('[assignLearningMaterial] Assignment result:', assignmentResult);
  return yield put(assignMultipleProductsRequestFailure());
}
