import moment from 'moment';
// TO DO: fix dependency cycle
// eslint-disable-next-line import/no-cycle
import store from '../../../store.js';

import { getIdpLoginUrl } from './getCredentials/redirectToIdP.saga.js';

import {
  setRenewedAuthCredentials,
  setOidcSessionClock,
  setRenewedOIDCAuthCredentials,
  setRefreshFailed,
  triggerLogout
} from '../../../reducers/identity.reducer.js';

import { isLocal, isOIDCStandard } from '../../../../globals/envSettings';

import authSettings from '../../../../globals/authSettings.js';
import initAuth0 from '../../../../globals/oidcSettings';
import session from '../../../../utils/session';

const IFRAME_ID = 'authRefresher';

let failTimeoutId;
let auth0IdleSessionCounter = null;

function handleRefreshFailure() {
  console.log('[handleRefreshFailure] Auth refresh failed. User must log in again');

  // Delete iframe
  document.body.removeChild(document.getElementById(IFRAME_ID));

  // Set up message for when expiry hits
  const expiryTime = store.getState().identity.auth.expiryTime;
  const delay = moment(expiryTime)
    .subtract(10, 'seconds')
    .diff();

  console.log(`[handleRefreshFailure] User will be forced to refresh in ${delay}ms`);
  setTimeout(() => {
    store.dispatch(setRefreshFailed());
    store.dispatch(triggerLogout(true));
  }, delay);
}

async function performAuthRefresh() {
  console.log('[performAuthRefresh]');

  // Generate an iframe
  const iframe = document.createElement('iframe');
  iframe.id = IFRAME_ID;
  const url = await getIdpLoginUrl(null, { withReturnTo: false, authRefreshOnly: true });
  iframe.src = url;

  console.debug(`[performAuthRefresh] IDP URL is set to:${iframe.src}`);

  iframe.onload = event => console.log('onload', event);
  iframe.onerror = event => console.log('onerror', event);

  // Hide or show the iframe based on env
  // @ts-ignore
  iframe.style.cssText = __PROD__
    ? 'display: none;'
    : `
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 300px;
    height: 300px;
    background: #fff;
    border: 1px solid #000;
  `;

  // Add the iframe to the page to trigger the process
  document.body.appendChild(iframe);

  // Give the refresh 60 seconds to complete
  failTimeoutId = setTimeout(handleRefreshFailure, 60000);
}

function extendOidcSessionCall(done) {
  initAuth0()
    .then(async auth0 => {
      try {
        const accessToken = await auth0.getTokenSilently({ cacheMode: 'off' });
        const claims = await auth0.getIdTokenClaims();
        done(null, {
          idToken: claims.__raw,
          accessToken,
          expiresIn: claims.exp - claims.iat
        });
      } catch (err) {
        done(err, {});
      }
    })
    .catch(err => {
      done(err, {});
    });
}

function setOIDCSessionClock() {
  const sessionInfo = session.get();
  const currentTimeinMilliseconds = moment().valueOf();
  const newTime = currentTimeinMilliseconds + sessionInfo.oidcExpiresIn * 1000;

  store.dispatch(setOidcSessionClock(newTime));

  sessionInfo.oidcSessionClock = newTime;
  session.set(sessionInfo);
}

function updateOidcCredentials({ idToken, accessToken, expiresIn }) {
  store.dispatch(setRenewedOIDCAuthCredentials(accessToken, expiresIn));

  const sessionInfo = session.get();
  sessionInfo.oidcIdToken = idToken;
  sessionInfo.oidcAccessToken = accessToken;
  sessionInfo.oidcExpiresIn = expiresIn;
  session.set(sessionInfo);
}

function startOIDCSessionTimer() {
  let oidcIdleSessionInterval = 0;

  extendOidcSessionCall((error, startAuthResult) => {
    clearInterval(auth0IdleSessionCounter);

    if (error) {
      console.log('Session Expired:: Logging out');
      store.dispatch(triggerLogout(true));
      return;
    }

    oidcIdleSessionInterval = startAuthResult.expiresIn * 1000 - authSettings.oidcIdleSessionPreCallValue; // in milliseconds
    setOIDCSessionClock();
    updateOidcCredentials(startAuthResult);

    auth0IdleSessionCounter = setInterval(() => {
      extendOidcSessionCall((err, authResult) => {
        if (err) {
          clearInterval(auth0IdleSessionCounter);
          console.log('Session Expired:: Logging out');
          store.dispatch(triggerLogout(true));
          return;
        }

        setOIDCSessionClock();
        updateOidcCredentials(authResult);
      });
    }, oidcIdleSessionInterval);
  });
}

const extendSessionPromise = (currentTimeinMilliseconds, expirySessionTime) =>
  new Promise(resolve => {
    if (expirySessionTime - currentTimeinMilliseconds > 0) {
      extendOidcSessionCall((err, authResult) => {
        if (err) {
          console.log('Error::CheckSession', err);
          clearInterval(auth0IdleSessionCounter);
          console.log('Session Expired:: Logging out');
          resolve(false);
        }
        setOIDCSessionClock();
        updateOidcCredentials(authResult);
        startOIDCSessionTimer();
        resolve(true);
      });
    } else {
      console.log('Session Expired:: Logging out');
      resolve(false);
    }
  });

function* checkOIDCSessionStatus() {
  const currentTimeinMilliseconds = moment().valueOf();
  const sessionInfo = session.get();
  const expirySessionTime = parseInt(sessionInfo.oidcSessionClock, 10);
  // Extend Session
  const status = yield extendSessionPromise(currentTimeinMilliseconds, expirySessionTime);
  return status;
}

export function* handleOidcSession(receivedNewCreds) {
  let sessionResult;
  if (receivedNewCreds) {
    setOIDCSessionClock();
    startOIDCSessionTimer();
    // Deliberately returning true to not block the identity call after login
    sessionResult = true;
  } else {
    try {
      sessionResult = yield checkOIDCSessionStatus();
    } catch (error) {
      console.log(`Something went wrong while extending session, ${error}`);
      sessionResult = false;
    }
  }
  return sessionResult;
}

export function setRefreshTimeout(expiryTimeParm) {
  console.log(`[setRefreshTimeout] Parm : <${expiryTimeParm}>`);
  if (isOIDCStandard(authSettings.idpStandard)) {
    return;
  }
  // Session expires in approx 26 mins
  // For local set refresh to minimum
  // For non dev set refresh to 26 - 6 = approx 20 mins
  // If refresh is out of bounds set to max refresh

  const refreshMin = 60000 * 5;
  const refreshMax = 60000 * 20;
  const refreshGap = 6;

  let refreshDelay = 0;

  const currentTime = moment();
  const expiryTime = moment(expiryTimeParm);

  if (isLocal()) {
    refreshDelay = refreshMin;
  } else {
    refreshDelay = expiryTime.subtract(refreshGap, 'minutes').diff(currentTime);

    if (refreshDelay < refreshMin || refreshDelay > refreshMax) {
      refreshDelay = refreshMax;
    }
  }

  const refreshDelayMins = (refreshDelay / (1000 * 60)).toFixed(1);
  console.log(`[setRefreshTimeout] Current time (client): ${currentTime.format()}`);
  console.log(`[setRefreshTimeout] Expiry time  (server): ${expiryTime.format()}`);
  console.log(`[setRefreshTimeout] Setting auth refresh timer for: ${refreshDelayMins}mins (${refreshDelay}ms)`);

  setTimeout(performAuthRefresh, refreshDelay);
}

export function prepareAuthRefreshListener() {
  console.log('[prepareAuthRefreshListener]');
  window.addEventListener(
    'message',
    // @ts-ignore
    (event = {}) => {
      const sourceOrigin = event.origin;
      const payload = event.data;

      // console.debug('[preparePostListener] window.location.origin :', window.location.origin);
      // console.debug('[preparePostListener] event.origin           :', event.origin);
      // console.debug('[preparePostListener] event.data             :', event.data);

      if (sourceOrigin === window.location.origin) {
        if (payload.type === 'AUTH_REFRESH') {
          console.log('[preparePostListener] Got new auth credentials post:', event);
          clearTimeout(failTimeoutId);

          // Delete iframe
          document.body.removeChild(document.getElementById(IFRAME_ID));

          // Update stored creds
          store.dispatch(
            setRenewedAuthCredentials(
              payload.credentials.accessKeyId,
              payload.credentials.secretAccessKey,
              payload.credentials.sessionToken,
              payload.credentials.expiryTime
            )
          );

          // Prepare next timeout
          setRefreshTimeout(payload.credentials.expiryTime);
        } else if (payload.type === 'LOGIN_REQUIRED') {
          console.log('[preparePostListener] Login required', event);
          handleRefreshFailure();
        }
      }
    },
    false
  );
}
