import React from 'react';
import TextInputField from '../TextInputField/TextInputField';
import styles from './ListPageControls.scss';

function ListPageControlsSkeleton() {
  return (
    <div className={styles.controls}>
      <TextInputField required={false} disabled />
      <TextInputField required={false} disabled customStyleName={styles.skeletonButton} />

      <TextInputField required={false} disabled customStyleName={styles.skeletonButton} />

      <TextInputField required={false} disabled customStyleName={styles.skeletonRoundButton} />

      {/* <Button type={buttonTypes.ROUNDED} customClassName={styles.refreshButton} onClick={() => {}} /> */}
    </div>
  );
}

export default ListPageControlsSkeleton;
