import PropTypes from 'prop-types';
import React from 'react';
import PanelNavigationLink from '../../PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIllustrationHeading from '../../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import InformationBanner from '../../InformationBanner/InformationBanner';
import { HubIllustrationConstants, HubIllustrationAltText, PLATFORMS } from '../../../globals/hubConstants.js';
import { featureIsEnabled } from '../../../globals/envSettings';
import styles from './ProductFinderMultipleConfirmation.scss';

function ProductFinderMultipleConfirmation({
  contextName,
  userEmail,
  closePanel,
  productFinderContent,
  selectedProducts = []
}) {
  const isNgContent = products => !products?.some(product => product.platform !== PLATFORMS.ELTCORE);

  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={productFinderContent.close_panel_text} action={closePanel} />
          </div>
          <PopoutPanelIllustrationHeading
            title={`${productFinderContent.multiple_assignments_success_title} ${contextName}`}
            illustrationSrc={HubIllustrationConstants.INVITED}
            illustrationAltText={HubIllustrationAltText.INVITED}
          />
        </div>
      }
      footerContent={
        <PopoutActionFooter
          primaryButtonText={productFinderContent.complete_panel_text}
          primaryButtonAction={closePanel}
          primaryButtonTestHook="ASSIGN_LEARNING_MATERIAL_PANEL_CONFIRMATION_BUTTON"
        />
      }
    >
      <div>
        <div className={`pad-top1 pad-bot1 pad-left2 pad-right2 ${styles.subtextContainer}`}>
          <p>{productFinderContent.we_will_send_you_email_1}</p>
          <div className={styles.emailContainer}>
            <span>{userEmail}</span>
          </div>
          <p>{productFinderContent.we_will_send_you_email_2}</p>
          <p>{productFinderContent.continue_using_your_account}</p>
          {isNgContent(selectedProducts) && featureIsEnabled('content-lock-unlock') ? (
            <InformationBanner message={productFinderContent.lock_unlock_information_message} />
          ) : null}
        </div>
      </div>
    </ScrollContainer>
  );
}

ProductFinderMultipleConfirmation.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  contextName: PropTypes.string,
  userEmail: PropTypes.string,
  closePanel: PropTypes.func.isRequired,
  selectedProducts: PropTypes.array
};

export default ProductFinderMultipleConfirmation;
