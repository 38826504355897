import * as t from '../actionTypes';

export const initialState = {
  inProgress: true,
  loading: false,
  errorOccurred: false,
  errorMessage: null,
  success: false,
  generateReportResult: null
};

function schoolReport(state = initialState, action = {}) {
  switch (action.type) {
    // Initialise action
    case t.INITIALISE:
      return {
        ...initialState
      };
    case t.GENERATE_SCHOOL_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        errorOccurred: false,
        success: false,
        formData: action.reportType === 'orbclassreport' ? action.options : action.formData,
        reportType: action.reportType
      };
    case t.GENERATE_SCHOOL_REPORT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        success: true,
        generateReportResult: action.generateReportResult,
        loading: false,
        errorOccurred: false,
        inProgress: false
      };
    case t.GENERATE_SCHOOL_REPORT_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: false,
        errorOccurred: true,
        inProgress: false,
        success: false
      };
    default:
      return state;
  }
}
export default schoolReport;

export const startGenerateReportRequest = (reportType, options) => ({
  type: t.GENERATE_SCHOOL_REPORT_REQUEST,
  reportType,
  options: reportType === 'orbclassreport' ? options : {}
});

export const showSuccessMessage = generateReportResult => ({
  type: t.GENERATE_SCHOOL_REPORT_SUCCESS,
  generateReportResult
});

export const showGenerateReportError = errorMessage => ({
  type: t.GENERATE_SCHOOL_REPORT_FAILURE,
  errorMessage
});

export const reset = () => ({
  type: t.INITIALISE
});
