import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import withLocalizedContent from '../language/withLocalizedContent';

import OrganisationAdmin from '../components/OrganisationAdmin/OrganisationAdmin';

function OrganisationAdminPage({ localizedContent }) {
  return <OrganisationAdmin localizedContent={localizedContent.organisationAdmin} />;
}

OrganisationAdminPage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('organisationAdmin'), connect(null, {}))(OrganisationAdminPage);
