import React from 'react';
import PropTypes from 'prop-types';

const withTheme = Component => {
  function WithTheme(props, context) {
    const { themeOptions } = context;
    return <Component {...props} themeOptions={themeOptions || {}} />;
  }

  WithTheme.contextTypes = { themeOptions: PropTypes.object };
  WithTheme.propTypes = {
    className: PropTypes.string,
    themeOptions: PropTypes.object,
    glyph: PropTypes.any,
    fill: PropTypes.string
  };

  return WithTheme;
};

export default withTheme;
