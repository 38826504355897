import { API_VERSIONS, JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../util/signedFetch';

export default function* getCourseContentsFetch(params) {
  const URL = `${__API_BASE__}/open/content/${params.courseCode}/contents`;

  const headers = { 'Content-Type': JSON_ENCODED, Accept: API_VERSIONS.API_VERSION_0_2 };

  return yield signedFetch('getCourseContents', URL, 'GET', undefined, headers);
}
