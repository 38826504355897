import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { cloneDeep, isEmpty } from 'lodash';

import styles from './styles.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
import { teacherResourcesRequest } from '../../redux/actions/orbTeacherResources';
import actions from '../../redux/actions';

import SearchService from './SearchService';

import { ProductStatus, getProductStatus } from '../../components/ProductListing/ProductListing';
import OrbTeacherSearchForm from '../../components/OrbTeacherSearchForm/OrbTeacherSearchForm.jsx';
import OrbTeacherBook from '../../components/OrbTeacherBook/OrbTeacherBook.jsx';
import OrbTeacherFlashcard from '../../components/OrbTeacherFlashcard/OrbTeacherFlashcard.jsx';
import OrbTeacherGenericContent from '../../components/OrbTeacherGenericContent/OrbTeacherGenericContent.jsx';
import OrbTeacherDownloadableContent from '../../components/OrbTeacherDownloadableContent/OrbTeacherDownloadableContent.jsx';
import ExpandableItem from '../../components/ExpandableItem/ExpandableItem';
import CorrelationChartTab from '../../components/CorrelationChartTab/CorrelationChartTab';
import LTIOpener from '../../components/LTIOpener/LTIOpener';

class OrbTeacherResourcesPage extends Component {
  constructor(props) {
    super(props);

    this.receiveMessage = this.receiveMessage.bind(this);
    this.searchService = new SearchService(props.resources);

    this.state = {
      readyToLoadResources: props.subscriptionsLoaded && props.activeProducts.length > 0,
      searchParams: {},
      autocompleteSuggestions: [],
      filteredResources: props.resources,
      numberOfResourcesVisible: 20,
      correlationChartExpanded: false
    };
  }

  componentDidMount() {
    const { readyToLoadResources } = this.state;
    if (readyToLoadResources) this.loadTeacherResources();
    window.addEventListener('scroll', this.trackScrolling);
    window.addEventListener('message', this.receiveMessage);
  }

  componentDidUpdate(prevProps, prevState) {
    const { resources, subscriptionsLoaded, activeProducts, openedContent } = this.props;
    const { readyToLoadResources } = this.state;

    if (!readyToLoadResources && subscriptionsLoaded && activeProducts.length > 0) {
      this.setState({ readyToLoadResources: true });
    }

    if (!prevState.readyToLoadResources && readyToLoadResources) {
      this.loadTeacherResources();
    }

    if (prevProps.resources.length === 0 && resources.length > 0) {
      this.searchService = new SearchService(resources);
      this.setState({ filteredResources: resources });
    }

    if (openedContent && !openedContent.downloadable) {
      if (!document.body.classList.contains('content-open')) document.body.classList.add('content-open');
    } else {
      document.body.classList.remove('content-open');
    }
    this.redirectIfNoPreferences();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.trackScrolling);
    window.removeEventListener('message', this.receiveMessage);
  }

  onChangeSearchParams = newSearchParams => {
    const { searchParams } = this.state;
    const prevSearchTerm = searchParams.search || '';
    const currentSearchTerm = newSearchParams.search || '';

    this.setState({ searchParams: newSearchParams });

    if (prevSearchTerm === currentSearchTerm) return;

    if (currentSearchTerm.length > 2) {
      const autocompleteSuggestions = this.searchService.getAutocompleteSuggestions(newSearchParams);
      this.setState({ autocompleteSuggestions });
    }

    if (prevSearchTerm.length > currentSearchTerm.length && currentSearchTerm.length < 3) {
      this.setState({ autocompleteSuggestions: [] });
    }
  };

  onSelectAutocompleteSuggestion = searchTerm => {
    const { searchParams } = this.state;
    const newSearchParams = { ...searchParams, search: searchTerm };
    this.setState({ searchParams: newSearchParams });
    this.triggerSearch(newSearchParams);
  };

  onResetSearch = () => {
    const { filters } = this.props;
    const newSearchParams = { filters: {}, search: '' };
    this.setState({ filters: undefined });
    filters.forEach(filter => {
      newSearchParams.filters[filter.key] = '';
    });

    this.onChangeSearchParams(newSearchParams);
    this.triggerSearch(newSearchParams);
  };

  toggleCorrelationChart = () => {
    const { correlationChartExpanded } = this.state;
    this.setState({ correlationChartExpanded: !correlationChartExpanded });
  };

  trackScrolling = () => {
    const scrollableHeight = document.body.scrollHeight;

    if (Math.abs(scrollableHeight - window.scrollY - window.innerHeight) < 300) {
      this.displayMore();
    }
  };

  regenerateFilters = availableFilters => {
    const { filters: filtersAlias } = this.props;
    const filters = cloneDeep(filtersAlias);
    filters.forEach(filter => {
      filter.options = filter.options.filter(
        o => !o[1] || !availableFilters[filter.key] || availableFilters[filter.key].includes(o[1])
      );
    });
    this.setState({ filters });
  };

  triggerSearch = searchParams => {
    const searchResult = this.searchService.search(searchParams);
    const filteredResources = searchResult.resources;
    this.regenerateFilters(searchResult.filters);
    this.setState({ filteredResources, autocompleteSuggestions: [], numberOfResourcesVisible: 20 });
  };

  loadTeacherResources() {
    const { orgId, locationId, activeProducts, loadTeacherResources } = this.props;
    const isbns = activeProducts.map(({ isbn }) => isbn).join(',');

    loadTeacherResources(orgId, locationId, isbns);
  }

  receiveMessage({ data: { actionCode } }) {
    if (actionCode === 'BOOK_CLOSED') {
      const { closeContent } = this.props;
      closeContent();
    }
  }

  redirectIfNoPreferences() {
    const { userPreferences, history, closePreferences } = this.props;
    const {
      success,
      data: { contact_preferences: contactPreferences }
    } = userPreferences;
    if (success && isEmpty(contactPreferences) && !closePreferences) {
      history.push('/teacherHome/contactPreferences');
    }
  }

  displayMore() {
    const { numberOfResourcesVisible } = this.state;
    this.setState({ numberOfResourcesVisible: numberOfResourcesVisible + 20 });
  }

  static renderContent = item => {
    switch (item.type) {
      case 'book':
        return <OrbTeacherBook content={item} />;
      case 'flashcard':
        return <OrbTeacherFlashcard content={item} />;
      case 'generic':
        return <OrbTeacherGenericContent content={item} />;
      default:
        return <OrbTeacherDownloadableContent content={item} />;
    }
  };

  renderSearchForm() {
    const { filters: filtersAlias1, searchParams, autocompleteSuggestions } = this.state;
    const { filters: filtersAlias2 } = this.props;
    const filters = filtersAlias1 || filtersAlias2;
    return (
      <OrbTeacherSearchForm
        params={searchParams}
        filters={filters}
        autocompleteSuggestions={autocompleteSuggestions}
        onChange={this.onChangeSearchParams}
        onSelectAutocompleteSuggestion={this.onSelectAutocompleteSuggestion}
        onResetSearch={this.onResetSearch}
        doSearch={this.triggerSearch}
      />
    );
  }

  renderResults() {
    const {
      localizedContent: { orbTeacherResourcesPage: i18n }
    } = this.props;
    const { filteredResources, numberOfResourcesVisible } = this.state;

    return filteredResources.length ? (
      [
        <p className={styles.numberOfResources} key={filteredResources.length}>
          {filteredResources.length} {i18n.resources}
        </p>,
        filteredResources.slice(0, numberOfResourcesVisible).map(OrbTeacherResourcesPage.renderResultItem)
      ]
    ) : (
      <div className="empty" id="resources">
        <p>{i18n.search_with_no_results}</p>
        <button type="submit" onClick={this.onResetSearch}>
          {i18n.start_again_button}
        </button>
      </div>
    );
  }

  static renderResultItem = (item, i) => (
    <div className="row" key={i}>
      <div className="col">{OrbTeacherResourcesPage.renderContent(item)}</div>
    </div>
  );

  renderExpiredWarning() {
    const {
      subscriptionsLoaded,
      activeProducts,
      startSoonProducts,
      localizedContent: { orbTeacherResourcesPage: i18n }
    } = this.props;

    if (subscriptionsLoaded && activeProducts.length === 0 && startSoonProducts.length === 0) {
      const message = i18n.expired_warning_message;
      // eslint-disable-next-line react/no-danger
      return <div className={styles.expiredWarning} dangerouslySetInnerHTML={{ __html: message }} />;
    }
    return null;
  }

  renderStartSoonAnnouncement() {
    const {
      activeProducts,
      subscriptionsLoaded,
      startSoonProducts,
      localizedContent: { orbTeacherResourcesPage: i18n }
    } = this.props;

    if (subscriptionsLoaded && startSoonProducts.length > 0 && activeProducts.length === 0) {
      const message = i18n.start_soon_announcement;
      // eslint-disable-next-line react/no-danger
      return <div className={styles.expiredWarning} dangerouslySetInnerHTML={{ __html: message }} />;
    }
    return null;
  }

  renderCorrelationChart() {
    const {
      locationId,
      subscriptions,
      localizedContent: { orbTeacherResourcesPage: i18n }
    } = this.props;
    const { correlationChartExpanded } = this.state;

    if (
      locationId === 'af' ||
      (Object.values(subscriptions).length > 0 &&
        Object.values(subscriptions).find(subscription => subscription.productDetails.market === 'ASIA'))
    )
      return null;

    return (
      <div className={styles.correlationChart}>
        <ExpandableItem
          label={i18n.level_correlation_chart}
          expanded={correlationChartExpanded}
          onToggleExpand={this.toggleCorrelationChart}
        >
          <CorrelationChartTab locationId={locationId} />
        </ExpandableItem>
      </div>
    );
  }

  render() {
    const {
      loading,
      openedContent,
      localizedContent: { orbTeacherResourcesPage: i18n }
    } = this.props;

    return (
      <div>
        <Helmet title="Resource library" />

        {openedContent && (
          <div className={openedContent.downloadable ? styles.downloadableContent : styles.openedContent}>
            <LTIOpener
              content={{ external_id: openedContent.id, platform: 'unity', openQuizPanel: openedContent.openQuizPanel }}
              formTarget={openedContent.downloadable ? '_blank' : 'iframe'}
            />
          </div>
        )}

        <div className={styles.searchArea}>
          <div className="grid">
            <div className="row">
              <div className="col">{this.renderSearchForm()}</div>
            </div>
          </div>
        </div>

        <div className={styles.resourcesArea}>
          <div className="grid">
            <div className="row">
              <div className="col">
                {this.renderExpiredWarning()}
                {this.renderStartSoonAnnouncement()}
                {this.renderCorrelationChart()}
              </div>
            </div>

            {loading ? <div className="waiting">{i18n.loading}</div> : this.renderResults()}
          </div>
        </div>
      </div>
    );
  }
}

OrbTeacherResourcesPage.propTypes = {
  orgId: PropTypes.string.isRequired,
  subscriptionsLoaded: PropTypes.bool.isRequired,
  activeProducts: PropTypes.arrayOf(
    PropTypes.shape({
      isbn: PropTypes.string.isRequired
    })
  ).isRequired,
  startSoonProducts: PropTypes.arrayOf(
    PropTypes.shape({
      isbn: PropTypes.string.isRequired
    })
  ).isRequired,
  locationId: PropTypes.string,
  resources: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired,

  loadTeacherResources: PropTypes.func.isRequired,
  openedContent: PropTypes.object,
  history: PropTypes.object,
  userPreferences: PropTypes.object,
  closeContent: PropTypes.func,
  closePreferences: PropTypes.bool,
  subscriptions: PropTypes.object
};

export default compose(
  withRouter,
  withLocalizedContent('orbTeacherResourcesPage', 'sharedTerms'),
  connect(
    ({ identity, subscriptions, orbUserPreferences, orbTeacherResources }) => {
      const {
        data: { results, filters },
        openedContent
      } = orbTeacherResources;

      const activeProducts = Object.values(subscriptions)
        .filter(subscription => {
          const status = getProductStatus(subscription);
          return status === ProductStatus.STARTED || status === ProductStatus.EXPIRING;
        })
        .map(({ productDetails }) => productDetails);

      const startSoonProducts = Object.values(subscriptions)
        .filter(subscription => {
          const status = getProductStatus(subscription);
          return status === ProductStatus.NOT_STARTED;
        })
        .map(({ productDetails }) => productDetails);

      return {
        orgId: identity.currentOrganisationId,
        userId: identity.userId,
        subscriptionsLoaded: Object.keys(subscriptions).length > 0,
        subscriptions,
        activeProducts,
        startSoonProducts,
        locationId: orbUserPreferences.data.region,
        userPreferences: orbUserPreferences,
        closePreferences: orbUserPreferences.closePreferences,
        resources: results || [],
        filters: filters || [],
        autocompleteSuggestions: orbTeacherResources.autocompleteSuggestions || [],
        openedContent,
        loading: !orbTeacherResources.success
      };
    },
    dispatch => ({
      loadTeacherResources: (orgId, locationId, isbns) => {
        dispatch(teacherResourcesRequest(orgId, locationId, isbns));
      },
      closeContent: () => {
        dispatch(actions.teacherCloseContent());
      }
    })
  )
)(OrbTeacherResourcesPage);
