import * as t from '../actionTypes';

export const reportsRequest = (orgId) => ({
  type: t.NGS_TEACHER_REPORTS_REQUEST,
  payload: { orgId }
});

export const reportsRequestSuccess = payload => ({
  type: t.NGS_TEACHER_REPORTS_SUCCESS,
  payload
});

export const reportsRequestFailure = payload => ({
  type: t.NGS_TEACHER_REPORTS_FAILURE,
  payload
});
