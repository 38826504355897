import React from 'react';
import styles from './Tooltip.css';

const tooltipTitleText = hubContent => (
  <div className={styles.tooltipTitle}>
    <p>{hubContent.tooltip_title_copy_and_share_code}</p>
    <p
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: hubContent.tooltip_title_students_use_code.replace('englishhub.oup.com', '<a>englishhub.oup.com</a>')
      }}
    />
    <br />
    <a href={hubContent.link_in_help_and_support_for_joining_class_code}>{hubContent.learn_more}</a>
  </div>
);

export default tooltipTitleText;

export const owTooltipText = hubContent => (
  <span
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: hubContent.add_student_info_tooltip.replace('englishhub.oup.com', '<b>englishhub.oup.com</b>')
    }}
  />
);
