import { put } from 'redux-saga/effects';
import { courseStructureRequestSuccess, courseStructureRequestFailure } from '../../../../actions/ngsCourse';
import getCourseStructureApiCall from '../../../apiCalls/ngs/getCourseStructure';

function* getCourseStructure(orgId, courseId) {
  const response = yield getCourseStructureApiCall(orgId, courseId);

  if (response.status === 'success') {
    yield put(courseStructureRequestSuccess(response.data));
    return;
  }

  yield put(courseStructureRequestFailure(response.message));
}

export default getCourseStructure;
