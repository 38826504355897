import { put, takeLatest } from 'redux-saga/effects';

import * as t from '../../../../actionTypes';
import actions from '../../../../actions';
import { storePeople } from '../../../../reducers/data/people';

import { getSettings } from './settingsLoader.saga';
import { isHubMode } from '../../../../../utils/platform';
import getGradebookDetailsApi from '../../../apiCalls/gradebook/getGradebookDetails.api';

function* loadGradebookDetails({ payload }) {
  const isHubMyProgress = payload.params.myProgress && isHubMode();
  const attemptFilter = getSettings('attemptFilter') || (isHubMyProgress ? 'latest' : 'best');
  const apiResults = yield getGradebookDetailsApi(payload.level, payload.params, attemptFilter);

  if (apiResults.error || apiResults.status !== 'success') {
    yield put(actions.gradebookClassReportFailure(apiResults, payload.page));
  } else {
    if (apiResults.data.learners) {
      yield put(storePeople(apiResults.data.learners));
      yield put(actions.gradebookClassReportSuccess(apiResults.data.learners, payload.page));
      yield put(actions.gradebookAssignSelectedProduct(apiResults.data.productName));
    }

    if (apiResults.data.products) {
      yield put(
        actions.gradebookClassReportSuccess(
          { products: apiResults.data.products, studentsInClass: apiResults.data.studentsInClass },
          payload.page
        )
      );
    }
  }
}

export default function* getGradebookClassReport() {
  console.log('[getGradebookClassReport] Registering action listeners');
  yield takeLatest(t.GRADEBOOK_CLASS_REPORT_REQUEST, loadGradebookDetails);
}
