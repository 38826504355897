import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon.js';
import { GLYPHS } from '../SVGIcon/SVGIcon.js';
import OptionsDropdown from '../OptionsDropdown/OptionsDropdown';
import styles from './StudentAnswerView.scss';
import { toInitials } from '../../utils/string';
import { loadSavedSettings, storeSavedSettings } from '../../redux/reducers/savedSettings';
import { RETURN_PAGE_TARGET } from '../../globals/appConstants';
import { isHubMode } from '../../utils/platform.js';

class StudentAnswerViewHeader extends Component {
  static filterStudent = (people, classDetails, currentClassId) =>
    classDetails.data &&
    classDetails.data[currentClassId] &&
    classDetails.data[currentClassId].studentIdList &&
    classDetails.data[currentClassId].studentIdList.reduce(
      (filteredPeople, studentId) => Object.assign(filteredPeople, pick(people, studentId)),
      {}
    );

  // eslint-disable-next-line react/no-unused-class-component-methods
  onToggleFilterByScore = value => {
    const { storeSavedSettingsAction } = this.props;
    storeSavedSettingsAction({ useFilterByScore: value });
  };

  getAttemptIdForLearner = learner => {
    const { params } = this.props;
    const productType = params.uId.substring(0, params.uId.indexOf(':'));
    const uId = `${productType}:${params.bid}-${params.activityId}`;
    const activityScore = learner.activityScore && learner.activityScore.find(element => element.uId === uId);
    return activityScore && activityScore.attemptId;
  };

  render() {
    const {
      params,
      gradebookData,
      classDetails,
      selectLearner,
      selectedLearnerId,
      classTitle,
      currentClassId
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const pageInfo = urlParams.get('page');

    const learnerProgressUsers = StudentAnswerViewHeader.filterStudent(gradebookData, classDetails, currentClassId);
    let backToProgressLink = `/org/${params.orgId}/class/${params.classroomId}/gradebook/workbook/${params.itemId}/${
      params.learnerId
    }#${encodeURIComponent(params.uId)}`;
    if (pageInfo === RETURN_PAGE_TARGET.CLASS_PROGRESS) {
      backToProgressLink = `/org/${params.orgId}/class/${params.classroomId}/gradebook/workbook/${
        params.itemId
      }#${encodeURIComponent(params.parentUId || params.uId)}`;
    }
    if (isHubMode()) {
      // myClasses/singleClass/:classroomId/:orgId(/:tabName(/:panelName(/:itemId(/:learnerId))
      backToProgressLink = `/myClasses/singleClass/${params.classroomId}/progress/${params.orgId}/gradebook/workbook/${
        params.itemId
      }#${encodeURIComponent(params.parentUId || params.uId)}`;
    }

    return (
      <div className={`grid ${styles.studentAnswerView}`}>
        <div className="row">
          <div className={`col sm4 gin-bot1 gin-top1 xs6 ${styles.backBtnContainer}`}>
            <LinkWithIcon
              customClassName={styles.backBtn}
              isLhs
              text="Back to class progress"
              to={backToProgressLink}
              glyph={GLYPHS.ICON_LEFT}
            />
            <div className={styles.className}>
              <h3>{classTitle}</h3>
            </div>
          </div>
          <div className="col sm8 gin-bot1 gin-top1 xs6">
            <div className={styles.stdListContainer}>
              <div className={styles.className}>
                <h3>{classTitle}</h3>
              </div>
              <div className={styles.stdDropDown}>
                {learnerProgressUsers && learnerProgressUsers[selectedLearnerId] && (
                  <OptionsDropdown
                    overlayHangRight
                    buttonId="filterButton"
                    buttonText={`${learnerProgressUsers[selectedLearnerId].firstname} ${learnerProgressUsers[selectedLearnerId].lastname}`}
                    buttonIconText={toInitials(
                      learnerProgressUsers[selectedLearnerId].firstname,
                      learnerProgressUsers[selectedLearnerId].lastname
                    )}
                    inputType="radio"
                    options={Object.keys(learnerProgressUsers).map(learnerId => ({
                      text: `${learnerProgressUsers[learnerId].firstname} ${learnerProgressUsers[learnerId].lastname}`,
                      id: learnerId,
                      name: learnerId,
                      value: learnerId,
                      hasUserSubmittedActivities: learnerProgressUsers[learnerId].hasUserSubmittedActivities,
                      checked: selectedLearnerId === learnerId,
                      attemptId: this.getAttemptIdForLearner(learnerProgressUsers[learnerId])
                    }))}
                    onChange={selectLearner}
                    showLegend={false}
                    isTruncateName
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StudentAnswerViewHeader.propTypes = {
  params: PropTypes.object.isRequired,
  gradebookData: PropTypes.array,
  selectLearner: PropTypes.func,
  classDetails: PropTypes.object.isRequired,
  classTitle: PropTypes.string,
  currentClassId: PropTypes.string,
  storeSavedSettingsAction: PropTypes.func,
  selectedLearnerId: PropTypes.string
};

export default connect(
  (state, { params }) => ({
    params,
    useFilterByScore: state.savedSettings.settings.useFilterByScore
  }),
  {
    loadSavedSettingsAction: loadSavedSettings,
    storeSavedSettingsAction: storeSavedSettings
  }
)(StudentAnswerViewHeader);
