import * as t from '../actionTypes';

export const setInvoiceReportFromDateInput = input => ({
  type: t.CREATE_INVOICE_REPORT_INPUT,
  payload: input
});

export const invoiceReportValidateInput = () => ({
  type: t.CREATE_INVOICE_REPORT_VALIDATE
});

export const invoiceReportValidated = input => ({
  type: t.CREATE_INVOICE_REPORT_VALIDATED,
  payload: input
});

export const createInvoiceReportRequest = input => ({
  type: t.CREATE_INVOICE_REPORT_REQUEST,
  payload: input
});

export const createInvoiceReportSuccess = data => ({
  type: t.CREATE_INVOICE_REPORT_SUCCESS,
  payload: data
});

export const createInvoiceReportFailure = errors => ({
  type: t.CREATE_INVOICE_REPORT_FAILURE,
  payload: errors
});
