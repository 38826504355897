import signedFetch from './util/signedFetch.js';

/**
 * This takes isbn and contentCode as input and deletes the product
 * ISBN - String
 * contentCode - string
 */
export default function* deleteProductRequest(body) {
  const { contentCode, isbn } = body;
  return yield signedFetch(
    'deleteProduct',
    `${__API_BASE__}/products/${encodeURIComponent(contentCode)}/delete/${encodeURIComponent(isbn)}`,
    'DELETE'
  );
}
