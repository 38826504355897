import { select } from 'redux-saga/effects';
import userRoles from '../../../../../globals/userRoles.js';
import archiveUsersPreFlightChecksApi from '../../../apiCalls/users/archiveUsersPreFlightChecks.api.js';

export default function* archiveUsersPreFlightChecksRequest() {
  const { userIds, orgId, checkOupAdmin } = yield select(state => ({
    userIds: state.archiveUsers.userIds,
    orgId: state.organisationPage.orgId,
    checkOupAdmin: state.archiveUsers.checkOupAdmin
  }));

  // we need to check if selected users are OUP Admins, Teachers or Org Admins
  const roles = [userRoles.TEACHER, userRoles.ORG_ADMIN];
  if (checkOupAdmin) {
    roles.push(userRoles.OUP_ADMIN);
  }
  console.log('[archiveUsers Saga] running archiveUsersPreFlightChecks:', { userIds, orgId, roles });
  return yield archiveUsersPreFlightChecksApi({ userIds, orgId, roles });
}
