import { put } from 'redux-saga/effects';
import getApiContentFetch from '../../../apiCalls/cptStructuredContentPlayer/getCptContent';
import { loadCptContentFailure, loadCptContentSuccess } from '../../../../actions/structuredContentPlayer';

function* getCptContent(params) {
  const response = yield getApiContentFetch(params);
  if (response.status === 'success') {
    yield put(loadCptContentSuccess(response.data));
  } else {
    yield put(loadCptContentFailure('Failed to get CPT content'));
  }
}

export default getCptContent;
