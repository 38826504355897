import { put } from 'redux-saga/effects';
import qs from 'query-string';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { getProductMetadata } from '../../../../../utils/platform';

export const getStudentReportUrl = (orgId, studentId, locationId, subject) =>
  `${__API_BASE__}/org/${orgId}/student/${studentId}/student-report?${qs.stringify({
    locationId,
    subject,
    cache: true
  })}`;

function* getStudentReport(orgId, studentId, locationId) {
  yield put(actions.studentAttainmentReportRequest(orgId, studentId, locationId));
  yield put(actions.studentEngagementReportRequest(orgId, studentId, locationId));
  yield put(actions.studentProgressReportRequest(orgId, studentId, locationId));

  const subject = getProductMetadata('orb', 'subject');
  const response = yield signedFetch(
    'getStudentReport',
    yield getStudentReportUrl(orgId, studentId, locationId, subject),
    'GET'
  );

  if (response.status === 'success') {
    yield put(actions.studentAttainmentReportSuccess(response.data.attainment));
    yield put(actions.studentEngagementReportSuccess(response.data.engagement));
    yield put(actions.studentProgressReportSuccess(response.data.progress));
    return;
  }
  yield put(actions.studentAttainmentReportFailure(response.message));
  yield put(actions.studentEngagementReportFailure(response.message));
  yield put(actions.studentProgressReportFailure(response.message));
}

export default getStudentReport;
