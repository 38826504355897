import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getUserDetails from '../../../api/getUserDetails';

import { featureIsEnabled } from '../../../../../globals/envSettings';
import getIdentity from '../../../preRender/refactor/auth/getIdentity.saga';
import getIdentityLegacy from '../../../preRender/auth/getIdentity.saga';

function* getUser(orgId, userId) {
  let response;
  let data;

  if (orgId && userId) {
    response = yield getUserDetails(orgId, userId);
    yield getIdentity();
    data = response.data;
  } else {
    if (featureIsEnabled('single-client-session-store')) {
      response = yield getIdentity();
    } else {
      response = yield getIdentityLegacy();
    }
    data = response.data.user;
  }

  if (response && response.status && response.status.toLowerCase() === 'success') {
    yield put(actions.editUserGetDetailsSuccess(data));
    return;
  }
  yield put(actions.editUserGetDetailsFailure());
}

export default getUser;
