import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ListPageControls from '../../../components/ListPageControls/ListPageControls.js';

import { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';
import PaginationButtons from '../../../components/PaginationButtons/PaginationButtons.js';
import Table from '../../../components/Table/Table.js';
import Thumbnail, { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail.js';
import ConnectedClassRepresentation from '../../../components/ClassRepresentation/ConnectedClassRepresentation.js';

import TimeTag from '../../../components/TimeTag/TimeTag.js';
import {
  triggerSearch,
  setTerm,
  setSort,
  setPage,
  setFilterClass,
  setPageClass,
  setSortClass,
  setFilter
} from '../../../redux/reducers/data/search.reducer.js';
import SearchStatus from '../../../components/SearchStatus/SearchStatus.js';
import { featureIsEnabled } from '../../../globals/envSettings';

import cmsContent from '../../../utils/cmsContent.js';

function UserProfileClassSearch({
  userId,
  classrooms,
  searchTerm,
  sort,
  page,
  filters,
  resultIds,
  totalResults,
  triggerSearchAction,
  setSearchTerm,
  setSortAction,
  setPageAction,
  setFilterAction,
  paginatedClass,
  loading
}) {
  const CMS = cmsContent.userProfileClassSearchTab;
  const row = featureIsEnabled('client-side-pagination') ? Object.keys(paginatedClass) : resultIds;

  return (
    <div>
      <ListPageControls
        idPrefix="userClassesSearch"
        searchInputLabel={CMS.search_classes_label}
        searchInputPlaceholder={CMS.search_classes_label}
        searchInputValue={searchTerm}
        searchInputOnChange={setSearchTerm}
        searchInputOnSubmit={triggerSearchAction}
        filterOptions={[
          {
            text: CMS.filter_options_active,
            id: 'searchFilter-by-active-classes',
            name: 'searchFilter-by-active-classes',
            value: 'active',
            checked: filters.active,
            onChange: setFilterAction
          },
          {
            text: CMS.filter_options_archived,
            id: 'searchFilter-by-archived-classes',
            name: 'searchFilter-by-archived-classes',
            value: 'archived',
            checked: filters.archived,
            onChange: setFilterAction
          }
        ]}
        sortOnChange={setSortAction}
        sortOptions={[
          {
            text: CMS.sort_name_asc,
            id: 'searchOrder-by-name-ASC',
            name: 'searchOrder',
            value: 'name:asc',
            checked: sort === 'name:asc'
          },
          {
            text: CMS.sort_name_desc,
            id: 'searchOrder-by-name-DESC',
            name: 'searchOrder',
            value: 'name:desc',
            checked: sort === 'name:desc'
          },
          {
            text: CMS.sort_newest_first,
            id: 'searchOrder-by-createdDate-DESC',
            name: 'searchOrder',
            value: 'date:desc',
            checked: sort === 'date:desc'
          },
          {
            text: CMS.sort_oldest_first,
            id: 'searchOrder-by-createdDate-ASC',
            name: 'searchOrder',
            value: 'date:asc',
            checked: sort === 'date:asc'
          }
        ]}
        ariaControls="searchResults"
        showSkeletonLoader={loading}
      />

      <SearchStatus
        searchSource="userClasses"
        noResultsFoundContent={
          <div className="grid">
            <div className="row">
              <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
                <p id="searchResults-classes-noResults" className="gin-top1 gin-bot1">
                  {CMS.no_search_results_text}
                </p>
              </div>
            </div>
          </div>
        }
      />
      {loading ? null : (
        <div className="grid">
          <div className="row">
            <div
              id="searchResults"
              className="col"
              role="region"
              aria-live="polite"
              aria-atomic="true"
              aria-label="User profile - classrooms list"
            >
              {row.length > 0 && (
                <div className="gin-top2">
                  <Table
                    columns={[
                      { heading: CMS.table_heading_classes },
                      { heading: CMS.table_heading_status },
                      { heading: CMS.table_heading_joined_classes }
                    ]}
                    rows={row.map(id => {
                      const classroom = classrooms[id] || {};
                      const parentOrgId = classroom.orgId;

                      const cells = [
                        <div key={0}>
                          <ConnectedClassRepresentation
                            id={`searchResults-item-${id}`}
                            entityId={id}
                            linkIdPrefix="linkToClassroom"
                            linkTo={`/org/${parentOrgId}/class/${id}`}
                            thumbnailSize={thumbnailSizes.TABLE}
                            deletedStyle={classroom.archived}
                            orgId={parentOrgId}
                          />
                        </div>,

                        <div
                          key={1}
                          id={`searchResults-archive-status-${id}`}
                          title={classroom.archived ? CMS.archived_text : CMS.active_text}
                        >
                          <div className="a11y-hide">{classroom.archived ? CMS.archived_text : CMS.active_text}</div>
                          <Thumbnail
                            size={thumbnailSizes.MEDIUM}
                            glyph={classroom.archived ? GLYPHS.ICON_REMOVED_CIRCLE : GLYPHS.ICON_CHECK_CIRCLE}
                          />
                        </div>,

                        <div key={2} id={`searchResults-date-${id}`}>
                          <TimeTag dateString={classroom[`userJoinedDate-${userId}`]} />
                        </div>
                      ];

                      return {
                        id: `class${id}`,
                        cells
                      };
                    })}
                  />
                </div>
              )}

              {totalResults > 10 && (
                <div className="gin-top2">
                  <PaginationButtons
                    idPrefix="userClassesSearch"
                    value={page}
                    numberOfPages={Math.ceil(totalResults / 10)}
                    onClick={setPageAction}
                    aria={{ 'aria-controls': 'searchResults' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

UserProfileClassSearch.propTypes = {
  userId: PropTypes.string.isRequired,
  classrooms: PropTypes.object.isRequired,

  searchTerm: PropTypes.string.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  resultIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,

  triggerSearchAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  paginatedClass: PropTypes.object.isRequired
};

export default connect(
  state => ({
    selectedIds: state.organisationPage.selectedClassroomIds,
    userRole: state.identity.role,
    classrooms: state.classrooms.data,
    classroomSearchTerm: state.organisationPage.classroomSearchTerm,
    classroomIdList: state.organisations.data[state.identity.currentOrganisationId].classes,
    selectedClassroomIds: state.organisationPage.selectedClassroomIds,

    searchTerm: state.search.userClasses.term,
    sort: state.search.userClasses.sort,
    page: state.search.userClasses.page,
    filters: state.search.userClasses.filters,
    resultIds: state.search.userClasses.ids,
    paginatedClass: state.search.userClasses.paginatedClassList,
    totalResults: state.search.userClasses.totalResults,
    loading: state.search.userClasses.loading
  }),
  {
    setSearchTerm: term => setTerm('userClasses', term),

    setSortAction: sort =>
      featureIsEnabled('client-side-pagination')
        ? setSortClass('userClasses', sort[0])
        : setSort('userClasses', sort[0]),
    setPageAction: page =>
      featureIsEnabled('client-side-pagination') ? setPageClass('userClasses', page) : setPage('userClasses', page),
    setFilterAction: (valueName, value) =>
      featureIsEnabled('client-side-pagination')
        ? setFilterClass('userClasses', valueName, value)
        : setFilter('userClasses', valueName, value),

    triggerSearchAction: () => triggerSearch('userClasses')
  }
)(UserProfileClassSearch);
