import { takeLatest } from 'redux-saga/effects';

import createOrgSchema from '../../../../../../static/schema/draft-4/create-org-request-body.json';
import * as t from '../../../../actionTypes';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import editOrg from './editOrg';
import getOrg from './getOrg';

function* root() {
  yield takeLatest(t.EDIT_ORG_VALIDATE_INPUT, ({ payload: input }) =>
    validateInputUsingSchema(createOrgSchema, t.EDIT_ORG_VALIDATE_COMPLETED, input)
  );
  yield takeLatest(t.EDIT_ORG_GET_DETAILS_REQUEST, ({ payload: id }) => getOrg(id));
  yield takeLatest(t.EDIT_ORG_REQUEST, ({ payload: { orgId, input } }) => editOrg(orgId, input));
}

export default root;
