import { put } from 'redux-saga/effects';
import publishCourse from '../../../apiCalls/publishCourse';
import { publishCourseDataSuccess, publishCourseDataFailure } from '../../../../actions/coursePublish';

function* publishCourseData({ courseCode, body }) {
  const response = yield publishCourse(courseCode, body);
  if (response.status === 'success') {
    yield put(publishCourseDataSuccess(response));
  } else {
    yield put(publishCourseDataFailure(response.error));
  }
}

export default publishCourseData;
