// Enum for the breakpoint names.
// Used where code differs between breakpoints (eg mobile only Carousels)

const breakpoints = {
  XXS: 'xxs',
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg'
};

// Array of valid breakpoint values for use in validation
export const validBreakpoints = Object.keys(breakpoints).map(key => breakpoints[key]);

// Export the object to be used in this format: `breakpoints.XS`
export default breakpoints;
