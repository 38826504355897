import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './BottowRow.scss';
import Button, { buttonTypes } from '../../../../components/Button/Button.js';

import cmsContent from '../../../../utils/cmsContent.js';

// Helper to rotate current carousel value when it reaches its first or last value:

export default class BottowRow extends Component {
  constructor() {
    super();
    this.state = { CMS: {} };
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.homepage || {} });
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.homepage || {} });
  }

  render() {
    const { CMS } = this.state;

    return (
      <div className={styles.mainSection}>
        <div className="grid">
          <div className="row">
            <div className="col">
              <div className={styles.title}>
                <h2>{CMS.page_title_header}</h2>
                <p>{CMS.page_title_text}</p>
              </div>
            </div>

            <div className={classnames('col md4', styles.listItem)}>
              <img className={styles.image} aria-hidden="true" alt="" src={CMS.image_one_source} />
              <p>{CMS.image_one_title_text}</p>
              <p>{CMS.image_one_body_text}</p>
            </div>

            <div className={classnames('col md4', styles.listItem)}>
              <img className={styles.image} aria-hidden="true" alt="" src={CMS.image_two_source} />
              <p>{CMS.image_two_title_text}</p>
              <p>{CMS.image_two_body_text}</p>
            </div>

            <div className={classnames('col md4', styles.buttonCol, styles.listItem)}>
              <img className={styles.image} aria-hidden="true" alt="" src={CMS.image_three_source} />

              <p>{CMS.image_three_title_text}</p>
              <p>{CMS.image_three_body_text}</p>
              {/* <Button
                id="watch-video-button"
                to={CMS.heroBanner_link_url}
                type={buttonTypes.GHOST}
                text={CMS.to_videos_button}
              /> */}
              <Button
                id="contact-button"
                to={CMS.contact_us_link}
                type={buttonTypes.GHOST}
                text={CMS.contact_us_button}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
