import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { validColors } from '../../globals/colors';
import { validGlyphs } from '../SVGIcon/SVGIcon';
import Thumbnail, { validSizes as validThumbnailSizes } from '../Thumbnail/Thumbnail';
import styles from './CardWithThumbnail.scss';

/** The component renders a white box with a colored top border and heading label. Contains generic content. */
function CardWithThumbnail({ thumbnailSize, thumbnailColor, thumbnailGlyph, children }) {
  return (
    <div className={styles.cardWithThumbnail}>
      <div className={styles.thumbnail}>
        <Thumbnail size={thumbnailSize} backgroundColor={thumbnailColor} glyph={thumbnailGlyph} />
      </div>
      <div className={classnames(styles.contentCard, styles[`size-${thumbnailSize}`])}>{children}</div>
    </div>
  );
}

CardWithThumbnail.propTypes = {
  /** The thumbnail size variant to display */
  thumbnailSize: PropTypes.oneOf(validThumbnailSizes).isRequired,
  /** The color of the cards thumbnail. */
  thumbnailColor: PropTypes.oneOf(validColors).isRequired,
  /** The icon to be used in the cards thumbnail. */
  thumbnailGlyph: PropTypes.oneOf(validGlyphs).isRequired,
  /** The content to be displayed within the Card. */
  children: PropTypes.any.isRequired
};

export default CardWithThumbnail;
