/* eslint-disable no-unsafe-optional-chaining */
import { featureIsEnabled } from '../../globals/envSettings';
import * as t from '../actionTypes';

export const productFinderFormStates = {
  SEARCHING: 'SEARCHING',
  SERIES_OVERVIEW: 'SERIES_OVERVIEW',
  REVIEW_LICENCES: 'REVIEW_LICENCES',
  REVIEW: 'REVIEW',
  VIEW_PRODUCT_LICENCE_INFO: 'VIEW_PRODUCT_LICENCE_INFO',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION'
};

export const searchTypes = {
  PRODUCTS: 'PRODUCTS',
  SERIES: 'SERIES',
  TEACHER_PRODUCTS_IN_SERIES: 'TEACHER_PRODUCTS_IN_SERIES',
  STUDENT_PRODUCTS_IN_SERIES: 'STUDENT_PRODUCTS_IN_SERIES'
};

const initialState = {
  formStateHistory: [productFinderFormStates.SEARCHING],
  searchType: searchTypes.SERIES,
  searchState: {
    [searchTypes.SERIES]: {
      searchTerm: '',
      page: 1
    },
    [searchTypes.PRODUCTS]: {
      searchTerm: '',
      page: 1
    },
    [searchTypes.TEACHER_PRODUCTS_IN_SERIES]: {
      page: 1
    },
    [searchTypes.STUDENT_PRODUCTS_IN_SERIES]: {
      page: 1
    }
  },
  panelOpen: false,
  loadingProducts: true,
  loadingSeries: true,
  filters: {},
  products: [],
  series: [],
  errorOccurred: false,
  productsInSelectedSeries: [],
  selectedCountryCode: '',
  selectedSeries: null,
  error: false,
  errorMessage: null,
  loadingLicences: false,
  classId: null,
  product: {},
  selectedProducts: [],
  licenceStructure: {},
  assignLicences: false,
  assignmentInitialToggleValue: true
};

const getLicenceData = licence => ({
  licenceType: licence.licenceType,
  beginOn: licence.beginOn,
  timePeriod: licence.timePeriod,
  unitType: licence.unitType,
  createdDate: licence.createdDate,
  startDate: licence.startDate,
  endDate: licence.endDate
});

const generateStructureForProduct = (licenceStructure, shouldTeacherBeAssigned) => {
  const result = {
    licencesTypes: [...licenceStructure.licencesTypes],
    userIdsInOrder: [...licenceStructure.userIdsInOrder],
    proposedAssignments: { ...licenceStructure.proposedAssignments },
    assignLicenceToTeacher: shouldTeacherBeAssigned
  };
  const { usersIdsWithLicences = [], teacherIds = [], studentIds = [] } = licenceStructure;

  let unassignedStudentIds = [...studentIds].filter(id => usersIdsWithLicences.indexOf(id) === -1);
  let unassignedTeacherIds = [...teacherIds].filter(id => usersIdsWithLicences.indexOf(id) === -1);

  if (shouldTeacherBeAssigned) {
    const availableLicences = result.licencesTypes.map(licence => licence.availableCount).reduce((a, b) => a + b);

    if (unassignedStudentIds.length + unassignedTeacherIds.length <= availableLicences) {
      result.userIdsInOrder = [...unassignedStudentIds, ...unassignedTeacherIds];

      result.licencesTypes.forEach(licence => {
        const stillAvailableLicences = licence.availableCount - licence.amountAssigning;
        if (stillAvailableLicences && unassignedTeacherIds.length) {
          if (stillAvailableLicences >= unassignedTeacherIds.length) {
            // it means the available licences of this type are enough for the rest of the users
            licence.amountAssigning += unassignedTeacherIds.length;
            unassignedTeacherIds.forEach(teacherId => {
              result.proposedAssignments[teacherId] = getLicenceData(licence);
            });
            unassignedTeacherIds = [];
          } else {
            // it means the available licences of this type are not enough for the rest of the users
            licence.amountAssigning = licence.availableCount;
            const assigned = unassignedTeacherIds.splice(0, stillAvailableLicences);
            assigned.forEach(teacherId => {
              result.proposedAssignments[teacherId] = getLicenceData(licence);
            });
          }
        }
      });
      return { ...licenceStructure, ...result };
    }
    // it means there aren't enough licences for both users and teachers
    result.assignLicenceToTeacher = false;
    result.disabledTeacherAssign = true;

    return { ...licenceStructure, ...result };
  }
  result.userIdsInOrder = [...unassignedStudentIds];
  result.proposedAssignments = {};
  result.licencesTypes.forEach(licence => {
    const stillAvailableLicences = licence.availableCount;
    if (stillAvailableLicences && unassignedStudentIds.length) {
      if (stillAvailableLicences >= unassignedStudentIds.length) {
        // it means the available licences of this type are enough for the rest of the users
        licence.amountAssigning = unassignedStudentIds.length;
        unassignedStudentIds.forEach(studentId => {
          result.proposedAssignments[studentId] = getLicenceData(licence);
        });
        unassignedStudentIds = [];
      } else {
        // it means the available licences of this type are not enough for the rest of the users
        licence.amountAssigning = licence.availableCount;
        const assigned = unassignedStudentIds.splice(0, stillAvailableLicences);
        assigned.forEach(studentId => {
          result.proposedAssignments[studentId] = getLicenceData(licence);
        });
      }
      // if there are no students that need to be assigned, and the teachers are not included to be assigned
    } else if (!unassignedStudentIds.length) {
      licence.amountAssigning = 0;
    }
  });
  return { ...licenceStructure, ...result };
};

const ammendLicenceStructure = (state, assignLicences) => {
  const licenceStructure = { ...state.licenceStructure };
  if (featureIsEnabled('product-finder-multi-select')) {
    Object.keys(state.licenceStructure).forEach(productId => {
      licenceStructure[productId].assignLicencesForProduct =
        assignLicences && licenceStructure[productId].canAssignLicences;
    });
  }
  return licenceStructure;
};

// function which decides what value should the canAssignLicenceForTeacher variable have for each product
// based on the number of licences available + number of students / teachers
const ammendLicenceStructureForTeacher = (state, shouldTeacherBeAssigned) => {
  const licenceStructure = { ...state.licenceStructure };
  if (featureIsEnabled('product-finder-multi-select')) {
    Object.keys(state.licenceStructure).forEach(productId => {
      licenceStructure[productId].assignLicenceToTeacher = shouldTeacherBeAssigned;
    });
  } else {
    const productId = state.product?.productid;
    licenceStructure[productId] = generateStructureForProduct(licenceStructure[productId], shouldTeacherBeAssigned);
  }
  return licenceStructure;
};

export default function productFinder(state = initialState, { type, payload }) {
  switch (type) {
    case t.PRODUCT_FINDER_CHANGE_SERIES:
      return {
        ...initialState,
        selectedCountryCode: state.selectedCountryCode,
        selectedProducts: state.selectedProducts
      };
    case t.PRODUCT_FINDER_RESET_STATE:
      return {
        ...initialState
      };
    case t.PRODUCT_FINDER_SET_DATA: {
      if (payload.formState) {
        payload.formStateHistory = [payload.formState, ...state.formStateHistory];
      }
      return {
        ...state,
        ...payload
      };
    }
    case t.PRODUCT_FINDER_SET_CURRENT_FORM_STATE: {
      return {
        ...state,
        formStateHistory: [payload, ...state.formStateHistory]
      };
    }
    case t.PRODUCT_FINDER_GET_SERIES_REQUEST:
      return {
        ...state,
        loadingSeries: true,
        error: false
      };
    case t.PRODUCT_FINDER_GET_SERIES_SUCCESS:
      return {
        ...state,
        loadingSeries: false,
        series: payload
      };
    case t.PRODUCT_FINDER_GET_SERIES_FAILURE:
      return {
        ...state,
        loadingSeries: false,
        series: [],
        error: true,
        errorMessage: payload
      };
    case t.PRODUCT_FINDER_GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loadingProducts: true,
        error: false
      };
    case t.PRODUCT_FINDER_GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loadingProducts: false,
        products: payload
      };
    }
    case t.PRODUCT_FINDER_GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loadingProducts: false,
        products: [],
        error: true,
        errorMessage: payload
      };
    case t.PRODUCT_FINDER_SET_FILTERS:
      return {
        ...state,
        filters: payload
      };
    case t.PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_REQUEST:
      return {
        ...state,
        productsInSelectedSeries: [],
        selectedSeries: payload,
        error: false
      };
    case t.PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_SUCCESS:
      return {
        ...state,
        productsInSelectedSeries: payload
      };
    case t.PRODUCT_FINDER_GET_PRODUCTS_FOR_SERIES_FAILURE:
      return {
        ...state,
        productsInSelectedSeries: [],
        error: true,
        errorMessage: payload
      };
    // temporary until
    case t.PRODUCT_FINDER_SELECT_SINGLE_PRODUCT:
      return {
        ...state,
        product: payload
      };
    case t.PRODUCT_FINDER_SELECT_PRODUCT:
      return {
        ...state,
        product: state.products.find(product => payload === product.productid) || {}
      };
    case t.PRODUCT_FINDER_GET_LICENCES_REQUEST:
      return {
        ...state,
        loadingLicences: true
      };
    case t.PRODUCT_FINDER_ASSIGN_PRODUCTS_REQUEST:
      return {
        ...state,
        formStateHistory: [productFinderFormStates.SUBMITTING, ...state.formStateHistory]
      };
    case t.PRODUCT_FINDER_ASSIGN_PRODUCTS_SUCCESS:
      return {
        ...state,
        formStateHistory: [productFinderFormStates.CONFIRMATION, ...state.formStateHistory]
      };
    case t.PRODUCT_FINDER_ASSIGN_PRODUCTS_FAILURE:
      return {
        ...state,
        errorOccurred: true,
        formStateHistory: [productFinderFormStates.CONFIRMATION, ...state.formStateHistory]
      };
    case t.PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_REQUEST:
      return {
        ...state,
        formStateHistory: [productFinderFormStates.SUBMITTING, ...state.formStateHistory]
      };
    case t.PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_SUCCESS:
      return {
        ...state,
        formStateHistory: [productFinderFormStates.CONFIRMATION, ...state.formStateHistory]
      };
    case t.PRODUCT_FINDER_ASSIGN_MULTIPLE_PRODUCTS_FAILURE:
      return {
        ...state,
        errorOccurred: true,
        formStateHistory: [productFinderFormStates.CONFIRMATION, ...state.formStateHistory]
      };
    case t.PRODUCT_FINDER_ADD_LICENCE_DATA:
      return {
        ...state,
        licenceData: payload
      };
    case t.PRODUCT_FINDER_SET_ASSIGN_LICENCE: {
      const licenceStructure = ammendLicenceStructure(state, payload);
      return {
        ...state,
        licenceStructure,
        assignLicences: payload
      };
    }
    case t.PRODUCT_FINDER_SET_ASSIGN_LICENCE_TO_TEACHER: {
      const licenceStructure = ammendLicenceStructureForTeacher(state, payload);
      return {
        ...state,
        licenceStructure
      };
    }
    case t.PRODUCT_FINDER_GET_LICENCES_SUCCESS:
      return {
        ...state,
        loadingLicences: false,
        licenceStructure: payload
      };
    case t.PRODUCT_FINDER_REMOVE_SELECTED_PRODUCT: {
      let selectedProducts = [...state.selectedProducts];
      selectedProducts = selectedProducts.filter(product => product.productid !== payload);
      return {
        ...state,
        selectedProducts
      };
    }
    case t.PRODUCT_FINDER_SET_SELECTED_COUNTRY_CODE: {
      return {
        ...state,
        selectedCountryCode: payload
      };
    }
    case t.PRODUCT_FINDER_CLEAR_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: []
      };
    case t.PRODUCT_FINDER_SELECT_MULTIPLE_PRODUCTS: {
      const { products, isProductSelected } = payload;
      let selectedProducts = [...state.selectedProducts];
      if (isProductSelected) {
        // if these products are already selected it means they need to be removed
        let newSelectedProducts = selectedProducts;
        products.forEach(product => {
          newSelectedProducts = newSelectedProducts.filter(nProduct => nProduct.productid !== product.productid);
        });
        selectedProducts = newSelectedProducts;
      } else {
        // otherwise it means the products need to be added
        products.forEach(product => {
          selectedProducts.push(product);
        });
      }

      return {
        ...state,
        selectedProducts
      };
    }
    case t.PRODUCT_FINDER_CHANGE_SEARCH_TYPE: {
      return {
        ...state,
        searchType: payload
      };
    }
    case t.PRODUCT_FINDER_GO_TO_PREVIOUS_FORM_STATE: {
      return {
        ...state,
        formStateHistory: state.formStateHistory.slice(1)
      };
    }
    case t.PRODUCT_FINDER_SET_SEARCH_STATE: {
      return {
        ...state,
        searchState: {
          [searchTypes.SERIES]: {
            ...state.searchState[searchTypes.SERIES],
            ...payload[searchTypes.SERIES]
          },
          [searchTypes.PRODUCTS]: {
            ...state.searchState[searchTypes.PRODUCTS],
            ...payload[searchTypes.PRODUCTS]
          },
          [searchTypes.STUDENT_PRODUCTS_IN_SERIES]: {
            ...state.searchState[searchTypes.STUDENT_PRODUCTS_IN_SERIES],
            ...payload[searchTypes.STUDENT_PRODUCTS_IN_SERIES]
          },
          [searchTypes.TEACHER_PRODUCTS_IN_SERIES]: {
            ...state.searchState[searchTypes.TEACHER_PRODUCTS_IN_SERIES],
            ...payload[searchTypes.TEACHER_PRODUCTS_IN_SERIES]
          }
        }
      };
    }
    case t.PRODUCT_FINDER_CLEAR_SEARCH_STATE: {
      return {
        ...state,
        searchState: {
          ...state.searchState,
          [payload]: initialState.searchState[payload]
        }
      };
    }
    case t.PRODUCT_FINDER_SET_ORG_LICENCES_INITIAL_TOGGLE: {
      return {
        ...state,
        assignmentInitialToggleValue: false
      };
    }
    default:
      return state;
  }
}
