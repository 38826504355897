import { marked } from 'marked';
import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './UserAgreement.scss';
import { createAnchorToBlank } from '../../utils/markdown/markdown';
import {
  isHubMode,
  isCesMode,
  isOptMode,
  isOrbMode,
  getCurrentPlatform,
  isOicMode,
  isEbookSampleMode
} from '../../utils/platform';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Tooltip from '../Tooltip/Tooltip';
import { socialIdpIsEnabled } from '../../globals/envSettings';

const termsAndConditionContent = content => {
  switch (true) {
    case isHubMode() || isCesMode() || isOicMode():
      return createAnchorToBlank(content.terms_and_conditions);
    case isOptMode():
      return marked(content.terms_and_conditions_opt);
    case isOrbMode():
      return createAnchorToBlank(content.terms_and_conditions_orb);
    case isEbookSampleMode():
      return createAnchorToBlank(content.terms_and_conditions_insp);
    default:
      return marked(content.terms_and_conditions);
  }
};

const UserAgreement = function UserAgreement({
  termsAndConditions,
  marketingPreferencesEmail,
  marketingPreferencesUnderAge,
  privacyPolicy,
  dataUsage,
  personalInformation,
  personalInfoEditForm,
  localizedContent: { userAgreement: content },
  acceptedTermsAndConditions,
  hidden = ['acceptedTermsAndConditions'],
  setTermsAndConditions = () => {},
  preview = false
}) {
  return (
    <div className={!hidden.includes('acceptedTermsAndConditions') ? `${styles.container} gin-top0` : styles.container}>
      {termsAndConditions ? (
        <div className={`${styles.terms} gin-top2`}>
          {!hidden.includes('acceptedTermsAndConditions') && preview === false ? (
            <input
              id="terms_and_conditions"
              aria-label="checkbox"
              className={styles.checkbox}
              type="checkbox"
              onChange={() => {
                setTermsAndConditions();
              }}
              checked={acceptedTermsAndConditions}
            />
          ) : (
            ''
          )}
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: termsAndConditionContent(content)
            }}
          />
        </div>
      ) : null}
      {socialIdpIsEnabled(getCurrentPlatform()) && marketingPreferencesEmail ? (
        <div
          className="gin-top2"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: createAnchorToBlank(content.marketing_preferences_text_email)
          }}
        />
      ) : null}
      {socialIdpIsEnabled(getCurrentPlatform()) && marketingPreferencesUnderAge ? (
        <div className={styles.marketingPreferencesContainer}>
          <div
            className="gin-top2"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: createAnchorToBlank(content.marketing_preferences_text_under_age)
            }}
          />
          <div className={styles.marketingPreferencesContainer}>
            <Tooltip className={styles.mpTooltip} title="Insert reason text here">
              <SVGIcon className={styles.infoIcon} glyph={GLYPHS.ICON_HELP_CIRCLE} />
            </Tooltip>
          </div>
        </div>
      ) : null}
      {personalInformation ? (
        <div className={`${styles.personalInformationContainer}`}>
          <div className={`gin-top2 ${styles.privacy} ${styles.personalInformationHeader}`}>
            {content.personal_information_header}
          </div>
          <div
            className={`gin-top2 ${styles.privacy}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: createAnchorToBlank(content.personal_information_text)
            }}
          />
        </div>
      ) : null}
      {personalInfoEditForm ? (
        <div className={`${styles.personalInformationContainer}`}>
          <div className={` ${styles.privacyEditForm} ${styles.personalInformationHeader}`}>
            {content.personal_information_header}
          </div>
          <div
            className={`${styles.privacyEditForm}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: createAnchorToBlank(content.personal_information_text)
            }}
          />
        </div>
      ) : null}
      {privacyPolicy ? (
        <div
          className={`gin-top2 ${styles.privacy}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: createAnchorToBlank(isEbookSampleMode() ? content.insp_privacy_policy : content.privacy_policy)
          }}
        />
      ) : null}
      {/* eslint-disable-next-line react/no-danger */}
      {dataUsage ? <div className="gin-top2" dangerouslySetInnerHTML={{ __html: marked(content.data_usage) }} /> : null}
    </div>
  );
};

UserAgreement.propTypes = {
  termsAndConditions: PropTypes.bool,
  marketingPreferencesEmail: PropTypes.bool,
  marketingPreferencesUnderAge: PropTypes.bool,
  privacyPolicy: PropTypes.bool,
  personalInformation: PropTypes.bool,
  personalInfoEditForm: PropTypes.bool,
  dataUsage: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired,
  acceptedTermsAndConditions: PropTypes.bool,
  hidden: PropTypes.array,
  setTermsAndConditions: PropTypes.func,
  preview: PropTypes.bool
};

export default withLocalizedContent('userAgreement')(UserAgreement);
