import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './InformationBanner.scss';

function InformationBanner({ message = '', children }) {
  return (
    <div className={styles.informationBanner}>
      <SVGIcon className={styles.icon} fill="currentColor" glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
      {message ? <span className={styles.bannerMessage}>{message}</span> : null}
      {children}
    </div>
  );
}

InformationBanner.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node
};

export default InformationBanner;
