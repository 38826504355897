import SessionStorage from '../../../../utils/storage/Session';

/**
 * Encode JSON keys and values.
 *
 * btoa only supports Latin1 characters, need to support other formats
 */
const _encodeJson = json => {
  const encodedJson = {};

  Object.keys(json).forEach(key => {
    const value = json[key];

    const codeUnits = new Uint16Array(value.length);
    for (let i = 0; i < codeUnits.length; i += 1) {
      codeUnits[i] = value.toString().charCodeAt(i);
    }

    encodedJson[btoa(key)] = btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
  });

  return encodedJson;
};

/**
 * Decode JSON keys and values.
 */
const _decodeJson = json => {
  const decodedObject = {};

  Object.keys(json).forEach(key => {
    const binary = atob(json[key]);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i += 1) {
      bytes[i] = binary.charCodeAt(i);
    }

    decodedObject[atob(key)] = String.fromCharCode(...new Uint16Array(bytes.buffer));
  });

  return decodedObject;
};

// Names non-indicative on purpose due to requirements that their nature not be obvious from the dev tools
const validChannel = 'channel19';
const invalidChannel = 'channel8';
const retryChannel = 'channel9';

// saves an cert, encoded for obfuscation
const addToChannel = (data, channelName) => {
  const storage = new SessionStorage(channelName);
  storage.set(_encodeJson(data));
};

const addToValidCertChannel = cert => addToChannel(cert, validChannel);
const addToInvalidCertChannel = cert => addToChannel(cert, invalidChannel);
const addToRetryChannel = cert => addToChannel(cert, retryChannel);

const clearChannel = channelName => {
  const storage = new SessionStorage(channelName);
  storage.clear();
};

const clearValidChannel = () => clearChannel(validChannel);
const clearInvalidChannel = () => clearChannel(invalidChannel);
const clearRetryChannel = () => clearChannel(retryChannel);
const clearAllCertChannels = () => {
  clearValidChannel();
  clearInvalidChannel();
  clearRetryChannel();
};

// Reading includes emptying the channel both for syntactic sugar and to make sure no one forgets to empty
// it after it's been used
const readFromChannel = channelName => {
  const storage = new SessionStorage(channelName);
  const data = storage.get();
  // If channel is empty no need to decode
  if (!data) {
    return data;
  }
  clearChannel(channelName);
  return _decodeJson(data);
};

const readValidCertChannel = () => readFromChannel(validChannel);
const readInvalidCertChannel = () => readFromChannel(invalidChannel);
const readRetryChannel = () => readFromChannel(retryChannel);

export {
  addToValidCertChannel,
  addToInvalidCertChannel,
  addToRetryChannel,
  clearValidChannel,
  clearInvalidChannel,
  clearRetryChannel,
  clearAllCertChannels,
  readValidCertChannel,
  readInvalidCertChannel,
  readRetryChannel
};
