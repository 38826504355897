import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import GradedReadersClassView from '../../components/GradedReadersClassView/GradedReadersClassView';
import actions from '../../redux/actions';
import { loadSavedSettings, storeSavedSettings } from '../../redux/reducers/savedSettings';
import flipSortDirection from '../../components/GradebookTable/flipSortDirection';

class GradedReadersClassPage extends Component {
  componentDidMount() {
    const { classroomId, loadSavedSettingsAction, gradedReadersRequestAction, params } = this.props;
    console.log('[GradedReadersClassPage] Initialising page with class ID: ', classroomId);
    loadSavedSettingsAction();
    gradedReadersRequestAction('group', params);
  }

  componentWillUnmount() {
    const { gradedReadersLoadingAction } = this.props;
    gradedReadersLoadingAction();
  }

  /**
   * Sets both state and the URL serarch query string with the same values
   *
   * @param {Object} params The object to set
   */
  _setStateAndQueryString = key => {
    const newState = {
      sortKey: key,
      sortDirection: flipSortDirection(key)
    };
    // eslint-disable-next-line no-restricted-globals
    history.pushState(newState, 'Sorting', `?${qs.stringify(newState)}${window.location.hash}`);
    this.setState(newState);
  };

  /**
   * Switches the spacing config for gradebook.
   *
   * @param {Object} target The target spacing button
   */
  switchSpacing = ({ target }) => {
    const { storeSavedSettingsAction } = this.props;
    return storeSavedSettingsAction({ tableSpacing: target.id });
  };

  render() {
    const { loading, failure, data, tableSpacing, classTitle } = this.props;
    const { sortKey = 'none', sortDirection = 'none' } = qs.parse(window.location.search);

    return (
      <GradedReadersClassView
        data={data}
        tableLoading={loading}
        failure={failure}
        switchSpacing={this.switchSpacing}
        sortKey={sortKey}
        sortDirection={sortDirection}
        saveSort={(key, direction) => this._setStateAndQueryString(key, direction)}
        tableSpacing={tableSpacing}
        classTitle={classTitle}
      />
    );
  }
}

GradedReadersClassPage.propTypes = {
  params: PropTypes.object.isRequired,
  classroomId: PropTypes.string.isRequired,
  tableSpacing: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  loadSavedSettingsAction: PropTypes.func.isRequired,
  storeSavedSettingsAction: PropTypes.func.isRequired,
  gradedReadersRequestAction: PropTypes.func.isRequired,
  gradedReadersLoadingAction: PropTypes.func.isRequired,
  classTitle: PropTypes.string.isRequired
};

export default connect(
  (state, { params }) => ({
    classroomId: params.classroomId,
    tableSpacing: state.savedSettings.settings.tableSpacing,
    loading: state.results.gradedReaders.loading,
    failure: state.results.gradedReaders.failure,
    data: state.results.gradedReaders.group
  }),
  {
    loadSavedSettingsAction: loadSavedSettings,
    storeSavedSettingsAction: storeSavedSettings,
    gradedReadersRequestAction: actions.gradedReadersResultsRequest,
    gradedReadersLoadingAction: actions.gradedReadersResultsLoading
  }
)(GradedReadersClassPage);
