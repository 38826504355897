import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { debounce } from 'lodash';
import styles from './PopupOverlay.scss';
import prefixKeys from '../../utils/object/prefixKeys';
import querySelectorAllFocusable from '../../utils/dom/querySelectorAllFocusable';
import querySelectorPrevFocusable from '../../utils/dom/querySelectorPrevFocusable';
import querySelectorNextFocusable from '../../utils/dom/querySelectorNextFocusable';

// A11y: Aria tags etc are based on real screenreader experiments and influenced by
//       https://www.w3.org/TR/wai-aria/roles
//       https://www.w3.org/WAI/GL/wiki/Using_ARIA_menus
//       http://terrillthompson.com/blog/474

// IMPORTANT: Make sure the following interactions still work:
// - Close panel when Escape is pressed. Return focus to the trigger button.
// - Close panel when click the trigger button. Return focus to the trigger button.
// - Close panel when click outside it. Return focus to the trigger button.
// - Close panel when click another panel trigger button. Set focus on new panel.
// - Close panel when click another focusable element. Set focus on that element.
// - Close panel when tabbing out of it. Set focus on next element.

/** Component for displaying an overlay popup element containing some content. */
export default class PopupOverlay extends Component {
  // This is only necessary because this component does not know which element was clicked to make it visible.
  // If that element is clicked again it will close the popup, so we don't want to call it twice and instantly reopen it.
  // We could just use e.stopImmediatePropagation() but that has the side effect of absorbing legit clicks on other links.
  debouncedHide = debounce(e => {
    const { visible, onTogglePopup } = this.props;
    if (visible) onTogglePopup.call(this, e);
  }, 250);

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { visible, buttonElementId } = this.props;
    if (visible) this.bindEvents();
    this.buttonElement = document.getElementById(buttonElementId);
  }

  // Bind/unbind events when popup is shown/hidden:
  componentDidUpdate({ visible: wasVisible }) {
    const { visible } = this.props;
    if (visible && !wasVisible) this.onShow();
    else if (!visible && wasVisible) this.onHide();
  }

  // Be a good citizen and tidy up after ourselves:
  componentWillUnmount() {
    this.unbindEvents();
  }

  onShow = () => {
    // For accessibility we'll need to remember the button that opened the panel:
    this.setState({ triggeredBy: document.activeElement === document.body ? null : document.activeElement });

    this.bindEvents();

    // Set focus on the first radio or checkbox when popup opens:
    const firstFocusableElement = querySelectorAllFocusable(this.wrapper)[0];
    if (firstFocusableElement) {
      firstFocusableElement.focus();
    }
  };

  onHide = () => {
    const { aria } = this.props;
    const { triggeredBy } = this.state;

    this.unbindEvents();

    // Return focus to the button when popup is closed:
    const returnFocusTo = triggeredBy || (aria && aria.labelledby && document.getElementById(aria.labelledby));

    if (returnFocusTo && (document.activeElement === document.body || this.wrapper.contains(document.activeElement))) {
      returnFocusTo.focus();
    }
  };

  // Decide whether click was OUTSIDE the popup, in which case fire onTogglePopup()
  onDomClick = e => {
    const { buttonElementId, visible } = this.props;
    const buttonElement = document.getElementById(buttonElementId);
    const clickIsOnButton = buttonElement && buttonElement.contains(e.target);
    if (visible) {
      if (!clickIsOnButton && (!this.wrapper.contains(e.target) || e.target.tagName === 'a')) {
        this.debouncedHide(e);
      }
    }
  };

  onKeyPress = e => {
    const { visible } = this.props;
    if (visible) {
      switch (e.which) {
        case 27:
          // Escape to close menu:
          return this.onKeyEscape(e);
        case 38:
        case 40:
          // Up/down to navigate:
          e.preventDefault();
          return this.onKeyUpDown(e);
        default:
      }
    }
    return null;
  };

  onKeyEscape = e => {
    if (e.which === 27) {
      e.stopImmediatePropagation();
      this.debouncedHide(e);
    }
  };

  onKeyUpDown = e => {
    if (e.which === 38) querySelectorPrevFocusable(this.wrapper).focus();
    else if (e.which === 40) querySelectorNextFocusable(this.wrapper).focus();
  };

  onFocusin = e => {
    const { visible } = this.props;
    const clickIsOnButton =
      this.buttonElement && (this.buttonElement.contains(e.target) || this.buttonElement.id === e.target.id);
    if (!clickIsOnButton && visible && !this.wrapper.contains(e.target) && this.wrapper.contains(e.relatedTarget)) {
      this.debouncedHide(e);
    }
  };

  bindEvents = () => {
    const { onTogglePopup } = this.props;
    // These events are for the Escape key, clicking outside the popup and to know when it loses focus:
    if (onTogglePopup) {
      document.addEventListener('click', this.onDomClick, true);
      document.addEventListener('keydown', this.onKeyPress, true);
      document.addEventListener('focusin', this.onFocusin, true);
    }
  };

  unbindEvents = () => {
    document.removeEventListener('click', this.onDomClick, true);
    document.removeEventListener('keydown', this.onKeyPress, true);
    document.removeEventListener('focusin', this.onFocusin, true);
  };

  render() {
    const { visible, hangRight, children, id, customClassName, inline, aria, role, customStyle } = this.props;
    // If aria attributes were supplied, ensure they all have a prefix of "aria-":
    const ariaAttrs = prefixKeys(aria, 'aria-');
    return (
      <div
        id={id}
        ref={elem => {
          this.wrapper = elem;
        }}
        className={classnames(styles.popupOverlay, customClassName, {
          [styles.inline]: inline,
          [styles.visible]: visible,
          [styles.hangRight]: hangRight,
          [styles.filterButton]: customClassName.indexOf('filterButton') !== -1,
          [styles.sortButton]: customClassName.indexOf('sortButton') !== -1
        })}
        {...ariaAttrs}
        {...(role && { role })}
        style={{ ...customStyle }}
      >
        {children}
      </div>
    );
  }
}

PopupOverlay.propTypes = {
  /** Html id of the component */
  id: PropTypes.string,
  /** Boolean flag to show the popup or not */
  visible: PropTypes.bool,
  /** Method to show/hide the popup (Used to close the popup when use clicks outside or on a link) */
  onTogglePopup: PropTypes.func.isRequired,
  /** Boolean flag to render element an an inline block intead of absolutely positioned */
  inline: PropTypes.bool,
  /** Boolean flag to move the popup to the right of the element it overlays */
  hangRight: PropTypes.bool,
  /** The content to be dispalyed in the popup. */
  children: PropTypes.element.isRequired,
  /** Optional extra className for extending styling in context. */
  customClassName: PropTypes.string,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object,
  /** This is the ID of the button used to toggle the popup. Click to this element are ignored when closing the popup */
  buttonElementId: PropTypes.string,
  /** used to provide semantic meaning to content */
  role: PropTypes.string,
  customStyle: PropTypes.object
};
