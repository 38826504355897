import { put } from 'redux-saga/effects';
import { omit } from 'lodash';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch.js';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import contactUsSchema from '../../../../../../static/schema/draft-4/contact-us-submission-OLB-request-body.json';
import * as t from '../../../../actionTypes';

function* submit(data) {
  const payload = {
    ...omit(data, 'email'),
    ces10: data.email
  };

  const errors = yield validateInputUsingSchema(contactUsSchema, t.CONTACT_US_VALIDATE_COMPLETED, payload);
  const hasErrors = Object.values(errors).includes(true);

  if (hasErrors) {
    yield put(actions.validateContactUsInputFragment());
    return;
  }

  const response = yield signedFetch('contactUsSubmission', `${__API_BASE__}/open/contact`, 'POST', payload);
  if (response.status === 'success') {
    if (payload.platformCode === 'OLB') {
      yield put(actions.setContactUsOlbSuccess(payload.platformCode));
    }
    if (payload.platformCode === 'HUB') {
      yield put(actions.setContactUsOlbSuccess(payload.platformCode));
    }
    return;
  }
  yield put(actions.setContactUsOlbFailure(response.errorMessages, payload.platformCode));
}

export default submit;
