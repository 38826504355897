import { put } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { getBookTitleSuccess, getBookTitleFailure } from '../../../../actions/inspectionCopyBookTitle';

export default function* getBookTitle(bookId) {
  const response = yield signedFetch('getBookTitle', `${__API_BASE__}/open/content/ebook-sample/book/${bookId}`, 'GET');

  if (response.status === 'success') {
    return yield put(getBookTitleSuccess(response));
  }

  return yield put(getBookTitleFailure({ errors: response.message }));
}
