import { mapValues, pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { PRODUCT_TARGET_USERTYPE } from '@oup/shared-node-browser/constants';
import classnames from 'classnames';
import LinkWithIcon from '../../../../components/LinkWithIcon/LinkWithIcon.js';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon.js';
import UserLicenceListing from '../../../../components/UserLicenceListing/UserLicenceListing';
import cmsContent from '../../../../utils/cmsContent';
import styles from './ReviewLicencesViews.scss';
import { featureIsEnabled } from '../../../../globals/envSettings';

const filterIds = (ids = [], filterId = []) => ids.filter(userId => filterId.includes(userId));

function UserAssignments({
  users,
  userIdsToAssignLicencesTo = [],
  userIdsWithExistingLicence = [],
  userIdsToNotAssignLicencesTo = [],
  newLicenceAssignments = {},
  existingLicenceAssignments = {},
  teacherIds = [],
  learnerIds = [],
  toggleView,
  shouldTeacherAssignLicences,
  userIdsWithoutPermission,
  productTargetUsertype
}) {
  const CMS = cmsContent.assignLearningMaterialSummary || {};
  const existingLicences = mapValues(existingLicenceAssignments, licenceDetails => ({
    ...licenceDetails,
    isAssigned: true
  }));
  // Filter for userIds To Assign Licences
  const learnerUserIdsToAssignLicencesTo = filterIds(userIdsToAssignLicencesTo, learnerIds);
  const teacherUserIdsToAssignLicencesTo = filterIds(userIdsToAssignLicencesTo, teacherIds);
  // Filter for userIds With Existing Licences
  const learnerUserIdsWithExistingLicence = filterIds(userIdsWithExistingLicence, learnerIds);
  const teacherUserIdsWithExistingLicence = filterIds(userIdsWithExistingLicence, teacherIds);
  // Filter for userIds Not Assign Licences
  const learnerUserIdsToNotAssignLicencesTo = filterIds(userIdsToNotAssignLicencesTo, learnerIds);
  const teacherUserIdsToNotAssignLicencesTo = filterIds(userIdsToNotAssignLicencesTo, teacherIds);
  const isAssignMultipleLmIsEnable = featureIsEnabled('add-multiple-learning-material');
  // filter userIds With Existing Licences based on productTargetUsertype for showing header
  const finalUserIdsWithExistingLicence = () => {
    if (productTargetUsertype === PRODUCT_TARGET_USERTYPE.STUDENT) return learnerUserIdsWithExistingLicence;
    if (productTargetUsertype === PRODUCT_TARGET_USERTYPE.TEACHER) return teacherUserIdsWithExistingLicence;
    return userIdsWithExistingLicence;
  };

  return (
    <div
      className={classnames(styles.userAssignments, {
        [styles.assignMultipleLm]: isAssignMultipleLmIsEnable
      })}
    >
      {!isAssignMultipleLmIsEnable && (
        <LinkWithIcon text={CMS.back_to_licensing_text} isLhs glyph={GLYPHS.ICON_LEFT} action={toggleView} />
      )}

      {learnerUserIdsToAssignLicencesTo.length || teacherUserIdsToAssignLicencesTo.length ? (
        <div className={`gin-top2 git-bot ${styles.borderTop}`}>
          <h3>{isAssignMultipleLmIsEnable ? CMS.assign_licence_to_text : CMS.assign_with_licence_text_single_lm}</h3>
        </div>
      ) : null}

      {!userIdsToAssignLicencesTo.length && !userIdsToNotAssignLicencesTo.length && !teacherIds.length ? (
        <p className="gin-top2">{CMS.none_text}</p>
      ) : null}

      {learnerUserIdsToAssignLicencesTo.length ? (
        <div className="gin-top2">
          <UserLicenceListing
            heading={CMS.students_header_text}
            users={pick(users, learnerUserIdsToAssignLicencesTo)}
            userLicences={newLicenceAssignments}
          />
        </div>
      ) : null}

      {teacherUserIdsToAssignLicencesTo.length ? (
        <div className="gin-top2">
          <UserLicenceListing
            heading={CMS.teachers_header_text}
            users={pick(users, teacherUserIdsToAssignLicencesTo)}
            userLicences={newLicenceAssignments}
          />
        </div>
      ) : null}

      {!!finalUserIdsWithExistingLicence().length ||
      !!userIdsToNotAssignLicencesTo.length ||
      (!shouldTeacherAssignLicences && !!teacherIds.length) ? (
        <div className="gin-top4 git-bot">
          <h3>
            {isAssignMultipleLmIsEnable
              ? CMS.assign_have_licence_or_nocode_text
              : CMS.assign_without_licence_text_single_lm}
          </h3>
        </div>
      ) : null}

      {(!!learnerUserIdsWithExistingLicence.length || !!learnerUserIdsToNotAssignLicencesTo.length) &&
      (productTargetUsertype === PRODUCT_TARGET_USERTYPE.STUDENT || !productTargetUsertype) ? (
        <div className="gin-top2">
          <UserLicenceListing
            heading={CMS.students_header_text}
            users={pick(users, [...learnerUserIdsToNotAssignLicencesTo, ...learnerUserIdsWithExistingLicence])}
            userLicences={existingLicences}
          />
        </div>
      ) : null}

      {!shouldTeacherAssignLicences &&
      teacherIds.length &&
      (productTargetUsertype === PRODUCT_TARGET_USERTYPE.TEACHER || !productTargetUsertype) ? (
        <div className="gin-top2">
          <UserLicenceListing
            heading={CMS.teachers_header_text}
            users={pick(users, teacherIds)}
            userIdsWithoutPermission={userIdsWithoutPermission}
            userLicences={existingLicences}
          />
        </div>
      ) : null}

      {shouldTeacherAssignLicences &&
      (!!teacherUserIdsWithExistingLicence.length ||
        !!teacherUserIdsToNotAssignLicencesTo.length ||
        !!userIdsWithoutPermission.length) &&
      (productTargetUsertype === PRODUCT_TARGET_USERTYPE.TEACHER || !productTargetUsertype) ? (
        <div className="gin-top2">
          <UserLicenceListing
            heading={CMS.teachers_header_text}
            users={pick(users, [
              ...teacherUserIdsToNotAssignLicencesTo,
              ...teacherUserIdsWithExistingLicence,
              ...userIdsWithoutPermission
            ])}
            userIdsWithoutPermission={userIdsWithoutPermission}
            userLicences={existingLicences}
          />
        </div>
      ) : null}
    </div>
  );
}

UserAssignments.propTypes = {
  users: PropTypes.object.isRequired,
  teacherIds: PropTypes.array,
  learnerIds: PropTypes.array,
  userIdsToAssignLicencesTo: PropTypes.array,
  userIdsToNotAssignLicencesTo: PropTypes.array,
  userIdsWithExistingLicence: PropTypes.array,
  newLicenceAssignments: PropTypes.object,
  existingLicenceAssignments: PropTypes.object,
  toggleView: PropTypes.func.isRequired,
  shouldTeacherAssignLicences: PropTypes.bool.isRequired,
  userIdsWithoutPermission: PropTypes.array.isRequired,
  productTargetUsertype: PropTypes.string
};

export default UserAssignments;
