import PropTypes from 'prop-types';
import { maxBy } from 'lodash';
import sumOf from '../../utils/array/sumOf';

export const getAverageTotals = (levels, useCompletedOnly) => ({
  totalAverageLevelsScore: sumOf(levels, 'averageLevelScoreAchieved'),
  totalAverageLevelsCompleted: sumOf(levels, 'averageSubmittedNumberOfActivities'),
  totalLevelScoreAvailable: sumOf(levels, useCompletedOnly ? 'levelScoreAvailable' : 'allActivityLevelScoreAvailable'),
  totalLevelScoreAchieved: sumOf(levels, 'sumLevelScoreAchieved'),
  totalNumberOfActivities: sumOf(levels, 'totalNumberOfActivities'),
  maxTotalStudentsThatSubmitted: maxBy(levels, 'totalStudentsThatSubmitted').totalStudentsThatSubmitted,
  sumTotalSubmittedNumberOfActivities: sumOf(levels, 'totalSubmittedNumberOfActivities')
});

/**
 * Iterates over the levels averages and builds up an aggregate average total (score/completed).
 */
function AverageTotals({ levels, useCompletedOnly, children }) {
  return levels.length ? children(getAverageTotals(levels, useCompletedOnly)) : null;
}
AverageTotals.propTypes = {
  levels: PropTypes.array.isRequired,
  useCompletedOnly: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired
};

export default AverageTotals;
