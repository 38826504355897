import { omit } from 'lodash';
import * as types from '../actionTypes';

const initialState = {
  user: {},
  errors: {},
  usernameTaken: null,
  usernameValidationLoading: false,
  submitting: false,
  success: false,
  failure: false,
  generatedUserName: ''
};

/**
 * Resolve the next state object for the managed user change username panel
 * when an action is invoked.
 *
 * @param {Object} state
 * @param {string} action.type
 * @param {?Object} action.payload
 *
 * @return {Object}
 */
export default function getManagedUserChangeUsernameState(state = initialState, { type, payload }) {
  switch (type) {
    case types.MANAGED_USER_CHANGE_USERNAME_SET_RECORD:
      return {
        ...initialState,
        user: payload
      };
    case types.MANAGED_USER_CHANGE_USERNAME_VALIDATE_USERNAME:
      return {
        ...state,
        usernameValidationLoading: true
      };
    case types.MANAGED_USER_CHANGE_USERNAME_SET_VALIDATION_RESULT:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...omit(payload, 'usernameTaken')
        },
        usernameTaken: payload.usernameTaken,
        usernameValidationLoading: false
      };
    case types.MANAGED_USER_CHANGE_USERNAME_SUBMIT:
      return {
        ...state,
        failure: false,
        submitting: true
      };
    case types.MANAGED_USER_CHANGE_USERNAME_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true
      };
    case types.MANAGED_USER_CHANGE_USERNAME_FAILURE:
      return {
        ...state,
        submitting: false,
        failure: true
      };
    case types.MANAGED_USER_CHANGE_USERNAME_RETRY:
      return {
        ...state,
        failure: false
      };
    case types.GENERATE_USERNAME_FOR_MANAGED_USER_REQUEST:
      return {
        ...state,
        generating: true
      };
    case types.GENERATE_USERNAME_FOR_MANAGED_USER_SUCCESS:
      return {
        ...state,
        generating: false,
        generatedUserName: payload
      };
    case types.GENERATE_USERNAME_FOR_MANAGED_USER_FAILURE:
      return {
        ...state,
        generating: false,
        errors: payload
      };
    default:
      return state;
  }
}
