import { put, select, all, call, takeLatest } from 'redux-saga/effects';
import { USER_STATUS } from '@oup/shared-node-browser/constants';
import {
  setRequestFailed,
  setResult,
  SET_STUDENT_LIST,
  DOWNLOAD_SIGN_IN_CARD,
  VALIDATE_USER_PASSWORD_REQUESTED,
  GET_CLASSROOM_STUDENTS,
  resetPasswordSelectedUsers,
  validateUserPasswordCompleted,
  GENERATE_RANDOM_PASSWORD_REQUESTED,
  generateRandomPasswordCompleted,
  SUBMIT_LEGACY_USERS,
  savingLegacyUsersCompleted,
  setStudentsToDownload,
  GET_USER_QUEUE_REQUEST,
  getUserQueueRequestCompleted,
  getUserQueueRequestFailed,
  getUserQueueResponseFailed,
  setManagedUserPasswordFailure,
  setArchiveUsers
} from '../../../../reducers/userSignInCard.reducer';

import { pollStartAction, pollStopAction } from '../../../../reducers/polling.reducer';

import { storePeople } from '../../../../reducers/data/people.js';

import { searchRequest } from '../searchAndSelection/search.saga';

import getSignInCardDetailsApi from '../../../apiCalls/users/getManagedUserDetails.api';
import getStudentsByClassApi from '../../../apiCalls/classes/getStudentsByClass.api';
import generateSignInCard from '../../../../../globals/generateSignInCard';
import baseSchema from '../../../../../../static/schema/draft-4/bulk-upload-managedusers-request-body.json';
import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import bulkManagedUserUploadApi from '../../../apiCalls/bulkManagedUserUploadApi';
import getUserQueueApi from '../../../apiCalls/getUserQueueApi';
import managedUserPasswordGenerator from '../../../apiCalls/managedUserPasswordGenerator';
import { MANAGED_USER_ACCOUNT_STATUS, SIGN_IN_CARD_USER_STATUS } from '../../../../../globals/appConstants';

const moduleName = '[userSignInCard Saga]';
let passwordFailed = false;

function* getSignInCardDetails() {
  const { userIds, orgId, students } = yield select(state => ({
    userIds: state.userSignInCard.userIds,
    orgId: state.identity.currentOrganisationId,
    students: state.search.orgStudents
  }));
  // on Class Tab refresh students data will not available So calling user query api
  if (!students) {
    console.log('[performSearch for Org Students]');

    const apiResults = yield searchRequest({
      instance: 'orgStudents',
      term: '',
      sort: undefined,
      page: 1,
      size: 10,
      filters: {
        orgId,
        roles: ['LEARNER', 'MANAGED_USER'],
        active: true,
        invited: true,
        archived: true,
        isOrgStudentTab: true
      }
    });

    if (apiResults.data.users) {
      yield put(storePeople(apiResults.data.users));
    }
  }
  const { people } = yield select(state => ({
    people: state.people.data
  }));

  const archiveUserIds = userIds.filter(
    id => people[id].orgArchiveStatus && people[id].orgArchiveStatus === USER_STATUS.ARCHIVED
  );
  const archiveUsers = archiveUserIds.length
    ? archiveUserIds.map(id => ({ userName: people[id].username, status: SIGN_IN_CARD_USER_STATUS.ARCHIVED }))
    : [];
  const activeUserIds = archiveUserIds.length ? userIds.filter(id => !archiveUserIds.includes(id)) : userIds;
  yield put(setArchiveUsers(archiveUsers));
  const signInCardApi = activeUserIds.length
    ? yield getSignInCardDetailsApi(orgId, activeUserIds)
    : { status: 'archive' };
  return signInCardApi;
}

function* uploadManagedUser() {
  const { legacyUserIds, orgId } = yield select(state => ({
    legacyUserIds: state.userSignInCard.failedIds,
    orgId: state.organisationPage.orgId
  }));
  return yield bulkManagedUserUploadApi(orgId, legacyUserIds);
}

function* getUsersSignInCard() {
  const result = yield getSignInCardDetails();
  if (result.status === 'archive') {
    const { archiveUsers, successUsers, orgId } = yield select(state => ({
      archiveUsers: state.userSignInCard.archiveUsers,
      successUsers: state.userSignInCard.successUsers,
      orgId: state.identity.currentOrganisationId
    }));
    const currentOrg = yield select(state => state.organisations.data[orgId]);
    const schoolCode = currentOrg && currentOrg.customId;
    const pdf = yield generateSignInCard({ successUsers, archiveUsers }, schoolCode);
    yield put(setResult(successUsers, pdf.docSize));
  } else if (result.status !== 'success') {
    yield put(setRequestFailed(true));
  } else if (result.data.failedUsers.length > 0) {
    const { people } = yield select(state => ({
      people: state.people.data
    }));
    const failedUsers = result.data.failedUsers.map(id => ({
      userName: people[id].username,
      firstName: people[id].firstname,
      lastName: people[id].lastname,
      yearGroup: people[id].yearGroup,
      password: '',
      status: MANAGED_USER_ACCOUNT_STATUS.EXISTS
    }));
    if (passwordFailed) {
      yield put(setManagedUserPasswordFailure(true));
      passwordFailed = false;
    } else {
      yield put(resetPasswordSelectedUsers(failedUsers));
    }
  } else {
    const { archiveUsers, orgId } = yield select(state => ({
      archiveUsers: state.userSignInCard.archiveUsers,
      orgId: state.identity.currentOrganisationId
    }));
    const cards = { ...result.data, archiveUsers };
    const currentOrg = yield select(state => state.organisations.data[orgId]);
    const schoolCode = currentOrg && currentOrg.customId;
    const pdf = yield generateSignInCard(cards, schoolCode);
    yield put(setResult(result.data, pdf.docSize));
  }
}

function* validatePassword(pass) {
  const [errors] = yield validateInputUsingSchema(baseSchema, null, [pass.input]);
  yield put(validateUserPasswordCompleted(pass.id, errors));
}

function* generatePassword(password) {
  const { orgId, failedIds } = yield select(state => ({
    orgId: state.identity.currentOrganisationId,
    failedIds: state.userSignInCard.failedIds
  }));
  const passwords = password.isGenerated
    ? yield managedUserPasswordGenerator(orgId, failedIds.length)
    : { status: 'success', data: [] };
  if (passwords.status !== 'success') {
    yield put(setRequestFailed(true));
  }
  yield put(generateRandomPasswordCompleted(passwords.data));
}

function* submitLegacyUsers() {
  const results = yield uploadManagedUser();
  if (
    results.status === 'fail' &&
    (Object.keys(results.data)[0] === 'request[0]' || Object.keys(results.data)[0] === 'request[0].yearGroup')
  ) {
    yield put(getUserQueueResponseFailed(true));
  } else if (results.status !== 'success') {
    yield put(setRequestFailed(true));
  } else {
    yield put(savingLegacyUsersCompleted(results.data.batchId));
    yield put(pollStartAction('MANAGED_USER_POLL'));
  }
}

function* downloadSignInCard() {
  const { result, archiveUsers, orgId } = yield select(state => ({
    result: state.userSignInCard.result,
    archiveUsers: state.userSignInCard.archiveUsers,
    orgId: state.identity.currentOrganisationId
  }));
  const currentOrg = yield select(state => state.organisations.data[orgId]);
  const schoolCode = currentOrg && currentOrg.customId;
  const cards = { ...result, archiveUsers };
  yield generateSignInCard(cards, schoolCode);
}

function* getUserQueue() {
  const { orgId, batchId } = yield select(state => ({
    batchId: state.userSignInCard.batchId,
    orgId: state.identity.currentOrganisationId
  }));
  if (batchId !== null) {
    const res = yield getUserQueueApi(orgId, batchId);
    console.log(`UserDetails by getUserQueueApi:`, res);
    if (
      res.data &&
      res.data[0] &&
      res.data[0].message &&
      res.data[0].message.indexOf('Password Policy Violated') !== -1
    ) {
      yield put(setManagedUserPasswordFailure(true));
      passwordFailed = true;
    } else if (res.status !== 'success') {
      yield put(getUserQueueRequestFailed(true));
    }
    return res;
  }
  return null;
}

function* getStudentsByClass() {
  const { orgId, classIds } = yield select(state => ({
    classIds: state.userSignInCard.classIds,
    orgId: state.organisationPage.orgId
  }));
  const result = yield getStudentsByClassApi(orgId, classIds);
  if (result !== 'success' || result.data.length === 0) yield put(setRequestFailed(true));
  yield put(setStudentsToDownload(result.data));
}

export function* checkUserQueue() {
  const { totalUsers, userIds } = yield select(state => ({
    totalUsers: state.userSignInCard.totalUsers,
    userIds: state.userSignInCard.userIds
  }));
  const response = yield call(getUserQueue);
  if (response === null) {
    yield all([put(pollStopAction()), put(getUserQueueRequestCompleted())]);
  } else {
    const isAllUsersAvailable = response.data && response.data.length === totalUsers;
    if (isAllUsersAvailable) {
      yield all([put(pollStopAction()), put(getUserQueueRequestCompleted()), put(setStudentsToDownload(userIds))]);
    }
  }
}

export default function* managedUserSignInCardSaga() {
  console.log(moduleName, 'Beginning');

  yield all([
    takeLatest(GET_CLASSROOM_STUDENTS, getStudentsByClass),
    takeLatest(SET_STUDENT_LIST, getUsersSignInCard),
    takeLatest(DOWNLOAD_SIGN_IN_CARD, downloadSignInCard),
    takeLatest(VALIDATE_USER_PASSWORD_REQUESTED, validatePassword),
    takeLatest(GENERATE_RANDOM_PASSWORD_REQUESTED, generatePassword),
    takeLatest(SUBMIT_LEGACY_USERS, submitLegacyUsers),
    takeLatest(GET_USER_QUEUE_REQUEST, checkUserQueue)
  ]);
}
