import { pick, values } from 'lodash';
import * as t from '../actionTypes';
import schema from '../../../static/schema/draft-4/edit-managed-user-auth-request-body.json';

const initialState = {
  orgId: null,
  userId: null,
  user: {},
  password: '',
  errors: {},
  passwordMinLength: schema.properties.newPassword.minLength,
  passwordUsed: false,
  submitting: false,
  success: false,
  failure: false,
  download: false,
  generating: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.VALIDATE_CHANGE_MANAGED_USER_PASSWORD_INPUT:
      return {
        ...state,
        password: payload,
        errors: {}
      };
    case t.VALIDATE_CHANGE_MANAGED_USER_PASSWORD_COMPLETED:
      return {
        ...state,
        errors: { ...state.errors, ...payload }
      };
    case t.GENERATE_MANAGED_USER_PASSWORD_REQUEST:
      return {
        ...state,
        generating: true,
        errors: {}
      };
    case t.GENERATE_MANAGED_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        password: payload.password,
        generating: false,
        errors: {}
      };
    case t.GENERATE_MANAGED_USER_PASSWORD_FAILURE:
      return {
        ...state,
        success: false,
        submitting: false,
        generating: false,
        errors: { ...state.errors, generatedPassword: values(payload.errors.message) }
      };
    case t.CHANGE_MANAGED_USER_PASSWORD_REQUEST:
      return {
        ...state,
        submitting: true,
        errors: {}
      };
    case t.CHANGE_MANAGED_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        errors: {}
      };
    case t.CHANGE_MANAGED_USER_PASSWORD_FAILURE:
      return {
        ...state,
        success: false,
        submitting: false,
        failure: true
      };
    case t.CHANGE_MANAGED_USER_PASSWORD_CLEAR_FAILURE:
      return {
        ...state,
        failure: false
      };
    case t.SET_MANAGED_USER:
      return {
        ...initialState,
        ...pick(payload, ['userId', 'user', 'orgId'])
      };
    case t.MANAGED_USER_NAVIGATE_DOWNLOAD_CARD:
      return {
        ...state,
        download: true
      };
    default:
      return state;
  }
};
