import PropTypes from 'prop-types';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import classnames from 'classnames';

import styles from './ExpandableItem.scss';
import Button, { buttonTypes } from '../Button/Button';

function ExpandableItem({ label, expanded = false, onToggleExpand, duration = 300, children }) {
  return (
    <div className={classnames(styles.expandableItem, { [styles.collapsed]: !expanded })}>
      <div className={styles.label}>
        <Button text={label} type={buttonTypes.DROPDOWN_SHORT} onClick={onToggleExpand} active={expanded} />
      </div>
      <AnimateHeight duration={duration} height={expanded ? 'auto' : 0}>
        <div className={styles.content}>{children}</div>
      </AnimateHeight>
    </div>
  );
}

ExpandableItem.propTypes = {
  label: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  onToggleExpand: PropTypes.func.isRequired,
  duration: PropTypes.number,
  children: PropTypes.node
};

export default ExpandableItem;
