import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Button, { buttonTypes } from '../Button/Button';
import ContentWithThumbnail from '../ContentWithThumbnail/ContentWithThumbnail';
import HorizontalScrollContainer from '../HorizontalScrollContainer/HorizontalScrollContainer';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import COLORS from '../../globals/colors';
import styles from './EntityPageHeader.scss';
import ENTITY_TYPES, { VALID_ENTITY_TYPES } from '../../globals/entityTypes';
import withLocalizedContent from '../../language/withLocalizedContent';
import userRoles from '../../globals/userRoles';
import { isEltAssessmentMode, isHubMode, isRvsMode } from '../../utils/platform';
import redirectToUrl from '../../utils/redirectToUrl';
import Tooltip from '../Tooltip/Tooltip';
import { orgRoleDescriptions } from '../../globals/orgRoles';
import env from '../../globals/envSettings';

const ENTITY_PRESETS = {
  [ENTITY_TYPES.ACTIVITY]: {
    glyph: GLYPHS.ICON_ACTIVITY,
    entityGlyphBackground: COLORS.ACTIVITY,
    entityBackground: COLORS.ACTIVITY
  },
  [ENTITY_TYPES.ARCHIVED_CLASS]: {
    glyph: GLYPHS.ICON_CLASS,
    entityGlyphBackground: COLORS.ARCHIVED,
    entityBackground: COLORS.ARCHIVED
  },
  [ENTITY_TYPES.ARCHIVED_ORGANISATION]: {
    glyph: GLYPHS.ICON_ORG,
    entityGlyphBackground: COLORS.ARCHIVED,
    entityBackground: COLORS.ARCHIVED
  },
  [ENTITY_TYPES.ARCHIVED_PROFILE]: {
    glyph: GLYPHS.ICON_USER,
    entityGlyphBackground: COLORS.ARCHIVED,
    entityBackground: COLORS.ARCHIVED
  },
  [ENTITY_TYPES.CLASS]: {
    glyph: GLYPHS.ICON_CLASS,
    entityGlyphBackground: COLORS.CLASSROOM,
    entityBackground: COLORS.CLASSROOM
  },
  [ENTITY_TYPES.ORGANISATION]: {
    glyph: GLYPHS.ICON_ORG,
    entityGlyphBackground: COLORS.ORGANIZATION,
    entityBackground: COLORS.PRIMARY_BLUE
  },
  [ENTITY_TYPES.PENDING_ORGANISATION]: {
    glyph: GLYPHS.ICON_ORG,
    entityGlyphBackground: COLORS.ORGANIZATION,
    entityBackground: COLORS.PRIMARY_BLUE,
    smallIcon: {
      smallCircleGlyph: GLYPHS.ICON_PENDING,
      smallCircleBackgroundColor: COLORS.BACKGROUND
    }
  },
  [ENTITY_TYPES.PROFILE]: {
    glyph: GLYPHS.ICON_USER,
    entityGlyphBackground: COLORS.REGISTER,
    entityBackground: COLORS.PROFILE
  },
  [ENTITY_TYPES.RESOURCES]: {
    entityBackground: COLORS.TR_HEADER,
    squareImg: true
  },
  [ENTITY_TYPES.SUPPORT]: {
    glyph: GLYPHS.ICON_HELP,
    entityGlyphBackground: COLORS.SUPPORT_ICON,
    entityBackground: COLORS.SUPPORT
  }
};

const myTestsButtonOnClick = amStaffUrl => {
  redirectToUrl(amStaffUrl, true);
};
function EntityPageHeader({
  customClass,
  entityType,
  entityTitle,
  entitySubtitle = null,
  entityImage = null,
  entityImageAlt = null,
  editButtonTo = null,
  backButtonText = null,
  editButtonOnClick = null,
  backButtonOnClick = null,
  backButtonTo = null,
  displayEditButton,
  userRole,
  orgRole,
  localizedContent: { appComponents: content }
}) {
  const { glyph, entityBackground, entityGlyphBackground, smallIcon, squareImg } = ENTITY_PRESETS[entityType];
  const editButtonId =
    (editButtonOnClick || editButtonTo) && entityType === ENTITY_TYPES.PROFILE && userRole === userRoles.MANAGED_USER
      ? 'entityEditButtonV2'
      : 'entityEditButton';

  const orgTypeDescription = orgRoleDescriptions[orgRole] ? orgRoleDescriptions[orgRole] : '';
  const showOrgType = [userRoles.OUP_ADMIN, userRoles.OUP_SUPPORT].includes(userRole);

  return (
    <HorizontalScrollContainer backgroundColor={entityBackground} hideScrollBar>
      <div className={classnames(styles.entityPageHeader, customClass)}>
        <div className="grid">
          <div className="row">
            <div className="col">
              <ContentWithThumbnail
                size={thumbnailSizes.HEADING}
                glyph={isHubMode() || isRvsMode() ? GLYPHS.ICON_HELP_AND_SUPPORT : glyph}
                imageSrc={entityImage}
                imageAlt={entityImageAlt || entityTitle}
                squareImg={squareImg}
                thumbnailClassName={styles.thumbnailContainer}
                backgroundColor={entityGlyphBackground}
                {...smallIcon}
                {...(entityType === ENTITY_TYPES.PENDING_ORGANISATION
                  ? { smallCircleText: content.entity_page_header_pending }
                  : {})}
              >
                <div className={styles.linkContainer}>
                  {(backButtonOnClick || backButtonTo) && (
                    <Button
                      id="entityBackButton"
                      to={backButtonTo}
                      text={backButtonText}
                      type={buttonTypes.GHOST}
                      glyph={GLYPHS.ICON_LEFT}
                    />
                  )}
                </div>
                <div className={styles.headingAndButton}>
                  <h1>{entityTitle}</h1>
                  {displayEditButton && (
                    <Button
                      id={editButtonId}
                      to={editButtonTo}
                      onClick={editButtonOnClick}
                      text={content.edit_button}
                      type={buttonTypes.GHOST}
                      // This may need to be updated to the new edit glyph in the future
                      glyph={GLYPHS.ICON_EDIT}
                      dataTestId="ENTITY_HEADER_OPEN_EDIT_ACCOUNT"
                    />
                  )}
                  {isEltAssessmentMode() && (
                    <Button
                      id="oteMyTestsButton"
                      onClick={() => myTestsButtonOnClick(env.integrations.ote.amStaffUrl)}
                      text={content.my_tests_button}
                      type={buttonTypes.GHOST}
                      glyph={GLYPHS.ICON_EXTERNAL}
                    />
                  )}
                </div>
                <p className={styles.subtitle}>
                  {entitySubtitle && <span style={{ marginRight: '1rem' }}>{entitySubtitle}</span>}
                  {showOrgType && orgTypeDescription && (
                    <Tooltip
                      style={{ color: '#ffce53', display: 'inline-block' }}
                      title="Only shown to OUP Admin and Support users"
                    >
                      [Org type: {orgTypeDescription}]
                    </Tooltip>
                  )}
                </p>
              </ContentWithThumbnail>
            </div>
          </div>
        </div>
      </div>
    </HorizontalScrollContainer>
  );
}

EntityPageHeader.propTypes = {
  customClass: PropTypes.any,
  // Titles
  entityType: PropTypes.oneOf(VALID_ENTITY_TYPES).isRequired,
  entityTitle: PropTypes.string.isRequired,
  entitySubtitle: PropTypes.string,
  entityImage: PropTypes.string,
  entityImageAlt: PropTypes.string,
  // Button props - These are not required as not all headers have these buttons
  editButtonOnClick: PropTypes.func,
  editButtonTo: PropTypes.string,
  backButtonOnClick: PropTypes.func,
  backButtonTo: PropTypes.string,
  backButtonText: PropTypes.string,
  userRole: PropTypes.string,
  displayEditButton: PropTypes.bool,
  orgRole: PropTypes.string,
  localizedContent: PropTypes.object.isRequired
};
export default withLocalizedContent('appComponents')(EntityPageHeader);
