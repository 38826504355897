import * as t from '../actionTypes';

const requestStatusOptions = { isLoading: true, success: false, error: false };
const successStatusOptions = { isLoading: false, success: true, error: false };
const failureStatusOptions = { isLoading: false, success: false, error: true };

export const initialState = {
  productLocks: {},
  getProductLocksStatus: '',
  updateProductLocksStatus: {}
};

export const requestStatus = {
  SUCCESS: 'success',
  ERROR: 'error'
};

export default function productLocks(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_PRODUCT_LOCKS_SUCCESS:
      return {
        ...state,
        productLocks: payload.productLocks,
        getProductLocksStatus: requestStatus.SUCCESS
      };
    case t.GET_PRODUCT_LOCKS_ERROR: {
      return { ...state, getProductLocksStatus: requestStatus.ERROR };
    }
    case t.UPDATE_PRODUCT_LOCKS_REQUEST:
      return {
        ...state,
        updateProductLocksStatus: { ...requestStatusOptions }
      };
    case t.UPDATE_PRODUCT_LOCKS_SUCCESS:
      return {
        ...state,
        updateProductLocksStatus: { ...successStatusOptions }
      };
    case t.UPDATE_PRODUCT_LOCKS_ERROR:
      return { ...state, updateProductLocksStatus: { ...failureStatusOptions } };
    case t.PRODUCT_LOCKS_RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
