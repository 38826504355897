import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const getUserLicenceUrl = `${__API_BASE__}/user-licence`;

function getUserLicenceRequest(licenceId) {
  return signedFetch('getUserLicence', `${getUserLicenceUrl}/${licenceId}`);
}

export default getUserLicenceRequest;
