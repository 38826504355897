import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

import submitCodeUpdates from './submitCodeUpdates';

function* updateJoiningCode() {
  yield takeLatest(t.SUBMIT_EDIT_JOINING_CODE, submitCodeUpdates);
}

export default updateJoiningCode;
