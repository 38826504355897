import { select } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch.js';
import { toQueryString } from '../../../../../utils/url.js';

export default function* getEReaderProductsRequest() {
  console.log(`[getEReaderProductsRequest Saga] Submitting request to get eReader books.`);

  const { filters } = yield select(state => ({
    filters: state.contentPreview.filters
  }));

  const { id: bookId, title, field, search, max, bookPath } = filters;
  const queryParams = {
    ...(bookId && { bookId }),
    ...(title && { title }),
    ...(bookPath && { bookPath }),
    ...(field && { field }),
    ...(search && { search }),
    ...(max !== undefined ? { max } : { max: 0 })
  };
  const url = `${__API_BASE__}/ereader/books${toQueryString(queryParams)}`;

  return yield signedFetch('getEReaderProducts', url, 'GET', null, null, false, true);
}
