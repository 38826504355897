// Helper for use in an array filter.
// Expects a map of one key:value pair to filter by. Or the name of a prop to filter by "truthy" values.
// Eg: invites.filter(by({ orgId }));
// Eg: invites.filter(by('expired'));
export default function filterBy(propOrKeyValueMap) {
  const key = typeof propOrKeyValueMap === 'string' ? propOrKeyValueMap : Object.keys(propOrKeyValueMap)[0];

  return typeof propOrKeyValueMap === 'string'
    ? item => !key || (item && item[key])
    : item => !key || (item && item[key] === propOrKeyValueMap[key]);
}
