import * as t from '../actionTypes';

export const userPreferencesRequest = (orgId, userId) => ({
  type: t.ORB_USER_PREFERENCES_REQUEST,
  payload: { orgId, userId }
});

export const userPreferencesSuccess = payload => ({
  type: t.ORB_USER_PREFERENCES_SUCCESS,
  payload
});

export const userPreferencesFailure = error => ({
  type: t.ORB_USER_PREFERENCES_FAILURE,
  payload: error
});

export const setUserPreferencesRequest = (orgId, userId, preferences) => ({
  type: t.SET_ORB_USER_PREFERENCES_REQUEST,
  payload: { orgId, userId, preferences }
});

export const setUserPreferencesSuccess = payload => ({
  type: t.SET_ORB_USER_PREFERENCES_SUCCESS,
  payload
});

export const setUserPreferencesFailure = error => ({
  type: t.SET_ORB_USER_PREFERENCES_FAILURE,
  payload: error
});

export const closeUserPreferences = () => ({
  type: t.CLOSE_ORB_USER_PREFERENCES
});
