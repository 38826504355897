// Much like jQuery's .closest() method.
// Return get the closest matching element up the DOM tree.

// Polyfill adapted from https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#Polyfill
// Instead of polyfilling, we just need to figure out which vendor-specific method name to call:
// Also note we use documentElement instead of Element.prototype because it may not have been polyfilled;
const doc = document.documentElement;
let vendorMethod;

// For browsers that don't support some sort of native elem.matches()
function polyfill(s) {
  if (!(this.document || this.ownerDocument || this).querySelectorAll) return false;
  const matched = (this.document || this.ownerDocument || this).querySelectorAll(s);
  let i = matched.length;
  // eslint-disable-next-line no-plusplus, no-empty
  while (--i >= 0 && matched.item(i) !== this) {}
  return i > -1;
}

export default function closest(elem, selector) {
  /* eslint-disable no-cond-assign, no-param-reassign */

  // Attempt to feature-detect the browser's native method name:
  if (!vendorMethod) {
    vendorMethod = (
      doc.matches ||
      doc.matchesSelector ||
      doc.webkitMatchesSelector ||
      doc.mozMatchesSelector ||
      doc.msMatchesSelector ||
      doc.oMatchesSelector
    ).name;
  }

  // Use native methods if possible, otherwise fall back to polyfill:
  if (vendorMethod) {
    do {
      if (elem && elem[vendorMethod] && elem[vendorMethod](selector)) return elem;
    } while ((elem = elem.parentNode));
  } else {
    do {
      if (elem && polyfill.call(elem, selector)) return elem;
    } while ((elem = elem.parentNode));
  }

  return null;
}

window.closest = closest;
