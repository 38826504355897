import { put, select, take, takeLatest, debounce } from 'redux-saga/effects';
import {
  clearPreselectedTeacher,
  CLOSE_FORM,
  CREATE_CLASS,
  setClassroomNameValidated,
  setClassroomNameOnBlurValidation,
  setPreselectedTeacher,
  SET_CLASSROOM_NAME,
  CLASSROOM_NAME_ON_BLUR,
  showConfirmation
} from '../../../../reducers/classroomCreate';
import checkClassNameApi from '../../../apiCalls/classes/checkClassName.api';
import createClassroomApi from '../../../apiCalls/createClassroomApi';
import { pollOrgClassesAdded } from '../dataRecency/pollOrgClasses';
import { getCurrentPlatform, isHubMode } from '../../../../../utils/platform';

// Helpers to extract failed IDs from the object of user statuses returned by the lambda:
function byFail(userId, userStatuses) {
  return userStatuses[userId].status !== 'SUCCESS';
}

function* preselectThisUser() {
  // Get userId from identity
  const { userId, role } = yield select(state => ({
    userId: state.identity.userId,
    role: state.identity.role
  }));

  if (role === 'TEACHER') {
    console.log(`[createClassroom Saga] Preselecting userId: ${userId}`);
    yield put(setPreselectedTeacher(userId));
  } else {
    console.log('[createClassroom Saga] Not preselecting user as role is not TEACHER');
    yield put(clearPreselectedTeacher());
  }
}

function* validateClassname(action) {
  const classroomName = action.classroomName.trim().replace(/  +/g, ' ');

  // Also need current orgId
  const orgId = yield select(state =>
    isHubMode() ? state.identity.currentOrganisationId : state.organisationPage.orgId
  );

  // Only start validating once the input's length is greater than zero
  if (classroomName.length > 0) {
    const response = yield checkClassNameApi(orgId, classroomName);
    const failed = !response || !response.data || response.data.exists === null;
    const exists = response && response.data && response.data.exists;
    const specialCharacterError = response?.message?.data?.name === 'Name cannot contain invalid characters.';
    const invalidClassName =
      response?.data?.name === 'Name cannot contain invalid characters.' || typeof response.data === 'undefined';

    // If exists is null then the second parameter to this action indicates an API error
    yield put(setClassroomNameValidated({ exists, apiError: failed, invalidClassName, specialCharacterError }));
  }
}

function* validateClassnameOnBlur() {
  const classroomNameValue = yield select(state => state.classroomCreate.classroomNameValue);

  if (!classroomNameValue) {
    const invalidClassName = true;
    const emptyInput = true;

    yield put(setClassroomNameOnBlurValidation({ invalidClassName, emptyInput }));
  }
}

function* createClass() {
  const { orgId, creatingTeacher, name, teachers, students, managedUsers, limit } = yield select(state => ({
    // Context IDs:
    orgId: isHubMode() ? state.identity.currentOrganisationId : state.organisationPage.orgId,
    creatingTeacher: state.identity.userId,

    // Details of class to create:
    name: state.classroomCreate.classroomNameValue.trim().replace(/\s+/g, ' '),
    teachers: state.classroomCreate.selectedTeacherIds,
    [state.organisations.data[state.organisationPage.orgId] &&
    state.organisations.data[state.organisationPage.orgId].role === 'PRIMARY_SCHOOL'
      ? 'managedUsers'
      : 'students']: state.classroomCreate.selectedStudentIds,
    ...(state.classroomCreate.enableStudentLimit ? { limit: state.classroomCreate.studentLimit } : {})
  }));

  // Assemble request body to send to API:
  const payload = {
    creatingTeacher,
    name,
    teachers,
    students,
    managedUsers,
    limit,
    platformCode: getCurrentPlatform() || ''
  };

  console.log(`[createClassroom Saga] Submitting request to create class: ${JSON.stringify(payload)}.`);
  return yield createClassroomApi(orgId, payload);
}

export default function* classroomCreate() {
  console.log('[classroomCreate Saga] Beginning');

  // Preselect the current user's Id
  yield preselectThisUser();

  // Spin up a listener for classname value inputs
  yield debounce(1500, SET_CLASSROOM_NAME, validateClassname);
  yield takeLatest(CLASSROOM_NAME_ON_BLUR, validateClassnameOnBlur);

  while (true) {
    console.log('[classroomCreate Saga] Waiting for user to submit form');
    yield take(CREATE_CLASS);

    console.log('[classroomCreate Saga] Submitting Create Class request');
    const resultOfCreate = yield createClass();

    console.log('[classroomCreate Saga] Create Class request complete, showing confirmation page', resultOfCreate);

    let failedUserIds;

    if (!resultOfCreate || resultOfCreate.error || resultOfCreate.status !== 'success') {
      // append any failed ids returned by the lambda
      if (resultOfCreate.failedIds) {
        failedUserIds = [].concat(resultOfCreate.failedIds);
      }
    } else {
      // add failed teacher and student ids to an array
      const failedTeachers = Object.keys(resultOfCreate.data.teacherStatuses || []).filter(key =>
        byFail(key, resultOfCreate.data.teacherStatuses)
      );

      const failedStudents = Object.keys(resultOfCreate.data.studentStatuses || []).filter(key =>
        byFail(key, resultOfCreate.data.studentStatuses)
      );

      failedUserIds = [].concat(failedTeachers, failedStudents);
    }

    if (resultOfCreate.status === 'success' && resultOfCreate.data && resultOfCreate.data.groupId) {
      yield pollOrgClassesAdded([resultOfCreate.data.groupId]);
    }

    yield put(
      showConfirmation(
        resultOfCreate.data && resultOfCreate.data.groupId ? resultOfCreate.data.groupId : null,
        failedUserIds
      )
    );

    yield take(CLOSE_FORM);
    console.log('[classroomCreate Saga] Create Class form closed, resetting state and saga');

    // Preselect the current user's Id
    yield preselectThisUser();
  }
}
