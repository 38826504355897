import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PanelNavigationLink from '../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer.js';
import content from '../../../../utils/cmsContent.js';

function OrganisationEditConfirmation({ closePanel, userEmail, failed }) {
  const history = useHistory();
  const CMS = content.orgArchivePage || {};

  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink
                isLhs={false}
                text={CMS.button_close_text}
                action={() => {
                  history.push('/mySchool/organisations');
                  closePanel(true);
                }}
              />
            </div>
            <PopoutPanelIconHeading
              title={failed ? CMS.afterArchive_error_heading_text : CMS.afterArchive_success_heading_text}
              subtitle={failed ? CMS.afterArchive_error_subheading_text : null}
              type={failed ? iconHeadingTypes.ERROR : 'PROCESSING'}
            />
          </div>
        }
        footerContent={
          <PopoutActionFooter
            primaryButtonAction={() => {
              // Trigger the search to refresh the list
              history.push('/mySchool/organisations');
              closePanel(true);
            }}
            primaryButtonText={CMS.afterArchive_button_done_text}
          />
        }
      >
        {!failed && (
          <div>
            <div className="gin2 text-center">
              <p id="user-email" className="gin-top4">
                {CMS.afterArchive_success_email_intro_text}
                <em className="block pad2 gin2 color-borders-bg">{userEmail}</em>
                {CMS.afterArchive_success_email_outro_text}
              </p>
            </div>
          </div>
        )}
      </ScrollContainer>
    </form>
  );
}

OrganisationEditConfirmation.propTypes = {
  closePanel: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  failed: PropTypes.bool.isRequired
};

export default connect(state => ({
  userEmail: state.identity.email,
  failed: state.forms.orgArchive.failed
}))(OrganisationEditConfirmation);
