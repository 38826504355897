import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../globals/colors.js';
// Styles
import styles from './HubMissingLicenseErrorModal.scss';
// Components
import Button, { buttonTypes } from '../../../components/Button/Button.js';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail.js';
import HubEmptyState from '../../../components/HubEmptyState/HubEmptyState.js';
import HubModalLayout from '../../../components/HubModalLayout/HubModalLayout.js';

function HubMissingLicenseErrorModal({ hubContent, closeModal, redeemCallback, isOpen }) {
  const closeErrorModal = () => {
    closeModal();
  };

  const redeemAction = () => {
    redeemCallback();
  };

  return (
    <div className={`${styles.outerModal} ${isOpen ? styles.open : ''}`}>
      <div className={styles.innerModal}>
        <HubModalLayout isOpen={isOpen} id="missingLicenseErrorModalId" closeAction={closeErrorModal}>
          <div className={styles.modalButtonHeader}>
            <Button
              text={hubContent.button_close_text}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              onClick={e => {
                e.stopPropagation();
                closeModal();
              }}
            />
          </div>
          <div className={styles.modalContent}>
            <HubEmptyState
              glyphIcon={GLYPHS.ICON_SEARCHING}
              glyphSize={thumbnailSizes.VERY_LARGE}
              glyphBackgroundColour={colors.COLORS}
              title={hubContent.missing_license_modal_title}
              subTitle={hubContent.missing_license_modal_subtitle}
            />
            <a
              target="_blank"
              rel="noreferrer"
              href={hubContent.missing_license_modal_support_link}
              className={styles.externalLink}
            >
              {hubContent.missing_license_modal_learn_more}
              <SVGIcon glyph={GLYPHS.ICON_LINK} />
            </a>
          </div>
          <div className={styles.modalButtonBottom}>
            <Button
              text={hubContent.cancel}
              type={buttonTypes.CANCEL}
              onClick={() => closeErrorModal()}
              className={styles.cancelButton}
            />
            <Button text={hubContent.enter_a_code_text} type={buttonTypes.PRIMARY} onClick={() => redeemAction()} />
          </div>
        </HubModalLayout>
      </div>
    </div>
  );
}

HubMissingLicenseErrorModal.propTypes = {
  hubContent: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  redeemCallback: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

export default HubMissingLicenseErrorModal;
