import qs from 'query-string';
import signedFetch from '../apiCalls/util/signedFetch';
import { getProductMetadata } from '../../../utils/platform';

export const getSchoolReportUrl = (orgId, locationId, subject) =>
  // @ts-ignore
  `${__API_BASE__}/gradebook/org/${orgId}/school-report?${qs.stringify({
    locationId,
    subject
  })}`;

function* getSchoolDialReport(orgId, locationId) {
  const subject = getProductMetadata('orb', 'subject');

  return yield signedFetch(`schoolReport`, getSchoolReportUrl(orgId, locationId, subject), 'GET');
}

export default getSchoolDialReport;
