import * as t from '../actionTypes';
import { StatusEnum } from '../sagas/appFunctions/authorisedFunctions/csvUserUpload/csvUserUpload';
// Used for importing Regex
import appSettings from '../../globals/appSettings.js';

const initialState = {
  status: '',
  fileStatus: '',
  results: '',
  email: '',
  isValidEmail: false,
  isFormValid: false
};

function csvUserUploadServices(state = initialState, { type, payload }) {
  switch (type) {
    case t.CSVUSER_DATA_UPLOAD_INIT:
      return {
        ...initialState
      };
    case t.CSVUSER_INPUT_UPDATE: {
      const email = payload.email;
      const REGEX = appSettings.EMAIL_REGEX;
      const isValidEmail = email && REGEX.test(email);

      return {
        ...state,
        email,
        isValidEmail,
        isFormValid: isValidEmail && state.fileStatus === 'SUCCESS'
      };
    }
    case t.CSVUSER_DATA_UPLOAD_START_UPLOAD:
      return {
        ...state,
        status: StatusEnum.InProgress
      };
    case t.CSVUSER_DATA_UPLOAD_SUCCESS:
      return {
        ...state,
        status: payload.status
      };
    case t.CSVUSER_DATA_UPLOAD_FAILURE:
      return {
        ...state,
        status: payload.status
      };
    case t.CSVUSER_DATA_UPLOAD_PARSE_FILE_REQUEST:
      return {
        ...state,
        fileStatus: payload.status,
        results: '',
        fileName: '',
        isFormValid: false
      };
    case t.CSVUSER_DATA_UPLOAD_PARSE_FILE_COMPLETED:
      return {
        ...state,
        fileStatus: payload.status,
        results: payload.fileData,
        fileName: payload.fileName,
        isFormValid: state.isValidEmail
      };
    case t.CSVUSER_DATA_UPLOAD_PARSE_FILE_FAILURE:
      return {
        ...state,
        fileStatus: payload.status,
        results: '',
        isFormValid: false
      };
    default:
      return state;
  }
}

export default csvUserUploadServices;
