import { userRoleFriendlyNames } from '../globals/userRoles.js';
import { GLYPHS } from '../components/SVGIcon/SVGIcon.js';

export const USER_ARCHIVED_STATUS = 'ARCHIVED';

export const CLASS_ARCHIVED_STATUS = 'ARCHIVED';

export const userStatusIcon = (inviteStatus, archivedStatus) => {
  if (archivedStatus === 'ARCHIVED') {
    return GLYPHS.ICON_ARCHIVED;
  }
  if (inviteStatus === 'PENDING') {
    return GLYPHS.ICON_INVITED;
  }
  if (inviteStatus === 'NONE' || inviteStatus === 'ACCEPTED' || inviteStatus === 'APPROVED') {
    return GLYPHS.ICON_CHECK_CIRCLE;
  }
  return GLYPHS.ICON_INFORMATION_CIRCLE;
};

export const userStatusText = (inviteStatus, archivedStatus, userRole) => {
  const role = userRoleFriendlyNames(userRole) || 'user';
  if (archivedStatus === 'ARCHIVED') {
    return `Archived ${role}`;
  }
  if (inviteStatus === 'PENDING') {
    return `Invited ${role}`;
  }
  if (inviteStatus === 'NONE' || inviteStatus === 'ACCEPTED' || inviteStatus === 'APPROVED') {
    return `Active ${role}`;
  }
  return inviteStatus || 'No status';
};

export const selectArchiveStatus = (person, classId) => {
  /* Org invite PENDING status superseed class invite status but not class archive */
  const orgArchiveStatus = person.orgArchiveStatus;
  const orgInviteStatus = person.orgInviteStatus;
  const classArchiveStatus = person[`classArchiveStatus-${classId}`];
  const archiveStatus = {
    text: '',
    icon: ''
  };

  if (classId && orgInviteStatus === 'PENDING') {
    // if the user has been invited and assigned to a class then they must show as invited
    // in that class unless they have already been archived from that class
    archiveStatus.icon = userStatusIcon(orgInviteStatus, classArchiveStatus);
    archiveStatus.text = userStatusText(orgInviteStatus, classArchiveStatus, person.roleName);
  } else if (
    // if we're looking for class status and a user is active in org, then return the class invite/archive status
    classId &&
    (orgInviteStatus === 'NONE' || orgInviteStatus === 'ACCEPTED' || orgInviteStatus === 'APPROVED')
  ) {
    archiveStatus.icon = userStatusIcon('NONE', classArchiveStatus);
    archiveStatus.text = userStatusText('NONE', classArchiveStatus, person.roleName);
  } else {
    archiveStatus.icon = userStatusIcon(orgInviteStatus, orgArchiveStatus);
    archiveStatus.text = userStatusText(orgInviteStatus, orgArchiveStatus, person.roleName);
  }
  return archiveStatus;
};
