import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import updateRecentlyAccessed from './updateRecentlyAccessed';
import getRecentlyAccessed from './getRecentlyAccessed';

function* root() {
  yield takeLatest(t.GET_RECENTLY_ACCESSED_REQUEST, ({ payload }) => getRecentlyAccessed(payload));
  yield takeLatest(t.UPDATE_RECENTLY_ACCESSED_REQUEST, ({ payload }) => updateRecentlyAccessed(payload));
}

export default root;
