import * as t from '../actionTypes';

export const getProductLocksRequest = payload => ({
  type: t.GET_PRODUCT_LOCKS_REQUEST,
  payload
});

export const getProductLocksSuccess = payload => ({
  type: t.GET_PRODUCT_LOCKS_SUCCESS,
  payload
});

export const getProductLocksError = () => ({
  type: t.GET_PRODUCT_LOCKS_ERROR
});

export const updateProductLocksRequest = (payload, params) => ({
  type: t.UPDATE_PRODUCT_LOCKS_REQUEST,
  payload,
  params
});

export const updateProductLocksSuccess = () => ({
  type: t.UPDATE_PRODUCT_LOCKS_SUCCESS
});

export const updateProductLocksError = payload => ({
  type: t.UPDATE_PRODUCT_LOCKS_ERROR,
  payload
});

export const productLocksResetState = payload => ({
  type: t.PRODUCT_LOCKS_RESET_STATE,
  payload
});
