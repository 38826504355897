import { pick } from 'lodash';
import * as t from '../../actionTypes';
import UserRoles from '../../../globals/userRoles';
import { sortUsers } from '../../../../sharedNodeBrowser/sortUsers.js';
import { featureIsEnabled } from '../../../globals/envSettings';

export const INITIALISE_INSTANCE = 'search/INITIALISE_INSTANCE';
export const WARM_UP_SEARCH = 'search/WARM_UP_SEARCH';

export const SET_SEARCH_BY = 'search/SET_SEARCH_BY';
export const SET_TERM = 'search/SET_TERM';
export const SET_SORT = 'search/SET_SORT';
export const SET_PAGE = 'search/SET_PAGE';
export const SET_FILTER = 'search/SET_FILTER';
export const SET_REVEAL = 'search/SET_REVEAL';
export const SET_PAGE_ORG_STUDENT = 'search/SET_PAGE_ORG_STUDENT';
export const SET_PAGE_ORG_CLASS = 'search/SET_PAGE_ORG_CLASS';
export const SET_SORT_ORG_STUDENT = 'search/SET_SORT_ORG_STUDENT';
export const SET_SORT_ORG_CLASS = 'search/SET_SORT_ORG_CLASS';
export const SET_FILTER_ORG_STUDENT = 'search/SET_FILTER_ORG_STUDENT';
export const SET_FILTER_ORG_CLASS = 'search/SET_FILTER_ORG_CLASS';
export const SET_FILTERS_ORG_CLASS = 'search/SET_FILTERS_ORG_CLASS';
export const TRIGGER_SEARCH = 'search/TRIGGER_SEARCH';
export const TRIGGER_SEARCH_ORG_STUDENT = 'search/TRIGGER_SEARCH_ORG_STUDENT';

const DEFAULT_PAGE_SIZE = 10;
const initialState = {
  instance: 'oupOrgs',
  defaultState: true
};
// This object is used when initialising a new search instance
const initialInstanceState = {
  defaultSearch: true,
  // Query values
  term: '',
  sort: '',
  page: 1,
  size: DEFAULT_PAGE_SIZE,
  filters: {},

  // Timestamp of most recent search
  timestamp: null,

  // Loading/error state
  loading: true,
  error: false,

  // Result values
  ids: [],
  paginatedUserList: [], // used in fornt end pagination in orgStudentTab
  data: {},
  users: [], // used in fornt end pagination in orgStudentTab
  classIds: [], // used in front end pagination for orgClassTab
  currentClassList: [], // used in front end pagination for orgclassTab
  currentUsersList: [], // used in fornt end pagination in orgStudentTab
  paginatedClassList: {},
  filterOptions: [],
  totalResults: 0
};

const applyYearGroupFilter = (term, yearGroupOptions) => {
  const yearGroup = (Object.values(yearGroupOptions).find(({ name }) => term.match(new RegExp(`^${name}$`, 'i'))) || {})
    .value;
  return { yearGroup };
};

// filter user list for orgStudentTab
const filterUsers = (users, filters) => {
  const filterParam = [];
  if (filters.active) {
    filterParam.push('ACTIVE');
  }
  if (filters.archived) {
    filterParam.push('ARCHIVED');
  }
  if (filters.invited) {
    filterParam.push('PENDING');
  }
  if (!filters.invited) {
    filterParam.push('ACCEPTED');
  }
  if (filters.locked) {
    filterParam.push('LOCKED');
  }

  // all options in the filter unselected
  if (!filters.invited && !filters.active && !filters.archived && !filters.locked) {
    filterParam.push('PENDING');
    filterParam.push('ACTIVE');
    filterParam.push('ARCHIVED');
    filterParam.push('LOCKED');
  }

  const filteredIds = [];
  Object.keys(users).forEach(key => {
    const user = users[key];
    const isManagedUser = user.orgInviteStatus === 'NONE'; // check managed user
    if (isManagedUser) {
      let id;
      // for managedUsers
      if (featureIsEnabled('lock-account')) {
        if (filterParam.includes('LOCKED')) {
          id = filterParam.includes(user.orgArchiveStatus) || user.isLocked ? key : null;
          filteredIds.push(id);
        } else {
          id = filterParam.includes(user.orgArchiveStatus) && !user.isLocked ? key : null;
          filteredIds.push(id);
        }
      } else {
        id = filterParam.includes(user.orgArchiveStatus) ? key : null;
        filteredIds.push(id);
      }
    } else if (
      (filterParam.includes('ACTIVE') || filterParam.includes('ARCHIVED')) &&
      !filterParam.includes('PENDING')
    ) {
      const id = filterParam.includes(user.orgArchiveStatus) && filterParam.includes(user.orgInviteStatus) ? key : null;
      filteredIds.push(id);
    } else if (
      !filterParam.includes('ACTIVE') &&
      !filterParam.includes('ARCHIVED') &&
      filterParam.includes('PENDING')
    ) {
      const id = filterParam.includes(user.orgInviteStatus) ? key : null;
      filteredIds.push(id);
    } else if (
      (filterParam.includes('ACTIVE') || filterParam.includes('ARCHIVED')) &&
      filterParam.includes('PENDING')
    ) {
      const id = filterParam.includes(user.orgInviteStatus) || filterParam.includes(user.orgArchiveStatus) ? key : null;
      filteredIds.push(id);
    } else {
      filteredIds.push(key);
    }
  });
  const filteredUsers = pick(users, filteredIds);
  return filteredUsers;
};

// filter user list for orgclassTab
const filterClass = (classIds, filters) => {
  const filterParam = [];
  if (filters.active) {
    filterParam.push('ACTIVE');
  }
  if (filters.archived) {
    filterParam.push('ARCHIVED');
  }

  const filteredIds = [];
  Object.keys(classIds).forEach(key => {
    if (filterParam.includes('ACTIVE')) {
      if (!classIds[key].archived) filteredIds.push(key);
    } else if (filterParam.includes('ARCHIVED')) {
      if (classIds[key].archived) filteredIds.push(key);
    } else {
      filteredIds.push(key);
    }
  });

  if (filterParam.includes('ACTIVE') && filterParam.includes('ARCHIVED')) {
    Object.keys(classIds).forEach(key => {
      filteredIds.push(key);
    });
  }
  const filteredClasses = pick(classIds, filteredIds);
  return filteredClasses;
};

// update user current list for orgStudent
const updateCurrentUserList = (users, currentUsersList, valueName, value, instance, filters) => {
  if (valueName === 'filters' && instance === 'orgClasses') {
    return filterClass(users, value);
  }
  if (valueName === 'filters' && (instance === 'orgStudents' || instance === 'orgStaff')) {
    return filterUsers(users, value);
  }
  if (valueName === 'sort') {
    return sortUsers(currentUsersList, value, filters, true);
  }
  if (valueName === 'page') {
    return currentUsersList;
  }
  return users;
};

// Update class current list for orgclasses
const updateCurrentClassList = (classIds, currentClassList, valueName, value, instance, filters) => {
  if (
    valueName === 'filters' &&
    (instance === 'orgClasses' || instance === 'profileClasses' || instance === 'userClasses')
  ) {
    return filterClass(classIds, value);
  }
  if (valueName === 'sort') {
    return sortUsers(currentClassList, value, filters, true);
  }
  if (valueName === 'page') {
    return currentClassList;
  }
  return classIds;
};

// sorting ids based on page size to support pagination
const getPaginatedUserList = (userList, page = 1, pageSize = DEFAULT_PAGE_SIZE) => {
  const start = (page - 1) * pageSize;
  const keys = Object.keys(userList);
  const paginatedIds = keys.slice(start, start + pageSize);
  const users = pick(userList, paginatedIds);
  return users;
};

// pagination for class

const getPaginatedClassList = (classList, page = 1, pageSize = DEFAULT_PAGE_SIZE) => {
  const start = (page - 1) * pageSize;
  const keys = Object.keys(classList);
  const paginatedIds = keys.slice(start, start + pageSize);
  const classIds = pick(classList, paginatedIds);
  return classIds;
};

// update instace value for orgStudentTab
const updateInstanceValueOrgStudent = (state, instance, valueName, value, isLoading = true) => {
  const currentUsers = updateCurrentUserList(
    state[instance].users,
    state[instance].currentUsersList,
    valueName,
    value,
    instance,
    state[instance].filters
  );
  const currentUsersListLength = Object.keys(currentUsers).length;
  const startPage = valueName === 'page' ? value : 1;
  const paginatedUsers = getPaginatedUserList(currentUsers, startPage, DEFAULT_PAGE_SIZE);
  return {
    ...state,
    [instance]: {
      ...state[instance],
      defaultSearch: false,
      loading: isLoading,
      error: false,
      [valueName]: value,
      totalResults: currentUsersListLength,
      page: startPage,
      currentUsersList: currentUsers,
      paginatedUserList: paginatedUsers
    }
  };
};

// update instance value for orgclasstab
const updateInstanceValueOrgClass = (state, instance, valueName, value, isLoading = true) => {
  const currentClass = updateCurrentClassList(
    state[instance].classIds,
    state[instance].currentClassList,
    valueName,
    value,
    instance,
    state[instance].filters
  );
  const currentClassListLength = Object.keys(currentClass).length;
  const startPage = valueName === 'page' ? value : 1;
  const paginatedClass = getPaginatedClassList(currentClass, startPage, DEFAULT_PAGE_SIZE);
  return {
    ...state,
    [instance]: {
      ...state[instance],
      defaultSearch: false,
      loading: isLoading,
      error: false,
      [valueName]: value,
      totalResults: currentClassListLength,
      page: startPage,
      currentClassList: currentClass,
      paginatedClassList: paginatedClass
    }
  };
};

const updateInstanceValue = (state, instance, valueName, value) => ({
  ...state,
  [instance]: {
    ...state[instance],
    defaultSearch: false,
    loading: true,
    error: false,
    [valueName]: value
  }
});

const updateTerm = (state, instance, value, yearGroupOptions) => {
  const { filters = {}, ...currentState } = state[instance] || {};
  const shouldApplyYearGroupFilter =
    yearGroupOptions && (filters.roles || []).some(role => [UserRoles.LEARNER, UserRoles.MANAGED_USER].includes(role));

  return {
    ...state,
    [instance]: {
      ...currentState,
      defaultSearch: false,
      term: value,
      ...(currentState.searchAsYouType
        ? {
            loading: true,
            error: false,
            page: 1
          }
        : {}),
      filters: {
        ...filters,
        ...(shouldApplyYearGroupFilter ? applyYearGroupFilter(value, yearGroupOptions) : { yearGroup: undefined })
      }
    }
  };
};

const filterResults = (state, instance, ids, totalResults, data, users) => {
  const userlist = filterUsers(users, state[instance].filters);
  const paginatedUsers = getPaginatedUserList(userlist);
  const resultCount = Object.keys(userlist).length;
  const trimmedSearchTerm = state[instance]?.term.replace(/^\s+|\s+$/gm, '');
  return {
    ...state,
    instance,
    [instance]: {
      ...state[instance],
      loading: false,
      ids,
      totalResults: resultCount,
      data,
      users,
      currentUsersList: userlist,
      paginatedUserList: paginatedUsers,
      initialHaveSingleOrg: trimmedSearchTerm ? state[instance].initialHaveSingleOrg : totalResults === 1
    }
  };
};

const filterclassResults = (state, instance, ids, totalResults, data, classIds) => {
  const classlist = filterClass(classIds, state[instance].filters);
  const paginatedClass = getPaginatedUserList(classlist);
  const resultCount = Object.keys(classlist).length;
  return {
    ...state,
    [instance]: {
      ...state[instance],
      loading: false,
      ids,
      totalResults: resultCount,
      data,
      classIds,
      currentClassList: classlist,
      paginatedClassList: paginatedClass
    }
  };
};

const updateClassResults = (state, { instance, classIds }) => {
  const mergeClassIds = { ...classIds, ...state[instance].classIds };
  const classlist = filterClass(mergeClassIds, state[instance].filters);
  const paginatedClass = getPaginatedUserList(classlist);
  const resultCount = Object.keys(mergeClassIds).length;
  return {
    ...state,
    [instance]: {
      ...state[instance],
      loading: false,
      page: 1,
      totalResults: resultCount,
      classIds: mergeClassIds,
      currentClassList: mergeClassIds,
      paginatedClassList: paginatedClass
    }
  };
};
export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case t.WARM_UP_SEARCH:
    case t.INITIALISE_INSTANCE:
      return {
        ...state,
        defaultState: true,
        [action.instance]: {
          ...initialInstanceState,
          searchAsYouType: action.searchAsYouType,
          filters: action.initialFilters,
          size: action.pageSize,
          sort: action.sort,
          instanceLoadingStatus: action.instanceLoadingStatus,
          clientSideSearch: action.clientSideSearch
        }
      };

    case t.SET_TERM:
      return updateTerm(state, action.instance, action.term, action.yearGroupOptions);
    case t.SET_SORT:
      return updateInstanceValue(state, action.instance, 'sort', action.sort);
    case t.SET_PAGE:
      return updateInstanceValue(state, action.instance, 'page', action.page);
    case t.SET_FILTER:
      return {
        ...state,
        [action.instance]: {
          ...state[action.instance],
          defaultSearch: false,
          loading: true,
          error: false,
          page: 1,
          filters: {
            ...state[action.instance].filters,
            [action.filterName]: action.filterValue
          }
        }
      };
    case t.SET_FILTER_OPTIONS:
      return updateInstanceValue(state, action.instance, 'filterOptions', action.filterValue);
    case t.SET_PAGE_ORG_STUDENT:
      return updateInstanceValueOrgStudent(state, action.instance, 'page', action.page, false);
    case t.SET_PAGE_ORG_CLASS:
      return updateInstanceValueOrgClass(state, action.instance, 'page', action.page, false);
    case t.SET_SORT_ORG_STUDENT:
      return updateInstanceValueOrgStudent(state, action.instance, 'sort', action.sort, false);
    case t.SET_SORT_ORG_CLASS:
      return updateInstanceValueOrgClass(state, action.instance, 'sort', action.sort, false);
    case t.SET_FILTER_ORG_STUDENT:
      return updateInstanceValueOrgStudent(
        state,
        action.instance,
        'filters',
        {
          ...state[action.instance].filters,
          [action.filterName]: action.filterValue
        },
        false
      );
    case t.SET_FILTERS_ORG_CLASS:
      return {
        ...state,
        [action.instance]: {
          ...state[action.instance],
          filters: action.filters
        }
      };
    case t.SET_FILTER_ORG_CLASS:
      return updateInstanceValueOrgClass(
        state,
        action.instance,
        'filters',
        {
          ...state[action.instance].filters,
          [action.filterName]: action.filterValue
        },
        false
      );
    case t.TRIGGER_SEARCH:
      return {
        ...state,
        [action.instance]: {
          ...state[action.instance],
          defaultSearch: false,
          page: 1,
          loading: true,
          error: false
        }
      };
    case t.PREPARE_SEARCH:
      return {
        ...state,
        [action.instance]: {
          ...state[action.instance],
          loading: true
        }
      };
    case t.END_SEARCH:
      return {
        ...state,
        [action.instance]: {
          ...state[action.instance],
          loading: false
        }
      };
    case t.TRIGGER_SEARCH_ORG_STUDENT: // for OrgStudentTab
      return {
        ...state,
        [action.instance]: {
          ...state[action.instance],
          defaultSearch: false,
          page: 1,
          loading: true,
          error: false,
          filters: {
            ...state[action.instance].filters,
            active: true,
            invited: true
          }
        }
      };

    case t.SET_SEARCH_TIMESTAMP:
      return {
        ...state,
        [action.instance]: {
          ...state[action.instance],
          timestamp: action.timestamp
        }
      };

    case t.SET_REVEAL:
      return {
        ...state,
        [action.instance]: {
          ...state[action.instance],
          reveal: action.reveal
        }
      };

    case t.SET_RESULTS:
      return {
        ...state,
        instance: action.instance,
        [action.instance]: {
          ...state[action.instance],
          loading: false,
          ids: action.ids,
          totalResults: action.totalResults,
          data: action.data
        }
      };

    case t.SET_RESULTS_ORG_STUDENT:
      return filterResults(state, action.instance, action.ids, action.totalResults, action.data, action.users);

    case t.SET_RESULTS_PLACEMENT_TEST_STUDENTS:
      return filterResults(state, action.instance, action.ids, action.totalResults, action.data, action.users);

    case t.SET_RESULTS_CLASS:
      return filterclassResults(
        state,
        [action.instance],
        action.ids,
        action.totalResults,
        action.data,
        action.classIds
      );
    case t.ADD_RESULTS_ORG_CLASS:
      return updateClassResults(state, action.instanceClassData);

    case t.SET_ERROR:
      return {
        ...state,
        [action.instance]: {
          ...state[action.instance],
          loading: false,
          error: true
        }
      };
    case SET_SEARCH_BY: {
      return {
        ...state,
        instance: action.instance,
        defaultState: false
      };
    }
    default:
      return state;
  }
}

export const initialiseInstance = (
  instance,
  searchAsYouType,
  initialFilters,
  pageSize = DEFAULT_PAGE_SIZE,
  sort,
  preventSearch,
  clientSideSearch
) => ({
  type: t.INITIALISE_INSTANCE,
  instance,
  searchAsYouType,
  initialFilters,
  pageSize,
  sort,
  preventSearch,
  clientSideSearch
});

export const warmUpSearch = (instance, searchAsYouType, initialFilters, instanceLoadingStatus = false) => ({
  type: t.WARM_UP_SEARCH,
  instance,
  searchAsYouType,
  initialFilters,
  instanceLoadingStatus
});

export const triggerSearch = instance => ({
  type: t.TRIGGER_SEARCH,
  instance
});

export const prepareForSearch = instance => ({
  type: t.PREPARE_SEARCH,
  instance
});

export const endSearch = instance => ({
  type: t.END_SEARCH,
  instance
});

export const triggerSearchOrgStudent = instance => ({
  type: t.TRIGGER_SEARCH_ORG_STUDENT,
  instance
});

export const setTerm = (instance, term, yearGroupOptions) => ({ type: t.SET_TERM, instance, term, yearGroupOptions });
export const setSort = (instance, sort) => ({ type: t.SET_SORT, instance, sort });
export const setPage = (instance, page) => ({ type: t.SET_PAGE, instance, page });
export const setPageOrgStudent = (instance, page) => ({ type: t.SET_PAGE_ORG_STUDENT, instance, page });
export const setPageClass = (instance, page) => ({ type: t.SET_PAGE_ORG_CLASS, instance, page });
export const setSortOrgStudent = (instance, sort) => ({ type: t.SET_SORT_ORG_STUDENT, instance, sort });
export const setSortClass = (instance, sort) => ({ type: t.SET_SORT_ORG_CLASS, instance, sort });
export const setReveal = (instance, reveal) => ({ type: t.SET_REVEAL, instance, reveal });
export const setFilter = (instance, filterName, filterValue) => ({
  type: t.SET_FILTER,
  instance,
  filterName,
  filterValue
});
export const setFilterOptions = (instance, filterValue) => ({
  type: t.SET_FILTER_OPTIONS,
  instance,
  filterValue
});
export const setFilterOrgStudent = (instance, filterName, filterValue) => ({
  type: t.SET_FILTER_ORG_STUDENT,
  instance,
  filterName,
  filterValue
});

export const setFiltersClass = (instance, filters) => ({
  type: t.SET_FILTERS_ORG_CLASS,
  instance,
  filters
});

export const setFilterClass = (instance, filterName, filterValue) => ({
  type: t.SET_FILTER_ORG_CLASS,
  instance,
  filterName,
  filterValue
});

export const setSearchTimestamp = (instance, timestamp) => ({
  type: t.SET_SEARCH_TIMESTAMP,
  instance,
  timestamp
});

export const setResults = (instance, ids, totalResults, data) => ({
  type: t.SET_RESULTS,
  instance,
  ids,
  totalResults,
  data
});

export const setResultsOrgStudent = (instance, ids, totalResults, data, users) => ({
  type: t.SET_RESULTS_ORG_STUDENT,
  instance,
  ids,
  totalResults,
  data,
  users
});

export const setResultsPlacementTestStudents = (instance, ids, totalResults, data, users) => ({
  type: t.SET_RESULTS_PLACEMENT_TEST_STUDENTS,
  instance,
  ids,
  totalResults,
  data,
  users
});

export const setResultsClass = (instance, ids, totalResults, data, classIds) => ({
  type: t.SET_RESULTS_CLASS,
  instance,
  ids,
  totalResults,
  data,
  classIds
});
export const addResultsOrgClass = instanceClassData => ({
  type: t.ADD_RESULTS_ORG_CLASS,
  instanceClassData
});

export const setError = instance => ({
  type: t.SET_ERROR,
  instance
});

export const setSearchBy = searchBy => ({
  type: SET_SEARCH_BY,
  instance: searchBy
});
