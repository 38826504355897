/** Support Articles reducer
 *  This reducer will store the information about support articles.
 *  This should be the single source of their information,  all other
 *  references to supportArticles should be by ID only and pull their data from here.
 */

// TODO : Make sure this is removed in prod builds
// import supportArticlesData from '../../../testData/fakeSupportArticles.js';

const STORE_ARTICLES = 'support/STORE_ARTICLES';

const initialState = {
  data: null
};

// ACTIONS COMMENTED OUT UNTIL NEEDED...

export default function supportArticles(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_ARTICLES: {
      console.log(STORE_ARTICLES, action);
      return {
        ...initialState,
        data: { ...action.articles }
      };
    }
    default:
      return state;
  }
}

export const storeArticles = articles => ({
  type: STORE_ARTICLES,
  articles
});
