import { getCurrentPlatform } from '../../../utils/platform';
import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const reCAPTCHAExampleUrl = `${__API_BASE__}/user/recaptcha-example`;

function reCAPTCHAExample(token) {
  const reCAPTCHATokenSource = getCurrentPlatform();
  const body = {
    reCAPTCHAToken: token,
    reCAPTCHATokenSource
  };
  return signedFetch('reCAPTCHAExample', `${reCAPTCHAExampleUrl}`, 'POST', body);
}

export default reCAPTCHAExample;
