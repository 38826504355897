// Page load and clear actions
export const CLEAR_FORM = 'removeTeachersFromClassroom/CLEAR_FORM';

const SET_TEACHER_LIST = 'removeTeachersFromClassroom/SET_TEACHER_LIST';

// Nav actions
export const SUBMIT_FORM = 'removeTeachersFromClassroom/SUBMIT_FORM';
const SUBMISSION_COMPLETE = 'removeTeachersFromClassroom/SUBMISSION_COMPLETE';

export const formStates = {
  REVIEW_SELECTIONS: 'REVIEW_SELECTIONS',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION' // Success|Fail feedback after SUBMITTING
};

const initialState = {
  formState: formStates.REVIEW_SELECTIONS,

  // List submitted for removal
  submittedIds: [],
  // List of users who were not removed from the class after submission
  failedIds: [],

  // General error catch all
  requestFailed: null
};

export default function removeTeachersFromClassroom(state = initialState, action = {}) {
  switch (action.type) {
    // Page load and clear actions
    case CLEAR_FORM:
      return {
        ...initialState
      };
    case SET_TEACHER_LIST: {
      return {
        ...state,
        submittedIds: action.submittedIds
      };
    }
    // Nav actions
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SUBMISSION_COMPLETE:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        requestFailed: action.requestFailed,
        failedIds: action.failedIds
      };
    default:
      return state;
  }
}

// Page load and clear actions
export const clearForm = () => ({
  type: CLEAR_FORM
});

// Nav actions
export const submitForm = () => ({
  type: SUBMIT_FORM
});
export const submissionComplete = (requestFailed, failedIds) => ({
  type: SUBMISSION_COMPLETE,
  requestFailed,
  failedIds
});

export const setTeachersToRemove = userIds => ({
  type: SET_TEACHER_LIST,
  submittedIds: Array.isArray(userIds) ? userIds : [userIds]
});
