let isContentLoaded = false;
const content = {};

// The loadContent.saga will pass content to this function as soon as it's loaded:
function setCmsContent(contentFromCms) {
  if (contentFromCms) {
    Object.assign(content, contentFromCms);
    isContentLoaded = true;
  }
  return isContentLoaded;
}

export { setCmsContent };

export default content;
