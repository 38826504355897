export const INITIALISE = 'downloadStudentData/INITIALISE';
export const START_DOWNLOAD = 'downloadStudentData/START_DOWNLOAD';
export const SHOW_ERROR_MESSAGE = 'downloadStudentData/SHOW_ERROR_MESSAGE';
export const SHOW_DATA = 'downloadStudentData/SHOW_DATA';

export const initialState = {
  loading: false,
  errorOccurred: false,
  errorMessage: null,
  studentData: null
};

export default function downloadStudentData(state = initialState, action) {
  switch (action.type) {
    // Initialise action
    case INITIALISE:
      return {
        ...state
      };
    case START_DOWNLOAD:
      return {
        ...state,
        loading: true,
        errorOccurred: false
      };
    case SHOW_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: false,
        errorOccurred: true
      };
    case SHOW_DATA:
      return {
        ...state,
        errorMessage: null,
        studentData: action.studentData,
        loading: false,
        errorOccurred: false
      };
    default:
      return state;
  }
}

export const startDownload = () => ({
  type: START_DOWNLOAD
});

export const showData = studentData => ({
  type: SHOW_DATA,
  studentData
});

export const showError = errorMessage => ({
  type: SHOW_ERROR_MESSAGE,
  errorMessage
});
