import React from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './ResourceModal.scss';
import colors from '../../globals/colors.js';
// Components
import ResourceModalFooter from './ResourceModalFooter';
import ResourceModalHeader from './ResourceModalHeader';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import HubEmptyState from '../HubEmptyState/HubEmptyState';
import HubModalLayout from '../HubModalLayout/HubModalLayout';
import Button, { buttonTypes } from '../Button/Button.js';
// Constants
import { ResourceConstants } from '../../globals/hubConstants';
import breakpoints from '../../globals/breakpoints';

function ResourceModal({
  closeModal,
  resource,
  downloadResource = null,
  hubContent,
  resourceId,
  downloadIsLoading,
  breakpoint,
  url = 'about:blank'
}) {
  const { PREVIEWABLE_FORMATS } = ResourceConstants;
  const canBePreviewed = PREVIEWABLE_FORMATS.includes((resource.format || '').toLowerCase());
  const renderNonPreviewableContent = () => (
    <div className={styles.resourceModalNonPreviewable}>
      <HubEmptyState
        glyphIcon={GLYPHS.ICON_DOWNLOAD}
        glyphSize={breakpoint === breakpoints.XXS ? thumbnailSizes.MEDIUM : thumbnailSizes.HEADING}
        glyphBackgroundColour={colors.HUB_BLUE}
        title={hubContent.download_file_to_view_it}
        customClassName="resourceModalEmptyState"
      />
      {downloadResource ? (
        <div className={styles.buttonContainer}>
          <Button
            id={resource.resourceId}
            text={hubContent.button_download_text}
            disabled={downloadIsLoading}
            onClick={() => downloadResource(resource)}
            dataAttribute={resource.title}
            type={buttonTypes.SECONDARY}
          />
        </div>
      ) : null}
    </div>
  );
  const renderPreviewableContent = () => (
    <div className={styles.resourceModalPreviewable}>
      <iframe name="player" title="player" src={url} className={styles.resourceModalIframe} allowFullScreen />
    </div>
  );

  return (
    <div className={`${styles.resourceOuterModal} ${resourceId === resource.resourceId ? styles.open : ''}`}>
      <HubModalLayout
        isOpen={resourceId === resource.resourceId}
        id="resourceModalId"
        closeAction={closeModal}
        ariaLabel={hubContent.preview_resource_modal_aria.replace('{resourceTitle}', resource?.title)}
      >
        {Object.keys(resource).length ? (
          <div>
            <ResourceModalHeader
              resource={resource}
              downloadResource={canBePreviewed && downloadResource}
              downloadIsLoading={downloadIsLoading}
              closeModal={closeModal}
              hubContent={hubContent}
              breakpoint={breakpoint}
              resourceCanBePreviewed={canBePreviewed}
            />
            <div className={styles.resourceModalContent}>
              {canBePreviewed ? renderPreviewableContent() : renderNonPreviewableContent()}
            </div>
            {breakpoint === breakpoints.XXS && canBePreviewed && (
              <ResourceModalFooter
                resource={resource}
                downloadResource={downloadResource}
                hubContent={hubContent}
                downloadIsLoading={downloadIsLoading}
              />
            )}
          </div>
        ) : null}
      </HubModalLayout>
    </div>
  );
}

ResourceModal.propTypes = {
  resource: PropTypes.object.isRequired,
  downloadResource: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  downloadIsLoading: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string,
  url: PropTypes.string
};

export default ResourceModal;
