import { put, select, throttle } from 'redux-saga/effects';
import * as t from '../../../../actionTypes.js';
import checkUsername from '../../../api/checkUsername.js';
import { validateUserNameGlobalComplete } from '../../../../actions/managedUserEdit.js';

/**
 * This function checks if the new username provided
 * it's the same (regardless of casing) as the old one
 * @param {string} oldUsername
 * @param {string} newUsername
 * @returns {boolean}
 */
const itsTheSameUsername = (oldUsername, newUsername) =>
  String(oldUsername)
    .toLowerCase()
    .localeCompare(String(newUsername).toLowerCase()) === 0;

function* validateUserEnrolInput() {
  const userNameWithCustomId = yield select(state => state.managedUserEdit.userNameWithCustomId);
  const oldUsername = yield select(state => state.managedUserEdit.user.username);

  if (userNameWithCustomId) {
    // if the new username it's the same as the old one
    // allow the username to be updated
    if (itsTheSameUsername(oldUsername, userNameWithCustomId)) {
      const exists = false;
      yield put(validateUserNameGlobalComplete(exists));
    } else {
      const { data } = yield checkUsername(userNameWithCustomId);
      yield put(validateUserNameGlobalComplete(data.exists));
    }
  }
}

function* checkUsernameSaga() {
  yield throttle(500, t.MANAGED_USER_VALIDATE_USERNAME_GLOBAL, validateUserEnrolInput);
}

export default checkUsernameSaga;
