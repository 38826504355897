import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import loadCourses from './loadCourses';
import callLTIConsumer from './callLTIConsumer';

function* root() {
  yield takeLatest(t.HUB_GET_COURSES, () => loadCourses());

  yield takeLatest(t.HUB_DOWNLOAD_RESOURCE, () => callLTIConsumer());
}

export default root;
