/* eslint-disable react/no-danger */
import { marked } from 'marked';
import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../language/withLocalizedContent';
import ManagedUserAuthLayout, { Footer } from '../components/ManagedUserAuthLayout/ManagedUserAuthLayout';
import SampleSignInCard from '../components/SampleSignInCard/SampleSignInCard';
import styles from './ManagedUserLoginHelpView.scss';

function ManagedUserLoginHelpView({ onNavigateSignIn, localizedContent: { managedUserLoginHelpView: content } }) {
  return (
    <ManagedUserAuthLayout content={content} hideBackButton>
      <article className={styles.content}>
        <p dangerouslySetInnerHTML={{ __html: marked(content.body_1) }} />
        <SampleSignInCard className={styles.sampleSignInCard} />
        <p dangerouslySetInnerHTML={{ __html: marked(content.body_2) }} />
        <p dangerouslySetInnerHTML={{ __html: marked(content.body_3) }} />
      </article>
      <Footer actionLabel={content.back_to_sign_in_button_text} onActionClick={onNavigateSignIn} />
    </ManagedUserAuthLayout>
  );
}

ManagedUserLoginHelpView.propTypes = {
  onNavigateSignIn: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('managedUserLoginHelpView')(ManagedUserLoginHelpView);
