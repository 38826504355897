import { put, select } from 'redux-saga/effects';

import { setFormResult } from '../../../../reducers/data/forms.reducer.js';
import orgCreateApi from '../../../apiCalls/orgs/orgCreate.api.js';
import { getFieldValue, requestFailed } from '../formUtil.js';

export default function* submitOrgCreate() {
  const payload = yield select(state => ({
    // Details of org to create:
    name: getFieldValue(state, 'orgCreate', 'orgName'),
    role: getFieldValue(state, 'orgCreate', 'orgRole'),
    primaryEmail: getFieldValue(state, 'orgCreate', 'orgEmail'),
    webAddress: getFieldValue(state, 'orgCreate', 'orgWebsite')
  }));

  const apiResult = yield orgCreateApi(payload);

  yield put(setFormResult('orgCreate', 'CONFIRMATION', requestFailed(apiResult), apiResult.data));
}
