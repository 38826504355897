import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import Link from '../../../../components/Link/Link';
import styles from './ResourceFolder.scss';

class ResourceFolder extends PureComponent {
  render() {
    const { title, itemLink } = this.props;
    return (
      <div role="button" tabIndex={0} className={styles.itemFolderContainer}>
        <Link to={itemLink} className={styles.itemFolderLinkContainer} />
        <div className={styles.itemFolderIconContainer}>
          <SVGIcon className={styles.itemFolderIcon} glyph={GLYPHS.ICON_UNLOCKED_FOLDER} />
        </div>
        <div className={title.split(' ').length > 1 ? styles.itemFolderTitleLong : styles.itemFolderTitleShort}>
          {title}
        </div>
      </div>
    );
  }
}

ResourceFolder.propTypes = {
  title: PropTypes.string,
  itemLink: PropTypes.string
};

export default ResourceFolder;
