/* eslint-disable no-nested-ternary */

import classnames from 'classnames';
import { omit, pick, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes } from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import schema from '../../../static/schema/draft-4/bulk-upload-managedusers-request-body.json';
import styles from './ImportUsersTable.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import TextInput from '../TextInput/TextInput';
import Validation from '../Validation/Validation';
import { NAME_MAX_LIMIT, USERNAME_MAX_LIMIT } from '../../globals/validations';
import { defaultYearGroup } from '../../globals/yearGroup';

const isColumnRequired = column => schema.items.required.includes(column);

const columns = {
  firstName: { heading: 'First name', required: isColumnRequired('firstName') },
  lastName: { heading: 'Last name', required: isColumnRequired('lastName') },
  userName: { heading: 'Username', required: isColumnRequired('userName') },
  password: { heading: 'Password', required: isColumnRequired('password') },
  yearGroup: { heading: 'Year group', required: isColumnRequired('yearGroup') },
  class: { heading: 'Class', required: isColumnRequired('class') }
};

class ImportUsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoFocus: {},
      editable: null,
      removeable: null,
      updates: {}
    };
  }

  _autoFocus = (id, column) => {
    this.setState({
      autoFocus: { id, column }
    });
  };

  _getAutoFocus = (id, column) => {
    const { autoFocus } = this.state;
    const { id: stateId, column: stateColumn } = autoFocus;
    return id === stateId && column === stateColumn;
  };

  _edit = user => {
    // default yearGroup to the first value since Dropdown doesn't trigger onChange when selecting
    // the first element when the current value is not within the options
    const yearGroup = this._getInputValue(user, 'yearGroup');
    if (!this._getYearGroupOptionValue(yearGroup)) {
      this._change(user.id, 'yearGroup', defaultYearGroup);
    }

    this.setState({ editable: user.id });
  };

  _cancelEdit = id => {
    const { updates: updatesAlias } = this.state;
    const { validate, users, unsavedUsers } = this.props;
    const undone = Object.keys(updatesAlias[id] || {});
    if (undone.length) {
      validate(id, pick(users[id], undone));
    }
    this.setState(({ updates }) => ({
      editable: null,
      updates: omit(updates, [id])
    }));
    delete unsavedUsers[id];
  };

  _change = (id, column, value) => {
    const { yearGroups, onChange } = this.props;
    this.setState(({ updates }) => ({
      updates: {
        ...updates,
        [id]: {
          ...(updates[id] || {}),
          [column]: column === 'yearGroup' ? yearGroups[value].value : value
        }
      }
    }));

    onChange();
  };

  _update = id => {
    const { unsavedUsers, update = () => {} } = this.props;
    const { updates } = this.state;
    const unSavedData = pick(unsavedUsers, id);
    const editedData = pick(updates, id);
    update({ [id]: { ...unSavedData[id], ...editedData[id] } });
    this._storeUnsavedData(id);
    this.setState({ editable: null, updates: {} });
  };

  _storeUnsavedData = id => {
    const { updates } = this.state;
    const { unsavedUsers, storeUnsavedUsers } = this.props;
    delete updates[id];
    delete unsavedUsers[id];
    const data = !isEmpty(updates) ? updates : unsavedUsers;
    storeUnsavedUsers(data);
  };

  _getUnsavedData = (user, column) => {
    const { unsavedUsers, yearGroups } = this.props;
    if (column === 'yearGroup' && !isEmpty(unsavedUsers[user.id])) {
      const yearGroupKey = Object.keys(yearGroups).find(
        key => yearGroups[key].value === unsavedUsers[user.id].yearGroup
      );
      return yearGroups[yearGroupKey].value;
    }
    return unsavedUsers[user.id] && column in unsavedUsers[user.id] ? unsavedUsers[user.id][column] : user[column];
  };

  _remove = id => {
    this.setState({ removeable: id });
  };

  _cancelRemove = () => {
    this.setState({ removeable: null });
  };

  _destroy = () => {
    const { removeable } = this.state;
    const { destroy = () => {} } = this.props;
    destroy(removeable);
    this.setState(({ updates }) => ({
      removeable: null,
      updates: omit(updates, [removeable])
    }));
  };

  _validate = (id, column) => {
    const { validate, users } = this.props;
    const { updates: updatesAlias } = this.state;
    const updates = updatesAlias[id];
    let updateValue = updates[column];
    if (!columns[column].required && updateValue === '') {
      updateValue = undefined;
    }
    validate(id, { [column]: updates ? updateValue : users[id][column] });
  };

  _isDisable = user => {
    const { id, errors } = user;
    const { updates } = this.state;
    let retval = false;
    if (!isEmpty(updates[id])) {
      const validFirstNameField = errors.firstName
        ? updates[id].firstName !== undefined && updates[id].firstName !== ''
        : !errors.firstName;
      const validLastNameeField = errors.lastName
        ? updates[id].lastName !== undefined && updates[id].lastName !== ''
        : !errors.lastName;
      const validUserNameField = errors.userName
        ? updates[id].userName !== undefined && updates[id].userName !== ''
        : !errors.userName;
      const validPasswordField = errors.password
        ? updates[id].password !== undefined && updates[id].password !== ''
        : !errors.password;
      const validYearGroupField = errors.yearGroup
        ? updates[id].yearGroup !== undefined && updates[id].yearGroup !== ''
        : !errors.yearGroup;
      retval = !(
        validFirstNameField &&
        validLastNameeField &&
        validUserNameField &&
        validPasswordField &&
        validYearGroupField
      );
    } else {
      retval = Object.values(errors).some(Boolean);
    }
    return retval;
  };

  _getActions = user => {
    const { id } = user;
    const { editable, removeable, updates } = this.state;
    const { unsavedUsers } = this.props;
    switch (true) {
      case editable === id || !!updates[id] || Object.keys(unsavedUsers).includes(id):
        return (
          <div>
            <span className={styles.cta}>
              <Button
                type={buttonTypes.PRIMARY}
                text="Ok"
                disabled={this._isDisable(user) && !Object.keys(unsavedUsers).includes(id)}
                onClick={() => this._update(id)}
              />
            </span>
            <Button type={buttonTypes.SECONDARY} text="Cancel" onClick={() => this._cancelEdit(id)} />
          </div>
        );
      case removeable === id:
        return (
          <div>
            <div className={styles.label}>Are you sure?</div>
            <span className={styles.cta}>
              <Button type={buttonTypes.PRIMARY} text="Yes" onClick={this._destroy} />
            </span>
            <Button type={buttonTypes.SECONDARY} text="No" onClick={() => this._cancelRemove(id)} />
          </div>
        );
      default:
        return (
          <div>
            <Button
              type={buttonTypes.NO_BORDER}
              glyph={GLYPHS.ICON_EDIT}
              text="Edit"
              iconOnly
              onClick={() => this._edit(user)}
            />
            <Button
              type={buttonTypes.NO_BORDER}
              glyph={GLYPHS.ICON_REMOVE}
              text="Remove"
              iconOnly
              onClick={() => this._remove(id)}
            />
          </div>
        );
    }
  };

  _getErrorMessage = (column, value, isArchive) => {
    const {
      localizedContent: { importUsersTableComponent: content }
    } = this.props;
    const columnName = columns[column].heading;

    if (!value) {
      return `${content.missing_column} ${columnName.toLowerCase()}`;
    }

    switch (true) {
      case ['firstName', 'lastName'].includes(column) && value.length > NAME_MAX_LIMIT:
        return content[`${column.toLowerCase()}_limit_exceeded`];

      case column === 'userName' && value.length > USERNAME_MAX_LIMIT:
        return content.username_limit_exceeded;

      case column === 'userName' && value.length && isArchive:
        return content.duplicate_userName;

      case column === 'userName':
        return content.username_invalid_character;

      case column === 'password':
        return content.password_invalid_error;

      case column === 'class':
        return content.classname_invalid_character;

      default:
        return `${columnName} ${content.column_is_invalid}`;
    }
  };

  _getInputValue = (user, column) => {
    const { updates } = this.state;
    const { yearGroups, unsavedUsers } = this.props;
    if (
      (!updates[user.id] || !(column in updates[user.id])) &&
      unsavedUsers[user.id] &&
      column in unsavedUsers[user.id]
    ) {
      return this._getUnsavedData(user, column);
    }
    if (column === 'yearGroup' && !isEmpty(updates[user.id]) && column in updates[user.id]) {
      const yearGroupKey = Object.keys(yearGroups).find(key => yearGroups[key].value === updates[user.id].yearGroup);
      return yearGroups[yearGroupKey].value;
    }
    return updates[user.id] && column in updates[user.id] ? updates[user.id][column] : user[column];
  };

  _getYearGroupOptionValue = value => {
    const { yearGroups } = this.props;

    return Object.keys(yearGroups).find(key => yearGroups[key].value === value);
  };

  _getYearGroupDisplayValue = (user, column) => {
    const { yearGroups } = this.props;
    const value = this._getYearGroupOptionValue(this._getInputValue(user, column));
    const displayValue = yearGroups[value];

    return displayValue ? displayValue.name : value;
  };

  render() {
    const { editable, removeable } = this.state;
    const {
      localizedContent: { importUsersTableComponent: content },
      users = {},
      yearGroups
    } = this.props;

    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={classnames(styles.th, styles.edit, { [styles.updating]: editable || removeable })} />
            <th className={classnames(styles.th, styles.status)}>Status</th>
            {Object.entries(columns).map(([key, { heading, required }]) => (
              <th
                key={key}
                className={styles.th}
                data-tooltip={required ? `${heading} ${content.column_is_required}` : null}
              >
                {heading}
                {required ? <span className={styles.required}>*</span> : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.values(users).reduce(
            (acc, user, index) =>
              acc.concat(
                user.archive ? (
                  <tr key={`${index}-banner2`}>
                    <td colSpan="8" className={styles.warningTd}>
                      <div className={styles.warningRow}>
                        <SVGIcon className={styles.warningIcon} glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
                        <span>{content.archived_user} </span>
                      </div>
                    </td>
                  </tr>
                ) : user.duplicate || (user.exists && !user.archived) ? (
                  <tr key={`${index}-banner2`}>
                    <td colSpan="8" className={styles.warningTd}>
                      <div className={styles.warningRow}>
                        <SVGIcon className={styles.warningIcon} glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
                        <span>{content.duplicate_user}</span>
                      </div>
                    </td>
                  </tr>
                ) : null,
                <tr key={`${index}-data`} className={styles.tr}>
                  <td className={styles.td}>{this._getActions(user)}</td>
                  <td className={styles.td} title={user.status.info}>
                    <div title={user.status.message}>
                      <SVGIcon className={styles.icon} glyph={user.status.icon} />
                    </div>
                  </td>
                  {Object.keys(pick(user, Object.keys(columns))).map(column => (
                    <td
                      className={classnames(styles.td, { [styles.top]: editable === user.id || user.errors[column] })}
                      key={column}
                    >
                      {editable === user.id || user.errors[column] ? (
                        <Validation
                          isError={user.errors[column]}
                          message={this._getErrorMessage(column, this._getInputValue(user, column), user.archive)}
                          noIcon
                        >
                          {editable === user.id && column === 'yearGroup' ? (
                            <Dropdown
                              label={columns[column].heading}
                              value={this._getYearGroupOptionValue(this._getInputValue(user, column))}
                              options={Object.entries(yearGroups).map(([key, { name }]) => ({
                                text: name,
                                value: key
                              }))}
                              onChange={value => this._change(user.id, column, value)}
                              onBlur={() => this._validate(user.id, column)}
                              disabled={editable !== user.id && user.errors[column]}
                              labelHidden
                            />
                          ) : (
                            <TextInput
                              id={user.id}
                              label={columns[column].heading}
                              autoFocus={this._getAutoFocus(user.id, column)}
                              autoHighlight={this._getAutoFocus(user.id, column)}
                              value={
                                column === 'yearGroup'
                                  ? this._getYearGroupDisplayValue(user, column)
                                  : this._getInputValue(user, column)
                              }
                              readOnly={editable !== user.id && user.errors[column]}
                              readOnlyPreserveStyle
                              onClick={() => {
                                this._edit(user);
                                this._autoFocus(user.id, column);
                              }}
                              onChange={value => this._change(user.id, column, value)}
                              onBlur={() => this._validate(user.id, column)}
                              labelHidden
                            />
                          )}
                        </Validation>
                      ) : column === 'yearGroup' ? (
                        this._getYearGroupDisplayValue(user, column)
                      ) : (
                        this._getInputValue(user, column)
                      )}
                    </td>
                  ))}
                </tr>
              ),
            []
          )}
        </tbody>
      </table>
    );
  }
}

ImportUsersTable.propTypes = {
  localizedContent: PropTypes.object,
  users: PropTypes.object,
  yearGroups: PropTypes.object.isRequired,
  update: PropTypes.func,
  destroy: PropTypes.func,
  validate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  storeUnsavedUsers: PropTypes.func,
  unsavedUsers: PropTypes.object
};

export default withLocalizedContent('importUsersTableComponent')(ImportUsersTable);
