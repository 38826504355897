import { put } from 'redux-saga/effects';
import { loadOfflineUnits } from '../../../../actions/offlineContentPlayer';

function* deleteOfflineUnits(unitsIds) {
  const storedValue = localStorage.getItem('offline_units');
  const units = storedValue ? JSON.parse(storedValue) : {};

  const remainingUnits = units.filter(unit => !unitsIds.includes(unit.id));

  localStorage.setItem('offline_units', JSON.stringify(remainingUnits));
  yield put(loadOfflineUnits(remainingUnits));
}

export default deleteOfflineUnits;
