import { select, take, put } from 'redux-saga/effects';

import {
  BULK_SUBMIT_FORM,
  RESET_FORM,
  showErrorReview,
  bulkShowConfirmation
} from '../../../../../reducers/addToLibrary.reducer.js';

import bulkUploadRequest from './bulkUploadRequest.saga.js';

export default function* bulkUploadToLibrary() {
  console.log('[bulkUploadToLibrary Saga] Beginning');

  while (true) {
    console.log('[bulkUploadToLibrary Saga] Waiting for user to submit form');
    yield take(BULK_SUBMIT_FORM);

    console.log('[bulkUploadToLibrary Saga] Submitting addTolLibrary request');
    const result = yield bulkUploadRequest();
    const errorEntries = [];
    if (result.status === 'success') {
      console.log('[bulkUploadToLibrary Saga] Showing confirmation screen');
      yield put(bulkShowConfirmation());
    } else {
      if (result.data) {
        const { fileContents } = yield select(state => ({
          fileContents: state.addToLibrary.file.data
        }));

        const errors = Object.entries(result.data);
        // Format the file to remove any double commas
        const formattedFileContents = fileContents.replace(/,{2}/g, ',');
        // Split the file
        const splitFile = formattedFileContents.split('\n');

        errors.forEach(entry =>
          errorEntries.push({
            row: entry[0],
            value: entry[0] === '0' ? null : splitFile[entry[0] - 1],
            cmsErrorKeys: entry[1]
          })
        );

        console.log('[bulkUploadToLibrary Saga] Showing error review screen, file error');
      }
      yield put(showErrorReview(errorEntries));
    }

    yield take(RESET_FORM);
    console.log('[bulkUploadToLibrary Saga] Resetting bulkUploadToLibrary state and saga');
  }
}
