import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import createLMSConfig from './createLMSConfig';
// import getLMSConfig from './getLMSConfig';
import deleteLMSConfig from './deleteLMSConfig';

function* root() {
  yield takeLatest(t.CREATE_LMS_CONFIG_DEPLOYMENT_REQUEST, ({ orgId, toolId = '', payload }) =>
    createLMSConfig(orgId, toolId, payload)
  );
  yield takeLatest(t.DELETE_LMS_CONFIG_DEPLOYMENT_REQUEST, ({ payload: { orgId, toolId } }) =>
    deleteLMSConfig(orgId, toolId)
  );
}

export default root;
