import React from 'react';
import PropTypes from 'prop-types';
import { marked } from 'marked';
import { renderToStaticMarkup } from 'react-dom/server';
import parseMarkdown from '../../utils/markdown/markdown';
import { isExternalLink } from '../../globals/validations';

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
  const isResetPasswordPathname = window.location.pathname.includes('reset-password');
  const targetForInternalLink = isResetPasswordPathname ? '_blank' : undefined;
  return renderToStaticMarkup(
    <a href={href} target={isExternalLink(href) ? '_blank' : targetForInternalLink} title={title} rel="noreferrer">
      {text}
    </a>
  );
};

/** Display text containing markdown formatted links */
function TextWithLink({ text, className }) {
  return (
    <p
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: parseMarkdown(text, {
          renderer
        })
      }}
    />
  );
}

TextWithLink.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.any
};

export default TextWithLink;
