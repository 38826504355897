export const INITIALISE = 'productUploadLogs/INITIALISE';
export const PRODUCT_UPLOAD_LOGS_REQUEST = 'productUploadLogs/PRODUCT_UPLOAD_LOGS_REQUEST';
export const PRODUCT_UPLOAD_LOGS_SUCCESS = 'productUploadLogs/PRODUCT_UPLOAD_LOGS_SUCCESS';
export const PRODUCT_UPLOAD_LOGS_FAILURE = 'productUploadLogs/PRODUCT_UPLOAD_LOGS_FAILURE';

export const initialState = {
  initial: true,
  loading: false,
  errorOccurred: false,
  errorMessage: null,
  success: false,
  productUploadLogsData: null
};

export default function productUploadLogs(state = initialState, action) {
  switch (action.type) {
    // Initialise action
    case INITIALISE:
      return {
        ...initialState
      };
    case PRODUCT_UPLOAD_LOGS_REQUEST:
      return {
        ...state,
        initial: false,
        loading: true,
        errorOccurred: false,
        success: false
      };
    case PRODUCT_UPLOAD_LOGS_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        initial: false,
        loading: false,
        errorOccurred: true,
        success: false
      };
    case PRODUCT_UPLOAD_LOGS_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        productUploadLogsData: action.productUploadLogsData,
        initial: false,
        loading: false,
        errorOccurred: false,
        success: true
      };
    default:
      return state;
  }
}

export const startProductLogsRequest = () => ({
  type: PRODUCT_UPLOAD_LOGS_REQUEST
});

export const showproductUploadLogsData = productUploadLogsData => ({
  type: PRODUCT_UPLOAD_LOGS_SUCCESS,
  productUploadLogsData
});

export const showproductUploadLogsError = errorMessage => ({
  type: PRODUCT_UPLOAD_LOGS_FAILURE,
  errorMessage
});

export const resetForm = () => ({
  type: INITIALISE
});
