import qs from 'query-string';
import signedFetch from '../apiCalls/util/signedFetch';

export const getAllTeacherResourcesUrl = titleIds =>
  // @ts-ignore
  `${__API_BASE__}/resources?${qs.stringify({
    platforms: 'premium',
    'resource-schema': 'premium',
    'user-type': 'teacher',
    'premium-title-ids': titleIds
  })}`;

function* getAllTeacherResources(titleIds) {
  return yield signedFetch('getTeacherResources', getAllTeacherResourcesUrl(titleIds));
}

export default getAllTeacherResources;
