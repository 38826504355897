import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import { ErrorStatus, PageWrapper } from '../../../../components';
import PopoutPanelIconHeading, { types } from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ProductUpload from '../../ProductUpload/ProductUpload.js';
import CsvProductStructureUploadSuccess from '../../../../components/CsvProductStructureUpload/CsvProductStructureUploadSuccess';
import { resetForm } from '../../../../redux/reducers/productUpload.reducer.js';
import { resetForm as resetFormLogs } from '../../../../redux/reducers/productUploadLogs.reducer.js';
import styles from './UploadProductStructure.scss';
import { SOURCES } from '../../../../globals/contentPreviewConstants';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import { MODAL_CLOSE_LINK_KEY, selfSelectedUserRoles } from '../../../../globals/cptConstants';
import {
  openStructuredContentPlayerModal,
  setModalBasePath,
  setPreviewRole
} from '../../../../redux/actions/structuredContentPlayer';
import { getContentPreviewRequest } from '../../../../redux/actions/contentPreview';
import changeRoleToProductVariant from '../../../../components/StructuredContentPlayer/structuredContentPlayerUtils.js';

class ProductUploadPage extends Component {
  componentDidMount() {
    const { getContentPreviewRequestData, openStructuredContentPlayerModalAction, setModalBasePathAction } = this.props;
    sessionStorage.setItem(MODAL_CLOSE_LINK_KEY, window.location.pathname);
    getContentPreviewRequestData({ source: SOURCES.ELTCORE });
    openStructuredContentPlayerModalAction();
    setModalBasePathAction(window.location);
  }

  render() {
    const {
      inProgress,
      loading,
      errorOccurred,
      success,
      localizedContent: { productUploadPage: content, contentPreview: contentPreviewContent },
      redirect,
      errorMessage,
      productUploadResult,
      setPreviewRoleAction,
      history
    } = this.props;

    const formatedErrorMessage = errorMessage
      ?.replace('Error: ', '')
      .split('\n')
      .map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));

    const handleEltcorePreview = role => {
      setPreviewRoleAction(role);
      const viewVariant = changeRoleToProductVariant(role);
      history.push(`/launch/${viewVariant}/${productUploadResult.contentCode}`);
    };

    const contentAreaPreviewButton = key => (
      <div className={styles.previewButtonsContainer}>
        <Button
          key={`id_${key}_teacher`}
          text={contentPreviewContent.preview_as_teacher}
          onClick={() => handleEltcorePreview(selfSelectedUserRoles.SELF_TEACHER)}
          type={buttonTypes.SECONDARY}
        />
        <Button
          key={`id_${key}_student`}
          text={contentPreviewContent.preview_as_student}
          onClick={() => handleEltcorePreview(selfSelectedUserRoles.SELF_LEARNER)}
          type={buttonTypes.SECONDARY}
        />
      </div>
    );

    return (
      <PageWrapper>
        <Helmet title={content.title} />
        {inProgress ? (
          <div>
            <ProductUpload content={content} platform="ELTCORE" />
          </div>
        ) : null}
        {loading ? <PopoutPanelIconHeading type={types.LOADING} title={content.waitingMessage} /> : null}
        {errorOccurred ? (
          <ErrorStatus
            title={formatedErrorMessage}
            buttonText={content.backButton}
            buttonOnClickHandler={() => redirect('/eltcore-builder')}
          />
        ) : null}
        {success ? (
          <>
            <CsvProductStructureUploadSuccess
              content={content}
              goBackToDashboard={() => redirect('/eltcore-builder')}
              warnings={productUploadResult?.warnings || []}
            />
            {contentAreaPreviewButton()}
          </>
        ) : null}
      </PageWrapper>
    );
  }
}

ProductUploadPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errorOccurred: PropTypes.bool,
  inProgress: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  productUploadResult: PropTypes.string,
  setPreviewRoleAction: PropTypes.func,
  history: PropTypes.object.isRequired,
  openStructuredContentPlayerModalAction: PropTypes.func,
  getContentPreviewRequestData: PropTypes.func,
  setModalBasePathAction: PropTypes.func
};

export default compose(
  withRouter,
  withLocalizedContent('productUploadPage', 'contentPreview'),
  connect(
    state => ({
      inProgress: state.productUpload.inProgress,
      loading: state.productUpload.loading,
      errorOccurred: state.productUpload.errorOccurred,
      errorMessage: state.productUpload.errorMessage,
      success: state.productUpload.success,
      productUploadResult: state.productUpload.productUploadResult,
      contentPreviewLoading: state.contentPreview.loading,
      contentPreviewSuccess: state.contentPreview.success,
      contentPreviewResults: state.contentPreview.results
    }),
    (dispatch, props) => ({
      redirect: path => {
        dispatch(resetForm());
        dispatch(resetFormLogs());
        props.history.push(path);
      },
      setPreviewRoleAction: role => dispatch(setPreviewRole(role)),
      openStructuredContentPlayerModalAction: () => dispatch(openStructuredContentPlayerModal()),
      getContentPreviewRequestData: filters => dispatch(getContentPreviewRequest(filters)),
      setModalBasePathAction: path => dispatch(setModalBasePath(path))
    })
  )
)(ProductUploadPage);
