/* eslint-disable no-template-curly-in-string */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import { isLevelGreaterThan } from '../../utils/reading-buddy';
import Button, { buttonTypes } from '../Button/Button';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import styles from './LevelChangePrompt.scss';

class LevelChangePrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModalOpen: false,
      cancelModalOpen: false,
      resetModalOpen: false
    };
  }

  _handleToggleConfirmModal = () => {
    this.setState(({ confirmModalOpen }) => ({ confirmModalOpen: !confirmModalOpen }));
  };

  _handleToggleCancelModal = () => {
    this.setState(({ cancelModalOpen }) => ({ cancelModalOpen: !cancelModalOpen }));
  };

  _handleToggleResetModal = () => {
    this.setState(({ resetModalOpen }) => ({ resetModalOpen: !resetModalOpen }));
  };

  _handleConfirm = () => {
    const { onConfirm } = this.props;
    onConfirm();
    this.setState({ confirmModalOpen: false });
  };

  _handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
    this.setState({ cancelModalOpen: false });
  };

  _handleReset = () => {
    const { onLevelReset } = this.props;
    onLevelReset();
    this.setState({ resetModalOpen: false });
  };

  _delayMessage() {
    const {
      newLevelName,
      newLevelDelayPeriod,
      localizedContent: { levelChangePrompt: i18n }
    } = this.props;

    let message = (i18n.delay_modal_text || '').replace('${level}', newLevelName);

    if (newLevelDelayPeriod) {
      message += (i18n.delay_reminder_text || '')
        .replace('${number}', newLevelDelayPeriod.value)
        .replace('${unit}', newLevelDelayPeriod.unit);
    }

    return message;
  }

  render() {
    const {
      studentName,
      newLevel,
      newLevelName,
      currentLevel = '1',
      newLevelDaysRemaining,
      localizedContent: { levelChangePrompt: i18n }
    } = this.props;
    const { confirmModalOpen, cancelModalOpen, resetModalOpen } = this.state;
    const isLevelIncrease = isLevelGreaterThan(newLevel, currentLevel);
    const isLevelDirection = isLevelIncrease ? 'up' : 'down';

    return (
      <div className={styles.boxFormat}>
        <p className={styles.smallerTitle}>
          {(i18n.panel_title || '').replace('${direction}', isLevelDirection).replace('${level}', newLevelName)}
        </p>
        <div className={styles.buttonsFormat}>
          <Button text={i18n.approve_button_text} onClick={this._handleToggleConfirmModal} type={buttonTypes.BLUE} />
          {isLevelDirection === 'up' ? (
            <Button text={i18n.delay_button_text} onClick={this._handleToggleCancelModal} type={buttonTypes.WHITE} />
          ) : null}
          <Button text={i18n.reset_button_text} onClick={this._handleToggleResetModal} type={buttonTypes.WHITE} />
        </div>
        {newLevelDaysRemaining ? (
          <p className={styles.subText}>
            {(i18n.fallback_message || '')
              .replace('${direction}', isLevelDirection)
              .replace('${days}', newLevelDaysRemaining)}
          </p>
        ) : null}
        {confirmModalOpen ? (
          <ConfirmationModal
            title={studentName}
            body={(i18n.approve_modal_text || '')
              .replace('${direction}', isLevelDirection)
              .replace('${level}', newLevelName)}
            positiveClickText={i18n.confirm}
            negativeClickText={i18n.cancel}
            positiveClick={this._handleConfirm}
            negativeClick={this._handleToggleConfirmModal}
          />
        ) : null}

        {cancelModalOpen ? (
          <ConfirmationModal
            title={studentName}
            body={this._delayMessage()}
            positiveClickText={i18n.confirm}
            negativeClickText={i18n.cancel}
            positiveClick={this._handleCancel}
            negativeClick={this._handleToggleCancelModal}
          />
        ) : null}

        {resetModalOpen ? (
          <ConfirmationModal
            title={studentName}
            body={i18n.reset_modal_text}
            positiveClickText={i18n.confirm}
            negativeClickText={i18n.cancel}
            positiveClick={this._handleReset}
            negativeClick={this._handleToggleResetModal}
          />
        ) : null}
      </div>
    );
  }
}

LevelChangePrompt.propTypes = {
  currentLevel: PropTypes.string,
  newLevel: PropTypes.string.isRequired,
  newLevelName: PropTypes.string.isRequired,
  newLevelDaysRemaining: PropTypes.number,
  newLevelDelayPeriod: PropTypes.shape({
    value: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired
  }),
  studentName: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onLevelReset: PropTypes.func,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('sharedTerms', 'levelChangePrompt')(LevelChangePrompt);
