import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import withLocalizedContent from '../language/withLocalizedContent.js';
import OrganizationsList from '../components/OrganizationsList/OrganizationsList';

function OrganizationsPage({ localizedContent: { organizationsPage: localizedContent = {} } }) {
  return (
    <>
      <Helmet title={localizedContent.page_title} />
      <OrganizationsList tabName="organizations" />
    </>
  );
}

OrganizationsPage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('organizationsPage'))(OrganizationsPage);
