import * as t from '../actionTypes';

export const rvsFormValidateInput = input => ({
  type: t.RVS_FORM_VALIDATE_INPUT,
  payload: input
});

export const rvsFormValidateCompleted = input => ({
  type: t.RVS_FORM_VALIDATE_COMPLETED,
  payload: input
});

export const rvsFormSubmit = (input = null) => ({
  type: t.RVS_FORM_SUBMIT,
  payload: input
});

export const rvsFormReset = () => ({
  type: t.RVS_FORM_RESET
});

export const rvsFormSuccess = () => ({
  type: t.RVS_FORM_SUCCESS
});

export const rvsFormBot = () => ({
  type: t.RVS_FORM_BOT
});

export const rvsFormFailure = errorMessages => ({
  type: t.RVS_FORM_FAILURE,
  payload: { errorMessages }
});

export const rvsUnavailableApi = () => ({
  type: t.RVS_UNAVAILABLE_API
});
