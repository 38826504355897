import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../globals/colors';
import EntityRepresentation from '../EntityRepresentation/EntityRepresentation';
import { GLYPHS, validSizes } from '../Thumbnail/Thumbnail';

/** This component consists of a thumbnail, name and some subtext. The name can be a link */
function OrgRepresentation({ thumbnailSize, linkId, linkTo, name, subtext, statusText, status, id, deletedStyle }) {
  return (
    <EntityRepresentation
      id={id}
      thumbnailColor={colors.ORGANIZATION}
      thumbnailGlyph={GLYPHS.ICON_ORG}
      thumbnailSize={thumbnailSize}
      linkId={linkId}
      linkTo={linkTo}
      line1Text={name}
      line2Text={subtext}
      line3Text={statusText}
      status={status}
      deletedStyle={deletedStyle}
    />
  );
}

OrgRepresentation.propTypes = {
  /** The size of the thumbnail. */
  thumbnailSize: PropTypes.oneOf(validSizes).isRequired,
  /** Link Id for testing */
  linkId: PropTypes.string,
  /** Target of the link if the entity contains a link */
  linkTo: PropTypes.string,
  /** Org name */
  name: PropTypes.string.isRequired,
  /** Second line text */
  subtext: PropTypes.string,
  /** Third line text */
  statusText: PropTypes.string,
  /** Org's status, eg SUCCESS, WARNING, ERROR */
  status: PropTypes.string,
  /** Html id for this component */
  id: PropTypes.string.isRequired,
  /** This applies a "deleted" style to the element */
  deletedStyle: PropTypes.bool
};

export default OrgRepresentation;
