import PropTypes from 'prop-types';
import React from 'react';
import ContentWithSVGIcon from '../../../components/ContentWithSVGIcon/ContentWithSVGIcon';
import MaterialRepresentation from '../../../components/MaterialRepresentation/MaterialRepresentation';
import PanelNavigationLink from '../../../components/PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../../components/PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../../../components/ScrollContainer/ScrollContainer';
import { sanitizeUrl } from '../../../utils/url.js';
import { isHubMode } from '../../../utils/platform';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import cmsContent from '../../../utils/cmsContent';
import { LICENSE_STATUS_COLOR } from '../../../globals/appConstants';
import { featureIsEnabled } from '../../../globals/envSettings';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';

function AssignLearningMaterialConfirmation({
  userIdsInOrder,
  assignLicences,
  context,
  contextName,
  errorOccurred,
  product,
  closePanel,
  onAddAnotherClick,
  onComplete
}) {
  const CMS = cmsContent.assignLearningMaterialPanel || {};
  let successMessage = '';
  if (featureIsEnabled('add-multiple-learning-material')) {
    successMessage = CMS && CMS.assignment_multiple_success_text;
  } else {
    successMessage = CMS && CMS.assignment_success_text;
  }

  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePanel} />
          </div>
          {isHubMode() ? (
            <PopoutPanelIllustrationHeading
              title={errorOccurred ? CMS.assignment_error_text : CMS.learning_material_added || ''}
              subtitle={!errorOccurred && (successMessage || '').replace('{assigneeName}', contextName)}
              illustrationSrc={errorOccurred ? HubIllustrationConstants.ERROR : HubIllustrationConstants.SUCCESS}
              illustrationAltText={errorOccurred ? HubIllustrationAltText.ERROR : HubIllustrationAltText.SUCCESS}
            />
          ) : (
            <PopoutPanelIconHeading
              type={errorOccurred ? 'WARNING_ERROR' : 'VALID'}
              title={errorOccurred ? CMS.assignment_error_text : CMS.learning_material_added || ''}
              subtitle={!errorOccurred && (successMessage || '').replace('{assigneeName}', contextName)}
            />
          )}
        </div>
      }
      footerContent={
        <PopoutActionFooter
          secondaryButtonAction={onAddAnotherClick}
          secondaryButtonText={context === 'USER_PROFILE' || errorOccurred ? null : CMS.button_add_another_assignment}
          primaryButtonText={errorOccurred ? CMS.button_try_again_assignment : CMS.complete_panel_text}
          primaryButtonAction={errorOccurred ? onAddAnotherClick : onComplete}
          primaryButtonTestHook="ASSIGN_LEARNING_MATERIAL_PANEL_CONFIRMATION_BUTTON"
        />
      }
    >
      {errorOccurred ? (
        ''
      ) : (
        <div>
          {featureIsEnabled('add-multiple-learning-material') ? (
            <div className="pad-top1 pad-bot1 pad-left2 pad-right2">
              {product &&
                product.map((productDetails, index) => (
                  <div style={{ margin: '1rem' }} key={index}>
                    <ContentWithSVGIcon glyph={errorOccurred ? GLYPHS.ICON_ERROR_CIRCLE : GLYPHS.ICON_CHECK_CIRCLE}>
                      <MaterialRepresentation
                        thumbnailSize={thumbnailSizes.TABLE}
                        // To be removed
                        // #PRODUCT_FINDER_REFACTOR
                        imageSrc={sanitizeUrl(product?.coverImage || product?.cover || '')}
                        name={productDetails.title}
                        subtext={productDetails.subtitle}
                        statusColor={
                          productDetails.assignLicences ? LICENSE_STATUS_COLOR.SUCCESS : LICENSE_STATUS_COLOR.WARNING
                        }
                        statusText={
                          (errorOccurred && CMS.assignment_error_text) || productDetails.assignLicences
                            ? `${userIdsInOrder[productDetails.productId].length} ${
                                CMS.review_library_assignment_count
                              }`
                            : CMS.review_required_access_code
                        }
                      />
                    </ContentWithSVGIcon>
                  </div>
                ))}
            </div>
          ) : (
            <div className="pad-top1 pad-bot1 pad-left2 pad-right2">
              <ContentWithSVGIcon glyph={errorOccurred ? GLYPHS.ICON_ERROR_CIRCLE : GLYPHS.ICON_CHECK_CIRCLE}>
                <MaterialRepresentation
                  thumbnailSize={thumbnailSizes.TABLE}
                  imageSrc={sanitizeUrl(product?.coverImage || product?.cover || '')}
                  name={product?.title}
                  subtext={product?.subtitle}
                  statusColor={assignLicences ? LICENSE_STATUS_COLOR.SUCCESS : LICENSE_STATUS_COLOR.WARNING}
                  statusText={
                    (errorOccurred && CMS.assignment_error_text) || assignLicences
                      ? `${userIdsInOrder.length} ${CMS.review_library_assignment_count}`
                      : CMS.review_required_access_code
                  }
                />
              </ContentWithSVGIcon>
            </div>
          )}
        </div>
      )}
    </ScrollContainer>
  );
}

AssignLearningMaterialConfirmation.propTypes = {
  userIdsInOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  assignLicences: PropTypes.bool.isRequired,
  context: PropTypes.string,
  contextName: PropTypes.string.isRequired,
  errorOccurred: PropTypes.bool.isRequired,
  product: PropTypes.any,
  onComplete: PropTypes.func,
  onAddAnotherClick: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired
};

export default AssignLearningMaterialConfirmation;
