import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Action imports
import { backToReviewLicences, submitAssignment } from '../../../redux/reducers/assignLearningMaterial.reducer.js';

// Component imports
import PanelHeading from '../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../components/PopoutActionFooter/PopoutActionFooter.js';
import MaterialRepresentation from '../../../components/MaterialRepresentation/MaterialRepresentation.js';
import { sanitizeUrl } from '../../../utils/url.js';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail.js';

import cmsContent from '../../../utils/cmsContent.js';

function AssignLearningMaterialReview({
  closePopoutAction,
  contextName,
  product,
  assignLicences,
  userIdsInOrder,
  backToReviewLicencesAction,
  submitAssignmentAction
}) {
  const CMS = cmsContent.assignLearningMaterialPanel || {};
  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePopoutAction} />
            </div>
            <PanelHeading
              title={CMS.review_panel_text}
              subtitle={(CMS.assignment_review_subtitle || '').replace('{assigneeName}', contextName)}
            />
          </div>
        }
        footerContent={
          <PopoutActionFooter
            secondaryButtonText={CMS.button_back_text}
            secondaryButtonAction={backToReviewLicencesAction}
            primaryButtonText={CMS.review_add_assignment_text}
            primaryButtonAction={submitAssignmentAction}
            primaryButtonTestHook="ASSIGN_LEARNING_MATERIAL_REVIEW_NEXT"
            wizardButtons
          />
        }
      >
        <div style={{ margin: '1rem' }}>
          <MaterialRepresentation
            thumbnailSize={thumbnailSizes.TABLE}
            // To be removed
            // #PRODUCT_FINDER_REFACTOR
            imageSrc={sanitizeUrl(product?.coverImage || product?.cover || '')}
            name={product && product.title}
            subtext={product && product.subtitle}
            statusText={assignLicences ? `${userIdsInOrder.length} ${CMS.review_library_assignment_count}` : null}
          />
        </div>
      </ScrollContainer>
    </form>
  );
}

AssignLearningMaterialReview.propTypes = {
  closePopoutAction: PropTypes.func.isRequired,
  contextName: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  assignLicences: PropTypes.bool.isRequired,
  userIdsInOrder: PropTypes.array,
  backToReviewLicencesAction: PropTypes.func.isRequired,
  submitAssignmentAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    assignLicences: state.assignLearningMaterial.assignLicences,
    userIdsInOrder: state.assignLearningMaterial.userIdsInOrder
  }),
  {
    backToReviewLicencesAction: backToReviewLicences,
    submitAssignmentAction: submitAssignment
  }
)(AssignLearningMaterialReview);
