import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import content from '../../utils/cmsContent';
import Button, { buttonTypes } from '../Button/Button';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './PaginationButtons.scss';

// Useful background info:
// http://www.bbc.co.uk/gel/guidelines/numbered-pagination
//
// Rules:
// - Always show the first page number so it's easy to skip to start.
// - Always show the last page nummber so we know how many there are.
// - Disable left arrow when first page is current page.
// - Disable right arrow when last page is current page.
// - Replace buttons with ellipses as appropriate. Mantaining specified number of buttons.

// const content = {
//   appComponents: {
//     archived_button: 'Archived',
//     edit_button: 'Edit',
//     search_button: 'Search',
//     filter_button: 'Filter',
//     sort_button: 'Sort',
//     help_and_support_button: 'Help & Support',
//     settings_button: 'Settings',
//     ally_subtract_1: 'Subtract 1',
//     ally_add_1: 'Add 1',
//     previous_page_text: 'Previous page',
//     aria_page_text: 'Page',
//     next_page_text: 'Next page',
//     password_error_text: ' Password must contain 1 uppercase, 1 lowercase and be between 6-15 characters',
//     added_to_class_success: 'Added to class',
//     added_to_class_warning: 'Not added to class',
//     removed_from_class_success: 'Removed from class',
//     removed_from_class_warning: 'Not removed from class',
//     error_loading_search_text: 'There was an error loading search results',
//     close_text: 'Close',
//     label_show_text: 'Show',
//     search_text: 'Search',
//     results_text: 'results',
//     spaces_avalible_text: 'spaces available',
//     selected_text: 'selected',
//     next_text: 'Next',
//     skip_text: 'Skip'
//   }
// };

// This could become a prop if it needs to be configurable:
// Note: The logic will break down below ~4 buttons :(
const numberOfButtons = 6;
const ELLIPSIS = '...';

/** The component renders Buttons in a row to form a paging control */
function PaginationButtons({ idPrefix, value, numberOfPages, aria, onClick, paginationCenter }) {
  const CMS = content.appComponents || {};
  let remove;
  let start;

  // The "current" page must be zero when value is invalid, or between 1 and numberOfPages:
  const current = Math.min(Math.max(value || 0, 0), numberOfPages);

  // Generate an array of button labels from 1 to N:
  const buttons = [...Array(numberOfPages + 1).keys()].slice(1);

  // Are we going to need an ellipsis?
  const needEllipsis = numberOfPages > numberOfButtons;

  if (needEllipsis) {
    if (current <= numberOfButtons / 2) {
      // Current page is in the first 50% of buttons (eg: In 1-4 of 8 buttons)
      // so place ellipsis on the right:
      remove = numberOfPages - numberOfButtons + 1;
      buttons.splice(numberOfButtons - 2, remove, ELLIPSIS);
    } else if (current > numberOfPages - numberOfButtons / 2) {
      // Current page is in the last 50% of buttons (eg: In 5-8 of 8 buttons)
      // so place ellipsis on the left:
      remove = numberOfPages - numberOfButtons + 1;
      buttons.splice(1, remove, ELLIPSIS);
    } else {
      // Current page needs an ellipsis either side of it:
      // This is where the logic gets a bit funky...

      // First we'll replace buttons to the right of the current page with ellipsis:
      start = current;
      remove = numberOfPages - current - 1;
      buttons.splice(start, remove, ELLIPSIS);

      // Then we'll figure out how many remaining buttons to remove on the left:
      // TODO: Make this compensate when previous step cuases imbalance.
      remove = current - 3;
      buttons.splice(1, remove, ELLIPSIS);
    }
  }

  return (
    // The nav element has an implicit role="navigation". Some advise that we should
    // set that attribute but in tests our screenreaders were happy with the element as is.
    <nav aria-label={CMS.pagination_heading_text || 'Pagination navigation'}>
      <ol
        className={classnames(styles.paginationButtons, {
          [styles.center]: paginationCenter
        })}
      >
        <li>
          {/* Arrow button on far left */}
          <Button
            id={`${idPrefix}-prevPage`}
            type={buttonTypes.GROUP}
            text={CMS.previous_page_text}
            onClick={() => {
              onClick(Math.max(current - 1, 1));
              window.scrollTo(0, 0);
            }}
            glyph={GLYPHS.ICON_LEFT_THICK}
            iconOnly
            disabled={current === 1}
            aria={aria}
          />
        </li>

        {/* Page number buttons. We use aria-current to tell Assistive Tech about current page: */}
        {buttons.map((page, i) => (
          <li key={i}>
            <Button
              id={`${idPrefix}-page-${page}`}
              type={buttonTypes.GROUP}
              text={`${page}`}
              onClick={() => {
                onClick(page);
                window.scrollTo(0, 0);
              }}
              active={page === current}
              aria={{ current: page === current ? 'page' : null, label: `${CMS.aria_page_text} ${page}` }}
              disabled={Number.isNaN(Number(page))}
            />
          </li>
        ))}

        <li>
          {/* Arrow button on far right */}
          <Button
            id={`${idPrefix}-nextPage`}
            type={buttonTypes.GROUP}
            text={CMS.next_page_text}
            onClick={() => {
              onClick(Math.min(current + 1, numberOfPages));
              window.scrollTo(0, 0);
            }}
            glyph={GLYPHS.ICON_RIGHT_THICK}
            iconOnly
            disabled={current === numberOfPages}
            aria={aria}
          />
        </li>
      </ol>
    </nav>
  );
}

PaginationButtons.propTypes = {
  /** prefix to be applied to all button ids */
  idPrefix: PropTypes.string.isRequired,
  /** The curent "active" page */
  value: PropTypes.number.isRequired,
  /** Total number of pages to be represented */
  numberOfPages: PropTypes.number.isRequired,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object,
  /** click function when clicking on a pagination Button */
  onClick: PropTypes.func.isRequired,
  /** Checking on a pagination center position */
  paginationCenter: PropTypes.bool
};

export default PaginationButtons;
