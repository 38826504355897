import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const deferredEmailsUrl = `${__API_BASE__}/user/deferred-email/orgs`;

function getDeferredEmails(userRole) {
  return signedFetch('getDeferredEmailsOrgs', `${deferredEmailsUrl}/${userRole}`);
}

export default getDeferredEmails;
