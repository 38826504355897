import { takeEvery } from 'redux-saga/effects';
// prettier-ignore
import {
  GOTO_MESSAGE_POST,
  NAVIGATION_MESSAGE_POST,
  NOTIFICATION_MESSAGE_POST
} from '../../actionTypes';
import envSettings from '../../../globals/envSettings';

function* handlePostMessage({ payload }) {
  console.log(`[postMessage]: ${JSON.stringify(payload)}`);

  if (window.top !== window.self) {
    yield window.top.postMessage(payload, envSettings.topLevelOrigin);
  }
}

export default function* postMessageSaga() {
  // prettier-ignore
  yield takeEvery([
    GOTO_MESSAGE_POST,
    NAVIGATION_MESSAGE_POST,
    NOTIFICATION_MESSAGE_POST], handlePostMessage);
}
