import dot from 'dot-object';
import { compose, withHandlers, withStateHandlers } from 'recompose';

const withSearchHandlers = (defaults = {}, context = 'searchParams') => Component =>
  compose(
    withStateHandlers(
      {
        [context]: {
          term: '',
          filters: {},
          start: 0,
          size: 10,
          page: 1,
          ...defaults
        }
      },
      {
        onChangeSearchParam: prevState => (param, value) => ({
          [context]: dot.object({
            ...dot.dot(prevState[context]),
            [param]: value
          })
        })
      }
    ),
    withHandlers({
      onUpdateSearchResults: ({ search, ...props }) => (params = {}) => {
        if (!search) {
          throw new Error('Search source not defined, provide a .search method to continue');
        }
        search(
          dot.object({
            ...dot.dot(props[context]),
            ...dot.dot(params)
          })
        );
      }
    })
  )(Component);

export default withSearchHandlers;
