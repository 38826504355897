import React from 'react';
import PropTypes from 'prop-types';
import styles from './ArticleParagraph.scss';

function ArticleParagraph({ heading, title: paragraph, isNote }) {
  return (
    <div className={isNote ? styles.note : styles.ArticleParagraph}>
      {heading && isNote ? <h3>{heading}</h3> : <h2>{heading}</h2>}
      {paragraph}
    </div>
  );
}

ArticleParagraph.propTypes = {
  heading: PropTypes.string,
  title: PropTypes.string.isRequired, // paragraph text
  isNote: PropTypes.bool
};

export default ArticleParagraph;
