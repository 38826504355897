import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import withLocalizedContent from '../../language/withLocalizedContent';
import ControlledForm from '../ControlledForm/ControlledForm';
import PanelScrollContainer from '../PanelScrollContainer/PanelScrollContainer';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import TextInput from '../TextInput/TextInput';
import ImportUsersGuidance from './ImportUsersGuidance';
import styles from './ImportUsersCopyPaste.scss';

function ImportUsersCopyPaste({
  source = '',
  onPaste = () => {},
  onNext = () => {},
  onBack = () => {},
  localizedContent: { importUsersCopyPaste: content }
}) {
  return (
    <ControlledForm>
      <PanelScrollContainer
        heading={content.panel_heading}
        subHeading={content.panel_subheading}
        header={
          <div className="pad2">
            <TextInput
              id="copyAndPasteTextInputId"
              label={content.input_label_text}
              textArea
              placeholder={content.input_placeholder_text}
              value={source}
              onChange={onPaste}
            />
          </div>
        }
        footer={
          <PopoutActionFooter
            secondaryButtonText={content.back_button_text}
            secondaryButtonAction={onBack}
            primaryButtonText={content.next_button_text}
            primaryButtonAction={onNext}
            primaryButtonDisabled={!source}
            wizardButtons
          />
        }
      >
        <div className="pad-left2 pad-right2 pad-bot2 pad-top2">
          <p>{content.guidance_heading_pre_text}</p>
          <h2>{content.instructions_heading}</h2>
          <ImportUsersGuidance heading={content.data_format_heading} subheading={content.guidance_subheading} />

          <em>{content.examples_heading}</em>
          <p className="gin2">{content.examples_description}</p>
          <em>{content.tab_separated_example_heading}</em>
          <pre className={classnames(['gin2', 'pad2', styles.exampleText])}>{content.tab_separated_example_source}</pre>
          <em>{content.comma_separated_example_heading}</em>
          <pre className={classnames(['gin2', 'pad2', styles.exampleText])}>
            {content.comma_separated_example_source}
          </pre>
        </div>
      </PanelScrollContainer>
    </ControlledForm>
  );
}

ImportUsersCopyPaste.propTypes = {
  source: PropTypes.string,
  onPaste: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('importUsersCopyPaste')(ImportUsersCopyPaste);
