import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getProductServicesApi from '../../../apiCalls/productServices.api';

export default function* getProductServices(params) {
  console.log('[getProductServices] Waiting for the "GET_PRODUCT_SERVICES"');
  const apiResults = yield getProductServicesApi(params);
  console.log(apiResults);
  yield put(actions.getProductServicesSuccess(apiResults));
}
