// Page load and clear actions
const SET_SELECTED_STUDENTS = 'addStudentsToClassroom/SET_SELECTED_STUDENTS';
export const CLEAR_FORM = 'addStudentsToClassroom/CLEAR_FORM';

// Remove individual student
const REMOVE_STUDENT = 'addStudentsToClassroom/REMOVE_STUDENT';

// Student Limit actions
const SET_ENABLE_STUDENT_LIMIT = 'addStudentsToClassroom/SET_ENABLE_STUDENT_LIMIT';
const SET_STUDENT_LIMIT = 'addStudentsToClassroom/SET_STUDENT_LIMIT';

// Nav actions
export const ASSIGN_MATERIAL = 'addStudentsToClassroom/ASSIGN_MATERIAL';
const GOTO_STUDENT_SELECTION = 'addStudentsToClassroom/GOTO_STUDENT_SELECTION';
export const SUBMIT_FORM = 'addStudentsToClassroom/SUBMIT_FORM';
const SUBMISSION_COMPLETE = 'addStudentsToClassroom/SUBMISSION_COMPLETE';

export const formStates = {
  SELECTING: 'SELECTING',
  REVIEW_SELECTIONS: 'REVIEW_SELECTIONS',
  ASSIGNMATERIAL: 'ASSIGNMATERIAL',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION' // Success|Fail feedback after SUBMITTING
};

const initialState = {
  formState: formStates.SELECTING,

  // Selected users
  selectedStudentIds: [],

  // Optional student limit
  enableStudentLimit: null,
  studentLimit: null,

  // List of users who were not removed from the class after submission
  failedIds: [],

  // General error catch all
  requestFailed: null
};

export default function addStudentsToClassroom(state = initialState, action = {}) {
  switch (action.type) {
    // Page load and clear actions
    case SET_SELECTED_STUDENTS:
      return {
        ...state,
        formState: formStates.REVIEW_SELECTIONS,
        selectedStudentIds: action.selectedStudentIds,
        enableStudentLimit: action.enableStudentLimit,
        studentLimit: action.studentLimit
      };
    case CLEAR_FORM:
      return {
        ...initialState
      };

    case REMOVE_STUDENT:
      return {
        ...state,
        selectedStudentIds: state.selectedStudentIds.filter(id => id !== action.id)
      };

    // Student Limit actions
    case SET_ENABLE_STUDENT_LIMIT:
      return {
        ...state,
        enableStudentLimit: action.enableStudentLimit
      };
    case SET_STUDENT_LIMIT:
      return {
        ...state,
        studentLimit: parseInt(action.studentLimit, 10) || 1
      };

    // Nav actions
    case ASSIGN_MATERIAL:
      return {
        ...state,
        formState: formStates.ASSIGNMATERIAL
      };
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case GOTO_STUDENT_SELECTION:
      return {
        ...state,
        formState: formStates.SELECTING
      };
    case SUBMISSION_COMPLETE:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        requestFailed: action.requestFailed,
        failedIds: action.failedIds
      };
    default:
      return state;
  }
}

// Page load and clear actions
export const setSelectedStudents = (selectedStudentIds, enableStudentLimit, studentLimit) => ({
  type: SET_SELECTED_STUDENTS,
  selectedStudentIds,
  enableStudentLimit,
  studentLimit
});
export const clearForm = () => ({
  type: CLEAR_FORM
});

export const removeStudent = id => ({
  type: REMOVE_STUDENT,
  id
});

// Student Limit actions
export const setEnableStudentLimit = enableStudentLimit => ({
  type: SET_ENABLE_STUDENT_LIMIT,
  enableStudentLimit
});
export const setStudentLimit = studentLimit => ({
  type: SET_STUDENT_LIMIT,
  studentLimit
});

// Nav actions
export const gotoStudentSelection = () => ({
  type: GOTO_STUDENT_SELECTION
});
export const submitForm = () => ({
  type: SUBMIT_FORM
});
export const submissionComplete = (requestFailed, failedIds) => ({
  type: SUBMISSION_COMPLETE,
  requestFailed,
  failedIds
});
export const assignMaterial = classId => ({
  type: ASSIGN_MATERIAL,
  classId
});
