import signedFetch from './util/signedFetch.js';

export default (groupType, groupId, userType, body) => {
  const formData = new FormData();
  const blob = new Blob([body.fileContents], { type: body.fileType });
  formData.append('fileContents', blob, body.fileName);

  const encodedGroupType = encodeURIComponent(groupType);
  const encodedGroupId = encodeURIComponent(groupId);
  const encodedUserType = encodeURIComponent(userType);

  return signedFetch(
    'bulkUploadByUserType',
    `${__API_BASE__}/${encodedGroupType}/${encodedGroupId}/users/bulk-import/${encodedUserType}`,
    'POST',
    formData,
    null,
    false,
    true
  );
};
