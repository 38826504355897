import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './MiddleRow.scss';
import CarouselControls from '../../../../components/CarouselControls/CarouselControls.js';

import { isHubMode } from '../../../../utils/platform';
import cmsContent from '../../../../utils/cmsContent.js';

const carouselPageRegex = /carouselPages_\d_heading_text/;

export default class MiddleRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carouselPage: 0,
      transitioning: false
    };

    // eslint-disable-next-line react/prop-types
    const { hubContent } = this.props;

    const CMS = !isHubMode() ? cmsContent.homepage || {} : hubContent;
    this.carouselItems = Object.keys(CMS)
      .filter(key => carouselPageRegex.test(key))
      .map((_, index) => ({
        heading: CMS[`carouselPages_${index}_heading_text`],
        body: CMS[`carouselPages_${index}_body_text`],
        imgSrc: CMS[`carouselPages_${index}_preview_img_src`],
        imgBg: CMS[`carouselPages_${index}_preview_img_bg`]
      }));
  }

  incrementPage = () => {
    const { carouselPage } = this.state;
    this.updatePage(carouselPage + 1);
  };

  decrementPage = () => {
    const { carouselPage } = this.state;
    this.updatePage(carouselPage - 1);
  };

  updatePage = newPage => {
    let postTransitionPage = newPage;

    if (newPage === this.carouselItems.length) {
      // Exceeded end of list
      postTransitionPage = 0;
    } else if (newPage === -1) {
      // Gone under the first list item
      postTransitionPage = this.carouselItems.length - 1;
    }

    this.setState({
      transitioning: true,
      carouselPage: newPage
    });

    setTimeout(
      () =>
        this.setState({
          transitioning: false,
          carouselPage: postTransitionPage
        }),
      600
    );
  };

  render() {
    const CMS = cmsContent.homepage || {};
    const { carouselPage, transitioning } = this.state;

    let currentPage = carouselPage;
    if (currentPage === this.carouselItems.length) {
      // About to cycle back around
      currentPage = 0;
    } else if (currentPage === -1) {
      // About to cycle back around
      currentPage = this.carouselItems.length - 1;
    }

    return (
      <div className={styles.middleSection}>
        <div className={`${isHubMode() ? styles.hubGrid : 'grid'}`}>
          <div className="row">
            <div className="col md8">
              <div className={styles.carouselImage}>
                <div className={styles.carouselMonitor}>
                  <div
                    className={classnames(styles.imageSlider, transitioning && styles.transitioning)}
                    style={{ width: `${this.carouselItems.length + 2}00%`, marginLeft: `-${carouselPage + 1}00%` }}
                  >
                    {/* Hidden overflow panel for the infinite carousel */}
                    <div
                      aria-hidden="true"
                      className={styles.image}
                      style={{
                        backgroundColor: this.carouselItems[this.carouselItems.length - 1].imgBg,
                        width: `${(1 / (this.carouselItems.length + 2)) * 100}%`
                      }}
                    >
                      <img aria-hidden="true" alt="" src={this.carouselItems[this.carouselItems.length - 1].imgSrc} />
                    </div>

                    {/* Actual carousel items */}
                    {this.carouselItems.map(({ imgSrc, imgBg }, index) => (
                      <div
                        key={index}
                        className={styles.image}
                        style={{ backgroundColor: imgBg, width: `${(1 / (this.carouselItems.length + 2)) * 100}%` }}
                      >
                        <img aria-hidden="true" alt="" src={imgSrc} />
                      </div>
                    ))}

                    {/* Hidden overflow panel for the infinite carousel */}
                    <div
                      aria-hidden="true"
                      className={styles.image}
                      style={{
                        backgroundColor: this.carouselItems[0].imgBg,
                        width: `${(1 / (this.carouselItems.length + 2)) * 100}%`
                      }}
                    >
                      <img aria-hidden="true" alt="" src={this.carouselItems[0].imgSrc} />
                    </div>
                  </div>
                </div>

                <img className={styles.screenBorder} aria-hidden="true" alt="" src={CMS.screen_border_base_image} />
              </div>
            </div>

            <div className={classnames('col md4', styles.carouselCol)}>
              <div className={styles.carousel}>
                <div className={styles.carouselText}>
                  <div
                    id="carousel-panels"
                    className={classnames(styles.imageSlider, transitioning && styles.transitioning)}
                    style={{ width: `${this.carouselItems.length + 2}00%`, marginLeft: `-${carouselPage + 1}00%` }}
                  >
                    {/* Hidden overflow panel for the infinite carousel */}
                    <div style={{ width: `${(1 / (this.carouselItems.length + 2)) * 100}%` }} aria-hidden="true">
                      <h2>{this.carouselItems[this.carouselItems.length - 1].heading}</h2>
                      <p>{this.carouselItems[this.carouselItems.length - 1].body}</p>
                    </div>

                    {/* Actual carousel items */}
                    {this.carouselItems.map(({ heading, body }, index) => (
                      <div
                        key={index}
                        style={{ width: `${(1 / (this.carouselItems.length + 2)) * 100}%` }}
                        aria-hidden={currentPage !== index}
                        aria-live={currentPage === index ? 'polite' : undefined}
                      >
                        <h2>{heading}</h2>
                        <p>{body}</p>
                      </div>
                    ))}

                    {/* Hidden overflow panel for the infinite carousel */}
                    <div style={{ width: `${(1 / (this.carouselItems.length + 2)) * 100}%` }} aria-hidden="true">
                      <h2>{this.carouselItems[0].heading}</h2>
                      <p>{this.carouselItems[0].body}</p>
                    </div>
                  </div>
                </div>
                <CarouselControls
                  id="carousel-controls"
                  ariaControls="carousel-panels"
                  current={currentPage}
                  prevButtonAction={!transitioning ? this.decrementPage : () => {}}
                  nextButtonAction={!transitioning ? this.incrementPage : () => {}}
                  items={this.carouselItems.map((_, index) => ({
                    action: !transitioning ? () => this.updatePage(index) : () => {}
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
