import * as t from '../actionTypes';

export const createOrgSubscriptionInput = input => ({
  type: t.CREATE_ORG_SUBSCRIPTION_INPUT,
  payload: input
});

export const createOrgSubscriptionValidate = input => ({
  type: t.CREATE_ORG_SUBSCRIPTION_VALIDATE,
  payload: input
});

export const createOrgSubscriptionValidated = input => ({
  type: t.CREATE_ORG_SUBSCRIPTION_VALIDATED,
  payload: input
});

export const createOrgSubscriptionRequest = input => ({
  type: t.CREATE_ORG_SUBSCRIPTION_REQUEST,
  payload: input
});

export const createOrgSubscriptionSuccess = subscriptionId => ({
  type: t.CREATE_ORG_SUBSCRIPTION_SUCCESS,
  payload: { subscriptionId }
});

export const createOrgSubscriptionFailure = errors => ({
  type: t.CREATE_ORG_SUBSCRIPTION_FAILURE,
  payload: errors
});

export const createOrgSubscriptionReset = () => ({
  type: t.CREATE_ORG_SUBSCRIPTION_RESET,
  payload: null
});

export const createOrgSubscriptionRepeat = input => ({
  type: t.CREATE_ORG_SUBSCRIPTION_REPEAT,
  payload: input
});

// update
export const updateOrgSubscriptionRequest = input => ({
  type: t.UPDATE_ORG_SUBSCRIPTION_REQUEST,
  payload: input
});

export const updateOrgSubscriptionSuccess = output => ({
  type: t.UPDATE_ORG_SUBSCRIPTION_SUCCESS,
  payload: output
});

export const updateOrgSubscriptionFailure = error => ({
  type: t.UPDATE_ORG_SUBSCRIPTION_FAILURE,
  payload: error
});

export const updateOrgSubscriptionClearFailure = () => ({
  type: t.UPDATE_ORG_SUBSCRIPTION_CLEAR_FAILURE,
  payload: null
});

export const updateOrgSubscriptionReset = () => ({
  type: t.UPDATE_ORG_SUBSCRIPTION_RESET,
  payload: null
});

export const validateUpdateOrgSubscriptionInput = (input, additionalProps) => ({
  type: t.VALIDATE_UPDATE_ORG_SUBSCRIPTION_INPUT,
  payload: { input, additionalProps }
});

export const validateUpdateOrgSubscriptionInputError = () => ({
  type: t.VALIDATE_UPDATE_ORG_SUBSCRIPTION_INPUT_ERROR
});

export const updateFormData = input => ({
  type: t.UPDATE_FORM_DATA,
  payload: input
});
