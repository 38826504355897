import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import ENTITY_TYPES from '../../../globals/entityTypes';
import EntityPageHeader from '../../../components/EntityPageHeader/EntityPageHeader';
import UrlControlledTabs from '../../../components/UrlControlledTabs/UrlControlledTabs';
import withDataRecency from '../../../dataRecency/withDataRecency';
import colors from '../../../globals/colors';
import withLocalizedContent from '../../../language/withLocalizedContent';
import StaffTab from '../tabs/StaffTab/StaffTab';
import userRoles from '../../../globals/userRoles';

class PendingOrganisation extends Component {
  componentDidMount() {
    const { location, history } = this.props;
    if (location.pathname.includes('students')) {
      history.push(location.pathname.replace('/students', ''));
    }
  }

  render() {
    const {
      localizedContent: { mySchoolPage: content },
      orgStaffDataRecency,
      location,
      organisationCustomId,
      organisationId,
      organisationName,
      params,
      userRole
    } = this.props;

    const items = [
      {
        urlName: 'schoolStaff',
        color: colors.COURSES,
        tabText: content.tabs_schoolStaff_text,
        isContentLoading: orgStaffDataRecency.syncing,
        isContentLoaded: orgStaffDataRecency.synced,
        panelContent: <StaffTab orgId={organisationId} tabName={params.tabName} panelName={params.panelName} />
      }
    ];

    return (
      <div className="pad-bot8">
        <EntityPageHeader
          entityType={ENTITY_TYPES.PENDING_ORGANISATION}
          entityTitle={organisationName}
          entitySubtitle={organisationCustomId}
          displayEditButton={userRole && userRole !== userRoles.OUP_ADMIN && userRole !== userRoles.OUP_SUPPORT}
        />
        <UrlControlledTabs
          backgroundColor={colors.PRIMARY_BLUE}
          tabName={params.tabName}
          pathname={location.pathname}
          items={items}
        />
      </div>
    );
  }
}

PendingOrganisation.propTypes = {
  orgStaffDataRecency: PropTypes.object,
  location: PropTypes.any,
  history: PropTypes.any,
  organisationCustomId: PropTypes.string.isRequired,
  organisationId: PropTypes.string.isRequired,
  organisationName: PropTypes.string.isRequired,
  params: PropTypes.any,
  localizedContent: PropTypes.object.isRequired,
  userRole: PropTypes.string
};

export default compose(
  withRouter,
  withDataRecency('orgStaff'),
  withLocalizedContent('mySchoolPage'),
  connect((state, props) => {
    const organisation = state.organisations.data[props.organisationId] || {};
    const userRole = state.identity.role;
    return {
      organisationName: organisation.name || '',
      organisationCustomId: organisation.customId,
      userRole
    };
  })
)(PendingOrganisation);
