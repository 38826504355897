import { takeLatest } from 'redux-saga/effects';

import getCoursePublishList from './getCoursePublishList';
import { GET_COURSE_PUBLISH_REQUEST } from '../../../../actionTypes';

function* root() {
  yield takeLatest(GET_COURSE_PUBLISH_REQUEST, ({ payload }) => getCoursePublishList(payload));
}

export default root;
