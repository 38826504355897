import { put, select, take } from 'redux-saga/effects';
import userRoles from '../../../../../globals/userRoles';
import manageSelfEnrolRequest from '../../../api/manageSelfEnrolRequest';
import { CLEAR_FORM, submissionComplete, SUBMIT_FORM } from '../../../../reducers/manageJoinAClassRequest.reducer';
import { pollClassStudentsUpdated } from '../dataRecency/pollClassStudents';

export default function* manageJoinAClassRequest() {
  console.log('[manageJoinAClassRequest Saga] Beginning');

  while (true) {
    console.log('[manageJoinAClassRequest Saga] Waiting for user to submit form');
    yield take(SUBMIT_FORM);

    console.log('[manageJoinAClassRequest Saga] Form submitted. capturing data from state...');
    const { organisationId, selectedStudents, requestStatus, requested } = yield select(state => ({
      organisationId: state.classroomPage.orgId,
      requestStatus: state.manageJoinAClassRequest.requestStatus,
      selectedStudents: state.manageJoinAClassRequest.submittedIds,
      requested: state.manageJoinAClassRequest.requested,
      people: state.people.data
    }));

    const classRequest = yield manageSelfEnrolRequest(organisationId, {
      roleName: userRoles.LEARNER,
      invitationStatus: requestStatus,
      requested,
      userIds: selectedStudents
    });
    const requestFailed = classRequest.status !== 'success';
    const failedIds = (classRequest.data || {}).failedIds || [];

    console.log('[manageJoinAClassRequest Saga] Edit Class request complete, showing confirmation page');
    yield put(submissionComplete(requestFailed, requestFailed ? selectedStudents : failedIds));

    if (!requestFailed) {
      const successfullyStudentIds = selectedStudents.filter(id => !failedIds.includes(id));
      yield pollClassStudentsUpdated(successfullyStudentIds);
    }

    yield take(CLEAR_FORM);
    console.log('[manageJoinAClassRequest Saga] Form closed, resetting state and saga');
  }
}
