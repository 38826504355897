import signedFetch from '../util/signedFetch.js';

export default ({ sessionId, userId }) => {
  const queryParams = new URLSearchParams();

  if (userId) queryParams.set('userId', userId);

  const URL = `${__API_BASE__}/placementTest/${sessionId}/results/download`;

  return signedFetch('loadPlacementTestResults', URL, 'GET');
};
