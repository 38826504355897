/** Classroom reducer
 *  This reducer contains state related to the current app classroom. This
 *  includes details of the classroom's app account if the classroom is logged in.
 */
import cmsContent from '../../utils/cmsContent.js';
import { isHubMode } from '../../utils/platform.js';

// Name input actions
const VALIDATED_CLASSROOM_NAME = 'classroomCreate/VALIDATED_CLASSROOM_NAME';
const VALIDATE_CLASSROOM_NAME_ON_BLUR = 'classroomCreate/VALIDATE_CLASSROOM_NAME_ON_BLUR';
export const SET_CLASSROOM_NAME = 'classroomCreate/SET_CLASSROOM_NAME';
export const SET_CLASSROOM_NAME_EDIT = 'classroomCreate/SET_CLASSROOM_NAME_EDIT';
export const CLASSROOM_NAME_ON_BLUR = 'classroomCreate/CLASSROOM_NAME_ON_BLUR';

// Selection submit search actions
const SUBMIT_TEACHERS = 'classroomCreate/SUBMIT_TEACHERS';
const SUBMIT_STUDENTS = 'classroomCreate/SUBMIT_STUDENTS';

// User removal actions
const REMOVE_TEACHER = 'classroomCreate/REMOVE_TEACHER';
const REMOVE_STUDENT = 'classroomCreate/REMOVE_STUDENT';

// Student Limit actions
const SET_ENABLE_STUDENT_LIMIT = 'classroomCreate/SET_ENABLE_STUDENT_LIMIT';
const SET_STUDENT_LIMIT = 'classroomCreate/SET_STUDENT_LIMIT';

// Form page change actions
const GOTO_NAME_INPUT = 'classroomCreate/GOTO_NAME_INPUT';
const GOTO_TEACHER_SELECTION = 'classroomCreate/GOTO_TEACHER_SELECTION';
const GOTO_STUDENT_SELECTION = 'classroomCreate/GOTO_STUDENT_SELECTION';

// Form submission and response
export const CREATE_CLASS = 'classroomCreate/CREATE_CLASS';
const SHOW_CONFIRMATION = 'classroomCreate/SHOW_CONFIRMATION';

export const ASSIGN_MATERIAL = 'classroomCreate/ASSIGN_MATERIAL';

// Reset the form
export const CLOSE_FORM = 'classroomCreate/CLOSE_FORM';

const SET_PRESELECTED_TEACHER = 'classroomCreate/SET_PRESELECTED_TEACHER';
const CLEAR_PRESELECTED_TEACHER = 'classroomCreate/CLEAR_PRESELECTED_TEACHER';

export const formStates = {
  CHOOSE_CLASSNAME: 'CHOOSE_CLASSNAME', // While adding name of new class.
  CHOOSE_TEACHERS: 'CHOOSE_TEACHERS',
  CHOOSE_STUDENTS: 'CHOOSE_STUDENTS',
  REVIEW_SELECTIONS: 'REVIEW_SELECTIONS',
  SUBMITTING: 'SUBMITTING',
  ASSIGNMATERIAL: 'ASSIGNMATERIAL',
  CONFIRMATION: 'CONFIRMATION' // Success|Fail feedback after SUBMITTING
};

const initialState = {
  formState: formStates.CHOOSE_CLASSNAME,

  // Classroom Name and its many validation states
  classroomNameValue: '',
  classroomNameValidationMessage: '',
  classroomNameValidationPending: false,
  classroomNameValidationIsInvalid: false,
  classroomNameValidationIsWarning: false,
  classroomNameValidationIsValid: false,

  // List of teachers and students to add
  selectedTeacherIds: [],
  selectedStudentIds: [],

  // Optional student limit
  enableStudentLimit: false,
  studentLimit: 1,

  // Results of the creation attempt
  createdClassroomId: null,
  failedUserIds: [],

  //
  editTempNewClassId: ''
};

export default function classroomCreate(state = initialState, action = {}) {
  const CMS = cmsContent.classroomState || {};
  switch (action.type) {
    case SET_PRESELECTED_TEACHER:
      // When a user logs in, if they are a teacher we prepare them as a selected user
      return {
        ...state,
        selectedTeacherIds: [action.userId]
      };
    case CLEAR_PRESELECTED_TEACHER:
      // When a user logs in, if they are a teacher we prepare them as a selected user
      return {
        ...state,
        selectedTeacherIds: []
      };
    case SET_CLASSROOM_NAME: {
      const actualClassroomNameLength = action.classroomName.trim().length;

      return {
        ...state,
        classroomNameValue: action.classroomName,

        classroomNameValidationPending: actualClassroomNameLength > 0,
        classroomNameValidationIsInvalid: actualClassroomNameLength === 0,
        classroomNameValidationIsWarning: false,
        classroomNameValidationIsValid: false,
        classroomNameValidationMessage: actualClassroomNameLength === 0 ? CMS.class_length_error : ''
      };
    }
    case SET_CLASSROOM_NAME_EDIT: {
      return {
        ...state,
        classroomNameValue: action.classroomName,
        editTempNewClassId: action.id
      };
    }

    case VALIDATED_CLASSROOM_NAME: {
      let errorMessage = '';

      // If the name is in use set a message
      if (action.exists) {
        errorMessage = CMS.class_name_duplicate;
      } else if (!isHubMode() && action.invalidClassName) {
        errorMessage = CMS.class_name_special_character_error;
      } else if (isHubMode() && action.specialCharacterError) {
        errorMessage = CMS.class_name_special_character_error;
      } else if (action.apiError) {
        errorMessage = CMS.unable_to_check_class_name;
      }

      return {
        ...state,
        classroomNameValidationPending: false,
        classroomNameValidationIsInvalid: false || action.invalidClassName,
        classroomNameValidationIsWarning: action.exists || action.apiError,
        classroomNameValidationIsValid: !action.exists && !action.apiError && !action.invalidClassName,
        classroomNameValidationMessage: errorMessage
      };
    }

    case VALIDATE_CLASSROOM_NAME_ON_BLUR: {
      const { emptyInput } = action;
      return {
        ...state,
        classroomNameValidationPending: false,
        classroomNameValidationIsInvalid: emptyInput,
        classroomNameValidationIsValid: !emptyInput,
        classroomNameValidationMessage: emptyInput ? CMS.class_length_error : null
      };
    }

    // Submit selections
    case SUBMIT_TEACHERS:
      return {
        ...state,
        formState: formStates.CHOOSE_STUDENTS,
        selectedTeacherIds: action.selectedTeacherIds
      };

    case SUBMIT_STUDENTS:
      return {
        ...state,
        formState: formStates.REVIEW_SELECTIONS,
        selectedStudentIds: action.selectedStudentIds,
        enableStudentLimit: action.enableStudentLimit,
        studentLimit: action.studentLimit
      };

    // Remove individual people in review
    case REMOVE_TEACHER:
      return {
        ...state,
        selectedTeacherIds: state.selectedTeacherIds.filter(id => id !== action.id)
      };

    case REMOVE_STUDENT:
      return {
        ...state,
        selectedStudentIds: state.selectedStudentIds.filter(id => id !== action.id)
      };

    case SET_ENABLE_STUDENT_LIMIT:
      return {
        ...state,
        enableStudentLimit: action.enableStudentLimit
      };
    case SET_STUDENT_LIMIT:
      return {
        ...state,
        studentLimit: parseInt(action.studentLimit, 10) || 1
      };

    // Navigation actions
    case GOTO_NAME_INPUT:
      return {
        ...state,
        formState: formStates.CHOOSE_CLASSNAME
      };
    case GOTO_TEACHER_SELECTION:
      return {
        ...state,
        formState: formStates.CHOOSE_TEACHERS
      };
    case GOTO_STUDENT_SELECTION:
      return {
        ...state,
        formState: formStates.CHOOSE_STUDENTS
      };

    // Temporarily removed while dev-ing final step:
    case CREATE_CLASS:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };

    // After Class has been created:
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        createdClassroomId: action.createdClassroomId,
        failedUserIds: action.failedUserIds
      };
    case ASSIGN_MATERIAL:
      return {
        ...state,
        formState: formStates.ASSIGNMATERIAL
      };
    case CLOSE_FORM:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

// Name Actions
export const setClassroomName = classroomName => ({
  type: SET_CLASSROOM_NAME,
  classroomName
});

export const setClassroomNameEdit = (classroomName, id) => ({
  type: SET_CLASSROOM_NAME_EDIT,
  classroomName,
  id
});

export const classroomNameOnBlur = () => ({
  type: CLASSROOM_NAME_ON_BLUR
});

export const setClassroomNameValidated = ({ exists, apiError, invalidClassName, specialCharacterError }) => ({
  type: VALIDATED_CLASSROOM_NAME,
  exists,
  apiError,
  invalidClassName,
  specialCharacterError
});

export const setClassroomNameOnBlurValidation = emptyInput => ({
  type: VALIDATE_CLASSROOM_NAME_ON_BLUR,
  emptyInput
});

// Selection submission
export const submitTeachers = selectedTeacherIds => ({
  type: SUBMIT_TEACHERS,
  selectedTeacherIds
});

export const submitStudents = (selectedStudentIds, enableStudentLimit, studentLimit) => ({
  type: SUBMIT_STUDENTS,
  selectedStudentIds,
  enableStudentLimit,
  studentLimit
});

// Remove individual users
export const removeTeacher = id => ({
  type: REMOVE_TEACHER,
  id
});

export const removeStudent = id => ({
  type: REMOVE_STUDENT,
  id
});

// Student Limit actions
export const setEnableStudentLimit = enableStudentLimit => ({
  type: SET_ENABLE_STUDENT_LIMIT,
  enableStudentLimit
});

export const setStudentLimit = studentLimit => ({
  type: SET_STUDENT_LIMIT,
  studentLimit
});

// Navigation Actions
export const gotoNameInput = () => ({
  type: GOTO_NAME_INPUT
});
export const gotoTeacherSelection = () => ({
  type: GOTO_TEACHER_SELECTION
});
export const gotoStudentSelection = () => ({
  type: GOTO_STUDENT_SELECTION
});

// Form submission and response
export const createClass = () => ({
  type: CREATE_CLASS
});

export const showConfirmation = (createdClassroomId, failedUserIds) => ({
  type: SHOW_CONFIRMATION,
  createdClassroomId,
  failedUserIds
});

// Reset action
export const closeForm = () => ({
  type: CLOSE_FORM
});

export const setPreselectedTeacher = userId => ({
  type: SET_PRESELECTED_TEACHER,
  userId
});

export const clearPreselectedTeacher = () => ({
  type: CLEAR_PRESELECTED_TEACHER
});
export const assignMaterial = classId => ({
  type: ASSIGN_MATERIAL,
  classId
});
