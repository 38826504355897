import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import PopoutPanelIconHeading, {
  types
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import { SidePanel } from '../../../../../../components';
import AddClassroomConfirmation from '../../../../../../components/AddClassroomConfirmation/AddClassroomConfirmation';
import USER_ROLES from '../../../../../../globals/userRoles';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
import StudentSelectionPanel from '../../../../../../panels/StudentSelectionPanel';
import TeacherSelectionPanel from '../../../../../../panels/TeacherSelectionPanel';
import {
  assignMaterial,
  closeForm,
  gotoNameInput,
  gotoTeacherSelection,
  submitStudents,
  submitTeachers
} from '../../../../../../redux/reducers/classroomCreate.js';
import AssignLearningMaterial from '../../../../../panels/AssignLearningMaterial/AssignLearningMaterial';
import { CONTEXT_OPTIONS } from '../../../../../../views/UserSelectionView';
import AddClassroomNaming from './AddClassroomNaming';
import AddClassroomReview from './AddClassroomReview';
import { closeCreateClassModal } from '../../../../../../redux/actions/hubUi';
import { featureIsEnabled } from '../../../../../../globals/envSettings.js';
import { isHubMode } from '../../../../../../utils/platform.js';
import ProductFinderPanel from '../../../../../../components/ProductFinder/ProductFinderPanel.js';

class AddClassroom extends React.Component {
  _handleOnComplete = () => {
    const { onComplete, resetForm } = this.props;
    resetForm();
    onComplete();
  };

  _handleClosePanel = () => {
    const { closePopoutAction } = this.props;

    closePopoutAction();
  };

  _handleTryAgain = () => {
    const { teacherBackAction, resetForm } = this.props;
    teacherBackAction();
    resetForm();
  };

  render() {
    const {
      orgId,
      orgRole,
      formState,
      classroomEditingState,
      createdClassroomId,
      classroomName,
      teachers,
      students,
      failedUserIds,
      assignMaterialAction,
      closePopoutAction,
      teacherBackAction,
      studentBackAction,
      studentSubmitAction,
      teacherSubmitAction,
      localizedContent: { addClassPanel: content },
      goToMyClass,
      selectedStudentIds,
      selectedTeacherIds,
      classId
    } = this.props;

    const initialLimitToggleValue = classroomEditingState.enableStudentLimit || false;
    const initialLimitValue = parseInt(classroomEditingState.studentLimit, 10) || 1;

    switch (formState) {
      case 'CHOOSE_CLASSNAME':
        return <AddClassroomNaming closePopoutAction={closePopoutAction} />;
      case 'CHOOSE_TEACHERS':
        return (
          <TeacherSelectionPanel
            orgId={orgId}
            closePopoutAction={closePopoutAction}
            context={CONTEXT_OPTIONS.CREATE_CLASS}
            classroomEditingState={classroomEditingState}
            initialFilter={[USER_ROLES.TEACHER, USER_ROLES.TEACHER_ADMIN, USER_ROLES.ORG_ADMIN]}
            submitAction={teacherSubmitAction}
            backAction={teacherBackAction}
          />
        );
      case 'CHOOSE_STUDENTS':
        return (
          <StudentSelectionPanel
            orgId={orgId}
            closePopoutAction={closePopoutAction}
            context={CONTEXT_OPTIONS.CREATE_CLASS}
            classroomEditingState={classroomEditingState}
            initialRoles={[USER_ROLES.LEARNER, USER_ROLES.MANAGED_USER]}
            submitAction={studentSubmitAction}
            backAction={studentBackAction}
            initialLimitToggleValue={initialLimitToggleValue}
            initialLimitValue={initialLimitValue}
          />
        );
      case 'REVIEW_SELECTIONS':
        return <AddClassroomReview closePopoutAction={closePopoutAction} />;
      case 'SUBMITTING':
        return (
          <PopoutPanelIconHeading
            type={types.LOADING}
            title={content.loading_page_title}
            subtitle={content.loading_page_subtitle}
          />
        );
      case 'CONFIRMATION':
        return (
          <SidePanel isOpen onClose={this._handleOnComplete}>
            <AddClassroomConfirmation
              orgRole={orgRole}
              orgId={orgId}
              createdClassroomId={createdClassroomId}
              classroomName={classroomName}
              teachers={teachers}
              students={students}
              failedUserIds={failedUserIds}
              onAddMaterialClick={() => assignMaterialAction(createdClassroomId)}
              onDoneClick={this._handleOnComplete}
              goToMyClass={goToMyClass}
              goToNameInputPanel={this._handleTryAgain}
              formState={formState}
            />
          </SidePanel>
        );
      case 'ASSIGNMATERIAL':
        return featureIsEnabled('product-finder-refactor') && isHubMode() ? (
          <ProductFinderPanel
            orgId={orgId}
            classId={classId}
            selectedUsers={{ teacherIdList: [...selectedTeacherIds], studentIdList: [...selectedStudentIds] }}
            contextName={classroomName}
            onClose={this._handleClosePanel}
            onComplete={this._handleOnComplete}
            // needed for polling. will be removed when polling is removed
            context="CLASS_CREATION"
          />
        ) : (
          <AssignLearningMaterial
            orgId={orgId}
            context="CLASS_CREATION"
            closePopoutAction={this._handleClosePanel}
            onComplete={this._handleOnComplete}
          />
        );

      default:
        return (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }
  }
}

AddClassroom.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  orgRole: PropTypes.any,
  closePopoutAction: PropTypes.func.isRequired,
  formState: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
  classroomEditingState: PropTypes.object.isRequired,
  teacherBackAction: PropTypes.func.isRequired,
  studentBackAction: PropTypes.func.isRequired,
  teacherSubmitAction: PropTypes.func.isRequired,
  studentSubmitAction: PropTypes.func.isRequired,
  createdClassroomId: PropTypes.string.isRequired,
  classroomName: PropTypes.string.isRequired,
  teachers: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired,
  failedUserIds: PropTypes.array.isRequired,
  assignMaterialAction: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  goToMyClass: PropTypes.func.isRequired,
  selectedStudentIds: PropTypes.array,
  selectedTeacherIds: PropTypes.array,
  classId: PropTypes.string
};

export default compose(
  withRouter,
  withLocalizedContent('addClassPanel'),
  connect(
    state => {
      const people = (state.people || {}).data;
      const classroomCreate = state.classroomCreate || {};
      return {
        formState: classroomCreate.formState,
        classroomEditingState: classroomCreate,
        classroomName: classroomCreate.classroomNameValue,
        createdClassroomId: classroomCreate.createdClassroomId,
        selectedStudentIds: classroomCreate.selectedStudentIds,
        selectedTeacherIds: classroomCreate.selectedStudentIds,
        classId: classroomCreate.createdClassroomId,
        teachers: pick(people, classroomCreate.selectedTeacherIds),
        students: pick(people, classroomCreate.selectedStudentIds),
        failedUserIds: classroomCreate.failedUserIds || []
      };
    },
    (dispatch, props) => ({
      assignMaterialAction: createdClassroomId => dispatch(assignMaterial(createdClassroomId)),
      resetForm: () => dispatch(closeForm()),
      goToMyClass: url => {
        dispatch(closeCreateClassModal());
        dispatch(closeForm());
        props.history.push(url);
      },
      teacherBackAction: () => dispatch(gotoNameInput()),
      studentBackAction: () => dispatch(gotoTeacherSelection()),
      studentSubmitAction: (selectedStudentIds, enableStudentLimit, studentLimit) =>
        dispatch(submitStudents(selectedStudentIds, enableStudentLimit, studentLimit)),
      teacherSubmitAction: (selectedTeacherIds, enableTeacherLimit, teacherLimit) =>
        dispatch(submitTeachers(selectedTeacherIds, enableTeacherLimit, teacherLimit))
    })
  )
)(AddClassroom);
