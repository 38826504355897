import signedFetch from '../util/signedFetch.js';

/**
 * Get user details.
 *  Request params:
 *  - orgId: Integer - The organisation to check for user membership and invitation status in
 *  - userId: Integer - The user’s id
 */
export default (orgId, userId, allDetails = false) =>
  signedFetch(
    'getManagedUserDetails',
    `${__API_BASE__}/ngs/org/${encodeURIComponent(orgId)}/signin-card-details`,
    'POST',
    { userIds: userId, allDetails }
  );
