import { put } from 'redux-saga/effects';
import qs from 'query-string';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { getProductMetadata } from '../../../../../utils/platform';
import { featureIsEnabled } from '../../../../../globals/envSettings';

export const getLevelPanelUrl = (orgId, classId, locationId, subject) =>
  `${__API_BASE__}/gradebook/org/${orgId}/class/${classId}/level-report?${qs.stringify({
    locationId,
    subject
  })}`;

function* getLevelPanel(orgId, classId, studentId, levelId, locationId) {
  const subject = getProductMetadata('orb', 'subject');
  const response = yield signedFetch('getLevelPanel', getLevelPanelUrl(orgId, classId, locationId, subject), 'POST', {
    studentId: [studentId],
    levelId: [levelId]
  });
  if (response.status === 'success') {
    if (featureIsEnabled('mat-report-change-for-reducers')) {
      yield put(actions.gradebookLevelPanelSuccessV2(response.data, orgId));
    } else {
      yield put(actions.gradebookLevelPanelSuccess(response.data));
    }
    return;
  }
  if (featureIsEnabled('mat-report-change-for-reducers')) {
    yield put(actions.gradebookLevelPanelErrorV2(response.message, orgId));
  } else {
    yield put(actions.gradebookLevelPanelError(response.error));
  }
}

export default getLevelPanel;
