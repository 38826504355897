import { takeEvery, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import enrolUser from './enrolUser';
import validateUserEnrolInput from './validateUserEnrolInput';
import triggerDownloadSigninCard from './downloadSigninCard';

function* root() {
  // @ts-ignore
  yield takeEvery(t.USER_ENROL_VALIDATE_INPUT, ({ payload: { orgId, input } }) => validateUserEnrolInput(orgId, input));
  // @ts-ignore
  yield takeLatest(t.USER_ENROL_REQUEST, ({ payload: { orgId, user } }) => enrolUser(orgId, user));
  yield takeLatest(t.USER_ENROL_DOWNLOAD_SIGN_IN_CARD, triggerDownloadSigninCard);
}

export default root;
