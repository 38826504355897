import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { isHubMode } from '../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';

function SendInvitationReminderFailure({
  localizedContent: { sendInvitationReminderFailureComponent: content },
  onDone
}) {
  return (
    <ScrollContainer
      footerContent={
        <PopoutActionFooter
          secondaryButtonText={content.back_button}
          secondaryButtonAction={onDone}
          primaryButtonText={content.done_button}
          primaryButtonAction={onDone}
          wizardButtons
        />
      }
    >
      {isHubMode() ? (
        <PopoutPanelIllustrationHeading
          title={[content.title]}
          subtitle={[content.subtitle]}
          illustrationSrc={HubIllustrationConstants.WARNING}
          illustrationAltText={HubIllustrationAltText.WARNING}
        />
      ) : (
        <PopoutPanelIconHeading
          type={popoutPanelIconHeadingTypes.WARNING}
          title={[content.title]}
          subtitle={[content.subtitle]}
        />
      )}
    </ScrollContainer>
  );
}

SendInvitationReminderFailure.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired
};

export default withLocalizedContent('sendInvitationReminderFailureComponent')(SendInvitationReminderFailure);
