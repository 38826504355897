import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIconHeading, {
  types as HEADING_ICON_TYPES
} from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';

import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../globals/hubConstants.js';
import ConnectedPersonRepresentation from '../../../../components/PersonRepresentation/ConnectedPersonRepresentation.js';
import { GLYPHS } from '../../../../components/Thumbnail/Thumbnail.js';
import userRoles from '../../../../globals/userRoles';

// Action imports
import { resetForm } from '../../../../redux/reducers/user/licenceRecall.reducer.js';

import content from '../../../../utils/cmsContent.js';
import { isHubMode } from '../../../../utils/platform.js';
// const content = {
//   licenceRecallPage: {
//     button_close_text: 'Close',
//     button_submit_text: 'Recall licences',

//     beforeSubmit_heading_text: 'Are you sure you want to recall this licence from the following students?',
//     beforeSubmit_subheading_text: 'Any recalled licences will be returned to the library.',

//     duringSubmit_heading_text: 'Please wait...',
//     duringSubmit_subheading_text: 'The licences are being recalled',

//     afterSubmit_success_heading_text: 'You recalled this licence from the following students',
//     afterSubmit_success_subheading_text: 'The licences have been returned to the library',
//     afterSubmit_error_heading_text: 'There was an error.',
//     afterSubmit_error_subheading_text: 'No licences have been recalled.'
//   }
// };

function LicenceRecallComplete({ errorOccurred, userId, closePanel, resetFormAction, isStudent }) {
  const CMS = content.licenceRecallPage || {};

  let popoutPanel;
  if (isHubMode()) {
    popoutPanel = errorOccurred ? (
      <PopoutPanelIllustrationHeading
        title={CMS.afterSubmit_error_heading_text}
        subtitle={CMS.afterSubmit_error_subheading_text}
        type={HEADING_ICON_TYPES.ERROR}
        illustrationSrc={HubIllustrationConstants.ERROR}
        illustrationAltText={HubIllustrationAltText.ERROR}
      />
    ) : (
      <PopoutPanelIllustrationHeading
        title={isStudent ? CMS.afterSubmit_success_heading_text : CMS.afterSubmit_success_heading_text_staff}
        subtitle={CMS.afterSubmit_success_subheading_text}
        type={HEADING_ICON_TYPES.VALID}
        illustrationSrc={HubIllustrationConstants.SUCCESS}
        illustrationAltText={HubIllustrationAltText.SUCCESS}
      />
    );
  } else {
    popoutPanel = errorOccurred ? (
      <PopoutPanelIconHeading
        title={CMS.afterSubmit_error_heading_text}
        subtitle={CMS.afterSubmit_error_subheading_text}
        type={HEADING_ICON_TYPES.ERROR}
      />
    ) : (
      <PopoutPanelIconHeading
        title={isStudent ? CMS.afterSubmit_success_heading_text : CMS.afterSubmit_success_heading_text_staff}
        subtitle={CMS.afterSubmit_success_subheading_text}
        type={HEADING_ICON_TYPES.VALID}
      />
    );
  }

  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={CMS.button_close_text} action={closePanel} />
          </div>
          {popoutPanel}
        </div>
      }
      footerContent={
        <PopoutActionFooter
          primaryButtonAction={() => {
            // Reset the form after it has closed
            closePanel();
            setTimeout(resetFormAction, 300);
          }}
          primaryButtonText="Done"
        />
      }
    >
      <div>
        <div className="gin2">
          <ConnectedPersonRepresentation
            entityId={userId}
            secondaryThumbnailGlyph={errorOccurred ? GLYPHS.ICON_ERROR_CIRCLE : GLYPHS.ICON_CHECK_CIRCLE}
          />
        </div>
      </div>
    </ScrollContainer>
  );
}

LicenceRecallComplete.propTypes = {
  errorOccurred: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  closePanel: PropTypes.func.isRequired,
  resetFormAction: PropTypes.func.isRequired,
  isStudent: PropTypes.bool.isRequired
};

export default connect(
  ({ licenceRecall, people }) => ({
    errorOccurred: licenceRecall.failed,
    userId: licenceRecall.userId,
    isStudent:
      people && people.data && licenceRecall.userId && people.data[licenceRecall.userId].roleName === userRoles.LEARNER
  }),
  { resetFormAction: resetForm }
)(LicenceRecallComplete);
