import { put, select } from 'redux-saga/effects';
import actions from '../../../../actions';
import createActivationCodeApi from '../../../apiCalls/createActivationCodeApi';

function* createActivationCode(data = false) {
  const { allowedUsage, batchName } = yield select(state => ({
    allowedUsage: state.activationCodeDashboard.allowedUsage,
    batchName: state.activationCodeDashboard.batchName
  }));

  if (data && typeof data !== 'object') {
    throw new Error('createActivationCode parameter must be an Object');
  }
  let body = {};

  if (data?.payload && Object.keys(data.payload).length) {
    body = {
      ...data.payload
    };

    let apiResults = {};

    apiResults = yield createActivationCodeApi(body);

    if (apiResults.error || apiResults.status !== 'success') {
      yield put(actions.createCodeBatchFailure({ error: apiResults.message }));
    } else {
      yield put(actions.createCodeBatchSuccess({ success: apiResults.data }));
    }
  } else {
    const nowDate = new Date();
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    body = {
      numberOfTokens: 1,
      tokenValidFrom: nowDate.toISOString().slice(0, 10),
      tokenValidTo: oneYearFromNow.toISOString().slice(0, 10),
      productId: '2621cfe3-bce1-462b-a980-755c4c21ab19',
      licenseStartDate: nowDate.toISOString().slice(0, 10),
      licenseEndDate: oneYearFromNow.toISOString().slice(0, 10),
      licenseType: 'ROLLING',
      totalConcurrency: 2,
      userConcurrency: 2,
      quantity: 1,
      timePeriod: 1,
      unitType: 'year',
      beginOn: 'creation'
    };
    body.allowedUsages = parseInt(allowedUsage, 10) || 1;
    body.batchName = batchName;

    let apiResults = {};

    apiResults = yield createActivationCodeApi(body);

    if (apiResults.error || apiResults.status !== 'success') {
      yield put(actions.setErrorForDashboard({ error: apiResults.message }));
    } else {
      yield put(actions.setResponseForDashboard({ success: apiResults.data.activationCodes[0] }));
    }
  }
}

export default createActivationCode;
