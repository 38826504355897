import * as t from '../../actionTypes';

const initialState = {
  data: {},
  submitting: false,
  success: false,
  error: false
};

function ngsTeacherReports(state = initialState, { type, payload }) {
  switch (type) {
    case t.NGS_TEACHER_REPORTS_REQUEST:
      return {
        ...state,
        submitting: true,
        success: false,
        error: false
      };
    case t.NGS_TEACHER_REPORTS_SUCCESS:
      return {
        ...state,
        data: payload,
        submitting: false,
        success: true,
        error: false
      };
    case t.NGS_TEACHER_REPORTS_FAILURE:
      return {
        ...state,
        submitting: false,
        error: true,
        success: false
      };
    default:
      return state;
  }
}

export default ngsTeacherReports;
