import { put } from 'redux-saga/effects';
import deleteProductRequest from '../../../apiCalls/deleteProduct';
import { deleteProductSuccess, deleteProductFailure } from '../../../../actions/deleteProduct';

function* deleteProduct(params) {
  const response = yield deleteProductRequest(params);
  if (response.status === 'success') {
    yield put(deleteProductSuccess(response.data));
  } else {
    yield put(deleteProductFailure('Failed to delete the product'));
  }
}

export default deleteProduct;
