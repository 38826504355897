import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './PageWrapper.scss';

function PageWrapper({ children, limitedWidth }) {
  return (
    <div className={styles.root}>
      <div className={classnames('grid', { grid_max_width: limitedWidth })}>
        <div className="row">
          <div className="col">{children}</div>
        </div>
      </div>
    </div>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  limitedWidth: PropTypes.bool
};

PageWrapper.defaultProps = {
  limitedWidth: false
};

export default PageWrapper;
