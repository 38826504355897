import { toQueryString } from '../../../../utils/url';
import signedFetch from '../util/signedFetch';

// eslint-disable-next-line func-names
export default function*(params) {
  const baseUrl = `${__API_BASE__}/gradebook/org/${params.orgId}`;
  const queryString = toQueryString({ cache: 'true' });

  if (params.itemId) {
    return yield signedFetch(
      'getGradedReaderLearnerView',
      `${baseUrl}/class/${params.classroomId}/user/${params.itemId}/graded-readers-learner-report${queryString}`
    );
  }
  if (params.panelName) {
    return yield signedFetch(
      'getGradedReaderClassView',
      `${baseUrl}/class/${params.classroomId}/graded-readers-class-report${queryString}`
    );
  }

  return yield signedFetch(
    'gradedReaderProductView',
    `${baseUrl}/class/${params.classroomId}/graded-reader-summary-report${queryString}`
  );
}
