// asyncConsole is used to batch up a group of async console.logs and output them all at once.
// It prevents noise from other console.logs from appearing inside a console.group.
// The downside is that no part of the group will output until groupEnd() or timeout.

// To use asyncConsole:
// import console from '../../utils/console/asyncConsoleGroup.js';

// We look for this special keyword in console.group(name, 'ASYNC') to trigger a custom log group:
const SPECIAL_TRIGGER_WORD = 'ASYNC';

// To display alongside a timed-out group of logs:
const ASYNC_CONSOLE_TIMEOUT = 'Async console timed out';

// This will cache the queued log commands until groupEnd() or timeout:
const waitingFor = {};
const customConsole = {};

// // Deprecated because iOS crashes if you try to call copies of console methods:
// const pimpedMethods = ['log', 'info', 'warn', 'error', 'group', 'groupCollapsed', 'groupEnd', 'native'];
// const nativeMethods = pimpedMethods.reduce((cloned, method) => {
//   try {
//     if (console[method]) cloned[method] = console[method];
//   } catch (err) {
//     cloned[method] = function dummy() {};
//   }
//   return cloned;
// }, {});

// Helper to initialise settings for a new console.group batch:
function createNewBatchGroup(groupName, groupMethod, timeout) {
  waitingFor[groupName] = {
    queue: [],
    groupMethod,
    startTime: new Date().getTime(),
    timerId: setTimeout(
      () => customConsole.groupEnd(groupName, ASYNC_CONSOLE_TIMEOUT),
      timeout || customConsole.timeout
    )
  };
}

// Generic handler for every custom console.log(), info(), warn(), error():
function log(method, ...args) {
  const groupName = args.length > 1 && args[0];

  if (groupName && waitingFor[groupName]) {
    waitingFor[groupName].queue.push({ method, args: args.slice(1) });
  } else {
    window.console.log(...args);
  }
}

// Generic handler for every custom console.group() or groupCollapsed():
function group(groupMethod, ...args) {
  // Look for "ASYNC" as the last param -or- "ASYNC" & timeout as the last two params.
  const isAsync =
    args.length > 1 &&
    (args[args.length - 1] === SPECIAL_TRIGGER_WORD ||
      (args.length > 2 && args[args.length - 2] === SPECIAL_TRIGGER_WORD && parseInt(args[args.length - 1], 10)));

  if (isAsync) {
    const groupName = args[0];
    const timeout = parseInt(args[args.length - 1], 10);
    if (!waitingFor[groupName]) createNewBatchGroup(groupName, groupMethod, timeout);
  } else {
    window.console[groupMethod](...args);
  }
}

// Custom version of groupEnd():
// If groupName is an async batch then output the whole group at once.
// In which case we assume the 2nd arg is an optional reason for ending the group, such as an error or timeout.
function groupEnd(...args) {
  const groupName = args[0];
  const logGroup = groupName && waitingFor[groupName];

  if (logGroup) {
    const reason = args[1];
    const duration = (new Date().getTime() - logGroup.startTime) / 1000;
    const batchTitle = `${groupName} [${duration.toFixed(2)}s] ${reason ? `(${reason})` : ''}`;
    const expandGroup = customConsole.autoExpandError && logGroup.queue.some(item => item.method === 'error');
    const groupMethod = expandGroup ? 'group' : logGroup.groupMethod;

    // Housekeeping:
    clearTimeout(logGroup.timerId);
    delete waitingFor[groupName];

    // Output entire group all at once
    console[groupMethod](batchTitle);
    logGroup.queue.forEach(item => window.console[item.method](...item.args));
    console.groupEnd(batchTitle);
  } else {
    window.console.groupEnd(...args);
  }
}

// Define methods on our custom console object:
Object.assign(customConsole, {
  // Expose default timeout for convenience:
  timeout: 5000,

  // When true, a console.groupCollapsed will be output as console.group if it contains a console.error:
  autoExpandError: true,

  group: group.bind(this, 'group'),
  groupCollapsed: group.bind(this, 'groupCollapsed'),
  groupEnd,

  log: log.bind(this, 'log'),
  info: log.bind(this, 'info'),
  warn: log.bind(this, 'warn'),
  error: log.bind(this, 'error')

  // // Deprecated because iOS crashes if you try to call copies of console methods:
  // // CONTROVERTIAL: Helper to override native console methods with our custom ones:
  // overrideNative: function overrideNative() {
  //   let i = pimpedMethods.length;
  //   // eslint-disable-next-line no-plusplus
  //   while (i--) {
  //     const method = pimpedMethods[i];
  //     try {
  //       console[method] = customConsole[method];
  //       // eslint-disable-next-line no-empty
  //     } catch (e) {}
  //   }
  // }
});

export default customConsole;
