import React from 'react';
import PropTypes from 'prop-types';
// Icons
import SVGIcon, { validGlyphs } from '../SVGIcon/SVGIcon';
// Style
import styles from './ImpersonationAccountButton.scss';

function ImpersonationAccountButton({
  buttonElementId,
  icon,
  onClick = () => {},
  ariaLabel = '',
  disabled = false,
  isOpen
}) {
  return (
    <button
      id={buttonElementId}
      type="button"
      className={`${styles.buttonContainer} ${isOpen ? styles.open : ''}`}
      onClick={onClick}
      disabled={disabled}
      aria-disabled={disabled}
      aria-label={ariaLabel}
    >
      {icon && <SVGIcon glyph={icon} />}
    </button>
  );
}

export default ImpersonationAccountButton;

ImpersonationAccountButton.propTypes = {
  buttonElementId: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(validGlyphs).isRequired,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool
};
