import React from 'react';
import PropTypes from 'prop-types';
import PageHeading from '../../components/PageHeading/PageHeading';

function UserProfileTeacherArchived({ text = '', thumbnail }) {
  return (
    <div className="gin2 gin-top8 cols-center">
      <div className="row gin-bot2 cols-center">{thumbnail}</div>
      <div className="row cols-center">
        <PageHeading title={text} />
      </div>
    </div>
  );
}
UserProfileTeacherArchived.propTypes = {
  text: PropTypes.string,
  thumbnail: PropTypes.node.isRequired
};

export default UserProfileTeacherArchived;
