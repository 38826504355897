import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import withLocalizedContent from '../../language/withLocalizedContent';
import DataRefresherButton from './DataRefresherButton';
import DataRefresherLabel from './DataRefresherLabel';
// Style
import styles from './DataRefresher.scss';

class DataRefresher extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      interval: null,
      timer: 0,
      colorClass: 'greenLabel',
      labelShown: ''
    };
  }

  componentDidMount() {
    this._startRunningTimer();
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props;
    if (prevProps.loading === true && loading === false) {
      this._clearTimer();
      this._startRunningTimer();
    }
  }

  componentWillUnmount() {
    this._clearTimer();
  }

  _generateLabel = timer => {
    const {
      localizedContent: { hubGlossary: content }
    } = this.props;
    switch (timer) {
      case 30:
        this.setState({
          labelShown: [
            content.data_refresher_last_updated,
            timer,
            content.data_refresher_seconds,
            content.data_refresher_ago
          ].join(' '),
          colorClass: 'greenLabel'
        });
        break;
      case 60:
        this.setState({
          labelShown: [
            content.data_refresher_last_updated,
            timer / 60,
            content.data_refresher_minute,
            content.data_refresher_ago
          ].join(' '),
          colorClass: 'greenLabel'
        });
        break;
      case 300:
        this.setState({
          labelShown: [
            content.data_refresher_last_updated,
            content.data_refresher_over,
            timer / 60,
            content.data_refresher_minutes,
            content.data_refresher_ago
          ].join(' '),
          colorClass: 'orangeLabel'
        });
        break;
      default:
        this.setState({
          labelShown: [
            content.data_refresher_last_updated,
            timer / 60,
            content.data_refresher_minutes,
            content.data_refresher_ago
          ].join(' '),
          colorClass: 'greenLabel'
        });
        break;
    }
  };

  _runInterval = () => {
    const { timer } = this.state;

    this.setState({ timer: timer + 30 });

    if (timer === 30 || (timer % 60 === 0 && timer > 0 && timer < 300)) {
      this._generateLabel(timer);
    } else if (timer === 300) {
      this._generateLabel(timer);
      this._clearTimer();
    }
  };

  _clearTimer = () => {
    const { interval } = this.state;
    clearInterval(interval);
    this.setState({ timer: 0, interval: null });
  };

  _startRunningTimer = () => {
    const { localizedContent } = this.props;
    const { hubGlossary: content } = localizedContent;
    this.setState({
      timer: 30,
      labelShown: content.data_refresher_up_to_date,
      colorClass: 'greenLabel',
      interval: setInterval(this._runInterval, 30000)
    });
  };

  _triggerRefresh = async () => {
    const { refreshData, callback } = this.props;
    const { interval } = this.state;
    let response;
    if (typeof refreshData === 'function') {
      response = await refreshData();
    } else if (refreshData && refreshData.length) {
      response = await Promise.all(refreshData);
    }
    if (callback) {
      callback(response);
    }
    if (interval != null) {
      this._clearTimer();
    }
    this._startRunningTimer();
  };

  render() {
    const { localizedContent, showLabel, noSidePadding } = this.props;
    const { colorClass, interval, timer, labelShown } = this.state;
    const { hubGlossary: content } = localizedContent;
    return (
      <>
        {showLabel && (
          <div className={!noSidePadding ? styles.labelRow : classnames(styles.labelRow, styles.noSidePadding)}>
            <span className={styles.labelChange}>{content.data_refresher_made_change}</span>
            {content.data_refresher_refresh_again}
          </div>
        )}
        <div className={!noSidePadding ? styles.refreshBar : classnames(styles.refreshBar, styles.noSidePadding)}>
          <DataRefresherButton onClick={this._triggerRefresh} label={content.data_refresher_refresh_data} />
          <DataRefresherLabel colorClass={colorClass} interval={interval} timer={timer} label={labelShown} />
        </div>
      </>
    );
  }
}

DataRefresher.propTypes = {
  refreshData: PropTypes.func,
  callback: PropTypes.func,
  showLabel: PropTypes.bool,
  noSidePadding: PropTypes.bool,
  loading: PropTypes.bool,
  localizedContent: PropTypes.object
};

DataRefresher.defaultProps = {
  showLabel: true,
  noSidePadding: false,
  loading: false
};
export default compose(withLocalizedContent('hubGlossary'))(DataRefresher);
