import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import store from '../../store.js';

import { setRenewedAuthCredentials } from '../../reducers/identity.reducer.js';
import { embedCredsReady } from '../../reducers/app.reducer.js';
import { isLocal, isDev, getIntegratedOrigins, isOIDCStandard } from '../../../globals/envSettings';
import authSettings from '../../../globals/authSettings.js';
import initAuth0 from '../../../globals/oidcSettings';
import session from '../../../utils/session';
// import { postNotificationMessage } from '../../actions/postMessage';

async function frameHandler(command, data, history) {
  if (command === 'goto') {
    const newRoute = data;
    console.log(`[COMMAND:goto] ${newRoute}`);
    history.push(newRoute);
  } else if (command === 'update-session-info') {
    const sessionInfo = data;
    console.log(`[COMMAND:update-session-info]`, sessionInfo);

    if (!sessionInfo.expiryTime) {
      console.log('[COMMAND:update-session-info] Session sent invalid');
      return;
    }

    const expiryTime = moment(sessionInfo.expiryTime);
    const now = moment();

    if (expiryTime.isBefore(now)) {
      console.log(`[COMMAND:update-session-info] session expired`);
      return;
    }

    const localSessionInfo = session.get();
    if (localSessionInfo && localSessionInfo.expiryTime) {
      // check if localStorage token expiry time is greater then received data
      const localExpiryTime = moment(localSessionInfo.expiryTime);
      if (localExpiryTime.isAfter(expiryTime)) {
        console.log(`[COMMAND:update-session-info] already have latest credentials`);
        return;
      }
    }

    if (isOIDCStandard(authSettings.idpStandard)) {
      if (!sessionInfo.idToken) {
        // Message payload is wrong or user is not logged-in in sender
        console.log(`[COMMAND:update-session-info] id missing`);
        return;
      }

      // Check stored auth0 with the incomming session.
      await initAuth0().then(async auth0 => {
        try {
          const claims = await auth0.getIdTokenClaims();
          const auth0UserId = claims && claims['https://edu.oup.com/UserId'];
          const userId = sessionInfo.userId;

          if (auth0UserId && userId && auth0UserId !== userId) {
            await auth0.logout({ openUrl: false });
          }
        } catch (error) {
          console.log('Error authorising', error);
        }
      });

      const sessInfo = session.get();

      // Old properties
      sessInfo.oidcIdToken = sessionInfo.idToken;
      sessInfo.oidcAccessToken = sessionInfo.accessToken;
      sessInfo.oidcRefreshToken = sessionInfo.refreshToken;
      sessInfo.oidcExpiresIn = sessionInfo.expiresIn;
      sessInfo.oidcSessionClock = sessionInfo.expiryTime;
      sessInfo.expiryTime = sessionInfo.expiryTime;

      // New properties
      sessInfo.oidc.idToken = sessionInfo.idToken;
      sessInfo.oidc.accessToken = sessionInfo.accessToken;
      sessInfo.oidc.refreshToken = sessionInfo.refreshToken;
      sessInfo.oidc.expiresIn = sessionInfo.expiresIn;
      sessInfo.oidc.sessionClock = sessionInfo.expiryTime;

      session.set(sessInfo);
    } else {
      // Update stored creds
      store.dispatch(
        setRenewedAuthCredentials(
          sessionInfo.accessKeyId,
          sessionInfo.secretAccessKey,
          sessionInfo.sessionToken,
          sessionInfo.expiryTime
        )
      );

      const sessInfo = session.get();

      sessInfo.saml.accessKeyId = sessionInfo.accessKeyId;
      sessInfo.saml.secretAccessKey = sessionInfo.secretAccessKey;
      sessInfo.saml.sessionToken = sessionInfo.sessionToken;
      sessInfo.saml.expiryTime = sessionInfo.expiryTime;

      session.set({ ...sessInfo, ...sessionInfo });
    }

    localStorage.setItem('embed-creds-status', 'loaded');

    store.dispatch(embedCredsReady());
  } else if (command === 'clear-session-info') {
    console.log(`[COMMAND:clear-session-info]`);
    session.clear();
    localStorage.removeItem('embed-creds-status');
  } /* else if (command === 'acknowledge-embed') {
    console.log(`[COMMAND:acknowledge-embed]`);
    store.dispatch(postNotificationMessage({ eventName: 'APP_WAITING_FOR_CREDS', viewId: data }));
  } */ else {
    console.log(`[EMBED] unknown command '${command}'`, data);
  }
}

export default function listenForCommands() {
  console.log('[listenForCommands]');
  window.addEventListener(
    'message',
    async (event = {}) => {
      const sourceOrigin = event.origin;
      const payload = event.data;

      // these origins can command ACES to change route and send it new credentials so must be trusted
      const integratedOrigins = getIntegratedOrigins();

      if (isLocal() || isDev()) {
        // allow commands if this is a local server or DEV in the case of a non-EPS local test suite testing against DEV
        integratedOrigins.push('http://localhost:7700');
        // allow one additional "different platform" port for testing EPS alongside another app, e.g. Kerboodle
        integratedOrigins.push('http://localhost:3000');
      }

      const isAnInstruction =
        integratedOrigins.indexOf(sourceOrigin) >= 0 &&
        typeof payload === 'object' &&
        payload.type === 'frame-instruction';

      if (isAnInstruction) {
        await frameHandler(payload.command, payload.data, window.history);
      }
    },
    false
  );
}
