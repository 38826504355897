import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import style from './StudentReportCard.scss';
import StudentAttainmentTable from '../StudentAttainmentTable/StudentAttainmentTable';
import ProgressText from '../ProgressText/ProgressText';
import CoachingCard from '../CoachingCard/CoachingCard';

function StudentReportCard({
  className,
  status,
  title,
  type,
  detailReport,
  coachingBookData = null,
  tableHeaders,
  tableRows,
  tableEmptyMessage
}) {
  return (
    <div className={className}>
      <div className={style.titleRow}>
        <strong className={style.title}>{title}</strong>
        {status && (
          <ProgressText
            type={type}
            status={status}
            customIconClass={style.statusIcon}
            isCurrentLevel
            variables={{ name: title.toLowerCase() }}
          />
        )}
      </div>
      <div className={style.blockContainer}>
        <div className={classnames(style.leftColumn, 'col', 'sm4')}>{detailReport}</div>
        <div className={classnames(style.rightColumn, 'col', 'sm8')}>
          {coachingBookData ? (
            <div className="gin-bot2">
              <CoachingCard {...coachingBookData} />
            </div>
          ) : null}
          <StudentAttainmentTable rows={tableRows} columns={tableHeaders} emptyMessage={tableEmptyMessage} />
        </div>
      </div>
    </div>
  );
}

StudentReportCard.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  detailReport: PropTypes.element.isRequired,
  coachingBookData: PropTypes.shape({
    readCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    clickedPercentage: PropTypes.string.isRequired,
    scorePercentage: PropTypes.string.isRequired
  }),
  tableHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string
    })
  ).isRequired,
  tableRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableEmptyMessage: PropTypes.string
};

export default StudentReportCard;
