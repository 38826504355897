import { put } from 'redux-saga/effects';
import actions from '../../../../../actions';

import signedFetch from '../../../../apiCalls/util/signedFetch';

export function* validateEntitiesSyncedRequest(apiRequest) {
  try {
    const response = yield signedFetch(
      apiRequest.constantString,
      apiRequest.apiUrl,
      apiRequest.requestType,
      apiRequest.body
    );
    return response;
  } catch (err) {
    yield put(actions.pollEntitiesSyncedFailure());
    return null;
  }
}

export default validateEntitiesSyncedRequest;
