import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Utils
import { sanitizeUrl } from '../../utils/url.js';
import { PRODUCT_TARGET_USERTYPE } from '../../../sharedNodeBrowser/constants';
import getDropdownItems from '../HubDropdownMenu/Services/getDropdownItems';

// Styles
import styles from './ProductFinder.scss';

// Component imports
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Link from '../Link/Link';
import Thumbnail, { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail.js';
import HubDropdownMenu from '../HubDropdownMenu/HubDropdownMenu.js';

function ProductFinderLicence({ product, licenceDetails = {}, content, dropdownActions, assignLicences }) {
  const [licenceLabel, setLicenceLabel] = useState('');
  const [licencesToBeUsed, setLicencesToBeUsed] = useState(0);
  const [allUsersHaveLicence, setAllUsersHaveLicence] = useState(false);

  const doAllAssignedUsersHaveLicence = (firstArray, secondArray) =>
    secondArray.every(item => firstArray.indexOf(item) !== -1);

  const getProductLicenceStatus = (licencesNumber, usersHaveLicence, targetUserType) => {
    switch (true) {
      case usersHaveLicence:
        return (
          <span className={styles.containerLicenceUsed}>
            <SVGIcon className={styles.checkIcon} glyph={GLYPHS.ICON_CHECK_CIRCLE} />
            {targetUserType === PRODUCT_TARGET_USERTYPE.TEACHER && content.all_teachers_have_licence}
            {targetUserType === PRODUCT_TARGET_USERTYPE.STUDENT && content.all_students_have_licence}
            {!targetUserType && content.all_users_have_licence}
            <Link openInNewTab to={content.what_is_a_licence} className={styles.link} underlined>
              {content.learn_more}
              <SVGIcon glyph={GLYPHS.ICON_LINK} className={styles.linkGlyph} />
            </Link>
          </span>
        );
      case licencesNumber === 1 && assignLicences:
        return (
          <span className={styles.containerLicenceUsed}>
            <SVGIcon className={styles.checkIcon} glyph={GLYPHS.ICON_CHECK_CIRCLE} />
            {`${licencesNumber} ${content.licence_will_be_used}`}
            <Link openInNewTab to={content.use_a_code} className={styles.link} underlined>
              {content.learn_more}
              <SVGIcon glyph={GLYPHS.ICON_LINK} className={styles.linkGlyph} />
            </Link>
          </span>
        );
      case licencesNumber > 1 && assignLicences:
        return (
          <span className={styles.containerLicenceUsed}>
            <SVGIcon className={styles.checkIcon} glyph={GLYPHS.ICON_CHECK_CIRCLE} />
            {`${licencesNumber} ${content.licences_will_be_used}`}
            <Link openInNewTab to={content.use_a_code} className={styles.link} underlined>
              {content.learn_more}
              <SVGIcon glyph={GLYPHS.ICON_LINK} className={styles.linkGlyph} />
            </Link>
          </span>
        );
      default:
        return (
          <span className={styles.containerLicenceNotUsed}>
            <SVGIcon className={styles.infoIcon} glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
            {targetUserType === PRODUCT_TARGET_USERTYPE.TEACHER && content.teachers_can_redeem}
            {targetUserType === PRODUCT_TARGET_USERTYPE.STUDENT && content.students_can_redeem}
            {!targetUserType && content.students_and_teachers_can_redeem}
            <Link openInNewTab to={content.use_a_code} className={styles.link} underlined>
              {content.learn_more}
              <SVGIcon glyph={GLYPHS.ICON_LINK} className={styles.linkGlyph} />
            </Link>
          </span>
        );
    }
  };

  useEffect(() => {
    if (product?.target_usertype === PRODUCT_TARGET_USERTYPE.TEACHER) {
      setLicenceLabel(content.teacher_material);
    } else if (product?.target_usertype === PRODUCT_TARGET_USERTYPE.STUDENT) {
      setLicenceLabel(content.student_material);
    }
  }, []);

  useEffect(() => {
    const licencesNeeded = licenceDetails.licencesTypes.reduce(
      (acc, currentValue) => acc + currentValue.amountAssigning,
      0
    );
    setLicencesToBeUsed(licencesNeeded);
    const targetUserType = product?.target_usertype;
    let usersToBeAssigned = [];
    switch (targetUserType) {
      case PRODUCT_TARGET_USERTYPE.STUDENT:
        usersToBeAssigned = [...licenceDetails.studentIds];
        break;
      case PRODUCT_TARGET_USERTYPE.TEACHER:
        usersToBeAssigned = [...licenceDetails.teacherIds];
        break;
      default:
        usersToBeAssigned = [...licenceDetails.studentIds, ...licenceDetails.teacherIds];
    }
    const doAllUsersHaveLicence = doAllAssignedUsersHaveLicence(
      [...licenceDetails.usersIdsWithLicences, ...licenceDetails.userIdsWithoutPermission],
      usersToBeAssigned
    );
    if (doAllUsersHaveLicence) {
      setAllUsersHaveLicence(true);
    } else {
      setAllUsersHaveLicence(false);
    }
  });

  const imageSrc = product?.cover ? sanitizeUrl(product.cover) : sanitizeUrl(product.coverImage);

  return (
    <div className={styles.productLicence}>
      <div>
        <Thumbnail size={thumbnailSizes.TABLE} imageSrc={imageSrc} squareImg />
      </div>
      <div className={styles.productLicenceTextContainer}>
        {licenceLabel && <span className={styles.productLicenceLabel}>{licenceLabel}</span>}
        <p className={styles.productLicenceTitle}>{product.title}</p>
        <div className={styles.productLicenceStatus}>
          {getProductLicenceStatus(licencesToBeUsed, allUsersHaveLicence, product.target_usertype)}
        </div>
      </div>
      <HubDropdownMenu
        customClassname={styles.dropDownMenu}
        dropdownData={getDropdownItems('productLicences', content, {}, dropdownActions)}
        parentId={product.productid}
      />
    </div>
  );
}

ProductFinderLicence.propTypes = {
  product: PropTypes.object,
  content: PropTypes.object,
  dropdownActions: PropTypes.object,
  licenceDetails: PropTypes.object,
  assignLicences: PropTypes.bool
};

export default ProductFinderLicence;
