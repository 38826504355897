import { useState } from 'react';

function useLicenseState() {
  const [licenseStartDate, setLicenseStartDate] = useState('');
  const [licenseEndDate, setLicenseEndDate] = useState('');
  const [licenseType, setLicenseType] = useState('ROLLING');
  const [totalConcurrency, setTotalConcurrency] = useState(1);
  const [userConcurrency, setUserConcurrency] = useState(1);
  const [licenseInfo, setLicenseInfo] = useState(false);
  const [beginOn, setBeginOn] = useState('First Use');
  const [unitType, setUnitType] = useState('Year');
  const [timePeriod, setTimePeriod] = useState(1);
  const [licenseAllowedUsage, setLicenseAllowedUsage] = useState(1);

  const setStartDateFunction = dateParam => setLicenseStartDate(dateParam);

  const setEndDateFucntion = dateParam => setLicenseEndDate(dateParam);

  const setLicenseTypeFunction = param => setLicenseType(param);

  const setTotalConcurrencyFunction = param => setTotalConcurrency(param);

  const setUserConcurrencyFunction = param => setUserConcurrency(param);

  const setLicenseInfoFunction = param => setLicenseInfo(param);

  const setBeginOnFunction = param => setBeginOn(param);

  const setUnitTypeFunction = param => setUnitType(param);

  const setTimePeriodFunction = param => setTimePeriod(param);

  const setLicenseAllowedUsageFunction = param => setLicenseAllowedUsage(param);

  const resetAllFields = () => {
    setStartDateFunction('');
    setEndDateFucntion('');
    setLicenseTypeFunction('ROLLING');
    setTotalConcurrencyFunction('');
    setUserConcurrencyFunction('');
    setLicenseInfoFunction('');
    setBeginOnFunction('First Use');
    setUnitTypeFunction('Year');
    setTimePeriodFunction(1);
    setLicenseAllowedUsageFunction('');
  };
  const setLicenseDetails = () => ({
    setStartDateFunction,
    setEndDateFucntion,
    setLicenseTypeFunction,
    setTotalConcurrencyFunction,
    setUserConcurrencyFunction,
    setLicenseInfoFunction,
    setBeginOnFunction,
    setUnitTypeFunction,
    resetAllFields,
    setTimePeriodFunction,
    setLicenseAllowedUsageFunction
  });

  const licenseDetails = {
    licenseType,
    beginOn,
    unitType,
    timePeriod,
    licenseStartDate,
    licenseEndDate,
    licenseAllowedUsage,
    totalConcurrency,
    userConcurrency
  };

  if (licenseInfo) {
    licenseDetails.licenseInfo = licenseInfo;
  }

  return [licenseDetails, setLicenseDetails];
}

export default useLicenseState;
