import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import SampleOrgUniqueId from '../SampleOrgUniqueId/SampleOrgUniqueId';
import styles from './OrgUniqueIdGenerationField.scss';

class OrgUniqueIdGenerationField extends Component {
  async componentDidUpdate(prevProps) {
    const { orgUniqueId, onChange = () => {}, onBlur = () => {} } = this.props;
    if (prevProps.orgUniqueId !== orgUniqueId) {
      await onChange(orgUniqueId);
      onBlur();
    }
  }

  _getInputField = () => {
    const {
      localizedContent: { orgUniqueIdGenerationField: content },
      orgUniqueId,
      disabled = false,
      readOnly = false,
      onSubmit = () => {}
    } = this.props;
    switch (true) {
      case disabled:
        return <input id="orgUniqueId" type="text" className={styles.disabled} value={orgUniqueId} disabled />;
      case readOnly:
        return <div>{orgUniqueId || 'n/a'}</div>;
      default:
        return (
          <div className={styles.card}>
            <div className={styles.field}>
              <input
                id="orgUniqueId"
                type="text"
                className={styles.input}
                placeholder="School ID"
                value={orgUniqueId}
              />
              <span className={styles.generate}>
                <button id="generate-id-button" className={styles.button} type="button" onClick={onSubmit}>
                  {content.submit_button}
                </button>
              </span>
            </div>
            <p className={styles.caption}>{content.input_caption}</p>
            <SampleOrgUniqueId orgUniqueId={orgUniqueId} />
          </div>
        );
    }
  };

  render() {
    const {
      localizedContent: { orgUniqueIdGenerationField: content }
    } = this.props;

    return (
      <div className={styles.container}>
        <label className={styles.label} htmlFor="orgUniqueId">
          {content.input_label}
        </label>
        {this._getInputField()}
      </div>
    );
  }
}

OrgUniqueIdGenerationField.propTypes = {
  localizedContent: PropTypes.object,
  orgUniqueId: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onSubmit: PropTypes.func
};

export default withLocalizedContent('orgUniqueIdGenerationField')(OrgUniqueIdGenerationField);
