import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './TextInputLabel.scss';

/** This component is for the input label only. It should always be used with TextInputField. */
function TextInputLabel({ id, label, labelHidden, ariaLabel }) {
  return (
    <label
      className={classnames(styles.textInputLabel, { 'a11y-hide': labelHidden })}
      htmlFor={id}
      aria-label={ariaLabel || label}
    >
      {label}
    </label>
  );
}

TextInputLabel.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** the inputs text label */
  label: PropTypes.string.isRequired,
  /** The label can be hidden to users and only visible to screen readers */
  labelHidden: PropTypes.bool,
  ariaLabel: PropTypes.string
};

export default TextInputLabel;
