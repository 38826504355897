import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

function* oicUpdateTestCredits(input) {
  const { orgId, productId, testCredits: credits } = input;

  const response = yield signedFetch(
    'oicUpdateTestCredits',
    `${__API_BASE__}/org/${orgId}/oic/test-credits/${productId}`,
    'PUT',
    { credits: parseInt(credits, 10) }
  );

  if (response.status === 'success') {
    const { credits: creditsUpdated } = response.data;
    return yield put(actions.updateOicTestCreditsSuccess({ orgId, productId, testCredits: creditsUpdated }));
  }
  return yield put(actions.updateOicTestCreditsFailure({ productId, errors: response.message }));
}

export default oicUpdateTestCredits;
