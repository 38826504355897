import { toString } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isLevelGreaterThan } from '../../utils/reading-buddy';
import NavigatorCard from '../NavigatorCard/NavigatorCard';
import DataCard from '../DataCard/DataCard';
import LevelChangePrompt from '../LevelChangePrompt/LevelChangePrompt';
import styles from './ReadingBuddyTable.scss';

export default class ReadingBuddyTable extends Component {
  componentDidMount() {
    this.el.querySelector('[tabindex]:not([tabindex="-1"])').focus();
  }

  _renderLevelChangePrompt() {
    const {
      levelChange: { newLevel, newLevelName, days, delayPeriod },
      studentName,
      // eslint-disable-next-line camelcase
      navigation_data: { current_level },
      onLevelUpConfirmation,
      onLevelDownConfirmation,
      onLevelChangeDelay,
      onLevelReset
    } = this.props;

    const currentLevel = toString(current_level);

    return (
      <LevelChangePrompt
        studentName={studentName}
        currentLevel={currentLevel}
        newLevel={toString(newLevel)}
        newLevelName={newLevelName}
        newLevelDaysRemaining={days}
        newLevelDelayPeriod={delayPeriod}
        onConfirm={
          isLevelGreaterThan(toString(newLevel), currentLevel) ? onLevelUpConfirmation : onLevelDownConfirmation
        }
        onCancel={onLevelChangeDelay}
        onLevelReset={onLevelReset}
      />
    );
  }

  render() {
    const {
      // eslint-disable-next-line
      number_of_attempt,
      onNavigateLeft,
      onNavigateRight,
      // eslint-disable-next-line
      navigation_data,
      // eslint-disable-next-line
      datacard_data,
      navigateLeftDisabled,
      navigateRightDisabled,
      isCurrentLevel,
      // eslint-disable-next-line
      levelChange,
      previousAttempts
    } = this.props;

    return (
      <div
        className={styles.navigatorFormat}
        ref={el => {
          this.el = el;
        }}
      >
        <NavigatorCard
          // eslint-disable-next-line
          number_of_attempt={number_of_attempt}
          onNavigateLeft={onNavigateLeft}
          onNavigateRight={onNavigateRight}
          // eslint-disable-next-line
          navigation_data={navigation_data}
          // eslint-disable-next-line
          datacard_data={datacard_data}
          navigateLeftDisabled={navigateLeftDisabled}
          navigateRightDisabled={navigateRightDisabled}
        />
        {/* eslint-disable-next-line */}
        <DataCard cardData={datacard_data} previousAttempts={previousAttempts} isCurrentLevel={isCurrentLevel} />
        {levelChange ? this._renderLevelChangePrompt() : null}
      </div>
    );
  }
}

ReadingBuddyTable.propTypes = {
  /** If the data being displayed is a previous attempt then this is the number of that attempt */
  number_of_attempt: PropTypes.number,
  navigation_data: PropTypes.shape({
    /** Whether the currently viewed data is of a previous attempt at a level */
    previous_attempt: PropTypes.bool,
    /** If the viewed data is a previous attempt at when did the attempt start & end */
    previous_attempt_date_start: PropTypes.string,
    previous_attempt_date_end: PropTypes.string,
    /** Number of the the level currently being viewed by the user */
    currently_viewed_level: PropTypes.number.isRequired,
    /** The current level of the actual student being viewed */
    current_level: PropTypes.number.isRequired,
    /** The current level name of the actual student being viewed */
    current_level_name: PropTypes.string.isRequired,
    /** If the currently viewed level is completed, at what date was it completed */
    current_level_completion_date: PropTypes.string,
    /** The level of expected attainment of the student */
    level_expected: PropTypes.string.isRequired,
    viewing_current_level: PropTypes.bool.isRequired
  }),
  datacard_data: PropTypes.shape({
    /** Number of days the student has spent at this level */
    days_at_level: PropTypes.number.isRequired,
    /** Expected number of days the student is to spend at this level */
    expected_days_at_level: PropTypes.number.isRequired,
    /** Amount of quizzes the student has passed */
    quizzes_passed: PropTypes.number.isRequired,
    /** Expected number of quizzes passed */
    quizzes_expected: PropTypes.number.isRequired,
    /** Number of quizzes the completed */
    quizzes_completed: PropTypes.number.isRequired,
    /** Quiz score average, in percent */
    quizzes_average: PropTypes.string.isRequired,
    quizzes_pass_score: PropTypes.string.isRequired,
    /** Whether the student has skipped the level currently being viewed */
    skipped_level: PropTypes.bool.isRequired,
    /** If the currently viewed level has been reset, when was the date & time of the last reset */
    reset_date: PropTypes.string,
    completeness: PropTypes.bool
  }),
  previousAttempts: PropTypes.arrayOf(
    PropTypes.shape({
      attemptEndDate: PropTypes.string,
      attemptId: PropTypes.number.isRequired,
      attemptStartDate: PropTypes.string,
      status: PropTypes.string.isRequired,
      days: PropTypes.shape({
        count: PropTypes.number,
        expected: PropTypes.number
      }),
      quizzes: PropTypes.shape({
        passScore: PropTypes.shape({
          percentage: PropTypes.string
        }),
        passed: PropTypes.number,
        average: PropTypes.shape({
          percentage: PropTypes.string
        }),
        completed: PropTypes.number,
        expected: PropTypes.number
      })
    })
  ),
  /** Funciton fired when user click left pointing caret in navigator panel */
  navigateLeftDisabled: PropTypes.bool,
  /** Disablediton fired when user click right pointing caret in navigator panel */
  navigateRightDisabled: PropTypes.bool,
  /** Is the currently viewed level the current level of the student? */
  isCurrentLevel: PropTypes.bool,
  /** Details around if a student is in the levelling up or down window */
  levelChange: PropTypes.shape({
    newLevel: PropTypes.number,
    inDays: PropTypes.number,
    newLevelName: PropTypes.string,
    days: PropTypes.number,
    delayPeriod: PropTypes.number
  }),
  onLevelUpConfirmation: PropTypes.func,
  onLevelDownConfirmation: PropTypes.func,
  onLevelChangeDelay: PropTypes.func,
  onNavigateLeft: PropTypes.func,
  onNavigateRight: PropTypes.func,
  studentName: PropTypes.string,
  onLevelReset: PropTypes.func.isRequired
};
