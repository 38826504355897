import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Component imports
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import PopoutPanelIconHeading, {
  types as HEADING_ICON_TYPES
} from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';

import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../globals/hubConstants.js';
import ConnectedPersonRepresentation from '../../../../components/PersonRepresentation/ConnectedPersonRepresentation.js';
import userRoles from '../../../../globals/userRoles';

import content from '../../../../utils/cmsContent.js';
// const content = {
//   licenceRecallPage: {
//     button_close_text: 'Close',
//     button_submit_text: 'Recall licences',

//     beforeSubmit_heading_text: 'Are you sure you want to recall this licence from the following students?',
//     beforeSubmit_subheading_text: 'Any recalled licences will be returned to the library.',

//     duringSubmit_heading_text: 'Please wait...',
//     duringSubmit_subheading_text: 'The licences are being recalled',

//     afterSubmit_success_heading_text: 'You recalled this licence from the following students',
//     afterSubmit_success_subheading_text: 'The licences have been returned to the library',
//     afterSubmit_error_heading_text: 'There was an error.',
//     afterSubmit_error_subheading_text: 'No licences have been recalled.'
//   }
// };

// Action imports
import { submitForm, resetForm } from '../../../../redux/reducers/user/licenceRecall.reducer.js';
import { isHubMode } from '../../../../utils/platform.js';

function LicenceRecallInputting({ closePanel, userId, submitFormAction, isStudent }) {
  const CMS = content.licenceRecallPage || {};

  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} action={closePanel} text={CMS.button_close_text} />
            </div>
            {isHubMode() ? (
              <PopoutPanelIllustrationHeading
                type={HEADING_ICON_TYPES.WARNING}
                title={isStudent ? CMS.beforeSubmit_heading_text : CMS.beforeSubmit_heading_text_staff}
                subtitle={CMS.beforeSubmit_subheading_text}
                illustrationSrc={HubIllustrationConstants.WARNING}
                illustrationAltText={HubIllustrationAltText.WARNING}
              />
            ) : (
              <PopoutPanelIconHeading
                type={HEADING_ICON_TYPES.WARNING}
                title={isStudent ? CMS.beforeSubmit_heading_text : CMS.beforeSubmit_heading_text_staff}
                subtitle={CMS.beforeSubmit_subheading_text}
              />
            )}
          </div>
        }
        footerContent={
          <PopoutActionFooter primaryButtonAction={submitFormAction} primaryButtonText={CMS.button_submit_text} />
        }
      >
        <div className="pad2">
          <div className="gin2">
            {console.log(userId)}
            <ConnectedPersonRepresentation entityId={userId} />
          </div>
        </div>
      </ScrollContainer>
    </form>
  );
}

LicenceRecallInputting.propTypes = {
  userId: PropTypes.string,
  submitFormAction: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  isStudent: PropTypes.bool
};

export default connect(
  state => ({
    userId: state.licenceRecall.userId,
    isStudent:
      state.people &&
      state.people.data &&
      state.licenceRecall.userId &&
      state.people.data[state.licenceRecall.userId].roleName === userRoles.LEARNER
  }),
  {
    submitFormAction: submitForm,
    resetFormAction: resetForm
  }
)(LicenceRecallInputting);
