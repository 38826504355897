import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// Action imports

import { assignProductFinderProductsRequest, goToPreviousFormState } from '../../../redux/actions/productFinderActions';

// Component imports
import PanelHeading from '../../PanelHeading/PanelHeading.js';
import ScrollContainer from '../../ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../PopoutActionFooter/PopoutActionFooter.js';
import MaterialRepresentation from '../../MaterialRepresentation/MaterialRepresentation.js';
import { sanitizeUrl } from '../../../utils/url.js';
import { SIZES as thumbnailSizes } from '../../Thumbnail/Thumbnail.js';
import { HubLayoutConstants } from '../../../globals/hubConstants';
// Styles
import styles from './ProductFinderFormStates.scss';

function ProductFinderReview({
  closePopoutAction,
  contextName,
  goToPreviousFormStateAction,
  assignProductFinderProductsRequestAction,
  productFinderContent,
  assignLicences,
  usersAssigned,
  orgId,
  classId,
  product,
  context,
  studentIdList,
  teacherIdList,
  usersIdsWithLicences,
  assignLicenceToTeacher
}) {
  const getTeacherList = () =>
    (assignLicenceToTeacher && classId) ||
    !classId ||
    (!assignLicences && classId) ||
    product.target_usertype === HubLayoutConstants.TARGET_USERTYPE.TEACHER
      ? teacherIdList
      : [];

  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        headerContent={
          <div>
            <div className={styles.textRightRow}>
              <PanelNavigationLink
                isLhs={false}
                text={productFinderContent.close_panel_text}
                action={closePopoutAction}
              />
            </div>
            <PanelHeading
              title={productFinderContent.review_panel_text}
              subtitle={(productFinderContent.assignment_review_subtitle || '').replace('{assigneeName}', contextName)}
            />
          </div>
        }
        footerContent={
          <PopoutActionFooter
            secondaryButtonText={productFinderContent.button_back_text}
            secondaryButtonAction={goToPreviousFormStateAction}
            primaryButtonText={productFinderContent.review_add_assignment_text}
            primaryButtonAction={() => {
              assignProductFinderProductsRequestAction({
                orgId,
                classId,
                product,
                assignLicences,
                usersIdsWithLicences,
                studentIdList,
                teacherIdList: getTeacherList(),
                context
              });
            }}
            primaryButtonTestHook="ASSIGN_LEARNING_MATERIAL_REVIEW_NEXT"
            wizardButtons
          />
        }
      >
        <div className={styles.textContainer}>
          <MaterialRepresentation
            thumbnailSize={thumbnailSizes.TABLE}
            imageSrc={sanitizeUrl(product?.cover || product?.coverImage || '')}
            name={product?.title}
            subtext={product?.subtitle}
            statusTet={null}
            statusText={
              assignLicences ? `${usersAssigned} ${productFinderContent.review_library_assignment_count}` : null
            }
          />
        </div>
      </ScrollContainer>
    </form>
  );
}

ProductFinderReview.propTypes = {
  closePopoutAction: PropTypes.func.isRequired,
  contextName: PropTypes.string.isRequired,
  orgId: PropTypes.string,
  classId: PropTypes.string,
  usersAssigned: PropTypes.number,
  assignLicences: PropTypes.bool,
  product: PropTypes.object,
  assignProductFinderProductsRequestAction: PropTypes.func.isRequired,
  productFinderContent: PropTypes.object,
  studentIdList: PropTypes.array,
  teacherIdList: PropTypes.array,
  usersIdsWithLicences: PropTypes.array,
  context: PropTypes.string,
  assignLicenceToTeacher: PropTypes.bool,
  goToPreviousFormStateAction: PropTypes.func.isRequired
};

export default compose(
  connect(
    state => {
      const product = state.productFinder?.product || state.productFinder?.selectedProducts?.[0];
      const productId = product.productid;
      const productLicence = state.productFinder?.licenceStructure?.[productId] || {};
      return {
        assignLicences: state.productFinder.assignLicences,
        usersAssigned: productLicence.userIdsInOrder?.length,
        teacherIdList: productLicence.teacherIds,
        studentIdList: productLicence.studentIds,
        assignLicenceToTeacher: productLicence.assignLicenceToTeacher,
        usersIdsWithLicences: productLicence.usersIdsWithLicences
      };
    },
    {
      assignProductFinderProductsRequestAction: assignProductFinderProductsRequest,
      goToPreviousFormStateAction: goToPreviousFormState
    }
  )
)(ProductFinderReview);
