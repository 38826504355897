import moment from 'moment';

/**
 * Checks whether the given date matches the given format
 * @param {string} dateString
 * @param {string} dateFormat - moment compatable date format
 */
const isValidDateFormat = (dateString, dateFormat) => moment(dateString, dateFormat, true).isValid();

/**
 *
 * @param {Object} input - key/value of last changed input
 * @param {Object} startEndDates - both startDate & endDate props should be set otherwise defaults to undefined
 *
 * @returns {Object} keys: startDate, endDate; values set true if error is detected.
 */
function validateStartEndDates(input, startEndDates = {}, dateFormat, isCheckSameDate = false) {
  const { startDate, endDate } = startEndDates;
  let isSameDate = moment(startDate).isSame(endDate);
  const errors = { startDate: false, endDate: false };

  if (dateFormat) {
    errors.startDate = !!startDate && !isValidDateFormat(startDate, dateFormat);
    errors.endDate = !!endDate && !isValidDateFormat(endDate, dateFormat);
  }

  if (isCheckSameDate) {
    isSameDate = false;
  }

  if (input.startDate) {
    errors.startDate = moment(startDate).isAfter(endDate) || isSameDate;
  } else if (input.endDate) {
    errors.endDate = moment(endDate).isBefore(startDate) || isSameDate;
  }

  return errors;
}

export default validateStartEndDates;
