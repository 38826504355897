import React from 'react';
import PropTypes from 'prop-types';

import styles from './CoachingCard.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import withLocalizedContent from '../../language/withLocalizedContent';

function CoachingCard({
  localizedContent: { coachingCard: localizedContent },
  readCount,
  totalCount,
  clickedPercentage,
  scorePercentage
}) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SVGIcon className={styles.logo} glyph={GLYPHS.ICON_BUDDY_BOOK} />
        <strong className={styles.title}>{localizedContent.title}</strong>
        {totalCount > 0 ? (
          <div className={styles.booksRead}>
            {localizedContent.books_read.replace('{readCount}', readCount).replace('{totalCount}', totalCount)}
          </div>
        ) : null}
      </div>
      <div className={styles.content}>
        <div className={styles.clickedPercentageBlock}>
          <div className={styles.blockTitle}>{localizedContent.prompts_clicked_title}</div>
          <div className={styles.percentageText}>{clickedPercentage}</div>
        </div>
        <div className={styles.scorePercentageBlock}>
          <div className={styles.blockTitle}>{localizedContent.question_score_title}</div>
          <div className={styles.percentageText}>{scorePercentage}</div>
        </div>
      </div>
    </div>
  );
}

CoachingCard.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  readCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  clickedPercentage: PropTypes.string.isRequired,
  scorePercentage: PropTypes.string.isRequired
};

export default withLocalizedContent('coachingCard')(CoachingCard);
