import * as t from '../actionTypes';

export const loadCourseBook = (orgId, courseId, bookId) => ({
  type: t.NGS_LOAD_COURSE_BOOK,
  payload: { orgId, courseId, bookId }
});

export const unloadCourseBook = () => ({
  type: t.NGS_UNLOAD_COURSE_BOOK
});

export const loadCourseBookSuccess = payload => ({
  type: t.NGS_LOAD_COURSE_BOOK_SUCCESS,
  payload
});

export const loadCourseBookFailure = payload => ({
  type: t.NGS_LOAD_COURSE_BOOK_FAILURE,
  payload
});
