import * as t from '../actionTypes';

const requestStatusOptions = { isLoading: true, error: false };
const successStatusOptions = { isLoading: false, error: false };
const failureStatusOptions = { isLoading: false, error: true };

const initialState = {
  isLoading: false,
  error: false
};

function impersonateUser(state = initialState, { type }) {
  switch (type) {
    case t.IMPERSONATE_USER_REQUEST:
      return {
        ...state,
        ...requestStatusOptions
      };
    case t.IMPERSONATE_USER_SUCCESS:
      return { ...state, ...successStatusOptions };
    case t.IMPERSONATE_USER_FAILURE:
      return { ...state, ...failureStatusOptions };
    default:
      return state;
  }
}

export default impersonateUser;
