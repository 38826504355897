import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const productsUrl = platform => `${__API_BASE__}/products?platform=${platform}`;

function getProducts(platform) {
  return signedFetch('getProducts', productsUrl(platform));
}

export default getProducts;
