import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import qs from 'query-string';
import withLocalizedContent from '../language/withLocalizedContent';
import { isMflMode } from '../utils/platform';

import ContactUs from '../components/ContactUs/ContactUs';
import ContactUsProductPicker from '../components/ContactUsProductPicker/ContactUsProductPicker';
import pick from '../utils/object/pick';
import actions from '../redux/actions';

const _updateQueryString = product => {
  const queryString = qs.stringify({ product });
  const pathName = isMflMode() ? '/support/mfl-contact-us' : '/support/contact-us';
  window.history.pushState(null, null, `${pathName}?${queryString}`);
};
class ContactUsPage extends Component {
  constructor(props) {
    super(props);
    this._updateQueryString = _updateQueryString.bind(this);
  }

  componentWillMount() {
    const product = qs.parse(window.location.search || {}).product;
    const { selectProduct } = this.props;
    if (product) {
      selectProduct(product.toUpperCase());
    }
  }

  _handleProductSelection = product => {
    const { selectProduct } = this.props;
    selectProduct(product);
    this._updateQueryString(product);
  };

  _renderSelectedContactPage = () => {
    const {
      localizedContent: {
        contactUsDetails,
        contactUsDetailsOte,
        contactUsOlbWithoutForm,
        contactUsDetailsOic,
        contactUsDetailsOwl,
        contactUsDetailsOe
      },
      selectedProduct
    } = this.props;

    switch (selectedProduct) {
      case 'ORB':
        return <ContactUs contactUsDetails={contactUsDetails} />;
      case 'OTE':
        return <ContactUs contactUsDetails={contactUsDetailsOte} />;
      case 'OLB': {
        return <ContactUs contactUsDetails={contactUsOlbWithoutForm} />;
      }
      case 'OIC': {
        return <ContactUs contactUsDetails={contactUsDetailsOic} />;
      }
      case 'OWL': {
        return <ContactUs contactUsDetails={contactUsDetailsOwl} />;
      }
      case 'OE': {
        return <ContactUs contactUsDetails={contactUsDetailsOe} />;
      }
      default:
        return null;
    }
  };

  render() {
    const { selectedProduct = '' } = this.props;
    return (
      <div>
        <ContactUsProductPicker handleSelection={this._handleProductSelection} selectedProduct={selectedProduct} />
        {this._renderSelectedContactPage()}
      </div>
    );
  }
}
ContactUsPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  selectedProduct: PropTypes.string.isRequired,
  selectProduct: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent(
    'contactUsDetails',
    'contactUsDetailsOte',
    'contactUsOlbWithoutForm',
    'contactUsDetailsOic',
    'contactUsDetailsOwl',
    'contactUsDetailsOe'
  ),
  connect(
    state => pick(state.contactUs, ['selectedProduct', 'products']),
    dispatch => ({
      selectProduct: input => {
        dispatch(actions.setContactUsChangeProductSelection(input));
      },
      validateInput: input => {
        dispatch(actions.validateContactUsInputFragment(input));
      },
      submit: input => {
        dispatch(actions.submitContactUsRequest(input));
      },
      reset: platform => {
        dispatch(actions.resetContactUs(platform));
      }
    })
  )
)(ContactUsPage);
