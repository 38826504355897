import { pick } from 'lodash';
import { all, put, select } from 'redux-saga/effects';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import userRoles from '../../../../../globals/userRoles';
import actions from '../../../../actions';

export const PollOrgUserOperation = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE'
};

function getRoleContext(role) {
  switch (true) {
    case [userRoles.MANAGED_USER, userRoles.LEARNER].includes(role):
      return {
        collection: 'orgStudents',
        search: 'orgStudents'
      };
    case [userRoles.TEACHER, userRoles.TEACHER_ADMIN, userRoles.ORG_ADMIN].includes(role):
      return {
        collection: 'orgStaff',
        search: 'orgStaff'
      };
    case [userRoles.OUP_SUPPORT, userRoles.OUP_ADMIN].includes(role):
      return {
        collection: 'oupStaff',
        search: 'oupStaff'
      };
    default:
      return { collection: 'others' };
  }
}

export default function* pollOrgUsers(ids, operation, people, orgId) {
  if (!featureIsEnabled('data-recency') || !featureIsEnabled('data-recency-org-users') || !ids.length) {
    return;
  }
  const users = people || pick(yield select(state => state.people.data), ids);
  const polls = Object.entries(users).reduce(
    (carry, [userId, { roleName: role, ...user }]) => ({
      ...carry,
      [role]: {
        ...getRoleContext(role),
        role,
        users: [
          ...(carry[role] ? carry[role].users : []),
          { userId, ...(operation === PollOrgUserOperation.UPDATE ? pick(user, ['lastUpdatedTimeStamp']) : {}) }
        ]
      }
    }),
    {}
  );
  yield all(
    Object.values(polls).map(poll =>
      put(
        actions.pollEntitiesSynced({
          ...poll,
          orgId,
          operation,
          target: 'users',
          responseEntityKey: 'userPollingStatus',
          identifier: 'userId'
        })
      )
    )
  );
}

export function pollOrgUsersAdded(ids, people, orgId) {
  return pollOrgUsers(ids, PollOrgUserOperation.ADD, people, orgId);
}

export function pollOrgUsersUpdated(ids) {
  return pollOrgUsers(ids, PollOrgUserOperation.UPDATE);
}

export function pollOrgUsersRemoved(ids) {
  return pollOrgUsers(ids, PollOrgUserOperation.REMOVE);
}
