import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import styles from './ActivationCodeDetailDisplay.scss';
import Label from '../../../../components/Label/Label';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Table from '../../../../components/Table/Table';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import formatDateForDashboard from '../../../../utils/date/formatDateForDashboard';

function ActivationCodeDetailDisplay({
  localizedContent: { activationCodeDashboard: content },
  activationCodeDetails: detail,
  activationCodeDetailError: error
}) {
  const renderUserTable = () => {
    const columns = [
      {
        heading: content.user
      },
      {
        heading: content.username
      },
      {
        heading: content.activation_date
      }
    ];

    const rows = detail.users.map((user, index) => ({
      id: index,
      cells: [
        <span key={`user-${index}`}>{user.user}</span>,
        <span key={`name-${index}`}>{user.userName}</span>,
        <span key={`date-${index}`}>{user.activationDate}</span>
      ]
    }));

    return (
      <div className={styles.tableWrapper}>
        <Table columns={columns} rows={rows} />
      </div>
    );
  };

  const renderDetail = () => {
    if (!detail) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        <div>
          <div className={styles.detailWrapper}>
            <div>
              <Label text={content.detail_act_code} />
              <span> {detail.activationCodeId} </span>
            </div>

            <div>
              <Label text={content.detail_batch} />
              <span> {detail.batch} </span>
            </div>

            <div>
              <Label text={content.detail_product} />
              <span> {detail.product} </span>
            </div>

            <div>
              <Label text={content.detail_product_group} />
              <span> {detail.productGroup} </span>
            </div>

            <div>
              <Label text={content.detail_validity} />
              <span>
                {formatDateForDashboard(detail.validityStart)} - {formatDateForDashboard(detail.validityEnd)}
              </span>
            </div>

            <div>
              <Label text={content.detail_allowed_usage} />
              <span> {detail.allowedUsage} </span>
            </div>

            <div>
              <Label text="Actual Usage" />
              <span>{detail.actualUsage}</span>
            </div>
          </div>

          {detail.users ? renderUserTable() : <div />}
        </div>
      </div>
    );
  };

  const renderError = () => (
    <div>
      <SVGIcon glyph={GLYPHS.ICON_WARNING_TRIANGLE} className={styles.warningIcon} />
      <div className={styles.errorMsg}>{content.detail_err_msg}</div>
    </div>
  );

  return (
    <div>
      <div>{error ? renderError() : renderDetail()}</div>
      <Button
        onClick={() => {
          window.history.back();
        }}
        type={buttonTypes.PRIMARY}
        text={content.go_back}
      />
    </div>
  );
}

ActivationCodeDetailDisplay.propTypes = {
  localizedContent: PropTypes.object,
  activationCodeDetails: PropTypes.object,
  activationCodeDetailError: PropTypes.object
};

const mapStateToProps = state => {
  const {
    activationCodeDashboard: { activationCodeDetails, activationCodeDetailError }
  } = state;
  return { activationCodeDetails, activationCodeDetailError };
};

const mapDispatchToProps = null;

export default compose(
  withLocalizedContent('activationCodeDashboard'),
  connect(mapStateToProps, mapDispatchToProps)
)(ActivationCodeDetailDisplay);
