/* eslint-disable camelcase */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { formatPercentage } from '../../utils/number';
import ragStatuses from '../../globals/ragStatuses';
import withLocalizedContent from '../../language/withLocalizedContent';
import ProgressText from '../ProgressText/ProgressText';
import DetailReportGrid from '../DetailReportGrid/DetailReportGrid';

import styles from './ProgressDetails.scss';

function ProgressDetails({
  hideProgressText,
  isCurrentLevel,
  forceRowDisplay,
  days_at_level,
  quizzes_passed,
  quizzes_average,
  progress_status,
  skipped_level,
  quizzes_completed,
  quizzes_expected,
  quizzes_pass_score,
  expected_days_at_level,
  localizedContent: { sharedTerms, progressDetails }
}) {
  return (
    <div className={styles.progressDetails}>
      {!hideProgressText && progress_status ? (
        <div className={classnames(styles.progressTextWrapper, 'gin-bot2')}>
          <ProgressText
            status={progress_status}
            isCurrentLevel={isCurrentLevel}
            isSkipped={skipped_level}
            variables={{ quizzesScore: formatPercentage(quizzes_pass_score, { literal: true }) }}
          />
        </div>
      ) : null}
      <DetailReportGrid
        forceRowDisplay={forceRowDisplay}
        gridData={[
          {
            header: progressDetails.days_at_level_text.replace('{levelSystem}', sharedTerms.OXFORD_LEVEL),
            content: days_at_level || progressDetails.not_yet_logged_in_text,
            // eslint-disable-next-line camelcase
            description: days_at_level ? `(${progressDetails.expected_text}: ${expected_days_at_level})` : null
          },
          {
            // eslint-disable-next-line camelcase
            header: `${progressDetails.quizzes_passed_text} \u2265 ${formatPercentage(quizzes_pass_score, {
              literal: true
            })}`,
            content: quizzes_passed,
            // eslint-disable-next-line camelcase
            description: `(${progressDetails.needed_to_complete_level_text}: ${quizzes_expected})`
          },
          {
            header: progressDetails.average_score_text,
            content: formatPercentage(quizzes_average, { literal: true }),
            // eslint-disable-next-line camelcase
            description: `${progressDetails.quizzes_completed_text}: ${quizzes_completed}`
          }
        ]}
      />
    </div>
  );
}

ProgressDetails.propTypes = {
  hideProgressText: PropTypes.bool,
  days_at_level: PropTypes.number.isRequired,
  progress_status: PropTypes.oneOf(Object.values(ragStatuses)),
  skipped_level: PropTypes.bool,
  quizzes_completed: PropTypes.number.isRequired,
  quizzes_expected: PropTypes.number.isRequired,
  quizzes_passed: PropTypes.number.isRequired,
  quizzes_pass_score: PropTypes.number.isRequired,
  quizzes_average: PropTypes.string.isRequired,
  expected_days_at_level: PropTypes.number.isRequired,
  isCurrentLevel: PropTypes.bool,
  forceRowDisplay: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('sharedTerms', 'progressDetails')(ProgressDetails);
