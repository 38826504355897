import * as t from '../actionTypes';

export const searchUserFromConsole = data => ({
  type: t.SEARCH_USER_FOR_CONSOLE,
  data
});

export const setUserSearchResultForConsole = data => ({
  type: t.SET_USER_SEARCH_RESULT_FOR_CONSOLE,
  data
});

export const setUserSearchErrorForConsole = data => ({
  type: t.SET_USER_SEARCH_ERROR_FOR_CONSOLE,
  data
});

export const stopUserSearchForConsole = data => ({
  type: t.STOP_USER_SEARCH_FOR_CONSOLE,
  data
});

export const emptyUserSearchResultsForConsole = data => ({
  type: t.EMPTY_USER_SEARCH_RESULTS_FOR_CONSOLE,
  data
});

export const searchUserEdit = data => ({
  type: t.SEARCH_USER_EDIT_REQUEST,
  payload: data
});

export const searchUserEditSuccess = payload => ({
  type: t.SEARCH_USER_EDIT_SUCCESS,
  payload
});

export const searchUserEditFailure = payload => ({
  type: t.SEARCH_USER_EDIT_FAILURE,
  payload
});
