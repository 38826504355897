import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import TableAccordion, { columnTypes } from '../TableAccordion/TableAccordion';
import PlatformSettings from './PlatformSettings';
import { displayOnMapping } from '../../globals/appConstants';
import styles from './SystemNotificationSettings.scss';

function SystemNotificationSettings({ content, systemNotification, updateSystemNotificationAction }) {
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    if (systemNotification) {
      setPlatforms(systemNotification);
    }
  }, [systemNotification]);

  return (
    <div className="pad-top2">
      <div className="row">
        <div className={`col ${styles.systemNotificationsTable}`}>
          <TableAccordion
            columns={[
              { heading: content.heading_platform },
              { heading: content.heading_enabled, type: columnTypes.TEXT },
              { heading: content.heading_message_type, type: columnTypes.TEXT },
              { heading: content.heading_display_on, type: columnTypes.TEXT },
              { heading: content.heading_settings, type: columnTypes.BUTTON }
            ]}
            rows={platforms.map((el, i) => ({
              id: el.platformCode,
              cells: [
                el.platformCode,
                el.enabled ? 'Yes' : 'No',
                el?.messageType || '-',
                <span key={i} className={classnames(styles['inline-block'], styles['text-left'], styles['w-full'])}>
                  {el?.displayOn
                    ? Object.entries(el.displayOn)
                        .filter(([, value]) => value)
                        .map(([key, value]) => (value ? displayOnMapping(content, key) : '-'))
                        .join(', ')
                    : '-'}
                </span>,
                undefined
              ],
              revealableContent: (
                <PlatformSettings
                  initialState={el}
                  updateSystemNotificationAction={updateSystemNotificationAction}
                  content={content}
                />
              )
            }))}
          />
        </div>
      </div>
    </div>
  );
}

SystemNotificationSettings.propTypes = {
  content: PropTypes.object.isRequired,
  systemNotification: PropTypes.array.isRequired,
  updateSystemNotificationAction: PropTypes.func.isRequired
};

export default SystemNotificationSettings;
