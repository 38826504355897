import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { startProductLogsRequest } from '../../../redux/reducers/productUploadLogs.reducer.js';
import Button, { buttonTypes } from '../../../components/Button/Button';
import styles from './ProductUpload.scss';

class ProductUploadLogs extends Component {
  renderLogs(productUploadLogsData) {
    const { content } = this.props;
    return (
      <div>
        <p>{`${content.currentRecordsNo}: ${productUploadLogsData.currentRecordsNo}`}</p>
        <p>{`${content.dataType}: ${productUploadLogsData.dataType}`}</p>
        <p>{`${content.date}: ${productUploadLogsData.date}`}</p>
        <p>{`${content.errors}: ${productUploadLogsData.errors}`}</p>
        <p>{`${content.platformCode}: ${productUploadLogsData.platformCode}`}</p>
        <p>{`${content.recordsUpserted}: ${productUploadLogsData.recordsUpserted}`}</p>
        <p>{`${content.userId}: ${productUploadLogsData.userId}`}</p>
        <p>{`${content.warrnings}: ${productUploadLogsData.warrnings}`}</p>
      </div>
    );
  }

  render() {
    const {
      initial,
      loading,
      errorOccurred,
      errorMessage,
      success,
      productUploadLogsData,
      content,
      startProductLogsRequestAction
    } = this.props;
    return (
      <div className={styles.logsContainer}>
        {initial ? (
          <div className={styles.centeredButtonContainer}>
            <Button type={buttonTypes.PRIMARY} text={content.getLogsButton} onClick={startProductLogsRequestAction} />
          </div>
        ) : null}
        {loading ? content.loadingMessage : null}
        {errorOccurred ? `${content.errorDetails} ${errorMessage} ` : null}
        {success && productUploadLogsData ? this.renderLogs(productUploadLogsData) : null}
        {success && !productUploadLogsData ? content.noLogs : null}
      </div>
    );
  }
}

ProductUploadLogs.propTypes = {
  startProductLogsRequestAction: PropTypes.func,
  productUploadLogsData: PropTypes.object,
  errorMessage: PropTypes.string,
  errorOccurred: PropTypes.bool,
  loading: PropTypes.bool,
  initial: PropTypes.bool,
  success: PropTypes.bool,
  content: PropTypes.object.isRequired
};

export default connect(
  state => ({
    initial: state.productUploadLogs.initial,
    loading: state.productUploadLogs.loading,
    success: state.productUploadLogs.success,
    errorOccurred: state.productUploadLogs.errorOccurred,
    errorMessage: state.productUploadLogs.errorMessage,
    productUploadLogsData: state.productUploadLogs.productUploadLogsData
  }),
  {
    startProductLogsRequestAction: startProductLogsRequest
  }
)(ProductUploadLogs);
