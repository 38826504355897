import PropTypes from 'prop-types';
import { Component } from 'react';

export default class LocalizedContentProvider extends Component {
  getChildContext() {
    const { localizedContent } = this.props;

    return {
      localizedContent
    };
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

LocalizedContentProvider.childContextTypes = {
  localizedContent: PropTypes.object
};

LocalizedContentProvider.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};
