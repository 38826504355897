import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import OicTopRow from './parts/OicTopRow/OicTopRow.js';
import styles from './OicHomepage.scss';
import stylesOverwrites from './OicHomePageOverwrites.scss';
import withLocalizedContent from '../../language/withLocalizedContent';

function OicHomepage({ localizedContent: { oicHomepage: localizedContent } }) {
  return (
    <div className={styles.OicHomePageContainer}>
      <Helmet title={localizedContent.page_title} style={stylesOverwrites} />
      <OicTopRow localizedContent={localizedContent} />
    </div>
  );
}

OicHomepage.propTypes = {
  localizedContent: PropTypes.object
};

export default withLocalizedContent('oicHomepage')(OicHomepage);
