import jsonFetch from '../util/jsonFetch';

export default async productIds => {
  const url = `${__API_BASE__}/open/ltitool1v3/content-selection-product-details`;
  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify({ productIds });
  const response = await jsonFetch('ltiContentConfirmation', [url, { method: 'POST', headers, body }], false);

  return response;
};
