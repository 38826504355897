import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import content from '../../utils/cmsContent';
import preventBodyScroll from '../../utils/dom/preventBodyScroll';
// To control keyboard tabbing and prevent content behind modal from scrolling:
import trapFocusIn from '../../utils/dom/trapFocusIn';
import Button, { buttonTypes } from '../Button/Button';
import CarouselControls from '../CarouselControls/CarouselControls';
import styles from './InformationPopupModal.scss';
import PageHeading from '../PageHeading/PageHeading';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

// Helper to rotate current carousel value when it reaches its first or last value:
function prevOrNext(value, length, displayAFinalPanel) {
  if (displayAFinalPanel) {
    return (value < 0 && length) || (value < length + 1 && value) || 0;
  }

  return (value < 0 && length - 1) || (value < length && value) || 0;
}

class InformationPopupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carouselPage: 0,
      transition: false
    };
  }

  componentDidMount() {
    // To control keyboard tabbing and prevent content behind modal from scrolling:
    trapFocusIn(this.wrapper);
    preventBodyScroll(true);

    // Timeout used to introduce a class that adds transition to the modal when active
    setTimeout(() => {
      this.setState({
        transition: true
      });

      // Set focus on the Next button:
      const carouselNext = document.getElementById('carouselNext');

      if (carouselNext) {
        carouselNext.focus();
      }
    }, 0);
  }

  componentWillUnmount() {
    trapFocusIn(false);
    preventBodyScroll(false);
  }

  render() {
    const {
      negativeClick,
      contentList,
      children,
      displayAFinalPanel,
      finalPanelTitle,
      finalPanelSubtitle,
      firstSuggestionButton,
      firstSuggestionButtonText,
      secondSuggestionButton,
      secondSuggestionButtonText
    } = this.props;
    const { carouselPage, transition } = this.state;
    const carouselPages = contentList;
    const transitionClass = transition ? styles.active : null;
    const CMS = content.appComponents || {};

    const prevButtonActionHandler = () =>
      this.setState({
        carouselPage: prevOrNext(carouselPage - 1, carouselPages.length, displayAFinalPanel)
      });

    const nextButtonActionHandler = () =>
      this.setState({
        carouselPage: prevOrNext(carouselPage + 1, carouselPages.length, displayAFinalPanel)
      });

    // Content for the modal
    return (
      <div
        className={styles.informationPopupModal}
        id="informationPopupModal"
        ref={elem => {
          this.wrapper = elem;
        }}
      >
        <div className={classnames(styles.modal, transitionClass)}>
          {children || (
            <div>
              <button
                aria-label={CMS.close_text}
                className={styles.close}
                type="button"
                onClick={negativeClick}
                id="informationPopupModalClose"
              >
                <SVGIcon glyph={GLYPHS.ICON_CLOSE_BOLD} />
              </button>

              <div id="carousel-panels" aria-live="assertive">
                {/* Information to show while we are not on the final title */}
                {carouselPage < contentList.length && (
                  <div className={styles.topSection}>
                    {/* Note: Without aria-label, VoiceOver struggles to notice when h2 has changed: */}
                    <h2 className={styles.header} aria-label={contentList[carouselPage].header}>
                      {contentList[carouselPage].header}
                    </h2>

                    <img
                      className={styles.image}
                      src={contentList[carouselPage].imgSrc}
                      alt={contentList[carouselPage].imgAlt}
                    />

                    {contentList[carouselPage].body && (
                      <div className={styles.body}>
                        <p>{contentList[carouselPage].body}</p>
                      </div>
                    )}
                  </div>
                )}

                {/* Information to show while we are on the final title, if we want to display a final title */}
                {displayAFinalPanel && carouselPage >= contentList.length && (
                  <div className={styles.finalPanelTitle}>
                    <PageHeading title={finalPanelTitle} subtitle={finalPanelSubtitle} />

                    {firstSuggestionButton && firstSuggestionButtonText && (
                      <div className="pad-top2">
                        <Button
                          to={firstSuggestionButton}
                          type={buttonTypes.PRIMARY}
                          text={firstSuggestionButtonText}
                          onClick={negativeClick}
                          preventDefault={false}
                        />
                      </div>
                    )}

                    {secondSuggestionButton && secondSuggestionButtonText && (
                      <div className="pad-top3">
                        <Button
                          to={secondSuggestionButton}
                          type={buttonTypes.PRIMARY}
                          text={secondSuggestionButtonText}
                          onClick={negativeClick}
                          preventDefault={false}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.carouselControls}>
                <CarouselControls
                  id="carousel-controls"
                  current={carouselPage}
                  ariaControls="carousel-panels"
                  prevButtonAction={prevButtonActionHandler}
                  nextButtonAction={nextButtonActionHandler}
                  items={Array.from({
                    length: displayAFinalPanel ? carouselPages.length + 1 : carouselPages.length
                  }).map((page, i) => ({
                    action: () => this.setState({ carouselPage: i })
                  }))}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

InformationPopupModal.propTypes = {
  /* The "X" on the modal */
  negativeClick: PropTypes.func.isRequired,
  children: PropTypes.element,
  contentList: PropTypes.any.isRequired,
  firstSuggestionButton: PropTypes.string,
  firstSuggestionButtonText: PropTypes.string,
  secondSuggestionButton: PropTypes.string,
  secondSuggestionButtonText: PropTypes.string,
  displayAFinalPanel: PropTypes.bool,
  finalPanelTitle: PropTypes.string,
  finalPanelSubtitle: PropTypes.string
};

export default InformationPopupModal;
