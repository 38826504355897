import { put, takeLatest } from 'redux-saga/effects';
import actions from '../../../../actions';
import * as t from '../../../../actionTypes';
import getGradedReadersApi from '../../../apiCalls/gradebook/getGradedReaders.api';

function* loadGradedReadersDetails({ payload }) {
  const apiResults = yield getGradedReadersApi(payload.params);

  if (apiResults.error || apiResults.status !== 'success') {
    yield put(actions.gradedReadersResultsFailure(apiResults, payload.page));
  } else {
    if (apiResults.data.learners) {
      yield put(actions.gradedReadersResultsSuccess(apiResults.data.learners, payload.page));
    }

    if (apiResults.data.products) {
      yield put(actions.gradedReadersResultsSuccess(apiResults.data, payload.page));
    }
  }
}

export default function* gradedReadersPageLoader() {
  console.log('[gradedReadersPageLoader] Registering action listeners');
  yield takeLatest(t.GRADED_READERS_RESULTS_REQUEST, loadGradedReadersDetails);
}
