import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup';

import styles from './HubFilterOptions.scss';
import Button, { buttonTypes } from '../Button/Button';
import { validGlyphs } from '../SVGIcon/SVGIcon';
import PopupOverlay from '../PopupOverlay/PopupOverlay';
import colors, { hexCodes } from '../../globals/colors';
import keyCodes from '../../globals/keyCodes';
import OptionIcon from './OptionIcon';

export default class HubFilterOptions extends Component {
  static optionClicked = (option, checked) => {
    if (option.isDisabled) return;
    if (option.onClick) {
      option.onClick(option.value, checked);
    }
  };

  constructor(props) {
    super(props);

    const { options } = this.props;

    this.state = {
      programmaticId: Math.random()
        .toString(36)
        .substring(7),
      popupOpen: false,
      // Also maintain list of all checked values to pass to onChange events etc:
      // Note: For consistency, this is an array even when inputType is radio.
      selectedValues: props.initialSelectedValues || options.filter(opt => opt.checked).map(opt => opt.value)
    };
  }

  /** creating pagination, filter, sorting, refresh for Front_End
   * orgStudentsSearch check for Org Student Tab
   */
  componentWillReceiveProps(nextProps) {
    const { options } = this.props;
    if (nextProps.options !== options) {
      this.setState({ selectedValues: nextProps.options.filter(opt => opt.checked).map(opt => opt.value) });
    }
  }

  togglePopup = () => {
    const { popupOpen } = this.state;
    this.setState({ popupOpen: !popupOpen });
  };

  render() {
    const {
      buttonId,
      buttonText,
      buttonCustomClassName,
      buttonGlyph,
      a11yHeading,
      ariaControls,
      inputType,
      options,
      shallow,
      onChange,
      iconOnly,
      showLegend,
      overlayHangRight,
      overlayCustomClass
    } = this.props;
    const { popupOpen, programmaticId } = this.state;
    const menuId = `${buttonId}-menu`;
    let { selectedValues } = this.state;

    // CES-2230: Set aria-label so screenreaders will read a friendly summary of the current selections:
    // Possible i18n debt :(
    const summaryOfSelections = options
      .filter(option => option.checked)
      .map(option => option.text)
      .join(', ');

    const isSingleOption = options.length < 2;

    let args = {};

    if (inputType === 'radio') {
      args = {
        name: programmaticId,
        variant: 'transparent',
        legend: showLegend,
        classnames: { container: styles.radioButtonGroupContainer },
        required: false,
        checkedValue: selectedValues,
        radioGroup: options.map(option => ({
          id: `control-${option.id}-${programmaticId}`,
          name: option.name,
          value: option.value,
          disabled: option.isDisabled ? option.isDisabled : false,
          checkedValue: selectedValues.includes(option.value),
          type: 'transparent',
          label: option.text,
          onChange: option.onChange
            ? event => {
                selectedValues = event.target.checked
                  ? [...selectedValues, option.value]
                  : selectedValues.filter(val => JSON.stringify(val) !== JSON.stringify(option.value));
                this.setState({ selectedValues });
                option.onChange(option.value, event.target.checked);
                if (onChange) onChange(selectedValues);
              }
            : undefined
        })),
        onChange: event => {
          selectedValues = [event.target.value];
          this.setState({ selectedValues });
          if (onChange) onChange(selectedValues);
        }
      };
    }

    return (
      <div
        className={classnames(styles.container, {
          [styles.containerShallow]: shallow
        })}
      >
        <Button
          id={buttonId}
          type={iconOnly ? buttonTypes.ROUNDED : buttonTypes.DROPDOWN}
          text={buttonText}
          glyph={buttonGlyph}
          glyphFill={hexCodes[colors.PRIMARY]}
          onClick={!isSingleOption ? this.togglePopup : null}
          aria={{
            haspopup: 'true',
            owns: menuId,
            controls: menuId,
            expanded: popupOpen,
            label: summaryOfSelections ? `${a11yHeading} ${summaryOfSelections}` : null
          }}
          iconOnly={iconOnly}
          customClassName={buttonCustomClassName}
          hidePostIcon={isSingleOption}
        />

        {!isSingleOption && (
          <PopupOverlay
            id={menuId}
            customClassName={classnames(styles.popupOverlay, overlayHangRight ? styles.hangRight : styles.hangLeft, {
              [overlayCustomClass]: overlayCustomClass
            })}
            visible={popupOpen}
            hangRight={overlayHangRight}
            onTogglePopup={this.togglePopup}
            returnFocusTo={buttonId}
            aria={{ labelledby: buttonId, expanded: popupOpen }}
            buttonElementId={buttonId}
            role="group"
          >
            {inputType === 'radio' ? (
              <RadioButtonGroup {...args} />
            ) : (
              <fieldset>
                {showLegend && <span className={styles.overlayLabel}>{a11yHeading}:</span>}
                <ul className={styles.popupContainer} role="menu">
                  {options.map((option, index) => (
                    <li key={index} role="menuitem">
                      <input
                        id={`control-${option.id}-${programmaticId}`}
                        name={option.name}
                        type={inputType}
                        value={option.value}
                        disabled={option.isDisabled}
                        checked={selectedValues.includes(option.value)}
                        onClick={event => HubFilterOptions.optionClicked(option, event.target.checked)}
                        onKeyDown={event => {
                          if (event.keyCode === keyCodes.ENTER) event.target.click();
                        }}
                        onChange={event => {
                          selectedValues = event.target.checked
                            ? [...selectedValues, option.value]
                            : selectedValues.filter(val => JSON.stringify(val) !== JSON.stringify(option.value));
                          this.setState({ selectedValues });
                          if (option.onChange) option.onChange(option.value, event.target.checked);
                          if (onChange) onChange(selectedValues);
                        }}
                        aria-controls={ariaControls}
                      />
                      <OptionIcon
                        text={option.text}
                        inputType={inputType}
                        checked={option.checked}
                        programmaticId={`control-${option.id}-${programmaticId}`}
                      />
                    </li>
                  ))}
                </ul>
              </fieldset>
            )}
          </PopupOverlay>
        )}
      </div>
    );
  }
}

HubFilterOptions.propTypes = {
  buttonId: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonCustomClassName: PropTypes.string,
  buttonGlyph: PropTypes.oneOf(validGlyphs),
  a11yHeading: PropTypes.string.isRequired,
  ariaControls: PropTypes.string,
  inputType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  shallow: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      isDisabled: PropTypes.bool
    })
  ).isRequired,
  iconOnly: PropTypes.bool,
  showLegend: PropTypes.bool,
  overlayHangRight: PropTypes.bool,
  overlayCustomClass: PropTypes.string,
  initialSelectedValues: PropTypes.arrayOf(PropTypes.string)
};

HubFilterOptions.defaultProps = {
  showLegend: true,
  overlayHangRight: true
};
