import PropTypes from 'prop-types';
import React from 'react';

import { ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';
import Button from '@oup/shared-front-end/src/components/Button';
import colors from '../../globals/colors.js';
import withLocalizedContent from '../../language/withLocalizedContent.js';
import Card from '../Card/Card.js';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard.js';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import { isHubMode } from '../../utils/platform.js';
import styles from '../../pages/RegisterOrgPage.scss';

function RegisterOrgSuccess({
  branch,
  localizedContent: { registerOrgSuccessComponent: content },
  id,
  name,
  primaryButtonLabel,
  secondaryButtonLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick
}) {
  const hasPrimaryButton = primaryButtonLabel && onPrimaryButtonClick;
  const hasSecondaryButton = secondaryButtonLabel && onSecondaryButtonClick;

  let { title, subtitle } = content;

  if (branch !== ORG_REGISTRATION_CONTEXT.OUP_CUSTOMER_SUPPORT) {
    title = content.normal_title;
    subtitle = (content.normal_subtitle || '').replace('{organizationName}', name);
  }
  return (
    <div>
      {isHubMode() ? (
        <PopoutPanelIllustrationHeading
          title={title}
          subtitle={subtitle}
          illustrationSrc={HubIllustrationConstants.SUCCESS}
          illustrationAltText={HubIllustrationAltText.SUCCESS}
        />
      ) : (
        <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.VALID} title={title} />
      )}
      {!isHubMode() && <p className="lead text-center gin-bot3">{subtitle}</p>}
      {branch === ORG_REGISTRATION_CONTEXT.OUP_CUSTOMER_SUPPORT ? (
        <div className="gin-bot3">
          <Card headingText={content.header} headingColor={colors.ORGANIZATION}>
            <CopyToClipboard label={content.name_label} value={name} />
            <CopyToClipboard label={content.id_label} value={id} />
          </Card>
        </div>
      ) : null}

      {hasPrimaryButton || hasSecondaryButton ? (
        <div className={`row cols-center mView ${isHubMode ? styles.buttonsContainer : ''}`}>
          {hasPrimaryButton ? (
            <span className="gin-right2">
              <Button variant="filled" text={primaryButtonLabel} onClick={onPrimaryButtonClick} />
            </span>
          ) : null}
          {hasSecondaryButton ? (
            <span className="gin-right2">
              <Button variant="outline" text={secondaryButtonLabel} onClick={onSecondaryButtonClick} />
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

RegisterOrgSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  branch: PropTypes.string
};

export default withLocalizedContent('registerOrgSuccessComponent')(RegisterOrgSuccess);
