import React from 'react';
import PropTypes from 'prop-types';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import cmsContent from '../../utils/cmsContent.js';

function RegisterFormSubmitting({ emailSending }) {
  const CMS = cmsContent.registrationPage || {};

  // Ensure spinner and subsequent confirmation are visible:
  window.scroll(0, 0);

  return (
    <PopoutPanelIconHeading
      type={types.LOADING}
      title={CMS.loading_title_text}
      subtitle={emailSending ? CMS.loading_emailsending_text : CMS.loading_registering_text}
    />
  );
}

RegisterFormSubmitting.propTypes = {
  emailSending: PropTypes.bool
};
export default RegisterFormSubmitting;
