import * as t from '../actionTypes';

export const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.STORE_SUBSCRIPTIONS:
      return { ...payload };
    default:
      return state;
  }
};
