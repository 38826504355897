import * as t from '../actionTypes';

export const parseImportUsersSourceRequest = source => ({
  type: t.PARSE_IMPORT_USERS_SOURCE_REQUEST,
  payload: source
});

export const parseImportUsersSourceCompleted = result => ({
  type: t.PARSE_IMPORT_USERS_SOURCE_COMPLETED,
  payload: result
});

export const parseImportUsersSourceFailure = (type, reason) => ({
  type: t.PARSE_IMPORT_USERS_SOURCE_FAILURE,
  payload: { type, reason }
});

export const parseImportUsersSourceRetry = () => ({
  type: t.PARSE_IMPORT_USERS_SOURCE_RETRY
});

export const storeImportUsersUpdatesRequest = updates => ({
  type: t.STORE_IMPORT_USERS_UPDATES_REQUEST,
  payload: updates
});

export const updateImportUsersRequest = updates => ({
  type: t.UPDATE_IMPORT_USERS_REQUEST,
  payload: updates
});

export const removeImportUsersRequest = id => ({
  type: t.REMOVE_IMPORT_USERS_REQUEST,
  payload: id
});

export const destroyImportUsersRequest = id => ({
  type: t.DESTROY_IMPORT_USERS_REQUEST,
  payload: id
});

export const checkImportUsersRequest = () => ({
  type: t.CHECK_IMPORT_USERS_REQUEST,
  payload: null
});

export const checkImportUsersCompleted = result => ({
  type: t.CHECK_IMPORT_USERS_COMPLETED,
  payload: result
});

export const uploadImportUsersRequest = queue => ({
  type: t.UPLOAD_IMPORT_USERS_REQUEST,
  payload: queue
});

export const uploadImportUsersSuccess = (batchId, importRecordsCount) => ({
  type: t.UPLOAD_IMPORT_USERS_SUCCESS,
  payload: {
    batchId,
    importRecordsCount
  }
});

export const uploadImportUsersFailure = () => ({
  type: t.UPLOAD_IMPORT_USERS_FAILURE,
  payload: null
});

export const validateImportUsersRequest = (id, input) => ({
  type: t.VALIDATE_IMPORT_USERS_REQUEST,
  payload: {
    id,
    input
  }
});

export const validateImportUsersCompleted = (id, errors) => ({
  type: t.VALIDATE_IMPORT_USERS_COMPLETED,
  payload: {
    id,
    errors
  }
});

export const validateImportUsersListCompleted = (id, errors) => ({
  type: t.VALIDATE_IMPORT_USERS_LIST_COMPLETED,
  payload: {
    id,
    errors
  }
});

export const storeUnsavedUsers = users => ({
  type: t.STORE_UNSAVED_USERS,
  payload: { users }
});
