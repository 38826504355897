import { JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../util/signedFetch.js';

export default (body, associatedResourceId) => {
  const URL = `${__API_BASE__}/adaptedTests/${associatedResourceId}`;

  const headers = { 'Content-Type': JSON_ENCODED, Accept: JSON_ENCODED };

  return signedFetch('updateAdaptedTest', URL, 'PUT', body, headers);
};
