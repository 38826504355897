import PropTypes from 'prop-types';
import React from 'react';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading, { types as headingIconTypes } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import UserConfirmationList from '../UserConfirmationList/UserConfirmationList';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import { isHubMode } from '../../utils/platform';

export const HeadingTypes = {
  VALID: headingIconTypes.VALID,
  WARNING: headingIconTypes.WARNING,
  ERROR: headingIconTypes.ERROR
};

function ClassUserConfirmation({
  localizedContent: content,
  title,
  type = HeadingTypes.VALID,
  infoMessage = '',
  users = {},
  failedIds = [],
  onAddAnother,
  onDoneClick,
  canAssignLearningMaterials,
  illustrationSrc,
  illustrationAltText,
  isPlacementTest
}) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          {isHubMode ? (
            <PopoutPanelIllustrationHeading
              title={title}
              illustrationSrc={illustrationSrc}
              illustrationAltText={illustrationAltText}
            />
          ) : (
            <PopoutPanelIconHeading title={title} type={type} />
          )}
        </div>
      }
      footerContent={
        <PopoutActionFooter
          {...(onAddAnother && canAssignLearningMaterials
            ? {
                secondaryButtonText: content.confirmation_add_another_button,
                secondaryButtonAction: onAddAnother
              }
            : {})}
          primaryButtonText={
            isPlacementTest && failedIds.length === Object.keys(users).length
              ? content.try_again_button
              : content.confirmation_done_button
          }
          primaryButtonAction={onDoneClick}
          primaryButtonTestHook="ADD_STUDENTS_PANEL_CONFIRMATION_BUTTON"
        />
      }
    >
      {infoMessage ? (
        <div className="gin2">
          <em>{infoMessage}</em>
        </div>
      ) : null}
      <UserConfirmationList
        users={users}
        isPlacementTest={isPlacementTest}
        failedIds={failedIds}
        successUserStatusText={
          isPlacementTest
            ? content.confirmation_success_user_status_text.replace('class', 'session')
            : content.confirmation_success_user_status_text
        }
        warningUserStatusText={content.confirmation_warning_user_status_text}
        noUsersText={
          isPlacementTest
            ? content.confirmation_no_users_text.replace('class', 'session')
            : content.confirmation_no_users_text
        }
      />
    </ScrollContainer>
  );
}

ClassUserConfirmation.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  users: PropTypes.object,
  canAssignLearningMaterials: PropTypes.bool,
  failedIds: PropTypes.array,
  type: PropTypes.oneOf(Object.values(HeadingTypes)),
  infoMessage: PropTypes.string,
  onAddAnother: PropTypes.func,
  onDoneClick: PropTypes.func.isRequired,
  illustrationSrc: PropTypes.string,
  illustrationAltText: PropTypes.string,
  isPlacementTest: PropTypes.bool
};

export default ClassUserConfirmation;
