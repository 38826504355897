import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import Card from '../../../../components/Card/Card';
import FormCalendar from '../../../../components/FormCalendar/FormCalendar';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import HorizontalScrollContainer from '../../../../components/HorizontalScrollContainer/HorizontalScrollContainer';
import TextInput from '../../../../components/TextInput/TextInput';
import colors from '../../../../globals/colors';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import IteratorPagination from '../../EpsAdminOPS/IteratorPagination/IteratorPagination';
import useIteratorPagination from '../../EpsAdminOPS/IteratorPagination/useIteratorPagination';
import styles from './UserAdminSearch.scss';
import UserSearchResults from './UserSearchResults';
import EditUserPanel from './EditUserPanel';

import {
  emptyUserSearchResultsForConsole,
  searchUserFromConsole,
  stopUserSearchForConsole
} from '../../../../redux/actions/userAdminDashboard';

function UserAdminSearch({
  localizedContent: { userAdminSearch: content },
  searchUser,
  stopSearch,
  loading,
  userSearchResults: initialUserSearchResults,
  userSearchTotal,
  emptySearch
}) {
  const [oupIdFilter, setOupIdFilter] = useState(null);
  const [userNameFilter, setUsernameFilter] = useState(null);
  const [emailFilter, setEmailFilter] = useState(null);
  const [firstNameFilter, setFirstNameFilter] = useState(null);
  const [lastNameFilter, setLastNameFilter] = useState(null);
  const [createdDateFromFilter, setCreatedDateFromFilter] = useState(null);
  const [createdDateToFilter, setCreatedDateToFilter] = useState(null);
  const [numberPerPage, setNumberPerPage] = useState(20);
  const [userSearchResults, setUserSearchResults] = useState(initialUserSearchResults);
  const [editUser, setEditUser] = useState(false);
  const [userData, setUserData] = useState({
    userId: null,
    userName: null,
    firstName: null,
    lastName: null,
    email: null
  });

  useEffect(() => {
    setUserSearchResults(initialUserSearchResults);
  }, [initialUserSearchResults]);

  const [goToFirst] = useIteratorPagination();

  const encodeFilter = filter => {
    if (!filter) {
      return filter;
    }

    const trimedFilter = filter.trim();
    if (!trimedFilter) {
      return filter;
    }

    return encodeURIComponent(filter);
  };

  const handleEditUser = user => {
    setEditUser(!editUser);
    setUserData({
      userId: user.userId,
      userName: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email
    });
  };

  const handleEditUserClose = updatedUser => {
    setEditUser(!editUser);

    if (Object.keys(updatedUser).length === 0) {
      return;
    }
    setUserSearchResults(prevState =>
      prevState.map(user => (user.userId === updatedUser.userId ? { ...user, ...updatedUser } : user))
    );
  };

  const resetForm = () => {
    setOupIdFilter(null);
    setUsernameFilter(null);
    setEmailFilter(null);
    setFirstNameFilter(null);
    setLastNameFilter(null);
    setCreatedDateFromFilter(null);
    setCreatedDateToFilter(null);
    setNumberPerPage(20);
    stopSearch();
    emptySearch();
    const elements = document.getElementsByTagName('input');
    Array.from(elements).forEach(e => {
      e.value = '';
    });
  };

  const search = query => {
    const request = {
      userId: oupIdFilter,
      userEmail: encodeFilter(emailFilter),
      userName: encodeFilter(userNameFilter),
      firstName: encodeFilter(firstNameFilter),
      lastName: encodeFilter(lastNameFilter),
      createdDateTo: createdDateToFilter,
      createdDateFrom: createdDateFromFilter,
      pagination: query || goToFirst(numberPerPage)
    };
    searchUser(request);
  };

  const renderResults = () => {
    if (userSearchResults.length) {
      return (
        <HorizontalScrollContainer>
          <div className={styles.resultsContainer}>
            <UserSearchResults results={userSearchResults} total={userSearchTotal} onEditUserAction={handleEditUser} />
          </div>
        </HorizontalScrollContainer>
      );
    }
    return <div />;
  };

  const renderPagination = () => {
    if (userSearchResults.length) {
      return (
        <div>
          <div className={styles.paginationContainer}>
            <IteratorPagination
              paginationCallback={query => search(query)}
              items={userSearchResults}
              numberPerPage={numberPerPage}
              idField="userId"
              lastPage={Math.ceil(userSearchTotal / numberPerPage)}
            />
          </div>
        </div>
      );
    }
    return <div />;
  };

  const renderForm = () => (
    <div className={styles.searchForm}>
      <div className={styles.searchCriteriaContainer}>
        <Card headingText={content.enter} headingTag="h2" showHeadingLabel headingColor={colors.ARCHIVED}>
          <div className={styles.cardRow}>
            <span>{content.oup_id}</span>
            <TextInput
              labelHidden
              disableAutoComplete
              onChange={e => setOupIdFilter(e)}
              value={oupIdFilter || ''}
              id="oup_id"
              name="oup_id"
              placeholder=""
              label=""
            />
          </div>
          <div className={styles.cardRow}>
            <span>{content.username}</span>
            <TextInput
              labelHidden
              disableAutoComplete
              onChange={e => setUsernameFilter(e)}
              value={userNameFilter || ''}
              id="username"
              name="username"
              placeholder=""
              label=""
            />
          </div>
          <div className={styles.cardRow}>
            <span>{content.email}</span>
            <TextInput
              labelHidden
              disableAutoComplete
              onChange={e => setEmailFilter(e)}
              value={emailFilter || ''}
              id="email"
              name="email"
              placeholder=""
              label=""
            />
          </div>
          <div className={styles.cardRow}>
            <span>{content.first_name}</span>
            <TextInput
              labelHidden
              disableAutoComplete
              onChange={e => setFirstNameFilter(e)}
              value={firstNameFilter || ''}
              id="first_name"
              name="first_name"
              placeholder=""
              label=""
            />
          </div>
          <div className={styles.cardRow}>
            <span>{content.last_name}</span>
            <TextInput
              labelHidden
              disableAutoComplete
              onChange={e => setLastNameFilter(e)}
              value={lastNameFilter || ''}
              id="last_name"
              name="last_name"
              placeholder=""
              label=""
            />
          </div>
          <div className={styles.cardRow}>
            <span>{content.created_date_from}</span>
            <div>
              <FormCalendar
                dateFormat="YYYY-MM-DD"
                dateSelectedHandler={e => setCreatedDateFromFilter(e)}
                uniqueId="cal-from"
              />
            </div>
          </div>
          <div className={styles.cardRow}>
            <span>{content.created_date_to}</span>
            <div>
              <FormCalendar
                dateFormat="YYYY-MM-DD"
                dateSelectedHandler={e => setCreatedDateToFilter(e)}
                uniqueId="cal-to"
              />
            </div>
          </div>
          <div className={styles.cardRow}>
            <span>{content.per_page}</span>
            <select
              name="per_page"
              id="per_page"
              onChange={e => {
                setNumberPerPage(Number(e.target.value));
              }}
            >
              <option value={20} selected={numberPerPage === 20}>
                20
              </option>
              <option value={10} selected={numberPerPage === 10}>
                10
              </option>
              <option value={5} selected={numberPerPage === 5}>
                5
              </option>
            </select>
          </div>
        </Card>
      </div>
      <div className={styles.searchButtonsContainer}>
        <div>
          <Button type={buttonTypes.PRIMARY} text={content.search} onClick={() => search()} />
          <Button type={buttonTypes.PRIMARY} text={content.reset} onClick={() => resetForm()} />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.searchContainer}>
      <div>{content.facility}</div>
      <div>{content.list_header}</div>
      <div>
        <ul className={styles.fieldsList}>
          <li>{content.username}</li>
          <li>{content.email}</li>
          <li>{content.first_name}</li>
          <li>{content.last_name}</li>
        </ul>
      </div>
      <div>{renderForm()}</div>
      <div>{loading ? <LoadingSpinner /> : renderResults()}</div>
      <div>{renderPagination()}</div>
      <EditUserPanel isOpen={editUser} onCloseAction={handleEditUserClose} userData={userData} />
    </div>
  );
}

UserAdminSearch.propTypes = {
  localizedContent: PropTypes.object,
  searchUser: PropTypes.func,
  stopSearch: PropTypes.func,
  emptySearch: PropTypes.func,
  loading: PropTypes.bool,
  userSearchResults: PropTypes.any,
  userSearchTotal: PropTypes.number
};

const mapStateToProps = state => {
  const {
    userConsole: { loading, userSearchResults, userSearchTotal }
  } = state;
  return { loading, userSearchResults, userSearchTotal };
};

const mapDispatchToProps = dispatch => ({
  searchUser: filters => {
    dispatch(searchUserFromConsole(filters));
  },
  stopSearch: () => {
    dispatch(stopUserSearchForConsole());
  },
  emptySearch: () => {
    dispatch(emptyUserSearchResultsForConsole());
  }
});

export default compose(
  withLocalizedContent('userAdminSearch'),
  connect(mapStateToProps, mapDispatchToProps)
)(UserAdminSearch);
