import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import searchActivationCodes from '../../../apiCalls/searchActivationCodes';

const formatErrorMessage = msg => {
  const start = msg.lastIndexOf('[');
  const end = msg.lastIndexOf(']');
  return msg.substring(start + 1, end - 1);
};

function* checkActivationCodeStatus(action) {
  let apiResults = {};
  const request = {
    activationCodeId: action.data.activationCodeFilter || '',
    productId: action.data.productIdFilter || '',
    status: action.data.statusFilter || 'ALL',
    limit: action.data.numberPerPage || 10,
    currentPage: action.data.currentPage || 1,
    pagination: action.data.pagination || ''
  };
  apiResults = yield searchActivationCodes(request);

  if (apiResults.error || apiResults.status !== 'success') {
    yield put(actions.setErrorForDashboard({ error: formatErrorMessage(apiResults.message) }));
  } else {
    yield put(actions.setResponseForDashboard({ success: apiResults.data }));
  }
}

export default checkActivationCodeStatus;
