import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getUserLicences from './getUserLicencesByUser.saga';
import getUserLicence from './getUserLicence.saga';
import sendRemoveUserLicence from './sendRemoveUserLicence.saga';

function* root() {
  yield takeLatest(t.GET_USER_LICENCES_REQUEST, ({ payload: { criteria, value } }) => getUserLicences(criteria, value));
  yield takeLatest(t.GET_USER_LICENCE_REQUEST, ({ payload: licenceId }) => getUserLicence(licenceId));
  yield takeLatest(t.SEND_REMOVE_USER_LICENCE_REQUEST, ({ payload: licenceId }) => sendRemoveUserLicence(licenceId));
}

export default root;
