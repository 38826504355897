import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { featureIsEnabled } from '../globals/envSettings';

/**
 * Conditionally render a given context or fallback based on the value of a
 * named feature flag once environment settings have been fetched.
 *
 * @param {string}    props.name
 * @param {?Function} props.render
 * @param {?Function} props.renderFallback
 *
 * @return {React.Element}
 */
function Feature({ name, envReady, render = () => null, renderFallback = () => null }) {
  if (!envReady) {
    return null;
  }

  return featureIsEnabled(name) ? render() : renderFallback();
}

Feature.propTypes = {
  name: PropTypes.string.isRequired,
  render: PropTypes.func,
  renderFallback: PropTypes.func,
  envReady: PropTypes.bool
};

export default connect(state => ({ envReady: state.app.envReady }))(Feature);
