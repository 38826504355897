import React from 'react';
import withLocalizedContent from './withLocalizedContent';
import createGetYearGroupOptions from '../utils/createGetYearGroupOptions';

/**
 * Return a new component with the property `getYearGroupOptions` supplied
 * offering the ability to get the set of available year group options for a
 * given curriculum type. Additionally supplies a `getOptionNameByValue`
 * helper for looking up the display name for an option from its value.
 */
const withGetYearGroupOptions = Component =>
  withLocalizedContent('sharedTerms')(props => {
    const getYearGroupOptions = createGetYearGroupOptions(props.localizedContent.sharedTerms);

    return (
      <Component
        {...props}
        getYearGroupOptions={getYearGroupOptions}
        // eslint-disable-next-line prettier/prettier
        getOptionNameByValue={
          (options, value) => (Object.values(options).find(option => option.value === value) || {}).name
        }
      />
    );
  });

export default withGetYearGroupOptions;
