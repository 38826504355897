import { put } from 'redux-saga/effects';
import { loadOfflineUnits } from '../../../../actions/offlineContentPlayer';

function* addOfflineUnits(unitsToAdd) {
  const storedValue = localStorage.getItem('offline_units');
  const units = storedValue ? JSON.parse(storedValue) : [];

  unitsToAdd.forEach(unit => {
    const existingUnitIndex = units.findIndex(item => item.id === unit.id && item.userId === unit.userId);

    if (existingUnitIndex !== -1) {
      units[existingUnitIndex] = unit;
    } else {
      units.push(unit);
    }
  });

  localStorage.setItem('offline_units', JSON.stringify(units));
  yield put(loadOfflineUnits(units));
}

export default addOfflineUnits;
