import { put } from 'redux-saga/effects';
import { loadOfflineProducts } from '../../../../actions/offlineContentPlayer';

function* getOfflineProducts() {
  const storedValue = localStorage.getItem('offline_products');
  const products = storedValue ? JSON.parse(storedValue) : {};

  yield put(loadOfflineProducts(products));
}

export default getOfflineProducts;
