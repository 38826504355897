import { JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const getUnlinkSocialAccountUrl = userId => `${__API_BASE__}/user/unlink?userId=${encodeURIComponent(userId)}`;

export default function* unlinkSocialAccountsFetch(userId, body) {
  return yield signedFetch('unlinkSocialAccounts', getUnlinkSocialAccountUrl(userId), 'DELETE', body, {
    Accept: JSON_ENCODED,
    'Content-Type': JSON_ENCODED
  });
}
