// Constants
import { licenceStatus } from '../../../../globals/hubConstants';
import userRoles from '../../../../globals/userRoles';

const getOrgLicencesActionButtons = (
  content,
  { redirectToBuyMoreLicences = '', toggleDetails = null, recallLicenceAction = null },
  { status = '', isTeacherAssignedToClass = false }
) => {
  const { NOT_STARTED, ACTIVE, EXPIRED, NO_LICENCE, EXPIRING } = licenceStatus;

  if (status === NOT_STARTED) {
    return {
      [userRoles.ORG_ADMIN]: [
        {
          title: content.recall_licence,
          action: recallLicenceAction
        },
        {
          title: content.org_licences_buy_more_licences_text,
          action: null,
          to: redirectToBuyMoreLicences
        },
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ],
      [userRoles.TEACHER_ADMIN]: [
        {
          title: content.recall_licence,
          action: recallLicenceAction
        },
        {
          title: content.org_licences_buy_more_licences_text,
          action: null,
          to: redirectToBuyMoreLicences
        },
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ],
      [userRoles.TEACHER]: [
        isTeacherAssignedToClass
          ? {
              title: content.recall_licence,
              action: recallLicenceAction
            }
          : [],
        isTeacherAssignedToClass
          ? {
              title: content.org_licences_buy_more_licences_text,
              action: null,
              to: redirectToBuyMoreLicences
            }
          : [],
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ]
    };
  }
  if (status === ACTIVE || status === EXPIRING) {
    return {
      [userRoles.ORG_ADMIN]: [
        {
          title: content.org_licences_buy_more_licences_text,
          action: null,
          to: redirectToBuyMoreLicences
        },
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ],
      [userRoles.TEACHER_ADMIN]: [
        {
          title: content.org_licences_buy_more_licences_text,
          action: null,
          to: redirectToBuyMoreLicences
        },
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ],
      [userRoles.TEACHER]: [
        isTeacherAssignedToClass
          ? {
              title: content.org_licences_buy_more_licences_text,
              action: null,
              to: redirectToBuyMoreLicences
            }
          : [],
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ]
    };
  }
  if (status === EXPIRED) {
    return {
      [userRoles.ORG_ADMIN]: [
        {
          title: content.org_licences_buy_more_licences_text,
          action: null,
          to: redirectToBuyMoreLicences
        },
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ],
      [userRoles.TEACHER_ADMIN]: [
        {
          title: content.org_licences_buy_more_licences_text,
          action: null,
          to: redirectToBuyMoreLicences
        },
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ],
      [userRoles.TEACHER]: [
        isTeacherAssignedToClass
          ? {
              title: content.org_licences_buy_more_licences_text,
              action: null,
              to: redirectToBuyMoreLicences
            }
          : [],
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ]
    };
  }
  if (status === NO_LICENCE) {
    return {
      [userRoles.ORG_ADMIN]: [
        {
          title: content.org_licences_buy_more_licences_text,
          action: null,
          to: redirectToBuyMoreLicences
        },
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ],
      [userRoles.TEACHER_ADMIN]: [
        {
          title: content.org_licences_buy_more_licences_text,
          action: null,
          to: redirectToBuyMoreLicences
        },
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ],
      [userRoles.TEACHER]: [
        isTeacherAssignedToClass
          ? {
              title: content.org_licences_buy_more_licences_text,
              action: null,
              to: redirectToBuyMoreLicences
            }
          : [],
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        }
      ]
    };
  }

  return {
    [userRoles.ORG_ADMIN]: [
      {
        title: content.org_licences_buy_more_licences_text,
        action: null,
        to: redirectToBuyMoreLicences
      },
      {
        title: content.org_licences_view_product_info_text,
        action: toggleDetails
      }
    ],
    [userRoles.TEACHER_ADMIN]: [
      {
        title: content.org_licences_buy_more_licences_text,
        action: null,
        to: redirectToBuyMoreLicences
      },
      {
        title: content.org_licences_view_product_info_text,
        action: toggleDetails
      }
    ]
  };
};

export default getOrgLicencesActionButtons;
