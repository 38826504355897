// Helper to merge new objects into state storage data.
// Important: Existing ones are amended instead of being replaced.
//  oldData - One of the state store objects, eg state.people.data. (A map of id:object pairs)
//  newData - A map of new or updated objects to merge into the store. (A map of id:object pairs)
export default function updateStore(oldData, newData) {
  console.log('[updateStore] newData:', newData, 'oldData:', oldData);

  if (!newData) {
    return oldData;
  }
  if (oldData === Object(oldData) && newData === Object(newData)) {
    return Object.keys(newData || {}).reduce(
      (results, id) => {
        // eslint-disable-next-line no-param-reassign
        results[id] = { ...oldData[id], ...newData[id] };
        return results;
      },
      { ...oldData }
    );
  }
  throw new Error('updateStore() expects 2 arguments that are plain objects with numeric keys');
}
