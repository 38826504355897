import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import Button, { validButtonTypes } from '../Button/Button';
import { validGlyphs } from '../SVGIcon/SVGIcon';
import styles from './ContentWithButton.scss';

/** Simple composable component for adding a button to some content */
function ContentWithButton({
  id,
  buttonType,
  buttonText,
  buttonGlyph,
  buttonOnClick,
  buttonDisabled,
  buttonIconOnly,
  buttonTestHook = '',
  buttonContainerClassName,
  children
}) {
  return (
    <div className={classnames(styles.contentWithButton, buttonContainerClassName)}>
      <div className={styles.children}>{children}</div>
      {buttonOnClick && (
        <Button
          id={id}
          type={buttonType}
          text={buttonText}
          glyph={buttonGlyph}
          onClick={buttonOnClick}
          disabled={buttonDisabled}
          iconOnly={buttonIconOnly}
          dataTestId={buttonTestHook}
        />
      )}
    </div>
  );
}

ContentWithButton.propTypes = {
  id: PropTypes.string,
  buttonType: PropTypes.oneOf(validButtonTypes).isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonGlyph: PropTypes.oneOf(validGlyphs),
  buttonOnClick: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  buttonIconOnly: PropTypes.bool,
  buttonTestHook: PropTypes.string,
  buttonContainerClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired
};

export default ContentWithButton;
