import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PopoutPanelIconHeading, { types } from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import FormController from '../../../CreateOrganisation/formUtil/FormController.js';

// Sub-view components
import OrgArchiveInputting from './OrgArchiveInputting.js';
import OrgArchiveConfirmation from './OrgArchiveConfirmation.js';

import content from '../../../../utils/cmsContent.js';

// Define component:
function OrgArchive({ backButtonAction, closePanel }) {
  const CMS = content.orgArchivePage || {};
  return (
    <div>
      <FormController
        formName="orgArchive"
        initialFormState="INPUTTING"
        formStateMapping={{
          INPUTTING: <OrgArchiveInputting closePanel={closePanel} backButtonAction={backButtonAction} />,
          ARE_YOU_SURE: <OrgArchiveInputting closePanel={closePanel} backButtonAction={backButtonAction} />,
          SUBMITTING: (
            <PopoutPanelIconHeading
              type={types.LOADING}
              title={CMS.duringArchive_heading_text}
              subtitle={CMS.duringArchive_subheading_text}
            />
          ),
          CONFIRMATION: <OrgArchiveConfirmation closePanel={closePanel} />
        }}
      />
    </div>
  );
}

// Define props:
OrgArchive.propTypes = {
  closePanel: PropTypes.func.isRequired,
  // Only needed when we want to show a Back button, such as when Archive is launched from EditOrg:
  backButtonAction: PropTypes.func
};

export default connect(null, (dispatch, { closePanel }) => ({
  closePanel: skipConfirmCloseModal => {
    closePanel(skipConfirmCloseModal);
  }
}))(OrgArchive);
