import qs from 'query-string';

/**
 * This function is responsible for the shared sort switching in
 * Workbook and Graded Readers. Existing sort state is pulled from the
 * query string, updated (flipped) then passed back out.
 *
 * @param {string} sortKey
 */
export default function flipSortDirection(sortKey) {
  const { sortKey: currentSortKey, sortDirection: currentSortDirection } = qs.parse(window.location.search);

  if (sortKey === currentSortKey) {
    return currentSortDirection === 'asc' ? 'desc' : 'asc';
  }

  return 'asc';
}
