import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getDeferredEmailsRequest from '../../../api/getDeferredEmails';

function* getDeferredEmails(userRole) {
  const response = yield getDeferredEmailsRequest(userRole);
  if (response.status !== 'success') {
    yield put(actions.getDeferredEmailsFailure(response.error));
    return;
  }
  yield put(actions.getDeferredEmailsSuccess(response));
}

export default getDeferredEmails;
