import { createSelector } from 'reselect';

/**
 * A selector returning the current organisation for the authenticated user.
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const getCurrentOrganisation = createSelector(
  state => state.identity.currentOrganisationId,
  state => state.organisations.data,
  (orgId, data) => data[orgId]
);

export default getCurrentOrganisation;
