import React, { Component } from 'react';
import styles from './OteTopRow.scss';

import cmsContent from '../../../../utils/cmsContent.js';

export default class OteTopRow extends Component {
  constructor() {
    super();
    this.state = { CMS: {}, style: { imgTop: 0, heroMarginTop: 0 } };
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.oteHomepage || {} });
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll);
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.oteHomepage || {} });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  _handleScroll = () => {
    const value = window.scrollY / 6;
    this.setState({
      style: {
        imgTop: value,
        heroMarginTop: -value
      }
    });
  };

  render() {
    const { CMS, style } = this.state;

    return (
      <div className={styles.OteHomeTopRow}>
        <div className={styles.heroContainer} data-testid="HERO_CONTAINER">
          <img
            src={CMS.content_image_src_0}
            alt={CMS.alt_image_src_0}
            className={styles.heroImage}
            style={{ marginTop: style.heroMarginTop }}
          />
          <div className={styles.heroContent}>
            <p>{CMS.hero_description_0}</p>
            <p>{CMS.hero_description_1}</p>
            <p>
              <span className={styles.blueBackground}>{CMS.hero_description_2}</span>
            </p>
          </div>
          <img
            src={CMS.content_image_src_3}
            alt={CMS.alt_image_src_3}
            className={styles.triangleImage}
            style={{ top: style.imgTop }}
          />
        </div>
        <div className={styles.halfSunContainer} data-testid="SUB_HERO_CONTAINER">
          <img src={CMS.content_image_src_1} alt={CMS.alt_image_src_1} />
        </div>
      </div>
    );
  }
}
