import React, { Component } from 'react';
import styles from './TopRow.scss';
import Button, { buttonTypes } from '../../../../components/Button/Button.js';
import { socialIdpIsEnabled } from '../../../../globals/envSettings';
import { getCurrentPlatform } from '../../../../utils/platform';

import cmsContent from '../../../../utils/cmsContent.js';

export default class TopRow extends Component {
  constructor() {
    super();
    this.state = { CMS: {} };
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.homepage || {} });
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.homepage || {} });
  }

  render() {
    const { CMS } = this.state;

    // Temporary code to represent NGS DemoPages CMS Content
    const isNGS = /^\/ngs$/.test(window.location.pathname);

    if (isNGS) {
      CMS.heroBanner_heading_text = 'Welcome to NGS';
      CMS.heroBanner_body_text = '';
      CMS.heroBanner1_img_src = null;
      CMS.heroBanner2_img_src = null;
    }

    // Temporary code to represent NGS DemoPages CMS Content
    const registerButtonLinkHref = socialIdpIsEnabled(getCurrentPlatform())
      ? CMS.heroBanner_link_choice_url
      : CMS.heroBanner_link_url;
    return (
      <div className={styles.homePageSectionOne}>
        <div className="grid grid_max_width">
          {/* Hero banner */}
          <div className="row" id="hero-banner">
            <div className="col md6">
              <div className={styles.bannerText}>
                <h1>{CMS.heroBanner_heading_text}</h1>
                <p>{CMS.heroBanner_body_text}</p>
                <Button
                  to={registerButtonLinkHref}
                  type={buttonTypes.SECONDARY}
                  text={CMS.heroBanner_link_text}
                  dataTestId="GOTO_REGISTER"
                />
              </div>
            </div>

            <div className="col md6">
              <div className={styles.imageContainer}>
                {CMS.heroBanner1_img_src && <img aria-hidden="true" alt="" src={CMS.heroBanner1_img_src} />}
                {CMS.heroBanner2_img_src && <img aria-hidden="true" alt="" src={CMS.heroBanner2_img_src} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
