import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './CarouselControls.scss';
import { bgClass } from '../../utils/colorClassNames';

import COLORS, { validColors } from '../../globals/colors';
import Button, { buttonTypes, GLYPHS } from '../Button/Button';

import content from '../../utils/cmsContent';
// const content = {
//   appComponents: {
//     archived_button: 'Archived',
//     edit_button: 'Edit',
//     search_button: 'Search',
//     filter_button: 'Filter',
//     sort_button: 'Sort',
//     help_and_support_button: 'Help & Support',
//     settings_button: 'Settings',
//     ally_subtract_1: 'Subtract 1',
//     ally_add_1: 'Add 1',
//     pagination_heading_text: 'Pagination navigation',
//     previous_page_text: 'Previous page',
//     aria_page_text: 'Page',
//     next_page_text: 'Next page',
//     password_error_text: 'Password must contain 1 uppercase, 1 lowercase and be between 6-15 characters',
//     added_to_class_success: 'Added to class',
//     added_to_class_warning: 'Not added to class',
//     removed_from_class_success: 'Removed from class',
//     removed_from_class_warning: 'Not removed from class',
//     error_loading_search_text: 'There was an error loading search results',
//     close_text: 'Close',
//     label_show_text: 'Show',
//     search_text: 'Search',
//     results_text: 'results',
//     spaces_avalible_text: 'spaces available',
//     selected_text: 'selected',
//     next_text: 'Next',
//     skip_text: 'Skip',
//     aria_currently_selected: 'currently selected'
//   }
// };

function CarouselControls({
  id,
  current,
  items,
  disabled,
  prevButtonAction,
  nextButtonAction,
  ariaControls,
  hasBeenPressed = false,
  carouselText = '',
  handleOnBlur = null
}) {
  const CMS = content.appComponents || {};
  return (
    <div id={id} className={classnames(styles.carouselControls)}>
      {/* Prev button */}
      <Button
        iconOnly
        text={hasBeenPressed ? '' : CMS.previous_page_text}
        title={null}
        id="carouselPrev"
        type={buttonTypes.SECONDARY}
        glyph={GLYPHS.ICON_LEFT_THICK}
        aria={{ controls: ariaControls }}
        onClick={disabled ? null : prevButtonAction}
        onBlur={handleOnBlur}
        ariaLabel={hasBeenPressed ? carouselText : ''}
      />

      <div>
        {items.length > 6 ? (
          <p>
            <em>{current + 1}</em>
            <span> of {items.length}</span>
          </p>
        ) : (
          // The nav element has an implicit role="navigation". Some advise that we should
          // set that attribute but in tests our screenreaders were happy with the element as is.
          <nav aria-label={CMS.pagination_heading_text || 'Pagination navigation'} aria-controls={ariaControls}>
            <ol>
              {items.map((item, index) => {
                const friendlyPageLabel =
                  index === current
                    ? `${CMS.aria_page_text} ${index + 1} ${CMS.aria_currently_selected}`
                    : `${CMS.aria_page_text} ${index + 1}`;

                // Page number buttons. We use aria-current to tell Assistive Tech about current page:
                return (
                  <li key={index} className={index === current ? styles.selected : ''}>
                    <button
                      type="button"
                      id={`carouselBreadcrumb${index}`}
                      className={index === current ? bgClass(item.color || COLORS.PRIMARY_BLUE) : ''}
                      aria-current={index === current ? 'page' : null}
                      aria-label={hasBeenPressed ? carouselText : friendlyPageLabel}
                      onClick={disabled ? null : item.action}
                      onBlur={handleOnBlur}
                    />
                  </li>
                );
              })}
            </ol>
          </nav>
        )}
      </div>

      {/* Next button */}
      <Button
        iconOnly
        text={hasBeenPressed ? '' : CMS.next_page_text}
        title={null}
        id="carouselNext"
        type={buttonTypes.SECONDARY}
        glyph={GLYPHS.ICON_RIGHT_THICK}
        aria={{ controls: ariaControls }}
        onClick={disabled ? null : nextButtonAction}
        onBlur={handleOnBlur}
        ariaLabel={hasBeenPressed ? carouselText : ''}
      />
    </div>
  );
}

CarouselControls.propTypes = {
  id: PropTypes.string.isRequired,
  current: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      color: PropTypes.oneOf(validColors)
    })
  ).isRequired,
  disabled: PropTypes.bool,
  prevButtonAction: PropTypes.func.isRequired,
  nextButtonAction: PropTypes.func.isRequired,
  ariaControls: PropTypes.string.isRequired,
  hasBeenPressed: PropTypes.bool.isRequired,
  handleOnBlur: PropTypes.func,
  carouselText: PropTypes.string
};

export default CarouselControls;
