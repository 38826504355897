import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Action imports
import { setClassroomName, classroomNameOnBlur } from '../../../../../../redux/reducers/classroomCreate.js';

// Component imports
import TextInput from '../../../../../../components/TextInput/TextInput.js';
import Validation from '../../../../../../components/Validation/Validation.js';

import cmsContent from '../../../../../../utils/cmsContent.js';

// Diddy form for adding name of NEW CLASS:
function ClassroomNameInput({
  // Input values
  classroomNameValue,
  classroomNameValidationMessage,
  classroomNameValidationPending,
  classroomNameValidationIsInvalid,
  classroomNameValidationIsWarning,
  classroomNameValidationIsValid,
  classNameLabel,
  isInputDisabled,
  // Input onChanges
  setClassroomNameAction,
  // Input onBlur
  classroomNameOnBlurAction,
  dataTestId = ''
}) {
  const CMS = cmsContent.addClassPanel || {};
  return (
    <Validation
      isError={classroomNameValidationIsInvalid}
      isWarning={classroomNameValidationIsWarning}
      isWaiting={classroomNameValidationPending}
      isValid={classroomNameValidationIsValid}
      message={classroomNameValidationMessage}
      forId="classroomName"
      dataTestId="CLASSROOM_NAME_INPUT"
    >
      {/* Note how we hide the browser's native autoComplete suggestions while displaying error or warning: */}
      <TextInput
        id="classroomName"
        label={classNameLabel || CMS.class_name_label}
        placeholder={CMS.class_name_placeholder}
        maxLength={255}
        value={classroomNameValue}
        onChange={setClassroomNameAction}
        onBlur={classroomNameOnBlurAction}
        disableAutoComplete={
          classroomNameValidationIsInvalid || classroomNameValidationIsWarning || classroomNameValidationPending
        }
        disabled={isInputDisabled}
        dataTestId={dataTestId}
      />
    </Validation>
  );
}

ClassroomNameInput.propTypes = {
  // Input values
  classroomNameValue: PropTypes.string.isRequired,
  classroomNameValidationMessage: PropTypes.string.isRequired,
  classroomNameValidationPending: PropTypes.bool.isRequired,
  classroomNameValidationIsInvalid: PropTypes.bool.isRequired,
  classroomNameValidationIsWarning: PropTypes.bool.isRequired,
  classroomNameValidationIsValid: PropTypes.bool.isRequired,
  isInputDisabled: PropTypes.bool,
  // Input onChanges
  setClassroomNameAction: PropTypes.func.isRequired,
  classNameLabel: PropTypes.string,
  // Validate input onBlur
  classroomNameOnBlurAction: PropTypes.func,
  dataTestId: PropTypes.string
};

export default connect(
  state => ({
    // Connect values
    classroomNameValue: state.classroomCreate.classroomNameValue,
    classroomNameValidationMessage: state.classroomCreate.classroomNameValidationMessage,
    classroomNameValidationPending: state.classroomCreate.classroomNameValidationPending,
    classroomNameValidationIsInvalid: state.classroomCreate.classroomNameValidationIsInvalid,
    classroomNameValidationIsWarning: state.classroomCreate.classroomNameValidationIsWarning,
    classroomNameValidationIsValid: state.classroomCreate.classroomNameValidationIsValid
  }),
  {
    // Connect onChange actions
    setClassroomNameAction: setClassroomName,
    classroomNameOnBlurAction: classroomNameOnBlur
  }
)(ClassroomNameInput);
