import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Button from '../Button/Button';

function Loading() {
  return (
    <div className="text-center">
      <div style={{ width: '5rem', margin: '2rem auto' }}>
        <SVGIcon glyph={GLYPHS.ICON_LOADING} />
      </div>
    </div>
  );
}

function Failure({ localizedContent, triggerSearch }) {
  return (
    <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="text-center">
      <div style={{ width: '5rem', margin: '2rem auto 0' }}>
        <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
      </div>
      <p className="gin2">{localizedContent.search_results_error_text}</p>
      <Button id="search_retry" text={localizedContent.action_message_retry} onClick={triggerSearch} />
    </div>
  );
}

Failure.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  triggerSearch: PropTypes.func.isRequired
};

function RequestComponent({ requestFailed = false, localizedContent, triggerSearch }) {
  return requestFailed ? <Failure localizedContent={localizedContent} triggerSearch={triggerSearch} /> : <Loading />;
}

RequestComponent.propTypes = {
  requestFailed: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired,
  triggerSearch: PropTypes.func.isRequired
};

export default RequestComponent;
