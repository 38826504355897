import signedFetch from '../apiCalls/util/signedFetch';

// @ts-ignore
export const sendOicEmailsUrl = () => `${__API_BASE__}/oic/send-oic-report-ready-notification`;

function sendOicEmails(payload) {
  return signedFetch('sendOicEmails', sendOicEmailsUrl(payload), 'POST');
}

export default sendOicEmails;
