import { put } from 'redux-saga/effects';
import {
  getProductFinderProductsSuccess,
  getProductFinderProductsFailure
} from '../../../../actions/productFinderActions';
import getProductFinderProductsRequest from '../../../apiCalls/hub/getProductFinderProducts';

function* getProductFinderProducts(payload) {
  const response = yield getProductFinderProductsRequest(payload);
  if (response.status === 'success') {
    const assignableProducts = response.data.filter(product => product.isassignable === 'true');
    yield put(getProductFinderProductsSuccess(assignableProducts));
  } else {
    yield put(getProductFinderProductsFailure('Failed to get products'));
  }
}

export default getProductFinderProducts;
