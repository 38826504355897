import React from 'react';
import PropTypes from 'prop-types';
import PersonRepresentation from '../../../../../../components/PersonRepresentation/PersonRepresentation';
import PopoutActionFooter from '../../../../../../components/PopoutActionFooter/PopoutActionFooter';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import { SIZES as thumbnailSizes } from '../../../../../../components/Thumbnail/Thumbnail';
import { COLOR_FOR_ROLE } from '../../../../../../globals/colors';
import { toInitials } from '../../../../../../utils/string';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer';

function ClassUserRequestReview({
  localizedContent: content,
  classroomName = '',
  curriculumType = '',
  users = {},
  onSubmitClick,
  getOptionNameByValue,
  getYearGroupOptions
}) {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <PopoutPanelIconHeading
              type={iconHeadingTypes.WARNING}
              title={(content.review_join_user_confirmation_title || '').replace('{className}', classroomName)}
            />
          </div>
        }
        footerContent={
          <PopoutActionFooter
            primaryButtonText={content.review_accepts_confirm_button.replace('{className}', classroomName)}
            primaryButtonAction={() => onSubmitClick('ACCEPTED', true)}
            tertaryButtonText={content.review_rejects_confirm_button.replace('{className}', classroomName)}
            tertaryButtonAction={() => onSubmitClick('REJECTED', true)}
          />
        }
      >
        <div>
          {Object.entries(users).map(([userId, { firstname, lastname, email, yearGroup, isLocked, roleName }]) => (
            <div key={userId} className="gin2">
              <PersonRepresentation
                key={userId}
                thumbnailSize={thumbnailSizes.MEDIUM}
                name={`${firstname} ${lastname}`}
                initials={toInitials(firstname, lastname)}
                email={email}
                thumbnailColor={COLOR_FOR_ROLE[roleName] || COLOR_FOR_ROLE.USER}
                locked={isLocked}
                yearGroup={curriculumType ? getOptionNameByValue(getYearGroupOptions(curriculumType), yearGroup) : ''}
              />
            </div>
          ))}
        </div>
      </ScrollContainer>
    </form>
  );
}

ClassUserRequestReview.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  classroomName: PropTypes.string.isRequired,
  curriculumType: PropTypes.string,
  users: PropTypes.object,
  // notifyByEmail: PropTypes.bool,
  onSubmitClick: PropTypes.func,
  // onNotifyByEmailToggle: PropTypes.func,
  getYearGroupOptions: PropTypes.func.isRequired,
  getOptionNameByValue: PropTypes.func.isRequired
};

export default ClassUserRequestReview;
