// Usage: myString.replace(LTRIM,'')
export const LTRIM = /^\s+/;

// Usage: myString.replace(RTRIM,'')
export const RTRIM = /\s+$/;

export const EXTERNAL_LINK = /^https?:\/\//;

export const NAME_MAX_LIMIT = 40;

export const USERNAME_MAX_LIMIT = 60;

export const ALLOWED_ONLY_ALPHANUMERIC = /^[A-Za-z0-9]+$/;

export const ALLOWED_ONLY_ALPHANUMERIC_AND_OPTIONAL_PERIOD = /^([A-Za-z0-9]+)\.?([A-Za-z0-9]+)?$/;

export const MANAGED_USER_ALLOWED_CHARACTERS = /^[a-zA-Z0-9\s\-'’]{1,40}$/;

export function isExternalLink(href) {
  return (
    !!href && EXTERNAL_LINK.test(href) && href.indexOf(`${window.location.protocol}//${window.location.host}`) !== 0
  );
}

export function isScrollToIdLink(href) {
  return href?.toString().startsWith('#');
}
