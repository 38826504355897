function formatDateTime(value) {
  const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
  const formatedValue = new Intl.DateTimeFormat('en-GB', options).format(new Date(value));

  return formatedValue;
}

function formatMaintenanceMessage(maintenanceStartDate, maintenanceEndDate, toBeReplaced) {
  const formattedStartDateTime = formatDateTime(maintenanceStartDate);
  const formattedEndDateTime = formatDateTime(maintenanceEndDate);

  return toBeReplaced.replace('{startDateTime}', formattedStartDateTime).replace('{endDateTime}', formattedEndDateTime);
}

export default formatMaintenanceMessage;
