import * as types from '../actionTypes';

/**
 * Create an action to set the user record for the managed user edit panel.
 *
 * @param {Object} user
 *
 * @return {Object}
 */
export const setManagedUserEditRecord = user => ({
  type: types.MANAGED_USER_EDIT_SET_RECORD,
  payload: user
});

/**
 * Create an action to locally validate attributes when editing a managed user.
 *
 * @param {Object} fragment The subset of user data to validate
 *
 * @return {Object}
 */
export const validateManagedUserEditUserFragment = fragment => ({
  type: types.MANAGED_USER_EDIT_VALIDATE,
  payload: fragment
});

export const editUserPasswordFragment = fragment => ({
  type: types.MANAGED_USER_EDIT_PASSWORD,
  payload: {
    password: fragment
  }
});

export const resetValidation = key => ({
  type: types.MANAGED_USER_RESET_VALIDATION,
  payload: { key }
});

/**
 * Create an action to store the result error set following local validation for
 * the managed user edit panel.
 *
 * @param {Object} result
 *
 * @return {Object}
 */
export const setManagedUserEditValidationResult = result => ({
  type: types.MANAGED_USER_EDIT_VALIDATE_SET_RESULT,
  payload: result
});

/**
 * Create an action to submit a request to update a managed user record.
 *
 * @param {string} orgId
 * @param {string} userId
 * @param {Object} user
 *
 * @return {Object}
 */
export const submitEditManagedUser = (orgId, userId, user, fields) => ({
  type: types.MANAGED_USER_EDIT_SUBMIT,
  payload: { orgId, userId, user, fields }
});

/**
 * Create an action to mark the managed user update as succeeded.
 *
 * @return {Object}
 */
export const setManagedUserEditSuccess = () => ({
  type: types.MANAGED_USER_EDIT_SUCCESS
});

/**
 * Create an action to mark the managed user update as failed, with an optional
 * payload object detailing errors.
 *
 * @param {?Object} payload
 *
 * @return {Object}
 */
export const setManagedUserEditFailure = payload => ({
  type: types.MANAGED_USER_EDIT_FAILURE,
  payload
});

export const setManagedUserPasswordFailure = payload => ({
  type: types.MANAGED_USER_PASSWORD_FAILURE,
  payload
});

export const setYearGroupRequired = payload => ({
  type: types.MANAGED_USER_YEAR_GROUP_FAILURE,
  payload
});

/**
 * Create an action to clear the failure state of the managed user edit form.
 *
 * @return {Object}
 */
export const clearManagedUserEditFailure = () => ({
  type: types.MANAGED_USER_EDIT_RETRY
});

// Bulikupload V2

export const validateUserNameGlobal = userNameWithCustomId => ({
  type: types.MANAGED_USER_VALIDATE_USERNAME_GLOBAL,
  payload: { userNameWithCustomId }
});

export const validateUserNameGlobalComplete = isUserNameTaken => ({
  type: types.MANAGED_USER_VALIDATE_USERNAME_GLOBAL_COMPLETE,
  payload: { isUserNameTaken }
});

export const setManagedUserResetPassword = () => ({
  type: types.MANAGED_USER_RESET_PASSWORD
});

export const triggerBack = () => ({
  type: types.MANAGED_USER_TRIGGER_BACK
});

export const setManagedUserApiStatus = status => ({
  type: types.PUT_MANAGED_USER_API,
  payload: status
});

export const setStateForEditedField = (editedField, editedFieldState) => ({
  type: types.SET_MANAGED_USER_EDITED_FIELD_STATE,
  payload: { editedField, editedFieldState }
});

export const setStateForEditedForm = (editedField, editedFieldState) => ({
  type: types.SET_MANAGED_USER_EDITED_FORM_STATE,
  payload: { editedField, editedFieldState }
});

export const clearStateForEditedField = () => ({
  type: types.SET_MANAGED_USER_EDITED_CLEAR_FORM_STATE
});
