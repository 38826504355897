import { select, put } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { toQueryStringWithMandatoryParams } from '../../../../../utils/url';

function* callLTIConsumer() {
  const {
    downloadResourceId,
    downloadUrl,
    resourceAction,
    product = 'lor',
    domain = null,
    assignmentId,
    resourceType = null,
    roles,
    contextId = null,
    activityId = null,
    studentId = null,
    isAdaptTestFlow = false,
    assessmentEntitlement = null,
    productTitle = null,
    returnPath = null,
    isAdaptedTest = false,
    assessmentTimeLimit = '',
    assessmentId = null,
    courseCode = null,
    courseId = null,
    subtitleResourceId = null,
    title = null
  } = yield select(state => state.hubResourceDownload);
  yield put({ type: t.HUB_DOWNLOAD_IS_LOADING });
  console.log(`LTI product - ${product}`);
  const queryParams = {
    product,
    resourceId: downloadResourceId,
    action: resourceAction,
    domain,
    roles,
    resourceType,
    contextId,
    activityId,
    studentId,
    isAdaptTestFlow,
    assessmentEntitlement,
    productTitle,
    returnPath,
    isAdaptedTest,
    assessmentTimeLimit,
    taskAssignmentId: assessmentId,
    courseCode,
    courseId,
    subtitleResourceId
  };
  const url = downloadUrl || `${__API_BASE__}/lti/consumer?${toQueryStringWithMandatoryParams(queryParams)}`;

  const response = yield signedFetch('ltiConsumer', url, 'GET');
  if (response.status && response.status === 'SUCCESS') {
    const apiResponse = response.data;
    apiResponse.resourceName = title;
    yield put({ type: t.HUB_DOWNLOAD_RESOURCE_SUCCESS, resource: apiResponse, resourceId: downloadResourceId });
  } else {
    yield put({
      type: t.HUB_DOWNLOAD_RESOURCE_FAILURE,
      isError: true,
      downloadType: product,
      resourceId: downloadResourceId,
      assignmentId,
      productTitle
    });
  }
}

export default callLTIConsumer;
