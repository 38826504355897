import * as t from '../../actionTypes';

const initialState = {
  courseId: '',
  bookId: '',
  book: {},
  submitting: false,
  success: false,
  error: false
};

function ngsCourseBook(state = initialState, { type, payload }) {
  switch (type) {
    case t.NGS_LOAD_COURSE_BOOK:
      return {
        ...initialState,
        courseId: payload.courseId,
        bookId: payload.bookId
      };
    case t.NGS_UNLOAD_COURSE_BOOK:
      return initialState;
    case t.NGS_LOAD_COURSE_BOOK_SUCCESS:
      return { ...state, book: payload, submitting: false, success: true };
    case t.NGS_LOAD_COURSE_BOOK_FAILURE:
      return { ...state, submitting: false, error: true };
    default:
      return state;
  }
}

export default ngsCourseBook;
