import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import styles from '../SkeletonLoader.scss';
import colors from '../../../globals/colors';

function DialsSkeletonLoader({
  speed = 2,
  foregroundColor = colors.COLOR_GREY_DISABLED,
  backgroundColor = colors.COLOR_WHITE,
  style = styles.orbDialsContainer
}) {
  return (
    <div className={styles.skeletonLoader}>
      <div className={style}>
        <ContentLoader
          height="100%"
          width="100%"
          viewBox="0 0 770 100"
          className={styles.loader}
          speed={speed}
          foregroundColor={foregroundColor}
          backgroundColor={backgroundColor}
        >
          <circle cx="14%" cy="105%" r="17%" />
          <circle cx="50%" cy="105%" r="17%" />
          <circle cx="85%" cy="105%" r="17%" />
        </ContentLoader>
      </div>
      <div className={styles.orbDialsFlexContainer}>
        {Array(3)
          .fill('')
          .map((e, i) => (
            <div key={i}>
              <ContentLoader
                height="100%"
                width="100%"
                viewBox="0 0 100 100"
                className={styles.loader}
                speed={speed}
                foregroundColor={foregroundColor}
                backgroundColor={backgroundColor}
              >
                <circle cx="50%" cy="105%" r="40%" />
              </ContentLoader>
            </div>
          ))}
      </div>
    </div>
  );
}

DialsSkeletonLoader.propTypes = {
  // Prop Defined
  speed: PropTypes.number,
  foregroundColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  style: PropTypes.any
};

export default DialsSkeletonLoader;
