import { put, take, call } from 'redux-saga/effects';
import { RESET_FORM, showConfirmation, SUBMIT_FORM } from '../../../../../reducers/addToProfile.reducer';
import { featureIsEnabled } from '../../../../../../globals/envSettings';
import { pollRedemptionAdded } from '../../dataRecency/pollRedemption';
import userPlatformStatistics from '../../userPlatformStatistics';
import activationCodeService from './singleInputToProfileRequest.saga';

function* _isActivationCodeValid() {
  console.log('[singleInputToProfile Saga] Send api request to validate activation code');

  const result = yield activationCodeService.validateActivationCodeRequest();

  console.log(`result:::: ${result}`);

  const errorOccurred = result.status !== 'success';
  const errorCode = (errorOccurred && { code: result.message.match(/\d+/)[0] }) || '';

  if (typeof errorCode === 'object') {
    const codeActivationDate = result.message.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
    if (codeActivationDate) {
      errorCode.activationDate = codeActivationDate[0];
    }
  }
  return { errorOccurred, errorCode };
}

function* _redeemCode() {
  console.log('[singleInputToProfile Saga] Submitting addTolLibrary request');

  const result = yield activationCodeService.singleInputToProfileRequest();

  console.log(`result:::: ${result}`);

  const errorOccurred = result.status !== 'success';
  const errorCode = (errorOccurred && { code: result.message.match(/\d+/)?.[0] }) || '';
  let licenseId = '';

  if (result?.data?.licenses && result.data.licenses.length) {
    const [license] = result.data.licenses;
    licenseId = license.oupLicense?.licenseId;
  }

  if (typeof errorCode === 'object') {
    const codeActivationDate = result.message.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
    if (codeActivationDate) {
      errorCode.activationDate = codeActivationDate[0];
    }
  }
  return { errorOccurred, errorCode, licenseId };
}

export default function* singleInputToProfile() {
  console.log('[singleInputToProfile Saga] Beginning');
  while (true) {
    console.log('[singleInputToProfile Saga] Waiting for user to submit form');
    yield take(SUBMIT_FORM);

    let { errorCode, errorOccurred } = yield _isActivationCodeValid();

    if (!errorOccurred) {
      const result = yield _redeemCode();
      ({ errorOccurred, errorCode } = result);

      if (!errorOccurred && result.licenseId) {
        yield pollRedemptionAdded([result.licenseId]);

        if (featureIsEnabled('opt-main-features')) {
          yield call(userPlatformStatistics);
        }
      }
    }

    yield put(
      // Get ERROR based on code from API
      showConfirmation(errorOccurred, errorCode)
    );

    yield take(RESET_FORM);
    console.log('[singleInputToProfile Saga] Resetting singleInputToProfile state and saga');
  }
}
