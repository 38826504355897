import * as t from '../actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const switchToPage = data => ({
  type: t.ASSESSMENTS_SWITCH_TO_PAGE,
  payload: data
});
export const timeGotSet = () => ({
  type: t.ASSESSMENTS_TIME_SET
});
export const setHasErrors = data => ({
  type: t.ASSESSMENTS_SET_HAS_ERRORS,
  payload: data
});
export const setStartDate = data => ({
  type: t.ASSESSMENTS_SET_START_DATE,
  payload: data
});
export const setEndDate = data => ({
  type: t.ASSESSMENTS_SET_END_DATE,
  payload: data
});
export const setStartHour = data => ({
  type: t.ASSESSMENTS_SET_START_HOUR,
  payload: data
});
export const setEndHour = data => ({
  type: t.ASSESSMENTS_SET_END_HOUR,
  payload: data
});
export const setSendEmailPreference = data => ({
  type: t.SET_SEND_EMAIL_PREFERENCE,
  payload: data
});
export const setStudentsFormReseted = () => ({
  type: t.ASSESSMENTS_STUDENT_FORM_RESETED
});
export const setOnlineTestAllStudents = data => ({
  type: t.ASSESSMENTS_SET_ALL_STUDENTS,
  payload: data
});
export const setOnlineTestStudents = data => ({
  type: t.ASSESSMENTS_SET_STUDENTS,
  payload: data
});
export const setInitialOnlineTestStudents = () => ({
  type: t.ASSESSMENTS_SET_INITIAL_STUDENTS
});
export const resetOnlineTestPanel = () => ({
  type: t.ASSESSMENTS_RESET_PANEL
});
export const resetStatusOptionsOnlineTest = () => ({
  type: t.ASSESSMENTS_RESET_STATUS_OPTIONS
});
export const resetOnlineClass = data => ({
  type: t.ASSESSMENTS_RESET_FROM_CLASS,
  payload: data
});
export const setOnlineTestId = data => ({
  type: t.ASSESSMENTS_SET_ONLINE_TEST_ID,
  payload: data
});
export const setOnlineTestData = data => ({
  type: t.ASSESSMENTS_SET_ONLINE_TEST_DATA,
  payload: data
});
export const setOnlineTest = data => ({
  type: t.ASSESSMENTS_SET_ONLINE_TEST,
  payload: data
});
export const setOnlineTestSuccess = () => ({
  type: t.ASSESSMENTS_SET_ONLINE_TEST_SUCCESS
});
export const setOnlineTestFailure = () => ({
  type: t.ASSESSMENTS_SET_ONLINE_TEST_FAILURE
});
export const setDuration = durationInMinutes => ({
  type: t.ASSESSMENTS_SET_DURATION,
  payload: durationInMinutes
});
export const showSetDuration = showDurationToggle => ({
  type: t.ASSESSMENTS_SHOW_SET_DURATION,
  payload: showDurationToggle
});
