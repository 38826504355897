import { takeLatest, takeEvery } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getClassReportEnvironment from './getClassReportEnvironment';
import getClassReport from './getClassReport';
import getClassrooms from './getClassrooms';
import getLevelPanel from './getLevelPanel';
import getClassReportDials from './getClassReportDials';
import ModifyORBLevel from './modifyORBLevel';
import { featureIsEnabled } from '../../../../../globals/envSettings';

export default function* root() {
  const dispatchedActionClassReportEnvironment = featureIsEnabled('mat-report-change-for-reducers')
    ? t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_REQUEST_V2
    : t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_REQUEST;
  yield takeLatest(dispatchedActionClassReportEnvironment, ({ payload: { orgId } }) =>
    getClassReportEnvironment(orgId)
  );

  yield takeLatest(t.GET_CLASSROOMS_REQUEST, ({ payload: { orgId } }) => getClassrooms(orgId));

  const gradebookGroupClassReportRequestAction = featureIsEnabled('mat-report-change-for-reducers')
    ? t.GRADEBOOK_GROUP_CLASS_REPORT_REQUEST_V2
    : t.GRADEBOOK_GROUP_CLASS_REPORT_REQUEST;

  if (featureIsEnabled('orb-class-report-date-selector')) {
    yield takeLatest(
      gradebookGroupClassReportRequestAction,
      ({ payload: { orgId, groupId, locationId, academicYear, startDate, endDate } }) =>
        getClassReport(orgId, groupId, locationId, academicYear, startDate, endDate)
    );
  } else {
    yield takeLatest(
      gradebookGroupClassReportRequestAction,
      ({ payload: { orgId, groupId, locationId, academicYear } }) =>
        getClassReport(orgId, groupId, locationId, academicYear)
    );
  }

  if (featureIsEnabled('mat-report-change-for-reducers')) {
    yield takeLatest(
      t.GRADEBOOK_LEVEL_PANEL_REQUEST_V2,
      ({ payload: { orgId, classId, learnerId, levelId, locationId } }) =>
        getLevelPanel(orgId, classId, learnerId, levelId, locationId)
    );
  } else {
    yield takeLatest(
      t.GRADEBOOK_LEVEL_PANEL_REQUEST,
      ({ payload: { orgId, classId, learnerId, levelId, locationId } }) =>
        getLevelPanel(orgId, classId, learnerId, levelId, locationId)
    );
  }

  yield takeEvery(t.GRADEBOOK_CLASS_DIAL_REPORT_REQUEST, ({ payload: { orgId, classId, locationId, academicYear } }) =>
    getClassReportDials(orgId, classId, locationId, academicYear)
  );
  yield takeLatest(
    [
      t.GRADEBOOK_ASSIGN_LEVEL,
      t.GRADEBOOK_LEVEL_UP,
      t.GRADEBOOK_LEVEL_DOWN,
      t.GRADEBOOK_RESET_LEVEL,
      t.GRADEBOOK_DELAY_LEVEL_CHANGE
    ],
    ({ payload: { action, userIds, orgId, level } }) => ModifyORBLevel(userIds, action, orgId, level)
  );
}
