import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import styles from './OrbCourseSelector.scss';

export default class OrbCourseSelector extends React.Component {
  static propTypes = {
    courses: PropTypes.array.isRequired,
    withLabel: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    withLabel: true
  };

  onChangeCourse = e => {
    const subject = e.target.value;
    location.href = `${location.origin}/users/change_subject?subject=${subject}`;
  };

  render() {
    const { courses, withLabel, className } = this.props;
    if (courses.length <= 1) return null;

    const currentCourse = courses.find(course => course.active);

    return (
      <div className={classnames(styles.orbCourseSelector, className)}>
        {withLabel && <label>Course:</label>}
        <select value={currentCourse.value} onChange={this.onChangeCourse}>
          {courses.map((course, index) => (
            <option value={course.value} key={index}>
              {course.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
