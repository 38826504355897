import * as t from '../actionTypes';

// const requestStatusOptions = { loading: true, success: false, error: false };
const successStatusOptions = { loading: false, success: true, error: false };
const failureStatusOptions = { loading: false, success: false, error: true };
const resetStatusOptions = { loading: false, success: undefined, error: undefined };

const initialState = {
  currentPage: 'class',
  startDate: new Date(),
  endDate: new Date(),
  startHour: '09:30',
  endHour: '11:00',
  classId: '',
  selectedStudentIds: [],
  allStudentIds: [],
  initialStudentsSet: false,
  timeSet: false,
  sendEmailToStudents: false,
  testId: '',
  hasErrors: false,
  test: {},
  productAssessmentTitle: '',
  durationTestSet: false,
  durationInMinutes: 45
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.ASSESSMENTS_SET_ONLINE_TEST_ID:
      return {
        ...state,
        testId: payload
      };
    case t.ASSESSMENTS_SET_ONLINE_TEST_DATA:
      return {
        ...state,
        test: payload.test,
        ...(payload.test.testDuration &&
          payload.test.testDuration !== -1 && { durationInMinutes: payload.test.testDuration }),
        durationTestSet: payload.test.testDuration && payload.test.testDuration !== -1,
        productAssessmentTitle: payload.productAssessmentTitle
          ? payload.productAssessmentTitle
          : payload.assessmentCourseTitle
      };
    case t.ASSESSMENTS_TIME_SET:
      return {
        ...state,
        timeSet: true
      };
    case t.ASSESSMENTS_SET_ALL_STUDENTS:
      return {
        ...state,
        allStudentIds: payload
      };
    case t.ASSESSMENTS_SET_STUDENTS:
      return {
        ...state,
        selectedStudentIds: payload
      };
    case t.ASSESSMENTS_SET_INITIAL_STUDENTS:
      return {
        ...state,
        initialStudentsSet: true
      };
    case t.ASSESSMENTS_SWITCH_TO_PAGE:
      if (payload.classId) {
        return {
          ...state,
          currentPage: payload.page,
          classId: payload.classId
        };
      }
      if (payload.testId) {
        return {
          ...state,
          currentPage: payload.page,
          testId: payload.testId
        };
      }
      return {
        ...state,
        currentPage: payload.page
      };
    case t.ASSESSMENTS_SET_HAS_ERRORS:
      return {
        ...state,
        hasErrors: payload
      };
    case t.ASSESSMENTS_SET_START_DATE:
      return {
        ...state,
        startDate: payload
      };
    case t.ASSESSMENTS_SET_END_DATE:
      return {
        ...state,
        endDate: payload
      };
    case t.ASSESSMENTS_SET_START_HOUR:
      return {
        ...state,
        startHour: payload
      };
    case t.ASSESSMENTS_SET_END_HOUR:
      return {
        ...state,
        endHour: payload
      };
    case t.SET_SEND_EMAIL_PREFERENCE:
      return {
        ...state,
        sendEmailToStudents: payload.sendEmailToStudents
      };
    case t.ASSESSMENTS_STUDENT_FORM_RESETED:
      return {
        ...state,
        initialStudentsSet: false
      };
    case t.ASSESSMENTS_RESET_FROM_CLASS:
      return {
        ...initialState,
        classId: payload?.classId || state.classId,
        currentPage: state.currentPage,
        testId: state.testId
      };
    case t.ASSESSMENTS_RESET_PANEL:
      return {
        ...initialState,
        test: state.test,
        productAssessmentTitle: state.productAssessmentTitle
      };
    case t.ASSESSMENTS_RESET_STATUS_OPTIONS:
      return {
        ...state,
        ...resetStatusOptions
      };
    case t.ASSESSMENTS_SET_ONLINE_TEST_SUCCESS:
      return {
        ...state,
        ...successStatusOptions
      };
    case t.ASSESSMENTS_SET_ONLINE_TEST_FAILURE:
      return {
        ...state,
        ...failureStatusOptions
      };
    case t.ASSESSMENTS_SET_DURATION:
      return {
        ...state,
        durationInMinutes: payload
      };
    case t.ASSESSMENTS_SHOW_SET_DURATION:
      return {
        ...state,
        durationTestSet: payload
      };
    default:
      return state;
  }
};
