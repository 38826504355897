const moment = require('moment');

const LICENCE_CONSTANTS = {
  LICENCE_TYPE: {
    ROLLING: 'ROLLING',
    CONCURRENT: 'CONCURRENT',
    USAGE: 'USAGE'
  },
  BEGIN_ON: {
    FIRST_USE: 'FIRST_USE',
    CREATION: 'CREATION'
  }
};

const momentTimeUnit = {
  YEAR: 'years',
  MONTH: 'months',
  WEEK: 'weeks',
  DAY: 'days',
  HOUR: 'hours',
  MINUTE: 'minutes'
};

// Using Max Decimal Number to represent Indefinite Licences
const INFINITE_LICENCE_IN_MINUTES = 4294967296;

function getTimeInMinutes(codeDetails) {
  // For Indefinite Expiry Licence Returning the Max Decimal Number
  if (!codeDetails.timeUnit && !codeDetails.timePeriod && !codeDetails.licenceEndDate) {
    return INFINITE_LICENCE_IN_MINUTES;
  }

  let timePeriodInMins;

  if (codeDetails.timePeriod && codeDetails.timeUnit) {
    let startFrom = moment(codeDetails.licenceStartDate);

    if (codeDetails.beginOn === LICENCE_CONSTANTS.BEGIN_ON.CREATION && !codeDetails.licenceStartDate) {
      startFrom = moment(codeDetails.createdDate);
    }
    timePeriodInMins = startFrom
      .add(codeDetails.timePeriod, momentTimeUnit[codeDetails.timeUnit])
      .diff(moment(), 'minutes');
  }

  let timeInMinutes = timePeriodInMins;
  if (codeDetails.licenceEndDate) {
    const dateDiffInMins = moment(codeDetails.licenceEndDate).diff(moment(), 'minutes');
    timeInMinutes = timePeriodInMins && dateDiffInMins > timePeriodInMins ? timePeriodInMins : dateDiffInMins;
  }

  return timeInMinutes;
}

const sortLicence = (usableCodes, activationCodeDetails) => {
  const newCodes = [
    ...usableCodes.sort((a, b) => {
      let aDetails = {};
      let bDetails = {};

      if (activationCodeDetails) {
        aDetails = {
          licenceType: activationCodeDetails[a].licenceType,
          beginOn: activationCodeDetails[a].beginOn,
          timePeriod: activationCodeDetails[a].timePeriod,
          timeUnit: activationCodeDetails[a].timeUnit,
          createdDate: activationCodeDetails[a].createdDate,
          licenceStartDate: activationCodeDetails[a].codeStartDate,
          licenceEndDate: activationCodeDetails[a].codeEndDate
        };

        bDetails = {
          licenceType: activationCodeDetails[b].licenceType,
          beginOn: activationCodeDetails[b].beginOn,
          timePeriod: activationCodeDetails[b].timePeriod,
          timeUnit: activationCodeDetails[b].timeUnit,
          createdDate: activationCodeDetails[b].createdDate,
          licenceStartDate: activationCodeDetails[b].codeStartDate,
          licenceEndDate: activationCodeDetails[b].codeEndDate
        };
      } else {
        aDetails = {
          licenceType: a.licenceType,
          beginOn: a.beginOn,
          timePeriod: a.timePeriod,
          timeUnit: a.unitType,
          createdDate: a.createdDate,
          licenceStartDate: a.licenceStartDate,
          licenceEndDate: a.licenceEndDate
        };

        bDetails = {
          licenceType: b.licenceType,
          beginOn: b.beginOn,
          timePeriod: b.timePeriod,
          timeUnit: b.unitType,
          createdDate: b.createdDate,
          licenceStartDate: b.licenceStartDate,
          licenceEndDate: b.licenceEndDate
        };
      }

      let aTime = getTimeInMinutes(aDetails);
      let bTime = getTimeInMinutes(bDetails);

      if (aDetails.timePeriod === bDetails.timePeriod && aDetails.timeUnit === bDetails.timeUnit) {
        // For Licence with no end date returning the Max Decimal Number
        if (!aDetails.licenceEndDate) {
          aTime = INFINITE_LICENCE_IN_MINUTES;
        }
        if (!bDetails.licenceEndDate) {
          bTime = INFINITE_LICENCE_IN_MINUTES;
        }

        // For Licence with no start date adding a minute
        if (!aDetails.licenceStartDate && !moment(aDetails.licenceStartDate).isAfter(moment())) {
          aTime += 1;
        }
        if (!bDetails.licenceStartDate && !moment(bDetails.licenceStartDate).isAfter(moment())) {
          bTime += 1;
        }
      }

      return bTime - aTime;
    })
  ];

  return newCodes;
};

const isLicenceExpiresEarly = (licenceData, showSubMessageCheck = false) => {
  if (licenceData.timePeriod && licenceData.unitType && licenceData.licenceEndDate) {
    if (showSubMessageCheck) {
      return (
        moment(licenceData.licenceEndDate).diff(moment()) !==
          moment()
            .add(parseInt(licenceData.timePeriod, 10), momentTimeUnit[licenceData.unitType])
            .diff(moment(), 'minutes') &&
        licenceData.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.ROLLING &&
        licenceData.beginOn === LICENCE_CONSTANTS.BEGIN_ON.FIRST_USE
      );
    }
    return (
      moment(licenceData.licenceEndDate).diff(moment(), 'minutes') <
      moment()
        .add(parseInt(licenceData.timePeriod, 10), momentTimeUnit[licenceData.unitType])
        .diff(moment(), 'minutes')
    );
  }
  return false;
};

module.exports = {
  momentTimeUnit,
  LICENCE_CONSTANTS,
  sortLicence,
  isLicenceExpiresEarly
};
