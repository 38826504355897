import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

function* oicGetTestCredits(input) {
  const { orgId, productId } = input;
  const response = yield signedFetch('oicGetTestCredits', `${__API_BASE__}/org/${orgId}/oic/test-credits/${productId}`);

  if (response.status === 'success') {
    if (response.data) {
      const { credits } = response.data;
      return yield put(actions.getOicTestCreditsSuccess({ orgId, productId, testCredits: credits }));
    }
    return yield put(actions.getOicTestCreditsEmptySuccess({ productId }));
  }
  return yield put(actions.getOicTestCreditsFailure({ productId, errors: response.message }));
}

export default oicGetTestCredits;
