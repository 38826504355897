import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import getAllTeacherResources from '../../../api/getAllTeacherResources';

function* getTeacherResources(titleIds) {
  const response = yield getAllTeacherResources(titleIds);
  if (response.status === 'SUCCESS' || response.status === 'PARTIAL_SUCCESS') {
    yield put(actions.teacherResourcesSuccess(response.data));
    return;
  }
  yield put(actions.teacherResourcesFailure(response.data));
}

export default getTeacherResources;
