import { orderBy, isNil, pull } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from '../../../components/Link/Link';
import Checkbox from '../../../components/Checkbox/Checkbox';
import LevelDetails from '../../../components/LevelDetails/LevelDetails';
import DataRefresherButton from '../../../components/DataRefresher/DataRefresherButton';
import LevelProgressBar, { LevelProgressTitles } from '../../../components/LevelProgressBar/LevelProgressBar';
import SearchOptions from '../../../components/SearchOptions/SearchOptions';
import StudentDetails from '../../../components/StudentDetails/StudentDetails';
import TableSticky from '../../../components/TableSticky/TableSticky';
import styles from './ClassReportTable.scss';
import withGetYearGroupOptions from '../../../language/withGetYearGroupOptions';
import { getOrbStudentReportLink } from '../../../utils/orb';

class ClassReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revealRow: [],
      barFocus: null,
      activeItem: null,
      expectedFocus: null,
      checkboxes: [],
      results: props.results ? props.results : { levels: {}, students: [] }
    };
  }

  /**
   * Click handler for the title boxes.
   */
  _handleTitleClick = levelId => () => {
    this.setState({
      expectedFocus: levelId
    });
  };

  _createHandleCheckboxChange = students => {
    const ids = students.map(({ id }) => id);

    return async checked => {
      await this.setState(prevState => {
        // "Select all" was checked/unchecked
        if (prevState.checkboxes.length < students.length) {
          return {
            checkboxes: checked ? [...ids] : []
          };
        }

        // Default (a single student was selected)
        return {
          checkboxes: checked ? [...prevState.checkboxes, ...ids] : pull(prevState.checkboxes, ...ids)
        };
      });
      const { onStudentSelect } = this.props;
      const { checkboxes } = this.state;
      onStudentSelect(checkboxes);
    };
  };

  /**
   * Click handler for the main level boxes.
   *  barId, levelId, progressLevel
   */
  _handleRevealClick = student => (barId, levelId) => () => {
    const { getLevelPanel } = this.props;
    this.setState(({ revealRow, barFocus, activeItem }) => {
      const rowId = `row-${student.id}`;
      const close = rowId === barFocus && levelId === activeItem;

      if (!close) {
        getLevelPanel(student.id, levelId);
      }

      return {
        revealRow: revealRow.includes(rowId) && close ? [] : [rowId], // toogle row
        barFocus: close ? null : rowId,
        activeItem: close ? null : levelId
      };
    });
  };

  _handleSortClick = sortType => {
    const { sortType: sortTypeFromState, results: resultsFromState } = this.state;
    const order = sortTypeFromState !== sortType ? 'asc' : 'dec';
    const results = resultsFromState;
    const sortColumns = {
      name: [i => i.name.toLowerCase()],
      surname: [i => i.name.split(' ')[1].toLowerCase()],
      attainment: ['levels[0].levelId', 'levels[0].status'],
      progress: ['levels[0].levelId'],
      yearGroup: ['yearGroup']
    };

    if (!sortColumns[sortType].length) return;

    this.setState({
      sortType,
      results: {
        ...results,
        students: orderBy(results.students, sortColumns[sortType], order)
      }
    });
  };

  _handleActiveItemChange = activeItem => {
    this.setState({ activeItem });
  };

  render() {
    const {
      curriculumType,
      orgId,
      classroom,
      getYearGroupOptions,
      level = null,
      levelLoading = true,
      onNavigateToOrg,
      getOptionNameByValue,
      onTriggerRefresh,
      showClassReportRefreshBtn,
      content,
      errors,
      getLevelPanel,
      onLevelUpConfirmation,
      onLevelDownConfirmation,
      onLevelChangeDelay,
      onLevelReset
    } = this.props;

    const {
      revealRow,
      barFocus,
      activeItem,
      expectedFocus,
      checkboxes,
      sortType,
      results: { levels = {}, students = [] }
    } = this.state;

    if (!levels || !students) {
      return null;
    }

    const focusedExpectedLevel = expectedFocus || (orderBy(levels.active || [], 'levelId', 'asc')[0] || {}).levelId;

    let columnLevels = levels;

    if (students.every(({ yearGroup }) => isNil(yearGroup))) {
      columnLevels = { ...columnLevels, active: [] };
    }

    const columns = [
      { heading: 'Students' },
      {
        heading: (
          <LevelProgressTitles
            levels={columnLevels}
            expectedFocus={focusedExpectedLevel}
            onLevelClick={this._handleTitleClick}
          />
        )
      }
    ];

    const rows = students.map((student, $0) => {
      const rowId = `row-${student.id}`;
      const { expectedLevelId } = student;
      const studentLevelsConfig = {
        ...levels,
        active: expectedLevelId == null ? [] : [{ levelId: expectedLevelId }]
      };

      return {
        id: rowId,
        hoverableClass: false,
        revealableContent:
          barFocus === rowId ? (
            <LevelDetails
              loading={levelLoading}
              student={student}
              level={level ? level.userLevelsData : level}
              changeActiveItem={e => this._handleActiveItemChange(e)}
              getLevelPanelFunc={(learnerId, levelId) => getLevelPanel(learnerId, levelId)}
              onLevelUpConfirmation={onLevelUpConfirmation}
              onLevelDownConfirmation={onLevelDownConfirmation}
              onLevelChangeDelay={onLevelChangeDelay}
              onLevelReset={onLevelReset}
              errors={errors}
            />
          ) : null,
        cells: [
          <div key={$0} className={styles.nameColumn}>
            <Checkbox
              id={`checkbox${$0}`}
              value={checkboxes.includes(student.id)}
              label={student.name}
              labelHidden
              onChange={this._createHandleCheckboxChange([student])}
            />
            <StudentDetails
              className="gin-bot2 gin-top2"
              name={student.name}
              nameLink={getOrbStudentReportLink(orgId, classroom, student.id, true)}
              year={getOptionNameByValue(getYearGroupOptions(curriculumType), student.yearGroup)}
              error={
                typeof student.yearGroup === 'undefined' ? (
                  <span className={styles.errorMessage}>
                    Please assign a{' '}
                    <Link role="button" onClick={onNavigateToOrg}>
                      year group
                    </Link>
                  </span>
                ) : null
              }
              notesLink={student.notes ? '/dev/components/new/StudentDetails' : null}
            />
          </div>,
          <LevelProgressBar
            key={$0}
            barId={$0}
            levels={studentLevelsConfig}
            progress={student.levels}
            onLevelClick={this._handleRevealClick(student)}
            activeItem={barFocus === rowId ? activeItem : null}
            expectedFocus={focusedExpectedLevel}
            barFocus={barFocus === rowId}
          />
        ]
      };
    });

    const toolbarRow = {
      id: 'row-toolbar',
      colSpan: 2,
      cells: [
        <div className={styles.toolbarRow} key="0">
          <div className={styles.optionsColumn}>
            <SearchOptions
              buttonId="searchOptions"
              buttonText="Sort"
              inputType="radio"
              a11yHeading="Sort by"
              overlayHangRight="true"
              options={[
                {
                  text: 'First name',
                  id: 'name',
                  name: 'name',
                  value: 'name',
                  checked: sortType === 'name',
                  onClick: () => this._handleSortClick('name')
                },
                {
                  text: 'Surname',
                  id: 'surname',
                  name: 'surname',
                  value: 'surname',
                  checked: sortType === 'surname',
                  onClick: () => this._handleSortClick('surname')
                },
                {
                  text: 'Attainment',
                  id: 'attainment',
                  name: 'attainment',
                  value: 'attainment',
                  checked: sortType === 'attainment',
                  onClick: () => this._handleSortClick('attainment')
                },
                {
                  text: 'Progress',
                  id: 'progress',
                  name: 'progress',
                  value: 'progress',
                  checked: sortType === 'progress',
                  onClick: () => this._handleSortClick('progress')
                },
                {
                  text: 'Year group',
                  id: 'yearGroup',
                  name: 'yearGroup',
                  value: 'yearGroup',
                  checked: sortType === 'yearGroup',
                  onClick: () => this._handleSortClick('yearGroup')
                }
              ]}
            />
          </div>
          <div className={styles.selectAllColumn}>
            <Checkbox
              id="checkbox-all"
              value={checkboxes.length === students.length}
              label="Select all"
              labelHidden
              onChange={this._createHandleCheckboxChange(students)}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="checkbox-all">Select All</label>
          </div>
        </div>
      ]
    };

    return (
      <div className={showClassReportRefreshBtn ? 'gin-top2' : 'gin-top8'}>
        {showClassReportRefreshBtn && (
          <div className={styles.classReportTableRefreshBar}>
            <div className={styles.labelRow}>
              <span className={styles.labelChange}>{content.data_refresher_made_change} </span>
              {content.data_refresher_refresh_again}
            </div>
            <div className={styles.refreshBar}>
              <DataRefresherButton onClick={onTriggerRefresh} label={content.data_refresher_refresh_data} />
            </div>
          </div>
        )}
        <TableSticky
          columns={columns}
          rows={rows}
          toolbarRow={toolbarRow}
          customClass={styles.classReportTable}
          secondTabLabel="Oxford Reading Level:"
          revealControl={revealRow}
        />
      </div>
    );
  }
}

ClassReportTable.propTypes = {
  curriculumType: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  classroom: PropTypes.string.isRequired,
  results: PropTypes.object.isRequired,
  getLevelPanel: PropTypes.func.isRequired,
  getYearGroupOptions: PropTypes.func.isRequired,
  getOptionNameByValue: PropTypes.func.isRequired,
  levelLoading: PropTypes.bool,
  onStudentSelect: PropTypes.func.isRequired,
  level: PropTypes.object,
  onNavigateToOrg: PropTypes.func,
  onLevelUpConfirmation: PropTypes.func.isRequired,
  onLevelDownConfirmation: PropTypes.func.isRequired,
  onLevelChangeDelay: PropTypes.func.isRequired,
  onLevelReset: PropTypes.func.isRequired,
  onTriggerRefresh: PropTypes.func.isRequired,
  showClassReportRefreshBtn: PropTypes.bool.isRequired,
  content: PropTypes.object,
  errors: PropTypes.string
};

export default withGetYearGroupOptions(ClassReportTable);
