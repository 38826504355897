import React from 'react';
import PropTypes from 'prop-types';

import styles from './SampleOrgUniqueId.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import withLocalizedContent from '../../language/withLocalizedContent';

function SampleOrgUniqueId({
  localizedContent: { sampleOrgUniqueId: localizedContent },
  orgUniqueId = 'g5r16',
  username = 'kasia.wajda',
  password = 'fish5'
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.ribbon}>
        <div className={styles.sample}>
          <span>{localizedContent.ribbon_text}</span>
        </div>
      </div>
      <SVGIcon glyph={GLYPHS.ORB_LOGO} />
      <ul>
        <li className={styles.detail}>
          <span className={styles.label}>{localizedContent.school_code_label}: </span>
          <span className={styles.value}>{orgUniqueId}</span>
        </li>
        <li className={styles.detail}>
          <span className={styles.label}>{localizedContent.username_label}: </span>
          <span className={styles.value}>{username}</span>
        </li>
        <li className={styles.detail}>
          <span className={styles.label}>{localizedContent.password_label}: </span>
          <span className={styles.value}>{password}</span>
        </li>
      </ul>
    </div>
  );
}

SampleOrgUniqueId.propTypes = {
  localizedContent: PropTypes.object,
  orgUniqueId: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string
};

export default withLocalizedContent('sampleOrgUniqueId')(SampleOrgUniqueId);
