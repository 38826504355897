import React from 'react';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import cmsContent from '../../utils/cmsContent.js';

function RegisterFormFetching() {
  const CMS = cmsContent.registrationPage || {};
  return (
    <PopoutPanelIconHeading type={types.LOADING} title={CMS.loading_title_text} subtitle={CMS.loading_subtitle_text} />
  );
}
export default RegisterFormFetching;
