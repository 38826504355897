// TODO: fix dependency cycle
// eslint-disable-next-line import/no-cycle
import processAuth from './processAuth.saga';
import getIdentity from './getIdentity.saga';
import session from '../../../../../utils/session';

/**
 * Process authentication ( including embedded frames )
 *
 * NOTE: authSettings was already loaded in preRender loadEnvironment before initialiseAuth call
 */
export default function* initialiseAuth() {
  console.log('[initialiseAuth] Started, processing...');
  const authResult = yield processAuth();

  if (session.exists()) {
    console.log('[initialiseAuth] Credentials loaded , getting identity...');
    yield getIdentity(authResult);
  }

  console.log('[initialiseAuth] Complete');
}
