export const INITIALISE = 'report/INITIALISE';
export const GENERATE_REPORT_REQUEST = 'report/GENERATE_REPORT_REQUEST';
export const GENERATE_REPORT_SUCCESS = 'report/GENERATE_REPORT_SUCCESS';
export const GENERATE_REPORT_FAILURE = 'report/GENERATE_REPORT_FAILURE';

export const initialState = {
  inProgress: true,
  loading: false,
  errorOccurred: false,
  errorMessage: null,
  success: false,
  generateReportResult: null
};

export default function generateReport(state = initialState, action = {}) {
  switch (action.type) {
    // Initialise action
    case INITIALISE:
      return {
        ...initialState
      };
    case GENERATE_REPORT_REQUEST:
      return {
        ...state,
        inProgress: false,
        loading: true,
        errorOccurred: false,
        success: false,
        formData: action.formData,
        reportType: action.reportType
      };
    case GENERATE_REPORT_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: false,
        errorOccurred: true,
        inProgress: false,
        success: false
      };
    case GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        success: true,
        generateReportResult: action.generateReportResult,
        loading: false,
        errorOccurred: false,
        inProgress: false
      };
    default:
      return state;
  }
}

export const startGenerateReportRequest = reportType => ({
  type: GENERATE_REPORT_REQUEST,
  reportType
});

export const showSuccessMessage = generateReportResult => ({
  type: GENERATE_REPORT_SUCCESS,
  generateReportResult
});

export const showGenerateReportError = errorMessage => ({
  type: GENERATE_REPORT_FAILURE,
  errorMessage
});

export const reset = () => ({
  type: INITIALISE
});
