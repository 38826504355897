import { put } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { redeemCodeRequestSuccess, redeemCodeRequestFailure } from '../../../../actions/activationCode';

export default function* redeemCodeGuest(data) {
  const response = yield signedFetch('redeemCodeGuest', `${__API_BASE__}/open/guest/redeem-code`, 'POST', {
    emailId: data.payload.guestEmailId,
    activationCode: data.payload.redeemCodeValue
  });

  if (response.status === 'success') {
    return yield put(redeemCodeRequestSuccess(response));
  }

  return yield put(redeemCodeRequestFailure({ errors: response.error }));
}
