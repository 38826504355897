import * as t from '../actionTypes';

export const loadPlacementTestResultsRequest = payload => ({
  type: t.DOWNLOAD_PLACEMENT_TEST_RESULTS,
  payload
});

export const downloadPlacementTestResultsSuccess = payload => ({
  type: t.DOWNLOAD_PLACEMENT_TEST_RESULTS_SUCCESS,
  payload
});

export const downloadPlacementTestResultsFailure = payload => ({
  type: t.DOWNLOAD_PLACEMENT_TEST_RESULTS_FAILURE,
  payload
});
