import { parse as parseQueryString } from 'query-string';
import envSettings, { isOIDCStandard, isLocal } from '../globals/envSettings';
import authSettings from '../globals/authSettings.js';

/**
 * Ensure that a URL is correctly prefixed with a protocol.
 *
 * @param {string} url
 *
 * @return {string}
 */
export function sanitizeUrl(url, protocol) {
  if (!url || typeof url !== 'string') {
    return '';
  }

  // if protocol is empty, null or undefined force to "https://"
  let urlProtocol = protocol || (isLocal() ? 'http://' : 'https://');

  if (!urlProtocol.endsWith('://')) {
    urlProtocol += '://';
  }

  const hasAcceptableProtocol = url.startsWith(urlProtocol);
  return hasAcceptableProtocol ? url : `${urlProtocol}${url.replace(/^.*?\/\//, '')}`;
}

/**
 * Return the absolute URL path to a local resource.
 *
 * @param {string}   url
 * @param {Location} location
 *
 * @return {string}
 */
export function getFullUrl(url, location) {
  if (!url || typeof url !== 'string') {
    return '';
  }
  let inputURL = url;
  if (isOIDCStandard(authSettings.idpStandard)) {
    const cesGatewayPath = envSettings.idp[envSettings.sso.DefaultIdpName].gatewayPath;
    inputURL = inputURL.replace(`/${cesGatewayPath}/`, `/${authSettings.gatewayPath}/`);
  }
  return inputURL.replace(/^\//, `${location.protocol}//${location.hostname}/`);
}

/**
 * Reduce a set of query parameters from an object to a formatted query string.
 *
 * @param {Object} params
 *
 * @return {string}
 */
export function toQueryString(params) {
  // eslint-disable-next-line prefer-template
  return `?${Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`;
}
/**
 * Reduce a set of query parameters from an object to a formatted query string,
 * but only if those params have a value
 *
 * @param {Object} params
 *
 * @return {string}
 */
export function toQueryStringWithMandatoryParams(params) {
  let url = '';
  const keys = Object.keys(params);
  for (let i = 0; i < keys.length; i += 1) {
    if (params[keys[i]] && url) {
      url += `&${keys[i]}=${params[keys[i]]}`;
    } else if (params[keys[i]] && !url) {
      url += `${keys[i]}=${params[keys[i]]}`;
    }
  }
  return url;
}

export function isUrlFromTrustedDomain(url) {
  const regex = /^https:\/\/(?:www\.|(?!www))((?!\.)(?!.*\.$)(?!.*?\.\.)[\w][\w-_~.]+\.[a-zA-Z]{2,})/;

  const baseUrlArray = Object.keys(envSettings.integrations).map(key => {
    const baseUrl = envSettings.integrations[key].baseUrl || '';
    const matchGroup = baseUrl.toString().match(regex);
    return matchGroup ? matchGroup[1] : null;
  });

  const dashboardUrlArray = Object.keys(envSettings.integrations).map(key => {
    const dashboardUrl = envSettings.integrations[key].dashboardUrl || '';
    const matchGroup = dashboardUrl.toString().match(regex);
    return matchGroup ? matchGroup[1] : null;
  });

  const whitelistDomains = [...baseUrlArray, ...dashboardUrlArray].filter(whitelist => whitelist);
  const urlDomain = isLocal() ? new URL(url).hostname : url && url.match(regex) && url.match(regex)[1];

  return urlDomain ? !!whitelistDomains.find(element => element.includes(urlDomain[1])) : false;
}

function extractHostname(url) {
  let hostname;
  // find & remove protocol (http, https, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove port number
  hostname = hostname.split(':')[0];
  // find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}

export function extractRootDomain(url) {
  let domain = extractHostname(url);
  const splitArr = domain.split('.');
  const arrLen = splitArr.length;

  // extracting the root domain here
  // if there is a subdomain
  if (arrLen > 2) {
    domain = `${splitArr[arrLen - 2]}.${splitArr[arrLen - 1]}`;
    // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length === 2 && splitArr[arrLen - 1].length === 2) {
      // this is using a ccTLD
      domain = `${splitArr[arrLen - 3]}.${domain}`;
    }
  }
  return domain;
}

export function prepareTargetUrl() {
  const { target_url: targetUrl = '', protocol = '' } = parseQueryString(window.location.search);

  return sanitizeUrl(decodeURIComponent(targetUrl), decodeURIComponent(protocol));
}

export function getProviderId() {
  const { providerId = '' } = parseQueryString(window.location.search);

  return decodeURIComponent(providerId);
}

export function getLinkSocialAccount() {
  const { linkSocialAccount = '' } = parseQueryString(window.location.search);

  return decodeURIComponent(linkSocialAccount) === '1';
}

/**
 * Filter query string based on array of parameter names
 *
 * @param {string} query
 * @param {array}  filter
 *
 * @return {string}
 */
export function queryFilter(query, filter) {
  //
  // e.g. queryFilter('?foo=1&bar=2&baz=3', [ 'foo', 'baz'])
  // returns foo=1&baz=3'
  //
  if (query) {
    let newParams = '';
    const params = new URLSearchParams(query);
    filter.forEach(param => {
      const paramValue = params.get(param);
      if (paramValue) {
        if (newParams) {
          newParams = `${newParams}&${param}=${paramValue}`;
        } else {
          newParams = `${param}=${paramValue}`;
        }
      }
    });
    return newParams;
  }
  return '';
}

export function redirectToAm() {
  const urlParams = window.location.search;
  return (urlParams && (urlParams.match(/redirectToAM=([1]{1})/) || [])[1]) === '1';
}
