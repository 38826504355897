import { put, select } from 'redux-saga/effects';

import { parse as parseQueryString } from 'query-string';
import appSettings from '../../../../../globals/appSettings.js';

import { setFormResult } from '../../../../reducers/data/forms.reducer.js';
import requestPasswordResetApi from '../../../apiCalls/requestPasswordResetApi.js';
import { getCurrentPlatform } from '../../../../../utils/platform.js';
import { getFieldValue, requestFailed } from '../formUtil.js';

export default function* submitRequestPasswordReset() {
  const queryParams = {};
  const queryString = parseQueryString(window.location.search) || {};
  const redirectToTargetUrl = queryString.target_url || '';
  let providerId = null;

  if (redirectToTargetUrl) {
    queryParams.target_url = redirectToTargetUrl;
    providerId = (queryString.providerId || '').toUpperCase();

    const protocol = queryString.protocol || '';

    if (protocol) {
      queryParams.protocol = protocol;
    }
  }

  const payload = yield select(state => ({
    email: getFieldValue(state, 'requestPasswordReset', 'userName'),
    platform:
      providerId || getFieldValue(state, 'requestPasswordReset', 'platform') || getCurrentPlatform().toUpperCase(),
    queryParams
  }));

  if (!appSettings.EMAIL_REGEX.test(payload.email)) {
    yield put(setFormResult('requestPasswordReset', 'LEGACY_MESSAGE'));
  } else {
    const apiResult = yield requestPasswordResetApi(payload);
    const failed = requestFailed(apiResult);
    yield put(setFormResult('requestPasswordReset', 'CONFIRMATION', failed));
  }
}
