import React from 'react';
import PropTypes from 'prop-types';
import Card from '../Card/Card';
import colors from '../../globals/colors';
import styles from './GradedReadersCard.scss';

function GradebookCard({
  studentNameElement,
  lastAccessed,
  booksFinished,
  currentLevel,
  mostReadLevel,
  readingTime,
  title,
  wordsRead,
  classTitle
}) {
  return (
    <div className={styles.GradebookCard}>
      <Card headingText={classTitle} headingColor={colors.CLASSROOM}>
        <div className={styles.GradebookCard__title}>{studentNameElement}</div>
        <table className={styles.GradebookCard__table}>
          <thead>
            <tr>
              <td colSpan={2}>
                Last Accessed <b>{lastAccessed}</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td />
              <td colSpan={2}>
                <div className={styles.GradebookCard__totalLabel}>Current title</div>
                <div className={styles.GradebookCard__total}>{title}</div>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <div className={styles.GradebookCard__totalLabel}>Current level</div>
                <div className={styles.GradebookCard__total}>{currentLevel}</div>
              </td>
              <td>
                <div className={styles.GradebookCard__totalLabel}>Most read level</div>
                <div className={styles.GradebookCard__total}>{mostReadLevel}</div>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <div className={styles.GradebookCard__totalLabel}>Books finished</div>
                <div className={styles.GradebookCard__total}>{booksFinished}</div>
              </td>
              <td>
                <div className={styles.GradebookCard__totalLabel}>Words read</div>
                <div className={styles.GradebookCard__total}>{wordsRead}</div>
              </td>
            </tr>
            <tr>
              <td />
              <td colSpan={2}>
                <div className={styles.GradebookCard__totalLabel}>Reading time</div>
                <div className={styles.GradebookCard__total}>{readingTime}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </div>
  );
}

GradebookCard.propTypes = {
  studentNameElement: PropTypes.node,
  lastAccessed: PropTypes.string,
  booksFinished: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentLevel: PropTypes.string,
  mostReadLevel: PropTypes.string,
  readingTime: PropTypes.string,
  title: PropTypes.any,
  wordsRead: PropTypes.any,
  classTitle: PropTypes.string.isRequired
};

export default GradebookCard;
