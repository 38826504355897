import Ajv from 'ajv';
import jsonSchema4 from 'ajv/lib/refs/json-schema-draft-04.json';
import dot from 'dot-object';
import { intersection, trim } from 'lodash';
import { put } from 'redux-saga/effects';

let ajv;
if (!ajv) {
  ajv = new Ajv({
    schemaId: 'auto',
    meta: false,
    extendRefs: true,
    unknownFormats: 'ignore',
    allErrors: true
  });

  ajv.addMetaSchema(jsonSchema4);
  ajv._opts.defaultMeta = jsonSchema4.id;
  ajv._refs['http://json-schema.org/schema'] = 'http://json-schema.org/draft-04/schema';

  ajv.removeKeyword('propertyNames');
  ajv.removeKeyword('contains');
  ajv.removeKeyword('const');

  ajv.addKeyword('notPattern', {
    compile(_schema) {
      return function checkNotPattern(data) {
        const regex = new RegExp(_schema);
        return !regex.test(data);
      };
    }
  });
}

const getErroredInputs = input => {
  const scope = Object.keys(dot.dot(input));
  const defaults = scope.reduce(
    (defaulted, path) => ({
      ...defaulted,
      [path]: false
    }),
    {}
  );
  const delimiter = '.';
  const slash = '/';
  if (ajv.errors === null) {
    return dot.object(defaults);
  }
  return dot.object(
    ajv.errors.reduce((errors, { keyword, dataPath, schemaPath }) => {
      let path = trim(dataPath, delimiter);
      if (dataPath.startsWith('[')) {
        const [index, ...segments] = path.split(delimiter);
        path = [trim(index, '[]'), ...segments].join(delimiter);
      }
      if (!scope.includes(path)) {
        return errors;
      }
      if (['enum'].includes(keyword)) {
        const expandable = schemaPath.split(slash).some(segment => ['oneOf', 'anyOf'].includes(segment));
        if (expandable) {
          const error = ajv.errors.reduce((previous, { schemaPath: $schemaPath }) => {
            const matches = intersection(schemaPath.split(slash), $schemaPath.split(slash));
            if (matches.includes(path) && $schemaPath !== schemaPath && matches.length > previous.length) {
              return matches;
            }
            return previous;
          }, []);
          if (!error.includes(path)) {
            return errors;
          }
        }
      }
      return {
        ...errors,
        [path]: true
      };
    }, defaults)
  );
};

function* validateInputUsingSchema(schema, type, input) {
  ajv.validate(schema, input);
  const errors = Array.isArray(input) ? Object.values(getErroredInputs({ ...input })) : getErroredInputs(input);
  if (type) {
    yield put({
      type,
      payload: errors
    });
  }
  return errors;
}

export default validateInputUsingSchema;
