import { pick } from 'lodash';

export const CHECK_SELECTED_USERS = 'userSignInCard/CHECK_SELECTED_USERS';
export const SET_STUDENT_LIST = 'userSignInCard/SET_STUDENT_LIST';
export const DOWNLOAD_SIGN_IN_CARD = 'userSignInCard/DOWNLOAD_SIGN_IN_CARD';
const SHOW_SELECTION_REVIEW = 'userSignInCard/SHOW_SELECTION_REVIEW';
const RESET_PASSWORD_SELECTED_USERS = 'userSignInCard/RESET_PASSWORD_SELECTED_USERS';
export const SET_RESULT = 'userSignInCard/SET_RESULT';
const SET_REQUEST_FAILED = 'userSignInCard/SET_REQUEST_FAILED';
const RESET_SIGN_IN_CARD = 'userSignInCard/RESET_SIGN_IN_CARD';
const CLEAR_SIGN_IN_CARD_DATA = 'userSignInCard/CLEAR_SIGN_IN_CARD_DATA';

export const GET_CLASSROOM_STUDENTS = 'userSignInCard/GET_CLASSROOM_STUDENTS';

export const SUBMIT_LEGACY_USERS = 'userSignInCard/SUBMIT_LEGACY_USERS';
export const SUBMIT_LEGACY_USERS_COMPLETED = 'userSignInCard/SUBMIT_LEGACY_USERS_COMPLETED';

export const GENERATE_RANDOM_PASSWORD_REQUESTED = 'userSignInCard/GENERATE_RANDOM_PASSWORD_REQUESTED';
export const GENERATE_RANDOM_PASSWORD_COMPLETED = 'userSignInCard/GENERATE_RANDOM_PASSWORD_COMPLETED';

export const VALIDATE_USER_PASSWORD_REQUESTED = 'userSignInCard/VALIDATE_USER_PASSWORD_REQUESTED';
export const VALIDATE_USER_PASSWORD_COMPLETED = 'userSignInCard/VALIDATE_USER_PASSWORD_COMPLETED';

export const GET_USER_QUEUE_REQUEST = 'userSignInCard/GET_USER_QUEUE_REQUEST';
export const GET_USER_QUEUE_REQUEST_COMPLETED = 'userSignInCard/GET_USER_QUEUE_REQUEST_COMPLETED';
export const GET_USER_QUEUE_REQUEST_FAILED = 'userSignInCard/GET_USER_QUEUE_REQUEST_FAILED';
export const GET_USER_QUEUE_RESPONSE_FAILED = 'userSignInCard/GET_USER_QUEUE_RESPONSE_FAILED';
export const GET_USER_QUEUE_PASSWORD_RESPONSE_FAILED = 'userSignInCard/GET_USER_QUEUE_PASSWORD_RESPONSE_FAILED';
export const SET_ARCHIVE_USERS = 'userSignInCard/SET_ARCHIVE_USERS';

export const formStates = {
  CHECKING_SELECTED_USERS: 'CHECKING_SELECTED_USERS',
  DOWNLOAD_SELECTED_USERS: 'DOWNLOAD_SELECTED_USERS',
  ERROR_RETRIEVING_USERS: 'ERROR_RETRIEVING_USERS',
  ERROR_RETRIEVING_RESPONSE: 'ERROR_RETRIEVING_RESPONSE',
  ERROR_RETRIEVING_PASSWORD_RESPONSE: 'ERROR_RETRIEVING_PASSWORD_RESPONSE',
  RESET_PASSWORD_SELECTED_USERS: 'RESET_PASSWORD_SELECTED_USERS',
  SAVING_LEGACY_USERS: 'SAVING_LEGACY_USERS',
  GET_CLASSROOM_STUDENTS: 'GET_CLASSROOM_STUDENTS'
};

const initialState = {
  formState: formStates.CHECKING_SELECTED_USERS,
  classIds: [],
  userIds: [],
  result: [],
  size: null,
  requestFailed: false,
  successUsers: [],
  failedUsers: [],
  archiveUsers: [],
  failedIds: [],
  errors: {},
  isPasswordGenerated: false,
  isPasswordDisabled: false,
  totalUsers: 0,
  data: false,
  polling: false,
  batchId: null
};

export default function userSignInCard(state = initialState, action) {
  switch (action.type) {
    case SET_STUDENT_LIST:
      return {
        ...state,
        userIds: action.userIds,
        formState: formStates.CHECKING_SELECTED_USERS
      };
    case GET_CLASSROOM_STUDENTS:
      return {
        ...state,
        classIds: action.classIds,
        formState: formStates.GET_CLASSROOM_STUDENTS
      };
    case CLEAR_SIGN_IN_CARD_DATA:
      return {
        ...initialState
      };
    case DOWNLOAD_SIGN_IN_CARD:
      return {
        ...state,
        formState: formStates.DOWNLOAD_SELECTED_USERS
      };
    case SET_RESULT:
      return {
        ...state,
        successUsers: action.result.successUsers,
        failedUsers: action.result.failedUsers,
        result: action.result,
        size: action.size,
        formState: formStates.DOWNLOAD_SELECTED_USERS
      };
    case SET_REQUEST_FAILED:
      return {
        ...state,
        requestFailed: action.requestFailed,
        formState: formStates.ERROR_RETRIEVING_USERS
      };
    case RESET_PASSWORD_SELECTED_USERS:
      return {
        ...state,
        failedIds: action.failedIds,
        totalUsers: action.failedIds.length,
        formState: formStates.RESET_PASSWORD_SELECTED_USERS
      };
    case SET_ARCHIVE_USERS:
      return {
        ...state,
        archiveUsers: action.archiveUsers
      };
    case SHOW_SELECTION_REVIEW:
      return {
        ...state,
        formState: formStates.DOWNLOAD_SELECTED_USERS
      };
    case SUBMIT_LEGACY_USERS:
      return {
        ...state,
        formState: formStates.SAVING_LEGACY_USERS
      };
    case SUBMIT_LEGACY_USERS_COMPLETED:
      return {
        ...state,
        batchId: action.batchId,
        formState: formStates.SAVING_LEGACY_USERS
      };
    case VALIDATE_USER_PASSWORD_REQUESTED:
      return {
        ...state,
        failedIds: state.failedIds.map((faileduser, uidx) => {
          if (action.id !== uidx) return faileduser;
          return { ...faileduser, password: action.input.password };
        }),
        validating: true
      };
    case VALIDATE_USER_PASSWORD_COMPLETED:
      return {
        ...state,
        validating: false,
        errors: {
          ...pick(state.errors, Object.keys(state.failedIds)),
          [action.id]: {
            password: Object.keys(action.errors).length ? action.errors.password : action.errors
          }
        }
      };
    case GENERATE_RANDOM_PASSWORD_REQUESTED:
      return {
        ...state,
        isPasswordDisabled: true,
        isPasswordGenerated: action.isGenerated
      };
    case GENERATE_RANDOM_PASSWORD_COMPLETED:
      return {
        ...state,
        isPasswordDisabled: false,
        failedIds: state.failedIds.map((faileduser, uidx) => ({
          ...faileduser,
          password: state.isPasswordGenerated ? action.passwords[uidx] : ''
        })),
        errors: state.isPasswordGenerated
          ? {
              ...state.failedIds.map(() => ({ password: false }))
            }
          : {}
      };
    case RESET_SIGN_IN_CARD:
      return {
        ...initialState,
        errors: {},
        isPasswordGenerated: false
      };
    case GET_USER_QUEUE_REQUEST:
      return {
        ...state
      };
    case GET_USER_QUEUE_REQUEST_COMPLETED:
      return {
        ...state
      };
    case GET_USER_QUEUE_REQUEST_FAILED:
      return {
        ...state,
        requestFailed: action.requestFailed,
        formState: formStates.ERROR_RETRIEVING_USERS
      };
    case GET_USER_QUEUE_RESPONSE_FAILED:
      return {
        ...state,
        requestFailed: action.requestFailed,
        formState: formStates.ERROR_RETRIEVING_RESPONSE
      };
    case GET_USER_QUEUE_PASSWORD_RESPONSE_FAILED:
      return {
        ...state,
        requestFailed: action.requestFailed,
        formState: formStates.ERROR_RETRIEVING_PASSWORD_RESPONSE
      };
    default:
      return state;
  }
}

export const setStudentsToDownload = userIds => ({
  type: SET_STUDENT_LIST,
  userIds: Array.isArray(userIds) ? userIds : [userIds]
});

export const setClassRoomStudents = classIds => ({
  type: GET_CLASSROOM_STUDENTS,
  classIds: Array.isArray(classIds) ? classIds : [classIds]
});

export const downloadSignInCard = () => ({
  type: DOWNLOAD_SIGN_IN_CARD
});

export const checkSelectedUsers = () => ({
  type: CHECK_SELECTED_USERS
});

export const showSelectionReview = () => ({
  type: SHOW_SELECTION_REVIEW
});

export const resetPasswordSelectedUsers = failedIds => ({
  type: RESET_PASSWORD_SELECTED_USERS,
  failedIds
});

export const setResult = (result, size) => ({
  type: SET_RESULT,
  result,
  size
});

export const setArchiveUsers = archiveUsers => ({
  type: SET_ARCHIVE_USERS,
  archiveUsers
});

export const setRequestFailed = requestFailed => ({
  type: SET_REQUEST_FAILED,
  requestFailed
});

export const resetSignInCard = () => ({
  type: RESET_SIGN_IN_CARD
});

export const clearSignInCardData = () => ({
  type: CLEAR_SIGN_IN_CARD_DATA
});

export const validateUserPasswordRequest = (id, input) => ({
  type: VALIDATE_USER_PASSWORD_REQUESTED,
  id,
  input
});

export const validateUserPasswordCompleted = (id, errors) => ({
  type: VALIDATE_USER_PASSWORD_COMPLETED,
  id,
  errors
});

export const generateRandomPasswordRequest = isGenerated => ({
  type: GENERATE_RANDOM_PASSWORD_REQUESTED,
  isGenerated
});
export const generateRandomPasswordCompleted = passwords => ({
  type: GENERATE_RANDOM_PASSWORD_COMPLETED,
  passwords
});
export const savingLegacyUsers = () => ({
  type: SUBMIT_LEGACY_USERS
});
export const savingLegacyUsersCompleted = batchId => ({
  type: SUBMIT_LEGACY_USERS_COMPLETED,
  batchId
});
export const getUserQueueRequest = () => ({
  type: GET_USER_QUEUE_REQUEST
});
export const getUserQueueRequestCompleted = () => ({
  type: GET_USER_QUEUE_REQUEST_COMPLETED
});
export const getUserQueueRequestFailed = requestFailed => ({
  type: GET_USER_QUEUE_REQUEST_FAILED,
  requestFailed
});
export const getUserQueueResponseFailed = requestFailed => ({
  type: GET_USER_QUEUE_RESPONSE_FAILED,
  requestFailed
});
export const setManagedUserPasswordFailure = requestFailed => ({
  type: GET_USER_QUEUE_PASSWORD_RESPONSE_FAILED,
  requestFailed
});
