import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import styles from './ProductAdminDashboardPage.scss';
import { featureIsEnabled } from '../../../../globals/envSettings';

function ProductAdminDashboardPage({ localizedContent: { productAdminDashboardPage: content } }) {
  const renderCreate = () => {
    if (featureIsEnabled('enable-eps-admin-product-creation')) {
      return (
        <Button
          to="/dashboard_eps/product_dashboard_create"
          type={buttonTypes.SECONDARY}
          text={content.create_label}
          fullWidth
          customClassName={styles.button}
        />
      );
    }

    return <div />;
  };

  const renderSearch = () => {
    if (featureIsEnabled('enable-eps-admin-product-creation')) {
      return (
        <Button
          to="/dashboard_eps/product_dashboard_search"
          type={buttonTypes.SECONDARY}
          text="Search Products"
          fullWidth
          customClassName={styles.button}
        />
      );
    }

    return <div />;
  };

  return (
    <PageWrapper>
      <Helmet title="EPS" />
      {renderCreate()}
      {renderSearch()}
    </PageWrapper>
  );
}

ProductAdminDashboardPage.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('productAdminDashboardPage'))(ProductAdminDashboardPage);
