import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './ReadersTable.scss';
import Table from '../Table/Table';
import tableStyles from '../Table/Table.scss';
import SortHeader from '../SortHeader/SortHeader';

function ReadersTable({
  id = 'readersTable-table',
  caption = '',
  columns,
  rows,
  sortTable = false,
  sortConfig,
  tableSpacing,
  isBreakpointAtTablet
}) {
  const sortableColumns = columns.map((column, colIndex) =>
    sortTable
      ? {
          heading: (
            <SortHeader
              onSwitch={sortTable}
              accessor={column.heading}
              text={column.heading}
              descending={sortConfig && sortConfig.accessor === `cells.${colIndex}` ? sortConfig.descending : null}
            />
          )
        }
      : column
  );

  return (
    <div
      className={classnames(
        isBreakpointAtTablet ? styles.tabletBreakpoint : null,
        styles.readersTable,
        styles[`readersTable__spaced--${tableSpacing}`]
      )}
    >
      <Table
        id={id}
        caption={caption}
        columns={sortableColumns}
        rows={rows}
        customClass={tableStyles.darkBorders}
        isBreakpointAtTablet
        fixedFirstColumnWidth
      />
    </div>
  );
}

ReadersTable.propTypes = {
  id: PropTypes.string,
  /* Table caption text. Recommended. Defaults to cmsContent.searchResults.defaultCaption */
  caption: PropTypes.string,
  /** The headings of the Table */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired
    })
  ).isRequired,
  /** The data of the Table */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      cells: PropTypes.arrayOf(PropTypes.node)
    })
  ).isRequired,
  sortTable: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  sortConfig: PropTypes.object,
  tableSpacing: PropTypes.string,
  isBreakpointAtTablet: PropTypes.bool
};

export default ReadersTable;
