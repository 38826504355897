import { HubLayoutConstants, COURSE_FOLDERS } from '../../../globals/hubConstants.js';
import getResourceLink from './getResourceLink';

const getHomePath = resourceFolderName => {
  if (resourceFolderName === COURSE_FOLDERS.GET_STARTED) return HubLayoutConstants.PATH_NAMES.GET_STARTED_HOME;
  if (resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT)
    return HubLayoutConstants.PATH_NAMES.PROFESSIONAL_DEVELOPMENT_HOME;
  if (resourceFolderName === COURSE_FOLDERS.TESTS) return HubLayoutConstants.PATH_NAMES.TEST_HOME;
  return HubLayoutConstants.PATH_NAMES.RESOURCE_HOME;
};
export default function getHomeLink(courseId, folder, isPreview) {
  return `${getResourceLink(courseId, folder, isPreview)}${getHomePath(folder)}`;
}
