import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button, { buttonTypes } from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Resource.scss';
import getResourceIcon from './ResourceConstants';
import breakpoints from '../../globals/breakpoints';
import { RESOURCE_DOWNLOAD_TYPE } from '../../globals/hubConstants';
import { hasLockableKeywords } from '../../structure/HubCourseAssessments/Utils/isLockedNode';
import usePrevious from '../../utils/hooks/usePrevious';

function Resource({
  resource,
  downloadResource,
  openModal,
  hubContent,
  downloadIsLoading,
  resourceIdsDownloading,
  hasLicence,
  isExpired = false,
  startedLicence,
  openExpiredModal,
  openNotStartedModal,
  key,
  breakpoint,
  resourceId
}) {
  const prevResourcesDownloading = usePrevious(resourceIdsDownloading);
  const isCurrentResourceDownloading = downloadIsLoading && resourceIdsDownloading?.[resourceId];

  const [resourceDownloadedState, setResourceDownloadedState] = useState(false);

  useEffect(() => {
    if (!isCurrentResourceDownloading && prevResourcesDownloading?.[resourceId]) {
      setResourceDownloadedState(true);
      setTimeout(() => setResourceDownloadedState(false), 600);
    }
  }, [downloadIsLoading, resourceIdsDownloading]);

  const { format = '' } = resource;
  const resourceIcon = getResourceIcon(format);
  const isLocked = resource?.keywords?.length && hasLockableKeywords(resource.keywords);

  const isDownloadable =
    !isLocked && (resource?.downloadable === RESOURCE_DOWNLOAD_TYPE.YES || !resource?.downloadable);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (hasLicence && startedLicence && !isExpired) {
        openModal(resourceId, isDownloadable);
      }
      if (!hasLicence || isExpired) {
        openExpiredModal(key);
      } else if (!startedLicence) {
        openNotStartedModal(key);
      }
    }
  };

  const handleClick = () => {
    if (hasLicence && startedLicence && !isExpired) {
      if ('url' in resource) {
        window.open(resource.url);
      } else if ('epsResourceUrl' in resource) {
        window.open(resource.epsResourceUrl);
      } else {
        openModal(resourceId, isDownloadable);
      }
    }
    if (!hasLicence || isExpired) {
      openExpiredModal(key);
    } else if (!startedLicence) {
      openNotStartedModal(key);
    }
  };

  const getDownloadButtonText = () => {
    if (breakpoint === breakpoints.XXS) {
      return '';
    }
    if (isCurrentResourceDownloading) {
      return hubContent.button_downloading_text;
    }
    if (resourceDownloadedState) {
      return hubContent.button_downloaded_text;
    }
    return hubContent.button_download_text;
  };

  const getDownloadButtonIcon = () => {
    if (isCurrentResourceDownloading) {
      return GLYPHS.ICON_LOADING;
    }
    if (resourceDownloadedState) {
      return GLYPHS.ICON_CHECK_CIRCLE;
    }
    return GLYPHS.ICON_DOWNLOAD;
  };

  let svgIcon = GLYPHS[resourceIcon];
  svgIcon = hasLicence && startedLicence && !isExpired && !isLocked ? GLYPHS[resourceIcon] : GLYPHS.ICON_LOCK_24;

  return (
    <li
      data-list="resource"
      className={`${styles.resource} ${!hasLicence || isExpired ? styles.expiredLicence : ''}
        ${hasLicence && !isExpired && !startedLicence ? styles.notStartedLicence : ''}`}
    >
      <div
        className={styles.resourceItemContainer}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="link"
        tabIndex={0}
      >
        <div className={styles.resourceDetailsContainer}>
          <div className={!isLocked ? styles.svgIconContainer : styles.svgIconContainerLocked}>
            <SVGIcon glyph={'url' in resource && !isLocked ? GLYPHS.ICON_EXTERNAL_LINK_MATERIAL : svgIcon} />
          </div>
          {resource.title}
          <span className="a11y-hide">{hubContent.ally_opens_in_a_pop_up}</span>
          {'url' in resource ? (
            <div className={styles.svgIconExternalLink}>
              <SVGIcon glyph={GLYPHS.ICON_LINK} />{' '}
            </div>
          ) : null}
        </div>
        {!('url' in resource) ? (
          <div
            className={`${styles.buttonContainer} ${
              resourceDownloadedState || isCurrentResourceDownloading ? styles.isDownloadingButton : ''
            }`}
          >
            {hasLicence && startedLicence && isDownloadable && (
              <Button
                id={resource.resourceId}
                text={getDownloadButtonText()}
                glyph={getDownloadButtonIcon()}
                type={buttonTypes.DOWNLOAD_RESOURCE}
                disabled={isCurrentResourceDownloading || resourceDownloadedState}
                onClick={e => {
                  downloadResource(resource);
                  e.stopPropagation();
                }}
                dataAttribute={resource.title}
                hasGlyphContainer={!resourceDownloadedState && !isCurrentResourceDownloading}
              />
            )}
          </div>
        ) : null}
      </div>
    </li>
  );
}
export default Resource;

Resource.propTypes = {
  resource: PropTypes.object.isRequired,
  downloadResource: PropTypes.func,
  openModal: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  downloadIsLoading: PropTypes.bool.isRequired,
  key: PropTypes.string,
  hasLicence: PropTypes.bool,
  isExpired: PropTypes.bool,
  startedLicence: PropTypes.bool,
  openExpiredModal: PropTypes.func,
  openNotStartedModal: PropTypes.func,
  resourceId: PropTypes.string.isRequired,
  breakpoint: PropTypes.string,
  resourceIdsDownloading: PropTypes.object
};

Resource.defaultProps = {
  downloadResource: () => {}
};
