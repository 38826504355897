const FOCUSABLE_ELEMENTS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const onKeyDownHandler = callback => event => {
  if (event.key === 'Enter' || event.key === ' ') {
    callback(event);
  }
};

const displayHeaderOrFooter = (historyOrHook, setDisplay) => {
  let history;
  if (typeof historyOrHook === 'object') {
    history = historyOrHook;
  } else if (typeof historyOrHook === 'function') {
    history = historyOrHook();
  } else {
    throw new Error('Invalid argument: historyOrHook must be either a history object or useHistory hook.');
  }

  let urlRegex = /^\/launch\/(teacher|student)\/.+$/.test(history.location.pathname);
  setDisplay(!urlRegex);

  const unlisten = history.listen(location => {
    urlRegex = /^\/launch\/(teacher|student)\/.+$/.test(location.pathname);
    setDisplay(!urlRegex);
  });

  return unlisten;
};

export { FOCUSABLE_ELEMENTS, onKeyDownHandler, displayHeaderOrFooter };
