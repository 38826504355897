import jsonFetch from '../util/jsonFetch.js';
import { getCurrentPlatform } from '../../../../utils/platform';
import { getLanguage } from '../../../../utils/manageSelectorLanguage';

export default () => {
  let url = `${__API_BASE__}/open/support-articles`;
  const params = [];
  const platformCode = getCurrentPlatform();
  const language = getLanguage();
  params.push(`platformCode=${platformCode}`);
  params.push(`language=${language}`);

  if (__SHA_VERSION__) {
    params.push(`buildVersion=${__SHA_VERSION__}`);
  }

  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  return jsonFetch('CmsSupportArticles', [url]);
};
