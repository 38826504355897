import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ManagedUserChangePasswordPanel } from '../../../panels';
import { featureIsEnabled } from '../../../globals/envSettings';

function UnlockAccount({ userIds = [], ...props }) {
  const { people } = props;
  const userDetails = [];
  const filteredPeople = Object.fromEntries(Object.entries(people).filter(([id]) => userIds.includes(id)));
  Object.values(filteredPeople).forEach(person => {
    const firstName = person.firstname;
    const lastName = person.lastname;
    const username = person.email && person.email !== null ? person.email : person.username;
    userDetails.push({ firstName, lastName, username });
  });
  return (
    <ManagedUserChangePasswordPanel
      title="This account is locked"
      detail="You must set a new password to unlock this account"
      multiple={featureIsEnabled('lock-account') ? false : userIds.length > 1}
      userId={featureIsEnabled('lock-account') ? userIds : userIds[0]}
      userLocked
      {...props}
      userDetails={userDetails}
    />
  );
}

UnlockAccount.propTypes = {
  ...ManagedUserChangePasswordPanel.propTypes,
  userIds: PropTypes.arrayOf(PropTypes.string)
};

export default connect(state => ({ userIds: state.unlockAccount.userIds }))(UnlockAccount);
