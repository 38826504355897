import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import styles from './ActivationCodeContentPage.scss';

function ActivationCodeContentPage({ localizedContent: { activationCodeDashboard: content } }) {
  return (
    <PageWrapper>
      <Helmet title="EPS" />
      <Button
        to="/dashboard_eps/act_codes_search"
        type={buttonTypes.SECONDARY}
        text={content.search_section}
        fullWidth
        customClassName={styles.button}
      />
      <Button
        to="/dashboard_eps/act_codes_create"
        type={buttonTypes.SECONDARY}
        text={content.create_section}
        fullWidth
        customClassName={styles.button}
      />
      <Button
        to="/dashboard_eps/act_codes_delete"
        type={buttonTypes.SECONDARY}
        text={content.delete_section}
        fullWidth
        customClassName={styles.button}
      />
    </PageWrapper>
  );
}

ActivationCodeContentPage.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('activationCodeDashboard'))(ActivationCodeContentPage);
