import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ORG_REGISTRATION_STATUS } from '@oup/shared-node-browser/constants';
import Button from '../../components/Button/Button';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import userRoles, { roleIsAtLeast } from '../../globals/userRoles';
import withLocalizedContent from '../../language/withLocalizedContent';
import { EditOrgPanel } from '../../panels';
import { closeForm } from '../../redux/reducers/classroomCreate';
import { resetFields } from '../../redux/reducers/data/fields.reducer';
import { resetForm } from '../../redux/reducers/data/forms.reducer';
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';
import { setActiveOrgId, showClosePanelModal, clearSelectedUser } from '../../redux/reducers/organisationPage.reducer';
import { safePanelUnlink } from '../../utils/links/panelLinks';
import ActiveOrganisation from './organisations/ActiveOrganisation';
import AdminOrganisations from './organisations/AdminOrganisations';
import PendingOrganisation from './organisations/PendingOrganisation';
import { switchOrg } from '../../redux/reducers/identity.reducer';
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader';
import actions from '../../redux/actions';
import MyProfileEditPanel from '../../panels/MyProfileEditPanel';
import { isEltAssessmentMode } from '../../utils/platform';

const roleWithEditAccess = [
  userRoles.OUP_ADMIN,
  userRoles.OUP_SUPPORT,
  userRoles.OUP_SERVICE_MANAGER,
  userRoles.ORG_ADMIN
];

class MySchoolPage extends Component {
  constructor(props) {
    super(props);

    const { organisationId } = this.props;

    // Set organisationId in state for easy access
    this.initialise(organisationId);

    this.state = {
      isEditPanelOpen: false
    };
  }

  componentDidMount() {
    const {
      orgIdFromState,
      organisationId: orgId,
      initialiseSearchSubscriptions,
      orgSwitchAction,
      updateEditOrgState,
      match: { params }
    } = this.props;
    const tabName = params?.tabName;
    if (tabName !== 'organisations') {
      initialiseSearchSubscriptions(orgId);
    }

    if (orgIdFromState !== orgId) {
      orgSwitchAction(orgId);
      updateEditOrgState(orgId);
    }
  }

  // Handle changes in OrgId such as when org is clicked in Orgs list:
  componentDidUpdate(prevProps) {
    // orgId is derived by Connect from ether the url or the identity:
    const prevId = prevProps.organisationId;
    const { organisationId } = this.props;

    if (prevId !== organisationId) {
      // Item being viewed has changed, update state
      this.initialise(organisationId);
    }
  }

  getOrganisationView = () => {
    const {
      match: { params },
      location,
      orgPending,
      orgLoadError,
      userCanEditOrg,
      userCanAdminOrgs,
      organisationId
    } = this.props;

    const openEditPanel = userCanEditOrg ? () => this.setState({ isEditPanelOpen: true }) : null;

    if (orgLoadError) {
      return null;
    }
    if (userCanAdminOrgs) {
      return (
        <AdminOrganisations
          organisationId={organisationId}
          params={params}
          location={location}
          openEditPanel={openEditPanel}
        />
      );
    }
    if (orgPending) {
      return (
        <PendingOrganisation
          organisationId={organisationId}
          orgLoadError={orgLoadError}
          params={params}
          location={location}
        />
      );
    }
    return (
      <ActiveOrganisation
        organisationId={organisationId}
        params={params}
        location={location}
        orgLoadError={orgLoadError}
        openEditPanel={openEditPanel}
      />
    );
  };

  initialise = orgId => {
    const { setActiveOrgIdAction } = this.props;
    console.log('[MySchoolPage] Initialising page with ID: ', orgId);

    setActiveOrgIdAction(orgId);
  };

  render() {
    const {
      localizedContent: { mySchoolPage: content, removeLearningMaterialModal: rlmModalContent },
      organisationName,
      orgLoading,
      orgLoadError,
      userCanEditOrg,
      confirmCloseModal,
      showConfirmCloseModalAction,
      resetFormAction,
      resetFieldsAction,
      closeFormAction,
      setActiveOrgIdAction,
      organisationId,
      isMyOrganisation,
      clearSelectedUserAction,
      match: { params },
      history
    } = this.props;

    const { isEditPanelOpen } = this.state;

    return (
      <div className="pad-bot8">
        <Helmet title={isMyOrganisation ? content.pageTab : organisationName} />

        {
          <div>
            {/* Skeleton Loader */}
            {orgLoading && !orgLoadError ? <SkeletonLoader loadingResults={orgLoading} /> : this.getOrganisationView()}
          </div>
        }

        {/* Error message */}
        {orgLoadError && (
          <div style={{ textAlign: 'center' }}>
            <div style={{ width: '5rem', margin: '2rem auto 0' }}>
              <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
            </div>
            <p className="gin2">{content.loading_error_text}</p>
            <Button text={content.button_retry_text} onClick={() => setActiveOrgIdAction(organisationId)} />
          </div>
        )}
        {/* Edit Org panel */}
        {userCanEditOrg ? (
          <EditOrgPanel opened={isEditPanelOpen} close={() => this.setState({ isEditPanelOpen: false })} />
        ) : null}
        {confirmCloseModal && (
          <ConfirmationModal
            title={rlmModalContent.title}
            body={rlmModalContent.body}
            positiveClickText={rlmModalContent.positiveClickText}
            negativeClickText={rlmModalContent.negativeClickText}
            positiveClick={() => {
              // Close the Popout panel
              showConfirmCloseModalAction(false);
              this.setState({ isEditPanelOpen: false });
              history.push(safePanelUnlink('addClass'));
              history.push(safePanelUnlink('archiveClass'));
              history.push(safePanelUnlink('archiveOrg'));
              history.push(safePanelUnlink('downloadSignInCard'));
              closeFormAction();
              clearSelectedUserAction();
              setTimeout(() => {
                // Reset the archive form
                resetFormAction('orgArchive', 'INPUTTING');
                resetFieldsAction('orgArchive', ['userConcent', 'emailNotify']);
                // Reset the edit form
                resetFormAction('orgEdit', 'INPUTTING');

                resetFieldsAction('orgEdit', ['orgName', 'orgCode', 'orgRole', 'orgEmail', 'orgWebsite']);
              }, 300);
            }}
            negativeClick={() => showConfirmCloseModalAction(false)}
          />
        )}
        {isEltAssessmentMode() ? (
          <MyProfileEditPanel
            isOpen={params.panelName === 'editUser'}
            closePanel={() => history.push(safePanelUnlink('editUser'))}
          />
        ) : null}
      </div>
    );
  }
}

MySchoolPage.propTypes = {
  match: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  orgIdFromState: PropTypes.string.isRequired,
  organisationId: PropTypes.string.isRequired,
  organisationName: PropTypes.string.isRequired,
  orgPending: PropTypes.bool.isRequired,
  orgLoading: PropTypes.bool.isRequired,
  orgLoadError: PropTypes.bool.isRequired,
  userCanEditOrg: PropTypes.bool.isRequired,
  userCanAdminOrgs: PropTypes.bool.isRequired,
  setActiveOrgIdAction: PropTypes.func.isRequired,
  confirmCloseModal: PropTypes.bool,
  showConfirmCloseModalAction: PropTypes.func.isRequired,
  resetFormAction: PropTypes.func.isRequired,
  resetFieldsAction: PropTypes.func.isRequired,
  closeFormAction: PropTypes.func.isRequired,
  initialiseSearchSubscriptions: PropTypes.func.isRequired,
  isMyOrganisation: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired,
  orgSwitchAction: PropTypes.func.isRequired,
  clearSelectedUserAction: PropTypes.func.isRequired,
  updateEditOrgState: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('mySchoolPage', 'removeLearningMaterialModal'),
  connect(
    (state, props) => {
      // Current org depends on whether you're an admin. The RouteManager passes context="MY_ORG":
      // Url for most people will be /mySchool but for admins it will be /org/xxx

      const isMyOrganisation = props.context === 'MY_ORG';
      const organisationId = isMyOrganisation ? state.identity.currentOrganisationId : props.match.params.orgId;
      const organisation = state.organisations.data[organisationId] || {};

      return {
        orgIdFromState: state.identity.currentOrganisationId,
        organisationId,
        organisationName: organisation.name || '',
        orgPending: state.editOrg.registrationStatus === ORG_REGISTRATION_STATUS.PARTIALLY_REGISTERED,
        confirmCloseModal: state.organisationPage.showModal,
        orgLoading: state.editOrg.loading || state.organisationPage.loading,
        orgLoadError: !isMyOrganisation && state.organisationPage.errorLoading,
        userCanEditOrg: roleWithEditAccess.includes(state.identity.role),
        userCanAdminOrgs: isMyOrganisation && roleIsAtLeast(userRoles.OUP_SUPPORT, state.identity.role),
        isMyOrganisation
      };
    },
    {
      setActiveOrgIdAction: setActiveOrgId,
      showConfirmCloseModalAction: showClosePanelModal,
      clearSelectedUserAction: clearSelectedUser,
      resetFormAction: resetForm,
      resetFieldsAction: resetFields,
      closeFormAction: closeForm,
      initialiseSearchSubscriptions: orgId =>
        initialiseInstance('orgSubscriptions', false, {
          orgId,
          active: true,
          notStarted: true,
          expired: true,
          expiring: true
        }),
      orgSwitchAction: switchOrg,
      updateEditOrgState: orgId => actions.editOrgGetDetailsRequest(orgId)
    }
  )
)(MySchoolPage);
