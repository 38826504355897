import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { validColors } from '../../globals/colors';
import { bgClass } from '../../utils/colorClassNames';
import styles from './HorizontalScrollContainer.scss';

function HorizontalScrollContainer({ backgroundColor, children, className, hideScrollBar = false }) {
  return (
    <div
      className={classnames(
        styles.horizontalScrollContainer,
        {
          [bgClass(`${backgroundColor}-dark`)]: backgroundColor,
          [styles.hideScrollBar]: hideScrollBar
        },
        className
      )}
    >
      <div>{children}</div>
    </div>
  );
}

HorizontalScrollContainer.propTypes = {
  backgroundColor: PropTypes.oneOf(validColors),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideScrollBar: PropTypes.bool
};

HorizontalScrollContainer.defaultProps = {
  backgroundColor: null
};

export default HorizontalScrollContainer;
