import { put, take } from 'redux-saga/effects';
import { checkProductExistsSuccess, checkProductExistsFailure } from '../../../../actions/productUploadCheck';
import { CHECK_PRODUCT_EXISTS_REQUEST } from '../../../../actionTypes';
import productUploadCheckLogsRequest from './productUploadCheckRequest';

export default function* productUploadCheckData() {
  console.log('[productUploadLogs Saga] Beginning');

  while (true) {
    yield take(CHECK_PRODUCT_EXISTS_REQUEST);

    console.log('[productUploadCheckLogs Saga] Start Request');
    const result = yield productUploadCheckLogsRequest();
    const status = result.status ? result.status.toLowerCase() : 'error';

    const failed = !!result.error || status !== 'success';
    const apiError = result.error || status !== 'success' ? result.message : null;

    if (!failed) {
      console.log('[productUploadCheckLogs Saga] Request completed, showing results');
      yield put(checkProductExistsSuccess(result.data));
    } else {
      console.log('[productUploadCheckLogs Saga] Request completed, but failed, showing error');
      yield put(checkProductExistsFailure(apiError));
    }
  }
}
