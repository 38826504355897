import * as t from '../actionTypes';

export const getProductsRequest = platform => ({
  type: t.GET_PRODUCTS_REQUEST,
  payload: platform
});

export const getProductsSuccess = products => ({
  type: t.GET_PRODUCTS_SUCCESS,
  payload: products
});

export const getProductsFailure = errors => ({
  type: t.GET_PRODUCTS_FAILURE,
  payload: errors
});

export const resetGBProducts = () => ({
  type: t.RESET_GB_PRODUCTS
});

export const getGBProductsRequest = productIDs => ({
  type: t.GET_GB_PRODUCTS_REQUEST,
  payload: productIDs
});

export const getGBProductsSuccess = products => ({
  type: t.GET_GB_PRODUCTS_SUCCESS,
  payload: products
});

export const getGBProductsFailure = errors => ({
  type: t.GET_GB_PRODUCTS_FAILURE,
  payload: errors
});

export const getOrgProductsRequest = orgId => ({
  type: t.GET_ORG_PRODUCTS_REQUEST,
  payload: orgId
});
export const getOrgProductsSuccess = productsAdded => ({
  type: t.GET_ORG_PRODUCTS_SUCCESS,
  payload: productsAdded
});
