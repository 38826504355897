import * as t from '../../actionTypes';

const requestStatusOptions = { success: [], isLoading: true, error: {} };
const successStatusOptions = { success: [], isLoading: false, error: {} };
const failureStatusOptions = { success: [], isLoading: false, error: {} };

const initialState = {
  success: [],
  isLoading: false,
  error: {}
};

function generateDownloadLinks(state = initialState, { type, payload }) {
  switch (type) {
    case t.GENERATE_DOWNLOAD_LINKS_REQUEST:
      return {
        ...state,
        ...requestStatusOptions,
        error: {}
      };
    case t.GENERATE_DOWNLOAD_LINKS_SUCCESS:
      return { ...state, ...successStatusOptions, success: payload.success, error: {} };
    case t.GENERATE_DOWNLOAD_LINKS_FAILURE:
      return { ...state, ...failureStatusOptions, error: payload.error, success: [] };
    default:
      return state;
  }
}

export default generateDownloadLinks;
