import React from 'react';
import PropTypes from 'prop-types';

import { isLicenceExpiresEarly } from '@oup/shared-node-browser/licenceHelper.js';
import { PRODUCT_TARGET_USERTYPE } from '@oup/shared-node-browser/constants';
import LinkWithIcon from '../../../../components/LinkWithIcon/LinkWithIcon.js';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon.js';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch.js';
import cmsContent from '../../../../utils/cmsContent.js';
import { getExpiresOnText, getLicencePeriodDetails } from '../../../../utils/licences.js';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { assignmentContexts } from '../../../../globals/assignmentConstants';
import colors from '../../../../globals/colors';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import styles from './ReviewLicencesViews.scss';
import { isHubMode } from '../../../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../globals/hubConstants.js';
import Illustration from '../../../../components/Illustration/Illustration.js';
import showIncludeLicencesForTeachers from './utils/showIncludeLicencesForTeachers.js';

const viewLicensesMessage = (shouldAssignLicences, totalUserCount, licencesAvailableCount) => {
  const CMS = cmsContent.assignLearningMaterialSummary || {};
  if (shouldAssignLicences && totalUserCount <= licencesAvailableCount) {
    return CMS.getting_licences_text;
  }
  if (!shouldAssignLicences && totalUserCount <= licencesAvailableCount) {
    return CMS.view_licences_text;
  }
  return CMS.users_needing_licence_text;
};

const filterIds = (ids = [], filterId = []) => ids.filter(userId => filterId.includes(userId));

function AssignmentSummary({
  shouldAssignLicences,
  shouldTeacherAssignLicences,
  hasLicencesAvailable = false,
  licencesTypes,
  setAssignLicencesAction,
  setTeacherAssignLicencesAction,
  toggleView,
  licencesAvailableCount = 0,
  usersNeedingLicenceCount = 0,
  totalUserCount = 0,
  usersWithLicenceCount = 0,
  context,
  teacherLicenceAssignStatus,
  productTargetUsertype,
  userIdsWithExistingLicence,
  teacherIds,
  productPlatform
}) {
  const teacherUserIdsWithExistingLicence = filterIds(userIdsWithExistingLicence, teacherIds);
  const CMS = cmsContent.assignLearningMaterialSummary || {};
  const classAssignmentContext = [
    assignmentContexts.CLASS,
    assignmentContexts.CLASS_CREATION,
    assignmentContexts.CLASS_RENEW,
    assignmentContexts.CLASS_ONBOARDING_WIZARD
  ].includes(context);
  let showIncludeLicencesTeachers = classAssignmentContext && shouldAssignLicences && !productTargetUsertype;

  if (showIncludeLicencesTeachers && isHubMode()) {
    showIncludeLicencesTeachers = showIncludeLicencesForTeachers(
      productTargetUsertype,
      productPlatform,
      shouldAssignLicences,
      teacherUserIdsWithExistingLicence,
      teacherIds
    );
  }

  return (
    <div className={styles.assignmentSummary}>
      <div className={styles.displayFlexBox}>
        <ToggleSwitch
          id="useOrganisationLicencesToggle"
          value={shouldAssignLicences}
          onChange={hasLicencesAvailable ? setAssignLicencesAction : () => {}}
          label={CMS.assign_licence_header}
          disabled={!hasLicencesAvailable || totalUserCount > licencesAvailableCount}
        />
        <Tooltip title={CMS.org_licence_info_text} className={styles.largeTooltip}>
          <SVGIcon glyph={GLYPHS.ICON_HELP_CIRCLE} className={styles.questionIcon} />
        </Tooltip>
      </div>
      <div id="licenseTypeView" className={styles.licenseTypeView}>
        <div style={{ padding: '16px' }}>
          {shouldAssignLicences && totalUserCount <= licencesAvailableCount ? (
            <div>
              {licencesTypes.length > 1 && (
                <div className={styles.jumbotron}>
                  {isHubMode() ? (
                    <Illustration
                      illustrationSrc={HubIllustrationConstants.INFORMATION}
                      illustrationAltText={HubIllustrationAltText.INFORMATION}
                    />
                  ) : (
                    <div className={styles.mixedLicenceInfo}>
                      <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
                    </div>
                  )}
                  {CMS.mixed_licence_type_text}
                </div>
              )}
              <table className={styles.licenseViewTable}>
                <thead>
                  <tr>
                    <th>{CMS.licence_type_text}</th>
                    <th>{CMS.avalible_text}</th>
                    <th>{CMS.assign_text}</th>
                  </tr>
                </thead>
                <tbody>
                  {licencesTypes.map((licenceType, index) => (
                    <tr key={index}>
                      <td>
                        <span
                          style={{
                            display: 'block',
                            marginTop: '2px'
                          }}
                        >
                          {getLicencePeriodDetails(CMS, licenceType)}
                        </span>
                        <span style={{ display: 'block', color: '#666' }}>
                          {isLicenceExpiresEarly(licenceType, true)
                            ? `${getExpiresOnText(CMS, licenceType.licenceEndDate)}`
                            : ``}
                        </span>
                      </td>
                      <td>
                        <strong>{licenceType.availableCount}</strong>
                      </td>
                      <td>
                        <strong>{licenceType.amountAssigning}</strong>
                      </td>
                    </tr>
                  ))}
                  {productTargetUsertype && (
                    <tr>
                      <td colSpan={3}>
                        <span>
                          <strong>{`${CMS.note_text}: `}</strong>
                          {`${
                            productTargetUsertype === PRODUCT_TARGET_USERTYPE.STUDENT
                              ? CMS.assignment_not_available_teachers
                              : CMS.assignment_not_available_students
                          }.`}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              {isHubMode() ? (
                <Illustration
                  illustrationSrc={HubIllustrationConstants.INFORMATION}
                  illustrationAltText={HubIllustrationAltText.INFORMATION}
                />
              ) : (
                <div style={{ height: '40px', width: '40px', margin: 'auto' }}>
                  <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
                </div>
              )}
              {!shouldAssignLicences && totalUserCount <= licencesAvailableCount ? (
                <div>
                  <p className="lead-bold">{CMS.not_enough_licences_avalible_header}</p>
                  <p>{CMS.no_students_for_licences_body}</p>
                </div>
              ) : (
                <div>
                  <p className="lead-bold">{CMS.not_enough_licences_avalible_header}</p>
                  <p>{CMS.not_enough_licences_body}</p>
                </div>
              )}
            </div>
          )}
          {shouldAssignLicences &&
          // The actual licences being used is <> to number of users in the request
          usersNeedingLicenceCount !== 0 &&
          usersNeedingLicenceCount !== totalUserCount &&
          // If some users already have licences
          usersWithLicenceCount !== 0 ? (
            <div style={{ marginTop: '1rem' }}>
              <div
                style={{
                  textAlign: 'center',
                  background: `${colors.COLOR_WHITE}`,
                  borderRadius: '5px',
                  padding: '1rem'
                }}
              >
                {isHubMode() ? (
                  <Illustration
                    illustrationSrc={HubIllustrationConstants.INFORMATION}
                    illustrationAltText={HubIllustrationAltText.INFORMATION}
                  />
                ) : (
                  <div style={{ height: '30px', width: '30px', margin: '0 auto 0.5rem' }}>
                    <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
                  </div>
                )}

                <div>
                  <p className="lead">{CMS.licences_in_use_title}</p>
                  <p style={{ maxWidth: '300px', margin: '0.5rem auto 0' }}>{CMS.licences_in_use_body}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <hr />
        <div style={{ padding: '12px 0', textAlign: 'center' }}>
          <LinkWithIcon
            id="viewLicensesToBeAssigned"
            text={viewLicensesMessage(shouldAssignLicences, totalUserCount, licencesAvailableCount)}
            glyph={GLYPHS.ICON_RIGHT}
            action={toggleView}
          />
        </div>
      </div>

      {showIncludeLicencesTeachers && (
        <Checkbox
          id="include_licences_teachers"
          label={CMS.include_licences_teachers}
          value={shouldTeacherAssignLicences}
          onChange={() => setTeacherAssignLicencesAction(!shouldTeacherAssignLicences)}
          disabled={teacherLicenceAssignStatus}
        />
      )}
    </div>
  );
}

AssignmentSummary.propTypes = {
  shouldAssignLicences: PropTypes.bool.isRequired,
  shouldTeacherAssignLicences: PropTypes.bool.isRequired,
  hasLicencesAvailable: PropTypes.bool,
  licencesTypes: PropTypes.array.isRequired,
  setAssignLicencesAction: PropTypes.func.isRequired,
  setTeacherAssignLicencesAction: PropTypes.func.isRequired,
  toggleView: PropTypes.func.isRequired,
  licencesAvailableCount: PropTypes.number,
  usersNeedingLicenceCount: PropTypes.number,
  totalUserCount: PropTypes.number,
  usersWithLicenceCount: PropTypes.number,
  context: PropTypes.string.isRequired,
  teacherLicenceAssignStatus: PropTypes.bool,
  productTargetUsertype: PropTypes.string.isRequired,
  userIdsWithExistingLicence: PropTypes.array,
  teacherIds: PropTypes.array,
  productPlatform: PropTypes.string
};

export default AssignmentSummary;
