import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ScrollContainer from '../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import PopoutActionFooter from '../../../components/PopoutActionFooter/PopoutActionFooter.js';
import MaterialRepresentation from '../../../components/MaterialRepresentation/MaterialRepresentation.js';
import { sanitizeUrl } from '../../../utils/url.js';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail.js';

import {
  toggleNotifyLearners,
  removeLearningMaterials
} from '../../../redux/reducers/removeLearningMaterial.reducer.js';

import content from '../../../utils/cmsContent.js';
import { isHubMode } from '../../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';

// const content = {
//   removeLearningMaterialPanel: {
//     review_title: 'Are you sure you want to remove learning material assigned to {contextName}',
//     review_subtitle: 'Any unused licences will be returned to the library.',
//     review_submit_text: 'Remove assigned learning material',
//     review_notify_learners: 'Notify students with an unused licence',
//     submitting_title: 'Please wait...',
//     submitting_subtitle: 'Learning material is being removed',
//     confirmation_success_title: 'You removed {removedCount} learning materials.',
//     confirmation_success_subtitle: '{returnedCount} unused licences have been returned to the library.',
//     confirmation_warning_title: 'Not all learning material could be removed.',
//     confirmation_error_title: 'There was an error.',
//     confirmation_error_subtitle: 'No learning material has been removed.',
//     confirmation_material_success: 'Removed assignment to learning material',
//     confirmation_material_warning: 'Could not remove assignment to learning material'
//   }
// };

function RemoveLearningMaterialReview({
  contextName,
  assignments,
  notifyLearners,
  products,
  data,
  backButtonAction,
  closeButtonAction,
  toggleNotifyLearnersAction,
  removeLearningMaterialsAction
}) {
  const CMS = content.removeLearningMaterialPanel || {};

  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        headerContent={
          <div>
            <div className="row cols-edge">
              <div className="col sm1">
                {!isHubMode() && (
                  <PanelNavigationLink isLhs id="panelNavLink_back" text="Back" action={backButtonAction} />
                )}
              </div>
              <div className="col sm1">
                <PanelNavigationLink isLhs={false} text="Close" action={closeButtonAction} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                {isHubMode() ? (
                  <PopoutPanelIllustrationHeading
                    title={CMS.review_title.replace('{contextName}', contextName)}
                    subtitle={CMS.review_subtitle}
                    illustrationSrc={HubIllustrationConstants.WARNING}
                    illustrationAltText={HubIllustrationAltText.WARNING}
                  />
                ) : (
                  <PopoutPanelIconHeading
                    type={iconHeadingTypes.WARNING}
                    title={CMS.review_title.replace('{contextName}', contextName)}
                    subtitle={CMS.review_subtitle}
                  />
                )}
              </div>
            </div>
          </div>
        }
        footerContent={
          <PopoutActionFooter
            primaryButtonText={CMS.review_submit_text}
            primaryButtonAction={removeLearningMaterialsAction}
            toggleSwitchId="notifyLearners"
            toggleSwitchLabel={CMS.review_notify_learners}
            toggleSwitchValue={notifyLearners}
            toggleSwitchOnChange={() => toggleNotifyLearnersAction()}
          />
        }
      >
        {assignments.length > 0 &&
          assignments.map(({ productId }) => {
            const product = products[productId];
            const licenceDetails = data[productId].licenceDetails || data[productId].orgLicenceDetails;
            const archiveStatus = data[productId].archiveStatus;
            const statusText = `
                ${licenceDetails && licenceDetails.assigned ? `${licenceDetails.assigned} licences assigned` : ''}
                ${licenceDetails && licenceDetails.assigned && licenceDetails.available ? String.fromCharCode(183) : ''}
                ${licenceDetails && licenceDetails.available ? `${licenceDetails.available} available` : ''}
                ${archiveStatus && archiveStatus === 'ARCHIVED' ? `Already archived` : ''}
            `;

            return (
              <div className="pad2" style={{ borderBottom: '1px solid #d9d9d9' }} key={productId}>
                <MaterialRepresentation
                  thumbnailSize={thumbnailSizes.TABLE}
                  imageSrc={sanitizeUrl(product.coverImage)}
                  name={product.title}
                  statusText={isHubMode() ? '' : statusText}
                  statusColor={
                    licenceDetails && (licenceDetails.assigned || licenceDetails.available) ? 'SUCCESS' : 'ERROR'
                  }
                />
              </div>
            );
          })}
      </ScrollContainer>
    </form>
  );
}

RemoveLearningMaterialReview.propTypes = {
  contextName: PropTypes.string.isRequired,
  assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
  notifyLearners: PropTypes.bool.isRequired,
  products: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,

  backButtonAction: PropTypes.func.isRequired,
  closeButtonAction: PropTypes.func.isRequired,
  toggleNotifyLearnersAction: PropTypes.func.isRequired,
  removeLearningMaterialsAction: PropTypes.func.isRequired
};

export default connect(
  state => {
    let searchInstance;
    const context = state.removeLearningMaterial.context;
    if (context === 'ORG') {
      searchInstance = 'orgAssignments';
    } else if (context === 'CLASS') {
      searchInstance = 'classAssignments';
    } else if (context === 'USER') {
      searchInstance = 'userAssignments';
    }

    return {
      assignments: state.removeLearningMaterial.assignments,
      notifyLearners: state.removeLearningMaterial.notifyLearners,
      products: state.products.data,
      data: state.search[searchInstance].data || {}
    };
  },
  {
    toggleNotifyLearnersAction: toggleNotifyLearners,
    removeLearningMaterialsAction: removeLearningMaterials
  }
)(RemoveLearningMaterialReview);
