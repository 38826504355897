import { put, all, takeLatest, fork } from 'redux-saga/effects';
import uuid from 'uuid';
import { COURSE_UPLOAD_START } from '../../../../actionTypes';
import { pollStartAction, pollStopAction } from '../../../../reducers/polling.reducer';
import { courseUploadSuccess, courseUploadFailure } from '../../../../actions/courseUpload';
import courseUploadApi from '../../../apiCalls/courseUpload/courseUploadApi';
import courseUploadValidation from '../../../apiCalls/courseUpload/courseUploadValidation';

const STATUS = {
  SUCCESS: 'success',
  UPLOADING: 'uploading',
  FIRST_CALL: 'first_call'
};

function* processHandler(courseStageId) {
  const res = yield courseUploadValidation(courseStageId);

  if (res?.error) {
    yield all([put(pollStopAction()), put(courseUploadFailure(res.error))]);
  } else if (res?.status === STATUS.SUCCESS) {
    yield all([put(pollStopAction()), put(courseUploadSuccess(res))]);
  }
}

function* pollingStart(courseStageId) {
  yield all([processHandler(courseStageId), put(pollStartAction('COURSE_UPLOAD_PROCESS'))]);
}

function* postCourseUpload(formData) {
  const courseStageId = uuid.v4();
  yield fork(courseUploadApi, { courseStageId, formData });

  yield pollingStart(courseStageId);
  yield takeLatest(COURSE_UPLOAD_START, () => processHandler(courseStageId));
}

export default postCourseUpload;
