const initialState = {
  instance: 'libProducts',
  selectedProductIds: []
};

export const SET_SEARCH_BY = 'library/SET_SEARCH_BY';
const SET_PRODUCT_SELECTED = 'library/SET_PRODUCT_SELECTED';
const CLEAR_PRODUCT_SELECTION = 'library/CLEAR_PRODUCT_SELECTION';

export default function library(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SEARCH_BY: {
      return {
        ...state,
        instance: action.instance
      };
    }
    // Update list selection actions
    case SET_PRODUCT_SELECTED:
      return {
        ...state,
        selectedProductIds: action.isSelected
          ? // If now is selected, add to list
            [...state.selectedProductIds, action.productId]
          : // Else remove from list
            state.selectedProductIds.filter(id => id !== action.productId)
      };
    case CLEAR_PRODUCT_SELECTION:
      return {
        ...state,
        selectedProductIds: []
      };
    default:
      return state;
  }
}

export const setSearchBy = searchBy => ({
  type: SET_SEARCH_BY,
  instance: searchBy
});

export const setProductSelected = (productId, isSelected) => ({
  type: SET_PRODUCT_SELECTED,
  productId,
  isSelected
});

export const clearProductSelection = () => ({
  type: CLEAR_PRODUCT_SELECTION
});
