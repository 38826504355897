import { createSelector } from 'reselect';
import { get } from 'lodash';
import createAuthorizeByRole from '../../../authorization/createAuthorizeByRole';
import { orgRoles } from '../../../globals/orgRoles';
import userRoles, { roleIsAtLeast } from '../../../globals/userRoles';
import createGetUserRoleForOrg from '../identity/createGetUserRoleForOrg';

/**
 * A selector resolving if the authenticated user is an OUP administrator.
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const isAdmin = createAuthorizeByRole(userRoles.OUP_ADMIN);

/**
 * A selector resolving if the authenticated user is an OUP support user.
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const isSupport = createAuthorizeByRole(userRoles.OUP_SUPPORT);

/**
 * A selector resolving if the user is authenticated
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const isLoggedIn = createAuthorizeByRole(Object.values(userRoles));

/**
 * A selector returning if role has authorisation of TEACHER_ADMIN or above
 * @param {Object} state
 *
 * @return {boolean}
 */
export const isAtLeastTeacherAdmin = createSelector(
  state => state.identity.role,
  userRole => roleIsAtLeast(userRoles.TEACHER_ADMIN, userRole)
);

/**
 * A selector resolving if the authenticated user is an OUP administrator or
 * OUP support user.
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const isAdminOrSupport = createAuthorizeByRole([userRoles.OUP_ADMIN, userRoles.OUP_SUPPORT]);

/**
 * A selector resolving if the authenticated user is an OUP administrator or
 * OUP support user.
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const isAdminSupportOrContent = createAuthorizeByRole([
  userRoles.OUP_ADMIN,
  userRoles.OUP_SUPPORT,
  userRoles.OUP_CONTENT
]);

/**
 * A selector resolving if the authenticated user should have access to
 * ELT Preview.
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const isELTReviewer = createAuthorizeByRole([
  userRoles.OUP_CONTENT_REVIEWER,
  userRoles.EXTERNAL_CONTENT,
  userRoles.OUP_ADMIN,
  userRoles.OUP_SUPPORT,
  userRoles.OUP_CONTENT
]);

/**
 * A selector resolving if the authenticated user is an MANAGED_USER or LEARNER.
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const isManagedUserOrLearner = createAuthorizeByRole([userRoles.MANAGED_USER, userRoles.LEARNER]);

/**
 * A selector returning if role has authorisation of MANAGED_USER or above
 * @param {Object} state
 *
 * @return {boolean}
 */
export const isAtLeastManagedUser = createSelector(
  state => state.identity.role,
  userRole => roleIsAtLeast(userRoles.MANAGED_USER, userRole)
);

/**
 * A selector returning if role has authorisation of TEACHER or above
 * @param {Object} state
 *
 * @return {boolean}
 */
export const isAtLeastTeacher = createSelector(
  state => state.identity.role,
  userRole => roleIsAtLeast(userRoles.TEACHER, userRole)
);

/**
 * A selector returning a function resolving if the authenticated user can edit
 * a user in a given organisation.
 *
 * @param {Object} state
 *
 * @return {Function}
 */
export const createAuthorizeUpdateUser = createSelector(
  isAdmin,
  isSupport,
  createGetUserRoleForOrg,
  state => state.userProfile.role,
  (isAuthUserOupAdmin, isAuthUserOupSupport, getUserRoleForOrg, targetUserRole) => ({ orgId }) => {
    const userOrgRole = getUserRoleForOrg(orgId);
    return (
      isAuthUserOupAdmin ||
      (isAuthUserOupSupport && targetUserRole !== userRoles.OUP_ADMIN) ||
      userOrgRole === userRoles.ORG_ADMIN ||
      ((userOrgRole === userRoles.TEACHER_ADMIN || userOrgRole === userRoles.TEACHER) &&
        targetUserRole === userRoles.MANAGED_USER)
    );
  }
);

/**
 * A selector returning a function resolving if the authenticated user can unlock/change password of
 * a user in a given organisation.
 *
 * @param {Object} state
 *
 * @return {Function}
 */
export const createAuthorizeUnlockUser = createSelector(
  isAdmin,
  isSupport,
  createGetUserRoleForOrg,
  state => state.userProfile.role,
  (isAuthUserOupAdmin, isAuthUserOupSupport, getUserRoleForOrg, targetUserRole) => ({ orgId }) => {
    const userOrgRole = getUserRoleForOrg(orgId);
    return (
      isAuthUserOupAdmin ||
      (isAuthUserOupSupport && targetUserRole !== userRoles.OUP_ADMIN) ||
      userOrgRole === userRoles.ORG_ADMIN ||
      userOrgRole === userRoles.TEACHER_ADMIN ||
      userOrgRole === userRoles.TEACHER
    );
  }
);

/**
 * A selector returning a function resolving if the authenticated user can
 * create or invite a user to the given organisation.
 *
 * @param {Object} state
 *
 * @return {Function}
 */
export const createAuthorizeRegisterUser = createSelector(
  isAdminOrSupport,
  createGetUserRoleForOrg,
  state => state.organisations.data,
  (base, getUserRoleForOrg, organizations) => ({ orgId }) => {
    const organization = organizations[orgId] || {};
    const userOrgRole = getUserRoleForOrg(orgId);

    return (
      base ||
      [userRoles.ORG_ADMIN]
        .concat(organization.role === orgRoles.PRIMARY_SCHOOL ? userRoles.TEACHER_ADMIN : [])
        .includes(userOrgRole)
    );
  }
);

/**
 * A selector resolving if the authenticated user can remove another user.
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const createAuthorizeRemoveUser = createSelector(
  isAdminOrSupport,
  createGetUserRoleForOrg,
  state => get(state, ['organisations', 'data'], {}),
  state => get(state, ['userProfile', 'role'], ''),
  (base, getUserRoleForOrg, organizations, userProfileRole) => ({ orgId, targetUserRole = userProfileRole }) => {
    const organization = organizations[orgId] || {};
    const userRole = getUserRoleForOrg(orgId);
    const orgRole = organization.role;
    return (
      base ||
      userRole === userRoles.ORG_ADMIN ||
      (userRole === userRoles.TEACHER_ADMIN &&
        orgRole === orgRoles.PRIMARY_SCHOOL &&
        targetUserRole === userRoles.MANAGED_USER) ||
      (userRole === userRoles.TEACHER_ADMIN &&
        orgRole === orgRoles.SECONDARY_SCHOOL &&
        targetUserRole !== userRoles.MANAGED_USER)
    );
  }
);

/**
 * A selector returning a function resolving if the authenticated user can accept/reject student request
 * a user in a given organisation.
 * Authorize to OUP_Admin, OUP_support, ORG_Admin, Teacher_Admin, Teacher
 *
 * @param {Object} state
 *
 * @return {Function}
 */
export const createAuthorizeToJoinAClass = createSelector(
  isAdmin,
  isSupport,
  createGetUserRoleForOrg,
  state => state.userProfile.role,
  (isAuthUserOupAdmin, isAuthUserOupSupport, getUserRoleForOrg, targetUserRole) => ({ orgId }) => {
    const userOrgRole = getUserRoleForOrg(orgId);
    return (
      isAuthUserOupAdmin ||
      (isAuthUserOupSupport && targetUserRole !== userRoles.OUP_ADMIN) ||
      userOrgRole === userRoles.ORG_ADMIN ||
      userOrgRole === userRoles.TEACHER_ADMIN ||
      userOrgRole === userRoles.TEACHER
    );
  }
);

/**
 * A selector resolving if the authenticated user can remove another user conditionally .
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const createAuthorizeRemoveUserBasedOnRole = createSelector(
  isAdminOrSupport,
  createGetUserRoleForOrg,
  state => state.organisations.data,
  state => state.userProfile.role,
  (base, getUserRoleForOrg, organizations, userProfileRole) => ({ orgId, targetUserRole = userProfileRole }) => {
    const organization = organizations[orgId] || {};
    const userRole = getUserRoleForOrg(orgId);
    const orgRole = organization.role;
    return (
      base ||
      userRole === userRoles.ORG_ADMIN ||
      (userRole === userRoles.TEACHER_ADMIN &&
        orgRole === orgRoles.PRIMARY_SCHOOL &&
        targetUserRole === userRoles.MANAGED_USER)
    );
  }
);

/**
 * A selector resolving if the authenticated user can download students sign in card .
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const createAuthorizeDownloadSignInCard = createSelector(
  isAdminOrSupport,
  createGetUserRoleForOrg,
  state => state.organisations.data,
  state => state.userProfile.role,
  (base, getUserRoleForOrg, organizations, userProfileRole) => ({ orgId, targetUserRole = userProfileRole }) => {
    const organization = organizations[orgId] || {};
    const userRole = getUserRoleForOrg(orgId);
    const orgRole = organization.role;
    return (
      base ||
      ((userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN) &&
        orgRole === orgRoles.PRIMARY_SCHOOL &&
        targetUserRole === userRoles.MANAGED_USER)
    );
  }
);
/**
 * A selector returning a boolen resolving if the authenticated user can edit other class student profile
 * a user in a given organisation.
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const createAuthorizeEditUser = createSelector(
  isAdmin,
  isSupport,
  createGetUserRoleForOrg,
  state => state.classrooms.data,
  state => state.search.userClasses,
  state => state.userProfile.role,
  (isAuthUserOupAdmin, isAuthUserOupSupport, getUserRoleForOrg, classrooms, userClasses, targetUserRole) => ({
    orgId,
    userId
  }) => {
    const userOrgRole = getUserRoleForOrg(orgId);
    const userCanEditStudentProfile =
      userClasses &&
      userClasses.ids &&
      userClasses.ids.filter(id => classrooms[id] && classrooms[id].teacherIdList.includes(userId)).length > 0;
    return (
      isAuthUserOupAdmin ||
      (isAuthUserOupSupport && targetUserRole !== userRoles.OUP_ADMIN) ||
      userOrgRole === userRoles.ORG_ADMIN ||
      userOrgRole === userRoles.TEACHER_ADMIN ||
      (userRoles.TEACHER && userCanEditStudentProfile && targetUserRole === userRoles.MANAGED_USER)
    );
  }
);

/**
 * A selector resolving if the authenticated user can archive a class.
 *
 * @param {Object} state
 *
 * @return {boolean}
 */
export const createAuthorizeArchiveClassBasedOnRole = createSelector(
  isAdminOrSupport,
  createGetUserRoleForOrg,
  state => state.organisations.data,
  state => state.userProfile.role,
  (base, getUserRoleForOrg, organizations, userProfileRole) => ({ orgId, targetUserRole = userProfileRole }) => {
    const userRole = getUserRoleForOrg(orgId);

    const actingAsOrgAdmin = userRole === userRoles.ORG_ADMIN;
    const targetingStudentsAsClassAdmin =
      userRole === userRoles.TEACHER_ADMIN &&
      (targetUserRole === userRoles.MANAGED_USER || targetUserRole === userRoles.LEARNER);

    return base || actingAsOrgAdmin || targetingStudentsAsClassAdmin;
  }
);
