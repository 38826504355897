import * as t from '../actionTypes';

const initialState = {
  loading: false,
  success: false,
  failure: false,
  errorMessage: ''
};

function gradebookModifyStudent(state = initialState, { type, payload }) {
  switch (type) {
    case t.GRADEBOOK_ASSIGN_LEVEL:
    case t.GRADEBOOK_LEVEL_UP:
    case t.GRADEBOOK_LEVEL_DOWN:
    case t.GRADEBOOK_RESET_LEVEL:
    case t.GRADEBOOK_DELAY_LEVEL_CHANGE:
      return {
        ...initialState,
        loading: true,
        success: false,
        failure: false
      };
    case t.GRADEBOOK_MODIFY_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failure: true,
        errorMessage: payload
      };
    case t.GRADEBOOK_MODIFY_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        success: true
      };
    default:
      return state;
  }
}

export default gradebookModifyStudent;
