import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validColors } from '../../globals/colors';
import { bgClass } from '../../utils/colorClassNames';
import SVGIcon, { validGlyphs } from '../SVGIcon/SVGIcon';
import Tooltip from '../Tooltip/Tooltip';
// @ts-ignore
import fallbackImage from './no-image.png';
import styles from './Thumbnail.scss';

export { GLYPHS } from '../SVGIcon/SVGIcon';

export const SIZES = {
  HUB: 'hub',
  SECTION: 'section', // For use in UI element headings
  HEADING: 'heading', // For use in Page headings
  TABLE: 'table', // For use in tables and lists. This is a responsive size switching between medium and smal
  LARGE: 'large', // The largest size avaliable
  MEDIUM: 'medium', // Use in RHS panel headings
  NARROW: 'narrow', // Used in Graded Readers learner view
  SMALL: 'small', // This size is used in the header and menu. Its the same size as the table variant on small devices
  VERY_LARGE: 'very-large' // This size is used on hub missing license error modal
};

export const validSizes = Object.keys(SIZES).map(key => SIZES[key]);

class Thumbnail extends Component {
  static _handleError = ({ target }) => {
    target.src = fallbackImage;
  };

  _renderContent = () => {
    const { text, glyph, imageSrc, imageAlt } = this.props;

    if (text) {
      // If text is longer than 2 initials then we assume we need to derive initials:
      const initials = (text.length < 3
        ? text
        : (text.match(/\b(\w)/g) || ['-']).slice(0, 2).join('') || ''
      ).toUpperCase();

      return <span>{initials}</span>;
    }
    if (glyph) {
      return <SVGIcon glyph={glyph} />;
    }
    if (imageSrc) {
      return <img src={imageSrc} alt={imageAlt} onError={Thumbnail._handleError} />;
    }

    return null;
  };

  render() {
    const {
      size,
      title,
      backgroundColor,
      imageSrc,
      squareImg,
      disabled,
      smallCircleText,
      smallCircleGlyph,
      smallCircleBackgroundColor,
      isNewTag
    } = this.props;

    return (
      <div
        className={classnames(styles.thumbnail, styles[`size-${size}`], bgClass(backgroundColor), {
          [styles.image]: !!imageSrc,
          [styles.disabled]: disabled,
          [styles.squareImg]: squareImg
        })}
        aria-hidden="true"
        title={title}
      >
        {isNewTag && <span className={styles.newTag}>new</span>}
        {this._renderContent()}
        {smallCircleText && (
          <div className={classnames(styles.smallCircle, styles[`size-${size}`], bgClass(smallCircleBackgroundColor))}>
            {smallCircleGlyph ? (
              <Tooltip title={smallCircleText} className={styles.smallCircleTooltip}>
                <SVGIcon glyph={smallCircleGlyph} />
              </Tooltip>
            ) : (
              smallCircleText
            )}
          </div>
        )}
      </div>
    );
  }
}

Thumbnail.propTypes = {
  size: PropTypes.oneOf(validSizes),
  text: PropTypes.string,
  title: PropTypes.string,
  glyph: PropTypes.oneOf(validGlyphs),
  backgroundColor: PropTypes.oneOf(validColors),
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  squareImg: PropTypes.bool,
  disabled: PropTypes.bool,
  smallCircleText: PropTypes.string,
  smallCircleGlyph: PropTypes.oneOf(validGlyphs),
  smallCircleBackgroundColor: PropTypes.oneOf(validColors),
  isNewTag: PropTypes.bool
};

export default Thumbnail;
