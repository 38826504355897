import moment from 'moment';
import downloadActCodeForBatchApi from '../../../apiCalls/downloadActCodeForBatchApi';

const formatCode = rawCode => {
  const groups = rawCode.match(/(.|[\r\n]){1,4}/g);
  if (groups) {
    const formatedCode = groups.reduce((accumulator, currentValue) => `${accumulator}-${currentValue}`, '');
    return formatedCode.slice(1);
  }
  return 'Error formating code';
};

const createTxtContent = rows => {
  let txtContent = `data:text/plain;charset=utf-8,`;
  rows.forEach(row => {
    txtContent += row;
    txtContent += '\r\n';
  });
  return txtContent;
};

const createTxtFileLink = (content, batchName) => {
  const time = moment().format('YYYY-MM-DD-HH-mm-ss');
  const encodedUri = encodeURI(content);
  const link = document.createElement('a');
  const uniqueName = `${batchName}-${time}`;
  link.setAttribute('href', encodedUri);
  link.setAttribute('id', uniqueName);
  link.setAttribute('download', `${uniqueName}.txt`);
  document.body.appendChild(link);
  return link;
};

function* downloadActCodesForBatch(action) {
  const apiResponse = yield downloadActCodeForBatchApi(action.data.batchName);
  if (apiResponse?.data) {
    let codes;
    if (action.data.structured) {
      codes = apiResponse.data.map(code => formatCode(code));
    } else {
      codes = apiResponse.data;
    }
    try {
      const link = createTxtFileLink(createTxtContent(codes), action.data.batchName);
      link.click();
    } catch (e) {
      console.log(e);
    }
  } else {
    console.log(apiResponse?.error);
  }
  yield null;
}

export default downloadActCodesForBatch;
