import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import withLocalizedContent from '../../language/withLocalizedContent';
import PopupOverlay from '../PopupOverlay/PopupOverlay';
import styles from './NGSAccountMenu.scss';

const NGSAccountMenu = ({
  buttonElementId,
  onTogglePopup,
  onLogout,
  localizedContent: { accountMenuComponent: content }
}) => (
  <PopupOverlay
    id="accountWidget"
    visible
    hangRight
    customClassName={classnames(styles.headerPopup, styles.accountWidget)}
    onTogglePopup={onTogglePopup}
    aria={{
      label: 'Account Menu'
    }}
    ariaRole="listbox"
    buttonElementId={buttonElementId}
  >
    <nav className={styles.accountWidget}>
      <Link id="accountWidget-proifle" className={styles.itemLink}>
        My Profile
      </Link>
      <Link id="accountWidget-signOut" className={styles.itemLink} onClick={onLogout}>
        Log out
      </Link>
    </nav>
  </PopupOverlay>
);

NGSAccountMenu.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  /** This is the ID of the button used to toggle the popup. Click to this element are ignored when closing the popup */
  buttonElementId: PropTypes.string.isRequired,
  onTogglePopup: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired
};

export default withLocalizedContent('accountMenuComponent')(NGSAccountMenu);
