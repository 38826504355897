import PropTypes from 'prop-types';

export default PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  unitUuid: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  unitName: PropTypes.string,
  previewURL: PropTypes.string,
  downloadURL: PropTypes.string,
  downloadable: PropTypes.bool,
  type: PropTypes.string,
  mimetype: PropTypes.string,
  size: PropTypes.number
});
