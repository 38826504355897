import signedFetch from './util/signedFetch.js';

/**
 * This takes the following params
 *
  "productName": "TestForProductCreate",
  "organizationalUnit": 'ELT',
  "state": 1,
  "email": "oup@oup.com",
 */
export default () => signedFetch('getOrganizationalUnits', `${__API_BASE__}/admin-console/product/org-unit`, 'GET');
